import React, { useMemo } from 'react';
import styled from 'styled-components';
import { createPortal } from 'react-dom';

import { ActionMenuContainer, SelectableContainer } from '@splunk/dashboard-ui';
import type {
    DataSourceMeta,
    InputDefinition,
    RootDataSourcesDefinition,
    VisualizationDefinition,
    LayoutItemType,
} from '@splunk/dashboard-types';

import { useActionMenus } from '../contexts/ActionMenusContext';

export interface ActionMenuProps {
    top?: number;
    itemId?: string;

    isSelected?: boolean;
    showLastUpdated?: boolean;

    dataSourceMeta?: DataSourceMeta;
    dataSourceDefinitions?: RootDataSourcesDefinition;

    actionMenuPortal?: React.MutableRefObject<HTMLDivElement | null>;
    renderActionMenuInPortal?: boolean;

    type: LayoutItemType;
    itemDefinition?: InputDefinition | VisualizationDefinition;
}

type ActionMenuWrapperProps = Pick<
    ActionMenuProps,
    'isSelected' | 'top' | 'renderActionMenuInPortal'
>;

type useActionMenusWithDataProps = Pick<
    ActionMenuProps,
    'itemId' | 'dataSourceDefinitions' | 'type' | 'itemDefinition'
>;

const ActionMenuWrapper = styled.div.attrs<ActionMenuWrapperProps>(
    ({ top }) => {
        return {
            'data-test': 'action-menu-wrapper',
            style: {
                ...(top ? { top: `${top}px` } : { bottom: 0 }),
            },
        };
    }
)<ActionMenuWrapperProps>`
    visibility: ${({ isSelected }) => (isSelected ? 'visible' : 'hidden')};
    transition: visibility 0.2s ease-in 0;

    position: absolute;
    right: 0;
    width: fit-content;
    height: fit-content;
    pointer-events: ${({ isSelected }) => (isSelected ? 'all' : 'none')};
    z-index: ${({ renderActionMenuInPortal }) =>
        renderActionMenuInPortal ? 'undefined' : '3'};

    /* On hover of absolute-item (or menu portal) make the action menu visible */
    div[data-test='absolute-item']:hover
        + div[data-test='action-menu-portal']
        > &,
    & div[data-test='action-menus']:hover,
    ${SelectableContainer}:hover & {
        transition: visibility 0.2s ease-in 0.3s;
        visibility: visible;
        pointer-events: all;
    }

    // The :hover pseudo selector cannot be mocked during visual testing, so also
    // support a data-test selector to trigger this state in storybook test cases
    // but DO NOT add a transition duration for visual tests (reduces flakiness)
    div[data-test='absolute-item'][data-test-hovered]
        + div[data-test='action-menu-portal']
        > &,
    & div[data-test='action-menus'][data-test-hovered],
    ${SelectableContainer}[data-test-hovered] & {
        transition: visibility 0s;
        visibility: visible;
        pointer-events: all;
    }
`;

// Helper hook to add additional props to the ActionMenu buttons
const useActionMenusWithData = ({
    type,
    itemId,
    itemDefinition,
    dataSourceDefinitions,
}: useActionMenusWithDataProps) => {
    // Action Menu Props
    const actionMenus = useActionMenus();

    return useMemo(() => {
        return actionMenus.map((item) =>
            React.cloneElement(item, {
                type,
                itemId,
                itemDefinition,
                visualization: itemDefinition,
                dataSources: dataSourceDefinitions,
            })
        );
    }, [type, itemId, actionMenus, dataSourceDefinitions, itemDefinition]);
};

export const ActionMenu = ({
    top,
    itemId,
    isSelected,
    dataSourceMeta,
    dataSourceDefinitions,
    type,
    itemDefinition,
    showLastUpdated,
    actionMenuPortal,
    renderActionMenuInPortal,
}: ActionMenuProps) => {
    const useActionMenusWithDataProps = useMemo(
        () => ({
            type,
            itemId,
            itemDefinition,
            dataSourceDefinitions,
        }),
        [type, itemId, itemDefinition, dataSourceDefinitions]
    );
    const buttonsWithData = useActionMenusWithData(useActionMenusWithDataProps);

    const ActionMenuComponent = useMemo(() => {
        return (
            <ActionMenuWrapper
                isSelected={isSelected}
                top={top}
                renderActionMenuInPortal={renderActionMenuInPortal}
            >
                <ActionMenuContainer
                    actionButtons={buttonsWithData}
                    lastUpdated={dataSourceMeta?.lastUpdated}
                    status={dataSourceMeta?.status}
                    isRealTimeSearch={dataSourceMeta?.isRealTimeSearch}
                    showLastUpdated={showLastUpdated}
                    itemId={itemId}
                />
            </ActionMenuWrapper>
        );
    }, [
        buttonsWithData,
        dataSourceMeta?.isRealTimeSearch,
        dataSourceMeta?.lastUpdated,
        dataSourceMeta?.status,
        itemId,
        isSelected,
        showLastUpdated,
        top,
        renderActionMenuInPortal,
    ]);

    return actionMenuPortal?.current && renderActionMenuInPortal
        ? createPortal(ActionMenuComponent, actionMenuPortal.current)
        : ActionMenuComponent;
};
