import styled from 'styled-components';
import { Map } from 'maplibre-gl';
import * as React from 'react';
import { isColor } from '@splunk/visualizations-shared/colorUtils';
import { noop } from 'lodash';
import { DEFAULT_CLUSTER_COLOR, DEFAULT_RESULT_LIMIT, DEFAULT_SERIES_COLORS } from './MapUtils';
import type { MarkerLayerType } from './MapUtils';
import MarkerWrapper from './MarkerWrapper';
import type { TooltipProps } from './Tooltip';

interface MarkerIconProps extends React.HTMLAttributes<HTMLOrSVGElement> {
    theme: string;
    color: string;
}

export const MarkerIcon = ({ theme, color, ...otherProps }: MarkerIconProps): JSX.Element => {
    const strokeOpacity = theme === 'light' ? 0.7 : 0.15;
    return (
        <svg width={22} height={28} fill="none" {...otherProps}>
            <path
                d="M11 .5C5.201.5.5 5.201.5 11c0 3.136 1.376 5.952 3.555 7.875 2.839 2.506 4.144 4.58 4.893 6.02.183.35.334.665.469.946l.014.03c.136.285.263.548.387.766.235.412.571.863 1.182.863.61 0 .947-.45 1.182-.863.124-.218.25-.48.387-.765l.014-.03c.135-.282.286-.597.469-.947.75-1.44 2.054-3.514 4.893-6.02A10.476 10.476 0 0 0 21.5 11C21.5 5.201 16.799.5 11 .5Z"
                fill={color}
            />
            <path
                d="M11 .5C5.201.5.5 5.201.5 11c0 3.136 1.376 5.952 3.555 7.875 2.839 2.506 4.144 4.58 4.893 6.02.183.35.334.665.469.946l.014.03c.136.285.263.548.387.766.235.412.571.863 1.182.863.61 0 .947-.45 1.182-.863.124-.218.25-.48.387-.765l.014-.03c.135-.282.286-.597.469-.947.75-1.44 2.054-3.514 4.893-6.02A10.476 10.476 0 0 0 21.5 11C21.5 5.201 16.799.5 11 .5Z"
                stroke="#08090A"
            />
            <path
                d="M11 .5C5.201.5.5 5.201.5 11c0 3.136 1.376 5.952 3.555 7.875 2.839 2.506 4.144 4.58 4.893 6.02.183.35.334.665.469.946l.014.03c.136.285.263.548.387.766.235.412.571.863 1.182.863.61 0 .947-.45 1.182-.863.124-.218.25-.48.387-.765l.014-.03c.135-.282.286-.597.469-.947.75-1.44 2.054-3.514 4.893-6.02A10.476 10.476 0 0 0 21.5 11C21.5 5.201 16.799.5 11 .5Z"
                stroke={color}
                strokeOpacity={strokeOpacity}
            />
        </svg>
    );
};

interface MarkerProps {
    latitude: number;
    longitude: number;
    color: string;
    themeColorScheme: string;
    additionalFields?: TooltipProps['additionalFields'];
    map: React.MutableRefObject<Map>;
    handleLayerClick?: (e) => void;
}

const Marker = React.memo(
    ({
        latitude,
        longitude,
        color,
        themeColorScheme,
        map,
        additionalFields,
        handleLayerClick,
    }: MarkerProps): JSX.Element => {
        const icon = React.useMemo(
            () => <MarkerIcon color={color} theme={themeColorScheme} onClick={handleLayerClick} />,
            [color, handleLayerClick, themeColorScheme]
        );
        return (
            <StyledMarkerWrapper
                latitude={latitude}
                longitude={longitude}
                map={map}
                additionalFields={additionalFields}
                data-test-type="marker"
            >
                {icon}
            </StyledMarkerWrapper>
        );
    }
);

Marker.displayName = 'MapMarker';

const StyledMarkerWrapper = styled(MarkerWrapper)`
    width: 22px;
    height: 28px;
    cursor: pointer;
`;

export const generateMarkerWrapperProps = (
    markerLayer: MarkerLayerType,
    onMapClick: ({ e, payload }) => void = noop
): Omit<MarkerProps, 'themeColorScheme' | 'map'>[] => {
    const latArray = markerLayer.latitude;
    const lonArray = markerLayer.longitude;
    const isStaticColor = !markerLayer.dataColors;
    const limit = Math.min(
        latArray.length,
        lonArray.length,
        markerLayer.resultLimit ? markerLayer.resultLimit : DEFAULT_RESULT_LIMIT
    );
    const markerProps: ReturnType<typeof generateMarkerWrapperProps> = [];
    for (let i = 0; i < limit; i += 1) {
        const staticColor =
            markerLayer.seriesColors && isColor(markerLayer.seriesColors[0])
                ? markerLayer.seriesColors[0]
                : DEFAULT_CLUSTER_COLOR;
        const dynamicColor =
            markerLayer.dataColors && isColor(markerLayer.dataColors[i])
                ? markerLayer.dataColors[i]
                : DEFAULT_CLUSTER_COLOR;
        const color = isStaticColor ? staticColor : dynamicColor;
        const additionalFields = markerLayer.metadata
            ? markerLayer.additionalTooltipFields.map(field => ({
                  name: field,
                  value: markerLayer.metadata[i][field],
              }))
            : [];
        const handleLayerClick = e => {
            const metadata = markerLayer?.metadata[i] ?? {};
            const payload = Object.keys(metadata).reduce((acc, curr) => {
                const key = `row.${curr}.value`;
                const value = String(metadata[curr]);
                return { ...acc, [key]: value };
            }, {});

            onMapClick({ e, payload });
        };
        markerProps.push({
            latitude: latArray[i],
            longitude: lonArray[i],
            color,
            additionalFields,
            handleLayerClick,
        });
    }
    return markerProps;
};

export interface MarkerLayerProps {
    markerLayer: MarkerLayerType;
    themeColorScheme: string;
    map: React.MutableRefObject<Map>;
    onMapClick?: ({ e, payload }) => void;
}

const MarkerLayer = (props: MarkerLayerProps): JSX.Element => {
    const { map, themeColorScheme, markerLayer, onMapClick } = props;
    markerLayer.seriesColors = markerLayer.seriesColors || DEFAULT_SERIES_COLORS;
    const markerPropsList = React.useMemo(() => generateMarkerWrapperProps(markerLayer, onMapClick), [
        markerLayer,
        onMapClick,
    ]);

    return (
        <>
            {markerPropsList.map((markerProps, i) => (
                <Marker key={String(i)} themeColorScheme={themeColorScheme} map={map} {...markerProps} />
            ))}
        </>
    );
};

export default MarkerLayer;
