import React from 'react';
import T from 'prop-types';
import Charting from '@splunk/react-visualizations/common/Charting';
import jsCharting from '@splunk/charting-bundle';
import omit from 'lodash/omit';
import isEmpty from 'lodash/isEmpty';
import { convertPropsToString } from '@splunk/visualizations-shared/propUtils';
import { withSanitizedProps } from '@splunk/visualizations-shared/SanitizeProps';
import { CATEGORICAL } from '@splunk/visualization-color-palettes';

const Pie = ({ ...props }) => {
    const {
        width,
        height,
        style,
        themeKey,
        pieLabels,
        pieValues,
        labelTitle,
        valueTitle,
        onPointClick,
        colorPalette,
    } = props;
    const chartOptions = {
        ...omit(props, [
            'width',
            'height',
            'onEventTrigger',
            'style',
            'pieLabels',
            'pieValues',
            'labelTitle',
            'valueTitle',
            'colorPalette',
            'innerSize',
            'legendPlacement',
            'legendLabelOverflowMode',
            'onPointClick',
            'showLabels',
            'showLegend',
            'showPercent',
            'sliceCollapsingLabel',
            'sliceCollapsingThreshold',
        ]),
        chart: 'pie',
        'chart.showLabels': props.showLabels,
        'chart.showPercent': props.showPercent,
        'chart.showLegend': props.showLegend,
        'legend.placement': props.legendPlacement,
        'legend.labelStyle.overflowMode': props.legendLabelOverflowMode,
        'chart.pieInnerSize': props.innerSize,
        'chart.sliceCollapsingThreshold': props.sliceCollapsingThreshold,
        'chart.sliceCollapsingLabel': props.sliceCollapsingLabel,
    };

    // splunk charting expects certain options like 'seriesColors', 'fieldColors' etc to be a string
    const convertedChartOptions = convertPropsToString({
        vizProps: chartOptions,
        propNames: ['seriesColors', 'fieldColors'],
    });

    // copy convertedChartOptions into chartOptions
    Object.assign(chartOptions, convertedChartOptions);

    const primaryDataSet = {};
    primaryDataSet.fields = [{ name: labelTitle }, { name: valueTitle }];
    primaryDataSet.columns = [pieLabels, pieValues];
    const chartData = !isEmpty(primaryDataSet) ? jsCharting.extractChartReadyData(primaryDataSet) : null;

    const onClick = ({ type, originalEvent, payload }) => {
        if (type === 'point.click') {
            onPointClick({ payload, originalEvent });
        }
    };

    return (
        <Charting
            width={width}
            height={height}
            style={style}
            themeKey={themeKey}
            chartData={chartData}
            options={chartOptions}
            onEventTrigger={onPointClick}
            onClick={onClick}
            colorPalette={colorPalette}
        />
    );
};

Pie.propTypes = {
    width: T.oneOfType([T.string, T.number]),
    height: T.oneOfType([T.string, T.number]),
    onPointClick: T.func,
    style: T.object,
    themeKey: T.string,
    pieLabels: T.arrayOf(T.string),
    pieValues: T.arrayOf(T.string),
    showLabels: T.bool,
    showPercent: T.bool,
    showLegend: T.bool,
    legendPlacement: T.oneOf(['right', 'left', 'top', 'bottom', 'none']),
    legendLabelOverflowMode: T.oneOf(['ellipsisEnd', 'ellipsisMiddle', 'ellipsisStart', 'ellipsisNone']),
    innerSize: T.number,
    sliceCollapsingLabel: T.string,
    sliceCollapsingThreshold: T.number,
    backgroundColor: T.string,
    foregroundColor: T.string,
    seriesColors: T.oneOfType([T.string, T.arrayOf(T.string)]),
    fieldColors: T.oneOfType([T.string, T.object]),
    fontColor: T.string,
    labelTitle: T.string,
    valueTitle: T.string,
    colorPalette: T.array,
};

Pie.defaultProps = {
    height: 250,
    width: '100%',
    style: {},
    themeKey: 'prismaDark',
    showLabels: true,
    showPercent: false,
    showLegend: false,
    legendPlacement: 'right',
    legendLabelOverflowMode: 'ellipsisMiddle',
    innerSize: 0,
    sliceCollapsingLabel: 'other',
    sliceCollapsingThreshold: 0.01,
    labelTitle: 'component',
    valueTitle: 'count',
    onPointClick: () => {},
    colorPalette: CATEGORICAL,
};

export default withSanitizedProps(Pie);
