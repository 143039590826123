import React from 'react';
import type { DataSourceEventPayload } from '@splunk/dashboard-types';
import hoistNonReactStatics from 'hoist-non-react-statics';
import { useRiskySearchToast } from './hooks/useRiskySearchToast';

export interface HOCProps {
    dataSources?: Record<string, DataSourceEventPayload>;
}

export interface ComponentProps extends HOCProps {
    [x: string]: unknown;
}

/**
 * Simple wrapper that adds a risky search command toast message to any component that takes in datasources
 *
 * NOTE: this uses hoistNonReactStatics to retain the original static properties of the component
 * so things like menus and editors still work
 *
 * @param {React.ComponentType} ComponentToWrap Any React component that has { dataSources } as a prop
 * @returns A wrapped component
 */
export const withRiskySearchToast = <P extends HOCProps>(
    ComponentToWrap: React.ComponentType<P>
): React.FC<P> =>
    hoistNonReactStatics(({ dataSources, ...rest }) => {
        useRiskySearchToast({ dataSource: dataSources?.primary });

        return <ComponentToWrap {...(rest as P)} dataSources={dataSources} />;
    }, ComponentToWrap);

export const mapWithRiskySearchToast = <
    M extends Record<string, React.ComponentType>
>(
    components: M
) =>
    Object.fromEntries(
        Object.entries(components).map(([key, value]) => [
            key,
            withRiskySearchToast(value),
        ])
    ) as M;
