import { _ } from '@splunk/ui-utils/i18n';

const axes = {
    label: _('Axes'),
    layout: [
        [
            {
                label: _('X Axis title'),
                option: 'axisTitleX.text',
                editor: 'editor.text',
            },
        ],
        [
            {
                label: _('Y Axis title'),
                option: 'axisTitleY.text',
                editor: 'editor.text',
            },
        ],
    ],
};

export default axes;
