/* eslint-disable no-param-reassign */
import * as React from 'react';
import { isPlainObject } from 'lodash';
import { isColor } from './colorUtils';

const propsKeyValidators = {
    backgroundColor: isColor,
    deltaColor: isColor, // SV react-visualizations & dashboard-visualizations
    trendColor: isColor, // SV visualizations
    fontColor: isColor,
    fillColor: isColor,
    gaugeColor: isColor,
    majorColor: isColor,
    radialBackgroundColor: isColor,
    radialStrokeColor: isColor,
    sparklineFillColor: isColor,
    sparklineStrokeColor: isColor,
    strokeColor: isColor,
    strokeHighlightColor: isColor,
    headerBackgroundColor: isColor,
    rowBackgroundColorOdd: isColor,
    rowBackgroundColorEven: isColor,
    headerTextColor: isColor,
    rowTextColorOdd: isColor,
    rowTextColorEven: isColor,
    color: isColor,
    textColor: isColor,
    defaultFontColor: isColor,
    defaultBlockFontColor: isColor,
    sparklineAreaColor: isColor,
    iconColor: isColor,
    areaColor: isColor,
    nodeColor: isColor,
    nodeHighlightColor: isColor,
    linkColor: isColor,
    nodeTextColor: isColor,
    nodeTextHighlightColor: isColor,
    trellisBackgroundColor: isColor,
};

/**
 * Validates if a given key value pair are valid
 * based on a set of predefined rules.
 *
 * @param {string} key
 * @param {string} value
 * @returns {boolean}
 */
function isValidProperty(key: string, value: string): boolean {
    const { hasOwnProperty } = Object.prototype;
    return hasOwnProperty.call(propsKeyValidators, key) ? propsKeyValidators[key](value) : true;
}

/**
 * Validates every property in the key and return
 * only valid keys
 *
 * @export
 * @template T
 * @param {T} props
 * @returns {Partial<T>}
 */
export function sanitize<T extends Record<string, any>>(props: T): Partial<T> {
    return Object.keys(props).reduce((accum, key) => {
        if (key === 'options' && isPlainObject(props.options)) {
            accum[key] = sanitize(props.options);
        } else if (isValidProperty(key, props[key])) {
            accum[key] = props[key];
        }
        return accum;
    }, {});
}

/**
 * Helper HOC which will sanitize invalid props
 *
 * @export
 * @param {React.ComponentType} WrappedComponent
 * @returns {React.ComponentType}
 */
export function withSanitizedProps<T>(
    WrappedComponent: React.ComponentType<T>
): React.ComponentType<Partial<T>> {
    const Wrapper = (props: T) => {
        const sanitizedProps = sanitize(props);
        return <WrappedComponent {...(sanitizedProps as T)} />;
    };
    return Wrapper;
}
