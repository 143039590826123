import React, {
    createContext,
    useCallback,
    useContext,
    useEffect,
} from 'react';
import type {
    DashboardJSON,
    DashboardPlugin,
    DashboardPluginWrapper,
} from '@splunk/dashboard-types';
import {
    SearchMetricsCollector,
    useTelemetryApi,
} from '@splunk/dashboard-telemetry';
import { createDashboardPlugin } from './plugin';

const emptyDef = Object.freeze<DashboardJSON>({});
// Tuple makes it easy to pull out only the needed part
export type PluginContextType = [
    DashboardPluginWrapper,
    SearchMetricsCollector
];

export const createCollector = ({
    initialDefinition = emptyDef,
}: {
    initialDefinition?: DashboardJSON;
} = {}) =>
    new SearchMetricsCollector({
        dataSourceCount: Object.keys(initialDefinition.dataSources ?? {})
            .length,
        timeout: 60 * 1000, // 60s timeout before we force log metrics
    });

export const createPlugin = ({
    plugin,
    collector,
}: {
    plugin: React.MutableRefObject<DashboardPlugin>;
    collector: SearchMetricsCollector;
}) => createDashboardPlugin(plugin, collector);

export const DashboardPluginContext = createContext<PluginContextType>(
    [] as unknown as PluginContextType
);

export const DashboardPluginContextProvider = ({
    children,
    value,
}: {
    children: React.ReactNode;
    value: PluginContextType;
}) => {
    // Must get telemetry here instead of the useDashboardPluginContextValue hook else the correct telemetryApi won't be in the tree yet
    const telemetryAPI = useTelemetryApi();
    const [, searchMetricsCollector] = value;

    // We'll emit telemetry whenever all the searches have completed, or we unmount whichever comes first
    const handleSearchMetricsComplete = useCallback<
        SearchMetricsCollector['onAllMetricsCollected']
    >(
        ({ searchMetrics, loadTime, isUnmounting }) => {
            telemetryAPI.emit({
                pageAction: isUnmounting
                    ? 'dashboard.unmount_metrics'
                    : 'dashboard.metrics',
                // If dashboard is unmounting, we don't want to record the load time since it will be inaccurate
                loadTime: isUnmounting ? null : `${loadTime}`,
                searchMetrics,
            });
        },
        [telemetryAPI]
    );

    // emit telemetry on unmount, noop if metrics telemetry already emitted
    useEffect(() => {
        searchMetricsCollector.addMetricsCollectedCallback(
            handleSearchMetricsComplete
        );
        return () => {
            searchMetricsCollector.forceComplete({ isUnmounting: true });
        };
    }, [searchMetricsCollector, handleSearchMetricsComplete]);

    return (
        <DashboardPluginContext.Provider value={value}>
            {children}
        </DashboardPluginContext.Provider>
    );
};

export const DashboardPluginContextConsumer = DashboardPluginContext.Consumer;

export const useDashboardPlugin = () => {
    const [plugin] = useContext(DashboardPluginContext);

    return plugin;
};

export const useSearchMetricsCollector = () => {
    const [, collector] = useContext(DashboardPluginContext);

    return collector;
};
