import { pick as _pick } from 'lodash';
import { isNumber } from '@splunk/visualization-encoding/utils/types';
import * as React from 'react';
import { toDimension, toPadding } from '@splunk/visualizations-shared/style';
import ReactMarkdown from '@splunk/react-ui/Markdown';
import styled, { css } from 'styled-components';
import * as T from 'prop-types';
import { mixins, variables, pick, useSplunkTheme } from '@splunk/themes';

import Heading from '@splunk/react-ui/Heading';
import P from '@splunk/react-ui/Paragraph';
import List from '@splunk/react-ui/List';
import Code from '@splunk/react-ui/Code';

import withDashboardViz from '../common/withDashboardViz';
import config from './config';

type validFontSizes = 'extraSmall' | 'small' | 'default' | 'large' | 'extraLarge';
type validTags = 'h1' | 'h2' | 'h3' | 'p';
type fontMap = { [tag in validTags]: number };
type fontOptions = { [fontSize in validFontSizes]: fontMap };

// map of valid font sizes with elements' respective pixel sizes
export const FONT_SIZES: fontOptions = {
    extraSmall: {
        h1: 18,
        h2: 14,
        h3: 12,
        p: 10,
    },
    small: {
        h1: 20,
        h2: 16,
        h3: 14,
        p: 12,
    },
    default: {
        h1: 24,
        h2: 18,
        h3: 16,
        p: 14,
    },
    large: {
        h1: 32,
        h2: 24,
        h3: 18,
        p: 16,
    },
    extraLarge: {
        h1: 48,
        h2: 32,
        h3: 24,
        p: 18,
    },
};

// startsWithHeader is a prop that can be
// falsy if the markdown text begins with text
// or the number of heading level;
// casting heading level below 4 to 4
// because ReactMarkdown supports heading level 1-4
const MarkdownContainer = styled.div<{
    height: string | number;
    width: string | number;
    rotation: number;
    padding: Array<number>;
    backgroundColor: string;
    fontColor: string;
    startsWithHeader: number | undefined;
}>`
    overflow: auto;
    position: relative;
    box-sizing: border-box;
    ${props => toDimension(_pick(props, ['width', 'height']))};
    ${props => toPadding(props.padding)};
    background-color: ${props => props.backgroundColor};
    transform-origin: center;
    transform: rotate(${props => props.rotation || 0}deg);
    & *:not(code):not(pre):not(code *):not(a) {
        color: ${props => props.fontColor};
    }
    ${({ startsWithHeader }) =>
        startsWithHeader &&
        `
        h${startsWithHeader >= 4 ? 4 : startsWithHeader}:first-of-type { 
            margin-top: 0px;
        }
    `};
`;

const CodeBlockContainer = styled.div<{
    fontSize: number;
}>`
    // from: https://cd.splunkdev.com/devplat/splunk-ui/-/blob/develop/packages/react-ui/src/Markdown/MarkdownStyles.ts
    padding: ${pick({
        enterprise: variables.spacingHalf,
        prisma: css`10px ${variables.spacingLarge}`,
    })};
    margin: ${pick({
            enterprise: variables.spacingHalf,
            prisma: variables.spacingLarge,
        })}
        0;
    background-color: ${pick({
        enterprise: {
            light: variables.gray96,
            dark: variables.gray22,
        },
        prisma: variables.backgroundColorSection,
    })};

    // remove extraneous bottom margin
    pre[data-test='code'] {
        margin-bottom: 0px;
    }

    // add font-size, as span elements did not inherit <code /> font size in SSC testing
    code {
        font-size: ${props => props.fontSize}px !important;
        line-height: ${props => props.fontSize}px !important;
    }
`;

const CodeInlineContainer = styled.code`
    // from: https://cd.splunkdev.com/devplat/splunk-ui/-/blob/develop/packages/react-ui/src/Markdown/MarkdownStyles.ts
    ${mixins.reset('inline')};
    font-family: ${variables.monoFontFamily};
    background-color: ${pick({
        enterprise: {
            light: variables.gray92,
            dark: variables.gray22,
        },
        prisma: variables.neutral200,
    })};
`;

// retrieves the font map associated with a valid `fontSize` value. If the fontSize
//  passed is invalid (does not exist in FONT_SIZES), the `default` value is used.
const getFontMap = (fontSize: string): fontMap => {
    let font = fontSize;
    if (!FONT_SIZES[fontSize] && fontSize !== 'custom') {
        console.error(
            `'${fontSize}' is not a valid value for Markdown's fontSize option. Using the 'default' font size.`
        );
        font = 'default';
    }

    return FONT_SIZES[font];
};

const handleLinkClick = e => e.stopPropagation();

const StyledA = styled.a`
    text-decoration: underlined;
`;

// returns renderers customized with the appropriate font sizes given the provided `fontSize` value.
const getMarkdownRenderer = (
    fontFamilyStyle: React.CSSProperties,
    fontSize: string,
    customFontSize?: number
) => {
    // defaults to customFontSize of 14px if value is invalid
    const cleanedCustomFontSize = isNumber(customFontSize) ? customFontSize : 14;
    const isCustomFontSize = fontSize === 'custom';

    /* eslint-disable react/display-name, react/prop-types */
    const fontMap: fontMap = isCustomFontSize
        ? {
              h1: cleanedCustomFontSize * 2.0,
              h2: cleanedCustomFontSize * 1.5,
              h3: cleanedCustomFontSize * 1.25,
              p: cleanedCustomFontSize,
          }
        : getFontMap(fontSize);

    return {
        paragraphRenderer: ({ children }) => {
            return (
                <P style={{ fontSize: fontMap.p, lineHeight: `${fontMap.p}px`, ...fontFamilyStyle }}>
                    {children}
                </P>
            );
        },
        headingRenderer: ({ children, level }) => {
            return (
                <Heading
                    level={level}
                    style={{
                        fontSize: fontMap[`h${level}`],
                        lineHeight: `${fontMap[`h${level}`]}px`,
                        ...fontFamilyStyle,
                    }}
                >
                    {children}
                </Heading>
            );
        },
        itemRenderer: ({ children, nodeKey }) => {
            return (
                <List.Item
                    style={{ fontSize: fontMap.p, lineHeight: `${fontMap.p}px`, ...fontFamilyStyle }}
                    key={nodeKey}
                >
                    {children}
                </List.Item>
            );
        },
        codeBlockRenderer: ({ language, literal, nodeKey }) => {
            return (
                <CodeBlockContainer fontSize={fontMap.p}>
                    <Code value={literal} language={language} key={nodeKey} />
                </CodeBlockContainer>
            );
        },
        codeRenderer: ({ literal }) => {
            return <CodeInlineContainer style={{ fontSize: fontMap.p }}>{literal}</CodeInlineContainer>;
        },
        linkRenderer: ({ href, children }) => {
            return (
                <StyledA href={href} onClick={handleLinkClick}>
                    {children}
                </StyledA>
            );
        },
    };
};

const MarkdownVisualization = ({
    height,
    width,
    markdown,
    backgroundColor,
    fontColor,
    fontFamily,
    fontSize,
    customFontSize,
    rotation,
    hasEventHandlers,
    onMarkdownClick,
}: MarkdownProps): React.ReactElement => {
    const { fontFamily: defaultFontFamily } = useSplunkTheme();
    // font-family style for non-code content - default font family appended to custom option value as fallback
    const markdownContentFontFamilyStyle: React.CSSProperties = {
        fontFamily: `${fontFamily}, ${defaultFontFamily}`,
    };

    // because DSL evaluation is not guaranteed to return string data if `markdown` is powered by DSL, we do a typecast here
    const stringifiedMarkdown = `${markdown}`;
    const renderersForFontSize = getMarkdownRenderer(
        markdownContentFontFamilyStyle,
        fontSize,
        customFontSize
    );

    return (
        <MarkdownContainer
            data-test="markdown-container"
            padding={[5, 5, 5, 5]}
            width={width}
            height={height}
            rotation={rotation}
            backgroundColor={backgroundColor}
            fontColor={fontColor}
            startsWithHeader={stringifiedMarkdown.trim().match(/^#+/)?.[0].length}
        >
            <ReactMarkdown
                text={stringifiedMarkdown}
                {...renderersForFontSize}
                style={{ height: '100%', cursor: hasEventHandlers ? 'pointer' : 'inherit' }}
                onClick={onMarkdownClick}
            />
        </MarkdownContainer>
    );
};
interface MarkdownProps {
    // width in pixel or string, defaults to 100%
    width: string | number;
    // height in pixel or string
    // set to optional as SUI markdown component handles it by assigning 'height: auto' if no height is specified
    height?: string | number;
    // visualization formatting options
    markdown: string;
    // background color for markdown, does not apply to code blocks
    backgroundColor: string;
    // Font text color of markdown except for code blocks and links
    fontColor: string;
    // Font family of markdown content except for code blocks
    fontFamily: string;
    // Font size for markdown content. Defaults to 'default'.
    fontSize: string;
    // Custom font size for markdown content. Defaults to 14px.
    customFontSize?: number;
    // Custom rotation angle for markdown content. Defaults to 0 degrees.
    rotation?: number;
    // Informs viz if there are handlers listening to events
    hasEventHandlers: boolean;
    // Click event callback
    onMarkdownClick: (...args: any[]) => void;
}

const propTypes: Record<keyof MarkdownProps, T.Validator<any>> = {
    width: T.oneOfType([T.string, T.number]),
    height: T.oneOfType([T.string, T.number]),
    markdown: T.string,
    backgroundColor: T.string,
    fontColor: T.string,
    fontFamily: T.string,
    fontSize: T.string,
    customFontSize: T.number,
    rotation: T.number,
    hasEventHandlers: T.bool,
    onMarkdownClick: T.func,
};

const defaultProps: Record<keyof MarkdownProps, any> = {
    width: '100%',
    height: undefined,
    markdown: '',
    backgroundColor: 'transparent',
    fontColor: 'rgba(255, 255, 255, 0.7)',
    fontFamily: 'Splunk Platform Sans',
    fontSize: 'default',
    customFontSize: 14,
    rotation: 0,
    hasEventHandlers: false,
    onMarkdownClick: () => {},
};

MarkdownVisualization.propTypes = propTypes;
MarkdownVisualization.defaultProps = defaultProps;

export const computeVizProps = (props): MarkdownProps => {
    return props;
};

const Markdown = withDashboardViz({
    ReactViz: MarkdownVisualization as any,
    vizConfig: config,
    computeVizProps,
});
const { themes } = config;

export { themes, config, getFontMap };
export default Markdown;
