import * as React from 'react';
import { _ } from '@splunk/ui-utils/i18n';
import SVG from '@splunk/react-icons/SVGEnterprise';

const Sankey = (props: React.ComponentProps<typeof SVG>): React.ReactElement<SVGElement> => (
    <SVG screenReaderText={_('Sankey')} fill="currentColor" viewBox="0 0 150 100" {...props}>
        <g>
            <path d="M11 39.9956V15H140V18.0054C122.347 18.1939 100.918 23.3084 79.0366 28.5307L76.9556 29.0273C54.2425 34.4439 31.1278 39.8215 11 39.9956Z" />
            <path d="M68.5049 46.2188C49.0321 44.0413 29.6782 42.0704 11 42.002V66.9909C21.1218 66.8063 31.4903 63.8441 42.035 59.4216C50.7914 55.7492 59.6252 51.0896 68.5049 46.2188Z" />
            <path d="M50.65 71.0687C59.5165 72.549 68.4985 74.2858 77.379 76.0186L79.3274 76.399C101.314 80.6918 122.564 84.8409 140 84.9956V52.0028C112.87 52.1564 90.7833 58.5377 70.3622 64.954C68.7943 65.4467 67.2355 65.9398 65.6845 66.4305L65.6792 66.4321L65.6767 66.4329L65.6749 66.4335C60.5999 68.039 55.6082 69.6181 50.65 71.0687Z" />
            <path d="M11 68.9913V75.9949C29.4086 75.8043 46.5435 70.3857 65.1294 64.5082C66.6634 64.023 68.2074 63.5348 69.7627 63.0461C90.2186 56.6189 112.548 50.1567 140 50.0029V20.0093C129.878 20.1938 119.51 23.1561 108.965 27.5786C98.071 32.1475 87.0571 38.2443 75.9844 44.375L75.9568 44.3902C64.9136 50.5046 53.8117 56.6513 42.8086 61.2661C32.134 65.743 21.4883 68.8067 11 68.9913Z" />
        </g>
    </SVG>
);

export default Sankey;
