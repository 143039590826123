import {
    parseNameBasedFieldReference,
    parseIndexBasedFieldReference,
} from '@splunk/visualization-encoding-parsers/Base';
// @TODO(pwied): move the parsing helpers so we can deprecate visualization-encoding-parsers package

// index based references are defined by <dataSource>[<index>]
// this regex matches <text with numbers>[<anything>]
// the "[" "]" part makes it an index based reference
const indexBasedRegx = /^[A-Za-z0-9_]+\[.*\].*$/;
// name based references are defined by <dataSource>.<columnName>
// the "." access makes it a name based reference
const nameBasedRegx = /^[A-Za-z0-9_]+\..*$/;

interface AdvancedEncodingConfig {
    field: string;
    format?: Record<string, unknown>;
}
type EncodingConfig = string | string[] | AdvancedEncodingConfig;

export type { EncodingConfig };

interface DynamicOptionsChunk {
    options: Record<string, unknown>;
    context: Record<string, unknown>;
}
const isIndexBasedReference = ref => indexBasedRegx.test(ref);
const isNameBasedReference = ref => nameBasedRegx.test(ref);

type formatterFromEncodingType = {
    contextConfig: Record<string, unknown> | unknown;
    contextKey: string;
    dsl: string;
};

const convertFormatterToDSL = (
    fieldReference: AdvancedEncodingConfig,
    key: string,
    translatedValue: string
): formatterFromEncodingType | void => {
    switch (fieldReference?.format?.type) {
        case 'gradient':
            return {
                contextConfig: {
                    ...(Array.isArray(fieldReference.format?.ranges) && {
                        stops: [...fieldReference.format.ranges],
                    }),
                    ...(Array.isArray(fieldReference.format?.values) && {
                        colors: [...fieldReference.format.values],
                    }),
                },
                contextKey: `${key}GradientContext`,
                dsl: `${translatedValue} | gradient(${key}GradientContext)`,
            };
        case 'rangevalue': {
            const contextKey = `${key}RangeValueContext`;
            return {
                contextConfig: fieldReference.format?.ranges || [],
                contextKey,
                dsl: `${translatedValue} | rangeValue(${contextKey})`,
            };
        }
        case 'matchvalue': {
            const defaultValue = fieldReference.format?.defaultValue;
            const matchValueContextKey = `${key}MatchValueContext`;
            return {
                contextConfig: fieldReference.format?.matches || [],
                contextKey: matchValueContextKey,
                dsl: `${translatedValue} | matchValue(${matchValueContextKey}${
                    defaultValue !== null && defaultValue !== undefined
                        ? `, ${typeof defaultValue === 'string' ? `"${defaultValue}"` : defaultValue}`
                        : ''
                })`,
            };
        }
        default:
            break;
    }
    return null;
};

/**
 * Translates encoding data selections in array format
 *
 * Note:
 * 'frameBySeriesIndexes' only supports positive indexes
 *
 * Examples:
 * 1. all index based selection
 * ['primary[0]', 'primary[1]'] => '> primary | frameBySeriesIndexes(0,1)'
 *
 * 2. all name based selection
 * ['primary.foo', 'primary.bar'] => '> primary | frameBySeriesNames("foo","bar")'
 *
 * 3. index or name based selections
 * ['primary.foo', 'primary[1]'] => '> primary | frameBySeriesNamesOrIndexes("foo",1)'
 */
const translateArrayFormatDataReference = (encodingDataReference: string[]): string => {
    if (encodingDataReference.length === 0) {
        return null;
    }
    const allIndexBased = encodingDataReference.every(isIndexBasedReference);
    const allNameBased = encodingDataReference.every(isNameBasedReference);

    if (allIndexBased) {
        const { dataSourceName } = parseIndexBasedFieldReference(encodingDataReference[0]);
        const indexesStr = encodingDataReference
            .map(ref => parseIndexBasedFieldReference(ref)?.fieldIndex)
            .join(',');
        return `> ${dataSourceName} | frameBySeriesIndexes(${indexesStr})`;
    }
    if (allNameBased) {
        const { dataSourceName } = parseNameBasedFieldReference(encodingDataReference[0]);
        const namesStr = encodingDataReference
            .map(ref => `"${parseNameBasedFieldReference(ref)?.fieldName}"`)
            .join(',');
        return `> ${dataSourceName} | frameBySeriesNames(${namesStr})`;
    }
    // it's a mix of name and index based references
    // get the first ref to extract the dataSource
    const firstEntry = encodingDataReference[0];
    const { dataSourceName } = isIndexBasedReference(firstEntry)
        ? parseIndexBasedFieldReference(firstEntry)
        : parseNameBasedFieldReference(firstEntry);
    const paramStr = encodingDataReference
        .map(ref => {
            return isIndexBasedReference(ref)
                ? parseIndexBasedFieldReference(ref)?.fieldIndex
                : `"${parseNameBasedFieldReference(ref)?.fieldName}"`;
        })
        .join(',');
    return `> ${dataSourceName} | frameBySeriesNamesOrIndexes(${paramStr})`;
};

/**
 * Translates an encoding data selection string to a dynamic options data selection
 *
 * Index based column selection
 * 'primary[0]' => '> primary | seriesByIndex(0)'
 *
 * Index range based column selection
 * 'primary[1:]' => '> primary | frameBySeriesIndexRange(1)'
 * 'primary[0:3]' => '> primary | frameBySeriesIndexRange(0,2)'
 *
 * Name based column selection
 * 'primary.foo' => '> primary | seriesByName("foo")'
 *
 * Encoding references
 * 'encoding.x' => '> x'
 */
const translateDataReference = (encodingDataReference: string): string => {
    if (encodingDataReference.indexOf('encoding.') === 0) {
        return `> ${encodingDataReference.replace('encoding.', '')}`;
    }
    const isIndexBasedRef = isIndexBasedReference(encodingDataReference);
    const isNameBasedRef = isNameBasedReference(encodingDataReference);

    if (!isIndexBasedRef && !isNameBasedRef) {
        console.warn('Unable to parse the encoding. Please verify the encoding format is correct');
        return null;
    }

    if (isNameBasedRef) {
        const { dataSourceName, fieldName, columnIndex } = parseNameBasedFieldReference(
            encodingDataReference
        );
        let returnString = `> ${dataSourceName} | seriesByName("${fieldName}")`;
        if (columnIndex) {
            returnString += ` | pointByIndex(${columnIndex})`;
        }
        return returnString;
    }
    if (isIndexBasedRef) {
        const {
            dataSourceName,
            fieldIndex,
            columnIndex,
            isFieldIndexRange,
            fromFieldIndex,
            toFieldIndex,
        } = parseIndexBasedFieldReference(encodingDataReference);
        if (isFieldIndexRange) {
            let dslToIndexString;
            if (toFieldIndex === -1) {
                dslToIndexString = '';
            } else {
                // adjust index, DSL is using default slice behavior whereas encoding behaved differently
                dslToIndexString = `,${toFieldIndex + 1}`;
            }
            return `> ${dataSourceName} | frameBySeriesIndexRange(${fromFieldIndex}${dslToIndexString})`;
        }
        let returnString = `> ${dataSourceName} | seriesByIndex(${fieldIndex})`;
        if (columnIndex) {
            returnString += ` | pointByIndex(${columnIndex})`;
        }
        return returnString;
    }
    return null;
};
/**
 * An encoding configuration can come in various different forms:
 *
 * 1. Simple data selection (either index or name based)
 * {
 *    x: 'primary[0]',
 *    y: 'primary.count'
 * }
 *
 * 2. Multi column selection in array format
 * {
 *    x: 'primary[0]',
 * 	  y: ['primary.count', 'primary.count2']
 * }
 *
 * 3. Advanced Encoding Configuration (for data selection and formatting)
 * {
 *    x: {
 * 	     field: 'primary[0]',
 * 	  },
 *    y: {
 * 	     field: 'primary[1]',
 *       format: {},
 *    }
 * }
 */
const encodingToDynamicOptionsDSL = (encoding: Record<string, EncodingConfig>): DynamicOptionsChunk => {
    const dynamicOptions: DynamicOptionsChunk = {
        options: {},
        context: {},
    };
    const translatedOptions = {};
    const encodingObj = encoding ?? {};
    Object.keys(encodingObj).forEach(key => {
        const fieldReference = encoding[key];
        let fieldReferenceStr = fieldReference as string;
        if (typeof fieldReference === 'object' && !Array.isArray(fieldReference)) {
            const { field } = fieldReference;
            if (field && !Array.isArray(field)) {
                fieldReferenceStr = field;
            }
        }
        let translatedValue;
        // field reference is either in form of array or string
        if (Array.isArray(fieldReference)) {
            translatedValue = translateArrayFormatDataReference(fieldReference);
        } else {
            translatedValue = translateDataReference(fieldReferenceStr);
        }

        // omit if translated value is not set
        if (translatedValue) {
            translatedOptions[key] = translatedValue;
            const convertedFormatterObject = convertFormatterToDSL(
                fieldReference as AdvancedEncodingConfig,
                key,
                translatedValue
            );

            if (convertedFormatterObject) {
                translatedOptions[key] = convertedFormatterObject.dsl;
                dynamicOptions.context[convertedFormatterObject.contextKey] =
                    convertedFormatterObject.contextConfig;
            }
        }
    });
    dynamicOptions.options = translatedOptions;
    return dynamicOptions;
};

export { encodingToDynamicOptionsDSL };
