import React, { useCallback } from 'react';
import { noop } from '@splunk/dashboard-utils';
import styled from 'styled-components';
import Remove from '@splunk/react-icons/enterprise/Remove';
import { variables } from '@splunk/themes';
import type { ItemProps } from './Gallery';

const ImageContainer = styled.div`
    width: 90px;
    height: 35px;
    margin: 8px 5px 0 5px;
    border-radius: 3px;
    &:hover {
        cursor: pointer;
        background: ${variables.backgroundColorHover};
    }
`;
const ImageClose = styled.div<Pick<ItemProps, 'selected' | 'onClick'>>`
    display: flex;
    visibility: ${(props) => (props.selected ? 'visible' : 'hidden')};
    justify-content: flex-end;
`;

const ImageText = styled.div`
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    text-align: center;
    color: ${variables.contentColorDefault};
`;

const ImageGalleryItem = ({
    item,
    selected = false,
    onClick = noop,
    onMouseEnter = noop,
    onMouseLeave = noop,
    onRemove = noop,
}: ItemProps) => {
    const { name, url } = item;
    const handleClick = useCallback(() => onClick(item.url), [onClick, item]);
    const handleMouseEnter = useCallback(
        () => onMouseEnter(item),
        [onMouseEnter, item]
    );
    const handleMouseLeave = useCallback(
        () => onMouseLeave(item),
        [onMouseLeave, item]
    );
    const handleDelete = useCallback(
        (evt) => {
            evt.stopPropagation();
            onRemove?.(item);
        },
        [onRemove, item]
    );

    return (
        <ImageContainer
            data-test="image-container"
            data-url={url}
            key={url}
            title={name}
            onClick={handleClick}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
        >
            {onRemove && (
                <ImageClose
                    selected={selected}
                    data-test="image-close"
                    onClick={handleDelete}
                >
                    <Remove size={0.5} />
                </ImageClose>
            )}
            {/* TODO Need a way to view a smaller low res version of the image in thumbnail */}
            <ImageText data-test="image-name">{name}</ImageText>
        </ImageContainer>
    );
};

export default ImageGalleryItem;
