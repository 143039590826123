import type { ErrorObject, ValidateFunction, JSONSchemaType } from 'ajv';
import type { DashboardJSON } from '@splunk/dashboard-types';

import type { ValidationErrors } from '../types';

// Split the checks into individual files to make the tests more readable
import { checkDuplicateTokens } from '../utils/checkDuplicateTokens';
import { checkInputsInStructure } from '../utils/checkInputsInStructure';
import { checkInvalidSmartSources } from '../utils/checkInvalidSmartSources';
import { checkVisualizationsInStructure } from '../utils/checkVisualizationsInStructure';
import { memoizedSchemaCompile } from '../utils/memoizedSchemaCompile';
import { isEmpty } from '../utils/isEmpty';

/**
 * A dashboard definition validation class
 * @class DashboardValidator
 */
export class DashboardValidator {
    // Make this property protected so that in the unit tests the
    // class can be extended and this property can be validated.
    protected validateDefinition: ValidateFunction | null = null;

    /**
     * set up customized schema
     * @method setSchema
     * @param {Object} newSchema
     * @returns {Object} error
     */
    setSchema(newSchema: JSONSchemaType<DashboardJSON>): Error | null {
        if (!newSchema?.$id) {
            return null;
        }

        try {
            this.validateDefinition = memoizedSchemaCompile(newSchema);
        } catch (error) {
            return error as Error;
        }

        return null;
    }

    /**
     * Validates the current definition
     * @method validate
     * @returns {Array} list of errors, or null
     */
    validate(
        definition: DashboardJSON,
        readOnlyTokenNamespaces?: string[]
    ): ErrorObject[] | ValidationErrors | null {
        if (!this.validateDefinition) {
            throw new Error('setSchema must be called first');
        }
        const valid = this.validateDefinition(definition);

        if (!valid && this.validateDefinition?.errors?.length) {
            return this.validateDefinition.errors;
        }

        const res = [
            ...checkDuplicateTokens(definition),
            ...checkVisualizationsInStructure(definition),
            ...checkInputsInStructure(definition),
            ...checkInvalidSmartSources(definition, readOnlyTokenNamespaces),
        ];

        return isEmpty(res) ? null : res;
    }
}
