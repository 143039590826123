import React from 'react';
import T from 'prop-types';
import { _ } from '@splunk/ui-utils/i18n';
import SVG, {
    type SVGPropsOptionalViewBox,
} from '@splunk/react-icons/SVGEnterprise';

export default function Input({
    screenReaderText,
    ...props
}: SVGPropsOptionalViewBox): ReturnType<typeof SVG> {
    return (
        <SVG viewBox="0 0 24 24" screenReaderText={screenReaderText} {...props}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M7 6C7 5.44772 7.44772 5 8 5C8.55228 5 9 5.44772 9 6C9 6.55228 8.55228 7 8 7C7.44772 7 7 6.55228 7 6ZM5.17071 7H4C3.44772 7 3 6.55228 3 6C3 5.44772 3.44772 5 4 5H5.17071C5.58254 3.83481 6.69378 3 8 3C9.30622 3 10.4175 3.83481 10.8293 5H20C20.5523 5 21 5.44772 21 6C21 6.55228 20.5523 7 20 7H10.8293C10.4175 8.16519 9.30622 9 8 9C6.69378 9 5.58254 8.16519 5.17071 7ZM20 13.0094H19.2805C18.8661 14.1697 17.7573 15 16.4546 15C15.1519 15 14.0431 14.1697 13.6286 13.0094H4C3.44772 13.0094 3 12.5617 3 12.0094C3 11.4571 3.44772 11.0094 4 11.0094H13.622C14.0312 9.8393 15.1449 9 16.4546 9C17.7643 9 18.878 9.8393 19.2872 11.0094H20C20.5523 11.0094 21 11.4571 21 12.0094C21 12.5617 20.5523 13.0094 20 13.0094ZM10.8293 19C10.4175 20.1652 9.30622 21 8 21C6.69378 21 5.58254 20.1652 5.17071 19H4C3.44772 19 3 18.5523 3 18C3 17.4477 3.44772 17 4 17H5.17071C5.58254 15.8348 6.69378 15 8 15C9.30622 15 10.4175 15.8348 10.8293 17H20C20.5523 17 21 17.4477 21 18C21 18.5523 20.5523 19 20 19H10.8293ZM7 18C7 17.4477 7.44772 17 8 17C8.55228 17 9 17.4477 9 18C9 18.5523 8.55228 19 8 19C7.44772 19 7 18.5523 7 18ZM16.4546 11C15.9023 11 15.4546 11.4477 15.4546 12C15.4546 12.5523 15.9023 13 16.4546 13C17.0069 13 17.4546 12.5523 17.4546 12C17.4546 11.4477 17.0069 11 16.4546 11Z"
                fill="currentColor"
            />
        </SVG>
    );
}

Input.propTypes = {
    screenReaderText: T.string,
};

Input.defaultProps = {
    screenReaderText: _('Input'),
};
