import * as React from 'react';
import { extent as d3extent } from 'd3-array';
import { scalePoint as d3scalePoint, scaleLinear as d3scaleLinear } from 'd3-scale';
import * as T from 'prop-types';
import { _ } from '@splunk/ui-utils/i18n';
import Message from '@splunk/visualizations-shared/Message';
import ParallelCoordinatesChart from './src/ParallelCoordinatesChart';
import { VizProps } from '../../common/interfaces/VizProps';

interface PureParallelCoordinatesProps extends VizProps {
    fields?: string[];
    columns?: (string[] | number[])[];
    showNullAxis?: boolean;
    lineColor?: string;
    backgroundColor?: string;
    axisLineHighlightColor: string;
    axisLineColor: string;
    axisTitleColor: string;
    axisLabelColor: string;
    lineOpacity: number;
    filterButtonDisabledColor: string;
    filterButtonActiveColor: string;
    filterTextColor: string;
    truncatedTextColor: string;
    filterSelectionColor: string;
    textShadowColor: string;
}

const PureParallelCoordinates = ({ ...props }: PureParallelCoordinatesProps) => {
    const {
        width,
        height,
        columns,
        fields,
        showNullAxis,
        lineColor,
        backgroundColor,
        axisLineHighlightColor,
        axisLineColor,
        axisTitleColor,
        axisLabelColor,
        lineOpacity,
        filterButtonDisabledColor,
        filterButtonActiveColor,
        filterTextColor,
        truncatedTextColor,
        filterSelectionColor,
        textShadowColor,
        mode,
    } = props;

    const minWidth = 200;
    const minHieght = 150;

    if (width < minWidth || height < minHieght) {
        return (
            <Message width={width} height={height} message={_('Too small to render content')} level="info" />
        );
    }

    const nullOffsetHeight = 30;
    const axisPadding = 0.3;
    const minLabelHeight = 16;
    let truncatedCategoriesFlag = false;

    // Set up chart margins
    const margin = { top: 27, right: 20, bottom: 40, left: 40 };
    if (showNullAxis) {
        margin.bottom += nullOffsetHeight;
    }

    const chartWidth = +width - margin.left - margin.right;
    const chartHeight = +height - margin.top - margin.bottom;

    // Data processing logic:
    let data = [];

    for (let i = 0; i < columns[0].length; i += 1) {
        const newObj = {};
        fields.forEach((field, index) => {
            if (columns[index][i] === '') {
                newObj[field] = null;
            } else {
                newObj[field] = columns[index][i];
            }
        });
        data.push(newObj);
    }

    // Calculate horizontal and vertical scales
    const keys = Object.keys(data[0]);
    const yPositionScale = {};

    // check whether the value is numeric
    const quantVal = v => !Number.isNaN(Number(v)) || v === null;

    // const dimensions = keys.filter(key => key !== 'name');
    const dimensions = [...keys];

    dimensions.forEach(d => {
        const val = data.map(p => p[d]);
        if (val.every(quantVal)) {
            // set numeric axis scale
            yPositionScale[d] = d3scaleLinear()
                .domain(d3extent(data, p => Number(p[d])))
                .range([chartHeight, 0]);
        } else {
            // categorical axis scale
            const categoryDomain = val.filter((v, i) => val.indexOf(v) === i);
            let updatedDomain;

            if (chartHeight / categoryDomain.length > minLabelHeight) {
                updatedDomain = [...categoryDomain];
            } else {
                updatedDomain = categoryDomain.slice(0, Math.round(chartHeight / minLabelHeight));
                truncatedCategoriesFlag = true;
                data = data.filter(row => updatedDomain.includes(row[d]));
            }
            yPositionScale[d] = d3scalePoint().domain(updatedDomain).range([chartHeight, 0]);
        }
    });

    const xPositionScale = d3scalePoint().padding(axisPadding).range([0, chartWidth]);
    const nullAxisScale = d3scalePoint().padding(0);

    return (
        <ParallelCoordinatesChart
            backgroundColor={backgroundColor}
            width={chartWidth}
            height={chartHeight}
            margin={margin}
            axisPadding={axisPadding}
            xPositionScale={xPositionScale}
            yPositionScale={yPositionScale}
            data={data}
            dimensions={dimensions}
            showNullAxis={showNullAxis}
            nullOffsetHeight={nullOffsetHeight}
            nullAxisScale={nullAxisScale}
            lineColor={lineColor}
            lineOpacity={lineOpacity}
            axisLineHighlightColor={axisLineHighlightColor}
            axisLineColor={axisLineColor}
            axisTitleColor={axisTitleColor}
            axisLabelColor={axisLabelColor}
            filterButtonDisabledColor={filterButtonDisabledColor}
            filterButtonActiveColor={filterButtonActiveColor}
            filterTextColor={filterTextColor}
            filterSelectionColor={filterSelectionColor}
            truncatedCategoriesFlag={truncatedCategoriesFlag}
            truncatedTextColor={truncatedTextColor}
            textShadowColor={textShadowColor}
            mode={mode}
        />
    );
};

PureParallelCoordinates.propTypes = {
    backgroundColor: T.string,
    width: T.number,
    height: T.number,
    fields: T.array,
    columns: T.array,
    lineColor: T.string,
    lineOpacity: T.number,
    showNullAxis: T.bool,
    axisLineHighlightColor: T.string,
    axisLineColor: T.string,
    axisTitleColor: T.string,
    axisLabelColor: T.string,
    filterButtonDisabledColor: T.string,
    filterButtonActiveColor: T.string,
    filterTextColor: T.string,
    filterSelectionColor: T.string,
    truncatedCategoriesFlag: T.bool,
    truncatedTextColor: T.string,
    textShadowColor: T.string,
    mode: T.string,
};

PureParallelCoordinates.defaultProps = {
    backgroundColor: '#0b0c0e',
    width: 700,
    height: 400,
    fields: null,
    columns: [],
    lineColor: '#7B56DB',
    lineOpacity: 0.5,
    showNullAxis: true,
    axisLineHighlightColor: 'rgba(255, 255, 255, 0.98)',
    axisLineColor: 'rgb(129, 130, 133)',
    axisTitleColor: 'rgba(255, 255, 255, 0.7)',
    axisLabelColor: 'rgba(255, 255, 255, 0.7)',
    filterButtonDisabledColor: 'rgba(255, 255, 255, 0.3)',
    filterButtonActiveColor: '#3993FF',
    filterTextColor: 'rgba(255, 255, 255, 0.7)',
    filterSelectionColor: '#FFFFFF',
    truncatedTextColor: 'rgba(255, 255, 255, 0.5)',
    textShadowColor: '#111215',
    truncatedCategoriesFlag: false,
};

export default PureParallelCoordinates;
