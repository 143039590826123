import type {
    DashboardJSON,
    DashboardProfilerMarks,
    DashboardProfilerMeasurement,
    DashboardProfilerMeasurements,
    DashboardProfilerMeasurementSummary,
    DashboardProfilerPartialMeasurements,
    DashboardProfilerTimers,
} from '@splunk/dashboard-types';
import { console } from '@splunk/dashboard-utils';
import type { TelemetryAPI } from './TelemetryAPI';
import { extractDefinitionInfo } from './utils/extractDefinitionInfo';

export const PROFILER_PAGE_ACTION = 'performance.measure';

export class DashboardProfiler {
    // Track a link to a telemetry API so payloads can be emitted
    private telemetryAPI?: TelemetryAPI;

    // Internal timers (named start/stop/mark structures)
    private timers: DashboardProfilerTimers;

    // Internal measurements (named custom-duration structures)
    private measurements: DashboardProfilerMeasurements;

    // Internal incomplete data for measurements
    private partialMeasurements: DashboardProfilerPartialMeasurements;

    constructor({
        telemetryAPI,
        importFromProfiler,
    }: {
        telemetryAPI?: TelemetryAPI;
        importFromProfiler?: DashboardProfiler | null;
    } = {}) {
        this.telemetryAPI = telemetryAPI;
        this.timers = {};
        this.measurements = {};
        this.partialMeasurements = {};

        if (importFromProfiler) {
            this.importTimers(importFromProfiler);
            this.importMeasurements(importFromProfiler);
            this.importPartialMeasurements(importFromProfiler);
        }
    }

    /**
     * Starts a new timer, overwriting any existing timer with the same name
     * @param timerName Name of the timer to be started
     * @param definition [OPTIONAL] Dashboard definition to be converted to a
     * summary definitionInfo object in the emitted telemetry payload
     */
    startTimer({
        timerName,
        definition,
    }: {
        timerName: string;
        definition?: DashboardJSON;
    }): void {
        this.timers[timerName] = {
            start: performance.now(),
            marks: [],
            definition,
        };
    }

    /**
     * Ends a timer if it has been started and not yet ended
     * @param timerName Name of timer to be ended
     */
    endTimer(timerName: string): void {
        // Do nothing if the timer doesn't exist or has already ended
        if (!this.hasTimer(timerName) || this.timers[timerName].end) {
            return;
        }

        this.timers[timerName].end = performance.now();
    }

    /**
     * Delete the timer with the given name, if it exists
     * @param timerName Name of timer to be cleared
     */
    clearTimer(timerName: string): void {
        delete this.timers[timerName];
    }

    /**
     * Check if the provided timerName is a valid timer
     * @param timerName Name of the timer to check
     * @returns boolean true if the timer has been declared and started
     */
    hasTimer(timerName: string): boolean {
        return Object.prototype.hasOwnProperty.call(this.timers, timerName);
    }

    /**
     * Marks a timer if it has been started and not yet ended. If the timer has not
     * been started then a new timer will be created and no mark will be recorded.
     * @param {Object} markTimerConfig
     * @param {String} markConfig.timerName Name of timer to be marked
     * @param {String | undefined} markConfig.markName [OPTIONAL] A name to display with the mark
     */
    markTimer({
        timerName,
        markName,
    }: {
        timerName: string;
        markName?: string;
    }): void {
        if (!this.hasTimer(timerName)) {
            this.startTimer({ timerName });
            return;
        }

        // Do nothing if the timer has already ended
        if (this.timers[timerName].end) {
            return;
        }

        const markTime = performance.now();
        let prevMarkTime = this.timers[timerName].start;

        if (this.timers[timerName].marks.length) {
            // The timer has been marked before. Get the previous mark.
            const prevMarkId = this.timers[timerName].marks.length - 1;
            const prevMark = this.timers[timerName].marks[prevMarkId];

            // Use the previous mark time instead of the timer
            // start time for the mark duration calculation
            prevMarkTime = prevMark.time;
        }

        this.timers[timerName].marks.push({
            name: markName,
            time: markTime,
            elapsedSinceLastMark: markTime - prevMarkTime,
        });
    }

    /**
     * Emits a summary of a timer. If the timer has not been ended then the
     * emitted summary will use the current time for duration calculations.
     * @param timerName Name of the timer to be emitted
     * @param definition [OPTIONAL] Dashboard definition to be converted to a
     * summary definitionInfo object in the emitted telemetry payload
     * @param metadata [OPTIONAL] Additional event metadata to be included
     */
    emitTimerSummary({
        timerName,
        definition,
        metadata: additionalMetadata,
    }: {
        timerName: string;
        definition?: DashboardJSON;
        metadata?: Record<string, unknown>;
    }): void {
        if (!this.telemetryAPI) {
            console.warn(
                'Cannot emit timer summary without linked TelemetryAPI object'
            );
            return;
        }

        if (!this.hasTimer(timerName)) {
            return;
        }

        // Calculate the duration of the timer, using either performance.now or the end time
        const {
            start,
            end = performance.now(),
            definition: initialDefinition,
        } = this.timers[timerName];
        const duration = end - start;

        // Build the telemetry metadata.
        const metadata: Record<string, unknown> = {
            id: timerName,
            totalDuration: duration,
            ...additionalMetadata,
        };

        // Summarize marks if recorded for the timer
        const summarizedMarks = this.summarizeMarks(timerName);
        if (typeof summarizedMarks !== 'undefined') {
            metadata.marks = summarizedMarks;
        }

        // If definition is provided, convert it to definitionInfo and write it
        // to the metadata. Do NOT include the whole dashboard definition in the
        // payload; it is too large and can cause dropped telemetry
        const definitionInfo = definition || initialDefinition;
        if (typeof definitionInfo !== 'undefined') {
            metadata.definitionInfo = extractDefinitionInfo(definitionInfo);
        }

        // Emit the telemetry with the linked telemetry API
        this.telemetryAPI.emit({
            pageAction: PROFILER_PAGE_ACTION,
            metadata,
        });
    }

    /**
     * Emits a summary of a timer and clears the timer from the profiler. If
     * the timer has not been ended then the emitted summary will use the
     * current time for duration calculations. The cleared timer will no longer
     * be accessible after this method is executed.
     * @param timerName Name of the timer to be emitted
     * @param definition [OPTIONAL] Dashboard definition to be converted to a
     * summary definitionInfo object in the emitted telemetry payload
     * @param metadata [OPTIONAL] Additional event metadata to be included
     */
    emitAndClearTimer({
        timerName,
        definition,
        metadata,
    }: {
        timerName: string;
        definition?: DashboardJSON;
        metadata?: Record<string, unknown>;
    }): void {
        this.emitTimerSummary({ timerName, definition, metadata });
        this.clearTimer(timerName);
    }

    /**
     * Begins a measurement for a provided name
     * @param measurementName Name by which the measurement is tracked
     */
    startMeasurement(measurementName: string): void {
        this.partialMeasurements[measurementName] = performance.now();
    }

    /**
     * Commits a completed measurement with the provided name to an internal
     * measurement collection which can be emitted via a telemetry API
     * @param measurementName Name of measurement with which it is tracked
     */
    endMeasurement(
        measurementName: string,
        metadata?: Record<string, unknown>
    ): void {
        if (!this.hasPartialMeasurement(measurementName)) {
            return;
        }

        if (!this.hasMeasurements(measurementName)) {
            this.measurements[measurementName] = [];
        }

        const measurementEnd = performance.now();
        this.measurements[measurementName].push({
            time: measurementEnd,
            elapsed: measurementEnd - this.partialMeasurements[measurementName],
            metadata,
        });

        delete this.partialMeasurements[measurementName];
    }

    /**
     * Delete the timer with the given name, if it exists
     * @param timerName Name of timer to be cleared
     */
    clearMeasurements(measurementName: string): void {
        delete this.partialMeasurements[measurementName];
        delete this.measurements[measurementName];
    }

    /**
     * Emits a summary of measurements. If the measurement name provided has no
     * associated measurement data then nothing is emitted.
     * @param measurementName Name of the measurement collection to be emitted
     * @param definition [OPTIONAL] Dashboard definition to be converted to a
     * summary definitionInfo object in the emitted telemetry payload
     * @param metadata [OPTIONAL] Additional event metadata to be included
     */
    emitMeasurementSummary({
        measurementName,
        definition,
        metadata: additionalMetadata,
    }: {
        measurementName: string;
        definition?: DashboardJSON;
        metadata?: Record<string, unknown>;
    }): void {
        if (!this.telemetryAPI) {
            console.warn(
                'Cannot emit measurement summary without linked TelemetryAPI object'
            );
            return;
        }

        if (!this.hasMeasurements(measurementName)) {
            return;
        }

        // Build the telemetry metadata.
        const metadata: Record<string, unknown> = {
            id: measurementName,
            ...this.summarizeMeasurements(measurementName),
            ...additionalMetadata,
        };

        // If definition is provided, convert it to definitionInfo and write it
        // to the metadata. Do NOT include the whole dashboard definition in the
        // payload; it is too large and can cause dropped telemetry
        if (typeof definition !== 'undefined') {
            metadata.definitionInfo = extractDefinitionInfo(definition);
        }

        // Emit the telemetry with the linked telemetry API
        this.telemetryAPI.emit({
            pageAction: PROFILER_PAGE_ACTION,
            metadata,
        });
    }

    /**
     * Emits a summary of a measurement and clears the data from the profiler. If
     * the measurement name is not associated with any data, nothing is emitted.
     * The cleared measurement data will no longer be accessible after execution.
     * @param measurementName Name of the measurements to summarize, emit, and clear
     * @param definition [OPTIONAL] Dashboard definition to be converted to a
     * summary definitionInfo object in the emitted telemetry payload
     * @param metadata [OPTIONAL] Additional event metadata to be included
     */
    emitAndClearMeasurements({
        measurementName,
        definition,
        metadata,
    }: {
        measurementName: string;
        definition?: DashboardJSON;
        metadata?: Record<string, unknown>;
    }): void {
        this.emitMeasurementSummary({ measurementName, definition, metadata });
        this.clearMeasurements(measurementName);
    }

    /**
     * Check if the provided measurementName currently has measurement data associated with it
     * @param measurementName Name of the measurement to check for
     * @returns boolean true if there is measurement data associated with the provided measurement name
     */
    hasMeasurements(measurementName: string): boolean {
        const measurementsExist = Object.prototype.hasOwnProperty.call(
            this.measurements,
            measurementName
        );

        return measurementsExist && !!this.measurements[measurementName].length;
    }

    /**
     * Check if the provided measurementName currently has a started partial measurement
     * @param measurementName Name of the measurement to check for
     * @returns boolean true if there is a current partial measurement for the measurement name
     */
    hasPartialMeasurement(measurementName: string): boolean {
        return Object.prototype.hasOwnProperty.call(
            this.partialMeasurements,
            measurementName
        );
    }

    /**
     * Internal method for exporting existing timers to a new profiler. Exported for testing.
     * @returns The currently-defined timer data of the profiler
     */
    exportTimers(): DashboardProfilerTimers {
        return this.timers;
    }

    /**
     * Internal method for exporting measurements to a new profiler. Exported for testing.
     * @returns The currently-defined measurement data of the profiler
     */
    exportMeasurements(): DashboardProfilerMeasurements {
        return this.measurements;
    }

    /**
     * Internal method for exporting existing partial measurements to a new profiler. Exported for testing.
     * @returns The currently-defined partial measurement data of the profiler
     */
    exportPartialMeasurements(): DashboardProfilerPartialMeasurements {
        return this.partialMeasurements;
    }

    /**
     * Internal method for importing timers from another DashboardProfiler instance
     * @param profiler External DashboardProfiler instance from which timers should be imported
     */
    private importTimers(profiler: DashboardProfiler): void {
        this.timers = profiler.exportTimers();
    }

    /**
     * Internal method for importing measurements from another DashboardProfiler instance
     * @param profiler External DashboardProfiler instance from which measurements should be imported
     */
    private importMeasurements(profiler: DashboardProfiler): void {
        this.measurements = profiler.exportMeasurements();
    }

    /**
     * Internal method for importing partial measurements from another DashboardProfiler instance
     * @param profiler External DashboardProfiler instance from which partial measurements should be imported
     */
    private importPartialMeasurements(profiler: DashboardProfiler): void {
        this.partialMeasurements = profiler.exportPartialMeasurements();
    }

    /**
     * Gets the summary of marks in a timer, if the timer exists and has been marked
     * @param timerName Name of the timer to be summarized
     * @returns Populated PerfEntries value if the timer has been marked, else undefined
     */
    private summarizeMarks(
        timerName: string
    ): DashboardProfilerMarks | undefined {
        const data = this.timers[timerName].marks;
        if (!data.length) {
            return undefined;
        }

        return data;
    }

    /**
     * Creates a summary of the measurements in a timer, if the timer exists
     * and has measurements available
     * @param measurementName Name of the measurements to be summarized
     * @returns A populated DashboardProfilerMeasurementSummary value
     */
    private summarizeMeasurements(
        measurementName: string
    ): DashboardProfilerMeasurementSummary {
        const data = this.measurements[measurementName];

        // Get the min duration between marks. Start with positive inf so we can work down
        let minDuration = Number.POSITIVE_INFINITY;

        // Get the max duration between marks. Start with negative inf so we can work up
        let maxDuration = Number.NEGATIVE_INFINITY;

        // Calculate the total duration of every mark
        // Do this manually instead of with a reduction because we're already iterating the array
        let totalDuration = 0;

        let metadata = {};

        // Iterate the data to get the min duration, max duration, and total duration.
        // Do a single pass and manually get the values instead of using three reducers.
        data.forEach((record: DashboardProfilerMeasurement) => {
            if (minDuration > record.elapsed) {
                minDuration = record.elapsed;
            }

            if (maxDuration < record.elapsed) {
                maxDuration = record.elapsed;
            }

            if (typeof record.metadata !== 'undefined') {
                metadata = { ...metadata, ...record.metadata };
            }

            totalDuration += record.elapsed;
        });

        return {
            measurementCount: data.length,
            avgMeasurementDuration: totalDuration / data.length,
            minMeasurementDuration: minDuration,
            maxMeasurementDuration: maxDuration,
            totalMeasurementDuration: totalDuration,
            ...metadata,
        };
    }
}
