import memoize from 'lodash/memoize';
import console from './console';

/**
 * display deprecation message
 */
const deprecated = memoize((message: string | null): void => {
    if (message == null) {
        throw new Error('deprecation message is required');
    }
    console.warn(message);
});

export default deprecated;
