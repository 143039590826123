/* eslint-disable no-param-reassign */
import type { JSONSchema7, JSONSchema7Definition } from 'json-schema';
import { has, isEmpty, get } from 'lodash';

interface OptionsSchema {
    [key: string]: JSONSchema7;
}

interface KV {
    [key: string]: any;
}

export const pickFieldFromJSONSchema = (
    optionsSchema: OptionsSchema | JSONSchema7Definition,
    fieldName = 'default'
): KV =>
    Object.keys(optionsSchema).reduce((accum: Record<string, unknown>, k): Record<string, unknown> => {
        if (has(optionsSchema[k], fieldName)) {
            accum[k] = optionsSchema[k][fieldName];
        } else if (get(optionsSchema, [k, 'type']) === 'object' && has(optionsSchema[k], 'properties')) {
            const nestedSchema = pickFieldFromJSONSchema(optionsSchema[k].properties, fieldName);
            if (!isEmpty(nestedSchema)) {
                accum[k] = nestedSchema;
            }
        }
        return accum;
    }, {});
