/* eslint-disable no-param-reassign */
/* eslint-disable class-methods-use-this */
import { cloneDeep, isPlainObject } from 'lodash';
import { DataFrame } from './DataFrame';
import { Expr, LITERAL_TYPES, LiteralSymbol, Method, ParserSymbol, OptionsStanza } from './AST';
import { formatterClasses } from './FormatterPresets';
import { DataPrimitive, DataType, isDataPrimitive } from './DataPrimitive';
import { IDimension } from './Dimensions';
import { TypeSafeValue } from './TypeSafeValue';
import { DataPoint } from './DataPoint';
import { OptionScopes } from './OptionScopes';
import { DslParser } from './DslParser';
import EncodingParser from './EncodingParser';
import { Helper } from './formatters/Helper';

/**
 * An interface for abstracting data sources as map of DataFrames
 */
export interface Frames {
    [dataSourceType: string]: DataFrame<DataType>;
}
/**
 * Class with static methods used to execute DSL expressions
 */
export default class EncodingExecutor {
    executeOptions(
        optionsStanza: OptionsStanza,
        frames: Frames,
        themeFunc: (themeKey) => any
    ): Record<string, any> {
        const ret = cloneDeep(optionsStanza); // evaluate DSL expressions, in place, in ret object
        const scopes: OptionScopes = new OptionScopes(ret, frames, themeFunc);
        this.eval(ret, scopes);
        return EncodingExecutor.rawTree(ret.options);
    }

    /**
     * o is what we are evaluating. If it is an array or an object that its parts are recursively
     * evaluated. Any DSL expressions encountered are evaluated. The scopes object is uses by the
     * DSL expression to resolve any identifiers. The scope object's 'local' scope is updated by
     * pushLocalScope as the eval method moves through the tree. The path array for a path "context.a.b.c"
     * will be ['context', 'a', 'b','c']. As eval moves through the tree, the scopes add the current
     * path its visited list. If the current path is found in the visited list, then a circular reference
     * error is thrown.
     * current 'path' in the
     * @param o
     * @param {OptionScopes} scopes
     * @param {string[]} path
     * @returns {any}
     */
    public eval(o: any, scopes: OptionScopes, path: string[] = []): any {
        scopes.pushLocalScope(o);
        scopes.addToVisitedList(path);
        // evaluate array or option fields, and write the resulting value back into object o
        if (Array.isArray(o)) {
            // evaluate each array element
            o.forEach((v, i) => {
                path.push(i.toString());
                o[i] = this.eval(o[i], scopes, path);
                path.pop();
            });
        } else if (isPlainObject(o)) {
            // evaluate each object field
            Object.keys(o).forEach(k => {
                path.push(k);
                o[k] = this.eval(o[k], scopes, path);
                path.pop();
            });
        } else if (EncodingParser.isDslString(o)) {
            scopes.popLocalScope();
            const tmp = this.evalDsl(o, scopes);
            scopes.removeFromVisitedList(path);
            return tmp;
        }
        // if here then o is js primitive value or DataPrimitive.
        scopes.popLocalScope();
        scopes.removeFromVisitedList(path);
        return o;
    }

    evalDsl(dsl: string, scopes: OptionScopes): IDimension {
        try {
            const parsedDsl: Expr[] = DslParser.parse(EncodingParser.withoutArrow(dsl));
            return this.execOptionsPipeline(parsedDsl, scopes, scopes.frames.primary);
        } catch (e) {
            console.log(`dsl error: ${e.message}`);
            return undefined;
        }
    }

    /**
     * Takes anything that can have DSL embedded in it, such as tree or array, and insures
     * that all DataPrimitive have been converted to their 'raw' equivalents
     * @param {object} o
     * @returns {any}
     */
    static rawTree(o: Record<string, any>): any {
        // evaluate array or option fields, and write the resulting value back into object o
        if (Array.isArray(o)) {
            // evaluate each array element
            o.forEach((v, i) => {
                o[i] = EncodingExecutor.rawTree(o[i]);
            });
            return o;
        }
        if (isPlainObject(o)) {
            // use isPlainObject to avoid traversing fields of funky options like IconComponent that have methods and circular reference graph
            // evaluate each object field. Such objects would never expect to have DSL embedded in them and will cause a stack overflow due to cycles
            Object.keys(o).forEach(k => {
                o[k] = EncodingExecutor.rawTree(o[k]);
            });
            return o;
        }
        const tmp = isDataPrimitive(o) ? o.getRawValue() : o;
        return tmp;
    }

    /**
     * Executes sequence of pipe delimited expressions that constitute a DSL
     * @param {Expr[]} pipeline
     * @param {OptionScopes} scopes
     * @param {DataPrimitive<DataType>} origin
     * @param {ExecutedOptions} executedOptions
     * @returns {{location: string; val: Option | DataPrimitive}}
     */
    execOptionsPipeline(
        pipeline: Expr[],
        scopes: OptionScopes,
        origin: DataPrimitive<DataType> // meta fields default to the value DataPrimitve as origin. Value field default to frames.primary as their origin
    ): IDimension {
        let subject = origin;
        // process each expression in pipeline expr|expr|expr...
        pipeline.forEach((expr: Expr, i) => {
            if (expr.type === 'method') {
                if (subject) {
                    subject = this.executeMethod(expr, subject, scopes.context);
                }
            } else if (expr.type === 'identifier') {
                // When an identifier exists in the pipeline line like delta in 'delta|foo(bar)' it can refer to
                // either a DataPrimitive or a SimpleOption.
                subject = scopes.resolve(expr.v.toString()).val;
            } else if (LITERAL_TYPES.includes(expr.type)) {
                const tsv = TypeSafeValue.from({ type: expr.type, value: expr.v });
                subject = new DataPoint('', tsv);
            }
            const isLastInPipeline = i === pipeline.length - 1;
            // if the subject will pass forward through another pipe, we must convert it to a DataPrimtive
            if (!isLastInPipeline && subject && !isDataPrimitive(subject)) {
                try {
                    subject = Helper.dataPrimitiveFromRaw(subject);
                } catch (e) {
                    // Expr is a union type of ParserSymbol and Method which has these types defined; however TS flags that these fields are not present, so we do explicit type assertions
                    const exprStr = (expr as ParserSymbol).v
                        ? (expr as ParserSymbol).v
                        : (expr as Method).name;
                    throw new Error(
                        `Output of '${exprStr}' cannot be piped because it is not a DataFrame, DataPoint, or DataSeries`
                    );
                }
            }
        });

        return subject as IDimension;
    }

    /**
     * Methods are either selector methods that built-ins such as
     * 'selectSeriesByPosition(...)",or they are formatter calls like 'rangeValue(...)'
     * @param {Method} expr
     * @param {DataPrimitive} subject
     * @param {Scopes} scopes
     * @param {string} metaName
     * @returns {DataPrimitive}
     */
    private executeMethod(
        expr: Method,
        subject: DataPrimitive<DataType>,
        context: Record<string, any>
    ): DataPrimitive<DataType> {
        const m = expr as Method;
        const args = this.args(m.args, context);
        const method = subject[m.name];
        if (method) {
            subject = method.apply(subject, args); // 'built in' DataPrimitive selectors like selectSeriesByPosition
        } else {
            subject = this.applyFormatter(subject, m.name, args);
        }
        return subject;
    }

    /**
     * Applies the Formatter to either metaData or value
     * @param {DataPrimitive} subject
     * @param {string} funcName
     * @param {ResolvedValue[]} args
     * @param {string} metaName
     */
    private applyFormatter(
        subject: DataPrimitive<DataType>,
        funcName: string,
        args: any[]
    ): DataPrimitive<DataType> {
        const FormatterClass = formatterClasses[funcName];
        if (FormatterClass) {
            return new FormatterClass(...args).format(subject as IDimension);
        }
        throw Error(`No such method or formatter function: '${funcName}'`);
    }

    /**
     * Sometimes when invoking a pipeline we may find degenerate pipelines like
     * '42' that simply set the metadata to a literal. Or, we may perhaps in
     * a value pipeline, we set the value to 'hello' after w perform selection
     * like "selectByPosition(0)|'hello'"
     * @param {DataPrimitive} subject
     * @param {LiteralSymbol} symbol
     */
    applyLiteral(subject: DataPrimitive<DataType>, symbol: LiteralSymbol): void {
        const { type, v: value } = symbol;
        subject.setValue({ type, value });
    }

    /**
     * reduces method call arguments (ParserSymbols) to actual values
     * @param {ParserSymbol[]} args
     * @param {Scopes} scopes
     * @returns {any[]}
     */
    private args(args: ParserSymbol[], context: Record<string, any>): any[] {
        return args.reduce((acc, cur) => {
            const v = this.getArg(cur, context);
            acc.push(v);
            return acc;
        }, []);
    }

    /**
     * Returns either the literal value from the symbol, or a value from context.
     * Throws error if identifier not found in context.
     * @param {ParserSymbol} s
     * @param {object} context
     * @returns {any}
     */
    private getArg(s: ParserSymbol, context: Record<string, any>): any {
        if (s.type === 'identifier') {
            // fixme todo this should use a proper 'resolve' not just look into context
            const val = context[s.v];
            if (!val) {
                throw Error(
                    `Could not resolve ${s.v} in context. Did you mean one of '[${Object.keys(
                        context
                    ).toString()}]'`
                );
            }
            return val;
        }
        return s.v;
    }

    /**
     * Simple method to tell if the argument is an object or primitive.
     * @param opt
     * @returns {boolean}
     */
    static isObject(opt: any): boolean {
        return Object(opt) === opt;
    }
}
