import type { FieldActions, EventAction } from '@splunk/visualization-context/EventsContext';
import { FieldData } from '@splunk/react-field-summary/components/FieldSummary';
import { getAcceleratedTimeRange, isISO, getISOWithTimeZone } from '@splunk/time-range-utils/time';
import { mergeWith, union } from 'lodash';
import { convertToArrOfObjects } from '../common/utils/dataUtils';
import { DataSource } from '../common/interfaces/DataSource';

type SpecialParameters = {
    '@offset'?: string;
    '@sid'?: string;
    '@namespace'?: string;
    '@field_name'?: string;
    '@field_value'?: string;
};

export type TokenReplacementMap = { specialParameters: SpecialParameters; fields: Record<string, string> };

// util to convert a field-value map into an object with row.<fieldname>.value keys for payloads
export const convertToPayloadFormat = (obj, initialValue = {}) => {
    if (!obj) return {};

    return Object.keys(obj).reduce((acc, curr) => {
        const key = `row.${curr}.value`;
        return { ...acc, [key]: obj[curr] };
    }, initialValue);
};

export const getFieldSummaryMapping = (columns, fields) => {
    // convert the `fieldsummary` payload to an array of objects
    const arr = convertToArrOfObjects(columns, fields);

    // forms an array of FieldData elements expected by the @splunk/react-field-summary component
    const fieldSummaryData: FieldData[] = arr.map(d => ({
        name: d.field,
        count: +d.count,
        distinctCount: +d.distinct_count,
        numericCount: +d.numeric_count,
        max: d.max === null ? null : +d.max,
        mean: d.mean === null ? null : +d.mean,
        min: d.min === null ? null : +d.min,
        stdev: d.stdev === null ? null : +d.stdev,
        modes: JSON.parse(d.values),
    }));

    // creates a map of field name to its data for O(1) retrieval in the tooltip component
    const fieldSummaryMapping = fieldSummaryData.reduce((map, fieldData) => {
        return { ...map, [fieldData.name]: fieldData };
    }, {});

    return fieldSummaryMapping;
};

/**
 * Helper to merge event actions into one array of event actions.
 * @method mergeEventActions
 * @param {EventAction[]} eventActions1
 * @param {EventAction[]} eventActions2
 * @returns {EventAction[]} merged event actions
 */
export const mergeEventActions = (
    eventActions1: EventAction[],
    eventActions2: EventAction[]
): EventAction[] => eventActions1.concat(eventActions2);

/**
 * Helper to merge field actions. If same key exists in both field actions,
 * combine each array of actions under one key.
 *
 * Actions under the `default` key should be merged into actions under the `*` key.
 * Although the `default` key is supported to indicate actions that apply to all fields,
 * we are working towards deprecating the `default` key in favour of the `*` key.
 * As of 5.0.0, the react-events-viewer only supports the `*` key to indicate common field actions.
 * @method mergeFieldActions
 * @param {FieldActions} fieldActions1
 * @param {FieldActions} fieldActions2
 * @returns {FieldActions} merged field actions
 */
export const mergeFieldActions = (fieldActions1: FieldActions, fieldActions2: FieldActions): FieldActions => {
    const fieldActions1WithoutDefault = { ...fieldActions1 };
    if ('default' in fieldActions1WithoutDefault) {
        fieldActions1WithoutDefault['*'] =
            '*' in fieldActions1WithoutDefault
                ? [...fieldActions1WithoutDefault['*'], ...fieldActions1WithoutDefault.default]
                : fieldActions1WithoutDefault.default;

        delete fieldActions1WithoutDefault.default;
    }

    const fieldActions2WithoutDefault = { ...fieldActions2 };
    if ('default' in fieldActions2WithoutDefault) {
        fieldActions2WithoutDefault['*'] =
            '*' in fieldActions2WithoutDefault
                ? [...fieldActions2WithoutDefault['*'], ...fieldActions2WithoutDefault.default]
                : fieldActions2WithoutDefault.default;

        delete fieldActions2WithoutDefault.default;
    }

    return mergeWith(
        fieldActions1WithoutDefault,
        fieldActions2WithoutDefault,
        (fieldAction1value, fieldAction2value) =>
            Array.isArray(fieldAction1value) && Array.isArray(fieldAction2value)
                ? union(fieldAction1value, fieldAction2value)
                : undefined
    );
};

/**
 * Helper to replace all tokens from the tokenReplacementMap in text with their corresponding values.
 * Special parameters will only be escaped if allowSpecialParametersEscape is true.
 * Field values will only be escaped if allowFieldValuesEscape is true, and the field does not begin with `!`.
 * https://dev.splunk.com/enterprise/docs/devtools/customworkflowactions/#Prevent-field-value-escape
 *
 * @method replaceTokens
 * @param {string} text
 * @param {TokenReplacementMap} tokenReplacementMap
 * @param {boolean} allowSpecialParametersEscape
 * @param {boolean} allowFieldValuesEscape
 * @returns {string} text with replaced tokens
 */
export const replaceTokens = ({
    text,
    tokenReplacementMap,
    allowSpecialParametersEscape,
    allowFieldValuesEscape,
}: {
    text: string;
    tokenReplacementMap: TokenReplacementMap;
    allowSpecialParametersEscape: boolean;
    allowFieldValuesEscape: boolean;
}): string => {
    let replacedText = text;

    // replace special parameters
    Object.entries(tokenReplacementMap.specialParameters).forEach(([tokenName, tokenValue]) => {
        const maybeEscapedTokenValue = allowSpecialParametersEscape
            ? encodeURIComponent(tokenValue)
            : tokenValue;
        replacedText = replacedText.split(`$${tokenName}$`).join(maybeEscapedTokenValue);
    });

    // replace field values
    Object.entries(tokenReplacementMap.fields).forEach(([tokenName, tokenValue]) => {
        // when allowFieldValuesEscape is true, replace field with escaped value
        const maybeEscapedTokenValue = allowFieldValuesEscape ? encodeURIComponent(tokenValue) : tokenValue;
        replacedText = replacedText.split(`$${tokenName}$`).join(maybeEscapedTokenValue);

        // If field starts with `!`, always replace with the unescaped value
        replacedText = replacedText.split(`$!${tokenName}$`).join(tokenValue);
    });
    return replacedText;
};

/**
 * Generate a token replacement map taking into account Special Parameters and field replacements.
 */
export const generateTokenReplacementMap = ({
    dataSources,
    rowIndex,
    pageNumber,
    field,
    value,
    event = {},
}: {
    dataSources?: { [name: string]: DataSource };
    rowIndex?: number;
    pageNumber?: number;
    field?: string;
    value?: string;
    event?: { [fieldName: string]: any };
}): TokenReplacementMap => {
    const tokenReplacementMap = {
        specialParameters: {},
        fields: {},
    };
    // Map special parameters for workflow actions with values
    // Note that @latest_time will not be supported since it does not work in sxml
    // https://dev.splunk.com/enterprise/docs/devtools/customworkflowactions/#Special-parameters
    const count = dataSources?.primary?.requestParams?.count;
    const offset =
        rowIndex !== undefined && pageNumber !== undefined && count !== undefined
            ? rowIndex + count * (pageNumber - 1)
            : undefined;
    if (offset !== undefined) {
        tokenReplacementMap.specialParameters['@offset'] = String(offset);
    }
    if (dataSources?.primary?.meta?.sid) {
        tokenReplacementMap.specialParameters['@sid'] = dataSources?.primary?.meta?.sid;
    }
    if (dataSources?.primary?.meta?.app) {
        tokenReplacementMap.specialParameters['@namespace'] = dataSources?.primary?.meta?.app;
    }
    if (field) {
        tokenReplacementMap.specialParameters['@field_name'] = field;
    }
    if (value) {
        tokenReplacementMap.specialParameters['@field_value'] = value;
    }

    // Map fields with field values
    Object.entries(event).forEach(([fieldName, fieldValue]) => {
        // handling of _raw and _time fields matches SXML behaviour. Implementation is based on fieldSubstitute() from splunkcore-web-ui
        let replacedValue: string;
        if (fieldName === '_time' && typeof fieldValue === 'string') {
            const splunkTime: string = getISOWithTimeZone(fieldValue);
            const epochTime: number = getEpochTime(splunkTime);
            replacedValue = Math.floor(epochTime).toString();
        } else if (fieldName === '_raw' && typeof fieldValue === 'object') {
            const rawFieldValue = Array.isArray(fieldValue) ? fieldValue[0] : fieldValue?.value;
            replacedValue = rawFieldValue ? rawFieldValue.toString() : '';
        } else {
            replacedValue = typeof fieldValue === 'string' ? fieldValue : JSON.stringify(fieldValue);
        }
        tokenReplacementMap.fields[`${fieldName}`] = replacedValue;
    });

    return tokenReplacementMap;
};

export interface TimeInterval {
    earliest?: number;
    latest?: number | 'now';
}

// return epoch unix timestamp in seconds
export const getEpochTime = (time: string): number => {
    // if not a valid time string, then indicate an error and return NaN.
    if (!isISO(time)) {
        console.error(`'${time}' is not a valid time string.`);
        return NaN;
    }

    // retrieves the epoch time in milliseconds, and divides by 1000
    return new Date(time).getTime() / 1000;
};

/**
 * Helper to calculate the start (`earliest`) and end (`latest`) times when user clicks a predefined control in the Time Controls Popover
 *  (Before this time/After this time/At this time). If `Before this time` is selected, the time window only includes `latest`, as there is
 *  no `earliest` start time, and adds a 0.001 increment is order to capture all events at `time`. If `After this time` is selected, the
 *  window is from `time` to `now`. If `At this time` is selected, the window starts at `time` (in epoch time) and ends at `time` plus 0.001
 *  in order to include all events at `time`.
 * @method getPredefinedTimeInterval
 * @param {string} time
 * @param {('before'|'after'|'at')} when
 * @returns {TimeInterval} interval with `earliest` and `latest` defined in epoch time
 */
export const getPredefinedTimeInterval = ({
    time,
    when,
}: {
    time: string;
    when: 'before' | 'after' | 'at';
}): TimeInterval => {
    const epochTime = getEpochTime(time);

    switch (when) {
        case 'before':
            return { latest: epochTime + 0.001 };
        case 'after':
            return { earliest: epochTime, latest: 'now' };
        case 'at':
            return { earliest: epochTime, latest: epochTime + 0.001 };
        default:
            // returns 'at' interval by default
            return { earliest: epochTime, latest: epochTime + 0.001 };
    }
};

/**
 * Helper to calculate the start (`earliest`) and end (`latest`) times when user clicks `Apply` in the Time Controls Popover.
 * If the `plusminus` type is selected, the window expands either way with the `time` as a midpoint, and amount and interval length
 *  dependent on `rangeAmount` and `rangeUnit`. `plus` indicates starting at `time` and `minus` indicates ending at `time`.
 * To match sXML behavior, the time interval is converted to epoch time and 0.001 is added to the `latest` time to ensure the
 *  time window is inclusive of the end time.
 * @method getCustomTimeInterval
 * @param {string} time
 * @param {string} rangeType
 * @param {string} rangeAmount
 * @param {string} rangeUnit
 * @returns {TimeInterval} interval with `earliest` and `latest` defined in epoch time
 */
export const getCustomTimeInterval = ({
    time,
    rangeType,
    rangeAmount,
    rangeUnit,
}: {
    time: string;
    rangeType: string;
    rangeAmount: string;
    rangeUnit: string;
}): TimeInterval => {
    const timeRange = getAcceleratedTimeRange(time, rangeAmount, rangeUnit);

    let timeInterval;

    if (rangeType === 'plusminus') {
        timeInterval = { ...timeRange };
    } else if (rangeType === 'plus') {
        timeInterval = { ...timeRange, earliest: time };
    } else if (rangeType === 'minus') {
        timeInterval = { ...timeRange, latest: time };
    }

    // converts to use epoch time in the interval, and adds 0.001 to `latest` per spec
    return {
        earliest: getEpochTime(timeInterval.earliest),
        latest: getEpochTime(timeInterval.latest) + 0.001,
    };
};

/**
 * Helper to truncate strings to a maximum length.
 * The string is broken up into the leading characters, followed by ellipsis and the trailing characters.
 * @method truncateString
 * @param {string} stringToTruncate
 * @returns {string} truncated string
 */
export const truncateString = (stringToTruncate: string): string => {
    const MAX_LENGTH = 100;
    const LEAD_AND_TRAIL_LENGTH = 50;
    let truncatedString = stringToTruncate;
    if (truncatedString.length > MAX_LENGTH) {
        truncatedString = `${truncatedString.substring(
            0,
            LEAD_AND_TRAIL_LENGTH
        )}...${truncatedString.substring(truncatedString.length - LEAD_AND_TRAIL_LENGTH)}`;
    }
    return truncatedString;
};
