import React, { useState, useCallback } from 'react';
import styled from 'styled-components';
import Text from '@splunk/react-ui/Text';

const Search = styled.div`
    height: 50px;
    width: 100%;
    padding: 5px 0px;
`;

export interface GallerySearchProps {
    onSearch?: (value?: string) => void;
    disabled?: boolean;
    placeholder?: string;
}

const GallerySearch = React.forwardRef<HTMLInputElement, GallerySearchProps>(
    ({ onSearch, disabled, placeholder = 'Search' }, ref) => {
        const [search, setSearch] = useState('');

        const handleSearch = useCallback(
            (event, { value }) => {
                setSearch(value);
                onSearch?.(value);
            },
            [onSearch]
        );

        if (!onSearch) {
            return null;
        }

        return (
            <Search>
                <Text
                    inputRef={ref}
                    canClear
                    value={`${search}`}
                    disabled={disabled}
                    placeholder={placeholder}
                    appearance="search"
                    onChange={handleSearch}
                    data-test="gallery-search"
                />
            </Search>
        );
    }
);

export default GallerySearch;
