import { pick, isEqual, omit, without, isEmpty, clone, uniq } from 'lodash';
import { DashboardDefinition } from '@splunk/dashboard-definition';
import { createAction } from '@reduxjs/toolkit';
import type { Draft, PayloadAction } from '@reduxjs/toolkit';
import type {
    DashboardJSON,
    VisualizationDefinition,
} from '@splunk/dashboard-types';

/**
 * Compares two objects for changed values, and replays changes onto a third object
 * @param {Object} obj
 * @param {Object|null} obj.obj1 The original unmodified object, e.g. the flattened def from state
 * @param {Object} obj.obj2 The new modified object, e.g. a flattened def with changes
 * @param {Object|null} [obj.rootObj={}] The configuration object that acts as the root definition, e.g. has not had defaults flattened on it
 * @param {String[]} [obj.omitKeys=[]] Keys to ignore when checking for changes
 */
export const objectDiff = <T extends object = VisualizationDefinition>({
    obj1 = {} as T, // original obj
    obj2, // updated obj
    rootObj = {} as T, // non-flattened obj
    omitKeys = [], // don't process these keys
}: {
    obj1?: T | null;
    obj2: NonNullable<T>;
    rootObj?: T | null;
    omitKeys?: string[];
}): T => {
    // Make sure we always have an object to compare
    const orig = obj1 || ({} as T);
    // Find all keys in both objects, excluding some
    const keys = without(
        uniq(Object.keys(orig).concat(Object.keys(obj2))),
        ...omitKeys
    ) as (keyof T)[];
    // make sure result is always an object
    const result: T = rootObj ? clone(rootObj) : ({} as T);

    keys.forEach((key) => {
        if (!isEqual(orig[key], obj2[key])) {
            // key was deleted
            if (!Object.prototype.hasOwnProperty.call(obj2, key)) {
                delete result[key];
            } else {
                result[key] = obj2[key];
            }
        }
    });

    return result;
};

export interface UpdateVisualizationPayload {
    id: string;
    vizDefinition:
        | VisualizationDefinition
        | ((def: VisualizationDefinition | null) => VisualizationDefinition);
}

export const updateVisualization = createAction<UpdateVisualizationPayload>(
    'definition/visualization/update'
);

export const updateVisualizationReducer = (
    state: Draft<DashboardJSON>,
    action: PayloadAction<UpdateVisualizationPayload>
): void => {
    const { id, vizDefinition } = action.payload;
    state.visualizations ??= {};
    const def = DashboardDefinition.fromJSON(state);
    const flattenedDef = def.getVisualizationWithFlattenedDefaults(id);

    // if vizDefinition is callback, use it to to receive the updated definition
    const updatedDef =
        typeof vizDefinition === 'function'
            ? vizDefinition(def.getVisualization(id))
            : vizDefinition;

    // Since this viz changed type, we'll just pass it directly through
    // TODO: we may want to check against flattened defaults for the new viz type later
    if (flattenedDef?.type !== updatedDef.type) {
        state.visualizations[id] = updatedDef;
        return;
    }

    // get root level changes
    const mergedDef = objectDiff({
        obj1: flattenedDef,
        obj2: updatedDef,
        rootObj: def.getVisualization(id),
        omitKeys: ['options', 'context'],
    });
    // changed options
    mergedDef.options = objectDiff<
        NonNullable<VisualizationDefinition['options']>
    >({
        obj1: flattenedDef?.options,
        obj2: updatedDef.options ?? {},
        rootObj: mergedDef?.options ?? {},
    });
    // changed context
    mergedDef.context = objectDiff<
        NonNullable<VisualizationDefinition['context']>
    >({
        obj1: flattenedDef?.context,
        obj2: updatedDef.context ?? {},
        rootObj: mergedDef.context ?? {},
    });
    // Remove empty objects
    if (isEmpty(mergedDef.options)) {
        delete mergedDef.options;
    }
    if (isEmpty(mergedDef.context)) {
        delete mergedDef.context;
    }

    state.visualizations[id] = mergedDef;
};

// TODO: make action payloads consistent
export interface RemoveDataSourceFromVisualizationPayload {
    vizId: string;
    dsBindingType: string;
}

export const removeDataSourceFromVisualization =
    createAction<RemoveDataSourceFromVisualizationPayload>(
        'definition/visualization/removeDataSource'
    );

export const removeDataSourceFromVisualizationReducer = (
    state: Draft<DashboardJSON>,
    action: PayloadAction<RemoveDataSourceFromVisualizationPayload>
): void => {
    const { vizId, dsBindingType } = action.payload;
    // Just skip if the visualization does not exist
    if (state.visualizations?.[vizId]?.dataSources) {
        state.visualizations[vizId].dataSources = omit(
            state.visualizations[vizId].dataSources,
            dsBindingType
        );
    }
};

export const updateVizOptions = createAction(
    'definition/visualization/updateOptions',
    (id: string, options: VisualizationDefinition['options']) => ({
        payload: {
            id,
            options,
        },
    })
);

export const updateVizOptionsReducer = (
    state: Draft<DashboardJSON>,
    action: ReturnType<typeof updateVizOptions>
): void => {
    const { id, options: newOptions = {} } = action.payload;
    // Just skip if the visualization does not exist
    if (state.visualizations?.[id]) {
        state.visualizations[id].options ??= {};
        const newOptionsKeys = Object.keys(newOptions);
        if (
            !isEqual(
                pick(state.visualizations[id].options, newOptionsKeys),
                newOptions
            )
        ) {
            // only update state if the new options are not equal to the existing options
            Object.assign(
                state.visualizations[id].options as object,
                newOptions
            );
        }
    }
};

export const updateVizTitle = createAction(
    'definition/visualization/updateTitle',
    (id: string, title?: string) => ({
        payload: {
            id,
            title,
        },
    })
);

export const updateVizTitleReducer = (
    state: Draft<DashboardJSON>,
    action: ReturnType<typeof updateVizTitle>
): void => {
    const { id, title } = action.payload;
    if (state.visualizations?.[id]) {
        const newTitle = title?.trim();
        if (newTitle) {
            state.visualizations[id].title = newTitle;
        } else {
            delete state.visualizations[id].title;
        }
    }
};

export const updateVizDescription = createAction(
    'definition/visualization/updateDescription',
    (id: string, description?: string) => ({
        payload: {
            id,
            description,
        },
    })
);

export const updateVizDescriptionReducer = (
    state: Draft<DashboardJSON>,
    action: ReturnType<typeof updateVizDescription>
): void => {
    const { id, description } = action.payload;
    if (state.visualizations?.[id]) {
        const newDescription = description?.trim();
        if (newDescription) {
            state.visualizations[id].description = newDescription;
        } else {
            delete state.visualizations[id].description;
        }
    }
};
