import { _ } from '@splunk/ui-utils/i18n';
import { validateTokenName } from '../utils/validate';
import DefaultValueEditor from './DefaultSelectedValueEditor';

export const StaticItemEditorConfig = {
    label: '',
    option: 'items',
    editor: 'editor.staticItem',
    showEditor: ({ options }: { options: Record<string, unknown> }): boolean =>
        Array.isArray(options.items),
};

export const DynamicItemEditorConfig = {
    label: _('Dynamic menu configuration'),
    option: 'items',
    context: 'statics',
    editor: 'editor.dynamicItem',
    showEditor: ({ options }: { options: Record<string, unknown> }): boolean =>
        Array.isArray(options.items) ||
        (typeof options.items === 'string' && /^>/.test(options.items)),
    editorProps: {
        help: _(
            'Advanced configuration options are being used by this input. Please edit in source mode.'
        ),
        // return value of null means no error. This is to avoid Dynamic Editors's "default" validation
        //  which does not allow the use of tokens $token$.
        validate: () => null,
    },
};

export const BaseEditor = [
    {
        enableCollapsible: false,
        enableSeparator: true,
        layout: [
            [
                {
                    label: _('Token name'),
                    option: 'token',
                    editor: 'editor.text',
                    editorProps: {
                        validate: validateTokenName,
                    },
                },
            ],
        ],
    },
    {
        label: _('Menu configuration'),
        enableCollapsible: true,
        enableSeparator: true,
        layout: [[DynamicItemEditorConfig]],
    },
];

export default [
    ...BaseEditor,
    {
        label: _('Default selected values'),
        enableSeparator: false,
        layout: [
            [
                {
                    label: _('Default value'),
                    option: 'defaultValue',
                    context: 'statics',
                    editor: DefaultValueEditor,
                    editorProps: { isMultiselect: false },
                },
            ],
        ],
    },
];
