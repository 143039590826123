import React, {
    useState,
    useEffect,
    useLayoutEffect,
    useRef,
    useCallback,
    useMemo,
    useReducer,
} from 'react';
import memoizeOne from 'memoize-one';
import { isEqual, omit } from 'lodash';
import {
    useFeatureFlags,
    useCanvas,
    useKeyboardListener,
    useUserMessageAPI,
} from '@splunk/dashboard-context';
import { useTelemetryApi } from '@splunk/dashboard-telemetry';
import {
    usePrevious,
    useEventCallback,
    useMouseMoveHandler,
} from '@splunk/dashboard-ui';
import { getScrollbarWidth, noop } from '@splunk/dashboard-utils';

import type {
    SelectedItem,
    AbsoluteBlockItem,
    Coordinate,
    AbsolutePosition,
    GridLayoutStructure,
    GridLayoutOptions,
    Mode,
    HandleDirection,
} from '@splunk/dashboard-types';
import type { CountableEvent } from '@splunk/dashboard-telemetry';

import { GridLayoutApi } from './apis';
import {
    Edge,
    GridCanvas,
    ItemDragPlaceholder,
    ItemDropTarget,
    Layer,
    PreviewPlaceholderItem,
    ResponsiveBlockItem,
    type ResponsiveBlockItemProps,
    ResponsiveBlockOutline,
} from './components';
import { gridLayoutOptions } from './DefaultOptions';
import {
    GRID_PADDING_PX,
    GRID_SIZE_PX,
    MIN_HEIGHT_PX,
    MIN_WIDTH_PX,
    SNAP_RANGE_PX,
    PLACEHOLDER_SIZE_PX,
    VIZ_PREVIEW_DELAY_MS,
    EDGE_PREVIEW_DELAY_MS,
} from './GridLayoutConstants';
import gridLayoutOptionsSchema from './gridLayoutOptionsSchema';
import {
    computeEdges,
    findEdgesInBoundary,
    findSnappableEdges,
    moveVerticalEdge,
    moveHorizontalEdge,
    getVerticalBoundaries,
    getHorizontalBoundaries,
    applyVizPadding,
} from './utils/edgeUtils';
import {
    filterSelectedItems,
    getBlockItems,
    getFilteredStructure,
    getItem,
    getStructureItem,
    gridReducer,
    hasPreviewItem,
    initializeGridReducer,
    resetLayoutAction,
    updateItemAction,
} from './utils/gridLayoutUtils';
import { getGridLayoutOrder } from './utils/gridOrderUtils';
import {
    isMouseOnEdge,
    updateDropOnEdge,
    findQuadrant,
    updateDropOnViz,
    updateItems,
    previewDropOnEdge,
    previewDropOnViz,
    validateLayoutStructure,
    getDimensions,
    formatEdgeWrapper,
    isInvalidAdjacentVizDrop,
    isDropOnOwnEdge,
} from './utils/gridUtils';
import {
    computeScaleToFit,
    computeMaxHeight,
    updateBlockItemSize,
    getOffset,
    getClientPosition,
    findTopBlockItemByPosition,
    updateBlockItemPosition,
    scaleGridLayoutStructureByWidth,
} from './utils/layoutUtils';
import { gridLayoutShowHideReflow } from './utils/gridLayoutShowHide';

import type {
    EdgeItem,
    Quadrant,
    LayoutError,
    EdgeBoundaries,
    VerticalBoundaries,
    HorizontalBoundaries,
    EdgeAppearance,
    Offset,
    OnItemSelected,
} from './types';
import { withLayoutShowHide } from './enhancers';

export interface GridLayoutProps {
    layoutApiRef: (api: GridLayoutApi | null) => void;
    onLayoutItemsSelect: (selectedItems: SelectedItem[]) => void;
    selectedItems?: SelectedItem[];
    layoutStructure: GridLayoutStructure;
    onLayoutStructureChange: (layoutStructure: GridLayoutStructure) => void;
    options?: GridLayoutOptions;
    renderLayoutItem: (...args: unknown[]) => JSX.Element;
    mode: Mode;
    containerHeight: number;
    containerWidth: number;
    showGrid: boolean;
}

const HANDLE_DIRECTIONS: HandleDirection[] = [];

interface RenderBlockItemsProps {
    layoutStructure: GridLayoutStructure;
    renderLayoutItem: (...args: unknown[]) => JSX.Element;
    handleItemSelected: ResponsiveBlockItemProps['onItemSelected'];
    isBlockItemMoving: boolean;
    selectedItem: SelectedItem;
    mode: Mode;
    errors: { itemId: string; messages: string[] }[];
    padding: number;
    canvasHeight: number;
}

const MemoizedBlockItem = React.memo(
    ResponsiveBlockItem,
    (prevProps, nextProps) => {
        if (nextProps.y + nextProps.h === nextProps.canvasHeight) {
            return isEqual(prevProps, nextProps);
        }
        const prevPropsWithoutCanvasHeight: Omit<
            ResponsiveBlockItemProps,
            'canvasHeight'
        > = omit(prevProps, 'canvasHeight');
        const nextPropsWithoutCanvasHeight: Omit<
            ResponsiveBlockItemProps,
            'canvasHeight'
        > = omit(nextProps, 'canvasHeight');
        return isEqual(
            prevPropsWithoutCanvasHeight,
            nextPropsWithoutCanvasHeight
        );
    }
);
const MemoizedBlockOutline = React.memo(ResponsiveBlockOutline);
const MemoizedEdge = React.memo(Edge);

/**
 * loop through structure to render each block. Memoized block rendering to prevent rerender of each block
 */
const renderBlockItems = memoizeOne(
    ({
        layoutStructure,
        renderLayoutItem,
        handleItemSelected,
        isBlockItemMoving,
        selectedItem,
        mode,
        errors,
        padding,
        canvasHeight,
    }: RenderBlockItemsProps): React.ReactNode => {
        return layoutStructure.map((item) => {
            const key = item.item;
            if (key === 'preview-old-item') {
                return null;
            }

            const isSelected = selectedItem?.id === key;

            // Highlight the item if:
            // 1. in view mode
            // 2. in edit mode and no item is selected
            // 3. in edit mode and the item is selected
            let appearance: ResponsiveBlockItemProps['appearance'] =
                mode === 'view' || !selectedItem || isSelected
                    ? 'highlighted'
                    : 'visible';

            // If the selected visualization is moving, then we want to hide the viz itself since the
            //   preview placeholder will be rendered on top. The hiding is done using `visibility: hidden`, to avoid
            //   causing the viz to unmount, which would re-run the search.
            if (isSelected && isBlockItemMoving) {
                appearance = 'hidden';
            }

            // find the error object belonging to this viz if the error exists
            let errorMessages;
            if (errors) {
                const vizErrors = errors.find(({ itemId }) => itemId === key);
                errorMessages = vizErrors?.messages;
            }

            return (
                <MemoizedBlockItem
                    key={key}
                    itemId={key}
                    type={item.type}
                    x={item.position.x}
                    y={item.position.y}
                    h={item.position.h}
                    w={item.position.w}
                    padding={padding}
                    canvasHeight={canvasHeight}
                    renderLayoutItem={renderLayoutItem}
                    onItemSelected={handleItemSelected}
                    appearance={appearance}
                    errorMessages={errorMessages}
                />
            );
        });
    },
    isEqual
);

const BOTTOM_EDGE_MIN_SPEED = 2;
const BOTTOM_EDGE_MAX_SPEED = 4;
const BOTTOM_RESIZING_SCROLL_SPEED = 50;
const CANVAS_BOTTOM_PADDING = 8;

const BaseGridLayout = ({
    layoutApiRef,
    onLayoutItemsSelect,
    selectedItems: selectedLayoutItems,
    layoutStructure,
    onLayoutStructureChange,
    options = {},
    renderLayoutItem,
    mode,
    showGrid,
    scale,
    setCanvasHeight,
    canvasWidth,
}: Omit<
    GridLayoutProps & {
        scale: number;
        setCanvasHeight: React.Dispatch<number>;
        canvasWidth: number;
    },
    'containerWidth'
>): JSX.Element => {
    const canvasRef = useRef<HTMLDivElement>(null);
    const canvasContext = useCanvas();
    const keyboardListener = useKeyboardListener();
    const userMessage = useUserMessageAPI() ?? noop;
    const telemetryAPI = useTelemetryApi();
    const { enableGridLayoutErrors, enableGridLayoutCssScaling } =
        useFeatureFlags();

    const { gutterSize = gridLayoutOptions.gutterSize } = options;
    // gutterSize is split between the two panels it shares
    const panelPadding = gutterSize / 2;

    const [gridState, dispatch] = useReducer(
        gridReducer,
        layoutStructure,
        initializeGridReducer
    );
    const maxHeight = computeMaxHeight(getBlockItems(gridState));

    useLayoutEffect(() => {
        setCanvasHeight(maxHeight);
    }, [setCanvasHeight, maxHeight]);

    const [isBlockItemMoving, setIsBlockItemMoving] = useState(false);

    const [edges, setEdges] = useState<EdgeItem[]>(
        computeEdges({
            layout: layoutStructure,
            canvasWidth,
            canvasHeight: computeMaxHeight(layoutStructure), // need to recompute height on layout change
        })
    );
    const [snappableEdges, setSnappableEdges] = useState<EdgeItem[]>([]);
    const [scrollToBottom, setScrollToBottom] = useState(false);
    const [mousePosition, setMousePosition] = useState<Coordinate | null>(null);
    const [isItemAdded, setIsItemAdded] = useState(false);
    const [edgesBeforeMove, setEdgesBeforeMove] = useState<EdgeItem[] | null>(
        null
    );
    const [invalidEdgeId, setInvalidEdgeId] = useState<string | null>(null);
    const [hoveredEdge, setHoveredEdge] = useState<EdgeItem | null>(null);

    const [initialItemToMove, setInitialItemToMove] =
        useState<AbsoluteBlockItem | null>(null);
    const [hoveredBlock, setHoveredBlock] = useState<AbsoluteBlockItem | null>(
        null
    );
    const [hoveredQuadrant, setHoveredQuadrant] = useState<Quadrant | null>(
        null
    );
    const [, setForceUpdate] = useState(0);
    const [selectedItemsForEdge, setSelectedItemsForEdge] = useState<string[]>(
        []
    );

    const [isInvalidVizDrop, setIsInvalidVizDrop] = useState(false);
    const [showPreviewPlaceholder, setShowPreviewPlaceholder] = useState(true);
    const [layoutErrors, setLayoutErrors] = useState<LayoutError[]>([]);

    const isDraggingEdge = useRef(false);
    const delayPreviewEdge = useRef<NodeJS.Timeout | null>(null);
    const delayPreviewViz = useRef<NodeJS.Timeout | null>(null);
    /**
     * the follow values are saved in refs instead of states for two reasons:
     *     1. they don't affect rendering;
     *     2. they should be updated synchronously in order to handle mouse events https://jira.splunk.com/browse/SCP-25610
     */
    // mouseDownEdge is tracking the initial edge state when mouse down event happens, it is a snapshot, it won't update when mouse moves
    // the reason we need it is because we use the initial edge position and latest mouse position to calculate latest edge position
    const mouseDownEdge = useRef<EdgeItem | null>(null);
    const edgeMouseDownPosition = useRef<Coordinate | null>(null);
    const edgeBoundaries = useRef<EdgeBoundaries | null>(null);
    const edgesInBoundary = useRef<EdgeItem[] | null>(null);
    const cumulativeScrollIncrease = useRef(0);
    const edgeVelocity = useRef(0);

    const containsPreviewItem = hasPreviewItem(gridState);

    const sendTelemetry = useCallback(
        (eventData: CountableEvent) => {
            telemetryAPI.collect(eventData);
        },
        [telemetryAPI]
    );

    const onItemAdded = useCallback(() => {
        setIsItemAdded(true);
    }, [setIsItemAdded]);

    const layoutApi = useRef<GridLayoutApi | null>(null);
    const layoutStructureRef = useRef<GridLayoutStructure>(layoutStructure);

    const firstSelectedItemStructure = useMemo(
        () =>
            selectedLayoutItems?.length
                ? getItem(gridState, selectedLayoutItems[0].id)
                : null,
        [gridState, selectedLayoutItems]
    );

    const initializeLayoutApi = useCallback(() => {
        const getCanvasDomElement = (): HTMLDivElement => {
            return canvasRef.current as HTMLDivElement;
        };

        if (layoutApi.current === null) {
            layoutApi.current = new GridLayoutApi({
                layoutStructureRef,
                options,
                userMessage,
                onItemAdded,
                getCanvasDomElement,
                telemetry: telemetryAPI,
            });
        }
    }, [telemetryAPI, userMessage, onItemAdded, options]);

    const prevLayoutStructure = usePrevious(layoutStructure);

    useEffect(() => {
        if (isEqual(prevLayoutStructure, layoutStructure)) {
            return;
        }

        dispatch(resetLayoutAction(layoutStructure));
        // Adding/removing/cloning is done by using this reference to the layoutStructure
        // so we need to make sure it reflects the unscaled versions of the visualizations
        layoutStructureRef.current = enableGridLayoutCssScaling
            ? layoutStructure
            : scaleGridLayoutStructureByWidth({
                  scale: 1 / scale,
                  layout: layoutStructure,
              });
    }, [
        enableGridLayoutCssScaling,
        layoutStructure,
        prevLayoutStructure,
        scale,
    ]);

    // Need to do this after initializing layout structure ref!
    initializeLayoutApi();

    useEffect(() => {
        // only show errors when we're in edit mode
        if (mode === 'edit' && enableGridLayoutErrors) {
            const errors = validateLayoutStructure({
                layout: layoutStructure,
                canvasBounds: { x: 0, y: 0, w: canvasWidth, h: maxHeight },
            });
            setLayoutErrors(errors);
        } else if (mode === 'view') {
            // in case we had errors in edit mode, remove them when going to view mode
            setLayoutErrors([]);
        }
        // We only want this to run when we commit changes to item position/size.
        //  For example, we want it to run after resizing (on mouse up), not during.
        //  And also when we switch modes, since we only want to show errors in edit mode
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [layoutStructure, mode, enableGridLayoutErrors]);

    // we must use `useLayoutEffect` because we need to guarantee `layoutApiRef` is called before `componentDidMount()` is called on
    // the parent component (namely LayoutContainer). Otherwise LayoutContainer will not get the api.
    useLayoutEffect(() => {
        layoutApiRef(layoutApi.current);

        return () => {
            layoutApiRef(null);
        };
    }, [layoutApiRef]);

    // this ensures the layout reducer gets the latest layoutStructure
    useEffect(() => {
        // TODO: we should look into how to make `layoutStructure` not constantly change if the actual content of the layoutStructure did not change.
        // Currently, we have to do a deep comparison, because `layoutStructure` as an object reference is always changing.
        if (isEqual(prevLayoutStructure, layoutStructure)) {
            return;
        }
        dispatch(resetLayoutAction(layoutStructure));

        const canvasHeight = computeMaxHeight(layoutStructure);
        const prevCanvasHeight = computeMaxHeight(prevLayoutStructure || []);

        setEdges(
            computeEdges({
                layout: layoutStructure,
                canvasWidth,
                canvasHeight, // need to recompute height on layout change
            })
        );

        if (
            prevLayoutStructure &&
            canvasHeight > prevCanvasHeight &&
            isItemAdded
        ) {
            // when we add a new item (viz or input) to canvas, we want to scroll to it
            setScrollToBottom(true);
            setIsItemAdded(false);
        }
    }, [isItemAdded, layoutStructure, canvasWidth, prevLayoutStructure]);

    useEffect(() => {
        if (scrollToBottom) {
            const layoutContainer = canvasContext.current;
            if (layoutContainer) {
                layoutContainer.scrollTop = layoutContainer.scrollHeight;
            }
            setScrollToBottom(false);
        }
    }, [canvasContext, layoutStructure, scrollToBottom]);

    // Not idempotent, but doesn't matter because this will only result in resetting a mouseup handler, not a rerender of any component
    const handleLayoutStructureChange = useCallback(() => {
        const layout = getBlockItems(gridState);
        onLayoutStructureChange(layout);
    }, [onLayoutStructureChange, gridState]);

    /**
     * return valid selected items, this needs to be a function because we cannot detect structureRegistry change.
     */
    const getSelectedItems = useMemo(() => {
        let itemsCache: SelectedItem[];

        return () => {
            const filteredItems = filterSelectedItems(
                layoutStructure,
                selectedLayoutItems
            );

            if (!isEqual(itemsCache, filteredItems)) {
                itemsCache = filteredItems;
            }

            return itemsCache;
        };
    }, [layoutStructure, selectedLayoutItems]);

    /**
     * set selected items
     * @param {Object} e mouse event
     * @param {Array} selectedItems list of selected Items
     */
    const handleItemSelected = useCallback<OnItemSelected>(
        (_e, selectedItems = []) => {
            // filter out other Items as we do not currently support multi selecting Items
            const selectedItem =
                selectedItems.length > 0
                    ? [selectedItems[selectedItems.length - 1]]
                    : [];
            onLayoutItemsSelect(selectedItem);
        },
        [onLayoutItemsSelect]
    );

    // If we're not scaling the grid via CSS we don't need to scale the mouse position
    const mouseScale = enableGridLayoutCssScaling ? scale : 1;

    /**
     * Handles scrolling in Canvas when a Viz is being dragged to the top or bottom of the visible Canvas
     */
    // TODO: something is a little odd here...when showing an edge preview on canvas bottom causes scrolling things break a little
    useEffect(() => {
        let delayed: NodeJS.Timeout;
        const layoutContainer = canvasContext.current;
        if (!isBlockItemMoving || !mousePosition || !layoutContainer) {
            return () => undefined;
        }
        const visibleCanvasHeight = layoutContainer.clientHeight / mouseScale;

        const hasScrolledToBottom =
            layoutContainer.scrollTop / mouseScale + visibleCanvasHeight >=
            maxHeight;

        const isInBottomScrollArea =
            layoutContainer.scrollTop / mouseScale +
                visibleCanvasHeight -
                mousePosition.y <
            MIN_HEIGHT_PX;

        const hasScrolledToTop = layoutContainer.scrollTop <= 0;

        const isInTopScrollArea =
            mousePosition.y - layoutContainer.scrollTop / mouseScale <
            MIN_HEIGHT_PX;
        if (!hasScrolledToBottom && isInBottomScrollArea) {
            const increment = Math.min(
                Math.abs(
                    maxHeight -
                        (layoutContainer.scrollTop / mouseScale +
                            visibleCanvasHeight)
                ),
                10
            );
            delayed = setTimeout(() => {
                layoutContainer.scrollTop += increment;
                setMousePosition((pos) =>
                    pos
                        ? {
                              ...pos,
                              y: pos.y + increment / mouseScale,
                          }
                        : null
                );
            }, 32);
        } else if (!hasScrolledToTop && isInTopScrollArea) {
            const decrement = Math.min(layoutContainer.scrollTop, 10);
            delayed = setTimeout(() => {
                layoutContainer.scrollTop -= decrement;
                setMousePosition((pos) =>
                    pos
                        ? {
                              ...pos,
                              y: pos.y - decrement / mouseScale,
                          }
                        : null
                );
            }, 32);
        }

        return () => {
            clearTimeout(delayed);
        };
    }, [
        isBlockItemMoving,
        mousePosition,
        canvasContext,
        mouseScale,
        maxHeight,
    ]);

    /*
        Reset the preview states to original values for edge preview
    */
    const resetEdgePreviewState = useCallback(() => {
        if (edgesBeforeMove) {
            // If there was an update, stop it from happening
            if (delayPreviewEdge.current) {
                clearTimeout(delayPreviewEdge.current);
            }
            // Set edges back to original ones before the move happened
            setEdges(edgesBeforeMove);
            // Set visualizations to original
            dispatch(resetLayoutAction(layoutStructure));
        }
    }, [edgesBeforeMove, layoutStructure]);

    const showEdgeDropPreview = useCallback(
        ({ mousePos }: { mousePos: Coordinate }) => {
            if (edgesBeforeMove === null) {
                // Only update the edges before move once, since it will always be the same
                setEdgesBeforeMove(edges);
            }
            // Compute against the initial edges before we started moving, unless they haven't been set yet
            const validEdges = edgesBeforeMove || edges;
            // find if the mouse is on the edge
            const edge = validEdges.find((edgeToFind) => {
                return isMouseOnEdge({
                    edge: edgeToFind,
                    mousePosition: mousePos,
                    padding: panelPadding,
                });
            });

            if (!edge) {
                /* If edge is undefined, there is no edge being hovered */
                // If an edge was previously hovered, then undo the state changes made, and revert back to original
                // This deals with hovering on an edge and then hovering off it
                if (hoveredEdge) {
                    setHoveredEdge(null);
                    setInvalidEdgeId(null);
                    resetEdgePreviewState();
                }
                return;
            }

            // Only compute preview if this is the first time we are hovering this particular edge
            // which means either the edge is undefined or it's a different edge than the last one that was hovered
            if (!hoveredEdge || hoveredEdge.item !== edge.item) {
                let itemToMove = firstSelectedItemStructure;
                if (!initialItemToMove) {
                    // We need to keep the original item to move since the 'selectedItem' will change positions
                    //  to the new position inserted in the preview
                    setInitialItemToMove(itemToMove);
                } else {
                    // use the original item to move
                    itemToMove = initialItemToMove;
                }
                // hide preview when an item is dropped on its own edge that has the same width/height as item
                if (
                    isDropOnOwnEdge({
                        edge,
                        itemToMove: itemToMove as AbsoluteBlockItem,
                    })
                ) {
                    return;
                }

                // Compute the preview when hovering over the edge
                const updatedItems = previewDropOnEdge({
                    edge,
                    itemToMove: itemToMove as AbsoluteBlockItem,
                    items: layoutStructure,
                    canvasWidth,
                });
                // If the move was valid, update state to render the preview
                if (updatedItems) {
                    // clear invalid edge if it exists
                    setInvalidEdgeId(null);
                    if (delayPreviewEdge.current) {
                        clearTimeout(delayPreviewEdge.current);
                    }
                    // When we set timeout, it means it is a valid drop, thus hide preview placeholder until the preview is shown
                    setShowPreviewPlaceholder(false);
                    delayPreviewEdge.current = setTimeout(() => {
                        // Update layout reducer with the new structure
                        dispatch(resetLayoutAction(updatedItems));
                        // render the new edges using the new updated layout structure
                        setEdges(
                            computeEdges({
                                layout: updatedItems,
                                canvasWidth,
                                canvasHeight: computeMaxHeight(updatedItems),
                            })
                        );
                        // When preview is computed and shown, bring back the placeholder
                        setShowPreviewPlaceholder(true);
                    }, EDGE_PREVIEW_DELAY_MS);
                } else {
                    // if the move was invalid, highlight the hovered edge to red
                    setShowPreviewPlaceholder(true);
                    setInvalidEdgeId(edge.item);
                    // if user moved from a valid preview directly on an invalid edge, reset
                    //  the currently shown preview
                    resetEdgePreviewState();
                }
            }
            // This state is used to keep track of which edge is being hovered, so that we can track
            //  when we switch to a different edge (to re-compute preview)
            setHoveredEdge(edge);
        },
        [
            firstSelectedItemStructure,
            edgesBeforeMove,
            edges,
            hoveredEdge,
            panelPadding,
            resetEdgePreviewState,
            initialItemToMove,
            layoutStructure,
            canvasWidth,
        ]
    );

    const showVizDropPreview = useCallback(
        ({ mousePos }: { mousePos: Coordinate }) => {
            // Find the viz that the Mouse is hovering over
            const block = findTopBlockItemByPosition(
                layoutStructure,
                mousePos,
                panelPadding
            );

            let itemToMove = firstSelectedItemStructure;
            if (!initialItemToMove) {
                // We need to keep the original item to move since the 'selectedItem' will change positions
                //  to the new position inserted in the preview
                setInitialItemToMove(itemToMove);
            } else {
                // use the original item to move
                itemToMove = initialItemToMove;
            }

            if (block === undefined || block.item === itemToMove?.item) {
                if (delayPreviewViz.current) {
                    clearTimeout(delayPreviewViz.current);
                }
                setHoveredQuadrant(null);
                setHoveredBlock(null);
                setIsInvalidVizDrop(false);
                if (hoveredBlock) {
                    // only reset to original if there was a preview computed
                    dispatch(resetLayoutAction(layoutStructure));
                }
                return;
            }

            const currentQuadrant = findQuadrant({
                item: applyVizPadding({ item: block, padding: panelPadding }),
                position: mousePos,
            });

            // Only compute (or re-compute) preview if either it's the first time user hovers a viz,
            //   or if the quadrant changed, or if the viz that is being hovered changed.
            if (
                (!hoveredBlock && !hoveredQuadrant) ||
                hoveredQuadrant !== currentQuadrant ||
                hoveredBlock?.item !== block.item
            ) {
                if (
                    isInvalidAdjacentVizDrop({
                        itemToMove: itemToMove as AbsoluteBlockItem,
                        itemToDropOn: block,
                        visualizations: layoutStructure,
                        direction: currentQuadrant as Quadrant,
                    })
                ) {
                    return;
                }

                // get the updated position of the items that are changing as a result of the Viz drop on Viz
                const updatedItems = previewDropOnViz({
                    itemToDropOn: block,
                    itemToMove: itemToMove as AbsoluteBlockItem,
                    items: layoutStructure,
                    direction: currentQuadrant as Quadrant,
                });
                // clear previous preview if it's queued up
                if (delayPreviewViz.current) {
                    clearTimeout(delayPreviewViz.current);
                }
                if (updatedItems) {
                    setIsInvalidVizDrop(false);
                    // The quadrant of the current visualization we are hovering while dragging a viz
                    setHoveredQuadrant(currentQuadrant);
                    // the visualization we are hovering over
                    setHoveredBlock(block);
                    // Same as with edge preview, hide the placeholder until the timeout has run to avoid 'flashing' of the green preview
                    setShowPreviewPlaceholder(false);
                    delayPreviewViz.current = setTimeout(() => {
                        // If the move was valid, show preview of new layout
                        dispatch(resetLayoutAction(updatedItems));
                        // While structure registry exists, we need to force an update. This is a clear indication that it needs to be removed, asap!
                        setForceUpdate(
                            (prevForceUpdate) => prevForceUpdate + 1
                        );
                        setShowPreviewPlaceholder(true);
                    }, VIZ_PREVIEW_DELAY_MS);
                } else {
                    setHoveredQuadrant(null);
                    setHoveredBlock(null);
                    setIsInvalidVizDrop(true);
                    if (hoveredBlock) {
                        // only reset to original if there was a preview computed
                        dispatch(resetLayoutAction(layoutStructure));
                    }
                }
            }
        },
        [
            firstSelectedItemStructure,
            panelPadding,
            hoveredBlock,
            hoveredQuadrant,
            initialItemToMove,
            layoutStructure,
        ]
    );

    /**
     * Renders the Item Drop Target
     * @param {Object} params
     * @param {Object} params.position position of Item being hovered over
     * @param {String} params.direction direction of where Drop Target should be rendered
     */
    const renderItemDropTarget = memoizeOne(
        ({
            position,
            direction,
        }: {
            position: AbsolutePosition;
            direction: Quadrant;
        }) => {
            return <ItemDropTarget position={position} direction={direction} />;
        }
    );

    /**
     * Handles rendering for Item Drop Targets when an Item is being dragged and hovers on top of another Item
     */
    const handleRenderItemDropTarget = useCallback(() => {
        let block = findTopBlockItemByPosition(
            layoutStructure,
            mousePosition as Coordinate,
            panelPadding
        );

        const selectedItems = getSelectedItems();
        if (
            block !== undefined &&
            selectedItems.length > 0 &&
            block.item !== selectedItems[0].id &&
            // disables drop targets when showing preview
            !hoveredQuadrant
        ) {
            // apply padding to Viz to get accurate mouse hover
            block = applyVizPadding({ item: block, padding: panelPadding });
            return renderItemDropTarget({
                position: block.position,
                direction: findQuadrant({
                    item: block,
                    position: mousePosition as Coordinate,
                }) as Quadrant,
            });
        }
        return null;
    }, [
        layoutStructure,
        panelPadding,
        getSelectedItems,
        hoveredQuadrant,
        mousePosition,
        renderItemDropTarget,
    ]);

    const handleShowPreviewPlaceholder = useCallback(
        ({ mousePos }: { mousePos: Coordinate }) => {
            const selectedItem = getSelectedItems()[0];
            if (!selectedItem) {
                setShowPreviewPlaceholder(false);
                return;
            }
            // Find the viz that the Mouse is hovering over
            const hoveredViz = findTopBlockItemByPosition(
                layoutStructure,
                mousePos,
                panelPadding
            );
            // case 1 to show placeholder: mouse is on original viz
            if (hoveredViz && hoveredViz.item === selectedItem.id) {
                setShowPreviewPlaceholder(true);
                return;
            }
            // case 2 to show placeholder: mouse is on an invalid drop zone
            if (invalidEdgeId !== null || isInvalidVizDrop) {
                setShowPreviewPlaceholder(true);
                return;
            }

            // case 3 to show placeholder: preview has been computed
            // Note: preview stays on screen as long as mouse hovers on the drop zone where it was created
            //   so as soon as user moves mouse off, preview goes back to original position (case 1 or 2)
            if (containsPreviewItem) {
                setShowPreviewPlaceholder(true);
                return;
            }
            // If neither of those three cases, hide preview placeholder
            setShowPreviewPlaceholder(false);
        },
        [
            containsPreviewItem,
            panelPadding,
            getSelectedItems,
            invalidEdgeId,
            isInvalidVizDrop,
            layoutStructure,
        ]
    );

    /**
     * set isBlockItemMoving state to true for selected Items
     */
    const handleBlockItemMove = useCallback(
        (
            _e: MouseEvent,
            { currentPosition }: { currentPosition: Coordinate }
        ) => {
            if (getSelectedItems().length > 0) {
                handleShowPreviewPlaceholder({ mousePos: currentPosition });
                setIsBlockItemMoving(true);
                setMousePosition(currentPosition);
                showEdgeDropPreview({ mousePos: currentPosition });
                showVizDropPreview({ mousePos: currentPosition });
            }
        },
        [
            getSelectedItems,
            showEdgeDropPreview,
            showVizDropPreview,
            handleShowPreviewPlaceholder,
        ]
    );

    /**
     * Handler to deal with when a Visualization that is being dragged gets dropped on mouseUp Event
     */
    const handleVizDrop = useCallback(() => {
        const validEdges = edgesBeforeMove || edges;

        const selectedItems = getSelectedItems();
        // return early if there are no selected Items
        if (selectedItems.length === 0) {
            return;
        }
        // Find if the viz is being moved on an edge, return that edge
        const edge = validEdges.find((e) => {
            return isMouseOnEdge({
                edge: e,
                mousePosition: mousePosition as Coordinate,
                padding: panelPadding,
            });
        });
        // Find the viz that the Mouse is hovering over
        const block = findTopBlockItemByPosition(
            layoutStructure,
            mousePosition as Coordinate,
            panelPadding
        );
        const itemToMove = layoutStructure.find(
            ({ item }) => item === selectedItems[0].id
        ) as AbsoluteBlockItem;
        // nothing changed as a result of the Viz drop on itself - return early
        if (block === itemToMove) {
            sendTelemetry({
                source: 'canvas',
                event: 'viz_drop_on_self_successful',
            });
            return;
        }
        const itemsSelected = layoutStructure;
        let updatedItems = null;
        if (edge !== undefined) {
            // handle drop on Edge
            // get the updated position of the items that are changing as a result of the Viz drop on Edge
            updatedItems = updateDropOnEdge({
                edge,
                itemToMove,
                items: itemsSelected,
                canvasWidth,
            });
            // nothing changed as a result of the Viz drop on Edge - return early
            if (updatedItems === null) {
                sendTelemetry({
                    source: 'canvas',
                    event: 'viz_drop_on_edge_unsuccessful',
                });
                return;
            }
            // updated the entire definition with the updated Items
            updatedItems = updateItems({
                updatedVisualizations: updatedItems.updatedVisualizations,
                itemToMove: updatedItems.updatedItemToMove,
                items: itemsSelected,
                canvasWidth,
            });
            sendTelemetry({
                source: 'canvas',
                event: 'viz_drop_on_edge_successful',
            });
        } else if (block !== undefined && block.item !== selectedItems[0].id) {
            // handle drop on Viz
            // apply padding to Viz to get accurate mouse hover position
            const direction = findQuadrant({
                item: applyVizPadding({ item: block, padding: panelPadding }),
                position: mousePosition as Coordinate,
            });

            if (
                isInvalidAdjacentVizDrop({
                    itemToMove,
                    itemToDropOn: block,
                    direction: direction as Quadrant,
                    visualizations: layoutStructure,
                })
            ) {
                return;
            }

            // get the updated position of the items that are changing as a result of the Viz drop on Viz
            const updatedVisualizations = updateDropOnViz({
                itemToMove,
                itemToDropOn: block,
                direction: direction as Quadrant,
            });
            if (updatedVisualizations === null) {
                sendTelemetry({
                    source: 'canvas',
                    event: 'viz_drop_on_viz_unsuccessful',
                });
                return;
            }
            // updated the entire definition with the updated Items
            updatedItems = updateItems({
                updatedVisualizations,
                itemToMove,
                items: itemsSelected,
                canvasWidth,
            });
            sendTelemetry({
                source: 'canvas',
                event: 'viz_drop_on_viz_successful',
            });
        }
        if (updatedItems != null) {
            onLayoutStructureChange(updatedItems);
        }
    }, [
        layoutStructure,
        edgesBeforeMove,
        edges,
        getSelectedItems,
        mousePosition,
        panelPadding,
        sendTelemetry,
        canvasWidth,
        onLayoutStructureChange,
    ]);

    /*
        Clean up the state from the preview, and undo any changes by reverting to original state before the item move
    */
    const cleanupAfterPreview = useCallback(() => {
        if (delayPreviewViz.current) {
            clearTimeout(delayPreviewViz.current);
        }
        if (delayPreviewEdge.current) {
            clearTimeout(delayPreviewEdge.current);
        }
        setInvalidEdgeId(null);
        setIsInvalidVizDrop(false);
        setInitialItemToMove(null);
        setHoveredBlock(null);
        setHoveredQuadrant(null);
        if (edgesBeforeMove) {
            // If a preview was shown, revert back to original
            setEdges(edgesBeforeMove);
            setEdgesBeforeMove(null);
            dispatch(resetLayoutAction(layoutStructure));
        }
        if (hoveredBlock) {
            dispatch(resetLayoutAction(layoutStructure));
        }
    }, [edgesBeforeMove, hoveredBlock, layoutStructure]);

    /**
     * update the blocks when it finished moving
     */
    const handleBlockItemMoved = useCallback(() => {
        cleanupAfterPreview();
        if (mousePosition !== null) {
            // updates layout structure
            handleVizDrop();
        }
        setIsBlockItemMoving(false);
        setMousePosition(null);
    }, [cleanupAfterPreview, mousePosition, handleVizDrop]);

    const handleBlockItemPositionUpdate = useCallback(({ item, offset }) => {
        const newItem = updateBlockItemPosition(item, offset);

        // update runtime structure
        dispatch(updateItemAction(newItem));
    }, []);

    /**
     * Block Items resize and move Handler
     * @param {Object} e mouse event
     * @param {Object} options
     * @param {Object} options.itemId itemId for selected Viz
     * @param {Object} options.offset offset amount to move Viz
     * @param {Object} options.dir direction to resize Viz
     */
    const handleBlockItemResize = useCallback(
        (
            e: MouseEvent | Coordinate,
            {
                itemId,
                offset,
                dir,
            }: { itemId: string; offset: Offset; dir: HandleDirection }
        ) => {
            // all computation is done against last committed items!
            const item = getStructureItem(layoutStructure, itemId);
            const newItem = updateBlockItemSize({
                item,
                offset,
                dir,
                options: {
                    minHeight: MIN_HEIGHT_PX,
                    minWidth: MIN_WIDTH_PX,
                },
            });

            // update runtime structure
            dispatch(updateItemAction(newItem));
        },
        [layoutStructure]
    );

    /**
     * handler for when Block is finished Resizing
     */
    const handleBlockItemResized = useCallback(() => {
        // resize completed
        handleLayoutStructureChange();
        sendTelemetry({
            source: 'canvas',
            event: 'edge_resize',
        });
    }, [handleLayoutStructureChange, sendTelemetry]);

    const handleMouseDownOnEdge = useCallback(
        (e, { id }) => {
            isDraggingEdge.current = true;

            const edge = edges.find(({ item }) => item === id);

            if (!edge) {
                return;
            }

            // set selected visualizations
            // select proper viz items and also proper edge orientation for viz
            const visualizationIds = edge.visualizations.map((viz) => viz.item);

            // find boundaries, either horizontal boundary - { leftBoundary, rightBoundary }
            // or vertical boundary - { upperBoundary, lowerBoundary }
            const newEdgeBoundaries =
                edge.orientation === 'horizontal'
                    ? getVerticalBoundaries({
                          visualizations: edge.visualizations,
                          y: edge.edgeStart.y,
                          minHeight: MIN_HEIGHT_PX,
                          isFullWidthEdge:
                              edge.edgeEnd.x - edge.edgeStart.x === canvasWidth,
                      })
                    : getHorizontalBoundaries({
                          visualizations: edge.visualizations,
                          x: edge.edgeStart.x,
                          minWidth: MIN_WIDTH_PX,
                      });
            // save new boundary
            edgeBoundaries.current = newEdgeBoundaries;

            // compute edges in boundary
            edgesInBoundary.current = findEdgesInBoundary({
                edge,
                edges,
                edgeBoundaries: newEdgeBoundaries,
            });

            edgeMouseDownPosition.current = getClientPosition(e, mouseScale);
            mouseDownEdge.current = { ...edge };
            setSelectedItemsForEdge(visualizationIds);
        },
        [edges, mouseScale, canvasWidth]
    );

    /**
     * handle resizing for selected edge and visualizations
     */
    const handleEdgeMove = useEventCallback((e: MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();

        // update selected edge location
        if (!isDraggingEdge.current || !mouseDownEdge.current) {
            return;
        }

        const currentPosition = getClientPosition(e, mouseScale);
        const offset = getOffset(
            currentPosition,
            edgeMouseDownPosition.current as Coordinate
        );

        offset.offsetY += cumulativeScrollIncrease.current;

        let updatedEdge =
            mouseDownEdge?.current?.orientation === 'vertical'
                ? moveVerticalEdge({
                      edge: mouseDownEdge.current,
                      offset: offset.offsetX,
                      edgeBoundaries:
                          edgeBoundaries.current as HorizontalBoundaries,
                  })
                : moveHorizontalEdge({
                      edge: mouseDownEdge.current,
                      offset: offset.offsetY,
                      edgeBoundaries:
                          edgeBoundaries.current as VerticalBoundaries,
                  });

        let updatedSnappableEdges: EdgeItem[] = [];
        if (!e.shiftKey) {
            const { updatedEdge: ue, snappableEdges: se } = findSnappableEdges({
                edge: updatedEdge,
                edges: edgesInBoundary.current ?? [],
                snapRange: SNAP_RANGE_PX,
            });
            updatedEdge = ue;
            updatedSnappableEdges = se;
        }

        setEdges((prevEdges) =>
            prevEdges.map((anEdge) => {
                if (anEdge.item === updatedEdge.item) {
                    return updatedEdge;
                }

                return anEdge;
            })
        );

        // update visualizations locations
        const vizOffset = {
            offsetX:
                updatedEdge.edgeStart.x - mouseDownEdge.current.edgeStart.x,
            offsetY:
                updatedEdge.edgeStart.y - mouseDownEdge.current.edgeStart.y,
        };

        const isFullWidthEdge =
            mouseDownEdge.current.edgeEnd.x -
                mouseDownEdge.current.edgeStart.x ===
            canvasWidth;
        if (isFullWidthEdge) {
            // If user is dragging full width edge, update ALL the visualizations below the edge by
            //  shifting them downwards/upwards, including the ones that are not directly touching the edge
            // The block items need to be the committed versions
            layoutStructure.forEach((item) => {
                if (
                    mouseDownEdge.current &&
                    item.position.y >= mouseDownEdge.current.edgeStart.y
                ) {
                    handleBlockItemPositionUpdate({
                        item,
                        offset: vizOffset,
                    });
                }
            });
        }

        if (selectedItemsForEdge.length > 0) {
            selectedItemsForEdge.forEach((itemId) => {
                const item = getStructureItem(layoutStructure, itemId);
                if (
                    !mouseDownEdge.current ||
                    (isFullWidthEdge &&
                        item.position.y >= mouseDownEdge.current.edgeStart.y)
                ) {
                    // if the moving edge is a full width edge, we already took care of the visualizations
                    //   below that edge, thus ignore them here, and only resize the items above the edge
                    return;
                }
                let resizeDir: HandleDirection;
                if (mouseDownEdge.current?.orientation === 'horizontal') {
                    resizeDir =
                        item.position.y >= mouseDownEdge.current.edgeStart.y
                            ? 'n'
                            : 's';
                } else {
                    resizeDir =
                        item.position.x >= mouseDownEdge.current.edgeStart.x
                            ? 'w'
                            : 'e';
                }
                handleBlockItemResize(e, {
                    itemId,
                    offset: vizOffset,
                    dir: resizeDir,
                });
            });
        }

        // check for snappable edges
        // highlight snappable edges
        setSnappableEdges(updatedSnappableEdges);

        // Start scrolling the bottom edge slightly before reaching the bottom of the screen at a reduced speed
        if (
            mouseDownEdge?.current?.orientation === 'horizontal' &&
            mouseDownEdge?.current?.isCanvasEdge
        ) {
            const scrollVelocity =
                e.clientY - window.innerHeight + CANVAS_BOTTOM_PADDING * 2;
            edgeVelocity.current = scrollVelocity;
            setMousePosition(getClientPosition(e, mouseScale));
        }
    });

    useMouseMoveHandler({
        onMouseMove: handleEdgeMove,
        isEnabled: mode === 'edit',
        throttledMs: 32,
    });

    useEffect(() => {
        if (
            mode !== 'edit' ||
            edgeVelocity.current <= 0 ||
            !mouseDownEdge.current ||
            !edgeMouseDownPosition.current ||
            !mousePosition
        ) {
            return undefined;
        }
        const currEdge = mouseDownEdge.current;

        const isFullWidthEdge =
            currEdge.edgeEnd.x - currEdge.edgeStart.x === canvasWidth;

        if (!isFullWidthEdge) {
            return undefined;
        }

        const offset =
            edgeVelocity.current > 8
                ? BOTTOM_EDGE_MAX_SPEED
                : BOTTOM_EDGE_MIN_SPEED;
        const edgeMousePos = edgeMouseDownPosition.current;

        const interval = setInterval(() => {
            cumulativeScrollIncrease.current += offset;

            const mouseOffset = getOffset(mousePosition, edgeMousePos);

            const updatedEdge = moveHorizontalEdge({
                edge: currEdge,
                offset: mouseOffset.offsetY + cumulativeScrollIncrease.current,
                edgeBoundaries: edgeBoundaries.current as VerticalBoundaries,
            });

            setEdges((prevEdges) =>
                prevEdges.map((anEdge) => {
                    if (anEdge.item === currEdge.item) {
                        return {
                            ...anEdge,
                            edgeStart: {
                                x: anEdge.edgeStart.x,
                                y: updatedEdge.edgeStart.y,
                            },
                            edgeEnd: {
                                x: anEdge.edgeEnd.x,
                                y: updatedEdge.edgeStart.y,
                            },
                        };
                    }
                    return anEdge;
                })
            );

            const vizOffset = {
                offsetX: 0,
                offsetY: updatedEdge.edgeStart.y - currEdge.edgeStart.y,
            };

            // Handles increasing the size of the visualizations on the edge
            if (selectedItemsForEdge.length > 0) {
                selectedItemsForEdge.forEach((itemId) => {
                    const resizeDir = 's';

                    handleBlockItemResize(mousePosition, {
                        itemId,
                        offset: vizOffset,
                        dir: resizeDir,
                    });
                });
            }

            if (canvasContext.current) {
                canvasContext.current.scrollTop += BOTTOM_RESIZING_SCROLL_SPEED;
            }
        }, 32);

        return () => {
            clearInterval(interval);
        };
    }, [
        mode,
        edgeVelocity,
        mousePosition,
        cumulativeScrollIncrease,
        canvasContext,
        canvasWidth,
        handleBlockItemResize,
        layoutStructure,
        selectedItemsForEdge,
    ]);

    /**
     * handler for when Edge is finished Moving
     */
    const handleEdgeMoved = useEventCallback((e: MouseEvent) => {
        // DO NOT stop propagation, because visualizations are listening to mouse up to tell
        // whether it stopped moving. Refer to GridCanvas.jsx for the logic.
        e.preventDefault();

        // need to reset this before updating state
        mouseDownEdge.current = null;
        edgeMouseDownPosition.current = null;
        edgeBoundaries.current = null;
        edgesInBoundary.current = null;
        cumulativeScrollIncrease.current = 0;
        edgeVelocity.current = 0;

        if (isDraggingEdge.current) {
            isDraggingEdge.current = false;
            // only unset select viz when resizing edge is done,
            // not on clicking a viz.
            setSelectedItemsForEdge([]);
            handleBlockItemResized();
        }

        if (snappableEdges.length > 0) {
            setSnappableEdges([]);
        }
    });

    useEffect(() => {
        if (mode !== 'edit') {
            return undefined;
        }

        document.addEventListener('mouseup', handleEdgeMoved);

        return () => {
            document.removeEventListener('mouseup', handleEdgeMoved);
        };
    }, [handleEdgeMoved, mode]);

    useEffect(() => {
        if (!keyboardListener) {
            return () => undefined;
        }

        const unsubscribeKeyboardListener = keyboardListener.subscribe(
            'cancel',
            () => {
                if (isBlockItemMoving) {
                    cleanupAfterPreview();
                    setIsBlockItemMoving(false);
                    setMousePosition(null);
                } else {
                    // deselect all selected items;
                    onLayoutItemsSelect([]);
                }
            }
        );

        return () => {
            unsubscribeKeyboardListener();
        };
    }, [
        cleanupAfterPreview,
        isBlockItemMoving,
        onLayoutItemsSelect,
        keyboardListener,
    ]);

    /**
     * render outline for block items
     * @param {Number} scale scale value
     */
    const renderOutline = () => {
        const selectedItems =
            selectedItemsForEdge.length > 0
                ? selectedItemsForEdge
                : getSelectedItems().map(({ id }) => id);
        const blockItems = getBlockItems(gridState);
        return blockItems.map((item) => {
            // 1. item must be selected AND
            // 2. either the item is not moving (user just selected it)
            //   OR the item is moving and the preview is shown (to show green outline around preview placeholder)
            const displayOutline =
                selectedItems.includes(item.item) &&
                (!isBlockItemMoving || showPreviewPlaceholder);
            if (displayOutline) {
                const paddedItem = applyVizPadding({
                    item,
                    padding: panelPadding,
                });
                return (
                    <MemoizedBlockOutline
                        handleDirections={HANDLE_DIRECTIONS}
                        key={item.item}
                        itemId={item.item}
                        scale={mouseScale}
                        x={paddedItem.position.x}
                        y={paddedItem.position.y}
                        w={paddedItem.position.w}
                        h={paddedItem.position.h}
                    />
                );
            }
            // for all other block items, no outline will be displayed.
            return null;
        });
    };

    const getEdgeAppearance = useCallback(
        ({ edge }: { edge: EdgeItem }): EdgeAppearance => {
            const renderAllEdges = !mouseDownEdge.current;
            let appearance: EdgeAppearance =
                renderAllEdges ||
                edge.item === mouseDownEdge.current?.item ||
                snappableEdges.find((e) => e.item === edge.item)
                    ? 'normal'
                    : 'hidden';

            if (isBlockItemMoving) {
                const itemToMove = layoutStructure.find(
                    ({ item }) => item === getSelectedItems()[0]?.id
                );
                // hide edge if it's adjacent to the moving item and has the same width/height as item
                if (itemToMove && isDropOnOwnEdge({ edge, itemToMove })) {
                    appearance = 'hidden';
                } else {
                    appearance = 'dropTarget';
                }
            }
            if (invalidEdgeId === edge.item) {
                appearance = 'invalid';
            }
            return appearance;
        },
        [
            invalidEdgeId,
            isBlockItemMoving,
            mouseDownEdge,
            snappableEdges,
            getSelectedItems,
            layoutStructure,
        ]
    );

    /**
     * Renders the Edges
     * @param {Object} options
     * @param {Number} options.edgeThickness edgeThickness
     * @param {Number} options.scale scale value
     * @returns {Object} returns edge with updated position
     */
    const edgeItems = useMemo(() => {
        if (mode !== 'edit') {
            return null;
        }

        // if no edge is selected, render all edges
        return edges.map((edge) => {
            const formattedEdge = formatEdgeWrapper({
                edge,
                padding: panelPadding,
            });
            const { width, height } = getDimensions({
                edge: formattedEdge,
                thickness: 2 * panelPadding,
            });
            const { x, y } = formattedEdge.edgeStart;

            return (
                <MemoizedEdge
                    key={edge.item}
                    itemId={edge.item}
                    x={x}
                    y={y}
                    w={width}
                    h={height}
                    padding={panelPadding}
                    orientation={edge.orientation}
                    isCanvasEdge={edge.isCanvasEdge}
                    appearance={getEdgeAppearance({ edge })}
                    onMouseDown={handleMouseDownOnEdge}
                    // use the raw coordinates as test hook, so that it is easier to triage and is compatible with the previous implementation
                    data-test-edge-position={`${edge.edgeStart.x},${edge.edgeStart.y}-${edge.edgeEnd.x},${edge.edgeEnd.y}`}
                />
            );
        });
    }, [mode, edges, getEdgeAppearance, panelPadding, handleMouseDownOnEdge]);

    const handleRenderPreviewPlaceholderItem = useCallback(() => {
        if (!firstSelectedItemStructure || !showPreviewPlaceholder) {
            return null;
        }

        const item = applyVizPadding({
            item: firstSelectedItemStructure,
            padding: panelPadding,
        });
        return (
            <PreviewPlaceholderItem
                key={`${firstSelectedItemStructure.item}-preview`}
                position={item.position}
            />
        );
    }, [firstSelectedItemStructure, panelPadding, showPreviewPlaceholder]);

    // TODO: this is an ugly hack to make deleting a viz not break the layout
    /**
     * History:
     * layoutStructure prop changes will cause a rerender.
     * The renderBlockItems method needs to receive the modified structure (e.g. from dragging) stored in the reducer state.
     * Removing a viz will result in a layoutStructure change and render before the reducer updates, referencing a viz that no longer exists.
     * This will throw an error when trying to resolve tokens in the unknown viz config.
     * An undo operation after a delete will restore a viz that does not exist in gridState, so a filtered structure must account for this case.
     */
    const filteredState = useMemo(
        () => getFilteredStructure(gridState, layoutStructure),
        [gridState, layoutStructure]
    );

    const items = getSelectedItems();

    // no need to memoize because it is destructured before passing to GridCanvas
    const commonProps = {
        width: canvasWidth,
        height: maxHeight,
        scale,
        selectable: true,
        selectedLayoutItems: items,
        onItemSelected: handleItemSelected,
        panelPadding,
        ref: canvasRef,
    };
    const modeSpecificProps =
        mode === 'edit'
            ? {
                  movable: true,
                  showOverflowContent: false,
                  showGrid,
                  showBorder: true,
                  gridWidth: GRID_SIZE_PX - 1,
                  gridHeight: GRID_SIZE_PX - 1,
                  gridLineWidth: 1,
                  gridPadding: GRID_PADDING_PX,
                  onItemMove: handleBlockItemMove,
                  onItemMoved: handleBlockItemMoved,
                  userSelect: false,
              }
            : {
                  movable: false,
                  showOverflowContent: false,
                  showGrid: false,
                  showBorder: false,
                  userSelect: true,
              };

    return (
        <GridCanvas
            data-test="grid-layout"
            blockItems={filteredState}
            data-test-scale={scale}
            {...commonProps}
            {...modeSpecificProps}
        >
            <Layer key="block-item-layer" data-test="block-item-layer">
                {renderBlockItems({
                    layoutStructure: filteredState,
                    renderLayoutItem,
                    handleItemSelected,
                    isBlockItemMoving,
                    selectedItem: getSelectedItems()[0],
                    mode,
                    errors: layoutErrors,
                    padding: panelPadding,
                    canvasHeight: maxHeight,
                })}
            </Layer>
            {edgeItems}
            <Layer key="outline-layer" data-test="outline-layer">
                {renderOutline()}
            </Layer>
            {mousePosition && isBlockItemMoving && handleRenderItemDropTarget()}
            {mousePosition &&
                isBlockItemMoving &&
                handleRenderPreviewPlaceholderItem()}
            {mousePosition && isBlockItemMoving && (
                <ItemDragPlaceholder
                    position={mousePosition}
                    size={PLACEHOLDER_SIZE_PX}
                />
            )}
        </GridCanvas>
    );
};

// Wrapper function that primarily handles the enableGridLayoutCssScaling functionality.
const GridLayout = (props: GridLayoutProps): JSX.Element => {
    const {
        containerWidth,
        containerHeight,
        options: { width: canvasWidth = gridLayoutOptions.width } = {},
        onLayoutStructureChange,
        layoutStructure,
    } = props;
    const { enableGridLayoutCssScaling } = useFeatureFlags();
    const scrollbarWidth = getScrollbarWidth();

    const [canvasHeight, setCanvasHeight] = useState(() =>
        computeMaxHeight(layoutStructure)
    );

    // When css scaling is disabled there needs to be a consistent scale for handleLayoutStructureChange
    const scale = useMemo(
        () =>
            computeScaleToFit({
                canvasWidth,
                canvasHeight,
                containerWidth,
                containerHeight,
                scrollbarWidth,
                enableGridLayoutCssScaling,
            }),

        [
            enableGridLayoutCssScaling,
            canvasWidth,
            canvasHeight,
            containerWidth,
            containerHeight,
            scrollbarWidth,
        ]
    );

    // Undo the scaling when we change the layoutStructure. With the enableGridLayoutCssScaling
    // flag we're now modifying the actual width of the BlockItem so we want to ensure
    // we are using the original unscaled values (1 / scale)
    const handleLayoutStructureChange = useCallback(
        (layout) => {
            onLayoutStructureChange(
                scaleGridLayoutStructureByWidth({
                    layout,
                    scale: 1 / scale,
                })
            );
        },
        [scale, onLayoutStructureChange]
    );

    // If we're not scaling the layout we have to scale each blockItem
    const scaledLayoutStructure = useMemo(
        () =>
            !enableGridLayoutCssScaling
                ? scaleGridLayoutStructureByWidth({
                      layout: layoutStructure,
                      scale,
                  })
                : layoutStructure,
        [enableGridLayoutCssScaling, layoutStructure, scale]
    );

    const sortedLayoutStructure = useMemo(
        () =>
            getGridLayoutOrder({
                layout: scaledLayoutStructure,
                canvasWidth,
                canvasHeight: computeMaxHeight(scaledLayoutStructure),
            }),
        [canvasWidth, scaledLayoutStructure]
    );
    return (
        <BaseGridLayout
            {...props}
            scale={scale}
            canvasWidth={canvasWidth}
            layoutStructure={sortedLayoutStructure}
            setCanvasHeight={setCanvasHeight}
            {...(!enableGridLayoutCssScaling
                ? {
                      canvasWidth: containerWidth - scrollbarWidth,
                      onLayoutStructureChange: handleLayoutStructureChange,
                  }
                : null)}
        />
    );
};

export default withLayoutShowHide(GridLayout, {
    schema: gridLayoutOptionsSchema,
    reflowFn: gridLayoutShowHideReflow,
});
