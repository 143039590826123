import * as React from 'react';
import { _ } from '@splunk/ui-utils/i18n';
import SVG from '@splunk/react-icons/SVGEnterprise';

const FillerGauge = (props: React.ComponentProps<typeof SVG>): React.ReactElement<SVGElement> => (
    <SVG screenReaderText={_('Filler Gauge')} fill="currentColor" viewBox="0 0 150 100" {...props}>
        <path fillRule="evenodd" clipRule="evenodd" d="M59 10H91V91H59V10ZM61 12H89V39H61V12Z" />
    </SVG>
);

export default FillerGauge;
