import React, { useMemo } from 'react';
import { usePreset } from '@splunk/dashboard-context';
import type { PresetMap } from '@splunk/dashboard-types';
import {
    defaultSchema as defaultDashboardSchema,
    createSchemaBasedOnPresets,
} from '@splunk/dashboard-definition';
import { console } from '@splunk/dashboard-utils';

const createSchema = (preset: PresetMap) => {
    try {
        return createSchemaBasedOnPresets(preset);
    } catch (e) {
        console.error('Schema Error:', e);
        return defaultDashboardSchema;
    }
};

interface SchemaProps {
    schema: ReturnType<typeof createSchemaBasedOnPresets>;
}

export const withValidationSchema =
    <P extends SchemaProps>(WrappedComponent: React.ComponentType<P>) =>
    (props: Omit<P, keyof SchemaProps>) => {
        const preset = usePreset();

        const schema = useMemo(
            () => createSchema(preset.getPresetDefinition()),
            [preset]
        );

        return <WrappedComponent {...(props as P)} schema={schema} />;
    };
