import styled from 'styled-components';

const VizStyleWrapper = styled.div.attrs(({ dataTestKey }) => ({
    'data-test': dataTestKey,
}))`
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    height: 100%;
    background-color: ${props => props.backgroundColor};
`;

VizStyleWrapper.displayName = 'VizStyleWrapper';

export default VizStyleWrapper;
