import React from 'react';
import styled from 'styled-components';
import Table from '@splunk/react-ui/Table';
import { pick, variables } from '@splunk/themes';
import { formatNumber, parseNumber } from '@splunk/react-visualizations/utils/numberUtils';
import { CellPropTypes, CellDefaultProps, CellDefaultStyle } from './CellPropShape';

const ChildCell = styled.div`
    padding-right: 12px;
    padding-left: 12px;

    &[data-text-align='right'] {
        text-align: right;
    }

    &[data-text-align='center'] {
        text-align: center;
    }

    &[data-clickable] {
        cursor: pointer;

        &:hover {
            background-color: ${pick({
                enterprise: {
                    light: variables.accentColorL50,
                    dark: variables.accentColorD50,
                },
            })};
            box-shadow: ${variables.focusShadowInset};
        }

        &:focus {
            box-shadow: ${variables.focusShadowInset};
            outline: none;
        }
    }
`;

const formatNumberValue = ({ value, cellOptions }) => {
    const { numberPrecision, useThousandSeparators, unit, unitPosition, defaultPrecision = 20 } = cellOptions;
    const number = parseNumber(value);
    let v = formatNumber(number, numberPrecision, { useThousandSeparators, defaultPrecision });
    if (unit) {
        v = unitPosition === 'before' ? `${unit} ${v}` : `${v} ${unit}`;
    }
    return v;
};

const defaultFormatValue = ({ value }) => value;

const ArrayCell = ({ value: values, cellOptions, fieldOptions, onCellClick }) => {
    const { align, type } = fieldOptions;
    const style = {
        ...CellDefaultStyle,
        color: cellOptions.textColor,
        paddingRight: 0,
        paddingLeft: 0,
    };
    const clickable = onCellClick !== Table.Cell.defaultProps.onClick;
    const formatValue = type === 'number' ? formatNumberValue : defaultFormatValue;
    const childCells = values.map((value, index) => {
        const formattedValue = formatValue({ value, cellOptions, fieldOptions });
        return (
            <ChildCell
                // eslint-disable-next-line react/no-array-index-key
                key={index}
                data-test="child-cell"
                title={formattedValue}
                data-clickable={clickable}
                onClick={onCellClick}
                tabIndex={clickable ? 0 : undefined}
            >
                {formattedValue}
            </ChildCell>
        );
    });

    return (
        <Table.Cell align={align} style={style} test-cell-type="array">
            {childCells}
        </Table.Cell>
    );
};

ArrayCell.propTypes = CellPropTypes;
ArrayCell.defaultProps = CellDefaultProps;
ArrayCell.canRender = (field, fieldOptions, value) => Array.isArray(value);
export default ArrayCell;
