import React from 'react';
import { _ } from '@splunk/ui-utils/i18n';
import SVG, {
    type SVGPropsOptionalViewBox,
} from '@splunk/react-icons/SVGEnterprise';

/* eslint-disable max-len */
export default function Share(
    props: SVGPropsOptionalViewBox
): ReturnType<typeof SVG> {
    return (
        <SVG screenReaderText={_('Share')} viewBox="0 0 32 32" {...props}>
            <path
                fill="currentColor"
                fillRule="evenodd"
                d="M20 8a4 4 0 1 1 1.31 2.96L11.97 15.5a4.035 4.035 0 0 1-.276 2.039L20.96 21.4a4 4 0 1 1-.88 1.8l-9.651-4.021a4 4 0 1 1 .799-5.542l8.979-4.364A3.994 3.994 0 0 1 20 8zm4-2a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm0 16a2 2 0 1 0 0 4 2 2 0 0 0 0-4zM6 16a2 2 0 1 1 4 0 2 2 0 0 1-4 0z"
            />
        </SVG>
    );
}
