import { _ } from '@splunk/ui-utils/i18n';
import { COLOR_OR_TOKEN_PATTERN_WITH_RGBA } from '@splunk/visualizations-shared/schemaUtils';
import pick from '@splunk/themes/pick';
import variables from '@splunk/themes/variables';
import { VIZ_CATEGORICAL } from '@splunk/visualization-color-palettes';
import { ParallelCoordinates as ParallelCoordinatesIcon } from '@splunk/visualization-icons';
import { ParallelCoordinates as ParallelCoordinatesPlaceholderIcon } from '@splunk/visualization-icons/placeholders';
import { VizConfig } from '../common/interfaces/VizConfig';
import { DataContract } from '../common/interfaces/DataContract';
import { VizSize } from '../common/interfaces/VizSize';
import { OptionsSchema } from '../common/interfaces/OptionsSchema';
import { EditorConfig } from '../common/interfaces/Editor';
import { VizBehavior } from '../common/interfaces/VizBehavior';
import { enhanceConfig } from '../common/utils/configUtils';
import { ThemedDefaults } from '../common/interfaces/ThemedDefaults';
import { VizCategory } from '../common/interfaces/VizCategory';
import getBackgroundColorEditor from '../common/editorConfig/BackgroundColor';

const dataContract: DataContract = {
    requiredDataSources: [
        {
            name: 'primary',
            description: 'DataSource that powers the visualization',
        },
    ],
    optionalDataSources: [],
    initialRequestParams: {
        primary: { offset: 0, count: 10000 },
    },
};

const size: VizSize = {
    initialWidth: 700,
    initialHeight: 400,
};

const optionsSchema: OptionsSchema = {
    backgroundColor: {
        default: '> themes.defaultBackgroundColor',
        description:
            'Specify the color used for the background. The default for enterprise light is "#FFFFFF". The default for enterprise dark is "#000000". The default for prisma dark is "#0b0c0e".',
        pattern: COLOR_OR_TOKEN_PATTERN_WITH_RGBA,
        type: 'string',
    },
    lineColor: {
        default: VIZ_CATEGORICAL[0],
        description: 'Specify the dataSource color for the lines. The hex value format should be #FFFFFF.',
        pattern: COLOR_OR_TOKEN_PATTERN_WITH_RGBA,
        type: 'string',
    },
    lineOpacity: {
        default: 0.5,
        description:
            'Specify the opacity of the lines. Choose a number in the range of 0 - 1 (inclusive). You can also express the value as a percentage. For example, "0.50" in source or "50%" in UI.',
        type: 'number',
    },
    showNullAxis: {
        default: true,
        description: 'Select whether you would like to show or hide the null value axis.',
        type: 'boolean',
    },
};

const themes: ThemedDefaults = {
    defaultBackgroundColor: props =>
        pick({
            enterprise: {
                dark: variables.black(props),
                light: variables.backgroundColor(props),
            },
            prisma: variables.backgroundColorSidebar(props),
        })(props),
    axisLineColor: props =>
        pick({
            enterprise: {
                dark: variables.gray45(props),
                light: variables.gray60(props),
            },
            prisma: {
                dark: variables.neutral400(props),
                light: variables.syntaxGray(props),
            },
        })(props),
    axisLineHighlightColor: props =>
        pick({
            enterprise: variables.textColor(props),
            prisma: variables.contentColorActive(props),
        })(props),
    axisTitleColor: props =>
        pick({
            enterprise: {
                dark: variables.textGray(props),
                light: variables.textColor(props),
            },
            prisma: variables.contentColorDefault(props),
        })(props),
    axisLabelColor: props =>
        pick({
            enterprise: {
                dark: variables.textGray(props),
                light: variables.textColor(props),
            },
            prisma: variables.contentColorDefault(props),
        })(props),
    filterButtonDisabledColor: props =>
        pick({
            enterprise: variables.textDisabledColor(props),
            prisma: variables.contentColorDisabled(props),
        })(props),
    filterButtonActiveColor: props =>
        pick({
            enterprise: variables.focusColor(props),
            prisma: variables.interactiveColorPrimary(props),
        })(props),
    filterTextColor: props =>
        pick({
            enterprise: {
                dark: variables.textGray(props),
                light: variables.textColor(props),
            },
            prisma: variables.contentColorDefault(props),
        })(props),
    truncatedTextColor: props =>
        pick({
            enterprise: variables.textGray(props),
            prisma: variables.contentColorMuted(props),
        })(props),
    filterSelectionColor: props =>
        pick({
            enterprise: {
                dark: '#FFFFFF',
                light: '#000000',
            },
            prisma: {
                dark: '#FFFFFF',
                light: '#000000',
            },
        })(props),
    textShadowColor: props =>
        pick({
            enterprise: {
                dark: variables.black(props),
                light: variables.backgroundColor(props),
            },
            prisma: variables.backgroundColorSection(props),
        })(props),
};

const EDITOR_LABEL_WIDTH = 100;
const editorConfig: EditorConfig[] = [
    {
        label: _('Data display'),
        layout: [
            [
                {
                    label: _('Show null axis'),
                    option: 'showNullAxis',
                    editor: 'editor.toggle',
                    editorProps: {
                        labelWidth: EDITOR_LABEL_WIDTH,
                    },
                },
            ],
        ],
    },
    {
        label: _('Color and style'),
        layout: [
            [
                {
                    label: _('Lines'),
                    option: 'lineColor',
                    editor: 'editor.color',
                    editorProps: {
                        themes,
                    },
                },
                {
                    label: _('Line opacity (%)'),
                    option: 'lineOpacity',
                    editor: 'editor.percent',
                    editorProps: {
                        themes,
                        min: 0,
                    },
                },
            ],
            getBackgroundColorEditor({ themes }),
        ],
    },
];

/**
 * visualization configuration
 */
const config: VizConfig = {
    /**
     * unique viz key
     */
    key: 'splunk.parallelcoordinates',
    /**
     * viz name
     */
    name: 'Parallel Coordinates',
    category: VizCategory.COMPARISONS,
    status: 'preview',
    /**
     * viz icon
     */
    icon: ParallelCoordinatesIcon,
    placeholderIcon: ParallelCoordinatesPlaceholderIcon,
    dataContract,
    size,
    optionsSchema,
    editorConfig,
    events: {}, // To be completed
    supports: [VizBehavior.DYNAMIC_OPTIONS, VizBehavior.EVENTS, VizBehavior.PLACEHOLDER], // will there be more??
    themes,
    requiredProps: [],
};

export default enhanceConfig(config);
