import * as React from 'react';
import { useCallback, useMemo } from 'react';
import * as T from 'prop-types';
import styled from 'styled-components';
import { pick } from 'lodash';
import { _ } from '@splunk/ui-utils/i18n';
import { toDimension } from '@splunk/visualizations-shared/style';
import SizeAwareWrapper from '@splunk/visualizations-shared/SizeAwareWrapper';
import Message from '@splunk/visualizations-shared/Message';
import { createDOMID } from '@splunk/ui-utils/id';
import {
    GAUGE_THICKNESS,
    getGaugeDimensions,
    getMarkerBarDimensions,
    validateRanges,
    getSortedRanges,
    getGaugeRange,
    getGradientStops,
    getGradientDimensions,
    getMarkerGaugeValueMarkerPositions,
} from '../../common/gaugeUtils';
import MajorTicks from '../../common/GaugeMajorTicks';
import ValueMarker from '../../common/GaugeValueMarker';
import { VizProps } from '../../common/interfaces/VizProps';

interface ContainerProps {
    width: number;
    height: number;
    backgroundColor: string;
}

interface MarkerGaugeWrapperProps {
    backgroundColor: string;
}

interface MarkerGaugeContainerProps {
    width: number;
    height: number;
}

interface BackgroundBarProps {
    fillColor: string;
}

const MAX_VERTICAL_CONTAINER_WIDTH = 200;
const MIN_VERTICAL_CONTAINER_HEIGHT = 200;
const MIN_HORIZONTAL_CONTAINER_WIDTH = 200;
const MIN_HORIZONTAL_CONTAINER_HEIGHT = 100;

const Container = styled.div<ContainerProps>`
    overflow: hidden;
    ${props => toDimension(pick(props, ['width', 'height']))};
    background-color: ${props => props.backgroundColor};
`;

const MarkerGaugeWrapper = styled.div<MarkerGaugeWrapperProps>`
    display: flex;
    justify-content: center;
    flex-direction: row;
    width: 100%;
    height: 100%;
    background-color: ${props => props.backgroundColor};
`;

const MarkerGaugeContainer = styled.div<MarkerGaugeContainerProps>`
    overflow: hidden;
    position: relative;
    ${props => toDimension(pick(props, ['width', 'height']))};
`;

const MarkerGaugeSVG = styled.svg`
    ${props => toDimension(pick(props, ['width', 'height']))};
`;

const BackgroundBar = styled.rect.attrs({ 'data-test': 'background-bar-rect' })<BackgroundBarProps>`
    fill: ${props => props.fillColor};
`;

const MarkerBar = styled.rect.attrs({ 'data-test': 'marker-bar-rect' })``;

export interface MarkerGaugeProps extends VizProps {
    mode: string;
    width: number;
    height: number;
    value: string;
    orientation: string;
    majorUnit: number | string;
    showLabels: boolean;
    showValue: boolean;
    usePercentageRange: boolean;
    usePercentageValue: boolean;
    backgroundColor: string;
    ranges: any;
    options: any;
    fillColor: string;
    majorTickFillColor: string;
    majorTickStrokeColor: string;
    valueMarkerFillColor: string;
    valueMarkerLabelFillColor: string;
}

const MarkerGauge = ({ ...props }: MarkerGaugeProps) => {
    const {
        width,
        height,
        value,
        orientation,
        majorUnit,
        showLabels,
        showValue,
        usePercentageRange,
        usePercentageValue,
        backgroundColor,
        fillColor,
        ranges,
        majorTickFillColor,
        majorTickStrokeColor,
        valueMarkerFillColor,
        valueMarkerLabelFillColor,
    } = props;

    /**
     * Standard error message render
     */
    const errorMessage = useCallback(
        (containerWidth, containerHeight, err, errID) => (
            <Message width={containerWidth} height={containerHeight} message={err} level="info" id={errID} />
        ),
        []
    );

    const errorId = useMemo(() => createDOMID(), []);

    /**
     * Pre-calculate linearGradientId, error, sortedRanges, gauge range and linear gradient
     */
    const error = useMemo(() => validateRanges(ranges), [ranges]);
    const sortedRanges = useMemo(() => getSortedRanges(ranges), [ranges]);
    const { min, max } = useMemo(() => getGaugeRange(sortedRanges), [sortedRanges]);
    const linearGradientId = useMemo(() => createDOMID('marker'), []);
    const linearGradient = useMemo(() => {
        const stops = getGradientStops({ ranges: sortedRanges, orientation });
        const { x1, y1, x2, y2 } = getGradientDimensions(orientation);
        const linearGradientStops = stops.map(stop => (
            <stop key={`${stop.offset}_${stop.stopColor}`} offset={stop.offset} stopColor={stop.stopColor} />
        ));

        return (
            <linearGradient id={linearGradientId} x1={x1} y1={y1} x2={x2} y2={y2}>
                {linearGradientStops}
            </linearGradient>
        );
    }, [sortedRanges, orientation, linearGradientId]);

    /**
     * Calculate marker gauge dimensions
     */
    const calculateMarkerGaugeDimensions = useCallback(
        (containerWidth, containerHeight) => {
            const { gaugeLength, gaugeStartX, gaugeStartY, gaugeWidth, gaugeHeight } = getGaugeDimensions({
                containerWidth,
                containerHeight,
                orientation,
            });

            const {
                markerBarLength,
                markerBarX,
                markerBarY,
                markerBarWidth,
                markerBarHeight,
            } = getMarkerBarDimensions({
                gaugeLength,
                gaugeStartX,
                gaugeStartY,
                gaugeWidth,
                gaugeHeight,
                orientation,
            });

            const { valueMarkerX, valueMarkerY } = getMarkerGaugeValueMarkerPositions({
                markerBarX,
                markerBarY,
                markerBarHeight,
                markerBarLength,
                orientation,
                value,
                min,
                max,
            });

            return {
                markerBarX,
                markerBarY,
                markerBarWidth,
                markerBarHeight,
                valueMarkerX,
                valueMarkerY,
                gaugeStartX,
                gaugeStartY,
                gaugeWidth,
                gaugeHeight,
            };
        },
        [orientation, value, min, max]
    );

    const renderVisualization = useCallback(
        ({ width: containerWidth, height: containerHeight }) => {
            // todo: need change the size when get design for smaller space
            // 58 is the title & description header height in dashboard visualization
            if (
                ((containerWidth < MAX_VERTICAL_CONTAINER_WIDTH ||
                    containerHeight + 58 < MIN_VERTICAL_CONTAINER_HEIGHT) &&
                    orientation === 'vertical') ||
                ((containerWidth < MIN_HORIZONTAL_CONTAINER_WIDTH ||
                    containerHeight < MIN_HORIZONTAL_CONTAINER_HEIGHT) &&
                    orientation === 'horizontal')
            ) {
                return errorMessage(
                    containerWidth,
                    containerHeight,
                    _('Too small to render content'),
                    errorId
                );
            }

            if (error) {
                return errorMessage(containerWidth, containerHeight, error, errorId);
            }

            const {
                markerBarX,
                markerBarY,
                markerBarWidth,
                markerBarHeight,
                valueMarkerX,
                valueMarkerY,
                gaugeStartX,
                gaugeStartY,
                gaugeWidth,
                gaugeHeight,
            } = calculateMarkerGaugeDimensions(containerWidth, containerHeight);

            return (
                <MarkerGaugeWrapper backgroundColor={backgroundColor}>
                    <MarkerGaugeContainer width={containerWidth} height={containerHeight}>
                        <MarkerGaugeSVG width={containerWidth} height={containerHeight}>
                            {linearGradient}
                            <BackgroundBar
                                x={gaugeStartX}
                                y={gaugeStartY}
                                width={gaugeWidth}
                                height={gaugeHeight}
                                fillColor={fillColor}
                            />
                            <MajorTicks
                                width={gaugeWidth}
                                height={gaugeHeight}
                                startX={gaugeStartX}
                                startY={gaugeStartY}
                                min={min}
                                max={max}
                                majorUnit={majorUnit}
                                orientation={orientation}
                                showLabels={showLabels}
                                usePercentageRange={usePercentageRange}
                                majorTickFillColor={majorTickFillColor}
                                majorTickStrokeColor={majorTickStrokeColor}
                            />
                            <MarkerBar
                                x={markerBarX}
                                y={markerBarY}
                                width={markerBarWidth}
                                height={markerBarHeight}
                                fill={`url(#${linearGradientId})`}
                            />
                            <ValueMarker
                                x={valueMarkerX}
                                y={valueMarkerY}
                                length={GAUGE_THICKNESS}
                                min={min}
                                max={max}
                                value={value}
                                orientation={orientation}
                                showValue={showValue}
                                usePercentageValue={usePercentageValue}
                                valueMarkerFillColor={valueMarkerFillColor}
                                valueMarkerLabelFillColor={valueMarkerLabelFillColor}
                            />
                        </MarkerGaugeSVG>
                    </MarkerGaugeContainer>
                </MarkerGaugeWrapper>
            );
        },
        [
            orientation,
            error,
            calculateMarkerGaugeDimensions,
            backgroundColor,
            linearGradient,
            fillColor,
            min,
            max,
            majorUnit,
            showLabels,
            usePercentageRange,
            majorTickFillColor,
            majorTickStrokeColor,
            linearGradientId,
            value,
            showValue,
            usePercentageValue,
            valueMarkerFillColor,
            valueMarkerLabelFillColor,
            errorMessage,
            errorId,
        ]
    );

    return (
        <Container width={width} height={height} backgroundColor={backgroundColor}>
            <SizeAwareWrapper>
                {containerDimension => renderVisualization(containerDimension)}
            </SizeAwareWrapper>
        </Container>
    );
};

MarkerGauge.propTypes = {
    width: T.oneOfType([T.string, T.number]),
    height: T.oneOfType([T.string, T.number]),
    value: T.number,
    // valueFieldName: T.string, // todo: need add it back when displaying name later
    orientation: T.oneOf(['horizontal', 'vertical']),
    majorUnit: T.oneOfType([T.string, T.number]),
    showLabels: T.bool,
    showValue: T.bool,
    usePercentageRange: T.bool,
    usePercentageValue: T.bool,
    backgroundColor: T.string,
    fillColor: T.string,
    ranges: T.arrayOf(
        T.shape({
            from: T.number.isRequired, // For now, we want close bounds - range should include 'from' and 'to'
            to: T.number.isRequired,
            value: T.string.isRequired,
        })
    ),
    majorTickFillColor: T.string,
    majorTickStrokeColor: T.string,
    valueMarkerFillColor: T.string,
    valueMarkerLabelFillColor: T.string,
};

MarkerGauge.defaultProps = {
    width: '100%',
    height: 250,
    orientation: 'vertical',
    majorUnit: 'auto',
    showLabels: true,
    showValue: true,
    usePercentageRange: false,
    usePercentageValue: false,
    backgroundColor: 'transparent',
    value: 0,
    fillColor: '#f2f4f5',
    majorTickFillColor: '#3c444d',
    majorTickStrokeColor: '#6b7785',
    valueMarkerFillColor: '#3c444d',
    valueMarkerLabelFillColor: '#f2f4f5',
    ranges: [
        {
            from: 0,
            to: 50,
            value: '#4BEBA8',
        },
        {
            from: 50,
            to: 90,
            value: '#F4DF7A',
        },
        {
            from: 90,
            to: 100,
            value: '#CB3B43',
        },
    ],
};

export default MarkerGauge;
