import { getScrollbarWidth, isBlockItem, noop } from '@splunk/dashboard-utils';
import { BehaviorSubject } from 'rxjs';
import React, {
    useMemo,
    useState,
    useCallback,
    useRef,
    useEffect,
    useLayoutEffect,
    useReducer,
} from 'react';
import { isEqual } from 'lodash';
import {
    useFeatureFlags,
    useKeyboardListener,
    usePreset,
    useDashboardCoreApi,
} from '@splunk/dashboard-context';
import { DashboardDefinition } from '@splunk/dashboard-definition';
import {
    useDashboardProfiler,
    useTelemetryApi,
    VIZ_MOVE_EVENT,
    VIZ_RESIZE_EVENT,
} from '@splunk/dashboard-telemetry';
import { usePrevious } from '@splunk/dashboard-ui';
import type {
    AbsoluteLayoutStructure,
    SelectedItem,
    AbsoluteLayoutOptions,
    ConnectedLineItem,
    Port,
    AbsoluteBlockItem,
} from '@splunk/dashboard-types';

import { AbsoluteLayoutApi } from './apis';
import {
    AbsoluteCanvas,
    Layer,
    ResponsiveBlockItem,
    ResponsiveLine,
    ResponsiveBlockOutline,
} from './components';
import { absoluteLayoutOptions } from './DefaultOptions';
import {
    initializeLayoutStructureState,
    reducer,
    updateLayoutStructureOnKeyboardMove,
} from './utils/absoluteLayoutUtils';
import {
    findSelectedBlockItems,
    getAllBlockItems,
    InvalidBlockItemError,
} from './utils/blockUtils';
import { validateBackgroundImage } from './utils/imageUtils';
import {
    updateBlockItemSize,
    updateBlockItemPosition,
    snapOffsetToXY,
    snapOffsetToWH,
    computeScaleToFit,
    shiftViewportOnZoom,
} from './utils/layoutUtils';
import {
    computeLineAbsPosition,
    findSelectedLineItems,
    handleSingleLineMove,
    handleSingleLineDragStart,
} from './utils/lineUtils';
import {
    GRID_SIZE,
    GRID_PADDING,
    MIN_WIDTH,
    MIN_HEIGHT,
    ZOOM_STEP_SIZE,
} from './AbsoluteLayoutConstants';
import absoluteLayoutOptionsSchema from './absoluteLayoutOptionsSchema';
import type { RenderLayoutItem, Offset, LineDirection } from './types';
import { withLayoutShowHide } from './enhancers';
import { useBackgroundImage } from './hooks';

const emptySelectedItems: SelectedItem[] = [];

export interface AbsoluteLayoutProps {
    mode?: 'view' | 'edit';
    showGrid?: boolean;
    options?: AbsoluteLayoutOptions;
    layoutStructure?: AbsoluteLayoutStructure;
    rawLayoutStructure?: AbsoluteLayoutStructure;
    containerWidth: number;
    containerHeight: number;
    selectedItems?: SelectedItem[];
    renderLayoutItem: RenderLayoutItem;
    onLayoutItemsSelect?: (selectedItems: SelectedItem[]) => void;
    onLayoutStructureChange?: (
        layoutStructure: AbsoluteLayoutStructure
    ) => void;
    layoutApiRef?: (layoutAPi: AbsoluteLayoutApi | null) => void;
}

const emptyLayoutStructure: AbsoluteLayoutStructure = [];

const AbsoluteLayout = (props: AbsoluteLayoutProps): JSX.Element => {
    const {
        mode = 'view',
        showGrid = true,
        options: {
            width = absoluteLayoutOptions.width,
            height = absoluteLayoutOptions.height,
            display = absoluteLayoutOptions.display,
            backgroundColor,
            backgroundImage,
        } = {},
        layoutStructure = emptyLayoutStructure,
        rawLayoutStructure = emptyLayoutStructure,
        containerWidth,
        containerHeight,
        selectedItems = emptySelectedItems,
        renderLayoutItem,
        onLayoutItemsSelect = noop,
        onLayoutStructureChange = noop,
        layoutApiRef = noop,
    } = props;

    const [activeLine, setActiveLine] = useState<{
        id: string;
        dir: LineDirection;
    } | null>(null);
    const [scale, setScaleInternal] = useState(1);
    const [fitToWidth, setFitToWidth] = useState<false | number>(false);

    const [layoutStructureState, dispatch] = useReducer(
        reducer,
        layoutStructure,
        initializeLayoutStructureState
    );

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const canvasRef = useRef<HTMLDivElement>(null!);
    const layoutApi = useRef<AbsoluteLayoutApi | null>(null);
    const zoomObserver = useRef<BehaviorSubject<number> | null>(null);
    const savedScroll = useRef<{ x: number; y: number } | null>(null);
    const isMouseDownEventRef = useRef(false);

    // have a ref to the latest layoutStructure, so that the AbsoluteLayoutApi can use it
    const layoutStructureRef = useRef<AbsoluteLayoutStructure>(layoutStructure);
    layoutStructureRef.current = layoutStructure;
    // have a ref to the latest zoom step, so that the AbsoluteLayoutApi can use it
    const currentStepRef = useRef(1);
    currentStepRef.current =
        Math.round(scale / ZOOM_STEP_SIZE) * ZOOM_STEP_SIZE;

    const keyboardListener = useKeyboardListener();
    const featureFlags = useFeatureFlags();
    const preset = usePreset();
    const dashboardApi = useDashboardCoreApi();
    const telemetry = useTelemetryApi();
    const profiler = useDashboardProfiler();

    const previousScale = usePrevious(scale);
    const previousLayoutStructure = usePrevious(layoutStructure);

    const scrollbarWidth = useMemo(() => getScrollbarWidth(), []);

    const getZoomObserver = useCallback(() => {
        if (zoomObserver.current == null) {
            zoomObserver.current = new BehaviorSubject(1);
        }

        return zoomObserver.current;
    }, []);

    const setScale = useCallback(
        (s) => {
            getZoomObserver().next(s);
            setScaleInternal(s);
            setFitToWidth(false);
        },
        [getZoomObserver]
    );

    const getCanvasDomElement = useCallback(() => {
        return canvasRef.current;
    }, []);

    const scrollToTopLeft = useCallback(() => {
        const layoutEl = getCanvasDomElement().parentElement;
        if (layoutEl) {
            layoutEl.scrollTop = 0;
            layoutEl.scrollLeft = 0;
        }
    }, [getCanvasDomElement]);

    const setFitToWidthAndScrollToTopLeft = useCallback(
        (maxScale = Infinity) => {
            setFitToWidth(maxScale);
            scrollToTopLeft();
        },
        [scrollToTopLeft]
    );

    const zoomIn = useCallback(() => {
        setScale(currentStepRef.current + ZOOM_STEP_SIZE);
    }, [setScale]);

    const zoomOut = useCallback(() => {
        const nextStep = currentStepRef.current - ZOOM_STEP_SIZE;

        if (nextStep >= ZOOM_STEP_SIZE) {
            setScale(nextStep);
        }
    }, [setScale]);

    const handleKeyboardZoom = useCallback(
        ({ dir, preventDefault }) => {
            switch (dir) {
                case 'in':
                    zoomIn();
                    break;
                case 'out':
                    zoomOut();
                    break;
                case 'reset':
                    setFitToWidthAndScrollToTopLeft();
                    break;
                default:
                    break;
            }
            preventDefault();
        },
        [setFitToWidthAndScrollToTopLeft, zoomIn, zoomOut]
    );

    /**
     * Keyboard move updates the definition directly, no need to update state
     */
    const handleKeyboardMove = useCallback(
        ({ dir, snap, preventDefault }) => {
            const selectedLineItems = findSelectedLineItems({
                layoutStructure,
                selectedItems,
            });
            const selectedBlockItems = findSelectedBlockItems({
                layoutStructure,
                selectedItems,
            });

            if (
                (selectedLineItems.length === 0 &&
                    selectedBlockItems.length === 0) ||
                mode !== 'edit'
            ) {
                return;
            }

            const updatedLayoutStructure = updateLayoutStructureOnKeyboardMove({
                selectedBlockItems,
                selectedLineItems,
                dir,
                snap,
                gridSize: GRID_SIZE,
                layoutStructure,
            });

            onLayoutStructureChange(updatedLayoutStructure);

            preventDefault();
        },
        [layoutStructure, mode, onLayoutStructureChange, selectedItems]
    );

    const handleLayoutStructureChange = useCallback(() => {
        const updatedLayoutStructure = layoutStructure.map(
            (item) => layoutStructureState[item.item] ?? item
        );

        onLayoutStructureChange(updatedLayoutStructure);
    }, [layoutStructure, layoutStructureState, onLayoutStructureChange]);

    const handleLineMove = useCallback(
        (e, offset) => {
            const selectedLineItems = findSelectedLineItems({
                layoutStructure,
                selectedItems,
            });

            if (selectedLineItems.length === 0) {
                return;
            }

            const lineId = selectedLineItems[0].item;

            dispatch({
                type: 'lineMove',
                payload: handleSingleLineMove({
                    layoutStructure,
                    lineId,
                    offset,
                }),
            });
        },
        [layoutStructure, selectedItems]
    );

    const handleLineMoved = useCallback(() => {
        handleLayoutStructureChange();
    }, [handleLayoutStructureChange]);

    const handleLineDragStart = useCallback(
        (e, dir: LineDirection) => {
            const selectedLineItems = findSelectedLineItems({
                layoutStructure,
                selectedItems,
            });

            if (selectedLineItems.length !== 1) {
                return;
            }

            const lineId = selectedLineItems[0].item;

            const line = handleSingleLineDragStart({
                lineId,
                layoutStructure,
                lineDir: dir,
            });

            dispatch({
                type: 'lineDragStart',
                payload: line,
            });

            setActiveLine({ id: lineId, dir });
        },
        [layoutStructure, selectedItems]
    );

    const handleLineDrag = useCallback(
        (e, offset: Offset) => {
            if (!activeLine) {
                return;
            }

            const line = layoutStructure.find(
                (item) => item.item === activeLine.id
            ) as ConnectedLineItem;

            const absPos = computeLineAbsPosition({
                layoutStructure,
                position: line.position,
            })[activeLine.dir];

            // line should already been disconnect in this case
            dispatch({
                type: 'lineDrag',
                payload: {
                    id: activeLine.id,
                    dir: activeLine.dir,
                    absPos: {
                        x: absPos.x + offset.offsetX,
                        y: absPos.y + offset.offsetY,
                    },
                },
            });
        },
        [activeLine, layoutStructure]
    );

    const handleLineDragged = useCallback(() => {
        setActiveLine(null);
        handleLayoutStructureChange();
    }, [handleLayoutStructureChange]);

    const handleLineConnected = useCallback(
        (itemId: string, port: Port) => {
            if (!activeLine) {
                return;
            }

            const lineId = activeLine.id;

            dispatch({
                type: 'lineConnect',
                payload: { lineId, lineDir: activeLine.dir, itemId, port },
            });
        },
        [activeLine]
    );

    const handleLineDisconnected = useCallback(() => {
        if (!activeLine) {
            return;
        }

        dispatch({
            type: 'lineDisconnect',
            payload: {
                lineId: activeLine.id,
                lineDir: activeLine.dir,
            },
        });
    }, [activeLine]);

    const handleItemSelected = useCallback(
        (e, newSelectedItems = emptySelectedItems) => {
            onLayoutItemsSelect(newSelectedItems);
        },
        [onLayoutItemsSelect]
    );

    const handleBlockItemResize = useCallback(
        (e, itemId: string, offset: Offset, dir) => {
            const item = layoutStructure.find(
                (itm) => itm.item === itemId
            ) as AbsoluteBlockItem;

            const snapOffset = snapOffsetToWH({
                position: item.position,
                offset,
                gridWidth: GRID_SIZE,
                gridHeight: GRID_SIZE,
                spacing: 0,
                padding: 0,
            });

            const newItem = updateBlockItemSize({
                item,
                offset: snapOffset,
                dir,
                options: {
                    minHeight: MIN_HEIGHT,
                    minWidth: MIN_WIDTH,
                },
            });

            // check if the item actually changed size
            if (
                isEqual(
                    newItem.position,
                    layoutStructureState[newItem.item].position
                )
            ) {
                return;
            }

            // start measuring item resize
            profiler?.startMeasurement(VIZ_RESIZE_EVENT);

            dispatch({
                type: 'blockResize',
                payload: newItem,
            });
        },
        [layoutStructure, layoutStructureState, profiler]
    );

    const handleBlockItemResized = useCallback(() => {
        profiler?.emitAndClearMeasurements({
            measurementName: VIZ_RESIZE_EVENT,
        });
        handleLayoutStructureChange();
    }, [handleLayoutStructureChange, profiler]);

    const handleBlockItemMove = useCallback(
        (e, offset: Offset) => {
            const selectedBlockItems = findSelectedBlockItems({
                layoutStructure,
                selectedItems,
            });

            if (selectedBlockItems.length === 0) {
                return;
            }

            // use any block item to calculate the moveOffset
            const firstBlockItem = selectedBlockItems[0];

            const moveOffset = snapOffsetToXY({
                position: firstBlockItem.position,
                offset,
                gridWidth: GRID_SIZE,
                gridHeight: GRID_SIZE,
                spacing: 0,
                padding: 0,
            });

            const updatedSelectedBlockItems = selectedBlockItems.map(
                (blockItem) => updateBlockItemPosition(blockItem, moveOffset)
            );

            // check if the items actually changed position since the last moveOffset
            const firstUpdatedBlockItem = updatedSelectedBlockItems[0];
            if (
                isEqual(
                    firstUpdatedBlockItem.position,
                    layoutStructureState[firstUpdatedBlockItem.item].position
                )
            ) {
                return;
            }

            // start measuring item move
            profiler?.startMeasurement(VIZ_MOVE_EVENT);

            dispatch({
                type: 'blocksMove',
                payload: updatedSelectedBlockItems,
            });
        },
        [layoutStructure, layoutStructureState, profiler, selectedItems]
    );

    const handleBlockItemMoved = useCallback(() => {
        profiler?.emitAndClearMeasurements({
            measurementName: VIZ_MOVE_EVENT,
            metadata: { numVisualizationsMoved: selectedItems?.length },
        });

        handleLayoutStructureChange();
    }, [handleLayoutStructureChange, profiler, selectedItems]);

    // todo: we should memoize the return value
    const blockItems = useMemo(
        () =>
            layoutStructure
                .filter((item) => isBlockItem(item))
                .map(
                    (block) => layoutStructureState[block.item] ?? block
                ) as AbsoluteBlockItem[],
        [layoutStructure, layoutStructureState]
    );

    const lineItems = useMemo(
        () =>
            layoutStructure
                .filter(({ type }) => type === 'line')
                .map(
                    (line) => layoutStructureState[line.item] ?? line
                ) as ConnectedLineItem[],
        [layoutStructure, layoutStructureState]
    );

    const optionsRef = useRef(props.options);
    optionsRef.current = props.options;

    const bgImageSrcRef = useRef<string>();
    /**
     * This may cause an update, but shouldn't cause a redraw/paint for this component or any of its children
     * Canvas also uses this hook to fetch the background image (and will repaint).
     * while doing this work twice isn't great,
     * but it's probably better than passing this value down the tree or resolving background image in a context
     */
    const realBGImageUrl = useBackgroundImage(backgroundImage?.src);
    bgImageSrcRef.current = realBGImageUrl;
    const getBgImageSrc = useCallback(() => {
        return bgImageSrcRef.current;
    }, []);

    const getLayoutApi = useCallback(() => {
        if (layoutApi.current == null) {
            layoutApi.current = new AbsoluteLayoutApi({
                getZoomObserver,
                setFitToWidthAndScrollToTopLeft,
                zoomIn,
                zoomOut,
                setScale,
                optionsRef,
                layoutStructureRef,
                getCanvasDomElement,
                telemetry,
                getBgImageSrc,
            });
        }

        return layoutApi.current;
    }, [
        getCanvasDomElement,
        getZoomObserver,
        layoutStructureRef,
        optionsRef,
        setFitToWidthAndScrollToTopLeft,
        setScale,
        zoomIn,
        zoomOut,
        telemetry,
        getBgImageSrc,
    ]);

    // reset the state when the layoutStructure changes, this handles the case like undo/redo
    useEffect(() => {
        if (!isEqual(layoutStructure, previousLayoutStructure)) {
            dispatch({
                type: 'reset',
                payload: layoutStructure,
            });
        }
    }, [layoutStructure, previousLayoutStructure]);

    // register layout api
    useEffect(() => {
        layoutApiRef(getLayoutApi());

        return () => {
            layoutApiRef(null);
        };
    }, [getLayoutApi, layoutApiRef]);

    useEffect(() => {
        if (!keyboardListener) {
            return noop;
        }

        const unsubscribeMove = keyboardListener.subscribe(
            'move',
            handleKeyboardMove
        );

        return () => {
            unsubscribeMove();
        };
    }, [handleKeyboardMove, keyboardListener]);

    useEffect(() => {
        if (!keyboardListener) {
            return noop;
        }

        const unsubscribeZoom = keyboardListener.subscribe(
            'zoom',
            handleKeyboardZoom
        );

        return () => {
            unsubscribeZoom();
        };
    }, [handleKeyboardZoom, keyboardListener]);

    useEffect(() => {
        if (!keyboardListener) {
            return noop;
        }

        const unsubscribeCancel = keyboardListener.subscribe('cancel', () =>
            handleItemSelected(null, [])
        );

        return () => {
            unsubscribeCancel();
        };
    }, [handleItemSelected, keyboardListener]);

    // apply display mode
    useEffect(() => {
        if (display === 'fit-to-width' || display === 'auto-scale') {
            setFitToWidthAndScrollToTopLeft();
        } else if (display === 'actual-size') {
            setScale(1);
        } else {
            setFitToWidthAndScrollToTopLeft(1);
        }
    }, [display, setFitToWidthAndScrollToTopLeft, setScale]);

    // We need to record the scroll position right before next DOM update,
    // so that we can restore the scroll position later.
    // Refer to the next useEffect() for the logic of restoring scroll position.
    // Reference to the original implementation of this logic:
    // https://cd.splunkdev.com/devplat/dashboard-framework/-/merge_requests/2356
    if (
        previousScale != null &&
        previousScale >= 1 &&
        scale < 1 &&
        featureFlags.enableZoomCenter
    ) {
        const previousLayout = getCanvasDomElement().parentElement;
        if (previousLayout) {
            savedScroll.current = {
                x: previousLayout.scrollLeft + previousLayout.offsetWidth / 2,
                y: previousLayout.scrollTop + previousLayout.offsetHeight / 2,
            };
        }
    }

    // center zoom
    useLayoutEffect(() => {
        if (fitToWidth || previousScale == null || previousScale === scale) {
            return;
        }

        const layout = getCanvasDomElement().parentElement;
        if (!layout) {
            return;
        }

        const { offsetWidth, offsetHeight, scrollTop, scrollLeft } = layout;

        /* scale changed from scale = 0.25 or 0.5 to scale = 1, 1.5 or 2.0 then the scroll position is restored
         * scale changed from scale >= 1 to scale = 0.5 or 0.25 then the scroll position is saved
         * savedScroll.x and SavedScroll.y saves the horizontal and vertical scroll position
         * The scroll position is calculated based on taking half of offsetWidth and offsetHeight
         */

        if (
            previousScale < 1 &&
            scale > 1 &&
            savedScroll?.current?.x != null &&
            savedScroll?.current?.y != null &&
            featureFlags.enableZoomCenter
        ) {
            layout.scrollLeft = savedScroll.current.x - layout.offsetWidth / 2;
            layout.scrollTop = savedScroll.current.y - layout.offsetHeight / 2;
        } else {
            const scaleRatio = scale / previousScale;
            const newScroll = shiftViewportOnZoom({
                offsetWidth,
                offsetHeight,
                scrollTop,
                scrollLeft,
                scaleRatio,
            });
            layout.scrollLeft = newScroll.scrollLeft;
            layout.scrollTop = newScroll.scrollTop;
        }
    }, [
        featureFlags.enableZoomCenter,
        fitToWidth,
        getCanvasDomElement,
        previousScale,
        scale,
    ]);

    // scale to fit
    useEffect(() => {
        if (!fitToWidth) {
            return;
        }

        const newScale = computeScaleToFit({
            canvasWidth: width,
            canvasHeight: height,
            containerWidth,
            containerHeight,
            max: fitToWidth,
            scrollbarWidth,
        });

        getZoomObserver().next(newScale);
        setScaleInternal(newScale);
    }, [
        height,
        getCanvasDomElement,
        containerWidth,
        containerHeight,
        fitToWidth,
        scrollbarWidth,
        getZoomObserver,
        width,
    ]);

    // update active line
    useEffect(() => {
        if (activeLine && layoutStructureState[activeLine.id] == null) {
            setActiveLine(null);
        }
    }, [activeLine, layoutStructureState]);

    const image = useMemo(
        () =>
            backgroundImage
                ? validateBackgroundImage({
                      backgroundImage,
                      canvasWidth: width,
                      canvasHeight: height,
                  })
                : {},
        [backgroundImage, height, width]
    );

    const modeSpecificProps = useMemo(
        () =>
            mode === 'edit'
                ? {
                      movable: true,
                      showOverflowContent: true,
                      showGrid,
                      showBorder: true,
                      gridLineWidth: 1,
                      gridPadding: GRID_PADDING,
                      onItemMove: handleBlockItemMove,
                      onItemMoved: handleBlockItemMoved,
                      userSelect: false,
                      allowMultiselect: true,
                  }
                : {
                      movable: false,
                      showOverflowContent: false,
                      showGrid: false,
                      showBorder: false,
                      userSelect: true,
                      allowMultiselect: false,
                  },
        [handleBlockItemMove, handleBlockItemMoved, mode, showGrid]
    );

    // Memoized block items
    const allBlockItems = useMemo<AbsoluteBlockItem[]>(
        () => getAllBlockItems({ layoutStructure, layoutStructureState }),
        [layoutStructure, layoutStructureState]
    );

    const blockItemElements = useMemo(() => {
        return allBlockItems.map(({ item, type, position: { x, y, w, h } }) => (
            <ResponsiveBlockItem
                key={item}
                itemId={item}
                type={type}
                x={x}
                y={y}
                w={w}
                h={h}
                canvasHeight={height}
                renderLayoutItem={renderLayoutItem}
                onItemSelected={handleItemSelected}
                isMouseDownEventRef={isMouseDownEventRef}
            />
        ));
    }, [handleItemSelected, height, allBlockItems, renderLayoutItem]);

    const lineElements = useMemo(() => {
        const selectedLineItems = findSelectedLineItems({
            layoutStructure,
            selectedItems,
        });
        const selectable = mode === 'edit';

        return lineItems.map((line) => {
            // we don't support multiple select for line
            const editable =
                selectedLineItems.length === 1 &&
                selectedLineItems[0].item === line.item &&
                selectable;

            let absPos = { from: { x: 0, y: 0 }, to: { x: 0, y: 0 } };
            try {
                absPos = computeLineAbsPosition({
                    layoutStructure: Object.values(layoutStructureState),
                    position: line.position,
                });
            } catch (err) {
                // If computeLineAbsPosition threw an error due to a block item not
                // existing then recompute with the layout structure as defined in
                // the definition. This is possible if a line is anchored to a port
                // on a block item which was hidden due to a show/hide configuration
                if (
                    err instanceof Error &&
                    err.message.endsWith(InvalidBlockItemError)
                ) {
                    absPos = computeLineAbsPosition({
                        layoutStructure: rawLayoutStructure,
                        position: line.position,
                    });
                }
            }

            const fromTestHooks =
                'item' in line.position.from && 'port' in line.position.from
                    ? {
                          fromItem: line.position.from.item,
                          fromPort: line.position.from.port,
                      }
                    : {};
            const toTestHooks =
                'item' in line.position.to && 'port' in line.position.to
                    ? {
                          toItem: line.position.to.item,
                          toPort: line.position.to.port,
                      }
                    : {};

            return (
                <ResponsiveLine
                    key={line.item}
                    lineId={line.item}
                    scale={scale}
                    selectable={selectable}
                    editable={editable}
                    renderLayoutItem={renderLayoutItem}
                    onLineMove={handleLineMove}
                    onLineMoved={handleLineMoved}
                    onLineDragStart={handleLineDragStart}
                    onLineDrag={handleLineDrag}
                    onLineDragged={handleLineDragged}
                    onItemSelected={handleItemSelected}
                    fromX={absPos.from.x}
                    fromY={absPos.from.y}
                    toX={absPos.to.x}
                    toY={absPos.to.y}
                    {...fromTestHooks}
                    {...toTestHooks}
                />
            );
        });
    }, [
        handleItemSelected,
        handleLineDrag,
        handleLineDragStart,
        handleLineDragged,
        handleLineMove,
        handleLineMoved,
        rawLayoutStructure,
        layoutStructure,
        layoutStructureState,
        lineItems,
        mode,
        renderLayoutItem,
        scale,
        selectedItems,
    ]);

    const outlineElements = useMemo(() => {
        const selectedLineItems = findSelectedLineItems({
            layoutStructure: Object.values(layoutStructureState),
            selectedItems,
        });
        const selectedBlockItems = findSelectedBlockItems({
            layoutStructure: Object.values(layoutStructureState),
            selectedItems,
        });

        return allBlockItems.map((block) => {
            // current outline has 3 state
            // 1. If only one line is selected and actively been dragging, set outline to connectable
            // outline will display connect port in this case and fire onLineConnect/onLineDisconnect callback
            const connectable =
                mode === 'edit' &&
                selectedBlockItems.length === 0 &&
                selectedLineItems.length === 1 &&
                activeLine != null;

            // 2. If this is the only block item been selected, set outline to resizable
            // in this case outline will display resize handle and fire onResize/onResized callback
            const resizable =
                mode === 'edit' &&
                selectedBlockItems.length === 1 &&
                selectedBlockItems[0].item === block.item;

            // 3. current block item is selected or active, just display the outline
            const selected =
                selectedBlockItems.findIndex((b) => b.item === block.item) >= 0;

            if (!connectable && !resizable && !selected) {
                return null;
            }

            const definition = DashboardDefinition.fromJSON(
                dashboardApi?.getDefinition()
            );

            const itemPresetType = definition.getItemPresetType(block.item);

            const handleDirections =
                preset.getResizeHandleDirections(itemPresetType);

            return (
                <ResponsiveBlockOutline
                    key={block.item}
                    itemId={block.item}
                    x={block.position.x}
                    y={block.position.y}
                    w={block.position.w}
                    h={block.position.h}
                    scale={scale}
                    connectable={connectable}
                    resizable={resizable}
                    onResize={handleBlockItemResize}
                    onResized={handleBlockItemResized}
                    onLineConnect={handleLineConnected}
                    onLineDisconnect={handleLineDisconnected}
                    handleDirections={handleDirections}
                />
            );
        });
    }, [
        layoutStructureState,
        selectedItems,
        allBlockItems,
        mode,
        activeLine,
        dashboardApi,
        preset,
        scale,
        handleBlockItemResize,
        handleBlockItemResized,
        handleLineConnected,
        handleLineDisconnected,
    ]);

    // The following functions are used to capture click events in order to differentiate
    //  between gaining focus via tab or click.
    const handleMouseDownCapture = useCallback(() => {
        isMouseDownEventRef.current = true;
    }, []);
    const handleMouseUpCapture = useCallback(() => {
        isMouseDownEventRef.current = false;
    }, []);

    return (
        <AbsoluteCanvas
            data-test="absolute-layout"
            blockItems={blockItems}
            width={width}
            height={height}
            scale={scale}
            // can someone tell me why the original implementation had this magic number minus one?
            gridWidth={GRID_SIZE - 1}
            gridHeight={GRID_SIZE - 1}
            backgroundColor={backgroundColor}
            backgroundImageSrc={image.src}
            backgroundImageSizeType={image.sizeType}
            backgroundImageWidth={image.w}
            backgroundImageHeight={image.h}
            backgroundImagePositionX={image.x}
            backgroundImagePositionY={image.y}
            selectedLayoutItems={selectedItems}
            onItemSelected={handleItemSelected}
            ref={canvasRef}
            onMouseDownCapture={handleMouseDownCapture}
            onMouseUpCapture={handleMouseUpCapture}
            {...modeSpecificProps}
        >
            <Layer
                key="block-item-layer"
                data-test="block-item-layer"
                zIndex={0}
            >
                {/* render lines at the start of block-item-layer so they are correctly placed behind
                action menus, even if they share the z-index with the back-most action menu */}
                {lineElements}
                {blockItemElements}
            </Layer>
            <Layer key="outline-layer" data-test="outline-layer" zIndex={1}>
                {outlineElements}
            </Layer>
        </AbsoluteCanvas>
    );
};

export default withLayoutShowHide(AbsoluteLayout, {
    schema: absoluteLayoutOptionsSchema,
});
