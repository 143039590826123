import { Bounds, DataPoint } from './ICoordinateTransformation';
import { Interval } from './Scale';

export default class BoundsUtils {
    /**
     * checks if a line segment [dp1, dp2] is fully within a Bounds
     * @param {Bounds} bounds
     * @param {DataPoint} dp1
     * @param {DataPoint} dp2
     * @returns {boolean}
     */
    public static contains(bounds: Bounds, dp1: DataPoint, dp2: DataPoint): boolean {
        return BoundsUtils.withinInterval(bounds, dp1) && BoundsUtils.withinInterval(bounds, dp2);
    }

    /**
     * returns a turf BBox from Bounds.
     * @param {Bounds} bounds Interval keys must be 'lat' and 'long'
     * @returns {number[]} formatted in single array as [long.min, lat.min, long.max, lat.max]
     */
    public static toTurfBBox(bounds: Bounds): number[] {
        const { long, lat } = bounds;
        return [long.min, lat.min, long.max, lat.max];
    }

    /**
     * tells if the number is within the inclusive Interval
     * @param {Interval} interval
     * @param {number} v
     * @returns {boolean}
     */
    public static within(interval: Interval, v: number): boolean {
        return interval.min <= v && interval.max >= v;
    }

    /**
     * returns the distance from interval min to max
     * @param {Interval} interval
     * @returns {number}
     */
    public static distance(interval: Interval): number {
        return interval.max - interval.min;
    }

    /**
     * A very special-purposed utility method that creates CSS style from the Bounds.
     * The 2-dimensional rectangular bounds are converted top, left, width, height
     * @param {Bounds} must use 'x' and 'y' axis and assumes x and y are screen-oriented with zero,zero being the top left
     * @returns {object}
     */
    public static logicalBoundsToCssStyle(logicalBounds: Bounds): Record<string, unknown> {
        if (!logicalBounds) {
            return {};
        }
        const xInterval = logicalBounds.x;
        const yInterval = logicalBounds.y;
        // use GeoJsonFeatureGrpup f's Bounds to create a CSS inline style to position the inset using a div
        return {
            position: 'absolute',
            top: yInterval.min,
            left: xInterval.min,
            width: BoundsUtils.distance(xInterval),
            height: BoundsUtils.distance(yInterval),
            border: 'solid 1px',
        };
    }

    private static withinInterval(bounds: Bounds, dp: DataPoint): boolean {
        const axis = Object.keys(dp)[0];
        return BoundsUtils.within(bounds[axis], dp[axis]);
    }
}
