import { each, isNumber } from 'lodash';
import moment from '@splunk/moment';
import { isNumerial, parseNumber } from '@splunk/react-visualizations/utils/numberUtils';

const TIMESTAMP_FORMATS = {
    year: 'YYYY',
    month: 'YYYY-MM',
    day: 'YYYY-MM-DD',
    hour: 'YYYY-MM-DD hh:00 A',
    minute: 'YYYY-MM-DD hh:mm:00 A',
    second: 'YYYY-MM-DD hh:mm:ss A',
    millisecond: 'YYYY-MM-DD hh:mm:ss.SSS A',
};

const timeToMoment = time => {
    // try convert epoch time into number first
    const t = isNumerial(time) ? parseNumber(time) : time;
    // if splunk time zone is set, create time in splunk timezone and locale
    if (moment.getDefaultSplunkTimezone()) {
        return moment.newSplunkTime({ time: t });
    }
    // fallback to moment default
    return isNumber(t) ? moment.unix(t) : moment(t);
};

export const computeGranularity = (timeArray = [], defaultGranularity = 'second') => {
    let granularity = defaultGranularity;
    each(timeArray, time => {
        const t = timeToMoment(time);
        if (t.isValid()) {
            if (t.millisecond() > 0) {
                granularity = 'millisecond';
            } else if (t.second() > 0) {
                granularity = 'second';
            } else if (t.minute() > 0) {
                granularity = 'minute';
            } else if (t.hour() > 0) {
                granularity = 'hour';
            } else if (t.date() > 1) {
                granularity = 'day';
            } else if (t.month() > 1) {
                granularity = 'month';
            } else if (t.year() > 1) {
                granularity = 'year';
            }
        }
    });
    return granularity;
};

export const formatTime = (timeStr, granularity = 'millisecond') => {
    const time = timeToMoment(timeStr);
    if (time.isValid()) {
        return time.format(TIMESTAMP_FORMATS[granularity]);
    }
    return timeStr;
};
