import { isPlainObject } from 'lodash';
import filterDefinitionEdits from './filterDefinitionEdits';
import filterSearchMetrics from './filterSearchMetrics';
import filterDefinition from './filterDefinition';
import type { FilterFunction } from './FilterStrategyTypes';

/**
 * A collection of filter functions
 */
export type FilterStrategyCollection = Record<string, FilterFunction>;

// Placeholder function for just returning the input
const returnIt: FilterFunction = (value) => value;

const metaFilter: (value: unknown) => Record<string, unknown> = (metadata) =>
    metadata && isPlainObject(metadata)
        ? <Record<string, unknown>>metadata
        : {};

export const filterStrategies: FilterStrategyCollection = {
    definition: filterDefinition,
    definitionEdits: filterDefinitionEdits,
    searchMetrics: filterSearchMetrics,
    pageAction: returnIt,
    loadTime: returnIt,
    error: returnIt,
    source: returnIt,
    event: returnIt,
    metadata: metaFilter,
    undoActions: returnIt,
    redoActions: returnIt,
};
