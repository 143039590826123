/* eslint-disable class-methods-use-this,no-useless-constructor */
import { _ } from '@splunk/ui-utils/i18n';
import Provider from '@splunk/visualization-context/Provider';

/**
 * a local icon/image provider
 */
class MockedProvider extends Provider {
    constructor() {
        super();
        this.id = 0;
        this.items = {};
    }

    /**
     * return provider type
     */
    getType() {
        return 'mock';
    }

    upload(dataURI, metaData) {
        try {
            const obj = { dataURI, metaData };
            const { id, items } = this;

            this.items = { ...items, [id]: obj };
            this.id += 1;

            return id;
        } catch (error) {
            throw new Error(_('The obj cannot be uploaded.'));
        }
    }

    /**
     * get by id
     */
    getById(id) {
        if (this.items[id]) {
            return this.items[id];
        }
        throw new Error(_('The obj is not found.'));
    }

    /**
     * get all obj ids
     */
    listIds({ offset = 0, count = 100, meta = [], search = null }) {
        try {
            const icons = { ...this.items };
            const results = [];

            Object.keys(icons)
                .slice(offset, count)
                .forEach(id => {
                    const icon = { id };
                    const iconMeta = icons[id].metaData || {};
                    const isSearchedIcon =
                        search != null &&
                        Object.keys(iconMeta).some(attr => {
                            const iconMetaValue = iconMeta[attr].toLowerCase();
                            return iconMetaValue.indexOf(search.toLowerCase()) > -1;
                        });

                    if (search == null || isSearchedIcon) {
                        if (meta && meta.length) {
                            icon.metaData = {};
                            meta.forEach(attr => {
                                if (iconMeta[attr] != null) {
                                    icon.metaData[attr] = iconMeta[attr];
                                }
                            });
                        }

                        results.push(icon);
                    }
                });

            return results;
        } catch (error) {
            throw new Error(_('The ids cannot be listed.'));
        }
    }

    /**
     * delete by id
     */
    deleteById(id) {
        try {
            const obj = this.getById(id);
            delete this.items[id];
            return obj;
        } catch (error) {
            throw new Error(_('The obj cannot be deleted.'));
        }
    }

    clean() {
        this.id = 0;
        this.items = {};
    }
}

export default MockedProvider;
