import React from 'react';
import { _ } from '@splunk/ui-utils/i18n';
import SVG, {
    type SVGPropsOptionalViewBox,
} from '@splunk/react-icons/SVGEnterprise';

export default function ContentExport(
    props: SVGPropsOptionalViewBox
): ReturnType<typeof SVG> {
    /* eslint-disable max-len */
    return (
        <SVG
            viewBox="0 0 24 24"
            screenReaderText={_('Content Export')}
            {...props}
        >
            <path fill="currentColor" d="M19 20L19 18L5 18L5 20L19 20Z" />
            <path
                fill="currentColor"
                d="M15.3 11.5347L12.1683 14.4222L9 11.5347L10.1683 11.5347L10.1683 5.68161L14.1683 5.68161L14.1683 11.5347L15.3 11.5347ZM20 9.71297L16.1683 9.71297L16.1683 4L8.16828 4L8.16828 9.71297L4.33828 9.71297L12.1683 16.9999L20 9.71297Z"
            />
        </SVG>
    );
    /* eslint-enable max-len */
}
