import { COLOR_OR_TOKEN_PATTERN, getPattern } from '@splunk/visualizations-shared/schemaUtils';

export default {
    fontFamily: {
        default: 'Splunk Platform Sans',
        description:
            'Specify the font family to be used. For example "Comic Sans MS". Note: the font needs to be available to your end user.',
        type: 'string',
    },
    fontSize: { default: 24, description: 'Specify the font size in pixels.', type: 'number' },
    fontWeight: {
        default: 'normal',
        description: 'Specify the font weight.',
        pattern: getPattern(['normal', 'bold', 'light']),
        type: 'string',
    },
    lineHeight: {
        default: '120%',
        description: 'Specify the line height for the text block.',
        type: ['number', 'string'],
    },
    rotation: { default: 0, description: 'Specify the text angle in degrees.', type: 'number' },
    textColor: {
        description: `Specify the text color using Hex codes or RGBA values, such as "#FF0000" or "rgba(25,12,13,0.1)". The default for enterprise light mode is "#171D21". The default for enterprise dark mode is "#FFFFFF". The default for prisma dark mode is "rgba(255, 255, 255, 0.7)".`,
        type: 'string',
        pattern: COLOR_OR_TOKEN_PATTERN,
    },
    backgroundColor: {
        default: 'transparent',
        description: 'Specify the background color using a Hex code such as "#FF0000".',
        pattern: COLOR_OR_TOKEN_PATTERN,
        type: 'string',
    },
    content: { default: '', description: 'Specify the text content.', type: 'string' },
};
