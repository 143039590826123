import React from 'react';
import SVG, {
    type SVGPropsOptionalViewBox,
} from '@splunk/react-icons/SVGEnterprise';

/* eslint-disable max-len */
export default function Cloud(
    props: SVGPropsOptionalViewBox
): ReturnType<typeof SVG> {
    return (
        <SVG viewBox="0 0 62 41" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path d="m46.9511 14.2519c-.9666-6.75983-6.7818-11.95668-13.809-11.95668-5.0864 0-9.5383 2.7216-11.9769 6.79295l-.4393.73352-.8028-.29429c-.8304-.30438-1.7283-.47099-2.6676-.47099-4.2802 0-7.7501 3.46989-7.7501 7.75009 0 .6134.07105 1.2088.20493 1.7791l.22326.951-.93701.2763c-4.00673 1.1814-6.92939 4.8887-6.92939 9.2759 0 5.3401 4.32906 9.6692 9.66921 9.6692.1199 0 .2392-.0022.358-.0065l.0187-.0007h35.6072l.0166.0006c.1285.0041.2576.0062.3872.0062 6.5223 0 11.8097-5.2874 11.8097-11.8098 0-6.5223-5.2874-11.8097-11.8097-11.8097-.0461 0-.0922.0003-.1382.0008l-.9065.0104zm-13.809-14.023384c7.7662 0 14.24 5.526404 15.706 12.861484 7.3272.3766 13.1524 6.437 13.1524 13.8578 0 7.6638-6.2127 13.8765-13.8764 13.8765-.1461 0-.2917-.0023-.4367-.0068h-35.5372c-.1374.0048-.2753.0072-.4138.0072-6.48156 0-11.73591172-5.2544-11.73591172-11.7359 0-4.9994 3.12527172-9.2665 7.52685172-10.9583-.05848-.4333-.08864-.8754-.08864-1.324 0-5.4216 4.3952-9.8168 9.8168-9.8168.8908 0 1.7551.11891 2.5773.34208 2.8732-4.28194 7.7613-7.103264 13.3093-7.103264zm9.0188 18.748184c0 1.0639-.1786 2.086-.5075 3.0381h.6991c1.0986-.9894 2.5528-1.5915 4.1477-1.5915 3.4242 0 6.2001 2.7759 6.2001 6.2001s-2.7759 6.2001-6.2001 6.2001c-.0696 0-.1389-.0011-.208-.0034h-30.5643l.0009-.0071c-.1105.007-.2219.0106-.3341.0106-2.8535 0-5.1668-2.3132-5.1668-5.1668 0-2.8535 2.3133-5.1667 5.1668-5.1667.3768 0 .7441.0403 1.098.1169-.5439-.7613-.8639-1.6934-.8639-2.7003 0-2.5682 2.0819-4.6501 4.6501-4.6501 1.4073 0 2.6687.6252 3.5214 1.6131.9546-4.1214 4.6488-7.19318 9.0604-7.19318 5.1364 0 9.3002 4.16388 9.3002 9.30018z" />
        </SVG>
    );
}
