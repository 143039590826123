import React, { useCallback, useMemo, useState } from 'react';
import type { ReactElement } from 'react';
import { useLayoutLayers } from '@splunk/dashboard-context';
import type {
    AbsoluteBlockItem,
    StructureItemType,
} from '@splunk/dashboard-types';
import ResponsiveBox from './ResponsiveBox';
import { ErrorMessageOverlay } from './ErrorMessageOverlay';
import type { RenderLayoutItem, OnItemSelected } from '../types';
import ActionMenuPortal from './ActionMenuPortal';
import { applyVizPadding } from '../utils/edgeUtils';

export interface ResponsiveBlockItemProps {
    x: number;
    y: number;
    w: number;
    h: number;
    canvasHeight: number;
    padding?: number;
    itemId: string;
    type?: StructureItemType;
    renderLayoutItem: RenderLayoutItem;
    onItemSelected: OnItemSelected;
    appearance?: 'hidden' | 'visible' | 'highlighted';
    errorMessages?: string[];
    isMouseDownEventRef?: React.MutableRefObject<boolean>;
}

type ActionMenuPortalState = React.MutableRefObject<HTMLDivElement | null>;
const defaultActionMenuPortalState: ActionMenuPortalState = Object.freeze({
    current: null,
});

/**
 * layout item that renders block element
 */
const ResponsiveBlockItem = (props: ResponsiveBlockItemProps): ReactElement => {
    const {
        x: originalX,
        y: originalY,
        w: originalW,
        h: originalH,
        canvasHeight,
        padding,
        itemId,
        type = 'block',
        errorMessages,
        onItemSelected,
        renderLayoutItem,
        appearance = 'highlighted',
        isMouseDownEventRef,
    } = props;
    // don't use a ref because on first render this needs to force a
    // second render when the element changes and the menu can be mounted
    const [actionMenuPortal, setActionMenuPortal] =
        useState<ActionMenuPortalState>(defaultActionMenuPortalState);

    const layerData = useLayoutLayers();
    const itemLayer = layerData?.[itemId]?.layer;
    const actionMenuLayer = layerData?.[itemId]?.actionMenu;

    // Only render action menu in ReactDOM portal when there's z-index layering
    const renderActionMenuInPortal = typeof itemLayer === 'number';

    const {
        position: { x, y, w, h },
    } = applyVizPadding({
        item: {
            position: {
                x: originalX,
                y: originalY,
                w: originalW,
                h: originalH,
            },
        } as AbsoluteBlockItem,
        padding,
    });

    const layoutItem = useMemo(
        () =>
            renderLayoutItem(
                itemId,
                {
                    width: w,
                    height: h,
                    y,
                    canvasHeight,
                    renderActionMenuInPortal,
                    actionMenuPortal,
                },
                type,
                onItemSelected
            ),
        [
            renderLayoutItem,
            itemId,
            type,
            w,
            h,
            y,
            canvasHeight,
            onItemSelected,
            renderActionMenuInPortal,
            actionMenuPortal,
        ]
    );

    // FocusEvent does not have any fields that indicate exactly what caused focus to occur, e.g. key, click, it must be inferred
    const handleFocus = useCallback<React.FocusEventHandler>(
        (event) => {
            // relatedTarget is set to the previously focused element when tabbing and is null when clicking.
            // This test may result in the first tab press failing to change focus if there is no relatedTarget for it to add. A second tab press fixes it.
            if (!event.relatedTarget || isMouseDownEventRef?.current) {
                // The click handler will handle selection instead (but maybe it doesn't need to?)
                return;
            }
            if (
                // The tabindex was moved to the SelectableContainer (in renderLayoutItem), so we'll check that thats the target
                event.target.getAttribute('data-test') === 'select-outline' &&
                event.target.getAttribute('data-id') === itemId
            ) {
                onItemSelected(event, [{ id: itemId, type }]);
            }
        },
        [isMouseDownEventRef, itemId, onItemSelected, type]
    );

    const trackActionMenuPortal = useCallback((element: HTMLDivElement) => {
        setActionMenuPortal({ current: element });
    }, []);

    // If a z-index exists in the layer context then render the
    // action menu in a portal to allow proper stacking contexts
    const ActionMenuContainer = useMemo(
        () =>
            renderActionMenuInPortal ? (
                <ActionMenuPortal
                    x={x}
                    y={y}
                    w={w}
                    h={h}
                    ref={trackActionMenuPortal}
                    zIndex={actionMenuLayer}
                />
            ) : null,
        [
            actionMenuLayer,
            h,
            renderActionMenuInPortal,
            w,
            x,
            y,
            trackActionMenuPortal,
        ]
    );

    const BlockItem = useMemo(
        () => (
            <ResponsiveBox
                data-test="absolute-item"
                itemId={itemId}
                appearance={appearance}
                x={x}
                y={y}
                w={w}
                h={h}
                onFocusCapture={handleFocus}
            >
                {layoutItem}
                <ErrorMessageOverlay messages={errorMessages} />
            </ResponsiveBox>
        ),
        [itemId, appearance, x, y, w, h, handleFocus, layoutItem, errorMessages]
    );

    return (
        <>
            {BlockItem}
            {ActionMenuContainer}
        </>
    );
};

export default React.memo(ResponsiveBlockItem);
