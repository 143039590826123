import * as React from 'react';
import { _ } from '@splunk/ui-utils/i18n';
import SVG from '@splunk/react-icons/SVGEnterprise';

const Sankey = (props: React.ComponentProps<typeof SVG>): React.ReactElement<SVGElement> => (
    <SVG screenReaderText={_('Sankey')} viewBox="0 0 24 24" {...props}>
        <path
            d="M11.278 8.29599C13.4714 5.3705 15.9985 2 21 2C21.5523 2 22 2.44772 22 3V12C22 12.5523 21.5523 13 21 13C19.2142 13 17.8255 13.3351 16.6433 13.8765C15.1641 13.0082 13.9957 11.7226 12.7433 10.331L12.6941 10.2764C12.1812 9.70642 11.6533 9.11976 11.0843 8.55419C11.1228 8.50294 11.1613 8.45154 11.2 8.4L11.278 8.29599Z"
            fill="currentColor"
        />
        <path
            d="M2 16V20C2 20.5523 2.44772 21 3 21C6.40592 21 8.64914 19.9206 10.4654 18.5825C9.6609 17.98 8.85617 17.4364 7.92086 16.9999C6.65179 16.4077 5.10923 16 3 16H2Z"
            fill="currentColor"
        />
        <path
            d="M3 7C2.44772 7 2 7.44772 2 8V13C2 13.5523 2.44772 14 3 14C5.10923 14 6.65179 14.4077 7.92086 14.9999C9.21024 15.6016 10.2515 16.4069 11.3861 17.2894L11.4179 17.3141C12.5245 18.1748 13.7247 19.1084 15.2334 19.8124C16.7768 20.5327 18.6092 21 21 21C21.5523 21 22 20.5523 22 20V18C22 17.4477 21.5523 17 21 17C16.9823 17 14.9991 14.8375 12.7433 12.331L12.6941 12.2764C10.4623 9.79625 7.94594 7 3 7Z"
            fill="currentColor"
        />
    </SVG>
);

export default Sankey;
