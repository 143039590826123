import React from 'react';
import T from 'prop-types';
import styled from 'styled-components';
import { getIcon } from './utils/iconUtils';

const StyledIconContainer = styled.div.attrs(() => ({
    'data-test': 'single-value-icon-container',
}))`
    margin: 0 3%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-size: ${props => props.fontSize}px;
    height: ${props => props.height}px;
    color: ${props => props.color};
    opacity: ${props => props.opacity || 1.0};
    cursor: ${props => (props.onClick ? 'pointer' : 'inherit')};
`;

const IconContainer = ({ width, height, fontSize, color, opacity, icon, IconComponent, onIconClick }) => {
    const onClick = typeof onIconClick === 'function' ? onIconClick : undefined;
    if (IconComponent) {
        return (
            <StyledIconContainer width={width} height={height} onClick={onClick}>
                {IconComponent}
            </StyledIconContainer>
        );
    }

    // todo: remove it after we have proper icon provider
    const Icon = getIcon(icon);
    return (
        <StyledIconContainer
            width={width}
            height={height}
            fontSize={fontSize}
            color={color}
            opacity={opacity}
            onClick={onClick}
        >
            <Icon />
        </StyledIconContainer>
    );
};

IconContainer.propTypes = {
    width: T.number.isRequired,
    height: T.number.isRequired,
    fontSize: T.number.isRequired,
    icon: T.string.isRequired,
    IconComponent: T.node,
    color: T.string,
    opacity: T.number,
    onIconClick: T.func,
};

export default IconContainer;
