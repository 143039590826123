import { COLOR_OR_TOKEN_PATTERN } from '@splunk/visualizations-shared/schemaUtils';

export default {
    stroke: {
        description: `Specify the color of the stroke using a Hex code such as "#FF0000". The default for enterprise light mode is "#3C444D". The default for enterprise dark mode is "#C3CBD4". The default for prisma dark mode is "#ACACAD".`,
        pattern: COLOR_OR_TOKEN_PATTERN,
        type: 'string',
    },
    strokeOpacity: {
        default: 1,
        description:
            'Specify the opacity of the stroke. Choose a number in the range of 0 - 1 (inclusive). You can also express the value as a percentage. For example, "0.80" in source or "80%" in UI.',
        type: 'number',
    },
    strokeWidth: {
        default: 1,
        description: 'Specify the width of the stroke, in pixels, in the range of 1 - 25 (inclusive).',
        type: 'number',
    },
    fill: {
        description: `Specify the color fill using a Hex code such as "#FF0000". The default for enterprise light mode is "#C3CBD4". The default for enterprise dark mode is "#31373E". The default for prisma dark mode is "#0B0C0E".`,
        pattern: COLOR_OR_TOKEN_PATTERN,
        type: 'string',
    },
    fillOpacity: {
        default: 1,
        description:
            'Specify the opacity of the fill. Choose a number in the range of 0 - 1 (inclusive). You can also express the value as a percentage. For example, "0.80" in source or "80%" in UI.',
        type: 'number',
    },
    strokeDasharray: {
        default: 0,
        description:
            'Specify the size, in pixels, of dashes and spaces used to create a custom, stitched outline. The value you specify applies to both the dashes and the spaces.',
        type: 'number',
    },
};
