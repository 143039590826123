import { createAction, createReducer } from '@reduxjs/toolkit';
import type { GlobalState } from '@splunk/dashboard-types';
import resetStore from './resetStore';

type GlobalMessage = GlobalState['message'];

export const setGlobalMessage =
    createAction<GlobalMessage>('globalMessage/set');

const initialState: GlobalMessage = null;

export const selectGlobalMessage = (state: GlobalState): GlobalMessage =>
    state.message;

// Create a state slice, ideally we'd use createSlice here, but the CaseReducer type it uses doesn't deal well with returning primitives
const reducer = createReducer<GlobalMessage>(initialState, (builder) => {
    builder
        .addCase(setGlobalMessage, (state, action) => {
            return action.payload;
        })
        .addCase(resetStore, (state, action) => {
            const { message } = action.payload;
            return message || state;
        });
});

export default reducer;
