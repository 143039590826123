import { chain } from 'lodash';
// Charting imports i18n which needs to be run to instantiate window globals in the
// eslint-disable-next-line import/order
import Charting, { ChartingProps } from '../common/Charting';
import jsCharting from '@splunk/charting-bundle';
import { getSimpleXMLOptionsForProps } from '@splunk/visualizations-shared/simpleXMLOptions';
import config from './config';
import withDashboardViz from '../common/withDashboardViz';
import { allInvalidDataValues, convertToPieSCProperties, invalidOptionField } from './pieUtils';
import { truncateData } from '../common/chartingUtils';
import { DataSource } from '../common/interfaces/DataSource';
import { shouldShowIconPlaceholder } from '../common/components/IconPlaceholder';

const validOptions = Object.keys(config.optionsSchema);
export const PieInnerSize = 0.7;

type computeVizPropsFn = (options: Record<string, any>) => Partial<ChartingProps>;
export const computeVizProps: computeVizPropsFn = ({
    dataSources,
    onEventTrigger,
    label,
    value,
    showDonutHole,
    visualizationApiRef,
    ...otherOptions
}): Partial<ChartingProps> => {
    // options to be passed as props
    const computedProps: Record<string, any> = { visualizationApiRef };
    const { labelField, valueField, hasEventHandlers } = otherOptions;
    computedProps.options = chain(otherOptions)
        .pick(validOptions)
        .thru(convertToPieSCProperties)
        .thru(options => ({ ...getSimpleXMLOptionsForProps(options), chart: 'pie' }))
        .value();

    const truncationLimit = computedProps.options['chart.resultTruncationLimit'];

    let primaryData = {
        ...dataSources.primary.data,
        columns: [label, value],
    };
    primaryData.fields = [{ name: labelField }, { name: valueField }];
    if (!value) {
        primaryData.columns = [label, []];
    }
    primaryData.columns.forEach((column, index) => {
        if (column) {
            primaryData.columns[index] = column.map(String);
        }
    });

    if (truncationLimit) {
        primaryData = truncateData(primaryData, truncationLimit);
    }

    computedProps.chartData = jsCharting.extractChartReadyData({
        ...primaryData,
    });

    if (showDonutHole) {
        computedProps.options['chart.pieInnerSize'] = PieInnerSize;
    } else {
        computedProps.options['chart.pieInnerSize'] = 0;
    }

    if (hasEventHandlers) {
        computedProps.onClick = onEventTrigger;
    }

    return computedProps;
};

// Returns true to display the placeholder icon, if invalid datasource condition or invalid data value/labels condition is met
export const showPlaceHolderIcon = (dataSources, loading, options) =>
    shouldShowIconPlaceholder(dataSources, loading) ||
    invalidOptionField('value', dataSources, options, config) ||
    invalidOptionField('label', dataSources, options, config) ||
    allInvalidDataValues(dataSources, options, config);

const Pie = withDashboardViz({
    ReactViz: Charting,
    computeVizProps,
    useIconPlaceholder: (
        dataSources: { [name: string]: DataSource },
        loading: boolean,
        options: Record<string, unknown>
    ) => showPlaceHolderIcon(dataSources, loading, options),
    vizConfig: config,
});
const themes = null;

export { themes, config };
export default Pie;
