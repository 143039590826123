// this is a copy of RadialLayout.jsx @ 23.5.0 before the design changes to PureSingleValueRadial were rolled out
import React from 'react';
import T from 'prop-types';
import styled from 'styled-components';
import SingleValueBasic, { SingleValueTrendValue } from '@splunk/visualizations/SingleValueBasic';
import { getTrendFontSize } from '@splunk/visualizations/common/utils/singleValueUtils';
import RadialGraph from './RadialGraph';
import {
    showTrendPositionStandard,
    showTrendPositionBelow,
    showTrendPositionOutside,
} from '../utils/displayUtils';

const PositionDiv = styled.div`
    position: absolute;
    ${props => (props.bottom ? '' : 'top: 0')};
    bottom: ${props => props.bottom || 0}px;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: ${props => props.align || 'center'};
    justify-content: center;
`;

const Column = styled.div`
    display: flex;
    flex-direction: column;
    align-items: ${props => props.align || 'center'};
    justify-content: ${props => props.align || 'center'};
`;
const Row = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
`;

const Square = styled.div`
    position: relative;
    margin: 0 auto;
    width: ${props => props.size}px;
    height: ${props => props.size}px;
`;

const UnderLabelContainer = styled.div`
    color: ${props => props.color};
    margin-top: ${props => (props.size && `-${props.size * 0.1}px`) || 0};
    font-size: ${props => props.size || 12}px;
`;

/**
 * Render the trend indicator
 * @method renderTrendValue
 * @returns {Object}
 * @private
 */

const renderIsolateTrend = ({ trendText, trendColor, contentSize }) => {
    const trendTextFontSize = getTrendFontSize({
        width: contentSize / 2,
        height: contentSize / 4,
        trendText,
        spaceRatio: 1,
    });

    return (
        <SingleValueTrendValue
            fontSize={trendTextFontSize}
            color={trendColor}
            value={trendText}
            text={trendText}
        />
    );
};

renderIsolateTrend.propTypes = {
    trendText: T.string,
    trendColor: T.string,
    contentSize: T.number,
};

const RadialContent = ({ ...props }) => {
    const {
        majorColor,
        majorText,
        majorTextFontSize,
        majorValueTitle,
        trendColor,
        trendText,
        trendTextFontSize,
        showTrendIndicator,
        trendPosition,
        unit,
        unitPosition,
        underLabelColor,
        underLabelFontSize,
        underLabel,
        onValueClick,
        contentSize,
        showRadialGraph,
        graphSize,
        progress,
        radialBackgroundColor,
        radialStrokeColor,
        radialStrokeWidth,
    } = props;

    // for trend position below and outside
    const trendComponent = renderIsolateTrend({ trendText, trendColor, contentSize });

    const basicSingleValues = (
        <SingleValueBasic
            majorColor={majorColor}
            majorText={majorText}
            majorTextFontSize={majorTextFontSize}
            majorValueTitle={majorValueTitle}
            trendColor={trendColor}
            trendText={trendText}
            trendTextFontSize={trendTextFontSize}
            showTrendIndicator={showTrendPositionStandard({ showTrendIndicator, position: trendPosition })}
            unit={unit}
            unitPosition={unitPosition}
            onValueClick={onValueClick}
        />
    );
    const radialGraph = showRadialGraph ? (
        <RadialGraph
            size={graphSize}
            progress={progress}
            backgroundColor={radialBackgroundColor}
            progressColor={radialStrokeColor}
            strokeWidth={radialStrokeWidth}
        />
    ) : null;

    const underLabelComponent = underLabel ? (
        <UnderLabelContainer data-test="underLabel" color={underLabelColor} size={underLabelFontSize}>
            {underLabel}
        </UnderLabelContainer>
    ) : null;

    const outsideComponent = showTrendPositionOutside({ showTrendIndicator, position: trendPosition }) ? (
        <Column align="end" data-test="trend-position-outside">
            {trendComponent}
            {underLabelComponent}
        </Column>
    ) : null;

    const belowTrendComponent = showTrendPositionBelow({ showTrendIndicator, position: trendPosition }) ? (
        <PositionDiv
            data-test="trend-position-below"
            align="flex-end"
            bottom={graphSize * 0.1}
            style={{ textAlign: 'center' }}
        >
            {trendComponent}
        </PositionDiv>
    ) : null;

    return (
        <Row>
            <Column>
                <Square size={graphSize}>
                    {radialGraph}
                    {belowTrendComponent}
                    <PositionDiv>{basicSingleValues}</PositionDiv>
                </Square>
                {trendPosition !== 'outside' && underLabelComponent}
            </Column>
            {outsideComponent}
        </Row>
    );
};

RadialContent.propTypes = {
    ...SingleValueBasic.propTypes,
    trendPosition: T.string,
    contentSize: T.number,
    showRadialGraph: T.bool,
    graphSize: T.number,
    progress: T.number,
    radialBackgroundColor: T.string,
    radialStrokeColor: T.string,
    radialStrokeWidth: T.number,
};

export default RadialContent;
