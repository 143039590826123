import React from 'react';
import { _ } from '@splunk/ui-utils/i18n';
import SVG, {
    type SVGPropsOptionalViewBox,
} from '@splunk/react-icons/SVGEnterprise';

/* eslint-disable max-len */
export default function AlignLeft(
    props: SVGPropsOptionalViewBox
): ReturnType<typeof SVG> {
    return (
        <SVG viewBox="0 0 24 24" screenReaderText={_('Align Left')} {...props}>
            <path
                fill="currentColor"
                d="M1 23.5V0.5C1 0.223858 0.776142 0 0.5 0C0.223858 0 0 0.223857 0 0.5V23.5C0 23.7761 0.223858 24 0.5 24C0.776142 24 1 23.7761 1 23.5Z"
            />
            <path
                fill="currentColor"
                d="M15.5 4H3.5C3.22386 4 3 4.22386 3 4.5V10.5C3 10.7761 3.22386 11 3.5 11H15.5C15.7761 11 16 10.7761 16 10.5V4.5C16 4.22386 15.7761 4 15.5 4Z"
            />
            <path
                fill="currentColor"
                d="M23.5 13H3.5C3.22386 13 3 13.2239 3 13.5V19.5C3 19.7761 3.22386 20 3.5 20H23.5C23.7761 20 24 19.7761 24 19.5V13.5C24 13.2239 23.7761 13 23.5 13Z"
            />
        </SVG>
    );
}
