import { _ } from '@splunk/ui-utils/i18n';
import {
    Area,
    Bar,
    Bubble,
    ChoroplethSvg,
    Column,
    Image,
    Line,
    Punchcard,
    Scatter,
    SingleValueRadial,
    Table,
    SingleValue,
} from '@splunk/visualization-icons';
import ChartPie from '@splunk/react-icons/enterprise/ChartPie';
import SingleValueIcon from '@splunk/react-icons/enterprise/SingleValue';
import Ellipse from '@splunk/react-icons/enterprise/Circle';
import Rectangle from '@splunk/react-icons/enterprise/BoxUnchecked';
import {
    FillerGauge,
    MarkerGauge,
    RadialGauge,
    USMap,
    WorldMap,
} from '@splunk/dashboard-icons';
import { vizCategories as categories } from '@splunk/dashboard-context';
import { geoJsonOptions } from './initialGeoJsonOptions';

const vizMap = {
    'viz.table': {
        label: _('Table (deprecated)'),
        icon: Table,
        category: categories.get('table'),
        deprecated: true,
    },
    'viz.pie': {
        label: _('Pie (deprecated)'),
        icon: ChartPie,
        category: categories.get('comparisons'),
        deprecated: true,
    },
    'viz.punchcard': {
        label: _('Punchcard (deprecated)'),
        icon: Punchcard,
        category: categories.get('distributions'),
        deprecated: true,
    },
    'viz.area': {
        label: _('Area (deprecated)'),
        icon: Area,
        category: categories.get('trends'),
        deprecated: true,
    },
    'viz.bar': {
        label: _('Bar (deprecated)'),
        icon: Bar,
        category: categories.get('comparisons'),
        deprecated: true,
    },
    'viz.bubble': {
        label: _('Bubble (deprecated)'),
        icon: Bubble,
        category: categories.get('distributions'),
        deprecated: true,
    },
    'viz.column': {
        label: _('Column (deprecated)'),
        icon: Column,
        category: categories.get('comparisons'),
        deprecated: true,
    },
    'viz.fillergauge': {
        label: _('Filler Gauge (deprecated)'),
        icon: FillerGauge,
        category: categories.get('gauge'),
        deprecated: true,
    },
    'viz.line': {
        label: _('Line (deprecated)'),
        icon: Line,
        category: categories.get('trends'),
        deprecated: true,
    },
    'viz.markergauge': {
        label: _('Marker Gauge (deprecated)'),
        icon: MarkerGauge,
        category: categories.get('gauge'),
        deprecated: true,
    },
    'viz.scatter': {
        label: _('Scatter (deprecated)'),
        icon: Scatter,
        category: categories.get('distributions'),
        deprecated: true,
    },
    'viz.radialgauge': {
        label: _('Radial Gauge'),
        icon: RadialGauge,
        category: categories.get('gauge'),
    },
    'viz.singlevalue': {
        label: _('Single Value (deprecated)'),
        icon: SingleValue,
        category: categories.get('singleValue'),
        deprecated: true,
    },
    'viz.singlevalueicon': {
        label: _('Single Value Icon (deprecated)'),
        icon: SingleValueIcon,
        category: categories.get('singleValue'),
        deprecated: true,
    },
    'viz.singlevalueradial': {
        label: _('Single Value Radial (deprecated)'),
        icon: SingleValueRadial,
        category: categories.get('singleValue'),
        deprecated: true,
    },
    'viz.markdown': {
        label: _('Markdown (deprecated)'),
        category: categories.get('text'),
        deprecated: true,
    },
    'viz.img': {
        label: _('Image (deprecated)'),
        icon: Image,
        category: categories.get('image'),
        deprecated: true,
    },
    'viz.ellipse': {
        label: _('Ellipse (deprecated)'),
        icon: Ellipse,
        category: categories.get('shapes'),
        deprecated: true,
    },
    'viz.rectangle': {
        label: _('Rectangle (deprecated)'),
        icon: Rectangle,
        category: categories.get('shapes'),
        deprecated: true,
    },
    'viz.text': {
        label: _('Text (deprecated)'),
        category: categories.get('text'),
        deprecated: true,
    },
    'viz.geojson.us': {
        label: _('Choropleth USA (deprecated)'),
        icon: USMap,
        category: categories.get('choropleth'),
        initialOptions: geoJsonOptions.us,
        deprecated: true,
    },
    'viz.geojson.world': {
        label: _('Choropleth World (deprecated)'),
        icon: WorldMap,
        category: categories.get('choropleth'),
        initialOptions: geoJsonOptions.world,
        deprecated: true,
    },
    'viz.choropleth.svg': {
        label: _('SVG (deprecated)'),
        icon: ChoroplethSvg,
        category: categories.get('choropleth'),
        initialOptions: {
            svg: '',
        },
        deprecated: true,
    },
};

/**
 * Add a `meta` property to legacy viz containing information such as key, name, and icon
 * @param {Visualization} Viz The visualization to enhance
 */
export default (Viz, key) => {
    if (key) {
        Viz.meta = { key, ...(vizMap[key] ?? {}) };
    }
    return Viz;
};
