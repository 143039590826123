import type { DataSourceDefinition } from '@splunk/dashboard-types';
import hash from 'hash-it';
import { omit } from 'lodash';

const keys = ['name', 'options.enableSmartSources'];
const stableEmptyObject = Object.freeze({});
/**
 * Scrubs the definition of properties that do not impact the running of the search
 * @param {DataSourceDefinition} [def]
 * @returns {Partial<DataSourceDefinition>} The scrubbed definition
 */
export const computeStableDSDefinition = (
    def?: DataSourceDefinition
): Partial<DataSourceDefinition> => (def ? omit(def, keys) : stableEmptyObject);

/**
 * Creates a hash based on the provided definition (scrubs non-important data)
 * @param {DataSourceDefinition} [def]
 * @returns {number} A stable hash representing the definition
 */
export const computeDSHash = (def?: DataSourceDefinition) =>
    hash(computeStableDSDefinition(def));
