import React, { useState, useMemo, useCallback } from 'react';
import ComboBox from '@splunk/react-ui/ComboBox';

const identity = ({ currOptions }: { currOptions: ComboBoxOptions }) =>
    currOptions;

export type ComboBoxOptions = { label?: string; value: string }[];

export type GenerateComboBoxOptionsFunc = ({
    currValue,
    currOptions,
}: {
    currValue: string;
    currOptions: ComboBoxOptions;
}) => ComboBoxOptions;

interface ComboBoxEditorProps {
    /**
     * Value of the combo box.
     */
    value?: string;
    /**
     * List of label-value pairs to list as options for the combo box. Labels are optional.
     */
    options?: ComboBoxOptions;
    /**
     * Placeholder to display when the combo box is empty.
     */
    placeholder?: string;
    /**
     * Callback invoked when the value of the combo box changes.
     */
    onChange: (evt: React.SyntheticEvent, updatedValue: string) => void;
    /**
     * Optional function that generates options for the combo box.
     */
    generateOptions?: GenerateComboBoxOptionsFunc;
    /**
     * Show error state
     */
    error?: boolean;
    /**
     * footer message
     */
    footerMessage?: React.ReactNode;
}

const ComboBoxEditor = ({
    value: initialValue = '',
    options: initialOptions = [],
    placeholder,
    onChange,
    generateOptions = identity,
    error,
    footerMessage,
}: ComboBoxEditorProps): JSX.Element => {
    const [currValue, setValue] = useState(initialValue);

    const safeHandleChange = useCallback(
        (evt, updatedValue) => {
            if (updatedValue !== initialValue) {
                onChange(evt, updatedValue.trim());
            }
            setValue(updatedValue);
        },
        [onChange, initialValue]
    );

    const handleInputKeyPress = useCallback(
        (evt) => {
            if (evt.key === 'Enter') {
                safeHandleChange(evt, currValue);
            }
        },
        [safeHandleChange, currValue]
    );

    const handleInputBlur = useCallback(
        (evt) => {
            safeHandleChange(evt, currValue);
        },
        [safeHandleChange, currValue]
    );

    const handleInputChange = useCallback(
        (evt, { value }) => {
            if (!value) {
                safeHandleChange(evt, value);
            }
            setValue(value);
        },
        [safeHandleChange]
    );

    const options = useMemo(
        () =>
            generateOptions({
                currValue,
                currOptions: initialOptions,
            }).map(({ value: v, ...others }) => (
                <ComboBox.Option key={v} value={v} {...others} />
            )),
        [currValue, initialOptions, generateOptions]
    );

    return (
        <ComboBox
            value={currValue}
            onChange={handleInputChange}
            onKeyPress={handleInputKeyPress}
            onBlur={handleInputBlur}
            placeholder={placeholder}
            error={error}
            footerMessage={footerMessage}
        >
            {options}
        </ComboBox>
    );
};

export default ComboBoxEditor;
