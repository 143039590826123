import React, { Component } from 'react';
import { _ } from '@splunk/ui-utils/i18n';

export interface ErrorBoundaryProps {
    /** Allow nodes to be added as child to the error boundary */
    children: React.ReactNode | React.ReactNode[];
    /** Allows passing in a custom error message */
    errorMessage?: string;
    /** Render function (render props pattern) to allow Custom UI rendered on error */
    render?: (errorMessage: string) => JSX.Element;
    /** In case of error , use the message from "error.message" if set to true */
    useMessageFromError?: boolean;
}

interface ErrorBoundaryState {
    hasError: boolean;
    errorMessage?: string;
}

/**
 * Generic ErrorBoundary class that could be used to wrap any
 * component. In case the wrapped component or any of its children
 * throw an error, ErrorBoundary would catch it. It also exposes
 * render function ( render props ) that allows or custom UI to
 * be rendered in case of error.
 */
class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
    constructor(props: ErrorBoundaryProps) {
        super(props);
        this.state = {
            hasError: false,
            errorMessage: undefined,
        };
    }

    static getDerivedStateFromError(error: Error) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true, errorMessage: error.message };
    }

    render() {
        const {
            children,
            errorMessage: customErrorMessage = _(
                'failed to render react component'
            ),
            useMessageFromError = true,
            render: customRender = (errorMessage) => <div>{errorMessage}</div>,
        } = this.props;

        const { hasError, errorMessage } = this.state;

        if (hasError) {
            return customRender(
                useMessageFromError && errorMessage
                    ? errorMessage
                    : customErrorMessage
            );
        }
        return children;
    }
}

export default ErrorBoundary;
