import { Column as ColumnIcon } from '@splunk/visualization-icons';
import { Column as ColumnPlaceholderIcon } from '@splunk/visualization-icons/placeholders';
import { COLOR_OR_TOKEN_PATTERN_WITH_RGBA, getPattern } from '@splunk/visualizations-shared/schemaUtils';
import { _ } from '@splunk/ui-utils/i18n';
import pick from '@splunk/themes/pick';
import variables from '@splunk/themes/variables';
import { VIZ_CATEGORICAL } from '@splunk/visualization-color-palettes';
import { cloneDeep } from 'lodash';
import { DataContract } from '../common/interfaces/DataContract';
import { DefaultContext } from '../common/interfaces/DefaultContext';
import { EditorConfig } from '../common/interfaces/Editor';
import { OptionsSchema } from '../common/interfaces/OptionsSchema';
import { ThemedDefaults } from '../common/interfaces/ThemedDefaults';
import { VizBehavior } from '../common/interfaces/VizBehavior';
import { VizCategory } from '../common/interfaces/VizCategory';
import { VizConfig } from '../common/interfaces/VizConfig';
import { VizSize } from '../common/interfaces/VizSize';
import { enhanceConfig } from '../common/utils/configUtils';
import { AxesChartDataConfigAreaColumnLine } from '../common/editorConfig/AxesChartDataConfig';
import { AxesChartDataDisplayColumn } from '../common/editorConfig/AxesChartDataDisplay';
import AxesChartColorAndStyle from '../common/editorConfig/AxesChartColorAndStyle';
import AxesChartLegend from '../common/editorConfig/AxesChartLegend';
import AxesChartXGridAndLabels from '../common/editorConfig/AxesChartXGridAndLabels';
import AxesChartYGridAndLabels from '../common/editorConfig/AxesChartYGridAndLabels';
import AxesChartY2GridAndLabels from '../common/editorConfig/AxesChartY2GridAndLabels';

const dataContract: DataContract = {
    requiredDataSources: [
        {
            name: 'primary',
            description: 'DataSource that powers the visualization',
        },
    ],
    optionalDataSources: [
        {
            name: 'annotation',
            description: 'DataSource that populates event annotations',
        },
    ],
    initialRequestParams: {
        primary: { offset: 0, count: 10000 },
        annotation: { offset: 0, count: 10000 },
    },
};

const size: VizSize = {
    initialWidth: 300,
    initialHeight: 300,
};

const defaultContext: DefaultContext = {};

export const optionsSchema: OptionsSchema = {
    x: {
        default: '> primary | seriesByIndex(0)',
        description: 'Specify a data source to apply to the x-axis.',
        type: 'array',
        items: { type: ['string', 'number'] },
    },
    y: {
        default: '> primary | frameBySeriesIndexRange(1)',
        description: 'Specify a data source to apply to the y-axis.',
        type: 'array',
        items: { type: ['string', 'number'] },
    },
    y2: {
        description: 'Specify a data source to apply to the second y-axis',
        type: 'array',
        items: { type: ['string', 'number'] },
    },
    xField: {
        default: '> x | getField()',
        description: 'Specify a field to map to the x-axis.',
        type: 'string',
    },
    yFields: {
        default: '> y | getField()',
        description: 'Specify a field to map to the y-axis.',
        type: 'string',
    },
    y2Fields: {
        default: '> y2 | getField()',
        description: 'Specify one or more fields to map to a second y-axis.',
        type: ['array', 'string'],
        items: {
            type: 'string',
        },
    },
    annotationColor: {
        description: `Specify the annotation colors. For example, [“#FF0000”, “#0000FF", “#008000”]. You can use a data source or hexadecimal code to apply the color.`,
        type: 'array',
        items: { type: 'string' },
    },
    annotationLabel: {
        description: `Specify a list of labels to use for annotations. For example, [“Sales”, “Revenue”, “Orders”].You can use a data source to apply the labels. `,
        type: 'array',
        items: { type: 'string' },
    },
    annotationX: {
        description: 'Specify a data source field to apply to the event annotation on the x-axis.',
        type: 'array',
        items: { type: ['string', 'number'] },
    },
    backgroundColor: {
        default: '> themes.defaultBackgroundColor',
        description:
            'Specify the color to use for the background. You can use a data source or a hexadecimal code to apply the color.',
        pattern: COLOR_OR_TOKEN_PATTERN_WITH_RGBA,
        type: 'string',
    },
    dataValuesDisplay: {
        description:
            'Specify the labels to display. Enter "all" to show labels for all data points, "off" to show no labels, or "minmax" to show high and low values.',
        type: 'string',
        pattern: getPattern(['off', 'all', 'minmax']),
        default: 'off',
    },
    columnGrouping: {
        default: 'auto',
        description:
            'Specify a column grouping mode. If chart.stackMode is defined, it overrides grouping mode settings.',
        pattern: getPattern(['auto', 'overlay']),
        type: 'string',
    },
    columnSpacing: {
        description: 'Specifies the spacing (px) between columns in a column chart.',
        type: 'number',
    },
    legendDisplay: {
        description: 'Specify the location of the legend on the panel.',
        pattern: getPattern(['right', 'left', 'top', 'bottom', 'off']),
        type: 'string',
        default: 'right',
    },
    legendLabels: {
        description: `Specify a list of labels to populate the legend in advance. For example, ["percent", "count"].`,
        type: 'array',
        items: { type: 'string' },
    },
    legendMode: {
        default: 'standard',
        description:
            'Specify visual and behavioral settings for the tooltip and legend. "seriesCompare" is useful when comparing series.',
        pattern: getPattern(['standard', 'seriesCompare']),
        type: 'string',
    },
    legendTruncation: {
        description:
            'Specify how to display legend labels when they overflow the layout boundaries by replacing overflow text with an ellipsis.',
        pattern: getPattern(['ellipsisEnd', 'ellipsisMiddle', 'ellipsisStart', 'ellipsisOff']),
        type: 'string',
        default: 'ellipsisEnd',
    },
    lineWidth: {
        default: 2,
        description: 'Specify the line width (px) for overlay field lines.',
        type: 'number',
    },
    resultLimit: {
        default: 50000,
        description: 'Specify the number of data points to render in a chart.',
        type: 'number',
    },
    seriesColors: {
        default: VIZ_CATEGORICAL,
        description: 'Specify the colors to use in a series. For example, ["#FF0000", "#0000FF", "#008000"].',
        type: 'array',
        items: { type: 'string' },
    },
    seriesColorsByField: {
        description:
            'Specify the colors to use for specific fields in a series. For example, {“count”: “#008000”, “percent”: “#FFA500”}.',
        type: 'object',
    },
    seriesSpacing: {
        description: 'Specify the spacing (px) between clustered series in column and bar charts.',
        type: 'number',
    },
    stackMode: {
        description: 'Specify stack mode.',
        type: 'string',
        pattern: getPattern(['auto', 'stacked', 'stacked100']),
        default: 'auto',
    },
    showIndependentYRanges: {
        description: 'Specify whether split series charts have independent y-ranges.',
        type: 'boolean',
        default: false,
    },
    showSplitSeries: {
        description:
            'Specify whether to split a multi-series chart into separate charts that are stacked from top to bottom; uses one chart for each series.',
        type: 'boolean',
        default: false,
    },
    showTooltip: {
        description: 'Specify whether to make a tooltip visible on hover',
        type: 'boolean',
        default: true,
    },
    showXMajorGridLines: {
        description: 'Specify whether to show major grid lines on the x-axis.',
        type: 'boolean',
        default: false,
    },
    showYMajorGridLines: {
        description: 'Specify whether to show major grid lines on the y-axis.',
        type: 'boolean',
        default: true,
    },
    showY2MajorGridLines: {
        description: 'Specify whether to show major grid lines on the second y-axis.',
        type: 'boolean',
        default: false,
    },
    showYMinorGridLines: {
        description: 'Specify whether to show minor grid lines on the y-axis.',
        type: 'boolean',
        default: false,
    },
    showY2MinorGridLines: {
        description: 'Specify whether to show minor grid lines on the second y-axis.',
        type: 'boolean',
        default: false,
    },
    showYAxisExtendedRange: {
        description: 'Specify whether to extend the y-axis to include whole major tick marks.',
        type: 'boolean',
        default: true,
    },
    showYAxisWithZero: {
        description: 'Specify whether to include zero in the y-axis range.',
        type: 'boolean',
        default: false,
    },
    showY2AxisWithZero: {
        description: 'Specify whether to include zero in the second y-axis range.',
        type: 'boolean',
        default: false,
    },
    xAxisLabelRotation: {
        description: 'Specify the rotation of the x-axis label in degrees.',
        enum: [-90, -45, 0, 45, 90],
        type: 'number',
        default: 0,
    },
    xAxisLabelVisibility: {
        description: 'Specify whether to show labels on the x-axis.',
        pattern: getPattern(['auto', 'show', 'hide']),
        type: 'string',
        default: 'auto',
    },
    yAxisLabelVisibility: {
        default: 'auto',
        description: 'Specify whether to show labels on the y-axis.',
        pattern: getPattern(['auto', 'show', 'hide']),
        type: 'string',
    },
    y2AxisLabelVisibility: {
        default: 'auto',
        description: 'Specify whether to show labels on the second y-axis.',
        pattern: getPattern(['auto', 'show', 'hide']),
        type: 'string',
    },
    xAxisLineVisibility: {
        default: 'hide',
        description: 'Specify whether to show the x-axis line.',
        enum: ['hide', 'show'],
        pattern: getPattern(['show', 'hide']),
        type: 'string',
    },
    yAxisLineVisibility: {
        default: 'hide',
        description: 'Specify whether to show the y-axis line.',
        enum: ['hide', 'show'],
        pattern: getPattern(['show', 'hide']),
        type: 'string',
    },
    y2AxisLineVisibility: {
        default: 'hide',
        description: 'Specify whether to show the second y-axis line.',
        enum: ['hide', 'show'],
        pattern: getPattern(['show', 'hide']),
        type: 'string',
    },
    xAxisMajorTickSize: {
        description: 'Specify the size (px) of major tick marks on the x-axis.',
        type: 'number',
        default: 6,
    },
    yAxisMajorTickSize: {
        default: 6,
        description: 'Specify the size (px) of major tick marks on the y-axis.',
        type: 'number',
    },
    y2AxisMajorTickSize: {
        default: 6,
        description: 'Specify the size (px) of major tick marks on the second y-axis.',
        type: 'number',
    },
    yAxisMinorTickSize: {
        default: 6,
        description: 'Specify the size (px) of minor tick marks on the y-axis.',
        type: 'number',
    },
    y2AxisMinorTickSize: {
        default: 6,
        description: 'Specify the size (px) of minor tick marks on the second y-axis.',
        type: 'number',
    },
    xAxisMajorTickVisibility: {
        default: 'auto',
        description: 'Specify whether to show major tick marks on the x-axis.',
        pattern: getPattern(['auto', 'show', 'hide']),
        type: 'string',
    },
    yAxisMajorTickVisibility: {
        default: 'auto',
        description: 'Specify whether to show major tick marks on the y-axis.',
        pattern: getPattern(['auto', 'show', 'hide']),
        type: 'string',
    },
    y2AxisMajorTickVisibility: {
        default: 'auto',
        description: 'Specify whether to show  major tick marks on the second y-axis.',
        pattern: getPattern(['auto', 'show', 'hide']),
        type: 'string',
    },
    yAxisMinorTickVisibility: {
        default: 'auto',
        description: 'Specify whether to show minor tick marks on the y-axis.',
        pattern: getPattern(['auto', 'show', 'hide']),
        type: 'string',
    },
    y2AxisMinorTickVisibility: {
        default: 'auto',
        description: 'Specify whether to show minor tick marks on the second y-axis.',
        pattern: getPattern(['auto', 'show', 'hide']),
        type: 'string',
    },
    xAxisMaxLabelParts: {
        description:
            'Specify the maximum number of time-parts for a tick label. The 3 possible parts are year, month, and time. Values can range from 1 to 3.',
        type: 'number',
        default: 3,
        minimum: 1,
        maximum: 3,
    },
    yAxisScale: {
        type: 'string',
        pattern: getPattern(['linear', 'log']),
        description: 'Specify the type of scale that applies to a numerical y-axis.',
        default: 'linear',
    },
    y2AxisScale: {
        type: 'string',
        pattern: getPattern(['linear', 'log']),
        description: 'Specify the type of scale that applies to a numerical second y-axis.',
        default: 'linear',
    },
    xAxisTitleText: {
        description: 'Specify the title of the x-axis.',
        type: 'string',
    },
    yAxisTitleText: {
        description: 'Specify the title of the y-axis.',
        type: 'string',
    },
    y2AxisTitleText: {
        description: 'Specify the title of the second y-axis.',
        type: 'string',
    },
    xAxisTitleVisibility: {
        default: 'show',
        description: 'Specify whether to show the title of the x-axis.',
        enum: ['hide', 'show'],
        pattern: getPattern(['show', 'hide']),
        type: 'string',
    },
    yAxisTitleVisibility: {
        default: 'show',
        description: 'Specify whether to show the title of the y-axis.',
        enum: ['hide', 'show'],
        pattern: getPattern(['show', 'hide']),
        type: 'string',
    },
    y2AxisTitleVisibility: {
        default: 'show',
        description: 'Specify whether to show the title of the second y-axis.',
        enum: ['hide', 'show'],
        pattern: getPattern(['show', 'hide']),
        type: 'string',
    },
    yAxisAbbreviation: {
        default: 'auto',
        description:
            'Specify whether to abbreviate large y-axis values with the closest International System of Units (SI) prefix.',
        enum: ['auto', 'off'],
        pattern: getPattern(['off', 'auto']),
        type: 'string',
    },
    y2AxisAbbreviation: {
        default: 'auto',
        description:
            'Specify whether to abbreviate large second y-axis values with the closest International System of Units (SI) prefix.',
        enum: ['auto', 'off'],
        pattern: getPattern(['off', 'auto']),
        type: 'string',
    },
    yAxisMajorTickInterval: {
        default: 'auto',
        description: 'Specify the spacing unit between major tick marks along the numeric y-axis.',
        type: ['string', 'number'],
    },
    y2AxisMajorTickInterval: {
        default: 'auto',
        description: 'Specify the spacing unit between major tick marks along the numeric second y-axis.',
        type: ['string', 'number'],
    },
    yAxisMax: {
        type: ['string', 'number'],
        description: 'Specify the largest value for the visible y-axis range.',
        default: 'auto',
    },
    y2AxisMax: {
        type: ['string', 'number'],
        description: 'Specify the largest value for the visible second y-axis range.',
        default: 'auto',
    },
    yAxisMin: {
        type: ['string', 'number'],
        description: 'Specify the smallest value for the visible y-axis range.',
        default: 'auto',
    },
    y2AxisMin: {
        type: ['string', 'number'],
        description: 'Specify the smallest value for the visible second y-axis range.',
        default: 'auto',
    },
    overlayFields: {
        description:
            'Specify one or more fields to differentiate on the chart and display as chart overlays.',
        type: ['array', 'string'],
        items: {
            type: 'string',
        },
    },
    showOverlayY2Axis: {
        default: false,
        description:
            'Enable a second y-axis for chart overlays. All overlay fields will map to a second y-axis.',
        type: 'boolean',
    },
    showRoundedY2AxisLabels: {
        default: true,
        description: 'Specify whether to round the second y-axis values to the nearest integer.',
        type: 'boolean',
    },
};

const themes: ThemedDefaults = {
    defaultBackgroundColor: props =>
        pick({
            enterprise: {
                dark: variables.black(props),
                light: variables.backgroundColor(props),
            },
            prisma: variables.backgroundColorSidebar(props),
        })(props),
};

const updatedAxesChartLegend = cloneDeep(AxesChartLegend);
updatedAxesChartLegend.layout.push([
    {
        label: _('Legend mode'),
        option: 'legendMode',
        editor: 'editor.radioBar',
        editorProps: {
            values: [
                { label: _('Standard'), value: 'standard' },
                { label: _('Compare series'), value: 'seriesCompare' },
            ],
        },
    },
]);

const editorConfig: EditorConfig[] = [
    AxesChartDataConfigAreaColumnLine,
    AxesChartDataDisplayColumn,
    {
        label: AxesChartColorAndStyle.label,
        layout: [
            ...AxesChartColorAndStyle.layout,
            [
                {
                    label: _('Spacing between column series'),
                    option: 'seriesSpacing',
                    editor: 'editor.number',
                },
            ],
            [
                {
                    label: _('Spacing between columns'),
                    option: 'columnSpacing',
                    editor: 'editor.number',
                },
            ],
            [
                {
                    label: _('Line width'),
                    option: 'lineWidth',
                    editor: 'editor.slider',
                    editorProps: {
                        min: 0.5,
                        max: 8.0,
                        step: 0.5,
                    },
                    showEditor: ({ options }) =>
                        Array.isArray(options.overlayFields) && options.overlayFields.length > 0,
                },
            ],
            [
                {
                    label: _('Annotation colors'),
                    option: 'annotationColor',
                    editor: 'editor.columnSelector',
                    editorProps: {
                        dataSourceKey: 'annotation',
                        supportsDSL: true,
                        expectedDataPrimitive: ['series'],
                    },
                },
            ],
        ],
    },
    updatedAxesChartLegend,
    AxesChartXGridAndLabels,
    AxesChartYGridAndLabels,
    AxesChartY2GridAndLabels,
];

/**
 * visualization configuration
 */
const config: VizConfig = {
    /**
     * unique viz key
     */
    key: 'splunk.column',
    /**
     * viz name
     */
    name: 'Column',
    category: VizCategory.COMPARISONS,
    /**
     * viz icon
     */
    icon: ColumnIcon,
    placeholderIcon: ColumnPlaceholderIcon,
    dataContract,
    size,
    defaultContext,
    optionsSchema,
    editorConfig,
    events: {
        'legend.click': {
            description: 'triggered when user clicks on chart legend',
        },
        'point.click': {
            description: 'triggered when user clicks on a point in the column chart',
        },
        'range.select': {
            description: 'triggered when user makes a selection',
        },
        'point.mouseover': {
            description: 'triggered when user hovers over a data point',
        },
        'point.mouseout': {
            description: 'triggered when user moves out of a data point',
        },
    },
    supports: [VizBehavior.DYNAMIC_OPTIONS, VizBehavior.PLACEHOLDER],
    themes,
};

export default enhanceConfig(config);
