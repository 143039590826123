import { scaleBand, scaleLinear, scaleOrdinal } from 'd3-scale';

/**
 * @enum {AXIS_ORIENTATION_TYPES}
 */
export enum AXIS_ORIENTATION_TYPES {
    VERTICAL = 'vertical',
    HORIZONTAL = 'horizontal',
}

/**
 * @enum {BUBBLE_ROW_SCALE_TYPES}
 */
export enum BUBBLE_ROW_SCALE_TYPES {
    GLOBAL = 'global',
    ROW = 'row',
}

/**
 * @enum {BUBBLE_SCALE_TYPES}
 */
export enum BUBBLE_SCALE_TYPES {
    RADIUS = 'radius',
    AREA = 'area',
}

/**
 * @enum {COLOR_MODE_TYPES}
 */
export enum COLOR_MODE_TYPES {
    SEQUENTIAL = 'sequential',
    CATEGORICAL = 'categorical',
}

/**
 * @enum {BUBBLE_LABELS_TYPES}
 */
export enum BUBBLE_LABELS_TYPES {
    ALL = 'all',
    MAX = 'max',
    NONE = 'none',
}

/**
 * @string GRAYSCALE_FILTER_ID
 */
export const GRAYSCALE_FILTER_ID = `punchcard-grayscale-filter-id-${Math.floor(Math.random() * 1000)}`;

const X_AXIS_HEIGHT = 15;
const Y_AXIS_WIDTH = 77;
const MARGIN = 10;
const LEGEND_WIDTH = 135;
const HORIZONTAL_SPACE_FOR_SINGLE_CHAR = 6;

/**
 * get a mapping of categories to colors given all categories
 * @method getColorCategoryMapping
 * @param {Object} input
 * @param {Array<String>} category
 * @param {COLOR_MODE_TYPES} colorMode
 * @param {Array<String>} seriesColors
 * @return {Function} colorCategoryMapping
 */
export const getColorCategoryMapping = ({ category, colorMode, seriesColors }) => {
    if (colorMode === COLOR_MODE_TYPES.SEQUENTIAL) {
        return null;
    }

    return scaleOrdinal().domain(category).range(seriesColors).unknown(seriesColors[0]);
};

/**
 * get punchcard dimensions given container width and height
 * @method getChartAreaDimensions
 * @param {Object} input
 * @param {Number} containerWidth
 * @param {Number} containerHeight
 * @param {Boolean} displayLegend
 * @return {Object} dimensions
 * @return {Number} dimensions.xAxisStartY
 * @return {Number} dimensions.yAxisStartX
 * @return {Number} dimensions.chartAreaStartX
 * @return {Number} dimensions.chartAreaStartY
 * @return {Number} dimensions.chartAreaEndX
 * @return {Number} dimensions.chartAreaEndY
 */
export const getChartAreaDimensions = ({ containerWidth, containerHeight, displayLegend }) => {
    const yAxisStartX = MARGIN;
    const chartAreaStartX = yAxisStartX + Y_AXIS_WIDTH + MARGIN;

    let chartAreaEndX = containerWidth - MARGIN;
    let legendStartX = null;

    if (displayLegend) {
        legendStartX = containerWidth - LEGEND_WIDTH;
        chartAreaEndX = legendStartX - MARGIN;
    }

    const chartAreaStartY = MARGIN;
    const xAxisStartY = containerHeight - X_AXIS_HEIGHT - MARGIN;
    const chartAreaEndY = xAxisStartY - MARGIN;

    return {
        xAxisStartY,
        yAxisStartX,
        chartAreaStartX,
        chartAreaStartY,
        chartAreaEndX,
        chartAreaEndY,
        legendStartX,
    };
};

/**
 * gets a scale used to map values to x or y plane
 * @method getScales
 * @param {Object} input
 * @param {(Array<String>\|Array<Number>)} values
 * @param {Number} start
 * @param {Number} end
 * @return {Object} scales
 * @return {Function} scales.scale function to return beginning of band
 * @return {Function} scales.positionScale function to return middle of band
 */
export const getScales = ({ values, start, end }) => {
    const scale = scaleBand().domain(values).range([start, end]);

    // Returns the middle of the band
    const positionScale = value => scale(value) + scale.bandwidth() / 2;

    return {
        scale,
        positionScale,
    };
};

/**
 * Maps an array of values to a percentage
 * Excludes zero values and takes the absolute minimum and maximum value
 * @method createValueScale
 * @param {Array<Number>} values
 * @return {Function} radiusScale
 */
export const createValueScale = values => {
    const absValuesWithoutZero = [];
    values.forEach(value => {
        // Ignore undefined, null and 0 values
        if (value) {
            absValuesWithoutZero.push(Math.abs(value));
        }
    });

    return scaleLinear()
        .domain([Math.min(...absValuesWithoutZero), Math.max(...absValuesWithoutZero)])
        .range([0, 1])
        .clamp(true)
        .unknown(0);
};

/**
 * @typedef RowData
 * @type {Object}
 * @property {(Array<String>\|Array<Number>)} rowXValues
 * @property {Array<Number>} rowSizeValues
 * @property {Array<String>} rowCategoryValues
 * @property {Array<Number>} rowColorValues
 * @property {Array<Number>} rowFillOpacityValues
 * @property {Function} rowSizeScale
 * @property {Array<Number>} rowRadiusValue
 */

interface RowData {
    rowXValues: (string | number)[];
    rowSizeValues: number[];
    rowCategoryValues: string[];
    rowColorValues: number[];
    rowFillOpacityValues: number[];
    rowSizeScale: (...args: any) => void;
    rowRadiusValues: number[];
}

/**
 * Given a dataset, partition it by row and for each row:
 * get x, size, category and rowSizeScales
 * @method getDataByRows
 * @param {Object} input
 * @param {BUBBLE_ROW_SCALE_TYPES} bubbleRowScale
 * @param {BUBBLE_SCALE_TYPES} bubbleScale
 * @param {Array<String>} category
 * @param {Function} colorCategoryMapping
 * @param {COLOR_MODE_TYPES} colorMode
 * @param {(String|Array<String>)} bubbleColor
 * @param {Number} minBubbleColorIntensity
 * @param {Array<Number>} size
 * @param {(Array<String>\|Array<Number>)} x
 * @param {(Array<String>\|Array<Number>)} y
 * @param {Boolean} isBubbleSizeDynamic
 * @param {Number} minBubbleSize  // Used when isBubbleSizeDynamic
 * @param {Number} maxBubbleSize  // Used when isBubbleSizeDynamic
 * @param {Number} minBand  // Used to calculated radius when isBubbleSizeDynamic
 * @param {Number} maxBubbleRadius  // Used when !isBubbleSizeDynamic
 * @param {Number} minBubbleRadius  // Used when !isBubbleSizeDynamic
 * @return {Object<String\|Number, RowData>} dataByRows
 */
export const getDataByRows = ({
    bubbleRowScale,
    bubbleScale,
    category,
    colorCategoryMapping,
    colorMode,
    bubbleColor,
    minBubbleColorIntensity,
    size,
    x,
    y,
    isBubbleSizeDynamic,
    minBubbleSize,
    maxBubbleSize,
    minBand,
    maxBubbleRadius,
    minBubbleRadius,
}) => {
    const dataByRows: { [key: string]: RowData } = {};
    const minRadius = isBubbleSizeDynamic ? (minBand / 2) * minBubbleSize : minBubbleRadius;
    const maxRadius = isBubbleSizeDynamic ? (minBand / 2) * maxBubbleSize : maxBubbleRadius;
    const minArea = Math.PI * minRadius ** 2;
    const maxArea = Math.PI * maxRadius ** 2;
    const isBubbleColorAnArray = Array.isArray(bubbleColor);

    const globalSizeScale = createValueScale(size);
    const getRadiusValue = (value, sizeScale) =>
        bubbleScale === BUBBLE_SCALE_TYPES.RADIUS
            ? (maxRadius - minRadius) * sizeScale(Math.abs(value)) + minRadius
            : Math.sqrt(((maxArea - minArea) * sizeScale(Math.abs(value)) + minArea) / Math.PI);
    const getColorValue = c =>
        colorMode === COLOR_MODE_TYPES.CATEGORICAL ? colorCategoryMapping(c) : bubbleColor;
    const getOpacityValue = (value, sizeScale) =>
        colorMode === COLOR_MODE_TYPES.CATEGORICAL || isBubbleColorAnArray
            ? 1
            : sizeScale(Math.abs(value)) * (1 - minBubbleColorIntensity) + minBubbleColorIntensity;

    y.forEach((yValue, index) => {
        const xValue = x[index];
        const sizeValue = size[index];
        const categoryValue = category && category[index] ? category[index] : null;
        const bubbleColorValue =
            colorMode === COLOR_MODE_TYPES.SEQUENTIAL && isBubbleColorAnArray
                ? bubbleColor[index]
                : getColorValue(categoryValue);

        // if we find an invalid data point here (x, y or size is null or undefined), we ignore it
        if (xValue == null || yValue == null || sizeValue == null) {
            return;
        }

        // initialize rowData
        if (!dataByRows[yValue]) {
            dataByRows[yValue] = {
                rowXValues: [],
                rowSizeValues: [],
                rowCategoryValues: [],
                rowColorValues: [],
                rowFillOpacityValues: [],
                rowSizeScale: globalSizeScale,
                rowRadiusValues: [],
            };
        }

        dataByRows[yValue].rowXValues.push(xValue);
        dataByRows[yValue].rowSizeValues.push(sizeValue);
        dataByRows[yValue].rowCategoryValues.push(categoryValue);
        dataByRows[yValue].rowColorValues.push(bubbleColorValue);
        dataByRows[yValue].rowFillOpacityValues.push(
            getOpacityValue(size[index], dataByRows[yValue].rowSizeScale)
        );
        dataByRows[yValue].rowRadiusValues.push(getRadiusValue(size[index], dataByRows[yValue].rowSizeScale));
    });

    // If we are using row scaling we need to recalculate opacity, radius and size scale
    if (bubbleRowScale === BUBBLE_ROW_SCALE_TYPES.ROW) {
        Object.entries(dataByRows).forEach(([row, rowData]) => {
            const rowSizeScale = createValueScale(rowData.rowSizeValues);
            dataByRows[row].rowSizeScale = rowSizeScale;

            // Fill in opacity and radius values based on row scale
            rowData.rowSizeValues.forEach((rowSizeValue, index) => {
                dataByRows[row].rowFillOpacityValues[index] = getOpacityValue(
                    rowData.rowSizeValues[index],
                    rowSizeScale
                );
                dataByRows[row].rowRadiusValues[index] = getRadiusValue(
                    rowData.rowSizeValues[index],
                    rowSizeScale
                );
            });
        });
    }

    return dataByRows;
};

/**
 * Given selected dimensions
 * return true if there is something currently being selected
 * @method isSomethingSelected
 * @param {Object} input
 * @param {String|Number} selectedX
 * @param {String|Number} selectedY
 * @param {String} selectedCategory
 * @return {Boolean}
 */
export const isSomethingSelected = ({ selectedX, selectedY, selectedCategory }) =>
    selectedX !== null || selectedY !== null || selectedCategory !== null;

/**
 * Given selected dimensions and a specific bubble's values
 * return true if that bubble should be selected
 * @method isBubbleSelected
 * @param {Object} input
 * @param {String|Number} selectedX
 * @param {String|Number} selectedY
 * @param {String} selectedCategory
 * @param {String|Number} xValue
 * @param {String|Number} yValue
 * @param {Boolean} categoryValue
 * @return {Boolean}
 */
export const isBubbleSelected = ({
    selectedX,
    selectedY,
    selectedCategory,
    xValue,
    yValue,
    categoryValue,
}) => {
    if (selectedX !== null && xValue !== selectedX) {
        return false;
    }

    if (selectedY !== null && yValue !== selectedY) {
        return false;
    }

    if (selectedCategory !== null && categoryValue !== selectedCategory) {
        return false;
    }

    return true;
};

/**
 * Given text and available width, the truncateAxisLabelText will ellipsize
 * the truncateLocation of the text
 * @method truncateAxisLabelText
 * @param {Object} input
 * @param {String} label - The text to truncate
 * @param {Number} widthInPX - Available width to render the text
 * @param {('ellipsisStart' | 'ellipsisEnd')} axisLabelOverflowMode - Truncate start or end of the text
 */
export const truncateAxisLabelText = ({
    label,
    widthInPX = Y_AXIS_WIDTH,
    axisLabelOverflowMode = 'ellipsisEnd',
}) => {
    // convert available width to number of characters that can be rendered.
    const availableLength = Math.floor(widthInPX / HORIZONTAL_SPACE_FOR_SINGLE_CHAR);

    if (label.length <= availableLength) {
        return label;
    }

    // return empty string if available space cannot accomodate
    // at least one label character
    if (availableLength < 'X...'.length) {
        return '';
    }

    const endMarker = Math.max(0, availableLength - '...'.length);
    if (axisLabelOverflowMode === 'ellipsisStart') {
        return `...${label.slice(-endMarker)}`;
    }

    return `${label.slice(0, endMarker)}...`;
};
