/* eslint-disable no-param-reassign */
import type { JSONCols, RequestParams } from '@splunk/dashboard-types';
import DataSet from './DataSet';

const emptyInitialRequestParams = {};

interface ConsumerModule {
    config?: {
        dataContract?: {
            initialRequestParams?: RequestParams;
        };
    };
    initialRequestParams?: RequestParams;
}

const normalizeRequestParamsFromConsumerModule = (
    bindingType: string,
    consumerModule?: ConsumerModule
): RequestParams => {
    if (consumerModule == null) {
        return emptyInitialRequestParams;
    }

    let initialRequestParams =
        consumerModule.config?.dataContract?.initialRequestParams?.[
            bindingType
        ];
    if (!initialRequestParams) {
        initialRequestParams =
            consumerModule.initialRequestParams || emptyInitialRequestParams;
    }

    return initialRequestParams as RequestParams;
};

/**
 * Compute initial request params for given visualization and dataSource
 * @param {Object} config
 * @param {String} config.bindingType dataSource binding type
 * @param {Object} config.consumerModule module from preset
 * @param {Object} config.options current visualization/input options
 * @private
 */
export const findInitialRequestParams = ({
    bindingType = 'primary',
    consumerModule,
    options = {},
}: {
    bindingType?: string;
    consumerModule?: ConsumerModule;
    options?: Record<string, unknown>;
} = {}): RequestParams => {
    const initialRequestParams = normalizeRequestParamsFromConsumerModule(
        bindingType,
        consumerModule
    );

    if (typeof initialRequestParams === 'function') {
        return (
            initialRequestParams as unknown as (
                opts: Record<string, unknown>
            ) => RequestParams
        )(options);
    }
    return initialRequestParams;
};

export const formatData = (data?: DataSet | JSONCols): JSONCols | null => {
    if (data == null) {
        return DataSet.empty().toJSONCols();
    }
    if (data.constructor === DataSet) {
        return data.toJSONCols(); // we use json cols by default
    }

    // 'fields' and 'columns' exist in DataSet as private properties
    if (
        (data as unknown as JSONCols)?.fields &&
        (data as unknown as JSONCols)?.columns
    ) {
        return data as unknown as JSONCols;
    }
    return null; // return null as invalid data
};
