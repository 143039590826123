// This file can probably have more type guards added later

/**
 * @method isPlainObject
 * @description TypeScript type guard (narrow the possible types) to detect plain objects
 * @param val Variable which may be a plain object
 * @returns true of the parameter is truthy, not an array, and has the type 'object'
 */
export const isPlainObject = (val: unknown): val is object =>
    !!val && !Array.isArray(val) && typeof val === 'object';
