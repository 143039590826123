import { _ } from '@splunk/ui-utils/i18n';
import { isDynamicOption } from '@splunk/visualizations-shared/configUtils';

const StaticStrokeColorEditor = {
    label: _('Stroke'),
    option: 'strokeColor',
    editor: 'editor.color',
    showEditor: ({ options }) => !isDynamicOption(options.strokeColor),
};

export default StaticStrokeColorEditor;
