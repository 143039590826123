// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const memoize = <T extends (...args: any[]) => any>(
    func: T,
    resolver: (...args: Parameters<T>) => string
): T => {
    const memoizedComputations: Record<string, ReturnType<T>> = {};

    return ((...args: Parameters<T>): ReturnType<T> => {
        const argKey = resolver(...args);
        if (!memoizedComputations[argKey]) {
            memoizedComputations[argKey] = func(...args);
        }

        return memoizedComputations[argKey];
    }) as T;
};
