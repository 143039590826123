import { Bounds, DataPoint } from './ICoordinateTransformation';
import { Interval } from './Scale';

export default class BoundsCollector {
    public intervals: { [axis: string]: Interval } = {};

    public getBounds(): Bounds {
        return Object.keys(this.intervals).length > 0 ? this.intervals : undefined;
    }

    // expand the Bounds if the added point is outside the Bounds.
    // Since we don't know what they axes will be for a given DataPoint
    public addPoint(p: DataPoint): void {
        Object.keys(p).forEach((axis: string): void => {
            let interval: Interval = this.intervals[axis];
            if (!interval) {
                interval = { min: p[axis], max: p[axis] };
                this.intervals[axis] = interval;
            }
            if (p[axis] < interval.min) {
                interval.min = p[axis];
            }
            if (p[axis] > interval.max) {
                interval.max = p[axis];
            }
        });
    }
}
