import { once } from 'lodash';
import React, {
    cloneElement,
    type ReactElement,
    useMemo,
    useEffect,
    useCallback,
} from 'react';
import {
    CoreOverridesContext,
    useApiRegistry,
    useDashboardPlugin,
    useSearchMetricsCollector,
    useFeatureFlags,
    useLogger,
    useIsFirstRender,
    useDashboardCoreApi,
} from '@splunk/dashboard-context';
import {
    useDashboardProfiler,
    DASHBOARD_CORE_MOUNT_TO_RENDER_EVENT,
} from '@splunk/dashboard-telemetry';
import { console } from '@splunk/dashboard-utils';
import {
    useStore,
    selectDefinition,
    selectDataSourceDefinitions,
} from '@splunk/dashboard-state';
import { Validator } from '@splunk/dashboard-validation';
import DashboardContainer from './containers/connected/ConnectedDashboardContainer';
import { ActionMenusContextProvider } from './contexts/ActionMenusContext';
import { withDefinition } from './utils/withDefinition';
import { withReadOnlyTokenNamespaces } from './utils/withReadOnlyTokenNamespaces';
import { withValidationSchema } from './utils/withValidationSchema';
import { withDeprecatedProps } from './utils/withDeprecatedProps';
import type { DashboardCoreProps } from './types/DashboardCoreProps';
import { withTokensInUrl } from './utils/withTokensInUrl';

// Wrapping Validator with the extra props it needs, so they don't need to be in Core
const ValidatorComponent = withValidationSchema(
    withReadOnlyTokenNamespaces(withDefinition(Validator))
);

const formatValidateError = ({
    instancePath,
    message,
}: {
    instancePath: string;
    message: string;
}) => {
    if (instancePath) {
        return `${instancePath}: ${message}`;
    }

    return message;
};

// SearchMetricsCollector also wraps the callback with `once` but
// if that gets removed for some reason then this will prevent multiple
// event dispatches. Overly cautious, but at the same time: `once` isn't expensive.
const emitDashboardRenderedEvent = once(() => {
    document.dispatchEvent(
        new Event('dashboardCore.rendered', { bubbles: true })
    );
});

const noop = () => undefined;
const defaultActionMenus: ReactElement[] = [];
const defaultMeta = Object.freeze({});
const defaultOverrides = Object.freeze({});

const DashboardCore = ({
    dashboardCoreApiRef = noop,
    width = '100%',
    height = 600,
    actionMenus = defaultActionMenus,
    metadata = defaultMeta,
    onValidationError,
    overrides = defaultOverrides,
}: DashboardCoreProps) => {
    const store = useStore();
    const apiRegistry = useApiRegistry();
    const profiler = useDashboardProfiler();
    const dashboardPlugin = useDashboardPlugin();
    const searchMetricsCollector = useSearchMetricsCollector();
    const { enableSSR } = useFeatureFlags();
    const logger = useLogger();
    const isFirstRender = useIsFirstRender();

    const dashboardCoreApi = useDashboardCoreApi();

    useEffect(() => {
        dashboardCoreApiRef(dashboardCoreApi);

        return () => {
            dashboardCoreApiRef(null);
        };
    }, [dashboardCoreApiRef, dashboardCoreApi]);

    // Start profiler timer ASAP. Inside useEffect is too late.
    if (isFirstRender) {
        // Start the profiler timer for the dashboard mount
        profiler?.startTimer({
            timerName: DASHBOARD_CORE_MOUNT_TO_RENDER_EVENT,
        });

        // TODO: In performance tests all searches are already resolved by the time Core mounts (ds.test).
        const onAllDSResolved = () => {
            // Get the current definition from redux so DashboardInfo can be populated with the timing summary
            const definition = selectDefinition(store.getState());

            // Emit a summary of the mount time via telemetry and clear the timer in the profiler
            profiler?.emitAndClearTimer({
                timerName: DASHBOARD_CORE_MOUNT_TO_RENDER_EVENT,
                definition,
            });

            // Should this be emitted even when the flag is disabled? Hmm...
            if (enableSSR) {
                emitDashboardRenderedEvent();
            }
        };

        searchMetricsCollector.addMetricsCollectedCallback(onAllDSResolved);
    }

    // TODO: this might actually capture the mount/render time of Core more accurately, but doesn't account for the render time of the full canvas which happens over several frames
    // useEffect(() => {
    //     if (isFirstRender) {
    //         const definition = selectDefinition(store.getState());
    //         profiler?.emitAndClearTimer({
    //             timerName: DASHBOARD_CORE_MOUNT_TO_RENDER_EVENT,
    //             definition,
    //         });
    //     }
    // }, [profiler, isFirstRender, store]);

    // cleanup api registry on unmount
    useEffect(() => {
        return () => {
            apiRegistry.cleanup();
        };
    }, [apiRegistry]);

    // initialize dashboard plugin ASAP on mount to start timers
    if (isFirstRender) {
        const dataSources = selectDataSourceDefinitions(store.getState());
        dashboardPlugin.invokePluginCallback('onInitialize', {
            dataSourceCount: Object.keys(dataSources || {}).length,
        });
    }

    // inject dashboardApi into action menus
    const clonedActionMenus = useMemo(
        () =>
            actionMenus.map((item) => {
                if (!item.props.dashboardApi) {
                    return cloneElement(item, {
                        dashboardApi: dashboardCoreApi,
                    });
                }
                return item;
            }),
        [actionMenus, dashboardCoreApi]
    );

    const handleValidationErrors = useCallback(
        (errors) => {
            if (!errors) {
                return;
            }

            const handleError =
                typeof onValidationError === 'function'
                    ? onValidationError
                    : console.error;

            const log = typeof logger === 'function' ? logger : noop;

            (Array.isArray(errors) ? errors : [errors]).forEach((error) => {
                const errorMessage = formatValidateError(error);
                handleError(errorMessage);
                log({
                    message: errorMessage,
                    metadata,
                });
            });
        },
        [onValidationError, metadata, logger]
    );

    return (
        <ActionMenusContextProvider value={clonedActionMenus}>
            <CoreOverridesContext.Provider value={overrides}>
                <ValidatorComponent onError={handleValidationErrors} />
                <DashboardContainer width={width} height={height} />
            </CoreOverridesContext.Provider>
        </ActionMenusContextProvider>
    );
};

export default withDeprecatedProps(withTokensInUrl(DashboardCore));
