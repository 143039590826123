import { reject } from 'lodash';
import type { MutableRefObject } from 'react';
import type { TelemetryAPI, EmittableEvent } from '@splunk/dashboard-telemetry';
import type { UserMessageArgs } from '@splunk/dashboard-context';
import {
    computeNewGridStructureItem,
    updateRemovedVizNeighbors,
} from '@splunk/dashboard-utils';
import type {
    GridLayoutStructure,
    GridLayoutOptions,
} from '@splunk/dashboard-types';
import { gridLayoutOptions } from '../DefaultOptions';
import {
    CLONE_LAYOUT_ITEMS_MSG_PANEL_TOO_SMALL,
    MIN_WIDTH_PX,
} from '../GridLayoutConstants';
import { updateBlockItemSize } from '../utils/layoutUtils';
import type { AddLayoutItemArgs } from '../utils/layoutApiUtils';
import { getBlockItem } from '../utils/blockUtils';
import BaseLayoutApi from '../BaseLayoutApi';

interface LayoutApiArgs {
    layoutStructureRef: MutableRefObject<GridLayoutStructure>;
    options?: GridLayoutOptions;
    userMessage: (args: UserMessageArgs) => void;
    onItemAdded: () => void;
    getCanvasDomElement: () => HTMLElement;
    telemetry?: TelemetryAPI;
}

const empty = {};

class GridLayoutApi extends BaseLayoutApi {
    private layoutStructureRef: LayoutApiArgs['layoutStructureRef'];

    // This will never be undefined
    private options: GridLayoutOptions;

    private userMessage: LayoutApiArgs['userMessage'];

    private onItemAdded: LayoutApiArgs['onItemAdded'];

    getCanvasDomElement: LayoutApiArgs['getCanvasDomElement'];

    constructor({
        layoutStructureRef,
        options = empty,
        userMessage,
        onItemAdded,
        getCanvasDomElement,
        telemetry,
    }: LayoutApiArgs) {
        super();
        this.layoutStructureRef = layoutStructureRef;
        this.options = options;
        this.userMessage = userMessage;
        this.onItemAdded = onItemAdded;
        this.getCanvasDomElement = getCanvasDomElement;
        this.telemetry = telemetry;
    }

    /**
     * Add a new item to grid layout
     * @method
     * @param {Object} options
     * @param {String} options.itemId visualization id
     * @returns {Object[]} updated layout structure
     * @public
     */
    addLayoutItem = ({
        itemId,
        metadata,
    }: AddLayoutItemArgs): GridLayoutStructure => {
        this.emitTelemetry({
            pageAction: 'addLayoutItem',
            metadata,
        });

        // always add new item at the bottom of the root container

        // generate a proposed layout structure
        // it's up to the consumer to apply this structure via setting layoutStructure prop
        const width = this.options.width ?? gridLayoutOptions.width;
        const items = this.layoutStructureRef.current;
        const newItem = computeNewGridStructureItem({
            canvasWidth: width,
            layoutItems: items,
            itemId,
        });
        this.onItemAdded();
        return [...items, newItem];
    };

    /**
     * Remove item specified in itemIds list.
     * Note: Only removes one item even though it accepts a list of item ids.
     * @method
     * @param {string[]} item ids to remove.
     * @returns {object[]} updated layout structure
     * @public
     */
    removeLayoutItems = (
        // TODO: remove eslint comment below and update
        // eslint-disable-next-line default-param-last
        itemIds: string[] = [],
        metadata?: EmittableEvent['metadata']
    ): GridLayoutStructure => {
        this.emitTelemetry({
            pageAction: 'removeLayoutItems',
            metadata,
        });

        const items = this.layoutStructureRef.current;
        const itemToRemove = getBlockItem({
            layoutStructure: items,
            id: itemIds[0],
        });
        const width = this.options.width ?? gridLayoutOptions.width;

        const updatedItems = updateRemovedVizNeighbors({
            itemToRemove,
            items,
            width,
        });

        const keys = updatedItems.map((item) => item.item);
        const filteredItems = items.filter(
            (vizItem) =>
                vizItem.item !== itemToRemove.item &&
                keys.indexOf(vizItem.item) < 0
        );

        return [...updatedItems, ...filteredItems];
    };

    /**
     * There are 3 actors in this cloning algorithm:
     * 1. Clone Ancestor
     * 2. Left-side Descendant
     * 3. Right-side Descendant
     * @method
     * @param {Object} options
     * @param {String[]} options.from List of original viz ids (ancestors). These id's will be re-used to produce left-side descendants.
     * @param {String[]} options.to List of new viz ids. Has the id's of right-side descendants.
     * @returns {Object[]} Layout structure of all known items
     * @public
     */
    cloneLayoutItems = ({
        from,
        to,
        metadata,
    }: {
        from: string[];
        to: string[];
        metadata?: EmittableEvent['metadata'];
    }): GridLayoutStructure => {
        if (
            !Array.isArray(from) ||
            !Array.isArray(to) ||
            from.length !== to.length
        ) {
            throw new Error(
                `Cannot clone viz, incorrect inputs from ${from}, to: ${to}`
            );
        }
        this.emitTelemetry({
            pageAction: 'cloneLayoutItems',
            metadata,
        });

        // generate a proposed layout structure
        // it's up to the consumer to apply this structure via setting layoutStructure prop
        const items = this.layoutStructureRef.current;

        return from.reduce((accumulator, fromItemId, index) => {
            const ancestor = getBlockItem({
                layoutStructure: items,
                id: fromItemId,
            });
            if (ancestor.position.w < MIN_WIDTH_PX * 2) {
                this.userMessage({
                    message: CLONE_LAYOUT_ITEMS_MSG_PANEL_TOO_SMALL,
                    level: 'warning',
                    sender: 'GridLayoutApi',
                });
                return accumulator;
            }

            // Update the Left-side and Right-side Descendant's position values so that they equally share
            // the Ancestor's space.
            const lsDescendant = updateBlockItemSize({
                item: {
                    ...ancestor,
                },
                offset: {
                    offsetX: -1 * Math.floor(ancestor.position.w / 2),
                    offsetY: 0,
                },
                dir: 'e',
            });
            const rsDescendant = updateBlockItemSize({
                item: {
                    ...ancestor,
                    item: to[index],
                },
                offset: {
                    offsetX: Math.ceil(ancestor.position.w / 2),
                    offsetY: 0,
                },
                dir: 'w',
            });

            // Remove the Ancestor from the accumulator.
            const accumulatorSansAncestor = reject(accumulator, {
                item: fromItemId,
            });

            return [...accumulatorSansAncestor, lsDescendant, rsDescendant];
        }, items);
    };

    /**
     * Return snapshot as an empty object for the time being
     * @method
     */
    // eslint-disable-next-line class-methods-use-this
    snapshot = (): Record<string, never> => {
        return {};
    };

    /**
     * Return zoom level as null for now
     * @method
     */
    // eslint-disable-next-line class-methods-use-this
    getZoomLevel = (): null => {
        return null;
    };
}

export default GridLayoutApi;
