export const geoJsonOptions = {
    us: {
        name: 'USA',
        source: 'geo://default/us',
        projection: 'mercator',
        sourceBounds: {
            lat: {
                min: 24,
                max: 50,
            },
            long: {
                min: -130,
                max: -60,
            },
        },
        logicalBounds: {
            x: {
                min: 0,
                max: 800,
            },
            y: {
                min: 0,
                max: 600,
            },
        },
        fillColor: '#EAEFF2',
        strokeColor: '#689C8D',
        selector: '.feature',
        geoFeatureGroups: [
            {
                name: 'lower48',
                featureMatcher: {
                    property: 'postal',
                    regex: '^(?!(AK|HI))',
                },
                sourceBounds: {
                    lat: { min: 24, max: 50 },
                    long: { min: -130, max: -60 },
                },
            },
            {
                name: 'Alaska',
                featureMatcher: {
                    property: 'postal',
                    regex: 'AK',
                },
                logicalBounds: {
                    x: { min: 100, max: 200 },
                    y: { min: 325, max: 425 },
                },
                sourceBounds: {
                    lat: { min: 45, max: 72 },
                    long: { min: -180, max: -120 }, // manually cutoff the Alaskan islands in the eastern hemisphere by taking only the western hemisphere parts
                },
            },
            {
                name: 'Hawaii',
                featureMatcher: {
                    property: 'postal',
                    regex: 'HI',
                },
                logicalBounds: {
                    x: { min: 250, max: 350 },
                    y: { min: 375, max: 475 },
                },
                sourceBounds: {
                    lat: { min: 18.665677, max: 22.461292 },
                    long: { min: -160.921571, max: -154.242648 },
                },
            },
        ],
    },
    world: {
        name: 'WORLD',
        source: 'geo://default/world',
        projection: 'mercator',
        sourceBounds: {
            lat: { min: -60, max: 85 },
            long: { min: -180, max: 180 },
        },
        logicalBounds: { x: { min: 0, max: 800 }, y: { min: 0, max: 600 } },
        fillColor: '#EAEFF2',
        strokeColor: '#689C8D',
        strokeHighlightColor: '#000000',
        selector: '.feature',
    },
};
