import { useLayoutEffect, useEffect, useState, useRef } from 'react';
import { once } from 'lodash';

import {
    useTelemetryApi,
    useDashboardProfiler,
    VIZ_RESIZE_EVENT,
    VIZ_ADD_EVENT,
    DASHBOARD_MOUNT_TO_FIRST_VIZ_EVENT,
} from '@splunk/dashboard-telemetry';
import { hashString } from '@splunk/dashboard-utils';

interface UseVizTelemetryProps {
    width: number | string;
    height?: number | string;
    id: string;
    type: string;
    loading: boolean;
}

export const useVizTelemetry = ({
    width,
    height,
    id,
    type,
    loading,
}: UseVizTelemetryProps) => {
    const profiler = useDashboardProfiler();
    const [timerCreated, setTimerCreated] = useState(false);
    const telemetry = useTelemetryApi();

    useLayoutEffect(() => {
        // Needs to be in a useLayoutEffect due to react batching and dom event handler logic
        if (
            width &&
            height &&
            profiler?.hasPartialMeasurement(VIZ_RESIZE_EVENT)
        ) {
            profiler?.endMeasurement(VIZ_RESIZE_EVENT, {
                vizType: type,
            });
        }
    }, [height, profiler, type, width]);

    // Unique timerId for performanceCollector to create timer with
    const timerId = `Visualization - ${type} - ${hashString(
        id
    )} - Time to Interactive`;

    useEffect(() => {
        const initialPayload = {
            pageAction: 'performance.createTimer',
            metadata: {
                timerId,
                sourceElement: 'TimeRangePicker',
                heroElement: timerId,
                currentTime: performance.now(),
            },
        };
        // Creates an initial timer object when this visualization is initially mounted
        // Source element is the Time Range Picker, because we want to track time between
        // the time range change and this visualization updating
        if (!timerCreated) {
            telemetry.emit(initialPayload);
            setTimerCreated(true);
        }
    }, [telemetry, timerCreated, timerId]);

    useEffect(() => {
        const actionPayload = {
            pageAction: 'performance.mark',
            metadata: {
                timerId,
                markInformation: timerId,
                currentTime: performance.now(),
            },
        };
        const initialPayload = {
            pageAction: 'performance.createTimer',
            metadata: {
                timerId,
                sourceElement: 'TimeRangePicker',
                heroElement: timerId,
                currentTime: performance.now(),
            },
        };

        // After loading is finished, we emit a mark indicating that this visualization
        // has finished updating. We create a new timer since the original timer's job is
        // finished; a future update to time range picker and visualization updating
        // will have a fresh timer object to act upon, and cycle continues.
        if (!loading && timerCreated) {
            telemetry.emit(actionPayload);
            telemetry.emit(initialPayload);
        }
    }, [telemetry, loading, timerCreated, timerId]);

    // emit telemetry upon adding a visualization
    useEffect(() => {
        profiler?.emitAndClearTimer({
            timerName: VIZ_ADD_EVENT,
            metadata: {
                vizType: type,
            },
        });
        // to ensure to only run on mount
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Use _.once to prevent multiple calls to emitAndClearTimer (not really needed, given how/where the firstVizMount timer is created)
    const firstVizEmit = useRef(
        once((currentProfiler) =>
            currentProfiler?.emitAndClearTimer({
                timerName: DASHBOARD_MOUNT_TO_FIRST_VIZ_EVENT,
            })
        )
    );

    // emit telemetry once upon mount
    useEffect(() => {
        if (!loading) {
            firstVizEmit.current(profiler);
        }
    }, [loading, profiler]);
};
