import {
    AbsoluteLayoutViewer,
    GridLayoutViewer,
} from '@splunk/dashboard-layouts';
import type { PresetMap } from '@splunk/dashboard-types';

import datasourcePresets from './DatasourcePresets';
import eventHandlerPresets from './EventHandlerPresets';
import inputPresets from './InputPresets';
import vizPresets from './VisualizationPresets';

export default {
    layouts: {
        absolute: AbsoluteLayoutViewer,
        grid: GridLayoutViewer,
    },
    ...datasourcePresets,
    ...eventHandlerPresets,
    ...inputPresets,
    ...vizPresets,
} as PresetMap;
