import get from 'lodash/get';
import BaseApi from '@splunk/dashboard-visualizations/common/BaseVisualizationApi';
import { isRemoteIcon } from '@splunk/visualizations-shared/iconUtils';

/**
 * SingleValueIcon Api
 */
class SingleValueIconApi extends BaseApi {
    constructor(viz) {
        super();
        this.viz = viz;
    }

    /**
     * return snapshot of that includes inlined image
     */
    snapshot() {
        const iconFromOptions = get(this.viz.props, ['options', 'icon'], '');
        const isRemote = isRemoteIcon(iconFromOptions);
        return {
            options: {
                ...this.viz.props.options,
                icon:
                    isRemote && this.viz.remoteIcon
                        ? get(this.viz.remoteIcon, ['state', 'iconDataURI'], '')
                        : iconFromOptions,
            },
        };
    }
}

export default SingleValueIconApi;
