import { has, isArray, isUndefined } from 'lodash';
import type { JsonColumnData } from './interfaces/DataSource';

interface ChartOptions {
    [key: string]: any;
}

/**
 * Get formatted annotations
 * @method getAnnotations
 * @param {Object} options
 * @param {Array} options.annotationX
 * @param {Array} options.annotationColor
 * @param {Array} options.annotationLabel
 * @returns {Array}
 */
export const getAnnotations = ({ annotationX, annotationColor, annotationLabel }) =>
    annotationX && annotationX.length
        ? annotationX.map((timestamp, i) => ({
              color: annotationColor && annotationColor[i],
              label: annotationLabel && annotationLabel[i],
              time: timestamp,
          }))
        : [];

/**
 * Truncate the result set to given limit
 * @method truncateData
 * @param {Object} dataSet - dataSources.primary.data
 * @param {Number|String} truncationLimit - options['chart.resultTruncationLimit']
 * @returns {Object}
 */
export const truncateData = (dataSet, truncationLimit): Record<string, any[]> => {
    if (!dataSet) {
        return null;
    }

    const limit = parseInt(truncationLimit, 10);
    if (!limit || limit <= 0) {
        return dataSet;
    }

    const truncatedData: Record<string, Array<string[] | number[]>> = { ...dataSet };
    truncatedData.columns = truncatedData.columns.map(columnData =>
        columnData && Array.isArray(columnData) ? columnData.slice(0, limit) : columnData
    );
    return truncatedData;
};

/**
 * Helper method to update the option values into the values that SC accepts
 * @method mapToValues
 * @param {Object} options
 * @returns {Object}
 */
export const mapToValues = (optionValuesSCMapping: Record<string, any>) => options => {
    const convertedOptions: Record<string, any> = { ...options };
    Object.keys(optionValuesSCMapping).forEach(key => {
        if (has(options, key)) {
            const updatedValue = optionValuesSCMapping[key][options[key]];
            if (!isUndefined(updatedValue)) {
                convertedOptions[key] = updatedValue;
            }
        }
    });
    return convertedOptions;
};

interface y2AxisOptions extends ChartOptions {
    y2Fields?: string | string[];
}

/**
 * Helper method to process options related to y2Fields
 * @method convertY2AxisOptions
 * @param {Object} originalOptions
 * @returns {Object}
 */
export const convertY2AxisOptions = ({
    y2Fields,
    ...originalOptions
}: y2AxisOptions): Record<string, any> => {
    const convertedOptions: Record<string, any> = {
        ...originalOptions,
    };
    if (y2Fields) {
        convertedOptions['axisY2.enabled'] = true;
        convertedOptions['axisY2.fields'] = isArray(y2Fields)
            ? y2Fields.filter(name => name).join(',')
            : y2Fields;
    }
    return convertedOptions;
};

/**
 * Helper method to format value for overlayFields
 * @method convertOverlayFields
 * @param {Object} originalOptions
 * @returns {Object}
 */
export const convertOverlayFields = (originalOptions: ChartOptions): ChartOptions => {
    const convertedOptions: ChartOptions = {
        ...originalOptions,
    };
    const { overlayFields } = originalOptions;
    if (overlayFields) {
        convertedOptions.overlayFields = Array.isArray(overlayFields)
            ? overlayFields
                  .filter(field => field)
                  .map(field => field.trim())
                  .join(',')
            : overlayFields;
    }
    return convertedOptions;
};

/**
 * checks whether data is multi series data
 * @param {array} data array
 * @return {Boolean}
 */
export const isMultiSeriesData = (data: string[] | string[][]): boolean =>
    Array.isArray(data) && Array.isArray(data[0]);
/**
 * checks whether data is multi series data and returns value as array
 * @param {array} data array
 * @param {*} value to return as array
 * @return {array}
 */
export const toArrayForSeries = (data, value): any[] => (isMultiSeriesData(data) ? value : [value]);

interface ConstructFieldsAndColumnsParams {
    x: string[];
    y: string[] | string[][];
    xField: string;
    yFields: string | string[];
    y2?: string[] | string[][];
    y2Fields?: string | string[];
}
export const constructFieldsAndColumns = ({
    x = [],
    y = [],
    xField,
    yFields,
    y2,
    y2Fields,
}: ConstructFieldsAndColumnsParams): JsonColumnData => {
    const columns = [x.map(String), ...toArrayForSeries(y, y).map(series => series.map(String))];
    const yFieldsArray = toArrayForSeries(y, yFields);
    const fields = [{ name: xField }, ...yFieldsArray.map(field => ({ name: field }))];
    // splunk-charting requires y2 to be part of columns
    // when y & y2 options are configured with different (mutually exclusive) series
    // y2 columns need to be added to columns
    if (y2Fields) {
        const y2FieldsArray = toArrayForSeries(y2, y2Fields);
        const y2AsArray = toArrayForSeries(y2, y2).map(series => series?.map(String));
        y2FieldsArray.forEach((y2Field, index) => {
            if (yFieldsArray.indexOf(y2Field) === -1 && y2AsArray[index]) {
                fields.push({
                    name: y2Field,
                });
                columns.push(y2AsArray[index]);
            }
        });
    }
    return {
        columns,
        fields,
    };
};
