import { _ } from '@splunk/ui-utils/i18n';
import { EditorConfig } from '../interfaces/Editor';

const AxesChartLegend: EditorConfig = {
    label: _('Legend'),
    layout: [
        [
            {
                label: _('Legend display'),
                option: 'legendDisplay',
                editor: 'editor.select',
                editorProps: {
                    values: [
                        { label: _('Right'), value: 'right' },
                        { label: _('Left'), value: 'left' },
                        { label: _('Top'), value: 'top' },
                        { label: _('Bottom'), value: 'bottom' },
                        { label: _('Off'), value: 'off' },
                    ],
                },
            },
        ],
        [
            {
                label: _('Legend truncation'),
                option: 'legendTruncation',
                editor: 'editor.radioBar',
                editorProps: {
                    values: [
                        { label: _('A...'), value: 'ellipsisEnd' },
                        { label: _('A...Z'), value: 'ellipsisMiddle' },
                        { label: _('...Z'), value: 'ellipsisStart' },
                        { label: _('Off'), value: 'ellipsisOff' },
                    ],
                },
                showEditor: ({ options }) =>
                    options.legendDisplay === 'left' ||
                    options.legendDisplay === 'right' ||
                    options.legendDisplay === 'top' ||
                    options.legendDisplay === 'bottom' ||
                    options.legendDisplay === undefined,
            },
        ],
    ],
};

export default AxesChartLegend;
