import React, { useMemo, useEffect, useState, useContext } from 'react';
import merge from 'lodash/merge';
import {
    createDispatchHook,
    createSelectorHook,
    createStoreHook,
    Provider,
    type ReactReduxContextValue,
    type TypedUseSelectorHook,
} from 'react-redux';
import type { Store } from '@reduxjs/toolkit';
import type {
    GlobalState,
    DashboardPluginWrapper,
    PresetUtility,
    InitialDashboardContextProps,
} from '@splunk/dashboard-types';

import { teardown } from '../sagas/sagaActions';
import createStore from '../store';
import { useInitializeStore } from '../hooks/useInitializeStore';

// TODO: define these in dashboard-types, or eliminate sagas. Do not import types from dashboard-core or dashboard-context
type EventRegistry = unknown;
type APIRegistry = unknown;
type DataSourceRegistry = unknown;

export interface SagaContext {
    dashboardPlugin?: DashboardPluginWrapper;
    preset?: PresetUtility;
    eventRegistry?: EventRegistry;
    apiRegistry?: APIRegistry;
    dataSourceRegistry?: DataSourceRegistry;
}

export type MiddlewareContext = SagaContext;

export interface StateContextType {
    updateMiddlewareContext: (context: MiddlewareContext) => void;
}

const middlewareContext: MiddlewareContext = {};

// Note: this is only to make TS happy. In actual usage, this mocked value will never be seen.
// TODO: potentially provide a more specific type when createStore is typed
const mockedStoreForTS = {} as ReactReduxContextValue;
export const DashboardStoreContext = React.createContext(mockedStoreForTS);
export const useStore = createStoreHook(DashboardStoreContext);
export const useSelector: TypedUseSelectorHook<GlobalState> =
    createSelectorHook(DashboardStoreContext);
export const useDispatch = createDispatchHook(DashboardStoreContext);
export { batch } from 'react-redux';

export const DashboardStateContext = React.createContext<StateContextType>({
    updateMiddlewareContext: () => undefined,
});

export const useSagaCleanup = (store: Store): void => {
    useEffect(() => {
        return () => {
            if (typeof store?.dispatch === 'function') {
                store.dispatch(teardown());
            }
        };
    }, [store]);
};

export interface StateProviderProps extends InitialDashboardContextProps {
    children: React.ReactNode;
    featureFlags?: Record<string, boolean>;
    preset: PresetUtility;
    dashboardPlugin?: DashboardPluginWrapper;
}

export const StateProvider = ({
    children,
    featureFlags,
    preset,
    initialDefinition,
    initialTokenBinding,
    initialReadOnlyTokenNamespaces,
    initialMode,
    initialSelectedItems,
    initialShowGrid,
    dashboardPlugin,
}: StateProviderProps): JSX.Element => {
    const [store] = useState(() =>
        createStore({
            initialState: {},
            middlewareContext: merge(middlewareContext, {
                preset,
                dashboardPlugin,
            }),
            featureFlags,
        })
    );

    // Teardown the sagas when unmounted
    useSagaCleanup(store);

    const providerValue = useMemo(() => {
        const updateMiddlewareContext = (context: MiddlewareContext): void => {
            merge(middlewareContext, context);
        };

        return { updateMiddlewareContext };
    }, []);

    useInitializeStore({
        store,
        initialDefinition,
        initialTokenBinding,
        initialReadOnlyTokenNamespaces,
        initialMode,
        initialSelectedItems,
        initialShowGrid,
    });

    return (
        <DashboardStateContext.Provider value={providerValue}>
            <Provider store={store} context={DashboardStoreContext}>
                {children}
            </Provider>
        </DashboardStateContext.Provider>
    );
};

export const useUpdateMiddlewareContext = () =>
    useContext(DashboardStateContext);
