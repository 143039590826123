import * as React from 'react';
import { _ } from '@splunk/ui-utils/i18n';
import SVG from '@splunk/react-icons/SVGEnterprise';

const LinkGraph = (props: React.ComponentProps<typeof SVG>): React.ReactElement<SVGElement> => (
    <SVG screenReaderText={_('Link Graph')} fill="currentColor" viewBox="0 0 150 100" {...props}>
        <g>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M39.9474 31H1V38.7895H39.9474V36.1187L53.4368 49.6082H39.9474V46.5789H1V54.3684H39.9474V51.3392H55.5263V54.3684H94.4737V51.3392H100.863L94.4737 64.1173V62.1579H55.5263V65.1871H39.9474V62.1579H1V69.9474H39.9474V66.9181H55.5263V69.9474H94.4737V66.9181H95.0086L102.798 51.3392H110.053V54.3684H149V46.5789H110.053V49.2497L105.913 45.1098L110.053 36.83V38.7895H149V31H110.053V34.0292H109.518L109.341 34.382L102.263 41.4602L94.8322 34.0292H94.4737V31H55.5263V38.7895H94.4737V36.1187L101.039 42.6842L94.4737 49.2497V46.5789H55.5263V49.2497L40.3059 34.0292H39.9474V31ZM102.263 43.9082L96.5632 49.6082H101.728L103.807 45.4516L102.263 43.9082ZM104.623 43.8196L106.893 39.278L103.487 42.6842L104.623 43.8196ZM105.097 46.7418L103.664 49.6082H107.963L105.097 46.7418Z"
            />
            <path d="M149 62.1579V69.9474H110.053V62.1579H149Z" />
        </g>
    </SVG>
);

export default LinkGraph;
