import type { DashboardJSON, BindingType } from '@splunk/dashboard-types';
import type { DashboardContextProviderProps } from '../DashboardContext';
import {
    UnboundSmartSourceBindingType,
    UnboundSmartSourceConsumerId,
} from './constants';

type DSId = string;
type ItemId = string;

type GetSearchesToExecutePropsType = Pick<
    DashboardJSON,
    'dataSources' | 'inputs' | 'visualizations'
> &
    Partial<Pick<DashboardContextProviderProps, 'featureFlags'>>;

export type SearchesToExecute = Record<DSId, Record<ItemId, BindingType[]>>;

export type GetSearchesToExecuteType = ({
    dataSources,
    inputs,
    visualizations,
    featureFlags,
}: GetSearchesToExecutePropsType) => SearchesToExecute;

/* eslint no-param-reassign: ["error", { "props": true, "ignorePropertyModificationsFor": ["result"] }] */
const findSearchesToExecute = ({
    items,
    dataSources,
    result,
}: {
    items?:
        | GetSearchesToExecutePropsType['inputs']
        | GetSearchesToExecutePropsType['visualizations'];
    dataSources: GetSearchesToExecutePropsType['dataSources'];
    result: SearchesToExecute;
}) => {
    if (!items) {
        // Cannot process if no configuration is defined
        return;
    }

    // Object.keys with object prop access is slightly faster than Object.entries
    Object.keys(items).forEach((itemId) => {
        // DS bindings for the item (ex: { primary: 'ds_1', annotation: 'ds_2' })
        const { dataSources: itemBindings } = items[itemId];

        // Early return from forEach should be slightly quicker than Object.keys({})
        if (!itemBindings) {
            return;
        }

        Object.keys(itemBindings).forEach((bindingType) => {
            const dsId = itemBindings[bindingType];
            if (typeof dataSources?.[dsId] === 'undefined') {
                // Cannot execute a datasource which is undefined
                return;
            }

            result[dsId] ??= {};
            result[dsId][itemId] ??= [];
            result[dsId][itemId].push(bindingType);
        });
    });
};

export const getSearchesToExecute: GetSearchesToExecuteType = ({
    dataSources,
    inputs,
    visualizations,
    featureFlags,
}) => {
    const searchesToExecute: SearchesToExecute = {};

    // Process visualization DS bindings
    findSearchesToExecute({
        items: visualizations,
        dataSources,
        result: searchesToExecute,
    });

    // Process input DS bindings
    findSearchesToExecute({
        items: inputs,
        dataSources,
        result: searchesToExecute,
    });

    // Apply smart sources if they aren't already being executed and the feature flag is enabled
    if (featureFlags?.enableSmartSourceDS && dataSources) {
        Object.keys(dataSources).forEach((dsId) => {
            if (dsId in searchesToExecute) {
                return;
            }

            const { options } = dataSources[dsId];
            if (options?.enableSmartSources) {
                searchesToExecute[dsId] = {
                    [UnboundSmartSourceConsumerId]: [
                        UnboundSmartSourceBindingType,
                    ],
                };
            }
        });
    }

    return searchesToExecute;
};
