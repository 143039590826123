/* eslint-disable react/no-unused-prop-types */
import React from 'react';
import T from 'prop-types';
import Charting from '@splunk/react-visualizations/common/Charting';
import jsCharting from '@splunk/charting-bundle';
import omit from 'lodash/omit';
import isArray from 'lodash/isArray';
import { getSimpleXMLOptionsForProps } from '@splunk/visualizations-shared/simpleXMLOptions';
import { convertPropsToString } from '@splunk/visualizations-shared/propUtils';
import { withSanitizedProps } from '@splunk/visualizations-shared/SanitizeProps';
import {
    baseChartPropTypes,
    baseChartDefaultProps,
    xyChartPropTypes,
    xyChartDefaultProps,
} from '../../common/props';

const Scatter = props => {
    const {
        width,
        height,
        style,
        themeKey,
        x,
        xFieldName,
        y,
        yFieldName,
        category,
        categoryFieldName,
        onPointClick,
        onLegendClick,
        colorPalette,
    } = props;

    const chartOptions = {
        chart: 'scatter',
        ...getSimpleXMLOptionsForProps(
            omit(props, [
                'width',
                'height',
                'style',
                'x',
                'y',
                'category',
                'xFieldName',
                'yFieldName',
                'categoryFieldName',
                'onPointClick',
                'onLegendClick',
                'colorPalette',
            ])
        ),
    };

    // splunk charting expects certain options like 'seriesColors', 'fieldColors' etc to be a string
    const convertedChartOptions = convertPropsToString({
        vizProps: chartOptions,
        propNames: ['seriesColors', 'fieldColors'],
    });

    // copy convertedChartOptions into chartOptions
    Object.assign(chartOptions, convertedChartOptions);

    const primaryData = {};

    if (category && isArray(category) && categoryFieldName) {
        // multiseries
        primaryData.fields = [{ name: categoryFieldName }, { name: xFieldName }, { name: yFieldName }];
        primaryData.columns = [category, x, y];
    } else {
        primaryData.fields = [{ name: xFieldName }, { name: yFieldName }];
        primaryData.columns = [x, y];
    }

    const chartData = jsCharting.extractChartReadyData(primaryData);

    const onClick = ({ type, originalEvent, payload }) => {
        if (type === 'point.click') {
            onPointClick({ type, originalEvent, payload });
        }

        if (type === 'legend.click') {
            onLegendClick({ type, originalEvent, payload });
        }
    };

    return (
        <Charting
            width={width}
            height={height}
            style={style}
            themeKey={themeKey}
            options={chartOptions}
            chartData={chartData}
            onClick={onClick}
            colorPalette={colorPalette}
        />
    );
};

Scatter.propTypes = {
    themeKey: T.string,
    ...baseChartPropTypes,
    ...xyChartPropTypes,
    // scatter specific props
    xAxisExtendsAxisRange: T.bool,
    xAxisMajorLabelRotation: T.oneOf([-90, -45, 0, 90, 45]),
    xAxisMajorUnit: T.oneOfType([T.number, T.string]),
    xAxisAbbreviation: T.oneOf(['none', 'auto']),
    roundXAxisLabelsToInteger: T.bool,
    xAxisMinorTickSize: T.number,
    xAxisMinorTickVisibility: T.oneOf(['auto', 'show', 'hide']),
    xAxisIncludeZero: T.bool,
    xAxisMin: T.oneOfType([T.string, T.number]),
    xAxisScale: T.oneOf(['linear', 'log']),
    showMinorXGridLines: T.bool,
    markerSize: T.number,
};

Scatter.defaultProps = {
    themeKey: 'prismaDark',
    ...baseChartDefaultProps,
    ...xyChartDefaultProps,
    xAxisExtendsAxisRange: true,
    xAxisMajorLabelRotation: 0,
    xAxisMajorUnit: 'auto',
    xAxisAbbreviation: 'none',
    roundXAxisLabelsToInteger: false,
    xAxisMinorTickSize: 6,
    xAxisMinorTickVisibility: 'auto',
    xAxisIncludeZero: false,
    xAxisMin: 'auto',
    xAxisScale: 'linear',
    showMinorXGridLines: false,
    markerSize: 4,
};

export default withSanitizedProps(Scatter);
