import type {
    AbsoluteBlockItem,
    DispatchedMiddlewareAction,
    Middleware,
} from '@splunk/dashboard-types';
import {
    cloneDashboardItems,
    type CloneDashboardItemsActionPayload,
} from '../sagas/sagaActions';

export const isCloneDashboardItemsActionPayload = (
    payload: unknown
): payload is CloneDashboardItemsActionPayload =>
    // payload 'from' property must be an array
    Array.isArray((payload as CloneDashboardItemsActionPayload).from) &&
    // payload 'to' property must be an array
    Array.isArray((payload as CloneDashboardItemsActionPayload).to);

const clonedItemLayering: Middleware =
    ({ getState }) =>
    (next) => {
        // Return an action handler to intercept `cloneDashboardItemsAction` and
        // ensure the from/to arrays are sorted relative to the indexes of the from
        // ids in the current dashboard layout structure
        return (action: DispatchedMiddlewareAction) => {
            // If the received action isn't a cloneDashboardItems then pass the
            // request along the middleware chain with no mutations
            if (action.type !== cloneDashboardItems.type) {
                return next(action);
            }

            // If the received payload doesn't match the structure expected then
            // something is off, but to avoid breaking just pass the request along
            if (!isCloneDashboardItemsActionPayload(action.payload)) {
                return next(action);
            }

            const { from, to, ...originalPayload } = action.payload;

            // Get the layout structure from the global store
            // and create a mapping of [ITEM_ID] --> [STRUCTURE_INDEX]
            const layerOrder: Record<string, number> = {};
            getState().definition.layout?.structure?.forEach(
                ({ item }: AbsoluteBlockItem, index: number) => {
                    layerOrder[item] = index;
                }
            );

            // Calculate new from/to arrays by sorting the from/to id tuples
            // based on the layer of the "from" dashboard items
            const orderedFrom: string[] = [];
            const orderedTo: string[] = [];
            from.map((fromId, idx) => [fromId, to[idx]])
                .sort(
                    ([a], [b]) => (layerOrder[a] ?? -1) - (layerOrder[b] ?? -1)
                )
                .forEach(([fromId, toId]) => {
                    orderedFrom.push(fromId);
                    orderedTo.push(toId);
                });

            // Dispatch a new payload with updated from/to arrays
            return next(
                Object.assign(action, {
                    payload: {
                        ...originalPayload,
                        from: orderedFrom,
                        to: orderedTo,
                    },
                })
            );
        };
    };

export default clonedItemLayering;
