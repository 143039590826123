import React, { useCallback } from 'react';
import T from 'prop-types';
import pickFromTheme from '@splunk/themes/pick';
import variables from '@splunk/themes/variables';
import styled, { withTheme } from 'styled-components';
import pick from 'lodash/pick';
import isEmpty from 'lodash/isEmpty';
import FeatureFlagContext from '@splunk/visualization-context/FeatureFlagContext';
import { toDimension } from '@splunk/visualizations-shared/style';
import SizeAwareWrapper from '@splunk/visualizations-shared/SizeAwareWrapper';
import VizStyleWrapper from '../../common/components/VizStyleWrapper';
import { TrellisTitle, TRELLIS_TITLE_HEIGHT } from '../../common/components/TrellisTitle';
import { LAYOUT_TYPE } from '../../common/utils/layoutUtils';
import RadialLayout from './RadialLayout';
import {
    computeColors,
    computeSingleValueRadialContent,
    shouldShowTrend,
    handleValueClickCallback,
} from '../../common/utils/singleValueUtils';
import {
    getRadialGraphDimensions,
    getRadialHelperDimensions,
    getRadialProgress,
    getRadialStrokeWidth,
    getRadialColors,
} from './utils/displayUtils';

const Container = styled.div`
    overflow: hidden;
    ${props => toDimension(pick(props, ['width', 'height']))};
`;

const SingleValueRadial = ({ ...props }) => {
    const {
        width,
        height = 250,
        style,
        majorFontSize,
        majorValue,
        majorValueField,
        maxValue,
        trendValue,
        trendFontSize,
        unitPosition,
        numberPrecision,
        shouldUseThousandSeparators,
        shouldAbbreviateTrendValue,
        onValueClick,
        splitByLayout,
        trellisKey = '',
        trellisSplitBy = '',
    } = props;
    // calculate colors
    const defaultColors = {
        defaultFontColor: pickFromTheme({
            enterprise: {
                dark: variables.textColor(props),
                light: variables.gray20(props),
            },
            prisma: variables.contentColorActive(props),
        })(props),
        defaultBlockFontColor: pickFromTheme({
            enterprise: variables.white(props),
            prisma: variables.contentColorActive(props),
        })(props),
    };
    const { backgroundEnabled, backgroundColor, majorColor, trendColor, underLabelColor } = computeColors(
        props,
        defaultColors
    );

    const radialColors = getRadialColors({ backgroundEnabled, backgroundColor });
    const radialBackgroundColor = props.radialBackgroundColor || radialColors.background;
    const radialStrokeColor = props.radialStrokeColor || radialColors.stroke;
    const underLabelStrokeColor = backgroundEnabled ? backgroundColor : 'transparent';
    const featureFlagContext = React.useContext(FeatureFlagContext);
    const isTrellisFeatureFlagOn = Boolean(
        featureFlagContext && featureFlagContext.visualizations_enableTrellis
    );
    const trellisEnabled = isTrellisFeatureFlagOn && splitByLayout === LAYOUT_TYPE.Trellis;
    const handleValueClick = useCallback(
        ev =>
            handleValueClickCallback(
                ev,
                majorValue,
                majorValueField,
                trendValue,
                onValueClick,
                trellisEnabled,
                trellisSplitBy,
                trellisKey
            ),
        [onValueClick, majorValue, majorValueField, trendValue, trellisEnabled, trellisSplitBy, trellisKey]
    );

    const renderVisualization = ({ width: containerWidth, height: containerHeight }) => {
        const { underLabel } = props;
        let { trendDisplay, unit } = props;

        // calculate radial graph dimensions, strokewidth, and major and trend content dimensions
        let { width: graphWidth, height: graphHeight } = getRadialGraphDimensions({
            width: containerWidth,
            height: containerHeight,
        });

        // Radial stroke width is stepped to lower value at lower sizes.
        const radialStrokeWidth = getRadialStrokeWidth(graphHeight);

        let { contentWidth, contentHeight } = getRadialHelperDimensions({
            height: graphHeight - radialStrokeWidth / 2,
            strokeWidth: radialStrokeWidth,
        });

        // Subtract half of the stroke width for the exact internal radius.
        const semicircleRadius = graphHeight - radialStrokeWidth / 2;
        // If radius drops below 20px, hide the radial graph. The dependency of this variable on font size is removed, and it now only depends on semicircle radius.
        const showRadialGraph = semicircleRadius >= 20;

        // If not showing the radial graph, then can forgo padding logic and use original container dimensions for content sizing computation.
        if (!showRadialGraph) {
            graphWidth = containerWidth;
            contentWidth = containerWidth;
            graphHeight = containerHeight;
            contentHeight = containerHeight;
        }

        const {
            majorText,
            trendText,
            majorTextFontSize,
            trendTextFontSize,
            majorValueTitle,
            smallVizProps,
        } = computeSingleValueRadialContent({
            majorValue,
            trendValue,
            majorFontSize,
            trendFontSize,
            trendDisplay,
            contentWidth,
            contentHeight, // buffer for height of div
            containerWidth: containerWidth * 0.9,
            containerHeight: containerHeight * 0.9,
            unit,
            numberPrecision,
            shouldUseThousandSeparators,
            shouldAbbreviateTrendValue,
            smallViz: !showRadialGraph,
        });

        if (!isEmpty(smallVizProps)) {
            ({ trendDisplay, unit } = smallVizProps);
        }

        // calculate radial progress
        const progress = getRadialProgress(majorValue, maxValue);

        return (
            <VizStyleWrapper
                backgroundColor={backgroundColor}
                style={style}
                dataTestKey="SingleValueRadialWrapper"
            >
                <RadialLayout
                    data-test="RadialLayout"
                    majorColor={majorColor}
                    majorText={majorText}
                    majorTextFontSize={majorTextFontSize}
                    majorValueTitle={majorValueTitle}
                    trendColor={trendColor}
                    trendText={trendText}
                    trendTextFontSize={trendTextFontSize}
                    showTrendIndicator={shouldShowTrend(trendDisplay)}
                    unit={unit}
                    unitPosition={unitPosition}
                    underLabelColor={underLabelColor}
                    underLabel={underLabel}
                    onValueClick={handleValueClick}
                    showRadialGraph={showRadialGraph}
                    progress={progress}
                    radialBackgroundColor={radialBackgroundColor}
                    radialStrokeColor={radialStrokeColor}
                    radialStrokeWidth={radialStrokeWidth}
                    graphWidth={graphWidth}
                    graphHeight={graphHeight}
                    underLabelStrokeColor={underLabelStrokeColor}
                />
            </VizStyleWrapper>
        );
    };
    if (trellisEnabled) {
        return (
            <>
                <TrellisTitle
                    fontColor={defaultColors.defaultFontColor}
                    backgroundColor={backgroundColor}
                    trellisKey={trellisKey}
                />
                <Container width={width} height={height - TRELLIS_TITLE_HEIGHT}>
                    <SizeAwareWrapper>
                        {containerDimension => renderVisualization(containerDimension)}
                    </SizeAwareWrapper>
                </Container>
            </>
        );
    }

    return (
        <Container width={width} height={height}>
            <SizeAwareWrapper>
                {containerDimension => renderVisualization(containerDimension)}
            </SizeAwareWrapper>
        </Container>
    );
};

SingleValueRadial.propTypes = {
    width: T.oneOfType([T.string, T.number]),
    height: T.oneOfType([T.string, T.number]),
    style: T.object,
    majorValue: T.oneOfType([T.string, T.number]),
    majorValueField: T.string,
    majorColor: T.string,
    majorFontSize: T.number,
    maxValue: T.number,
    trendValue: T.oneOfType([T.string, T.number]),
    trendColor: T.string,
    trendFontSize: T.number,
    trendDisplay: T.string,
    trendIndicatorPosition: T.oneOf(['standard', 'below', 'outside']),
    shouldAbbreviateTrendValue: T.bool,
    backgroundColor: T.string,
    unit: T.string,
    unitPosition: T.string,
    underLabel: T.string,
    numberPrecision: T.number,
    shouldUseThousandSeparators: T.bool,
    radialBackgroundColor: T.string,
    radialStrokeColor: T.string,
    onValueClick: T.func,
    splitByLayout: T.string,
    trellisKey: T.string,
    trellisSplitBy: T.string,
};

SingleValueRadial.defaultProps = {
    width: '100%',
    height: 250,
    majorValue: 'N/A',
    majorValueField: '',
    maxValue: 100,
    trendDisplay: 'absolute',
    trendIndicatorPosition: 'standard',
    shouldAbbreviateTrendValue: false,
    unitPosition: 'after',
    numberPrecision: 0,
    shouldUseThousandSeparators: true,
    onValueClick: () => {},
    trellisKey: '',
    trellisSplitBy: '',
};

export default withTheme(SingleValueRadial);
