import type {
    AbsoluteBlockItem,
    GridLayoutStructure,
    SelectedItem,
} from '@splunk/dashboard-types';

// TODO: Possibly: { blockItems, edges, ... }
export type MappedGridLayout = Record<string, AbsoluteBlockItem>;

// Seems expensive, needs multi-block update
type ResetStructureAction = {
    type: 'structure/reset';
    payload: GridLayoutStructure;
};

type UpdateItemAction = {
    type: 'structure/update';
    payload: AbsoluteBlockItem;
};
export const resetLayoutAction = (
    payload: GridLayoutStructure
): ResetStructureAction => ({
    type: 'structure/reset',
    payload,
});

export const updateItemAction = (
    payload: AbsoluteBlockItem
): UpdateItemAction => ({
    type: 'structure/update',
    payload,
});

export type GridActions = ResetStructureAction | UpdateItemAction;

export const getBlockItems = (state: MappedGridLayout): GridLayoutStructure =>
    Object.values(state);

export const filterSelectedItems = (
    state: GridLayoutStructure,
    selectedItems: SelectedItem[] = []
): SelectedItem[] => {
    const itemKeys = state.map(({ item }) => item);
    return selectedItems.filter(({ id }) => itemKeys.indexOf(id) >= 0);
};

export const getItemKeys = (state: MappedGridLayout): string[] =>
    Object.keys(state);

export const getStructureItem = (
    state: GridLayoutStructure,
    itemId: string
): AbsoluteBlockItem =>
    state.find(({ item }) => item === itemId) as AbsoluteBlockItem;

export const getItem = (
    state: MappedGridLayout,
    itemId: string
): AbsoluteBlockItem => state[itemId];

export const hasPreviewItem = (
    state: MappedGridLayout,
    itemId = 'preview-old-item'
): boolean => !!state[itemId];

/**
 * Filter out any items not appearing in layoutStructure as they are currently unknown
 * Add any new items in layoutStructure, keep preview items
 * @param {MappedGridLayout} state reducer state
 * @param {GridLayoutStructure} layoutStructure current known state
 * @returns {GridLayoutStructure} filtered state
 */
export const getFilteredStructure = (
    state: MappedGridLayout,
    layoutStructure: GridLayoutStructure
): GridLayoutStructure => {
    const result: GridLayoutStructure = [];
    layoutStructure.forEach((item) => {
        result.push(state[item.item] || item);
    });

    if (hasPreviewItem(state)) {
        result.push(state['preview-old-item']);
    }

    return result;
};

// Reducer
export const initializeGridReducer = (
    layoutStructure: GridLayoutStructure = []
): MappedGridLayout => {
    const gridLayout: MappedGridLayout = {};
    layoutStructure.forEach((item) => {
        gridLayout[item.item] = item;
    });
    return gridLayout;
};

// TODO: reducer should probably intelligently handle other pieces of content (e.g. edges) and have additional dispatches to handle resize, move, etc
// TODO: reducer should handle updating multiple items, instead of a complete replacement of state (structure/reset should be a last resort)
export const gridReducer = (
    state: MappedGridLayout,
    action: GridActions
): MappedGridLayout => {
    switch (action.type) {
        // TODO: handle more actions (above), to further reduce size of grid code
        // TODO: expand set of reduced state, to limit amount of non-layout data tracked in layout component
        case 'structure/reset':
            return initializeGridReducer(action.payload);
        case 'structure/update':
            return {
                ...state,
                [action.payload.item]: action.payload,
            };
        default:
            return state;
    }
};
