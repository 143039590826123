import { _ } from '@splunk/ui-utils/i18n';
import { COLOR_OR_TOKEN_PATTERN_WITH_RGBA, getPattern } from '@splunk/visualizations-shared/schemaUtils';
import pick from '@splunk/themes/pick';
import variables from '@splunk/themes/variables';
import { defaultPalettesConfig } from '@splunk/visualization-color-palettes/editors/PresetPalettes';
import { SingleValueRadial as SingleValueRadialIcon } from '@splunk/visualization-icons';
import { SingleValueRadial as SingleValueRadialPlaceholderIcon } from '@splunk/visualization-icons/placeholders';
import StaticMajorAndTrendLayout from '../common/editorConfig/StaticMajorAndTrendLayout';
import { VizConfig } from '../common/interfaces/VizConfig';
import { DataContract } from '../common/interfaces/DataContract';
import { VizSize } from '../common/interfaces/VizSize';
import { DefaultContext } from '../common/interfaces/DefaultContext';
import { OptionsSchema } from '../common/interfaces/OptionsSchema';
import { EditorConfig } from '../common/interfaces/Editor';
import { VizBehavior } from '../common/interfaces/VizBehavior';
import { ThemedDefaults } from '../common/interfaces/ThemedDefaults';
import { PresetEntry, enhanceConfig, getInitialPreset } from '../common/utils/configUtils';
import { VizCategory } from '../common/interfaces/VizCategory';
import { getDynamicMajorColorEditor } from '../common/editorConfig/DynamicMajorColorEditor';
import { getDynamicTrendColorEditor } from '../common/editorConfig/DynamicTrendColorEditor';
import { getDynamicBackgroundColorEditor } from '../common/editorConfig/DynamicBackgroundColorEditor';
import StaticMajorColorEditor from '../common/editorConfig/StaticMajorColorEditor';
import StaticTrendColorEditor from '../common/editorConfig/StaticTrendColorEditor';
import StaticBackgroundColorEditor from '../common/editorConfig/StaticBackgroundColorEditor';
import getRadialBackgroundColorEditor from '../common/editorConfig/RadialBackgroundColor';
import { TrellisEditorConfig, TrellisBackgroundColorEditor } from '../common/editorConfig/TrellisEditors';
import { LAYOUT_TYPE } from '../common/utils/layoutUtils';

const dataContract: DataContract = {
    requiredDataSources: [
        {
            name: 'primary',
            description: 'DataSource that powers the visualization',
        },
    ],
    optionalDataSources: [],
    initialRequestParams: {
        primary: { offset: 0, count: 10000 },
    },
};

const size: VizSize = {
    initialWidth: 250,
    initialHeight: 250,
};

const defaultContext: DefaultContext = {
    trendColorConfig: [
        {
            to: 0,
            value: '#9E2520',
        },
        {
            from: 0,
            value: '#1C6B2D',
        },
    ],
    majorColorConfig: [
        { to: 20, value: '#D41F1F' },
        { from: 20, to: 40, value: '#D94E17' },
        { from: 40, to: 60, value: '#CBA700' },
        { from: 60, to: 80, value: '#669922' },
        { from: 80, value: '#118832' },
    ],
    majorColorMatchConfig: [{ match: '', value: '#5C33FF' }],
};

const optionsSchema: OptionsSchema = {
    backgroundColor: {
        default: '> themes.defaultBackgroundColor',
        description:
            'Specify the color for the background. The hex value format should be “#ffffff”. The default for enterprise light is "#ffffff". The default for enterprise dark is "#000000". The default for prisma dark is "#0b0c0e".',
        pattern: COLOR_OR_TOKEN_PATTERN_WITH_RGBA,
        type: 'string',
    },
    majorColor: {
        default: '> themes.defaultFontColor',
        description:
            'Specify the color for the major value. You may use a dataSource to apply the color. The hex value format should be “#ffffff”. The default for enterprise light is "#000000". The default for enterprise dark is "#ffffff". The default for prisma dark is "rgba(255, 255, 255, 0.98)".',
        pattern: COLOR_OR_TOKEN_PATTERN_WITH_RGBA,
        type: 'string',
    },
    majorValue: {
        default: '> primary | seriesByPrioritizedTypes("number", "string", "time") | lastPoint()',
        description: 'Specify the dataSource applied to the major value.',
        type: ['string', 'number'],
    },
    majorValueField: {
        default: '> majorValue | getField()',
        description: 'Specify the field that should be mapped to the major value.',
        type: 'string',
    },
    maxValue: {
        default: 100,
        description: 'Specify the value the radial fills up to.',
        type: 'number',
    },
    numberPrecision: {
        default: 0,
        maximum: 20,
        minimum: 0,
        description:
            'Specify the number of decimal places to display. For example, to display 3 decimal places, use a value of 3. Values can range from 0 to 20.',
        type: 'number',
    },
    radialBackgroundColor: {
        default: '> themes.defaultRadialBackgroundColor',
        description:
            'Specify the color for the radial background. The hex value format should be “#ffffff”. The default for enterprise light is "rgba(0, 0, 0, 0.1)". The default for enterprise dark is "rgba(255, 255, 255, 0.15)". The default for prisma dark is "rgba(255, 255, 255, 0.15)".',
        pattern: COLOR_OR_TOKEN_PATTERN_WITH_RGBA,
        type: 'string',
    },
    radialStrokeColor: {
        default: '> majorColor',
        description:
            'Specify the color for the radial. You may use a dataSource to apply the color. The hex value format should be “#ffffff”. The default for enterprise light is "#000000". The default for enterprise dark is "#ffffff". The default for prisma dark is "rgba(255, 255, 255, 0.98)".',
        pattern: COLOR_OR_TOKEN_PATTERN_WITH_RGBA,
        type: 'string',
    },
    shouldAbbreviateTrendValue: {
        default: false,
        description:
            'Specify whether to abbreviate the trend value to 2 decimal points. A magnitude unit will be displayed.',
        type: 'boolean',
    },
    shouldUseThousandSeparators: {
        default: true,
        description: 'Specify whether numeric values use commas as thousandths separaters.',
        type: 'boolean',
    },
    splitByLayout: {
        default: 'off',
        description:
            'Specify the layout method by which to display the visualization, which splits the data into individual visualizations based on a certain category.',
        type: 'string',
        pattern: getPattern([LAYOUT_TYPE.Off, LAYOUT_TYPE.Trellis]),
        enum: [LAYOUT_TYPE.Off, LAYOUT_TYPE.Trellis],
    },
    trendColor: {
        default: '> themes.defaultFontColor',
        description:
            'Specify the color for the trend value. You may use a dataSource to apply the color. The hex value format should be “#FFFFFF”. The default for enterprise light is "#000000". The default for enterprise dark is "#ffffff". The default for prisma dark is "rgba(255, 255, 255, 0.98)".',
        pattern: COLOR_OR_TOKEN_PATTERN_WITH_RGBA,
        type: 'string',
    },
    trendDisplay: {
        default: 'absolute',
        description: 'Specify how to display the trend value.',
        pattern: getPattern(['percent', 'absolute', 'off']),
        type: 'string',
    },
    trendValue: {
        default: '> primary | seriesByPrioritizedTypes("number", "string", "time") | delta(-2)', // todo: may need update delta method
        description: 'Specify the dataSource applied to the trend value.',
        type: 'number',
    },
    trellisBackgroundColor: {
        default: '> themes.defaultBackgroundColor',
        description:
            'Specify the color used for the trellis container background by using a hexadecimal code. For example, #0000FF.',
        pattern: COLOR_OR_TOKEN_PATTERN_WITH_RGBA,
        type: 'string',
    },
    trellisColumns: {
        description:
            'Specify the number of visualizations to display in a given row of the trellis container. The remaining visualizations will wrap accordingly. If nothing is specified, it will be auto-set based on the trellisMinColumnWidth. The minimum value is 1.',
        type: 'number',
        minimum: 1,
    },
    trellisMinColumnWidth: {
        default: 100,
        minimum: 1,
        description:
            'Specify the minimum width, in pixels, of each visualization in the trellis container. If the window or panel is resized, the remaining visualizations may be viewed by scrolling. The minimum value is 1.',
        type: 'number',
    },
    trellisPageCount: {
        default: 20,
        minimum: 1,
        description:
            'Specify the maximum number of visualizations to display in a single page in the trellis container. The remaining visualizations will paginate accordingly. The minimum value is 1.',
        type: 'number',
    },
    trellisRowHeight: {
        default: 70,
        minimum: 1,
        description:
            'Specify the height, in pixels, of each visualization in the trellis container. If the window or panel is resized, the remaining visualizations may be viewed by scrolling. The minimum value is 1.',
        type: 'number',
    },
    trellisSplitBy: {
        description:
            'Specify the field name of the column with categories used, or “aggregations”, to split the data into individual visualizations for trellis display, if applicable. If a SPL `timechart` command is used, this may default to the SPL `by` clause field. If a SPL `chart` command is used, this may default to the first string field.',
        type: 'string',
    },
    underLabel: { description: 'Specify the text that appears below the major value.', type: 'string' },
    unit: {
        description: 'Specify text to show next to the major value.',
        type: 'string',
    },
    unitPosition: {
        default: 'after',
        description: 'Specify whether the unit text should appear before or after the major value.',
        pattern: getPattern(['before', 'after']),
        type: 'string',
    },
};

const basePresetContext = {
    majorColorEditorConfig: undefined,
    trendColorEditorConfig: undefined,
    backgroundColorEditorConfig: undefined,
};

const basePresetOptions = {
    majorColor: undefined,
    trendColor: undefined,
    backgroundColor: undefined,
};

const presets: PresetEntry[] = [
    {
        // dummy context variables to allow for resetting of unused context states
        label: _('None'),
        name: 'singleValueRadial.none',
        value: {
            context: { ...basePresetContext },
            options: { ...basePresetOptions },
        },
    },
    {
        label: _('Major value'),
        name: 'singleValueRadial.majorValue',
        value: {
            context: {
                ...basePresetContext,
                majorColorEditorConfig: defaultContext.majorColorConfig,
            },
            options: {
                ...basePresetOptions,
                majorColor: '> majorValue | rangeValue(majorColorEditorConfig)',
            },
        },
    },
    {
        label: _('Major value and Trend value'),
        name: 'singleValueRadial.majorValueAndTrend',
        value: {
            context: {
                ...basePresetContext,
                majorColorEditorConfig: defaultContext.majorColorConfig,
                trendColorEditorConfig: defaultContext.trendColorConfig,
            },
            options: {
                ...basePresetOptions,
                majorColor: '> majorValue | rangeValue(majorColorEditorConfig)',
                trendColor: '> trendValue | rangeValue(trendColorEditorConfig)',
            },
        },
    },
    {
        label: _('Trend value'),
        name: 'singleValueRadial.trend',
        value: {
            context: {
                ...basePresetContext,
                trendColorEditorConfig: defaultContext.trendColorConfig,
            },
            options: {
                ...basePresetOptions,
                trendColor: '> trendValue | rangeValue(trendColorEditorConfig)',
            },
        },
    },
    {
        label: _('Background'),
        name: 'singleValueRadial.background',
        value: {
            context: {
                ...basePresetContext,
                backgroundColorEditorConfig: defaultContext.majorColorConfig,
            },
            options: {
                ...basePresetOptions,
                backgroundColor: '> majorValue | rangeValue(backgroundColorEditorConfig)',
            },
        },
    },
];

const themes: ThemedDefaults = {
    defaultFontColor: props =>
        pick({
            enterprise: {
                dark: variables.white(props),
                light: variables.black(props),
            },
            prisma: variables.contentColorActive(props),
        })(props),
    defaultBackgroundColor: props =>
        pick({
            enterprise: {
                dark: variables.black(props),
                light: variables.white(props),
            },
            prisma: variables.backgroundColorSidebar(props),
        })(props),
    defaultRadialBackgroundColor: props =>
        pick({
            enterprise: {
                dark: 'rgba(255, 255, 255, 0.15)',
                light: 'rgba(0, 0, 0, 0.1)',
            },
            prisma: variables.interactiveColorBackgroundDisabled(props),
        })(props),
};

const editorConfig: EditorConfig[] = [
    {
        label: _('Data configurations'),
        layout: [
            [
                {
                    label: _('Value'),
                    editor: 'editor.columnSelector',
                    option: 'majorValue',
                    context: 'valuesContext',
                    editorProps: {
                        dataSourceKey: 'primary',
                        prepareValue: (
                            definition
                        ): { context: Record<string, any>; options: Record<string, any> } => {
                            const {
                                options: { majorValue: baseSPL },
                            } = definition;

                            return {
                                context: {},
                                options: {
                                    majorValue: `${baseSPL} | lastPoint()`,
                                    trendValue: `${baseSPL} | delta(-2)`,
                                },
                            };
                        },
                    },
                    isDisabled: ({ options }) => options.splitByLayout === LAYOUT_TYPE.Trellis,
                },
            ],
        ],
    },
    {
        label: _('Data display'),
        layout: [
            ...StaticMajorAndTrendLayout,
            [
                {
                    label: _('Trend display'),
                    option: 'trendDisplay',
                    editor: 'editor.select',
                    editorProps: {
                        values: [
                            { label: _('Percent'), value: 'percent' },
                            { label: _('Absolute'), value: 'absolute' },
                            { label: _('Off'), value: 'off' },
                        ],
                    },
                    showEditor: ({ options }) => options.showValue || options.showValue === undefined,
                },
            ],
            ...TrellisEditorConfig,
        ],
    },
    {
        label: _('Color and style'),
        layout: [
            [
                {
                    label: 'Dynamic elements',
                    key: 'presetSelector',
                    editor: 'editor.presetSelector',
                    editorProps: {
                        presets,
                        value: ({ context, options }) => {
                            const initialPreset = getInitialPreset(context, options, presets);
                            return (initialPreset && initialPreset.label) || null;
                        },
                    },
                },
            ],
            [
                {
                    ...StaticMajorColorEditor,
                    editorProps: {
                        themes,
                    },
                },
            ],
            [getDynamicMajorColorEditor({ defaultContext, defaultPalettesConfig })],
            [
                {
                    ...StaticTrendColorEditor,
                    editorProps: {
                        themes,
                    },
                },
            ],
            [getDynamicTrendColorEditor({ defaultContext, defaultPalettesConfig })],
            getRadialBackgroundColorEditor({ themes }),
            [
                {
                    ...StaticBackgroundColorEditor,
                    editorProps: {
                        themes,
                    },
                },
            ],
            [
                {
                    ...TrellisBackgroundColorEditor,
                    editorProps: {
                        themes,
                    },
                },
            ],
            [getDynamicBackgroundColorEditor({ defaultContext, defaultPalettesConfig })],
        ],
    },
];
/**
 * visualization configuration
 */
const config: VizConfig = {
    /**
     * unique viz key
     */
    key: 'splunk.singlevalueradial',
    /**
     * viz name
     */
    name: 'Single Value Radial',
    category: VizCategory.SINGLE_VALUE,
    /**
     * viz icon
     */
    icon: SingleValueRadialIcon,
    placeholderIcon: SingleValueRadialPlaceholderIcon,
    dataContract,
    size,
    defaultContext,
    optionsSchema,
    editorConfig,
    events: {
        'value.click': {
            description: 'trigger when user clicks major value',
            payloadKeys: ['majorValue', 'trendValue'],
        },
    },
    supports: [VizBehavior.DYNAMIC_OPTIONS, VizBehavior.EVENTS, VizBehavior.PLACEHOLDER, VizBehavior.TRELLIS],
    themes,
};

export default enhanceConfig(config);
