import { parse as baseParse, mergeEncoding } from '@splunk/visualization-encoding-parsers/Base';

export const dataContract = {
    requiredDataSources: [],
    optionalDataSources: [
        {
            name: 'primary',
            description: 'DataSource that powers the visualization',
        },
    ],
    initialRequestParams: {
        primary: { offset: 0, count: 10000 },
    },
    encoding: {
        featureId: {
            isRequired: true,
            type: ['string'],
            default: 'primary[0]',
        },
        value: {
            isRequired: true,
            type: ['number'],
            default: 'primary[1]',
        },
        fill: {
            isRequired: true,
            type: ['number', 'string'],
            default: {
                field: 'primary[1]',
                format: {
                    type: 'gradient',
                    values: ['#7EEFDA', '#6484F6', '#C093F9', '#293873'],
                },
            },
        },
    },
};

/**
 * Choropleth parse function
 * @param {object} dataSources
 * @param {object} encoding
 * @return {object} props that dashboard/react visualizations can be used.
 * @public
 */
export const parse = (dataSources, userDefinedEncoding) => {
    const encoding = mergeEncoding(dataContract.encoding, userDefinedEncoding);
    const parsedProps = baseParse(dataSources, encoding);

    return {
        fill: parsedProps.fill,
        value: parsedProps.value,
        featureId: parsedProps.featureId,
    };
};
