import * as React from 'react';
import { _ } from '@splunk/ui-utils/i18n';
import SVG from '@splunk/react-icons/SVGEnterprise';

const Column = (props: React.ComponentProps<typeof SVG>): React.ReactElement<SVGElement> => (
    <SVG screenReaderText={_('Column')} fill="currentColor" viewBox="0 0 150 100" {...props}>
        <g>
            <path d="M73.3598 16H59.0783V83.0459H73.3598V16Z" />
            <path d="M36.5453 25.9487H22.3061V83.0459H36.5453V25.9487Z" />
            <path d="M18.3714 32.1835H4.08984V83.0459H18.3714V32.1835Z" />
            <path d="M40.5194 38.0602H55.0977V83.0459H40.5194V38.0602Z" />
            <path d="M77.2945 54.4973H91.5337V83.0459H77.2945V54.4973Z" />
            <path d="M109.701 34.1673H95.4617V83.0459H109.701V34.1673Z" />
            <path d="M113.629 45.4137H127.868V83.0459H113.629V45.4137Z" />
            <path d="M146.035 34.1673H131.796V83.0459H146.035V34.1673Z" />
        </g>
    </SVG>
);

export default Column;
