import { _ } from '@splunk/ui-utils/i18n';
import { isDynamicOption } from '@splunk/visualizations-shared/configUtils';

const StaticMajorColorEditor = {
    label: _('Major value'),
    option: 'majorColor',
    editor: 'editor.color',
    showEditor: ({ options }) => !isDynamicOption(options.majorColor),
};

export default StaticMajorColorEditor;
