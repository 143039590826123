/**
 * This is an escape hatch for the case where an event handler function depends on some states that changes too often thus the event handle is recreated frequently which causes bugs in some edge cases.
 *
 * One example is the AbsoluteCanvas where the `handleMouseUp` depends on the `startPosition` state set by the `handleMouseDown`, thus the `handleMouseUp` is re-registered every time mousedown event happens.
 * This is not only a performance hit, but also causes cypress test failures when `.click()` is used. The symptom is, `handleMouseUp` is not invoked when `.click()` is used. My theory is, the `.click()` triggers
 * both `mousedown` and `mouseup` synchronously without any interval, which gave React no time to re-register the updated `handleMouseUp` function.
 *
 * The solution is inspired by React official FAQ: https://reactjs.org/docs/hooks-faq.html#how-to-read-an-often-changing-value-from-usecallback
 * The original solution provided by the FAQ doesn't work. My theory is, the `useEffect` hook had no chance to kick in, so this implementation removes usage of `useEffect`.
 *
 * As the official doc mentioned, this approach is not recommended, and React team plans to provide more ergonomic alternatives in the future.
 */

import { useCallback, useRef } from 'react';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type EventHandler = (...args: any[]) => any;

const useEventCallback = <F extends EventHandler>(fn: F) => {
    const ref = useRef<EventHandler>(() => {
        throw new Error('Cannot call an event handler while rendering.');
    });

    ref.current = fn;

    return useCallback((...args: Parameters<F>): ReturnType<F> => {
        const f = ref.current;
        return f(...args);
    }, []);
};

export default useEventCallback;
