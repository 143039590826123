import React, { useMemo } from 'react';
import styled from 'styled-components';
import T from 'prop-types';
import { pickVariant } from '@splunk/themes';
import { customThemeVariables } from '@splunk/dashboard-ui';
import { positionToStyle } from '../utils/layoutUtils';
import { ITEM_DROP_TARGET_PX } from '../GridLayoutConstants';

export const DropTarget = styled.div.attrs(({ position }) => ({
    style: positionToStyle(position),
}))`
    background-color: ${pickVariant('validDropTarget', {
        true: customThemeVariables.validDropTargetBackgroundColor,
        false: customThemeVariables.invalidDropTargetBackgroundColor,
    })};
    border-width: '1px';
    border-style: 'solid';
    border-color: ${pickVariant('validDropTarget', {
        true: customThemeVariables.validDropTargetBorderColor,
        false: customThemeVariables.invalidDropTargetBorderColor,
    })};
    user-select: none;
    position: absolute;
    pointer-events: none;
`;

export const ItemDropTarget = ({ position, direction }) => {
    const validDropTarget =
        ((direction === 'e' || direction === 'w') && position.w >= 128) ||
        ((direction === 'n' || direction === 's') && position.h >= 128);

    const dropTargetPosition = useMemo(() => {
        let updatedPosition = null;
        switch (direction) {
            case 'n':
                updatedPosition = {
                    ...position,
                    h: ITEM_DROP_TARGET_PX,
                };
                break;
            case 's':
                updatedPosition = {
                    ...position,
                    y: position.y + position.h - ITEM_DROP_TARGET_PX,
                    h: ITEM_DROP_TARGET_PX,
                };
                break;
            case 'w':
                updatedPosition = {
                    ...position,
                    w: ITEM_DROP_TARGET_PX,
                };
                break;
            case 'e':
                updatedPosition = {
                    ...position,
                    x: position.x + position.w - ITEM_DROP_TARGET_PX,
                    w: ITEM_DROP_TARGET_PX,
                };
                break;
            default:
                updatedPosition = position;
                break;
        }
        return updatedPosition;
    }, [position, direction]);

    // TO DO: currently with this if,
    // we are only rendering the invalid drop target. Refactor file to only use invalid
    if (validDropTarget) {
        return null;
    }

    return (
        <DropTarget
            data-test="item-drop-target"
            position={dropTargetPosition}
            validDropTarget={validDropTarget}
        />
    );
};

ItemDropTarget.propTypes = {
    position: T.shape({
        x: T.number.isRequired,
        y: T.number.isRequired,
        w: T.number.isRequired,
        h: T.number.isRequired,
    }).isRequired,
    direction: T.oneOf(['n', 's', 'w', 'e']).isRequired,
};
