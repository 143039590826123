import { Bar as BarIcon } from '@splunk/visualization-icons';
import { Bar as BarPlaceholderIcon } from '@splunk/visualization-icons/placeholders';
import { getPattern, COLOR_OR_TOKEN_PATTERN_WITH_RGBA } from '@splunk/visualizations-shared/schemaUtils';
import { VIZ_CATEGORICAL } from '@splunk/visualization-color-palettes';
import { _ } from '@splunk/ui-utils/i18n';
import pick from '@splunk/themes/pick';
import variables from '@splunk/themes/variables';
import { cloneDeep } from 'lodash';
import { AxesChartDataConfigBar } from '../common/editorConfig/AxesChartDataConfig';
import { AxesChartDataDisplayBar } from '../common/editorConfig/AxesChartDataDisplay';
import { AxesChartXGridAndLabelsCommon } from '../common/editorConfig/AxesChartXGridAndLabels';
import AxesChartColorAndStyle from '../common/editorConfig/AxesChartColorAndStyle';
import AxesChartLegend from '../common/editorConfig/AxesChartLegend';
import AxesChartYGridAndLabels from '../common/editorConfig/AxesChartYGridAndLabels';
import AxesChartY2GridAndLabels from '../common/editorConfig/AxesChartY2GridAndLabels';

import { DataContract } from '../common/interfaces/DataContract';
import { DefaultContext } from '../common/interfaces/DefaultContext';
import { EditorConfig } from '../common/interfaces/Editor';
import { OptionsSchema } from '../common/interfaces/OptionsSchema';
import { ThemedDefaults } from '../common/interfaces/ThemedDefaults';
import { VizBehavior } from '../common/interfaces/VizBehavior';
import { VizCategory } from '../common/interfaces/VizCategory';
import { VizConfig } from '../common/interfaces/VizConfig';
import { VizSize } from '../common/interfaces/VizSize';
import { enhanceConfig } from '../common/utils/configUtils';

const dataContract: DataContract = {
    requiredDataSources: [
        {
            name: 'primary',
            description: 'DataSource that powers the visualization',
        },
    ],
    optionalDataSources: [
        // Note: SCP-14090 disable ui for optional datasources. We may need to add it back later
        // {
        //     name: 'annotation',
        //     description: 'DataSource that populates event annotations',
        // },
    ],
    initialRequestParams: {
        primary: { offset: 0, count: 10000 },
    },
};

const size: VizSize = {
    initialWidth: 300,
    initialHeight: 300,
};

const defaultContext: DefaultContext = {};

const optionsSchema: OptionsSchema = {
    x: {
        default: '> primary | seriesByIndex(0)',
        description: 'Specify the data source to apply to the x-axis.',
        type: 'array',
        items: { type: ['string', 'number'] },
    },
    y: {
        default: '> primary | frameBySeriesIndexRange(1)',
        description: 'Specify the data source to apply to the y-axis.',
        type: 'array',
        items: { type: ['string', 'number'] },
    },
    y2: {
        description: 'Specify the data source to apply to the second y-axis',
        type: 'array',
        items: { type: ['string', 'number'] },
    },
    xField: {
        default: '> x | getField()',
        description: 'Specify the field to map to the x-axis.',
        type: 'string',
    },
    yFields: {
        default: '> y | getField()',
        description: 'Specify the field to map to the y-axis.',
        type: 'string',
    },
    y2Fields: {
        default: '> y2 | getField()',
        description: 'Specify one or more fields to map to a second y-axis.',
        type: ['array', 'string'],
        items: {
            type: 'string',
        },
    },
    backgroundColor: {
        default: '> themes.defaultBackgroundColor',
        description:
            'Specify the color used for the background. You can use a data source or hexadecimal code to apply the color.',
        pattern: COLOR_OR_TOKEN_PATTERN_WITH_RGBA,
        type: 'string',
    },
    barSpacing: {
        description: 'Specify the spacing (px) between columns in a bar chart.',
        type: 'number',
    },
    dataValuesDisplay: {
        default: 'off',
        description:
            'Specify the labels to display. Enter "all" to show lables for all data points, "off" to show no labels, or "minmax to show high and low values".',
        pattern: getPattern(['off', 'all', 'minmax']),
        type: 'string',
    },
    legendDisplay: {
        default: 'right',
        description: 'Specify the location of the legend on the panel.',
        pattern: getPattern(['right', 'left', 'top', 'bottom', 'off']),
        type: 'string',
    },
    legendLabels: {
        description:
            'Specify a list of labels to populate the legend in advance. For example, ["percent", "count"].',
        type: 'array',
        items: { type: 'string' },
    },
    legendMode: {
        default: 'standard',
        description:
            'Specify visual and behavioral settings for the tooltip and legend. "seriesCompare" is useful when comparing series.',
        pattern: getPattern(['standard', 'seriesCompare']),
        type: 'string',
    },
    legendTruncation: {
        default: 'ellipsisEnd',
        description:
            'Specify how to display legend labels when they overflow the layout boundaries by replacing overflow text with an ellipsis.',
        pattern: getPattern(['ellipsisEnd', 'ellipsisMiddle', 'ellipsisStart', 'ellipsisOff']),
        type: 'string',
    },
    lineWidth: {
        default: 2,
        description: 'Specify the line width (px) for overlay field lines.',
        type: 'number',
    },
    resultLimit: {
        default: 50000,
        description: 'Specify the number of data points to render in a chart.',
        type: 'number',
    },
    seriesColors: {
        default: VIZ_CATEGORICAL,
        description: 'Specify the colors to use in a series. For example, ["#FF0000", "#0000FF", "#008000"].',
        type: 'array',
        items: { type: 'string' },
    },
    seriesColorsByField: {
        description:
            'Specify the colors to use for specific fields in a series. For example, {“count”: “#008000”, “percent”: “#FFA500”}.',
        type: 'object',
    },
    seriesSpacing: {
        description: 'Specify the spacing (px) between clustered series in column and bar charts.',
        type: 'number',
    },
    stackMode: {
        default: 'auto',
        description: 'Specify stack mode.',
        pattern: getPattern(['auto', 'stacked', 'stacked100']),
        type: 'string',
    },
    showIndependentYRanges: {
        default: false,
        description: 'Specify whether split series charts have independent y-ranges.',
        type: 'boolean',
    },
    showSplitSeries: {
        default: false,
        description:
            'Specify whether to split a multi-series chart into separate charts that are stacked from top to bottom, one for each series.',
        type: 'boolean',
    },
    showXMajorGridLines: {
        default: false,
        description: 'Specify whether to show major grid lines on the x-axis.',
        type: 'boolean',
    },
    showYMajorGridLines: {
        default: true,
        description: 'Specify whether to show major grid lines on the y-axis.',
        type: 'boolean',
    },
    showY2MajorGridLines: {
        default: false,
        description: 'Specify whether to show major grid lines on the second y-axis.',
        type: 'boolean',
    },
    showYMinorGridLines: {
        default: false,
        description: 'Specify whether to show minor grid lines on the y-axis.',
        type: 'boolean',
    },
    showY2MinorGridLines: {
        default: false,
        description: 'Specify whether to show minor grid lines on the second y-axis.',
        type: 'boolean',
    },
    showYAxisExtendedRange: {
        default: true,
        description: 'Specify whether to extend the y-axis to include whole major tick marks.',
        type: 'boolean',
    },
    showYAxisWithZero: {
        default: false,
        description: 'Specify whether to include zero in the y-axis range.',
        type: 'boolean',
    },
    showY2AxisWithZero: {
        default: false,
        description: 'Specify whether to include zero the second y-axis range .',
        type: 'boolean',
    },
    xAxisLabelVisibility: {
        default: 'auto',
        description: 'Specify whether to show labels on the x-axis.',
        pattern: getPattern(['auto', 'show', 'hide']),
        type: 'string',
    },
    yAxisLabelVisibility: {
        default: 'auto',
        description: 'Specify whether to show labels on the y-axis.',
        pattern: getPattern(['auto', 'show', 'hide']),
        type: 'string',
    },
    y2AxisLabelVisibility: {
        default: 'auto',
        description: 'Specify whether to show labels on the second y-axis.',
        pattern: getPattern(['auto', 'show', 'hide']),
        type: 'string',
    },
    xAxisLineVisibility: {
        default: 'hide',
        description: 'Specify whether to show the x-axis line.',
        enum: ['hide', 'show'],
        pattern: getPattern(['show', 'hide']),
        type: 'string',
    },
    yAxisLineVisibility: {
        default: 'hide',
        description: 'Specify whether to show the y-axis line.',
        enum: ['hide', 'show'],
        pattern: getPattern(['show', 'hide']),
        type: 'string',
    },
    y2AxisLineVisibility: {
        default: 'hide',
        description: 'Specify whether to show the second y-axis line.',
        enum: ['hide', 'show'],
        pattern: getPattern(['show', 'hide']),
        type: 'string',
    },
    xAxisMajorTickSize: {
        default: 6,
        description: 'Specify the size (px) of major tick marks on the x-axis.',
        type: 'number',
    },
    yAxisMajorTickSize: {
        default: 6,
        description: 'Specify the size (px) of major tick marks on the y-axis.',
        type: 'number',
    },
    y2AxisMajorTickSize: {
        default: 6,
        description: 'Specify the size (px) of major tick marks on the second y-axis.',
        type: 'number',
    },
    yAxisMinorTickSize: {
        default: 6,
        description: 'Specify the size (px) of minor tick marks on the y-axis.',
        type: 'number',
    },
    y2AxisMinorTickSize: {
        default: 6,
        description: 'Specify the size (px) of minor tick marks on the second y-axis.',
        type: 'number',
    },
    xAxisMajorTickVisibility: {
        default: 'auto',
        description: 'Specify whether to show major tick marks on the x-axis.',
        pattern: getPattern(['auto', 'show', 'hide']),
        type: 'string',
    },
    yAxisMajorTickVisibility: {
        default: 'auto',
        description: 'Specify whether to show major tick marks on the y-axis.',
        pattern: getPattern(['auto', 'show', 'hide']),
        type: 'string',
    },
    y2AxisMajorTickVisibility: {
        default: 'auto',
        description: 'Specify whether to show major tick marks on the second y-axis.',
        pattern: getPattern(['auto', 'show', 'hide']),
        type: 'string',
    },
    yAxisMinorTickVisibility: {
        default: 'auto',
        description: 'Specify whether to show  minor tick marks on the y-axis.',
        pattern: getPattern(['auto', 'show', 'hide']),
        type: 'string',
    },
    y2AxisMinorTickVisibility: {
        default: 'auto',
        description: 'Specify whether to show minor tick marks on the second y-axis.',
        pattern: getPattern(['auto', 'show', 'hide']),
        type: 'string',
    },
    xAxisMaxLabelParts: {
        default: 3,
        minimum: 1,
        maximum: 3,
        description:
            'Specify the maximum number of time-parts for a tick label. The 3 possible parts are year, month, and time. Values can range from 1 to 3.',
        type: 'number',
    },
    yAxisScale: {
        default: 'linear',
        description: 'Specify the type of scale that applies to a numerical y-axis.',
        pattern: getPattern(['linear', 'log']),
        type: 'string',
    },
    y2AxisScale: {
        default: 'linear',
        description: 'Specify the type of scale that applies to a numerical second y-axis.',
        pattern: getPattern(['linear', 'log']),
        type: 'string',
    },
    xAxisTitleText: {
        description: 'Specify the title of the x-axis.',
        type: 'string',
    },
    yAxisTitleText: {
        description: 'Specify the title of the y-axis.',
        type: 'string',
    },
    y2AxisTitleText: {
        description: 'Specify the title of the second y-axis.',
        type: 'string',
    },
    xAxisTitleVisibility: {
        default: 'show',
        description: 'Specify whether to show the title of the x-axis.',
        enum: ['hide', 'show'],
        pattern: getPattern(['show', 'hide']),
        type: 'string',
    },
    yAxisTitleVisibility: {
        default: 'show',
        description: 'Specify whether to show the title of the y-axis.',
        enum: ['hide', 'show'],
        pattern: getPattern(['show', 'hide']),
        type: 'string',
    },
    y2AxisTitleVisibility: {
        default: 'show',
        description: 'Specify whether to show the title of the second y-axis.',
        enum: ['hide', 'show'],
        pattern: getPattern(['show', 'hide']),
        type: 'string',
    },
    yAxisAbbreviation: {
        default: 'auto',
        description:
            'Specify whether to abbreviate large y-axis values with the closest International System of Units (SI) prefix.',
        enum: ['auto', 'off'],
        pattern: getPattern(['off', 'auto']),
        type: 'string',
    },
    y2AxisAbbreviation: {
        default: 'auto',
        description:
            'Specify whether to abbreviate large y2-axis values with the closest International System of Units (SI) prefix.',
        enum: ['auto', 'off'],
        pattern: getPattern(['off', 'auto']),
        type: 'string',
    },
    yAxisMajorTickInterval: {
        default: 'auto',
        description: 'Specify the spacing unit between major tick marks along the numeric y-axis.',
        type: ['string', 'number'],
    },
    y2AxisMajorTickInterval: {
        default: 'auto',
        description: 'Specify the spacing unit between major tick marks along the numeric second y-axis.',
        type: ['string', 'number'],
    },
    // TODO(SCP-40274): Restrict string value to only 'auto' to achieve precise schema validation
    yAxisMax: {
        default: 'auto',
        description: 'Specify the largest value to display in the visible y-axis range.',
        type: ['string', 'number'],
    },
    // TODO(SCP-40274): Restrict string value to only 'auto' to achieve precise schema validation
    y2AxisMax: {
        default: 'auto',
        description: 'Specify the largest value to display in the visible second y-axis range.',
        type: ['string', 'number'],
    },
    // TODO(SCP-40274): Restrict string value to only 'auto' to achieve precise schema validation
    yAxisMin: {
        default: 'auto',
        description: 'Specify the smallest value for the visible y-axis range.',
        type: ['string', 'number'],
    },
    // TODO(SCP-40274): Restrict string value to only 'auto' to achieve precise schema validation
    y2AxisMin: {
        default: 'auto',
        description: 'Specify the smallest value for the visible second y-axis range.',
        type: ['string', 'number'],
    },
    overlayFields: {
        description:
            'Specify one or more fields to differentiate on the chart and display as chart overlays.',
        type: ['array', 'string'],
    },
    showOverlayY2Axis: {
        default: false,
        description: 'Enable a second y-axis for chart overlays. All overlay fields map to a second y-axis.',
        type: 'boolean',
    },
    showRoundedY2AxisLabels: {
        default: true,
        description: 'Specify whether to round second y-axis values to the nearest integer.',
        type: 'boolean',
    },
};

const themes: ThemedDefaults = {
    defaultBackgroundColor: props =>
        pick({
            enterprise: {
                dark: variables.black(props),
                light: variables.backgroundColor(props),
            },
            prisma: variables.backgroundColorSidebar(props),
        })(props),
};

const updatedAxesChartLegend = cloneDeep(AxesChartLegend);
updatedAxesChartLegend.layout.push([
    {
        label: _('Legend mode'),
        option: 'legendMode',
        editor: 'editor.radioBar',
        editorProps: {
            values: [
                { label: _('Standard'), value: 'standard' },
                { label: _('Compare series'), value: 'seriesCompare' },
            ],
        },
    },
]);

const editorConfig: EditorConfig[] = [
    AxesChartDataConfigBar,
    AxesChartDataDisplayBar,
    {
        label: AxesChartColorAndStyle.label,
        layout: [
            ...AxesChartColorAndStyle.layout,
            [
                {
                    label: _('Line width'),
                    option: 'lineWidth',
                    editor: 'editor.slider',
                    editorProps: {
                        min: 0.5,
                        max: 8.0,
                        step: 0.5,
                    },
                    showEditor: ({ options }) =>
                        Array.isArray(options.overlayFields) && options.overlayFields.length > 0,
                },
            ],
            [
                {
                    label: _('Series spacing'),
                    option: 'seriesSpacing',
                    editor: 'editor.number',
                },
            ],
            [
                {
                    label: _('Bar spacing'),
                    option: 'barSpacing',
                    editor: 'editor.number',
                },
            ],
        ],
    },
    updatedAxesChartLegend,
    {
        label: _('X-axis grid and labels (vertical axis)'),
        layout: [
            ...AxesChartXGridAndLabelsCommon.layout,
            [
                {
                    label: _('Number of time label parts'),
                    option: 'xAxisMaxLabelParts',
                    editor: 'editor.number',
                    editorProps: {
                        min: 1,
                        max: 3,
                    },
                    showEditor: ({ options }) =>
                        options.xAxisLabelVisibility === 'show' ||
                        options.xAxisLabelVisibility === 'auto' ||
                        options.xAxisLabelVisibility === undefined,
                },
            ],
            [
                {
                    label: _('Axis line visibility'),
                    option: 'xAxisLineVisibility',
                    editor: 'editor.checkbox',
                },
            ],
            [
                {
                    label: _('Major gridlines'),
                    option: 'showXMajorGridLines',
                    editor: 'editor.checkbox',
                },
            ],
            [
                {
                    label: _('Major tick visibility'),
                    option: 'xAxisMajorTickVisibility',
                    editor: 'editor.radioBar',
                    editorProps: {
                        values: [
                            { label: _('Auto'), value: 'auto' },
                            { label: _('Show'), value: 'show' },
                            { label: _('Hide'), value: 'hide' },
                        ],
                    },
                },
            ],
            [
                {
                    label: _('Major tick size (px)'),
                    option: 'xAxisMajorTickSize',
                    editor: 'editor.slider',
                    editorProps: {
                        min: 0,
                        max: 10,
                        step: 1,
                    },
                    showEditor: ({ options }) =>
                        options.xAxisMajorTickVisibility === 'show' ||
                        options.xAxisMajorTickVisibility === 'auto' ||
                        options.xAxisMajorTickVisibility === undefined,
                },
            ],
        ],
    },
    // bar viz supports custom labels for the x- and y-axis titles (vertical and horizontal axes, respectively)
    { ...AxesChartYGridAndLabels, label: _('Y-axis grid and labels (horizontal axis)') },
    AxesChartY2GridAndLabels,
];

/**
 * visualization configuration
 */
const config: VizConfig = {
    /**
     * unique viz key
     */
    key: 'splunk.bar',
    /**
     * viz name
     */
    name: 'Bar',
    category: VizCategory.COMPARISONS,
    /**
     * viz icon
     */
    icon: BarIcon,
    placeholderIcon: BarPlaceholderIcon,
    dataContract,
    size,
    defaultContext,
    optionsSchema,
    editorConfig,
    events: {
        'legend.click': {
            description: 'triggered when user clicks on chart legend',
        },
        'point.click': {
            description: 'triggered when user clicks on a point in the bar chart',
        },
        'point.mouseover': {
            description: 'triggered when user hovers over a data point',
        },
        'point.mouseout': {
            description: 'triggered when user moves out of a data point',
        },
    },
    supports: [VizBehavior.DYNAMIC_OPTIONS, VizBehavior.PLACEHOLDER, VizBehavior.EVENTS],
    themes,
};

export default enhanceConfig(config);
