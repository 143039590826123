import { cloneDeep } from 'lodash';
import { findClosestMatchFromDefaultList } from './mapUtils';

/**
 * helper to deeply merge objects while preserving arrays in earlier source objects
 * deeply nested primitive keys are merged similarly to lodash's defaultsDeep, while keys with arrays of primitives will be preserved if already present in the resultant merged object
 * this prevents default arrays of primitives from appending values to user-defined arrays (this occurs if the default array is longer than the user-defined one).
 * Array of objects gets merged with the defaults, where the defaults can be an array of objects. Closest matching default object from the defaults list based on key - value pair
 * will be used for merging defaults for each object in the array. If no closest match found, first object in default array list used for merging.
 *
 * @method deepMergeWithArrayPrimitiveOverrides
 * @param {Object} initial - target object to merge values into
 * @param {Object[]} sources - other default options to be merged into the resultant object if no explicit value is provided, with earlier args taking precedence over later ones
 *
 * @returns {Object}
 */
export const deepMergeWithArrayPrimitiveOverrides = (initial, ...sources) => {
    const clone = cloneDeep(initial);
    sources.forEach(sourceToApply => {
        Object.keys(sourceToApply).forEach(sourceKey => {
            if (!Object.prototype.hasOwnProperty.call(clone, sourceKey)) {
                clone[sourceKey] = cloneDeep(sourceToApply[sourceKey]);
            }
            if (
                clone[sourceKey] &&
                typeof clone[sourceKey] === 'object' &&
                !Array.isArray(clone[sourceKey]) &&
                typeof sourceToApply[sourceKey] === 'object'
            ) {
                clone[sourceKey] = deepMergeWithArrayPrimitiveOverrides(
                    clone[sourceKey],
                    sourceToApply[sourceKey]
                );
            } else if (
                Array.isArray(clone[sourceKey]) &&
                typeof clone[sourceKey][0] === 'object' &&
                !Array.isArray(clone[sourceKey][0])
            ) {
                // If option is an array of objects
                for (let i = 0; i < clone[sourceKey].length; i += 1) {
                    if (typeof clone[sourceKey][i] === 'object') {
                        const closestMatchIdx = findClosestMatchFromDefaultList(
                            sourceToApply[sourceKey],
                            clone[sourceKey][i]
                        );
                        if (closestMatchIdx > -1) {
                            clone[sourceKey][i] = deepMergeWithArrayPrimitiveOverrides(
                                clone[sourceKey][i],
                                sourceToApply[sourceKey][closestMatchIdx]
                            );
                        }
                    }
                }
            }
        });
    });
    return clone;
};
