import type { ReactNode } from 'react';
import styled from 'styled-components';

export type BorderType = 'hover' | 'select' | 'move' | 'none';
// TODO: in the new implementation we want to let the parent calculate xywh based on the padding, i.e., call the applyVizPadding() in parent
export interface ResponsiveBoxProps {
    itemId: string;
    appearance?: 'hidden' | 'visible' | 'highlighted';
    x: number;
    y: number;
    w: number;
    h: number;
    'data-test'?: string;
    children: ReactNode;
    zIndex?: number;
}

const ResponsiveBox = styled.div.attrs<ResponsiveBoxProps>(
    ({ itemId, x, y, w, h, 'data-test': dataTest, zIndex }) => {
        const attributes = {
            'data-test': dataTest ?? 'responsive-box',
            'data-id': itemId,
            'data-test-viz-item-position': `${x},${y},${w},${h}`,
            style: {
                width: `${w}px`,
                height: `${h}px`,
                top: `${y}px`,
                left: `${x}px`,
                zIndex,
            },
        };

        if (!zIndex) {
            delete attributes.style.zIndex;
        }

        return attributes;
    }
)<ResponsiveBoxProps>`
    display: block;
    position: absolute;
    background: transparent;
    pointer-events: none;
    box-sizing: border-box;
    visibility: ${(props) => {
        return props.appearance === 'hidden' ? 'hidden' : 'visible';
    }};
    opacity: ${(props) => (props.appearance === 'highlighted' ? 1 : 0.5)};
`;

ResponsiveBox.defaultProps = {
    appearance: 'highlighted',
};

export default ResponsiveBox;
