import React, { useMemo, useRef, useEffect } from 'react';
import T from 'prop-types';
import { _ } from '@splunk/ui-utils/i18n';
import SUINumber from '@splunk/react-ui/Number';
import { NumberInput as NumberIcon } from '@splunk/dashboard-icons';
import { debounce, get, mapKeys } from 'lodash';
import BaseInput from '../components/BaseInput';
import { withInputWrapper } from '../utils/enhancer';
import schema from './NumberSchema';

const NumberInput = ({
    id,
    options: { min, max, step, defaultValue },
    value,
    onValueChange,
    isDisabled,
    disabledMessage,
    errorMessage,
    isError,
    isSelected,
}) => {
    const handleChange = useMemo(
        () => debounce((e, payload) => onValueChange(e, payload.value), 250),
        [onValueChange]
    );
    const consolidatedValue = value != null ? value : defaultValue;
    // if there is no value or defaultValue, show input as blank by passing null
    const displayValue =
        consolidatedValue != null ? Number(consolidatedValue) : null;
    const inputRef = useRef(null);

    let message;
    if (isError) {
        message = errorMessage;
    } else if (isDisabled) {
        message = disabledMessage;
    }

    useEffect(() => {
        // When input is selected, make sure we focus the textbox
        if (isSelected && !isDisabled && !!inputRef.current) {
            inputRef.current.focus({ preventScroll: true });
        }
    }, [isSelected, isDisabled]);

    return (
        <SUINumber
            min={min}
            max={max}
            step={step}
            error={isError}
            disabled={isDisabled}
            inputId={id}
            onChange={handleChange}
            // we want to show disabled message if the input is disabled
            value={message ? null : displayValue}
            placeholder={message}
            inputRef={inputRef}
        />
    );
};

NumberInput.propTypes = {
    ...BaseInput.propTypes,
    options: T.shape({
        min: T.number,
        max: T.number,
        step: T.number,
        defaultValue: T.number,
    }),
    value: T.number,
};

NumberInput.defaultProps = {
    ...BaseInput.defaultProps,
};

const editor = [
    {
        enableCollapsible: false,
        enableSeparator: false,
        layout: [
            [
                {
                    label: _('Token name'),
                    option: 'token',
                    editor: 'editor.text',
                },
            ],
            [
                {
                    label: _('Min'),
                    option: 'min',
                    editor: 'editor.number',
                    placeholder: 'e.g. 0',
                },
                {
                    label: _('Max'),
                    option: 'max',
                    editor: 'editor.number',
                    placeholder: 'e.g. 100',
                },
                {
                    label: _('Step'),
                    option: 'step',
                    editor: 'editor.number',
                    editorProps: {
                        min: 1,
                    },
                },
            ],
            [
                {
                    label: _('Default value'),
                    option: 'defaultValue',
                    editor: 'editor.number',
                },
            ],
        ],
    },
];
const meta = {
    label: _('Number'),
    description: _('Field to enter a number'),
    defaultConfig: {
        options: {
            defaultValue: 10,
        },
        title: _('Number Input Title'),
    },
    tokenPrefix: 'num',
    icon: NumberIcon,
};

NumberInput.config = {
    editorConfig: editor,
    optionsSchema: schema,
    ...mapKeys(meta, (_value, key) =>
        key === 'defaultConfig' ? 'baseShape' : key
    ),
};

/**
 * Transforms the value or values from the input to a set of token: value pairs
 * @param {String} value value of the number input
 * @param {Object} meta
 * @param {String} meta.token The token name
 * @returns {Object}
 */
NumberInput.valueToTokens = (value, { token }) => {
    if (!token) {
        return {};
    }
    if (value == null) {
        return {
            [token]: null,
        };
    }
    return {
        [token]: `${value}`,
    };
};

/**
 * Transforms a set of tokens belonging to the input to a valid input value
 * @param {Object} tokens set of submitted tokens
 * @param {String} tokenName the token name associated with the input
 * @param {String} tokenNamespace the namespace the token belongs to
 * @returns {Number|null}
 */
NumberInput.tokensToValue = ({ tokens, tokenName, tokenNamespace }) => {
    if (!tokens || !tokenName || !tokenNamespace) {
        return null;
    }

    // if token is a string, convert to number
    const value = Number(get(tokens, [tokenNamespace, tokenName]));

    return Number.isNaN(value) ? null : value;
};

/**
 * validates the static input definition - options/context and returns all the error messages accordingly
 * if no error is found, returns an empty object with errorMessages set to []
 * @param {Object} [inputDef={}]
 * @param {Object} [inputDef.options] input options
 * @returns {Object}
 */
NumberInput.validate = (inputDef = {}) => {
    const options = get(inputDef, 'options', {});
    const errorMessages = [];
    const { min, max } = options;
    if (min !== undefined && max !== undefined && min > max) {
        errorMessages.push('min should be less than max');
    }
    return { errorMessages };
};

export default withInputWrapper(NumberInput);
