import React, { Component } from 'react';
import { pick, defer, noop, isEqual, debounce, max } from 'lodash';
import { getClickHandler, enableClickHandler } from '@splunk/dashboard-visualizations/utils/eventUtils';
import BaseVisualization from '@splunk/dashboard-visualizations/common/BaseVisualization';
import { toDimension, toPx } from '@splunk/visualizations-shared/style';
import styled from 'styled-components';
import { _ } from '@splunk/ui-utils/i18n';
// need to name as pickFromTheme since we're using lodash/pick
import pickFromTheme from '@splunk/themes/pick';
import variables from '@splunk/themes/variables';
import optionsSchema from './optionsSchema';
import editorConfig from './editorConfig';
import TextApi from './TextApi';
import { withSanitizedOptions } from '../../utils/enhancer';

const TextContainer = styled.div.attrs(() => ({
    'data-test': 'text-container',
}))`
    position: relative;
    ${props => toDimension(pick(props, ['width', 'height']))};
    outline: none;
    background-color: ${props => props.backgroundColor || 'transparent'};
    font-family: ${props => props.fontFamily || 'Splunk Platform Sans'};
    font-size: ${props => toPx(props.fontSize || 24)};
    font-weight: ${props => (props.fontWeight ? props.fontWeight : 'normal')};
    line-height: ${props => toPx(props.lineHeight || '120%')};
    transform-origin: center;
    transform: rotate(${props => props.rotation || 0}deg);
    &:hover {
        cursor: ${props => (props.isClickable ? 'pointer' : 'all-scroll')};
    }
`;

const StyledTextArea = styled.textarea`
    font: inherit;
    line-height: inherit;
    outline: inherit;
    width: 100%;
    border: none;
    padding: 0;
    resize: none;
    overflow: auto; /* Remove permanent scroll bars on IE 11 */
    &:hover {
        cursor: auto;
    }
`;

const textareaHeightOffset = 15;
const getTextareaHeight = height => max([height - textareaHeightOffset, 0]);

const InputTextArea = styled(StyledTextArea).attrs(props => ({
    placeholder: props.disabled ? undefined : _('Enter text here'),
    'data-test': 'text-content',
}))`
    textarea&,
    textarea[disabled]& {
        margin: ${textareaHeightOffset}px 5px;
        height: ${props => toPx(props.height)};
        background-color: transparent;
        color: ${props =>
            props.textColor ||
            pickFromTheme({
                enterprise: {
                    dark: variables.white,
                    light: variables.gray20,
                },
                prisma: variables.contentColorDefault,
            })};
        border: none;
    }
    &[disabled] {
        cursor: default;
        pointer-events: none;
        text-decoration: ${props => (props.shouldUnderlineText ? 'underline' : 'none')};
    }
`;

/*
 * The shadow textarea element is used to determine the dynamic height
 * of the visible textarea.
 */
const ShadowTextArea = styled(StyledTextArea)`
    overflow: hidden;
    visibility: hidden;
    position: absolute;
    left: -10000px;
    top: -10000px;
`;

class TextVisualization extends Component {
    static propTypes = {
        ...BaseVisualization.propTypes,
    };

    static defaultProps = {
        ...BaseVisualization.defaultProps,
    };

    static vizContract = {
        initialDimension: {
            width: 300,
            height: 50,
        },
    };

    static schema = optionsSchema;

    static editor = editorConfig;

    constructor(props) {
        super(props);
        this.state = {
            height: null,
            content: null,
            isClickable: false,
            shouldUnderlineText: false,
        };
        this.api = new TextApi(this);
        this.debouncedOnOptionsChange = debounce(this.props.onOptionsChange, 2000);
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        const { hasEventHandlers, mode } = nextProps;
        // Check whether the text div is clickable in view mode (when drilldown onclick is added)
        const isClickable = enableClickHandler(hasEventHandlers, mode);
        return isClickable === prevState.isClickable ? null : { isClickable };
    }

    componentDidMount() {
        defer(this.handleContentChange);
        // send back the viz api
        this.props.visualizationApiRef(this.api);
    }

    componentDidUpdate(prevProps) {
        if (!isEqual(prevProps.options, this.props.options)) {
            // eslint-disable-next-line react/no-did-update-set-state
            this.setState({
                content: null,
            });
            defer(this.handleContentChange);
        }
        if (prevProps.height !== this.props.height || prevProps.width !== this.props.width) {
            defer(this.updateDimensions);
        }
    }

    componentWillUnmount() {
        this.props.visualizationApiRef(null);
    }

    handleContentChange = contentEl => {
        if (this.shadowEl) {
            let nuContent = null;
            if (contentEl) {
                nuContent = contentEl.target.value;
                this.shadowEl.value = nuContent;
            }
            this.setState({
                height: this.shadowEl.scrollHeight,
                ...(nuContent !== null && { content: nuContent }),
            });
            if (this.props.mode === 'edit' && nuContent !== null) {
                this.debouncedOnOptionsChange({
                    ...this.props.options,
                    ...{
                        content: nuContent,
                    },
                });
            }
        }
    };

    updateDimensions = () => {
        if (this.shadowEl) {
            this.setState({ height: max(this.shadowEl.scrollHeight, getTextareaHeight(this.props.height)) });
        }
    };

    handleMouseDown = evt => {
        evt.stopPropagation();
        this.props.onSelected(evt, [this.props.id]);
    };

    handleBlur = evt => {
        this.debouncedOnOptionsChange({
            ...this.props.options,
            ...{
                content: evt.target.value,
            },
        });
        this.debouncedOnOptionsChange.flush();
    };

    // If drilldown (onClick) is added, in view mode, mouseover on text div underlines the text
    handleMouseOver = evt => {
        evt.stopPropagation();
        const { isClickable } = this.state;
        this.setState({ shouldUnderlineText: isClickable });
    };

    handleMouseOut = evt => {
        evt.stopPropagation();
        this.setState({ shouldUnderlineText: false });
    };

    render() {
        const {
            mode,
            width,
            height,
            onEventTrigger,
            options: {
                rotation,
                textColor,
                color, // color option needs to be deprecated in the future
                backgroundColor,
                fontFamily,
                fontSize,
                fontWeight,
                lineHeight,
                content,
            },
        } = this.props;
        const { isClickable, shouldUnderlineText } = this.state;
        const textAreaHeight = this.state.height || getTextareaHeight(height);
        const textAreaContent = this.state.content === null ? content : this.state.content;
        const onClick = isClickable ? getClickHandler(onEventTrigger, {}, 'text.click') : () => {};
        return (
            <TextContainer
                width={width}
                height={height}
                rotation={rotation}
                backgroundColor={backgroundColor}
                fontFamily={fontFamily}
                fontSize={fontSize}
                fontWeight={fontWeight}
                lineHeight={lineHeight}
                onClick={onClick}
                isClickable={isClickable}
                onMouseOver={this.handleMouseOver}
                onMouseOut={this.handleMouseOut}
            >
                <InputTextArea
                    onChange={this.handleContentChange}
                    onMouseDown={this.handleMouseDown}
                    height={textAreaHeight}
                    textColor={textColor || color}
                    ref={inputTextArea => {
                        this.inputTextArea = inputTextArea;
                        return inputTextArea;
                    }}
                    value={textAreaContent}
                    onBlur={this.handleBlur}
                    disabled={mode !== 'edit'}
                    shouldUnderlineText={shouldUnderlineText}
                />
                <ShadowTextArea
                    tabIndex={-1}
                    ref={shadowEl => {
                        this.shadowEl = shadowEl;
                        return shadowEl;
                    }}
                    value={textAreaContent}
                    onChange={noop}
                />
            </TextContainer>
        );
    }
}

export default withSanitizedOptions(TextVisualization);
