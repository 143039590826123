import ICoordinateTransformation, { DataPoint, isSpaceCompatible, Space } from './ICoordinateTransformation';
import TransformationChain from './TransformationChain';

export default abstract class CoordinateTransformation implements ICoordinateTransformation {
    public sourceSpace: Space;

    public targetSpace: Space;

    static readonly INCOMPATIBLE_SPACES_ERROR =
        "incompatible transformation (appended source space doesn't match chain's output space)";

    protected constructor(sourceSpace: Space, targetSpace: Space) {
        this.sourceSpace = sourceSpace;
        this.targetSpace = targetSpace;
    }

    public appendTransformation(transformation: ICoordinateTransformation): ICoordinateTransformation {
        if (!isSpaceCompatible(this.targetSpace, transformation.sourceSpace)) {
            throw new Error(CoordinateTransformation.INCOMPATIBLE_SPACES_ERROR);
        }
        return new TransformationChain([this, transformation]);
    }

    abstract transform(sourcePoint: DataPoint): DataPoint;

    abstract transformBack(sourcePoint: DataPoint): DataPoint;
}
