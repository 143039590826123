import { _ } from '@splunk/ui-utils/i18n';
import { isDynamicOption } from '@splunk/visualizations-shared/configUtils';

export const getDynamicStrokeColorEditor = ({ defaultContext, defaultPalettesConfig }) => ({
    label: _('Stroke'),
    option: 'strokeColor',
    context: 'strokeColorEditorConfig',
    editor: 'editor.dynamicColor',
    showEditor: ({ options }) => isDynamicOption(options.strokeColor),
    editorProps: {
        labelPosition: 'top',
        flyoutTitle: _('Dynamic coloring: stroke'),
        formatters: [
            {
                label: _('Ranges'),
                value: 'rangeValue',
                defaults: {
                    strokeDataValue: defaultContext.strokeColorConfig,
                },
            },
            {
                label: _('Matches'),
                value: 'matchValue',
                defaults: {
                    strokeDataValue: defaultContext.strokeColorMatchConfig,
                },
            },
        ],
        dataSelectors: [
            {
                label: _('Stroke'),
                value: 'strokeDataValue',
            },
        ],
        defaultPalettesConfig,
    },
});
