/* eslint-disable class-methods-use-this */
import { _ } from '@splunk/ui-utils/i18n';

/**
 * Interface for a Content Export client.
 * A UDF consumer can use this to encapsulate API workflow implementation.
 */
class BaseContentExportClient {
    async initiateExport(): Promise<void> {
        throw new Error(_('initiateExport() not implemented.'));
    }
}

export default BaseContentExportClient;
