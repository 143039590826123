import { Sankey as SankeyIcon } from '@splunk/visualization-icons';
import { Sankey as SankeyPlaceholderIcon } from '@splunk/visualization-icons/placeholders';
import { VIZ_CATEGORICAL } from '@splunk/visualization-color-palettes';
import { COLOR_OR_TOKEN_PATTERN_WITH_RGBA, getPattern } from '@splunk/visualizations-shared/schemaUtils';
import { defaultPalettesConfig } from '@splunk/visualization-color-palettes/editors/PresetPalettes';
import { pick, variables } from '@splunk/themes';
import { _ } from '@splunk/ui-utils/i18n';
import { DataContract } from '../common/interfaces/DataContract';
import { DefaultContext } from '../common/interfaces/DefaultContext';
import { EditorConfig } from '../common/interfaces/Editor';
import { OptionsSchema } from '../common/interfaces/OptionsSchema';
import { ThemedDefaults } from '../common/interfaces/ThemedDefaults';
import { VizBehavior } from '../common/interfaces/VizBehavior';
import { VizCategory } from '../common/interfaces/VizCategory';
import { VizConfig } from '../common/interfaces/VizConfig';
import { VizSize } from '../common/interfaces/VizSize';
import { enhanceConfig } from '../common/utils/configUtils';
import { getDynamicLinkColorsEditor } from '../common/editorConfig/DynamicLinkColorsEditor';
import getBackgroundColorEditor from '../common/editorConfig/BackgroundColor';

const defaultContext: DefaultContext = {
    linkColorRangeConfig: [
        { to: 20, value: '#D41F1F' },
        { from: 20, to: 40, value: '#D94E17' },
        { from: 40, to: 60, value: '#CBA700' },
        { from: 60, to: 80, value: '#669922' },
        { from: 80, value: '#118832' },
    ],
    linkColorMatchConfig: [{ match: '', value: '#5C33FF' }],
};

const dataContract: DataContract = {
    requiredDataSources: [
        {
            name: 'primary',
            description: 'DataSource that powers the visualization',
        },
    ],
    optionalDataSources: [],
    initialRequestParams: {
        primary: { offset: 0, count: 10000 },
    },
};

const size: VizSize = {
    initialWidth: 600,
    initialHeight: 300,
};

const optionsSchema: OptionsSchema = {
    backgroundColor: {
        default: '> themes.defaultBackgroundColor',
        description:
            'Specify the color for the background. You may use a dataSource to apply the color. The default for enterprise light is "#ffffff". The default for enterprise dark is "#000000". The default for prisma dark is "#0b0c0e".',
        pattern: COLOR_OR_TOKEN_PATTERN_WITH_RGBA,
        type: 'string',
    },
    colorMode: {
        default: 'categorical',
        description:
            'Specify the coloring method used for the links. When set to "categorical" the nodes and links will be colored based on the "seriesColors". When set to "dynamic" the links will be colored based on the dynamic string assigned to "linkColors".',
        pattern: getPattern(['categorical', 'dynamic']),
        type: 'string',
    },
    linkColors: {
        default: `> linkValues | rangeValue(linkColorRangeConfig)`,
        description:
            'Specify the coloring method used for the links when the colorMode “dynamic” is specified.',
        type: 'array',
        items: {
            type: 'string',
            pattern: COLOR_OR_TOKEN_PATTERN_WITH_RGBA,
        },
    },
    linkOpacity: {
        default: 0.5,
        description: 'Specify the opacity of the links. Choose a number in the range of 0 - 1 (inclusive).',
        type: 'number',
    },
    linkValues: {
        default: `> primary | seriesByType('number')`,
        description: 'Specify the dataSource to apply link width.',
        type: 'string',
    },
    resultLimit: {
        default: 1000,
        description: 'Specify the maximum length of link data points rendered.',
        type: 'number',
    },
    seriesColors: {
        default: VIZ_CATEGORICAL,
        description: 'Specify the colors used for a series. For example, ["#FF0000", "#0000FF", "#008000"].',
        type: 'array',
        items: {
            type: 'string',
        },
    },
};

const themes: ThemedDefaults = {
    defaultBackgroundColor: props =>
        pick({
            enterprise: {
                dark: variables.black(props),
                light: variables.backgroundColor(props),
            },
            prisma: variables.backgroundColorSidebar(props),
        })(props),
    nodeTextColor: props =>
        pick({
            enterprise: {
                dark: variables.textColor(props),
                light: variables.textColor(props),
            },
            prisma: variables.contentColorActive(props),
        })(props),
    tooltipRowColor: props =>
        pick({
            enterprise: {
                dark: variables.textColor(props),
                light: variables.textColor(props),
            },
            prisma: variables.contentColorActive(props),
        })(props),
    tooltipLinkHeaderColor: props =>
        pick({
            enterprise: {
                dark: variables.textColor(props),
                light: variables.textColor(props),
            },
            prisma: variables.contentColorActive(props),
        })(props),
    tooltipHeaderColor: props =>
        pick({
            enterprise: {
                dark: variables.textGray(props),
                light: variables.textGray(props),
            },
            prisma: variables.contentColorMuted(props),
        })(props),
};

const editorConfig: EditorConfig[] = [
    {
        label: _('Data configurations'),
        layout: [
            [
                {
                    label: 'Link value',
                    editor: 'editor.columnSelector',
                    option: 'linkValues',
                    context: 'linkValuesContext',
                    editorProps: {
                        dataSourceKey: 'primary',
                        filterByTypes: ['number'],
                    },
                },
            ],
        ],
    },
    {
        label: _('Color and style'),
        layout: [
            [
                {
                    label: _('Color mode'),
                    option: 'colorMode',
                    editor: 'editor.radioBar',
                    editorProps: {
                        values: [
                            { label: _('Categorical'), value: 'categorical' },
                            { label: _('Dynamic'), value: 'dynamic' },
                        ],
                    },
                },
            ],
            [
                {
                    ...getDynamicLinkColorsEditor({ defaultContext, defaultPalettesConfig }),
                    showEditor: ({ options }) => options.colorMode === 'dynamic',
                },
            ],
            getBackgroundColorEditor({ themes }),
        ],
    },
];

/**
 * visualization configuration
 */
const config: VizConfig = {
    /**
     * unique viz key
     */
    key: 'splunk.sankey',
    /**
     * viz name
     */
    name: 'Sankey',
    category: VizCategory.FLOW,
    status: 'preview',
    /**
     * viz icon
     */
    icon: SankeyIcon,
    placeholderIcon: SankeyPlaceholderIcon,
    dataContract,
    size,
    defaultContext,
    optionsSchema,
    editorConfig,
    events: {},
    supports: [VizBehavior.DYNAMIC_OPTIONS, VizBehavior.EVENTS, VizBehavior.PLACEHOLDER],
    themes,
    requiredProps: [],
};

export default enhanceConfig(config);
