import React from 'react';
import { _ } from '@splunk/ui-utils/i18n';
import SVG, {
    type SVGPropsOptionalViewBox,
} from '@splunk/react-icons/SVGEnterprise';

/* eslint-disable max-len */
export default function Configuration(
    props: SVGPropsOptionalViewBox
): ReturnType<typeof SVG> {
    return (
        <SVG
            viewBox="0 0 24 24"
            screenReaderText={_('Configuration')}
            {...props}
        >
            <path
                fill="currentColor"
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12 16.2297C9.66801 16.2297 7.77028 14.332 7.77028 12C7.77028 9.66801 9.66801 7.77028 12 7.77028C14.332 7.77028 16.2297 9.66801 16.2297 12C16.2297 14.332 14.332 16.2297 12 16.2297ZM20.1173 10.1173H19.2723C19.0947 9.43115 18.8258 8.78447 18.4743 8.18855L18.9687 7.69508C19.7037 6.96005 19.7037 5.76727 18.9687 5.03224C18.6002 4.66472 18.1181 4.4805 17.6368 4.4805C17.1546 4.4805 16.6724 4.66472 16.3049 5.03224L15.8114 5.52571C15.2155 5.17417 14.5689 4.90535 13.8827 4.7277V3.88364C13.8827 2.84312 13.0396 2 12 2C10.9604 2 10.1173 2.84312 10.1173 3.88364V4.7277C9.47345 4.89407 8.86249 5.13939 8.29852 5.45991L7.86991 5.03224C7.5024 4.66472 7.02021 4.4805 6.53802 4.4805C6.05677 4.4805 5.57458 4.66472 5.20707 5.03224C4.47204 5.76727 4.47204 6.96005 5.20707 7.69508L5.59244 8.08046C5.20895 8.70552 4.91569 9.38885 4.7277 10.1173H3.88364C2.84312 10.1173 2 10.9604 2 12C2 13.0396 2.84312 13.8827 3.88364 13.8827H4.7277C4.93167 14.6713 5.25595 15.4092 5.68644 16.0746L5.20707 16.5531C4.47204 17.2881 4.47204 18.4809 5.20707 19.2168C5.57458 19.5844 6.05677 19.7676 6.53802 19.7676C7.02021 19.7676 7.5024 19.5844 7.86991 19.2168L8.45549 18.6313C8.97528 18.9095 9.5336 19.1219 10.1173 19.2723V20.1164C10.1173 21.1569 10.9604 22 12 22C13.0396 22 13.8827 21.1569 13.8827 20.1164V19.2723C14.5087 19.1106 15.1056 18.8756 15.6573 18.5683L16.3049 19.2168C16.6724 19.5844 17.1546 19.7676 17.6368 19.7676C18.1181 19.7676 18.6002 19.5844 18.9687 19.2168C19.7037 18.4809 19.7037 17.2881 18.9687 16.5531L18.3803 15.9656C18.777 15.3283 19.0796 14.629 19.2723 13.8827H20.1173C21.1569 13.8827 22 13.0396 22 12C22 10.9604 21.1569 10.1173 20.1173 10.1173Z"
            />
        </SVG>
    );
}
