import { mapValues, cloneDeep, isEqual } from 'lodash';
import { Interval } from './Scale';

export interface Dimension {
    unit: string;
}
export interface Space {
    [axis: string]: Dimension;
}
export interface DataPoint {
    [axis: string]: number;
}
export interface Bounds {
    [axis: string]: Interval;
}

export const screenSpace: Space = { x: { unit: 'pixel' }, y: { unit: 'pixel' } };

export const geoSpace: Space = {
    lat: { unit: '°' },
    long: { unit: '°' },
};

export const logicalSpace: Space = {
    x: { unit: null },
    y: { unit: null },
};

export const flipBounds = function (bounds: Bounds, flipAxis: string[]): Bounds {
    const result = cloneDeep(bounds);
    flipAxis.forEach((axis): void => {
        result[axis].min = bounds[axis].max;
        result[axis].max = bounds[axis].min;
    });
    return result;
};

export default interface ICoordinateTransformation {
    sourceSpace: Space;
    targetSpace: Space;

    /**
     *
     * @param sourcePoint - coords in source space
     * @return coords in target dimensions
     */
    transform(sourcePoint: DataPoint): DataPoint;

    /**
     *
     * @param  targetPoint - coords in target space
     * @return coords in source dimensions
     */
    transformBack(targetPoint: DataPoint): DataPoint;

    /**
     * returns a new TransformationChain without modifying this Transformation or Chain
     * @param {ICoordinateTransformation} transformation
     * @return {ICoordinateTransformation}
     */
    appendTransformation(transformation: ICoordinateTransformation): ICoordinateTransformation;
}

export const visibleSourceBounds = function visibleSourceBounds(
    transformation: ICoordinateTransformation,
    targetBounds: Bounds
): Bounds {
    const minTargetCoordinates = mapValues(targetBounds, (interval: Interval): number => interval.min);
    const maxTargetCoordinates = mapValues(targetBounds, (interval: Interval): number => interval.max);

    const minSourceCoordinates = transformation.transformBack(minTargetCoordinates);
    const maxSourceCoordinates = transformation.transformBack(maxTargetCoordinates);

    const result: Bounds = {};
    Object.keys(transformation.sourceSpace).forEach((axis): void => {
        result[axis] = { min: minSourceCoordinates[axis], max: maxSourceCoordinates[axis] };
    });

    return result;
};

/**
 * TODO: this is a suboptimal function name. It should be called transformBoundingBox. It's also a very
 * strange way to go about it. Impl seems counterintuitive
 * @param {ICoordinateTransformation} transformation
 * @param {Bounds} sourceBounds
 * @returns {Bounds}
 */
export const calculatedTargetBounds = function calculateTargetBounds(
    transformation: ICoordinateTransformation,
    sourceBounds: Bounds
): Bounds {
    const minSourceCoordinates = mapValues(sourceBounds, (interval: Interval): number => interval.min);
    const maxSourceCoordinates = mapValues(sourceBounds, (interval: Interval): number => interval.max);

    const minTargetCoordinates = transformation.transform(minSourceCoordinates);
    const maxTargetCoordinates = transformation.transform(maxSourceCoordinates);

    const result: Bounds = {};
    Object.keys(transformation.targetSpace).forEach((axis): void => {
        result[axis] = { min: minTargetCoordinates[axis], max: maxTargetCoordinates[axis] };
    });

    return result;
};

export function isSpaceCompatible(s1: Space, s2: Space): boolean {
    return isEqual(s1, s2);
}
