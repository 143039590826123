export const validCellRenderers = ['TextCell', 'ArrayCell', 'SparklineCell'] as const;
export type CellRenderers = typeof validCellRenderers[number];
export const validSparklineTypes = ['line', 'area'] as const;
export type SparklineType = typeof validSparklineTypes[number];
export const validHeaderVisibilities = ['none', 'fixed', 'inline'] as const;
export type TableHeaderVisibility = typeof validHeaderVisibilities[number];
export const validAlignments = ['left', 'center', 'right'] as const;
export type TableAlignment = typeof validAlignments[number];
export const validTextOverflows = ['anywhere', 'break-word', 'ellipsis'] as const;
export type TextOverflow = typeof validTextOverflows[number];
export const validFontSizes = ['extraSmall', 'small', 'default', 'large'] as const;
export type FontSize = typeof validFontSizes[number];
