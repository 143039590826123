import { createContext, useContext } from 'react';
import type { DataSourceRegistry } from '@splunk/dashboard-search';

const DataSourceRegistryContext = createContext<DataSourceRegistry | null>(
    null
);

export const DataSourceRegistryContextProvider =
    DataSourceRegistryContext.Provider;

export const useDataSourceRegistry = () => {
    const ctx = useContext(DataSourceRegistryContext);
    if (ctx === null) {
        throw new Error(
            'useDataSourceRegistry can only be used in a component that is a child of a DataSourceRegistryContextProvider'
        );
    }

    return ctx;
};

export default DataSourceRegistryContext;
