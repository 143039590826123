import { mapValues, omit } from 'lodash';
import type { PlainObject, FilterDefinition } from '../FilterTypes';

const filterDataSources: FilterDefinition = (definition) => {
    const def = omit(definition, ['dataSources']);

    return mapValues(def, (value: PlainObject, key) => {
        if (['visualizations', 'inputs'].includes(key)) {
            return mapValues(value, (item: PlainObject) => {
                return omit(item, ['dataSources']);
            });
        }

        if (key === 'defaults') {
            return omit(value, ['dataSources']);
        }

        return value;
    });
};

export default filterDataSources;
