import * as React from 'react';
import { _ } from '@splunk/ui-utils/i18n';
import SVG from '@splunk/react-icons/SVGEnterprise';

const LinkGraph = (props: React.ComponentProps<typeof SVG>): React.ReactElement<SVGElement> => (
    <SVG screenReaderText={_('Link Graph')} viewBox="0 0 20 16" {...props}>
        <path
            d="M0 1C0 0.447715 0.447715 0 1 0H8C8.55228 0 9 0.447715 9 1V3H11V1C11 0.447715 11.4477 0 12 0H19C19.5523 0 20 0.447715 20 1V6C20 6.55228 19.5523 7 19 7H12C11.4477 7 11 6.55228 11 6V5H9.53876L11.4041 9.1969C11.5705 9.07319 11.7767 9 12 9H19C19.5523 9 20 9.44771 20 10V15C20 15.5523 19.5523 16 19 16H12C11.4477 16 11 15.5523 11 15V13.2122L8.22747 6.97401C8.1544 6.99101 8.07825 7 8 7H1C0.447715 7 0 6.55228 0 6V1Z"
            fill="currentColor"
        />
    </SVG>
);

export default LinkGraph;
