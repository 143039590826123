import { DashboardValidator } from '../validators/DashboardValidator';

// Split this from the worker for testability
export const handleEvent = (event: MessageEvent) => {
    const { schema, definition, readOnlyTokenNamespaces } = event.data;
    const validator = new DashboardValidator();

    const errors = validator.setSchema(schema);
    if (errors) {
        return errors;
    }

    return validator.validate(definition, readOnlyTokenNamespaces);
};
