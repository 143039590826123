import VisualizationFeatureFlags from '@splunk/visualization-context/FeatureFlags';

const defaultFeatureFlags = {
    ...VisualizationFeatureFlags,
    enableTestDataSourceEditor: false,
    enableImageFileUpload: false,
    enableIconUploads: true,
    enableIconDelete: true,
    enableSVGImageUpload: true,
    enableSvgHttpDownloader: true,
    showProgressBar: true,
    showLastUpdated: true,
    enableVizSourceEditor: true,
    enableVizIdEditor: true,
    enableDSSourceEditor: true,
    enableDSIdEditor: true,
    enableDSModuleEditor: true,
    enableDSApplyButton: false,
    enableDragDropInputs: true,
    enableInputOptionsEditor: true,
    enableInputDSEditor: false,
    enableInputSourceEditor: true,
    enableInputIdEditor: true,
    enableGridLayoutErrors: false,
    enableGallerySharingWarning: true,
    enableCollapsibleToolbar: false,
    enableZoomCenter: false,
    showNewDataForViz: true,
    enableGridLayoutCssScaling: true,
    enableTokensInUrl: true,
    enableVizMigrationCheck: false,
    enableDefaultTokenUI: false,
    enableMarginDeselection: false,
    enableSmartSourceDS: false,
    enableInputsOnCanvas: false,
    enableTokensInBackgroundImage: false,
    enableShowHide: false,
    enableExpandedSourceEditor: false,
    enableSSR: false,
    enableSourceModeValidation: true,
};

type FeatureFlagKeys = keyof typeof defaultFeatureFlags | string;

export type FeatureFlags = Record<FeatureFlagKeys, boolean>;

export default defaultFeatureFlags as FeatureFlags;
