/* eslint-disable max-classes-per-file */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-param-reassign */
import * as React from 'react';
import { SerializedTreeRenderer } from './SerializedTreeRenderer';
// eslint-disable-next-line import/order
import camelCase = require('lodash/camelCase');

/**
 * This class represents the structure of a React element.
 */
export class ElementStructure {
    public name: string;

    public attributes: Record<string, unknown> = {};

    public children: React.ReactNode[] = [];

    public textContent; // a node may have childen, or text content

    /**
     * @constructor
     * @param {string} name - the element's tag name
     * @param {object} attributes - the element's attributes
     * @param {string} textContent - optional text content for a node
     */
    constructor(name: string, attributes = {}, textContent?: string) {
        this.name = name;
        this.attributes = ElementStructure.processStyleAttributes(attributes);
        this.textContent = textContent;
    }

    private static processStyleAttributes(a: Record<string, any>): Record<string, any> {
        if (a.style) {
            a.style = ElementStructure.parseStyle(a.style);
        }
        return a;
    }

    /**
     * In react, the style attribute is an object, not an inline css style string. This function converts inline css
     * style string into discrete key-value object that plays nicely with react.
     * @param {string} style
     * @returns {object}
     */
    private static parseStyle(style: string): Record<string, unknown> {
        const o = {};
        // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
        style.split(';').forEach(s => {
            const [k, v] = s.split(':');
            o[camelCase(k)] = v;
        });
        return o;
    }
}

/**
 * This class is used to build up a node tree. The node tree is turned into a ReactNode when the root node of the
 * tree is closed (aka  the closing tag).
 */
export class NodeTreeReactGenerator implements SerializedTreeRenderer {
    protected _root: React.ReactElement;

    protected _stack: ElementStructure[] = [];

    decorator: (el: React.ReactElement) => React.ReactElement; // useful to wrap elements in tooltips

    /**
     * This method is called to indicate an opening element tag.
     * @param {string} name - the element's tag name
     * @param {object} attributes - the element's attributes
     * @param {string} textContent - optional text content for a node
     */
    beginNode(name: string, attributes = {}, textContent?: string): void {
        const attrCopy = { key: Math.random() };
        // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
        Object.keys(attributes).forEach(k => {
            if (k.startsWith('data-')) {
                attrCopy[k] = attributes[k];
            } else {
                attrCopy[camelCase(k)] = attributes[k];
            }
        });

        this._stack.push(new ElementStructure(name, attrCopy, textContent));
    }

    /**
     * This method closes a tag. When a tag is closed its ElementStructure is converted to a ReactNode and added to the set of
     * child nodes tracked by the parent's ElementStructure. If the tag being closed is the root node, then the _root
     * is set and we are done, having created the ReactNode representing the entire tree.
     */
    endNode(): void {
        const popped = this._stack.pop();
        const { name, attributes, children, textContent } = popped;
        const el = this.peek();
        let reactNode: React.ReactElement = null;
        if (children.length === 0) {
            if (textContent) {
                reactNode = React.createElement(name, attributes, textContent);
            } else {
                reactNode = React.createElement(name, attributes);
            }
        } else {
            if (textContent) {
                throw new Error(`textContent can't be mixed with children on node ${name}`);
            }
            reactNode = React.createElement(name, attributes, children);
        }

        if (this.decorator) {
            reactNode = this.decorator(reactNode);
        }

        if (el) {
            el.children.push(reactNode);
        } else {
            this._root = reactNode;
        }
    }

    protected peek(): ElementStructure {
        if (this._stack.length === 0) {
            return null;
        }
        return this._stack[this._stack.length - 1];
    }

    /**
     * Returns the ReactNode representing the entire tree, or throws Error if tree is not fully processed.
     * @returns {React.ReactElement}
     */
    public getRoot(): React.ReactElement {
        if (this._root) {
            return this._root;
        }
        throw new Error(
            `Can't compute ReactElement until root node has been closed. Node stack: ${this._stack}`
        );
    }

    /**
     * Clears internal structures so this instance can be used to process a new tree.
     */
    public clear(): void {
        this._stack = [];
        this._root = null;
    }

    /**
     * Allows the current tag and all its children to be erased.
     */
    public cancelNode(): void {
        this._stack.pop();
    }
}
