import { chain, omit } from 'lodash';
// Charting imports i18n which needs to be run to instantiate window globals in the tests
// eslint-disable-next-line import/order
import Charting, { ChartingProps } from '../common/Charting';
import jsCharting from '@splunk/charting-bundle';
import { getSimpleXMLOptionsForProps } from '@splunk/visualizations-shared/simpleXMLOptions';
import config from './config';
import withDashboardViz from '../common/withDashboardViz';
import { convertToColumnSCProperties } from './columnUtils';
import { getAnnotations, truncateData, constructFieldsAndColumns } from '../common/chartingUtils';

const validOptions = Object.keys(config.optionsSchema);

export const computeVizProps: any = ({
    dataSources,
    onEventTrigger,
    x,
    y,
    xField,
    yFields,
    annotationX,
    annotationLabel,
    annotationColor,
    visualizationApiRef,
    ...otherOptions
}): Partial<ChartingProps> => {
    // options to be passed as props
    const computedProps: Record<string, any> = { visualizationApiRef };
    const { y2Fields, y2, hasEventHandlers } = otherOptions;

    computedProps.options = chain(otherOptions)
        .pick(validOptions)
        .thru(convertToColumnSCProperties)
        .thru(options => ({
            ...getSimpleXMLOptionsForProps(omit(options, ['y2Fields'])),
            chart: 'column',
        }))
        .value();

    const chartingAnnotations = getAnnotations({
        annotationX,
        annotationColor,
        annotationLabel,
    });
    let primaryData = { ...dataSources.primary.data };
    const { columns, fields } = constructFieldsAndColumns({
        x,
        y,
        xField,
        yFields,
        y2,
        y2Fields,
    });
    primaryData.columns = columns;
    primaryData.fields = fields;
    const truncationLimit = computedProps.options['chart.resultTruncationLimit'];
    if (truncationLimit) {
        primaryData = truncateData(primaryData, truncationLimit);
    }
    if (hasEventHandlers) {
        computedProps.onClick = onEventTrigger;
        computedProps.onSelect = onEventTrigger;
        computedProps.onPointMouseOver = onEventTrigger;
        computedProps.onPointMouseOut = onEventTrigger;
    }
    computedProps.chartData = jsCharting.extractChartReadyData({
        ...primaryData,
        annotations: chartingAnnotations,
    });

    return computedProps;
};

const Column = withDashboardViz({
    ReactViz: Charting,
    computeVizProps,
    vizConfig: config,
});
const themes = null;

export { themes, config };
export default Column;
