import React from 'react';
import T from 'prop-types';
import styled from 'styled-components';
import { isNumerial, formatNumber } from '@splunk/visualizations-shared/numberUtils';

const DEFAULT_NUM_OF_TICKS = 5;
const TICK_LENGTH_DELTA = 5;
const TICK_LABEL_PADDING_FACTOR = 3.2;

const MajorTickLine = styled.line`
    stroke: ${props => props.majorTickStrokeColor};
    stroke-width: 2px;
    stroke-dasharray: ${props => (props.index === 0 ? 0 : 3)};
`;

const MajorTickLabel = styled.text`
    font-size: 12px;
    font-family: Splunk Platform Sans, sans-serif;
    line-height: 19px;
    fill: ${props => props.majorTickFillColor};
    text-anchor: middle;
    text-align: center;
`;

export const MajorTick = ({
    index,
    x1,
    y1,
    x2,
    y2,
    showLabels,
    label,
    labelX,
    labelY,
    majorTickStrokeColor,
    majorTickFillColor,
}) => (
    <g>
        <MajorTickLine
            index={index}
            x1={x1}
            y1={y1}
            x2={x2}
            y2={y2}
            majorTickStrokeColor={majorTickStrokeColor}
        />
        {showLabels && (
            <MajorTickLabel x={labelX} y={labelY} majorTickFillColor={majorTickFillColor}>
                {isNumerial(label)
                    ? formatNumber(label, 0, {
                          useThousandSeparators: true,
                          useTrendUnits: true,
                      })
                    : label}
            </MajorTickLabel>
        )}
    </g>
);

MajorTick.propTypes = {
    index: T.number,
    x1: T.number,
    y1: T.number,
    x2: T.number,
    y2: T.number,
    showLabels: T.bool,
    label: T.oneOfType([T.string, T.number]),
    labelX: T.number,
    labelY: T.number,
    majorTickStrokeColor: T.string,
    majorTickFillColor: T.string,
};

/**
 * calculate position for each major tick
 * @method positionMajorTick
 * @param {Object} input
 * @param {Number} input.width
 * @param {Number} input.height
 * @param {Number} input.startX  start x for rendering the first major tick
 * @param {Number} input.startY  start y for rendering the first major tick
 * @param {String} input.orientation
 * @param {Number} input.numOfTicks  how many ticks need to render
 * @param {Number} input.i   index of major tick
 * @return {Object} MajorTick positions
 */
export const positionMajorTick = ({ width, height, startX, startY, orientation, numOfTicks, i }) => {
    let x1 = startX;
    let y1 = startY;
    let x2 = startX;
    let y2 = startY;
    let labelX = startX;
    let labelY;

    if (orientation === 'vertical') {
        const y = (height / numOfTicks) * (numOfTicks - i) + startY;
        x1 -= TICK_LENGTH_DELTA;
        y1 = y;
        x2 += width + TICK_LENGTH_DELTA;
        y2 = y;
        labelX += width + TICK_LENGTH_DELTA * TICK_LABEL_PADDING_FACTOR;
        labelY = y;
    } else {
        const x = (width / numOfTicks) * i + startX;
        x1 = x;
        y1 -= TICK_LENGTH_DELTA;
        x2 = x;
        y2 += height + TICK_LENGTH_DELTA;
        labelX = x;
        labelY = y2 + 16;
    }

    return { x1, y1, x2, y2, labelX, labelY };
};

const MajorTicks = ({ ...props }) => {
    const {
        width,
        height,
        startX,
        startY,
        min,
        max,
        majorUnit,
        orientation,
        showLabels,
        usePercentageRange,
        majorTickFillColor,
        majorTickStrokeColor,
    } = props;

    const range = max - min;
    const numOfTicks =
        majorUnit === 'auto' || Number.isNaN(Number(majorUnit)) || majorUnit <= 0
            ? DEFAULT_NUM_OF_TICKS
            : range / majorUnit;
    const majorTicks = [];

    for (let i = 0; i <= numOfTicks; i += 1) {
        const { x1, y1, x2, y2, labelX, labelY } = positionMajorTick({
            width,
            height,
            startX,
            startY,
            orientation,
            numOfTicks,
            i,
        });
        const label = usePercentageRange
            ? `${parseInt((i / numOfTicks - min / range) * 100, 10)}%`
            : parseInt(min + (range / numOfTicks) * i, 10);

        majorTicks.push(
            <MajorTick
                key={i}
                index={i}
                x1={x1}
                y1={y1}
                x2={x2}
                y2={y2}
                showLabels={showLabels}
                label={label}
                labelX={labelX}
                labelY={labelY}
                majorTickFillColor={majorTickFillColor}
                majorTickStrokeColor={majorTickStrokeColor}
            />
        );
    }

    // if the gauge is not divided evenly, put the last tick at the end of the gauge
    if (!Number.isInteger(numOfTicks)) {
        const extraNumOfTicks = Math.ceil(numOfTicks);
        const { x1, y1, x2, y2, labelX, labelY } = positionMajorTick({
            width,
            height,
            startX,
            startY,
            orientation,
            numOfTicks: extraNumOfTicks,
            i: extraNumOfTicks,
        });

        majorTicks.push(
            <MajorTick
                key={extraNumOfTicks}
                id={extraNumOfTicks}
                x1={x1}
                y1={y1}
                x2={x2}
                y2={y2}
                showLabels={showLabels}
                label={usePercentageRange ? '100%' : max}
                labelX={labelX}
                labelY={labelY}
                majorTickFillColor={majorTickFillColor}
                majorTickStrokeColor={majorTickStrokeColor}
            />
        );
    }

    return <g>{majorTicks}</g>;
};

MajorTicks.propTypes = {
    width: T.number,
    height: T.number,
    startX: T.number,
    startY: T.number,
    min: T.number,
    max: T.number,
    majorUnit: T.oneOfType([T.string, T.number]),
    orientation: T.string,
    showLabels: T.bool,
    usePercentageRange: T.bool,
    majorTickFillColor: T.string,
    majorTickStrokeColor: T.string,
};

MajorTicks.defaultProps = {
    min: 0,
    max: 100,
    majorUnit: 'auto',
    orientation: 'horizontal',
    showLabels: true,
    usePercentageRange: false,
};

export default MajorTicks;
