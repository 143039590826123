import { useFeatureFlags } from '@splunk/dashboard-context';
import type {
    InputDefinition,
    VisualizationDefinition,
} from '@splunk/dashboard-types';

export type UseCanItemBeHiddenArgs = {
    itemDefinition: InputDefinition | VisualizationDefinition;
};

// Note: don't consider the editor mode. In view mode when this hook returns true
// the component can be hidden. In edit mode when this hook returns true, the component
// can have visual cues applied to indicate the show/hide functionality
export const useCanItemBeHidden = ({
    itemDefinition,
}: UseCanItemBeHiddenArgs): boolean => {
    const { enableShowHide } = useFeatureFlags();

    // Items can be hidden when the featureFlag is enabled, and the definition
    // property hideWhenNoData = true
    return enableShowHide && !!itemDefinition.hideWhenNoData;
};
