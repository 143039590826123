import { cloneDeep, omit } from 'lodash';
import { FieldEntry } from '@splunk/visualizations-shared/dataSourceUtils';
import {
    DataSplitMode,
    CHART,
    TIMECHART,
    STATS,
    UNKNOWN,
    extractDataSourceAggregationKeysChart,
    extractDataSourceAggregationKeysStats,
    extractSplitByFieldsChartFields,
    extractSplitByFieldsForStats,
    extractSplitByFieldsForUnknown,
} from '@splunk/visualizations-shared/trellisUtils';
import { DataSource } from '../interfaces/DataSource';

export type SplitDataSource = { [key: string]: { [name: string]: DataSource } };
export interface DataSplitResult {
    splitDataSources: SplitDataSource;
    errorCode: 'UNKNOWN_DATA_SPLIT_MODE' | 'INVALID_SPLIT_BY_FIELD' | 'UNKNOWN';
}
export const AGGREGATIONS = 'aggregations';

/**
 * There can be certain SPL cmds that can produce dataSource without a specific field meta info (like group_by_rank, split_by_field etc),
 * to categorize it,
 * Example1:- `index = "_internal" | chart count`
 * Example2:- `index=_internal | chart avg(count), max(count)`
 * Example3:- `index = "_internal" | stats count`
 * Example4:- `index=_internal | stats avg(count), max(count)`
 */
export const dataSourcesSplitByAggregationsUnknown = (dataSource: {
    [name: string]: DataSource;
}): DataSplitResult => {
    const fields = dataSource?.primary?.data?.fields;
    const columns = dataSource?.primary?.data?.columns;
    let splitDataSources = null;
    let errorCode = null;

    if (fields && fields.length) {
        splitDataSources = {};
        for (let i = 0; i < fields.length; i += 1) {
            const fieldName = (fields[i] as FieldEntry).name;
            if (!fieldName?.startsWith('_')) {
                splitDataSources[fieldName] = cloneDeep(
                    omit(dataSource, ['primary.data.fields', 'primary.data.columns'])
                );
                splitDataSources[fieldName].primary.data.fields = [fields[i]];
                splitDataSources[fieldName].primary.data.columns = [columns[i]];
            }
        }
    } else {
        errorCode = 'INVALID_SPLIT_BY_FIELD';
    }
    return { splitDataSources, errorCode };
};

export const dataSourcesSplitByAggregationsTimechartAndChart = (dataSource: {
    [name: string]: DataSource;
}): DataSplitResult => {
    const fields = dataSource?.primary?.data?.fields;
    const columns = dataSource?.primary?.data?.columns;
    let splitDataSources = null;
    let splitByFields = [];
    let errorCode = null;

    if (typeof fields[0] !== 'string') {
        splitByFields = extractDataSourceAggregationKeysChart(fields as FieldEntry[]);
    }

    if (splitByFields && splitByFields.length > 0) {
        splitDataSources = {};
        splitByFields.forEach(splitByField => {
            splitDataSources[splitByField] = cloneDeep(
                omit(dataSource, ['primary.data.fields', 'primary.data.columns'])
            );
            splitDataSources[splitByField].primary.data.fields = [fields[0]];
            splitDataSources[splitByField].primary.data.columns = [columns[0]];
            fields.forEach((field: FieldEntry, idx) => {
                const fieldName = field?.name;
                if (
                    fieldName !== '_time' &&
                    !fieldName?.startsWith('_') &&
                    field.data_source === splitByField
                ) {
                    splitDataSources[splitByField].primary.data.fields.push(field);
                    splitDataSources[splitByField].primary.data.columns.push(columns[idx]);
                }
            });
        });
    } else if ((!splitByFields || !splitByFields.length) && fields.length > 1) {
        // handle where data_source doesnot exist in fields
        // example1 :- index=_internal | timechart count
        // example2 :- index=_internal |  timechart max(count) avg(count)
        splitDataSources = {};
        for (let i = 1; i < fields.length; i += 1) {
            const fieldName = (fields[i] as FieldEntry).name;
            if (!fieldName?.startsWith('_')) {
                splitDataSources[fieldName] = cloneDeep(
                    omit(dataSource, ['primary.data.fields', 'primary.data.columns'])
                );
                splitDataSources[fieldName].primary.data.fields = [fields[0], fields[i]];
                splitDataSources[fieldName].primary.data.columns = [columns[0], columns[i]];
            }
        }
    } else {
        errorCode = 'INVALID_SPLIT_BY_FIELD';
    }
    return { splitDataSources, errorCode };
};

export const dataSourcesSplitByAggregationsStats = (dataSource: {
    [name: string]: DataSource;
}): DataSplitResult => {
    const fields = dataSource?.primary?.data?.fields as FieldEntry[];
    const columns = dataSource?.primary?.data?.columns;
    let splitDataSources = null;
    let splitByFields = [];
    let errorCode = null;
    if (typeof fields[0] !== 'string') {
        splitByFields = extractDataSourceAggregationKeysStats(fields as FieldEntry[]);
    }

    if (splitByFields && splitByFields.length > 0) {
        splitDataSources = {};
        splitByFields.forEach(splitByField => {
            splitDataSources[splitByField] = cloneDeep(
                omit(dataSource, ['primary.data.fields', 'primary.data.columns'])
            );
            splitDataSources[splitByField].primary.data.fields = [fields[0]];
            splitDataSources[splitByField].primary.data.columns = [columns[0]];
            const idx = fields.findIndex(field => field.name === splitByField);
            if (idx > 0) {
                splitDataSources[splitByField].primary.data.fields.push(fields[idx]);
                splitDataSources[splitByField].primary.data.columns.push(columns[idx]);
            }
        });
    } else {
        errorCode = 'INVALID_SPLIT_BY_FIELD';
    }
    return { splitDataSources, errorCode };
};

export const dataSourcesSplitByModeTimechart = (
    dataSource: { [name: string]: DataSource },
    splitBy: string
): DataSplitResult => {
    const fields = dataSource?.primary?.data?.fields as FieldEntry[];
    const columns = dataSource?.primary?.data?.columns;
    let splitDataSources = null;
    let splitByFields = [];
    let errorCode = null;
    if (typeof fields[0] !== 'string') {
        splitByFields = extractSplitByFieldsChartFields(fields as FieldEntry[]);
    }
    if (splitByFields && splitByFields.indexOf(splitBy) > -1) {
        splitDataSources = {};
        const dataSourceKeys = extractDataSourceAggregationKeysChart(fields);
        const valueKeys = [];
        for (let i = 1; i < fields.length; i += 1) {
            // not including internal fields
            if (!fields[i].name?.startsWith('_')) {
                valueKeys.push(fields[i].splitby_value ? fields[i].splitby_value : fields[i].name);
            }
        }
        valueKeys.forEach(val => {
            splitDataSources[val] = cloneDeep(
                omit(dataSource, ['primary.data.fields', 'primary.data.columns'])
            );
            splitDataSources[val].primary.data.fields = [
                { name: fields[0].name },
                ...dataSourceKeys.map(k => ({
                    name: k,
                })),
            ];
            splitDataSources[val].primary.data.columns = [[...columns[0]]];
            dataSourceKeys.forEach(dsKey => {
                fields.forEach((field, idx) => {
                    if (field.data_source === dsKey && (field.splitby_value === val || field.name === val)) {
                        splitDataSources[val].primary.data.columns.push([...columns[idx]]);
                    }
                });
            });
        });
    } else {
        errorCode = 'INVALID_SPLIT_BY_FIELD';
    }
    return { splitDataSources, errorCode };
};

export const dataSourcesSplitByModeChart = (
    dataSource: { [name: string]: DataSource },
    splitBy: string
): DataSplitResult => {
    const fields = dataSource?.primary?.data?.fields as FieldEntry[];
    const columns = dataSource?.primary?.data?.columns;
    let splitDataSources = null;
    let splitByFields = [];
    let errorCode = null;
    if (typeof fields[0] !== 'string') {
        splitByFields = extractSplitByFieldsChartFields(fields);
    }
    if (splitByFields && splitByFields.indexOf(splitBy) > -1) {
        splitDataSources = {};
        const splitByFieldIdx = splitByFields.findIndex(f => f === splitBy);
        const otherSplitBy = splitByFields.filter(f => f !== splitBy);
        const dataSourceKeys = extractDataSourceAggregationKeysChart(fields);
        const nonDataSourceAggregations = [];
        if (
            !dataSourceKeys.length &&
            fields[0].groupby_rank === '0' &&
            fields.length &&
            !fields[1].groupby_rank
        ) {
            /* check for another aggregation scenario where no key 'data_source' will be returned (applies for both chart and stats cmd)
            For example :-
            index=_internal | chart count by status
            index = "_internal" | chart max(count) avg(count) by status
            index = "_internal" |  stats max(count) avg(count) by status
            where field meta info is in this format - 
             "fields": [
                    {
                        "name": "status",
                        "groupby_rank": "0"
                    },
                    {
                        "name": "max(count)"
                    },
                    {
                        "name": "avg(count)"
                    }
                ]
             */

            for (let i = 1; i < fields.length; i += 1) {
                nonDataSourceAggregations.push(fields[i].name);
            }
        }
        if (splitByFieldIdx === 0) {
            // if splitBy is the first field, split row wise
            const valueKeys = cloneDeep(columns[0]);
            valueKeys.forEach((val, idx) => {
                splitDataSources[val] = cloneDeep(
                    omit(dataSource, ['primary.data.fields', 'primary.data.columns'])
                );
                splitDataSources[val].primary.data.fields = [];
                if (otherSplitBy.length) {
                    splitDataSources[val].primary.data.fields = [{ name: otherSplitBy[0] }]; // there can only be one otherSplitBy for chart cmd
                }
                if (dataSourceKeys.length) {
                    const columnValues = [[]];
                    splitDataSources[val].primary.data.fields = [
                        ...splitDataSources[val].primary.data.fields,
                        ...dataSourceKeys.map(k => ({
                            name: k,
                        })),
                    ];
                    dataSourceKeys.forEach(() => {
                        columnValues.push([]);
                    });
                    /**
                     * There can be chart SPL cmd where there are multiple data_source keys in the field meta info. 
                     * For eg - index = "_internal" | chart avg(count) max(count) by status , user) can result in fields and columns as below where "data_source" keys are "avg(count)" and "max(count)"
                     * 'fields' : [
                            {
                                "name": "status",
                                "groupby_rank": "0"
                            },
                            {
                                "name": "avg(count): userA",
                                "data_source": "avg(count)",
                                "splitby_field": "user",
                                "splitby_value": "userA"
                            },
                            {
                                "name": "avg(count): userB",
                                "data_source": "avg(count)",
                                "splitby_field": "user",
                                "splitby_value": "userB"
                            },
                            {
                                "name": "avg(count): userC",
                                "data_source": "avg(count)",
                                "splitby_field": "user",
                                "splitby_value": "userC"
                            },
                            {
                                "name": "max(count): userA",
                                "data_source": "max(count)",
                                "splitby_field": "user",
                                "splitby_value": "userA"
                            },
                            {
                                "name": "max(count): userB",
                                "data_source": "max(count)",
                                "splitby_field": "user",
                                "splitby_value": "userB"
                            },
                            {
                                "name": "max(count): userC",
                                "data_source": "max(count)",
                                "splitby_field": "user",
                                "splitby_value": "userC"
                            },
                        ]
                        'columns' : [['200','303','304','400'], ['1','2','3','4'], ['10','20','30','40'], ['100','200','300','400'], ['5','6','7','8'], ['50','60','70','80'],['500','600','700','800']]

                        In this case when we split row wise, the data_source keys for userA appears in indices [1, 4, ..], the data_source keys for userB appears in indices [2, 5, ..]
                        The resulting dataSplit for status '200', will have the fields => ['user','avg(count)', 'max(count)'] columns  => [['userA', 'userB', 'userC'],['1','10','100'], ['5', '50', '500'] ]

                        For the example above for..loop below will iterate from i= 1 to 3 (Math.ceil((fields.length - 1) / dataSourceKeys.length)). Exclude i = 0 as first column is the splitBy input.
                        Since there are 2 data_source keys 'avg(count)' and 'max(count)', dataSourceKeys.forEach loop will collect individual arrays specific to the columnValues[indx] as below. 
                        columnValues[0] =  ['userA', 'userB', 'userC']
                        columnValues[1] = ['1','10','100']
                        columnValues[2] = ['5', '50', '500']
                     */
                    for (let i = 1; i <= Math.ceil((fields.length - 1) / dataSourceKeys.length); i += 1) {
                        columnValues[0].push(
                            fields[i].splitby_value ? fields[i].splitby_value : fields[i].name
                        );
                        dataSourceKeys.forEach((dskey, dsIndx) => {
                            const mul = (fields.length - 1) / dataSourceKeys.length; // This line does the index calculation
                            columnValues[dsIndx + 1].push(columns[i + dsIndx * mul][idx]);
                        });
                    }
                    splitDataSources[val].primary.data.columns = columnValues;
                } else if (nonDataSourceAggregations.length) {
                    // There can be SPL where data_source field metainfo is not available and needs special handling
                    // For eg :-
                    // index=_internal |  chart count by status
                    // index = "_internal" | chart max(count) avg(count) by status
                    // index = "_internal" |  stats max(count) avg(count) by status
                    const columnValues = [];
                    fields.forEach((aggregationField, fieldIdx) => {
                        if (fieldIdx > 0) {
                            splitDataSources[val].primary.data.fields.push(aggregationField);
                            columnValues.push([columns[fieldIdx][idx]]);
                        }
                    });
                    splitDataSources[val].primary.data.columns = columnValues;
                }
            });
        } else {
            // split column wise
            const valueKeys = [];
            for (let i = 1; i < fields.length; i += 1) {
                valueKeys.push(fields[i].splitby_value ? fields[i].splitby_value : fields[i].name);
            }
            valueKeys.forEach(val => {
                splitDataSources[val] = cloneDeep(
                    omit(dataSource, ['primary.data.fields', 'primary.data.columns'])
                );
                splitDataSources[val].primary.data.fields = [
                    { name: otherSplitBy[0] },
                    ...dataSourceKeys.map(k => ({
                        name: k,
                    })),
                ];
                splitDataSources[val].primary.data.columns = [[...columns[0]]];
                dataSourceKeys.forEach(dsKey => {
                    fields.forEach((field, idx) => {
                        if (
                            field.data_source === dsKey &&
                            (field.splitby_value === val || field.name === val)
                        ) {
                            splitDataSources[val].primary.data.columns.push([...columns[idx]]);
                        }
                    });
                });
            });
        }
    } else {
        errorCode = 'INVALID_SPLIT_BY_FIELD';
    }
    return { splitDataSources, errorCode };
};

/**
 * This method does datasplitting for 'stats' and generic cmds('makeresults','table' etc - categoried as 'unknown' splitMode).
 * The main difference is for 'stats' cmd possible splitBy fields have 'group_by_rank' in field meta info, whereas for 'unknown' cmd it can be any fieldnames
 */
export const dataSourcesSplitByModeStatsorUnknown = (
    dataSource: { [name: string]: DataSource },
    splitBy: string,
    splitMode?: DataSplitMode
): DataSplitResult => {
    const fields = dataSource?.primary?.data?.fields;
    const columns = dataSource?.primary?.data?.columns;
    let splitDataSources = null;
    let splitByFields = [];
    let errorCode = null;
    if (typeof fields[0] !== 'string') {
        splitByFields =
            splitMode !== UNKNOWN
                ? extractSplitByFieldsForStats(fields as FieldEntry[])
                : extractSplitByFieldsForUnknown(fields as FieldEntry[]);
    }
    const splitByIndex = fields?.findIndex(
        field => field === splitBy || (field as FieldEntry).name === splitBy
    );
    if (splitByFields && splitByFields.indexOf(splitBy) > -1) {
        const splitByColumn = dataSource?.primary?.data?.columns[splitByIndex];
        splitDataSources = {};
        splitByColumn.forEach((val, idx) => {
            if (!splitDataSources[val]) {
                splitDataSources[val] = [idx];
            } else {
                splitDataSources[val].push(idx);
            }
        });
        const cloneFields = cloneDeep(fields);
        cloneFields.splice(splitByIndex, 1);
        const valueKeys = Object.keys(splitDataSources);
        valueKeys.forEach(val => {
            const valIndices = cloneDeep(splitDataSources[val]);
            splitDataSources[val] = cloneDeep(
                omit(dataSource, ['primary.data.fields', 'primary.data.columns'])
            );
            splitDataSources[val].primary.data.fields = cloneFields;
            splitDataSources[val].primary.data.columns = [];
            columns.forEach((col, idx) => {
                if (idx !== splitByIndex) {
                    const newCol = valIndices.map(valIdx => col[valIdx]);
                    splitDataSources[val].primary.data.columns.push(newCol);
                }
            });
        });
    } else {
        errorCode = 'INVALID_SPLIT_BY_FIELD';
    }
    return { splitDataSources, errorCode };
};

export const getTrellisDataSourcesBySplitMode = (
    splitMode: DataSplitMode,
    dataSources: { [name: string]: DataSource },
    trellisSplitBy: string
): DataSplitResult => {
    let result: DataSplitResult = null;
    try {
        if (splitMode === TIMECHART) {
            result =
                trellisSplitBy === AGGREGATIONS
                    ? dataSourcesSplitByAggregationsTimechartAndChart(dataSources)
                    : dataSourcesSplitByModeTimechart(dataSources, trellisSplitBy);
        } else if (splitMode === STATS) {
            result =
                trellisSplitBy === AGGREGATIONS
                    ? dataSourcesSplitByAggregationsStats(dataSources)
                    : dataSourcesSplitByModeStatsorUnknown(dataSources, trellisSplitBy, STATS);
        } else if (splitMode === CHART) {
            result =
                trellisSplitBy === AGGREGATIONS
                    ? dataSourcesSplitByAggregationsTimechartAndChart(dataSources)
                    : dataSourcesSplitByModeChart(dataSources, trellisSplitBy);
        } else if (splitMode === UNKNOWN) {
            result =
                trellisSplitBy === AGGREGATIONS
                    ? dataSourcesSplitByAggregationsUnknown(dataSources)
                    : dataSourcesSplitByModeStatsorUnknown(dataSources, trellisSplitBy, UNKNOWN);
        }
    } catch (ex) {
        console.error('getTrellisDataSourcesBySplitMode ', ex);
        result = { splitDataSources: null, errorCode: 'UNKNOWN' };
    }
    return result;
};
