import { _ } from '@splunk/ui-utils/i18n';
import type { DashboardJSON, InputOptions } from '@splunk/dashboard-types';
import { DUPLICATE_INPUT_TOKEN } from './messages';
import type { ValidationErrors } from '../types';

const EmptyInputOptions: InputOptions = Object.freeze({}) as InputOptions;

/**
 * check duplication of inputs tokens
 * @method checkDuplicateTokens
 * @returns {Array} errors
 */
export const checkDuplicateTokens = (
    definition: DashboardJSON
): ValidationErrors => {
    const cache: Record<string, string[]> = {};
    const errors: ValidationErrors = [];

    const { inputs } = definition;

    Object.entries(inputs ?? {}).forEach(([key, input]) => {
        const { token } = input.options || EmptyInputOptions;
        if (token === undefined) {
            return;
        }

        if (token in cache) {
            cache[token].push(key);
        } else {
            cache[token] = [key];
        }
    });

    Object.entries(cache).forEach(([token, keys]) => {
        if (keys.length > 1) {
            const lastKey = keys[keys.length - 1];
            const msg = keys.slice(0, -1).join(', ');
            errors.push({
                instancePath: DUPLICATE_INPUT_TOKEN,
                message: _(
                    `${msg} and ${lastKey} have the same token (${token})`
                ),
            });
        }
    });

    return errors;
};
