import React, { createContext, useContext, useEffect, useMemo } from 'react';
import EventRegistry from './registries/events/EventRegistry';

const defaultRegistry = new EventRegistry();
const EventRegistryContext = createContext(defaultRegistry);

export const EventRegistryContextProvider: React.FC = ({ children }) => {
    const registry = useMemo(() => new EventRegistry(), []);
    useEffect(() => {
        return () => registry.teardown();
    }, [registry]);
    return (
        <EventRegistryContext.Provider value={registry}>
            {children}
        </EventRegistryContext.Provider>
    );
};

export const useEventRegistry = () => useContext(EventRegistryContext);

export default EventRegistryContext;
