import { _ } from '@splunk/ui-utils/i18n';
import { EditorConfig } from '../interfaces/Editor';
import { insertLayout } from '../utils/configUtils';

export const AxesChartXGridAndLabelsCommon: EditorConfig = {
    label: _('X-axis grid and labels'),
    layout: [
        [
            {
                label: _('Axis title'),
                option: 'xAxisTitleVisibility',
                editor: 'editor.toggle',
            },
        ],
        [
            {
                label: _('Axis title text'),
                option: 'xAxisTitleText',
                editor: 'editor.text',
                showEditor: ({ options }) =>
                    options.xAxisTitleVisibility === 'show' || options.xAxisTitleVisibility === undefined,
            },
        ],
        [
            {
                label: _('Label visibility'),
                option: 'xAxisLabelVisibility',
                editor: 'editor.radioBar',
                editorProps: {
                    values: [
                        { label: _('Auto'), value: 'auto' },
                        { label: _('Show'), value: 'show' },
                        { label: _('Hide'), value: 'hide' },
                    ],
                },
            },
        ],
    ],
};

const AxesChartXGridAndLabels: EditorConfig = {
    label: AxesChartXGridAndLabelsCommon.label,
    layout: [
        ...AxesChartXGridAndLabelsCommon.layout,
        [
            {
                label: _('Label rotation'),
                option: 'xAxisLabelRotation',
                editor: 'editor.select',
                editorProps: {
                    values: [
                        { label: _('-90'), value: -90 },
                        { label: _('-45'), value: -45 },
                        { label: _('0'), value: 0 },
                        { label: _('45'), value: 45 },
                        { label: _('90'), value: 90 },
                    ],
                },
                showEditor: ({ options }) =>
                    options.xAxisLabelVisibility === 'show' ||
                    options.xAxisLabelVisibility === 'auto' ||
                    options.xAxisLabelVisibility === undefined,
            },
        ],
        [
            {
                label: _('Number of time label parts'),
                option: 'xAxisMaxLabelParts',
                editor: 'editor.number',
                editorProps: {
                    min: 1,
                    max: 3,
                },
                showEditor: ({ options }) =>
                    options.xAxisLabelVisibility === 'show' ||
                    options.xAxisLabelVisibility === 'auto' ||
                    options.xAxisLabelVisibility === undefined,
            },
        ],
        [
            {
                label: _('Axis line visibility'),
                option: 'xAxisLineVisibility',
                editor: 'editor.checkbox',
            },
        ],
        [
            {
                label: _('Major gridlines'),
                option: 'showXMajorGridLines',
                editor: 'editor.checkbox',
            },
        ],
        [
            {
                label: _('Major tick visibility'),
                option: 'xAxisMajorTickVisibility',
                editor: 'editor.radioBar',
                editorProps: {
                    values: [
                        { label: _('Auto'), value: 'auto' },
                        { label: _('Show'), value: 'show' },
                        { label: _('Hide'), value: 'hide' },
                    ],
                },
            },
        ],
        [
            {
                label: _('Major tick size (px)'),
                option: 'xAxisMajorTickSize',
                editor: 'editor.slider',
                editorProps: {
                    min: 0,
                    max: 10,
                    step: 1,
                },
                showEditor: ({ options }) =>
                    options.xAxisMajorTickVisibility === 'show' ||
                    options.xAxisMajorTickVisibility === 'auto' ||
                    options.xAxisMajorTickVisibility === undefined,
            },
        ],
    ],
};

export const AxesChartXGridAndLabelsBubbleScatter: EditorConfig = {
    label: AxesChartXGridAndLabelsCommon.label,
    layout: [
        ...insertLayout(AxesChartXGridAndLabelsCommon.layout, 2, [
            {
                label: _('Axis scale'),
                option: 'xAxisScale',
                editor: 'editor.radioBar',
                editorProps: {
                    values: [
                        { label: _('Linear'), value: 'linear' },
                        { label: _('Log'), value: 'log' },
                    ],
                },
            },
        ]),
        [
            {
                label: _('Label rotation'),
                option: 'xAxisLabelRotation',
                editor: 'editor.select',
                editorProps: {
                    values: [
                        { label: _('-90'), value: -90 },
                        { label: _('-45'), value: -45 },
                        { label: _('0'), value: 0 },
                        { label: _('45'), value: 45 },
                        { label: _('90'), value: 90 },
                    ],
                },
                showEditor: ({ options }) =>
                    options.xAxisLabelVisibility === 'show' ||
                    options.xAxisLabelVisibility === 'auto' ||
                    options.xAxisLabelVisibility === undefined,
            },
        ],
        [
            {
                label: _('Round axis labels'),
                option: 'showRoundedXAxisLabels',
                editor: 'editor.checkbox',
                showEditor: ({ options }) =>
                    options.xAxisLabelVisibility === 'show' ||
                    options.xAxisLabelVisibility === 'auto' ||
                    options.xAxisLabelVisibility === undefined,
            },
        ],
        [
            {
                label: _('SI prefix abbreviation for labels'),
                option: 'xAxisAbbreviation',
                editor: 'editor.checkbox',
                showEditor: ({ options }) =>
                    options.xAxisLabelVisibility === 'show' ||
                    options.xAxisLabelVisibility === 'auto' ||
                    options.xAxisLabelVisibility === undefined,
            },
        ],
        [
            {
                label: _('Include zero'),
                option: 'showXAxisWithZero',
                editor: 'editor.checkbox',
            },
        ],
        [
            {
                label: _('Extend to whole grid'),
                option: 'showXAxisExtendedRange',
                editor: 'editor.checkbox',
            },
        ],
        [
            {
                label: _('Minimum axis value'),
                option: 'xAxisMin',
                editor: 'editor.number',
            },
            {
                label: _('Maximum axis value'),
                option: 'xAxisMax',
                editor: 'editor.number',
            },
        ],
        [
            {
                label: _('Axis line visibility'),
                option: 'xAxisLineVisibility',
                editor: 'editor.checkbox',
            },
        ],
        // this option is disabled for bubble and scatter
        // [
        //     {
        //         label: _('Major gridlines'),
        //         option: 'showXMajorGridLines',
        //         editor: 'editor.checkbox',
        //     },
        // ],
        [
            {
                label: _('Major tick visibility'),
                option: 'xAxisMajorTickVisibility',
                editor: 'editor.radioBar',
                editorProps: {
                    values: [
                        { label: _('Auto'), value: 'auto' },
                        { label: _('Show'), value: 'show' },
                        { label: _('Hide'), value: 'hide' },
                    ],
                },
            },
        ],
        [
            {
                label: _('Major tick size (px)'),
                option: 'xAxisMajorTickSize',
                editor: 'editor.slider',
                editorProps: {
                    min: 0,
                    max: 10,
                    step: 1,
                },
                showEditor: ({ options }) =>
                    options.xAxisMajorTickVisibility === 'show' ||
                    options.xAxisMajorTickVisibility === 'auto' ||
                    options.xAxisMajorTickVisibility === undefined,
            },
        ],
        [
            {
                label: _('Major tick interval spacing'),
                option: 'xAxisMajorTickInterval',
                editor: 'editor.number',
                showEditor: ({ options }) =>
                    options.xAxisMajorTickVisibility === 'show' ||
                    options.xAxisMajorTickVisibility === 'auto' ||
                    options.xAxisMajorTickVisibility === undefined,
            },
        ],
        [
            {
                label: _('Minor gridlines'),
                option: 'showXMinorGridLines',
                editor: 'editor.checkbox',
            },
        ],
        [
            {
                label: _('Minor tick visibility'),
                option: 'xAxisMinorTickVisibility',
                editor: 'editor.radioBar',
                editorProps: {
                    values: [
                        { label: _('Auto'), value: 'auto' },
                        { label: _('Show'), value: 'show' },
                        { label: _('Hide'), value: 'hide' },
                    ],
                },
            },
        ],
        [
            {
                label: _('Minor tick size (px)'),
                option: 'xAxisMinorTickSize',
                editor: 'editor.slider',
                editorProps: {
                    min: 0,
                    max: 10,
                    step: 1,
                },
                showEditor: ({ options }) =>
                    options.xAxisMinorTickVisibility === 'show' ||
                    options.xAxisMinorTickVisibility === 'auto' ||
                    options.xAxisMinorTickVisibility === undefined,
            },
        ],
    ],
};

export default AxesChartXGridAndLabels;
