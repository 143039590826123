import { getTokenNameError } from '@splunk/dashboard-utils';

export const validateTokenName = ({
    value,
}: {
    value: string;
}): { message: string } | null => {
    const error = getTokenNameError(value);

    return error ? { message: error } : null;
};
