import { has, includes, get } from 'lodash';
import { isColor } from '@splunk/visualizations-shared/colorUtils';
import {
    validHeaderVisibilities,
    validAlignments,
    TextOverflow,
    TableHeaderVisibility,
    TableAlignment,
    CellRenderers,
    validCellRenderers,
    SparklineType,
    validSparklineTypes,
} from './consts';

const isArray = (v: any): boolean => Array.isArray(v);

interface TextOverFlowStyles {
    maxWidth?: string;
    overflow?: string;
    overflowWrap?: string;
    textOverflow?: string;
    whiteSpace?: string;
    wordBreak?: string;
    wordWrap?: string;
}
interface TableHeaderCell {
    /* unique key for the table header cell */
    key: string;
    /* value to be displayed in the table header cell */
    value: string;
    /* key that will be used for sorting */
    sortKey: string;
    truncate?: boolean;
    width?: number;
    style?: TextOverFlowStyles;
    textOverflowType?: string;
    showCell: boolean;
}
export interface TableHeaderStyle extends TextOverFlowStyles {
    backgroundColor?: string;
    color?: string;
}
export interface TableHeader {
    /* header visiibility control */
    visibility: TableHeaderVisibility;
    /* array containing table header cells */
    cells: TableHeaderCell[];
    /* styles to be applied to table */
    style: TableHeaderStyle;
}

export type TableCellValueType = string | number | (string | number)[];

interface TableRowStyles extends TextOverFlowStyles {
    backgroundColor?: string;
    color?: string;
    width?: number | 'auto';
}
export interface TableRowCell {
    /* unique key for the row cell */
    key: string;
    /* value to be displayed in the row cell. This is a layered value columnFormat.[FieldName].data > tableFormat.data > table  options */
    value: TableCellValueType;
    /* always the value from table options */
    rawValue: TableCellValueType;
    align: TableAlignment;
    cellType: CellRenderers;
    columnIndex: number;
    rowIndex: number;
    sparklineAreaColor?: string;
    sparklineColor?: string;
    sparklineType: SparklineType;
    header: string;
    style: TableRowStyles;
    showCell: boolean;
}

interface TableRow {
    /* unique key for each row */
    key: string;
    /* row index */
    index: number;
    /* array of table cells to be displayed in the row */
    cells: TableRowCell[];
}

export interface TableBody {
    /* collection of table rows */
    rows: TableRow[];
}

export interface TableStructure {
    tableHeader: TableHeader;
    tableBody: TableBody;
}

export interface TableFormat {
    align: TableAlignment[][];
    cellTypes: CellRenderers[][];
    data: TableCellValueType[][];
    /* header background color. NOTE: react table does not support background color for individual column headers */
    headerBackgroundColor: string;
    /* header text color. NOTE: react table does not support text color for individual column headers */
    headerColor: string;
    /* array of colors for the background color of each table row */
    rowBackgroundColors: string[] | string[][];
    /* array of colors for the text color of each table row */
    rowColors: string[] | string[][];
    sparklineAreaColors: string[][];
    sparklineColors: string[][];
    sparklineTypes: SparklineType[][];
}

interface ColumnFormatter {
    align: TableAlignment[];
    cellTypes: CellRenderers[];
    data: TableCellValueType[];
    rowBackgroundColors: string[];
    rowColors: string[];
    sparklineAreaColors: string[];
    sparklineColors: string[];
    sparklineTypes: SparklineType[];
    width: number | 'auto';
    textOverflow: TextOverflow;
}
export interface ColumnFormat {
    [k: string]: Partial<ColumnFormatter>;
}

export interface UseTableArgs {
    /* array of headers to be displayed */
    headers?: string[];
    /* 2 dimensional array of table data to be displayed */
    table?: TableCellValueType[][];
    /* header visibility control */
    headerVisibility?: TableHeaderVisibility;
    /* adds row number column */
    showRowNumbers?: boolean;
    /* shows or hides internal fields */
    showInternalFields?: boolean;
    /* table wide formatting options */
    tableFormat?: Partial<TableFormat>;
    /* column format for individual columns */
    columnFormat?: Partial<ColumnFormat>;
    /* pagination offset */
    paginationOffset?: number;
    /* count */
    count?: number;
}

interface GetOverflowStylesArgs {
    textOverflow: TextOverflow;
}

const textOverflowStyles = {
    anywhere: {
        wordBreak: 'break-all',
    },
    'break-word': {
        wordBreak: 'break-word',
        wordWrap: 'break-word',
        overflowWrap: 'break-word',
    },
    ellipsis: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
};

const empty = {};

export const getOverflowStyles = ({ textOverflow }: GetOverflowStylesArgs): TextOverFlowStyles => {
    return textOverflowStyles[textOverflow] || empty;
};

const generateKey = (value = '', i: string | number): string => `${i}-${value}`;

export const getTextOverflowType = (columnFormat: Partial<ColumnFormat>, currentColumn: string): string =>
    get(columnFormat, [`${currentColumn}`, 'textOverflow']);

const generateHeader = (
    value: string,
    i: number,
    columnFormat: Partial<ColumnFormat>,
    showInternalFields: boolean
): TableHeaderCell => {
    const ret: TableHeaderCell = {
        key: generateKey(value, i),
        value,
        sortKey: value,
        showCell: true,
    };
    if (!showInternalFields && value.charAt(0) === '_' && value !== '_time') {
        ret.showCell = false;
    }

    const textOverflowType = getTextOverflowType(columnFormat, value);
    const textOverflowStyle = getOverflowStyles({
        textOverflow: textOverflowType as TextOverflow,
    });
    ret.truncate = true;
    ret.textOverflowType = textOverflowType;
    ret.style = textOverflowStyle;

    return ret;
};

const getColumnFormatAlign = (
    columnFormat: ColumnFormat,
    currentColumn: TableHeaderCell,
    currentRow: number
): TableAlignment | undefined => {
    const alignOption = get(columnFormat, [currentColumn.value, 'align']);
    let align;
    if (isArray(alignOption) && includes(validAlignments, alignOption[currentRow])) {
        align = alignOption[currentRow];
    } else if (typeof alignOption !== 'undefined') {
        console.warn(
            `columnFormat.${currentColumn.value}.align should be an array containing (${validAlignments.join(
                ' | '
            )}). ${JSON.stringify(alignOption)} provided`
        );
    }
    return align;
};

const getColumnFormatCellType = (
    columnFormat: ColumnFormat,
    currentColumn: TableHeaderCell,
    currentRow: number
): CellRenderers | undefined => {
    const cellTypes = get(columnFormat, [currentColumn.value, 'cellTypes']);
    let cellType;
    if (isArray(cellTypes) && includes(validCellRenderers, cellTypes[currentRow])) {
        cellType = cellTypes[currentRow];
    } else if (typeof cellTypes !== 'undefined') {
        console.warn(
            `columnFormat.${
                currentColumn.value
            }.cellType should be an array containing (${validCellRenderers.join(' | ')}). ${JSON.stringify(
                cellTypes
            )} provided`
        );
    }
    return cellType;
};

const getColumnFormatSparklineType = (
    columnFormat: ColumnFormat,
    currentColumn: TableHeaderCell,
    currentRow: number
): SparklineType | undefined => {
    const types = get(columnFormat, [currentColumn.value, 'sparklineTypes']);
    let sparklineType;
    if (isArray(types) && includes(validSparklineTypes, types[currentRow])) {
        sparklineType = types[currentRow];
    } else if (typeof types !== 'undefined') {
        console.warn(
            `columnFormat.${currentColumn.value}.sparklineTypes should be one of (${validSparklineTypes.join(
                ' | '
            )}). ${JSON.stringify(types)} provided`
        );
    }
    return sparklineType;
};

const getColumnFormatData = (
    columnFormat: ColumnFormat,
    currentColumn: TableHeaderCell,
    currentRow: number
): string | undefined => {
    const data = get(columnFormat, [currentColumn.value, 'data']);
    let datum;
    if (isArray(data)) {
        datum = data[currentRow];
    } else if (typeof data !== 'undefined') {
        console.warn(
            `columnFormat.${
                currentColumn.value
            }.data should be an array containing formatted data. ${typeof data} provided`
        );
    }
    return datum;
};

const getColumnFormatColor = (
    columnFormat: ColumnFormat,
    currentColumn: TableHeaderCell,
    currentRow: number,
    field: string
): string | undefined => {
    const colors = get(columnFormat, [currentColumn.value, field]);
    let color;
    if (isArray(colors) && isColor(colors[currentRow])) {
        color = colors[currentRow];
    } else if (typeof colors !== 'undefined') {
        console.warn(
            `columnFormat.${
                currentColumn.value
            }.${field} should be an array containing valid colors. ${JSON.stringify(colors)} provided`
        );
    }
    return color;
};

const getColumnFormatRowColor = (
    columnFormat: ColumnFormat,
    currentColumn: TableHeaderCell,
    currentRow: number
): string | undefined => {
    return getColumnFormatColor(columnFormat, currentColumn, currentRow, 'rowColors');
};

const getColumnFormatRowBgColor = (
    columnFormat: ColumnFormat,
    currentColumn: TableHeaderCell,
    currentRow: number
): string | undefined => {
    return getColumnFormatColor(columnFormat, currentColumn, currentRow, 'rowBackgroundColors');
};

const getColumnFormatSparklineColor = (
    columnFormat: ColumnFormat,
    currentColumn: TableHeaderCell,
    currentRow: number
): string | undefined => {
    return getColumnFormatColor(columnFormat, currentColumn, currentRow, 'sparklineColors');
};

const getColumnFormatSparklineAreaColor = (
    columnFormat: ColumnFormat,
    currentColumn: TableHeaderCell,
    currentRow: number
): string | undefined => {
    return getColumnFormatColor(columnFormat, currentColumn, currentRow, 'sparklineAreaColors');
};

const getTableFormatAlign = (
    tableFormat: Partial<TableFormat>,
    currentColumn: number,
    currentRow: number
): TableAlignment | undefined => {
    const alignOption = get(tableFormat, 'align');
    let align;
    if (
        isArray(alignOption) &&
        isArray(alignOption[currentColumn]) &&
        includes(validAlignments, alignOption[currentColumn][currentRow])
    ) {
        align = alignOption[currentColumn][currentRow];
    } else if (typeof alignOption !== 'undefined') {
        console.warn(
            `tableFormat.align should be a 2 dimensional array containing (${validAlignments.join(
                ' | '
            )}). ${JSON.stringify(alignOption)} provided`
        );
    }
    return align;
};

const getTableFormatCellType = (
    tableFormat: Partial<TableFormat>,
    currentColumn: number,
    currentRow: number
): CellRenderers | undefined => {
    const cellTypes = get(tableFormat, 'cellTypes');
    let cellType;
    if (
        isArray(cellTypes) &&
        isArray(cellTypes[currentColumn]) &&
        includes(validCellRenderers, cellTypes[currentColumn][currentRow])
    ) {
        cellType = cellTypes[currentColumn][currentRow];
    } else if (typeof cellTypes !== 'undefined') {
        console.warn(
            `tableFormat.cellType should be a 2 dimensional array containing (${validCellRenderers.join(
                ' | '
            )}). ${JSON.stringify(cellTypes)} provided`
        );
    }
    return cellType;
};

const getTableFormatSparklineType = (
    tableFormat: Partial<TableFormat>,
    currentColumn: number,
    currentRow: number
): SparklineType | undefined => {
    const types = get(tableFormat, 'sparklineTypes');
    let sparklineType;
    if (
        isArray(types) &&
        isArray(types[currentColumn]) &&
        includes(validSparklineTypes, types[currentColumn][currentRow])
    ) {
        sparklineType = types[currentColumn][currentRow];
    } else if (typeof types !== 'undefined') {
        console.warn(
            `tableFormat.sparklineTypes should be a 2 dimensional array containing one of (${validSparklineTypes.join(
                ' | '
            )}). ${JSON.stringify(types)} provided`
        );
    }
    return sparklineType;
};

const getTableFormatColor = (
    tableFormat: Partial<TableFormat>,
    currentColumn: number,
    currentRow: number,
    field: string
): string | undefined => {
    const colors = get(tableFormat, field);
    let color;
    if (isArray(colors) && isArray(colors[currentColumn]) && isColor(colors[currentColumn][currentRow])) {
        color = colors[currentColumn][currentRow];
    } else if (isArray(colors) && isColor(colors[currentRow])) {
        color = colors[currentRow];
    } else if (
        typeof colors !== 'undefined' &&
        isArray(colors) &&
        isArray(colors[currentColumn]) &&
        colors[currentColumn][currentRow] !== undefined
    ) {
        console.warn(
            `tableFormat.${field} needs to be an array of colors, provided ${JSON.stringify(
                colors
            )}. Current value at [${currentColumn}][${currentRow}] is ${JSON.stringify(colors[currentRow])}`
        );
    }
    return color;
};

const getTableFormatRowColor = (
    tableFormat: Partial<TableFormat>,
    currentCol: number,
    currentRow: number
): string | undefined => {
    return getTableFormatColor(tableFormat, currentCol, currentRow, 'rowColors');
};

const getTableFormatRowBgColor = (
    tableFormat: Partial<TableFormat>,
    currentCol: number,
    currentRow: number
): string | undefined => {
    return getTableFormatColor(tableFormat, currentCol, currentRow, 'rowBackgroundColors');
};

const getTableFormatSparklineColor = (
    tableFormat: Partial<TableFormat>,
    currentCol: number,
    currentRow: number
): string | undefined => {
    return getTableFormatColor(tableFormat, currentCol, currentRow, 'sparklineColors');
};

const getTableFormatSparklineAreaColor = (
    tableFormat: Partial<TableFormat>,
    currentCol: number,
    currentRow: number
): string | undefined => {
    return getTableFormatColor(tableFormat, currentCol, currentRow, 'sparklineAreaColors');
};

const getTableFormatData = (
    tableFormat: Partial<TableFormat>,
    currentCol: number,
    currentRow: number
): string | undefined => {
    const data = get(tableFormat, 'data');
    let datum;
    if (isArray(data) && isArray(data[currentCol])) {
        datum = data[currentCol][currentRow];
    } else if (typeof data !== 'undefined') {
        console.warn(
            `tableFormat.data should be a 2 dimensional array containing formatted data to be displayed. ${typeof data} provided`
        );
    }
    return datum;
};

const validValueSelector = (
    v1: TableCellValueType,
    v2: TableCellValueType,
    v3: TableCellValueType,
    defaultValue: TableCellValueType
): TableCellValueType => {
    const validTypes = ['string', 'number'];
    if (validTypes.includes(typeof v1) || isArray(v1)) {
        return v1;
    }
    if (validTypes.includes(typeof v2) || isArray(v2)) {
        return v2;
    }
    if (validTypes.includes(typeof v3) || isArray(v3)) {
        return v3;
    }
    return defaultValue;
};

export const useTable = (tableArgs: UseTableArgs = {}): TableStructure => {
    let { table = [], headers = [] } = tableArgs;
    const {
        headerVisibility = 'inline',
        showRowNumbers = false,
        showInternalFields = true,
        tableFormat = {},
        columnFormat = {},
        paginationOffset = 0,
        count = 0,
    } = tableArgs;

    const longestColumnLength: number = Math.max(...table.map((column): number => column.length));

    if (showRowNumbers) {
        const correctedPaginationOffset = Math.max(0, (paginationOffset - 1) * count);

        headers = ['#', ...headers];
        table = [
            Array.from({ length: longestColumnLength }, (v, i): number => correctedPaginationOffset + i + 1),
            ...table,
        ];
        if (isArray(tableFormat.align)) {
            tableFormat.align = [new Array(longestColumnLength).fill('left'), ...tableFormat.align];
        }
        if (isArray(tableFormat.cellTypes)) {
            tableFormat.cellTypes = [
                new Array(longestColumnLength).fill('TextCell'),
                ...tableFormat.cellTypes,
            ];
        }
        if (isArray(tableFormat.data)) {
            tableFormat.data = [
                Array.from({ length: longestColumnLength }, (v, i): string => `${i + 1}`),
                ...tableFormat.data,
            ];
        }
        if (isArray(tableFormat.rowBackgroundColors)) {
            if (tableFormat.rowBackgroundColors.length > 0 && isArray(tableFormat.rowBackgroundColors[0])) {
                tableFormat.rowBackgroundColors = [
                    new Array(longestColumnLength),
                    ...tableFormat.rowBackgroundColors,
                ] as string[][];
            }
        }
        if (isArray(tableFormat.rowColors)) {
            if (tableFormat.rowColors.length > 0 && isArray(tableFormat.rowColors[0])) {
                tableFormat.rowColors = [
                    new Array(longestColumnLength),
                    ...tableFormat.rowColors,
                ] as string[][];
            }
        }
        if (isArray(tableFormat.sparklineColors)) {
            tableFormat.sparklineColors = [new Array(longestColumnLength), ...tableFormat.sparklineColors];
        }
        if (isArray(tableFormat.sparklineAreaColors)) {
            tableFormat.sparklineAreaColors = [
                new Array(longestColumnLength),
                ...tableFormat.sparklineAreaColors,
            ];
        }
        if (isArray(tableFormat.sparklineTypes)) {
            tableFormat.sparklineTypes = [
                new Array(longestColumnLength).fill('area'),
                ...tableFormat.sparklineTypes,
            ];
        }
    }

    const tableHeader: TableHeader = {
        visibility:
            validHeaderVisibilities.indexOf(headerVisibility as TableHeaderVisibility) >= 0
                ? (headerVisibility as TableHeaderVisibility)
                : 'inline',
        cells: table.map(
            (f, i): TableHeaderCell => {
                if (i < headers.length) {
                    return generateHeader(headers[i], i, columnFormat, showInternalFields);
                }
                return generateHeader('', i, columnFormat, showInternalFields);
            }
        ),
        style: {
            backgroundColor:
                (has(tableFormat, 'headerBackgroundColor') &&
                    isColor(tableFormat.headerBackgroundColor) &&
                    tableFormat.headerBackgroundColor) ||
                undefined,
            color:
                (has(tableFormat, 'headerColor') &&
                    isColor(tableFormat.headerColor) &&
                    tableFormat.headerColor) ||
                undefined,
        },
    };

    const tableBody: TableBody = {
        rows: [],
    };

    for (let currentRow = 0; currentRow < longestColumnLength; currentRow += 1) {
        const row: TableRow = {
            key: `row-${currentRow}`,
            index: currentRow,
            cells: [],
        };

        table.forEach((column, i): void => {
            const currentHeader = tableHeader.cells[i];

            const value = validValueSelector(
                getColumnFormatData(columnFormat, currentHeader, currentRow),
                getTableFormatData(tableFormat, i, currentRow),
                column[currentRow],
                ''
            );

            const rawValue = column[currentRow];

            const rowBackgroundColor =
                getColumnFormatRowBgColor(columnFormat, currentHeader, currentRow) ||
                getTableFormatRowBgColor(tableFormat, i, currentRow) ||
                undefined;

            const rowColor =
                getColumnFormatRowColor(columnFormat, currentHeader, currentRow) ||
                getTableFormatRowColor(tableFormat, i, currentRow) ||
                undefined;

            const align =
                getColumnFormatAlign(columnFormat, currentHeader, currentRow) ||
                getTableFormatAlign(tableFormat, i, currentRow) ||
                'left';

            const cellType =
                getColumnFormatCellType(columnFormat, currentHeader, currentRow) ||
                getTableFormatCellType(tableFormat, i, currentRow) ||
                'TextCell';

            const sparklineColor =
                getColumnFormatSparklineColor(columnFormat, currentHeader, currentRow) ||
                getTableFormatSparklineColor(tableFormat, i, currentRow) ||
                undefined;

            const sparklineAreaColor =
                getColumnFormatSparklineAreaColor(columnFormat, currentHeader, currentRow) ||
                getTableFormatSparklineAreaColor(tableFormat, i, currentRow) ||
                undefined;

            const sparklineType =
                getColumnFormatSparklineType(columnFormat, currentHeader, currentRow) ||
                getTableFormatSparklineType(tableFormat, i, currentRow) ||
                'area';

            const key = generateKey(value as string, `${currentRow}-${i}`);

            let additionalStyles = {};
            const textOverflowType = getTextOverflowType(columnFormat, currentHeader.value);
            const textOverflowStyle = getOverflowStyles({
                textOverflow: textOverflowType as TextOverflow,
            });
            additionalStyles = {
                ...additionalStyles,
                ...textOverflowStyle,
            };

            let showCell = true;
            if (
                !showInternalFields &&
                currentHeader.value.charAt(0) === '_' &&
                currentHeader.value !== '_time'
            ) {
                showCell = false;
            }
            row.cells.push({
                key,
                value,
                rawValue,
                align,
                cellType,
                rowIndex: currentRow,
                columnIndex: i,
                sparklineColor,
                sparklineAreaColor,
                sparklineType,
                header: currentHeader.value,
                style: {
                    backgroundColor: rowBackgroundColor,
                    color: rowColor,
                    ...additionalStyles,
                },
                showCell,
            });
        });

        tableBody.rows.push(row);
    }

    return {
        tableHeader,
        tableBody,
    };
};
