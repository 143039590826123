import React from 'react';
import { _ } from '@splunk/ui-utils/i18n';
import SVG, {
    type SVGPropsOptionalViewBox,
} from '@splunk/react-icons/SVGEnterprise';

/* eslint-disable max-len */
export default function Help(
    props: SVGPropsOptionalViewBox
): ReturnType<typeof SVG> {
    return (
        <SVG viewBox="0 0 24 24" screenReaderText={_('Help')} {...props}>
            <path
                fill="currentColor"
                d="M12.125 8.73952C11.9876 8.68854 11.8398 8.67253 11.6947 8.69293L11.6556 8.69844C11.5061 8.71946 11.3577 8.7752 11.2282 8.85991C11.0924 8.9487 10.9768 9.0681 10.8946 9.20341L10.673 9.56788C10.3862 10.0398 9.77103 10.1899 9.29909 9.90299C8.82715 9.61612 8.67712 9.00099 8.96398 8.52905L9.18552 8.16458C9.42472 7.77106 9.75152 7.43584 10.1337 7.18596C10.5062 6.94243 10.9325 6.78043 11.377 6.71792L11.4162 6.71242C11.8898 6.64582 12.3726 6.69809 12.8209 6.86452C13.0423 6.9467 13.2528 7.05578 13.4476 7.18929L13.4959 7.22238C13.8511 7.46583 14.1439 7.78961 14.3505 8.16743L14.4031 8.26363C14.6997 8.80588 14.7811 9.43994 14.6312 10.0395C14.5188 10.489 14.2817 10.8976 13.9472 11.2182L13.5404 11.6081L13.131 12.024C13.1096 12.0457 13.091 12.0701 13.0757 12.0965C13.0436 12.1518 13.0268 12.2146 13.0268 12.2785V12.6364C13.0268 13.1887 12.579 13.6364 12.0268 13.6364C11.4745 13.6364 11.0268 13.1887 11.0268 12.6364V12.2785C11.0268 11.8623 11.1367 11.4536 11.3453 11.0936C11.4451 10.9215 11.5662 10.7627 11.7057 10.6209L12.1355 10.1843L12.5633 9.77428C12.6257 9.71449 12.6699 9.63829 12.6909 9.55445C12.7189 9.44263 12.7037 9.32438 12.6484 9.22325L12.5958 9.12705C12.54 9.02511 12.461 8.93776 12.3652 8.87208L12.3169 8.83899C12.2572 8.7981 12.1928 8.76469 12.125 8.73952Z"
            />
            <path
                fill="currentColor"
                d="M13.091 15.8182C13.091 16.4207 12.6026 16.9091 12.0001 16.9091C11.3976 16.9091 10.9092 16.4207 10.9092 15.8182C10.9092 15.2157 11.3976 14.7273 12.0001 14.7273C12.6026 14.7273 13.091 15.2157 13.091 15.8182Z"
            />
            <path
                fill="currentColor"
                fillRule="evenodd"
                clipRule="evenodd"
                d="M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12ZM20 12C20 16.4183 16.4183 20 12 20C7.58172 20 4 16.4183 4 12C4 7.58172 7.58172 4 12 4C16.4183 4 20 7.58172 20 12Z"
            />
        </SVG>
    );
}
