import { _ } from '@splunk/ui-utils/i18n';
import { isDynamicOption } from '@splunk/visualizations-shared/configUtils';

export const getDynamicLinkColorsEditor = ({ defaultContext, defaultPalettesConfig }) => ({
    label: _('Link'),
    option: 'linkColors',
    context: 'linkColorRangeConfig',
    editor: 'editor.dynamicColor',
    showEditor: ({ options }) => isDynamicOption(options.linkColors),
    editorProps: {
        labelPosition: 'top',
        flyoutTitle: _('Dynamic coloring: link'),
        formatters: [
            {
                label: _('Ranges'),
                value: 'rangeValue',
                defaults: {
                    linkValues: defaultContext.linkColorRangeConfig,
                },
            },
            {
                label: _('Matches'),
                value: 'matchValue',
                defaults: {
                    linkValues: defaultContext.linkColorMatchConfig,
                },
            },
        ],
        dataSelectors: [
            {
                label: _('Link'),
                value: 'linkValues',
            },
        ],
        defaultPalettesConfig,
    },
});
