import React from 'react';
import { _ } from '@splunk/ui-utils/i18n';
import SVG, {
    type SVGPropsOptionalViewBox,
} from '@splunk/react-icons/SVGEnterprise';

/* eslint-disable max-len */
export default function Clone(
    props: SVGPropsOptionalViewBox
): ReturnType<typeof SVG> {
    return (
        <SVG screenReaderText={_('Clone')} viewBox="0 0 24 24" {...props}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8.25421 3.0061C7.70193 3.0061 7.25421 3.45382 7.25421 4.0061V15.6317C7.25421 16.184 7.70193 16.6317 8.25421 16.6317H19.8798C20.4321 16.6317 20.8798 16.184 20.8798 15.6317V4.0061C20.8798 3.45382 20.4321 3.0061 19.8798 3.0061H8.25421ZM3.88574 7.58572C4.43803 7.58572 4.88574 8.03344 4.88574 8.58572V19.0002H15.3002C15.8525 19.0002 16.3002 19.4479 16.3002 20.0002C16.3002 20.5525 15.8525 21.0002 15.3002 21.0002H4.38574C3.55732 21.0002 2.88574 20.3286 2.88574 19.5002V8.58572C2.88574 8.03344 3.33346 7.58572 3.88574 7.58572ZM15.0674 7.31885C15.0674 6.76656 14.6197 6.31885 14.0674 6.31885C13.5151 6.31885 13.0674 6.76656 13.0674 7.31885V8.81885H11.5674C11.0151 8.81885 10.5674 9.26656 10.5674 9.81885C10.5674 10.3711 11.0151 10.8188 11.5674 10.8188H13.0674V12.3188C13.0674 12.8711 13.5151 13.3188 14.0674 13.3188C14.6197 13.3188 15.0674 12.8711 15.0674 12.3188V10.8188H16.5674C17.1197 10.8188 17.5674 10.3711 17.5674 9.81885C17.5674 9.26656 17.1197 8.81885 16.5674 8.81885H15.0674V7.31885Z"
                fill="currentColor"
            />
        </SVG>
    );
}
