/**
 * This is the output of payload filtering, used in the MetricsCollector payload
 */
export type CollectorData = {
    pageAction?: string;
    metadata: Record<string, unknown>;
    udfVersion: string;
    [key: string]: unknown;
};

/**
 * This is the expected input to a MetricsCollector's sendEvent
 */
export type CollectorPayload = {
    eventType: string;
    level: string;
    data: CollectorData;
    [key: string]: unknown;
};

export abstract class MetricsCollector {
    abstract sendEvent(payload: CollectorPayload): void;
}
