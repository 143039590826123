import React, { useMemo } from 'react';
import styled from 'styled-components';
import { mixins } from '@splunk/themes';
import { customThemeVariables } from '@splunk/dashboard-ui';
import type {
    GridLayoutStructure,
    GridLayoutOptions,
} from '@splunk/dashboard-types';

import BaseLayout from './BaseLayout';
import { GridItem } from './components';
import { gridLayoutOptions } from './DefaultOptions';
import { getCSSGridTemplate } from './utils/gridLayoutViewerUtils';
import { removeInvalidItems, computeMaxHeight } from './utils/layoutUtils';
import type { RenderLayoutItem } from './types';

interface GridCanvasProps {
    height: number;
    gutterSize: number;
    colTemplate: string;
    rowTemplate: string;
}

const GridCanvas = styled.div<GridCanvasProps>`
    ${mixins.reset('grid')};
    height: ${(props) => props.height}px;
    width: 100%;
    padding: ${(props) => props.gutterSize / 2}px;
    box-sizing: border-box;
    grid-template-columns: ${(props) => props.colTemplate};
    grid-template-rows: ${(props) => props.rowTemplate};
    gap: ${(props) => props.gutterSize}px;
    background: ${customThemeVariables.dashboardBackgroundColor};
`;
GridCanvas.displayName = 'GridCanvas';

export interface GridLayoutViewerProps {
    /*
     * The grid layout structure to render.
     */
    layoutStructure: GridLayoutStructure;
    /*
     * A method to render the items in the layout structure.
     */
    renderLayoutItem: RenderLayoutItem;
    /*
     * The options for the grid layout.
     */
    options: GridLayoutOptions;
}

const GridLayoutViewer = ({
    layoutStructure,
    renderLayoutItem,
    options,
}: GridLayoutViewerProps): JSX.Element => {
    const { width, gutterSize } = {
        ...gridLayoutOptions,
        ...options,
    };

    const { height, gridItems, rowTemplate, colTemplate } = useMemo(() => {
        const filteredStructure = removeInvalidItems(
            layoutStructure
        ) as GridLayoutStructure;
        const structureHeight = computeMaxHeight(filteredStructure);

        const { gridRowTemplate, gridColTemplate } = getCSSGridTemplate({
            layoutStructure: filteredStructure,
            width,
            height: structureHeight,
        });

        const items = filteredStructure.map(({ item: itemId, type }) => (
            <GridItem
                itemId={itemId}
                type={type}
                key={itemId}
                renderLayoutItem={renderLayoutItem}
            />
        ));

        return {
            height: structureHeight,
            gridItems: items,
            rowTemplate: gridRowTemplate,
            colTemplate: gridColTemplate,
        };
    }, [layoutStructure, width, renderLayoutItem]);

    return (
        <GridCanvas
            data-test="grid-layout-canvas"
            data-layout-type="grid"
            height={height}
            gutterSize={gutterSize}
            rowTemplate={rowTemplate}
            colTemplate={colTemplate}
        >
            {gridItems}
        </GridCanvas>
    );
};

GridLayoutViewer.propTypes = {
    ...BaseLayout.propTypes,
};

GridLayoutViewer.defaultProps = {
    ...BaseLayout.defaultProps,
};

export default GridLayoutViewer;
