import React, { createContext, useContext, useMemo } from 'react';
import { selectLayoutStructure, useSelector } from '@splunk/dashboard-state';
import { memoize } from 'lodash';

type StructureItemLike = { item: string };

const isLayerableStructureItem = (item: unknown): item is StructureItemLike =>
    typeof item === 'object' &&
    item !== null &&
    typeof (item as Record<'item', unknown>).item === 'string';

const convertToMemoizedContextValue = memoize<
    (items: StructureItemLike[]) => LayoutLayersContextValue
>(
    (items) =>
        items.reduce<LayoutLayersContextValue>((newValue, { item }, idx) => {
            // eslint-disable-next-line no-param-reassign
            newValue[item] = {
                layer: idx,
                selection: items.length,
                actionMenu: items.length + idx + 1,
            };

            return newValue;
        }, {}),
    // Only key the cache using the item IDs so position changes don't create new results.
    // Use Array.prototype.join instead of JSON.strigify due to slightly better performance
    (items) => items.map(({ item }) => item).join(',')
);

export interface LayersInfo {
    layer: number;
    actionMenu?: number;
    selection?: number;
}

export type LayoutLayersContextValue = Record<string, LayersInfo>;

const defaultContextValue: LayoutLayersContextValue = {};

const LayoutLayersContext =
    createContext<LayoutLayersContextValue>(defaultContextValue);

export const LayoutLayersContextProvider = ({
    children,
}: {
    children?: React.ReactNode;
}): JSX.Element => {
    const layoutStructure = useSelector(selectLayoutStructure);

    const contextValue = useMemo(() => {
        if (!Array.isArray(layoutStructure)) {
            return defaultContextValue;
        }

        // Pre-filter the layout structure so invalid entries don't cause gaps in the context value
        const validItems = layoutStructure.filter(isLayerableStructureItem);

        // Don't return a new object reference if no layout items are valid
        if (validItems.length === 0) {
            return defaultContextValue;
        }

        // Memoize the reduced object so changes in item positions don't cause context updates
        return convertToMemoizedContextValue(validItems);
    }, [layoutStructure]);

    return (
        <LayoutLayersContext.Provider value={contextValue}>
            {children}
        </LayoutLayersContext.Provider>
    );
};

export const useLayoutLayers = () => useContext(LayoutLayersContext);
export default LayoutLayersContext;
