import { createAction, createReducer } from '@reduxjs/toolkit';
import type { Mode, GlobalState } from '@splunk/dashboard-types';
import resetStore from './resetStore';

// Ideally, this would be created automatically by createSlice, but we can't use createSlice with primitive states
export const switchMode = createAction<Mode>('mode/update');

const initialState: Mode = 'view';

/**
 * Select mode from the global state
 */
export const selectMode = (state: GlobalState): Mode => state?.mode ?? 'view';

// Create a state slice, ideally we'd use createSlice here, but the CaseReducer type it uses doesn't deal well with returning primitives
const reducer = createReducer<Mode>(initialState, (builder) => {
    builder
        // action: mode/update
        .addCase(switchMode, (state, action) => action.payload)
        // action: store/reset
        .addCase(resetStore, (state, action) => action.payload.mode || state);
});

export default reducer;
