import React from 'react';
import Text, { type TextPropsBase } from '@splunk/react-ui/Text';
import { useTextInput } from './hooks/useTextInput';

const noop = () => undefined;

interface TextEditorProps extends Partial<TextPropsBase> {
    value: string;
    onValueChange?: (val: string) => void;
    uniqKey?: unknown;
    'data-test'?: string;
    defaultValue?: string;
    canClear?: boolean;
    id?: string;
}

const TextEditor = ({
    value: initialValue,
    onValueChange = noop,
    uniqKey,
    'data-test': dataTest = 'text-editor',
    defaultValue,
    canClear = true,
    id,
    ...otherProps
}: TextEditorProps): JSX.Element => {
    const { onHandleBlur, onHandleChange, onHandleKeyDown, value } =
        useTextInput({
            initialValue,
            handleCommit: onValueChange,
            uniqKey,
            defaultValue,
        });

    return (
        <Text
            {...otherProps}
            canClear={canClear}
            value={value}
            onBlur={onHandleBlur}
            onChange={onHandleChange}
            onKeyDown={onHandleKeyDown}
            data-test={dataTest}
            inputId={id}
        />
    );
};

export default TextEditor;
