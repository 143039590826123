import React from 'react';
import T from 'prop-types';
import { _ } from '@splunk/ui-utils/i18n';
import SVG, {
    type SVGPropsOptionalViewBox,
} from '@splunk/react-icons/SVGEnterprise';

/* eslint-disable max-len */
export default function Source({
    screenReaderText,
    ...props
}: SVGPropsOptionalViewBox): ReturnType<typeof SVG> {
    return (
        <SVG viewBox="0 0 32 32" screenReaderText={screenReaderText} {...props}>
            <path
                fill="currentColor"
                fillRule="evenodd"
                d="M4 4h24v24H4V4zm2 2h20v4.5H6V6zm0 6.5V26h20V12.5H6z"
            />
            <path
                fill="currentColor"
                fillRule="evenodd"
                d="M7.652 18.425l3.695-3.183 1.306 1.516-2.949 2.539 2.563 3.061-1.534 1.284-3.195-3.818a1 1 0 0 1 .114-1.4zM24.653 18.425l-3.696-3.183-1.305 1.516 2.948 2.539-2.562 3.061 1.534 1.284 3.195-3.818a1 1 0 0 0-.114-1.4zM18.803 15.656l-3.778 8.5-1.828-.812 3.779-8.5 1.827.812z"
            />
        </SVG>
    );
}

Source.propTypes = {
    screenReaderText: T.string,
};

Source.defaultProps = {
    screenReaderText: _('Source code'),
};
