import React from 'react';
import { useSelector, selectDefinition } from '@splunk/dashboard-state';
import type { DashboardJSON } from '@splunk/dashboard-types';

interface DefinitionProps {
    definition: DashboardJSON;
}

export const withDefinition =
    <P extends DefinitionProps>(WrappedComponent: React.ComponentType<P>) =>
    (props: Omit<P, keyof DefinitionProps>) => {
        const definition = useSelector(selectDefinition);

        return <WrappedComponent {...(props as P)} definition={definition} />;
    };
