import { MetricsCollector, type CollectorPayload } from './MetricsCollector';

export abstract class PerformanceCollector extends MetricsCollector {
    sendEvent(payload: CollectorPayload): void {
        const {
            data: { pageAction },
        } = payload;
        switch (pageAction) {
            case 'performance.createTimer':
                this.createTimer(payload);
                break;
            case 'performance.startTimer':
                this.startTimer(payload);
                break;
            case 'performance.mark':
                this.mark(payload);
                break;
            case 'performance.markAll':
                this.markAll(payload);
                break;
            case 'performance.endTimer':
                this.endTimer(payload);
                break;
            default:
        }
    }

    abstract createTimer(payload: CollectorPayload): void;

    abstract startTimer(payload: CollectorPayload): void;

    abstract mark(payload: CollectorPayload): void;

    abstract markAll(payload: CollectorPayload): void;

    abstract endTimer(payload: CollectorPayload): void;
}
