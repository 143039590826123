import { FieldEntry } from './dataSourceUtils';

export const CHART = 'chart';
export const TIMECHART = 'timechart';
export const STATS = 'stats';
export const UNKNOWN = 'unknown';
export type DataSplitMode = 'timechart' | 'chart' | 'stats' | 'unknown';

export const getTrellisDataSplitterMode = (fields: FieldEntry[]): DataSplitMode => {
    /**
     * Possible logic to determine the dataSource mode
     *
     * if (fields[0].name == '_time') and (fields[0].groupby_rank == 0) => timechart data
     * if (fields[0].name != '_time') and (fields[0].groupby_rank == 0) and fields[idx] (where idx >=1 and fields[idx] is not an internal field) has 'splitby_field' key  => chart data
     * if fields have no 'splitby_field' key and has 'groupby_rank' for fields ‘0,1,2,..’ => stats data
     * else return 'unknown' (means no field meta info exist to distinguish the datasource)
     */
    if (!fields) {
        return UNKNOWN;
    }
    if (isTimeChartCmdData(fields)) {
        return TIMECHART;
    }

    if (isChartCmdData(fields)) {
        return CHART;
    }

    if (isStatsCmdData(fields)) {
        return STATS;
    }

    return UNKNOWN;
};

export const isTimeChartCmdData = (fields: FieldEntry[]): boolean => {
    return (
        fields &&
        fields.length > 0 &&
        fields[0] &&
        fields[0].name === '_time' &&
        fields[0].groupby_rank === '0'
    );
};

export const isChartCmdData = (fields: FieldEntry[]): boolean => {
    if (!fields || !fields.length) {
        return false;
    }

    if (!checkForTrellisFieldMetaInfo(fields)) {
        return false;
    }

    if (fields.length > 1) {
        return fields[0].name !== '_time' && fields[0].groupby_rank === '0' && !fields[1].groupby_rank; // for eg:- only first field has `groupby_rank` (index = "_internal" | chart avg(count) max(count) by status)
    }
    for (let i = 1; i < fields.length; i += 1) {
        if (!fields[i].splitby_field) {
            return false;
        }
    }
    return true;
};

export const isStatsCmdData = (fields: FieldEntry[]): boolean => {
    if (!fields || !fields.length) {
        return false;
    }

    if (!checkForTrellisFieldMetaInfo(fields)) {
        return false;
    }

    const groupByRanks = [];
    for (let i = 0; i < fields.length; i += 1) {
        if (fields[i].splitby_field) {
            return false;
        }
        if (fields[i].groupby_rank) {
            groupByRanks.push(fields[i].groupby_rank);
        }
    }
    return groupByRanks.length > 0 && groupByRanks.length < fields.length;
};

// if no field meta exist in fields (for eg:- 'groupby_rank', 'splitby_field'), return false
export const checkForTrellisFieldMetaInfo = (fields: FieldEntry[]): boolean => {
    if (!fields || !fields.length) {
        return false;
    }
    for (let i = 0; i < fields.length; i += 1) {
        if (
            fields[i].groupby_rank ||
            fields[i].splitby_field ||
            fields[i].splitby_value ||
            fields[i].data_source
        ) {
            return true;
        }
    }
    return false;
};

// Returns possible split by field values for timechart and chart cmds
export const extractSplitByFieldsChartFields = (fields: FieldEntry[]): string[] => {
    const fieldsWithoutTime = fields.filter(({ name }) => name !== '_time');
    const splitByFields = [];
    fieldsWithoutTime.forEach(field => {
        if (field.splitby_field) {
            splitByFields.push(field.splitby_field);
        }
    });
    return isTimeChartCmdData(fields)
        ? [...new Set(splitByFields)]
        : [fields[0].name, ...new Set(splitByFields)];
};

// Extracts the unique field `data_source` values from fields metainfo. Used to compute possible aggregation field values for timechart and chart cmds
export const extractDataSourceAggregationKeysChart = (fields: FieldEntry[]): string[] => {
    const fieldsWithoutTime = fields.filter(({ name }) => name !== '_time');
    const aggregations = [];
    fieldsWithoutTime.forEach(field => {
        if (field.data_source) {
            aggregations.push(field.data_source);
        }
    });
    return [...new Set(aggregations)];
};

// Returns possible aggregation field values for timechart and chart cmds
export const extractAllAggregationKeysChart = (fields: FieldEntry[]): string[] => {
    let aggregations = [];

    aggregations = extractDataSourceAggregationKeysChart(fields);
    if (aggregations && aggregations.length) {
        return aggregations;
    }
    if ((!aggregations || !aggregations.length) && fields.length > 1) {
        // handle where data_source doesnot exist in fields
        // example1 :- index=_internal | timechart count
        // example2 :- index=_internal |  timechart max(count) avg(count)
        for (let i = 1; i < fields.length; i += 1) {
            const fieldName = (fields[i] as FieldEntry).name;
            if (!fieldName.startsWith('_')) {
                aggregations.push(fieldName);
            }
        }
    }
    return aggregations;
};

// Returns possible aggregation field values for stats cmds (return field names of field that doesnt have `groupby_rank`)
export const extractDataSourceAggregationKeysStats = (fields: FieldEntry[]): string[] => {
    const aggregations = [];
    fields.forEach(field => {
        if (field.groupby_rank === undefined) {
            aggregations.push(field.name);
        }
    });
    return aggregations;
};

// Returns possible split by field values for stats cmds
export const extractSplitByFieldsForStats = (fields: FieldEntry[]): string[] => {
    if (!fields) {
        return [];
    }
    const fieldsWithGroupByRank = fields.filter(field => field.groupby_rank);
    return fieldsWithGroupByRank.map(field => field.name);
};

// Returns possible split by field values for generic cmds (eg:- makeresult, table) that are not chart/timechart/stats cmds
export const extractSplitByFieldsForUnknown = (fields: FieldEntry[]): string[] => {
    if (!fields) {
        return [];
    }
    return fields.filter(field => !field?.name.startsWith('_')).map(field => field.name);
};

// Given the fields meta info of the datasource, this method infers the data splitter mode and
// returns all possible individual splitBy fields ('fields' property) and aggregation fields ('aggregations' property)
export const getAllPossibleSplitByFields = (
    fields: FieldEntry[]
): { fields: string[]; aggregations: string[] } => {
    const result = { fields: [], aggregations: [] };
    if (!fields || !fields.length) {
        return result;
    }
    // Infer the data splitter mode
    const splitMode = getTrellisDataSplitterMode(fields);
    if (splitMode === TIMECHART || splitMode === CHART) {
        return {
            fields: extractSplitByFieldsChartFields(fields),
            aggregations: extractAllAggregationKeysChart(fields),
        };
    }
    if (splitMode === STATS) {
        return {
            fields: [...extractSplitByFieldsForStats(fields)],
            aggregations: [...extractDataSourceAggregationKeysStats(fields)],
        };
    }
    if (splitMode === UNKNOWN) {
        const fieldNames = extractSplitByFieldsForUnknown(fields);
        return fieldNames.length === 1
            ? {
                  fields: [],
                  aggregations: [...fieldNames], // only populate the aggregation
              }
            : {
                  fields: [...fieldNames],
                  aggregations: [...fieldNames],
              };
    }
    return result;
};
