import React, { useCallback, useMemo, useRef, useEffect } from 'react';
import T from 'prop-types';
import { _ } from '@splunk/ui-utils/i18n';
import { mapKeys } from 'lodash';
import SUIText from '@splunk/react-ui/Text';
import { Text as TextIcon } from '@splunk/dashboard-icons';
import { useTextInput } from '@splunk/dashboard-ui';
import { withInputWrapper } from '../utils/enhancer';
import BaseInput from '../components/BaseInput';
import TextSchema from './TextSchema';

const TextInputIcon = (props) => (
    <TextIcon screenReaderText={_('Text Input')} {...props} />
);

/**
 * We use SUI Text under the hood to render the input
 */
const Text = ({
    id,
    options: { placeholder: customPlaceholder, defaultValue },
    value: committedValue,
    onValueChange,
    isDisabled,
    disabledMessage,
    errorMessage,
    isError,
    isSelected,
}) => {
    const inputRef = useRef(null);
    const initialValue = committedValue || defaultValue || '';
    const placeholder =
        errorMessage || disabledMessage || customPlaceholder || '';
    const uniqKey = useMemo(() => ({ id }), [id]);
    const handleCommit = useCallback(
        (val) => onValueChange(null, val),
        [onValueChange]
    );

    const {
        onHandleBlur,
        onHandleChange,
        onHandleKeyDown,
        value: inputValue,
    } = useTextInput({
        initialValue,
        handleCommit,
        uniqKey,
        defaultValue,
    });

    useEffect(() => {
        // When input is selected, make sure we focus the textbox
        if (isSelected && !isDisabled && !!inputRef.current) {
            inputRef.current.focus({ preventScroll: true });
        }
    }, [isSelected, isDisabled]);

    return (
        <SUIText
            canClear
            inputId={id}
            placeholder={placeholder}
            disabled={isDisabled}
            error={isError}
            value={inputValue}
            onChange={onHandleChange}
            onKeyDown={onHandleKeyDown}
            onBlur={onHandleBlur}
            inputRef={inputRef}
        />
    );
};

/**
 * Transforms the value from the input to a token: value pair
 * @param {String} value Text input value
 * @param {Object} meta
 * @param {String} meta.token The token name
 * @param {String} meta.prefix Content that prepends the value
 * @param {String} meta.suffix Content that appends the value
 * @returns {Object}
 */
Text.valueToTokens = (value, { token, prefix = '', suffix = '' }) => {
    if (!token) {
        return {};
    }
    if (!value) {
        return {
            [token]: null,
        };
    }
    return {
        [token]: `${prefix}${value}${suffix}`,
    };
};

Text.propTypes = { ...BaseInput.propTypes, placeholder: T.string };
Text.defaultProps = { ...BaseInput.defaultProps };

const editor = [
    {
        enableCollapsible: false,
        enableSeparator: false,
        layout: [
            [
                {
                    label: _('Token name'),
                    option: 'token',
                    editor: 'editor.text',
                },
            ],
            [
                {
                    label: _('Default value'),
                    option: 'defaultValue',
                    editor: 'editor.text',
                },
            ],
        ],
    },
];

const meta = {
    label: _('Text'),
    description: _('Field to enter text'),
    defaultConfig: {
        options: {
            defaultValue: _('Default Text'),
        },
        title: 'Text Input Title',
    },
    tokenPrefix: 'text',
    icon: TextInputIcon,
};

Text.schema = TextSchema;
Text.editor = editor;
Text.meta = meta;

Text.config = {
    editorConfig: editor,
    optionsSchema: TextSchema,
    ...mapKeys(meta, (value, key) =>
        key === 'defaultConfig' ? 'baseShape' : key
    ),
};

export default withInputWrapper(Text);
