import { createAction } from '@reduxjs/toolkit';
import type { PayloadAction, Draft } from '@reduxjs/toolkit';
import type { DashboardJSON, LayoutOptions } from '@splunk/dashboard-types';

export const updateLayoutOptions = createAction<LayoutOptions>(
    'definition/layout/updateOptions'
);

export const updateLayoutOptionsReducer = (
    state: Draft<DashboardJSON>,
    action: PayloadAction<LayoutOptions>
): void => {
    if (state.layout) {
        state.layout.options = action.payload;
    }
};

export const updateLayoutOptionDisplay = createAction<string>(
    'definition/layout/updateOption/display'
);

export const updateLayoutOptionDisplayReducer = (
    state: Draft<DashboardJSON>,
    action: PayloadAction<string>
): void => {
    if (state.layout) {
        state.layout.options ??= {};
        state.layout.options.display = action.payload;
    }
};

// We support custom layouts which could have anything for the structure
export const updateLayoutStructure = createAction<unknown>(
    'definition/layout/updateStructure'
);

export const updateLayoutStructureReducer = (
    state: Draft<DashboardJSON>,
    action: PayloadAction<unknown>
): void => {
    if (state.layout) {
        state.layout.structure = action.payload;
    }
};

export const updateInputStructure = createAction<string[]>(
    'definition/layout/updateGlobalInputs'
);

export const updateInputStructureReducer = (
    state: Draft<DashboardJSON>,
    action: PayloadAction<string[]>
): void => {
    if (state.layout) {
        state.layout.globalInputs = action.payload;
    }
};
