import { DataPrimitiveFormatter } from '../Formatter';
import { DataType } from '../DataPrimitive';
import { DataFrame } from '../DataFrame';
import { formatterClasses } from '../FormatterPresets';

import EncodingExecutor from '../EncodingExecutor';

interface FormatterMapConfig {
    nameField: string;
    valueField: string;
    formatters: {
        [key: string]: {
            type: string;
            config: Record<string, any>;
        };
    };
}

/**
 * Formatter which accepts a config object containing a map of field-specific formatters.
 * The field-specific formatter is used to evaluate each fields' corresponding values.
 *
 * The three required fields are as follows:
 *
 * - `nameField`, which denotes the list of keys with custom formatters to be applied against
 *
 * - `valueField`, which denotes the field to derive the values from
 *
 * - `formatter1. which is an object that requires the `type` of formatter and the specific required for the specified formatter.
 *
 * ```js
 *     <ChoroplethSvg
 *         context={{
 *             areaColorsFormat: {
 *                 nameField: 'pathIds',
 *                 valueField: 'values',
 *                 formatters: {
 *                     id1: {
 *                         type: 'matchValue',
 *                         config: [{
 *                             match: 100,
 *                             value: '#FF0000'
 *                         }]
 *                     },
 *                     id2: {
 *                         type: 'rangeValue',
 *                         config: [
 *                             {
 *                                 to: 100,
 *                                 value: '#00FF00'
 *                             }
 *                             {
 *                                 from: 100,
 *                                 value: '#0000FF'
 *                             }
 *                         ]
 *                     }
 *                 }
 *             }
 *         }}
 *         options={{
 *             areaIds: '> primary | seriesByName("pathIds")', // returns ['id1', 'id2']
 *             areaValues: '> primary | seriesByName("values")', // returns [100, 200]
 *             areaColors: '> primary | multiFormat(areaColorsFormat)' // returns ['#FF0000', '#0000FF']
 *         }}
 *         dataSources={{
 *             primary: {
 *                 data: {
 *                     columns: [['id1', 'id2'], [100, 200]]
 *                     fields: [{ name: 'pathIds' }, { name: 'values' }]
 *                 }
 *             }
 *         }}
 *     />
 * ```
 *
 */
export class MultiFormat implements DataPrimitiveFormatter<DataType, DataType> {
    private config: FormatterMapConfig;

    constructor(formatConfig: FormatterMapConfig) {
        this.config = EncodingExecutor.rawTree(formatConfig);
    }

    format(f: DataFrame<DataType>): Record<string, any> {
        const namesDataSeries = f.seriesByName(this.config.nameField);
        const valuesDataSeries = f.seriesByName(this.config.valueField);

        // choroplethSVG expects that the names and values are of the same length
        // otherwise there would be no way to map an areaId with an areaValue
        return namesDataSeries.points.map((name, i) => {
            const { type, config } = this.config.formatters[name.getRawValue() as string];
            const FormatterClass = formatterClasses[type] as any;
            const value = valuesDataSeries.pointByIndex(i);
            if (!FormatterClass) {
                throw new Error(`unknown formatter type "${type}" was specified`);
            }
            return new FormatterClass(config).format(value, valuesDataSeries);
        });
    }
}
