import React from 'react';
import { _ } from '@splunk/ui-utils/i18n';
import SVG, {
    type SVGPropsOptionalViewBox,
} from '@splunk/react-icons/SVGEnterprise';

/* eslint-disable max-len */
export default function Fullscreen(
    props: SVGPropsOptionalViewBox
): ReturnType<typeof SVG> {
    return (
        <SVG
            screenReaderText={_('Fullscreen Exit')}
            viewBox="0 0 18 18"
            {...props}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M16.5024 5.66408L12.3333 5.66398L12.3333 1.49788C12.3333 1.03764 11.9602 0.664549 11.5 0.664551C11.0397 0.664553 10.6666 1.03765 10.6666 1.49789L10.6666 6.08065C10.6667 6.77099 11.2263 7.33063 11.9166 7.33064L16.5023 7.33075C16.9626 7.33076 17.3357 6.95766 17.3357 6.49743C17.3357 6.03719 16.9626 5.66409 16.5024 5.66408Z"
                fill="currentColor"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M1.4974 12.3356H5.66145L5.66636 16.4999C5.66636 16.9601 6.03946 17.3332 6.4997 17.3332C6.95993 17.3332 7.33303 16.9601 7.33303 16.4999L7.32812 11.919C7.32812 11.2286 6.76847 10.669 6.07812 10.669H1.4974C1.03716 10.669 0.664062 11.0421 0.664062 11.5023C0.664062 11.9625 1.03716 12.3356 1.4974 12.3356Z"
                fill="currentColor"
            />
        </SVG>
    );
}
