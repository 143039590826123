import React from 'react';
import { _ } from '@splunk/ui-utils/i18n';
import SVG, {
    type SVGPropsOptionalViewBox,
} from '@splunk/react-icons/SVGEnterprise';

export default function Markdown(
    props: SVGPropsOptionalViewBox
): ReturnType<typeof SVG> {
    return (
        <SVG viewBox="0 0 208 128" screenReaderText={_('Markdown')} {...props}>
            <rect
                width="198"
                height="118"
                x="5"
                y="5"
                ry="10"
                stroke="currentColor"
                strokeWidth="10"
                fill="none"
            />
            <path
                d="M30 98V30h20l20 25 20-25h20v68H90V59L70 84 50 59v39zm125 0l-30-33h20V30h20v35h20z"
                fill="currentColor"
            />
        </SVG>
    );
}
