/**
 * Compute absolute horizontal position (top/bottom) based on dir
 * @param {String} dir
 * @param {Number} offset
 * @returns {String}
 */
export const getVerticalPosition = (dir: string, offset: number): string => {
    if (dir[0] === 'n') {
        return `top: -${offset}px`;
    }
    if (dir[0] === 's') {
        return `bottom: -${offset}px`;
    }
    return `top: calc(50% - ${offset}px)`;
};

/**
 * Compute absolute vertical position (left/right) based on dir
 * @param {String} dir
 * @param {Number} offset
 * @returns {String}
 */
export const getHorizontalPosition = (dir: string, offset: number): string => {
    if (dir.slice(-1) === 'w') {
        return `left: -${offset}px`;
    }
    if (dir.slice(-1) === 'e') {
        return `right: -${offset}px`;
    }
    return `left: calc(50% - ${offset}px)`;
};
