import React from 'react';
import T from 'prop-types';
import Charting from '@splunk/react-visualizations/common/Charting';
import { isMultiSeriesData, toArrayForSeries } from '@splunk/react-visualizations/utils/seriesUtils';
import jsCharting from '@splunk/charting-bundle';
import omit from 'lodash/omit';
import isArray from 'lodash/isArray';
import isEmpty from 'lodash/isEmpty';
import { getSimpleXMLOptionsForProps } from '@splunk/visualizations-shared/simpleXMLOptions';
import { convertPropsToString } from '@splunk/visualizations-shared/propUtils';
import { withSanitizedProps } from '@splunk/visualizations-shared/SanitizeProps';
import {
    baseChartPropTypes,
    baseChartDefaultProps,
    xyChartPropTypes,
    xyChartDefaultProps,
    multiYChartProps,
    multiYChartDefaultProps,
    dataChartProps,
    dataChartDefaultProps,
} from '../../common/props';

const Area = ({ ...props }) => {
    const {
        width,
        height,
        style,
        themeKey,
        x,
        xFieldName,
        y,
        yFieldName,
        y2,
        y2FieldName,
        annotationX,
        annotationLabel,
        annotationColor,
        onPointClick,
        onLegendClick,
        onRangeSelect,
        colorPalette,
    } = props;

    const chartOptions = {
        chart: 'area',
        ...getSimpleXMLOptionsForProps(
            omit(props, [
                'width',
                'height',
                'style',
                'x',
                'y',
                'y2',
                'annotationX',
                'annotationLabel',
                'annotationColor',
                'xFieldName',
                'yFieldName',
                'y2FieldName',
                'colorPalette',
                'onPointClick',
                'onLegendClick',
            ])
        ),
    };

    // splunk charting expects certain options like 'seriesColors', 'fieldColors' etc to be a string
    const convertedChartOptions = convertPropsToString({
        vizProps: chartOptions,
        propNames: [
            'seriesColors',
            'data.fieldShowList',
            'data.fieldHideList',
            'legend.labels',
            'fieldColors',
        ],
    });

    // copy convertedChartOptions into chartOptions
    Object.assign(chartOptions, convertedChartOptions);

    const primaryData = {};

    primaryData.fields = [
        { name: xFieldName },
        // add all y field names
        ...toArrayForSeries(y, yFieldName).map(name => ({ name })),
    ];
    primaryData.columns = [x, ...toArrayForSeries(y, y)];
    if (y2 && isArray(y2) && y2FieldName) {
        chartOptions['axisY2.enabled'] = true;
        chartOptions['axisY2.fields'] = isMultiSeriesData(y2) ? y2FieldName.join(',') : y2FieldName;
        primaryData.fields = [
            ...primaryData.fields,
            ...toArrayForSeries(y2, y2FieldName).map(name => ({ name })),
        ];
        primaryData.columns = [...primaryData.columns, ...toArrayForSeries(y2, y2)];
    }

    const chartingAnnotations =
        annotationX && annotationX.length
            ? annotationX.map((timestamp, i) => ({
                  color: annotationColor && annotationColor[i],
                  label: annotationLabel && annotationLabel[i],
                  time: timestamp,
              }))
            : [];

    const chartData = !isEmpty(primaryData)
        ? jsCharting.extractChartReadyData({
              ...primaryData,
              annotations: chartingAnnotations,
          })
        : null;

    const onClick = ({ type, originalEvent, payload }) => {
        if (type === 'point.click') {
            onPointClick({ type, originalEvent, payload });
        }

        if (type === 'legend.click') {
            onLegendClick({ type, originalEvent, payload });
        }
    };

    return (
        <Charting
            width={width}
            height={height}
            style={style}
            themeKey={themeKey}
            chartData={chartData}
            options={chartOptions}
            onClick={onClick}
            onSelect={onRangeSelect}
            colorPalette={colorPalette}
        />
    );
};

Area.propTypes = {
    themeKey: T.string,
    ...baseChartPropTypes,
    ...xyChartPropTypes,
    ...multiYChartProps,
    ...dataChartProps,
    // area specific props
    annotationX: T.arrayOf(T.string),
    annotationLabel: T.arrayOf(T.string),
    annotationColor: T.arrayOf(T.string),
    xAxisMajorLabelRotation: T.oneOf([-90, -45, 0, 90, 45]),
    showMajorXGridLines: T.bool,
    splitSeries: T.bool,
    allowIndependentYRanges: T.bool,
    showDataLabels: T.oneOf(['none', 'all', 'minmax']),
    stackMode: T.oneOf(['default', 'stacked', 'stacked100']),
    nullValueMode: T.oneOf(['gaps', 'zero', 'connect']),
    legendMode: T.oneOf(['standard', 'seriesCompare']),
    areaFillOpacity: T.number,
    showLines: T.bool,
    xAxisTitleAlignment: T.oneOf(['low', 'middle', 'high']),
    yAxisTitleAlignment: T.oneOf(['low', 'middle', 'high']),
    y2AxisTitleAlignment: T.oneOf(['low', 'middle', 'high']),
    areaLineCap: T.oneOf(['round', 'square', 'butt']),
    areaLineWidth: T.number,
    lineWidth: T.number,
};

Area.defaultProps = {
    themeKey: 'prismaDark',
    ...baseChartDefaultProps,
    ...xyChartDefaultProps,
    ...multiYChartDefaultProps,
    ...dataChartDefaultProps,
    xAxisMajorLabelRotation: 0,
    showMajorXGridLines: true,
    splitSeries: false,
    allowIndependentYRanges: false,
    showDataLabels: 'none',
    stackMode: 'default',
    areaFillOpacity: 0.75,
    nullValueMode: 'gaps',
    showLines: true,
    legendMode: 'standard',
    xAxisMajorTickVisibility: 'hide', // TODO: Once all the charts are updated to new design, update these props in common props file
    yAxisMajorTickVisibility: 'hide',
    y2AxisMajorTickVisibility: 'hide',
    areaLineCap: 'round', // Following props are internal. Required to align line chart with new design
    areaLineWidth: 1.5,
    xAxisTitleAlignment: 'low',
    yAxisTitleAlignment: 'low',
    y2AxisTitleAlignment: 'low',
    lineWidth: 2,
};

export default withSanitizedProps(Area);
