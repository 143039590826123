import { useEffect, useRef } from 'react';
import { selectMode, useSelector } from '@splunk/dashboard-state';
import type { UseModeChangeCallbackProps } from './onChange.types';

export const useModeChangeCallback = ({
    onModeChange: onChange,
}: UseModeChangeCallbackProps) => {
    const mode = useSelector(selectMode);
    const firstRender = useRef(true);

    useEffect(() => {
        if (!onChange || firstRender.current) {
            firstRender.current = false;
            return;
        }

        onChange(mode);
    }, [mode, onChange]);
};
