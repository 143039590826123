import { parse as baseParse, mergeEncoding } from '@splunk/visualization-encoding-parsers/Base';
import isEmpty from 'lodash/isEmpty';
import { ErrorMsgs } from './errorMsgs';

export const dataContract = {
    requiredDataSources: [
        {
            name: 'primary',
            description: 'DataSource that powers the visualization',
        },
    ],
    initialRequestParams: {
        primary: { offset: 0, count: 10000 },
    },
    encoding: {
        trend: {
            isRequired: true,
            type: ['number', 'string'],
            default: ['primary[0]', 'primary[1]'],
        },
        fill: {
            isRequired: false,
            type: ['string'],
            default: {
                field: 'primary[0]',
                format: {
                    type: 'rangevalue',
                    ranges: [
                        {
                            from: 100,
                            value: '#cb3b43',
                        },
                        {
                            from: 70,
                            to: 100,
                            value: '#ff7152',
                        },
                        {
                            from: 50,
                            to: 70,
                            value: '#fc9850',
                        },
                        {
                            from: 30,
                            to: 50,
                            value: '#f4df7a',
                        },
                        {
                            from: 10,
                            to: 30,
                            value: '#4beba8',
                        },
                        {
                            to: 10,
                            value: '#5fbcff',
                        },
                    ],
                },
            },
        },
        majorColor: {
            isRequired: false,
            type: ['string'],
        },
        deltaColor: {
            isRequired: false,
            type: ['string'],
        },
    },
};

/**
 * Single Value parse function
 * @param {object} dataSources
 * @param {object} encoding
 * @return {object} props that dashboard/react visualizations can be used.
 * @public
 */
export const parse = (dataSources, userDefinedEncoding) => {
    if (isEmpty(dataSources)) {
        throw new Error(ErrorMsgs.NO_DATA_SOURCES);
    }

    const encoding = mergeEncoding(dataContract.encoding, userDefinedEncoding);
    const props = baseParse(dataSources, encoding);
    const parsedProps = {};
    try {
        Object.keys(props).forEach(encodingKey => {
            const encodingValue = props[encodingKey];
            const valueIndex = encodingValue.length - 1;

            if (encodingKey === 'trend') {
                parsedProps.value = encodingValue[valueIndex];
                parsedProps.deltaValue =
                    encodingValue.length === 1
                        ? null
                        : encodingValue[valueIndex] - encodingValue[valueIndex - 1];
                parsedProps.graphData = encodingValue;
            } else {
                parsedProps[encodingKey] = encodingValue[valueIndex];
            }
        });
    } catch (err) {
        throw new Error(ErrorMsgs.ERROR_PARSE);
    }

    return {
        value: parsedProps.value,
        deltaValue: parsedProps.deltaValue,
        deltaColor: parsedProps.deltaColor,
        graphData: parsedProps.graphData,
        backgroundColor: parsedProps.fill,
        majorColor: parsedProps.majorColor,
    };
};
