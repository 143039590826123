export default {
    ref: {
        type: 'string',
        description:
            'The name of the saved search to retrieve the results from.',
    },
    app: {
        type: 'string',
        description: 'The name of the app that the saved search belongs to.',
    },
    refresh: {
        type: ['number', 'string'],
        description:
            'Specify the refresh interval in seconds or with a time expression. Ignored if saved search is scheduled.',
    },
    refreshType: {
        type: 'string',
        enum: ['delay', 'interval'],
        default: 'delay',
        description:
            'Indicate the starting time for counting down to a refresh. Use delay to start counting when the search is done. Use interval to count down when the search is dispatched. Ignored if saved search is scheduled.',
    },
};
