import * as React from 'react';
import styled from 'styled-components';
import type { Map } from 'maplibre-gl';
import { sum } from 'lodash';
import { isColor } from '@splunk/visualizations-shared/colorUtils';
import {
    bakeBubbleData,
    BubbleLayerType,
    DEFAULT_SERIES_COLORS,
    DEFAULT_RESULT_LIMIT,
    DEFAULT_CLUSTER_COLOR,
    getStaticColors,
    matchBubbleSize,
} from './MapUtils';
import MarkerWrapper from './MarkerWrapper';
import type { TooltipProps } from './Tooltip';

export const GEO_BOUND_PREFIX = '_geo_bounds_';
export const GEO_LAT_AND_LONG_FIELD = ['_geo_long_field', '_geo_lat_field'];
export interface PieSegmentProps {
    start: number;
    end: number;
    radius: number;
    color: string;
    themeColor: string;
    segmentMetadata?: { name: string; value: number; color: string };
    handleBubbleClick?: (e: Event, name: string, count: number) => void;
}

export const PieSegment = React.memo(
    ({
        start,
        end,
        radius,
        color,
        themeColor,
        segmentMetadata,
        handleBubbleClick,
    }: PieSegmentProps): JSX.Element => {
        const { name, value } = segmentMetadata ?? {};
        const handleSegmentClick = React.useCallback(
            e => {
                handleBubbleClick(e, name, value);
            },
            [name, value, handleBubbleClick]
        );

        if (end - start === 1) {
            return (
                <circle
                    onClick={handleSegmentClick}
                    cx={radius}
                    cy={radius}
                    r={radius}
                    fill={color}
                    fillOpacity={0.5}
                    strokeWidth={1.5}
                    stroke={color}
                />
            );
        }
        const a0 = 2 * Math.PI * (start - 0.25);
        const a1 = 2 * Math.PI * (end - 0.25);
        const x0 = Math.cos(a0);
        const y0 = Math.sin(a0);
        const x1 = Math.cos(a1);
        const y1 = Math.sin(a1);
        const arc = end - start > 0.5 ? 1 : 0;

        return (
            <path
                onClick={handleSegmentClick}
                d={`M ${radius} ${radius} L ${radius + radius * x0} ${
                    radius + radius * y0
                } A ${radius} ${radius} 0 ${arc} 1 ${radius + radius * x1} ${
                    radius + radius * y1
                } L ${radius} ${radius} A 0 0 0 ${arc} 0 ${radius} ${radius}`}
                fill={color}
                fillOpacity={0.8}
                stroke={themeColor}
                strokeWidth={2}
                strokeOpacity={0.3}
            />
        );
    }
);

PieSegment.displayName = 'MapBubblePieSegment';

const StyledMarkerWrapper = styled(MarkerWrapper)`
    cursor: pointer;
`;

export interface BubbleProps {
    originalValues: number[];
    valueRange: number[];
    colors: string[];
    themeColor: string;
    isPie: boolean;
    latitude: number;
    longitude: number;
    bubbleSize: Required<TooltipProps['additionalFields'][number] & { value: number }>[];
    additionalFields?: TooltipProps['additionalFields'];
    map: React.MutableRefObject<Map>;
    handleMapClick?: any;
}

export const Bubble = React.memo(
    ({
        originalValues,
        valueRange,
        colors,
        themeColor,
        isPie,
        latitude,
        longitude,
        bubbleSize,
        additionalFields = [],
        map,
        handleMapClick,
    }: BubbleProps): JSX.Element => {
        const onBubbleClick = React.useCallback(
            (e, name, value) => {
                handleMapClick(e, name, value);
                e.stopPropagation();
            },
            [bubbleSize]
        );

        const bubble = React.useMemo(() => {
            const allZeros = originalValues.every(v => v === 0);
            if (allZeros || !originalValues.length) {
                return null;
            }
            // sanitize values
            const values = originalValues.map(v => (v > 0 ? v : 0));

            // compute offsets for pie segments
            const offsets = [];
            let total = 0;
            values.forEach(count => {
                offsets.push(total);
                total += count;
            });

            const radius = matchBubbleSize(valueRange, total);
            const width = radius * 2;
            return (
                <svg width={width} height={width} viewBox={`-3 -3 ${width + 6} ${width + 6}`}>
                    {isPie ? (
                        <circle
                            cx={radius}
                            cy={radius}
                            r={radius}
                            fill="none"
                            strokeWidth={2}
                            stroke={themeColor}
                            strokeOpacity={0.3}
                        />
                    ) : null}
                    {values.map((v, i) => (
                        <PieSegment
                            key={String(i)}
                            start={offsets[i] / total}
                            end={(offsets[i] + v) / total}
                            radius={radius}
                            color={colors[i]}
                            themeColor={themeColor}
                            segmentMetadata={bubbleSize[i]}
                            handleBubbleClick={onBubbleClick}
                        />
                    ))}
                </svg>
            );
        }, [colors, isPie, originalValues, themeColor, valueRange]);

        if (!bubble) {
            return null;
        }

        return (
            <StyledMarkerWrapper
                longitude={longitude}
                latitude={latitude}
                additionalFields={[...bubbleSize, ...additionalFields]}
                map={map}
                data-test-type="bubble"
            >
                {bubble}
            </StyledMarkerWrapper>
        );
    }
);
Bubble.displayName = 'MapBubble';

export interface BubbleLayerProps {
    bubbleLayer: BubbleLayerType;
    themeColorScheme: string;
    zoom: number;
    map: React.MutableRefObject<Map>;
    onMapClick?: any;
}

const BubbleLayer = (props: BubbleLayerProps): JSX.Element => {
    const { bubbleLayer, themeColorScheme, map, zoom, onMapClick } = props;
    bubbleLayer.seriesColors = bubbleLayer.seriesColors || DEFAULT_SERIES_COLORS;
    bubbleLayer.bubbleSize = bubbleLayer.bubbleSize || [];
    const bakedData = React.useMemo(() => bakeBubbleData(bubbleLayer), [bubbleLayer]);
    const staticColors = React.useMemo(
        () => getStaticColors(bubbleLayer.bubbleSize.length, bubbleLayer.seriesColors),
        [bubbleLayer.bubbleSize.length, bubbleLayer.seriesColors]
    );
    const { data = [], dataRange = [], resultLimit, bubbleSizeFields, isPie } = bakedData ?? {};
    const { additionalTooltipFields = [] } = bubbleLayer;
    const roundedZoom = Math.round(zoom);
    const themeColor = themeColorScheme === 'light' ? '#ffffff' : '#1a1c20';
    const dynamicColors = bubbleLayer.dataColors;

    // filter data for the current zoom level and sort base on values
    const dataToRender = React.useMemo(
        () =>
            data
                .reduce((acc, datum, i) => {
                    if (datum.zoom === roundedZoom) {
                        acc.push({ data: datum, idx: i });
                    }
                    return acc;
                }, [])
                .slice(0, resultLimit || DEFAULT_RESULT_LIMIT)
                .sort((a, b) => sum(b.data.values) - sum(a.data.values)),
        [data, resultLimit, roundedZoom]
    );

    const handleMapClick = React.useCallback(
        (e, name, value, i) => {
            // retrieve metadata from the appropriate datum, indexed by i
            const metadata = dataToRender[i]?.data?.metadata ?? {};
            const payload = Object.keys(metadata).reduce(
                (acc, curr) => {
                    const key = `row.${curr}.value`;
                    const val = String(metadata[curr]);
                    return { ...acc, [key]: val };
                },
                { name, value: String(value) }
            );

            onMapClick({ e, payload });
        },
        [dataToRender]
    );

    return (
        <>
            {dataToRender.map((datum, i) => {
                const colors = dynamicColors
                    ? [
                          dataToRender.map(obj =>
                              isColor(dynamicColors[obj.idx]) ? dynamicColors[obj.idx] : DEFAULT_CLUSTER_COLOR
                          )[i],
                      ]
                    : staticColors;

                const [longitude, latitude] = datum.data.coordinates;
                const bubbleSize = datum.data.values.map((value, index) => ({
                    name: bubbleSizeFields[index],
                    value,
                    color: colors[index],
                }));

                const additionalFields = bubbleLayer.metadata
                    ? additionalTooltipFields.map(field => ({
                          name: field,
                          value: datum.data.metadata[field],
                      }))
                    : [];

                return (
                    <Bubble
                        key={String(i)}
                        originalValues={datum.data.values}
                        valueRange={dataRange}
                        colors={colors}
                        themeColor={themeColor}
                        isPie={isPie}
                        longitude={longitude}
                        latitude={latitude}
                        bubbleSize={bubbleSize}
                        additionalFields={additionalFields}
                        map={map}
                        handleMapClick={(e, name, value) => handleMapClick(e, name, value, i)}
                    />
                );
            })}
        </>
    );
};

export default BubbleLayer;
