import React from 'react';
import T from 'prop-types';
import { _ } from '@splunk/ui-utils/i18n';
import SVG, {
    type SVGPropsOptionalViewBox,
} from '@splunk/react-icons/SVGEnterprise';

/* eslint-disable max-len */
export default function AddShapeDropdown({
    screenReaderText,
    ...props
}: SVGPropsOptionalViewBox): ReturnType<typeof SVG> {
    return (
        <SVG viewBox="0 0 24 24" screenReaderText={screenReaderText} {...props}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M18.3456 3.7695C17.799 2.64143 16.1919 2.64166 15.6456 3.7699L13.1596 8.90439C12.6773 9.90051 13.4029 11.0581 14.5097 11.0581L19.4836 11.0581C20.5906 11.0581 21.3162 9.90016 20.8335 8.90401L18.3456 3.7695ZM15.3073 9.05807L16.9959 5.57058L18.6857 9.05808L15.3073 9.05807ZM4.5 3C3.67157 3 3 3.67157 3 4.5V9.5C3 10.3284 3.67157 11 4.5 11H9.5C10.3284 11 11 10.3284 11 9.5V4.5C11 3.67157 10.3284 3 9.5 3H4.5ZM5 9V5H9V9H5ZM7.021 15C5.91643 15 5.021 15.8954 5.021 17C5.021 18.1046 5.91643 19 7.021 19C8.12557 19 9.021 18.1046 9.021 17C9.021 15.8954 8.12557 15 7.021 15ZM3.021 17C3.021 14.7909 4.81186 13 7.021 13C9.23013 13 11.021 14.7909 11.021 17C11.021 19.2091 9.23013 21 7.021 21C4.81186 21 3.021 19.2091 3.021 17ZM13.2851 14.7032C12.8946 14.3127 12.8946 13.6795 13.2851 13.289C13.6756 12.8985 14.3088 12.8985 14.6993 13.289L20.7003 19.2899C21.0908 19.6805 21.0908 20.3136 20.7003 20.7042C20.3097 21.0947 19.6766 21.0947 19.2861 20.7042L13.2851 14.7032Z"
                fill="currentColor"
            />
        </SVG>
    );
}

AddShapeDropdown.propTypes = {
    screenReaderText: T.string,
};

AddShapeDropdown.defaultProps = {
    screenReaderText: _('Add shape'),
};
