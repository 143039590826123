import type {
    DataSourceEventPayload,
    ExtendedJobStatus,
    DataSourceSmartSourceTokens,
    HandledSmartSourceEvents,
} from '@splunk/dashboard-types';

import SmartSourceHandler from './SmartSourceHandler';

const getStatusMessage = (payload: DataSourceEventPayload): string[] =>
    payload.meta?.statusMessage ? [payload.meta.statusMessage] : [];

const getFirstResults = (
    payload: DataSourceEventPayload
): Partial<DataSourceSmartSourceTokens> => {
    const partial: Partial<DataSourceSmartSourceTokens> = {};
    if (payload.data) {
        const jobFields: string[] = [];

        const { fields, columns } = payload.data;
        fields.forEach(({ name: fieldName }, index) => {
            const fieldProp: keyof DataSourceSmartSourceTokens = `result.${fieldName}`;
            [partial[fieldProp]] = columns[index];
            jobFields.push(fieldName);
        });

        partial['job.fields'] = jobFields;
    }

    return partial;
};

// These flags will be reset on every action
const allJobFlags: ExtendedJobStatus[] = [
    'done',
    'failed',
    'inProgress',
    'queued',
];

const getCurrentTimeAsISOString = () => new Date(Date.now()).toISOString();

/**
 * For Data Source events we want to reset the flags on each event so we
 * don't leave a lingering 'job.inProgress' flag set even though the job
 * is finished.
 *
 * @example
 * {
 *   'datasource.done': (payload) => ({
 *     ...commonJobProperties(payload),
 *     'job.done': true
 *   })
 * };
 *
 * @param payload {DataSourceEventPayload}
 * @returns Partial<DataSourceSmartSourceTokens>
 */
export const commonJobProperties = (
    status: ExtendedJobStatus,
    payload: DataSourceEventPayload
): Partial<DataSourceSmartSourceTokens> => {
    const { meta = {} } = payload;
    const { sid, totalCount, lastUpdated, percentComplete, isRealTimeSearch } =
        meta;

    const resultCount = totalCount || 0;
    const jobProps: Partial<DataSourceSmartSourceTokens> = {
        'job.status': status,
        'job.messages': getStatusMessage(payload),
        'job.hasResults': resultCount > 0,
        'job.resultCount': resultCount,
        'job.percentComplete': percentComplete,
        'job.isRealTimeSearch': !!isRealTimeSearch,
        'job.lastUpdated': lastUpdated || getCurrentTimeAsISOString(),
    };

    // Don't overwrite the sid, we don't always get it
    if (sid) {
        jobProps['job.sid'] = sid;
    }

    // Reset all job flags
    allJobFlags.forEach((flag) => {
        jobProps[`job.${flag}`] = false;
    });

    return {
        ...jobProps,
    };
};

export const dsSmartSourceEvents: HandledSmartSourceEvents<
    DataSourceEventPayload,
    DataSourceSmartSourceTokens
> = {
    'datasource.done': (payload) => ({
        ...commonJobProperties('done', payload),
        ...getFirstResults(payload),
        'job.done': true,
    }),
    'datasource.error': (payload) => ({
        ...commonJobProperties('failed', payload),
        'job.failed': true,
    }),
    'datasource.progress': (payload) => ({
        ...commonJobProperties('inProgress', payload),
        ...getFirstResults(payload),
        'job.inProgress': true,
    }),
    'datasource.setup': (payload) => ({
        ...commonJobProperties('queued', payload),
        'job.queued': true,
        'job.startTime': getCurrentTimeAsISOString(),
    }),
};

export const getSmartSourceDSEventHandler = () => {
    return new SmartSourceHandler('ds', dsSmartSourceEvents);
};

export default dsSmartSourceEvents;
