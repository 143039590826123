// Generated automatically by nearley, version 2.19.5
// http://github.com/Hardmath123/nearley
(function () {
    function id(x) {
        return x[0];
    }
    var grammar = {
        Lexer: undefined,
        ParserRules: [
            { name: '_$ebnf$1', symbols: [] },
            {
                name: '_$ebnf$1',
                symbols: ['_$ebnf$1', 'wschar'],
                postprocess: function arrpush(d) {
                    return d[0].concat([d[1]]);
                },
            },
            {
                name: '_',
                symbols: ['_$ebnf$1'],
                postprocess: function (d) {
                    return null;
                },
            },
            { name: '__$ebnf$1', symbols: ['wschar'] },
            {
                name: '__$ebnf$1',
                symbols: ['__$ebnf$1', 'wschar'],
                postprocess: function arrpush(d) {
                    return d[0].concat([d[1]]);
                },
            },
            {
                name: '__',
                symbols: ['__$ebnf$1'],
                postprocess: function (d) {
                    return null;
                },
            },
            { name: 'wschar', symbols: [/[ \t\n\v\f]/], postprocess: id },
            { name: 'dqstring$ebnf$1', symbols: [] },
            {
                name: 'dqstring$ebnf$1',
                symbols: ['dqstring$ebnf$1', 'dstrchar'],
                postprocess: function arrpush(d) {
                    return d[0].concat([d[1]]);
                },
            },
            {
                name: 'dqstring',
                symbols: [{ literal: '"' }, 'dqstring$ebnf$1', { literal: '"' }],
                postprocess: function (d) {
                    return d[1].join('');
                },
            },
            { name: 'sqstring$ebnf$1', symbols: [] },
            {
                name: 'sqstring$ebnf$1',
                symbols: ['sqstring$ebnf$1', 'sstrchar'],
                postprocess: function arrpush(d) {
                    return d[0].concat([d[1]]);
                },
            },
            {
                name: 'sqstring',
                symbols: [{ literal: "'" }, 'sqstring$ebnf$1', { literal: "'" }],
                postprocess: function (d) {
                    return d[1].join('');
                },
            },
            { name: 'btstring$ebnf$1', symbols: [] },
            {
                name: 'btstring$ebnf$1',
                symbols: ['btstring$ebnf$1', /[^`]/],
                postprocess: function arrpush(d) {
                    return d[0].concat([d[1]]);
                },
            },
            {
                name: 'btstring',
                symbols: [{ literal: '`' }, 'btstring$ebnf$1', { literal: '`' }],
                postprocess: function (d) {
                    return d[1].join('');
                },
            },
            { name: 'dstrchar', symbols: [/[^\\"\n]/], postprocess: id },
            {
                name: 'dstrchar',
                symbols: [{ literal: '\\' }, 'strescape'],
                postprocess: function (d) {
                    return JSON.parse('"' + d.join('') + '"');
                },
            },
            { name: 'sstrchar', symbols: [/[^\\'\n]/], postprocess: id },
            {
                name: 'sstrchar',
                symbols: [{ literal: '\\' }, 'strescape'],
                postprocess: function (d) {
                    return JSON.parse('"' + d.join('') + '"');
                },
            },
            {
                name: 'sstrchar$string$1',
                symbols: [{ literal: '\\' }, { literal: "'" }],
                postprocess: function joiner(d) {
                    return d.join('');
                },
            },
            {
                name: 'sstrchar',
                symbols: ['sstrchar$string$1'],
                postprocess: function (d) {
                    return "'";
                },
            },
            { name: 'strescape', symbols: [/["\\\/bfnrt]/], postprocess: id },
            {
                name: 'strescape',
                symbols: [{ literal: 'u' }, /[a-fA-F0-9]/, /[a-fA-F0-9]/, /[a-fA-F0-9]/, /[a-fA-F0-9]/],
                postprocess: function (d) {
                    return d.join('');
                },
            },
            { name: 'unsigned_int$ebnf$1', symbols: [/[0-9]/] },
            {
                name: 'unsigned_int$ebnf$1',
                symbols: ['unsigned_int$ebnf$1', /[0-9]/],
                postprocess: function arrpush(d) {
                    return d[0].concat([d[1]]);
                },
            },
            {
                name: 'unsigned_int',
                symbols: ['unsigned_int$ebnf$1'],
                postprocess: function (d) {
                    return parseInt(d[0].join(''));
                },
            },
            { name: 'int$ebnf$1$subexpression$1', symbols: [{ literal: '-' }] },
            { name: 'int$ebnf$1$subexpression$1', symbols: [{ literal: '+' }] },
            { name: 'int$ebnf$1', symbols: ['int$ebnf$1$subexpression$1'], postprocess: id },
            {
                name: 'int$ebnf$1',
                symbols: [],
                postprocess: function (d) {
                    return null;
                },
            },
            { name: 'int$ebnf$2', symbols: [/[0-9]/] },
            {
                name: 'int$ebnf$2',
                symbols: ['int$ebnf$2', /[0-9]/],
                postprocess: function arrpush(d) {
                    return d[0].concat([d[1]]);
                },
            },
            {
                name: 'int',
                symbols: ['int$ebnf$1', 'int$ebnf$2'],
                postprocess: function (d) {
                    if (d[0]) {
                        return parseInt(d[0][0] + d[1].join(''));
                    } else {
                        return parseInt(d[1].join(''));
                    }
                },
            },
            { name: 'unsigned_decimal$ebnf$1', symbols: [/[0-9]/] },
            {
                name: 'unsigned_decimal$ebnf$1',
                symbols: ['unsigned_decimal$ebnf$1', /[0-9]/],
                postprocess: function arrpush(d) {
                    return d[0].concat([d[1]]);
                },
            },
            { name: 'unsigned_decimal$ebnf$2$subexpression$1$ebnf$1', symbols: [/[0-9]/] },
            {
                name: 'unsigned_decimal$ebnf$2$subexpression$1$ebnf$1',
                symbols: ['unsigned_decimal$ebnf$2$subexpression$1$ebnf$1', /[0-9]/],
                postprocess: function arrpush(d) {
                    return d[0].concat([d[1]]);
                },
            },
            {
                name: 'unsigned_decimal$ebnf$2$subexpression$1',
                symbols: [{ literal: '.' }, 'unsigned_decimal$ebnf$2$subexpression$1$ebnf$1'],
            },
            {
                name: 'unsigned_decimal$ebnf$2',
                symbols: ['unsigned_decimal$ebnf$2$subexpression$1'],
                postprocess: id,
            },
            {
                name: 'unsigned_decimal$ebnf$2',
                symbols: [],
                postprocess: function (d) {
                    return null;
                },
            },
            {
                name: 'unsigned_decimal',
                symbols: ['unsigned_decimal$ebnf$1', 'unsigned_decimal$ebnf$2'],
                postprocess: function (d) {
                    return parseFloat(d[0].join('') + (d[1] ? '.' + d[1][1].join('') : ''));
                },
            },
            { name: 'decimal$ebnf$1', symbols: [{ literal: '-' }], postprocess: id },
            {
                name: 'decimal$ebnf$1',
                symbols: [],
                postprocess: function (d) {
                    return null;
                },
            },
            { name: 'decimal$ebnf$2', symbols: [/[0-9]/] },
            {
                name: 'decimal$ebnf$2',
                symbols: ['decimal$ebnf$2', /[0-9]/],
                postprocess: function arrpush(d) {
                    return d[0].concat([d[1]]);
                },
            },
            { name: 'decimal$ebnf$3$subexpression$1$ebnf$1', symbols: [/[0-9]/] },
            {
                name: 'decimal$ebnf$3$subexpression$1$ebnf$1',
                symbols: ['decimal$ebnf$3$subexpression$1$ebnf$1', /[0-9]/],
                postprocess: function arrpush(d) {
                    return d[0].concat([d[1]]);
                },
            },
            {
                name: 'decimal$ebnf$3$subexpression$1',
                symbols: [{ literal: '.' }, 'decimal$ebnf$3$subexpression$1$ebnf$1'],
            },
            { name: 'decimal$ebnf$3', symbols: ['decimal$ebnf$3$subexpression$1'], postprocess: id },
            {
                name: 'decimal$ebnf$3',
                symbols: [],
                postprocess: function (d) {
                    return null;
                },
            },
            {
                name: 'decimal',
                symbols: ['decimal$ebnf$1', 'decimal$ebnf$2', 'decimal$ebnf$3'],
                postprocess: function (d) {
                    return parseFloat((d[0] || '') + d[1].join('') + (d[2] ? '.' + d[2][1].join('') : ''));
                },
            },
            {
                name: 'percentage',
                symbols: ['decimal', { literal: '%' }],
                postprocess: function (d) {
                    return d[0] / 100;
                },
            },
            { name: 'jsonfloat$ebnf$1', symbols: [{ literal: '-' }], postprocess: id },
            {
                name: 'jsonfloat$ebnf$1',
                symbols: [],
                postprocess: function (d) {
                    return null;
                },
            },
            { name: 'jsonfloat$ebnf$2', symbols: [/[0-9]/] },
            {
                name: 'jsonfloat$ebnf$2',
                symbols: ['jsonfloat$ebnf$2', /[0-9]/],
                postprocess: function arrpush(d) {
                    return d[0].concat([d[1]]);
                },
            },
            { name: 'jsonfloat$ebnf$3$subexpression$1$ebnf$1', symbols: [/[0-9]/] },
            {
                name: 'jsonfloat$ebnf$3$subexpression$1$ebnf$1',
                symbols: ['jsonfloat$ebnf$3$subexpression$1$ebnf$1', /[0-9]/],
                postprocess: function arrpush(d) {
                    return d[0].concat([d[1]]);
                },
            },
            {
                name: 'jsonfloat$ebnf$3$subexpression$1',
                symbols: [{ literal: '.' }, 'jsonfloat$ebnf$3$subexpression$1$ebnf$1'],
            },
            { name: 'jsonfloat$ebnf$3', symbols: ['jsonfloat$ebnf$3$subexpression$1'], postprocess: id },
            {
                name: 'jsonfloat$ebnf$3',
                symbols: [],
                postprocess: function (d) {
                    return null;
                },
            },
            { name: 'jsonfloat$ebnf$4$subexpression$1$ebnf$1', symbols: [/[+-]/], postprocess: id },
            {
                name: 'jsonfloat$ebnf$4$subexpression$1$ebnf$1',
                symbols: [],
                postprocess: function (d) {
                    return null;
                },
            },
            { name: 'jsonfloat$ebnf$4$subexpression$1$ebnf$2', symbols: [/[0-9]/] },
            {
                name: 'jsonfloat$ebnf$4$subexpression$1$ebnf$2',
                symbols: ['jsonfloat$ebnf$4$subexpression$1$ebnf$2', /[0-9]/],
                postprocess: function arrpush(d) {
                    return d[0].concat([d[1]]);
                },
            },
            {
                name: 'jsonfloat$ebnf$4$subexpression$1',
                symbols: [
                    /[eE]/,
                    'jsonfloat$ebnf$4$subexpression$1$ebnf$1',
                    'jsonfloat$ebnf$4$subexpression$1$ebnf$2',
                ],
            },
            { name: 'jsonfloat$ebnf$4', symbols: ['jsonfloat$ebnf$4$subexpression$1'], postprocess: id },
            {
                name: 'jsonfloat$ebnf$4',
                symbols: [],
                postprocess: function (d) {
                    return null;
                },
            },
            {
                name: 'jsonfloat',
                symbols: ['jsonfloat$ebnf$1', 'jsonfloat$ebnf$2', 'jsonfloat$ebnf$3', 'jsonfloat$ebnf$4'],
                postprocess: function (d) {
                    return parseFloat(
                        (d[0] || '') +
                            d[1].join('') +
                            (d[2] ? '.' + d[2][1].join('') : '') +
                            (d[3] ? 'e' + (d[3][1] || '+') + d[3][2].join('') : '')
                    );
                },
            },
            { name: 'Pipeline$ebnf$1', symbols: [] },
            { name: 'Pipeline$ebnf$1$subexpression$1', symbols: [{ literal: '|' }, 'Expr'] },
            {
                name: 'Pipeline$ebnf$1',
                symbols: ['Pipeline$ebnf$1', 'Pipeline$ebnf$1$subexpression$1'],
                postprocess: function arrpush(d) {
                    return d[0].concat([d[1]]);
                },
            },
            {
                name: 'Pipeline',
                symbols: ['Expr', 'Pipeline$ebnf$1'],
                postprocess: d => {
                    return [d[0], ...d[1].map(e => e[1])];
                },
            },
            { name: 'Expr', symbols: ['_', 'Identifier', '_'], postprocess: d => d[1] },
            { name: 'Expr', symbols: ['_', 'Method', '_'], postprocess: d => d[1] },
            { name: 'Expr', symbols: ['_', 'Literal', '_'], postprocess: d => d[1] },
            { name: 'Method$ebnf$1', symbols: ['Arg'], postprocess: id },
            {
                name: 'Method$ebnf$1',
                symbols: [],
                postprocess: function (d) {
                    return null;
                },
            },
            { name: 'Method$ebnf$2', symbols: [] },
            { name: 'Method$ebnf$2$subexpression$1', symbols: ['_', { literal: ',' }, '_', 'Arg'] },
            {
                name: 'Method$ebnf$2',
                symbols: ['Method$ebnf$2', 'Method$ebnf$2$subexpression$1'],
                postprocess: function arrpush(d) {
                    return d[0].concat([d[1]]);
                },
            },
            {
                name: 'Method',
                symbols: [
                    'Identifier',
                    { literal: '(' },
                    '_',
                    'Method$ebnf$1',
                    'Method$ebnf$2',
                    '_',
                    { literal: ')' },
                ],
                postprocess: d => {
                    let arg0 = d[3];
                    let otherArgs = d[4];
                    let args = [];
                    arg0 && args.push(arg0);
                    if (otherArgs) {
                        args = args.concat(otherArgs.map(e => e[3]));
                    }
                    let r = { type: 'method', name: d[0].v, args };
                    return r;
                },
            },
            { name: 'Arg', symbols: ['Identifier'], postprocess: d => d[0] },
            { name: 'Arg', symbols: ['Literal'], postprocess: d => d[0] },
            { name: 'Identifier$ebnf$1', symbols: [] },
            { name: 'Identifier$ebnf$1$subexpression$1', symbols: ['Letter'] },
            { name: 'Identifier$ebnf$1$subexpression$1', symbols: ['Digit'] },
            {
                name: 'Identifier$ebnf$1',
                symbols: ['Identifier$ebnf$1', 'Identifier$ebnf$1$subexpression$1'],
                postprocess: function arrpush(d) {
                    return d[0].concat([d[1]]);
                },
            },
            {
                name: 'Identifier',
                symbols: ['Letter', 'Identifier$ebnf$1'],
                postprocess: d => {
                    return { type: 'identifier', v: d[0] + d[1].join('') };
                },
            },
            { name: 'Identifier$ebnf$2', symbols: [] },
            { name: 'Identifier$ebnf$2$subexpression$1', symbols: ['Letter'] },
            { name: 'Identifier$ebnf$2$subexpression$1', symbols: ['Digit'] },
            {
                name: 'Identifier$ebnf$2',
                symbols: ['Identifier$ebnf$2', 'Identifier$ebnf$2$subexpression$1'],
                postprocess: function arrpush(d) {
                    return d[0].concat([d[1]]);
                },
            },
            { name: 'Identifier$ebnf$3$subexpression$1$ebnf$1', symbols: [] },
            { name: 'Identifier$ebnf$3$subexpression$1$ebnf$1$subexpression$1', symbols: ['Letter'] },
            { name: 'Identifier$ebnf$3$subexpression$1$ebnf$1$subexpression$1', symbols: ['Digit'] },
            {
                name: 'Identifier$ebnf$3$subexpression$1$ebnf$1',
                symbols: [
                    'Identifier$ebnf$3$subexpression$1$ebnf$1',
                    'Identifier$ebnf$3$subexpression$1$ebnf$1$subexpression$1',
                ],
                postprocess: function arrpush(d) {
                    return d[0].concat([d[1]]);
                },
            },
            {
                name: 'Identifier$ebnf$3$subexpression$1',
                symbols: ['Dot', 'Letter', 'Identifier$ebnf$3$subexpression$1$ebnf$1'],
            },
            { name: 'Identifier$ebnf$3', symbols: ['Identifier$ebnf$3$subexpression$1'] },
            { name: 'Identifier$ebnf$3$subexpression$2$ebnf$1', symbols: [] },
            { name: 'Identifier$ebnf$3$subexpression$2$ebnf$1$subexpression$1', symbols: ['Letter'] },
            { name: 'Identifier$ebnf$3$subexpression$2$ebnf$1$subexpression$1', symbols: ['Digit'] },
            {
                name: 'Identifier$ebnf$3$subexpression$2$ebnf$1',
                symbols: [
                    'Identifier$ebnf$3$subexpression$2$ebnf$1',
                    'Identifier$ebnf$3$subexpression$2$ebnf$1$subexpression$1',
                ],
                postprocess: function arrpush(d) {
                    return d[0].concat([d[1]]);
                },
            },
            {
                name: 'Identifier$ebnf$3$subexpression$2',
                symbols: ['Dot', 'Letter', 'Identifier$ebnf$3$subexpression$2$ebnf$1'],
            },
            {
                name: 'Identifier$ebnf$3',
                symbols: ['Identifier$ebnf$3', 'Identifier$ebnf$3$subexpression$2'],
                postprocess: function arrpush(d) {
                    return d[0].concat([d[1]]);
                },
            },
            {
                name: 'Identifier',
                symbols: ['Letter', 'Identifier$ebnf$2', 'Identifier$ebnf$3'],
                postprocess: d => {
                    function flat(input, depth = 1, stack = []) {
                        for (let item of input) {
                            if (item instanceof Array && depth > 0) {
                                flat(item, depth - 1, stack);
                            } else {
                                stack.push(item);
                            }
                        }
                        return stack;
                    }
                    const r = flat(d, Infinity);
                    return { type: 'identifier', v: r.join('') };
                },
            },
            {
                name: 'Literal',
                symbols: ['jsonfloat'],
                postprocess: d => {
                    return { type: 'number', v: d[0] };
                },
            },
            {
                name: 'Literal',
                symbols: ['dqstring'],
                postprocess: d => {
                    return { type: 'string', v: d[0] };
                },
            },
            {
                name: 'Literal',
                symbols: ['sqstring'],
                postprocess: d => {
                    return { type: 'string', v: d[0] };
                },
            },
            {
                name: 'Literal',
                symbols: ['btstring'],
                postprocess: d => {
                    return { type: 'string', v: d[0] };
                },
            },
            { name: 'Letter', symbols: [/[a-zA-Z_]/], postprocess: d => d[0] },
            { name: 'Digit', symbols: [/[0-9]/], postprocess: d => d[0] },
            { name: 'Dot', symbols: [/[\.]/], postprocess: d => d[0] },
        ],
        ParserStart: 'Pipeline',
    };
    if (typeof module !== 'undefined' && typeof module.exports !== 'undefined') {
        module.exports = grammar;
    } else {
        window.grammar = grammar;
    }
})();
