import * as React from 'react';
import type { Map } from 'maplibre-gl';
import Message from '@splunk/visualizations-shared/Message';
import GeoRegistry from '@splunk/visualization-context/GeoRegistry';
import GeoJsonProvider from '@splunk/visualization-context/GeoJsonProvider';
import { ChoroplethLayerType, getScaledOpacities, formatMetadata } from './MapUtils';
import { Choropleth } from './ChoroplethLayer';

interface ChoroplethLayerProps {
    width: number | string;
    height: number | string;
    choroplethLayer: ChoroplethLayerType;
    map: React.MutableRefObject<Map>;
    isThemeChanged: boolean;
    setIsThemeChanged: (isThemeChanged: boolean) => void;
    onMapClick?: ({ e, payload }) => void;
}

const LocalChoroplethLayer = (props: ChoroplethLayerProps): JSX.Element => {
    const { width, height, map, choroplethLayer, isThemeChanged, setIsThemeChanged, onMapClick } = props;
    const cfgOpacity = Math.min(Math.max(choroplethLayer?.choroplethOpacity, 0), 1);
    const [geoJson, setGeoJson] = React.useState({ features: [{ properties: { name: '' }, geometry: [] }] });
    const [validGeoJson, setValidGeoJson] = React.useState(true);
    const [geoJsonError, setGeoJsonError] = React.useState({ level: 'info', message: '' });

    // load local geoJson registry data
    React.useEffect(() => {
        let geoJsonData;
        const geoRegistry = GeoRegistry.create();
        geoRegistry.addDefaultProvider(new GeoJsonProvider());
        try {
            geoJsonData = geoRegistry.getByURL(choroplethLayer.source);
            setGeoJson(geoJsonData);
            setValidGeoJson(true);
        } catch (e) {
            setGeoJsonError(e);
            setValidGeoJson(false);
        }
    }, [choroplethLayer.source]);

    const updatedPropertyFormat = React.useMemo(() => {
        let propertyFormat = 'name';
        if (choroplethLayer.areaIds && validGeoJson) {
            const propertyFormats = Object.keys(geoJson.features[0].properties);

            // check the state or country format used in dataSource
            /* Accepted format for countries: 
                "properties": 
                {
                    "iso2": "AM",
                    "iso3": "ARM",
                    "name": "Armenia"
                }

            Accepted format for US States:
            "properties": 
                {
                    "iso_3166_2": "US-MI",
                    "postal": "MI",
                    "name": "Michigan",
                    "label_en": "Michigan"
                },
            */
            for (let idx = 0; idx < geoJson.features.length; idx += 1) {
                const shapeProperty = geoJson.features[idx].properties;
                const formatCheck = propertyFormats.find(
                    key => shapeProperty[key] === choroplethLayer.areaIds[0]
                );

                if (formatCheck !== undefined) {
                    propertyFormat = formatCheck;
                    break;
                }
            }
        }
        return propertyFormat;
    }, [choroplethLayer.areaIds, geoJson]);

    const colors = choroplethLayer?.dataColors?.map(color => (color?.length > 7 ? color.slice(0, 7) : color));
    const scaledOpacities = getScaledOpacities(choroplethLayer?.dataColors, cfgOpacity) ?? [];

    const updatedAllChoroplethData = React.useMemo(() => {
        const allChoroplethData = [];
        if (validGeoJson) {
            for (let idx = 0; idx < geoJson.features.length; idx += 1) {
                const el = geoJson.features[idx];

                const choroplethData: {
                    color: string;
                    areaValue: string | number;
                    opacity: number;
                    name: string;
                    idx: number;
                } = {
                    color: choroplethLayer.choroplethEmptyAreaColor,
                    areaValue: 'No Data',
                    opacity: cfgOpacity,
                    name: '',
                    idx: 0,
                };

                choroplethData.name = el.properties[updatedPropertyFormat];
                // check whether dataSource exists
                if (choroplethLayer.areaIds) {
                    // store the postion of dataSource in geoJson
                    const dataIdx = choroplethLayer.areaIds.indexOf(el.properties[updatedPropertyFormat]);
                    if (dataIdx !== -1) {
                        choroplethData.color = colors[dataIdx];
                        choroplethData.areaValue = choroplethLayer.areaValues[dataIdx];
                        choroplethData.opacity = scaledOpacities[dataIdx];
                        choroplethData.idx = dataIdx;
                    } else {
                        choroplethData.idx = choroplethLayer.areaIds.length;
                    }
                }
                allChoroplethData.push(choroplethData);
            }
        }
        return allChoroplethData;
    }, [choroplethLayer.areaIds, choroplethLayer.areaValues, colors, scaledOpacities]);

    const handleMapClick = (e, i) => {
        const payload = formatMetadata(choroplethLayer, i);
        onMapClick({ e, payload });
    };

    if (isThemeChanged) {
        setIsThemeChanged(false);
    }

    const additionalFields = choroplethLayer?.metadata
        ? choroplethLayer.metadata.map(choroplethMetadata =>
              choroplethLayer.additionalTooltipFields?.map(field => ({
                  name: field,
                  value: choroplethMetadata[field],
              }))
          )
        : [];

    return (
        <div>
            {!validGeoJson && (
                <Message
                    data-test="choropleth-message"
                    width={width}
                    height={height}
                    level={geoJsonError.level}
                    message={geoJsonError.message}
                />
            )}
            {validGeoJson &&
                updatedAllChoroplethData.map((el, i) => {
                    return (
                        <Choropleth
                            key={el.name}
                            id={el.name}
                            map={map}
                            color={el.color}
                            opacity={el.opacity}
                            strokeColor={choroplethLayer.choroplethStrokeColor}
                            areaId={el.name}
                            areaValue={el.areaValue}
                            handleMapClick={e => handleMapClick(e, el.idx)}
                            isThemeChanged={isThemeChanged}
                            geomData={geoJson.features[i].geometry}
                            additionalFields={additionalFields[el.idx]}
                        />
                    );
                })}
        </div>
    );
};

export default LocalChoroplethLayer;
