import numeral from 'numeral';
import moment from '@splunk/moment';
/**
 * Fixme, this is a mock i18n file to workaround window dependency from @splunk/charting
 * We need to refactor @splunk/charting to make it independent from globals
 */

const quarters = {
    1: '1st quarter',
    2: '2nd quarter',
    3: '3rd quarter',
    4: '4th quarter',
};

const days = {
    0: 'Monday',
    1: 'Tuesday',
    2: 'Wednesday',
    3: 'Thursday',
    4: 'Friday',
    5: 'Saturday',
    6: 'Sunday',
};

const months = {
    1: 'January',
    2: 'February',
    3: 'March',
    4: 'April',
    5: 'May',
    6: 'June',
    7: 'July',
    8: 'August',
    9: 'September',
    10: 'October',
    11: 'November',
    12: 'December',
};

const narrowMonths = {
    1: 'J',
    2: 'F',
    3: 'M',
    4: 'A',
    5: 'M',
    6: 'J',
    7: 'J',
    8: 'A',
    9: 'S',
    10: 'O',
    11: 'N',
    12: 'D',
};

const localeDefinition = {
    date_formats: {
        medium: { pattern: 'MMM d, yyyy', format: '%(MMM)s %(d)s, %(yyyy)s' },
        long: { pattern: 'MMMM d, yyyy', format: '%(MMMM)s %(d)s, %(yyyy)s' },
        short: { pattern: 'M/d/yy', format: '%(M)s/%(d)s/%(yy)s' },
        full: { pattern: 'EEEE, MMMM d, yyyy', format: '%(EEEE)s, %(MMMM)s %(d)s, %(yyyy)s' },
    },
    quarters: {
        'stand-alone': {
            wide: quarters,
            abbreviated: quarters,
            narrow: { 1: '1', 2: '2', 3: '3', 4: '4' },
        },
        format: {
            wide: quarters,
            abbreviated: { 1: 'Q1', 2: 'Q2', 3: 'Q3', 4: 'Q4' },
            narrow: { 1: '1', 2: '2', 3: '3', 4: '4' },
        },
    },
    min_week_days: 1,
    days: {
        'stand-alone': {
            wide: days,
            abbreviated: days,
            narrow: { 0: 'M', 1: 'T', 2: 'W', 3: 'T', 4: 'F', 5: 'S', 6: 'S' },
        },
        format: {
            wide: days,
            abbreviated: {
                0: 'Mon',
                1: 'Tue',
                2: 'Wed',
                3: 'Thu',
                4: 'Fri',
                5: 'Sat',
                6: 'Sun',
            },
            narrow: { 0: 'M', 1: 'T', 2: 'W', 3: 'T', 4: 'F', 5: 'S', 6: 'S' },
        },
    },
    eras: {
        wide: { 0: 'Before Christ', 1: 'Anno Domini' },
        abbreviated: { 0: 'BC', 1: 'AD' },
        narrow: { 0: 'B', 1: 'A' },
    },
    percent_format: '#,##0%',
    scientific_format: '#E0',
    first_week_day: 6,
    group_symbol: ',',
    minus_sign: '-',
    months: {
        'stand-alone': {
            wide: months,
            abbreviated: months,
            narrow: narrowMonths,
        },
        format: {
            wide: months,
            abbreviated: {
                1: 'Jan',
                2: 'Feb',
                3: 'Mar',
                4: 'Apr',
                5: 'May',
                6: 'Jun',
                7: 'Jul',
                8: 'Aug',
                9: 'Sep',
                10: 'Oct',
                11: 'Nov',
                12: 'Dec',
            },
            narrow: narrowMonths,
        },
    },
    decimal_symbol: '.',
    periods: { am: 'AM', pm: 'PM' },
    datetime_formats: { null: '{1} {0}' },
    exp_symbol: 'E',
    locale_name: 'en_US',
    number_format: '#,##0.###',
    plus_sign: '+',
    time_formats: {
        medium: { pattern: 'h:mm:ss a', format: '%(h)s:%(mm)s:%(ss)s %(a)s' },
        long: { pattern: 'h:mm:ss a z', format: '%(h)s:%(mm)s:%(ss)s %(a)s %(z)s' },
        short: { pattern: 'h:mm a', format: '%(h)s:%(mm)s %(a)s' },
        full: { pattern: 'h:mm:ss a v', format: '%(h)s:%(mm)s:%(ss)s %(a)s %(v)s' },
    },
};

const i18n = {
    format_decimal: (num, format) => numeral(num).format(format),
    format_number: (num, format) => numeral(num).format(format),
    format_percent: num => numeral(num).format('0%'),
    format_scientific: num => numeral(num).format('0,0e+0'),
    format_date: (num, format) =>
        moment(num).format(
            localeDefinition.date_formats[format] ? localeDefinition.date_formats[format].pattern : format
        ),
    format_datetime: (num, dateFormat) => {
        moment(num).format(
            localeDefinition.time_formats[dateFormat]
                ? localeDefinition.time_formats[dateFormat].pattern
                : dateFormat
        );
    },
    format_time: (num, format) =>
        moment(num).format(
            localeDefinition.time_formats[format] ? localeDefinition.time_formats[format].pattern : format
        ),
    format_datetime_microseconds: (num, format) =>
        moment(num).format(
            localeDefinition.time_formats[format] ? localeDefinition.time_formats[format].pattern : format
        ),
    format_time_microseconds: (num, format) =>
        moment(num).format(
            localeDefinition.time_formats[format] ? localeDefinition.time_formats[format].pattern : format
        ),
};

export const setI18nFunctions = global => {
    Object.keys(i18n).forEach(k => {
        if (!global[k]) {
            global[k] = i18n[k]; // eslint-disable-line no-param-reassign
        }
    });
    if (!global.gettext) {
        global.gettext = v => v; // eslint-disable-line no-param-reassign
    }
    if (!global.locale_name) {
        global.locale_name = () => 'en_US'; // eslint-disable-line no-param-reassign
    }
    return global;
};
setI18nFunctions(window);
