import type { AbsoluteBlockItem, Coordinate } from '@splunk/dashboard-types';
import type { SplitGridEntry } from './gridOrderUtils';
import {
    splitGrid,
    translateBlockItem,
    translateBlockItems,
} from './splitGridStructure';

/**
 * Mapping of block item ID to block item which contains changes to be made to a
 * grid structure in order to apply the appropriate resize behavior
 */
export type ResizeChanges = Record<string, AbsoluteBlockItem>;

type RecursiveDirectionalResizeArgs = {
    container: SplitGridEntry;
    newSize: number;
    position: number;
    translation: Coordinate;
};

type RecursiveDirectionalResizeFn = (
    args: RecursiveDirectionalResizeArgs
) => ResizeChanges;

/**
 * Recursively apply horizontal resizing to a grid structure.
 * All row splits will remain at their original width, while column splits will be resized and repositioned
 * @param {RecursiveDirectionalResizeArgs} args configuration
 * @param {SplitGridEntry} args.container The result of `splitGrid`
 * @param {number} args.newSize The new container width
 * @param {number} args.position The X-pos of the container
 * @param {Coordinate} args.translation A translation to be applied to the results
 * @returns Required block item updates for container to be resized
 */
const recursivelyApplyHorizontalResize: RecursiveDirectionalResizeFn = ({
    container: {
        content: { items, width: totalWidth },
        origin,
    },
    newSize,
    position,
    translation,
}) => {
    const changes: ResizeChanges = {};

    const { splitByRows, splitByColumns } = splitGrid({
        layoutStructure: translateBlockItems(items, {
            x: -origin.x,
            y: -origin.y,
        }),
    });

    // Handle resizing of row splits if they exist
    // When handling rows for a horizontal resize the width remains unchanged
    splitByRows.forEach((container) => {
        Object.assign(
            changes,
            recursivelyApplyHorizontalResize({
                container,
                newSize,
                position,
                translation: {
                    x: translation.x + origin.x,
                    y: translation.y + origin.y,
                },
            })
        );
    });

    // Handle resizing of column splits if they exist
    // When handling columns for a horizontal resize the width and X-pos should be updated
    let colPosition = position;
    splitByColumns.forEach((container, idx) => {
        let newColWidth = Math.floor(
            newSize * (container.content.width / totalWidth)
        );

        if (
            // If resizing the last column
            idx === splitByColumns.length - 1 &&
            // and there's a fractional correction needed
            colPosition + newColWidth !== position + newSize
        ) {
            // then override the calculated width to fill the would-be gap
            newColWidth = position + newSize - colPosition;
        }

        Object.assign(
            changes,
            recursivelyApplyHorizontalResize({
                container,
                newSize: newColWidth,
                position: colPosition,
                translation: {
                    x: translation.x + origin.x,
                    y: translation.y + origin.y,
                },
            })
        );

        colPosition += newColWidth;
    });

    // Base case: there was nothing to split (single item in container)
    if (splitByColumns.length === 0 && splitByRows.length === 0) {
        items.forEach((item) => {
            const { position: itemPos } = translateBlockItem(item, translation);

            changes[item.item] = {
                ...item,
                position: {
                    ...itemPos,
                    x: position,
                    w: newSize,
                },
            };
        });
    }

    return changes;
};

/**
 * Recursively apply vertical resizing to a grid structure.
 * All column splits will remain at their original height, while row splits will be resized and repositioned
 * @param {RecursiveDirectionalResizeArgs} args configuration
 * @param {SplitGridEntry} args.container The result of `splitGrid`
 * @param {number} args.newSize The new container height
 * @param {number} args.position The Y-pos of the container
 * @param {Coordinate} args.translation A translation to be applied to the results
 * @returns Required block item updates for container to be resized
 */
const recursivelyApplyVerticalResize: RecursiveDirectionalResizeFn = ({
    container: {
        content: { items, height: totalHeight },
        origin,
    },
    newSize,
    position,
    translation,
}) => {
    const changes: ResizeChanges = {};

    const { splitByRows, splitByColumns } = splitGrid({
        layoutStructure: translateBlockItems(items, {
            x: -origin.x,
            y: -origin.y,
        }),
    });

    // Handle resizing of column splits if they exist
    // When handling columns for a vertical resize the height remains unchanged
    splitByColumns.forEach((container) => {
        Object.assign(
            changes,
            recursivelyApplyVerticalResize({
                container,
                newSize,
                position,
                translation: {
                    x: translation.x + origin.x,
                    y: translation.y + origin.y,
                },
            })
        );
    });

    // Handle resizing of row splits if they exist
    // When handling rows for a vertical resize the height and Y-pos should be updated
    let rowPosition = position;
    splitByRows.forEach((container, idx) => {
        let newRowHeight = Math.floor(
            newSize * (container.content.height / totalHeight)
        );

        if (
            // If resizing the last row
            idx === splitByRows.length - 1 &&
            // and there's a fractional correction needed
            rowPosition + newRowHeight !== position + newSize
        ) {
            // then override the calculated height to fill the would-be gap
            newRowHeight = position + newSize - rowPosition;
        }

        Object.assign(
            changes,
            recursivelyApplyVerticalResize({
                container,
                newSize: newRowHeight,
                position: rowPosition,
                translation: {
                    x: translation.x + origin.x,
                    y: translation.y + origin.y,
                },
            })
        );

        rowPosition += newRowHeight;
    });

    // Base case: there was nothing to split (single item in container)
    if (splitByColumns.length === 0 && splitByRows.length === 0) {
        items.forEach((item) => {
            const { position: itemPos } = translateBlockItem(item, translation);

            changes[item.item] = {
                ...item,
                position: {
                    ...itemPos,
                    y: position,
                    h: newSize,
                },
            };
        });
    }

    return changes;
};

// Configuration for horizontal resize. Calculations for vertical/horizontal logic are equal, albeit with different variables
const HorizontalResizeConfig = {
    dimension: 'width',
    blockSizeDimension: 'w',
    blockPosDimension: 'x',
    splitGridConfig: 'columns',
    splitGridKey: 'splitByColumns',
    recursiveResizeFn: recursivelyApplyHorizontalResize,
} as const;

// Configuration for vertical resize. Calculations for vertical/horizontal logic are equal, albeit with different variables
const VerticalResizeConfig = {
    dimension: 'height',
    blockSizeDimension: 'h',
    blockPosDimension: 'y',
    splitGridConfig: 'rows',
    splitGridKey: 'splitByRows',
    recursiveResizeFn: recursivelyApplyVerticalResize,
} as const;

type HandleResizingArgs = {
    container?: SplitGridEntry;
    itemToRemove: AbsoluteBlockItem;
    config: typeof HorizontalResizeConfig | typeof VerticalResizeConfig;
};

/**
 * Generate a set of block item changes for the given container which will fill the
 * horizontal or vertical space originally being consumed by the item to be removed
 * @param {HandleResizingArgs} param0
 * @param {SplitGridEntry} param0.container A grid split entry, returned from `getContainingRowColumn`
 * @param {AbsoluteBlockItem} param0.itemToRemove The item being removed from the structure, whose
 * width or height should be redistributed to the other items in the structure
 * @param {HorizontalResizeConfig | VerticalResizeConfig} param0.config Configuration object for resize calculations
 * @returns {ResizeChanges} Object detailing block item changes needed for the resize
 */
const handleResizing = ({
    container,
    itemToRemove,
    config: {
        dimension,
        blockSizeDimension,
        blockPosDimension,
        splitGridConfig,
        splitGridKey,
        recursiveResizeFn,
    },
}: HandleResizingArgs): ResizeChanges => {
    if (!container) {
        return {};
    }

    const {
        origin,
        content: {
            // Items in the container which are being resized
            items: impactedItems,
            // The important container dimension (width or height) being recalculated
            [dimension]: containerSize,
        },
    } = container;

    // The negated origin will be used for transposing the grid while calculating mutations
    // so nested utilites can always assume an origin of (0, 0) in the received structure
    const negatedOrigin = { x: -origin.x, y: -origin.y };

    if (impactedItems.length < 2) {
        // Ideally this shouldn't happen, but to be safe include a base case
        return {};
    }

    if (impactedItems.length === 2) {
        // If there are only two items in the container then we just need to take the
        // one not being removed and make its size/position equal to the container
        // dimensions and origin
        const itemToUpdate =
            impactedItems[0].item !== itemToRemove.item
                ? impactedItems[0]
                : impactedItems[1];

        return {
            [itemToUpdate.item]: {
                ...itemToUpdate,
                position: {
                    ...itemToUpdate.position,
                    [blockPosDimension]: origin[blockPosDimension],
                    [blockSizeDimension]: containerSize,
                },
            },
        };
    }

    const pendingChanges: ResizeChanges = {};

    // When running vertical resize get the splitByRows, else get splitByColumns
    // because the desired split should isolate the item being removed into a single
    // split entry
    const {
        [splitGridKey]: splitByResizeDimension,
        itemToRemove: {
            position: { [blockSizeDimension]: itemToRemoveSize },
        },
    } = splitGrid({
        layoutStructure: translateBlockItems(impactedItems, negatedOrigin),
        splitConfig: splitGridConfig,
        idToRemove: itemToRemove.item,
    });

    // Calculate the percentage of the container taken up by the item
    // being removed and distribute that evenly to all other splits
    const pctToDistribute =
        itemToRemoveSize / (splitByResizeDimension.length - 1) / containerSize;

    // Track the current working position for the block items being mutated. As sizes are updated, item positions need to shift accordingly
    let itemPosition = origin[blockPosDimension];

    // Track the terminus of the container. After the last resize calculation itemPosition should equal containerEdge
    const containerEdge = origin[blockPosDimension] + containerSize;

    // Start by assuming the item being removed is last in the split array. This means the last chance to account for fractional resize corrections (FRC)
    // is the next-to-last split entry. If during processing the item being removed is found BEFORE the end of the array, then increment lastFRCIdx
    // to indicate that the last split entry can now be used for fractional resize correction
    let lastFRCIdx = splitByResizeDimension.length - 2;

    splitByResizeDimension.forEach((split, idx) => {
        // Skip the item being removed if it is found, and update lastFRCIdx per above
        if (split.content.items[0].item === itemToRemove.item) {
            lastFRCIdx += 1;
            return;
        }

        // Calculate the new size for the split. This calculation may be updated if
        // the resize calculations had to be floored and a fractional size remains
        const splitSize = split.content[dimension] / containerSize;
        let newSize = Math.floor(containerSize * (pctToDistribute + splitSize));

        if (idx === lastFRCIdx && itemPosition + newSize !== containerEdge) {
            // Adjust newSize as it wouldn't result in a correctly-dimensioned grid
            newSize = containerEdge - itemPosition;
        }

        Object.values(
            recursiveResizeFn({
                container: split,
                newSize,
                position: itemPosition,
                translation: origin,
            })
        ).forEach((change) => {
            pendingChanges[change.item] = change;
        });

        // Increment the working item position (position of the next split to be resized)
        itemPosition += newSize;
    });

    return pendingChanges;
};

/**
 * An object containing properties:
 *
 * `container` - the result of calling `getContainingRowColumn`
 *
 * `itemToRemove` - the {@link AbsoluteBlockItem block item} being removed from the grid, causing the resize
 */
export type HandleDirectionalResizingArgs = Pick<
    HandleResizingArgs,
    'container' | 'itemToRemove'
>;

/**
 * Handles horizontal resizing of a container.
 * The width of itemToRemove will be proportionally redistributed to all other column splits
 * in the container based upon the splits' width as a percentage of the total container width
 * @param {HandleDirectionalResizingArgs} args container and itemToRemove
 * @param {SplitGridEntry} args.container The result of `getContainingRowColumn`
 * @param {AbsoluteBlockItem} args.itemToRemove The block item being removed from the structure, causing the resize
 * @returns {ResizeChanges} Object detailing block item changes needed for the resize
 */
export const handleHorizontalResizing = (
    args: HandleDirectionalResizingArgs
): ResizeChanges => handleResizing({ ...args, config: HorizontalResizeConfig });

/**
 * Handles vertical resizing of a container.
 * The height of itemToRemove will be proportionally redistributed to all other row splits in
 * the container based upon the splits' height as a percentage of the total container height
 * @param {HandleDirectionalResizingArgs} args container and itemToRemove
 * @param {SplitGridEntry} args.container The non-nullable result of `getContainingRowColumn`
 * @param {AbsoluteBlockItem} args.itemToRemove The block item being removed from the structure, causing the resize
 * @returns {ResizeChanges} Object detailing block item changes needed for the resize
 */
export const handleVerticalResizing = (
    args: HandleDirectionalResizingArgs
): ResizeChanges => handleResizing({ ...args, config: VerticalResizeConfig });
