import { variables, pick } from '@splunk/themes';

const commonUIBackgroundColor = pick({
    enterprise: {
        light: variables.white,
        dark: variables.black,
    },
    prisma: variables.backgroundColorNavigation,
});

const commonUIBorderColor = pick({
    enterprise: variables.borderColor,
    prisma: variables.contentColorDisabled,
});

export const monacoColors = {
    background: {
        light: '#FFFFFE',
        dark: '#222222',
    },
    popup: {
        light: '#DDDDDD',
        dark: '#000000',
    },
};

export const customThemeVariables = {
    invalidDropTargetBackgroundColor: '#8A393B',
    validDropTargetBackgroundColor: pick({
        light: '#80EC9F',
        dark: '#117E32',
    }),
    invalidDropTargetBorderColor: pick({
        enterprise: '#FE3A3A',
        prisma: variables.accentColorNegative,
    }),
    validDropTargetBorderColor: pick({
        enterprise: {
            light: '#07BA39',
            dark: '#08E045',
        },
        prisma: variables.accentColorPositive,
    }),
    dashboardBackgroundColor: pick({
        enterprise: {
            light: variables.gray96,
            dark: variables.gray20,
        },
        prisma: variables.backgroundColorSection,
    }),
    selectedInputBackgroundColor: pick({
        enterprise: {
            light: variables.gray92,
            dark: variables.gray30,
        },
        prisma: variables.backgroundColorPopup,
    }),
    selectedInputBorderColor: pick({
        enterprise: {
            light: variables.borderColor,
            dark: variables.gray45,
        },
        prisma: variables.contentColorDisabled,
    }),
    vizPanelBackgroundColor: pick({
        enterprise: {
            light: variables.white,
            dark: variables.black,
        },
        prisma: variables.backgroundColorSidebar,
    }),
    toolbarBackgroundColor: commonUIBackgroundColor,
    toolbarBorderColor: commonUIBorderColor,
    sidebarPanelBackgroundColor: commonUIBackgroundColor,
    sidebarPanelBorderColor: commonUIBorderColor,
    monacoBackgroundColor: pick(monacoColors.background),
    monacoBorder: '1px solid rgba(0, 0, 0, 0.15)',
};
