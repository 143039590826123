import type { AnyTheme, Density } from '@splunk/themes';

// Using `Tree<AnyTheme, Record<Density, number>>` wasn't working. This is close enough.
type FamilyDensityTree = Record<AnyTheme['family'], Record<Density, number>>;

// Same heights used in `Header` (packages/dashboard/src/components/sidebars/Header.jsx)
export const headerHeightInterpolationTree: FamilyDensityTree = {
    enterprise: {
        compact: 38,
        comfortable: 42,
    },
    prisma: {
        compact: 42,
        comfortable: 50,
    },
};

// Header height plus 1px border less the theme icon heights, halved
export const headerPaddingInterpolationTree: FamilyDensityTree = {
    enterprise: {
        compact: 15,
        comfortable: 16.25,
    },
    prisma: {
        compact: 15.5,
        comfortable: 17.625,
    },
};
