import { useMemo } from 'react';
import type { DataSourceEventPayload } from '@splunk/dashboard-types';
import { NO_RESULTS_MSG } from '@splunk/dashboard-state';

export const useStatusMessage = ({
    dataSource,
}: {
    dataSource?: DataSourceEventPayload;
}) => {
    const errorMessage = dataSource?.error?.message;
    const warningMessage = dataSource?.meta?.serverLog?.[0]?.message;
    let message = errorMessage || warningMessage || '';
    const status = dataSource?.meta?.status ?? '';
    const noData = dataSource?.data?.columns?.length === 0;
    const noSearchResults = status === 'done' && noData;
    if (noSearchResults) {
        message = NO_RESULTS_MSG;
    }
    const showWaitingForInputPlaceholder = !!(
        noSearchResults ||
        errorMessage ||
        (noData && warningMessage)
    );

    return useMemo(
        () => ({ message, showWaitingForInputPlaceholder }),
        [message, showWaitingForInputPlaceholder]
    );
};
