export default {
    query: {
        type: 'string',
        description: 'An SPL search string.',
    },
    queryParameters: {
        type: 'object',
        properties: {
            earliest: {
                type: 'string',
                description: `Specify the earliest time to search for events. Choose from year (y), month (m), week (w), day (d), minute, (m), or second (s), or 0 for all time.
                See the examples below.`,
            },
            latest: {
                type: 'string',
                description: `Specify the earliest time to search for events. Choose from year (y), month (m), week (w), day (d), minute, (m), second (s), or now.
                See the examples below.`,
            },
        },
        additionalProperties: false,
        description:
            'SPL query parameters, only contains earliest and latest for now.',
    },
    refresh: {
        type: ['number', 'string'],
        description:
            'Specify the refresh interval in seconds or with a time expression.',
    },
    refreshType: {
        type: 'string',
        enum: ['delay', 'interval'],
        default: 'delay',
        description:
            'Indicate the starting time for counting down to a refresh. Use delay to start counting when the search is done. Use interval to count down when the search is dispatched.',
    },
};
