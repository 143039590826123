import { isEmpty, each } from 'lodash';
import { put, call, takeEvery, cancelled, select } from 'redux-saga/effects';
import { console, logfmt } from '@splunk/dashboard-utils';
import { DashboardDefinition } from '@splunk/dashboard-definition';
import {
    cloneDashboardItems,
    createVisualization,
    removeVisualizations,
    removeDashboardItems,
    adjustVisualizationOrder,
    removeInput,
    moveInputToCanvas,
    moveInputToGlobalInputs,
} from './sagaActions';
import { selectDefinition, updateDefinition } from '../reducers/definition';
import { unsetTokens } from '../reducers/tokens';
import { selectInputState } from '../reducers/inputState';
import { getTokensToUnset } from '../utils/input';

/**
 * create visualization and datasource
 * @param {*} sagaContext
 * @param {*} action
 */
export function* handleCreateVisualization(sagaContext, action) {
    const layoutApi = sagaContext.apiRegistry.getLayoutApi();
    if (layoutApi && typeof layoutApi.addLayoutItem === 'function') {
        const currentDefinition = yield select(selectDefinition);
        const definition = DashboardDefinition.fromJSON(currentDefinition);
        const {
            visualizationId,
            visualizationDefinition,
            layoutItemType,
            dataSourceType,
            dataSourceDefinition,
        } = action.payload || {};
        // fetch viz preset
        const viz = sagaContext.preset.findVisualization(
            visualizationDefinition.type
        );
        const vizContract = viz?.vizContract ?? {};
        const config = viz?.config ?? {};
        // add datasource and connect it with visualization if provided
        if (visualizationId && visualizationDefinition) {
            const vizDef = { ...visualizationDefinition };
            if (dataSourceType && dataSourceDefinition) {
                const dataSourceId = definition.nextDataSourceId();
                definition.addDataSource(dataSourceId, dataSourceDefinition);
                vizDef.dataSources = {
                    ...(vizDef.dataSources || {}),
                    [dataSourceType]: dataSourceId,
                };
            }
            const proposedLayoutStructure = yield call(
                layoutApi.addLayoutItem,
                {
                    itemId: visualizationId,
                    vizContract,
                    type: layoutItemType,
                    config,
                    metadata: { internal: true },
                }
            );
            definition
                .addVisualization(visualizationId, vizDef)
                .updateLayoutStructure(proposedLayoutStructure);
        }
        // onDefinitionChange callback will then be invoke
        yield put(updateDefinition(definition.toJSON()));
    }
}

export function* handleCloneDashboardItems(sagaContext, action) {
    const layoutApi = sagaContext.apiRegistry.getLayoutApi();
    if (layoutApi && typeof layoutApi.cloneLayoutItems === 'function') {
        const currentDefinition = yield select(selectDefinition);
        const definition = DashboardDefinition.fromJSON(currentDefinition);
        const { from, to, offsetMultiplier } = action.payload || {};
        if (from.length) {
            each(from, (srcId, iter) => {
                if (definition.getVisualization(srcId)) {
                    definition.cloneVisualization(srcId, to[iter]);
                } else {
                    definition.cloneInput(srcId, to[iter]);
                }
            });
            const proposedLayoutStructure = yield call(
                layoutApi.cloneLayoutItems,
                {
                    from,
                    to,
                    offsetMultiplier,
                    metadata: { internal: true },
                }
            );
            definition.updateLayoutStructure(proposedLayoutStructure);
        }
        yield put(updateDefinition(definition.toJSON()));
    }
}

export function* handleRemoveDashboardItems(sagaContext, action) {
    // separate ids between visualizations, global inputs, and inputs on canvas
    const ids = action.payload || [];

    const currentDefinition = yield select(selectDefinition);
    const currentInputState = yield select(selectInputState);
    const definition = DashboardDefinition.fromJSON(currentDefinition);
    const globalInputs = definition.getGlobalInputs();

    const vizIds = ids.filter((id) => definition.getVisualization(id) !== null);
    const inputsOnCanvasIds = ids.filter((id) =>
        definition.isInputOnCanvas(id)
    );
    const globalInputsIds = ids.filter((id) => globalInputs.includes(id));
    const inputIds = [...inputsOnCanvasIds, ...globalInputsIds];

    // unset any tokens connected to the removed inputs (for both global input or inputs on canvas)
    const tokensToUnset = getTokensToUnset({
        inputIds,
        definition,
        sagaContext,
        currentInputState,
    });

    if (!isEmpty(tokensToUnset)) {
        yield put(unsetTokens({ tokens: tokensToUnset }));
    }

    // remove items from layout structure
    const layoutApi = sagaContext.apiRegistry.getLayoutApi();
    if (layoutApi && typeof layoutApi.removeLayoutItems === 'function') {
        const layoutItemsToRemove = [...vizIds, ...inputsOnCanvasIds];
        if (layoutItemsToRemove.length) {
            const proposedLayoutStructure = yield call(
                layoutApi.removeLayoutItems,
                layoutItemsToRemove,
                { internal: true }
            );
            definition.updateLayoutStructure(proposedLayoutStructure);
        }
    }
    globalInputsIds.forEach((id) => {
        definition.removeInputFromLayout(id);
    });

    // remove items from their corresponding definitions (inputDef, vizDef, etc.)
    vizIds.forEach((id) => {
        definition.removeVisualization(id);
    });
    inputIds.forEach((id) => definition.removeInput(id));

    // update definition
    yield put(updateDefinition(definition.toJSON()));
}

function* handleVisualizationOrder(sagaContext, action) {
    const layoutApi = sagaContext.apiRegistry.getLayoutApi();
    if (layoutApi && layoutApi.adjustLayoutItemOrder) {
        const currentDefinition = yield select(selectDefinition);
        const definition = DashboardDefinition.fromJSON(currentDefinition);
        const proposedLayoutStructure = yield call(
            layoutApi.adjustLayoutItemOrder,
            action.payload.from,
            action.payload.to,
            { internal: true }
        );
        // onDefinitionChange callback will then be invoke
        definition.updateLayoutStructure(proposedLayoutStructure);
        yield put(updateDefinition(definition.toJSON()));
    }
}

export function* handleMoveInputToCanvas(sagaContext, action) {
    const layoutApi = sagaContext.apiRegistry.getLayoutApi();
    const inputId = action.payload;
    const currentDefinition = yield select(selectDefinition);
    const definition = DashboardDefinition.fromJSON(currentDefinition);
    definition.moveInputToCanvas(inputId);
    yield put(updateDefinition(definition.toJSON()));
    if (layoutApi && typeof layoutApi.addLayoutItem === 'function') {
        layoutApi.addLayoutItem({ itemId: inputId });
    }
}

export function* handleMoveInputToGlobalInputs(_sagaContext, action) {
    const inputId = action.payload;
    const currentDefinition = yield select(selectDefinition);
    const definition = DashboardDefinition.fromJSON(currentDefinition);
    definition.moveInputToGlobalInputs(inputId);
    yield put(updateDefinition(definition.toJSON()));
}

export default function* editingSaga(sagaContext) {
    try {
        yield takeEvery(
            createVisualization,
            handleCreateVisualization,
            sagaContext
        );
        yield takeEvery(
            cloneDashboardItems,
            handleCloneDashboardItems,
            sagaContext
        );
        yield takeEvery(
            removeVisualizations,
            handleRemoveDashboardItems,
            sagaContext
        );
        yield takeEvery(
            removeDashboardItems,
            handleRemoveDashboardItems,
            sagaContext
        );
        yield takeEvery(removeInput, handleRemoveDashboardItems, sagaContext);
        yield takeEvery(
            adjustVisualizationOrder,
            handleVisualizationOrder,
            sagaContext
        );
        yield takeEvery(
            moveInputToCanvas,
            handleMoveInputToCanvas,
            sagaContext
        );
        yield takeEvery(
            moveInputToGlobalInputs,
            handleMoveInputToGlobalInputs,
            sagaContext
        );
    } catch (error) {
        if (!(yield cancelled())) {
            console.error(...logfmt`Caught error: ${error}`);
        }
    }
}
