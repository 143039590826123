import React from 'react';
import { _ } from '@splunk/ui-utils/i18n';
import GaugeFiller from '@splunk/react-icons/enterprise/GaugeFiller';

const FillerGauge = (
    props: React.ComponentProps<typeof GaugeFiller>
): ReturnType<typeof GaugeFiller> => (
    <GaugeFiller {...props} screenReaderText={_('Filler Gauge')} />
);

export default FillerGauge;
