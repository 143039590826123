import isEmpty from 'lodash/isEmpty';
import { parse as baseParse, mergeEncoding } from '@splunk/visualization-encoding-parsers/Base';
import { ErrorMsgs } from './errorMsgs';

export const dataContract = {
    requiredDataSources: [
        {
            name: 'primary',
            description: 'DataSource that powers the visualization',
        },
    ],
    initialRequestParams: {
        primary: { offset: 0, count: 10000 },
    },
    encodings: {
        value: {
            isRequired: true,
            type: ['number'],
            default: 'primary[1]',
        },
        label: {
            isRequired: true,
            type: ['string'],
            default: 'primary[0]',
        },
    },
};

export const parse = (dataSources, userDefinedEncoding = {}) => {
    if (isEmpty(dataSources)) {
        throw new Error(ErrorMsgs.NO_DATA_SOURCES);
    }

    const encoding = mergeEncoding(dataContract.encodings, userDefinedEncoding);
    const data = baseParse(dataSources, encoding);
    return {
        value: data.value,
        label: data.label,
        labelTitle: data['_meta'].fieldNames.label, // eslint-disable-line dot-notation
        valueTitle: data['_meta'].fieldNames.value, // eslint-disable-line dot-notation
    };
};
