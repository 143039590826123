import { _ } from '@splunk/ui-utils/i18n';
import { EditorConfig } from '../interfaces/Editor';

const axesChartY2GridAndLabels: EditorConfig = {
    label: _('Y2-axis grid and labels'),
    layout: [
        [
            {
                label: _('Axis title'),
                option: 'y2AxisTitleVisibility',
                editor: 'editor.toggle',
            },
        ],
        [
            {
                label: _('Axis title'),
                option: 'y2AxisTitleText',
                editor: 'editor.text',
                showEditor: ({ options }) =>
                    options.y2AxisTitleVisibility === 'show' || options.y2AxisTitleVisibility === undefined,
            },
        ],
        [
            {
                label: _('Show overlays on the y2-axis'),
                option: 'showOverlayY2Axis',
                editor: 'editor.checkbox',
                showEditor: ({ options }) =>
                    Array.isArray(options.overlayFields) && options.overlayFields.length > 0,
            },
        ],
        [
            {
                label: _('Scale'),
                option: 'y2AxisScale',
                editor: 'editor.radioBar',
                editorProps: {
                    values: [
                        { label: _('Linear'), value: 'linear' },
                        { label: _('Log'), value: 'log' },
                    ],
                },
            },
        ],
        [
            {
                label: _('Label visibility'),
                option: 'y2AxisLabelVisibility',
                editor: 'editor.radioBar',
                editorProps: {
                    values: [
                        { label: _('Auto'), value: 'auto' },
                        { label: _('Show'), value: 'show' },
                        { label: _('Hide'), value: 'hide' },
                    ],
                },
            },
        ],
        [
            {
                label: _('Round axis labels'),
                option: 'showRoundedY2AxisLabels',
                editor: 'editor.checkbox',
                showEditor: ({ options }) =>
                    options.y2AxisLabelVisibility === 'show' ||
                    options.y2AxisLabelVisibility === 'auto' ||
                    options.y2AxisLabelVisibility === undefined,
            },
        ],
        [
            {
                label: _('SI prefix abbreviation for labels'),
                option: 'y2AxisAbbreviation',
                editor: 'editor.checkbox',
                showEditor: ({ options }) =>
                    options.y2AxisLabelVisibility === 'show' ||
                    options.y2AxisLabelVisibility === 'auto' ||
                    options.y2AxisLabelVisibility === undefined,
            },
        ],
        [
            {
                label: _('Include zero'),
                option: 'showY2AxisWithZero',
                editor: 'editor.checkbox',
            },
        ],
        [
            {
                label: _('Minimum axis value'),
                option: 'y2AxisMin',
                editor: 'editor.number',
            },
            {
                label: _('Maximum axis value'),
                option: 'y2AxisMax',
                editor: 'editor.number',
            },
        ],
        [
            {
                label: _('Axis line visibility'),
                option: 'y2AxisLineVisibility',
                editor: 'editor.checkbox',
            },
        ],
        [
            {
                label: _('Major gridlines'),
                option: 'showY2MajorGridLines',
                editor: 'editor.checkbox',
            },
        ],
        [
            {
                label: _('Major tick visibility'),
                option: 'y2AxisMajorTickVisibility',
                editor: 'editor.radioBar',
                editorProps: {
                    values: [
                        { label: _('Auto'), value: 'auto' },
                        { label: _('Show'), value: 'show' },
                        { label: _('Hide'), value: 'hide' },
                    ],
                },
            },
        ],
        [
            {
                label: _('Major tick size (px)'),
                option: 'y2AxisMajorTickSize',
                editor: 'editor.slider',
                editorProps: {
                    min: 0,
                    max: 10,
                    step: 1,
                },
                showEditor: ({ options }) =>
                    options.y2AxisMajorTickVisibility === 'show' ||
                    options.y2AxisMajorTickVisibility === 'auto' ||
                    options.y2AxisMajorTickVisibility === undefined,
            },
        ],
        [
            {
                label: _('Major tick interval spacing'),
                option: 'y2AxisMajorTickInterval',
                editor: 'editor.number',
                showEditor: ({ options }) =>
                    options.y2AxisMajorTickVisibility === 'show' ||
                    options.y2AxisMajorTickVisibility === 'auto' ||
                    options.y2AxisMajorTickVisibility === undefined,
            },
        ],
        [
            {
                label: _('Minor gridlines'),
                option: 'showY2MinorGridLines',
                editor: 'editor.checkbox',
            },
        ],
        [
            {
                label: _('Minor tick visibility'),
                option: 'y2AxisMinorTickVisibility',
                editor: 'editor.radioBar',
                editorProps: {
                    values: [
                        { label: _('Auto'), value: 'auto' },
                        { label: _('Show'), value: 'show' },
                        { label: _('Hide'), value: 'hide' },
                    ],
                },
            },
        ],
        [
            {
                label: _('Minor tick size (px)'),
                option: 'y2AxisMinorTickSize',
                editor: 'editor.slider',
                editorProps: {
                    min: 0,
                    max: 10,
                    step: 1,
                },
                showEditor: ({ options }) =>
                    options.y2AxisMinorTickVisibility === 'show' ||
                    options.y2AxisMinorTickVisibility === 'auto' ||
                    options.y2AxisMinorTickVisibility === undefined,
            },
        ],
    ],
};

export default axesChartY2GridAndLabels;
