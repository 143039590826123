import * as React from 'react';
import { _ } from '@splunk/ui-utils/i18n';
import SVG from '@splunk/react-icons/SVGEnterprise';

const Scatter = (props: React.ComponentProps<typeof SVG>): React.ReactElement<SVGElement> => (
    <SVG screenReaderText={_('Scatter')} viewBox="0 0 20 18" {...props}>
        <path
            d="M10.8333 3.99996C11.7538 3.99996 12.5 3.25377 12.5 2.33329C12.5 1.41282 11.7538 0.666626 10.8333 0.666626C9.91286 0.666626 9.16667 1.41282 9.16667 2.33329C9.16667 3.25377 9.91286 3.99996 10.8333 3.99996Z"
            fill="currentColor"
        />
        <path
            d="M9.16667 8.99996C10.0871 8.99996 10.8333 8.25377 10.8333 7.33329C10.8333 6.41282 10.0871 5.66663 9.16667 5.66663C8.24619 5.66663 7.5 6.41282 7.5 7.33329C7.5 8.25377 8.24619 8.99996 9.16667 8.99996Z"
            fill="currentColor"
        />
        <path
            d="M13.3333 10.6666C13.3333 11.5871 12.5871 12.3333 11.6667 12.3333C10.7462 12.3333 10 11.5871 10 10.6666C10 9.74615 10.7462 8.99996 11.6667 8.99996C11.6667 8.07948 12.4129 7.33329 13.3333 7.33329C14.2538 7.33329 15 8.07948 15 8.99996C15 9.92043 14.2538 10.6666 13.3333 10.6666Z"
            fill="currentColor"
        />
        <path
            d="M10.8333 13.1666C10.8333 14.0871 10.0871 14.8333 9.16667 14.8333C8.24619 14.8333 7.5 14.0871 7.5 13.1666C7.5 12.2462 8.24619 11.5 9.16667 11.5C10.0871 11.5 10.8333 12.2462 10.8333 13.1666Z"
            fill="currentColor"
        />
        <path
            d="M5 14.8333C5 15.7538 4.25381 16.5 3.33333 16.5C2.41286 16.5 1.66667 15.7538 1.66667 14.8333C1.66667 13.9128 2.41286 13.1666 3.33333 13.1666C4.25381 13.1666 5 13.9128 5 14.8333Z"
            fill="currentColor"
        />
        <path
            d="M13.3333 17.3333C14.2538 17.3333 15 16.5871 15 15.6666C15 14.7462 14.2538 14 13.3333 14C12.4129 14 11.6667 14.7462 11.6667 15.6666C11.6667 16.5871 12.4129 17.3333 13.3333 17.3333Z"
            fill="currentColor"
        />
        <path
            d="M7.5 9.83329C7.5 10.7538 6.75381 11.5 5.83333 11.5C4.91286 11.5 4.16667 10.7538 4.16667 9.83329C4.16667 8.91282 4.91286 8.16663 5.83333 8.16663C6.75381 8.16663 7.5 8.91282 7.5 9.83329Z"
            fill="currentColor"
        />
        <path
            d="M20 12.3333C20 13.2538 19.2538 14 18.3333 14C17.4129 14 16.6667 13.2538 16.6667 12.3333C16.6667 11.4128 17.4129 10.6666 18.3333 10.6666C19.2538 10.6666 20 11.4128 20 12.3333Z"
            fill="currentColor"
        />
        <path
            d="M16.6667 8.16663C17.5871 8.16663 18.3333 7.42043 18.3333 6.49996C18.3333 5.57948 17.5871 4.83329 16.6667 4.83329C15.7462 4.83329 15 5.57948 15 6.49996C15 7.42043 15.7462 8.16663 16.6667 8.16663Z"
            fill="currentColor"
        />
        <path
            d="M1.66667 8.16663C2.58714 8.16663 3.33333 7.42043 3.33333 6.49996C3.33333 5.57948 2.58714 4.83329 1.66667 4.83329C0.746192 4.83329 0 5.57948 0 6.49996C0 7.42043 0.746192 8.16663 1.66667 8.16663Z"
            fill="currentColor"
        />
    </SVG>
);

export default Scatter;
