import React from 'react';
import styled from 'styled-components';
import Plus from '@splunk/react-icons/enterprise/Plus';
import { variables, pick } from '@splunk/themes';
import {
    getVerticalPosition,
    getHorizontalPosition,
} from '../utils/outlineUtils';

const portOffset = 8;
export const PORT_DIRECTIONS = ['n', 'e', 's', 'w'] as const;
export type PortDirection = typeof PORT_DIRECTIONS[number];

export interface PortProps {
    port: PortDirection;
    onMouseEnter: React.MouseEventHandler<HTMLDivElement>;
    onMouseLeave: React.MouseEventHandler<HTMLDivElement>;
}

const PortContainer = styled.div.attrs<PortProps>(({ port }) => ({
    'data-test': 'port',
    'data-test-port': port,
}))<PortProps & { children: React.ReactElement }>`
    text-align: center;
    width: 16px;
    height: 16px;
    line-height: 16px;
    box-sizing: border-box;
    border-radius: 50%;
    position: absolute;
    color: ${pick({
        enterprise: variables.accentColorL10,
        prisma: variables.interactiveColorPrimary,
    })};
    ${({ port }) => getVerticalPosition(port, portOffset)};
    ${({ port }) => getHorizontalPosition(port, portOffset)};
    z-index: 999;
    pointer-events: auto;
    user-select: none;
    &:hover {
        background-color: ${pick({
            enterprise: variables.accentColorL10,
            prisma: variables.interactiveColorPrimary,
        })};
    }
`;

export const Port = ({
    port,
    onMouseEnter,
    onMouseLeave,
}: PortProps): JSX.Element => (
    <PortContainer
        port={port}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
    >
        <Plus />
    </PortContainer>
);
