import * as React from 'react';
import { useCallback, useContext, SyntheticEvent } from 'react';

import Popover from '@splunk/react-ui/Popover';
import Markdown from '@splunk/react-ui/Markdown';
import FieldSummary, { FieldData, Action } from '@splunk/react-field-summary/components/FieldSummary';
import { noop } from 'lodash';
import { _ } from '@splunk/ui-utils/i18n';
import styled from 'styled-components';
import EventsContext from '@splunk/visualization-context/EventsContext';
import type { EventsContextInterface } from '@splunk/visualization-context/EventsContext';

export interface FieldSummaryTooltipProps {
    open: boolean;
    anchor?: HTMLElement;
    handleTooltipClose: () => void;
    fieldName: string;
    fieldSummaryMap: { [key: string]: FieldData };
    onActionClicked: (e: SyntheticEvent, data: { action: string; field: string; selected: string[] }) => void;
    onFieldValueClicked: (e: SyntheticEvent, { field, value }: { field: string; value: string }) => void;
}

const popoverContainerStyle: React.CSSProperties = { padding: '20px', width: 500 };

const StyledMarkdown = styled(Markdown)`
    /* removes unnecessary margin on the internal paragraph component */
    p {
        margin: 0;
    }
`;

const actionsForField = (field: FieldData): Action[] => {
    const actions = [];

    // this logic is used in determining whether to display numeric stats in ui-platform (FieldStats.tsx)
    if (typeof field.mean === 'number') {
        actions.push({ name: 'avgByTime', label: _('Average over time') });
        actions.push({ name: 'maxByTime', label: _('Max value over time') });
        actions.push({ name: 'minByTime', label: _('Min value over time') });
    }

    actions.push({ name: 'topValues', label: _('Top values') });
    actions.push({ name: 'topValuesByTime', label: _('Top values by time') });
    actions.push({ name: 'rareValues', label: _('Rare values') });
    actions.push({ name: 'allValues', label: _('Events with this field') });

    return actions;
};

const FieldSummaryTooltip = (props: FieldSummaryTooltipProps): React.ReactElement => {
    const {
        open,
        anchor,
        handleTooltipClose,
        fieldName,
        fieldSummaryMap,
        onActionClicked,
        onFieldValueClicked,
    } = props;

    const { isSplunkWebAvailable } = useContext<EventsContextInterface>(EventsContext);

    const getActionsForField = useCallback(
        (field: FieldData) => (isSplunkWebAvailable ? actionsForField(field) : []),
        [isSplunkWebAvailable]
    );

    // if the anchor is null or no field has been clicked, do not render the tooltip
    if (!open) return null;

    // retrieve the field summary for this field. if there is no field data, show fallback messaging
    const field = fieldSummaryMap[fieldName];
    const fieldSummaryExists = !!field;

    const fallbackText = _(
        `Please configure an additional datasource called \`fieldsummary\` using the \`... | fieldsummary maxvals=10\` SPL command for field summaries to be shown.`
    );

    return (
        <Popover
            open={open}
            defaultPlacement="below"
            anchor={anchor}
            takeFocus
            onRequestClose={handleTooltipClose}
        >
            <div style={popoverContainerStyle}>
                {fieldSummaryExists ? (
                    <FieldSummary
                        eventCount={field.count}
                        field={field}
                        actionsForField={getActionsForField}
                        onActionClicked={onActionClicked}
                        onFieldValueClicked={onFieldValueClicked}
                    />
                ) : (
                    <StyledMarkdown text={fallbackText} />
                )}
            </div>
        </Popover>
    );
};

FieldSummaryTooltip.defaultProps = {
    open: false,
    anchor: null,
    fieldName: '',
    fieldSummaryMap: {},
    handleTooltipClose: noop,
    onActionClicked: noop,
    onFieldValueClicked: noop,
};

export default FieldSummaryTooltip;
