import { DEFAULT_VALUE, DEFAULT_LATEST } from '../components/BaseTimeRange';

export const parseValue = (value: string, defaultValue: string) => {
    const v = value || defaultValue || DEFAULT_VALUE;
    const timeRanges = v.split(',');

    return {
        earliest: timeRanges[0].trim(),
        latest:
            timeRanges[1] && timeRanges[1].trim()
                ? timeRanges[1].trim()
                : DEFAULT_LATEST,
    };
};
