import * as React from 'react';
import { _ } from '@splunk/ui-utils/i18n';
import SVG from '@splunk/react-icons/SVGEnterprise';

const Events = (props: React.ComponentProps<typeof SVG>): React.ReactElement<SVGElement> => (
    <SVG screenReaderText={_('Events')} fill="currentColor" viewBox="0 0 150 100" {...props}>
        <g>
            <path d="M20.7071 63.7929C20.3166 63.4024 19.6834 63.4024 19.2929 63.7929C18.9024 64.1834 18.9024 64.8166 19.2929 65.2071L22.0858 68L19.2929 70.7929C18.9024 71.1834 18.9024 71.8166 19.2929 72.2071C19.6834 72.5976 20.3166 72.5976 20.7071 72.2071L23.8536 69.0607C24.4393 68.4749 24.4393 67.5251 23.8536 66.9393L20.7071 63.7929Z" />
            <path d="M19.2929 45.7929C19.6834 45.4024 20.3166 45.4024 20.7071 45.7929L23.8536 48.9393C24.4393 49.5251 24.4393 50.4749 23.8536 51.0607L20.7071 54.2071C20.3166 54.5976 19.6834 54.5976 19.2929 54.2071C18.9024 53.8166 18.9024 53.1834 19.2929 52.7929L22.0858 50L19.2929 47.2071C18.9024 46.8166 18.9024 46.1834 19.2929 45.7929Z" />
            <path d="M20.7071 27.7929C20.3166 27.4024 19.6834 27.4024 19.2929 27.7929C18.9024 28.1834 18.9024 28.8166 19.2929 29.2071L22.0858 32L19.2929 34.7929C18.9024 35.1834 18.9024 35.8166 19.2929 36.2071C19.6834 36.5976 20.3166 36.5976 20.7071 36.2071L23.8536 33.0607C24.4393 32.4749 24.4393 31.5251 23.8536 30.9393L20.7071 27.7929Z" />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10 23C10 22.4477 10.4477 22 11 22H139C139.552 22 140 22.4477 140 23C140 41 140 59 140 77C140 77.5523 139.552 78 139 78H11C10.4477 78 10 77.5523 10 77C10 59 10 41 10 23ZM60.2563 60H138V76H60.2563V60ZM33.1284 60H58.2563V76H33.1284V60ZM31.1284 60V76H12V60H31.1284ZM12 58H31.1284V42H12V58ZM33.1284 58H58.2563V42H33.1284V58ZM60.2563 58H138V42H60.2563V58ZM138 24V40H60.2563V24H138ZM58.2563 24V40H33.1284V24H58.2563ZM31.1284 24V40H12V24H31.1284Z"
            />
        </g>
    </SVG>
);

export default Events;
