import { parse as baseParse, mergeEncoding } from '@splunk/visualization-encoding-parsers/Base';
import isEmpty from 'lodash/isEmpty';
import { ErrorMsgs } from './errorMsgs';

export const dataContract = {
    requiredDataSources: [
        {
            name: 'primary',
            description: 'DataSource that powers the visualization',
        },
    ],
    optionalDataSources: [],
    initialRequestParams: {
        primary: { offset: 0, count: 10000 },
    },
    encoding: {
        value: {
            isRequired: true,
            type: 'number',
            default: 'primary[0][-1]',
        },
        gaugeColor: {
            isRequired: true,
            type: 'string',
            default: {
                field: 'encoding.value',
                format: {
                    type: 'rangevalue',
                    ranges: [
                        {
                            from: 70,
                            to: 100,
                            value: '#ff7152',
                        },
                        {
                            from: 50,
                            to: 70,
                            value: '#fc9850',
                        },
                        {
                            from: 30,
                            to: 50,
                            value: '#f4df7a',
                        },
                        {
                            from: 10,
                            to: 30,
                            value: '#4beba8',
                        },
                        {
                            from: 0,
                            to: 10,
                            value: '#5fbcff',
                        },
                    ],
                },
            },
        },
    },
};

/**
 * FillerGauge parse function
 * @param {object} dataSources
 * @param {object} userDefinedEncoding
 * @return {object} props that dashboard/react visualizations can be used.
 * @public
 */
export const parse = (dataSources, userDefinedEncoding) => {
    if (isEmpty(dataSources)) {
        throw new Error(ErrorMsgs.NO_DATA_SOURCES);
    }

    const encoding = mergeEncoding(dataContract.encoding, userDefinedEncoding);
    const parsedProps = baseParse(dataSources, encoding);
    Object.keys(encoding).forEach(encodingKey => {
        const encodingValue = parsedProps[encodingKey];
        const valueIndex = encodingValue.length - 1;
        parsedProps[encodingKey] = parsedProps[encodingKey][valueIndex];
    });
    const ranges = (encoding.gaugeColor.format && encoding.gaugeColor.format.ranges) || [];
    const min = ranges.length ? Math.min(...ranges.map(range => range.from)) : undefined;
    const max = ranges.length ? Math.max(...ranges.map(range => range.to)) : undefined;

    return {
        value: parseInt(parsedProps.value, 10),
        gaugeColor: parsedProps.gaugeColor,
        min,
        max,
        valueFieldName: parsedProps['_meta'].fieldNames.value, // eslint-disable-line dot-notation
        gaugeColorFieldName: parsedProps['_meta'].fieldNames.gaugeColor, // eslint-disable-line dot-notation
        valueType: parsedProps['_meta'].types.value, // eslint-disable-line dot-notation
        gaugeColorType: parsedProps['_meta'].types.gaugeColor, // eslint-disable-line dot-notation
    };
};
