/**
 * check if a url starts with http or https
 * @param {String} url
 */
export const isAbsoluteURL = (url: string): boolean => {
    return /^https?:\/\//.test(url);
};

/**
 * check if a url is a valid relative url
 * @param {String} url
 */
export const isRelativeURL = (url: string): boolean => {
    return !!url?.length && url[0] === '/' && !/^\/\//.test(url);
};

/**
 * check if a url is consider as valid
 * @param {String} url
 */
export const isValidUrl = (url: string): boolean => {
    return isAbsoluteURL(url) || isRelativeURL(url);
};

// A utility function to make it easier to mock in unit test
export const getWindowLocationSearch = (): string => window.location.search;

export const navigateToUrl = (url: string, newTab = false) => {
    if (newTab) {
        window.open(url, '_blank');
    } else {
        window.location.href = url;
    }
};
