import { DefaultContext } from '../interfaces/DefaultContext';

export const defaultContext: DefaultContext = {
    trendColorConfig: [
        {
            to: 0,
            value: '#9E2520',
        },
        {
            from: 0,
            value: '#1C6B2D',
        },
    ],
    majorColorConfig: [
        { to: 20, value: '#D41F1F' },
        { from: 20, to: 40, value: '#D94E17' },
        { from: 40, to: 60, value: '#CBA700' },
        { from: 60, to: 80, value: '#669922' },
        { from: 80, value: '#118832' },
    ],
    majorColorMatchConfig: [{ match: '', value: '#5C33FF' }],
};
