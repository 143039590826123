import { isUndefined } from 'lodash';
import { AbstractFormatter } from '../Formatter';
import { TypedValue, DataType } from '../DataPrimitive';
import { getDataTypeForPoint, isNumber } from '../utils/types';
import { setDefaultValue } from '../utils/formatterUtils';
import { DataPoint } from '../DataPoint';
import EncodingExecutor from '../EncodingExecutor';

export interface RangesConfig {
    from?: number;
    value: any;
    to?: number;
}

const { hasOwnProperty } = Object.prototype;

/**
 * Formatter function that takes the list of ranges and a defaultValue (if no range is found)
 * and returns a function that takes a value and returns the range value if found, or the defaultValue if present.
 *
 * If neither are present, it returns the original value.
 *
 * The range fitting follows this criteria: `range.from <= value < range.to`
 *
 * A range can be defined as either a closed bound range: `{ from: 10, to: 20, value: 'foo' }`
 * or an open bound range:
 * `{ to: 20, value: 'bar' }` (open lower bound)
 * `{ from: 100, value: 'oof' }` (open upper bound)
 *
 * ```js
 *   <SampleViz
 *      context={{
 *          colorThresholds: [
 *              {
 *                  from: 1321,
 *                  value: '#00FFFF',
 *              },
 *              {
 *                  to: 1321,
 *                  value: '#FF00FF',
 *              },
 *          ],
 *      }}
 *      options={{
 *          valueOption: '> primary | seriesByIndex(0) | lastPoint()', // returns 103
 *          colorOption: '> deltaValue | rangeValue(colorThresholds)', // returns '#FF00FF'
 *      }}
 *      dataSources={{
 *          primary: {
 *              data: {
 *                  columns: [
 *                      ['1', '103'], ['2018-08-19T00:00:00.000+00:00', '2018-08-20T00:00:00.000+00:00'],
 *                  ],
 *                  fields: [{ name: 'foo', }, { name: '_time' }],
 *              }
 *          },
 *      }}
 *  />
 * ```
 * @extends AbstractFormatter<DataType, DataType>
 */
export class RangeValue extends AbstractFormatter<DataType, DataType> {
    // although the typical usage is that the input is  number, this formatter DOES accept an DataType
    private ranges: RangesConfig[];

    private defaultValue: any;

    constructor(ranges: RangesConfig[], defaultValue?: any) {
        super();
        this.ranges = EncodingExecutor.rawTree(ranges); // insure we can handle ranges that had DSL expressions shoved into them
        this.defaultValue = setDefaultValue(defaultValue);
    }

    protected formatTypedValue(p: DataPoint<'number'>): TypedValue<DataType> {
        const { type, value } = p.getValue();
        if (type !== 'number') {
            console.warn(`type '${type}' with value '${value}' is not a valid input to rangeValue`);
        }
        let rangeValueResult;
        // if no range is found and valid value, return defaultValue if present, otherwise return the original value
        const defaultRangeValue =
            isUndefined(this.defaultValue) && !isNumber(value) ? value : this.defaultValue;
        if (isNumber(value)) {
            const floatValue = parseFloat(value);
            for (let i = 0; i < this.ranges.length; i += 1) {
                if (
                    // open upper bound: value is bigger than or equal to open upper bound start (from)
                    // if there are several 'from'-only ranges, we look at the first one that satisfies the mapping criteria
                    // if there is overlap between a 'from'-only range and an inbetween range, this means that the ranges config is semantically invalid
                    // TODO: figure out if we want to enforce a semantically valid config by sorting,
                    // or by throwing an error if there are multiple 'from'/'to'-only ranges
                    (hasOwnProperty.call(this.ranges[i], 'from') &&
                        !hasOwnProperty.call(this.ranges[i], 'to') &&
                        floatValue >= this.ranges[i].from) ||
                    // inbetween: value falls into from (inclusive) - to (exclusive) range
                    (floatValue >= this.ranges[i].from && floatValue < this.ranges[i].to) ||
                    (hasOwnProperty.call(this.ranges[i], 'to') &&
                        !hasOwnProperty.call(this.ranges[i], 'from') &&
                        floatValue < this.ranges[i].to)
                ) {
                    rangeValueResult = this.ranges[i].value;
                    break;
                }
            }
        }
        const updatedValue = isUndefined(rangeValueResult) ? defaultRangeValue : rangeValueResult;
        const updatedType = getDataTypeForPoint(updatedValue);
        return { type: updatedType, value: updatedValue };
    }
}
