import React, { createContext, useContext, useMemo, useRef } from 'react';
import { DashboardProfiler } from './DashboardProfiler';
import type { TelemetryAPI } from './TelemetryAPI';

export const DashboardProfilerContext = createContext<DashboardProfiler | null>(
    null
);

export const DashboardProfilerContextProvider = ({
    telemetryAPI,
    children,
}: {
    telemetryAPI: TelemetryAPI;
    children: React.ReactNode;
}): React.ReactElement => {
    const prevDashboardProfiler = useRef<DashboardProfiler | null>(null);

    const value = useMemo(() => {
        const newProfiler = new DashboardProfiler({
            telemetryAPI,
            importFromProfiler: prevDashboardProfiler.current,
        });

        prevDashboardProfiler.current = newProfiler;
        return newProfiler;
    }, [telemetryAPI]);

    return (
        <DashboardProfilerContext.Provider value={value}>
            {children}
        </DashboardProfilerContext.Provider>
    );
};

export const DashboardProfilerContextConsumer =
    DashboardProfilerContext.Consumer;

export const useDashboardProfiler: () => DashboardProfiler | null = () =>
    useContext(DashboardProfilerContext);

export default DashboardProfilerContext;
