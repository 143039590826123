import * as React from 'react';
import { useState, useEffect, useCallback, useMemo } from 'react';
import { get, pick } from 'lodash';
import styled from 'styled-components';
import Paginator from '@splunk/react-ui/Paginator';
import { _ } from '@splunk/ui-utils/i18n';
import { getSettingsFromThemedProps } from '@splunk/themes';
import useSplunkTheme from '@splunk/themes/useSplunkTheme';
import FeatureFlagContext from '@splunk/visualization-context/FeatureFlagContext';
import SizeAwareWrapper from '@splunk/visualizations-shared/SizeAwareWrapper';
import { toDimension } from '@splunk/visualizations-shared/style';
import Message from '@splunk/visualizations-shared/Message';
import {
    getTrellisDataSplitterMode,
    getAllPossibleSplitByFields,
} from '@splunk/visualizations-shared/trellisUtils';
import { deepMergeWithArrayPrimitiveOverrides } from '@splunk/visualizations-shared/hocUtils';
import { pickFieldFromJSONSchema } from '@splunk/visualizations-shared/JSONSchemaUtils';
import { Options } from '@splunk/visualization-encoding/Options';
import { DataSource } from '../interfaces/DataSource';
import { KV } from '../interfaces/Object';
import { AGGREGATIONS, getTrellisDataSourcesBySplitMode } from '../utils/trellisUtils';
import { LAYOUT_TYPE } from '../utils/layoutUtils';

const TrellisContainer = styled.div<{
    width: number | string;
    height: number | string;
    backgroundColor: string;
}>`
    overflow: hidden;
    display: flex;
    flex-direction: column;
    background-color: ${props => props.backgroundColor};
    ${props => toDimension(pick(props, ['width', 'height']))};
    > [data-test='paginator'] {
        align-self: flex-end;
        margin-bottom: 10px;
    }
`;

const TrellisContainerGrid = styled.div<{
    height: number;
    colCount: number;
    colMinWidth: number;
    rowHeight: number;
}>`
    display: grid;
    height: ${props => {
        return `${props.height}px`;
    }};
    gap: 10px 10px;
    grid-template-columns: ${props => {
        return props.colCount
            ? `repeat(${props.colCount}, minmax(${props.colMinWidth}px, 1fr))`
            : `repeat(auto-fit, minmax(${props.colMinWidth}px, 1fr))`;
    }};
    overflow: auto;
    justify-items: stretch;
    grid-auto-rows: ${props => `${props.rowHeight}px`};
    .child {
        height: ${props => `${props.rowHeight}px`};
    }
`;

const showErrorMessage = (
    errorCode: string,
    width: string | number,
    height: string | number
): JSX.Element => {
    if (errorCode === 'INVALID_SPLIT_BY_FIELD') {
        return (
            <Message
                width={width}
                height={height}
                message={_('Select a valid trellis split by field')}
                level="info"
            />
        );
    }
    if (errorCode === 'UNKNOWN_DATA_SPLIT_MODE') {
        return <Message width={width} height={height} message={_('Unknown data split mode')} level="info" />;
    }
    return <Message width={width} height={height} message={_('Error render in trellis')} level="info" />;
};

interface VizWithTrellisProps {
    options?: KV;
    dataSources: { [name: string]: DataSource };
    height: number | string;
    width: number | string;
    theme?: unknown;
}

const withTrellis = Viz => {
    const VizWithTrellis = (opts: VizWithTrellisProps): JSX.Element => {
        const { options = {}, height, width, ...otherProps } = opts;
        const defaultOptions = pickFieldFromJSONSchema(Viz.config.optionsSchema, 'default');
        const mergedOptions = deepMergeWithArrayPrimitiveOverrides({}, options, defaultOptions);
        const {
            trellisColumns,
            trellisPageCount,
            trellisMinColumnWidth,
            trellisRowHeight,
            splitByLayout,
            trellisSplitBy,
        } = mergedOptions;
        const colCount = trellisColumns > 0 ? trellisColumns : defaultOptions.trellisColumns;
        const pageCount = +trellisPageCount > 0 ? +trellisPageCount : defaultOptions.trellisPageCount;
        const colMinWidth =
            trellisMinColumnWidth > 0 ? trellisMinColumnWidth : defaultOptions.trellisMinColumnWidth;
        const rowHeight = trellisRowHeight > 0 ? trellisRowHeight : defaultOptions.trellisRowHeight;
        const [pageNum, setPageNum] = useState(1);
        const [pagedData, setPagedData] = useState(null);
        let splitByKeys = [];
        let totalPages = 0;
        let showPaginator = false;
        const { dataSources } = otherProps;
        const theme = {
            splunkThemeV1: useSplunkTheme(),
        };
        const {
            family: currentThemeFamily,
            colorScheme: currentThemeColorScheme,
            density: currentThemeDensity,
        } = getSettingsFromThemedProps({ theme });
        const trellisBackgroundColor = useMemo(() => {
            const themeFunc = themeVar =>
                Viz.config.themes[themeVar]?.({
                    trellisBackgroundColor: mergedOptions.trellisBackgroundColor,
                    theme,
                });
            // trellisBackgroundColor option supports only static coloring and DSL option evaluation derived from the themes level only.
            const evaluated = Options.evaluate(
                {
                    context: Viz.config.context,
                    options: { trellisBackgroundColor: mergedOptions.trellisBackgroundColor },
                },
                {},
                themeFunc
            );

            return evaluated.trellisBackgroundColor;
        }, [
            mergedOptions.trellisBackgroundColor,
            currentThemeFamily,
            currentThemeColorScheme,
            currentThemeDensity,
        ]);
        const columns = get(dataSources, 'primary.data.columns', undefined);
        const fields = get(dataSources, 'primary.data.fields', undefined);
        const noColumns = columns === undefined;
        const noFields = fields === undefined;
        const updateDataDisplay = currPage => {
            if (!showPaginator) {
                setPagedData(splitByKeys);
                setPageNum(1);
            } else {
                const startIdx = pageCount * (currPage - 1);
                const paged = splitByKeys.slice(startIdx, startIdx + pageCount);
                setPagedData(paged);
            }
        };
        useEffect(() => {
            setPageNum(1);
            updateDataDisplay(1);
        }, [pageCount, trellisSplitBy]);

        useEffect(() => {
            updateDataDisplay(pageNum);
        }, [columns, fields, trellisSplitBy, pageNum, splitByLayout]);

        const handlePageChange = useCallback(
            (event, { page }) => {
                setPageNum(page);
            },
            [pageNum, pagedData]
        );

        const featureFlagContext = React.useContext<FeatureFlagContext>(FeatureFlagContext);
        const isTrellisFeatureFlagOn = Boolean(
            featureFlagContext && featureFlagContext.visualizations_enableTrellis
        );

        if (!isTrellisFeatureFlagOn || !splitByLayout || splitByLayout !== LAYOUT_TYPE.Trellis) {
            return <Viz {...opts} />;
        }

        if (splitByLayout === LAYOUT_TYPE.Trellis) {
            if (noColumns || noFields) {
                return <Message width={width} height={height} message={_('Loading')} level="info" />;
            }

            // If no trellisSplitBy value is passed in, assign default trellisSplitBy
            // The default value will be the first value in the individual splitBy field list or 'aggregations'
            let currTrellisSplitBy = trellisSplitBy;
            if (!trellisSplitBy || trellisSplitBy.length === 0) {
                const splitByPossibleFields = getAllPossibleSplitByFields(fields);
                currTrellisSplitBy =
                    splitByPossibleFields.fields && splitByPossibleFields.fields.length
                        ? splitByPossibleFields.fields[0]
                        : AGGREGATIONS;
            }
            const splitMode = getTrellisDataSplitterMode(fields);
            const dataSplitResult = getTrellisDataSourcesBySplitMode(
                splitMode,
                dataSources,
                currTrellisSplitBy
            );
            const modifiedDataSourceBySplitBy = dataSplitResult?.splitDataSources;
            const dataSplitErrorCode = dataSplitResult?.errorCode;
            if (
                !modifiedDataSourceBySplitBy ||
                Object.keys(modifiedDataSourceBySplitBy).length === 0 ||
                dataSplitErrorCode
            ) {
                return showErrorMessage(dataSplitErrorCode, width, height);
            }

            const splitByKeysModified = modifiedDataSourceBySplitBy
                ? Object.keys(modifiedDataSourceBySplitBy)
                : [];
            if (splitByKeysModified) {
                showPaginator = splitByKeysModified.length > pageCount;
                splitByKeys = splitByKeysModified;
                totalPages = splitByKeysModified ? Math.ceil(splitByKeysModified.length / pageCount) : 0;
            }
            return (
                <TrellisContainer height={height} width={width} backgroundColor={trellisBackgroundColor}>
                    <TrellisContainerGrid
                        height={height as number}
                        colCount={colCount}
                        colMinWidth={colMinWidth}
                        rowHeight={rowHeight}
                    >
                        {pagedData &&
                            pagedData.map((splitByKey, idx) => (
                                <div
                                    className="child"
                                    data-test="trellis-child"
                                    key={`div-child-${idx.toString()}`}
                                >
                                    <SizeAwareWrapper>
                                        {({ childWidth, childHeight = rowHeight }) => {
                                            return (
                                                <Viz
                                                    {...otherProps}
                                                    trellisKey={splitByKey}
                                                    trellisSplitBy={currTrellisSplitBy}
                                                    dataSources={modifiedDataSourceBySplitBy[splitByKey]}
                                                    options={options}
                                                    key={`viz-${idx.toString()}`}
                                                    height={childHeight}
                                                    width={childWidth}
                                                />
                                            );
                                        }}
                                    </SizeAwareWrapper>
                                </div>
                            ))}
                    </TrellisContainerGrid>
                    {showPaginator && totalPages >= pageNum && (
                        <Paginator
                            onChange={handlePageChange}
                            current={pageNum}
                            alwaysShowLastPageLink
                            totalPages={totalPages}
                        />
                    )}
                </TrellisContainer>
            );
        }
        return null;
    };
    VizWithTrellis.propTypes = Viz.proptypes;
    VizWithTrellis.defaultProps = Viz.defaultProps;
    VizWithTrellis.config = Viz.config;
    return VizWithTrellis;
};

export default withTrellis;
