import React, { Component } from 'react';
import styled from 'styled-components';
import SUIMessage from '@splunk/react-ui/Message';
import { variables } from '@splunk/themes';
import { _ } from '@splunk/ui-utils/i18n';

type BoundaryProps = { children: JSX.Element };
type BoundaryState = { hasError: boolean };

// Exported for testing
export const DashboardContextError = _(
    'An internal error occurred. Contact your Splunk Administrator.'
);

// Ideally this works (the application page structure has a height defined in the tree)
const ErrorPage = styled.div`
    width: 100%;
    height: 100%;
    background-color: ${variables.backgroundColorPage};
`;

// In case the `ErrorPage` fails to have a height, also set the background color of the
// text block so the text contrast with the page won't cause an impossible-to-read message
const ErrorContainer = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 15px;
    background-color: ${variables.backgroundColorPage};
`;

const DashboardContextErrorMessage: JSX.Element = (
    <ErrorPage>
        <ErrorContainer data-test="dashboard-context-error">
            <SUIMessage type="error">{DashboardContextError}</SUIMessage>
        </ErrorContainer>
    </ErrorPage>
);

/**
 * Basic error boundary to prevent full page crashes when an error is thrown
 * in the DashboardContext tree
 */
export default class extends Component<BoundaryProps, BoundaryState> {
    constructor(props: { children: JSX.Element }) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error: Error | string) {
        return {
            hasError: true,
            errorMessage: typeof error === 'string' ? error : error?.message,
        };
    }

    render() {
        return this.state.hasError
            ? DashboardContextErrorMessage
            : this.props.children;
    }
}
