import React from 'react';
import T from 'prop-types';
import styled from 'styled-components';
import { mixins, pick, variables } from '@splunk/themes';

const SelectDiv = styled.div.attrs(({ x, y, w, h }) => ({
    style: {
        width: w,
        height: h,
        transform: `translate(${x}px, ${y}px)`,
    },
}))`
    ${mixins.reset('block')};
    position: absolute;
    background: transparent;
    // the next line is important to avoid blocking the drilldowns, and it allows to select text from visualizations
    pointer-events: none;
    border: 1px solid
        ${pick({
            enterprise: variables.accentColorL10,
            prisma: variables.interactiveColorPrimary,
        })};
    z-index: 1000;
`;

const SelectBox = ({ start, end }) => (
    <SelectDiv
        w={Math.abs(start.x - end.x)}
        h={Math.abs(start.y - end.y)}
        x={Math.min(start.x, end.x)}
        y={Math.min(start.y, end.y)}
    />
);

SelectBox.propTypes = {
    /**
     * starting position
     */
    start: T.shape({
        x: T.number,
        y: T.number,
    }),
    /**
     * end position
     */
    end: T.shape({
        x: T.number,
        y: T.number,
    }),
};

export default SelectBox;
