import { groupBy } from 'lodash';
import { AddChartDropdown } from '@splunk/dashboard-icons';
import type { PresetMap } from '@splunk/dashboard-types';
import { vizCategories } from '@splunk/dashboard-context';

interface Visualization {
    type?: string;
    label?: string;
    icon: React.ReactNode;
    initialOptions: unknown;
    category?: string;
    includeInVizSwitcher?: boolean;
    includeInToolbar?: boolean;
}

interface Category {
    category: string;
    visualizations: Visualization[];
}

export type Taxonomy = Category[];

const isMatchedCategory = (cat: string, matcher: string) =>
    new RegExp(matcher, 'i').test(cat);

const filterVisualizations = (visualizations: Visualization[], label: string) =>
    visualizations.filter(
        ({ label: l }) => l && new RegExp(label, 'i').test(l)
    );

/**
 * Helper method to filter the category list
 * @method filterByCategory
 * @param {Array} categories List of categories
 * @param {String} category Portion of category name to search for
 * @returns Array
 * @private
 */
const filterByCategory = (categories: Taxonomy, category: string): Taxonomy => {
    return categories.filter(({ category: cat }) =>
        isMatchedCategory(cat, category)
    );
};

/**
 * Helper method to filter items from category lists
 * @method filterByLabel
 * @param {Array} categories List of categories
 * @param {String} label Portion of viz label to search for
 * @returns Array
 */
const filterByLabel = (categories: Taxonomy, label: string) => {
    const filteredCategories: Taxonomy = [];

    categories.forEach(({ category: cat, visualizations }) => {
        const list = visualizations.filter(
            ({ label: l }) => l && new RegExp(label, 'i').test(l)
        );

        if (list.length) {
            filteredCategories.push({ category: cat, visualizations: list });
        }
    });

    return filteredCategories;
};

/**
 * Maps visualization metadata to a taxonomy
 * @class VisualizationFilter
 */
class VisualizationFilter {
    taxonomy: Taxonomy;

    /**
     * @constructor
     * @param {Object} preset Hash of dashboard presets
     * @param {Array} [taxonomy] List of category -> visualizationId mapping [{ category: Foo, visualizations: ['viz.foo'] }]
     */
    constructor(preset?: PresetMap) {
        if (!preset || !preset.visualizations) {
            throw new Error('Must provide a valid preset');
        }

        const categoryValues = [...vizCategories.values()];

        this.taxonomy = Object.entries(
            groupBy(
                Object.values(preset.visualizations).map(
                    ({
                        config: { category, key, icon, name } = {},
                        meta,
                        includeInVizSwitcher,
                        includeInToolbar,
                    }): Visualization => ({
                        type: key,
                        label: name,
                        icon: icon ?? AddChartDropdown,
                        initialOptions: meta?.initialOptions ?? {},
                        category,
                        includeInVizSwitcher,
                        includeInToolbar,
                    })
                ),
                'category'
            )
        )
            .map(
                ([category, visualizations]): Category => ({
                    category,
                    visualizations,
                })
            )
            // sorting by category index
            .sort(
                (catA, catB) =>
                    categoryValues.indexOf(catA.category) -
                    categoryValues.indexOf(catB.category)
            );
    }

    /**
     * Retrieve a filtered taxonomy of visualization metadata
     * @method getVisualizationList
     * @param {Object} [searchCriteria] Filter { label, category}
     * @returns Array of category metadata
     */
    getVisualizationList({
        label,
        category,
    }: { label?: string; category?: string } = {}) {
        if (!label && !category) {
            return this.taxonomy;
        }
        if (category && !label) {
            return filterByCategory(this.taxonomy, category);
        }
        if (label && !category) {
            return filterByLabel(this.taxonomy, label);
        }

        const result: Taxonomy = [];

        this.taxonomy.forEach(({ category: cat, visualizations }) => {
            if (isMatchedCategory(cat, category as string)) {
                result.push({ category: cat, visualizations });
            } else {
                const filteredViz = filterVisualizations(
                    visualizations,
                    label as string
                );

                if (filteredViz.length) {
                    result.push({ category: cat, visualizations: filteredViz });
                }
            }
        });
        return result;
    }
}

export default VisualizationFilter;
