import { DataFrame } from '../DataFrame';
import { DataSeries } from '../DataSeries';
import { DataPoint } from '../DataPoint';
import { IDimension } from '../Dimensions';
import EncodingExecutor from '../EncodingExecutor';

export class Helper {
    static isRawDataFrame(d: any): boolean {
        return Array.isArray(d) && Helper.isRawDataSeries(d[0]);
    }

    static isRawDataSeries(d: any): boolean {
        return Array.isArray(d) && !EncodingExecutor.isObject(d[0]);
    }

    static isRawDataPoint(d: any): boolean {
        return !EncodingExecutor.isObject(d);
    }

    static dataPrimitiveFromRaw(d: any): IDimension {
        if (Helper.isRawDataFrame(d)) {
            return DataFrame.fromRaw(d);
        }
        if (Helper.isRawDataSeries(d)) {
            return DataSeries.fromRaw(d);
        }
        if (Helper.isRawDataPoint(d)) {
            return DataPoint.fromRaw(d);
        }
        throw new Error(`raw value does not resemble DataFrame, DataSeries, or DataPoint`);
    }
}
