import React from 'react';
import T from 'prop-types';
import Charting from '@splunk/react-visualizations/common/Charting';
import { isMultiSeriesData, toArrayForSeries } from '@splunk/react-visualizations/utils/seriesUtils';
import jsCharting from '@splunk/charting-bundle';
import omit from 'lodash/omit';
import isArray from 'lodash/isArray';
import isEmpty from 'lodash/isEmpty';
import { getSimpleXMLOptionsForProps } from '@splunk/visualizations-shared/simpleXMLOptions';
import { convertPropsToString } from '@splunk/visualizations-shared/propUtils';
import { withSanitizedProps } from '@splunk/visualizations-shared/SanitizeProps';
import {
    baseChartPropTypes,
    baseChartDefaultProps,
    xyChartPropTypes,
    xyChartDefaultProps,
    multiYChartProps,
    multiYChartDefaultProps,
    dataChartProps,
    dataChartDefaultProps,
} from '../../common/props';

const Column = ({ ...props }) => {
    const {
        width,
        height,
        style,
        themeKey,
        x,
        xFieldName,
        y,
        yFieldName,
        y2,
        y2FieldName,
        annotationX,
        annotationLabel,
        annotationColor,
        onPointClick,
        onPointMouseOver,
        onPointMouseOut,
        onLegendClick,
        onRangeSelect,
        colorPalette,
        columnGrouping,
        stackMode,
        visualizationApiRef,
    } = props;

    // when columnGrouping is set to overlay also set the columnFillOpacity
    // this overwrites columnFillOpacity defaultProp value but will be removed
    // once we decide to expose columnFillOpacity.
    // stackMode has precedence over columnGrouping. only apply when stackMode=default
    const columnGroupingOptions =
        columnGrouping === 'overlay' && stackMode === 'default'
            ? {
                  columnGrouping: 'overlay',
                  columnFillOpacity: 0.3,
              }
            : {};

    const chartOptions = {
        chart: 'column',
        ...getSimpleXMLOptionsForProps(
            omit(props, [
                'width',
                'height',
                'style',
                'x',
                'y',
                'y2',
                'annotationX',
                'annotationLabel',
                'annotationColor',
                'xFieldName',
                'yFieldName',
                'y2FieldName',
                'colorPalette',
                'onPointClick',
                'onPointMouseOver',
                'onPointMouseOut',
                'onLegendClick',
            ])
        ),
        ...columnGroupingOptions,
    };

    // splunk charting expects certain options like 'seriesColors', 'fieldColors' etc to be a string
    const convertedChartOptions = convertPropsToString({
        vizProps: chartOptions,
        propNames: [
            'seriesColors',
            'data.fieldShowList',
            'data.fieldHideList',
            'legend.labels',
            'fieldColors',
        ],
    });

    // copy convertedChartOptions into chartOptions
    Object.assign(chartOptions, convertedChartOptions);

    const primaryData = {};

    primaryData.fields = [
        { name: xFieldName },
        // add all y field names
        ...toArrayForSeries(y, yFieldName).map(name => ({ name })),
    ];
    primaryData.columns = [x, ...toArrayForSeries(y, y)];

    if (y2 && isArray(y2) && y2FieldName) {
        chartOptions['axisY2.enabled'] = true;
        chartOptions['axisY2.fields'] = isMultiSeriesData(y2) ? y2FieldName.join(',') : y2FieldName;
        primaryData.fields = [
            ...primaryData.fields,
            ...toArrayForSeries(y2, y2FieldName).map(name => ({ name })),
        ];
        primaryData.columns = [...primaryData.columns, ...toArrayForSeries(y2, y2)];
    }

    const chartingAnnotations =
        annotationX && annotationX.length
            ? annotationX.map((timestamp, i) => ({
                  color: annotationColor && annotationColor[i],
                  label: annotationLabel && annotationLabel[i],
                  time: timestamp,
              }))
            : [];

    const chartData = !isEmpty(primaryData)
        ? jsCharting.extractChartReadyData({
              ...primaryData,
              annotations: chartingAnnotations,
          })
        : null;

    const onClick = ({ type, originalEvent, payload }) => {
        if (type === 'point.click') {
            onPointClick({ type, originalEvent, payload });
        }

        if (type === 'legend.click') {
            onLegendClick({ type, originalEvent, payload });
        }
    };

    return (
        <Charting
            width={width}
            height={height}
            style={style}
            themeKey={themeKey}
            chartData={chartData}
            options={chartOptions}
            onClick={onClick}
            onPointMouseOver={onPointMouseOver}
            onPointMouseOut={onPointMouseOut}
            onSelect={onRangeSelect}
            colorPalette={colorPalette}
            visualizationApiRef={visualizationApiRef}
        />
    );
};

Column.propTypes = {
    themeKey: T.string,
    ...baseChartPropTypes,
    ...xyChartPropTypes,
    ...multiYChartProps,
    ...dataChartProps,
    // column specific props
    annotationX: T.arrayOf(T.string),
    annotationLabel: T.arrayOf(T.string),
    annotationColor: T.arrayOf(T.string),
    xAxisMajorLabelRotation: T.oneOf([-90, -45, 0, 90, 45]),
    showMajorXGridLines: T.bool,
    splitSeries: T.bool,
    allowIndependentYRanges: T.bool,
    showDataLabels: T.oneOf(['none', 'all', 'minmax']),
    stackMode: T.oneOf(['default', 'stacked', 'stacked100']),
    columnSpacing: T.number,
    seriesSpacing: T.number,
    columnGrouping: T.oneOf(['default', 'overlay']), // Following props are internal.
    columnFillOpacity: T.number,
    lineWidth: T.number,
    // temporarily column specific props
    // @TODO(pwied): move to common/props when all viz should support
    showTooltip: T.bool,
    onPointMouseOver: T.func,
    onPointMouseOut: T.func,
    visualizationApiRef: T.func,
};

Column.defaultProps = {
    themeKey: 'prismaDark',
    ...baseChartDefaultProps,
    ...xyChartDefaultProps,
    ...multiYChartDefaultProps,
    ...dataChartDefaultProps,
    xAxisMajorLabelRotation: 0,
    showMajorXGridLines: false,
    splitSeries: false,
    allowIndependentYRanges: false,
    showDataLabels: 'none',
    stackMode: 'default',
    columnGrouping: 'default', // Following props are internal.
    columnFillOpacity: 1,
    lineWidth: 2,
    // temporarily column specific props
    // @TODO(pwied): move to common/props when all viz should support
    showTooltip: true,
    onPointMouseOver: () => {},
    onPointMouseOut: () => {},
    visualizationApiRef: () => {},
};

export default withSanitizedProps(Column);
