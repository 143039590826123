import React, { forwardRef, useCallback, useState, useMemo } from 'react';
import styled from 'styled-components';

import { _ } from '@splunk/ui-utils/i18n';
import TriangleRight from '@splunk/react-icons/enterprise/TriangleRightSmall';
import TriangleDown from '@splunk/react-icons/enterprise/TriangleDownSmall';
import { variables } from '@splunk/themes';

import type {
    DashboardApi,
    DashboardJSON,
    Mode,
} from '@splunk/dashboard-types';

import Title, { LINE_HEIGHT } from './header/Title';
import Description from './header/Description';
import { useTextInput } from './hooks/useTextInput';

import { customThemeVariables } from './customThemeVariables';

const HeaderContainer = styled.div`
    padding: 8px 8px 0px 8px;
    background-color: ${customThemeVariables.dashboardBackgroundColor};
    box-sizing: border-box;
`;

const TitleContainer = styled.div`
    display: flex;
    align-items: flex-start;
    min-height: ${LINE_HEIGHT};
    box-sizing: inherit;
`;

const TriangleContainer = styled.div`
    display: flex;
    align-items: center;
    height: ${LINE_HEIGHT};
    box-sizing: inherit;
    color: ${variables.contentColorDefault};
`;

interface TriangleProps {
    isExpanded: boolean;
    onClick: () => void;
}

const Triangle = ({ isExpanded, onClick }: TriangleProps): JSX.Element => {
    return (
        <TriangleContainer>
            {isExpanded ? (
                <TriangleDown
                    data-test="triangle-header-expanded"
                    onClick={onClick}
                    screenReaderText={_('Show less')}
                />
            ) : (
                <TriangleRight
                    data-test="triangle-header-collapsed"
                    onClick={onClick}
                    screenReaderText={_('Show more')}
                />
            )}
        </TriangleContainer>
    );
};

export interface HeaderProps {
    definition: DashboardJSON;
    mode: Mode;
    dashboardApi: DashboardApi;
}

const DashboardHeader = forwardRef<HTMLDivElement, HeaderProps>(
    ({ definition, mode, dashboardApi }, ref) => {
        const [isExpanded, setIsExpanded] = useState(true);

        const handleDefinitionChange = useCallback(
            (def) => {
                dashboardApi?.updateDefinition(def);
            },
            [dashboardApi]
        );

        const handleExpandToggle = useCallback(() => {
            setIsExpanded((isExpandedVal) => !isExpandedVal);
        }, []);

        const handleTitleCommit = useCallback(
            (val) =>
                handleDefinitionChange({
                    ...definition,
                    title: val,
                }),
            [handleDefinitionChange, definition]
        );

        const {
            onHandleBlur: onHandleBlurTitle,
            onHandleChange: onHandleChangeTitle,
            onHandleKeyDown: onHandleKeyDownTitle,
            value: valueTitle,
        } = useTextInput({
            initialValue: definition.title,
            handleCommit: handleTitleCommit,
            uniqKey: definition.title || '',
        });

        const handleDescCommit = useCallback(
            (val) =>
                handleDefinitionChange({
                    ...definition,
                    description: val,
                }),
            [handleDefinitionChange, definition]
        );

        const {
            onHandleBlur: onHandleBlurDesc,
            onHandleChange: onHandleChangeDesc,
            onHandleKeyDown: onHandleKeyDownDesc,
            value: valueDesc,
        } = useTextInput({
            initialValue: definition.description,
            handleCommit: handleDescCommit,
            uniqKey: definition.description || '',
        });

        const renderTriangle = useMemo(() => {
            if (mode === 'edit') {
                return null;
            }
            return (
                <Triangle
                    isExpanded={isExpanded}
                    onClick={handleExpandToggle}
                />
            );
        }, [mode, isExpanded, handleExpandToggle]);

        if (!valueTitle && !valueDesc && mode === 'view') {
            // In this scenario, do not render this component at all as we want to maximize canvas space.
            return null;
        }

        return (
            <HeaderContainer data-test="dashboard-header" ref={ref}>
                {mode === 'view' && !valueTitle ? null : (
                    <TitleContainer>
                        <Title
                            handleBlur={onHandleBlurTitle}
                            handleChange={onHandleChangeTitle}
                            handleKeyDown={onHandleKeyDownTitle}
                            isExpanded={isExpanded}
                            mode={mode}
                            value={valueTitle}
                        />
                        {mode === 'view' && valueDesc && renderTriangle}
                    </TitleContainer>
                )}
                {mode === 'view' && !valueDesc ? null : (
                    <Description
                        handleBlur={onHandleBlurDesc}
                        handleChange={onHandleChangeDesc}
                        handleKeyDown={onHandleKeyDownDesc}
                        showInViewMode={isExpanded}
                        mode={mode}
                        value={valueDesc}
                    />
                )}
            </HeaderContainer>
        );
    }
);

export default DashboardHeader;
