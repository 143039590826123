import React from 'react';
import {
    usePopoverState,
    EnterprisePopoverContentFactory,
    PopoverStatusIcon,
    getIconStatus,
} from '@splunk/dashboard-ui';
import type { DataSourceEventPayload } from '@splunk/dashboard-types';

interface InputStatusIconProps {
    dataSource?: DataSourceEventPayload;
    validationMessage?: string;
    componentId: string;
}

export const InputStatusIcon = ({
    dataSource,
    validationMessage,
    componentId,
}: InputStatusIconProps): JSX.Element | null => {
    const { open, onRequestOpen, onRequestClose } = usePopoverState();
    let iconStatus = getIconStatus(dataSource);
    if (validationMessage) {
        iconStatus = 'error';
    }

    if (!iconStatus) {
        return null;
    }

    return (
        <PopoverStatusIcon
            height={20}
            iconSize={13}
            iconSizeOnHover={15}
            open={open}
            onRequestOpen={onRequestOpen}
            onRequestClose={onRequestClose}
            status={iconStatus}
        >
            <EnterprisePopoverContentFactory
                dataSource={dataSource}
                componentId={componentId}
                onRequestClose={onRequestClose}
                message={validationMessage}
                type="input"
            />
        </PopoverStatusIcon>
    );
};
