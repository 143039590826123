import * as React from 'react';
import * as T from 'prop-types';
import { get } from 'lodash';
import { _ } from '@splunk/ui-utils/i18n';
import Message from '@splunk/visualizations-shared/Message';
import { DataSource } from '../interfaces/DataSource';
import withFixedSizeContainer from '../hocs/FixedSizeContainer';

/**
 * @method shouldShowIconPlaceholder
 * @param {object} dataSources
 * @param {boolean} loading
 * @returns {boolean}
 */
export const shouldShowIconPlaceholder = (
    dataSources: { [name: string]: DataSource },
    loading = false
): boolean => {
    const data = get(dataSources, 'primary.data.columns[0]', undefined);
    const noData = data === undefined;
    const fields = get(dataSources, 'primary.data.fields[0]', undefined);
    const noFields = fields === undefined;
    return loading || noData || noFields;
};

export interface IconPlaceholderProps {
    icon?: React.ComponentClass<any>;
    width?: string | number;
    height?: string | number;
    backgroundColor?: string;
    iconColor?: string;
}

const propTypes: Record<keyof IconPlaceholderProps, T.Validator<any>> = {
    icon: T.func,
    width: T.oneOfType([T.string, T.number]),
    height: T.oneOfType([T.string, T.number]),
    // eslint-disable-next-line react/no-unused-prop-types
    backgroundColor: T.string,
    iconColor: T.string,
};

/**
 * @method IconPlaceholder
 * @param {IconPlaceholderProps} props
 * @returns {React.ReactElement}
 */
const IconPlaceholder = (props: IconPlaceholderProps): React.ReactElement => {
    const { width, height, icon, iconColor } = props;
    if (icon) {
        return React.createElement(icon, {
            style: {
                left: '50%',
                top: '50%',
                color: iconColor,
                opacity: 0.15,
                position: 'absolute',
                transform: 'translate(-50%, -50%)',
            },
            width: '50%',
            height: '50%',
        });
    }

    return <Message width={width} height={height} level="warning" message={_('No DataSource Connected')} />;
};

IconPlaceholder.propTypes = propTypes;
IconPlaceholder.defaultProps = {
    width: '100%',
    height: 250,
};

export default withFixedSizeContainer(IconPlaceholder);
