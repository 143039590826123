import { COLOR_OR_TOKEN_PATTERN, getPattern } from '@splunk/visualizations-shared/schemaUtils';

export default {
    backgroundColor: {
        description: 'Specify the Hex code for color used for a background. For example: "#0000FF".',
        pattern: COLOR_OR_TOKEN_PATTERN,
        type: 'string',
    },
    fontColor: {
        description:
            'Specify the Hex code for color used for a fonts (axis labels, legends). For example: "#0000FF".',
        pattern: COLOR_OR_TOKEN_PATTERN,
        type: 'string',
    },
    foregroundColor: {
        description: 'Specify the Hex code for color used for a foreground. For example, "#0000FF".',
        pattern: COLOR_OR_TOKEN_PATTERN,
        type: 'string',
    },
    fieldColors: {
        description: `Specify the Hex code for color used for a field. For example: {'count': '#008000', 'percent': '#FFA500'}. Note: Option type 'string' has been deprecated.`,
        type: ['object', 'string'],
    },
    seriesColors: {
        description: `Specify the Hex code for colors used for a series. For example, ['#FF0000', '#0000FF', '#008000'].
            The default for light and dark mode is ['#1E93C6', '#F2B827', '#D6563C', '#6A5C9E', '#31A35F'].
            The default for SCP mode is ['#A9F5E7', '#A870EF',  '#1E6271', '#45D4BA', '#602CA1',  '#9AE6F7', '#6CD0F0', '#2F8AA7',  '#422879', '#F29BAC', '#E85B79', '#C6335F',  '#FDAF93', '#FB865C', '#E9643A',  '#7EEFDA', '#26AA92', '#207865', '#C093F9', '#8747DA',  '#4EB2D2', '#912344',  '#AD3F20', '#D44C20', '#FFC34E',  '#95651D',  '#C0891E', '#ECAE11', '#FCD9A4', '#78062A'].
            Note: Option type 'string' has been deprecated.`,
        type: ['array', 'string'],
        items: { type: 'string' },
    },
    'chart.sliceCollapsingLabel': {
        default: 'other',
        description: 'Specify the label for the consolidated slice.',
        type: 'string',
    },
    'chart.sliceCollapsingThreshold': {
        default: 0.01,
        description:
            'Specify the size threshold as a number between 0 and 1 (inclusive), of the whole pie at which slices collapse into one consolidated slice.',
        type: 'number',
    },
    'chart.resultTruncationLimit': {
        default: 50000,
        description: 'Specify a limit for the number of data points rendered in a chart.',
        type: 'number',
    },
    'chart.showLabels': {
        default: true,
        description: 'Specify whether to display labels. This option has no effect when hasDonutHole=true.',
        type: 'boolean',
    },
    'chart.showPercent': {
        default: false,
        description: 'Specify whether to display percentage values in the labels.',
        type: 'boolean',
    },
    'legend.placement': {
        default: 'right',
        description:
            'Specify how the legend should be aligned. This option only has an effect when hasDonutHole=true.',
        pattern: getPattern(['right', 'left', 'top', 'bottom', 'none']),
        type: 'string',
    },
    'legend.labelStyle.overflowMode': {
        default: 'ellipsisMiddle',
        description:
            'Specify how to display legend labels when they overflow the layout boundaries by replacing overflow text with an ellipsis.',
        pattern: getPattern(['ellipsisEnd', 'ellipsisMiddle', 'ellipsisStart', 'ellipsisNone']),
        type: 'string',
    },
    hasDonutHole: {
        default: false,
        description: 'Specify whether the pie should be a donut.',
        type: 'boolean',
    },
};
