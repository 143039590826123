import React, { useCallback, useMemo } from 'react';
import {
    selectDataSourceDefinitionForVizFactory,
    selectMode,
    selectVisualizationDefinitionFactory,
    triggerEvent,
    updateVizOptions,
    useDispatch,
    useSelector,
} from '@splunk/dashboard-state';
import type { VizProps } from '@splunk/dashboard-types';
import LineContainer from '../LineContainer';

const ConnectedLineContainer = (props: VizProps) => {
    const selectVizDef = useMemo(selectVisualizationDefinitionFactory, []);
    const selectDSDef = useMemo(selectDataSourceDefinitionForVizFactory, []);

    const dispatch = useDispatch();

    // mapDispatchToProps
    const handleEvent = useCallback(
        (eventType, payload, eventId) =>
            dispatch(triggerEvent(props.id, eventType, payload, eventId)),
        [dispatch, props.id]
    );
    const onOptionsChange = useCallback(
        (newOptions) => dispatch(updateVizOptions(props.id, newOptions)),
        [dispatch, props.id]
    );

    // mapStateToProps
    const mode = useSelector(selectMode);
    const vizDef = useSelector((state) => selectVizDef(state, props.id));
    const dataSourceDefs = useSelector((state) => selectDSDef(state, props.id));

    return (
        <LineContainer
            {...props}
            handleEvent={handleEvent}
            onOptionsChange={onOptionsChange}
            mode={mode}
            vizDef={vizDef}
            dataSourceDefs={dataSourceDefs}
        />
    );
};

export default ConnectedLineContainer;
