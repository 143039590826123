import { mapValues, mapKeys, isPlainObject } from 'lodash';
import {
    hashString,
    DYNAMIC_STRING_SUBSTITUTE,
    isDynamicOption,
    hasTokens,
    replaceTokensWithHash,
} from '@splunk/dashboard-utils';
import type { PlainObject, FilterDefinition } from '../FilterTypes';
import { hashStringOnObject } from '../utils';

// the following two lists are copied from previous implementation
// NOTE: denyLists will have to be updated periodically if options change
// TODO: figure out a better way to block options with confidential information
// a more specific way to handle this is defining a denyList object with key-value pairs of option names and a boolean stating whether it should be removed
// however, this comes with the caveat of having to update this list each time a new option is added
const denyListStrings = [
    'src',
    'content',
    'markdown',
    'unit',
    'chart.overlayFields',
    'icon',
    'axisY2.fields',
    'svg',
];
// NOTE: these substrings cover all the options that could hold confidential info, but will strip out additional options that otherwise do not hold confidential info
const denyListSubstrings = /(label|text)/i;

type FilterOptions = (options: PlainObject) => PlainObject;

type FilterOptionsOnItem = (
    item: PlainObject,
    filter: FilterOptions
) => PlainObject;

const filterOptionsOnItem: FilterOptionsOnItem = (item, filter) =>
    mapValues(item, (value: PlainObject, key: string) => {
        if (key === 'options') {
            return filter(value);
        }
        if (key === 'context') {
            return {};
        }
        return value;
    });

const filterVisualizationOptions: FilterOptions = (options: PlainObject) =>
    mapValues(options, (value, key) => {
        if (['fieldColors', 'annotation.categoryColors'].includes(key)) {
            let obj: string | PlainObject;
            if (typeof value === 'string') {
                try {
                    obj = JSON.parse(value);
                } catch (e) {
                    // anonymize the value to be extra safe
                    return true;
                }
            } else {
                obj = value as PlainObject;
            }

            return mapKeys(
                obj as PlainObject,
                (_fieldColor, fieldName: string) =>
                    hashString(fieldName, 'field_')
            );
        }

        if (denyListStrings.includes(key) || denyListSubstrings.test(key)) {
            // return true instead of hash string, to be consistent with previous implementation
            return true;
        }

        if (isDynamicOption(value as string)) {
            return DYNAMIC_STRING_SUBSTITUTE;
        }

        if (isPlainObject(value)) {
            const x = filterVisualizationOptions(value as PlainObject);
            if (key === 'columnFormat') {
                return mapKeys(x, (v, fieldName) =>
                    hashString(fieldName, 'field_')
                );
            }
            return x;
        }

        if (typeof value === 'string' && hasTokens(value)) {
            return replaceTokensWithHash(value);
        }
        return value;
    });

const filterInputOptions: FilterOptions = (options) =>
    mapValues(options, (value, key) => {
        if (key === 'defaultValue') {
            return hashString(value as string);
        }

        if (key === 'token') {
            return hashString(value as string, 'token_');
        }

        if (key === 'items') {
            return Array.isArray(value) ? value.length : 0;
        }

        return value;
    });

const filterDataSourceOptions: FilterOptions = (options) =>
    mapValues(options, (value: PlainObject, key: string) => {
        if (key === 'queryParameters') {
            return mapValues(
                value,
                (queryParameterValue, queryParameterKey) => {
                    if (
                        ['earliest', 'latest', 'timezone'].includes(
                            queryParameterKey
                        )
                    ) {
                        return queryParameterValue;
                    }

                    return hashStringOnObject(value);
                }
            );
        }

        if (
            key === 'refresh' ||
            key === 'refreshType' ||
            key === 'requiredFreshness'
        ) {
            return value;
        }

        return hashStringOnObject(value);
    });

const filterLayoutOptions: FilterOptions = (options) =>
    mapValues(options, (value, key) => {
        if (key === 'backgroundImage') {
            // previous implementation deleted this option. Here we use true to indicate the option is used.
            return mapValues(
                value as PlainObject,
                (v: PlainObject, k: string) => {
                    if (k === 'src') {
                        return true;
                    }
                    return v;
                }
            );
        }

        return value;
    });

const filterOptions: FilterDefinition = (definition) =>
    mapValues(definition, (value: PlainObject, key: string) => {
        if (key === 'visualizations') {
            return mapValues(value, (viz: PlainObject) =>
                filterOptionsOnItem(viz, filterVisualizationOptions)
            );
        }

        if (key === 'dataSources') {
            return mapValues(value, (ds: PlainObject) =>
                filterOptionsOnItem(ds, filterDataSourceOptions)
            );
        }

        if (key === 'inputs') {
            return mapValues(value, (input: PlainObject) => {
                const withoutPII = filterOptionsOnItem(
                    input,
                    filterInputOptions
                );

                // Remove token from input canvasAlignment configuration if needed
                if (
                    typeof withoutPII.canvasAlignment === 'string' &&
                    hasTokens(withoutPII.canvasAlignment)
                ) {
                    withoutPII.canvasAlignment = replaceTokensWithHash(
                        withoutPII.canvasAlignment
                    );
                }

                return withoutPII;
            });
        }

        if (key === 'defaults') {
            return mapValues(value, (v: PlainObject) =>
                mapValues(v, hashStringOnObject)
            );
        }

        if (key === 'layout') {
            return filterOptionsOnItem(value, filterLayoutOptions);
        }

        return value;
    });

export default filterOptions;
