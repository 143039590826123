import React from 'react';
import SVG, {
    type SVGPropsOptionalViewBox,
} from '@splunk/react-icons/SVGEnterprise';

/* eslint-disable max-len */
export default function Server(
    props: SVGPropsOptionalViewBox
): ReturnType<typeof SVG> {
    return (
        <SVG viewBox="0 0 45 59" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path d="m5.45443 2.45866h33.83337c1.3346 0 2.4166 1.08198 2.4166 2.41667v48.33337c0 1.3346-1.082 2.4166-2.4166 2.4166h-33.83337c-1.33469 0-2.41667-1.082-2.41667-2.4166v-48.33338c0-1.33468 1.08198-2.41666 2.41667-2.41666zm-4.833336 2.41666c0-2.66937 2.163956-4.8333278 4.833336-4.8333278h33.83337c2.6693 0 4.8333 2.1639578 4.8333 4.8333378v48.33337c0 2.6693-2.164 4.8333-4.8333 4.8333h-33.83337c-2.66938 0-4.833336-2.164-4.833336-4.8333zm33.833306 4.83334h-24.1666v9.66664h24.1666zm-24.166 14.50014h24.1667v9.6667h-24.1667zm0 14.5h24.1667v9.6667h-24.1667zm4.8333-21.75c1.3347 0 2.4167-1.082 2.4167-2.4166 0-1.3347-1.082-2.4167-2.4167-2.4167-1.3346 0-2.4166 1.082-2.4166 2.4167 0 1.3346 1.082 2.4166 2.4166 2.4166zm2.4161 12.0832c0 1.3347-1.082 2.4167-2.4167 2.4167s-2.4167-1.082-2.4167-2.4167 1.082-2.4167 2.4167-2.4167 2.4167 1.082 2.4167 2.4167zm0 14.5c0 1.3347-1.082 2.4167-2.4167 2.4167s-2.4167-1.082-2.4167-2.4167 1.082-2.4167 2.4167-2.4167 2.4167 1.082 2.4167 2.4167z" />
        </SVG>
    );
}
