import { parse as baseParse, mergeEncoding } from '@splunk/visualization-encoding-parsers/Base';
import isEmpty from 'lodash/isEmpty';
import { ErrorMsgs } from './errorMsgs';

export const dataContract = {
    requiredDataSources: [
        {
            name: 'primary',
            description: 'DataSource that powers the visualization',
        },
    ],
    optionalDataSources: [
        // Note: SCP-14090 disable ui for optional datasources. We may need to add it back later
        // {
        //     name: 'annotation',
        //     description: 'DataSource that populate event annotations',
        // },
    ],
    initialRequestParams: {
        primary: { offset: 0, count: 10000 },
        annotation: { offset: 0, count: 10000 },
    },
    encoding: {
        x: {
            isRequired: true,
            type: ['time', 'number', 'string'],
            default: 'primary[0]',
        },
        y: {
            isRequired: true,
            type: ['number', 'string'],
            default: 'primary[1:]',
        },
        y2: {
            isRequired: false,
            type: ['number', 'string'],
        },
        annotationX: {
            isRequired: false,
            type: ['time', 'number', 'string'],
        },
        annotationLabel: {
            isRequired: false,
            type: ['string'],
        },
        annotationColor: {
            isRequired: false,
            type: ['string'],
        },
    },
};

/**
 * Column parse function
 * @param {object} dataSources
 * @param {object} userDefinedEncoding
 * @return {object} props that dashboard/react visualizations can be used.
 * @public
 */
export const parse = (dataSources, userDefinedEncoding) => {
    if (isEmpty(dataSources)) {
        throw new Error(ErrorMsgs.NO_DATA_SOURCES);
    }

    const encoding = mergeEncoding(dataContract.encoding, userDefinedEncoding);
    const parsedProps = baseParse(dataSources, encoding);

    return {
        x: parsedProps.x,
        y: parsedProps.y,
        y2: parsedProps.y2,
        annotationX: parsedProps.annotationX,
        annotationLabel: parsedProps.annotationLabel,
        annotationColor: parsedProps.annotationColor,
        xFieldName: parsedProps['_meta'].fieldNames.x, // eslint-disable-line dot-notation
        yFieldName: parsedProps['_meta'].fieldNames.y, // eslint-disable-line dot-notation
        y2FieldName: parsedProps['_meta'].fieldNames.y2, // eslint-disable-line dot-notation
    };
};
