import { DashboardDefinition } from '@splunk/dashboard-definition';
import { createAction } from '@reduxjs/toolkit';
import type { Draft, PayloadAction } from '@reduxjs/toolkit';
import type { DashboardJSON, InputDefinition } from '@splunk/dashboard-types';

/**
 * Actions
 */

export interface UpdateInputPayload {
    id: string;
    inputDefinition:
        | InputDefinition
        | ((def: InputDefinition) => InputDefinition);
}

export const updateInput = createAction<UpdateInputPayload>(
    'definition/input/update'
);

export const updateInputReducer = (
    state: Draft<DashboardJSON>,
    action: PayloadAction<UpdateInputPayload>
): void => {
    const { id, inputDefinition } = action.payload;
    let updatedDef: InputDefinition;
    if (typeof inputDefinition === 'function') {
        const def = DashboardDefinition.fromJSON(state);
        updatedDef = inputDefinition(def.getInput(id) as InputDefinition);
    } else {
        updatedDef = inputDefinition;
    }

    state.inputs ??= {};
    state.inputs[id] = updatedDef;
};
