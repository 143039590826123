import { _ } from '@splunk/ui-utils/i18n';
import { COLOR_OR_TOKEN_PATTERN_WITH_RGBA, getPattern } from '@splunk/visualizations-shared/schemaUtils';
import { defaultPalettesConfig } from '@splunk/visualization-color-palettes/editors/PresetPalettes';
import pick from '@splunk/themes/pick';
import variables from '@splunk/themes/variables';
import { isDynamicOption } from '@splunk/visualizations-shared/configUtils';
import { VizConfig } from '../common/interfaces/VizConfig';
import { DataContract } from '../common/interfaces/DataContract';
import { VizSize } from '../common/interfaces/VizSize';
import { defaultContext } from '../common/configContext/singleValueContext';
import StaticMajorAndTrendLayout from '../common/editorConfig/StaticMajorAndTrendLayout';
import StaticBackgroundColorEditor from '../common/editorConfig/StaticBackgroundColorEditor';
import StaticIconColorEditor from '../common/editorConfig/StaticIconColorEditor';
import StaticMajorColorEditor from '../common/editorConfig/StaticMajorColorEditor';
import StaticTrendColorEditor from '../common/editorConfig/StaticTrendColorEditor';
import { getDynamicMajorColorEditor } from '../common/editorConfig/DynamicMajorColorEditor';
import { getDynamicTrendColorEditor } from '../common/editorConfig/DynamicTrendColorEditor';
import { getDynamicBackgroundColorEditor } from '../common/editorConfig/DynamicBackgroundColorEditor';
import { TrellisEditorConfig, TrellisBackgroundColorEditor } from '../common/editorConfig/TrellisEditors';
import { OptionsSchema } from '../common/interfaces/OptionsSchema';
import { EditorConfig } from '../common/interfaces/Editor';
import { VizBehavior } from '../common/interfaces/VizBehavior';
import { PresetEntry, enhanceConfig, getInitialPreset } from '../common/utils/configUtils';
import { VizCategory } from '../common/interfaces/VizCategory';
import { LAYOUT_TYPE } from '../common/utils/layoutUtils';

const dataContract: DataContract = {
    requiredDataSources: [
        {
            name: 'primary',
            description: 'DataSource that powers the visualization',
        },
    ],
    optionalDataSources: [],
    initialRequestParams: {
        primary: { offset: 0, count: 10000 },
    },
};

const size: VizSize = {
    initialWidth: 250,
    initialHeight: 250,
};

const optionsSchema: OptionsSchema = {
    backgroundColor: {
        default: 'transparent',
        description:
            'Specify the color for the background. You may use a dataSource to apply the color. The hex value format should be “#FFFFFF”.',
        pattern: COLOR_OR_TOKEN_PATTERN_WITH_RGBA,
        type: 'string',
    },
    icon: { default: 'default', description: 'Specify an icon.', type: 'string' },
    iconColor: {
        default: null,
        description:
            'Specify the color for the icon. You may use a dataSource to apply the color. The hex value format should be “#FFFFFF”. The default for enterprise light is "#000000". The default for enterprise dark is "#ffffff". The default for prisma dark is "rgba(255, 255, 255, 0.98)".',
        pattern: COLOR_OR_TOKEN_PATTERN_WITH_RGBA,
        type: 'string',
    },
    iconOpacity: {
        default: 1,
        description: 'Specify the opacity for the icon using a number between 0 and 1 (inclusive).',
        type: 'number',
    },
    iconPosition: {
        default: 'before',
        description: 'Specify where to display the icon in relation to the major value.',
        type: 'string',
    },
    majorColor: {
        default: '> themes.defaultFontColor',
        description:
            'Specify the color for the major value.  You may use a dataSource to apply the color. The hex value format should be “#FFFFFF”. The default for enterprise light is "#000000". The default for enterprise dark is "#ffffff". The default for prisma dark is "rgba(255, 255, 255, 0.98)".',
        pattern: COLOR_OR_TOKEN_PATTERN_WITH_RGBA,
        type: 'string',
    },
    majorFontSize: {
        description:
            'Specify the font size (px) for the major value. By default the major value font size is calculated dynamically based on the available space.',
        type: 'number',
    },
    majorValue: {
        default: '> primary|seriesByPrioritizedTypes("number", "string", "time")|lastPoint()',
        description: 'The major value to display in the visualization.',
        type: ['string', 'number'],
    },
    majorValueField: {
        default: '> majorValue | getField()',
        description: 'The field name of major value.',
        type: 'string',
    },
    numberPrecision: {
        default: 0,
        maximum: 20,
        minimum: 0,
        description:
            'Specify the number of decimal places to display. For example, to display 3 decimal places, use a value of 3. Values can range from 0 to 20.',
        type: 'number',
    },
    shouldAbbreviateTrendValue: {
        default: false,
        description:
            'Specify whether to abbreviate the trend value to 2 decimal points. A magnitude unit will be displayed.',
        type: 'boolean',
    },
    shouldUseThousandSeparators: {
        default: true,
        description: 'Specify whether numeric values use commas as thousandths separators.',
        type: 'boolean',
    },
    showValue: {
        default: true,
        description: 'Specify whether to enable or disable the value and trend indicator displays.',
        type: 'boolean',
    },
    splitByLayout: {
        default: 'off',
        description:
            'Specify the layout method by which to display the visualization, which splits the data into individual visualizations based on a certain category.',
        type: 'string',
        pattern: getPattern([LAYOUT_TYPE.Off, LAYOUT_TYPE.Trellis]),
        enum: [LAYOUT_TYPE.Off, LAYOUT_TYPE.Trellis],
    },
    trendColor: {
        default: '> themes.defaultFontColor',
        description:
            'Specify the color for the trend value. You may use a dataSource to apply the color. The hex value format should be “#FFFFFF”. The default for enterprise light is "#000000". The default for enterprise dark is "#ffffff". The default for prisma dark is "rgba(255, 255, 255, 0.98)".',
        pattern: COLOR_OR_TOKEN_PATTERN_WITH_RGBA,
        type: 'string',
    },
    trendDisplay: {
        default: 'absolute',
        description: 'Specify whether the trend value is displayed as a percentage or an absolute count.',
        pattern: getPattern(['off', 'percent', 'absolute']),
        type: 'string',
    },
    trendFontSize: {
        description:
            'Specify the font size (px) for the trend value. By default the trend value font size is calculated dynamically based on the available space.',
        type: 'number',
    },
    trendValue: {
        default: '> primary|seriesByPrioritizedTypes("number", "string", "time")|delta(-2)', // todo: may need update delta method
        description: 'The trend value to display in the visualization.',
        type: 'number',
    },
    trellisBackgroundColor: {
        default: '> themes.defaultBackgroundColor',
        description:
            'Specify the color used for the trellis container background by using a hexadecimal code. For example, #0000FF.',
        pattern: COLOR_OR_TOKEN_PATTERN_WITH_RGBA,
        type: 'string',
    },
    trellisColumns: {
        description:
            'Specify the number of visualizations to display in a given row of the trellis container. The remaining visualizations will wrap accordingly. If nothing is specified, it will be auto-set based on the trellisMinColumnWidth. The minimum value is 1.',
        type: 'number',
        minimum: 1,
    },
    trellisMinColumnWidth: {
        default: 100,
        minimum: 1,
        description:
            'Specify the minimum width, in pixels, of each visualization in the trellis container. If the window or panel is resized, the remaining visualizations may be viewed by scrolling. The minimum value is 1.',
        type: 'number',
    },
    trellisPageCount: {
        default: 20,
        minimum: 1,
        description:
            'Specify the maximum number of visualizations to display in a single page in the trellis container. The remaining visualizations will paginate accordingly. The minimum value is 1.',
        type: 'number',
    },
    trellisRowHeight: {
        default: 70,
        minimum: 1,
        description:
            'Specify the height, in pixels, of each visualization in the trellis container. If the window or panel is resized, the remaining visualizations may be viewed by scrolling. The minimum value is 1.',
        type: 'number',
    },
    trellisSplitBy: {
        description:
            'Specify the field name of the column with categories used, or “aggregations”, to split the data into individual visualizations for trellis display, if applicable. If a SPL `timechart` command is used, this may default to the SPL `by` clause field. If a SPL `chart` command is used, this may default to the first string field.',
        type: 'string',
    },
    underLabel: { description: 'Specify the text that appears below the major value.', type: 'string' },
    unit: {
        description: 'Specify text to show next to the major value.',
        type: 'string',
    },
    unitPosition: {
        default: 'after',
        description: 'Specify whether the unit text should appear before or after the major value.',
        pattern: getPattern(['before', 'after']),
        type: 'string',
    },
};

const basePresetContext = {
    iconColorEditorConfig: undefined,
    majorColorEditorConfig: undefined,
    trendColorEditorConfig: undefined,
    backgroundColorEditorConfig: undefined,
};

const basePresetOptions = {
    iconColor: undefined,
    majorColor: undefined,
    trendColor: undefined,
    backgroundColor: undefined,
};

const presets: PresetEntry[] = [
    {
        // dummy context variables to allow for resetting of unused context states
        label: _('None'),
        name: 'singleValueIcon.none',
        value: {
            context: { ...basePresetContext },
            options: { ...basePresetOptions },
        },
    },
    {
        label: _('Icon'),
        name: 'singleValueIcon.icon',
        value: {
            context: {
                ...basePresetContext,
                iconColorEditorConfig: defaultContext.majorColorConfig,
            },
            options: {
                ...basePresetOptions,
                iconColor: '> majorValue | rangeValue(iconColorEditorConfig)',
            },
        },
    },
    {
        label: _('Major value'),
        name: 'singleValueIcon.majorValue',
        value: {
            context: {
                ...basePresetContext,
                majorColorEditorConfig: defaultContext.majorColorConfig,
            },
            options: {
                ...basePresetOptions,
                majorColor: '> majorValue | rangeValue(majorColorEditorConfig)',
            },
        },
    },
    {
        label: _('Trend value'),
        name: 'singleValueIcon.trend',
        value: {
            context: {
                ...basePresetContext,
                trendColorEditorConfig: defaultContext.trendColorConfig,
            },
            options: {
                ...basePresetOptions,
                trendColor: '> trendValue | rangeValue(trendColorEditorConfig)',
            },
        },
    },
    {
        label: _('Icon and Major value'),
        name: 'singleValueIcon.iconAndMajorValue',
        value: {
            context: {
                ...basePresetContext,
                iconColorEditorConfig: defaultContext.majorColorConfig,
                majorColorEditorConfig: defaultContext.majorColorConfig,
            },
            options: {
                ...basePresetOptions,
                iconColor: '> majorValue | rangeValue(iconColorEditorConfig)',
                majorColor: '> majorValue | rangeValue(majorColorEditorConfig)',
            },
        },
    },
    {
        label: _('Background'),
        name: 'singleValueIcon.background',
        value: {
            context: {
                ...basePresetContext,
                backgroundColorEditorConfig: defaultContext.majorColorConfig,
            },
            options: {
                ...basePresetOptions,
                backgroundColor: '> majorValue | rangeValue(backgroundColorEditorConfig)',
            },
        },
    },
];

/**
 * TODO
 * common theme variables such as enterprise.defaultFontColor
 * that are repeated in multiple viz config themes should be DRYd up.
 */
const themes = {
    defaultFontColor: props =>
        pick({
            enterprise: {
                dark: variables.textColor(props),
                light: variables.gray20(props),
            },
            prisma: variables.contentColorActive(props),
        })(props),
    defaultBlockFontColor: props =>
        pick({
            enterprise: variables.white(props),
            prisma: variables.contentColorActive(props),
        })(props),
};

const editorConfig: EditorConfig[] = [
    {
        label: _('Data configurations'),
        layout: [
            [
                {
                    label: _('Value'),
                    editor: 'editor.columnSelector',
                    option: 'majorValue',
                    context: 'valuesContext',
                    editorProps: {
                        dataSourceKey: 'primary',
                        prepareValue: (
                            definition
                        ): { context: Record<string, any>; options: Record<string, any> } => {
                            const {
                                options: { majorValue: baseSPL },
                            } = definition;

                            return {
                                context: {},
                                options: {
                                    majorValue: `${baseSPL} | lastPoint()`,
                                    trendValue: `${baseSPL} | delta(-2)`,
                                },
                            };
                        },
                    },
                    isDisabled: ({ options }) => options.splitByLayout === LAYOUT_TYPE.Trellis,
                },
            ],
        ],
    },
    {
        label: _('Data display'),
        layout: [
            [
                {
                    label: _('Show major and trend value'),
                    option: 'showValue',
                    editor: 'editor.toggle',
                },
            ],
            ...StaticMajorAndTrendLayout,
            [
                {
                    label: _('Trend display'),
                    option: 'trendDisplay',
                    editor: 'editor.select',
                    editorProps: {
                        values: [
                            { label: _('Percent'), value: 'percent' },
                            { label: _('Absolute'), value: 'absolute' },
                            { label: _('Off'), value: 'off' },
                        ],
                    },
                    showEditor: ({ options }) => options.showValue || options.showValue === undefined,
                },
            ],
            ...TrellisEditorConfig,
        ],
    },
    {
        label: _('Color and style'),
        layout: [
            [
                {
                    label: 'Dynamic elements',
                    key: 'presetSelector',
                    editor: 'editor.presetSelector',
                    editorProps: {
                        presets,
                        value: ({ context, options }) => {
                            const initialPreset = getInitialPreset(context, options, presets);
                            return (initialPreset && initialPreset.label) || null;
                        },
                    },
                },
            ],
            StaticIconColorEditor,
            [
                {
                    label: _('Icon'),
                    option: 'iconColor',
                    context: 'iconColorEditorConfig',
                    editor: 'editor.dynamicColor',
                    showEditor: ({ options }) => isDynamicOption(options.iconColor),
                    editorProps: {
                        labelPosition: 'top',
                        flyoutTitle: 'Dynamic coloring: icon',
                        formatters: [
                            {
                                label: _('Ranges'),
                                value: 'rangeValue',
                                defaults: {
                                    trendValue: defaultContext.trendColorConfig,
                                    majorValue: defaultContext.majorColorConfig,
                                },
                            },
                            {
                                label: _('Matches'),
                                value: 'matchValue',
                                defaults: {
                                    majorValue: defaultContext.majorColorMatchConfig,
                                },
                            },
                        ],
                        dataSelectors: [
                            {
                                label: _('Major value'),
                                value: 'majorValue',
                            },
                            {
                                label: _('Trend'),
                                value: 'trendValue',
                            },
                        ],
                        defaultPalettesConfig,
                    },
                },
            ],
            [
                {
                    ...StaticMajorColorEditor,
                    editorProps: {
                        themes,
                    },
                },
            ],
            [getDynamicMajorColorEditor({ defaultContext, defaultPalettesConfig })],
            [
                {
                    ...StaticTrendColorEditor,
                    editorProps: {
                        themes,
                    },
                },
            ],
            [getDynamicTrendColorEditor({ defaultContext, defaultPalettesConfig })],
            [
                {
                    ...StaticBackgroundColorEditor,
                    editorProps: {
                        themes,
                    },
                },
            ],
            [
                {
                    ...TrellisBackgroundColorEditor,
                    editorProps: {
                        themes,
                    },
                },
            ],
            [getDynamicBackgroundColorEditor({ defaultContext, defaultPalettesConfig })],
            [
                {
                    label: _('Major value size (px)'),
                    option: 'majorFontSize',
                    editor: 'editor.number',
                    editorProps: {
                        min: 1,
                    },
                    placeholder: _('Auto'),
                    showEditor: ({ options }) => options.showValue || options.showValue === undefined,
                },
                {
                    label: _('Trend value size (px)'),
                    option: 'trendFontSize',
                    editor: 'editor.number',
                    editorProps: {
                        min: 1,
                    },
                    placeholder: _('Auto'),
                    showEditor: ({ options }) => options.showValue || options.showValue === undefined,
                    isDisabled: ({ options }) => options.trendDisplay === 'off',
                },
            ],
        ],
    },
];

/**
 * visualization configuration
 */
const config: VizConfig = {
    /**
     * unique viz key
     */
    key: 'splunk.singlevalueicon',
    /**
     * viz name
     */
    name: 'Single Value Icon',
    category: VizCategory.SINGLE_VALUE,
    /**
     * viz icon
     */
    icon: null,
    dataContract,
    size,
    defaultContext,
    optionsSchema,
    editorConfig,
    events: {
        'value.click': {
            description: 'trigger when user clicks major value or icon',
            payloadKeys: ['majorValue', 'trendValue', 'icon'],
        },
    },
    supports: [VizBehavior.DYNAMIC_OPTIONS, VizBehavior.EVENTS, VizBehavior.TRELLIS],
    themes,
};

export default enhanceConfig(config);
