import { Events as EventsIcon } from '@splunk/visualization-icons';
import { Events as EventsPlaceholderIcon } from '@splunk/visualization-icons/placeholders';
import { COLOR_OR_TOKEN_PATTERN_WITH_RGBA } from '@splunk/visualizations-shared/schemaUtils';
import { pick, variables } from '@splunk/themes';
import { _ } from '@splunk/ui-utils/i18n';
import { DataContract } from '../common/interfaces/DataContract';
import { DefaultContext } from '../common/interfaces/DefaultContext';
import { EditorConfig } from '../common/interfaces/Editor';
import { OptionsSchema } from '../common/interfaces/OptionsSchema';
import { ThemedDefaults } from '../common/interfaces/ThemedDefaults';
import { VizBehavior } from '../common/interfaces/VizBehavior';
import { VizCategory } from '../common/interfaces/VizCategory';
import { VizConfig } from '../common/interfaces/VizConfig';
import { VizSize } from '../common/interfaces/VizSize';
import { enhanceConfig } from '../common/utils/configUtils';
import getBackgroundColorEditor from '../common/editorConfig/BackgroundColor';

const defaultContext: DefaultContext = {};

const dataContract: DataContract = {
    requiredDataSources: [
        {
            name: 'primary',
            description: 'DataSource that powers the visualization',
        },
    ],
    optionalDataSources: [
        {
            name: 'fieldsummary',
            description: 'DataSource that powers the field summary popover on field click',
        },
    ],
    initialRequestParams: {
        primary: (options: { count?: number } = {}): Record<string, unknown> => ({
            offset: 0,
            count: options.count ? options.count : 10,
            requestTotalCount: true,
        }),
    },
};

const size: VizSize = {
    initialWidth: 800,
    initialHeight: 500,
};

const optionsSchema: OptionsSchema = {
    backgroundColor: {
        default: '> themes.defaultBackgroundColor',
        description:
            'Specify the color used for the background by using a hexadecimal code. For example, #0000FF.',
        pattern: COLOR_OR_TOKEN_PATTERN_WITH_RGBA,
        type: 'string',
    },
    eventActions: {
        default: [],
        description:
            'Specify the available actions to take for an event. For example, `[{ "eventType": "eventAction.buildEventType.click", "label": "Build Event Type" }]`. Event types should end in `.click` if utilizing event handlers. Note that both `eventtypeValues` and `fieldFilters` keys support wildcard values.',
        type: 'array',
        items: {
            anyOf: [
                {
                    type: 'object',
                    properties: {
                        eventType: {
                            description: 'The distinct type for the event emitted upon event action click.',
                            type: 'string',
                            pattern: '.click$',
                            default: '',
                        },
                        label: {
                            description: 'The label for the event action.',
                            type: 'string',
                            default: '',
                        },
                        eventtypeValues: {
                            description:
                                'An array of string values that all need to match against the value(s) of the `eventtype` field if it exists in the event in order to be shown.',
                            items: { type: 'string' },
                            type: 'array',
                            default: [],
                        },
                        fieldFilters: {
                            description:
                                'An array of string values for fields that need to exist in the event in order for the given event action in order to be shown.',
                            items: { type: 'string' },
                            type: 'array',
                            default: [],
                        },
                    },
                    required: ['eventType', 'label'],
                },
            ],
        },
    },
    fieldActions: {
        description:
            'Specify the available actions for this field. For example, `{ *: [{ "eventType": "fieldAction.sharedAction1.click", "label": "Shared Action 1", "eventtypeValues": ["*"], "fieldFilters": ["*"] }] }`. Event types should end in `.click` if utilizing event handlers. Actions under the `*` key are shared across all field dropdowns. Note that both `eventtypeValues` and `fieldFilters` keys, as well as the field names, support wildcard values.',
        type: 'object',
        patternProperties: {
            '.*': {
                description: 'Specify the available actions for this field.',
                type: 'array',
                items: {
                    anyOf: [
                        {
                            type: 'object',
                            properties: {
                                eventType: {
                                    description:
                                        'The distinct type for the event emitted upon event action click.',
                                    type: 'string',
                                    pattern: '.click$',
                                    default: '',
                                },
                                label: {
                                    description: 'The label for the event action.',
                                    type: 'string',
                                    default: '',
                                },
                                eventtypeValues: {
                                    description:
                                        'An array of string values that all need to match against the value(s) of the `eventtype` field if it exists in the event in order to be shown.',
                                    items: { type: 'string' },
                                    type: 'array',
                                    default: [],
                                },
                                fieldFilters: {
                                    description:
                                        'An array of string values for fields that need to exist in the event in order for the given event action in order to be shown.',
                                    items: { type: 'string' },
                                    type: 'array',
                                    default: [],
                                },
                            },
                            required: ['eventType', 'label'],
                        },
                    ],
                },
            },
        },
    },
    footerFields: {
        description:
            'Specify field(s) that should be displayed in the footer component. Specify one or more fields to display in the footer component. For example, specify ["source", "dest"] to display the source and destination fields.',
        type: 'array',
        items: {
            type: 'string',
        },
        default: [],
    },
    highlightValuesByField: {
        description:
            'Specify the field-value pairs to highlight. For example, `{"source": "$srcToken$", "dest": "12.21.1.11"}`',
        type: 'object',
    },
    showFieldSummary: {
        description:
            'Specify that a field summary popup appears when a field is selected. To show field summaries for a search, configure an additional datasource called `fieldsummary` using the original search followed by the `... | fieldsummary maxvals=10` SPL command.',
        type: 'boolean',
        default: true,
    },
};

const themes: ThemedDefaults = {
    defaultBackgroundColor: props =>
        pick({
            enterprise: {
                dark: variables.black(props),
                light: variables.backgroundColor(props),
            },
            prisma: variables.backgroundColorSidebar(props),
        })(props),
};

const editorConfig: EditorConfig[] = [
    {
        label: _('Data display'),
        layout: [
            [
                {
                    label: _('Show field summary'),
                    option: 'showFieldSummary',
                    editor: 'editor.checkbox',
                    editorProps: {
                        labelPosition: 'right',
                    },
                },
            ],
        ],
    },
    {
        label: _('Color and style'),
        layout: [getBackgroundColorEditor({ themes })],
    },
];

/**
 * visualization configuration
 */
const config: VizConfig = {
    /**
     * unique viz key
     */
    key: 'splunk.events',
    /**
     * viz name
     */
    name: 'Events',
    category: VizCategory.TABLE,
    status: 'preview',
    /**
     * viz icon
     */
    icon: EventsIcon,
    placeholderIcon: EventsPlaceholderIcon,
    dataContract,
    size,
    defaultContext,
    optionsSchema,
    editorConfig,
    events: {
        'eventAction.<actionName>.click': {
            description:
                'Triggered when a user selects the Event Actions button within an event. An application developer can configure workflow action retrieval and event handling, as described in the Concepts tab.',
        },
        'fieldAction.<actionName>.click': {
            description:
                'Triggered when a user selects an arrow under the Actions column heading. Application developers can configure workflow action retrieval and event handling. For more information, see the Concepts tab.',
        },
        'events.search.<fieldSummaryReportActionName>': {
            description:
                'Triggered when a user selects a report action in the field summary of an event. Application developers must pass context and handle events in their applications. For more information, see the Concepts tab.',
        },
        'value.click': {
            description: 'Triggered when a user clicks a value in an event.',
        },
        'time.click': {
            description: 'Triggered when a user clicks to drilldown in the time controls popover.',
        },
    },
    supports: [VizBehavior.DYNAMIC_OPTIONS, VizBehavior.EVENTS, VizBehavior.PLACEHOLDER],
    themes,
    requiredProps: [],
};

export default enhanceConfig(config);
