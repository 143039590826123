import styled from 'styled-components';

export interface LayerProps {
    zIndex?: number;
}

/**
 * A layer that does not block events
 */
const Layer = styled.div<LayerProps>`
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    pointer-events: none;
    z-index: ${(props) => props.zIndex};
`;

export default Layer;
