import React from 'react';
import SVG, {
    type SVGPropsOptionalViewBox,
} from '@splunk/react-icons/SVGEnterprise';

/* eslint-disable max-len */
export default function Datacenters(
    props: SVGPropsOptionalViewBox
): ReturnType<typeof SVG> {
    return (
        <SVG viewBox="0 0 64 56" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path d="m48.7629.464798c.8674-.4110728 1.8735-.4110713 2.7409.000001l10.6666 5.055161c1.1173.52951 1.8296 1.65529 1.8296 2.8917v17.33154c0 1.2361-.7119 2.3617-1.8288 2.8914l-11.5808 5.4921-.9141-1.9276 11.5808-5.4921c.3723-.1765.6096-.5517.6096-.9638v-17.33154c0-.41214-.2375-.7874-.6099-.9639l-10.6667-5.05516c-.2891-.13703-.6245-.13703-.9136 0l-10.6666 5.05516c-.3725.1765-.6099.55176-.6099.9639v3.81174h-2.1333v-3.81174c0-1.23641.7122-2.36219 1.8295-2.8917z" />
            <path d="m43.7491 11.3196.002 2.9874 5.3107 2.5372v-3.1486zm6.9725 9.5213v5.0889l6.1482-2.9815v-11.8181l-5.6747 2.5562v7.1545zm4.2126-11.17861-4.8153 2.16911-4.6944-2.09944 4.6913-2.19682z" />
            <path d="m30.6296 8.99814c.8673-.41107 1.8734-.41107 2.7408 0l16 7.58276c1.1173.5295 1.8296 1.6553 1.8296 2.8917v25.6777c0 1.2361-.7119 2.3617-1.8288 2.8913l-16 7.5879c-.8678.4115-1.8746.4115-2.7424 0l-16-7.5879c-1.1169-.5296-1.8288-1.6552-1.8288-2.8913v-25.6777c0-1.2364.7123-2.3622 1.8296-2.8917zm1.8272 1.92776c-.2891-.137-.6245-.137-.9136 0l-16 7.5828c-.3725.1765-.6099.5517-.6099.9639v25.6777c0 .412.2373.7872.6096.9638l16 7.5878c.2893.1372.6249.1372.9142 0l16-7.5878c.3722-.1766.6096-.5518.6096-.9638v-25.6777c0-.4122-.2375-.7874-.6099-.9639z" />
            <path d="m21.9861 21.2396 10.0095-4.6936 9.9774 4.6753-9.9777 5.1359zm-1.0244 1.8723v20.2399l9.9714 4.4942v-19.6359zm12.1047 24.7306 9.9707-4.4907v-20.2788l-9.9707 5.1322z" />
            <path d="m15.2371.464798c-.8674-.4110728-1.8735-.4110713-2.7409.000001l-10.66664 5.055161c-1.117282.52951-1.82955809 1.65529-1.82955809 2.8917v17.33154c0 1.2361.71193509 2.3617 1.82880809 2.8914l11.58079 5.4921.9141-1.9276-11.58076-5.4921c-.37229-.1765-.6096-.5517-.6096-.9638v-17.33154c0-.41214.23742-.7874.60985-.9639l10.66671-5.05516c.2891-.13703.6245-.13703.9136 0l10.6666 5.05516c.3725.1765.6099.55176.6099.9639v3.81174h2.1333v-3.81174c0-1.23641-.7122-2.36219-1.8295-2.8917z" />
            <path d="m20.2509 11.3196-.002 2.9874-5.3107 2.5372v-3.1486zm-6.9725 9.5213v5.0889l-6.14822-2.9815v-11.8181l5.67472 2.5562v7.1545zm-4.2126-11.17861 4.8153 2.16911 4.6944-2.09944-4.6913-2.19682z" />
        </SVG>
    );
}
