import { isEqual } from 'lodash';
import { inputChanged } from '../sagas/sagaActions';

import resetStore from '../reducers/resetStore';

/**
 * monitor redux store state and dispatch definition/tokens changed action for datasource saga
 * @param {*} getState
 */
export default function changeMonitor({ getState }) {
    return (next) => (action) => {
        const { definition } = getState();
        const oldDefinition = { ...definition };
        let returnValue = next(action); // hit reducer
        if (action.type === resetStore.toString()) {
            return returnValue;
        }
        const { definition: newDefinition } = getState();

        if (!isEqual(oldDefinition.inputs, newDefinition.inputs)) {
            returnValue = next(inputChanged());
        }

        return returnValue;
    };
}
