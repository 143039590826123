import { isEqual } from 'lodash';
import type {
    DispatchedMiddlewareAction,
    Middleware,
} from '@splunk/dashboard-types';

// Return an action handler to intercept updates to selectedItems and
// force the active element in the document to blur before processing
const blurOnSelectedItemsChange: Middleware =
    ({ getState }) =>
    (next) =>
    (action: DispatchedMiddlewareAction) => {
        const oldSelectedItems = getState().editor.selectedItems;
        // hit reducer
        const result = next(action);
        const newSelectedItems = getState().editor.selectedItems;

        if (!isEqual(oldSelectedItems, newSelectedItems)) {
            if (
                typeof globalThis.document?.hasFocus === 'function' &&
                globalThis.document.hasFocus() &&
                typeof (globalThis.document.activeElement as HTMLElement)
                    ?.blur === 'function'
            ) {
                (globalThis.document.activeElement as HTMLElement).blur();
            }
        }
        return result;
    };

export default blurOnSelectedItemsChange;
