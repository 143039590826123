import React from 'react';
import {
    useSelector,
    selectReadOnlyTokenNamespaces,
} from '@splunk/dashboard-state';

interface NamespacesProps {
    readOnlyTokenNamespaces?: string[];
}

export const withReadOnlyTokenNamespaces =
    <P extends NamespacesProps>(WrappedComponent: React.ComponentType<P>) =>
    (props: Omit<P, keyof NamespacesProps>) => {
        const namespaces = useSelector(selectReadOnlyTokenNamespaces);

        return (
            <WrappedComponent
                {...(props as P)}
                readOnlyTokenNamespaces={namespaces}
            />
        );
    };
