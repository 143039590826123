import * as React from 'react';
import * as T from 'prop-types';
import { cloneDeep, noop } from 'lodash';
import styled, { css } from 'styled-components';

import { _ } from '@splunk/ui-utils/i18n';
import SUITable from '@splunk/react-ui/Table';
import Paginator from '@splunk/react-ui/Paginator';
import Message from '@splunk/react-ui/Message';
import { isColor } from '@splunk/visualizations-shared/colorUtils';
import { toDimension } from '@splunk/visualizations-shared/style';
import pick from '@splunk/themes/pick';
import variables from '@splunk/themes/variables';

import {
    TableCellValueType,
    TableRowCell,
    useTable,
    TableHeaderStyle,
    TableBody,
    TableHeader,
    TableFormat,
    ColumnFormat,
} from './hooks';

import {
    validAlignments,
    TableHeaderVisibility,
    validHeaderVisibilities,
    validCellRenderers,
    validSparklineTypes,
} from './consts';

import { SwitchTableCell, TextCell, ArrayCell, SparklineCell } from './cells';
import { PaginatorParamsPropTypes, PaginatorParams } from '../common/hooks/getPagination';
import { SortParamsPropTypes, SortParams } from '../common/hooks/getSorting';
import withFixedSizeContainer from '../common/hocs/FixedSizeContainer';

// debounce was imported this way due to typing
// eslint-disable-next-line import/order
import debounce = require('lodash/debounce');

export const MINIMUM_COLUMN_SIZE = 30;
export const INITIAL_UNSET_COLUMN_WIDTH = 90;
interface TableCellClickPayload {
    /* user should be able to use value to drilldown */
    value: string | number;
    // other details user can use to for building complex drilldown/tokens

    /* nth - column. starts at 0. row number column is ignored */
    cellIndex: number;

    /* nth - row */
    rowIndex: number;

    /* field name from the table header */
    fieldValue: string;
    name: string;

    /* displayed table cell value. This could be formatted data */
    cellValue: TableCellValueType;

    /* Raw unformatted data */
    cellRawValue: TableCellValueType;

    /* cell values of all cells of clicked row with the field and value - in shape row.<field>.value */
    [key: string]: TableCellValueType | TableCellValueType[];
}

export interface PureTableProps {
    backgroundColor?: string;
    count?: number;
    mode?: string;
    width?: number;
    height?: number;
    headers?: string[];
    table?: TableCellValueType[][];
    headerVisibility?: TableHeaderVisibility;
    showRowNumbers?: boolean;
    showInternalFields?: boolean;
    tableBody?: TableBody;
    tableHeader?: TableHeader;
    tableFormat?: Partial<TableFormat>;
    columnFormat?: Partial<ColumnFormat>;
    sortParams?: SortParams;
    paginatorParams?: PaginatorParams;
    onCellClick?: ({ e, payload }: { e: Event; payload: TableCellClickPayload }) => void;
    onColumnFormatChange?: (columnFormat: Partial<ColumnFormat>) => void;
    font?: string;
    fontSize?: string;
}

const NonVisibleTableHead = styled(SUITable.Head)`
    display: none;
`;

const FixedTableHead = styled(SUITable.Head)`
    position: sticky;
    top: 0;
`;

const TableHeadCell = styled(SUITable.HeadCell)`
    div > span {
        word-break: inherit;
        word-wrap: inherit;
        white-space: ${({ textOverflowType }) => (textOverflowType === 'ellipsis' ? 'nowrap' : 'inherit')};
        display: inline;
    }
`;

const VizTableContainer = styled.div<{ backgroundColor: string }>`
    box-sizing: border-box;
    padding: ${pick({
        enterprise: variables.spacingHalf,
        prisma: variables.spacingSmall,
    })};
    background-color: ${(props): string =>
        (isColor(props.backgroundColor) && props.backgroundColor) || props.theme.defaultBackgroundColor};
    display: flex;
    flex-flow: column nowrap;
    overflow: hidden;
`;
VizTableContainer.displayName = 'VizTableContainer';

const PAGINATOR_CONTAINER_HEIGHT = 38;
const PAGINATOR_SPACING = 5;
const SUITableContainer = styled.div<{
    headerStyle?: TableHeaderStyle;
    width: string | number;
    height: string | number;
    isPaging: boolean;
    font: string;
    fontSize: string;
    lineHeight: string;
    isFullyFixed: boolean;
}>`
    & * {
        font-family: ${(props): string => props.font};
        font-size: ${(props): string => props.fontSize};
        line-height: ${(props): string => props.lineHeight};
    }
    box-sizing: border-box;
    flex-grow: 1;
    overflow: hidden;
    ${({ width, height, isPaging, theme }: any): string => {
        const padding = pick({
            enterprise: variables.spacingHalf({ theme }),
            prisma: variables.spacingSmall({ theme }),
        })({ theme }) as string;
        const paddingOffset = parseInt(padding, 10) * 2;
        return toDimension({
            width: width - paddingOffset,
            height: isPaging
                ? height - PAGINATOR_CONTAINER_HEIGHT - PAGINATOR_SPACING - paddingOffset
                : height - paddingOffset,
        });
    }};

    ${({ isPaging }): string =>
        isPaging &&
        `
        margin-bottom: ${PAGINATOR_SPACING}px;
    `};

    /* TODO: Replace unsupported hacky style overrides */
    ${({ headerStyle }) =>
        headerStyle &&
        headerStyle.backgroundColor &&
        css`
            & [data-test='head-cell'] {
                background-color: ${headerStyle.backgroundColor};
            }
        `}

    ${({ headerStyle }) =>
        headerStyle &&
        headerStyle.color &&
        css`
            & [data-test='head-cell'] > div > span {
                color: ${headerStyle.color};
            }
        `}

    /* To accomodate custom logic that diverges from default resizableFillLayout behavior - allows the columns to shrink beneath Table width when columns are fixed */
    [data-test="main-table"] {
        ${({ isFullyFixed }) =>
            isFullyFixed &&
            css`
                width: fit-content;
                min-width: unset;
            `}
    }
` as any;
SUITableContainer.displayName = 'SUITableContainer';

const PaginatorContainer = styled.div`
    position: relative;
    text-align: right;
    height: ${PAGINATOR_CONTAINER_HEIGHT}px;
`;
PaginatorContainer.displayName = 'PaginatorContainer';

// allow for early return in case of empty tableBody while still following hook rules
const withEmptyTableMessage = (Viz): React.FunctionComponent<PureTableProps> => {
    // TODO(fkurniawan): add omit type to differentiate WrapperProps from PureTableProps
    const Wrapper = (props: PureTableProps): React.ReactElement => {
        const {
            headers,
            table,
            headerVisibility,
            showRowNumbers,
            showInternalFields,
            tableFormat,
            columnFormat,
            paginatorParams,
            count,
        } = props;
        const { tableHeader, tableBody } = useTable({
            headers,
            table,
            headerVisibility,
            showRowNumbers,
            showInternalFields,
            tableFormat: {
                ...tableFormat,
                ...(tableFormat?.align && { align: cloneDeep(tableFormat.align) }),
                ...(tableFormat?.cellTypes && { cellTypes: cloneDeep(tableFormat.cellTypes) }),
                ...(tableFormat?.data && { data: cloneDeep(tableFormat.data) }),
            },
            columnFormat,
            paginationOffset: paginatorParams?.current,
            count,
        });

        if (tableBody.rows.length === 0) {
            return <Message type="warning">{_('No data!')}</Message>;
        }

        return <Viz {...props} tableHeader={tableHeader} tableBody={tableBody} />;
    };

    Wrapper.displayName = 'PureTableWrapper';
    return Wrapper;
};

const useTableHead = (visibility: TableHeaderVisibility) => {
    if (visibility === 'none') {
        return NonVisibleTableHead;
    }

    if (visibility === 'fixed') {
        return FixedTableHead;
    }

    return SUITable.Head;
};

const HeadCell = ({ value, ...props }: any) => {
    const cellRef = React.useRef(null);
    return (
        <TableHeadCell elementRef={cellRef} {...props}>
            {value}
        </TableHeadCell>
    );
};

const fontMap: Record<string, string> = {
    proportional: 'Splunk Platform Sans',
    monospace: 'Splunk Platform Mono',
};

const fontSizeMap: Record<string, string> = {
    extraSmall: '10px',
    small: '12px',
    default: '14px',
    large: '16px',
};

const rowHeightMap: Record<string, string> = {
    extraSmall: '12px',
    small: '12px',
    default: '20px',
    large: '24px',
};

const tableInnerStyle = { height: '100%' };

const PureTable = (props: PureTableProps): React.ReactElement => {
    const {
        count,
        width,
        height,
        backgroundColor,
        tableHeader,
        tableBody,
        showRowNumbers,
        sortParams: { currentSortKey, currentSortDir, onSort },
        paginatorParams: { isPaging, onChange: paginatorOnChange, ...paginatorProps },
        onCellClick,
        onColumnFormatChange,
        columnFormat,
        mode,
        headerVisibility,
        font,
        fontSize,
    } = props;
    const [columns, setColumns] = React.useState(columnFormat);
    const containerRef = React.useRef(null);
    const containerRefInner = React.useRef(null);
    // this state avoids unncessary column sorting when column resize ends
    const [isResizing, setResizing] = React.useState(false);

    const firstRenderCount = React.useRef(count);
    React.useEffect((): void => {
        if (firstRenderCount.current !== count) {
            firstRenderCount.current = count;
            paginatorOnChange(null, { count });
        }
    }, [count, paginatorProps]);

    const stringifiedColumnFormat = JSON.stringify(columnFormat);
    const columnFormatRef = React.useRef(stringifiedColumnFormat);
    const modeRef = React.useRef(mode);
    React.useEffect((): void => {
        if (modeRef.current !== mode || columnFormatRef.current !== stringifiedColumnFormat) {
            columnFormatRef.current = stringifiedColumnFormat;
            modeRef.current = mode;
            setColumns(columnFormat);
        }
    }, [mode, stringifiedColumnFormat]);

    const TableHead = useTableHead(headerVisibility);

    const tableCellClickHandler = React.useCallback(
        e => {
            setResizing(false); // The resizing state will be set back to false on any cell click
            return onCellClick ? drilldownHandler(e) : noop;
        },
        [onCellClick, showRowNumbers, tableHeader, tableBody]
    );

    const debouncedDrilldown = debounce((e: Event, payload: TableCellClickPayload): void => {
        if (window.getSelection().toString() === '') {
            if (typeof onCellClick === 'function') {
                onCellClick({ e, payload });
            }
        }
    }, 200);

    const drilldownHandler = (e: React.SyntheticEvent): void => {
        const element = e.target as HTMLElement;
        const row = element.getAttribute('data-row-index');
        const col = element.getAttribute('data-col-index');
        const arrayIndex = element.getAttribute('data-array-index') || -1;
        if (row && col) {
            const cellRefIndex = +col;
            let cellIndex = cellRefIndex;
            const rowIndex = +row;
            const fieldValue = tableHeader.cells[cellRefIndex].value;
            const cellValue = tableBody.rows[rowIndex].cells[cellRefIndex].value;
            const cellRawValue = tableBody.rows[rowIndex].cells[cellRefIndex].value;

            const value = arrayIndex === -1 ? cellValue : cellRawValue[+arrayIndex];

            // Wish we did not have these if statements for
            // showRowNumbers
            if (showRowNumbers === true) {
                if (cellIndex === 0) {
                    // no click event for row numbers
                    return;
                }
                cellIndex -= 1;
            }

            const payload = {
                cellIndex,
                rowIndex,
                fieldValue,
                cellValue,
                cellRawValue,
                value,
                name: fieldValue,
            };

            tableBody.rows[rowIndex].cells.forEach((c: TableRowCell, index: number) => {
                const rowToken = `row.${tableHeader.cells[index].value}.value`;
                payload[rowToken] = c.value;
            });

            debouncedDrilldown(e.nativeEvent, payload);
        }
    };

    const debouncedOnColumnFormatChange = React.useMemo(
        () =>
            debounce((newFormat: Partial<ColumnFormat>): void => {
                onColumnFormatChange(newFormat);
            }, 300),
        [onColumnFormatChange]
    );

    const handleEventClick = e => {
        // Hack to target the resize handlers, since the only button within a HeadCell is the resize handlers
        if (e.target.tagName.toLowerCase() === 'button') {
            e.stopPropagation();
        }
    };
    const handleKeyDown = e => {
        if (e.keyCode !== 13) {
            return;
        }
        drilldownHandler(e);
    };

    const handleResizeColumn = (_event, payload) => {
        setResizing(true);
        const updatedCF = columns;
        const field = payload.columnId;
        updatedCF[field] = {
            ...updatedCF[field],
            width: Math.max(payload.width, MINIMUM_COLUMN_SIZE),
        };
        setColumns({ ...updatedCF });

        // Updates column json in edit mode
        if (mode === 'edit') {
            debouncedOnColumnFormatChange(updatedCF);
        }
    };

    const fontMapped = fontMap[font] || fontMap.proportional;
    const fontSizeMapped = fontSizeMap[fontSize] || fontSizeMap.default;
    const rowHeightMapped = rowHeightMap[fontSize] || rowHeightMap.default;
    const tableOuterStyle = React.useMemo(
        () => ({ height: '100%', cursor: onCellClick ? 'pointer' : 'unset' }),
        [onCellClick]
    );

    const numberOfHeaders = tableHeader.cells.filter(c => c.showCell).length;
    const numberOfFixedHeaders = tableHeader.cells
        .filter(c => c.showCell)
        .filter(({ value }) => {
            return columns[value]?.width && columns[value]?.width !== 'auto';
        }).length;

    const isFullyFixed = numberOfHeaders === numberOfFixedHeaders;

    return (
        <VizTableContainer backgroundColor={backgroundColor} ref={containerRef}>
            <SUITableContainer
                ref={containerRefInner}
                width={width}
                height={height}
                isPaging={isPaging}
                headerStyle={tableHeader.style}
                font={fontMapped}
                fontSize={fontSizeMapped}
                lineHeight={rowHeightMapped}
                isFullyFixed={isFullyFixed}
            >
                <SUITable
                    resizableFillLayout
                    headType={headerVisibility !== 'none' ? 'inline' : undefined}
                    innerStyle={tableInnerStyle}
                    outerStyle={tableOuterStyle}
                    onClick={tableCellClickHandler}
                    onKeyDown={onCellClick ? handleKeyDown : noop}
                    onRequestResizeColumn={handleResizeColumn}
                >
                    <TableHead>
                        {tableHeader.cells
                            .filter(c => c.showCell)
                            .map(
                                ({ key, value, sortKey, style, ...rest }): React.ReactElement => {
                                    return (
                                        <HeadCell
                                            key={key}
                                            columnId={value}
                                            sortDir={sortKey === currentSortKey ? currentSortDir : 'none'}
                                            width={columns[value]?.width ?? INITIAL_UNSET_COLUMN_WIDTH}
                                            onMouseDown={handleEventClick}
                                            value={value}
                                            onSort={
                                                isResizing || (showRowNumbers && value === '#')
                                                    ? null
                                                    : onSort
                                            }
                                            style={
                                                showRowNumbers && value === '#'
                                                    ? { ...style, cursor: 'auto' }
                                                    : style
                                            }
                                            headerVisibility={headerVisibility}
                                            {...{ sortKey, ...rest }}
                                        />
                                    );
                                }
                            )}
                    </TableHead>
                    <SUITable.Body>
                        {tableBody.rows.map(
                            (row): React.ReactElement => (
                                <SUITable.Row key={row.key}>
                                    {row.cells
                                        .filter(c => c.showCell)
                                        .map(
                                            (cell): React.ReactElement => {
                                                return (
                                                    <SwitchTableCell key={cell.key} type={cell.cellType}>
                                                        <TextCell
                                                            cell={cell}
                                                            useMouseOverEffect={!!onCellClick}
                                                        />
                                                        <SparklineCell
                                                            cell={cell}
                                                            useMouseOverEffect={!!onCellClick}
                                                            width={columns[cell.header]?.width ?? 'auto'}
                                                        />
                                                        <ArrayCell
                                                            cell={cell}
                                                            useMouseOverEffect={!!onCellClick}
                                                        />
                                                    </SwitchTableCell>
                                                );
                                            }
                                        )}
                                </SUITable.Row>
                            )
                        )}
                    </SUITable.Body>
                </SUITable>
            </SUITableContainer>
            {isPaging ? (
                <PaginatorContainer>
                    <Paginator onChange={paginatorOnChange} {...paginatorProps} />
                </PaginatorContainer>
            ) : null}
        </VizTableContainer>
    );
};

const cellDataType = T.oneOfType([T.string, T.number, T.arrayOf(T.oneOfType([T.string, T.number]))]);

PureTable.propTypes = {
    backgroundColor: T.string,
    count: T.number,
    columnFormat: T.objectOf(
        T.shape({
            align: T.arrayOf(T.oneOf(validAlignments)),
            cellTypes: T.arrayOf(T.oneOf(validCellRenderers)),
            data: T.arrayOf(cellDataType),
            rowBackgroundColors: T.arrayOf(T.string),
            rowColors: T.arrayOf(T.string),
            sparklineAreaColors: T.arrayOf(T.string),
            sparklineColors: T.arrayOf(T.string),
            sparklineTypes: T.arrayOf(T.oneOf(validSparklineTypes)),
        })
    ),
    headers: T.arrayOf(T.string),
    headerVisibility: T.oneOf(validHeaderVisibilities),
    height: T.oneOfType([T.number]),
    mode: T.string,
    onCellClick: T.func,
    paginatorParams: PaginatorParamsPropTypes,
    showRowNumbers: T.bool,
    sortParams: SortParamsPropTypes,
    table: T.arrayOf(T.arrayOf(cellDataType)),
    tableFormat: T.shape({
        align: T.arrayOf(T.arrayOf(T.oneOf(validAlignments))),
        cellTypes: T.arrayOf(T.arrayOf(T.oneOf(validCellRenderers))),
        data: T.arrayOf(T.arrayOf(cellDataType)),
        headerBackgroundColor: T.string,
        headerColor: T.string,
        rowBackgroundColors: T.oneOfType([T.arrayOf(T.string), T.arrayOf(T.arrayOf(T.string))]),
        rowColors: T.oneOfType([T.arrayOf(T.string), T.arrayOf(T.arrayOf(T.string))]),
        sparklineAreaColors: T.arrayOf(T.arrayOf(T.string)),
        sparklineColors: T.arrayOf(T.arrayOf(T.string)),
        sparklineTypes: T.arrayOf(T.arrayOf(T.oneOf(validSparklineTypes))),
    }),
    theme: T.any,
    width: T.oneOfType([T.number]),
    onColumnFormatChange: T.func,
    font: T.string,
    fontSize: T.string,
};

PureTable.defaultProps = {
    columnFormat: {},
    headers: [],
    headerVisibility: 'inline' as const,
    height: 300,
    mode: 'view',
    onCellClick: null,
    paginatorParams: {
        isPaging: false,
        onChange: noop,
    },
    showRowNumbers: false,
    sortParams: {
        onSort: noop,
    },
    table: [],
    tableFormat: {},
    theme: {},
    width: 300,
    onColumnFormatChange: noop,
    font: fontMap.proportional,
    fontSize: fontSizeMap.default,
};
PureTable.displayName = 'VizTable';

export default withEmptyTableMessage(withFixedSizeContainer(PureTable)) as any;
