import React, { useEffect, useMemo, useState, useCallback } from 'react';
import styled from 'styled-components';
import type { Mode, VisualizationDefinition } from '@splunk/dashboard-types';
import {
    useCoreOverrides,
    useFeatureFlags,
    usePreset,
    useEventRegistry,
} from '@splunk/dashboard-context';
import {
    sanitizeColor,
    getIconStatus,
    useRiskySearchToast,
    type UseSubscribeToSearchesReturnType,
} from '@splunk/dashboard-ui';
import { toPx } from '@splunk/dashboard-utils';
import {
    triggerEvent,
    updateVizDescription,
    updateVizOptions,
    updateVizTitle,
    useDispatch,
} from '@splunk/dashboard-state';
import VizProgressBar, {
    PROGRESSBAR_HEIGHT,
} from '../components/VizProgressBar';
import VizTitleAndDescription, {
    TITLE_CONTAINER_PADDING,
    TITLE_HEIGHT,
    DESCRIPTION_HEIGHT,
} from '../components/VizTitleAndDescription';
import VizWaitingForInput from '../components/VizWaitingForInput';
import VizStatusIcon from '../components/VizStatusIcon';
import { useVizTelemetry } from '../hooks/useVizTelemetry';
import { useStatusMessage } from '../hooks/useStatusMessage';

// Wrapper for just the Visualization
interface VizWrapperProps {
    width: string | number;
    height?: string | number;
}
const VizWrapper = styled.div.attrs<VizWrapperProps>(({ width, height }) => ({
    'data-test': 'viz-size-wrapper',
    style: {
        width: toPx(width),
        height: toPx(height),
    },
}))<VizWrapperProps>``;

const empty: Record<string, unknown> = {};

/**
 * Display Visualization and its sibling components (Progress, Title, Status)
 * @param {Object} props
 * @param {String} props.id The viz id
 * @param {String} props.mode The editing mode
 * @param {Number} props.visualizationDefinition The viz config
 * @param {Boolean} props.isFullscreen flag for fullscreen mode
 * @param {Function} props.registerApi Callback for vizApi
 * @param {Function} props.onSelected callback for selection
 * @param {Number} props.height The container height
 * @param {Number} props.width The container width
 * @param {Object} props.dataSources Data
 * @param {Boolean} props.loading DataSources state
 * @param {Function} props.updateRequestParams Callback to update request params for DS
 */
export interface VisualizationContentProps
    extends Omit<UseSubscribeToSearchesReturnType, 'refresh'> {
    id: string;
    mode?: Mode;
    width: number | string;
    height?: number | string;
    visualizationDefinition: VisualizationDefinition;
    isFullscreen: boolean;
    registerApi: (ref: unknown) => void;
    onSelected: (
        e: React.MouseEvent,
        visualizationIds: { id: string; type: string }[]
    ) => void;
}

const VisualizationContent = ({
    id,
    mode,
    height,
    width,
    dataSources = {},
    loading,
    updateRequestParams,
    visualizationDefinition: vizDef,
    isFullscreen,
    registerApi,
    onSelected: handleSelection,
}: VisualizationContentProps): JSX.Element => {
    const dispatch = useDispatch();

    const handleEvent = useCallback(
        (eventType, payload, eventId) =>
            dispatch(triggerEvent(id, eventType, payload, eventId)),
        [dispatch, id]
    );

    const onOptionsChange = useCallback(
        (newOptions) => dispatch(updateVizOptions(id, newOptions)),
        [dispatch, id]
    );

    const onTitleChange = useCallback(
        (newTitle) => dispatch(updateVizTitle(id, newTitle)),
        [dispatch, id]
    );

    const onDescriptionChange = useCallback(
        (newDescription) => dispatch(updateVizDescription(id, newDescription)),
        [dispatch, id]
    );

    const {
        type,
        title,
        description,
        options = empty,
        showProgressBar = false,
    } = vizDef;
    const [computedProps, setComputedProps] = useState<{
        backgroundColor?: string;
    }>({});
    const hasStatusIcon = !!getIconStatus(dataSources.primary);
    const eventRegistry = useEventRegistry();
    const preset = usePreset();
    const { showProgressBar: progressFeatureFlag } = useFeatureFlags();
    const { message, showWaitingForInputPlaceholder } = useStatusMessage({
        dataSource: dataSources?.primary,
    });
    const { backgroundColor } = options;
    const { waitingForInput: WaitingForInput = VizWaitingForInput } =
        useCoreOverrides();

    let bgColor =
        computedProps.backgroundColor || (backgroundColor as string) || null;
    if (bgColor) {
        bgColor = sanitizeColor(bgColor);
    }

    // Since we replace the viz with the placeholder when there is a datasource error, we never allow the HOC in the preset to do this for us.
    useRiskySearchToast({ dataSource: dataSources?.primary });

    useVizTelemetry({ width, height, id, loading, type });

    useEffect(() => {
        setComputedProps({});
    }, [type]);

    /**
     * Viz/Preset should have first say of whether the element should be enabled
     * Then determine if user has enabled/disabled in definition
     */
    const { useProgressBar, useTitle } = useMemo(() => {
        const presetFlags =
            preset?.shouldDisplayVisualizationSiblingContent(type);

        return {
            useProgressBar:
                progressFeatureFlag &&
                presetFlags?.showProgressBar &&
                showProgressBar &&
                !showWaitingForInputPlaceholder,
            useTitle:
                presetFlags?.showTitleAndDescription && (title || description),
        };
    }, [
        preset,
        type,
        showProgressBar,
        progressFeatureFlag,
        title,
        description,
        showWaitingForInputPlaceholder,
    ]);

    /**
     * Calculate the heights of all the components that are not the visualization
     * Used to tell the visualization how much room it has to use
     */
    const siblingHeights = useMemo(() => {
        let h = 0;

        if (useProgressBar) {
            // useProgressBar will enable progress bar
            h += PROGRESSBAR_HEIGHT;
        }

        if (useTitle) {
            // TODO: there has to be a better way...
            h += title || description ? TITLE_CONTAINER_PADDING : 0;
            h += title ? TITLE_HEIGHT : 0;
            h += description ? DESCRIPTION_HEIGHT : 0;
        }

        return h;
    }, [useProgressBar, useTitle, title, description]);

    const handleEventTrigger = useCallback(
        ({ type: eventType, originalEvent, payload }) => {
            const eventId = eventRegistry.registerEvent(originalEvent);
            handleEvent(eventType, payload, eventId);
        },
        [eventRegistry, handleEvent]
    );

    const containerWidth = useMemo(
        () => (isFullscreen ? '100vw' : width),
        [isFullscreen, width]
    );

    const containerHeight = useMemo(
        () =>
            isFullscreen
                ? `calc(100vh - ${siblingHeights}px)`
                : Number(height) - siblingHeights || height,
        [isFullscreen, siblingHeights, height]
    );

    // if the height is not a number, and not in fullscreen, it means
    //  it's probably a percentage, ie. 100%. If it's a percent, we need to only
    //  subtract siblingHeights once, otherwise it will continuously get smaller as each
    //  child will do this calculation. Instead we do it once in the parent component
    const VizWrapperHeight =
        Number(containerHeight) || isFullscreen
            ? containerHeight
            : `calc(${height} - ${siblingHeights}px)`;

    // Generate chart
    const GraphicalChart = useMemo(() => {
        if (showWaitingForInputPlaceholder) {
            return null;
        }

        const { eventHandlers } = vizDef;

        const props = {
            ...vizDef,
            hasEventHandlers: eventHandlers && eventHandlers.length > 0,
            width: containerWidth,
            height: containerHeight,
            dataSources,
            loading,
            onRequestParamsChange: updateRequestParams,
            onComputedProps: setComputedProps,
            id,
            mode,
            onEventTrigger: handleEventTrigger,
            vizActionHandlerRef: registerApi,
            visualizationApiRef: registerApi,
            onOptionsChange,
            // need to convert the array of string to an array of objects.
            onSelected: (e: React.MouseEvent, visualizationIds: string[]) =>
                handleSelection(
                    e,
                    visualizationIds.map((vizId: string) => ({
                        id: vizId,
                        type: 'block',
                    }))
                ),
            onTitleChange,
            onDescriptionChange,
            isFullscreen,
        };

        return preset.createVisualization(vizDef.type, props);
    }, [
        preset,
        vizDef,
        dataSources,
        containerHeight,
        isFullscreen,
        loading,
        updateRequestParams,
        containerWidth,
        mode,
        id,
        registerApi,
        handleEventTrigger,
        onOptionsChange,
        handleSelection,
        onTitleChange,
        onDescriptionChange,
        showWaitingForInputPlaceholder,
    ]);

    // Memoize rendering the viz
    const Placeholder = useMemo(() => {
        if (!showWaitingForInputPlaceholder) {
            return null;
        }

        const PlaceholderIcon = preset.getPlaceholderIcon(type);
        return (
            <WaitingForInput
                dataSource={dataSources.primary}
                message={message}
                backgroundColor={bgColor}
                width={containerWidth}
                height={containerHeight}
                PlaceholderIcon={PlaceholderIcon}
            />
        );
    }, [
        showWaitingForInputPlaceholder,
        WaitingForInput,
        dataSources,
        containerWidth,
        containerHeight,
        message,
        bgColor,
        preset,
        type,
    ]);

    // Memoize rendering the title and description
    const TitleAndDescription = useMemo(() => {
        if (!useTitle || (!title && !description)) {
            return null;
        }

        return (
            <VizTitleAndDescription
                title={title}
                description={description}
                backgroundColor={bgColor}
                truncateFirstLine={hasStatusIcon}
            />
        );
    }, [title, description, bgColor, useTitle, hasStatusIcon]);

    // Memoize ProgressBar
    const ProgressBar = useMemo(() => {
        if (!useProgressBar) {
            return null;
        }

        let currentProgress = dataSources?.primary?.meta?.percentComplete ?? 0;

        if (dataSources?.primary?.error?.message) {
            currentProgress = 100;
        }

        return (
            <VizProgressBar
                hasData={!!dataSources.primary}
                percentage={currentProgress}
                backgroundColor={bgColor}
            />
        );
    }, [dataSources.primary, useProgressBar, bgColor]);

    // Memoize Status Icon
    const StatusIcon = useMemo(() => {
        return <VizStatusIcon dataSource={dataSources.primary} id={id} />;
    }, [dataSources.primary, id]);

    return (
        <>
            {ProgressBar}
            {TitleAndDescription}
            <VizWrapper width={containerWidth} height={VizWrapperHeight}>
                {GraphicalChart}
                {Placeholder}
            </VizWrapper>
            {StatusIcon}
        </>
    );
};

export default VisualizationContent;
