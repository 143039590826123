import {
    cloneDeep,
    defaultsDeep,
    each,
    find,
    get,
    isEmpty,
    isEqual,
    isString,
    keyBy,
    memoize,
    omit,
    pickBy,
} from 'lodash';
import {
    computeNewAbsoluteStructureItem,
    computeNewGridStructureItem,
    deepMergeWithDefaults,
    deprecated,
    hashString,
    uniqueId,
    updateRemovedVizNeighbors,
    DEFAULT_CANVAS_HEIGHT,
    DEFAULT_CANVAS_WIDTH,
    DEFAULT_TOKEN_NAMESPACE,
    MAX_CHAIN_LENGTH,
} from '@splunk/dashboard-utils';
import {
    checkDuplicateTokens,
    checkVisualizationsInStructure,
    checkInputsInStructure,
} from '@splunk/dashboard-validation';
import { _ } from '@splunk/ui-utils/i18n';
import type {
    AbsoluteBlockItem,
    AbsoluteLayoutItem,
    ConnectedLineItem,
    DashboardDefaultsDefinition,
    DashboardJSON,
    DataSourceBindingMap,
    DataSourceDefinition,
    DataSourceOptions,
    EventHandlerDefinition,
    InputDefinition,
    LayoutDefinition,
    LayoutItemType,
    LayoutOptions,
    ResolvedTokenNamespaces,
    RootDataSourcesDefinition,
    RootVisualizationsDefinition,
    StructureItem,
    StructureItemType,
    TimeRange,
    VisualizationDefinition,
    VizContract,
} from '@splunk/dashboard-types';
import Ajv from 'ajv';
import type { ErrorObject, ValidateFunction, JSONSchemaType } from 'ajv';
import { compare } from 'fast-json-patch';
import schema from './DashboardSchema';

type ValidationErrors = Partial<ErrorObject>[];

interface UpdateDashboardArgs {
    title?: string;
    desc?: string;
}
type CollectionType = 'visualizations' | 'dataSources' | 'inputs';
interface NewDataSourceConnectionArgs {
    dataSourceType: string;
    dataSourceDefinition: DataSourceDefinition;
}
interface ConnectNewDataSourceToVisualizationArgs
    extends NewDataSourceConnectionArgs {
    vizId: string;
}
interface ConnectNewDataSourceToInputArgs extends NewDataSourceConnectionArgs {
    inputId: string;
}
interface ConnectNewDataSourceToItemArgs extends NewDataSourceConnectionArgs {
    itemId: string;
}
interface ExistingDataSourceConnectionArgs {
    dataSourceType: string;
    dataSourceId: string;
}
interface DataSourceToVisualizationArgs
    extends ExistingDataSourceConnectionArgs {
    vizId: string;
}
interface DataSourceToInputArgs extends ExistingDataSourceConnectionArgs {
    inputId: string;
}
interface DataSourceToItemArgs extends ExistingDataSourceConnectionArgs {
    itemId: string;
    type?: LayoutItemType;
}
type ChainSearchDefinitionList = {
    id: string;
    config: DataSourceDefinition | null;
}[];
interface AddLayoutItemArgs {
    visualizationId?: string;
    vizContract?: VizContract;
}
const generateUniqueId = (prefix: string) => `${prefix}_${uniqueId()}`;
const ajv = new Ajv({
    allErrors: true,
    // This is to make sure the definition `defaults` can have both `properties` and `patternProperties`
    allowMatchingProperties: true,
    // todo: lots of viz schemas violate this rule, will fix them later
    allowUnionTypes: true,
});

// We hash the string representation of the the schema to use as the memoize resolver.
const memoizedCompile = memoize(
    (newSchema) => {
        // Prevent warnings when adding a new schema with the same id
        if (ajv.getSchema(newSchema.$id)) {
            ajv.removeSchema(newSchema.$id);
        }
        return ajv.compile(newSchema);
    },
    (newSchema) => hashString(JSON.stringify(newSchema))
);

/**
 * A utility module to convert layout structure from array to object in dashboard definition.
 * This module is needed when generating meaningful json patches.
 * @private
 * @param {Object} def dashboard definition json object
 */
export const normalizeLayoutStructure = (
    def: DashboardJSON
): Omit<DashboardJSON, 'layout'> & {
    layout: { structure: Record<string, StructureItem> };
} => {
    const layout: LayoutDefinition = get(def, 'layout', {} as LayoutDefinition);
    const structure: StructureItem[] = get(def, 'layout.structure', []);

    // in absolute layout the layer is decided by the order in the array, so we need a temporary solution to reflect this info.
    const structureWithExplicitLayers = structure.map((viz, index) => ({
        ...viz,
        layer: index,
    }));

    return {
        ...def,
        layout: {
            ...layout,
            structure: keyBy(structureWithExplicitLayers, 'item'),
        },
    };
};

export const GLOBAL_TRP_TOKEN_NAME = 'global_time';

export const DEFAULT_DEFINITION: DashboardJSON = {
    visualizations: {},
    dataSources: {},
    defaults: {
        dataSources: {
            global: {
                options: {
                    queryParameters: {
                        latest: `$${GLOBAL_TRP_TOKEN_NAME}.latest$`,
                        earliest: `$${GLOBAL_TRP_TOKEN_NAME}.earliest$`,
                    },
                },
            },
        },
    },
    inputs: {
        input_global_trp: {
            type: 'input.timerange',
            options: {
                token: `${GLOBAL_TRP_TOKEN_NAME}`,
                defaultValue: '-24h@h,now',
            },
            title: _('Global Time Range'),
        },
    },
    layout: {
        type: 'absolute',
        options: {},
        structure: [],
        globalInputs: ['input_global_trp'],
    },
};

/**
 * Fetch a flattened set of global defaults for the given type
 * @param {Object} defaults The object containing default configurations
 * @param {String} type The type of dataSource to search for specific configurations
 * @returns {Object} Result of flattening global and specific configurations
 * @private
 */
export const getDefaultOptionsForDataSourceType = (
    defaults?: DashboardDefaultsDefinition,
    type?: string
): DataSourceOptions => {
    if (!defaults || !type) {
        return {};
    }

    // Get the options that apply to all datasources
    const globalOptions: DataSourceOptions = (defaults?.dataSources?.global
        ?.options ?? {}) as DataSourceOptions;

    // Get type specific options
    const typeSpecificOptions: DataSourceOptions = get(
        defaults,
        ['dataSources', type, 'options'],
        {}
    );

    // merge global options onto specific options
    const flattenedOptions: DataSourceOptions = defaultsDeep(
        {},
        typeSpecificOptions,
        globalOptions
    );

    // Don't include "query"
    return omit(flattenedOptions, ['query']);
};

/**
 * Parses out all static tokens defined in defaults, and removes any in reserved namespaces
 * @param {DashboardJSON} definition The current dashboard definition
 * @param {Set} reservedNamespaces The list of namespaces the user may NOT override
 * @returns {ResolvedTokenNamespaces} a tokenBinding
 */
export const getDefaultStaticTokens = (
    definition: DashboardJSON,
    reservedNamespaces = new Set<string>()
): ResolvedTokenNamespaces => {
    const tokenDefaults = definition.defaults?.tokens;
    const tokens: Record<string, Record<string, string>> = {};
    if (!tokenDefaults) {
        return tokens;
    }

    Object.keys(tokenDefaults).forEach((namespace) => {
        if (!reservedNamespaces.has(namespace)) {
            tokens[namespace] ??= {};
            Object.keys(tokenDefaults[namespace]).forEach((token) => {
                const { value } = tokenDefaults[namespace][token];
                if (typeof value === 'string') {
                    tokens[namespace][token] = value;
                }
            });
        }
    });

    return tokens;
};

/**
 * Generate a unique id given a prefix and a search function to verify uniqueness
 * @param {String} prefix The prefix of the id (viz, ds, input, etc)
 * @param {Function} searchFn A function that can search the definition to detect if an id is used, default return first id
 * @returns {String} A "guaranteed" unique id
 */
export const generateId = (
    prefix: string,
    searchFn: (nextId: string) => unknown | null = () => false
): string => {
    let nextId = generateUniqueId(prefix);
    while (searchFn(nextId)) {
        nextId = generateUniqueId(prefix);
    }
    return nextId;
};

/**
 * Search for a key or string that matches the provided key
 * @param {Object} definition The haystack to search for an id in
 * @param {String} newId The key to search for
 * @returns {Boolean}
 */
export const hasDuplicateId = (
    definition: DashboardJSON,
    newId: string
): boolean =>
    // Wrap in quotes to look for keys and not substrings
    JSON.stringify(definition).indexOf(`"${newId}"`) !== -1;

type VisualizationDefaults = Pick<
    VisualizationDefinition,
    'showLastUpdated' | 'showProgressBar' | 'context'
>;

/**
 * Obtain the global defaults for all visualizations
 * @param {DefinitionJSON.defaults} defaults The defaults configuration for the dashboard
 * @returns {Object} Shared context and flags
 */
export const getGlobalDefaultsForVisualizations = (
    defaults: DashboardDefaultsDefinition
): VisualizationDefaults => {
    const {
        showProgressBar = false,
        showLastUpdated = false,
        context = {},
    } = defaults?.visualizations?.global ?? {};

    // Intentionally not allowing a shared options object for visualizations
    return {
        showProgressBar,
        showLastUpdated,
        context,
    };
};

type VisualizationTypeDefaults = Partial<VisualizationDefaults> & {
    options?: Record<string, unknown>;
};

/**
 * Fetch the defaults for the given viz type. Note: this does not flatten with global defaults for all viz
 * @param {DashboardJSON.defaults} defaults Default configuration
 * @param {String} type The type of visualization
 * @return {Object} Shared context, options, and flags
 */
export const getDefaultsForVisualizationType = (
    defaults: DashboardDefaultsDefinition,
    type: string
): VisualizationTypeDefaults => {
    const {
        showProgressBar,
        showLastUpdated,
        context = {},
        options = {},
    } = defaults?.visualizations?.[type] ?? {};

    const result: VisualizationTypeDefaults = { options, context };
    // Do not add these flags to the viz defaults unless they are defined,
    // we don't want to override the global defaults unless explicitly defined here
    if (typeof showProgressBar === 'boolean') {
        result.showProgressBar = showProgressBar;
    }
    if (typeof showLastUpdated === 'boolean') {
        result.showLastUpdated = showLastUpdated;
    }

    return result;
};

/**
 * A dashboard definition helper class
 * @class DashboardDefinition
 */
class DashboardDefinition {
    private definition: DashboardJSON;

    validateDefinition?: ValidateFunction;

    /**
     * Creates a new DashboardDefinition based on input def
     * @method fromJSON
     * @param {Object} [def] A dashboard definition
     * @returns {DashboardDefinition}
     * @static
     */
    static fromJSON(def: DashboardJSON = {}): DashboardDefinition {
        return new DashboardDefinition(def);
    }

    /**
     * @param {Object} dataSources Datasources from dashboard definition
     * @param {Object} defaults Defaults from dashboard definition
     * @return {Object} Flattened data sources with the default options.
     */
    static flattenDataSources(
        dataSources: RootDataSourcesDefinition,
        defaults: DashboardDefaultsDefinition
    ): RootDataSourcesDefinition {
        const flattenedGlobalOptions: DataSourceOptions = {};
        const dataSourcesWithDefaults: RootDataSourcesDefinition = {};

        // Loop through all datasources
        each(dataSources, (dataSourceDef, dsId) => {
            const dataSourceType = get(dataSourceDef, 'type');
            const optionsCopy = cloneDeep(get(dataSourceDef, 'options'));

            // memoize calculation of the defaults for a given type
            if (!flattenedGlobalOptions[dataSourceType]) {
                flattenedGlobalOptions[dataSourceType] =
                    getDefaultOptionsForDataSourceType(
                        defaults,
                        dataSourceType
                    );
            }

            const defaultOptions = flattenedGlobalOptions[dataSourceType];

            // Merge specific options with flattened global defaults
            dataSourcesWithDefaults[dsId] = {
                ...dataSourceDef,
                options: defaultsDeep(optionsCopy, defaultOptions),
            };
        });

        return dataSourcesWithDefaults;
    }

    /**
     * Returns a configuration for all visualizations with global defaults flattened in
     * @param {Object} visualizations Visualizations from dashboard definition
     * @param {Object} defaults Defaults from the dashboard definition
     * @return {Object} Flattened visualizations with the global context, options, and flags
     */
    static flattenVisualizations(
        visualizations: RootVisualizationsDefinition,
        defaults: DashboardDefaultsDefinition
    ): RootVisualizationsDefinition {
        const vizTypeDefaults = new Map<string, VisualizationTypeDefaults>();
        const globalDefaults = getGlobalDefaultsForVisualizations(defaults);
        const flattenedVisualizations: RootVisualizationsDefinition = {};

        each(visualizations, (vizDef, vizId) => {
            if (!vizTypeDefaults.has(vizDef.type)) {
                const typeDefaults = getDefaultsForVisualizationType(
                    defaults,
                    vizDef.type
                );
                vizTypeDefaults.set(vizDef.type, typeDefaults);
            }
            const vizDefaults = vizTypeDefaults.get(vizDef.type);
            // deeply cascade settings with the precedent order highest to lowest of vizDef, vizTypeDefault, vizGlobalDefault
            flattenedVisualizations[vizId] = defaultsDeep(
                {},
                vizDef,
                vizDefaults,
                globalDefaults
            );
        });

        return flattenedVisualizations;
    }

    /**
     * Creates a new DashboardDefinition based on input def
     * @param {Object} [def] A dashboard definition
     * @returns {DashboardDefinition}
     * @constructor
     */
    constructor(def: DashboardJSON = {}) {
        this.definition = def;
    }

    /**
     * set up customized schema
     * @method setSchema
     * @param {Object} newSchema
     * @returns {Object} error
     */
    setSchema(newSchema: JSONSchemaType<DashboardJSON>): Error | null {
        deprecated(
            'This method is deprecated, please use the DashboardValidator class validation methods from @splunk/dashboard-validation'
        );
        if (!newSchema.$id) {
            return null;
        }

        try {
            this.validateDefinition = memoizedCompile(newSchema);
        } catch (error) {
            return error as Error;
        }
        return null;
    }

    /**
     * check duplication of inputs tokens
     * @method checkDuplicateTokens
     * @returns {ValidationErrors} errors
     * @see @splunk/dashboard-validation checkDuplicateTokens
     */
    checkDuplicateTokens = (): ValidationErrors =>
        checkDuplicateTokens(this.definition);

    /**
     * check visualizations in layout structure
     * @method checkVisualizationsInStructure
     * @returns {ValidationErrors} errors
     * @see @splunk/dashboard-validation checkVisualizationsInStructure
     */
    checkVisualizationsInStructure = (): ValidationErrors =>
        checkVisualizationsInStructure(this.definition);

    /**
     * check inputs in global and layout structure
     * @method checkInputsInStructure
     * @returns {ValidationErrors} errors
     * @see @splunk/dashboard-validation checkInputsInStructure
     */
    checkInputsInStructure = (): ValidationErrors =>
        checkInputsInStructure(this.definition);

    /**
     * Validates the current definition
     * @method validate
     * @returns {Array} list of errors, or null
     */
    validate(): ErrorObject[] | ValidationErrors | null {
        if (!this.validateDefinition) {
            this.setSchema(schema);
        } else {
            // only provide deprecation warning once, setSchema provides its own deprecation warning
            deprecated(
                'This method is deprecated, please use the DashboardValidator class validation methods from @splunk/dashboard-validation'
            );
        }

        const valid = this.validateDefinition?.(this.definition);

        if (!valid && this.validateDefinition?.errors?.length) {
            return cloneDeep(this.validateDefinition.errors);
        }

        const res = [
            ...this.checkDuplicateTokens(),
            ...this.checkVisualizationsInStructure(),
            ...this.checkInputsInStructure(),
        ];

        if (!isEmpty(res)) {
            return res;
        }

        return null;
    }

    /**
     * Update the dashboard title or description
     * @method updateDashboard
     * @param {String} title The new title
     * @param {String} desc The new description
     * @returns {DashboardDefinition}
     */
    updateDashboard({ title, desc }: UpdateDashboardArgs): DashboardDefinition {
        if (isString(title)) {
            this.definition = {
                ...this.definition,
                title: (title && title.trim()) || '',
            };
        }
        if (desc) {
            this.definition = {
                ...this.definition,
                description: desc,
            };
        }
        return this;
    }

    /**
     * Add a new datasource configuration
     * @method addDataSource
     * @param {String} dsId     The key to identify the datasource
     * @param {Object} dsDef    The configuration
     * @returns {DashboardDefinition}
     */
    addDataSource(
        dsId: string,
        dsDef?: DataSourceDefinition
    ): DashboardDefinition {
        if (!dsDef) {
            return this;
        }

        this.definition = {
            ...this.definition,
            dataSources: {
                ...this.definition.dataSources,
                [dsId]: dsDef,
            },
        };
        return this;
    }

    /**
     * Add a visualization configuration to the definition
     * @method addVisualization
     * @param {String} vizId    The key to identify the vis
     * @param {Object} vizDef   The configuration of the vis
     * @returns {DashboardDefinition}
     */
    addVisualization(
        vizId: string,
        vizDef?: VisualizationDefinition
    ): DashboardDefinition {
        return this.updateVisualization(vizId, vizDef);
    }

    /**
     * Add an input config to the definition
     * @method addInput
     * @param {String} inputId      Key to identify the input
     * @param {Object} [inputDef]     The input config
     * @returns {DashboardDefinition}
     */
    addInput(inputId: string, inputDef?: InputDefinition): DashboardDefinition {
        // All inputs require type and options.token, set these if not provided
        const token = get(inputDef, 'options.token', this.nextTokenId());
        const type = get(inputDef, 'type', 'input.text');

        this.definition = {
            ...this.definition,
            inputs: {
                ...this.definition.inputs,
                [inputId]: {
                    ...inputDef,
                    type,
                    options: {
                        ...inputDef?.options,
                        token,
                    },
                },
            },
        };

        return this;
    }

    /**
     * Adds a new input to the global inputs array
     * @param {String} inputId The id for the input
     * @returns {DashboardDefinition}
     */
    addInputToLayout(inputId: string): DashboardDefinition {
        const globalInputs = this.getGlobalInputs();
        return this.updateGlobalInputs([...globalInputs, inputId]);
    }

    /**
     * removes the input from the global inputs array
     * @param {String} inputId The inputId to remove
     * @returns {DashboardDefinition}
     */
    removeInputFromLayout(inputId: string): DashboardDefinition {
        const filteredGlobalInputs = this.getGlobalInputs().filter(
            (id) => id !== inputId
        );
        return this.updateGlobalInputs(filteredGlobalInputs);
    }

    /**
     * Sets global inputs to passed array
     * @param {String[]} newGlobalInputs Array of input ids
     * @returns {DashboardDefinition}
     */
    updateGlobalInputs(newGlobalInputs: string[] = []): DashboardDefinition {
        this.definition = {
            ...this.definition,
            layout: {
                type: 'absolute', // overwritten by next line unless not defined
                ...this.definition.layout,
                globalInputs: newGlobalInputs,
            },
        };

        return this;
    }

    /**
     * Clones a datasource configuration
     * @method cloneDataSource
     * @param {String} dsId     Key to identify datasource
     * @returns {String} The newly created DatasourceId
     */
    cloneDataSource(dsId: string): string | null {
        let dsDefinition = this.getDataSource(dsId);

        if (!dsDefinition) {
            return null;
        }

        const { name } = dsDefinition;
        const copyRegex = / copy (\d+)$/;

        if (name) {
            const pureDSName = name.replace(copyRegex, '');

            const dataSourceNames = Object.values(
                this.definition.dataSources as RootDataSourcesDefinition
            ).map((ds) => ds.name);

            const copiesOfDS = dataSourceNames.filter((ds) =>
                ds?.includes(pureDSName)
            );

            const finalCopy = copiesOfDS.reduce((latestCopy, currentCopy) => {
                const match = currentCopy?.match(copyRegex);
                const nextCopy = match ? parseInt(match[1], 10) + 1 : 1;
                return Math.max(latestCopy, nextCopy);
            }, 1);

            dsDefinition = {
                ...dsDefinition,
                name: `${pureDSName} copy ${finalCopy}`,
            };
        }

        const newDatasourceId = `${this.nextDataSourceId()}_${dsId}`;

        this.addDataSource(newDatasourceId, dsDefinition);

        return newDatasourceId;
    }

    cloneDataSourcesFromItemDef(
        def: InputDefinition | VisualizationDefinition
    ): DataSourceBindingMap | undefined {
        if (isEmpty(def?.dataSources)) {
            return undefined;
        }

        const dataSources: DataSourceBindingMap = {};
        each(def.dataSources, (dataSourceId, dataSourceType) => {
            if (this.getDataSource(dataSourceId)) {
                const newDatasourceId = this.cloneDataSource(
                    dataSourceId
                ) as string;
                dataSources[dataSourceType] = newDatasourceId;
            }
        });

        return dataSources;
    }

    /**
     * Clones a Visualization configuration
     * @method cloneVisualization
     * @param {String} vizId     Key to identify Viz
     * @param {String} newVizId  Key for new cloned Viz
     * @returns {String} The newly created VizId
     */
    cloneVisualization(vizId: string, newVizId?: string): string | null {
        if (!newVizId) {
            return null;
        }

        const vizDef = this.getVisualization(vizId);

        if (!vizDef) {
            return null;
        }

        const newVizDef = {
            ...vizDef,
        };

        const dataSources = this.cloneDataSourcesFromItemDef(vizDef);
        if (dataSources) {
            newVizDef.dataSources = dataSources;
        }

        this.addVisualization(newVizId, newVizDef);

        return newVizId;
    }

    /**
     * Clones an input configuration
     * @method cloneInput
     * @param {String} inputId Key to identify the source input
     * @param {String} newVizId  Key for new cloned input
     * @returns {String} The newly created input Id
     */
    cloneInput(inputId: string, newInputId?: string): string | null {
        if (!newInputId) {
            return null;
        }

        const inputDef = this.getInput(inputId);
        if (!inputDef) {
            return null;
        }

        const newInputDef = cloneDeep(inputDef);

        // Give the cloned input a new token
        newInputDef.options ??= {};
        newInputDef.options.token = this.nextTokenId();

        // Clone linked data sources if they exist
        const dataSources = this.cloneDataSourcesFromItemDef(inputDef);
        if (dataSources) {
            newInputDef.dataSources = dataSources;
        }
        this.addInput(newInputId, newInputDef);

        return newInputId;
    }

    /**
     * Removes a datasource configuration
     * @method removeDataSource
     * @param {String} dsId     Key to identify datasource
     * @returns {DashboardDefinition}
     */
    removeDataSource(dsId: string): DashboardDefinition {
        this.definition = {
            ...this.definition,
            dataSources: omit(this.definition.dataSources, [dsId]),
        };
        return this;
    }

    /**
     * Removes a visualization configuration
     * @method removeVisualization
     * @param {String} vizId key to identify visualization
     * @returns {DashboardDefinition}
     */
    removeVisualization(vizId: string): DashboardDefinition {
        // TODO: also remove from structure?
        this.definition = {
            ...this.definition,
            visualizations: omit(this.definition.visualizations, [vizId]),
        };
        return this;
    }

    /**
     * Removes an input configuration
     * @method removeInput
     * @param {String} inputId key to identify input
     * @returns {DashboardDefinition}
     */
    removeInput(inputId: string): DashboardDefinition {
        this.definition = {
            ...this.definition,
            inputs: omit(this.definition.inputs, [inputId]),
        };
        return this;
    }

    /**
     * Remove a default token value from the definition
     * @method removeDefaultStaticToken
     * @param {String} tokenName        String name of the token
     * @param {String} namespace        The targeted namespace
     * @returns {DashboardDefinition}
     */
    removeDefaultStaticToken({
        tokenName,
        namespace = DEFAULT_TOKEN_NAMESPACE,
    }: {
        tokenName: string;
        namespace?: string;
    }): DashboardDefinition {
        // Needed because this.definition is a frozen object
        const newDefinition = cloneDeep(this.definition);

        // Clean up the token
        if (newDefinition.defaults?.tokens?.[namespace]) {
            delete newDefinition.defaults.tokens[namespace][tokenName];
        }
        // clean up the namespace
        if (
            newDefinition.defaults?.tokens?.[namespace] &&
            isEmpty(newDefinition.defaults.tokens[namespace])
        ) {
            delete newDefinition.defaults.tokens[namespace];
        }
        // cleanup tokens
        if (
            newDefinition.defaults?.tokens &&
            isEmpty(newDefinition.defaults.tokens)
        ) {
            delete newDefinition.defaults.tokens;
        }
        // cleanup defaults
        if (newDefinition.defaults && isEmpty(newDefinition.defaults)) {
            delete newDefinition.defaults;
        }

        this.definition = newDefinition;

        return this;
    }

    /**
     * Update the layout to a different type
     * @method updateLayoutType
     * @param {String} type     Layout type, e.g. grid, absolute
     * @returns {DashboardDefinition}
     */
    updateLayoutType(type: string): DashboardDefinition {
        this.definition = {
            ...this.definition,
            layout: {
                ...this.definition.layout,
                type,
            },
        };
        return this;
    }

    /**
     * Replaces current layout options config
     * @method updateLayoutOptions
     * @param {Object} layoutOptions Options object to replace existing def
     * @returns {DashboardDefinition}
     */
    updateLayoutOptions(layoutOptions: LayoutOptions): DashboardDefinition {
        this.definition = {
            ...this.definition,
            layout: {
                type: 'absolute', // overwritten by next line if defined
                ...this.definition.layout,
                options: layoutOptions,
            },
        };
        return this;
    }

    /**
     * Replace current structure with a new one
     * @method updateLayoutStructure
     * @param {Array} newStructure List of vis layout item position data
     * @returns {DashboardDefinition}
     */
    updateLayoutStructure(newStructure: unknown): DashboardDefinition {
        this.definition = {
            ...this.definition,
            layout: {
                type: 'absolute',
                ...this.definition.layout,
                structure: newStructure,
            },
        };
        return this;
    }

    /**
     * Replace a visualization config
     * @method updateVisualization
     * @param {String} vizId  key to identify vis
     * @param {Object} vizDef Visualization definition
     * @returns {DashboardDefinition}
     */
    updateVisualization(
        vizId: string,
        vizDef?: VisualizationDefinition
    ): DashboardDefinition {
        if (!vizDef) {
            return this;
        }

        this.definition = {
            ...this.definition,
            visualizations: {
                ...this.definition.visualizations,
                [vizId]: vizDef,
            },
        };
        return this;
    }

    /**
     * Replaces existing datasource config
     * @method updateDataSource
     * @param {String} dsId     key to identify datasource
     * @param {Object} [dsDef]    The datasource definition
     * @returns {DashboardDefinition}
     */
    updateDataSource(
        dsId: string,
        dsDef?: DataSourceDefinition
    ): DashboardDefinition {
        if (
            !dsDef ||
            isEqual(dsDef, get(this.definition, ['dataSources', dsId]))
        ) {
            // if the config is the same, don't create a new definition
            return this;
        }
        this.definition = {
            ...this.definition,
            dataSources: {
                ...this.definition.dataSources,
                [dsId]: dsDef,
            },
        };
        return this;
    }

    /**
     * Replaces existing input config
     * @method updateInput
     * @param {String} inputId key to identify input
     * @param {Object} inputDef New input config
     * @returns {DashboardDefinition}
     */
    updateInput(
        inputId: string,
        inputDef?: InputDefinition
    ): DashboardDefinition {
        if (!inputDef) {
            return this;
        }

        this.definition = {
            ...this.definition,
            inputs: {
                ...this.definition.inputs,
                [inputId]: inputDef,
            },
        };
        return this;
    }

    /**
     * Set a default token value in the definition
     * @method setDefaultStaticToken
     * @param {String} tokenName        String name of the token
     * @param {String} defaultValue     The token's default value
     * @param {String} namespace        The targeted namespace
     * @returns {DashboardDefinition}
     */
    setDefaultStaticToken({
        tokenName,
        defaultValue,
        namespace = DEFAULT_TOKEN_NAMESPACE,
    }: {
        tokenName: string;
        defaultValue: string;
        namespace?: string;
    }): DashboardDefinition {
        if (defaultValue) {
            this.definition = {
                ...this.definition,
                defaults: {
                    ...this.definition.defaults,
                    tokens: {
                        ...this.definition.defaults?.tokens,
                        [namespace]: {
                            ...this.definition.defaults?.tokens?.[namespace],
                            [tokenName]: {
                                value: defaultValue,
                            },
                        },
                    },
                },
            };
        } else {
            this.removeDefaultStaticToken({
                tokenName,
                namespace,
            });
        }
        return this;
    }

    /**
     * Return the JSON representation of the dashboard
     * @method toJSON
     * @returns {Object}
     */
    toJSON(): DashboardJSON {
        return this.definition;
    }

    /**
     * Get the item's options
     * @method getItemOptions
     * @param {String} id The id of the item
     * @returns {Object | undefined} The item's options, if found
     */
    getItemOptions(id: string) {
        return (this.getVisualization(id) ?? this.getInput(id))?.options;
    }

    /**
     * Get the current layout structure
     * @method getLayoutStructure
     * @returns {Array} The current structure
     */
    getLayoutStructure(): AbsoluteLayoutItem[] {
        const structure = get(this.definition, 'layout.structure');

        return Array.isArray(structure) ? structure : [];
    }

    /**
     * return current layout type
     */
    getLayoutType(): string | undefined {
        return this.definition.layout?.type;
    }

    getValidatedLayoutType(): string {
        const layoutType = this.getLayoutType();
        if (layoutType !== 'grid' && layoutType !== 'absolute') {
            throw new TypeError(`${layoutType} is not supported by this api`);
        }

        return layoutType;
    }

    /**
     * Return the current options for the layout
     * @method getLayoutOptions
     * @returns {Object}
     */
    getLayoutOptions(): LayoutOptions {
        return get(this.definition, 'layout.options') || {};
    }

    /**
     * Fetch the current definition for a visualization by id
     * @method getVisualization
     * @param {String} visId    The identifier for the vis
     * @returns {Object} The vis definition, or null if not found
     */
    getVisualization(visId: string): VisualizationDefinition | null {
        return get(this.definition, `visualizations["${visId}"]`, null);
    }

    /**
     * Fetch the current definition for a visualization by id with all the global defaults flattened in
     * @param {String} vizId The identifier for the visualization
     * @returns {Object} The viz definition, or null if not found.
     */
    getVisualizationWithFlattenedDefaults(
        vizId: string
    ): VisualizationDefinition | null {
        const vizDef = this.getVisualization(vizId);
        if (!vizDef) {
            return null;
        }
        const defaults = this.getDefaults();
        const globalDefaults = getGlobalDefaultsForVisualizations(defaults);
        const typeDefaults = getDefaultsForVisualizationType(
            defaults,
            vizDef.type
        );

        return deepMergeWithDefaults(vizDef, typeDefaults, globalDefaults);
    }

    /**
     * Fetch all the visualization ids
     * @method getVisualizationIds
     * @returns {Array} All the Viz Ids
     */
    getVisualizationIds(): string[] {
        return Object.keys(get(this.definition, 'visualizations', {}));
    }

    /**
     * Fetch the current definition for a datasource by id
     * @param {String} dsId
     * @returns {Object} The datasource definition, or null if not found
     */
    getDataSource(dsId: string): DataSourceDefinition | null {
        return get(this.definition, `dataSources["${dsId}"]`, null);
    }

    /**
     * Returns true if datasource can be a base datasource for a chain datasource
     * @param {String} dsId
     * @param {Number} count
     * @returns {Boolean}
     */
    canBeBaseDataSource(dsId: string, count = 0): boolean {
        const parentDsId = get(this.getDataSource(dsId), 'options.extend', '');
        const isValidLength = count < MAX_CHAIN_LENGTH;
        return parentDsId && isValidLength
            ? this.canBeBaseDataSource(parentDsId, count + 1)
            : isValidLength;
    }

    /**
     * Returns datasources which can be a base datasource for a chain datasource
     * @returns {Object} Map of objects of form { dataSourceId: dataSourceDefinition }
     */
    getBaseDataSources(): RootDataSourcesDefinition {
        const dataSources = get(this.definition, 'dataSources', {});
        return pickBy(dataSources, (_dsDef, dsId) =>
            this.canBeBaseDataSource(dsId)
        );
    }

    /**
     * Fetch the default options for a datasource by id
     * @param {String} dsId
     * @returns {Object} The datasource default options
     */
    getDataSourceDefaultOptions(dsId: string): DataSourceOptions | null {
        const dataSourceType = get(this.getDataSource(dsId), 'type');

        return dataSourceType
            ? getDefaultOptionsForDataSourceType(
                  this.getDefaults(),
                  dataSourceType
              )
            : null;
    }

    /**
     * Get an input configuration for the provided id
     * @param {String} inputId The id for the desired input
     * @returns {Object} Returns config or null if not found
     */
    getInput(inputId: string): InputDefinition | null {
        return get(this.definition, ['inputs', inputId], null);
    }

    /**
     * Get the list of displayed inputs in the global input area
     * @returns {String[]}
     */
    getGlobalInputs(): string[] {
        // Return a copy of the array, so it can't be accidentally mutated
        return get(this.definition, 'layout.globalInputs', []).slice();
    }

    /**
     * Get an input that uses the provided token
     * @param {String} tokenId The token to look for
     * @returns {Object} The config for the first input that sets data with the provided token
     */
    getInputByToken(tokenId?: string): InputDefinition | null {
        if (!tokenId) {
            return null;
        }

        const inputs = get(this.definition, 'inputs');

        return (
            find(
                inputs,
                (config) => get(config, 'options.token') === tokenId
            ) || null
        );
    }

    /**
     * Get the type for the given visualization
     * @method getVisualizationType
     * @param {String} vizId key to identify the visualization
     * @returns {String | null}
     */
    getVisualizationType(
        vizId: string
    ): VisualizationDefinition['type'] | null {
        return this.definition.visualizations?.[vizId]?.type || null;
    }

    /**
     * Get the type for the given visualization
     * @method getInputType
     * @param {String} inputId key to identify the input
     * @returns {String | null}
     */
    getInputType(inputId: string): InputDefinition['type'] | null {
        return this.definition.inputs?.[inputId]?.type || null;
    }

    /**
     * Get the type for the given visualization
     * @method getItemPresetType
     * @param {String} itemId key to identify the item
     * @returns {String | undefined}
     */
    getItemPresetType(
        item: string
    ): InputDefinition['type'] | VisualizationDefinition['type'] | undefined {
        return (
            this.getVisualizationType(item) ||
            this.getInputType(item) ||
            undefined
        );
    }

    /**
     * return event handler array
     * @param {String} hostId node id, can be searchId, vizId or inputId
     * @param {String} [type='visualizations'] can be visualizations, dataSources or inputs
     */
    getEventHandlers(
        hostId?: string,
        type: CollectionType = 'visualizations'
    ): EventHandlerDefinition[] {
        if (!hostId) {
            return [];
        }

        const host = get(this.definition, [type, hostId], null);
        return (
            (host && Array.isArray(host.eventHandlers) && host.eventHandlers) ||
            []
        );
    }

    /**
     *
     * @param {String} hostId node id, can be search id, viz id or input id
     * @param {Object} handler eventhandler
     * @param {String} [type='visualizations'] can be visualizations, dataSources or inputs
     */
    createEventHandler(
        hostId: string,
        handler: EventHandlerDefinition,
        type: CollectionType = 'visualizations'
    ): DashboardDefinition {
        const host = get(this.definition, [type, hostId], null);
        if (host == null) {
            return this;
        }
        const eventHandlers = [...this.getEventHandlers(hostId, type), handler];
        this.definition = {
            ...this.definition,
            [type]: {
                ...this.definition[type],
                [hostId]: {
                    ...this.definition[type]?.[hostId],
                    eventHandlers,
                },
            },
        };
        return this;
    }

    /**
     *
     * @param {String} hostId node id, can be search id, viz id or input id
     * @param {Number} handlerIdx handler index
     * @param {String} [type='visualizations'] can be visualizations, dataSources or inputs
     */
    removeEventHandler(
        hostId: string,
        handlerIdx = 0,
        type: CollectionType = 'visualizations'
    ): DashboardDefinition {
        const host = get(this.definition, [type, hostId], null);
        if (host == null) {
            return this;
        }
        const eventHandlers = [...this.getEventHandlers(hostId, type)];
        // delete 1 element at handlerIdx
        eventHandlers.splice(handlerIdx, 1);
        this.definition = {
            ...this.definition,
            [type]: {
                ...this.definition[type],
                [hostId]: {
                    ...this.definition[type]?.[hostId],
                    eventHandlers,
                },
            },
        };
        return this;
    }

    /**
     *
     * @param {String} hostId node id, can be search id, viz id or input id
     * @param {Number} [handlerIdx=0] handler index
     * @param {Object} [handler={}] new handler
     * @param {String} [type='visualizations'] can be visualizations, dataSources or inputs
     */
    editEventHandler(
        hostId: string,
        handlerIdx = 0,
        handler: EventHandlerDefinition = {} as EventHandlerDefinition,
        type: CollectionType = 'visualizations'
    ): DashboardDefinition {
        const host = get(this.definition, [type, hostId], null);
        if (host == null) {
            return this;
        }
        const eventHandlers = [...this.getEventHandlers(hostId, type)];
        if (handlerIdx >= 0 && handlerIdx <= eventHandlers.length - 1) {
            eventHandlers[handlerIdx] = handler;
            this.definition = {
                ...this.definition,
                [type]: {
                    ...this.definition[type],
                    [hostId]: {
                        ...this.definition[type]?.[hostId],
                        eventHandlers,
                    },
                },
            };
        }
        return this;
    }

    /**
     * connect a visualization with new datasource
     * @param {Object} params
     * @param {String} params.vizId visualization id
     * @param {String} params.dataSourceType dataSource binding type such as primary, annotation
     * @param {Object} params.dataSourceDefinition dataSource definition
     */
    connectNewDataSourceToVisualization({
        vizId,
        dataSourceType,
        dataSourceDefinition,
    }: ConnectNewDataSourceToVisualizationArgs): DashboardDefinition {
        const dataSourceId = this.nextDataSourceId();
        this.addDataSource(dataSourceId, dataSourceDefinition);
        this.connectDataSourceToVisualization({
            vizId,
            dataSourceType,
            dataSourceId,
        });
        return this;
    }

    /**
     * connect an input with new datasource
     * @param {Object} params
     * @param {String} params.inputId inputId id
     * @param {String} params.dataSourceType dataSource binding type such as primary, annotation
     * @param {Object} params.dataSourceDefinition dataSource definition
     */
    connectNewDataSourceToInput({
        inputId,
        dataSourceType,
        dataSourceDefinition,
    }: ConnectNewDataSourceToInputArgs): DashboardDefinition {
        const dataSourceId = this.nextDataSourceId();
        this.addDataSource(dataSourceId, dataSourceDefinition);
        this.connectDataSourceToInput({
            inputId,
            dataSourceType,
            dataSourceId,
        });
        return this;
    }

    /**
     * connect an item with a new datasource
     * @param {Object} params
     * @param {String} params.itemId item id
     * @param {String} params.dataSourceType dataSource binding type such as primary, annotation
     * @param {Object} params.dataSourceDefinition dataSource definition
     */
    connectNewDataSourceToItem({
        itemId,
        dataSourceType,
        dataSourceDefinition,
    }: ConnectNewDataSourceToItemArgs): DashboardDefinition {
        const itemType = this.getItemType(itemId);
        return itemType === 'input'
            ? this.connectNewDataSourceToInput({
                  inputId: itemId,
                  dataSourceType,
                  dataSourceDefinition,
              })
            : this.connectNewDataSourceToVisualization({
                  vizId: itemId,
                  dataSourceType,
                  dataSourceDefinition,
              });
    }

    /**
     * connect a visualization with existing datasource
     * @param {Object} params
     * @param {String} params.vizId visualization id
     * @param {String} params.dataSourceType dataSource binding type such as primary, annotation
     * @param {String} params.dataSourceId existing datasource id
     */
    connectDataSourceToVisualization({
        vizId,
        dataSourceType,
        dataSourceId,
    }: DataSourceToVisualizationArgs): DashboardDefinition {
        const visualization = this.getVisualization(vizId);
        const dataSource = this.getDataSource(dataSourceId);
        if (visualization && dataSource) {
            this.updateVisualization(vizId, {
                ...visualization,
                dataSources: {
                    ...visualization.dataSources,
                    [dataSourceType]: dataSourceId,
                },
            });
        }

        return this;
    }

    /**
     * connect an input with existing datasource
     * @param {Object} params
     * @param {String} params.inputId input id
     * @param {String} params.dataSourceType dataSource binding type such as primary, annotation
     * @param {String} params.dataSourceId existing datasource id
     */
    connectDataSourceToInput({
        inputId,
        dataSourceType,
        dataSourceId,
    }: DataSourceToInputArgs): DashboardDefinition {
        const input = this.getInput(inputId);
        const dataSource = this.getDataSource(dataSourceId);
        if (input && dataSource) {
            this.updateInput(inputId, {
                ...input,
                dataSources: {
                    ...input.dataSources,
                    [dataSourceType]: dataSourceId,
                },
            });
        }

        return this;
    }

    /**
     * connect an item with existing datasource
     * @param {Object} params
     * @param {String} params.itemId item id
     * @param {String} params.dataSourceType dataSource binding type such as primary, annotation
     * @param {String} params.dataSourceId existing datasource id
     * @param {String} [params.type=block] type of item such as 'block', 'input'
     */
    connectDataSourceToItem({
        itemId,
        dataSourceType,
        dataSourceId,
        type = 'block',
    }: DataSourceToItemArgs): DashboardDefinition {
        return type === 'input'
            ? this.connectDataSourceToInput({
                  inputId: itemId,
                  dataSourceType,
                  dataSourceId,
              })
            : this.connectDataSourceToVisualization({
                  vizId: itemId,
                  dataSourceType,
                  dataSourceId,
              });
    }

    /**
     * disconnect a visualization from existing datasource
     * @param {Object} params
     * @param {String} params.vizId visualization id
     * @param {String} params.dataSourceType dataSource binding type such as primary, annotation
     * @param {String} params.dataSourceId existing datasource id
     */
    disconnectDataSourceFromVisualization({
        vizId,
        dataSourceType,
        dataSourceId,
    }: DataSourceToVisualizationArgs): DashboardDefinition {
        const visualization = this.getVisualization(vizId);
        if (visualization && visualization.dataSources) {
            if (visualization.dataSources[dataSourceType] === dataSourceId) {
                this.updateVisualization(vizId, {
                    ...visualization,
                    dataSources: omit(
                        visualization.dataSources,
                        dataSourceType
                    ),
                });
            }
        }
        return this;
    }

    /**
     * disconnect an input from existing datasource
     * @param {Object} params
     * @param {String} params.inputId input id
     * @param {String} params.dataSourceType dataSource binding type such as primary, annotation
     * @param {String} params.dataSourceId existing datasource id
     */
    disconnectDataSourceFromInput({
        inputId,
        dataSourceType,
        dataSourceId,
    }: DataSourceToInputArgs): DashboardDefinition {
        const input = this.getInput(inputId);
        if (input && input.dataSources) {
            if (input.dataSources[dataSourceType] === dataSourceId) {
                this.updateInput(inputId, {
                    ...input,
                    dataSources: omit(input.dataSources, dataSourceType),
                });
            }
        }
        return this;
    }

    /**
     * disconnect item from an existing datasource
     * @param {Object} params
     * @param {String} params.itemId item id
     * @param {String} params.dataSourceType dataSource binding type such as primary, annotation
     * @param {String} params.dataSourceId existing datasource id
     */
    disconnectDataSourceFromItem({
        itemId,
        dataSourceType,
        dataSourceId,
    }: DataSourceToItemArgs): DashboardDefinition {
        const itemType = this.getItemType(itemId);
        return itemType === 'input'
            ? this.disconnectDataSourceFromInput({
                  inputId: itemId,
                  dataSourceType,
                  dataSourceId,
              })
            : this.disconnectDataSourceFromVisualization({
                  vizId: itemId,
                  dataSourceType,
                  dataSourceId,
              });
    }

    /**
     * generate visualization id
     */
    nextVisualizationId(): string {
        return generateId('viz', (id) => this.getVisualization(id));
    }

    /**
     * generate datasource id
     */
    nextDataSourceId(): string {
        return generateId('ds', (id) => this.getDataSource(id));
    }

    /**
     * generate input id
     */
    nextInputId(): string {
        return generateId('input', (id) => this.getInput(id));
    }

    /**
     * generate token id
     */
    nextTokenId(prefix = 'token'): string {
        return generateId(prefix, (id) => this.getInputByToken(id));
    }

    /**
     * Returns the number of Visualizations using the Data Source with passed in dsId
     * @method countVisualizationsUsingDataSource
     * @param {String} dsId     Key to identify datasource
     * @returns {Number}
     */
    countVisualizationsUsingDataSource(dsId: string): number {
        const { visualizations = {} } = this.definition;
        let visualizationUseCount = 0;
        Object.keys(visualizations).forEach((vizId) => {
            const dataSources: DataSourceBindingMap = get(
                visualizations,
                [vizId, 'dataSources'],
                {}
            );
            Object.keys(dataSources).forEach((dataSourceType) => {
                if (dataSources[dataSourceType] === dsId) {
                    visualizationUseCount += 1;
                }
            });
        });
        return visualizationUseCount;
    }

    /**
     * Returns the number of Inputs using the Data Source with passed in dsId
     * @method countInputsUsingDataSource
     * @param {String} dsId     Key to identify datasource
     * @returns {Number}
     */
    countInputsUsingDataSource(dsId: string): number {
        const { inputs = {} } = this.definition;
        let inputUseCount = 0;
        Object.keys(inputs).forEach((inputId) => {
            const dataSources: DataSourceBindingMap = get(
                inputs,
                [inputId, 'dataSources'],
                {}
            );
            Object.keys(dataSources).forEach((dataSourceType) => {
                if (dataSources[dataSourceType] === dsId) {
                    inputUseCount += 1;
                }
            });
        });
        return inputUseCount;
    }

    /**
     * Returns the Data Sources of chain searches using the Data Source with passed in dsId
     * @method getChainSearchesUsingDataSource
     * @param {String} dsId     Key to identify datasource
     * @param {Number} count     running count of current chain length
     * @returns {Array} List of object of form { id: dsId, config: ds} which are descendants of Data Source with dsId with max length of MAX_CHAIN_LENGTH
     */
    getChainSearchesUsingDataSource(
        dsId: string,
        count = 0
    ): ChainSearchDefinitionList {
        const dataSources: RootDataSourcesDefinition = get(
            this.definition,
            'dataSources',
            {}
        );
        const chainSearches: ChainSearchDefinitionList = [];
        each(dataSources, (ds, id) => {
            const parentId = get(ds, 'options.extend', '');
            if (parentId === dsId && count < MAX_CHAIN_LENGTH) {
                chainSearches.push(
                    { id, config: this.getDataSource(id) },
                    ...this.getChainSearchesUsingDataSource(id, count + 1)
                );
            }
        });
        return chainSearches;
    }

    /**
     * Disconnects a dataSource from all visualizations and inputs in the definition
     * @method disconnectDataSource
     * @param {String} dataSourceId     Key to identify datasource
     * @returns {DashboardDefinition}
     */
    disconnectDataSource(dataSourceId: string): DashboardDefinition {
        const { visualizations = {}, inputs = {} } = this.definition;
        Object.keys(visualizations).forEach((vizId) => {
            const dataSources: DataSourceBindingMap = get(
                visualizations,
                [vizId, 'dataSources'],
                {}
            );
            Object.keys(dataSources).forEach((dataSourceType) => {
                this.disconnectDataSourceFromVisualization({
                    vizId,
                    dataSourceType,
                    dataSourceId,
                });
            });
        });
        Object.keys(inputs).forEach((inputId) => {
            const dataSources: DataSourceBindingMap = get(
                inputs,
                [inputId, 'dataSources'],
                {}
            );
            Object.keys(dataSources).forEach((dataSourceType) => {
                this.disconnectDataSourceFromInput({
                    inputId,
                    dataSourceType,
                    dataSourceId,
                });
            });
        });
        return this;
    }

    /**
     * Search for a key or string that matches the provided key
     * @param {String} newId The key to search for
     * @returns {Boolean}
     */
    hasDuplicateId(newId: string): boolean {
        // Wrap in quotes to look for keys and not substrings
        return hasDuplicateId(this.definition, newId);
    }

    /**
     * Converts all instances of an old id to a new id
     * @param {String} oldId The original string to replace
     * @param {String} newId The new string to add
     * @return {Boolean} To show success/fail
     */
    updateComponentId(oldId: string, newId: string): boolean {
        if (
            oldId === newId ||
            !/^(viz|input|ds)_[a-zA-Z0-9-_]+$/.test(newId) ||
            this.hasDuplicateId(newId) ||
            !this.hasDuplicateId(oldId)
        ) {
            return false;
        }

        const stringDef = JSON.stringify(this.definition);
        // Wrapping quotes around the dsId to make sure we target entire keys and not substrings
        const re = new RegExp(`"${oldId}"`, 'g');
        // Wrap newId with quotes to make sure we replace the ones we remove
        this.definition = JSON.parse(stringDef.replace(re, `"${newId}"`));
        return true;
    }

    /**
     * Compare two definitions to generate json patches.
     * @param {DashboardDefinition} other another instance of DashboardDefinition
     * @returns {object[]}
     */
    compare(other: DashboardDefinition): ReturnType<typeof compare> {
        return compare(
            normalizeLayoutStructure(this.toJSON()),
            normalizeLayoutStructure(other.toJSON())
        );
    }

    /**
     * Get the id of first time range input
     * @public
     */
    getFirstTimeRangeInputId(): string {
        return find(
            get(this.definition, ['layout', 'globalInputs'], []),
            // note: `input.timerange` is hardcoded, I can't think of a better way to determine what is a time range input
            (inputId) =>
                get(this.getInput(inputId), 'type') === 'input.timerange'
        );
    }

    /**
     * Get defaults
     * @returns {Object} dashboard defaults
     * @public
     */
    getDefaults(): DashboardDefaultsDefinition {
        return get(this.definition, 'defaults', {});
    }

    /**
     * Get default time range when create a new `ds.search` data source. The rule is:
     * 1. use `defaults` section from the definition if available.
     * 2. use the first time range input token if available
     * 3. use static time range `Last 24 hours`
     * @returns {Object} earliest and latest
     */
    getDefaultTimeRangeForNewSearch(): TimeRange {
        const defaultOptions = getDefaultOptionsForDataSourceType(
            this.getDefaults(),
            'ds.search'
        );
        const defaultQueryParameters = get(
            defaultOptions,
            'queryParameters',
            {}
        ) as TimeRange;

        // prefer definition defaults over hardcoded time range values
        if (defaultQueryParameters.earliest || defaultQueryParameters.latest) {
            return {} as TimeRange;
        }

        const timeRangeInputToken = get(
            this.getInput(this.getFirstTimeRangeInputId()),
            ['options', 'token']
        );
        // if there's at least one time range input, use it
        if (timeRangeInputToken) {
            return {
                earliest: `$${timeRangeInputToken}.earliest$`,
                latest: `$${timeRangeInputToken}.latest$`,
            };
        }

        return {
            earliest: '-24h@h',
            latest: 'now',
        };
    }

    /**
     * Get the visualization layout type - block/line
     * @method getVisualizationLayoutType
     * @param {String} vizId Key to identify visualization
     * @returns {StructureItemType} The viz layout type - line/block, or block if type is not found
     */
    getVisualizationLayoutType(vizId: string): StructureItemType {
        const layoutStructure = this.getLayoutStructure();
        const { type = 'block' } =
            layoutStructure.find((viz) => viz.item === vizId) || {};
        return type;
    }

    /**
     * Get the item type - visualizations are of type 'block' and inputs are of type 'input'
     * @method getItemType
     * @param {String} itemId item id
     * @returns {String} 'block' or 'input'
     */
    getItemType(itemId: string): LayoutItemType {
        return this.getInput(itemId) ? 'input' : 'block';
    }

    /**
     * Makes a best guess try to determine a good position for a new item in absolute/grid layouts.
     * @method addLayoutItem
     * @param {Object} config
     * @param {String} config.visualizationId The id for a visualization which already has a configuration in the definition
     * @param {Object} config.vizContract Metadata defining the default size properties for a viz. Usually defined by presets.
     */
    addLayoutItem({
        visualizationId,
        vizContract,
    }: AddLayoutItemArgs = {}): DashboardDefinition {
        if (!visualizationId) {
            throw new SyntaxError('No visualization id supplied');
        }

        // Make sure we support this layout structure
        const layoutType = this.getValidatedLayoutType();

        // Check that this exists
        const viz = this.getVisualization(visualizationId);
        if (!viz) {
            throw new ReferenceError('Visualization does not exist');
        }

        const layoutItems = this.getLayoutStructure() as AbsoluteBlockItem[];
        if (layoutItems.find((li) => li.item === visualizationId)) {
            throw new Error(
                `${visualizationId} is already in the layout structure`
            );
        }

        const layoutOptions = this.getLayoutOptions();
        const canvasWidth = get(layoutOptions, 'width', DEFAULT_CANVAS_WIDTH);
        let item: AbsoluteBlockItem | ConnectedLineItem | null = null;

        if (layoutType === 'grid') {
            item = computeNewGridStructureItem({
                canvasWidth,
                layoutItems,
                itemId: visualizationId,
            });
        } else {
            // No way around fetching the preset type for this. Lines are special.
            const isLine = get(viz, 'type') === 'abslayout.line';
            const canvasHeight = get(
                layoutOptions,
                'height',
                DEFAULT_CANVAS_HEIGHT
            );
            item = computeNewAbsoluteStructureItem({
                itemId: visualizationId,
                type: isLine ? 'line' : 'block',
                canvasWidth,
                canvasHeight,
                vizContract,
                layoutItems,
            });
        }

        this.updateLayoutStructure([...layoutItems, item]);

        return this;
    }

    addInputToLayoutStructure(inputId: string): DashboardDefinition {
        if (!inputId) {
            throw new SyntaxError('No input id supplied');
        }

        const layoutType = this.getValidatedLayoutType();

        // Check that this exists
        const input = this.getInput(inputId);
        if (!input) {
            throw new ReferenceError('Input does not exist');
        }

        const layoutItems = this.getLayoutStructure() as AbsoluteBlockItem[];
        if (layoutItems.find((li) => li.item === inputId)) {
            throw new Error(`${inputId} is already in the layout structure`);
        }

        const layoutOptions = this.getLayoutOptions();
        const canvasWidth = get(layoutOptions, 'width', DEFAULT_CANVAS_WIDTH);
        let item: AbsoluteBlockItem | ConnectedLineItem | null = null;

        if (layoutType === 'grid') {
            item = computeNewGridStructureItem({
                itemId: inputId,
                type: 'input',
                canvasWidth,
                layoutItems,
            });
        } else {
            const canvasHeight = get(
                layoutOptions,
                'height',
                DEFAULT_CANVAS_HEIGHT
            );
            item = computeNewAbsoluteStructureItem({
                itemId: inputId,
                type: 'input',
                canvasWidth,
                canvasHeight,
                layoutItems,
            });
        }

        this.updateLayoutStructure([...layoutItems, item]);

        return this;
    }

    /**
     * Parses out all static tokens defined in defaults, and removes any in reserved namespaces
     * @param {Set} reservedNamespaces The list of namespaces the user may NOT override
     * @returns {ResolvedTokenNamespaces} a tokenBinding
     */
    getDefaultStaticTokens(
        reservedNamespaces = new Set<string>()
    ): ResolvedTokenNamespaces {
        return getDefaultStaticTokens(this.definition, reservedNamespaces);
    }

    /**
     * Gets the visualization ids that use the provided tokenName in any of its eventHandlers
     * @param {string} tokenName The name of the token being used
     * @returns {string[]} array of visualization ids using the token
     */
    getVisualizationIdsUsingToken(tokenName: string): string[] {
        return this.getVisualizationIds().filter(
            (id) =>
                !!this.getEventHandlers(id).find(
                    (handler) =>
                        !!handler.options?.tokens?.find(
                            (token) => token.token === tokenName
                        )
                )
        );
    }

    isInputOnCanvas(inputId: string): boolean {
        const layoutStructure = this.getLayoutStructure();
        return !!layoutStructure.find(
            (item) => item.item === inputId && item.type === 'input'
        );
    }

    /**
     * Moves the input to the canvas
     * @param {string} inputId The inputId
     * @returns {DashboardDefinition}
     */
    moveInputToCanvas(inputId: string) {
        if (!this.isInputOnCanvas(inputId)) {
            this.addInputToLayoutStructure(inputId);
        }

        this.removeInputFromLayout(inputId);

        return this;
    }

    /**
     * Moves the input to the globalInputs
     * @param {string} inputId The inputId
     * @returns {DashboardDefinition}
     */
    moveInputToGlobalInputs(inputId: string) {
        if (this.isInputOnCanvas(inputId)) {
            this.removeInputFromLayoutStructure(inputId);
        }

        this.addInputToLayout(inputId);

        return this;
    }

    /**
     * @private
     * @method safeRemoveGridItem
     * @description Removes an input from a grid layout and prevents creating a hole between neighbors
     * @param {string} itemId The id of the input to be removed from the grid
     * @returns {DashboardDefinition}
     */
    private safeRemoveGridItem(itemId: string) {
        const items = this.getLayoutStructure();
        const itemToRemove = items.find((value) => value.item === itemId);

        if (!itemToRemove) {
            return this;
        }

        const layoutOptions = this.getLayoutOptions();
        const width = get(layoutOptions, 'width', DEFAULT_CANVAS_WIDTH);

        const updatedItems = updateRemovedVizNeighbors({
            itemToRemove: itemToRemove as AbsoluteBlockItem,
            items: items as AbsoluteBlockItem[],
            width,
        });

        const keys = updatedItems.map((item) => item.item);
        const filteredItems = items.filter(
            (vizItem) =>
                vizItem.item !== itemToRemove.item &&
                keys.indexOf(vizItem.item) < 0
        );

        this.updateLayoutStructure([...updatedItems, ...filteredItems]);
        return this;
    }

    /**
     * Removes the input from the layout.structure
     * @param {inputId} inputId The inputId
     */
    removeInputFromLayoutStructure(inputId: string) {
        if (this.getLayoutType() === 'grid') {
            // need to make sure this does not create holes in the grid
            return this.safeRemoveGridItem(inputId);
        }

        const layoutStructure = [...this.getLayoutStructure()];
        const inputIndex = layoutStructure.findIndex(
            ({ item, type }) => item === inputId && type === 'input'
        );

        if (inputIndex !== -1) {
            layoutStructure.splice(inputIndex, 1);
            this.updateLayoutStructure(layoutStructure);
        }

        return this;
    }
}

export default DashboardDefinition;
