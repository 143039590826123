import React, { useCallback } from 'react';
import styled from 'styled-components';
import { variables, pick } from '@splunk/themes';
import type { HandleDirection } from '@splunk/dashboard-types';
import {
    getVerticalPosition,
    getHorizontalPosition,
} from '../utils/outlineUtils';

const resizeHandleOffset = 7;
/**
 * compute cursor base on resize dir
 * @param {String} dir
 */
const computeCursor = (dir: HandleDirection): string => {
    switch (dir) {
        case 'n':
        case 's':
            return 'ns-resize';
        case 'e':
        case 'w':
            return 'ew-resize';
        case 'ne':
        case 'sw':
            return 'nesw-resize';
        case 'se':
        case 'nw':
        default:
            return 'nwse-resize';
    }
};

interface ContainerProps {
    direction: HandleDirection;
    onMouseDown: React.MouseEventHandler<HTMLAnchorElement>;
}

const Handle = styled.a.attrs<ContainerProps>(({ direction }) => ({
    'data-test': 'handle',
    'data-test-direction': direction,
}))<ContainerProps>`
    width: 10px;
    height: 10px;
    background-color: ${pick({
        enterprise: variables.accentColorL10,
        prisma: variables.interactiveColorPrimary,
    })};
    border-radius: 20%;
    position: absolute;
    ${({ direction }) => getVerticalPosition(direction, resizeHandleOffset)};
    ${({ direction }) => getHorizontalPosition(direction, resizeHandleOffset)};
    cursor: ${({ direction }) => computeCursor(direction)};
    z-index: 999;
    pointer-events: auto;
    user-select: none;
`;

export interface ResizeHandleProps {
    direction: HandleDirection;
    onMouseDown: (e: React.MouseEvent, dir: HandleDirection) => void;
}

export const ResizeHandle = ({
    onMouseDown,
    direction,
}: ResizeHandleProps): JSX.Element => {
    const handleMouseDown = useCallback(
        (e: React.MouseEvent) => {
            onMouseDown(e, direction);
        },
        [onMouseDown, direction]
    );

    return <Handle direction={direction} onMouseDown={handleMouseDown} />;
};
