import * as React from 'react';
import SUITable from '@splunk/react-ui/Table';
import { TableRowCell } from '../hooks';

interface ArrayCellProps {
    cell: TableRowCell;
    useMouseOverEffect: boolean;
}

const noop = () => undefined;

export const ArrayCell = ({ cell, useMouseOverEffect }: ArrayCellProps): React.ReactElement => {
    const value = React.useMemo(() => (Array.isArray(cell.value) ? cell.value : [cell.value]), [cell.value]);
    const cellStyle = React.useMemo(() => ({ ...cell.style }), [cell.style]);
    return (
        <SUITable.Cell
            style={cellStyle}
            align={cell.align}
            data-test-cell-type="ArrayCell"
            onClick={useMouseOverEffect ? noop : undefined}
        >
            {value.map(
                (v, i): React.ReactElement => (
                    <div
                        key={v.toString()}
                        data-row-index={cell.rowIndex}
                        data-col-index={cell.columnIndex}
                        data-array-index={i}
                    >
                        {v.toString()}
                    </div>
                )
            )}
        </SUITable.Cell>
    );
};
ArrayCell.displayName = 'ArrayCell';
