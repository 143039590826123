import React from 'react';
import T from 'prop-types';
import styled from 'styled-components';
import SplunkUIMessage from '@splunk/react-ui/Message';
import { toDimension } from './style';

const MessageContainer = styled.div`
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    ${p => toDimension({ width: p.width, height: p.height })};
`;

/**
 * A component that display message
 *
 */
const Message = ({ width, height, level, message }) => (
    <MessageContainer width={width} height={height} data-test="visualization-message">
        <SplunkUIMessage type={level} style={{ maxWidth: '100%' }}>
            {message}
        </SplunkUIMessage>
    </MessageContainer>
);

Message.propTypes = {
    /**
     * width in pixel or string, defaults to 100%
     */
    width: T.oneOfType([T.string, T.number]),
    /**
     * height in pixel or string
     */
    height: T.oneOfType([T.string, T.number]),
    /**
     * message text
     */
    message: T.string,
    /**
     * message level
     */
    level: T.oneOf(['info', 'warning', 'error']),
};

Message.defaultProps = {
    level: 'info',
    message: '',
};
export default React.memo(Message);
