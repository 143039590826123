import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import * as T from 'prop-types';
import { pick } from 'lodash';
import styled from 'styled-components';
import Message from '@splunk/visualizations-shared/Message';
import { _ } from '@splunk/ui-utils/i18n';
import SizeAwareWrapper from '@splunk/visualizations-shared/SizeAwareWrapper';
import { toDimension } from '@splunk/visualizations-shared/style';
import { VIZ_CATEGORICAL } from '@splunk/visualization-color-palettes';
// eslint-disable-next-line camelcase
import { d3_sankey } from './lib/sankey';
import VizStyleWrapper from '../../common/components/VizStyleWrapper';
import {
    checkDigitsOnly,
    computeAllConnectedLinksToNodes,
    convertInputToSankeyDataFormat,
    getAllNodeNames,
    getColorCategoryMapping,
    getLinkId,
    getNodeId,
} from './SankeyUtils';
import SankeyNode from './SankeyNode';
import SankeyLink from './SankeyLink';
import { COLOR_MODE_TYPES, NodeConnectionsMap, SankeyColors, SankeyDataLink } from './SankeyModels';
import SankeyTooltip from './SankeyTooltip';

export const DEFAULT_COLORS: SankeyColors = {
    backgroundColor: '#ffffff',
    nodeTextColor: '#2c2c2c',
    tooltip: {
        headerColor: 'rgba(0, 0, 0, 0.54)',
        linkHeaderColor: '#2c2c2c',
        rowColor: '#2c2c2c',
    },
};
export const NODE_TEXT_FONT_SIZE = 10;
export const DEFAULT_FONT_FAMILY =
    '"Splunk Platform Sans", "Proxima Nova", "Helvetica Neue", Helvetica, Arial, sans-serif';
export const DEFAULT_NODE_COLOR = '#909090';
export const DEFAULT_LINK_COLOR = '#909090';
export const DEFAULT_SERIES_COLOR = VIZ_CATEGORICAL;
export const DEFAULT_LINK_OPACITY = 0.5;
export const DEFAULT_NODE_OPACITY = 1;
export const INACTIVE_OPACITY = 0.05;
export const CHART_MIN_WIDTH = 300;
export const CHART_MIN_HEIGHT = 200;
export const DEFAULT_CHART_WIDTH = 600;
export const DEFAULT_CHART_HEIGHT = 300;
export const DEFAULT_SOURCE_INDEX = 0;
export const DEFAULT_TARGET_INDEX = 1;
export const DEFAULT_LINK_VALUE_INDEX = 2;

interface PureSankeyProps {
    columns: (string[] | number[])[];
    width: string | number;
    height: string | number;
    mode?: string;
    backgroundColor: string;
    colorMode: COLOR_MODE_TYPES;
    nodeWidth: number;
    nodePadding: number;
    resultLimit: number;
    seriesColors: string[];
    linkColors?: string[];
    linkOpacity: number;
    linkValueField?: string;
    nodeTextColor: string;
    tooltipRowColor: string;
    tooltipLinkHeaderColor: string;
    tooltipHeaderColor: string;
}

const SankeyContainer = styled.div<{
    width: number | string;
    height: number | string;
}>`
    overflow: hidden;
    ${props => toDimension(pick(props, ['width', 'height']))};
`;

const PureSankey = (props: PureSankeyProps) => {
    const {
        width,
        height,
        columns,
        colorMode,
        nodeWidth,
        nodePadding,
        resultLimit,
        seriesColors,
        linkColors,
        linkOpacity,
        linkValueField,
        backgroundColor,
        nodeTextColor,
        tooltipRowColor,
        tooltipLinkHeaderColor,
        tooltipHeaderColor,
    } = props;

    const margin = {
        top: 17,
        right: 25,
        bottom: 17,
        left: 30,
    };
    // This is to translate the svg's root g based on margin
    // The y position is same as margin top & bottom
    // The x position is an avg of margin right & left
    const translatePos = { x: 28, y: 17 };

    const [activeLinks, setActiveLinks] = useState([]);
    const [activeNodes, setActiveNodes] = useState([]);
    const [nodeHovered, setNodeHovered] = useState(null);
    const [hoveredNodeElement, setHoveredNodeElement] = useState(null);
    const [openNodeToolTip, setOpenNodeToolTip] = useState(false);
    const [hoveredLinkElement, setHoveredLinkElement] = useState(null);
    const [openLinkToolTip, setOpenLinkToolTip] = useState(false);
    const sankeyContainerElement = useRef(null);
    const [sankeyWidth, setSankeyWidth] = useState(DEFAULT_CHART_WIDTH - margin.left - margin.right);
    const [sankeyHeight, setSankeyHeight] = useState(DEFAULT_CHART_HEIGHT - margin.top - margin.bottom);

    useEffect(() => {
        if (sankeyContainerElement && sankeyContainerElement.current) {
            const w = sankeyContainerElement.current.getBoundingClientRect().width;
            const h = sankeyContainerElement.current.getBoundingClientRect().height;
            const sWidth = checkDigitsOnly(width as string) ? parseInt(width as string, 10) : w;
            const sHeight = checkDigitsOnly(height as string) ? parseInt(height as string, 10) : h;
            setSankeyWidth(sWidth - margin.left - margin.right);
            setSankeyHeight(sHeight - margin.top - margin.bottom);
        }
    }, [width, height]);

    const sankeyData = convertInputToSankeyDataFormat(
        columns,
        DEFAULT_SOURCE_INDEX,
        DEFAULT_TARGET_INDEX,
        DEFAULT_LINK_VALUE_INDEX,
        resultLimit
    );
    if (!sankeyData || !columns || !Array.isArray(columns) || columns.length < 3) {
        return <Message width={width} height={height} message={_('Invalid data to render')} level="info" />;
    }
    const sortAndAssignLinkIds = (links: SankeyDataLink[]): void => {
        links.sort((a, b) => a.dy - b.dy);
        links.forEach(link => {
            // eslint-disable-next-line no-param-reassign
            link.id = `link-${link.source.index}-${link.target.index}`;
        });
    };
    const d3sankey = (d3_sankey() as any)
        .nodeWidth(nodeWidth)
        .nodePadding(nodePadding)
        .size([sankeyWidth, sankeyHeight]);
    const path = d3sankey.reversibleLink();
    d3sankey.nodes(sankeyData.nodes).links(sankeyData.links).layout(500);
    sortAndAssignLinkIds(sankeyData.links);
    const nodeConnectionsMap: NodeConnectionsMap = computeAllConnectedLinksToNodes(
        sankeyData.nodes,
        sankeyData.links
    );
    const uniqCategory = getAllNodeNames(sankeyData);
    const colorCategoryMapping = getColorCategoryMapping({
        category: uniqCategory,
        colorMode,
        seriesColors,
        linkColors,
    });

    const resetStates = () => {
        setActiveLinks([]);
        setActiveNodes([]);
        setHoveredLinkElement(null);
        setHoveredNodeElement(null);
        setNodeHovered(null);
        setOpenLinkToolTip(false);
        setOpenNodeToolTip(false);
    };

    const renderVisualization = ({ width: containerWidth, height: containerHeight }) => {
        if (containerWidth < CHART_MIN_WIDTH || containerHeight < CHART_MIN_HEIGHT) {
            return (
                <Message
                    width={containerWidth}
                    height={containerHeight}
                    message={_('Too small to render content')}
                    level="info"
                />
            );
        }

        const renderLinks = links => {
            return links.map(link => {
                return (
                    <SankeyLink
                        link={link}
                        activeLinks={activeLinks}
                        linkOpacity={linkOpacity}
                        path={path}
                        key={getLinkId(link)}
                        data-test={getLinkId(link)}
                        colorCategoryMapping={colorCategoryMapping}
                        colorMode={colorMode}
                        handleMouseOver={ev => {
                            setActiveLinks([link]);
                            setActiveNodes([link.source, link.target]);
                            setHoveredLinkElement(ev.currentTarget.querySelector('circle'));
                            setOpenNodeToolTip(false);
                            setOpenLinkToolTip(true);
                        }}
                        handleMouseLeave={() => {
                            resetStates();
                        }}
                    />
                );
            });
        };

        const renderNodes = nodes => {
            const chartWidth = typeof width === 'number' ? width : Number.parseInt(width, 10);
            return nodes.map(node => {
                return (
                    <SankeyNode
                        node={node}
                        key={getNodeId(node)}
                        data-test={getNodeId(node)}
                        activeNodes={activeNodes}
                        nodeWidth={nodeWidth}
                        chartWidth={chartWidth}
                        colorCategoryMapping={colorCategoryMapping}
                        nodeTextColor={nodeTextColor}
                        handleMouseOver={ev => {
                            const connections = nodeConnectionsMap[node.index];
                            setActiveLinks(connections.links);
                            setNodeHovered(node);
                            setActiveNodes([...connections.sourceNodes, ...connections.targetNodes]);
                            setHoveredNodeElement(ev.currentTarget);
                            setOpenLinkToolTip(false);
                            setOpenNodeToolTip(true);
                        }}
                        handleMouseLeave={() => resetStates()}
                    />
                );
            });
        };

        return (
            <VizStyleWrapper
                backgroundColor={backgroundColor}
                dataTestKey="SankeyWrapper"
                onMouseLeave={() => resetStates()}
            >
                <svg height="100%" width="100%" data-test="sankey-svg">
                    <g transform={`translate(${translatePos.x},${translatePos.y})`}>
                        <g className="link-group" data-test="link-group">
                            {renderLinks(sankeyData.links)}
                            {openLinkToolTip && (
                                <SankeyTooltip
                                    mode="link"
                                    open={openLinkToolTip}
                                    handleTooltipRequestClose={() => resetStates()}
                                    linkValue={linkValueField}
                                    connectionsMap={nodeConnectionsMap}
                                    anchor={hoveredLinkElement}
                                    link={activeLinks[0]}
                                    height={sankeyHeight}
                                    width={sankeyWidth}
                                    colorCategoryMapping={colorCategoryMapping}
                                    tooltipRowColor={tooltipRowColor}
                                    tooltipLinkHeaderColor={tooltipLinkHeaderColor}
                                    tooltipHeaderColor={tooltipHeaderColor}
                                />
                            )}
                        </g>
                        <g className="node-group" data-test="node-group">
                            {renderNodes(sankeyData.nodes)}
                            {openNodeToolTip && (
                                <SankeyTooltip
                                    mode="node"
                                    open={openNodeToolTip}
                                    handleTooltipRequestClose={() => resetStates()}
                                    linkValue={linkValueField}
                                    connectionsMap={nodeConnectionsMap}
                                    anchor={hoveredNodeElement}
                                    node={nodeHovered}
                                    height={sankeyHeight}
                                    width={sankeyWidth}
                                    colorCategoryMapping={colorCategoryMapping}
                                    tooltipRowColor={tooltipRowColor}
                                    tooltipLinkHeaderColor={tooltipLinkHeaderColor}
                                    tooltipHeaderColor={tooltipHeaderColor}
                                />
                            )}
                        </g>
                    </g>
                </svg>
            </VizStyleWrapper>
        );
    };

    return (
        <SankeyContainer height={height} width={width} ref={sankeyContainerElement}>
            <SizeAwareWrapper>
                {containerDimension => renderVisualization(containerDimension)}
            </SizeAwareWrapper>
        </SankeyContainer>
    );
};

PureSankey.propTypes = {
    columns: T.array,
    width: T.oneOfType([T.string, T.number]),
    height: T.oneOfType([T.string, T.number]),
    // need this to satisfy props interface
    // eslint-disable-next-line react/no-unused-prop-types
    mode: T.string,
    colorMode: T.string,
    nodeWidth: T.number,
    nodePadding: T.number,
    resultLimit: T.number,
    seriesColors: T.arrayOf(T.string),
    linkColors: T.arrayOf(T.string),
    linkOpacity: T.number,
    linkValueField: T.string,
    backgroundColor: T.string,
    nodeTextColor: T.string,
    tooltipRowColor: T.string,
    tooltipLinkHeaderColor: T.string,
    tooltipHeaderColor: T.string,
};

PureSankey.defaultProps = {
    columns: [],
    width: DEFAULT_CHART_WIDTH,
    height: DEFAULT_CHART_HEIGHT,
    mode: 'view',
    colorMode: 'categorical',
    nodeWidth: 12,
    nodePadding: 18,
    resultLimit: 1000,
    seriesColors: DEFAULT_SERIES_COLOR,
    linkColors: [],
    linkOpacity: DEFAULT_LINK_OPACITY,
    linkValueField: 'value',
    backgroundColor: '#0b0c0e',
    nodeTextColor: 'rgba(255, 255, 255, 0.98)',
    tooltipRowColor: 'rgba(255, 255, 255, 0.98)',
    tooltipLinkHeaderColor: 'rgba(255, 255, 255, 0.98)',
    tooltipHeaderColor: 'rgba(255, 255, 255, 0.5)',
};

export default PureSankey;
