import { _ } from '@splunk/ui-utils/i18n';
import { EditorLayoutConfig } from '../interfaces/Editor';
import { ThemedDefaults } from '../interfaces/ThemedDefaults';

interface backgroundEditorProps {
    label?: string;
    themes: ThemedDefaults;
    labelPosition?: string;
}

const background: ({ label, themes, labelPosition }: backgroundEditorProps) => EditorLayoutConfig[] = ({
    label,
    themes,
    labelPosition,
}) => [
    {
        label: _(label || 'Background'),
        option: 'backgroundColor',
        editor: 'editor.color',
        editorProps: {
            labelPosition: labelPosition || 'top',
            themes,
        },
    },
];

export default background;
