import styled from 'styled-components';
import { pick, variables } from '@splunk/themes';

export const PaddedContainer = styled.div`
    padding: ${variables.spacingSmall}
        ${pick({
            enterprise: variables.spacingMedium,
            prisma: variables.spacingLarge,
        })};
`;
