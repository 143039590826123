import * as React from 'react';
import type { ScalePoint } from 'd3-scale';
import { convertHexToRgb } from '@mdhnpm/rgb-hex-converter';

type marginObj = { top: number; right: number; bottom: number; left: number };
type yPositionScaleType = { [key: string]: any };

interface ParallelCoordinatesCanvasProps {
    width: number;
    height: number;
    margin: marginObj;
    data: any[];
    selectedData: any[];
    currentDimensions: string[];
    xPositionScale: ScalePoint<Array<string>>;
    yPositionScale: yPositionScaleType;
    dragStatus: boolean;
    showNullAxis: boolean;
    nullOffsetHeight: number;
    lineColor: string;
    lineOpacity: number;
    backgroundColor: string;
}

const ParallelCoordinatesCanvas = ({ ...props }: ParallelCoordinatesCanvasProps) => {
    const {
        width,
        height,
        margin,
        data,
        selectedData,
        currentDimensions,
        xPositionScale,
        yPositionScale,
        dragStatus,
        showNullAxis,
        nullOffsetHeight,
        lineColor,
        lineOpacity,
        backgroundColor,
    } = props;

    const foregroundRef = React.useRef(null);
    const backgroundRef = React.useRef(null);

    // Get the device pixel ratio, falling back to 1.
    const dpr = window.devicePixelRatio || 1;

    // draw path on two layers of canvas
    const path = (d, ctx, dimensions) => {
        if (showNullAxis) {
            ctx.beginPath();
            dimensions.forEach((p, i) => {
                let updatedYPosition;
                if (d[p] == null) {
                    // add nullOffsetHeight to the bottom position of each axes and make it as the new yPosition of the null data
                    updatedYPosition = yPositionScale[p].range()[0] + nullOffsetHeight;
                } else {
                    // otherwise, yPosition stays at the same scale
                    updatedYPosition = yPositionScale[p](d[p]);
                }
                if (i === 0) {
                    ctx.moveTo(xPositionScale(p), updatedYPosition);
                } else {
                    ctx.lineTo(xPositionScale(p), updatedYPosition);
                }
            });
            ctx.stroke();
        } else {
            // if nullOffset option is false
            let prev = null;
            dimensions.forEach(p => {
                if (prev !== null && d[p] !== null) {
                    ctx.beginPath();
                    ctx.moveTo(xPositionScale(prev), yPositionScale[prev](d[prev]));
                    ctx.lineTo(xPositionScale(p), yPositionScale[p](d[p]));
                    ctx.stroke();
                }
                if (d[p] !== null) {
                    prev = p;
                } else {
                    prev = null;
                }
            });
        }
    };

    // draw all the lines on background canvas
    React.useEffect(() => {
        const backgroundCanvas = backgroundRef.current;
        const background = backgroundCanvas.getContext('2d');

        backgroundCanvas.width = (width + margin.left + margin.right) * dpr;
        backgroundCanvas.height = (height + margin.top + margin.bottom) * dpr;

        // ensure all drawing operations are scaled
        background.scale(dpr, dpr);

        background.clearRect(0, 0, width + margin.left + margin.right, height + margin.top + margin.bottom);
        background.translate(margin.left, margin.top);

        if (backgroundColor !== 'transparent') {
            background.strokeStyle = `rgba(${convertHexToRgb(lineColor)[0]}, ${
                convertHexToRgb(lineColor)[1]
            }, ${convertHexToRgb(lineColor)[2]}, ${lineOpacity})`;

            data.forEach(d => path(d, background, currentDimensions));

            // backgroundColor-ed or default page color mask btw background and foreground lines
            background.globalAlpha = 0.7;
            background.fillStyle = backgroundColor;
            background.fillRect(-10, -10, width + margin.right, height + margin.bottom);
        } else {
            // set the background lines color to be 0.1 if backgroundColor is transparent
            background.strokeStyle = `rgba(${convertHexToRgb(lineColor)[0]}, ${
                convertHexToRgb(lineColor)[1]
            }, ${convertHexToRgb(lineColor)[2]}, 0.1)`;

            data.forEach(d => path(d, background, currentDimensions));
        }
    }, [
        data,
        height,
        margin,
        width,
        currentDimensions,
        showNullAxis,
        nullOffsetHeight,
        lineColor,
        lineOpacity,
        backgroundColor,
        dpr,
    ]);

    // draw lines on the foreground canvas
    // when selectedData is updated via brushing, re-draw the foreground canvas
    React.useEffect(() => {
        const foregroundCanvas = foregroundRef.current;
        const foreground = foregroundCanvas.getContext('2d');

        foregroundCanvas.width = (width + margin.left + margin.right) * dpr;
        foregroundCanvas.height = (height + margin.top + margin.bottom) * dpr;

        // ensure all drawing operations are scaled
        foreground.scale(dpr, dpr);

        foreground.clearRect(0, 0, width + margin.left + margin.right, height + margin.top + margin.bottom);
        foreground.translate(margin.left, margin.top);
        foreground.strokeStyle = `rgba(${convertHexToRgb(lineColor)[0]}, ${convertHexToRgb(lineColor)[1]}, ${
            convertHexToRgb(lineColor)[2]
        }, ${lineOpacity})`;

        selectedData.forEach(d => path(d, foreground, currentDimensions));
    }, [
        selectedData,
        width,
        height,
        margin,
        currentDimensions,
        showNullAxis,
        nullOffsetHeight,
        lineColor,
        lineOpacity,
        dpr,
    ]);

    return (
        <div data-testid="canvas-div">
            <canvas
                data-testid="background-canvas-layer"
                ref={backgroundRef}
                style={{
                    position: 'absolute',
                    display: !dragStatus ? 'flex' : 'none',
                    width: width + margin.left + margin.right,
                    height: height + margin.top + margin.bottom,
                }}
            />
            <canvas
                data-testid="foreground-canvas-layer"
                ref={foregroundRef}
                style={{
                    position: 'absolute',
                    display: !dragStatus ? 'flex' : 'none',
                    width: width + margin.left + margin.right,
                    height: height + margin.top + margin.bottom,
                }}
            />
        </div>
    );
};

export default ParallelCoordinatesCanvas;
