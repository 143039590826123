import { _ } from '@splunk/ui-utils/i18n';
import { COLOR_OR_TOKEN_PATTERN_WITH_RGBA, getPattern } from '@splunk/visualizations-shared/schemaUtils';
import pick from '@splunk/themes/pick';
import variables from '@splunk/themes/variables';
import { CATEGORICAL } from '@splunk/visualization-color-palettes';
import { defaultPalettesConfig } from '@splunk/visualization-color-palettes/editors/PresetPalettes';

import SuiGaugeFillerIcon from '@splunk/react-icons/enterprise/GaugeFiller';
import { FillerGauge as FillerGaugePlaceholderIcon } from '@splunk/visualization-icons/placeholders';
import { DataContract } from '../common/interfaces/DataContract';
import { DefaultContext } from '../common/interfaces/DefaultContext';
import { EditorConfig } from '../common/interfaces/Editor';
import { OptionsSchema } from '../common/interfaces/OptionsSchema';
import { ThemedDefaults } from '../common/interfaces/ThemedDefaults';
import { VizBehavior } from '../common/interfaces/VizBehavior';
import { VizCategory } from '../common/interfaces/VizCategory';
import { VizConfig } from '../common/interfaces/VizConfig';
import { VizSize } from '../common/interfaces/VizSize';
import { PresetEntry, enhanceConfig, getInitialPreset } from '../common/utils/configUtils';
import GaugeConfig from '../common/editorConfig/Gauge';
import getBackgroundColorEditor from '../common/editorConfig/BackgroundColor';
import { getDynamicGaugeColorEditor } from '../common/editorConfig/DynamicGaugeColorEditor';
import StaticGaugeColorEditor from '../common/editorConfig/StaticGaugeColorEditor';

const dataContract: DataContract = {
    requiredDataSources: [
        {
            name: 'primary',
            description: 'DataSource that powers the visualization',
        },
    ],
    optionalDataSources: [],
    initialRequestParams: {
        primary: { offset: 0, count: 10000 },
    },
};

const size: VizSize = {
    initialWidth: 300,
    initialHeight: 300,
};

const defaultContext: DefaultContext = {
    gaugeColorConfig: [
        { to: 9, value: '#118832' },
        { from: 10, to: 29, value: '#669922' },
        { from: 30, to: 49, value: '#CBA700' },
        { from: 50, to: 69, value: '#D94E17' },
        { from: 70, value: '#D41F1F' },
    ],
};

const optionsSchema: OptionsSchema = {
    backgroundColor: {
        default: '> themes.defaultBackgroundColor',
        description:
            'Specify the color used for the background. The default for enterprise light is "#ffffff". The default for enterprise dark is "#000000". The default for prisma dark is "#0b0c0e".',
        pattern: COLOR_OR_TOKEN_PATTERN_WITH_RGBA,
        type: 'string',
    },
    gaugeColor: {
        default: CATEGORICAL[0],
        description:
            'Specify the color for the gauge color of the filler gauge. You may use a dataSource to apply the color. The hex value format should be "#FFFFFF".',
        type: 'string',
    },
    labelDisplay: {
        default: 'number',
        description: 'Specify whether to display the labels as numbers, percentages, or off.',
        pattern: getPattern(['number', 'percentage', 'off']),
        type: 'string',
    },
    majorTickInterval: {
        description: 'Specify, in pixels, the spacing between major tick marks.',
        default: 'auto',
        type: ['string', 'number'],
    },
    orientation: {
        default: 'vertical',
        description: 'Specify the axis orientation of the gauge.',
        pattern: getPattern(['vertical', 'horizontal']),
        type: 'string',
    },
    value: {
        default: '> primary | seriesByType("number") | lastPoint()',
        description: 'Specify the dataSource applied to the value of the filler gauge.',
        type: 'number',
    },
    valueDisplay: {
        default: 'number',
        description: 'Specify whether to display the value as a number, percentage, or off.',
        pattern: getPattern(['number', 'percentage', 'off']),
        type: 'string',
    },
};

const themes: ThemedDefaults = {
    defaultBackgroundColor: props =>
        pick({
            enterprise: {
                dark: variables.black(props),
                light: variables.backgroundColor(props),
            },
            prisma: variables.backgroundColorSidebar(props),
        })(props),
    defaultBackgroundBarFillColor: props =>
        pick({
            enterprise: variables.backgroundColorHover(props),
            prisma: {
                light: variables.contentColorActive(props),
                dark: variables.neutral100(props),
            },
        })(props),
    majorTickFillColor: props =>
        pick({
            enterprise: {
                light: variables.gray30(props), // was '#33343B'
                dark: variables.textGray(props), // was #D7DAE0
            },
            prisma: {
                // TODO: missed colors :(
                light: variables.neutral100(props), // TBD
                dark: variables.contentColorMuted(props), // was #D7DAE0; contentColor
            },
        })(props),
    majorTickStrokeColor: props =>
        pick({
            enterprise: {
                light: variables.textGray(props),
                dark: variables.contentColorMuted(props),
            },
            prisma: variables.contentColorMuted(props),
        })(props),
    valueMarkerFillColor: props =>
        pick({
            enterprise: variables.textColor(props),
            prisma: {
                dark: variables.white(props),
                light: variables.black(props),
            },
        })(props),
    valueMarkerLabelFillColor: props =>
        pick({
            enterprise: {
                light: variables.gray96(props),
                dark: variables.black(props),
            },
            prisma: variables.black(props),
        })(props),
};

const basePresetContext = {
    gaugeColorEditorConfig: undefined,
};

const basePresetOptions = {
    gaugeColor: CATEGORICAL[0],
};

const presets: PresetEntry[] = [
    {
        // dummy context variables to allow for resetting of unused context states
        label: _('None'),
        name: 'fillerGauge.none',
        value: {
            context: { ...basePresetContext },
            options: { ...basePresetOptions },
        },
    },
    {
        label: _('Gauge'),
        name: 'fillerGauge.gauge',
        value: {
            context: {
                gaugeColorEditorConfig: defaultContext.gaugeColorConfig,
            },
            options: {
                gaugeColor: '> value | rangeValue(gaugeColorEditorConfig)',
            },
        },
    },
];

const editorConfig: EditorConfig[] = [
    {
        label: _('Data configurations'),
        layout: [
            [
                {
                    label: _('Value'),
                    editor: 'editor.columnSelector',
                    option: 'value',
                    context: 'defaultContext',
                    editorProps: {
                        dataSourceKey: 'primary',
                        prepareValue: (
                            definition
                        ): { context: Record<string, any>; options: Record<string, any> } => {
                            const {
                                options: { value },
                            } = definition;
                            return {
                                context: {},
                                options: {
                                    value: `${value} | lastPoint()`,
                                },
                            };
                        },
                    },
                },
            ],
        ],
    },
    GaugeConfig,
    {
        label: _('Color and style'),
        layout: [
            [
                {
                    label: 'Dynamic elements',
                    key: 'presetSelector',
                    editor: 'editor.presetSelector',
                    editorProps: {
                        presets,
                        value: ({ context, options }) => {
                            const initialPreset = getInitialPreset(context, options, presets);
                            return (initialPreset && initialPreset.label) || null;
                        },
                    },
                },
            ],
            [getDynamicGaugeColorEditor({ defaultContext, defaultPalettesConfig })],
            [
                {
                    ...StaticGaugeColorEditor,
                    editorProps: {
                        themes,
                    },
                },
            ],
            getBackgroundColorEditor({ themes }),
        ],
    },
];

/**
 * visualization configuration
 */
const config: VizConfig = {
    /**
     * unique viz key
     */
    key: 'splunk.fillergauge',
    /**
     * viz name
     */
    name: 'FillerGauge',
    category: VizCategory.GAUGE,
    /**
     * viz icon
     */
    icon: SuiGaugeFillerIcon,
    placeholderIcon: FillerGaugePlaceholderIcon,
    dataContract,
    size,
    defaultContext,
    optionsSchema,
    editorConfig,
    supports: [VizBehavior.DYNAMIC_OPTIONS, VizBehavior.PLACEHOLDER],
    themes,
};

export default enhanceConfig(config);
