import { pick } from 'lodash';
import React from 'react';
import BaseVisualization from '@splunk/dashboard-visualizations/common/BaseVisualization';
import Markdown from '@splunk/react-ui/Markdown';
import styled from 'styled-components';
import { toDimension, toMargin } from '@splunk/visualizations-shared/style';
import optionsSchema from './optionsSchema';
import editorConfig from './editorConfig';

const Container = styled.div`
    overflow: auto;
    position: relative;
    box-sizing: border-box;
    ${props => toDimension(pick(props, ['width', 'height']))};
    ${props => toMargin(props.margin)};
`;

const MarkdownVisualization = ({ options, height, width }) => {
    const { markdown = '', margin = [5, 5, 5, 5] } = options;
    return (
        <Container margin={margin} width={width} height={height}>
            <Markdown text={markdown} />
        </Container>
    );
};

MarkdownVisualization.propTypes = {
    ...BaseVisualization.propTypes,
};

MarkdownVisualization.defaultProps = {
    ...BaseVisualization.defaultProps,
};

MarkdownVisualization.supportedDataSources = [];
MarkdownVisualization.schema = optionsSchema;
MarkdownVisualization.editor = editorConfig;

export default MarkdownVisualization;
