import type { MutableRefObject } from 'react';
import { useCallback, useLayoutEffect, useMemo, useRef } from 'react';
import { console } from '@splunk/dashboard-utils';
import { usePassiveEventsCheck } from './usePassiveEventsCheck';

type NoopType = (...args: never[]) => unknown;
type ListenerFactory = () => EventListenerOrEventListenerObject | undefined;

export type UseEventListenerArgs<T = Element> = {
    eventName: string;
    listener?: ListenerFactory | EventListenerOrEventListenerObject;
    target?: T | MutableRefObject<T>;
    options?: AddEventListenerOptions | boolean;
};

/**
 * Generic type guard for function returning function or function returning non-function
 * @param func Possibly-undefined function or function factory
 * @returns true if argument is a factory generating a function result
 */
const isFunctionFactory = <T>(
    func: T | (() => T | undefined) | undefined
): func is () => T => {
    if (typeof func !== 'function') {
        return false;
    }

    try {
        if (typeof (func as NoopType)() === 'function') {
            return true;
        }
    } catch (_error) {
        // Ignore the error
    }

    return false;
};

/**
 * Generic type guard for checking if argument is a ref
 * @param target Mutable ref or raw value
 * @returns true if the argument is a MutableRefObject<T> instance
 */
const isRefObject = <T>(
    target: T | MutableRefObject<T> | undefined
): target is MutableRefObject<T> => {
    const castTarget = target as MutableRefObject<NonNullable<T>>;
    const objKeys = Object.keys(castTarget ?? {});

    return objKeys.length === 1 && objKeys[0] === 'current';
};

/**
 * Allow compatibility with legacy EventListenerObject interface by returning just handleEvent function
 * @param listenerOrObject Instance of EventListener or legacy EventListenerObject
 * @returns EventListener if provided, or EventListenerObject.handleEvent function
 */
const eventListenerObjectToFunc = (
    listenerOrObject?: EventListenerOrEventListenerObject
): EventListener | undefined =>
    typeof listenerOrObject !== 'function'
        ? listenerOrObject?.handleEvent
        : listenerOrObject;

/**
 * Handle creation and cleanup of event listeners. If target or listener function are not provided, no handler is attached.
 * If options are provided but the client does not support passive event handlers then the options will be dropped.
 * @param {string} args.eventName Name of the event to be listened
 * @param {EventListenerOrEventListenerObject | () => (EventListenerOrEventListenerObject | undefined) | undefined} [args.listener]
 * Event handler function or event handler function factory to be run at the time of execution
 * @param {T | MutableRefObject<T> | undefined} [args.target] Target object to attach the event handler.
 * @param {AddEventListenerOptions | boolean | undefined} [args.options] Event handler options
 */
export const useEventListener = <
    T extends EventTarget | null | undefined = Element
>({
    eventName,
    listener,
    target: targetProp,
    options: optionsProp,
}: UseEventListenerArgs<T>): void => {
    // Checker for passive event support
    const passiveEventsSupported = usePassiveEventsCheck();

    // Handle internal memoization of options to prevent new, idential
    // objects from causing detaching/reattaching of the event listener
    const memoizedOptions = useMemo(
        () => JSON.stringify(optionsProp),
        [optionsProp]
    );

    // Track the callback function and reevaluate when changed
    const memoizedListener = useMemo<EventListener | undefined>(
        () =>
            eventListenerObjectToFunc(
                isFunctionFactory<EventListenerOrEventListenerObject>(listener)
                    ? listener()
                    : listener
            ),
        [listener]
    );

    // Assign the current memoized callback to a ref to prevent reevaluation of the callback
    const eventHandlerRef = useRef<EventListener | undefined>();

    // Create a wrapping event handler as a memoized callback to prevent churn
    const eventHandler = useCallback((...args: Parameters<EventListener>) => {
        eventHandlerRef.current?.(...args);
    }, []);

    const target: NonNullable<T> | undefined = isRefObject<T>(targetProp)
        ? targetProp.current ?? undefined
        : targetProp ?? undefined;

    // Track the memoized listener every time this hook runs
    eventHandlerRef.current = memoizedListener;

    // Attach/remove listener
    useLayoutEffect(() => {
        const eventTarget = target;

        // Parse the options string to retrieve options and convert null to undefined
        // (prevent unchanged config objects from causing re-execution of this block)
        const options = JSON.parse(memoizedOptions || 'null') ?? undefined;

        // Handle check for supported event options before adding the listener
        if (eventTarget) {
            if (typeof eventTarget.addEventListener !== 'function') {
                // target does not support adding event listener
                throw Error(
                    'Provided target does not support adding event listeners'
                );
            }

            if (typeof options === 'undefined') {
                // No options provided. Listener is universally supported
                eventTarget.addEventListener(eventName, eventHandler);
            } else if (typeof options === 'boolean') {
                // Boolean options provided. Listener is universally supported
                eventTarget.addEventListener(eventName, eventHandler, options);
            } else if (!passiveEventsSupported()) {
                // Object options provided. Passive event support required but unavailable
                console.warn(
                    'Attempted to create event listener with unsupported options'
                );

                // Add the listener without the provided options
                eventTarget.addEventListener(eventName, eventHandler);
            } else {
                // Object options provided and passive event support available
                eventTarget.addEventListener(eventName, eventHandler, options);
            }
        }

        // Cleanup
        return () => {
            if (eventTarget) {
                eventTarget.removeEventListener(eventName, eventHandler);
            }
        };
    }, [
        eventName,
        target,
        memoizedOptions,
        eventHandler,
        passiveEventsSupported,
    ]);
};
