/* eslint-disable class-methods-use-this */

class BaseVisualizationApi {
    /**
     * put focus on the visualization
     */
    focus() {
        // do nothing
    }

    /**
     * compute snapshot of this visualization
     */
    snapshot() {
        return {};
    }
}

export default BaseVisualizationApi;
