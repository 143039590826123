import { RADIAL_SEVERITY_COLORS } from '@splunk/visualizations-shared/colorConstants';
import { getGraphColors, lighten, darken, SVR_PADDING } from '../../../common/utils/singleValueUtils';

export const VALID_TREND_POSITIONS = ['standard', 'below', 'outside'];
export const MAJOR_FONT_THRESHOLD = 25;
export const TREND_FONT_THRESHOLD = 12;
export const DEFAULT_RADIAL_STROKE_WIDTH = 16;
export const SMALL_RADIAL_STROKE_WIDTH = 6;

/**
 * Get the colors for a radial graph based on severity
 * @method getRadialColors
 * @param {Object} props
 * @param {Bool} props.backgroundEnabled
 * @param {String} props.backgroundColor
 * @returns {Object} { background, stroke } colors
 */
export const getRadialColors = ({ backgroundEnabled, backgroundColor }) =>
    getGraphColors({
        backgroundEnabled,
        backgroundColor,
        defaultColors: RADIAL_SEVERITY_COLORS,
        customColorFormatter: color => ({ background: lighten(color), stroke: darken(color) }),
    });

/**
 * Figure out the dimensions of the graph
 * @method getRadialGraphDimensions
 * @param {Object} props
 * @param {Number} props.width                    // Width of the container
 * @param {Number} props.height                   // Height of the container
 * @returns {Number} width and height of the graph
 */
export const getRadialGraphDimensions = ({ width, height }) => {
    // height * 2 because we are fitting only half of a circle inside the container
    const radius = Math.min(width, height * 2) / 2;

    return {
        width: width - SVR_PADDING.left - SVR_PADDING.right,
        height: radius - SVR_PADDING.top - SVR_PADDING.bottom,
    };
};

/**
 * Figure out the dimensions of the content container for SVR
 * @method getRadialHelperDimensions
 * @param {Object} props
 * @param {Number} props.width                    // Width of the visualization space
 * @param {Number} props.height                   // Height of the visualization space
 * @returns {Number} width and height of the content graph
 */
export const getRadialHelperDimensions = ({ height, strokeWidth = DEFAULT_RADIAL_STROKE_WIDTH }) => {
    // The radius of the visualization is equal to the height
    const semicircleRadius = height - strokeWidth / 2; // The inner part of the arc is strokeWidth / 2 inset.
    const innerArcRadius = semicircleRadius * 0.9;
    const halfContentWidth = innerArcRadius * 0.7;
    const contentHeight = Math.floor(Math.sqrt(innerArcRadius ** 2 - halfContentWidth ** 2));

    return {
        contentWidth: halfContentWidth * 2,
        contentHeight,
        helperArcRadius: innerArcRadius,
        semicircleRadius,
    };
};

/**
 * Get the maximum size of the container that holds the graph text
 * @method getValueContentSize
 * @param {Object} props
 * @param {Number} props.graphSize
 * @param {Number} props.radialStrokeWidth             // Stroke width of the graph
 * @returns {Number} content size for showing major and trend values in radial graph
 */
export const getValueContentSize = ({ graphSize, radialStrokeWidth }) => {
    // Account for the graph's stroke width
    const diameter = graphSize - radialStrokeWidth;
    // Use pythagoreum theorum with circle diameter
    // to calculate the size of the sides of the square inside the circle
    return Math.floor(Math.sqrt(diameter ** 2 / 2));
};

/*
 * @method getRadialStrokeWidth
 * @param {Number} graphHeight
 * @returns {Number} radial stroke width
 */
export const getRadialStrokeWidth = graphHeight =>
    graphHeight >= 90 ? DEFAULT_RADIAL_STROKE_WIDTH : SMALL_RADIAL_STROKE_WIDTH;

/*
 * @method isValidPosition
 * @param {String} position
 * @returns {Bool}
 */
export const isValidPosition = position => VALID_TREND_POSITIONS.indexOf(position) > -1;

/*
 * @method showTrendPositionStandard
 * @param {Object} props
 * @param {Bool} props.showTrendIndicator
 * @param {String} props.position
 * @returns {Bool}
 */
export const showTrendPositionStandard = ({ showTrendIndicator, position }) =>
    showTrendIndicator && position === 'standard';

/*
 * @method showTrendPositionBelow
 * @param {Object} props
 * @param {Bool} props.showTrendIndicator
 * @param {String} props.position
 * @returns {Bool}
 */
export const showTrendPositionBelow = ({ showTrendIndicator, position }) =>
    showTrendIndicator && position === 'below';

/*
 * @method showTrendPositionOutside
 * @param {Object} props
 * @param {Bool} props.showTrendIndicator
 * @param {String} props.position
 * @returns {Bool}
 */
export const showTrendPositionOutside = ({ showTrendIndicator, position }) =>
    showTrendIndicator && position === 'outside';

/*
 * need to handle max and min
 * @method getRadialProgress
 * @param {Number} majorValue
 * @returns {Number} progress bar in radial graph
 */
export const getRadialProgress = (majorValue, maxValue) =>
    Number.isNaN(Number(majorValue)) ||
    Number.isNaN(Number(maxValue)) ||
    (majorValue < 0 && majorValue < maxValue)
        ? 0
        : (majorValue / maxValue) * 100;
