import type {
    DashboardJSON,
    DataSourceBindingMap,
    DataSourceDefinition,
    Snapshot,
    TokenState,
    StatefulDataSourceDefinition,
} from '@splunk/dashboard-types';
import { pick, each, cloneDeep, uniqueId, isFunction } from 'lodash';
import type { DataSourceRegistry } from '@splunk/dashboard-search';
import type ApiRegistry from '../registries/extensions/ApiRegistry';

interface AssembleSnapshotInterface {
    definition: DashboardJSON;
    tokens?: TokenState;
    dataSourceRegistry: DataSourceRegistry;
    apiRegistry: ApiRegistry;
}

export const assembleSnapshot = ({
    definition,
    tokens = {},
    dataSourceRegistry,
    apiRegistry,
}: AssembleSnapshotInterface): Snapshot => {
    const snapshotDefinition = cloneDeep(definition);
    const dataSourceSnapshot = dataSourceRegistry.snapshot();
    // replace data source by a special type of data source which holds the search result. By doing this the definition becomes self-contained.
    const dsDefs: Record<string, StatefulDataSourceDefinition> = {};
    each(snapshotDefinition.visualizations, (vizDef, vizId) => {
        const { dataSources = {} } = vizDef;
        const newBindings: DataSourceBindingMap = {};
        each(dataSources, (dsId, dsType) => {
            const dsDef =
                (snapshotDefinition?.dataSources?.[
                    dsId
                ] as DataSourceDefinition) ?? {};
            const dsState = dataSourceSnapshot?.[dsId]?.[vizId] ?? {};
            const newDsId = uniqueId('ds_');
            dsDefs[newDsId] = {
                ...dsDef,
                state: {
                    ...pick(dsState, [
                        'requestParams',
                        'meta',
                        'error',
                        'data',
                    ]),
                },
            };
            newBindings[dsType] = newDsId;
        });

        if (!snapshotDefinition?.visualizations?.[vizId]) {
            return;
        }

        snapshotDefinition.visualizations[vizId].dataSources = newBindings;
        // get snapshot of each visualization if applicable
        const vizApi = apiRegistry.getVisualizationApi(vizId);
        if (vizApi && isFunction(vizApi.snapshot)) {
            snapshotDefinition.visualizations[vizId] = {
                ...snapshotDefinition.visualizations[vizId],
                ...vizApi.snapshot(),
            };
        }
    });
    snapshotDefinition.dataSources = dsDefs;

    // replace background image with encoding
    const layoutApi = apiRegistry.getLayoutApi();
    if (layoutApi && isFunction(layoutApi.snapshot)) {
        snapshotDefinition.layout = {
            type: 'absolute',
            ...snapshotDefinition.layout,
            ...layoutApi.snapshot(),
        };
    }

    // remove inputs
    delete snapshotDefinition.inputs;
    if (snapshotDefinition.layout) {
        delete snapshotDefinition.layout.globalInputs;

        // remove submit button
        if (snapshotDefinition.layout.options) {
            delete snapshotDefinition.layout.options.submitButton;
        }
    }

    return {
        snapshotDefinition,
        tokens,
    };
};
