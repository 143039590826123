import { uniqueId } from '@splunk/dashboard-utils';
import type {
    AbsoluteBlockItem,
    AbsolutePosition,
    Coordinate,
} from '@splunk/dashboard-types';
import type {
    EdgeItem,
    VerticalBoundaries,
    HorizontalBoundaries,
    EdgeBoundaries,
} from '../types';

const nextEdgeId = () => `edge_${uniqueId()}`;

/**
 * generate edge id
 */
const getNextEdgeId = () => {
    return nextEdgeId();
};

export type Intersections = Record<number, Record<number, AbsoluteBlockItem[]>>;

/**
 * Returns a 2D array of every viz corner, indicating which visualizations touch which node(corner)
 * @param {Object} layoutStructure - Array of visualizations from definition
 * @returns {Object[][]} - {x: { y: [vizList] } }
 */
export const getNodes = (
    layoutStructure: AbsoluteBlockItem[]
): Intersections | null => {
    if (layoutStructure.length === 0) {
        return null;
    }

    const nodes: Intersections = {};
    layoutStructure.forEach((viz) => {
        const { x, y, w, h } = viz.position;
        const corners = [
            { x, y },
            { x: x + w, y },
            { x, y: y + h },
            { x: x + w, y: y + h },
        ];
        corners.forEach((corner) => {
            if (nodes[corner.x] === undefined) {
                nodes[corner.x] = {};
            }
            if (nodes[corner.x][corner.y] === undefined) {
                nodes[corner.x][corner.y] = [];
            }
            nodes[corner.x][corner.y].push(viz);
        });
    });
    return nodes;
};

const isTopEdge = (edge: EdgeItem): boolean =>
    edge.orientation === 'horizontal' && edge.edgeStart.y === 0;

const isBottomEdge = (edge: EdgeItem, canvasHeight: number) =>
    edge.orientation === 'horizontal' && edge.edgeStart.y === canvasHeight;

const isLeftEdge = (edge: EdgeItem): boolean =>
    edge.orientation === 'vertical' && edge.edgeStart.x === 0;

const isRightEdge = (edge: EdgeItem, canvasWidth: number): boolean =>
    edge.orientation === 'vertical' && edge.edgeStart.x === canvasWidth;

/**
 * Returns all the visualizations along a vertical edge
 * @param {Object} param - Param object needed to traverse along vertical edge
 * @param {num} param.x - x-coordinate of the edge
 * @param {num} param.yStart - y-coordinate of the edge start
 * @param {num} param.yEnd - y-coordinate of the edge end
 * @param {num} param.yCurrent - Current y in recursion
 * @param {num} param.nodes - The map of x,y coordinates to visualizations that touch that (x,y)
 * @param {num} param.visualizations - the list to add visualizations to, and then return
 * @returns {Set<Object>} - Set of unique visualizations along the edge
 */
const findVizAlongVerticalEdge = ({
    x,
    yStart,
    yEnd,
    yCurrent = yStart,
    nodes,
    visualizations = new Set(),
}: {
    x: number;
    yStart: number;
    yEnd: number;
    yCurrent?: number;
    nodes: Intersections;
    visualizations?: Set<AbsoluteBlockItem>;
}) => {
    nodes[x][yCurrent].forEach((viz) => {
        if (viz.position.y >= yStart && viz.position.y < yEnd) {
            visualizations.add(viz);
        }
        if (viz.position.y >= yCurrent && yCurrent + viz.position.h <= yEnd) {
            findVizAlongVerticalEdge({
                x,
                yStart,
                yEnd,
                yCurrent: yCurrent + viz.position.h,
                nodes,
                visualizations,
            });
        }
    });
    return visualizations;
};

/**
 * Returns all the visualizations along a horizontal edge
 * @param {Object} param - Param object needed to traverse along horizontal edge
 * @param {num} param.y - x-coordinate of the edge
 * @param {num} param.xStart - x-coordinate of the edge start
 * @param {num} param.xEnd - x-coordinate of the edge end
 * @param {num} param.xCurrent - Current x in recursion
 * @param {num} param.nodes - The map of x,y coordinates to visualizations that touch that (x,y)
 * @param {num} param.visualizations - the list to add visualizations to, and then return
 * @returns {Set<Object>} - Set of unique visualizations along the edge
 */
const findVizAlongHorizontalEdge = ({
    y,
    xStart,
    xEnd,
    xCurrent = xStart,
    nodes,
    visualizations = new Set(),
}: {
    y: number;
    xStart: number;
    xEnd: number;
    xCurrent?: number;
    nodes: Intersections;
    visualizations?: Set<AbsoluteBlockItem>;
}) => {
    nodes[xCurrent][y].forEach((viz) => {
        if (viz.position.x >= xStart && viz.position.x < xEnd) {
            visualizations.add(viz);
        }
        if (viz.position.x >= xCurrent && xCurrent + viz.position.w <= xEnd) {
            findVizAlongHorizontalEdge({
                y,
                xStart,
                xEnd,
                xCurrent: xCurrent + viz.position.w,
                nodes,
                visualizations,
            });
        }
    });
    return visualizations;
};

/**
 * Add edge to the provided list
 * @param {Object} param - Params containing edge info
 * @param {num} param.edges - the list to add the edge to
 * @param {Object} param.edgeStart - the start of the edge
 * @param {Object} param.edgeEnd - the end of the edge
 * @param {Object[][]} param.nodes - The map of x,y coordinates to visualizations that touch that (x,y)
 */
const addEdge = ({
    edges,
    edgeStart,
    edgeEnd,
    nodes,
}: {
    edges: EdgeItem[];
    edgeStart: Coordinate;
    edgeEnd: Coordinate;
    nodes: Intersections;
}): void => {
    const orientation = edgeStart.y === edgeEnd.y ? 'horizontal' : 'vertical';

    // Find all visualizations that are affected by this edge
    const visualizations =
        orientation === 'vertical'
            ? findVizAlongVerticalEdge({
                  x: edgeStart.x,
                  yStart: edgeStart.y,
                  yEnd: edgeEnd.y,
                  nodes,
              })
            : findVizAlongHorizontalEdge({
                  y: edgeStart.y,
                  xStart: edgeStart.x,
                  xEnd: edgeEnd.x,
                  nodes,
              });

    edges.push({
        item: getNextEdgeId(),
        edgeStart,
        edgeEnd,
        visualizations: Array.from(visualizations),
        orientation,
    });
};

/**
 * If there is no incoming edge from the top, return true
 * @param {num} y - Current y position to compare against
 * @param {Object[]} visualizations - Array of visualizations attached to the node at (x, y). Length will be 1 to 4.
 * @returns {boolean}
 */
const shouldTraverseDown = (
    y: number,
    visualizations: AbsoluteBlockItem[]
): boolean => {
    return visualizations.every((viz) => viz.position.y >= y);
};

/**
 * If there is no incoming edge from the left, return true
 * @param {num} x - Current x position to compare against
 * @param {Object[]} visualizations - Array of visualizations attached to the node at (x, y). Length will be 1 to 4.
 * @returns {boolean}
 */
const shouldTraverseRight = (
    x: number,
    visualizations: AbsoluteBlockItem[]
): boolean => {
    return visualizations.every((viz) => viz.position.x >= x);
};

/**
 * Find the offset to the next node.
 * @param {Object} param - Param object to find offset to next node
 * @param {Number} param.x - The x co-ordinate of the current position to find offset from
 * @param {Number} param.y - The y co-ordinate of the current position to find offset from
 * @param {Object[]} param.visualizations - Array of visualizations attached to the node at (x, y). Length will be 1 to 4.
 * @param {Object} param.visualizations[].position - Position information of the visualization
 * @param {String} param.type - The type of co-ordinate. One of 'w' or 'h'.
 * @returns {number}
 */
const findOffset = ({
    x,
    y,
    visualizations,
    type,
}: {
    x: number;
    y: number;
    visualizations: AbsoluteBlockItem[];
    type: 'w' | 'h';
}): number => {
    for (let i = 0; i < visualizations.length; i += 1) {
        const { position } = visualizations[i];
        if (position.y === y && position.x === x) {
            return position[type];
        }
    }

    // This for-loop is only for finding the bottom canvas edge and the right canvas edge
    for (let i = 0; i < visualizations.length; i += 1) {
        const { position } = visualizations[i];
        if (type === 'w' && position.x === x) {
            // horizontal canvas edge
            return position.w;
        }
        if (type === 'h' && position.y === y) {
            // vertical canvas edge
            return position.h;
        }
    }

    return 0;
};

/**
 * Traverse right along nodes, creating or extending edges
 * @param {Object} param - Param object to traverse right along layout
 * @param {Object[][]} param.nodes - An object that maps {x, y} coord to an array of visualizations. Indexed as nodes[x][y].
 * @param {Object[]} param.edges - The resulting array of edges that gets mutated
 * @param {Number} param.x - x coordinate of current node
 * @param {Number} param.y - y coordinate of current node
 * @param {Object} param.edgeStart - The { x, y } coordinates of start of edge
 * @param {Boolean} param.canTraverseDown - Used to prevent loops during indirect recursion
 */
function traverseRight({
    x,
    y,
    nodes,
    edges,
    edgeStart,
    canTraverseDown = true,
}: {
    x: number;
    y: number;
    nodes: Intersections;
    edges: EdgeItem[];
    edgeStart: Coordinate;
    canTraverseDown?: boolean;
}) {
    // Check if we should traverse down (if there is no incoming edge)
    // canTraverseRight must be false to avoid endless loop
    if (canTraverseDown && shouldTraverseDown(y, nodes[x][y])) {
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        traverseDown({
            x,
            y,
            nodes,
            edges,
            edgeStart: { x, y }, // Start new edge when changing directions
            canTraverseRight: false,
        });
    }

    // Check if horizontal edge ends here
    // 'w' signifies we want width offset returned as opposed to height
    const offset = findOffset({ x, y, visualizations: nodes[x][y], type: 'w' });

    // Case 1 for ending edge: can't go right anymore (hit a viz OR end of dashboard), create edge up to this point.
    if (!offset) {
        addEdge({ edges, edgeStart, edgeEnd: { x, y }, nodes });
        return;
    }

    // Case 2 for ending edge: reached window, need to create edge and start new one to avoid merging across window
    const isWindow = nodes[x][y].length === 4; // 4-way intersection
    if (isWindow) {
        addEdge({ edges, edgeStart, edgeEnd: { x, y }, nodes });
        // Continue traversing right, starting with a new edge from current position
        traverseRight({
            x: x + offset,
            y,
            nodes,
            edges,
            edgeStart: { x, y },
        });
        return;
    }

    // Continue traversing right, merging the past edge with the next
    traverseRight({
        x: x + offset,
        y,
        nodes,
        edges,
        edgeStart,
    });
}

/**
 * Traverse right along nodes, creating or extending edges
 * @param {Object} param - Param object to traverse down along layout
 * @param {Object[][]} param.nodes - An object that maps {x, y} coord to an array of visualizations. Indexed as nodes[x][y].
 * @param {Object[]} param.edges - The resulting array of edges that gets mutated
 * @param {Number} param.x - x coordinate of current node
 * @param {Number} param.y - y coordinate of current node
 * @param {Object} param.edgeStart - The { x, y } coordinates of start of edge
 * @param {Boolean} param.canTraverseRight - Used to prevent loops during indirect recursion
 */
function traverseDown({
    x,
    y,
    nodes,
    edges,
    edgeStart,
    canTraverseRight = true,
}: {
    x: number;
    y: number;
    nodes: Intersections;
    edges: EdgeItem[];
    edgeStart: Coordinate;
    canTraverseRight?: boolean;
}) {
    if (canTraverseRight && shouldTraverseRight(x, nodes[x][y])) {
        traverseRight({
            x,
            y,
            nodes,
            edges,
            edgeStart: { x, y }, // Start new edge when changing directions
            canTraverseDown: false,
        });
    }

    // Check if vertical edge ends here
    const offset = findOffset({ x, y, visualizations: nodes[x][y], type: 'h' });

    // Case 1 for ending edge: can't go down anymore (hit a viz OR end of dashboard), create edge up to this point.
    if (!offset) {
        addEdge({ edges, edgeStart, edgeEnd: { x, y }, nodes });
        return;
    }

    // Case 2 for ending edge: reached window, need to create edge and start new one to avoid merging across window
    const isWindow = nodes[x][y].length === 4;
    if (isWindow) {
        addEdge({ edges, edgeStart, edgeEnd: { x, y }, nodes });
        // Start a new edge from current position
        traverseDown({
            x,
            y: y + offset,
            nodes,
            edges,
            edgeStart: { x, y },
        });
        return;
    }

    // Continue traversing, thus "merging" edges
    traverseDown({
        x,
        y: y + offset,
        nodes,
        edges,
        edgeStart,
    });
}

/**
 * Compute the vertical edges for every canvas row - a row that spans the entire width of the canvas
 * @param {Object} param
 * @param {AbsoluteBlockItem[]} param.layout - Layout structure
 * @param {EdgeItem[]} param.edges - The resulting array of edges that gets mutated
 * @param {Number} param.canvasWidth - Canvas width
 */
const computeVerticalCanvasEdges = ({
    edges,
    canvasWidth,
    layout,
}: {
    edges: EdgeItem[];
    canvasWidth: number;
    layout: AbsoluteBlockItem[];
}): void => {
    const nodes = getNodes(layout);
    if (nodes == null) {
        return;
    }
    // get all the horizontal edges that span the entire canvas width
    const sortedRowEdges = edges
        .filter(
            (edge) =>
                edge.orientation === 'horizontal' &&
                edge.edgeStart.x === 0 &&
                edge.edgeEnd.x === canvasWidth
        )
        .sort((a, b) => a.edgeStart.y - b.edgeStart.y);

    /**
     * iterate through the sorted horizontal edges to get their start and end coordinates
     * vertical edges between canvas row A and canvas row B will span from edgeStartA to edgeStartB and edgeEndA to edgeEndB
     */
    for (let i = 0; i < sortedRowEdges.length - 1; i += 1) {
        const { edgeStart: edgeStartA, edgeEnd: edgeEndA } = sortedRowEdges[i];
        const { edgeStart: edgeStartB, edgeEnd: edgeEndB } =
            sortedRowEdges[i + 1];
        addEdge({
            edges,
            edgeStart: edgeStartA,
            edgeEnd: edgeStartB,
            nodes,
        });
        addEdge({
            edges,
            edgeStart: edgeEndA,
            edgeEnd: edgeEndB,
            nodes,
        });
    }
};

/**
 * Gets all the edges given a layout of AbsoluteBlockItems
 * @param {AbsoluteBlockItem[]} layout - Array of AbsoluteBlockItems
 * @returns {EdgeItem[]} - Array of computed edges
 */
export const getAllEdges = (layout: AbsoluteBlockItem[]): EdgeItem[] => {
    const edges: EdgeItem[] = [];
    const nodes = getNodes(layout);

    if (nodes === null || nodes[0] === undefined || nodes[0][0] === undefined) {
        // When no visualizations in the layout structure
        // OR when there is no visualization at (0,0), which this algorithm assumes
        return [];
    }
    // run the algorithm
    traverseRight({
        x: 0,
        y: 0,
        nodes,
        edges,
        edgeStart: { x: 0, y: 0 },
    });

    return edges;
};

/**
 * Compute edges given a valid layout, canvasHeight, and canvasWidth
 * @param {Object} param - Param object containing layout, canvasHeight, canvasWidth
 * @param {Object[]} param.layout - Array of visualizations from the dashboard layout definition
 * @param {Number} param.canvasHeight - Canvas height
 * @param {Number} param.canvasWidth - Canvas width
 * @returns {Object[]} - Array of computed edges
 */
export const computeEdges = ({
    layout,
    canvasHeight,
    canvasWidth,
}: {
    layout?: AbsoluteBlockItem[];
    canvasHeight: number;
    canvasWidth: number;
}): EdgeItem[] => {
    if (layout === undefined) {
        return [];
    }

    let edges = getAllEdges(layout);

    // remove the computed left and right vertical canvas edges as they span the entire height of the canvas
    edges = edges.filter((edge) => {
        return !(isLeftEdge(edge) || isRightEdge(edge, canvasWidth));
    });

    // recompute the vertical canvas edges for each canvas row where the row spans the entire width of the canvas
    computeVerticalCanvasEdges({ edges, layout, canvasWidth });

    // Additionally, the top and bottom edge are there, but set to hidden
    edges = edges.map((edge) => {
        let isCanvasEdge = false;
        if (
            isTopEdge(edge) ||
            isRightEdge(edge, canvasWidth) ||
            isBottomEdge(edge, canvasHeight) ||
            isLeftEdge(edge)
        ) {
            isCanvasEdge = true;
        }
        return { ...edge, isCanvasEdge };
    });

    return edges;
};

/**
 * Format edge according to a given padding and edge thickness
 * @param {Object} param - Param object containing edge, padding, and edgeThickness
 * @param {Object} param.edge - Edge object
 * @param {Number} param.padding - Layout padding
 * @param {Number} param.edgeThickness - Thickness of edge
 * @returns {Object} - Returns formatted edge object
 */
export const formatEdge = ({
    edge,
    padding = 0,
    edgeThickness = 0,
}: {
    edge: EdgeItem;
    padding?: number;
    edgeThickness?: number;
}): EdgeItem => {
    // Formatted edges are centered between visualizations and respect the padding (gutter-size) between them
    const formattedEdgeStart = { ...edge.edgeStart };
    const formattedEdgeEnd = { ...edge.edgeEnd };
    if (edge.orientation === 'horizontal') {
        formattedEdgeStart.x += padding;
        formattedEdgeStart.y -= edgeThickness / 2;
        formattedEdgeEnd.x -= padding;
        formattedEdgeEnd.y -= edgeThickness / 2;
    } else {
        formattedEdgeStart.y += padding;
        formattedEdgeStart.x -= edgeThickness / 2;
        formattedEdgeEnd.y -= padding;
        formattedEdgeEnd.x -= edgeThickness / 2;
    }

    return {
        ...edge,
        edgeStart: formattedEdgeStart,
        edgeEnd: formattedEdgeEnd,
    };
};

/**
 * Format visualization according to a given padding
 * @param {Object} param - Param object containing item and padding
 * @param {Object} param.item - Visualization object
 * @param {Number} param.padding - Layout padding
 * @returns {AbsoluteBlockItem} - Returns formatted edge object
 */
export const applyVizPadding = ({
    item,
    padding = 0,
}: {
    item: AbsoluteBlockItem;
    padding?: number;
}): AbsoluteBlockItem => {
    const { x, y, w, h } = item.position;
    return {
        ...item,
        position: {
            x: x + padding,
            y: y + padding,
            w: w - 2 * padding,
            h: h - 2 * padding,
        },
    };
};

/**
 * Determines Upper and Lower boundaries of an edge given its visualizations around it
 * @param {Object[]} visualizations - The visualizations surrounding the edge
 * @param {Number} y - The y position of the selected edge
 * @param {Number} minHeight - minimum Item Height Value
 * @param {Boolean} isFullWidthEdge - If the edge we are getting boundaries of is the size of canvas width
 * @returns {Object} - returns the boundaries in an object
 */
export const getVerticalBoundaries = ({
    visualizations,
    y,
    minHeight,
    isFullWidthEdge = false,
}: {
    visualizations: AbsoluteBlockItem[];
    y: number;
    minHeight: number;
    isFullWidthEdge?: boolean;
}): VerticalBoundaries => {
    const boundaries = {
        upperBoundary: Number.NEGATIVE_INFINITY,
        lowerBoundary: Number.POSITIVE_INFINITY,
    };

    const comparePosition = (position: AbsolutePosition) => {
        // If viz is below of the edge
        // When the edge is a full width edge, there is no lower boundary
        //  since dragging down increases canvas size
        if (position.y >= y && !isFullWidthEdge) {
            boundaries.lowerBoundary = Math.min(
                boundaries.lowerBoundary,
                position.y + position.h - minHeight
            );
        }
        // If viz is above the edge
        if (position.y < y) {
            boundaries.upperBoundary = Math.max(
                boundaries.upperBoundary,
                position.y + minHeight
            );
        }
    };

    visualizations.forEach((viz) => comparePosition(viz.position));
    return boundaries;
};

/**
 * Determines Left and Right boundaries of an edge given its visualizations around it
 * @param {Object[]} visualizations - The visualizations surrounding the edge
 * @param {Number} x - The x position of the selected edge
 * @param {Number} minWidth - minimum Item Width Value
 * @returns {Object} - returns the boundaries in an object
 */
export const getHorizontalBoundaries = ({
    visualizations,
    x,
    minWidth,
}: {
    visualizations: AbsoluteBlockItem[];
    x: number;
    minWidth: number;
}): HorizontalBoundaries => {
    const boundaries = {
        rightBoundary: Number.POSITIVE_INFINITY,
        leftBoundary: Number.NEGATIVE_INFINITY,
    };

    const comparePosition = (position: AbsolutePosition) => {
        if (position.x >= x) {
            // If viz is to the right of the edge
            boundaries.rightBoundary = Math.min(
                boundaries.rightBoundary,
                position.x + position.w - minWidth
            );
        }
        if (position.x < x) {
            // If viz is to the left of the edge
            boundaries.leftBoundary = Math.max(
                boundaries.leftBoundary,
                position.x + minWidth
            );
        }
    };

    visualizations.forEach((viz) => comparePosition(viz.position));
    return boundaries;
};

/**
 * Determines the next edge position for movement up and down of a horizontal edge
 * @param {Object} edge - the current moving edge
 * @param {Number} offset - offset to move edge
 * @param {Object} options.edgeBoundaries Object with lower/upper boundaries
 * @returns {Object} - returns edge with updated position
 */
export const moveHorizontalEdge = ({
    edge,
    offset,
    edgeBoundaries: { upperBoundary, lowerBoundary },
}: {
    edge: EdgeItem;
    offset: number;
    edgeBoundaries: VerticalBoundaries;
}): EdgeItem => {
    // Update edge to either the offset value,
    // or the defined maximum/minimum based on min viz height/width
    const updatedY =
        offset < 0
            ? Math.max(upperBoundary, edge.edgeStart.y + offset)
            : Math.min(lowerBoundary, edge.edgeStart.y + offset);

    return {
        ...edge,
        edgeStart: {
            x: edge.edgeStart.x,
            y: updatedY,
        },
        edgeEnd: {
            x: edge.edgeEnd.x,
            y: updatedY,
        },
    };
};

/**
 * Determines the next edge position for movement left and right of a vertical edge
 * @param {Object} edge - the current moving edge
 * @param {Number} offset - offset to move edge
 * @param {Object} options.edgeBoundaries Object with left/right boundaries
 * @returns {Object} - returns edge with updated position
 */
export const moveVerticalEdge = ({
    edge,
    offset,
    edgeBoundaries: { leftBoundary, rightBoundary },
}: {
    edge: EdgeItem;
    offset: number;
    edgeBoundaries: HorizontalBoundaries;
}): EdgeItem => {
    const updatedX =
        offset < 0
            ? Math.max(leftBoundary, edge.edgeStart.x + offset)
            : Math.min(rightBoundary, edge.edgeStart.x + offset);

    return {
        ...edge,
        edgeStart: {
            x: updatedX,
            y: edge.edgeStart.y,
        },
        edgeEnd: {
            x: updatedX,
            y: edge.edgeEnd.y,
        },
    };
};

/**
 * find edges that the given edge should snap to, according to the snap range
 * @param {Object} options
 * @param {Object} options.edge the edge being dragged, edge has the structure
 * @param {array}  options.edges all the edges that are parallel to target edge and within the boundary
 * @param {Number} options.snapRange the range within which that triggers snapping
 * @returns {Object} updatedEdge and snappableEdges
 */
export const findSnappableEdges = ({
    edge,
    edges,
    snapRange,
}: {
    edge: EdgeItem;
    edges: EdgeItem[];
    snapRange: number;
}): { updatedEdge: EdgeItem; snappableEdges: EdgeItem[] } => {
    const coordinate = edge.orientation === 'horizontal' ? 'y' : 'x';

    const snappableEdges = edges.reduce<EdgeItem[]>(
        (currentSnappableEdges, nextEdge) => {
            const nextEdgeDistance = Math.abs(
                nextEdge.edgeStart[coordinate] - edge.edgeStart[coordinate]
            );

            // nextEdge is in range
            if (nextEdgeDistance <= snapRange) {
                if (currentSnappableEdges.length === 0) {
                    return [nextEdge];
                }

                const lastEdge =
                    currentSnappableEdges[currentSnappableEdges.length - 1];
                const lastEdgeDistance = Math.abs(
                    lastEdge.edgeStart[coordinate] - edge.edgeStart[coordinate]
                );

                // find new closest snappable edge
                if (nextEdgeDistance < lastEdgeDistance) {
                    return [nextEdge];
                }

                // it is possible there are several snappable edges having the same x or y value
                if (nextEdgeDistance === lastEdgeDistance) {
                    return [...currentSnappableEdges, nextEdge];
                }
            }

            // nextEdge is out of range
            return [...currentSnappableEdges];
        },
        []
    );

    // if no snappable edge, snap to itself
    const firstSnappableEdge =
        snappableEdges.length > 0 ? snappableEdges[0] : edge;

    return {
        updatedEdge: {
            ...edge,
            edgeStart: {
                ...edge.edgeStart,
                [coordinate]: firstSnappableEdge.edgeStart[coordinate],
            },
            edgeEnd: {
                ...edge.edgeEnd,
                [coordinate]: firstSnappableEdge.edgeEnd[coordinate],
            },
        },
        snappableEdges,
    };
};

/**
 * Find all the edges that could be snapped to, given the limitations enforced by the surrounding visualizations.
 * @param {Object} options
 * @param {Object} options.edge the edge being dragged
 * @param {array}  options.edges all the edges on canvas
 * @param {Object} options.edgeBoundaries Object with either lower/upper or left/right boundaries
 * @returns {array} the edges that are within the boundary of the edge being moved
 */
export const findEdgesInBoundary = ({
    edge,
    edges,
    edgeBoundaries,
}: {
    edge: EdgeItem;
    edges: EdgeItem[];
    edgeBoundaries: EdgeBoundaries;
}): EdgeItem[] => {
    const { orientation } = edge;

    if (orientation === 'horizontal') {
        return edges.filter(
            (e) =>
                e.orientation === 'horizontal' &&
                e.edgeStart.y >=
                    (edgeBoundaries as VerticalBoundaries).upperBoundary &&
                e.edgeStart.y <=
                    (edgeBoundaries as VerticalBoundaries).lowerBoundary &&
                e !== edge
        );
    }
    if (orientation === 'vertical') {
        return edges.filter(
            (e) =>
                e.orientation === 'vertical' &&
                e.edgeStart.x >=
                    (edgeBoundaries as HorizontalBoundaries).leftBoundary &&
                e.edgeStart.x <=
                    (edgeBoundaries as HorizontalBoundaries).rightBoundary &&
                e !== edge
        );
    }
    // can't match any edge because the orientation is invalid, this shouldn't happen.
    return [];
};
