import * as React from 'react';
import { _ } from '@splunk/ui-utils/i18n';
import SVG from '@splunk/react-icons/SVGEnterprise';

const ChoroplethSvg = (props: React.ComponentProps<typeof SVG>): React.ReactElement<SVGElement> => (
    <SVG
        screenReaderText={_('SVG Choropleth')}
        viewBox="0 0 16 16"
        {...props}
        style={{ ...props.style, overflow: 'hidden' }}
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M0.5 1.75C0.5 1.05964 1.05964 0.5 1.75 0.5H14.25C14.9404 0.5 15.5 1.05964 15.5 1.75V14.25C15.5 14.9404 14.9404 15.5 14.25 15.5H2.59171L2.58802 15.5022L2.58676 15.5H1.75C1.05964 15.5 0.5 14.9404 0.5 14.25V1.75ZM13.8333 2.16667V2.17568L7.9999 5.47201V10.3975L2.16667 13.8172V2.16667H13.8333Z"
            fill="currentColor"
        />
    </SVG>
);

export default ChoroplethSvg;
