import sha256 from 'crypto-js/sha256';

/**
 * A hash function based on md5 to provide one-way string hashing. You can optionally give it a prefix.
 * @param {String} str
 * @param {String} [prefix]
 */
const hashString = (str: string, prefix = ''): string =>
    `${prefix}${sha256(str)}`;

export default hashString;
