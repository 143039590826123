// TODO: utilize this fn in ParallelCoordinates
export const convertToArrOfObjects = (columns, fields) => {
    if (!columns || !columns[0].length) return [];

    const data = [];

    for (let i = 0; i < columns[0].length; i += 1) {
        const newObj = {};
        fields.forEach((field, index) => {
            const fieldName = typeof field === 'string' ? field : field.name;
            if (columns[index][i] === '') {
                newObj[fieldName] = null;
            } else {
                newObj[fieldName] = columns[index][i];
            }
        });
        data.push(newObj);
    }

    return data;
};
