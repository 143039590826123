/* eslint-disable react/no-unused-prop-types,react/prefer-stateless-function */
import { Component } from 'react';
import T from 'prop-types';

class BaseVisualization extends Component {
    static propTypes = {
        /**
         * true indicate the visualization is loading data
         */
        loading: T.bool,
        /**
         * display mode
         */
        mode: T.oneOf(['view', 'edit', 'export']),
        /**
         * width in pixel or string, defaults to 100%
         */
        width: T.oneOfType([T.string, T.number]),
        /**
         * height in pixel or string
         */
        height: T.oneOfType([T.string, T.number]),
        /**
         * visualization formatting options
         */
        options: T.object,
        /**
         * A callback to update formatting options
         */
        onOptionsChange: T.func,
        /**
         * datasource state which include data and request params, object key indicate the datasource type.
         */
        dataSources: T.objectOf(
            T.shape({
                /**
                 * current request params
                 */
                requestParams: T.object,
                /**
                 * current dataset
                 */
                data: T.shape({
                    fields: T.array,
                    columns: T.array,
                }),
                /**
                 * error
                 */
                error: T.shape({
                    level: T.string,
                    message: T.string,
                }),
                /**
                 * meta data that came with the dataset
                 */
                meta: T.object,
            })
        ),
        /**
         * List of event handler objects which will process user interaction events (e.g. click)
         */
        eventHandlers: T.arrayOf(
            T.shape({
                // Name of the eventHandler preset
                type: T.string,
                // Configuration options for the instance of the eventHandler used by the viz
                options: T.object,
            })
        ),
        /**
         * Inform viz if there are handlers listening to events
         */
        hasEventHandlers: T.bool,
        /**
         * Description the fields and properties of the datasource used when retrieving data
         */
        encoding: T.object,
        /**
         * A callback to trigger event
         */
        onEventTrigger: T.func,
        /**
         * A callback to request new data with updated request params
         */
        onRequestParamsChange: T.func,
        /**
         * A callback to select the visualization
         */
        onSelected: T.func,
        /**
         * Custom style on visualization
         */
        style: T.object,
        /**
         * A callback to notify about computed props in a visualization
         */
        onComputedProps: T.func,
        /**
         * A callback to access the underlying visualization's API
         */
        visualizationApiRef: T.func,
    };

    static defaultProps = {
        width: '100%',
        mode: 'view',
        options: {},
        style: {},
        hasEventHandlers: false,
        onOptionsChange: () => {},
        onEventTrigger: () => {},
        onRequestParamsChange: () => {},
        visualizationApiRef: () => {},
        onSelected: () => {},
        onComputedProps: () => {},
    };

    /**
     * default data contract
     */
    static dataContract = {
        requiredDataSources: [
            {
                name: 'primary',
                description: 'DataSource that powers the visualization',
            },
        ],
        initialRequestParams: {
            primary: { offset: 0, count: 10000 },
        },
    };

    render() {
        return null;
    }
}

export default BaseVisualization;
