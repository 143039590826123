import React from 'react';
import { useFeatureFlags } from '@splunk/dashboard-context';
import { useTokensWithUrl } from '../hooks/useTokensWithUrl';

/**
 * Tracks tokens WRT browser history:
 * when the user hits the back button, update tokens based on the URL
 * when tokens change value, update browser URL
 * @returns null
 */
const TokensInUrl = () => {
    useTokensWithUrl();

    return null;
};

/**
 * HOC to add browser history management for tokens based on a feature flag
 * @param {DashboardCore} Component
 * @returns wrapped component
 */
export const withTokensInUrl =
    <T,>(Component: React.ComponentType<T>): React.ComponentType<T> =>
    (props: T & JSX.IntrinsicAttributes) => {
        const { enableTokensInUrl } = useFeatureFlags();

        return enableTokensInUrl ? (
            <>
                <TokensInUrl />
                <Component {...props} />
            </>
        ) : (
            <Component {...props} />
        );
    };
