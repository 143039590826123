import { createContext, useContext } from 'react';

export interface DataSourceContextValue {
    defaultModule?: string;
    [key: string]: unknown;
}

export const defaultDataSourceContextValue: DataSourceContextValue =
    Object.freeze({});

export const DataSourceContext = createContext<DataSourceContextValue>(
    defaultDataSourceContextValue
);
export const DataSourceContextProvider = DataSourceContext.Provider;

export const useDataSourceContext = () => useContext(DataSourceContext);
