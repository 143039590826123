export type Callback = (arg?: unknown) => void;
export default class EventListener {
    events: Record<string, Callback[]>;

    constructor() {
        this.events = {};
    }

    // Simple and Generic PubSub (not keyboard specific)
    subscribe(eventName: string, callback: Callback): Callback {
        if (
            !eventName ||
            !callback ||
            typeof eventName !== 'string' ||
            typeof callback !== 'function'
        ) {
            throw new TypeError('wrong parameters given to subscriber');
        }
        this.events[eventName] = this.getSubscribers(eventName);
        this.events[eventName].push(callback);

        return () => {
            this.events[eventName] = this.getSubscribers(eventName).filter(
                (cb) => cb !== callback
            );
        };
    }

    subscribeOnce(eventName: string, callback: Callback): Callback {
        const unsubscribe = this.subscribe(eventName, (...args) => {
            unsubscribe();
            callback(...args);
        });
        return unsubscribe;
    }

    publish(eventName: string, ...data: unknown[]): void {
        this.getSubscribers(eventName).forEach((cb) => cb(...data));
    }

    unsubscribeAll(): void {
        this.events = {};
    }

    getSubscribers(eventName: string): Callback[] {
        return this.events[eventName] || [];
    }

    hasSubscribers(eventName: string): boolean {
        return this.getSubscribers(eventName).length > 0;
    }
}
