import type {
    EventCanHandle,
    EventHandle,
    SmartSourceTokenEvent,
    ValidSmartSource,
    HandledSmartSourceEvents,
} from '@splunk/dashboard-types';
import { SMART_SOURCE_SAGA_EVENT } from '../constants';

interface SmartSourceHandlePayload {
    tokenNamespace: string;
    enableSmartSources?: boolean;
    [key: string]: unknown;
}

type PlainObject = Record<string, unknown>;

type SmartSourceToken = PlainObject;

type SmartSourceDefaultEvents = HandledSmartSourceEvents<
    PlainObject,
    SmartSourceToken
>;

/**
 *
 * A smart source as a class that uses our event handler system (see triggerEvent) to map events to tokens.
 *
 * @example
 * interface DataSourceEventPayload { data: ['a', 'b'] };
 *
 * interface DataSourceSmartSourceTokens { tokenA: string[], tokenStatic?: boolean };
 *
 * const eventMap<DataSourceEventPayload, DataSourceSmartSourceTokens> = {
 *   'datasource.done': (payload) => { tokenA: payload.data },
 *   'datasource.error': (payload) =>  { tokenA: payload.data, tokenStatic: false }
 * };
 *
 * dsHandler = new SmartSourceHandler('dataSource', eventMap)
 */
class SmartSourceHandler {
    // Which events are supported
    events: SmartSourceDefaultEvents;

    // What kind of SmartSource is this
    source: ValidSmartSource;

    /**
     * @param {ValidSmartSource} required
     * @param {HandledEvents} required
     */
    constructor(source: ValidSmartSource, events: SmartSourceDefaultEvents) {
        this.source = source;
        this.events = events;
    }

    canHandle: EventCanHandle<{
        payload?: Partial<SmartSourceHandlePayload>;
    }> = ({ type, payload }) =>
        !!payload?.enableSmartSources && this.events && !!this.events[type];

    handle: EventHandle<
        SmartSourceHandlePayload,
        SmartSourceTokenEvent<ValidSmartSource>[]
    > = ({ type, payload }) => {
        let tokens: Partial<SmartSourceToken> = {};
        if (
            typeof type === 'string' &&
            typeof this.events[type] === 'function'
        ) {
            tokens = this.events[type](payload);
        }

        // No tokens created we don't need to do anything
        if (
            typeof tokens === 'undefined' ||
            (tokens && Object.keys(tokens).length === 0)
        ) {
            return Promise.resolve([]);
        }

        return Promise.resolve([
            {
                type: SMART_SOURCE_SAGA_EVENT,
                payload: {
                    source: this.source,
                    tokens: {
                        [payload.tokenNamespace]: tokens,
                    },
                },
            },
        ]);
    };
}

export default SmartSourceHandler;
