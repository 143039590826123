import React from 'react';
import { _ } from '@splunk/ui-utils/i18n';
import SVG, {
    type SVGPropsOptionalViewBox,
} from '@splunk/react-icons/SVGEnterprise';

/* eslint-disable max-len */
export default function Redo(
    props: SVGPropsOptionalViewBox
): ReturnType<typeof SVG> {
    return (
        <SVG screenReaderText={_('Redo')} viewBox="0 0 32 32" {...props}>
            <path
                fill="currentColor"
                d="M24.552 17a9.996 9.996 0 0 0-8.662-5c-5.06 0-9.243 3.758-9.908 8.635L4 20.365C4.8 14.51 9.817 10 15.89 10c4.175 0 7.85 2.132 10 5.364V11h2v8h-8v-2h4.662z"
            />
        </SVG>
    );
}
