import { _ } from '@splunk/ui-utils/i18n';
import { isDynamicOption } from '@splunk/visualizations-shared/configUtils';

const StaticFillColorEditor = {
    label: _('Fill'),
    option: 'fillColor',
    editor: 'editor.color',
    showEditor: ({ options }) => !isDynamicOption(options.fillColor),
};

export default StaticFillColorEditor;
