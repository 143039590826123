import { chain } from 'lodash';
// Charting imports i18n which needs to be run to instantiate window globals in the tests
// eslint-disable-next-line import/order
import Charting, { ChartingProps } from '../common/Charting';
import jsCharting from '@splunk/charting-bundle';
import { getSimpleXMLOptionsForProps } from '@splunk/visualizations-shared/simpleXMLOptions';
import config from './config';
import withDashboardViz from '../common/withDashboardViz';
import { convertToLineSCProperties } from './lineUtils';
import { getAnnotations, truncateData, constructFieldsAndColumns } from '../common/chartingUtils';

const validOptions = Object.keys(config.optionsSchema);

type computeVizPropsFn = (options: Record<string, any>) => Partial<ChartingProps>;
export const computeVizProps: computeVizPropsFn = ({
    dataSources,
    x,
    y,
    xField,
    yFields,
    onEventTrigger,
    annotationX,
    annotationColor,
    annotationLabel,
    visualizationApiRef,
    ...otherOptions
}): Partial<ChartingProps> => {
    // options to be passed as props
    const computedProps: Record<string, any> = { visualizationApiRef };
    const { y2, y2Fields, hasEventHandlers } = otherOptions;

    computedProps.options = chain(otherOptions)
        .pick(validOptions)
        .thru(convertToLineSCProperties)
        .thru(options => ({ ...getSimpleXMLOptionsForProps(options), chart: 'line' }))
        .value();

    const chartingAnnotations = getAnnotations({ annotationX, annotationColor, annotationLabel });

    const truncationLimit = computedProps.options['chart.resultTruncationLimit'];
    let primaryData = { ...dataSources.primary.data };
    const { columns, fields } = constructFieldsAndColumns({ x, y, xField, yFields, y2, y2Fields });
    primaryData.columns = columns;
    primaryData.fields = fields;
    if (truncationLimit) {
        primaryData = truncateData(primaryData, truncationLimit);
    }

    computedProps.chartData = jsCharting.extractChartReadyData({
        ...primaryData,
        annotations: chartingAnnotations,
    });

    if (hasEventHandlers) {
        computedProps.onClick = onEventTrigger;
        computedProps.onSelect = onEventTrigger;
        computedProps.onPointMouseOver = onEventTrigger;
        computedProps.onPointMouseOut = onEventTrigger;
    }

    return computedProps;
};

const Line = withDashboardViz({
    ReactViz: Charting,
    computeVizProps,
    vizConfig: config,
});
const themes = null;

export { themes, config };
export default Line;
