import { COLOR_OR_TOKEN_PATTERN, getPattern } from '@splunk/visualizations-shared/schemaUtils';

export default {
    source: {
        description: 'Source for the geo data.',
        pattern: getPattern(['geo://default/us', 'geo://default/world']),
        type: 'string',
    },
    projection: {
        description: 'Specifies the map projection.',
        pattern: getPattern(['mercator', 'equirectangular']),
        type: 'string',
    },
    selector: { default: '.feature', description: 'Selector to get all features', type: 'string' },
    fillColor: {
        description: 'Specify the Hex code for fill color of features with no data. For example: "#0000FF".',
        pattern: COLOR_OR_TOKEN_PATTERN,
        type: 'string',
    },
    strokeColor: {
        description:
            'Specify the Hex code for stroke color for all features that are not selected. For example: "#0000FF".',
        pattern: COLOR_OR_TOKEN_PATTERN,
        type: 'string',
    },
    strokeHighlightColor: {
        description:
            'Specify the Hex code for stroke highlight color for all features that are selected. For example: "#0000FF".',
        pattern: COLOR_OR_TOKEN_PATTERN,
        type: 'string',
    },
    backgroundColor: {
        description:
            'Specify the Hex code for the color used for the Choropleth background. For example: "#0000FF".',
        pattern: COLOR_OR_TOKEN_PATTERN,
        type: 'string',
    },
    name: { description: 'Name of the Visualization', type: 'string' },
    sourceBounds: {
        description:
            'Specify the bounds of the geographic area as lat:{min:..., max:...}, long:{min:..., max:..}.',
        type: 'object',
    },
    logicalBounds: {
        description:
            'Specify the logical bounds of the projected map as x:{min:..., max:...}, y:{min:..., max:..}.',
        type: 'object',
    },
    geoFeatureGroups: {
        type: 'array',
        items: { type: 'object' },
        description:
            'A geo feature group consists of a name, logical bounds, sourcebounds and a featureMatcher, which consists of a property name and a regex to check against that property.',
    },
};
