import { COLOR_OR_TOKEN_PATTERN, getPattern } from '@splunk/visualizations-shared/schemaUtils';

export default {
    gaugeColors: {
        default: ['#84E900', '#FFE800', '#BF3030'],
        description: `Specify the Hex code for colors used in the gauge. For example, ['#FF0000', '#0000FF', '#008000']. Note: Option type 'string' has been deprecated.`,
        type: ['array', 'string'],
        items: { type: 'string' },
    },
    'chart.majorUnit': {
        default: 'auto',
        description: 'Specify, in pixels, the spacing between major tick marks.',
        type: ['number', 'string'],
    },
    'chart.rangeValues': {
        description: `Specify a numeric array that represents the overall numerical range represented by the gauge. This array automatically determines the relative size of the color-coded subranges within the overall range. For example, [100, 500, 300].
            Note: Option type 'string' has been deprecated.`,
        type: ['array', 'string'],
        items: { type: 'number' },
    },
    'chart.showLabels': { default: true, description: 'Specify whether to display labels.', type: 'boolean' },
    'chart.showMajorTicks': {
        default: true,
        description: 'Specify whether to display major tick marks.',
        type: 'boolean',
    },
    'chart.showMinorTicks': {
        default: true,
        description: 'Specify whether to display minor tick marks.',
        type: 'boolean',
    },
    'chart.showValue': {
        default: true,
        description: 'Specify whether the gauge value is displayed.',
        type: 'boolean',
    },
    'chart.style': {
        default: 'shiny',
        description:
            'Specify the display style of the gauge. Shiny: A graphically stylized version of the gauge with chrome, shading, and other features to mimic a real-world gauge. Minimal: A basic gauge display.',
        pattern: getPattern(['minimal', 'shiny']),
        type: 'string',
    },
    'chart.usePercentageRange': {
        default: false,
        description: 'Specify whether to format gauge ranges as percentages.',
        type: 'boolean',
    },
    'chart.usePercentageValue': {
        default: false,
        description: 'Specify whether to format the gauge values as percentages.',
        type: 'boolean',
    },
    'chart.rangeArcAngle': {
        default: 270,
        description:
            'Specify the length of the range arc, in degrees. Positive values are clockwise. Negative values are counterclockwise.',
        type: 'number',
    },
    'chart.rangeStartAngle': {
        default: 45,
        description:
            'Specify the angle, in degrees, to begin drawing the range arc. The range arc is drawn clockwise and the beginning orientation is the bottom of the gauge.',
        type: 'number',
    },
    'chart.showRangeBand': {
        default: true,
        description: 'Specify whether to show the color ranges as a band at the top of the radial gauge.',
        type: 'boolean',
    },
    backgroundColor: {
        description: 'Specify the color used for the background using a Hex Code. For example: "#0000FF".',
        pattern: COLOR_OR_TOKEN_PATTERN,
        type: 'string',
    },
};
