import React, { useContext } from 'react';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type CoreOverridesValue = Record<string, React.ElementType<any>>;

export const CoreOverridesContext = React.createContext<CoreOverridesValue>({});

export const useCoreOverrides = (): CoreOverridesValue => {
    return useContext(CoreOverridesContext);
};
