import { memo, useCallback, useEffect } from 'react';
import { useWorker } from '../hooks/useWorker';
import ValidationWorker from '../workers/validation.worker.ts';
import type { ValidatorArgs, useWorkerArgs } from '../types';
import { handleEvent } from '../workers/validationEventHandler';

const defaultReadOnlyTokenNamespaces: string[] = [];

const ValidatorComponent = ({
    schema,
    definition,
    onError,
    readOnlyTokenNamespaces = defaultReadOnlyTokenNamespaces,
}: ValidatorArgs) => {
    const onMessage = useCallback(
        ({ data }) => {
            if (data) {
                onError(data);
            }
        },
        [onError]
    );

    const postMessage = useCallback(
        (worker: Worker) => {
            worker.postMessage({ schema, definition, readOnlyTokenNamespaces });
        },
        [schema, definition, readOnlyTokenNamespaces]
    );

    // Attempt to use a web worker for validation checking
    const { enabled } = useWorker({
        WorkerClass: ValidationWorker as useWorkerArgs['WorkerClass'],
        onMessage,
        postMessage,
    });

    // `useWorker` hook will return an `enabled` boolean indicating if Web Worker
    // support exists in the target environment
    useEffect(() => {
        if (!enabled) {
            onMessage({
                data: handleEvent(
                    new MessageEvent('pseudoValidationEvent', {
                        data: { schema, definition, readOnlyTokenNamespaces },
                    })
                ),
            });
        }
    }, [definition, enabled, schema, readOnlyTokenNamespaces, onMessage]);

    return null;
};

const isEqual = (arg0: unknown, arg1: unknown): boolean =>
    arg0 === arg1 || JSON.stringify(arg0) === JSON.stringify(arg1);

export const Validator = memo(
    ValidatorComponent,
    (prevProps, nextProps) =>
        prevProps.onError === nextProps.onError &&
        isEqual(prevProps.schema, nextProps.schema) &&
        isEqual(prevProps.definition, nextProps.definition) &&
        isEqual(
            prevProps.readOnlyTokenNamespaces,
            nextProps.readOnlyTokenNamespaces
        )
);
