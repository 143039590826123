import type { CancellableLifecycleEvent } from '@splunk/dashboard-types';
import { DashboardLifecycleEvent } from './DashboardLifecycleEvent';

/**
 * Cancellable event
 */
export class CancellableDashboardLifecycleEvent
    extends DashboardLifecycleEvent
    implements CancellableLifecycleEvent
{
    private cancelled = false;

    preventDefault() {
        this.cancelled = true;
    }

    isCancelled() {
        return this.cancelled;
    }
}
