import React, { useMemo, type MutableRefObject } from 'react';
import type {
    DataSourceDefinition,
    DataSourceEventPayload,
    InputDefinition,
    LayoutItemType,
    Mode,
    VisualizationDefinition,
} from '@splunk/dashboard-types';
import { useFeatureFlags, usePreset } from '@splunk/dashboard-context';
import { ActionMenu } from '../containers/ActionMenu';

/**
 * canvasHeight - 40 is the closest a viz should be from bottom of canvas to
 * render action menu below viz.
 */
const minDistanceFromBottomCanvasForActionMenus = 40;

interface GetActionMenuPositionProps {
    height?: number | string;
    y: number;
    canvasHeight: number;
    shouldUsePortal: boolean;
}

export const getActionMenuPosition = ({
    height,
    y,
    canvasHeight,
    shouldUsePortal,
}: GetActionMenuPositionProps): number | undefined => {
    if (typeof height !== 'number') {
        return undefined;
    }

    // Bottom of the action menu to be rendered
    const menuBottom = height + y;

    // Bottom of the canvas
    const canvasBottom =
        canvasHeight - minDistanceFromBottomCanvasForActionMenus;

    // Only render below the visualization when the action
    // menu will not fall below the bottom of the canvas
    if (menuBottom <= canvasBottom) {
        // Menu can render below viz
        return shouldUsePortal ? 1 : height + 1;
    }

    return undefined;
};

export interface UseActionMenuProps {
    itemId: string;
    itemDefinition: VisualizationDefinition | InputDefinition;
    layoutItemType: LayoutItemType;
    portalRef?: MutableRefObject<HTMLDivElement | null>;
    shouldUsePortal: boolean;
    dataSourceMeta: DataSourceEventPayload['meta'];
    dataSourceDefinitions: Record<string, DataSourceDefinition>;
    isSelected: boolean;
    menuPosition?: number;
    mode?: Mode;
}

export const useActionMenu = ({
    itemId,
    itemDefinition,
    layoutItemType,
    portalRef,
    shouldUsePortal,
    dataSourceMeta,
    dataSourceDefinitions,
    isSelected,
    menuPosition,
    mode = 'view',
}: UseActionMenuProps) => {
    const preset = usePreset();
    const { showLastUpdated: lastUpdatedFeatureFlag } = useFeatureFlags();

    /**
     * Preset should have first say of whether the element should be enabled
     * Then determine if user has enabled/disabled in definition
     */
    const useLastUpdated = useMemo(() => {
        const presetFlags = preset.shouldDisplaySiblingContent(
            itemDefinition.type,
            layoutItemType
        );

        return (
            lastUpdatedFeatureFlag &&
            presetFlags?.showLastUpdated &&
            itemDefinition.showLastUpdated
        );
    }, [
        preset,
        layoutItemType,
        itemDefinition.type,
        itemDefinition.showLastUpdated,
        lastUpdatedFeatureFlag,
    ]);

    const shouldShowActionMenu = useMemo(
        () => preset.shouldShowActionMenu({ mode, layoutItemType }),
        [preset, mode, layoutItemType]
    );

    return useMemo(
        () =>
            shouldShowActionMenu ? (
                <ActionMenu
                    itemId={itemId}
                    type={layoutItemType}
                    top={menuPosition}
                    isSelected={isSelected}
                    showLastUpdated={useLastUpdated}
                    dataSourceMeta={dataSourceMeta}
                    dataSourceDefinitions={dataSourceDefinitions}
                    itemDefinition={itemDefinition}
                    actionMenuPortal={portalRef}
                    renderActionMenuInPortal={shouldUsePortal}
                />
            ) : null,
        [
            shouldShowActionMenu,
            portalRef,
            shouldUsePortal,
            dataSourceDefinitions,
            dataSourceMeta,
            itemId,
            layoutItemType,
            isSelected,
            useLastUpdated,
            itemDefinition,
            menuPosition,
        ]
    );
};
