import React from 'react';
import styled from 'styled-components';
import T from 'prop-types';
import { customThemeVariables } from '@splunk/dashboard-ui';

export const PlaceholderComponent = styled.div.attrs(({ position }) => ({
    style: {
        width: position.w,
        height: position.h,
        transform: `translate(${position.x}px, ${position.y}px)`,
    },
}))`
    display: block;
    position: absolute;
    pointer-events: none;
    box-sizing: border-box;
    border: 1px solid ${customThemeVariables.validDropTargetBorderColor};
    background-color: ${customThemeVariables.validDropTargetBackgroundColor};
`;

export const PreviewPlaceholderItem = ({ position }) => {
    return (
        <PlaceholderComponent
            position={position}
            data-test="preview-placeholder"
            data-test-preview-position={`${position.x},${position.y}-${position.w},${position.h}`}
        />
    );
};

PreviewPlaceholderItem.propTypes = {
    position: T.shape({
        x: T.number.isRequired,
        y: T.number.isRequired,
        w: T.number.isRequired,
        h: T.number.isRequired,
    }).isRequired,
};
