import React from 'react';
import T from 'prop-types';
import styled from 'styled-components';
import {
    usePopoverState,
    PopoverStatusIcon,
    getIconStatus,
    EnterprisePopoverContentFactory,
} from '@splunk/dashboard-ui';
import type { DataSourceEventPayload } from '@splunk/dashboard-types';
import { ProgressBarContainer } from './VizProgressBar';

const statusIconHeight = 20;

const StatusIconContainer = styled.div.attrs(() => ({
    'data-test': 'status-icon-container',
}))`
    height: ${statusIconHeight}px;
    display: flex;
    align-items: center;
    position: absolute;
    top: 5px;
    right: -3px;

    // Move the status icon when the progress bar pushes the title/desc/viz
    ${ProgressBarContainer}:not(:empty) ~ & {
        top: 8px;
    }
`;

const VizStatusIcon = ({
    dataSource,
    id,
}: {
    dataSource?: DataSourceEventPayload;
    id: string;
}): JSX.Element | null => {
    const { open, onRequestOpen, onRequestClose } = usePopoverState();

    const iconStatus = getIconStatus(dataSource);

    if (!iconStatus) {
        return null;
    }

    return (
        <StatusIconContainer>
            <PopoverStatusIcon
                height={statusIconHeight}
                open={open}
                onRequestOpen={onRequestOpen}
                onRequestClose={onRequestClose}
                status={iconStatus}
            >
                <EnterprisePopoverContentFactory
                    dataSource={dataSource}
                    componentId={id}
                    onRequestClose={onRequestClose}
                />
            </PopoverStatusIcon>
        </StatusIconContainer>
    );
};

VizStatusIcon.propTypes = {
    dataSource: T.object,
    id: T.string,
};

export default VizStatusIcon;
