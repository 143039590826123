import get from 'lodash/get';
import getSettingsFromThemedProps from '@splunk/themes/getSettingsFromThemedProps';

// TODO: update splunk-charting to accept 'prisma' directly values
const chartingThemes = {
    light: {
        enterprise: 'default',
        // TODO(fkurniawan): add prisma light theming to splunk-charting once designs are finalized
        prisma: 'default',
    },
    dark: {
        enterprise: 'dark',
        prisma: 'prismaDark',
    },
};

/*
 * convert theme name for splunk-charting based visualization
 * @params {Object} theme
 * @return {String} 'prismaDark', 'dark' or 'default'
 */
export const getChartingThemeKey = theme => {
    if (!theme) {
        return 'prismaDark';
    }

    const { colorScheme, family } = getSettingsFromThemedProps({ theme });
    return get(chartingThemes, [colorScheme, family], 'prismaDark');
};
