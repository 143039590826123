import React from 'react';
import styled from 'styled-components';
import WaitSpinner from '@splunk/react-ui/WaitSpinner';

const WaitSpinnerContainer = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 15px;
`;

const LoadingIndicator = () => (
    <WaitSpinnerContainer data-test="loading-indicators">
        <WaitSpinner size="medium" />
    </WaitSpinnerContainer>
);

LoadingIndicator.propTypes = {};
LoadingIndicator.defaultProps = {};
export default LoadingIndicator;
