import * as React from 'react';
import styled from 'styled-components';
import SizeAwareWrapper from '@splunk/visualizations-shared/SizeAwareWrapper';
import { toPx } from '@splunk/visualizations-shared/style';
import { isColor } from '@splunk/visualizations-shared/colorUtils';

// Global provided via webpack
// eslint-disable-next-line no-underscore-dangle
declare const __VISION_VERSION__: string;

interface ItemProps {
    backgroundColor?: string;
    width?: string | number;
}

const Item = styled.div.attrs<ItemProps>(({ width }) => ({
    style: {
        width: toPx(width),
    },
}))<ItemProps>`
    position: relative;
    background-color: ${(props): string =>
        (isColor(props.backgroundColor) && props.backgroundColor) || props.theme.defaultBackgroundColor}};
`;

interface FixSizeItemProps {
    backgroundColor?: string;
    height?: string | number;
    width?: string | number;
}

// Use `.attrs()` for highly dynamic styles. Ref: https://github.com/styled-components/styled-components/issues/134#issuecomment-312415291
const FixSizeItem = styled.div.attrs<FixSizeItemProps>(({ width, height }): {
    style: { width?: string; height?: string };
} => ({
    style: {
        ...(width && { width: toPx(width) }),
        ...(height && { height: toPx(height) }),
    },
}))<FixSizeItemProps>`
    position: relative;
    flex-direction: column;
    flex: 1;
    display: flex;
    background-color: ${(props): string =>
        (isColor(props.backgroundColor) && props.backgroundColor) || props.theme.defaultBackgroundColor}};
`;

const ItemContent = styled.div`
    position: relative;
    min-height: 100px;
    width: 100%;
`;

const FixSizeItemContent = styled.div`
    min-height: 0px;
    flex: 1;
    position: relative;
    box-sizing: border-box;
`;

interface HeightAndWidthProps {
    width?: string | number;
    height?: string | number;
    backgroundColor?: string;
}

const withFixedSizeContainer = <P extends HeightAndWidthProps>(
    Viz: React.ComponentType<P>
): React.ComponentType<P> => {
    const FixedSizeContainer = (props: P): React.ReactElement => {
        const { width, height, backgroundColor } = props;

        if (!height) {
            return (
                <Item data-test="item" width={width} backgroundColor={backgroundColor}>
                    <ItemContent>
                        <Viz {...props} />
                    </ItemContent>
                </Item>
            );
        }
        return (
            <FixSizeItem
                data-test="fix-size-item"
                data-splunk-visualization-version={__VISION_VERSION__}
                width={width}
                height={height}
                backgroundColor={backgroundColor}
            >
                <FixSizeItemContent>
                    <SizeAwareWrapper>
                        {({ width: newWidth, height: newHeight }): React.ReactElement => (
                            <Viz {...props} width={newWidth} height={newHeight} />
                        )}
                    </SizeAwareWrapper>
                </FixSizeItemContent>
            </FixSizeItem>
        );
    };
    FixedSizeContainer.displayName = 'withFixedSizeContainer';
    return FixedSizeContainer;
};

export default withFixedSizeContainer;
