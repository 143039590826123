import React, {
    useCallback,
    useMemo,
    useEffect,
    useState,
    cloneElement,
} from 'react';
import SUISelect from '@splunk/react-ui/Select';
import { _ } from '@splunk/ui-utils/i18n';
import {
    stringToKeywords,
    testPhrase,
    keywordLocations,
} from '@splunk/ui-utils/filter';
import { mapKeys } from 'lodash';
import { DropdownInput as DropdownIcon } from '@splunk/dashboard-icons';
import BaseInput from '../components/BaseInput';
import { withInputWrapper } from '../utils/enhancer';
import {
    dataContract,
    getFirstSearchResult,
    mergeItems,
    fetchDynamicContent,
    hasDynamicOptions,
    getSearchStatus,
} from '../utils/inputItem';
import SelectSchema from './SelectSchema';
import DropdownEditor from '../components/DropdownEditorConfig';

const selectStyle = {
    width: '100%',
};

const loadingMessage = _('Loading menu items...');

const noItems = [];

const Select = ({
    value,
    onValueChange,
    options: { defaultValue, items = noItems, selectFirstSearchResult = false },
    dataSources,
    encoding,
    context,
    loading: isLoading,
    isError,
    errorMessage,
    isDisabled: initialDisableStatus,
    disabledMessage: initialDisabledMessage,
}) => {
    const searchHasNoResults = getSearchStatus(dataSources);

    // can be disabled if no search results return and selectFirstSearchResult is off, OR if selectFirstSearchResult is on and there are no static items
    const selectFirstWithNoItems =
        (items.length === 0 && selectFirstSearchResult) ||
        !selectFirstSearchResult;

    // defaultValue should be null as it can evaluate to static items if the values are not presented in the item list
    const isDisabled =
        initialDisableStatus ||
        (defaultValue == null && searchHasNoResults && selectFirstWithNoItems);
    const disabledMessage =
        isDisabled && (initialDisabledMessage || _('No results'));

    // Memoized: Calculate the allItems and the first search result for dynamic items
    const { allItems, firstSearchResult, selectedItem } = useMemo(() => {
        if (isError || isLoading || isDisabled) {
            return { allItems: noItems, firstSearchResult: null };
        }

        // Fetch the set of items from dataSources
        const dynamicItems = fetchDynamicContent({
            context,
            items,
            dataSources,
            encoding,
        });

        const staticItems = hasDynamicOptions([items]) ? noItems : items;

        // Get the first result from the dynamic data
        const firstResult = selectFirstSearchResult
            ? getFirstSearchResult({ staticItems, dynamicItems })
            : null;

        // Merge dynamic and static values
        const defaultValues = defaultValue != null ? [defaultValue] : noItems;
        const mergedItems = mergeItems({
            dynamicItems,
            staticItems,
            defaultValues,
        });

        const menuOptions = [];
        let selected;

        mergedItems.forEach((item) => {
            // Filter invalid items from the items list
            // Items are invalid if they do not have a value
            if (!item.value) {
                return;
            }

            // if the item is currently selected, do not put it in menuOptions, instead keep track of it separately
            if (item.value === value) {
                selected = item;
                return;
            }

            // Convert merged items into Select Options
            // item.value is guaranteed to be unique from getItemList
            menuOptions.push(
                <SUISelect.Option
                    label={item.label}
                    value={item.value}
                    key={item.value}
                    truncate
                />
            );
        });

        return {
            allItems: menuOptions,
            firstSearchResult: firstResult,
            selectedItem: selected,
        };
    }, [
        isError,
        isLoading,
        isDisabled,
        context,
        items,
        dataSources,
        encoding,
        selectFirstSearchResult,
        defaultValue,
        value,
    ]);

    const selectedMenuItem = useMemo(() => {
        if (!selectedItem) {
            return null;
        }

        return (
            <SUISelect.Option
                label={selectedItem.label}
                value={selectedItem.value}
                key={`selected-${selectedItem.value}`}
                truncate
            />
        );
    }, [selectedItem]);

    const [currentKeyword, setCurrentKeyword] = useState('');
    const [resultLength, setResultLength] = useState(50);
    const [isSimulatingLoad, setIsSimulatingLoad] = useState(false);
    const currentValue = value || firstSearchResult || defaultValue || '';

    // Store this as a separate useMemo in order to make sure that changes to
    //  'allItems' is captured in the final filtered value. (ie. selecting an item removes it from allItems)
    const filterResults = useMemo(() => {
        if (!currentKeyword) {
            return [];
        }

        const keywords = stringToKeywords(currentKeyword);

        return (
            allItems
                // filter items using SUI helper function
                // filter out any already selected values
                .filter(
                    (option) =>
                        option.props.value !== selectedItem?.value &&
                        testPhrase(option.props.label, keywords)
                )
                // highlight the matched text
                .map((option) => {
                    const matchRanges =
                        keywords &&
                        keywordLocations(option.props.label, keywords);

                    return cloneElement(option, {
                        matchRanges: matchRanges || undefined,
                    });
                })
        );
    }, [allItems, currentKeyword, selectedItem?.value]);

    // current maximum possible length of list (filtered or otherwise)
    const maxLength = useMemo(
        () => (currentKeyword ? filterResults.length : allItems.length),
        [currentKeyword, filterResults, allItems.length]
    );

    const handleFilterChange = useCallback((e, { keyword }) => {
        // indicate whether results are being filtered
        setCurrentKeyword(keyword);
        // reset result length to 50 on each keyword update
        setResultLength(50);

        // Due to a SUI bug, when you are scrolled to the bottom of a Select and then change filter,
        //  because `scrollBottom` is set to null, the SUI select does not update its internal state.
        //  This setTimeout is basically ensuring that the state is reset to where scrollBottom is not null
        //  and only after that we update the children of the select (aka menuItems)
        setIsSimulatingLoad(true);
        setTimeout(() => setIsSimulatingLoad(false), 0);
    }, []);

    // In order to set the token on input creation once the search resolves
    useEffect(() => {
        if (!value && !!firstSearchResult) {
            onValueChange(null, firstSearchResult);
        }
    }, [firstSearchResult, onValueChange, value]);

    // Click handler for select, dereferences item value from SUI payload
    const handleClick = useCallback(
        (evt, { value: itemValue }) => {
            // TODO: keep this from firing if value hasn't actually changed?
            onValueChange(evt, itemValue);
        },
        [onValueChange]
    );

    const handleScrollBottom = useCallback(() => {
        setResultLength((currResultLen) => currResultLen + 50);
    }, []);

    const handleClose = useCallback(() => {
        setResultLength(50);
    }, []);

    const placeholder = useMemo(() => {
        return _(errorMessage || disabledMessage || 'Select a value');
    }, [errorMessage, disabledMessage]);

    const menuItems = useMemo(() => {
        if (isSimulatingLoad) {
            return [];
        }

        return currentKeyword
            ? filterResults.slice(0, resultLength)
            : allItems.slice(0, resultLength);
    }, [
        allItems,
        currentKeyword,
        filterResults,
        isSimulatingLoad,
        resultLength,
    ]);

    return (
        <SUISelect
            value={currentValue}
            onChange={handleClick}
            disabled={isDisabled || isError}
            defaultPlacement="below"
            // TODO: restore when theme is fixed to be red outline instead of red fill
            // error={isError}
            // Externally to the input can determine if the input is disabled and what the messaging is
            isLoadingOptions={isLoading || isSimulatingLoad}
            loadingMessage={loadingMessage}
            placeholder={placeholder}
            filter={isLoading ? false : 'controlled'}
            inline
            style={selectStyle}
            // set to null when scrolled fully to bottom, per SUI docs
            onScrollBottom={
                menuItems.length < maxLength ? handleScrollBottom : null
            }
            onFilterChange={handleFilterChange}
            onClose={handleClose}
        >
            {selectedMenuItem}
            {selectedMenuItem && <SUISelect.Divider />}
            {menuItems}
        </SUISelect>
    );
};

Select.propTypes = {
    ...BaseInput.propTypes,
};

Select.defaultProps = {
    ...BaseInput.defaultProps,
};

const meta = {
    label: _('Dropdown'),
    description: _('Select a single list value'),
    defaultConfig: {
        options: {
            items: [
                { label: _('All'), value: '*' },
                { label: _('Item 1'), value: 'item001' },
                { label: _('Item 2'), value: 'item002' },
            ],
            defaultValue: '*',
        },
        title: _('Dropdown Input Title'),
    },
    tokenPrefix: 'dd',
    icon: DropdownIcon,
};

Select.config = {
    optionsSchema: SelectSchema,
    editorConfig: DropdownEditor,
    dataContract,
    ...mapKeys(meta, (value, key) =>
        key === 'defaultConfig' ? 'baseShape' : key
    ),
};

/**
 * Transforms the value or values from the input to a set of token: value pairs
 * @param {String} value Select only the selected item value
 * @param {Object} meta
 * @param {String} meta.token The token name
 * @param {String} meta.prefix Content that prepends the value
 * @param {String} meta.suffix Content that appends the value
 * @returns {Object}
 */
Select.valueToTokens = (value, { token, prefix = '', suffix = '' }) => {
    if (!token) {
        return {};
    }
    if (!value) {
        return {
            [token]: null,
        };
    }
    return {
        [token]: `${prefix}${value}${suffix}`,
    };
};

export default withInputWrapper(Select);
