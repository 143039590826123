import { PureMarkerGauge, config } from '@splunk/visualizations/MarkerGauge';
import { withTheme } from 'styled-components';
import { withSanitizedProps } from '@splunk/visualizations-shared/SanitizeProps';
import React from 'react';

const ReactMarkerGauge = props => {
    const defaultColors = {
        fillColor: config.themes.defaultFillColor(props),
        majorTickFillColor: config.themes.majorTickFillColor(props),
        majorTickStrokeColor: config.themes.majorTickStrokeColor(props),
        valueMarkerFillColor: config.themes.valueMarkerFillColor(props),
        valueMarkerLabelFillColor: config.themes.valueMarkerLabelFillColor(props),
    };
    return <PureMarkerGauge {...defaultColors} {...props} />;
};

export default withTheme(withSanitizedProps(ReactMarkerGauge));
