/* eslint-disable max-len */
import React from 'react';
import { _ } from '@splunk/ui-utils/i18n';
import SVG from '@splunk/react-icons/SVGEnterprise';

const svgStyle = { stroke: 'currentColor', strokeWidth: 2, fill: 'none' };

export default function Image(props) {
    return (
        <SVG viewBox="0 0 32 32" screenReaderText={_('Image')} {...props}>
            <rect x="5" y="5" width="22" height="22" style={svgStyle} />
            <path
                d="M12,14.6667 C13.4728,14.6667 14.6667,13.4728 14.6667,12 C14.6667,10.5272 13.4728,9.33333 12,9.33333 C10.5273,9.33333 9.33337,10.5272 9.33337,12 C9.33337,13.4728 10.5273,14.6667 12,14.6667 Z"
                style={svgStyle}
            />
            <polyline
                strokeLinejoin="round"
                points="4.66663 22.6666 9.33329 20 14 22.6666 20.6666 14.6667 26.6666 22.6666"
                style={svgStyle}
            />
        </SVG>
    );
}
