import { _ } from '@splunk/ui-utils/i18n';

const StaticMajorAndTrendLayout = [
    [
        {
            label: 'Unit position', // todo: we need a new editor combining unit and position
            option: 'unitPosition',
            editor: 'editor.select',
            editorProps: {
                values: [
                    { label: _('Before'), value: 'before' },
                    { label: _('After'), value: 'after' },
                ],
            },
            showEditor: ({ options }) => options.showValue || options.showValue === undefined,
        },
        {
            label: _('Unit label'),
            option: 'unit',
            editor: 'editor.text',
            showEditor: ({ options }) => options.showValue || options.showValue === undefined,
        },
    ],
    [
        {
            label: _('Precision'),
            option: 'numberPrecision',
            editor: 'editor.number',
            editorProps: {
                min: 0,
                max: 20,
            },
            showEditor: ({ options }) => options.showValue || options.showValue === undefined,
        },
    ],
];

export default StaticMajorAndTrendLayout;
