import { mapValues } from 'lodash';
import { hashString } from '@splunk/dashboard-utils';
import type { PlainObject, FilterDefinition } from '../FilterTypes';

type FilterEventHandlerOptions = (eventHandler: PlainObject) => PlainObject;

const filterEventHandlerOptions: FilterEventHandlerOptions = (eventHandler) =>
    mapValues(eventHandler, (value: PlainObject, key) => {
        if (key === 'options') {
            return mapValues(value, (v, k: string) => {
                if (k === 'url') {
                    return hashString(v as string, 'url_');
                }
                if (Array.isArray(v)) {
                    return v.length;
                }
                if (typeof v === 'string') {
                    return v.length; // omit eventHandlers from Studio
                }
                return v;
            });
        }

        return value;
    });

type FilterOptionsOnItem = (item: PlainObject) => PlainObject;

const filterEventHandlersOnItem: FilterOptionsOnItem = (item) =>
    mapValues(item, (value: PlainObject[], key: string) =>
        key === 'eventHandlers'
            ? value.map((handler) => filterEventHandlerOptions(handler))
            : value
    );

const filterEventHandlers: FilterDefinition = (definition) =>
    mapValues(definition, (value: PlainObject, key) => {
        if (key === 'visualizations') {
            return mapValues(value, filterEventHandlersOnItem);
        }

        return value;
    });

export default filterEventHandlers;
