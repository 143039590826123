import { _ } from '@splunk/ui-utils/i18n';

const editorConfig = [
    {
        label: _('Visualization Settings'),
        open: true,
        layout: [
            [
                {
                    label: _('Upload SVG'),
                    option: 'svg',
                    editor: 'editor.image',
                    editorProps: {
                        validMediaTypes: ['svg'],
                        svgRenderAsDom: true,
                    },
                },
            ],
        ],
    },
];

export default editorConfig;
