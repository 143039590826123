import React from 'react';
import { _ } from '@splunk/ui-utils/i18n';
import SVG, {
    type SVGPropsOptionalViewBox,
} from '@splunk/react-icons/SVGEnterprise';

export default function WorldMap(
    props: SVGPropsOptionalViewBox
): ReturnType<typeof SVG> {
    return (
        <SVG
            screenReaderText={_('World Map')}
            viewBox="0 0 18 18"
            {...props}
            // TODO: react-icons's SVG seems to have added overflow: visible, bring back old behavior for now
            style={{ ...props.style, overflow: 'hidden' }} // eslint-disable-line react-perf/jsx-no-new-object-as-prop, react/prop-types
        >
            <path
                d="M9.00033 0.666748C4.40033 0.666748 0.666992 4.40008 0.666992 9.00008C0.666992 13.6001 4.40033 17.3334 9.00033 17.3334C13.6003 17.3334 17.3337 13.6001 17.3337 9.00008C17.3337 4.40008 13.6003 0.666748 9.00033 0.666748ZM2.33366 9.00008C2.33366 8.49175 2.40033 7.99175 2.50866 7.51675L6.49199 11.5001V12.3334C6.49199 13.2501 7.24199 14.0001 8.15866 14.0001V15.6084C4.88366 15.1917 2.33366 12.3917 2.33366 9.00008ZM13.9087 13.5001C13.692 12.8251 13.0753 12.3334 12.3253 12.3334H11.492V9.83342C11.492 9.37508 11.117 9.00008 10.6587 9.00008H5.65866V7.33342H7.32533C7.78366 7.33342 8.15866 6.95842 8.15866 6.50008V4.83342H9.82533C10.742 4.83342 11.492 4.08341 11.492 3.16675V2.82508C13.9337 3.80841 15.667 6.20842 15.667 9.00008C15.667 10.7334 14.992 12.3167 13.9087 13.5001Z"
                fill="currentColor"
            />
        </SVG>
    );
}
