import * as React from 'react';
import styled from 'styled-components';
import { DashboardVizProps } from '../interfaces/DashboardVizProps';
import { ExtendedDashViz } from '../interfaces/ExtendedDashViz';
import { extendStaticWrapperProps } from '../utils/hocUtils';

// Global provided via webpack
// eslint-disable-next-line no-underscore-dangle
declare const __VISION_VERSION__: string;

const Cover = styled.div`
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
`;

const Blocker = styled.div.attrs({
    'data-splunk-visualization-version': __VISION_VERSION__,
})`
    position: relative;
    width: 100%;
    height: 100%;
`;

/**
 * HOC that renders a cover layer in edit mode
 * @method withEditModeCover
 * @param {ExtendedDashViz} Visualization
 * @returns {ExtendedDashViz} Wrapper
 */
const withEditModeCover = (
    Visualization: ExtendedDashViz,
    disableEditModeCover?: boolean
): ExtendedDashViz => {
    const Wrapper = (props: DashboardVizProps): React.ReactElement<DashboardVizProps> => {
        const { mode } = props;
        const showEditCover = mode === 'edit' && !disableEditModeCover;

        return (
            <Blocker>
                <Visualization {...props} />
                {showEditCover && <Cover data-test="edit-mode-cover" />}
            </Blocker>
        );
    };
    extendStaticWrapperProps({ Wrapper, Visualization });
    return Wrapper as ExtendedDashViz;
};

export default withEditModeCover;
