import { COLOR_OR_TOKEN_PATTERN_WITH_RGBA, getPattern } from '@splunk/visualizations-shared/schemaUtils';
import { VIZ_CATEGORICAL } from '@splunk/visualization-color-palettes';
import { Line as LineIcon } from '@splunk/visualization-icons';
import { Line as LinePlaceholderIcon } from '@splunk/visualization-icons/placeholders';
import { _ } from '@splunk/ui-utils/i18n';
import pick from '@splunk/themes/pick';
import variables from '@splunk/themes/variables';
import { AxesChartDataConfigAreaColumnLine } from '../common/editorConfig/AxesChartDataConfig';
import { AxesChartDataDisplayLine } from '../common/editorConfig/AxesChartDataDisplay';
import AxesChartColorAndStyle from '../common/editorConfig/AxesChartColorAndStyle';
import AxesChartLegend from '../common/editorConfig/AxesChartLegend';
import AxesChartXGridAndLabels from '../common/editorConfig/AxesChartXGridAndLabels';
import AxesChartYGridAndLabels from '../common/editorConfig/AxesChartYGridAndLabels';
import AxesChartY2GridAndLabels from '../common/editorConfig/AxesChartY2GridAndLabels';

import { DataContract } from '../common/interfaces/DataContract';
import { DefaultContext } from '../common/interfaces/DefaultContext';
import { EditorConfig } from '../common/interfaces/Editor';
import { OptionsSchema } from '../common/interfaces/OptionsSchema';
import { ThemedDefaults } from '../common/interfaces/ThemedDefaults';
import { VizBehavior } from '../common/interfaces/VizBehavior';
import { VizCategory } from '../common/interfaces/VizCategory';
import { VizConfig } from '../common/interfaces/VizConfig';
import { VizSize } from '../common/interfaces/VizSize';
import { enhanceConfig } from '../common/utils/configUtils';

const dataContract: DataContract = {
    requiredDataSources: [
        {
            name: 'primary',
            description: 'DataSource that powers the visualization',
        },
    ],
    optionalDataSources: [
        {
            name: 'annotation',
            description: 'DataSource that populates event annotations',
        },
    ],
    initialRequestParams: {
        primary: { offset: 0, count: 10000 },
        annotation: { offset: 0, count: 10000 },
    },
};

const size: VizSize = {
    initialWidth: 300,
    initialHeight: 300,
};

const defaultContext: DefaultContext = {};

const optionsSchema: OptionsSchema = {
    x: {
        default: '> primary | seriesByIndex(0)',
        description: 'Specify the dataSource applied to the x-axis.',
        type: 'array',
        items: { type: ['string', 'number'] },
    },
    y: {
        default: '> primary | frameBySeriesIndexRange(1)',
        description: 'Specify the dataSource applied to the y-axis.',
        type: 'array',
        items: { type: ['string', 'number'] },
    },
    y2: {
        description: 'Specify the dataSource applied to the y2-axis',
        type: 'array',
        items: { type: ['string', 'number'] },
    },
    xField: {
        default: '> x | getField()',
        description: 'Specify the field that should be mapped to the x-axis.',
        type: 'string',
    },
    yFields: {
        default: '> y | getField()',
        description: 'Specify the field that should be mapped to the y-axis.',
        type: 'string',
    },
    annotationColor: {
        description: `Specify the annotation colors. You may use a dataSource to apply the color. For example, [“#FF0000”, “#0000FF", “#008000”].`,
        type: 'array',
        items: { type: 'string' },
    },
    annotationLabel: {
        description: `Specify a list of labels used for the annotations. You may use a dataSource to apply the labels. For example, [“houston, we have a problem”, “just close the jira”, “looking good now”].`,
        type: 'array',
        items: { type: 'string' },
    },
    annotationX: {
        description: 'Specify the dataSource field applied to the event annotation on the x-axis.',
        type: 'array',
        items: { type: ['string', 'number'] },
    },
    backgroundColor: {
        default: '> themes.defaultBackgroundColor',
        description:
            'Specify the color for the background. You may use a dataSource to apply the color. The default for enterprise light is "#ffffff". The default for enterprise dark is "#000000". The default for prisma dark is "#0b0c0e".',
        pattern: COLOR_OR_TOKEN_PATTERN_WITH_RGBA,
        type: 'string',
    },
    dataValuesDisplay: {
        default: 'off',
        description:
            'Specify whether chart should display no labels, all labels, or only the min and max labels.',
        pattern: getPattern(['off', 'all', 'minmax']),
        type: 'string',
    },
    legendDisplay: {
        default: 'right',
        description:
            'Specify the location of the legend on the panel. You must select the Standard legend mode to make this change.',
        pattern: getPattern(['right', 'left', 'top', 'bottom', 'off']),
        type: 'string',
    },
    legendLabels: {
        description: `Specify a list of labels to pre-populate the legend. For example, ["percent", "count"].`,
        type: 'array',
        items: { type: 'string' },
    },
    legendMode: {
        default: 'standard',
        description:
            'Specify visual and behavioral settings for the tooltip and legend. "seriesCompare" is useful when comparing series.',
        pattern: getPattern(['standard', 'seriesCompare']),
        type: 'string',
    },
    legendTruncation: {
        default: 'ellipsisEnd',
        description:
            'Specify how to display legend labels when they overflow the layout boundaries by replacing overflow text with an ellipsis.',
        pattern: getPattern(['ellipsisEnd', 'ellipsisMiddle', 'ellipsisStart', 'ellipsisOff']),
        type: 'string',
    },
    lineDashStyle: {
        default: 'solid',
        description: 'Specify a dash style for all line series in the chart.',
        pattern: getPattern([
            'solid',
            'shortDash',
            'shortDot',
            'shortDashDot',
            'shortDashDotDot',
            'dot',
            'dash',
            'longDash',
            'dashDot',
            'longDashDot',
            'longDashDotDot',
        ]),
        type: 'string',
    },
    lineDashStylesByField: {
        description: `Specify a dash style to use for each field. For example, {"count": "longDashDot", "percent": "longDashDotDot"}.
        Valid values: "solid", "shortDash", "shortDot", "shortDashDot", "shortDashDotDot", "dot", "dash",
        "longDash", "dashDot", "longDashDot", "longDashDotDot".`,
        type: 'object',
    },
    lineWidth: {
        default: 2,
        description: 'Specify the line width (px), for all line series.',
        type: 'number',
    },
    markerDisplay: {
        default: 'off',
        description: `Specify the marker display style for data points.`,
        pattern: getPattern(['off', 'filled', 'outlined']),
        enum: ['off', 'filled', 'outlined'],
        type: 'string',
    },
    nullValueDisplay: {
        default: 'gaps',
        description: 'Specify how area and line charts handle null values.',
        pattern: getPattern(['gaps', 'zero', 'connect']),
        type: 'string',
    },
    resultLimit: {
        default: 50000,
        description: 'Specify the number of data points rendered in a chart.',
        type: 'number',
    },
    seriesColors: {
        default: VIZ_CATEGORICAL,
        description: 'Specify the colors used for a series. For example, ["#FF0000", "#0000FF", "#008000"].',
        type: 'array',
        items: { type: 'string' },
    },
    seriesColorsByField: {
        description:
            'Specify the colors used for specific fields in a series. For example, {“count”: “#008000”, “percent”: “#FFA500”}.',
        type: 'object',
    },
    showLineSmoothing: {
        default: false,
        description: 'Specify whether lines are straight or smoothed using curves.',
        type: 'boolean',
    },
    showIndependentYRanges: {
        default: false,
        description: 'Specify whether split series charts have independent y-ranges.',
        type: 'boolean',
    },
    showSplitSeries: {
        default: false,
        description:
            'Set to "true" to split a multi-series chart into separate charts that are stacked from top to bottom; one for each series.',
        type: 'boolean',
    },
    showXMajorGridLines: {
        default: false,
        description: 'Specify whether major grid lines are visible on the x-axis.',
        type: 'boolean',
    },
    showYMajorGridLines: {
        default: true,
        description: 'Specify whether major grid lines are visible on the y-axis.',
        type: 'boolean',
    },
    showY2MajorGridLines: {
        default: false,
        description: 'Specify whether major grid lines are visible on the y2-axis.',
        type: 'boolean',
    },
    showYMinorGridLines: {
        default: false,
        description: 'Specify whether minor grid lines are visible on the y-axis.',
        type: 'boolean',
    },
    showY2MinorGridLines: {
        default: false,
        description: 'Specify whether minor grid lines are visible on y2-axis.',
        type: 'boolean',
    },
    showYAxisExtendedRange: {
        default: true,
        description: 'Specify whether the y-axis should be extended to include whole major tick marks.',
        type: 'boolean',
    },
    showYAxisWithZero: {
        default: false,
        description: 'Specify whether the y-axis range includes zero.',
        type: 'boolean',
    },
    showY2AxisWithZero: {
        default: false,
        description: 'Specify whether the y2-axis range includes zero.',
        type: 'boolean',
    },
    xAxisLabelRotation: {
        default: 0,
        description: 'Specify the rotation of the x-axis label in degrees.',
        enum: [-90, -45, 0, 45, 90],
        type: 'number',
    },
    xAxisLabelVisibility: {
        default: 'auto',
        description: 'Specify whether to show or hide labels on the x-axis.',
        pattern: getPattern(['auto', 'show', 'hide']),
        type: 'string',
    },
    yAxisLabelVisibility: {
        default: 'auto',
        description: 'Specify whether to show or hide labels on the y-axis.',
        pattern: getPattern(['auto', 'show', 'hide']),
        type: 'string',
    },
    y2AxisLabelVisibility: {
        default: 'auto',
        description: 'Specify whether to show or hide labels on the y2-axis.',
        pattern: getPattern(['auto', 'show', 'hide']),
        type: 'string',
    },
    xAxisLineVisibility: {
        default: 'hide',
        enum: ['hide', 'show'],
        description: 'Specify whether to show or hide the x-axis line.',
        pattern: getPattern(['show', 'hide']),
        type: 'string',
    },
    yAxisLineVisibility: {
        default: 'hide',
        description: 'Specify whether to show or hide the y-axis line.',
        enum: ['hide', 'show'],
        pattern: getPattern(['show', 'hide']),
        type: 'string',
    },
    y2AxisLineVisibility: {
        default: 'hide',
        description: 'Specify whether to show or hide the y2-axis line.',
        enum: ['hide', 'show'],
        pattern: getPattern(['show', 'hide']),
        type: 'string',
    },
    xAxisMajorTickSize: {
        default: 6,
        description: 'Specify the size, in pixels, of major tick marks on the x-axis.',
        type: 'number',
    },
    yAxisMajorTickSize: {
        default: 6,
        description: 'Specify the size, in pixels, of major tick marks on the y-axis.',
        type: 'number',
    },
    y2AxisMajorTickSize: {
        default: 6,
        description: 'Specify the size, in pixels, of major tick marks on the y2-axis.',
        type: 'number',
    },
    yAxisMinorTickSize: {
        default: 6,
        description: 'Specify the size, in pixels, of minor tick marks on the y-axis.',
        type: 'number',
    },
    y2AxisMinorTickSize: {
        default: 6,
        description: 'Specify the size, in pixels, of minor tick marks on the y2-axis.',
        type: 'number',
    },
    xAxisMajorTickVisibility: {
        default: 'auto',
        description: 'Specify whether to show or hide major tick marks on the x-axis.',
        pattern: getPattern(['auto', 'show', 'hide']),
        type: 'string',
    },
    yAxisMajorTickVisibility: {
        default: 'auto',
        description: 'Specify whether to show or hide major tick marks on the y-axis.',
        pattern: getPattern(['auto', 'show', 'hide']),
        type: 'string',
    },
    y2AxisMajorTickVisibility: {
        default: 'auto',
        description: 'Specify whether to show or hide major tick marks on the y2-axis.',
        pattern: getPattern(['auto', 'show', 'hide']),
        type: 'string',
    },
    yAxisMinorTickVisibility: {
        default: 'auto',
        description: 'Specify whether to show or hide minor tick marks on the y-axis.',
        pattern: getPattern(['auto', 'show', 'hide']),
        type: 'string',
    },
    y2AxisMinorTickVisibility: {
        default: 'auto',
        description: 'Specify whether to show or hide minor tick marks on the y2-axis.',
        pattern: getPattern(['auto', 'show', 'hide']),
        type: 'string',
    },
    xAxisMaxLabelParts: {
        default: 3,
        minimum: 1,
        maximum: 3,
        description:
            'Specify the maximum number of time-parts for a tick label. The 3 possible parts are year, month, and time. Values can range from 1 to 3.',
        type: 'number',
    },
    xAxisTitleText: {
        description: 'Specify the title of the x-axis.',
        type: 'string',
    },
    yAxisTitleText: {
        description: 'Specify the title of the y-axis.',
        type: 'string',
    },
    y2AxisTitleText: {
        description: 'Specify the title of the y2-axis.',
        type: 'string',
    },
    xAxisTitleVisibility: {
        default: 'show',
        description: 'Specify whether to show or hide the title of the x-axis.',
        enum: ['hide', 'show'],
        pattern: getPattern(['show', 'hide']),
        type: 'string',
    },
    yAxisTitleVisibility: {
        default: 'show',
        description: 'Specify whether to show or hide the title of the y-axis.',
        enum: ['hide', 'show'],
        pattern: getPattern(['show', 'hide']),
        type: 'string',
    },
    y2AxisTitleVisibility: {
        default: 'show',
        description: 'Specify whether to show or hide the title of the y2-axis.',
        enum: ['hide', 'show'],
        pattern: getPattern(['show', 'hide']),
        type: 'string',
    },
    yAxisAbbreviation: {
        default: 'auto',
        description: 'Specify whether to abbreviate large y-axis values with the closest SI prefix.',
        enum: ['off', 'auto'],
        pattern: getPattern(['off', 'auto']),
        type: 'string',
    },
    y2AxisAbbreviation: {
        default: 'auto',
        description: 'Specify whether to abbreviate large y2-axis values with the closest SI prefix.',
        enum: ['off', 'auto'],
        pattern: getPattern(['off', 'auto']),
        type: 'string',
    },
    yAxisMajorTickInterval: {
        default: 'auto',
        description: 'Specify the spacing unit between major tick marks along the numeric y-axis.',
        type: ['string', 'number'],
    },
    y2AxisMajorTickInterval: {
        default: 'auto',
        description: 'Specify the spacing unit between major tick marks along the numeric y2-axis.',
        type: ['string', 'number'],
    },
    // TODO(SCP-40274): Restrict string value to only 'auto' to achieve precise schema validation
    yAxisMax: {
        default: 'auto',
        description: 'Specify the maximum number for the visible y-axis range.',
        type: ['string', 'number'],
    },
    // TODO(SCP-40274): Restrict string value to only 'auto' to achieve precise schema validation
    y2AxisMax: {
        default: 'auto',
        description: 'Specify the maximum number for the visible y2-axis range.',
        type: ['string', 'number'],
    },
    // TODO(SCP-40274): Restrict string value to only 'auto' to achieve precise schema validation
    yAxisMin: {
        default: 'auto',
        description: 'Specify the minimum number for the visible y-axis range.',
        type: ['string', 'number'],
    },
    // TODO(SCP-40274): Restrict string value to only 'auto' to achieve precise schema validation
    y2AxisMin: {
        default: 'auto',
        description: 'Specify the minimum number for the visible y2-axis range.',
        type: ['string', 'number'],
    },
    yAxisScale: {
        default: 'linear',
        description: 'Specify the type of scale that applies to a numerical y-axis.',
        pattern: getPattern(['linear', 'log']),
        type: 'string',
    },
    y2AxisScale: {
        default: 'linear',
        description: 'Specify the type of scale that applies to a numerical y2-axis.',
        pattern: getPattern(['linear', 'log']),
        type: 'string',
    },
    // TODO: Figure out a way to explicitly support string value in addition to an array of strings
    // as UDF won't allow users to proceed if a string value is entered due to schema validation
    overlayFields: {
        description:
            'Specify field(s) that should be differentiated on the chart and displayed as chart overlays.',
        type: ['array', 'string'],
        items: {
            type: 'string',
        },
    },
    showOverlayY2Axis: {
        default: false,
        description:
            'Enable a y2-axis for chart overlays. All overlay fields will be mapped to a second y-axis.',
        type: 'boolean',
    },
    showRoundedY2AxisLabels: {
        default: true,
        description: 'Specify whether to round y2-axis values to the nearest integer.',
        type: 'boolean',
    },
    y2Fields: {
        default: '> y2 | getField()',
        description: 'Specify field(s) that should be mapped to a second y-axis.',
        type: ['array', 'string'],
        items: {
            type: 'string',
        },
    },
};

const themes: ThemedDefaults = {
    defaultBackgroundColor: props =>
        pick({
            enterprise: {
                dark: variables.black(props),
                light: variables.backgroundColor(props),
            },
            prisma: variables.backgroundColorSidebar(props),
        })(props),
};

const editorConfig: EditorConfig[] = [
    AxesChartDataConfigAreaColumnLine,
    AxesChartDataDisplayLine,
    {
        label: AxesChartColorAndStyle.label,
        layout: [
            ...AxesChartColorAndStyle.layout,
            // [
            //     {
            //         label: _('Line dash style'),
            //         option: 'lineDashStyle',
            //         editor: 'editor.seriesLineTypes',
            //     },
            // ],
            // [
            //     {
            //         label: _('Line dash styles by field name'),
            //         option: 'lineDashStylesByField',
            //         editor: 'editor.seriesLineTypesByField',
            //     },
            // ],
            [
                {
                    label: _('Line width (px)'),
                    option: 'lineWidth',
                    editor: 'editor.slider',
                    editorProps: {
                        min: 0.5,
                        max: 8.0,
                        step: 0.5,
                    },
                },
            ],
            [
                {
                    label: _('Line smoothing'),
                    option: 'showLineSmoothing',
                    editor: 'editor.toggle',
                },
            ],
            [
                {
                    label: _('Marker display'),
                    option: 'markerDisplay',
                    editor: 'editor.radioBar',
                    editorProps: {
                        values: [
                            {
                                label: _('Off'),
                                value: 'off',
                            },
                            {
                                label: _('Filled'),
                                value: 'filled',
                            },
                            {
                                label: _('Outlined'),
                                value: 'outlined',
                            },
                        ],
                    },
                },
            ],
            [
                {
                    label: _('Annotation colors'),
                    option: 'annotationColor',
                    editor: 'editor.columnSelector',
                    editorProps: {
                        dataSourceKey: 'annotation',
                        supportsDSL: true,
                        expectedDataPrimitive: ['series'],
                    },
                },
            ],
        ],
    },
    {
        label: AxesChartLegend.label,
        layout: [
            [
                {
                    label: _('Legend display'),
                    option: 'legendDisplay',
                    editor: 'editor.select',
                    editorProps: {
                        values: [
                            { label: _('Right'), value: 'right' },
                            { label: _('Left'), value: 'left' },
                            { label: _('Top'), value: 'top' },
                            { label: _('Bottom'), value: 'bottom' },
                            { label: _('Off'), value: 'off' },
                        ],
                        tooltip: _('Legend mode must be "Standard" to configure legend display'),
                    },
                    isDisabled: ({ options }) => options.legendMode === 'seriesCompare',
                },
            ],
            [
                {
                    label: _('Legend truncation'),
                    option: 'legendTruncation',
                    editor: 'editor.radioBar',
                    editorProps: {
                        values: [
                            { label: _('A...'), value: 'ellipsisEnd' },
                            { label: _('A...Z'), value: 'ellipsisMiddle' },
                            { label: _('...Z'), value: 'ellipsisStart' },
                            { label: _('Off'), value: 'ellipsisOff' },
                        ],
                    },
                    showEditor: ({ options }) =>
                        options.legendDisplay === 'left' ||
                        options.legendDisplay === 'right' ||
                        options.legendDisplay === 'top' ||
                        options.legendDisplay === 'bottom' ||
                        options.legendDisplay === undefined,
                },
            ],
            [
                {
                    label: _('Legend mode'),
                    option: 'legendMode',
                    editor: 'editor.radioBar',
                    editorProps: {
                        values: [
                            { label: _('Standard'), value: 'standard' },
                            { label: _('Compare series'), value: 'seriesCompare' },
                        ],
                    },
                },
            ],
        ],
    },
    AxesChartXGridAndLabels,
    AxesChartYGridAndLabels,
    AxesChartY2GridAndLabels,
];

/**
 * visualization configuration
 */
const config: VizConfig = {
    /**
     * unique viz key
     */
    key: 'splunk.line',
    /**
     * viz name
     */
    name: 'Line',
    category: VizCategory.COMPARISONS,
    /**
     * viz icon
     */
    icon: LineIcon,
    placeholderIcon: LinePlaceholderIcon,
    dataContract,
    size,
    defaultContext,
    optionsSchema,
    editorConfig,
    events: {
        'legend.click': {
            description: 'triggered when user clicks on chart legend',
        },
        'point.click': {
            description: 'triggered when user clicks on a point in the line chart',
        },
        'range.select': {
            description: 'triggered when user makes a selection',
        },
        'point.mouseover': {
            description: 'triggered when user hovers over a data point',
        },
        'point.mouseout': {
            description: 'triggered when user moves out of a data point',
        },
    },
    supports: [VizBehavior.DYNAMIC_OPTIONS, VizBehavior.PLACEHOLDER],
    themes,
};

export default enhanceConfig(config);
