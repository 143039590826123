import React from 'react';
import { _ } from '@splunk/ui-utils/i18n';
import SVG from '@splunk/react-icons/SVGEnterprise';

export default function Punchcard(props) {
    return (
        <SVG screenReaderText={_('Punchcard')} viewBox="0 0 24 24" {...props}>
            <circle cx="3.5" cy="3.5" r="0.5" fill="currentColor" />
            <circle cx="9.5" cy="3.5" r="0.5" transform="rotate(-180 9.5 3.5)" fill="currentColor" />
            <circle cx="15.5" cy="3.5" r="1.5" transform="rotate(-180 15.5 3.5)" fill="currentColor" />
            <circle r="1.5" transform="matrix(-1 0 0 1 21.5 3.5)" fill="currentColor" />
            <circle r="0.5" transform="matrix(-1 0 0 1 3.5 9.5)" fill="currentColor" />
            <circle cx="9.5" cy="9.5" r="1.5" fill="currentColor" />
            <circle cx="15.5" cy="9.5" r="1.5" fill="currentColor" />
            <circle r="2.5" transform="matrix(-1 0 0 1 21.5 9.5)" fill="currentColor" />
            <circle r="1.5" transform="matrix(-1 0 0 1 3.5 15.5)" fill="currentColor" />
            <circle cx="9.5" cy="15.5" r="0.5" transform="rotate(-180 9.5 15.5)" fill="currentColor" />
            <circle cx="15.5" cy="15.5" r="1.5" fill="currentColor" />
            <circle cx="21.5" cy="15.5" r="1.5" fill="currentColor" />
            <circle r="0.5" transform="matrix(-1 0 0 1 3.5 21.5)" fill="currentColor" />
            <circle cx="9.5" cy="21.5" r="0.5" fill="currentColor" />
            <circle cx="15.5" cy="21.5" r="2.5" fill="currentColor" />
            <circle cx="21.5" cy="21.5" r="1.5" fill="currentColor" />
        </SVG>
    );
}
