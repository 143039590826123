import * as React from 'react';

const getEllipseIcon = props =>
    function EllipseIcon(): React.ReactElement<SVGElement> {
        return (
            <svg width="108" height="64" viewBox="0 0 108 64" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M107.5 32C107.5 40.5888 101.622 48.4531 91.9289 54.1973C82.2464 59.935 68.8377 63.5 54 63.5C39.1623 63.5 25.7536 59.935 16.0711 54.1973C6.37777 48.4531 0.5 40.5888 0.5 32C0.5 23.4112 6.37777 15.5469 16.0711 9.80273C25.7536 4.065 39.1623 0.5 54 0.5C68.8377 0.5 82.2464 4.065 91.9289 9.80273C101.622 15.5469 107.5 23.4112 107.5 32Z"
                    {...props}
                />
            </svg>
        );
    };

export default {
    'ellipse.none': getEllipseIcon({ fill: '#F2F4F5', stroke: '#C3CBD4' }),
    'ellipse.fill': getEllipseIcon({ fill: '#00A4FD' }),
    'ellipse.stroke': getEllipseIcon({ fill: '#F2F4F5', stroke: '#00A4FD' }),
    'ellipse.fillAndStroke': getEllipseIcon({ fill: '#00A4FD', stroke: '#00A4FD' }),
};
