import React from 'react';
import { withTheme } from 'styled-components';
import { PureFillerGauge, config } from '@splunk/visualizations/FillerGauge';
import { withSanitizedProps } from '@splunk/visualizations-shared/SanitizeProps';

const ReactFillerGauge = props => {
    const defaultColors = {
        backgroundBarFillColor: config.themes.defaultBackgroundBarFillColor(props),
        majorTickFillColor: config.themes.majorTickFillColor(props),
        majorTickStrokeColor: config.themes.majorTickStrokeColor(props),
        valueMarkerFillColor: config.themes.valueMarkerFillColor(props),
        valueMarkerLabelFillColor: config.themes.valueMarkerLabelFillColor(props),
    };
    return <PureFillerGauge {...defaultColors} {...props} />;
};

export default withTheme(withSanitizedProps(ReactFillerGauge));
