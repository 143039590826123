import { useCallback, useEffect, useRef } from 'react';
import noop from 'lodash/noop';

export const usePassiveEventsCheck: () => () => boolean = () => {
    const passiveEventsSupported = useRef(false);

    // Determine on mount if passive events are supported
    useEffect(() => {
        try {
            const options = {
                get passive() {
                    passiveEventsSupported.current = true;
                    return false;
                },
            };

            window.addEventListener('test', noop, options);
            window.removeEventListener('test', noop);
        } catch (error) {
            // This should be a no-op
        }
    }, []);

    return useCallback(() => passiveEventsSupported.current, []);
};
