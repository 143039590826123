import { _ } from '@splunk/ui-utils/i18n';
import pick from '@splunk/themes/pick';
import variables from '@splunk/themes/variables';
import NumberFormattingConfig from '../../common/editorConfig/NumberFormatting';
import ThresholdConfig from '../../common/editorConfig/Threshold';

const editorConfig = [
    {
        label: _('Visualization Settings'),
        layout: [
            [
                {
                    label: _('Show Trending Value'),
                    option: 'showTrendIndicator',
                    editor: 'editor.checkbox',
                },
            ],
            [
                {
                    label: _('Trend Display as'),
                    option: 'trendDisplayMode',
                    editor: 'editor.select',
                    editorProps: {
                        values: [
                            { label: _('Percent'), value: 'percent' },
                            { label: _('Absolute'), value: 'absolute' },
                        ],
                    },
                    // If property matches the default value specified in schema, its not set in options. Hence we check if its undefined.
                    showEditor: ({ options }) =>
                        options.showTrendIndicator || options.showTrendIndicator === undefined,
                },
            ],
            [
                {
                    label: _('Trend Indicator Position'),
                    option: 'trendIndicatorPosition',
                    editor: 'editor.radioBar',
                    editorProps: {
                        values: [
                            { label: _('Standard'), value: 'standard' },
                            { label: _('Below'), value: 'below' },
                            { label: _('Outside'), value: 'outside' },
                        ],
                    },
                    // If property matches the default value specified in schema, its not set in options. Hence we check if its undefined.
                    showEditor: ({ options }) =>
                        options.showTrendIndicator || options.showTrendIndicator === undefined,
                },
            ],
        ],
    },
    ThresholdConfig,
    {
        label: _('Background Color'),
        layout: [
            [
                {
                    label: '',
                    option: 'backgroundColor',
                    editor: 'editor.color',
                    editorProps: {
                        pickFromTheme: theme =>
                            pick({
                                enterprise: {
                                    dark: variables.black,
                                    light: variables.backgroundColor,
                                },
                                prisma: variables.backgroundColorSidebar,
                            })({ theme }),
                    },
                },
            ],
        ],
    },
    NumberFormattingConfig,
];

export default editorConfig;
