import React, { Component } from 'react';
import { pick } from 'lodash';
import { _ } from '@splunk/ui-utils/i18n';
import IconPlaceholder from '@splunk/dashboard-visualizations/common/IconPlaceholder';
import BaseVisualization from '@splunk/dashboard-visualizations/common/BaseVisualization';
import { withPlaceholder } from '@splunk/dashboard-visualizations/utils/enhancer';
import styled from 'styled-components';
import { toDimension } from '@splunk/visualizations-shared/style';
import { getClickHandler, enableClickHandler } from '@splunk/dashboard-visualizations/utils/eventUtils';
import RemoteImage, { isRemoteImage } from '@splunk/visualizations-shared/RemoteImage';
import { Image as ImageIcon } from '@splunk/visualization-icons';
import ImageApi from './ImageApi';
import optionsSchema from './optionsSchema';
import editorConfig from './editorConfig';

const Container = styled.div.attrs(() => ({
    'data-test': 'image-container',
}))`
    position: relative;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: ${props => (props.onClick ? 'pointer' : 'inherit')};
    ${props => toDimension(pick(props, ['width', 'height']))};
`;

export const shouldShowPlaceholder = props => {
    if (props.options.src) {
        return false;
    }
    return true;
};

export const toImageDimensions = props => {
    if (props.shouldPreserveAspectRatio) {
        return `
            max-width: 100%;
            max-height: 100%;
            width: auto;
            height: auto;
        `;
    }
    return toDimension(pick(props, ['width', 'height']));
};

const Image = styled.img`
    display: block;
    ${props => toImageDimensions(props)};
`;

export class ImageComponent extends Component {
    static propTypes = {
        ...BaseVisualization.propTypes,
    };

    static defaultProps = {
        ...BaseVisualization.defaultProps,
    };

    static dataContract = {
        requiredDataSources: [],
    };

    static vizContract = {
        initialDimension: {
            width: 150,
            height: 150,
        },
    };

    static editor = editorConfig;

    static schema = optionsSchema;

    constructor(props, context) {
        super(props, context);
        this.api = new ImageApi(this);
    }

    componentDidMount() {
        // send back the viz api
        this.props.visualizationApiRef(this.api);
    }

    componentWillUnmount() {
        this.props.visualizationApiRef(null);
    }

    render() {
        const { options, height, width, title = '', mode, onEventTrigger, hasEventHandlers } = this.props;
        const { src, preserveAspectRatio } = options;
        const isRemote = isRemoteImage(src);
        const onClick = enableClickHandler(hasEventHandlers, mode)
            ? getClickHandler(onEventTrigger, {}, 'image.click')
            : () => {};
        return (
            <Container width={width} height={height} onClick={onClick}>
                {isRemote ? (
                    <RemoteImage
                        imageURL={src}
                        title={title}
                        preserveAspectRatio={preserveAspectRatio}
                        width={width}
                        height={height}
                        ref={ref => {
                            this.remoteImage = ref;
                        }}
                    />
                ) : (
                    <Image
                        data-test="image"
                        src={src}
                        shouldPreserveAspectRatio={preserveAspectRatio}
                        width={width}
                        height={height}
                        alt={_(title)}
                        onError={e => {
                            e.target.alt = _('Image not found');
                        }}
                        referrerPolicy="no-referrer"
                    />
                )}
            </Container>
        );
    }
}

export default withPlaceholder({
    placeholder: <IconPlaceholder icon={<ImageIcon />} />,
    shouldShowPlaceholder,
})(ImageComponent);
