export default {
    showTitleAndDescription: {
        type: 'boolean',
        default: true,
        description:
            'A Boolean value to determine whether to display the Canvas Title and Description or not.',
    },
    submitButton: {
        type: 'boolean',
        description:
            'You can also specify the layout option "submitButton". When set to true, a user must click a Submit button in order for the change in input selection to take effect (e.g. rerun searches with new dropdown values). If set to false, or if not specified at all, the dashboard will immediately refresh when a user makes a change in input selection.',
        default: false,
    },
    submitOnDashboardLoad: {
        type: 'boolean',
        default: false,
        description:
            'A Boolean value to determine whether inputs should submit their default values (if they exist) on initial dashboard load in order to allow searches to run once before requiring submit button to be used. To be used when `submitButton` is set to true.',
    },
};
