// this is a copy of displayUtils.js @ 23.5.0 before the design changes to PureSingleValueRadial were rolled out
import { RADIAL_SEVERITY_COLORS } from '@splunk/visualizations-shared/colorConstants';
import {
    DEFAULT_FONT,
    getGraphColors,
    lighten,
    darken,
} from '@splunk/visualizations/common/utils/singleValueUtils';

export const VALID_TREND_POSITIONS = ['standard', 'below', 'outside'];
export const MAJOR_FONT_THRESHOLD = 25;
export const TREND_FONT_THRESHOLD = 12;
export const DEFAULT_RADIAL_STROKE_WIDTH = 12;
export const SMALL_RADIAL_STROKE_WIDTH = 6;

/**
 * Get the colors for a radial graph based on severity
 * @method getRadialColors
 * @param {Object} props
 * @param {Bool} props.backgroundEnabled
 * @param {String} props.backgroundColor
 * @returns {Object} { background, stroke } colors
 */
export const getRadialColors = ({ backgroundEnabled, backgroundColor }) =>
    getGraphColors({
        backgroundEnabled,
        backgroundColor,
        defaultColors: RADIAL_SEVERITY_COLORS,
        customColorFormatter: color => ({ background: lighten(color), stroke: darken(color) }),
    });

/**
 * Figure out the diameter of the graph
 * @method getGraphSize
 * @param {Object} props
 * @param {Number} props.width                    // Width of the container
 * @param {Number} props.height                   // Height of the container
 * @param {Number} props.underLabel               // Underlabel if present
 * @returns {Number} diameter of the graph
 */
export const getGraphSize = ({ width, height, underLabel }) => {
    let size = Math.min(width, height);

    if (underLabel) {
        size -= DEFAULT_FONT;
    }

    return size;
};

/**
 * Get the maximum size of the container that holds the graph text
 * @method getValueContentSize
 * @param {Object} props
 * @param {Number} props.graphSize
 * @param {Number} props.radialStrokeWidth             // Stroke width of the graph
 * @returns {Number} content size for showing major and trend values in radial graph
 */
export const getValueContentSize = ({ graphSize, radialStrokeWidth }) => {
    // Account for the graph's stroke width
    const diameter = graphSize - radialStrokeWidth;
    // Use pythagoreum theorum with circle diameter
    // to calculate the size of the sides of the square inside the circle
    return Math.floor(Math.sqrt(diameter ** 2 / 2));
};

/*
 * @method getRadialStrokeWidth
 * @param {Number} graphSize
 * @returns {Number} radial stroke width
 */
export const getRadialStrokeWidth = graphSize =>
    graphSize >= 90 ? DEFAULT_RADIAL_STROKE_WIDTH : SMALL_RADIAL_STROKE_WIDTH;

/*
 * @method isValidPosition
 * @param {String} position
 * @returns {Bool}
 */
export const isValidPosition = position => VALID_TREND_POSITIONS.indexOf(position) > -1;

/**
 * Compute the trend indicator position based on font size of major and trend value
 * @method computeTrendIndicatorPosition
 * @param {Object} props
 * @param {String} props.trendIndicatorPosition
 * @param {Number} props.majorTextFontSize
 * @param {Number} props.trendTextFontSize
 * @returns {String} trend indicator position in radial graph
 */
export const computeTrendIndicatorPosition = ({
    trendIndicatorPosition,
    majorTextFontSize,
    trendTextFontSize,
}) => {
    let position = trendIndicatorPosition;

    if (!isValidPosition(position)) {
        position = 'standard';
    }

    if (
        position !== 'outside' &&
        (trendTextFontSize < TREND_FONT_THRESHOLD || majorTextFontSize < MAJOR_FONT_THRESHOLD)
    ) {
        position = 'below';
    }
    return position;
};

/*
 * @method showTrendPositionStandard
 * @param {Object} props
 * @param {Bool} props.showTrendIndicator
 * @param {String} props.position
 * @returns {Bool}
 */
export const showTrendPositionStandard = ({ showTrendIndicator, position }) =>
    showTrendIndicator && position === 'standard';

/*
 * @method showTrendPositionBelow
 * @param {Object} props
 * @param {Bool} props.showTrendIndicator
 * @param {String} props.position
 * @returns {Bool}
 */
export const showTrendPositionBelow = ({ showTrendIndicator, position }) =>
    showTrendIndicator && position === 'below';

/*
 * @method showTrendPositionOutside
 * @param {Object} props
 * @param {Bool} props.showTrendIndicator
 * @param {String} props.position
 * @returns {Bool}
 */
export const showTrendPositionOutside = ({ showTrendIndicator, position }) =>
    showTrendIndicator && position === 'outside';

/*
 * todo: need handle max and min
 * @method getRadialProgress
 * @param {Number} majorValue
 * @returns {Number} progress bar in radial graph
 */
export const getRadialProgress = majorValue =>
    Number.isNaN(Number(majorValue)) ? 0 : (majorValue / Math.max(majorValue, 100)) * 100;
