export default {
    src: {
        description: 'URL of an image file. All browser-supported image formats are acceptable.',
        type: 'string',
    },
    preserveAspectRatio: {
        default: false,
        description: 'Set to true to maintain the aspect ratio of an image when the screen is resized',
        type: 'boolean',
    },
};
