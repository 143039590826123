import React from 'react';
import T from 'prop-types';
import styled from 'styled-components';
import cssesc from 'cssesc';
import { DEFAULT_FONT_FAMILY } from '../common/utils/singleValueUtils';

const Row = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: left;
    height: ${props => props.size}px;
    line-height: ${props => props.size}px;
`;

const Text = styled.div`
    white-space: nowrap;
    height: 100%;
    font-size: ${props => props.size || 12}px;
    color: ${props => props.color};
    font-family: ${DEFAULT_FONT_FAMILY};
    font-weight: bold;
    &:hover {
        text-decoration: ${props => (props.showUnderline ? 'underline' : 'inherit')};
        cursor: ${props => (props.showUnderline ? 'pointer' : 'inherit')};
    }
    ${props => {
        if (!props.pseudoContent || !props.pseudoContentPosition) {
            return '';
        }
        return `&:${props.pseudoContentPosition} {
            // by default this pseudo element would inherit text-decoration from main element
            // and text-decoration could not be overwritten.
            // adding display: inline-block makes text decoration independent from main element.
            // => the unit will not be underlined if a user hovers over the main value
            display: inline-block;
            font-size: ${props.pseudoContentFontSize}px;
            position: relative;
            font-weight: normal;
            content: ${props.pseudoContent};
        }`;
    }}
`;

const validUnitPositions = ['before', 'after'];

const SingleValueContent = ({ color, fontSize, unitPosition, title, value, onValueClick, unit }) => {
    let sanitizedUnit;
    let sanitizedUnitPosition;

    if (typeof unit === 'string') {
        sanitizedUnit = cssesc(unit, { wrap: true, quotes: 'double' });
        if (typeof unitPosition === 'string' && validUnitPositions.includes(unitPosition)) {
            sanitizedUnitPosition = unitPosition;
        } else {
            sanitizedUnitPosition = 'after';
        }
    }

    const handleKeyDown = e => {
        if (e.keyCode !== 13) {
            return;
        }
        e.preventDefault();
        onValueClick(e);
    };

    return (
        <Row size={fontSize}>
            <Text
                data-test="majorValue"
                color={color}
                onClick={e => typeof onValueClick === 'function' && onValueClick(e)}
                showUnderline={!!onValueClick}
                size={fontSize}
                title={title}
                pseudoContent={sanitizedUnit}
                pseudoContentPosition={sanitizedUnitPosition}
                pseudoContentFontSize={fontSize * 0.6}
                tabIndex="0"
                role="button"
                onKeyDown={handleKeyDown}
            >
                {value}
            </Text>
        </Row>
    );
};

SingleValueContent.propTypes = {
    color: T.string,
    fontSize: T.number,
    unit: T.string,
    unitPosition: T.oneOf(validUnitPositions),
    value: T.oneOfType([T.string, T.number]),
    title: T.oneOfType([T.string, T.number]),
    onValueClick: T.oneOfType([T.bool, T.func]),
};

export default SingleValueContent;
