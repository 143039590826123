import { _ } from '@splunk/ui-utils/i18n';

const gauge = {
    label: _('Visualization Settings'),
    layout: [
        [
            {
                label: _('Show Value'),
                option: 'showValue',
                editor: 'editor.toggle',
            },
        ],
        [
            {
                label: _('Show Labels'),
                option: 'showLabels',
                editor: 'editor.toggle',
            },
        ],
        [
            {
                label: _('Orientation'),
                option: 'orientation',
                editor: 'editor.radioBar',
                editorProps: {
                    values: [
                        { label: _('Vertical'), value: 'vertical' },
                        { label: _('Horizontal'), value: 'horizontal' },
                    ],
                },
            },
        ],
    ],
};

export default gauge;
