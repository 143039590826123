import React, { createContext, useContext } from 'react';

type CanvasContextType = React.MutableRefObject<HTMLDivElement | null>;
const defaultRef = React.createRef<HTMLDivElement>();

const CanvasContext = createContext<CanvasContextType>(defaultRef);

export const useCanvas = (): CanvasContextType => useContext(CanvasContext);

export default CanvasContext;
