import { mapValues } from 'lodash';
import { Bounds, DataPoint, Space } from './ICoordinateTransformation';
import { Interval, Scale, toInterval } from './Scale';
import CoordinateTransformation from './CoordinateTransformation';

const sign =
    Math.sign ||
    function (n: number): number {
        if (n < 0) return -1;
        if (n > 0) return 1;
        return 0;
    };

const signedDistance = function distance(interval: Interval): number {
    return interval.max - interval.min;
};

const direction = function direction(interval: Interval): number {
    return sign(interval.max - interval.min);
};

export interface BoundsInfo {
    sourceBounds: Bounds;
    scaleFactors: DataPoint;
}

export const createBoundsInfo = function createBoundsInfo(
    sourceBounds: Bounds,
    transformation: LinearTransformation
): BoundsInfo {
    return {
        sourceBounds,
        scaleFactors: mapValues(transformation.scales, (scale: Scale): number => scale.scaleFactor),
    };
};

export default class LinearTransformation extends CoordinateTransformation {
    private targetCoordinate: { [sourceCoordinate: string]: string } = {};

    private sourceCoordinate: { [targetCoordinate: string]: string } = {};

    public scales: { [key: string]: Scale };

    private readonly preserveAspectRatio: boolean;

    public constructor(
        sourceDimensions: Space,
        targetDimensions: Space,
        sourceBounds?: Bounds,
        targetBounds?: Bounds,
        mapping?: { [sourceName: string]: string },
        preserveAspectRatio = true
    ) {
        super(sourceDimensions, targetDimensions);

        this.preserveAspectRatio = preserveAspectRatio;
        this.scales = {};
        Object.keys(sourceDimensions).forEach((sourceCoordinate): void => {
            const targetCoordinate = mapping ? mapping[sourceCoordinate] : sourceCoordinate;
            this.scales[sourceCoordinate] = new Scale();
            this.targetCoordinate[sourceCoordinate] = targetCoordinate;
            this.sourceCoordinate[targetCoordinate] = sourceCoordinate;
        });
        if (sourceBounds && targetBounds) {
            this.fit(sourceBounds, targetBounds);
        }
    }

    private fit(
        sourceRanges: Bounds,
        targetRanges: { [key: string]: number | Interval }
    ): LinearTransformation {
        if (this.preserveAspectRatio) {
            return this.fitUndistorted(sourceRanges, targetRanges);
        }
        return this.fitIndependently(sourceRanges, targetRanges);
    }

    private fitUndistorted(
        sourceRanges: Bounds,
        targetRanges: { [key: string]: number | Interval }
    ): LinearTransformation {
        let minScale = Number.MAX_VALUE;
        Object.keys(sourceRanges).forEach((dimension): void => {
            const targetDimension = this.targetCoordinate[dimension];
            if (!targetRanges[targetDimension]) {
                throw new Error(`Missing targetRanges for dimension ${targetDimension}`);
            }
            const range = sourceRanges[dimension];
            const sourceDistance = Math.abs(range.max - range.min);
            const targetBounds = toInterval(targetRanges[targetDimension]);
            minScale = Math.min(minScale, Math.abs(signedDistance(targetBounds)) / sourceDistance);
        });

        Object.keys(sourceRanges).forEach((dimension): void => {
            const targetDimension = this.targetCoordinate[dimension];
            this.scales[dimension].update(
                minScale * direction(toInterval(sourceRanges[targetDimension])),
                sourceRanges[dimension].min
            );
        });
        return this;
    }

    private fitIndependently(
        sourceRanges: Bounds,
        targetRanges: { [key: string]: Interval | number }
    ): LinearTransformation {
        Object.keys(sourceRanges).forEach((dimension): void => {
            const targetDimension = this.targetCoordinate[dimension];
            if (!targetRanges[targetDimension]) {
                throw new Error(`Missing parameter targetRanges for dimension ${dimension}`);
            }
            this.scales[dimension].fit(sourceRanges[dimension], targetRanges[targetDimension]);
        });
        return this;
    }

    public transform(sourcePoint: DataPoint): DataPoint {
        const result: DataPoint = {};
        Object.keys(this.sourceSpace).forEach(
            // TODO: move to explicit return
            // eslint-disable-next-line no-return-assign
            (dimension): number =>
                (result[this.targetCoordinate[dimension]] = this.scales[dimension].source2Target(
                    sourcePoint[dimension]
                ))
        );
        return result;
    }

    public transformBack(targetPoint: DataPoint): DataPoint {
        const result: DataPoint = {};
        Object.keys(this.targetSpace).forEach((coordinate): void => {
            const sourceCoordinate = this.sourceCoordinate[coordinate];
            result[sourceCoordinate] = this.scales[sourceCoordinate].target2Source(targetPoint[coordinate]);
        });
        return result;
    }

    public zoomCoordinate(
        dimension: string,
        physicalCenter: number,
        factor: number,
        minScale?: number,
        maxScale?: number
    ): LinearTransformation {
        const scale = this.scales[this.sourceCoordinate[dimension]];
        const currentScale = scale.scaleFactor;

        const newScale = Math.min(
            maxScale || Number.MAX_VALUE,
            Math.max(currentScale * factor, minScale || 0)
        );

        const targetCenter = scale.target2Source(physicalCenter);
        scale.zoomAroundSource(targetCenter, newScale);

        return this;
    }

    public zoomAroundTarget(
        targetCenter: { x: number; y: number },
        factor: number,
        minScale?: number,
        maxScale?: number
    ): LinearTransformation {
        const currentScale = this.scales.x.scaleFactor;

        const newScale = Math.min(
            maxScale || Number.MAX_VALUE,
            Math.max(currentScale * factor, minScale || 0)
        );

        const sourceCenter = this.transformBack(targetCenter);

        ['x', 'y'].forEach((axis): void => {
            const dimension = this.sourceCoordinate[axis];
            this.scales[dimension].zoomAroundSource(sourceCenter[dimension], newScale);
        });
        return this;
    }

    public moveTargetWindowBy(delta: DataPoint): LinearTransformation {
        ['x', 'y'].forEach((axis): void => {
            const dimension = this.sourceCoordinate[axis];
            if (delta[axis] != null) {
                this.scales[dimension].moveTargetBy(delta[axis]);
            }
        });
        return this;
    }

    public getTransformString(): string {
        const dx = -this.scales.x.offset * this.scales.x.scaleFactor;
        const dy = -this.scales.y.offset * this.scales.y.scaleFactor;
        return `translate(${dx}px, ${dy}px) scale(${this.scales.x.scaleFactor})`;
    }
}
