import { filter, map, debounce } from 'lodash';
import React, { Component } from 'react';
import EventListener from 'react-event-listener';
import Table from '@splunk/react-ui/Table';
import Line from '@splunk/react-sparkline/Line';
import { isNumerial, parseNumber } from '@splunk/react-visualizations/utils/numberUtils';
import { isSparklineData } from '@splunk/dashboard-visualizations/utils/dataUtils';
import { CellPropTypes, CellDefaultProps } from './CellPropShape';

/**
 * A Cell renders a Sparkline
 */
class SparklineCell extends Component {
    computeSize = debounce(() => {
        const { cell } = this.state;
        if (cell) {
            this.setState({
                height: cell.offsetHeight,
                width: cell.offsetWidth,
            });
        }
    }, 5);

    constructor(props, context) {
        super(props, context);
        this.state = {
            cell: null,
            width: null,
            height: null,
        };
    }

    onCellMount = cell => {
        this.setState(
            {
                cell,
            },
            this.computeSize
        );
    };

    render() {
        const {
            value,
            fieldOptions: { align },
            onCellClick,
        } = this.props;
        const sparklineData = map(filter(value.slice(1), isNumerial), parseNumber);
        const { width, height } = this.state;
        const style = { position: 'relative' };
        return (
            <Table.Cell
                style={style}
                elementRef={this.onCellMount}
                align={align}
                test-cell-type="sparkline"
                onClick={onCellClick}
            >
                <EventListener target={window} onResize={this.computeSize} />
                {width && (
                    <Line
                        style={{
                            position: 'absolute',
                            left: '5px',
                            right: '0px',
                            top: '5px',
                            bottom: '0px',
                        }}
                        data={sparklineData}
                        isArea
                        height={height - 8}
                        width={width - 10}
                    />
                )}
            </Table.Cell>
        );
    }
}

SparklineCell.propTypes = CellPropTypes;
SparklineCell.defaultProps = CellDefaultProps;
SparklineCell.canRender = (field, fieldOptions, value) =>
    fieldOptions.type === 'sparkline' && isSparklineData(value);
export default SparklineCell;
