import React, { Component } from 'react';
import BaseVisualization from '@splunk/dashboard-visualizations/common/BaseVisualization';
import Charting from '@splunk/react-visualizations/common/Charting';
import jsCharting from '@splunk/charting-bundle';
import { getDataSet } from '@splunk/dashboard-visualizations/utils/propUtils';
import { formatData } from '@splunk/dashboard-visualizations/utils/dataUtils';
import { withVisualizationAndThemeHOCs } from '@splunk/dashboard-visualizations/utils/enhancer';
import { enterprise } from '@splunk/visualization-themes/variables';
import IconPlaceholder from '@splunk/dashboard-visualizations/common/IconPlaceholder';
import SuiGaugeRadialIcon from '@splunk/react-icons/enterprise/GaugeRadial';
import { convertPropsToString } from '@splunk/visualizations-shared/propUtils';
import optionsSchema from './optionsSchema';

class RadialGauge extends Component {
    static propTypes = BaseVisualization.propTypes;

    static defaultProps = {
        ...BaseVisualization.defaultProps,
        height: 250,
    };

    static vizContract = {
        initialDimension: {
            width: 150,
            height: 150,
        },
    };

    static initialRequestParams = { offset: 0, count: 10000 };

    static supportedDataSources = ['primary'];

    static schema = optionsSchema;

    render() {
        const { width, height, options, onEventTrigger, style } = this.props;
        const chartOptions = {
            chart: 'radialGauge',
            ...options,
        };

        // splunk charting expects certain options like 'gaugeColors', 'rangeValues' etc to be a string
        const convertedChartOptions = convertPropsToString({
            vizProps: chartOptions,
            propNames: ['gaugeColors', 'chart.rangeValues'],
        });

        // copy convertedChartOptions into chartOptions
        Object.assign(chartOptions, convertedChartOptions);

        const primaryDataSet = getDataSet(this.props);
        const ds = formatData(primaryDataSet, options);
        const chartData = primaryDataSet ? jsCharting.extractChartReadyData(ds) : null;
        return (
            <Charting
                width={width}
                height={height}
                chartData={chartData}
                options={chartOptions}
                onEventTrigger={onEventTrigger}
                style={style}
            />
        );
    }
}

export default withVisualizationAndThemeHOCs({
    defaultBackgroundColor: enterprise.defaultBackgroundColor,
    placeholder: <IconPlaceholder icon={<SuiGaugeRadialIcon />} />,
})(RadialGauge);
