import type {
    CreateSearchArgs,
    CancelSearchArgs,
    ISearchModule,
    RequestParams,
    UpdateSearchArgs,
    DataSourceSubscription,
} from '@splunk/dashboard-types';
import type { ConstructableSearchModuleArgs } from './types';

const defaultInitialRequestParams: RequestParams = { offset: 0, count: 1000 };

export class SearchModule implements ISearchModule<DataSourceSubscription> {
    private registry: NonNullable<ConstructableSearchModuleArgs['registry']>;

    constructor({ registry }: Required<ConstructableSearchModuleArgs>) {
        this.registry = registry;
    }

    /**
     * Cancels the subscription to a search. Will tear down search if there are no remaining subscribers
     * @param {string} param0.dataSourceId An identifier for the datasource
     * @param {string} param0.consumerId An identifier for the consumer + binding
     */
    // eslint-disable-next-line class-methods-use-this
    cancel({ subscription }: CancelSearchArgs) {
        // Will clean up both viz/input and chain search subscriptions automatically
        // Will tear down controller if no subscriptions remain
        subscription.cancel();
    }

    async create({
        consumerId,
        dataSourceId,
        initialRequestParams = defaultInitialRequestParams,
        onData,
        onError,
    }: CreateSearchArgs) {
        try {
            const controller = this.registry.allocate({ dataSourceId });
            const subscription = await controller.subscribe({
                consumerId,
                initialRequestParams,
            });
            subscription.subscribeToData({ onData, onError });
            const statusSubscription = controller
                .getStatusObservable()
                .subscribe(({ error, meta }) => {
                    if (error) {
                        onError(error);
                    } else {
                        onData({ meta });
                    }
                });

            const { updateRequestParams } = subscription;
            const { refresh } = controller;

            const cancelSubscription = () => {
                statusSubscription.unsubscribe();
                subscription.cancel();
            };

            return {
                // requires .bind to ensure `this` correctly refers to the instantiated class
                updateRequestParams: updateRequestParams.bind(subscription),
                cancel: cancelSubscription,
                refresh: refresh.bind(controller),
                getInitialCallbacks: () => ({ onData, onError }),
            };
        } catch (error) {
            // no need to call onError here, since it will be handled in the catch clause of the caller
            return Promise.reject(error);
        }
    }

    async update({
        consumerId,
        dataSourceId,
        subscription,
        initialRequestParams = defaultInitialRequestParams,
    }: UpdateSearchArgs<DataSourceSubscription>) {
        const { onData, onError } = subscription.getInitialCallbacks();

        this.cancel({ subscription });
        return this.create({
            consumerId,
            dataSourceId,
            initialRequestParams,
            onData,
            onError,
        });
    }
}
