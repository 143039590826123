export enum VizBehavior {
    /**
     * Dynamic Options Behavior.
     * This allows visualization options to be powered by data
     *
     * **Example**
     *
     * sparklineValues: ">primary|seriesByIndex(0)"
     * would configure sparklineValues with an array of data points
     * coming from the first series in the primary datasource
     *
     */
    DYNAMIC_OPTIONS = 'dynamic-options',
    EVENTS = 'events',
    /**
     * Placeholder behavior: visualization will display a placeholder rather than fail if any of the `requiredProps` is
     * missing.
     * @type {string}
     */
    PLACEHOLDER = 'placeholder',
    PAGE_AND_SORT = 'page-and-sort',
    TRELLIS = 'trellis',
}
