import type { ReactNode } from 'react';
import { pickVariant, variables } from '@splunk/themes';
import styled from 'styled-components';
import type { Mode } from '@splunk/dashboard-types';

export type BorderStyle =
    | 'selected'
    | 'lowContrastGray'
    | 'highContrastGray'
    | 'none';

export interface GetBorderStyleArgs {
    isSelected: boolean;
    layoutItemType: string;
    mode?: Mode;
    hasDrilldown?: boolean;
    canBeHidden?: boolean;
}

export const getBorderStyle = ({
    isSelected,
    layoutItemType,
    mode = 'view',
    hasDrilldown = false,
    canBeHidden = false,
}: GetBorderStyleArgs): BorderStyle => {
    let outlineStyle: BorderStyle = 'none';
    if (layoutItemType === 'input' && mode === 'view' && isSelected) {
        outlineStyle = 'none';
    } else if (isSelected) {
        outlineStyle = 'selected';
    } else if (canBeHidden && mode === 'edit') {
        outlineStyle = 'highContrastGray';
    } else if (hasDrilldown || mode === 'edit') {
        outlineStyle = 'lowContrastGray';
    }

    return outlineStyle;
};

export interface SelectableContainerProps {
    itemId: string;
    width: string | number;
    height?: string | number;
    children: ReactNode;
    outlineStyle: BorderStyle;
    zIndex?: number;
    selectionZIndex?: number;
    canBeHidden?: boolean;
}

const EnterpriseColorsTree = {
    dark: variables.borderLightColor,
    light: variables.borderColor,
};

const UnselectedHoverColorTree = {
    enterprise: EnterpriseColorsTree,
    prisma: variables.interactiveColorBorderHover,
};

export const SelectableContainer = styled.div.attrs<SelectableContainerProps>(
    ({ itemId, width, height, outlineStyle, canBeHidden }) => ({
        'data-test': 'select-outline',
        'data-id': itemId,
        'data-outline': canBeHidden ? 'dashed' : 'none',
        'data-outline-style': `${outlineStyle}`,
        style: {
            width,
            height,
        },
    })
)<SelectableContainerProps>`
    display: flex;
    flex-flow: column nowrap;
    position: relative;

    // We position an outline using a pseudo-element absolutely positioned
    &::after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: ${(props) => props.zIndex};
        pointer-events: none;
        cursor: default;
        outline-style: ${(props) => (props.canBeHidden ? 'dashed' : 'solid')};
        outline-offset: ${(props) => (props.canBeHidden ? '-2px' : '1px')};
        outline-width: 2px;
        outline-color: ${pickVariant<SelectableContainerProps>('outlineStyle', {
            selected: {
                enterprise: variables.focusColor,
                prisma: variables.interactiveColorPrimary,
            },
            highContrastGray: {
                enterprise: EnterpriseColorsTree,
                prisma: variables.interactiveColorBorder,
            },
            lowContrastGray: 'transparent',
            none: 'transparent',
        })};
        opacity: ${pickVariant<SelectableContainerProps>('outlineStyle', {
            highContrastGray: 0.7,
        })};
    }

    &:hover::after {
        opacity: 1;
        outline-color: ${pickVariant<SelectableContainerProps>('outlineStyle', {
            selected: {
                enterprise: variables.focusColor,
                prisma: variables.interactiveColorPrimary,
            },
            lowContrastGray: UnselectedHoverColorTree,
            highContrastGray: UnselectedHoverColorTree,
            none: 'transparent',
        })};
    }
`;
