import * as React from 'react';
import { _ } from '@splunk/ui-utils/i18n';
import SVG from '@splunk/react-icons/SVGEnterprise';

const Scatter = (props: React.ComponentProps<typeof SVG>): React.ReactElement<SVGElement> => (
    <SVG screenReaderText={_('Scatter')} fill="currentColor" viewBox="0 0 150 100" {...props}>
        <g>
            <path d="M127.772 16H134.759V22.9873H127.772V16Z" />
            <path d="M29.9494 27.3544H36.9367V34.3418H29.9494V27.3544Z" />
            <path d="M64.8861 45.6962H71.8734V52.6835H64.8861V45.6962Z" />
            <path d="M71.8734 52.6835V59.6709H78.8608V52.6835H71.8734Z" />
            <path d="M43.9241 59.6709H36.9367V66.6582H43.9241V59.6709Z" />
            <path d="M43.9241 59.6709H50.9114V52.6835H43.9241V59.6709Z" />
            <path d="M120.785 34.3418H127.772V41.3291H120.785V34.3418Z" />
            <path d="M134.759 63.1646V70.1519H148.734V63.1646H134.759Z" />
            <path d="M120.785 63.1646V70.1519H92.8354V63.1646H120.785Z" />
            <path d="M64.8861 63.1646V70.1519H50.9114V63.1646H64.8861Z" />
            <path d="M22.962 63.1646H29.9494V70.1519H22.962V63.1646Z" />
            <path d="M8.98734 63.1646H2V70.1519H8.98734V63.1646Z" />
            <path d="M85.8481 78.0127H92.8354V85H85.8481V78.0127Z" />
            <path d="M85.8481 27.3544H78.8608V34.3418H85.8481V27.3544Z" />
            <path d="M22.962 49.1899H15.9747V56.1772H22.962V49.1899Z" />
            <path d="M15.9747 71.8987H8.98734V78.8861H15.9747V71.8987Z" />
        </g>
    </SVG>
);

export default Scatter;
