import type {
    DashboardDefaultsDefinition,
    DashboardJSON,
    DashboardVizDefaultsDefinition,
    RootVisualizationsDefinition,
} from '@splunk/dashboard-types';
import {
    canMigrate,
    inferToType,
    migrate,
} from '@splunk/visualization-migrations';
import console from './console';

interface DeprecatedVisualization {
    id: string;
    type: string;
}

interface MetaData {
    meta?: {
        deprecated?: boolean;
    };
}

export interface VisualizationPreset {
    visualizations: Record<string, MetaData>;
}

type migrateUtilityCallSig<TDefinition> = ({
    definition,
    preset,
}: {
    definition: TDefinition;
    preset: VisualizationPreset;
}) => TDefinition;

type migrateDefaultsCallSig =
    migrateUtilityCallSig<DashboardDefaultsDefinition>;
type migrateDefinitionsCallSig =
    migrateUtilityCallSig<RootVisualizationsDefinition>;
type migrateDashboardDefinitionCallSig = migrateUtilityCallSig<DashboardJSON>;

/**
 * Migrate the defaults configured for legacy viz types to defaults for newer splunk types
 * @param definition defaults configuration section definition
 * @returns Updated defaults configuration section with migrated visualization configurations.
 */
const migrateDefaults: migrateDefaultsCallSig = ({
    definition: { visualizations: vizDefaults, ...defaults },
    preset: { visualizations: vizPresets },
}) => {
    if (!vizDefaults) {
        return defaults;
    }

    const migratedDefaults: DashboardVizDefaultsDefinition = {};
    Object.keys(vizDefaults).forEach((type: string) => {
        try {
            const toType = inferToType(type);

            if (vizPresets[toType] && canMigrate({ fromType: type, toType })) {
                // Full migration (canMigrate returns true, toType in preset)
                const { type: migratedType, ...migratedDefinition } = migrate({
                    definition: { ...vizDefaults[type], type },
                });

                migratedDefaults[migratedType] = migratedDefinition;
            } else if (vizPresets[toType] && type !== toType) {
                // Assign non-options/encoding values to the migrated config
                const migratedDefault: Record<string, unknown> = {};
                Object.entries(vizDefaults[type]).forEach(([key, value]) => {
                    if (typeof value !== 'undefined' && key !== 'options') {
                        migratedDefault[key] = value;
                    }
                });

                if (Object.keys(migratedDefault).length) {
                    migratedDefaults[toType] = migratedDefault;
                }
            } else {
                // Migration not possible. Target type not in preset
                migratedDefaults[type] = vizDefaults[type];
            }
        } catch (e) {
            console.error(`Error migrating default: ${type}`, e);

            // Even if something goes wrong, never remove from the dashboard
            migratedDefaults[type] = vizDefaults[type];
        }
    });

    return { ...defaults, visualizations: migratedDefaults };
};

/**
 * Invokes a potentially-lossy migration for all deprecated items in the provided section.
 * @param definition visualizations definition section definition
 * @returns Updated section definition with migrated visualization configurations.
 */
const migrateDefinitions: migrateDefinitionsCallSig = ({
    definition,
    preset: { visualizations: vizPresets },
}) => {
    const migratedSection: RootVisualizationsDefinition = {};
    Object.entries(definition).forEach(([vizId, def]) => {
        try {
            const toType = inferToType(def.type);
            if (
                vizPresets[toType] &&
                canMigrate({ fromType: def.type, toType })
            ) {
                // Full migration (canMigrate returns true, toType in preset)
                migratedSection[vizId] = migrate({ definition: def });
            } else if (vizPresets[toType] && def.type !== toType) {
                // Lossy migration (canMigrate returns false, toType in preset)
                // Assign non-options values to the migrated config
                migratedSection[vizId] = { ...def, type: toType };
                delete migratedSection[vizId].encoding;
                delete migratedSection[vizId].options;
            } else {
                // Migration not possible. Target type not in preset
                migratedSection[vizId] = def;
            }
        } catch (e) {
            console.error(`Error migrating visualization: ${vizId}`, e);

            // Even if something goes wrong, never remove from the dashboard
            migratedSection[vizId] = def;
        }
    });

    return migratedSection;
};

// input: full dashboard definition and visualization preset
// output: { type, id }[]
export const getDeprecatedVisualizations = ({
    def,
    preset,
}: {
    def: DashboardJSON;
    preset: VisualizationPreset;
}): DeprecatedVisualization[] => {
    if (!def || !def.visualizations) {
        return [];
    }

    const { visualizations } = def;
    const result: DeprecatedVisualization[] = [];

    Object.entries(visualizations).forEach(([id, { type }]) => {
        if (preset.visualizations[type]?.meta?.deprecated) {
            result.push({
                id,
                type,
            });
        }
    });

    return result;
};

/**
 * Executes visualization and defaults migrations from legacy viz types to newer splunk types.
 * @param dashboardDef Full dashboard definition to be migrated
 * @returns Migrated dashboard definition
 */
export const migrateDashboardDefinition: migrateDashboardDefinitionCallSig = ({
    definition: { defaults = {}, visualizations = {}, ...dashboardDef },
    preset,
}) => ({
    ...dashboardDef,
    defaults: migrateDefaults({ definition: defaults, preset }),
    visualizations: migrateDefinitions({ definition: visualizations, preset }),
});
