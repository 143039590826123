import { useEffect, useRef } from 'react';
import { cloneDeep, isEqual } from 'lodash';
import { selectSubmittedTokens, useSelector } from '@splunk/dashboard-state';
import type { UseTokenBindingChangeCallbackProps } from './onChange.types';

export const useTokenBindingChangeCallback = ({
    initialTokenBinding,
    onTokenBindingChange: onChange,
}: UseTokenBindingChangeCallbackProps) => {
    const submittedTokens = useSelector(selectSubmittedTokens);
    const initialSubmittedTokens = useRef(submittedTokens);

    useEffect(() => {
        // If there's no callback then don't invoke the cost of isEqual/cloneDeep
        if (!onChange) {
            return;
        }

        // This doesn't need to run on first render, so if the initialSubmittedTokens
        // object equals (in reference) the current submittedTokens state value then
        // it's known that no enhancements were made during store initialization.
        // When not equal in reference it's possible that the current submittedTokens
        // equals the initialTokenBinding in value.
        if (
            submittedTokens === initialSubmittedTokens.current &&
            isEqual(submittedTokens, initialTokenBinding)
        ) {
            return;
        }

        onChange(cloneDeep(submittedTokens));
    }, [initialTokenBinding, submittedTokens, onChange]);
};
