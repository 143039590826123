const defaultFeatureFlags = {
    visualizations_useWebWorkersForDSL: false,
    visualizations_enableTrellis: false,
};

type FeatureFlagKeys = keyof typeof defaultFeatureFlags | string;

export type FeatureFlags = Record<FeatureFlagKeys, boolean>;

export default defaultFeatureFlags as FeatureFlags;
