import * as React from 'react';
import styled from 'styled-components';
import Popover from '@splunk/react-ui/Popover';
import { _ } from '@splunk/ui-utils/i18n';

const StyledPopover = styled(Popover)`
    width: 250px;
    font-size: 12px;
`;

const StyledTable = styled.table`
    margin: 2px 6px 2px 6px;
    width: calc(100% - 12px);
`;

const StyledTitleColumn = styled.td`
    opacity: 0.85;
`;

const StyledValueColumn = styled.td`
    text-align: right;
`;

const StyledColorBlock = styled.div`
    background-color: ${props => props.color};
    display: inline-block;
    margin-right: 4px;
    border-radius: 2px;
    width: 6px;
    height: 11px;
    transform: translateY(1px);
`;

export const convertDegToDMS = (decimalDegree: number, type: 'latitude' | 'longitude'): string => {
    const truncate = n => (n > 0 ? Math.floor(n) : Math.ceil(n));
    const absoluteDegree = Math.abs(decimalDegree);
    let degrees = truncate(absoluteDegree);
    let minutes = truncate((absoluteDegree - degrees) * 60);
    let seconds = Math.round((absoluteDegree - degrees - minutes / 60) * 3600);
    if (seconds === 60) {
        minutes += 1;
        seconds = 0;
    }
    if (minutes === 60) {
        degrees += 1;
        minutes = 0;
    }
    let direction: string;
    if (type === 'longitude') {
        direction = decimalDegree < 0 ? 'W' : 'E';
    } else if (type === 'latitude') {
        direction = decimalDegree < 0 ? 'S' : 'N';
    }
    return `${degrees}° ${minutes}' ${seconds}" ${direction}`;
};

export interface TooltipProps {
    open?: boolean;
    anchor?: HTMLElement;
    latitude?: number;
    longitude?: number;
    areaId?: string;
    areaValue?: number;
    additionalFields?: Array<{ name: string; value: number | string; color?: string }>;
}
// only want latitude/longitude for marker and bubble
// omit for choropleth, and put areaid, areavalue instead
const Tooltip = ({
    open,
    anchor,
    latitude,
    longitude,
    areaId,
    areaValue,
    additionalFields = [],
}: TooltipProps): JSX.Element => (
    <StyledPopover open={open} anchor={anchor} data-test="map-marker-tooltip" defaultPlacement="above">
        <StyledTable>
            <tbody>
                {areaId && areaValue && (
                    <tr>
                        <StyledTitleColumn data-test="area-id">{areaId}</StyledTitleColumn>
                        <StyledValueColumn data-test="area-value">{areaValue}</StyledValueColumn>
                    </tr>
                )}
                {latitude && (
                    <tr>
                        <StyledTitleColumn>{_('Latitude')}</StyledTitleColumn>
                        <StyledValueColumn data-test="latitude">
                            {convertDegToDMS(latitude, 'latitude')}
                        </StyledValueColumn>
                    </tr>
                )}
                {longitude && (
                    <tr>
                        <StyledTitleColumn>{_('Longitude')}</StyledTitleColumn>
                        <StyledValueColumn data-test="longitude">
                            {convertDegToDMS(longitude, 'longitude')}
                        </StyledValueColumn>
                    </tr>
                )}
                {additionalFields.map(({ name, value, color }, index) => (
                    <tr key={String(index)}>
                        <StyledTitleColumn data-test="field-title">
                            {color ? <StyledColorBlock data-test="field-color" color={color} /> : null}
                            {name}
                        </StyledTitleColumn>
                        <StyledValueColumn data-test="field-value">{value}</StyledValueColumn>
                    </tr>
                ))}
            </tbody>
        </StyledTable>
    </StyledPopover>
);

export default Tooltip;
