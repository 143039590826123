import { _ } from '@splunk/ui-utils/i18n';

import { Image as ImageIcon } from '@splunk/visualization-icons';
import { DataContract } from '../common/interfaces/DataContract';
import { DefaultContext } from '../common/interfaces/DefaultContext';
import { EditorConfig } from '../common/interfaces/Editor';
import { OptionsSchema } from '../common/interfaces/OptionsSchema';
import { ThemedDefaults } from '../common/interfaces/ThemedDefaults';
import { VizBehavior } from '../common/interfaces/VizBehavior';
import { VizCategory } from '../common/interfaces/VizCategory';
import { VizConfig } from '../common/interfaces/VizConfig';
import { VizSize } from '../common/interfaces/VizSize';
import { enhanceConfig } from '../common/utils/configUtils';

const dataContract: DataContract = {
    requiredDataSources: [],
    optionalDataSources: [
        // Note: SCP-14090 disable ui for optional datasources. We may need to add it back later
        // {
        //     name: 'annotation',
        //     description: 'DataSource that populate event annotations',
        // },
    ],
    initialRequestParams: {},
};

const size: VizSize = {
    initialWidth: 300,
    initialHeight: 300,
};

const defaultContext: DefaultContext = {};

const optionsSchema: OptionsSchema = {
    src: {
        description: 'URL of an image file. All browser-supported image formats are acceptable.',
        type: 'string',
    },
    preserveAspectRatio: {
        default: false,
        description: 'Set to true to maintain the aspect ratio of an image when the screen is resized.',
        type: 'boolean',
    },
};

const editorConfig: EditorConfig[] = [
    {
        label: _('Image content'),
        layout: [
            [
                {
                    label: _('Image file'),
                    option: 'src',
                    editor: 'editor.image',
                },
            ],
            [
                {
                    label: _('Preserve aspect ratio'),
                    option: 'preserveAspectRatio',
                    editor: 'editor.checkbox',
                    showEditor: ({ options }) => !!options.src,
                },
            ],
        ],
    },
];

const themes: ThemedDefaults = {};

/**
 * visualization configuration
 */
const config: VizConfig = {
    /**
     * unique viz key
     */
    key: 'splunk.image',
    /**
     * viz name
     */
    name: 'Image',
    category: VizCategory.MISC,
    /**
     * viz icon
     */
    icon: ImageIcon,
    dataContract,
    size,
    defaultContext,
    optionsSchema,
    editorConfig,
    supports: [VizBehavior.DYNAMIC_OPTIONS, VizBehavior.PLACEHOLDER, VizBehavior.EVENTS],
    themes,
    events: {
        'image.click': {
            description: 'triggered when user clicks on the image',
        },
    },
};

export default enhanceConfig(config);
