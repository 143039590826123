import isFunction from 'lodash/isFunction';
import React, { PureComponent } from 'react';
import styled from 'styled-components';
import ResizeObserver from 'resize-observer-polyfill';

const ResizeObserverDiv = styled.div.attrs(() => ({
    'data-test': 'resize-detector',
}))`
    position: 'absolute';
    width: 0;
    height: 0;
    visibility: 'hidden';
    display: 'none';
`;

export type RenderFn = ({
    width,
    height,
}: {
    width: number;
    height: number;
}) => JSX.Element | null;

export interface SizeAwareWrapperState {
    width: number | null;
    height: number | null;
}

export interface SizeAwareWrapperProps {
    children?: RenderFn;
}

class SizeAwareWrapper extends PureComponent<
    SizeAwareWrapperProps,
    SizeAwareWrapperState
> {
    private animationFrameID: number | null = null;

    private ro: InstanceType<typeof ResizeObserver>;

    private el: HTMLDivElement | null = null;

    constructor(props: SizeAwareWrapperProps) {
        super(props);
        this.state = {
            width: null,
            height: null,
        };
        this.animationFrameID = null;
        this.ro = new ResizeObserver(this.handleResize);
    }

    componentDidMount() {
        const resizableElement = this.getElement();
        if (resizableElement) {
            this.ro.observe(resizableElement);
        }
    }

    componentWillUnmount() {
        const resizableElement = this.getElement();
        if (resizableElement) {
            this.ro.unobserve(resizableElement);
        }
        if (window && this.animationFrameID) {
            window.cancelAnimationFrame(this.animationFrameID);
        }
    }

    getElement = () =>
        // it listens on the size of its parent element
        this.el && this.el.parentElement;

    handleResize: ResizeObserverCallback = (entries) => {
        entries.forEach((entry) => {
            const { inlineSize: width, blockSize: height } =
                entry.borderBoxSize[0];
            // use requestAnimationFrame to make sure width/height is updated in time
            this.animationFrameID = window.requestAnimationFrame(() => {
                this.setState({ width, height });
            });
        });
    };

    renderChildren = () => {
        // expect children to be a function
        const { width, height } = this.state;
        const { children = null } = this.props;
        if (width !== null && height !== null && isFunction(children)) {
            return children({ width, height });
        }
        return null;
    };

    handleRef = (el: HTMLDivElement) => {
        this.el = el;
    };

    render() {
        return (
            <>
                <ResizeObserverDiv key="resize-detector" ref={this.handleRef} />
                {this.renderChildren()}
            </>
        );
    }
}

export default SizeAwareWrapper;
