/* eslint-disable no-param-reassign */
import { ScaleOrdinal, scaleOrdinal } from 'd3-scale';
import { isNumber } from '@splunk/visualization-encoding/utils/types';
import {
    COLOR_MODE_TYPES,
    Connections,
    Coordinates,
    NodeConnectionsMap,
    SankeyData,
    SankeyDataLink,
    SankeyDataNode,
} from './SankeyModels';

const insertNodeIfNotExist = (sankeyData: SankeyData, nodeName: string): void => {
    let { nodes } = sankeyData;
    nodes = nodes || [];
    if (typeof nodeName !== 'string') {
        console.error('Node not created, invalid nodename:', nodeName);
        return;
    }
    nodeName = nodeName.trim();
    if (!nodes.find(node => node.name.toLocaleLowerCase() === nodeName.toLocaleLowerCase())) {
        nodes.push({ name: nodeName });
    }
};

const insertLink = (
    sankeyData: SankeyData,
    sourceFieldIdx: number,
    targetFieldIdx: number,
    linkValue: number,
    index: number
): void => {
    let { links } = sankeyData;
    links = links || [];
    links.push({
        source: (sourceFieldIdx as unknown) as SankeyDataNode,
        target: (targetFieldIdx as unknown) as SankeyDataNode,
        value: linkValue,
        index,
    });
};

export const getNodesIndex = (sankeyData: SankeyData, nodeName: string): number => {
    if (!sankeyData || !sankeyData.nodes || !sankeyData.nodes.length) {
        return -1;
    }
    return sankeyData.nodes.findIndex(node => node.name.toLocaleLowerCase() === nodeName.toLocaleLowerCase());
};

export const getColorCategoryMapping = ({
    category,
    colorMode,
    seriesColors,
    linkColors,
}: {
    category: string[];
    colorMode: COLOR_MODE_TYPES;
    seriesColors: string[];
    linkColors?: string[];
}): ScaleOrdinal<string, unknown, string> | string[] => {
    if (colorMode !== 'categorical') {
        return linkColors;
    }
    return seriesColors ? scaleOrdinal().domain(category).range(seriesColors).unknown(seriesColors[0]) : null;
};

export const convertInputToSankeyDataFormat = (
    columns: (string[] | number[])[],
    sourceFieldIdx: number,
    targetFieldIdx: number,
    linkValueFieldIdx: number,
    resultLimit: number
): SankeyData => {
    if (
        !columns ||
        !Array.isArray(columns) ||
        !columns.length ||
        !columns[sourceFieldIdx] ||
        !columns[targetFieldIdx] ||
        !columns[linkValueFieldIdx] ||
        columns.length < 3 ||
        !Array.isArray(columns[sourceFieldIdx]) ||
        !Array.isArray(columns[targetFieldIdx]) ||
        !Array.isArray(columns[linkValueFieldIdx]) ||
        !columns[sourceFieldIdx] ||
        typeof columns[sourceFieldIdx][0] !== 'string' ||
        !columns[targetFieldIdx] ||
        typeof columns[targetFieldIdx][0] !== 'string'
    ) {
        return null;
    }

    const sankeyData: SankeyData = {
        nodes: [],
        links: [],
    };

    const maxlen = Math.min(
        columns[sourceFieldIdx].length,
        columns[targetFieldIdx].length,
        columns[linkValueFieldIdx].length,
        resultLimit
    );

    for (let i = 0; i < maxlen; i += 1) {
        if (
            typeof columns[sourceFieldIdx][i] === 'string' &&
            typeof columns[targetFieldIdx][i] === 'string' &&
            isNumber(columns[linkValueFieldIdx][i])
        ) {
            const sourceNodeName = (columns[sourceFieldIdx][i] as string).trim();
            const targetNodeName = (columns[targetFieldIdx][i] as string).trim();

            insertNodeIfNotExist(sankeyData, sourceNodeName);
            insertNodeIfNotExist(sankeyData, targetNodeName);
            // If same link exists then sum up the value and donot insert new link
            const sourceNodeIdx = getNodesIndex(sankeyData, sourceNodeName);
            const targetNodeIdx = getNodesIndex(sankeyData, targetNodeName);
            const linkExist = sankeyData.links.find(link => {
                return (
                    link.source === ((sourceNodeIdx as unknown) as SankeyDataNode) &&
                    link.target === ((targetNodeIdx as unknown) as SankeyDataNode)
                );
            });
            const linkValue = +columns[linkValueFieldIdx][i];
            if (linkExist) {
                linkExist.value += linkValue;
            } else {
                insertLink(sankeyData, sourceNodeIdx, targetNodeIdx, linkValue, i);
            }
        } else {
            console.error(
                'Link excluded. Invalid type in column input, row :- ',
                i,
                `[${columns[sourceFieldIdx][i]}, ${columns[targetFieldIdx][i]}, ${columns[linkValueFieldIdx][i]}]`
            );
        }
    }
    return sankeyData;
};

export const getNodeId = (node: SankeyDataNode): string => {
    if (!node) {
        return '';
    }
    return `node-${node.index}`;
};

export const getLinkId = (link: SankeyDataLink): string => {
    if (!link) {
        return '';
    }
    return `link-${link.id}`;
};

export const getAllNodeNames = (sankeyData: SankeyData): string[] => {
    if (!sankeyData || !sankeyData.nodes || !sankeyData.nodes.length) {
        return [];
    }
    return sankeyData.nodes.map(node => node.name);
};

export const extractLinkMidPoint = (pathStr: string): Coordinates => {
    if (!pathStr) {
        return null;
    }
    const mIndex = pathStr.indexOf('M');
    const cIndexFirst = pathStr.indexOf('C');
    const lIndex = pathStr.indexOf('L');
    const cIndexLast = pathStr.lastIndexOf('C');
    const zIndex = pathStr.lastIndexOf('Z');
    if (mIndex < 0 || cIndexLast < 0 || cIndexLast < 0 || zIndex < 0) {
        console.error('Extract link midpoint error. Invalid path -', pathStr);
        return null;
    }
    const section1 = pathStr.slice(mIndex + 1, cIndexFirst).trim();
    const section2 = pathStr.slice(cIndexFirst + 1, lIndex).trim();
    const section3 = pathStr.slice(lIndex + 1, cIndexLast).trim();
    const section4 = pathStr.slice(cIndexLast + 1, zIndex).trim();
    if (!section1.length || !section2.length || !section3.length || !section4.length) {
        console.error('Extract link midpoint error. Invalid path -', pathStr);
        return null;
    }
    const points = [];
    points.push(section1.split(',').map(i => Number.parseFloat(i)));
    const point2Arr = section2.split(' ');
    points.push(point2Arr[point2Arr.length - 1].split(',').map(i => Number.parseFloat(i)));
    points.push(section3.split(',').map(i => Number.parseFloat(i)));
    const point4Arr = section4.split(' ');
    points.push(point4Arr[point4Arr.length - 1].split(',').map(i => Number.parseFloat(i)));
    const width = Math.abs(points[0][0] - points[1][0]) / 2;
    const x = Math.min(points[0][0], points[1][0]) + width;
    const height = Math.abs(points[0][1] - points[2][1]) / 2;
    const y = Math.min(points[0][1], points[2][1]) + height;
    return { x, y };
};

export const getAllConnectedLinks = (links: SankeyDataLink[], nodeIdx: number): Connections => {
    const result: Connections = { links: [], sourceNodes: [], targetNodes: [] };
    if (!links || !links.length) {
        return result;
    }

    links.forEach(link => {
        if (link.source.index === nodeIdx || link.target.index === nodeIdx) {
            const linkAdded = result.links.find(l => l.id === link.id);
            if (!linkAdded) {
                result.links.push(link);
            }

            const sourceNodeAdded = result.sourceNodes.find(n => n.index === link.source.index);
            if (!sourceNodeAdded) {
                result.sourceNodes.push(link.source);
            }

            const targetNodeAdded = result.targetNodes.find(n => n.index === link.target.index);
            if (!targetNodeAdded) {
                result.targetNodes.push(link.target);
            }
        }
    });
    return result;
};

export const computeAllConnectedLinksToNodes = (
    nodes: SankeyDataNode[],
    links: SankeyDataLink[]
): NodeConnectionsMap => {
    const connectionsMap: NodeConnectionsMap = {};
    if (!nodes || !links) {
        return connectionsMap;
    }

    nodes.forEach(node => {
        const connections = getAllConnectedLinks(links, node.index);
        connectionsMap[node.index] = connections;
    });

    return connectionsMap;
};

export const checkDigitsOnly = (input: string): boolean => {
    return /^\d+$/.test(input);
};

// Truncate the node text and show ellipsis at the end if text string width exceeds the max limit
export const truncateSVGTextWithEllipsis = (
    textElement: SVGTextElement,
    textString: string,
    width: number
): void => {
    if (textElement && textElement.getSubStringLength !== undefined) {
        textElement.textContent = textString;
        // ellipsis is needed
        if (textElement.getSubStringLength(0, textString.length) >= width) {
            for (let x = textString.length - 3; x > 0; x -= 3) {
                if (textElement.getSubStringLength(0, x) <= width) {
                    textElement.textContent = `${textString.substring(0, x)}...`;
                    return;
                }
            }
            textElement.textContent = '...'; // can't place at all
        }
    }
};
