import * as React from 'react';

interface MessageContextInterface {
    cspMessageLink?: string;
}

const MessageContext = React.createContext<MessageContextInterface>({});

export const MessageContextProvider = MessageContext.Provider;
export const MessageContextConsumer = MessageContext.Consumer;
export default MessageContext;
