import { isNumber } from '@splunk/visualization-encoding/utils/types';
import { getFieldsFromDSL } from '@splunk/visualization-encoding/utils/dsl';
import { deepMergeWithArrayPrimitiveOverrides } from '@splunk/visualizations-shared/hocUtils';
import { pickFieldFromJSONSchema } from '@splunk/visualizations-shared/JSONSchemaUtils';
import { GEO_LAT_AND_LONG_FIELD } from '../../Map/PureMap/BubbleLayer';

export const columnsNullToZeros = (config, dataSources, options) => {
    const { primary } = dataSources;
    const { columns, fields } = primary.data;
    let newColumns = columns;
    const mergedOptions = deepMergeWithArrayPrimitiveOverrides(
        {},
        options,
        pickFieldFromJSONSchema(config.optionsSchema, 'default')
    );
    if (mergedOptions.layers?.[0]?.type === 'bubble') {
        const latitudeField = getFieldsFromDSL(mergedOptions.layers[0].latitude, dataSources)[0] || '';
        const longitudeField = getFieldsFromDSL(mergedOptions.layers[0].longitude, dataSources)[0] || '';
        const excludedFields = [latitudeField, longitudeField, 'geobin'].concat(GEO_LAT_AND_LONG_FIELD);
        const indicesToEvaluate = new Set(
            fields.reduce((arr, e, i) => {
                const field = typeof e === 'string' ? e : e.name.toString();
                if (!excludedFields.includes(field)) {
                    arr.push(i);
                }
                return arr;
            }, [])
        );

        newColumns = columns.map((column, i) => {
            if (indicesToEvaluate.has(i)) {
                // sanitize value and convert string to numeric numbers
                return column.map(value => (!value || !isNumber(value) ? 0 : +value));
            }
            return column;
        });
    }

    const modifiedDatasources = {
        ...dataSources,
        primary: {
            ...primary,
            data: {
                ...primary.data,
                columns: newColumns,
            },
        },
    };

    return modifiedDatasources;
};
