import { _ } from '@splunk/ui-utils/i18n';
import { isDynamicOption } from '@splunk/visualizations-shared/configUtils';

export const getDynamicTrendColorEditor = ({ defaultContext, defaultPalettesConfig }) => ({
    label: _('Trend value'),
    option: 'trendColor',
    context: 'trendColorEditorConfig',
    editor: 'editor.dynamicColor',
    showEditor: ({ options }) => isDynamicOption(options.trendColor),
    editorProps: {
        labelPosition: 'top',
        flyoutTitle: _('Dynamic coloring: trend value'),
        formatters: [
            {
                label: _('Ranges'),
                value: 'rangeValue',
                defaults: {
                    trendValue: defaultContext.trendColorConfig,
                },
            },
        ],
        dataSelectors: [
            {
                label: _('Trend value'),
                value: 'trendValue',
            },
        ],
        defaultPalettesConfig,
    },
});
