/* eslint-disable no-restricted-syntax, guard-for-in */
import { get, inRange, merge } from 'lodash';
import { logfmt, DEFAULT_TOKEN_NAMESPACE } from '@splunk/dashboard-utils';
import { DashboardDefinition } from '@splunk/dashboard-definition';
import {
    put,
    select,
    takeLatest,
    takeEvery,
    cancelled,
} from 'redux-saga/effects';
import {
    selectInputState,
    setInputState,
    updateInputState,
} from '../reducers/inputState';
import { createNextState } from '../utils/input';
import {
    selectDefinition,
    updateDefinition,
    selectInputs,
    selectLayout,
} from '../reducers/definition';
import {
    setToken,
    selectSubmittedTokens,
    selectStashedTokens,
} from '../reducers/tokens';
import {
    createInput,
    adjustInputOrder,
    inputChanged,
    initialize,
    inputValueChanged,
    triggerEvent,
} from './sagaActions';
import { contains, filterExisting } from '../utils/token';
import resetStore from '../reducers/resetStore';

const hasSubmitButton = (layout) =>
    get(layout, ['options', 'submitButton'], false);
const hasSubmitOnDashboardLoad = (layout) =>
    get(layout, ['options', 'submitOnDashboardLoad'], false);

export const getTokenNamespace = (inputDef) =>
    get(inputDef, ['options', 'tokenNamespace'], DEFAULT_TOKEN_NAMESPACE);

/**
 * handle input value change, it's gonna update the input state ( value ) and set tokens
 * @param {*} action
 * @param {*} inputDefs
 * @param {*} sagaContext
 */
export function* handleValueChange(inputDefs, sagaContext, action) {
    const { id, value, eventId } = action.payload;
    const inputDef = inputDefs[id];
    const layout = yield select(selectLayout);
    const definition = yield select(selectDefinition);
    const definitionClass = DashboardDefinition.fromJSON(definition);
    // given the new value, compute the next input state.
    const nextState = createNextState({ value, inputDef });
    yield put(updateInputState(id, nextState));
    // dispatch value change event.
    // token submission will be handled in event saga as part of event handling pipeline
    const input = sagaContext.preset.findInput(inputDef.type);
    const tokens =
        input && input.valueToTokens
            ? input.valueToTokens(nextState.value, get(inputDef, 'options', {}))
            : {};
    // check if there's submit button for defined namespace.
    const tokenNamespace = getTokenNamespace(inputDef);
    // we only have submit button for default namespace now
    const submit = !(
        tokenNamespace === DEFAULT_TOKEN_NAMESPACE &&
        hasSubmitButton(layout) &&
        !definitionClass.isInputOnCanvas(id)
    );
    yield put(
        triggerEvent(
            id,
            'input.change',
            {
                value: nextState.value,
                tokens,
                tokenNamespace,
                submit,
            },
            eventId // the event dispatched from ui component
        )
    );
}

/**
 * Given the new inputState, compute and update token values.
 * We need to perform this because new inputState may contains default/initial value so we need to
 * populate the tokens accordingly
 * @param {*} inputState
 * @param {*} inputDefs
 * @param {*} sagaContext
 */
export function* handleInitialTokenSubmit({
    inputState,
    inputDefs,
    sagaContext,
    action = {},
}) {
    const layout = yield select(selectLayout);
    const submittedTokens = yield select(selectSubmittedTokens);
    const stashedTokens = yield select(selectStashedTokens);
    const definition = yield select(selectDefinition);
    const definitionClass = DashboardDefinition.fromJSON(definition);
    // submit first tokens if we're initializing and option for submitting on load is true.
    const submitFirstTokens =
        action.type === 'SAGA_INITIALIZE' && hasSubmitOnDashboardLoad(layout);
    let existingTokens = {}; // Tokens that have been submitted in this iteration. Used to check for duplicates
    for (const id in inputState) {
        const inputDef = inputDefs[id];
        const tokenNamespace = getTokenNamespace(inputDef);
        // submit tokens if no submit button, namespace is not default, we submit on load, OR if the input is on the canvas
        const submit =
            submitFirstTokens ||
            tokenNamespace !== DEFAULT_TOKEN_NAMESPACE ||
            !hasSubmitButton(layout) ||
            definitionClass.isInputOnCanvas(id);

        const input = sagaContext.preset.findInput(inputDef.type);
        const tokens =
            input && input.valueToTokens
                ? input.valueToTokens(
                      inputState[id].value,
                      get(inputDef, 'options', {})
                  )
                : {};
        const filteredTokens = filterExisting(tokens, existingTokens); // Filter tokens that were just submitted (duplicate tokens)
        existingTokens = { ...existingTokens, ...filteredTokens }; // Add new tokens to existing list
        if (
            !contains(
                (submit ? submittedTokens : stashedTokens)[tokenNamespace],
                filteredTokens
            )
        ) {
            yield put(setToken(filteredTokens, tokenNamespace, submit));
        }
    }
}

/**
 * handle input definition change
 * @param {*} sagaContext
 */
export function* handleInputDefinitionChanged(sagaContext, action) {
    const inputDefs = yield select(selectInputs);
    const currentInputState = yield select(selectInputState);
    const submittedTokens = yield select(selectSubmittedTokens);
    const stashedTokens = yield select(selectStashedTokens);

    const isReset = action.type === 'RESET';
    const tokens = merge({}, submittedTokens, stashedTokens);

    // default value and initialValue will be taken care here.
    const { preset } = sagaContext;
    yield put(
        setInputState({
            currentInputState,
            inputDefs,
            tokens,
            preset,
            isReset,
        })
    );
    // handle initial submission
    const inputState = yield select(selectInputState);
    yield handleInitialTokenSubmit({
        inputState,
        inputDefs,
        sagaContext,
        action,
    });
    // setup value change handler for inputs
    yield takeEvery(
        inputValueChanged,
        handleValueChange,
        inputDefs,
        sagaContext
    );
}

/**
 * create input
 * @param {Object} sagaContext Redux store context
 * @param {Object} action
 * @param {String} action.inputId The new input id
 * @param {Object} action.inputDefinition The configuration of the input
 */
// TODO: should we also support creating datasource at the same time (like viz?)
export function* handleCreateInput(_sagaContext, action) {
    const { inputId, inputDefinition } = action.payload || {};

    // Bail when we don't have information to use
    if (!inputId || !inputDefinition) {
        return;
    }

    const currentDefinition = yield select(selectDefinition);
    const definition = DashboardDefinition.fromJSON(currentDefinition);
    const token = get(inputDefinition, 'options.token');

    // Bail if the token is set by another input.
    if (token && definition.getInputByToken(token)) {
        return;
    }

    // Add input will normalize the inputDefinition
    definition.addInput(inputId, inputDefinition).addInputToLayout(inputId);
    // onDefinitionChange callback will then be invoked
    yield put(updateDefinition(definition.toJSON()));
}

/**
 * move an input around the globalInputs array
 * @param {Object} sagaContext Redux store context
 * @param {Object} action
 * @param {Number} action.from Original position
 * @param {Number} action.to The new position
 */
export function* handleInputOrderChange(_sagaContext, action) {
    const { from, to } = action.payload || {};

    const currentDefinition = yield select(selectDefinition);
    const definition = DashboardDefinition.fromJSON(currentDefinition);
    // getGlobalInputs returns a cloned array
    const globalInputs = definition.getGlobalInputs();

    // Make sure from and to values are sane
    if (
        !inRange(from, globalInputs.length) ||
        !inRange(to, globalInputs.length) ||
        from === to
    ) {
        return;
    }

    // take out the input value
    const orig = globalInputs.splice(from, 1);
    // put it back in
    globalInputs.splice(to, 0, ...orig);

    definition.updateGlobalInputs(globalInputs);
    // onDefinitionChange callback will then be invoked
    yield put(updateDefinition(definition.toJSON()));
}

/**
 * Handle adding an input to the definition and global inputs
 * @param {Object} sagaContext Redux store context
 */

export default function* inputSaga(sagaContext) {
    try {
        yield takeLatest(
            [resetStore, inputChanged, initialize],
            handleInputDefinitionChanged,
            sagaContext
        );
        yield takeLatest(createInput, handleCreateInput, sagaContext);
        yield takeLatest(adjustInputOrder, handleInputOrderChange, sagaContext);
    } catch (error) {
        if (!(yield cancelled())) {
            // eslint-disable-next-line no-console
            console.error(...logfmt`Caught error: ${error}`);
        }
    } finally {
        // do nothing
    }
}
