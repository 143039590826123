import type { VisualizationDefinition } from '@splunk/dashboard-types';
import config from '@splunk/visualizations/Markdown.config';
import { removeInvalidOptions } from '../utils/migrationUtils';

const validOptions = {
    ...config.optionsSchema,
};

export const migrateVizToSplunkMarkdown = (
    vizDefinition: VisualizationDefinition
): VisualizationDefinition => {
    const { options = {}, ...otherDefinitionParts } = vizDefinition;
    const migratedVisualization = { options, ...otherDefinitionParts, type: 'splunk.markdown', context: {} };

    // remove invalid options using the optionsSchema for splunk.markdown as reference
    removeInvalidOptions(migratedVisualization.options, validOptions);
    return migratedVisualization;
};
