import { useCallback, useEffect, useRef } from 'react';
import type { useWorkerArgs } from '../types';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type EventHandler = (...args: any[]) => void;

export const useWorker = ({
    WorkerClass,
    onMessage,
    postMessage,
}: useWorkerArgs) => {
    const enabled = typeof Worker !== 'undefined';
    const isFirstRender = useRef(true);

    const createInitialWorker = useCallback(() => {
        // if this is not the first render, or web workers are not enabled
        //  we do NOT want to create a Web Worker
        if (!isFirstRender.current || !enabled) {
            return undefined;
        }
        isFirstRender.current = false;
        return new WorkerClass();
    }, [WorkerClass, enabled]);

    const worker = useRef<Worker | undefined>(createInitialWorker());

    useEffect(() => {
        // needs to be copied to a variable because `ref.current` might be unset by the time the
        //  cleanup function runs
        const workerToCleanUp = worker.current;
        return () => {
            workerToCleanUp?.terminate?.();
        };
    }, []);

    const onMessageHandlerRef = useRef<EventHandler | undefined>();
    onMessageHandlerRef.current = onMessage;

    const onMessageHandler = useCallback<EventHandler>((...args) => {
        onMessageHandlerRef.current?.(...args);
    }, []);

    useEffect(() => {
        const currentWorker = worker.current;

        if (enabled && currentWorker) {
            currentWorker.addEventListener('message', onMessageHandler);
        }

        return () => {
            if (enabled && currentWorker) {
                currentWorker.removeEventListener('message', onMessageHandler);
            }
        };
    }, [onMessageHandler, enabled]);

    useEffect(() => {
        if (enabled && worker.current) {
            postMessage(worker.current);
        }
    }, [enabled, postMessage]);

    return { enabled };
};
