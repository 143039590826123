import * as React from 'react';
import type { Map } from 'maplibre-gl';
import BubbleLayer from './BubbleLayer';
import MarkerLayer from './MarkerLayer';
import type { ClusterLayerType, BubbleLayerType, MarkerLayerType } from './MapUtils';
import { LAYER_TYPE } from './MapUtils';

interface ClusterLayerProps {
    clusterLayer: ClusterLayerType;
    themeColorScheme: string;
    map: React.MutableRefObject<Map>;
    zoom?: number;
    onMapClick?: ({ e, payload }) => void;
}

const ClusterLayer = ({
    clusterLayer,
    themeColorScheme,
    zoom,
    map,
    onMapClick,
}: ClusterLayerProps): JSX.Element => {
    if (map?.current?.isStyleLoaded && [clusterLayer.longitude, clusterLayer.latitude].every(Array.isArray)) {
        if (clusterLayer?.type === LAYER_TYPE.Marker && !('geobin' in clusterLayer)) {
            return (
                <MarkerLayer
                    markerLayer={clusterLayer as MarkerLayerType}
                    themeColorScheme={themeColorScheme}
                    map={map}
                    onMapClick={onMapClick}
                />
            );
        }
        if (
            clusterLayer?.type === LAYER_TYPE.Bubble &&
            'geobin' in clusterLayer &&
            'bubbleSize' in clusterLayer
        ) {
            return (
                <BubbleLayer
                    bubbleLayer={clusterLayer as BubbleLayerType}
                    themeColorScheme={themeColorScheme}
                    map={map}
                    zoom={zoom}
                    onMapClick={onMapClick}
                />
            );
        }
    }
    return null;
};

export default ClusterLayer;
