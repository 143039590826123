import { mapKeys, chain, omit, values } from 'lodash';
import { convertPropsToString } from '@splunk/visualizations-shared/propUtils';
import { mapToValues, convertY2AxisOptions, convertOverlayFields } from '../common/chartingUtils';

interface LineOptions {
    [key: string]: any;
}

// map for key names which are either new or not present in SimpleXML options mapping
const lineOptionsSCMapping = {
    markerDisplay: 'lineMarkerStyle',
    legendDisplay: 'legendPlacement',
    legendTruncation: 'legendLabelOverflowMode',
    showYAxisWithZero: 'yAxisIncludeZero',
    showY2AxisWithZero: 'y2AxisIncludeZero',
    showYAxisExtendedRange: 'yAxisExtendsAxisRange',
    showSplitSeries: 'splitSeries',
    showIndependentYRanges: 'allowIndependentYRanges',
    showOverlayY2Axis: 'enableY2Axis',
    showXMajorGridLines: 'showMajorXGridLines',
    showYMajorGridLines: 'showMajorYGridLines',
    showYMinorGridLines: 'showMinorYGridLines',
    showY2MajorGridLines: 'showMajorY2GridLines',
    showY2MinorGridLines: 'showMinorY2GridLines',
    yAxisMajorTickInterval: 'yAxisMajorUnit',
    yAxisLabelVisibility: 'yAxisMajorLabelVisibility',
    dataValuesDisplay: 'showDataLabels',
    nullValueDisplay: 'nullValueMode',
    lineDashStylesByField: 'fieldDashStyles',
    xAxisLabelRotation: 'xAxisMajorLabelRotation',
    xAxisLabelVisibility: 'xAxisMajorLabelVisibility',
    seriesColorsByField: 'fieldColors',
    resultLimit: 'resultTruncationLimit',
    y2AxisLabelVisibility: 'y2AxisMajorLabelVisibility',
    y2AxisMajorTickInterval: 'y2AxisMajorUnit',
    showRoundedY2AxisLabels: 'roundY2AxisLabelsToInteger',
    xAxisLineVisibility: 'xAxisVisibility',
    yAxisLineVisibility: 'yAxisVisibility',
    y2AxisLineVisibility: 'y2AxisVisibility',
    showLineSmoothing: 'enableLineSmoothing',
};

// key value mapping of what options (and their corresponding values) which we expose in config.ts to property values SC understands
export const lineOptionValuesSCMapping = {
    lineMarkerStyle: {
        filled: 'normal',
        outlined: 'hollow',
    },
    xAxisTitleVisibility: {
        hide: 'collapsed',
    },
    yAxisTitleVisibility: {
        hide: 'collapsed',
    },
    y2AxisTitleVisibility: {
        hide: 'collapsed',
    },
    legendPlacement: {
        off: 'none',
    },
    legendLabelOverflowMode: {
        ellipsisOff: 'ellipsisNone',
    },
    y2AxisAbbreviation: {
        off: 'none',
    },
    yAxisAbbreviation: {
        off: 'none',
    },
    showDataLabels: {
        off: 'none',
    },
};

/**
 * Helper function to convert values for marker options
 * @method convertMarkerOptions
 * @param {Object} originalOptions
 * @returns {Object}
 */
export const convertMarkerOptions = (originalOptions: LineOptions): LineOptions => {
    const convertedOptions: LineOptions = { showMarkers: false };
    if (values(lineOptionValuesSCMapping.lineMarkerStyle).includes(originalOptions.lineMarkerStyle)) {
        convertedOptions.showMarkers = true;
        return { ...originalOptions, ...convertedOptions };
    }
    return omit({ ...originalOptions, ...convertedOptions }, ['lineMarkerStyle']);
};

/**
 * Helper method to update the option names which can be mapped to SimpleXML options or
 * can be used directly with SC if don't have a 1-1 mapping in SimpleXML
 * @method mapToOldKey
 * @param {Object} options
 * @returns {Object}
 */
export const mapToOldKey = (options: LineOptions): LineOptions =>
    mapKeys(options, (val, key) => (lineOptionsSCMapping[key] ? lineOptionsSCMapping[key] : key));

/**
 * Helper method to convert option which contains object literal values (e.g. arrays, objects)
 * to strings as SC can only accept their stringified equivalents
 * @method convertToStringValues
 * @param {Object} options
 * @returns {Object}
 */
export const convertToStringValues = (options: LineOptions): LineOptions => {
    const updatedOptions = convertPropsToString({
        vizProps: options,
        propNames: ['fieldDashStyles', 'seriesColors', 'fieldColors', 'legendLabels'],
    });
    return { ...options, ...updatedOptions };
};

/**
 * Chained helper for mapping the option properties (and values) we expose in config.ts into property values that splunk-charting accepts
 * This is for options that has new key name and can not directly be mapped with SimpleXML or
 * don't have a 1-1 mapping in SimpleXML (i.e. a straightforward name mapping where values are preserved)
 * @method convertToLineSCProperties
 * @param {Object} originalOptions
 * @returns {Object}
 */
export const convertToLineSCProperties = (originalOptions: LineOptions): LineOptions =>
    chain(mapToOldKey(originalOptions))
        .thru(mapToValues(lineOptionValuesSCMapping))
        .thru(convertToStringValues)
        .thru(convertMarkerOptions)
        .thru(convertOverlayFields)
        .thru(convertY2AxisOptions)
        .value();
