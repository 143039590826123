/* eslint-disable react/no-unused-prop-types,react/prefer-stateless-function */
import * as T from 'prop-types';
import { noop } from 'lodash';
import { DashboardVizProps } from './interfaces/DashboardVizProps';
import { DefaultVizActionHandler } from './interfaces/VizActionHandler';

// this is identical to the initial height and width we use in BaseVisualization in dashboard-visualizations
export const INITIAL_WIDTH = 250;
export const INITIAL_HEIGHT = 250;

/**
 * A Splunk visualization
 */
class SplunkVisualization {
    static propTypes: Record<keyof DashboardVizProps, T.Validator<any>> = {
        /**
         * display mode
         */
        mode: T.oneOf(['view', 'edit', 'export']).isRequired,
        /**
         * width in pixel or string, defaults to 100%
         */
        width: T.oneOfType([T.string, T.number]).isRequired,
        /**
         * height in pixel or string
         */
        height: T.oneOfType([T.string, T.number]).isRequired,
        /**
         * visualization context used for dynamic option evaluation
         */
        context: T.object,
        /**
         * visualization formatting options
         */
        options: T.object,
        /**
         * A callback to update formatting options
         */
        onOptionsChange: T.func,
        /**
         * datasource state which include data and request params, object key indicate the datasource type.
         */
        dataSources: T.objectOf(
            T.shape({
                /**
                 * current request params
                 */
                requestParams: T.object,
                /**
                 * current dataset
                 */
                data: T.shape({
                    fields: T.array,
                    columns: T.array,
                }),
                /**
                 * error
                 */
                error: T.shape({
                    level: T.string,
                    message: T.string,
                }),
                /**
                 * meta data that came with the dataset
                 */
                meta: T.object,
            })
        ),
        /**
         * A callback to trigger event
         */
        onEventTrigger: T.func,
        /**
         * Inform viz if there are handlers listening to events
         */
        hasEventHandlers: T.bool,
        /**
         * A callback to communicate computed props to a consumer
         */
        onComputedProps: T.func,
        /**
         * A callback to obtain visualization api
         */
        vizActionHandlerRef: T.func,

        loading: T.bool,
        theme: T.object,
        /**
         * A callback to request new data with updated request params
         */
        onRequestParamsChange: T.func,
    };

    /**
     *
     */
    static defaultProps: DashboardVizProps = {
        // BaseVisualization in dashboard-visualizations uses 100% for width but 250 for height
        width: '100%',
        height: INITIAL_HEIGHT,
        dataSources: {},
        onEventTrigger: (): Record<string, unknown> => ({}),
        mode: 'view',
        hasEventHandlers: false,
        options: {},
        context: {},
        onOptionsChange: (): Record<string, unknown> => ({}),
        vizActionHandlerRef: (): DefaultVizActionHandler => new DefaultVizActionHandler(),
        onComputedProps: (): Record<string, unknown> => ({}),
        loading: false,
        onRequestParamsChange: noop,
    };
}

export default SplunkVisualization;
