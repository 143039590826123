import T from 'prop-types';
import { CATEGORICAL } from '@splunk/visualization-color-palettes';

const noop = () => {};

export const baseChartPropTypes = {
    width: T.oneOfType([T.string, T.number]),
    height: T.oneOfType([T.string, T.number]),
    style: T.object,
    backgroundColor: T.string,
    foregroundColor: T.string,
    fontColor: T.string,
    seriesColors: T.oneOfType([T.string, T.arrayOf(T.string)]),
    fieldColors: T.oneOfType([T.string, T.object]),
    legendLabels: T.oneOfType([T.string, T.arrayOf(T.string)]),
    legendPlacement: T.oneOf(['right', 'left', 'top', 'bottom', 'none']),
    legendLabelOverflowMode: T.oneOf(['ellipsisEnd', 'ellipsisMiddle', 'ellipsisStart', 'ellipsisNone']),
    masterLegend: T.string,
    onPointClick: T.func,
    onLegendClick: T.func,
    resultTruncationLimit: T.number,
    colorPalette: T.array,
};

export const baseChartDefaultProps = {
    width: '100%',
    height: 250,
    style: {},
    legendPlacement: 'right',
    legendLabelOverflowMode: 'ellipsisMiddle',
    masterLegend: null,
    onPointClick: noop,
    onLegendClick: noop,
    resultTruncationLimit: 50000,
    colorPalette: CATEGORICAL,
};

export const zoomableChartPropTypes = {
    onRangeSelect: T.func,
};

export const zoomableChartDefaultProps = {
    onRangeSelect: noop,
};

export const xyChartPropTypes = {
    x: T.arrayOf(T.oneOfType([T.string, T.number])),
    xFieldName: T.string,
    y: T.arrayOf(T.oneOfType([T.string, T.number, T.arrayOf(T.oneOfType([T.string, T.number]))])),
    yFieldName: T.oneOfType([T.string, T.arrayOf(T.string)]),
    /* axes */
    xAxisVisibility: T.oneOf(['show', 'hide']),
    yAxisVisibility: T.oneOf(['show', 'hide']),
    yAxisExtendsAxisRange: T.bool,
    xAxisMajorLabelVisibility: T.oneOf(['auto', 'show', 'hide']),
    xAxisMajorTickSize: T.number,
    xAxisMaxLabelParts: T.number,
    yAxisMajorLabelVisibility: T.oneOf(['auto', 'show', 'hide']),
    yAxisMajorTickSize: T.number,
    yAxisMinorTickSize: T.number,
    xAxisMajorTickVisibility: T.oneOf(['auto', 'show', 'hide']),
    yAxisMajorTickVisibility: T.oneOf(['auto', 'show', 'hide']),
    yAxisMajorUnit: T.oneOfType([T.number, T.string]),
    yAxisMinorTickVisibility: T.oneOf(['auto', 'show', 'hide']),
    yAxisAbbreviation: T.oneOf(['none', 'auto']),
    yAxisIncludeZero: T.bool,
    xAxisMax: T.oneOfType([T.string, T.number]),
    yAxisMax: T.oneOfType([T.string, T.number]),
    yAxisMin: T.oneOfType([T.string, T.number]),
    yAxisScale: T.oneOf(['linear', 'log']),
    xAxisTitleText: T.string,
    yAxisTitleText: T.string,
    xAxisTitleVisibility: T.oneOf(['visible', 'collapsed']),
    yAxisTitleVisibility: T.oneOf(['visible', 'collapsed']),
    showMajorYGridLines: T.bool,
    showMinorYGridLines: T.bool,
};

export const xyChartDefaultProps = {
    xAxisVisibility: 'hide',
    yAxisVisibility: 'hide',
    yAxisExtendsAxisRange: true,
    xAxisMajorLabelVisibility: 'auto',
    yAxisMajorLabelVisibility: 'auto',
    xAxisMajorTickSize: 6,
    yAxisMajorTickSize: 6,
    xAxisMaxLabelParts: 3,
    yAxisMinorTickSize: 6,
    xAxisMajorTickVisibility: 'auto',
    yAxisMajorTickVisibility: 'auto',
    yAxisMajorUnit: 'auto',
    yAxisMinorTickVisibility: 'auto',
    yAxisAbbreviation: 'none',
    yAxisIncludeZero: false,
    xAxisMax: 'auto',
    yAxisMax: 'auto',
    yAxisMin: 'auto',
    yAxisScale: 'linear',
    xAxisTitleVisibility: 'visible',
    yAxisTitleVisibility: 'visible',
    showMajorYGridLines: true,
    showMinorYGridLines: false,
};

export const multiYChartProps = {
    y2: T.arrayOf(T.oneOfType([T.string, T.number, T.arrayOf(T.oneOfType([T.string, T.number]))])),
    y2FieldName: T.oneOfType([T.string, T.arrayOf(T.string)]),
    y2AxisMajorLabelVisibility: T.oneOf(['auto', 'show', 'hide']),
    y2AxisMajorTickSize: T.number,
    y2AxisMinorTickSize: T.number,
    y2AxisMajorTickVisibility: T.oneOf(['auto', 'show', 'hide']),
    y2AxisMajorUnit: T.oneOfType([T.number, T.string]),
    y2AxisMinorTickVisibility: T.oneOf(['auto', 'show', 'hide']),
    y2AxisAbbreviation: T.oneOf(['none', 'auto']),
    y2AxisIncludeZero: T.bool,
    roundY2AxisLabelsToInteger: T.bool,
    y2AxisMax: T.oneOfType([T.string, T.number]),
    y2AxisMin: T.oneOfType([T.string, T.number]),
    y2AxisScale: T.oneOf(['linear', 'log']),
    y2AxisTitleText: T.string,
    y2AxisTitleVisibility: T.oneOf(['visible', 'collapsed']),
    showMajorY2GridLines: T.bool,
    showMinorY2GridLines: T.bool,
    overlayFields: T.string,
    enableY2Axis: T.bool,
    y2Fields: T.string,
    y2AxisVisibility: T.oneOf(['show', 'hide']),
};

export const multiYChartDefaultProps = {
    y2AxisMajorLabelVisibility: 'auto',
    y2AxisMajorTickSize: 6,
    y2AxisMinorTickSize: 6,
    y2AxisMajorTickVisibility: 'auto',
    y2AxisMajorUnit: 'auto',
    y2AxisMinorTickVisibility: 'auto',
    y2AxisAbbreviation: 'none',
    roundY2AxisLabelsToInteger: false,
    y2AxisIncludeZero: false,
    y2AxisMax: 'auto',
    y2AxisMin: 'auto',
    y2AxisScale: 'linear',
    y2AxisTitleVisibility: 'visible',
    showMajorY2GridLines: true,
    showMinorY2GridLines: false,
    y2AxisVisibility: 'hide',
    enableY2Axis: false,
};

export const dataChartProps = {
    fieldListMode: T.oneOf(['show_hide', 'hide_show']),
    fieldShowList: T.oneOfType([T.string, T.arrayOf(T.string)]),
    fieldHideList: T.oneOfType([T.string, T.arrayOf(T.string)]),
};

export const dataChartDefaultProps = {
    fieldListMode: 'hide_show',
};
