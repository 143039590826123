import React, { useCallback, useMemo } from 'react';
import { pick, variables, pickVariant } from '@splunk/themes';
import styled from 'styled-components';
import { DragHandle as DragHandleSVG } from '@splunk/dashboard-icons';
import type { LayoutItemType } from '@splunk/dashboard-types';
import { SelectableContainer } from '../SelectableContainer';

interface DragHandleContainerProps {
    type: LayoutItemType;
}

// Input Constants
const DRAG_HANDLE_WIDTH_ON_INPUT = '65px';
const DRAG_HANDLE_HEIGHT_ON_INPUT = '24px';
const DRAG_HANDLE_MARGIN_ON_INPUT = '0 auto';

// Viz Constants
const DRAG_HANDLE_WIDTH_ON_VIZ = '88px';
const DRAG_HANDLE_HEIGHT_ON_VIZ = '36px';
const DRAG_HANDLE_MARGIN_ON_VIZ = '9px auto 0';

const DragHandleContainer = styled.div.attrs({
    'data-test': 'visualization-drag-handle',
})<DragHandleContainerProps>`
    display: none;
    position: absolute;
    left: 0;
    right: 0;
    margin: ${pickVariant<DragHandleContainerProps>('type', {
        input: DRAG_HANDLE_MARGIN_ON_INPUT,
        block: DRAG_HANDLE_MARGIN_ON_VIZ,
    })};
    min-width: 36px;
    min-height: 20px;
    max-width: ${pickVariant<DragHandleContainerProps>('type', {
        input: DRAG_HANDLE_WIDTH_ON_INPUT,
        block: DRAG_HANDLE_WIDTH_ON_VIZ,
    })};
    max-height: ${pickVariant<DragHandleContainerProps>('type', {
        input: DRAG_HANDLE_HEIGHT_ON_INPUT,
        block: DRAG_HANDLE_HEIGHT_ON_VIZ,
    })};
    z-index: 2;
    cursor: move;
    background-color: ${pick({
        enterprise: {
            dark: variables.gray22,
            light: variables.white,
        },
        prisma: variables.backgroundColorPopup,
    })};
    border-radius: 4px;
    box-shadow: 0px 10px 103px rgba(0, 0, 0, 0.64),
        0px 5px 18px rgba(0, 0, 0, 0.15), 0px 3px 6px rgba(0, 0, 0, 0.3);
    ${SelectableContainer}:hover & {
        display: block;
    }
`;

export interface UseDragHandleComponentProps {
    id: string;
    shouldShowDragHandle: boolean;
    type: LayoutItemType;
}

interface EventProps {
    vizId: string;
    initialEvent: React.MouseEvent;
    type?: LayoutItemType;
}

export type DragHandleEvent = CustomEvent<EventProps>;

export const EVENT_MOUSE_DOWN_ON_HANDLE = 'mousedownVizHandle';
export const EVENT_MOUSE_DOWN_ON_VIZ_WITH_HANDLE = 'mousedownVizWithHandle';

export const useDragHandleComponent = ({
    id,
    shouldShowDragHandle,
    type,
}: UseDragHandleComponentProps): JSX.Element | null => {
    const handleMouseDown = useCallback(
        (event) => {
            // send custom event with the info of the initial mousedown event on handle
            const passedDetail = {
                initialEvent: event,
                vizId: id,
                type,
            };
            document.dispatchEvent(
                new CustomEvent(EVENT_MOUSE_DOWN_ON_HANDLE, {
                    detail: passedDetail,
                })
            );
            event.stopPropagation();
        },
        [id, type]
    );

    // note the use of onMouseDownCapture to avoid any clicks being passed down to the viz below
    return useMemo(
        () =>
            shouldShowDragHandle ? (
                <DragHandleContainer
                    type={type}
                    onMouseDownCapture={handleMouseDown}
                >
                    <DragHandleSVG />
                </DragHandleContainer>
            ) : null,
        [handleMouseDown, shouldShowDragHandle, type]
    );
};
