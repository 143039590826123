import React, { useCallback, useMemo } from 'react';
import styled from 'styled-components';

import Button, { type ButtonClickHandler } from '@splunk/react-ui/Button';
import Modal from '@splunk/react-ui/Modal';
import P from '@splunk/react-ui/Paragraph';

import useSplunkTheme from '@splunk/themes/useSplunkTheme';

import { _ } from '@splunk/ui-utils/i18n';

const FixedWidthModal = styled(Modal)`
    width: 600px;
`;

export type GalleryDeletionModalProps = {
    testId?: string;
    objectName: string;
    objectType: 'icon' | 'image' | 'item';
    sourceElement?: React.MutableRefObject<HTMLElement>;
    handleObjectDeletion: ButtonClickHandler;
    handleDeletionCancel?: (e: Event) => void;
};

const GalleryDeletionModal = ({
    testId = 'gallery-deletion-modal',
    objectName,
    objectType,
    sourceElement,
    handleObjectDeletion,
    handleDeletionCancel,
}: GalleryDeletionModalProps): JSX.Element => {
    const { family } = useSplunkTheme();
    // onClose callback (cancel or close - do not delete)
    const handleCancelDeletion = useCallback(
        (event) => {
            if (handleDeletionCancel) {
                handleDeletionCancel(event);
            }

            sourceElement?.current?.focus();
        },
        [sourceElement, handleDeletionCancel]
    );

    // handle close requests (esc, click-away)
    const handleRequestClose = useCallback(
        ({ event }) => handleCancelDeletion(event),
        [handleCancelDeletion]
    );

    // Modal body in the format: 'Deleting the <i>{NAME}</> {TYPE} is permanent ... use the {TYPE}'
    const modalBody = useMemo(() => {
        const preamble = _('Deleting the ');

        let postscript = _(
            'is permanent for this dashboard and all other dashboards that use the'
        );
        postscript = ` ${objectType} ${postscript} ${objectType}.`;

        return (
            <P>
                {preamble}
                <i>{objectName}</i>
                {postscript}
            </P>
        );
    }, [objectName, objectType]);

    const capitalizedObjectType = objectType.length
        ? objectType[0].toUpperCase() + objectType.substring(1)
        : objectType;
    const cancelButtonAppearance =
        family === 'enterprise' ? 'pill' : 'secondary';
    const confirmButtonAppearance =
        family === 'enterprise' ? 'primary' : 'destructive';

    return (
        <FixedWidthModal
            data-test={testId}
            onRequestClose={handleRequestClose}
            open
            divider="none"
        >
            <Modal.Header
                onRequestClose={handleCancelDeletion}
                title={`${_('Delete')} ${objectName}`}
            />

            <Modal.Body>{modalBody}</Modal.Body>

            <Modal.Footer>
                <Button
                    data-test-appearance={cancelButtonAppearance}
                    data-test-family={family}
                    data-test={`${testId}-cancel`}
                    appearance={cancelButtonAppearance}
                    onClick={handleCancelDeletion}
                    label={_(`Keep ${capitalizedObjectType}`)}
                />
                <Button
                    data-test-appearance={confirmButtonAppearance}
                    data-test-family={family}
                    data-test={`${testId}-confirm`}
                    appearance={confirmButtonAppearance}
                    label={_(`Delete ${capitalizedObjectType}`)}
                    onClick={handleObjectDeletion}
                />
            </Modal.Footer>
        </FixedWidthModal>
    );
};

export default GalleryDeletionModal;
