import { isEqual } from 'lodash';
import type {
    AbsoluteBlockItem,
    AbsolutePosition,
} from '@splunk/dashboard-types';
/**
 * @param {Object} itemPosition
 * @param {Object} vizPosition
 * @returns {boolean} whether viz is contained with the y bounds of item
 */
const isInVerticalRange = (
    itemPosition: AbsolutePosition,
    vizPosition: AbsolutePosition
) => {
    return (
        vizPosition.y >= itemPosition.y &&
        vizPosition.y + vizPosition.h <= itemPosition.y + itemPosition.h
    );
};

/**
 * @param {Object} itemPosition
 * @param {Object} vizPosition
 * @returns {boolean} whether viz is contained with the x bounds of item
 */
const isInHorizontalRange = (
    itemPosition: AbsolutePosition,
    vizPosition: AbsolutePosition
) => {
    return (
        vizPosition.x >= itemPosition.x &&
        vizPosition.x + vizPosition.w <= itemPosition.x + itemPosition.w
    );
};

/**
 * @param {Object} item
 * @param {Object} viz
 * @returns {boolean} whether viz is left neighbor of item
 */
export const isLeftNeighbor = (
    item: AbsoluteBlockItem,
    viz: AbsoluteBlockItem
): boolean => {
    const isLeftOf = viz.position.x + viz.position.w === item.position.x;
    return isLeftOf && isInVerticalRange(item.position, viz.position);
};

/**
 * @param {Object} item
 * @param {Object} viz
 * @returns {boolean} whether viz is right neighbor of item
 */
export const isRightNeighbor = (
    item: AbsoluteBlockItem,
    viz: AbsoluteBlockItem
): boolean => {
    const isRightOf = viz.position.x === item.position.x + item.position.w;
    return isRightOf && isInVerticalRange(item.position, viz.position);
};

/**
 * @param {Object} item
 * @param {Object} viz
 * @returns {boolean} whether viz is top neighbor of item
 */
export const isTopNeighbor = (
    item: AbsoluteBlockItem,
    viz: AbsoluteBlockItem
): boolean => {
    const isAbove = viz.position.y + viz.position.h === item.position.y;
    return isAbove && isInHorizontalRange(item.position, viz.position);
};

/**
 * @param {Object} item
 * @param {Object} viz
 * @returns {boolean} whether viz is bottom neighbor of item
 */
export const isBottomNeighbor = (
    item: AbsoluteBlockItem,
    viz: AbsoluteBlockItem
): boolean => {
    const isBelow = viz.position.y === item.position.y + item.position.h;
    return isBelow && isInHorizontalRange(item.position, viz.position);
};

/**
 * Finds left and right neighbors of the given item and list of visualizations.
 * Note: This is *not* a generic function to find all neighbors. It is customized for remove
 * items and only returns neighbors that are contained with the y bounds of item.
 * @param {Object} item
 * @param {object[]} visualizations
 * @returns {{leftNeighbors: object[], rightNeighbors: object[]}}} list of horizontal neighbors that are within the y bounds of item
 */
export const findHorizontalNeighbors = ({
    item,
    visualizations,
}: {
    item: AbsoluteBlockItem;
    visualizations: AbsoluteBlockItem[];
}): {
    leftNeighbors: AbsoluteBlockItem[];
    rightNeighbors: AbsoluteBlockItem[];
} => {
    let leftHeight = 0;
    let rightHeight = 0;
    let leftNeighbors: AbsoluteBlockItem[] = [];
    let rightNeighbors: AbsoluteBlockItem[] = [];

    visualizations.forEach((viz) => {
        if (isEqual(item, viz)) {
            return;
        }

        if (isLeftNeighbor(item, viz)) {
            leftHeight += viz.position.h;
            leftNeighbors.push(viz);
        }

        if (isRightNeighbor(item, viz)) {
            rightHeight += viz.position.h;
            rightNeighbors.push(viz);
        }
    });
    if (leftHeight !== item.position.h) {
        leftNeighbors = [];
    }
    if (rightHeight !== item.position.h) {
        rightNeighbors = [];
    }
    return { leftNeighbors, rightNeighbors };
};

/**
 * Finds top and bottom neighbors of the given item and list of visualizations.
 * Note: This is *not* a generic function to find all neighbors. It is customized for remove
 * items and only returns neighbors that are contained with the x bounds of item.
 * @param {Object} item
 * @param {object[]} visualizations
 * @returns {{topNeighbors: object[], bottomNeighbors: object[]}} list of vertical neighbors that are within the y bounds of item
 */
export const findVerticalNeighbors = ({
    item,
    visualizations,
}: {
    item: AbsoluteBlockItem;
    visualizations: AbsoluteBlockItem[];
}): {
    topNeighbors: AbsoluteBlockItem[];
    bottomNeighbors: AbsoluteBlockItem[];
} => {
    let topWidth = 0;
    let bottomWidth = 0;
    let topNeighbors: AbsoluteBlockItem[] = [];
    let bottomNeighbors: AbsoluteBlockItem[] = [];
    visualizations.forEach((viz) => {
        if (isEqual(item, viz)) {
            return;
        }

        if (isTopNeighbor(item, viz)) {
            topWidth += viz.position.w;
            topNeighbors.push(viz);
        }

        if (isBottomNeighbor(item, viz)) {
            bottomWidth += viz.position.w;
            bottomNeighbors.push(viz);
        }
    });
    if (topWidth !== item.position.w) {
        topNeighbors = [];
    }
    if (bottomWidth !== item.position.w) {
        bottomNeighbors = [];
    }
    return { topNeighbors, bottomNeighbors };
};

/**
 * Returns the updated positions of visualizations when an item that spans an entire row is deleted.
 * Essentially all visualizations are shifted up.
 * @param {Object} obj
 * @param {Object} obj.itemToRemove
 * @param {object[]} obj.visualizations
 * @returns {object[]} visualizations with updated positions
 */
export const getItemsWithUpdatedPositions = ({
    itemToRemove,
    visualizations,
}: {
    itemToRemove: AbsoluteBlockItem;
    visualizations: AbsoluteBlockItem[];
}): AbsoluteBlockItem[] => {
    const updatedVisualizations: AbsoluteBlockItem[] = [];
    visualizations.forEach((viz) => {
        // Item is below removed row
        if (itemToRemove.position.y < viz.position.y) {
            updatedVisualizations.push({
                ...viz,
                position: {
                    ...viz.position,
                    y: viz.position.y - itemToRemove.position.h,
                },
            });
        }
    });
    return updatedVisualizations;
};

/**
 * Update the items around the item that is removed to fill the space
 * @param {Object} params
 * @param {Object} params.itemToRemove - The item that is being removed
 * @param {object[]} params.items - All the items on the canvas
 * @param {Number} params.width - The width of the entire canvas
 * @returns {object[]} - Array of updated items, filling the space of itemToRemove
 */
export const updateRemovedVizNeighbors = ({
    itemToRemove,
    items,
    width,
}: {
    itemToRemove: AbsoluteBlockItem;
    items: AbsoluteBlockItem[];
    width: number;
}): AbsoluteBlockItem[] => {
    const updatedItems: AbsoluteBlockItem[] = [];

    // viz spans entire row, delete it & move rows below in its place
    if (itemToRemove.position.w === width) {
        return getItemsWithUpdatedPositions({
            itemToRemove,
            visualizations: items,
        });
    }

    const { leftNeighbors, rightNeighbors } = findHorizontalNeighbors({
        item: itemToRemove,
        visualizations: items,
    });

    if (leftNeighbors.length || rightNeighbors.length) {
        if (leftNeighbors.length && rightNeighbors.length) {
            const leftWidthToAdd = Math.floor(itemToRemove.position.w / 2);
            // if itemToRemove.position.w is odd, we give 1 px extra to the right neighbor
            const rightWidthToAdd = Math.ceil(itemToRemove.position.w / 2);
            leftNeighbors.forEach((viz) => {
                updatedItems.push({
                    ...viz,
                    position: {
                        ...viz.position,
                        w: viz.position.w + leftWidthToAdd,
                    },
                });
            });
            rightNeighbors.forEach((viz) => {
                updatedItems.push({
                    ...viz,
                    position: {
                        ...viz.position,
                        x: viz.position.x - rightWidthToAdd,
                        w: viz.position.w + rightWidthToAdd,
                    },
                });
            });
        } else if (leftNeighbors.length) {
            leftNeighbors.forEach((viz) => {
                updatedItems.push({
                    ...viz,
                    position: {
                        ...viz.position,
                        w: viz.position.w + itemToRemove.position.w,
                    },
                });
            });
        } else {
            rightNeighbors.forEach((viz) => {
                updatedItems.push({
                    ...viz,
                    position: {
                        ...viz.position,
                        x: viz.position.x - itemToRemove.position.w,
                        w: viz.position.w + itemToRemove.position.w,
                    },
                });
            });
        }
    } else {
        const { topNeighbors, bottomNeighbors } = findVerticalNeighbors({
            item: itemToRemove,
            visualizations: items,
        });
        if (topNeighbors.length || bottomNeighbors.length) {
            if (topNeighbors.length && bottomNeighbors.length) {
                const topHeightToAdd = Math.floor(itemToRemove.position.h / 2);
                // if itemToRemove.position.h is odd, we give 1 px extra to the bottom neighbor
                const bottomHeightToAdd = Math.ceil(
                    itemToRemove.position.h / 2
                );
                topNeighbors.forEach((viz) => {
                    updatedItems.push({
                        ...viz,
                        position: {
                            ...viz.position,
                            h: viz.position.h + topHeightToAdd,
                        },
                    });
                });
                bottomNeighbors.forEach((viz) => {
                    updatedItems.push({
                        ...viz,
                        position: {
                            ...viz.position,
                            y: viz.position.y - bottomHeightToAdd,
                            h: viz.position.h + bottomHeightToAdd,
                        },
                    });
                });
            } else if (topNeighbors.length) {
                topNeighbors.forEach((viz) => {
                    updatedItems.push({
                        ...viz,
                        position: {
                            ...viz.position,
                            h: viz.position.h + itemToRemove.position.h,
                        },
                    });
                });
            } else {
                bottomNeighbors.forEach((viz) => {
                    updatedItems.push({
                        ...viz,
                        position: {
                            ...viz.position,
                            y: viz.position.y - itemToRemove.position.h,
                            h: viz.position.h + itemToRemove.position.h,
                        },
                    });
                });
            }
        }
    }

    return updatedItems;
};
