import type { GeoJsonDataType } from '@splunk/visualizations-shared/mapUtils';
import { DataPrimitive, TypedValue, DataType, IDataPoint } from './DataPrimitive';
import { TypeSafeValue } from './TypeSafeValue';

/**
 * Base DataPoint class and associated DataPoint selectors
 * @implements {DataPrimitive}
 * @implements {IDataPoint}
 */
export class DataPoint<T extends DataType = DataType> implements DataPrimitive<T>, IDataPoint<T> {
    private value: TypeSafeValue<T>;

    public field: string;

    static isDataPoint(o: any): o is DataPoint {
        return o instanceof DataPoint;
    }

    static fromRaw(value): DataPoint {
        return new DataPoint('', TypeSafeValue.fromRaw(value));
    }

    /**
     *
     * @param {string} field data field
     * @param {object} value data value + it's type (number, string, time, color, geojson)
     */
    public constructor(field: string, value: TypedValue<T>) {
        this.field = field;
        this.setValue(value);
    }

    getValue(): TypeSafeValue<T> {
        return this.value;
    }

    /**
     * Sets the data point's value to a static TypedValue.
     * @param {TypedValue} v
     */
    setValue(v: TypedValue<T>): void {
        this.value = TypeSafeValue.from(v);
    }

    /**
     * Get only value of the data point.
     * @returns {string|number|GeoJsonDataType|null}
     */
    getRawValue(): string | number | GeoJsonDataType | null {
        return this.value.toRawValue();
    }

    /**
     * Get only the coerced value of the data point.
     * @returns {string|number|null}
     */
    getCoercedValue(): string | number | GeoJsonDataType | null {
        return this.value.toRawCoercedValue();
    }

    /**
     * Returns the data field of the point.
     * @public
     * @returns {DataPoint<'string'>}
     */
    getField(): DataPoint<'string'> {
        return DataPoint.fromRaw(this.field) as DataPoint<'string'>;
    }

    /**
     * Returns the data type of the point.
     * @public
     * @returns {string}
     */
    getType(): string {
        return this.value.type;
    }
}
