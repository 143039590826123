import * as React from 'react';
import SUITable from '@splunk/react-ui/Table';
import { TableRowCell } from '../hooks';

interface TextCellProps {
    cell: TableRowCell;
    useMouseOverEffect: boolean;
}
const noop = () => undefined;

export const TextCell = ({ cell, useMouseOverEffect }: TextCellProps): React.ReactElement => {
    const cellStyle = React.useMemo(() => ({ ...cell.style }), [cell.style]);
    return (
        <SUITable.Cell
            style={cellStyle}
            align={cell.align}
            data-test-cell-type="TextCell"
            data-row-index={cell.rowIndex}
            data-col-index={cell.columnIndex}
            onClick={useMouseOverEffect ? noop : undefined}
        >
            {cell.value.toString()}
        </SUITable.Cell>
    );
};

TextCell.displayName = 'TextCell';
