// Stolen from @splunk/react-docs because it is not exported
import React, { useContext, useCallback, useMemo } from 'react';
import useSplunkTheme from '@splunk/themes/useSplunkTheme';
import { AnimationToggleContext } from '@splunk/react-ui/AnimationToggle';
import RadioList from '@splunk/react-ui/RadioList';
import Heading from '@splunk/react-ui/Heading';
import styled from 'styled-components';
import { pick, variables } from '@splunk/themes';
import Button from '@splunk/react-ui/Button';
import Dropdown from '@splunk/react-ui/Dropdown';

const StyledSectionWrapper = styled.div`
    padding: 12px 20px;
    width: 220px;

    & + & {
        border-top: 1px solid
            ${pick({
                enterprise: variables.borderColor,
                prisma: variables.neutral200,
            })};
    }
`;

const StyledButton = styled(Button)`
    text-transform: capitalize;
`;

type Setter = (value: string) => void;

interface ThemeMenuProps {
    onToggleAnimation?: (value: boolean) => void;
    onChangeThemeFamily?: Setter;
    onChangeColorScheme?: Setter;
    onChangeDensity?: Setter;
}

export const ThemeMenu = ({
    onToggleAnimation,
    onChangeThemeFamily,
    onChangeColorScheme,
    onChangeDensity,
}: ThemeMenuProps) => {
    const animationToggleContextEnabled = useContext(AnimationToggleContext);
    const { colorScheme, density, family } = useSplunkTheme();

    const handleFamilyChange = useCallback(
        (_e, { value }) => onChangeThemeFamily?.(value),
        [onChangeThemeFamily]
    );

    const handleColorSchemeChange = useCallback(
        (_e, { value }) => onChangeColorScheme?.(value),
        [onChangeColorScheme]
    );

    const handleDensityChange = useCallback(
        (_e, { value }) => onChangeDensity?.(value),
        [onChangeDensity]
    );

    const handleAnimationChange = useCallback(
        (_e, { value }) => onToggleAnimation?.(value),
        [onToggleAnimation]
    );

    const Toggle = useMemo(
        () => (
            <StyledButton
                isMenu
                label={`${family} ${colorScheme} ${density}`}
            />
        ),
        [family, colorScheme, density]
    );

    return (
        <Dropdown toggle={Toggle}>
            {onChangeThemeFamily && (
                <StyledSectionWrapper>
                    <Heading level="ss">Theme Family</Heading>
                    <RadioList onChange={handleFamilyChange} value={family}>
                        <RadioList.Option value="enterprise">
                            Enterprise
                        </RadioList.Option>
                        <RadioList.Option value="prisma">
                            Prisma
                        </RadioList.Option>
                    </RadioList>
                </StyledSectionWrapper>
            )}
            {onChangeColorScheme && (
                <StyledSectionWrapper>
                    <Heading level="ss">Color Scheme</Heading>
                    <RadioList
                        onChange={handleColorSchemeChange}
                        value={colorScheme}
                    >
                        <RadioList.Option value="light">Light</RadioList.Option>
                        <RadioList.Option value="dark">Dark</RadioList.Option>
                    </RadioList>
                </StyledSectionWrapper>
            )}
            {onChangeDensity && (
                <StyledSectionWrapper>
                    <Heading level="ss">Density</Heading>
                    <RadioList onChange={handleDensityChange} value={density}>
                        <RadioList.Option value="comfortable">
                            Comfortable
                        </RadioList.Option>
                        <RadioList.Option value="compact">
                            Compact
                        </RadioList.Option>
                    </RadioList>
                </StyledSectionWrapper>
            )}
            {onToggleAnimation && (
                <StyledSectionWrapper>
                    <Heading level="ss">Animation</Heading>
                    <RadioList
                        onChange={handleAnimationChange}
                        value={animationToggleContextEnabled}
                    >
                        <RadioList.Option value>Enabled</RadioList.Option>
                        <RadioList.Option value={false}>
                            Disabled
                        </RadioList.Option>
                    </RadioList>
                </StyledSectionWrapper>
            )}
        </Dropdown>
    );
};
