/**
 * Add a property to the Item class to inform that the item should display ONLY these resize handle directions
 * @param {LayoutItem} Item The item to enhance
 * @param {Array<'n' | 'ne' | 'e' | 'se' | 's' | 'sw' | 'w' | 'nw'>} handleDirections Array containing the directions in which the item can be resized
 */
export default (Item, handleDirections) => {
    Item.handleDirections = handleDirections;

    return Item;
};
