import * as React from 'react';

// Global provided via webpack
// eslint-disable-next-line no-underscore-dangle
declare const __VISION_VERSION__: string;

/**
 * ianaTimezone can be used to specify a timezone corresponding to one of the entries in the IANA time zone database (e.g. America/Los_Angeles)
 *
 * serializedTimezone is Splunk's serialized timezone format and is usually present in enterprise via window.$C.SERVER_ZONEINFO
 * serialized time format examples can be found here https://git.splunk.com/projects/UI/repos/splunk-ui/browse/packages/moment/src/test/timezones
 *
 * utcOffset can be used to specify the desired display timezone via its offset from UTC time in minutes
 * Positive values are west, negative values are east of UTC, as in the ECMAScript getTimezoneOffset method
 */
export interface TimezoneContextInterface {
    ianaTimezone?: string;
    serializedTimezone?: string;
    utcOffset?: number;
}

const TimezoneContext = React.createContext<TimezoneContextInterface>({});
TimezoneContext.displayName = `data-splunk-visualization-version-TimezoneContext-${__VISION_VERSION__}`;

export const TimezoneContextProvider = TimezoneContext.Provider;
export const TimezoneContextConsumer = TimezoneContext.Consumer;
export default TimezoneContext;
