import type {
    EventCanHandle,
    EventHandle,
    SetTokenEvent,
} from '@splunk/dashboard-types';
import { DEFAULT_TOKEN_NAMESPACE } from '@splunk/dashboard-utils';

interface HandlePayload {
    tokens: Record<string, string>;
    tokenNamespace?: string;
    submit?: boolean;
}

/* eslint-disable class-methods-use-this */
class DefaultInputHandler {
    canHandle: EventCanHandle = ({ type }) => {
        return type === 'input.change';
    };

    handle: EventHandle<HandlePayload, SetTokenEvent[]> = (event) => {
        const { payload } = event;
        const {
            tokens,
            tokenNamespace = DEFAULT_TOKEN_NAMESPACE,
            submit = true,
        } = payload;
        return Promise.resolve([
            {
                type: 'setToken',
                payload: { tokens, namespace: tokenNamespace, submit },
            },
        ]);
    };
}

export default DefaultInputHandler;
