import * as React from 'react';

const NonePreset = (): React.ReactElement<SVGElement> => (
    <svg width="108" height="64" viewBox="0 0 108 64" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="0.5" y="0.5" width="107" height="63" rx="2.5" fill="#F2F4F5" />
        <path
            d="M69.576 27.1135C68.63 27.1135 67.8807 26.7994 67.3282 26.1713C66.7757 25.5431 66.4995 24.6349 66.4995 23.4467C66.4995 22.6142 66.6471 21.8763 66.9423 21.233C67.2374 20.5897 67.6802 20.0864 68.2705 19.7231C68.8608 19.3523 69.6025 19.1668 70.4956 19.1668C70.9345 19.1668 71.3054 19.2122 71.6081 19.3031V20.5291H70.8361C69.9204 20.5291 69.2468 20.7259 68.8154 21.1194C68.384 21.513 68.1343 22.0731 68.0661 22.7996C68.2705 22.512 68.5392 22.2963 68.8722 22.1525C69.2052 22.0087 69.5495 21.9368 69.9052 21.9368C70.4047 21.9368 70.8399 22.039 71.2108 22.2433C71.5816 22.4477 71.8692 22.7353 72.0735 23.1061C72.2779 23.477 72.3801 23.9084 72.3801 24.4003C72.3801 24.9149 72.2703 25.3804 72.0508 25.7966C71.8314 26.2053 71.5097 26.527 71.0859 26.7616C70.6696 26.9962 70.1663 27.1135 69.576 27.1135ZM69.5306 25.7626C69.9317 25.7626 70.242 25.6453 70.4615 25.4107C70.6885 25.1685 70.8021 24.8506 70.8021 24.4571C70.8021 24.0786 70.6885 23.7721 70.4615 23.5375C70.2345 23.3029 69.9242 23.1856 69.5306 23.1856C69.1371 23.1856 68.8154 23.3029 68.5656 23.5375C68.3235 23.7721 68.2024 24.09 68.2024 24.4911C68.2024 24.862 68.3235 25.1685 68.5656 25.4107C68.8154 25.6453 69.1371 25.7626 69.5306 25.7626ZM76.576 27H75.0094V26.6708C75.0094 26.1032 75.0359 25.5961 75.0888 25.1496C75.1494 24.6955 75.244 24.2679 75.3727 23.8667C75.5013 23.458 75.6754 23.0494 75.8949 22.6407C76.1144 22.2244 76.3868 21.7741 76.7122 21.2897L77.155 20.6426H73.4882V19.2804H78.8806V20.654L78.1086 21.7892C77.8134 22.2206 77.5675 22.6255 77.3707 23.0039C77.1739 23.3748 77.015 23.7456 76.8939 24.1165C76.7804 24.4873 76.6971 24.8771 76.6441 25.2858C76.5987 25.6945 76.576 26.1561 76.576 26.6708V27Z"
            fill="#C4CBD3"
        />
        <rect x="8" y="33" width="92" height="15" fill="#C4CBD3" fillOpacity="0.3" />
        <rect x="8" y="33" width="65" height="15" fill="#C4CBD3" />
        <rect x="0.5" y="0.5" width="107" height="63" rx="2.5" stroke="#C3CBD4" />
    </svg>
);

const GaugePreset = (): React.ReactElement<SVGElement> => (
    <svg width="108" height="64" viewBox="0 0 108 64" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="0.5" y="0.5" width="107" height="63" rx="2.5" fill="#F2F4F5" />
        <path
            d="M69.576 27.1135C68.63 27.1135 67.8807 26.7994 67.3282 26.1713C66.7757 25.5431 66.4995 24.6349 66.4995 23.4467C66.4995 22.6142 66.6471 21.8763 66.9423 21.233C67.2374 20.5897 67.6802 20.0864 68.2705 19.7231C68.8608 19.3523 69.6025 19.1668 70.4956 19.1668C70.9345 19.1668 71.3054 19.2122 71.6081 19.3031V20.5291H70.8361C69.9204 20.5291 69.2468 20.7259 68.8154 21.1194C68.384 21.513 68.1343 22.0731 68.0661 22.7996C68.2705 22.512 68.5392 22.2963 68.8722 22.1525C69.2052 22.0087 69.5495 21.9368 69.9052 21.9368C70.4047 21.9368 70.8399 22.039 71.2108 22.2433C71.5816 22.4477 71.8692 22.7353 72.0735 23.1061C72.2779 23.477 72.3801 23.9084 72.3801 24.4003C72.3801 24.9149 72.2703 25.3804 72.0508 25.7966C71.8314 26.2053 71.5097 26.527 71.0859 26.7616C70.6696 26.9962 70.1663 27.1135 69.576 27.1135ZM69.5306 25.7626C69.9317 25.7626 70.242 25.6453 70.4615 25.4107C70.6885 25.1685 70.8021 24.8506 70.8021 24.4571C70.8021 24.0786 70.6885 23.7721 70.4615 23.5375C70.2345 23.3029 69.9242 23.1856 69.5306 23.1856C69.1371 23.1856 68.8154 23.3029 68.5656 23.5375C68.3235 23.7721 68.2024 24.09 68.2024 24.4911C68.2024 24.862 68.3235 25.1685 68.5656 25.4107C68.8154 25.6453 69.1371 25.7626 69.5306 25.7626ZM76.576 27H75.0094V26.6708C75.0094 26.1032 75.0359 25.5961 75.0888 25.1496C75.1494 24.6955 75.244 24.2679 75.3727 23.8667C75.5013 23.458 75.6754 23.0494 75.8949 22.6407C76.1144 22.2244 76.3868 21.7741 76.7122 21.2897L77.155 20.6426H73.4882V19.2804H78.8806V20.654L78.1086 21.7892C77.8134 22.2206 77.5675 22.6255 77.3707 23.0039C77.1739 23.3748 77.015 23.7456 76.8939 24.1165C76.7804 24.4873 76.6971 24.8771 76.6441 25.2858C76.5987 25.6945 76.576 26.1561 76.576 26.6708V27Z"
            fill="#C4CBD3"
        />
        <rect x="8" y="33" width="92" height="15" fill="#C4CBD3" fillOpacity="0.3" />
        <rect x="8" y="33" width="65" height="15" fill="#00A4FD" />
        <rect x="0.5" y="0.5" width="107" height="63" rx="2.5" stroke="#C3CBD4" />
    </svg>
);

export default {
    'fillerGauge.none': NonePreset,
    'fillerGauge.gauge': GaugePreset,
};
