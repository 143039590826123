import React from 'react';
import T from 'prop-types';
import styled from 'styled-components';
import { DEFAULT_FONT_FAMILY } from '../common/utils/singleValueUtils';
import TrendValue from './TrendValue';
import MajorValue from './MajorValue';

const Column = styled.div`
    display: flex;
    flex-direction: column;
    align-self: center;
    justify-content: center;
`;

const Row = styled.div`
    display: flex;
    flex-direction: row;
    align-items: ${props => props.align || 'center'};
    justify-content: center;
`;

const Text = styled.div`
    font-weight: normal;
    white-space: nowrap;
    font-size: ${props => props.size || 12}px;
    color: ${props => props.color || '#000'};
    font-family: ${DEFAULT_FONT_FAMILY};
    height: ${props => props.size || 12}px;
    line-height: 1.2;
`;

const SingleValueBasic = ({ ...props }) => {
    const {
        majorColor,
        majorTextFontSize,
        majorText,
        majorValueTitle,
        trendColor,
        trendTextFontSize,
        trendText,
        showTrendIndicator,
        unit,
        unitPosition,
        underLabel,
        underLabelFontSize,
        underLabelColor,
        onValueClick,
    } = props;
    const trendIndicatorEnabled = trendText !== '' && showTrendIndicator;

    return (
        <Column>
            <Row align="baseline">
                <MajorValue
                    data-test="MajorValue"
                    color={majorColor}
                    fontSize={majorTextFontSize}
                    unit={unit}
                    unitPosition={unitPosition}
                    value={majorText}
                    title={majorValueTitle}
                    onValueClick={onValueClick}
                />
                {trendIndicatorEnabled && (
                    // Passing the same value for value and text is a temporary hold until completion of https://jira.splunk.com/browse/SCP-21371
                    <TrendValue
                        data-test="TrendIndicator"
                        fontSize={trendTextFontSize}
                        color={trendColor}
                        value={trendText}
                        text={trendText}
                    />
                )}
            </Row>
            {underLabel && (
                <Row justify="center">
                    <Text data-test="underLabel" size={underLabelFontSize} color={underLabelColor}>
                        {underLabel}
                    </Text>
                </Row>
            )}
        </Column>
    );
};

SingleValueBasic.propTypes = {
    majorText: T.string,
    majorColor: T.string,
    majorTextFontSize: T.number,
    majorValueTitle: T.string,
    trendText: T.string,
    trendTextFontSize: T.number,
    trendColor: T.string,
    showTrendIndicator: T.bool,
    unit: T.string,
    unitPosition: T.oneOf(['before', 'after']),
    underLabel: T.string,
    underLabelFontSize: T.number,
    underLabelColor: T.string,
    onValueClick: T.oneOfType([T.bool, T.func]),
};

export const SingleValueTrendValue = TrendValue;
export default SingleValueBasic;
