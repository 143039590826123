import { _ } from '@splunk/ui-utils/i18n';
import BarBeside from '@splunk/react-icons/enterprise/BarBeside';
import BarStacked from '@splunk/react-icons/enterprise/BarStacked';
import BarStacked100 from '@splunk/react-icons/enterprise/BarStacked100';
import AxesConfig from '../../common/editorConfig/Axes';
import LegendConfig from '../../common/editorConfig/Legend';

const editorConfig = [
    {
        label: _('General'),
        layout: [
            [
                {
                    label: _('Stack Mode'),
                    option: 'chart.stackMode',
                    editor: 'editor.radioBar',
                    editorProps: {
                        values: [
                            { icon: BarBeside, label: _('Not stacked'), value: 'default' },
                            { icon: BarStacked, label: _('Stacked'), value: 'stacked' },
                            { icon: BarStacked100, label: _('Stacked 100%'), value: 'stacked100' },
                        ],
                    },
                },
            ],
            [
                {
                    label: _('Show Data Values'),
                    option: 'chart.showDataLabels',
                    editor: 'editor.radioBar',
                    editorProps: {
                        values: [
                            { label: _('Off'), value: 'none' },
                            { label: _('On'), value: 'all' },
                            { label: _('Min/Max'), value: 'minmax' },
                        ],
                    },
                },
            ],
        ],
    },
    AxesConfig,
    LegendConfig,
];

export default editorConfig;
