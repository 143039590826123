import React from 'react';
import { _ } from '@splunk/ui-utils/i18n';
import SVG, {
    type SVGPropsOptionalViewBox,
} from '@splunk/react-icons/SVGEnterprise';

/* eslint-disable max-len */
export default function Search(
    props: SVGPropsOptionalViewBox
): ReturnType<typeof SVG> {
    return (
        <SVG screenReaderText={_('Search')} viewBox="0 0 24 24" {...props}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M16 10.5015C16 13.539 13.5376 16.0015 10.5 16.0015C7.46243 16.0015 5 13.539 5 10.5015C5 7.4639 7.46243 5.00146 10.5 5.00146C13.5376 5.00146 16 7.4639 16 10.5015ZM15.0509 16.4635C13.7888 17.4283 12.2113 18.0015 10.5 18.0015C6.35786 18.0015 3 14.6436 3 10.5015C3 6.35933 6.35786 3.00146 10.5 3.00146C14.6421 3.00146 18 6.35933 18 10.5015C18 12.2112 17.4279 13.7873 16.4647 15.0489L20.7077 19.292C21.0983 19.6825 21.0983 20.3156 20.7077 20.7062C20.3172 21.0967 19.6841 21.0967 19.2935 20.7062L15.0509 16.4635Z"
                fill="currentColor"
            />
        </SVG>
    );
}
