import memoizeOne from 'memoize-one';
import { console } from '@splunk/dashboard-utils';
import type { SearchMetricsCollector } from '@splunk/dashboard-telemetry';
import type {
    DataSourceMeta,
    JSONCols,
    LifecycleEvent,
    LifecycleEventPayload,
    CancellableLifecycleEvent,
    DashboardPlugin,
    DashboardPluginWrapper as DashboardPluginWrapperType,
    PluginEventHandler,
} from '@splunk/dashboard-types';
import {
    DashboardLifecycleEvent,
    CancellableDashboardLifecycleEvent,
} from '../events';

const lifecycleCallbacks: Record<string, { deprecated?: boolean }> = {
    onInitialize: {},
    onEventTrigger: {},
    onLinkToUrl: {},
};

type DataSourceLifecycleEvent = LifecycleEvent & {
    targetId: string;
    type: string;
    payload: { data?: JSONCols; meta?: DataSourceMeta; consumerId?: string };
};

const isDataSourceEvent = (
    event: LifecycleEvent
): event is DataSourceLifecycleEvent => /^datasource\./.test(`${event.type}`);

type EventGenerator<T> = () => T;
type MutablePlugin = React.MutableRefObject<DashboardPlugin>;
/**
 * Capture errors when executing a dashboard plugin callback handler
 * @param {DashboardPlugin} plugin
 * @param {string} name callback name
 * @param {EventGenerator} eventGen creates a lifecycle event
 * @returns {ReturnType<EventGenerator> | null} The event
 */
function invokePluginCallbackWithEvent<
    T extends LifecycleEvent | CancellableLifecycleEvent
>(
    plugin: DashboardPlugin,
    name: string,
    eventGen: EventGenerator<T>
): T | null {
    const fn = plugin[name];

    if (typeof fn === 'function') {
        const event = eventGen();

        try {
            fn(event);
        } catch (e) {
            console.error(
                `Caught error while invoking lifecycle plugin callback ${name}`,
                e
            );
            event.error = e;
        }

        return event;
    }

    return null;
}

/**
 * Throws or emits warnings if a plugin is malformed
 * @param {DashboardPlugin} plugin
 * @returns {DashboardPlugin} the input
 */
export function validateDashboardPlugin(plugin: DashboardPlugin) {
    if (!plugin || typeof plugin !== 'object') {
        throw new Error(
            'Invalid lifecycle plugin provided - should be an object'
        );
    }

    Object.keys(plugin).forEach((key) => {
        if (!(key in lifecycleCallbacks)) {
            if (typeof plugin[key] === 'function') {
                console.warn(
                    `Unknown callback function "${key}" in lifecycle plugin`
                );
            }
        } else if (lifecycleCallbacks[key].deprecated) {
            console.warn(
                `Lifecycle plugin callback function "${key}" is DEPRECATED.`
            );
        }
    });

    return plugin;
}

type InitializeEvent = LifecycleEvent & { dataSourceCount?: number };

export const createOnInitialize = memoizeOne(
    (
            plugin: MutablePlugin,
            searchMetricsCollector: SearchMetricsCollector
        ): PluginEventHandler =>
        (payload: InitializeEvent) => {
            if (
                typeof payload.dataSourceCount === 'number' &&
                payload.dataSourceCount === 0
            ) {
                searchMetricsCollector.forceComplete();
            }

            plugin.current.onInitialize?.(payload);
        }
);

export const createOnEventTrigger = memoizeOne(
    (
        plugin: MutablePlugin,
        searchMetricsCollector: SearchMetricsCollector
    ): PluginEventHandler => {
        const searchJobMap: Record<string, number> = {};
        let prevType: string;
        let timeToFirstResult: number | null;

        return (event) => {
            if (isDataSourceEvent(event)) {
                const { targetId, type, payload } = event;

                switch (type) {
                    case 'datasource.setup': {
                        // Setup fires for every datasource that runs a search job, so save the timestamp.
                        searchJobMap[targetId] = performance.now();
                        searchMetricsCollector.addInFlightDataSource(targetId);
                        prevType = type;
                        break;
                    }
                    case 'datasource.progress': {
                        // Progress events can fire multiple times for a search job.
                        if (prevType === type) {
                            break;
                        }

                        const startTime = searchJobMap[targetId];
                        if (startTime) {
                            // We have a start time, compute the duration and log that.
                            if (
                                payload.data &&
                                // We're assuming search progressed when some results are returned.
                                // Caveat: If search runs but returns no results, we may not log its progress.
                                (payload.data?.fields?.length ||
                                    payload.data?.columns?.length)
                            ) {
                                timeToFirstResult =
                                    performance.now() - startTime;
                            }
                        } else {
                            // We don't have a start time so prev search is done and new one started, so save the new timestamp.
                            searchJobMap[targetId] = performance.now();
                        }

                        prevType = type;
                        break;
                    }
                    case 'datasource.done':
                    case 'datasource.error': {
                        const startTime = searchJobMap[targetId];
                        delete searchJobMap[targetId];

                        const duration = performance.now() - startTime;
                        searchMetricsCollector.collect({
                            status: type,
                            duration,
                            ttfr: timeToFirstResult || duration, // Time To First Result
                            consumerId: payload.consumerId,
                            sid: payload?.meta?.sid,
                            dataSourceId: targetId,
                        });

                        prevType = type;
                        timeToFirstResult = null;

                        break;
                    }
                    default:
                        break;
                }
            }

            plugin.current.onEventTrigger?.(event);
        };
    }
);

/**
 * A module to wrap user provided plugin object
 */
export class DashboardPluginWrapper implements DashboardPluginWrapperType {
    private plugin: MutablePlugin;

    private onEventTrigger: PluginEventHandler;

    private onInitialize: PluginEventHandler;

    constructor({
        plugin,
        collector,
    }: {
        plugin: MutablePlugin;
        collector: SearchMetricsCollector;
    }) {
        this.plugin = plugin;
        this.onEventTrigger = createOnEventTrigger(plugin, collector);
        this.onInitialize = createOnInitialize(plugin, collector);
    }

    invokePluginCallback(name: string, payload: LifecycleEventPayload) {
        invokePluginCallbackWithEvent(
            {
                ...this.plugin.current,
                onEventTrigger: this.onEventTrigger,
                onInitialize: this.onInitialize,
            },
            name,
            () => new DashboardLifecycleEvent(payload)
        );
    }

    invokeCancellablePluginCallback(
        name: string,
        payload: LifecycleEventPayload
    ) {
        const event = invokePluginCallbackWithEvent(
            {
                ...this.plugin.current,
                onEventTrigger: this.onEventTrigger,
                onInitialize: this.onInitialize,
            },
            name,
            () => new CancellableDashboardLifecycleEvent(payload)
        );

        return event?.isCancelled() ?? false;
    }
}

/**
 * DashboardPlugin Factory function, create a wrapped dashboard plugin
 * @param {MutableRefObject<DashboardPlugin>} plugin
 * @param {SearchMetricsCollector} searchMetricsCollector
 */
export const createDashboardPlugin = (
    plugin: MutablePlugin,
    searchMetricsCollector: SearchMetricsCollector
) => {
    validateDashboardPlugin(plugin.current);
    return new DashboardPluginWrapper({
        plugin,
        collector: searchMetricsCollector,
    });
};
