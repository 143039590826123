import { DashboardVizProps } from '../interfaces/DashboardVizProps';

export type DashboardVizType = React.ComponentType<DashboardVizProps>;

/**
 * @method extendStaticWrapperProps
 * @param {Object} props
 * @param {DashboardVizType} props.Wrapper
 * @param {DashboardVizType} props.Visualization
 */
export const extendStaticWrapperProps = ({
    Wrapper,
    Visualization,
}: {
    [key: string]: DashboardVizType;
}): void => {
    const extendProperties = ['propTypes', 'defaultProps', 'config'];
    extendProperties.forEach((prop: string): void => {
        if (Visualization[prop]) {
            // eslint-disable-next-line no-param-reassign
            Wrapper[prop] = Visualization[prop];
        }
    });
};
