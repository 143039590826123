import { pick, noop, chain } from 'lodash';
import * as React from 'react';
import { _ } from '@splunk/ui-utils/i18n';
import { toDimension } from '@splunk/visualizations-shared/style';
import RemoteImage, { isRemoteImage } from '@splunk/visualizations-shared/RemoteImage';
import styled from 'styled-components';
import * as T from 'prop-types';
import config from './config';
import { DataSource } from '../common/interfaces/DataSource';
import withDashboardViz from '../common/withDashboardViz';
import ImageApi from './ImageApi';

const { useEffect, useRef } = React;
const validOptions = Object.keys(config.optionsSchema);

const Container = styled.div.attrs(() => ({
    'data-test': 'image-container',
}))<{
    height: string | number;
    width: string | number;
}>`
    position: relative;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: ${props => (props.onClick ? 'pointer' : 'inherit')};
    ${props => toDimension(pick(props, ['width', 'height']))};
`;

type useIconPlaceholder = (
    dataSources: { [name: string]: DataSource },
    loading: boolean,
    options: Record<string, unknown>
) => boolean;

export const shouldShowPlaceholder: useIconPlaceholder = (dataSources, loading, options): boolean => {
    if (options.src) {
        return false;
    }
    return true;
};

export const toImageDimensions = props => {
    if (props.shouldPreserveAspectRatio) {
        return `
            max-width: 100%;
            max-height: 100%;
            width: auto;
            height: auto;
        `;
    }
    return toDimension(pick(props, ['width', 'height']));
};

const StyledImage = styled.img<{
    shouldPreserveAspectRatio: boolean;
}>`
    display: block;
    ${props => toImageDimensions(props)};
`;

interface ImageProps {
    // width in pixel or string, defaults to 100%
    width: string | number;
    // height in pixel or string
    height: string | number;
    options?: Record<string, any>;
    title?: string;
    visualizationApiRef?: (...args: any[]) => void;
    onImageClick: (...args: any[]) => void;
}

type computeVizPropsFn = (options: Record<string, any>) => Partial<ImageProps>;
const computeVizProps: computeVizPropsFn = ({
    visualizationApiRef,
    title,
    ...otherOptions
}): Partial<ImageProps> => {
    const options = chain(otherOptions).pick(validOptions).value();
    return { options, title, visualizationApiRef };
};

const ImageVisualization = ({
    height,
    width,
    title = '',
    visualizationApiRef,
    options,
    onImageClick,
}: ImageProps): React.ReactElement => {
    const { src, preserveAspectRatio } = options;
    const remoteImage = useRef<HTMLImageElement>(null);
    const api = useRef(new ImageApi({ options: { src, preserveAspectRatio }, remoteImage }));
    const isRemote = isRemoteImage(src);

    useEffect(() => {
        visualizationApiRef(api.current);
        return () => {
            visualizationApiRef(null);
        };
    }, []);

    return (
        <Container width={width} height={height} onClick={onImageClick}>
            {isRemote ? (
                <RemoteImage
                    imageURL={src}
                    title={title}
                    preserveAspectRatio={preserveAspectRatio}
                    width={width}
                    height={height}
                    ref={remoteImage}
                />
            ) : (
                <StyledImage
                    data-test="image"
                    src={src}
                    shouldPreserveAspectRatio={preserveAspectRatio}
                    width={width}
                    height={height}
                    alt={_(title)}
                    onError={(e: React.SyntheticEvent<HTMLImageElement, Event>) => {
                        (e.target as HTMLImageElement).alt = _('Image not found');
                    }}
                    referrerPolicy="no-referrer"
                />
            )}
        </Container>
    );
};

const propTypes: Record<keyof ImageProps, T.Validator<any>> = {
    width: T.oneOfType([T.string, T.number]),
    height: T.oneOfType([T.string, T.number]),
    options: T.object,
    title: T.string,
    visualizationApiRef: T.func,
    onImageClick: T.func,
};

const defaultProps: Record<keyof ImageProps, any> = {
    width: '100%',
    height: undefined,
    options: {},
    title: '',
    visualizationApiRef: noop,
    onImageClick: noop,
};

ImageVisualization.propTypes = propTypes;
ImageVisualization.defaultProps = defaultProps;

const Image = withDashboardViz({
    ReactViz: ImageVisualization as any,
    vizConfig: config,
    useIconPlaceholder: shouldShowPlaceholder,
    computeVizProps,
});
const themes = null;

export { themes, config };
export default Image;
