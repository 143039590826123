/**
 * @enum {BUBBLE_ROW_SCALE_TYPES}
 */
export const BUBBLE_ROW_SCALE_TYPES = {
    GLOBAL: 'global',
    ROW: 'row',
};

/**
 * @enum {BUBBLE_SCALE_TYPES}
 */
export const BUBBLE_SCALE_TYPES = {
    RADIUS: 'radius',
    AREA: 'area',
};

/**
 * @enum {COLOR_MODE_TYPES}
 */
export const COLOR_MODE_TYPES = {
    SEQUENTIAL: 'sequential',
    CATEGORICAL: 'categorical',
};

/**
 * @enum {BUBBLE_LABELS_TYPES}
 */
export const BUBBLE_LABELS_TYPES = {
    ALL: 'all',
    MAX: 'max',
    NONE: 'none',
};
