export enum DataSourceState {
    NONEXISTENT = 0,
    ERROR = 1,
    LOADING = 2,
    OK = 3,
}

/**
 * represents the data in a raw columnar format, resulting from a splunk
 * query request.
 */
export interface FieldEntry {
    name: string;
    type?: string;
    groupby_rank?: string; // eslint-disable-line
    splitby_field?: string; // eslint-disable-line
    splitby_value?: string; // eslint-disable-line
    data_source?: string; // eslint-disable-line
}

const getDataSourceState = (dataSource): DataSourceState => {
    if (!dataSource) {
        return DataSourceState.NONEXISTENT;
    }
    const { error, data } = dataSource;
    if (dataSource.error) {
        return DataSourceState.ERROR;
    }
    if (error == null && data == null) {
        return DataSourceState.LOADING;
    }
    return DataSourceState.OK;
};
/**
 * A utility fn to return data source state info as an object of boolean flags
 * @param dataSource
 */
export const getDataSourceStateInfo = (
    dataSource
): { hasError: boolean; isMissing: boolean; isLoading: boolean } => {
    const dataSourceState: DataSourceState = getDataSourceState(dataSource);
    return {
        hasError: dataSourceState === DataSourceState.ERROR,
        isMissing: dataSourceState === DataSourceState.NONEXISTENT,
        isLoading: dataSourceState === DataSourceState.LOADING,
    };
};
