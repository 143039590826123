import type {
    GlobalState,
    NullableSmartSourceTokenState,
    SmartSourceSlice,
    ValidSmartSource,
} from '@splunk/dashboard-types';
import type { Draft, PayloadAction } from '@reduxjs/toolkit';

import { createSelector, createSlice } from '@reduxjs/toolkit';
import isEqual from 'lodash/isEqual';
import omit from 'lodash/omit';
import { isNullOrUndefined } from '../utils/isNullOrUndefined';
import { noTokens } from '../utils/token';
import { selectReadOnlyTokenNamespaces } from './readOnlyTokenNamespaces';
import resetStore from './resetStore';

export interface SetPayload {
    tokens: NullableSmartSourceTokenState;
    source: ValidSmartSource;
}

/**
 * Selectors
 */
export const selectSmartSources = (state: GlobalState): SmartSourceSlice =>
    state?.smartSources || noTokens;

export const selectDSTokens = createSelector(
    [selectSmartSources, selectReadOnlyTokenNamespaces],
    (sources, readOnlyTokenNamespaces) =>
        omit(sources?.ds || noTokens, readOnlyTokenNamespaces)
);

/**
 * Shared logic for the set reducer to run on a single payload, or for each payload in an array
 * @param state Immer writable draft of the applicable Redux state property
 * @param payload SetPayload object to be processed
 */
const handleSet = (
    state: Draft<SmartSourceSlice>,
    payload: SetPayload
): void => {
    const { tokens: payloadTokens, source: payloadSource } = payload;
    const source = payloadSource as keyof SmartSourceSlice;

    // Ensure the token source collection exists in the state
    state[source] ??= {};
    const sourceCollection = { ...state[source] };

    // Handle insert/update/deletion of tokens from the collection
    Object.entries(payloadTokens).forEach(([namespace, tokens]) => {
        // Namespace <--> undefined mapping indicates unset all tokens in namespace
        if (isNullOrUndefined(tokens)) {
            delete sourceCollection[namespace];
            return;
        }

        // Ensure a collection exists for the namespace
        sourceCollection[namespace] ??= {};
        Object.entries(tokens).forEach(([tokenName, tokenValue]) => {
            if (isNullOrUndefined(tokenValue)) {
                // Unset null/undefined token values
                delete sourceCollection[namespace][tokenName];
            } else {
                // Set/update defined token values
                sourceCollection[namespace][tokenName] = tokenValue;
            }
        });

        // Don't keep empty namespaces in the store
        if (!Object.keys(sourceCollection[namespace]).length) {
            delete sourceCollection[namespace];
        }
    });

    if (!isEqual(sourceCollection, state[source])) {
        state[source] = sourceCollection;
    }
};

/**
 * Initial state.smartSources value
 */
const initialState: SmartSourceSlice = {
    ds: {},
};

/**
 * smartSources slice (action(s)/reducer(s))
 */
const smartSourceSlice = createSlice({
    name: 'smartSources',
    initialState,
    extraReducers(builder) {
        builder.addCase(resetStore, (state, { payload }): void => {
            const { smartSources } = payload ?? {};
            if (state && smartSources) {
                Object.assign(state, smartSources);
            }
        });
    },
    reducers: {
        set: (state, action: PayloadAction<SetPayload | SetPayload[]>) => {
            if (Array.isArray(action.payload)) {
                action.payload.forEach((payload) => handleSet(state, payload));
            } else {
                handleSet(state, action.payload);
            }
        },
    },
});

export const { set: setSmartSources } = smartSourceSlice.actions;
export default smartSourceSlice.reducer;
