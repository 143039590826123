import * as React from 'react';
import { chain, noop } from 'lodash';
import styled from 'styled-components';
import * as T from 'prop-types';
import config from './config';
import withDashboardViz from '../common/withDashboardViz';
import { mapToOldKey, getStyledShape } from '../common/shapeUtils';

const validOptions = Object.keys(config.optionsSchema);

const SVGContainer = styled.svg<{
    height: string | number;
    width: string | number;
}>`
    width: ${props => props.width}px;
    height: ${props => props.height}px;
`;

const EllipseShape = getStyledShape('ellipse');
interface EllipseProps {
    // width in pixel or string, defaults to 100%
    width: string | number;
    // height in pixel or string
    height: string | number;
    options?: Record<string, any>;
    onEllipseClick: (...args: any[]) => void;
}

const EllipseVisualization = ({
    height,
    width,
    onEllipseClick,
    options,
}: EllipseProps): React.ReactElement => {
    return (
        <SVGContainer width={width} height={height}>
            <EllipseShape
                cx="50%"
                cy="50%"
                rx={`${Number(width) / 2 - options.strokeWidth / 2}px`}
                ry={`${Number(height) / 2 - options.strokeWidth / 2}px`}
                {...options}
                vectorEffect="non-scaling-stroke"
                onClick={onEllipseClick}
            />
        </SVGContainer>
    );
};

type computeVizPropsFn = (options: Record<string, any>) => Partial<EllipseProps>;
const computeVizProps: computeVizPropsFn = ({ width, height, ...options }): Partial<EllipseProps> => {
    const computedProps: Record<string, any> = { width, height };
    computedProps.options = chain(options).pick(validOptions).thru(mapToOldKey).value();
    return computedProps;
};

const propTypes: Record<keyof EllipseProps, T.Validator<any>> = {
    width: T.oneOfType([T.string, T.number]),
    height: T.oneOfType([T.string, T.number]),
    options: T.object,
    onEllipseClick: T.func,
};

const defaultProps: Record<keyof EllipseProps, any> = {
    height: 250,
    width: 350,
    options: {},
    onEllipseClick: noop,
};

EllipseVisualization.propTypes = propTypes;
EllipseVisualization.defaultProps = defaultProps;

const Ellipse = withDashboardViz({
    ReactViz: EllipseVisualization as any,
    computeVizProps,
    vizConfig: config,
});
const themes = null;

export { themes, config };
export default Ellipse;
