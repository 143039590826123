import type { VisualizationDefinition } from '@splunk/dashboard-types';
import type { EncodingConfig } from '../encoding';
import { encodingToDynamicOptionsDSL } from '../encoding';

/**
 *  map of splunk charting based @splunk/dashboard-visualization option names
 *  to updated @splunk/visualization option names
 */
const optionRenames = {
    'axisTitleX.text': 'xAxisTitleText',
    'axisTitleY.text': 'yAxisTitleText',
    'axisTitleY2.text': 'y2AxisTitleText',
    'axisTitleX.visibility': 'xAxisTitleVisibility',
    'axisTitleY.visibility': 'yAxisTitleVisibility',
    'axisTitleY2.visibility': 'y2AxisTitleVisibility',
    'axisLabelsX.majorLabelStyle.rotation': 'xAxisLabelRotation',
    'axisLabelsX.majorLabelVisibility': 'xAxisLabelVisibility',
    'axisLabelsY.majorLabelVisibility': 'yAxisLabelVisibility',
    'axisLabelsY2.majorLabelVisibility': 'y2AxisLabelVisibility',
    'axisLabelsX.majorTickVisibility': 'xAxisMajorTickVisibility',
    'axisLabelsY.majorTickVisibility': 'yAxisMajorTickVisibility',
    'axisLabelsY2.majorTickVisibility': 'y2AxisMajorTickVisibility',
    'axisLabelsX.minorTickVisibility': 'xAxisMinorTickVisibility',
    'axisLabelsY.minorTickVisibility': 'yAxisMinorTickVisibility',
    'axisLabelsY2.minorTickVisibility': 'y2AxisMinorTickVisibility',
    'axisLabelsX.majorTickSize': 'xAxisMajorTickSize',
    'axisLabelsY.majorTickSize': 'yAxisMajorTickSize',
    'axisLabelsY2.majorTickSize': 'y2AxisMajorTickSize',
    'axisLabelsX.minorTickSize': 'xAxisMinorTickSize',
    'axisLabelsY.minorTickSize': 'yAxisMinorTickSize',
    'axisLabelsY2.minorTickSize': 'y2AxisMinorTickSize',
    'axisLabelsX.majorUnit': 'xAxisMajorTickInterval',
    'axisLabelsY.majorUnit': 'yAxisMajorTickInterval',
    'axisLabelsY2.majorUnit': 'y2AxisMajorTickInterval',
    'axisX.abbreviation': 'xAxisAbbreviation',
    'axisY.abbreviation': 'yAxisAbbreviation',
    'axisY2.abbreviation': 'y2AxisAbbreviation',
    'axisLabelsX.integerUnits': 'showRoundedXAxisLabels',
    'axisLabelsY2.integerUnits': 'showRoundedY2AxisLabels',
    'axisLabelsX.maxLabelParts': 'xAxisMaxLabelParts',
    'axisLabelsX.axisVisibility': 'xAxisLineVisibility',
    'axisLabelsY.axisVisibility': 'yAxisLineVisibility',
    'axisLabelsY2.axisVisibility': 'y2AxisLineVisibility',
    'gridLinesX.showMajorLines': 'showXMajorGridLines',
    'gridLinesY.showMajorLines': 'showYMajorGridLines',
    'gridLinesX.showMinorLines': 'showXMinorGridLines',
    'gridLinesY.showMinorLines': 'showYMinorGridLines',
    'gridLinesY2.showMajorLines': 'showY2MajorGridLines',
    'gridLinesY2.showMinorLines': 'showY2MinorGridLines',
    'axisX.scale': 'xAxisScale',
    'axisY.scale': 'yAxisScale',
    'axisY2.scale': 'y2AxisScale',
    'axisX.maximumNumber': 'xAxisMax',
    'axisY.maximumNumber': 'yAxisMax',
    'axisY2.maximumNumber': 'y2AxisMax',
    'axisX.minimumNumber': 'xAxisMin',
    'axisY.minimumNumber': 'yAxisMin',
    'axisY2.minimumNumber': 'y2AxisMin',
    'axisX.includeZero': 'showXAxisWithZero',
    'axisY.includeZero': 'showYAxisWithZero',
    'axisY2.includeZero': 'showY2AxisWithZero',
    'axisLabelsX.extendsAxisRange': 'showXAxisExtendedRange',
    'axisLabelsY.extendsAxisRange': 'showYAxisExtendedRange',
    'layout.splitSeries': 'showSplitSeries',
    'layout.splitSeries.allowIndependentYRanges': 'showIndependentYRanges',
    'axisY2.enabled': 'showOverlayY2Axis',
    'axisY2.fields': 'y2Fields',
    'chart.overlayFields': 'overlayFields',
    'legend.labels': 'legendLabels',
    'legend.labelStyle.overflowMode': 'legendTruncation',
    'legend.mode': 'legendMode',
    'legend.placement': 'legendDisplay',
    'chart.nullValueMode': 'nullValueDisplay',
    'chart.showDataLabels': 'dataValuesDisplay',
    fieldColors: 'seriesColorsByField',
    'chart.resultTruncationLimit': 'resultLimit',
    'chart.stackMode': 'stackMode',
    'chart.barSpacing': 'barSpacing',
    'chart.columnSpacing': 'columnSpacing',
    'chart.seriesSpacing': 'seriesSpacing',
    'chart.sliceCollapsingLabel': 'collapseLabel',
    'chart.sliceCollapsingThreshold': 'collapseThreshold',
    hasDonutHole: 'showDonutHole',
    // area specific mapped options
    areaFillOpacity: 'areaOpacity',
    'chart.showLines': 'showLines',
    // bubble specific mapped options
    'chart.bubbleMinimumSize': 'bubbleSizeMin',
    'chart.bubbleMaximumSize': 'bubbleSizeMax',
    'chart.bubbleSizeBy': 'bubbleSizeMethod',
    // line specific mapped options
    fieldDashStyles: 'lineDashStylesByField',
    'chart.showMarkers': 'markerDisplay',
    // scatter-specific options
    'chart.markerSize': 'markerSize',
};

const visibilityEnumReplacements = {
    visible: 'show',
    collapsed: 'hide',
};
const modeEnumReplacements = {
    none: 'off',
};
const abbreviationEnumReplacements = {
    none: 'auto',
};
/**
 * for some options the valid values changed.
 * this maps enum values that need to be changed
 */
const enumReplacements = {
    xAxisTitleVisibility: visibilityEnumReplacements,
    yAxisTitleVisibility: visibilityEnumReplacements,
    y2AxisTitleVisibility: visibilityEnumReplacements,
    xAxisAbbreviation: modeEnumReplacements,
    yAxisAbbreviation: abbreviationEnumReplacements,
    y2AxisAbbreviation: abbreviationEnumReplacements,
    dataValuesDisplay: modeEnumReplacements,
    legendDisplay: modeEnumReplacements,
    legendTruncation: {
        ellipsisNone: 'ellipsisOff',
    },
    stackMode: {
        default: 'auto',
    },
};

// splits a string with multiple fields separated by comma
// returns the same value if it's already an array
const splitValue = (oldValue: string | string[]) =>
    Array.isArray(oldValue) ? oldValue : oldValue.split(',');

/**
 * converts stringified objects into plain objects
 *
 * @param {string | Object} oldValue stringified object to parse
 * @returns {Object}
 */
const parseObjects = (oldValue: string | Record<string, unknown>): Record<string, unknown> => {
    if (typeof oldValue === 'object' && !Array.isArray(oldValue)) {
        return oldValue;
    }
    let nuValue = {};
    try {
        nuValue = JSON.parse(oldValue as string);
    } catch (e) {
        /* noop */
    }
    return nuValue;
};

/**
 * some option values require special processing
 * (e.g. if the type of the updated option changed)
 * "legendLabels": "test,test2" => "legendLabels": ["test", "test2"]
 */
const otherValueReplacements = {
    y2Fields: splitValue,
    overlayFields: splitValue,
    legendLabels: splitValue,
    seriesColorsByField: parseObjects,
    lineDashStylesByField: parseObjects,
    /**
     * the original markerDisplay was only exposed as `chart.showMarkers`
     * setting chart.showMarkers to true is the equivalent of `markerDisplay: 'outlined'`
     */
    markerDisplay: (oldValue: boolean) => {
        if (oldValue === false) return 'off';
        if (oldValue === true) return 'outlined';
        return oldValue;
    },
};

/**
 * migrateChartingOptions
 * - renames option keys
 * - checks if enum values have changed for new options, replaces it
 * - checks if other replacements are necessary and applies them
 * @param {object} options dashboard-viz charting options
 * @returns {object} migratedOptions viz charting options
 */
const migrateChartingOptions = (options: Record<string, unknown>): Record<string, unknown> => {
    const migratedOptions = {};
    Object.keys(options).forEach(key => {
        const value = options[key];
        const updatedKey = optionRenames[key] ?? key;
        let updatedValue = enumReplacements?.[updatedKey]?.[value] ?? value;
        if (otherValueReplacements[updatedKey]) {
            updatedValue = otherValueReplacements[updatedKey](updatedValue);
        }
        migratedOptions[updatedKey] = updatedValue;
    });
    return migratedOptions;
};

/**
 * helper that encapsulates shared migration logic across most charting-based viz
 * @param {Object} defaultChanges - map consisting of default value changes from viz.* to splunk.*
 * @param {Object} validOptions - the list of options that the new splunk.* viz supports
 * @param {VisualizationDefinition} vizDefinition - the viz definition to migrate
 * @param {String} vizType - the value of splunk.*
 */
const migrateBaseChartingVizDefinition = ({
    defaultChanges,
    validOptions,
    vizDefinition,
    vizType,
}: {
    defaultChanges: Record<string, unknown>;
    validOptions: Record<string, unknown>;
    vizDefinition: VisualizationDefinition;
    vizType: string;
}) => {
    const { encoding = {}, options = {}, ...otherDefinitionParts } = vizDefinition;
    const migratedDefinition: VisualizationDefinition = {
        ...otherDefinitionParts,
        type: vizType,
        options: {},
        context: {},
    };
    // translate encoding to dynamic options equivalent
    const { options: dataOptions, context: dataContext } = encodingToDynamicOptionsDSL(
        encoding as Record<string, EncodingConfig>
    );

    const migratedOptions = {
        ...defaultChanges,
        ...migrateChartingOptions(options),
        ...dataOptions,
    };
    // remove invalid options
    Object.keys(migratedOptions).forEach(key => {
        if (!validOptions[key]) {
            delete migratedOptions[key];
        }
    });
    migratedDefinition.options = migratedOptions;
    migratedDefinition.context = dataContext;

    return migratedDefinition;
};

export { migrateBaseChartingVizDefinition, migrateChartingOptions, otherValueReplacements };
