import * as React from 'react';
import { _ } from '@splunk/ui-utils/i18n';
import SVG from '@splunk/react-icons/SVGEnterprise';

const Table = (props: React.ComponentProps<typeof SVG>): React.ReactElement<SVGElement> => (
    <SVG screenReaderText={_('Table')} fill="currentColor" viewBox="0 0 150 100" {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7.92292 24.2117C7.75325 24.079 7.53966 24 7.30762 24C6.75533 24 6.30762 24.4477 6.30762 25V74.2308C6.30762 74.7831 6.75533 75.2308 7.30762 75.2308C7.53966 75.2308 7.75325 75.1517 7.92292 75.0191C8.0926 75.1517 8.30618 75.2308 8.53822 75.2308H141.461C141.693 75.2308 141.907 75.1517 142.077 75.019C142.246 75.1517 142.46 75.2308 142.692 75.2308C143.245 75.2308 143.692 74.7831 143.692 74.2308V25C143.692 24.4477 143.245 24 142.692 24C142.46 24 142.246 24.0791 142.077 24.2118C141.907 24.0791 141.693 24 141.461 24H8.53822C8.30618 24 8.09259 24.079 7.92292 24.2117ZM141.692 73.2576V62.8963C141.618 62.9138 141.541 62.9231 141.461 62.9231H98.564V73.2308H141.461C141.541 73.2308 141.618 73.24 141.692 73.2576ZM96.564 73.2308V62.9231H53.4358V73.2308H96.564ZM51.4358 73.2308V62.9231H8.53822C8.45886 62.9231 8.38165 62.9138 8.30762 62.8964V73.2575C8.38165 73.24 8.45886 73.2308 8.53822 73.2308H51.4358ZM8.53822 60.9231C8.45886 60.9231 8.38165 60.9323 8.30762 60.9498V50.5887C8.38165 50.6061 8.45886 50.6154 8.53822 50.6154H51.4358V60.9231H8.53822ZM96.564 60.9231H53.4358V50.6154H96.564V60.9231ZM141.461 60.9231H98.564V50.6154H141.461C141.541 50.6154 141.618 50.6061 141.692 50.5886V60.9499C141.618 60.9324 141.541 60.9231 141.461 60.9231ZM141.692 48.6422V38.2809C141.618 38.2984 141.541 38.3077 141.461 38.3077H98.564V48.6154H141.461C141.541 48.6154 141.618 48.6247 141.692 48.6422ZM96.564 48.6154V38.3077H53.4358V48.6154H96.564ZM51.4358 48.6154V38.3077H8.53822C8.45886 38.3077 8.38165 38.2984 8.30762 38.281V48.6421C8.38165 48.6246 8.45886 48.6154 8.53822 48.6154H51.4358ZM8.53822 36.3077C8.45886 36.3077 8.38165 36.3169 8.30762 36.3344V25.9733C8.38165 25.9908 8.45886 26 8.53822 26H51.4358V36.3077H8.53822ZM96.564 36.3077H53.4358V26H96.564V36.3077ZM141.461 36.3077H98.564V26H141.461C141.541 26 141.618 25.9907 141.692 25.9732V36.3345C141.618 36.317 141.541 36.3077 141.461 36.3077Z"
        />
    </SVG>
);

export default Table;
