import { _ } from '@splunk/ui-utils/i18n';

const legend = {
    label: _('Legend'),
    layout: [
        [
            {
                label: _('Position'),
                option: 'legend.placement',
                editor: 'editor.select',
                editorProps: {
                    values: [
                        { label: _('Right'), value: 'right' },
                        { label: _('Left'), value: 'left' },
                        { label: _('Top'), value: 'top' },
                        { label: _('Bottom'), value: 'bottom' },
                        { label: _('None'), value: 'none' },
                    ],
                },
            },
        ],
        [
            {
                label: _('Truncation'),
                option: 'legend.labelStyle.overflowMode',
                editor: 'editor.radioBar',
                editorProps: {
                    values: [
                        { label: _('A...'), value: 'ellipsisEnd' },
                        { label: _('A...Z'), value: 'ellipsisMiddle' },
                        { label: _('...Z'), value: 'ellipsisStart' },
                        { label: _('Off'), value: 'ellipsisNone' },
                    ],
                },
            },
        ],
    ],
};

export default legend;
