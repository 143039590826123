import moment from '@splunk/moment';
import { isFinite, isString, isInteger, memoize } from 'lodash';
import { _ } from '@splunk/ui-utils/i18n';

// Todo: need break out this utils after breaking out all visualizations
/**
 * @file
 * A set of number formatting function used in Table
 */
const DECIMAL_OR_SCIENTIFIC_REGEX = /(^[-+]?[0-9]*[.]?[0-9]*$)|(^[-+]?[0-9][.]?[0-9]*e[-+]?[0-9][0-9]*$)/i;
const COMMA_SEPARATED_NUMBER_REGEX = /(^[-+]?([0]|([1-9][0-9]{0,2})|(([1-9][0-9]{0,2}[,])([0-9]{3}[,])*[0-9]{3}))(([.][0-9]+)?)$)/;
function isDecimalOrExpo(str) {
    return DECIMAL_OR_SCIENTIFIC_REGEX.test(str);
}
function isCommaSeparatedNumber(str) {
    return COMMA_SEPARATED_NUMBER_REGEX.test(str);
}

/**
 * Check if a string can be considered as numeric, this includes decimal, scientific number and number with comma separator
 *
 * Examples:
 * ```js
 * const { isNumerialStr } from '@splunk/react-visualizations/utils/numberUtils';
 * assert.ok(isNumerialStr('11.123'));
 * ```
 * @param {String} str input value
 * @return {Boolean}
 * @public
 */
export function isNumerialStr(str) {
    return isDecimalOrExpo(str) || isCommaSeparatedNumber(str);
}

/**
 * Check if a value can be considered as numeric
 *
 * Examples:
 * ```js
 * const { isNumerial } from '@splunk/react-visualizations/utils/numberUtils';
 * assert.equal(isNumerial('11.123') == 11.123);
 * ```
 * @param {any} value input value
 * @return {Boolean}
 * @public
 */
export function isNumerial(value) {
    return isFinite(value) || (isString(value) && isNumerialStr(value));
}

/**
 * Parse input value into number, return NaN for not numercial value
 * @param {*} value input value
 * @return {Number}
 * @public
 */
export function parseNumber(value) {
    if (isFinite(value) || (isString(value) && isDecimalOrExpo(value))) {
        // only parses the value consider as numeric.
        return parseFloat(value);
    }
    if (isString(value) && isCommaSeparatedNumber(value)) {
        return parseFloat(value.replace(/,/g, ''));
    }
    return NaN;
}

/**
 *
 */
const getThousandSeparatorDecimalPrecisionFormatter = memoize(
    (locale = 'en', options = {}) => new Intl.NumberFormat(locale, options),
    (locale, options) => `${locale}${JSON.stringify(options)}`
);

/**
- * Format number into string with precision and/or grouping
- *
- * Examples:
- * ```js
- * const { formatNumber } from '@splunk/react-visualizations/utils/numberUtils';
- * assert.equal(formatNumber('1100’, 2, { useThousandSeparators: true }), '1,100.00’)
- * ```
- * @param {Number} number                                  // value to format
- * @param {Number} [precision]                             // number of digits after the decimal point
- * @param {Object} [options]
- * @param {Boolean} [options.useThousandSeparators=false]  // flag to determine if grouping is desired
- * @param {Object} [options.locale=moment.locale()]        // Locale of the user for determinining how numbers are grouped
- * @param {Number} [options.defaultPrecision=0]            // The default precision to use if none is defined
- * @param {Number} [options.useTrendUnits=false]           // Add K, M, B, T to end of value, and divide by the appropriate order of magnitude
- * @return {String}
- * @public
- */
export function formatNumber(number, precision, options = {}) {
    const formatOptions = {};

    // Precision is optional, so check if precision is an object, and use it as the options, otherwise load from options
    const {
        useThousandSeparators = false,
        locale = moment.locale(),
        defaultPrecision = 0,
        useTrendUnits = false,
    } = precision && typeof precision === 'object' ? precision : options;

    // Apply precision only when it is a positive integer
    if (isInteger(precision) && precision >= 0) {
        // Validate precision is within acceptable bounds
        const p = precision > 20 ? 20 : precision;

        formatOptions.minimumFractionDigits = p;
        formatOptions.maximumFractionDigits = p;
    } else {
        formatOptions.maximumFractionDigits = defaultPrecision;
    }

    formatOptions.useGrouping = useThousandSeparators === true;

    let trendUnit = '';
    let value = number;
    const units = [
        { letter: 'T', power: 12 },
        { letter: 'B', power: 9 },
        { letter: 'M', power: 6 },
        { letter: 'K', power: 3 },
    ];

    if (useTrendUnits) {
        const found = units.find(unit => Math.abs(value) > 10 ** unit.power);

        if (found) {
            value /= 10 ** found.power;
            trendUnit = found.letter;
            formatOptions.maximumFractionDigits = 2;
            formatOptions.minimumFractionDigits = 0;
        }
    }

    const formatter = getThousandSeparatorDecimalPrecisionFormatter(locale, formatOptions);

    return `${formatter.format(value)}${trendUnit ? _(trendUnit) : ''}`;
}
