import React from 'react';
import SVG, {
    type SVGPropsOptionalViewBox,
} from '@splunk/react-icons/SVGEnterprise';

/* eslint-disable max-len */
export default function Check(
    props: SVGPropsOptionalViewBox
): ReturnType<typeof SVG> {
    return (
        <SVG viewBox="0 0 61 47" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path d="m52.524.892987c.5303-.526571 1.387-.523591 1.9136.006655l6.0835 6.125968c.5265.53025.5236 1.38697-.0067 1.91354l-37.395 37.13575c-.529.5253-1.3831.5238-1.9102-.0033l-19.89881-19.8982c-.528424-.5284-.528437-1.3851-.00003-1.9135l6.10468-6.1049c.5284-.5284 1.38512-.5284 1.91354 0l12.86182 12.8614z" />
        </SVG>
    );
}
