import React from 'react';
import T from 'prop-types';
import styled from 'styled-components';
import SingleValueBasic from '../../SingleValueBasic/SingleValueBasic';
import SingleValueSparkline from './SingleValueSparkline';
import { shouldShowSparkline, VALID_SPARKLINE_POSITIONS } from './utils/displayUtils';

export const CONTENT_OVERFLOW_FADEOUT_AREA_PX = 16;

const Column = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    justify-content: center;
`;

const Row = styled.div`
    display: flex;
    flex-direction: row;
`;
const OverflowWrapper = styled.div.attrs(() => ({
    'data-test': 'overflowWrapper',
}))`
    box-sizing: border-box;
    padding-left: 16px;
    padding-right: 16px;
    width: 100%;
    height: ${props => props.height}px;
    position: relative;
    display: flex;
    align-items: start;
    justify-content: center;
    &:after,
    &:before {
        position: absolute;
        height: 100%;
        width: ${CONTENT_OVERFLOW_FADEOUT_AREA_PX}px;
        content: '';
        top: 0;
    }
    &:after {
        right: 0;
        background: linear-gradient(
            90deg,
            ${props => props.overflowBackgroundColor}00,
            ${props => props.overflowBackgroundColor}
        );
    }
    &:before {
        left: 0;
        background: linear-gradient(
            -90deg,
            ${props => props.overflowBackgroundColor}00,
            ${props => props.overflowBackgroundColor}
        );
    }
`;

const SingleValueContent = ({ ...props }) => {
    const {
        backgroundColor,
        contentHeight,
        majorColor,
        majorText,
        majorTextFontSize,
        majorValueTitle,
        trendColor,
        trendText,
        trendTextFontSize,
        showTrendIndicator,
        unit,
        unitPosition,
        underLabel,
        underLabelFontSize,
        underLabelColor,
        onValueClick,
        showSparkline,
        sparklinePosition,
        sparklineWidth,
        sparklineHeight,
        sparklineValues,
        sparklineAreaColor,
        sparklineStrokeColor,
        showSparklineTooltip,
        showSparklineAreaGraph,
        shouldSparklineAcceptNullData,
        sparklineSegments,
        endDots,
    } = props;
    let sparkline = null;
    if (shouldShowSparkline({ showSparkline, sparklineValues })) {
        sparkline = (
            <SingleValueSparkline
                width={sparklineWidth}
                height={sparklineHeight}
                data={sparklineValues}
                fill={sparklineAreaColor}
                stroke={sparklineStrokeColor}
                showTooltip={showSparklineTooltip}
                isArea={showSparklineAreaGraph}
                acceptNull={shouldSparklineAcceptNullData}
                lineLength={sparklineSegments}
                endDotCount={endDots}
            />
        );
    }

    return (
        <Column>
            <Row>
                {sparklinePosition === 'before' && sparkline}
                <OverflowWrapper overflowBackgroundColor={backgroundColor} height={contentHeight}>
                    <SingleValueBasic
                        majorColor={majorColor}
                        majorText={majorText}
                        majorTextFontSize={majorTextFontSize}
                        majorValueTitle={majorValueTitle}
                        trendColor={trendColor}
                        trendText={trendText}
                        trendTextFontSize={trendTextFontSize}
                        showTrendIndicator={showTrendIndicator}
                        unit={unit}
                        unitPosition={unitPosition}
                        underLabel={underLabel}
                        underLabelFontSize={underLabelFontSize}
                        underLabelColor={underLabelColor}
                        onValueClick={onValueClick}
                    />
                </OverflowWrapper>
                {sparklinePosition === 'after' && sparkline}
            </Row>
            {sparklinePosition === 'below' && sparkline}
        </Column>
    );
};

SingleValueContent.propTypes = {
    ...SingleValueBasic.propTypes,
    contentHeight: T.number,
    backgroundColor: T.string,
    showSparkline: T.bool,
    sparklinePosition: T.oneOf(VALID_SPARKLINE_POSITIONS),
    sparklineWidth: T.number,
    sparklineHeight: T.number,
    sparklineValues: T.arrayOf(T.oneOfType([T.string, T.number])),
    sparklineAreaColor: T.string,
    sparklineStrokeColor: T.string,
    showSparklineTooltip: T.bool,
    showSparklineAreaGraph: T.bool,
    shouldSparklineAcceptNullData: T.bool,
    sparklineSegments: T.number,
    endDots: T.number,
};

export default SingleValueContent;
