import isNumber from 'lodash/isNumber';
import isFinite from 'lodash/isFinite';

/**
 * getDataTypeForPoint
 * naive implementation of checking for the data type of a single data point
 * number > time > string > unknown
 * starting with number because a Date.parse(number) is a valid date
 *
 * @param {any} dataPoint
 * @return {string} type
 */
export const getDataTypeForPoint = dataPoint => {
    if (isFinite(+dataPoint) && isNumber(+dataPoint)) {
        return 'number';
    }
    if (!Number.isNaN(Date.parse(dataPoint))) {
        return 'time';
    }
    if (typeof dataPoint === 'string') {
        return 'string';
    }
    // nulls, objects, etc
    return 'unknown';
};

/**
 * inferDataTypeFromSample
 * based on a number of data points this function determines the data type for all of them
 * and returns the most common data type (naive type inference)
 *
 * @param {Array} dataSample an array of arbitrary size containing data points of any data type
 * @return {string} type the most common type in the sample
 */
export const inferDataTypeFromSample = dataSample => {
    const typeMatches = {
        time: 0,
        number: 0,
        string: 0,
        unknown: 0,
    };
    dataSample.forEach(point => {
        typeMatches[getDataTypeForPoint(point)] += 1;
    });
    let typeCount = -1;
    let returnType = 'unknown';
    Object.keys(typeMatches).forEach(key => {
        if (typeMatches[key] > typeCount) {
            typeCount = typeMatches[key];
            returnType = key;
        }
    });
    return returnType;
};

/**
 * based on a data array (e.g. one data column) this function returns a tiny sample of it
 * @TODO: very naive approach. there's papers with more advanced techniques but for now this should be very fast
 *
 * @param {Array} data to draw a sample from
 * @return {Array} a sample of the passed data
 */
export const drawSample = data => {
    if (data.length > 2) {
        return [data[0], data[Math.floor(data.length / 2)], data[data.length - 1]];
    }
    if (data.length === 2) {
        return [data[0], data[1]];
    }
    // small enough to check everything
    return data;
};
