/* eslint-disable class-methods-use-this */
import { _ } from '@splunk/ui-utils/i18n';
import BaseRegistry from './BaseRegistry';

class GeoRegistry extends BaseRegistry {
    static create() {
        return new GeoRegistry();
    }

    /*
     * parse url
     * @params {String} url  the valid url should be geo://default/us
     * @return {Object}
     *         {String} providerType  'geo'
     *         {String} storageType 'default' for now // todo: add more in the future if geojson are allowed to save in other storages such as 'kvstore'
     *         {String} mapId 'us' or 'world' for now
     */
    parseURL(url) {
        if (!/.+:\/\/.*\/.+/.test(url)) {
            throw new Error(_('Invalid url.'));
        }

        const [providerType, geoId] = url.split('://');
        const [storageType, mapId] = geoId.split('/');
        return { providerType, storageType, mapId };
    }

    /*
     * get geoJson by url
     * @params {String} url  e.g. geo://default/us
     * @return {Json} geoJson
     */
    getByURL(url) {
        try {
            const { providerType, storageType, mapId } = this.parseURL(url);
            const provider = this.findProvider(providerType);
            const geoJson = provider.getById({ storageType, mapId });
            return geoJson;
        } catch (error) {
            throw new Error(_('Cannot get geoJson data.'));
        }
    }

    // Todo: return map ids from storage
    /*
     * list all available geojson ids
     * @params {Object}
     * @params {String} providerType
     * @params {Object} options
     * @return {Array} ids [{id: "us", url: 'geo://default/us'}, {id: 'world', url: 'geo://default/world'}]
     */
    listIds({ providerType = 'geo', options = { offset: 0, count: 100 } }) {
        const provider = this.findProvider(providerType);
        const ids = provider.listIds(options);
        return ids;
    }

    // Todo: be able to upload geoJson in the future
    upload() {}

    // Todo: be able to delete geoJson in the future
    deleteByURL() {}
}

export default GeoRegistry;
