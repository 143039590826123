import React, { useMemo, type ComponentType, type ReactElement } from 'react';
import { useSelector } from '../providers/StateProvider';

/**
 * A default validation function for the `withSelectorValue` HOC
 * @see {@link withSelectorValue}
 * @param value A selector result to be validated
 * @returns `true` if `value` is defined, else `false`
 */
export const defaultValidationFn = (value: unknown) =>
    typeof value !== 'undefined';

/**
 * If the provided selector returns a value which is validated by the validationFn arugment then the component will render
 * with the result of the selector injected as a prop named with the value of selectorResultProp else this will return null.
 * @param Component Component to render if the selector returns a valid value
 * @param hocConfig Configuration for the HOC
 * @param hocConfig.selector The Redux state selector to call
 * @param hocConfig.selectorResultProp The name of the Component prop which should receive the selector's result
 * @param {Function} [hocConfig.validationFn=defaultValidationFn] An optional validation function for the selector's result.
 * If not provided, the default validation function checks that the selector's result is defined.
 * @see {@link defaultValidationFn}
 * @returns `null` if the selector result is invalid, else `Component` with the selector result injected as a prop
 */
export const withSelectorValue =
    <
        P extends Record<string, unknown>,
        S extends Parameters<typeof useSelector>[0],
        K extends keyof P
    >(
        Component: ComponentType<P>,
        {
            selector,
            selectorResultProp,
            validationFn = defaultValidationFn,
        }: {
            selector: S;
            selectorResultProp: K;
            validationFn?: (value: P[K]) => boolean;
        }
    ) =>
    (props: Omit<P, K>): ReactElement<P> | null => {
        const selectorValue = useSelector(selector) as P[K];

        const isValid = useMemo(
            () => validationFn(selectorValue),
            [selectorValue]
        );

        if (!isValid) {
            return null;
        }

        return (
            <Component
                {...({ ...props, [selectorResultProp]: selectorValue } as P)}
            />
        );
    };
