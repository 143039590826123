import { _ } from '@splunk/ui-utils/i18n';
import pick from '@splunk/themes/pick';
import variables from '@splunk/themes/variables';
import { COLOR_EDITOR_PALETTE } from '@splunk/visualizations-shared/colorConstants';
import BackgroundColorConfig from '../../common/editorConfig/BackgroundColor';

const editorConfig = [
    {
        label: _('Icon Color'),
        layout: [
            [
                {
                    label: _('Color'),
                    option: 'iconColor',
                    editor: 'editor.color',
                    editorProps: {
                        pickFromTheme: theme =>
                            pick({
                                enterprise: variables.textColor,
                                prisma: variables.contentColorActive,
                            })({ theme }),
                        palette: [null, ...COLOR_EDITOR_PALETTE],
                    },
                },
                {
                    label: _('Opacity (%)'),
                    option: 'iconOpacity',
                    editor: 'editor.percent',
                },
            ],
        ],
    },
    BackgroundColorConfig,
];

export default editorConfig;
