import React from 'react';
import T from 'prop-types';
import { _ } from '@splunk/ui-utils/i18n';
import SVG, {
    type SVGPropsOptionalViewBox,
} from '@splunk/react-icons/SVGEnterprise';

/* eslint-disable max-len */
export default function MultiselectInput({
    screenReaderText,
    ...otherProps
}: SVGPropsOptionalViewBox): ReturnType<typeof SVG> {
    return (
        <SVG
            viewBox="0 0 24 24"
            screenReaderText={screenReaderText}
            {...otherProps}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M7.6202 6.70711C8.01073 6.31658 8.01073 5.68342 7.6202 5.29289C7.22968 4.90237 6.59651 4.90237 6.20599 5.29289L4.18411 7.31477L3.62019 6.75086C3.22967 6.36033 2.5965 6.36033 2.20598 6.75086C1.81545 7.14138 1.81545 7.77455 2.20598 8.16507L3.12345 9.08254C3.70924 9.66833 4.65898 9.66833 5.24477 9.08254L7.6202 6.70711ZM12 7C11.4477 7 11 7.44772 11 8C11 8.55228 11.4477 9 12 9H20C20.5523 9 21 8.55228 21 8C21 7.44772 20.5523 7 20 7H12ZM12 15C11.4477 15 11 15.4477 11 16C11 16.5523 11.4477 17 12 17H20C20.5523 17 21 16.5523 21 16C21 15.4477 20.5523 15 20 15H12ZM2.00244 14.5C2.00244 13.6716 2.67401 13 3.50244 13H6.50244C7.33087 13 8.00244 13.6716 8.00244 14.5V17.5C8.00244 18.3284 7.33087 19 6.50244 19H3.50244C2.67401 19 2.00244 18.3284 2.00244 17.5V14.5ZM4.00244 15V17H6.00244V15H4.00244Z"
                fill="currentColor"
            />
        </SVG>
    );
}

MultiselectInput.propTypes = {
    screenReaderText: T.string,
};

MultiselectInput.defaultProps = {
    screenReaderText: _('Multiselect Input'),
};
