import React from 'react';
import { _ } from '@splunk/ui-utils/i18n';
import SVG, {
    type SVGPropsOptionalViewBox,
} from '@splunk/react-icons/SVGEnterprise';

/* eslint-disable max-len */
export default function ZoomOut(
    props: SVGPropsOptionalViewBox
): ReturnType<typeof SVG> {
    return (
        <SVG screenReaderText={_('Zoom Out')} viewBox="0 0 32 32" {...props}>
            <path
                fill="currentColor"
                fillRule="evenodd"
                d="M16.32 18.734a8 8 0 1 1 1.54-1.288l8.347 8.347-1.414 1.414-8.473-8.473zM18 12a6 6 0 1 1-12 0 6 6 0 0 1 12 0z"
            />
            <path fill="currentColor" fillRule="evenodd" d="M16 13H8v-2h8v2z" />
        </SVG>
    );
}
