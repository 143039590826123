// ??? visualizations
import ConnectedLine from '@splunk/dashboard-layouts/visualizations/ConnectedLine';
// New Visualizations
import PlatformArea from '@splunk/visualizations/Area';
import PlatformBar from '@splunk/visualizations/Bar';
import PlatformBubble from '@splunk/visualizations/Bubble';
import PlatformChoroplethSvg from '@splunk/visualizations/ChoroplethSvg';
import PlatformColumn from '@splunk/visualizations/Column';
import PlatformEllipse from '@splunk/visualizations/Ellipse';
import PlatformEvents from '@splunk/visualizations/Events';
import PlatformFillerGauge from '@splunk/visualizations/FillerGauge';
import PlatformImage from '@splunk/visualizations/Image';
import PlatformLine from '@splunk/visualizations/Line';
import PlatformLinkGraph from '@splunk/visualizations/LinkGraph';
import PlatformMap from '@splunk/visualizations/Map';
import PlatformMarkdown from '@splunk/visualizations/Markdown';
import PlatformMarkerGauge from '@splunk/visualizations/MarkerGauge';
import PlatformParallelCoordinates from '@splunk/visualizations/ParallelCoordinates';
import PlatformPie from '@splunk/visualizations/Pie';
import PlatformPunchcard from '@splunk/visualizations/Punchcard';
import PlatformRectangle from '@splunk/visualizations/Rectangle';
import PlatformSankey from '@splunk/visualizations/Sankey';
import PlatformScatter from '@splunk/visualizations/Scatter';
import PlatformSingleValue from '@splunk/visualizations/SingleValue';
import PlatformSingleValueIcon from '@splunk/visualizations/SingleValueIcon';
import PlatformSingleValueRadial from '@splunk/visualizations/SingleValueRadial';
import PlatformTable from '@splunk/visualizations/Table';

import {
    withCustomUrl,
    withDragHandle,
    withSetTokens,
    withToolbar,
    withVizSwitcher,
    withShowDrilldown,
    withCommonCapabilities,
} from './utils';

const platformVisualizations = {};
// Add shapes and shape-like special platform visualizations (no title/ descriptions, progress bar, last updated, no toolbar or switcher)
[
    ConnectedLine,
    PlatformEllipse,
    PlatformImage,
    PlatformMarkdown,
    PlatformRectangle,
    PlatformSingleValueIcon,
].forEach((vizType) => {
    platformVisualizations[vizType.config.key] = vizType;
});

// Add default platform visualizations (defaults in toolbar and switcher)
[
    PlatformArea,
    PlatformBar,
    PlatformBubble,
    PlatformChoroplethSvg,
    PlatformColumn,
    PlatformEvents,
    PlatformFillerGauge,
    PlatformLine,
    PlatformLinkGraph,
    PlatformMap,
    PlatformMarkerGauge,
    PlatformParallelCoordinates,
    PlatformPie,
    PlatformPunchcard,
    PlatformSankey,
    PlatformScatter,
    PlatformSingleValue,
    PlatformSingleValueRadial,
    PlatformTable,
].forEach((vizType) => {
    platformVisualizations[vizType.config.key] = withCommonCapabilities(
        withVizSwitcher(withToolbar(vizType))
    );
});

// Enhance platform visualization to support setting of dynamic and static tokens through event drilldowns
[
    PlatformArea,
    PlatformBar,
    PlatformBubble,
    PlatformChoroplethSvg,
    PlatformColumn,
    PlatformEvents,
    PlatformLine,
    PlatformLinkGraph,
    PlatformMap,
    PlatformParallelCoordinates,
    PlatformPie,
    PlatformPunchcard,
    PlatformSankey,
    PlatformScatter,
    PlatformSingleValue,
    PlatformSingleValueIcon,
    PlatformSingleValueRadial,
    PlatformTable,
].forEach((vizType) => {
    const viz = platformVisualizations[vizType.config.key];
    platformVisualizations[vizType.config.key] = withSetTokens(viz);
});

// Enhance platform visualization to support setting of static tokens through event drilldowns
[PlatformEllipse, PlatformRectangle, PlatformImage, PlatformMarkdown].forEach(
    (vizType) => {
        const viz = platformVisualizations[vizType.config.key];
        platformVisualizations[vizType.config.key] = withSetTokens(viz, [
            'static',
        ]);
    }
);

// Enhance platform visualization to support redirecting to custom url through event drilldowns
[
    PlatformArea,
    PlatformBar,
    PlatformBubble,
    PlatformChoroplethSvg,
    PlatformColumn,
    PlatformEllipse,
    PlatformImage,
    PlatformLine,
    PlatformLinkGraph,
    PlatformMap,
    PlatformPie,
    PlatformPunchcard,
    PlatformRectangle,
    PlatformScatter,
    PlatformSingleValue,
    PlatformSingleValueIcon,
    PlatformSingleValueRadial,
    PlatformTable,
].forEach((vizType) => {
    const viz = platformVisualizations[vizType.config.key];
    platformVisualizations[vizType.config.key] = withCustomUrl(viz);
});

// Enhance platform visualization to show drag handle
[PlatformMap].forEach((vizType) => {
    const viz = platformVisualizations[vizType.config.key];
    platformVisualizations[vizType.config.key] = withDragHandle(viz);
});

// drilldown deny list
const hideDrilldownList = [
    'abslayout.line',
    'splunk.fillergauge',
    'splunk.markergauge',
    'splunk.parallelcoordinates',
    'splunk.sankey',
    'splunk.events',
];

// apply showDrilldown to all visualizations except the ones in the denyList
Object.entries(platformVisualizations).forEach(([key, Viz]) => {
    if (hideDrilldownList.includes(key)) {
        return;
    }
    platformVisualizations[key] = withShowDrilldown(Viz);
});

export default {
    visualizations: platformVisualizations,
};
