import { chain } from 'lodash';
import MarkerGauge, { MarkerGaugeProps } from './PureMarkerGauge/MarkerGauge';
import config from './config';
import withDashboardViz from '../common/withDashboardViz';
import { convertToGaugeProperties } from '../common/gaugeUtils';

export const PureMarkerGauge = MarkerGauge;

const validOptions = Object.keys(config.optionsSchema);

type computeVizPropsFn = (options: Record<string, any>) => Partial<MarkerGaugeProps>;
export const computeVizProps: computeVizPropsFn = ({ ...props }): Partial<MarkerGaugeProps> => {
    const options = chain(props).pick(validOptions).thru(convertToGaugeProperties).value();

    const defaultColors = {
        fillColor: config.themes.defaultFillColor(props),
        majorTickFillColor: config.themes.majorTickFillColor(props),
        majorTickStrokeColor: config.themes.majorTickStrokeColor(props),
        valueMarkerFillColor: config.themes.valueMarkerFillColor(props),
        valueMarkerLabelFillColor: config.themes.valueMarkerLabelFillColor(props),
    };

    return { ...options, ...defaultColors };
};

const viz = withDashboardViz({
    ReactViz: MarkerGauge,
    computeVizProps,
    vizConfig: config,
});

export default viz;

export { config };
