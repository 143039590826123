import { isEmpty, defaultsDeep, omit, omitBy, isUndefined } from 'lodash';
import numbro from 'numbro';
import { formatTimeWithTimezoneCorrection } from '../utils/formatterUtils';

import { AbstractFormatter } from '../Formatter';
import { DataType, TypedValue } from '../DataPrimitive';
import { TypeSafeValue } from '../TypeSafeValue';
import { DataPoint } from '../DataPoint';
// There is a mismatch between typedefinition of numbro and numbro export.
// eslint-disable-next-line @typescript-eslint/no-var-requires
const numbrofn = require('numbro');

const validUnitPositions = ['before', 'after'] as const;
interface NumberConfig extends numbro.Format {
    unit?: string;
    unitPosition?: typeof validUnitPositions[number];
    precision?: number;
}

interface StringConfig {
    unit?: string;
    unitPosition?: typeof validUnitPositions[number];
}

interface TimeConfig {
    format?: string;
}
interface FormatByTypeConfig {
    number: Partial<NumberConfig>;
    string: Partial<StringConfig>;
    time: Partial<TimeConfig>;
}

const formatNumber = (number: number | string, config: NumberConfig): string => {
    const mappedConfig = {
        prefix: !isEmpty(config.unit) && config.unitPosition === 'before' ? `${config.unit} ` : undefined,
        postfix: !isEmpty(config.unit) && config.unitPosition !== 'before' ? ` ${config.unit}` : undefined,
        mantissa: typeof config.precision === 'number' ? config.precision : undefined,
    };
    const c: numbro.Format = defaultsDeep(
        {},
        omitBy(mappedConfig, isUndefined),
        omit(config, ['unit', 'unitPosition', 'precision'])
    );
    return numbrofn(number).format(c);
};

const formatString = (string: number | string, config: StringConfig): string => {
    if (!isEmpty(config.unit)) {
        return config.unitPosition === 'before' ? `${config.unit} ${string}` : `${string} ${config.unit}`;
    }
    return `${string}`;
};

const formatTime = (dateString: string, defaultValue: number | string, config: TimeConfig): string => {
    if (typeof config.format === 'string' && !isEmpty(config.format)) {
        // this preserves the timezone when formatting
        return formatTimeWithTimezoneCorrection(dateString, config.format);
    }
    return defaultValue.toString();
};

/**
 * @class FormatByType
 * 
 * Formats the value based on the value type and the provided config. For example 
 * 
 * ```js
 <Table
    context = {{
        formattedConfig: {
            number: {
                unit: '$',
                unitPosition: 'before',
                precision: 2,
                thousandSeparated: true,
            },
        },
    }}

    options = {{
        columnFormat: {
            formatted: {
                data: '> table | seriesByName("formatted") | formatByType(formattedConfig)',
            },
        },
    }}

    dataSources = {{
        primary: {
            requestParams: { offset: 0, count: 20 },
            data: {
                fields: [
                    { name: 'number' },
                    { name: 'formatted' },
                ],
                columns: [
                    [-927916.96, -924916.9, -654089.75],
                    [-927916.96, -924916.9, -654089.75],
                ],
            },
            meta: { totalCount: 100 },
        },
    }}
 />
 * ```
 *  
 *  ## Config Object
 * 
 *  ### number
 * 
 *  * **unit?**: `string` to be appended or prepended to the value
 *  * **unitPosition?**: `{"before" | "after"}` where should the `unit` be placed. Maps to numbro `prefix` or `postfix`
 *  * **numberPrecision?**: `number` maps to numbro `mantissa`
 *  * **mantissa?**: `number` number of decimal points to show
 *  * **trimMantissa?**: `boolean` ending 0s in decimal points will be trimmed
 *  * **thousandSeparated?**: `boolean` should show `,` for thousands
 *  * **output?**: `"currency" | "percent" | "byte" | "time" | "ordinal" | "number"` value will be converted to one of thse formats
 *  * **base?**: `"decimal" | "binary" | "general"` used for converting value to bytes
 *  * **prefix?**: `string` string to be added in front of the value
 *  * **postfix?**: `string` string to be appended to the value
 *  * **forceAverage?**: `"trillion" | "billion" | "million" | "thousand"` can be used to force one the selected average
 *  * **average?**: `boolean` rounds up the value to the closed average
 *  * **totalLength?**: `number` used only for `average`. The number length to format data in
 *  * **spaceSeparated?**: `boolean` used with `average` to introduce space between number and average
 *  * **abbreviations?**: `{
        thousand?: string;
        million?: string;
        billion?: string;
        trillion?: string;
    }` abbreviation values for averages.
 *  * **negative?**: `"sign" | "parenthesis"` display sign or parenthesis for negative numbers
 *  * **forceSign?**: `boolean` always show + or - sign
 * 
 * 
 *  * For examples, refer [Numbro](https://numbrojs.com/format.html#format)
 * 
 * #### time
 * 
 *  * **format**: valid moment format string.  Refer - [moment display](https://momentjs.com/docs/#/displaying/)
 * 
 * 
 * 
 
 */
export class FormatByType extends AbstractFormatter {
    private config: FormatByTypeConfig = { number: {}, string: {}, time: { format: '' } };

    constructor(config: Record<string, unknown>) {
        super();
        this.config = defaultsDeep({}, config, this.config);
    }

    protected formatTypedValue(p: DataPoint): TypedValue<DataType> {
        const { number: numberConfig, string: stringConfig, time: timeConfig } = this.config;
        const value = p.getValue();
        switch (value.type) {
            case 'number':
                return new TypeSafeValue(
                    'string',
                    formatNumber(p.getRawValue() as number, numberConfig),
                    value.value
                );
            case 'string':
                return new TypeSafeValue('string', formatString(value.value, stringConfig), value.value);
            case 'time':
                return new TypeSafeValue(
                    'string',
                    formatTime(value.coercedValue, value.value, timeConfig),
                    value.value
                );
            default:
                return p.getValue();
        }
    }
}
