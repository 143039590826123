import { _ } from '@splunk/ui-utils/i18n';
import { isDynamicOption } from '@splunk/visualizations-shared/configUtils';

export const getDynamicFillColorEditor = ({ defaultContext, defaultPalettesConfig }) => ({
    label: _('Fill'),
    option: 'fillColor',
    context: 'fillColorEditorConfig',
    editor: 'editor.dynamicColor',
    showEditor: ({ options }) => isDynamicOption(options.fillColor),
    editorProps: {
        labelPosition: 'top',
        flyoutTitle: _('Dynamic coloring: fill'),
        formatters: [
            {
                label: _('Ranges'),
                value: 'rangeValue',
                defaults: {
                    fillDataValue: defaultContext.fillColorConfig,
                },
            },
            {
                label: _('Matches'),
                value: 'matchValue',
                defaults: {
                    fillDataValue: defaultContext.fillColorMatchConfig,
                },
            },
        ],
        dataSelectors: [
            {
                label: _('Fill'),
                value: 'fillDataValue',
            },
        ],
        defaultPalettesConfig,
    },
});
