import React from 'react';
import SVG, {
    type SVGPropsOptionalViewBox,
} from '@splunk/react-icons/SVGEnterprise';

/* eslint-disable max-len */
export default function LoadBalancer(
    props: SVGPropsOptionalViewBox
): ReturnType<typeof SVG> {
    return (
        <SVG viewBox="0 0 61 61" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path d="m43.9483 1.5c0-.552284.4477-1 1-1h10.4138c.5523 0 1 .447715 1 1v10.4138c0 .5523-.4477 1-1 1h-10.4138c-.5523 0-1-.4477-1-1zm5.1379 22.7931c-.5523 0-1 .4477-1 1v10.4138c0 .5523.4477 1 1 1h10.4138c.5523 0 1-.4477 1-1v-10.4138c0-.5523-.4477-1-1-1zm-4.1379 23.7931c-.5523 0-1 .4477-1 1v10.4138c0 .5523.4477 1 1 1h10.4138c.5523 0 1-.4477 1-1v-10.4138c0-.5523-.4477-1-1-1zm-43.4483-27.8275c-.552285 0-1 .4477-1 1v18.6897c0 .5523.447715 1 1 1h16.4937c.5522 0 1-.4477 1-1v-18.6897c0-.5523-.4478-1-1-1zm34.3432 21.9883.7718.9648 2.012-2.012.0132 5.8671-5.8651-.0152 2.3679-2.368-.9154-1.1443c-.9758-1.2197-2.2254-2.1922-3.6474-2.8386-1.2913-.587-2.6934-.8906-4.1118-.8906h-2.1753v-2.069h2.1753c1.7138 0 3.4077.3669 4.9679 1.0761 1.7181.781 3.2279 1.956 4.4069 3.4297zm2.9327-12.7815h-14.6646v2.069h14.6646v3.1034l4.158-4.1393-4.158-4.1365zm-3.9054-12.8086-2.0954-2.0954 5.8651-.0152-.0132 5.8671-2.2845-2.2845-.4993.6241c-1.179 1.4737-2.6888 2.6487-4.4069 3.4297-1.5602.7092-3.2541 1.0761-4.9679 1.0761h-2.1753v-2.069h2.1753c1.4184 0 2.8205-.3036 4.1118-.8906 1.422-.6464 2.6716-1.6189 3.6474-2.8386z" />
        </SVG>
    );
}
