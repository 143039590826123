import { COLOR_OR_TOKEN_PATTERN, getPattern } from '@splunk/visualizations-shared/schemaUtils';

export default {
    'axisLabelsX.maxLabelParts': {
        default: 3,
        minimum: 1,
        description:
            'Specify the maximum number of time-parts that a tick label will have (the 3 possible parts are year, month, time).',
        type: 'number',
    },
    'axisLabelsX.axisVisibility': {
        default: 'hide',
        description: 'Specify whether to show the x-axis.',
        pattern: getPattern(['show', 'hide']),
        type: 'string',
    },
    'axisLabelsY.axisVisibility': {
        default: 'hide',
        description: 'Specify whether the y-axis line is visible.',
        pattern: getPattern(['show', 'hide']),
        type: 'string',
    },
    'axisLabelsY2.axisVisibility': {
        default: 'hide',
        description: 'Specify whether the y2-axis line is visible.',
        pattern: getPattern(['show', 'hide']),
        type: 'string',
    },
    'axisLabelsY.extendsAxisRange': {
        default: true,
        description: 'Specify whether the y-axis should be extended to include whole major tick marks.',
        type: 'boolean',
    },
    'axisLabelsY.integerUnits': {
        default: false,
        description: 'Specify whether the major unit of the y-axis is rounded to the nearest integer.',
        type: 'boolean',
    },
    'axisLabelsY2.integerUnits': {
        default: false,
        description: 'Specify whether the major unit of the y2-axis is rounded to the nearest integer.',
        type: 'boolean',
    },
    'axisLabelsX.majorLabelVisibility': {
        default: 'auto',
        description: 'Specify whether labels for major tick marks are visible on the x-axis.',
        pattern: getPattern(['auto', 'show', 'hide']),
        type: 'string',
    },
    'axisLabelsY.majorLabelVisibility': {
        default: 'auto',
        description: 'Specify whether labels for major tick marks are visible on the y-axis.',
        pattern: getPattern(['auto', 'show', 'hide']),
        type: 'string',
    },
    'axisLabelsY2.majorLabelVisibility': {
        default: 'auto',
        description: 'Specify whether labels for major tick marks are visible on the y2-axis.',
        pattern: getPattern(['auto', 'show', 'hide']),
        type: 'string',
    },
    'axisLabelsX.majorTickSize': {
        default: 6,
        description: 'Specify the size, in pixels, of major tick marks on the x-axis.',
        type: 'number',
    },
    'axisLabelsY.majorTickSize': {
        default: 6,
        description: 'Specify the size, in pixels, of major tick marks on the y-axis.',
        type: 'number',
    },
    'axisLabelsY2.majorTickSize': {
        default: 6,
        description: 'Specify the size, in pixels, of major tick marks on the y2-axis.',
        type: 'number',
    },
    'axisLabelsY.minorTickSize': {
        default: 6,
        description: 'Specify the size, in pixels, of minor tick marks on the y-axis.',
        type: 'number',
    },
    'axisLabelsY2.minorTickSize': {
        default: 6,
        description: 'Specify the size, in pixels, of minor tick marks on the y2-axis.',
        type: 'number',
    },
    'axisLabelsX.majorTickVisibility': {
        default: 'auto',
        description: 'Specify whether major tick marks on the x-axis are visible.',
        pattern: getPattern(['auto', 'show', 'hide']),
        type: 'string',
    },
    'axisLabelsY.majorTickVisibility': {
        default: 'auto',
        description: 'Specify whether major tick marks on the y-axis are visible.',
        pattern: getPattern(['auto', 'show', 'hide']),
        type: 'string',
    },
    'axisLabelsY2.majorTickVisibility': {
        default: 'auto',
        description: 'Specify whether major tick marks on the y2-axis are visible.',
        pattern: getPattern(['auto', 'show', 'hide']),
        type: 'string',
    },
    'axisLabelsY.majorUnit': {
        default: 'auto',
        description: 'Specify the spacing unit between major tick marks along the numeric y-axis.',
        type: ['number', 'string'],
    },
    'axisLabelsY2.majorUnit': {
        default: 'auto',
        description: 'Specify the spacing unit between major tick marks along the numeric y2-axis.',
        type: ['number', 'string'],
    },
    'axisLabelsY.minorTickVisibility': {
        default: 'auto',
        description: 'Specify whether minor tick marks on the y-axis are visible.',
        pattern: getPattern(['auto', 'show', 'hide']),
        type: 'string',
    },
    'axisLabelsY2.minorTickVisibility': {
        default: 'auto',
        description: 'Specify whether minor tick marks on the y2-axis are visible.',
        pattern: getPattern(['auto', 'show', 'hide']),
        type: 'string',
    },
    'axisY.abbreviation': {
        default: 'none',
        description: 'Specify whether to abbreviate large y-axis values with the closest SI prefix.',
        pattern: getPattern(['none', 'auto']),
        type: 'string',
    },
    'axisY2.abbreviation': {
        default: 'none',
        description: 'Specify whether to abbreviate large y2-axis values with the closest SI prefix.',
        pattern: getPattern(['none', 'auto']),
        type: 'string',
    },
    'axisY.includeZero': {
        default: false,
        description: 'Specify whether the y-axis range includes zero.',
        type: 'boolean',
    },
    'axisY2.includeZero': {
        default: false,
        description: 'Specify whether the y2-axis range includes zero.',
        type: 'boolean',
    },
    'axisY.maximumNumber': {
        default: 'auto',
        description: 'Specify the maximum number for the visible y-axis range.',
        type: ['number', 'string'],
    },
    'axisY2.maximumNumber': {
        default: 'auto',
        description: 'Specify the maximum number for the visible y2-axis range.',
        type: ['number', 'string'],
    },
    'axisY.minimumNumber': {
        default: 'auto',
        description: 'Specify the minimum number for the visible y-axis range.',
        type: ['number', 'string'],
    },
    'axisY2.minimumNumber': {
        default: 'auto',
        description: 'Specify the minimum number for the visible y2-axis range.',
        type: ['number', 'string'],
    },
    'axisY.scale': {
        default: 'linear',
        description: 'Specify the type of scale that applies to a numerical y-axis.',
        pattern: getPattern(['linear', 'log']),
        type: 'string',
    },
    'axisY2.scale': {
        default: 'linear',
        description: 'Specify the type of scale that applies to a numerical y2-axis.',
        pattern: getPattern(['linear', 'log']),
        type: 'string',
    },
    'axisTitleX.text': { description: 'Specify the title of the x-axis.', type: 'string' },
    'axisTitleY.text': { description: 'Specify the title of the y-axis.', type: 'string' },
    'axisTitleY2.text': { description: 'Specify the title of the y2-axis.', type: 'string' },
    'axisTitleX.visibility': {
        default: 'visible',
        description: 'Specify whether to show the title of the x-axis.',
        pattern: getPattern(['visible', 'collapsed']),
        type: 'string',
    },
    'axisTitleY.visibility': {
        default: 'visible',
        description: 'Specify whether to show the title of the y-axis.',
        pattern: getPattern(['visible', 'collapsed']),
        type: 'string',
    },
    'axisTitleY2.visibility': {
        default: 'visible',
        description: 'Specify whether to show the title of the y2-axis.',
        pattern: getPattern(['visible', 'collapsed']),
        type: 'string',
    },
    'chart.resultTruncationLimit': {
        default: 50000,
        description: 'Specify the number of data points rendered in a chart.',
        type: 'number',
    },
    backgroundColor: {
        description: 'Specify the Hex code for color used for a background. For example: "#0000FF".',
        pattern: COLOR_OR_TOKEN_PATTERN,
        type: 'string',
    },
    fontColor: {
        description:
            'Specify the Hex code for color used for fonts (axis labels, legends). For example: "#0000FF".',
        pattern: COLOR_OR_TOKEN_PATTERN,
        type: 'string',
    },
    foregroundColor: {
        description: 'Specify the Hex code for color used for a foreground. For example: "#0000FF".',
        pattern: COLOR_OR_TOKEN_PATTERN,
        type: 'string',
    },
    seriesColors: {
        description: `Specify the Hex code for colors used for a series. For example, ['#FF0000', '#0000FF', '#008000']'.
            The default for light and dark mode is ['#1E93C6', '#F2B827', '#D6563C', '#6A5C9E', '#31A35F'].
            The default for SCP mode is ['#A9F5E7', '#A870EF',  '#1E6271', '#45D4BA', '#602CA1',  '#9AE6F7', '#6CD0F0', '#2F8AA7',  '#422879', '#F29BAC', '#E85B79', '#C6335F',  '#FDAF93', '#FB865C', '#E9643A',  '#7EEFDA', '#26AA92', '#207865', '#C093F9', '#8747DA',  '#4EB2D2', '#912344',  '#AD3F20', '#D44C20', '#FFC34E',  '#95651D',  '#C0891E', '#ECAE11', '#FCD9A4', '#78062A'].
            Note: Option type 'string' has been deprecated.`,
        type: ['array', 'string'],
        items: { type: 'string' },
    },
    fieldColors: {
        description: `Specify the Hex code for color used for a field. For example: {'count': '#008000', 'percent': '#FFA500'}. Note: Option type 'string' has been deprecated.`,
        type: ['object', 'string'],
    },
    'chart.showDataLabels': {
        default: 'none',
        description:
            'Specify whether bar, line, area, or column charts should display no labels, all labels, or only the min and max labels.',
        pattern: getPattern(['none', 'all', 'minmax']),
        type: 'string',
    },
    'data.fieldListMode': {
        default: 'hide_show',
        description: 'Specify the order in which to apply the fieldShowList and fieldHideList filters.',
        pattern: getPattern(['show_hide', 'hide_show']),
        type: 'string',
    },
    'data.fieldShowList': {
        description: `Specify a list of fields to show in the results. For example: ['percent', 'count']. Note: Option type 'string' has been deprecated.`,
        type: ['array', 'string'],
        items: { type: 'string' },
    },
    'data.fieldHideList': {
        description: `Specify a list of fields to hide in the results. For example: ['percent', 'count']. Note: Option type 'string' has been deprecated.`,
        type: ['array', 'string'],
        items: { type: 'string' },
    },
    'gridLinesX.showMajorLines': {
        default: false,
        description: 'Specify whether major grid lines are visible on the x-axis.',
        type: 'boolean',
    },
    'gridLinesY.showMajorLines': {
        default: true,
        description: 'Specify whether major grid lines are visible on the y-axis.',
        type: 'boolean',
    },
    'gridLinesY2.showMajorLines': {
        default: true,
        description: 'Specify whether major grid lines are visible on the y2-axis.',
        type: 'boolean',
    },
    'gridLinesY.showMinorLines': {
        default: false,
        description: 'Specify whether minor grid lines are visible on the y-axis.',
        type: 'boolean',
    },
    'gridLinesY2.showMinorLines': {
        default: false,
        description: 'Specify whether minor grid lines are visible on y2-axis.',
        type: 'boolean',
    },
    'layout.splitSeries': {
        default: false,
        description:
            'Set to "true" to split a multi-series chart into separate charts that are stacked from top to bottom; one for each series.',
        type: 'boolean',
    },
    'layout.splitSeries.allowIndependentYRanges': {
        default: false,
        description: 'Specify whether split series charts have independent y-ranges.',
        type: 'boolean',
    },
    'legend.labels': {
        description: `Specify a list of labels to pre-populate the legend. For example: ['percent', 'count']. Note: Option type 'string' has been deprecated.`,
        type: ['array', 'string'],
        items: { type: 'string' },
    },
    'legend.labelStyle.overflowMode': {
        default: 'ellipsisMiddle',
        description:
            'Specify how to display legend labels when they overflow the layout boundaries by replacing overflow text with an ellipsis.',
        pattern: getPattern(['ellipsisEnd', 'ellipsisMiddle', 'ellipsisStart', 'ellipsisNone']),
        type: 'string',
    },
    'legend.placement': {
        default: 'right',
        description: 'Specify the location of the legend on the panel.',
        pattern: getPattern(['right', 'left', 'top', 'bottom', 'none']),
        type: 'string',
    },
    'axisY2.enabled': {
        default: false,
        description: 'Enable a y2-axis for chart overlays.',
        type: 'boolean',
    },
    'axisY2.fields': {
        description: 'Specify a field that should be mapped to a second y-axis for chart overlays.',
        type: 'string',
    },
    'chart.overlayFields': {
        description:
            'Specify a field or a space/comma-delimited list of fields that should be mapped to the y2-axis.',
        type: 'string',
    },
    'chart.stackMode': {
        default: 'default',
        description: 'Specify stacking options for area, bar, and column charts.',
        pattern: getPattern(['default', 'stacked', 'stacked100']),
        type: 'string',
    },
    'chart.barSpacing': {
        description: 'Specify, in pixels, the spacing between columns in a bar chart.',
        type: 'number',
    },
    'chart.seriesSpacing': {
        description: 'Specify, in pixels, the spacing between clustered series in column and bar charts.',
        type: 'number',
    },
    lineWidth: {
        default: 2,
        description: 'Specify the line width, in pixels, for chart overlay.',
        type: 'number',
    },
    pageable: {
        default: false,
        description:
            'Specify whether a paginator should be rendered to page through the data from the data source.',
        type: 'boolean',
    },
    count: {
        default: 1000,
        description: 'Specify the maximum number of data points to display.',
        type: 'number',
    },
};
