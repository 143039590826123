import React from 'react';
import { _ } from '@splunk/ui-utils/i18n';
import SVG, {
    type SVGPropsOptionalViewBox,
} from '@splunk/react-icons/SVGEnterprise';

/* eslint-disable max-len */
export default function Edit(
    props: SVGPropsOptionalViewBox
): ReturnType<typeof SVG> {
    return (
        <SVG screenReaderText={_('Edit')} viewBox="0 0 24 24" {...props}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M14.2828 4.29703C15.781 2.79651 18.2127 2.79687 19.7105 4.29782C21.2061 5.79655 21.2039 8.2238 19.7056 9.71982L18.9363 10.4879L17.5232 9.07263L17.5233 9.07251L13.8379 5.38276L13.5178 5.06314L13.5183 5.0627L13.5181 5.0625L13.5187 5.0623L14.2828 4.29703ZM3.4442 15.1493L12.1143 6.47093L13.5291 7.88447L13.5241 7.8895L17.5301 11.886L17.5264 11.8873L17.5296 11.8904L8.85336 20.5584C8.5721 20.8394 8.19078 20.9972 7.79321 20.9972H4.50537C3.67694 20.9972 3.00537 20.3257 3.00537 19.4972V16.2094C3.00537 15.8118 3.16321 15.4305 3.4442 15.1493Z"
                fill="currentColor"
            />
        </SVG>
    );
}
