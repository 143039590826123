import defaultsDeep from 'lodash/defaultsDeep';
import React, { type JSXElementConstructor } from 'react';
import { _ } from '@splunk/ui-utils/i18n';
import cloneDeep from 'lodash/cloneDeep';
import type {
    BaseDS,
    BaseViz,
    DataSourceDefinition,
    ItemType,
    LayoutItemType,
    Mode,
    PresetMap,
    PresetUtility,
    HandleDirection,
    VizProps,
} from '@splunk/dashboard-types';
import Message from './Message';

export const TooSmallToRenderContentMessage = _('Too small to render content');
const TooSmallToRenderContentComponent = React.createElement(Message, {
    message: TooSmallToRenderContentMessage,
    centered: false,
});

const noopHandler = {
    canHandle: () => false,
};

export const DEFAULT_RESIZE_HANDLE_DIRECTIONS = [
    'n',
    'ne',
    'e',
    'se',
    's',
    'sw',
    'w',
    'nw',
] as HandleDirection[];

export const DEFAULT_PRESET: PresetMap = {
    visualizations: {},
    inputs: {},
    dataSources: {},
    eventHandlers: {},
    layouts: {},
};

export default class Preset implements PresetUtility {
    private presetDef: PresetMap;

    private SnapshotDataSource: BaseDS;

    // eslint-disable-next-line class-methods-use-this
    getDefaultPreset(): PresetMap {
        return {
            ...DEFAULT_PRESET,
            dataSources: {
                ...DEFAULT_PRESET.dataSources,
                '_ds.snapshot_': this.SnapshotDataSource,
            },
        };
    }

    normalize(preset: Partial<PresetMap>): PresetMap {
        return defaultsDeep(preset, this.getDefaultPreset());
    }

    constructor({
        presetDefinition = {},
        featureFlags = {},
        SnapshotDataSource,
    }: {
        presetDefinition?: Partial<PresetMap>;
        featureFlags?: { enableSmartSourceDS?: boolean };
        SnapshotDataSource: Preset['SnapshotDataSource'];
    }) {
        this.SnapshotDataSource = SnapshotDataSource;
        this.presetDef = this.normalize(cloneDeep(presetDefinition));

        // If smart sources are enabled then update the schema at runtime
        // to validate the options.enableSmartSources: boolean type
        if (featureFlags.enableSmartSourceDS) {
            this.populateSmartSourceOptionsSchema();
        }
    }

    /* eslint no-param-reassign: ["error", { "props": true, "ignorePropertyModificationsFor": ["dsPreset"] }] */
    private populateSmartSourceOptionsSchema() {
        Object.values(this.presetDef.dataSources).forEach((dsPreset) => {
            if (dsPreset.config?.optionsSchema) {
                dsPreset.config.optionsSchema.enableSmartSources = {
                    type: 'boolean',
                    description: `When enabled, this datasource can be referenced elsewhere in the dashboard with the $data source name:result.<field>$ syntax`,
                };
            }
        });
    }

    getPresetDefinition() {
        return this.presetDef;
    }

    findVisualization(type: string): BaseViz | undefined {
        return this.presetDef.visualizations[type];
    }

    findDataSource(type: string): PresetMap['dataSources'][string] | undefined {
        return this.presetDef.dataSources[type];
    }

    findInput(type: string): PresetMap['inputs'][string] | undefined {
        return this.presetDef.inputs[type];
    }

    findItem<T extends LayoutItemType>(type: string, layoutType: T) {
        return layoutType === 'block'
            ? (this.findVisualization(type) as ItemType<T>)
            : (this.findInput(type) as ItemType<T>);
    }

    findEventHandler(
        type: string
    ): PresetMap['eventHandlers'][string] | undefined {
        return this.presetDef.eventHandlers[type];
    }

    findLayout(type: string): PresetMap['layouts'][string] | undefined {
        return this.presetDef.layouts[type];
    }

    /**
     * Gets a name from a DS definition using getDataSourceName
     * from the static configuration of the data source implementation
     * @param {DataSourceDefinition} [definition] Data source definition
     * @returns {string | undefined} Data source name if available else undefined
     */
    getDataSourceName(definition?: DataSourceDefinition): string | undefined {
        let dsType = definition?.type;
        let dsDef = definition;

        if (!dsType || !dsDef) {
            return undefined;
        }

        if (dsType === '_ds.snapshot_') {
            const dsMeta =
                (definition?.options?.meta as Record<string, unknown>) ?? {};
            dsDef = (dsMeta.originalDefinition as DataSourceDefinition) ?? {};
            dsType = dsDef.type;
        }

        return (
            this.presetDef.dataSources[dsType]?.config?.getDataSourceName?.({
                dataSource: dsDef,
            }) ?? dsDef.name
        );
    }

    getPlaceholderIcon(
        type: string
    ): JSXElementConstructor<unknown> | undefined {
        const vizConfig = this.presetDef.visualizations[type]?.config;
        return vizConfig?.placeholderIcon || vizConfig?.icon;
    }

    private getDataContract(type?: string, layoutType?: LayoutItemType) {
        if (!type || !layoutType) {
            return null;
        }
        const item = this.findItem(type, layoutType);
        if (!item) {
            return null;
        }

        return item.config?.dataContract ?? null;
    }

    getVisualizationDataContract(type?: string) {
        return this.getDataContract(type, 'block');
    }

    getInputDataContract(type?: string) {
        return this.getDataContract(type, 'input');
    }

    private getOptionsSchema(type?: string, layoutType?: LayoutItemType) {
        if (!type || !layoutType) {
            return null;
        }
        const item = this.findItem(type, layoutType);
        if (!item) {
            return null;
        }

        return item.config?.optionsSchema ?? null;
    }

    getVisualizationOptionsSchema(type?: string) {
        return this.getOptionsSchema(type, 'block');
    }

    getInputOptionsSchema(type?: string) {
        return this.getOptionsSchema(type, 'input');
    }

    private getEditorConfig(type?: string, layoutType?: LayoutItemType) {
        if (!type || !layoutType) {
            return null;
        }
        const item = this.findItem(type, layoutType);
        if (!item) {
            return null;
        }

        return item.config?.editorConfig ?? null;
    }

    getVisualizationEditorConfig(type?: string) {
        return this.getEditorConfig(type, 'block');
    }

    getInputEditorConfig(type?: string) {
        return this.getEditorConfig(type, 'input');
    }

    /**
     * create visualization component
     * @param {*} type visualization type
     * @param {*} props computed react props
     */
    createVisualization(type: string, props: VizProps): JSX.Element {
        try {
            const VizImplementation = this.findVisualization(type);
            if (!VizImplementation) {
                throw new Error(_(`${type} is not defined`));
            }

            return React.createElement<Record<string, unknown>>(
                VizImplementation,
                {
                    ...props,
                    type,
                }
            );
        } catch (ex) {
            return React.createElement(Message, {
                level: 'error',
                message: (ex as Error).message,
            });
        }
    }

    /**
     * Determine if preset class recommends displaying a title and description
     * @param {String} type Visualization type, e.g. ('viz.singlevalue')
     */
    shouldDisplayVisualizationSiblingContent(type: string) {
        const Viz = this.findVisualization(type);

        return {
            showTitleAndDescription: !!Viz?.showTitleAndDescription,
            showProgressBar: !!Viz?.showProgressBar,
            showLastUpdated: !!Viz?.showLastUpdated,
        };
    }

    /**
     * Get flags from preset for viz or input
     * @param {String} type Item Type 'splunk.singlevalue'
     */
    shouldDisplaySiblingContent(type: string, itemLayoutType: LayoutItemType) {
        const Item =
            itemLayoutType === 'block'
                ? this.findVisualization(type)
                : this.findInput(type);

        return {
            showTitleAndDescription: !!Item?.showTitleAndDescription,
            showProgressBar: !!Item?.showProgressBar,
            showLastUpdated: !!Item?.showLastUpdated,
        };
    }

    shouldShowDrilldown(type: string): boolean {
        const Viz = this.findVisualization(type);

        return !!Viz?.showDrilldown;
    }

    shouldShowDragHandle(type: string): boolean {
        const Input = this.findInput(type);
        const Viz = this.findVisualization(type);

        // always show drag handles for inputs whether it is configured or not
        return !!Input || !!Viz?.showDragHandle;
    }

    getResizeHandleDirections(type?: string): HandleDirection[] {
        if (!type) {
            return DEFAULT_RESIZE_HANDLE_DIRECTIONS;
        }

        const inputHandleDirections = this.findInput(type)?.handleDirections;
        if (Array.isArray(inputHandleDirections)) {
            return inputHandleDirections;
        }

        const vizHandleDirections =
            this.findVisualization(type)?.handleDirections;
        if (Array.isArray(vizHandleDirections)) {
            return vizHandleDirections;
        }

        return DEFAULT_RESIZE_HANDLE_DIRECTIONS;
    }

    /**
     * Determine if the dashboard item, in the current dashboard mode, can render an action menu
     * @param {Mode} args.mode Current dashboard mode (view or edit)
     * @param {LayoutItemType} args.layoutItemType Type of the dashboard item (block or input)
     */
    // eslint-disable-next-line class-methods-use-this
    shouldShowActionMenu({
        mode,
        layoutItemType,
    }: {
        mode: Mode;
        layoutItemType: LayoutItemType;
    }) {
        return mode !== 'view' || layoutItemType !== 'input';
    }

    /**
     * instantiate datasource
     * @param {*} type datasource type
     * @param {*} options datasource options
     * @param {*} dataSourceContext  datasource context
     * @param {*} meta metadata
     * @param {*} id datasource id
     */
    createDataSource(
        type: string,
        options?: Record<string, unknown>,
        dataSourceContext?: Record<string, unknown>,
        meta?: Record<string, unknown>,
        id?: string,
        baseChainModel?: unknown
    ) {
        try {
            const DSImplementation = this.findDataSource(type);
            if (!DSImplementation) {
                throw new Error(_(`${type} is not defined`));
            }

            // pass id within context for testing purpose
            return new DSImplementation(
                options,
                { ...dataSourceContext, id },
                meta,
                baseChainModel
            );
        } catch (ex) {
            return new this.SnapshotDataSource({
                errorLevel: 'error',
                error: (ex as Error).message,
            });
        }
    }

    /**
     * instantiate input component
     * @param {*} type
     * @param {*} props
     */
    createInput(type: string, props: Record<string, unknown>) {
        try {
            const InputImplementation = this.findInput(type);
            if (!InputImplementation) {
                throw new Error(_(`${type} is not defined`));
            }

            if (
                typeof props.width === 'number' &&
                (InputImplementation.minimumSize?.width ?? 0) > props.width
            ) {
                return TooSmallToRenderContentComponent;
            }

            if (
                typeof props.height === 'number' &&
                (InputImplementation.minimumSize?.height ?? 0) > props.height
            ) {
                return TooSmallToRenderContentComponent;
            }

            return React.createElement<Record<string, unknown>>(
                InputImplementation,
                {
                    ...props,
                    type,
                }
            );
        } catch (ex) {
            return React.createElement(Message, {
                level: 'error',
                message: (ex as Error).message,
                centered: false,
            });
        }
    }

    /**
     * instantiate event handlers
     * @param {*} type
     * @param {*} options
     */
    createEventHandler(type: string, options: Record<string, unknown>) {
        try {
            const EventHandlerImplementation = this.findEventHandler(type);
            if (!EventHandlerImplementation) {
                throw new Error(_(`${type} is not defined`));
            }

            return new EventHandlerImplementation(options);
        } catch (ex) {
            return noopHandler;
        }
    }

    /**
     * instantiate layout component
     * @param {*} type
     * @param {*} props
     */
    createLayout(type: string, props: Record<string, unknown>) {
        try {
            const LayoutImplementation = this.findLayout(type);
            if (!LayoutImplementation) {
                throw new Error(_(`${type} is not defined`));
            }

            return React.createElement<Record<string, unknown>>(
                LayoutImplementation,
                {
                    ...props,
                    type,
                }
            );
        } catch (ex) {
            return React.createElement(Message, {
                level: 'error',
                message: (ex as Error).message,
            });
        }
    }
}
