import isPlainObject from 'lodash/isPlainObject';

const mergeWithDefaultRecursive = (
    val: unknown,
    defaultVal: unknown
): unknown => {
    if (val === undefined) {
        return defaultVal;
    }
    // only continue recursion if the value is a plain object (ie. not a primitive or an array object)
    if (!isPlainObject(val) || !isPlainObject(defaultVal)) {
        return val;
    }

    // val and defaultVal must be objects
    const obj = val as Record<string, unknown>;
    const defaultObj = defaultVal as Record<string, unknown>;
    const newObj: { [k: string]: unknown } = {};

    // gather the keys of obj and defaultObj
    const keys = new Set([...Object.keys(obj), ...Object.keys(defaultObj)]);

    // apply the properties with default values onto newObj
    let didObjChange = false;
    keys.forEach((k) => {
        newObj[k] = mergeWithDefaultRecursive(obj[k], defaultObj[k]);
        if (obj[k] !== newObj[k]) {
            didObjChange = true;
        }
    });

    return didObjChange ? newObj : obj;
};

/**
 * Recursively replaces the undefined values of obj with the values from the defaults objects.
 * Defaults objects are applied from left to right. Does not mutate the reference of objects that
 * are not changed, unlike lodash's defaultsDeep.
 * @param obj The base object.
 * @param defaultsToApply A list of objects that will have their values applied to the base object.
 * @returns The base object with the default values applied to it.
 */
const deepMergeWithDefaults = <T>(obj: T, ...defaultsToApply: unknown[]): T =>
    defaultsToApply.reduce(
        (objWithDefaults, defaultToApply) =>
            mergeWithDefaultRecursive(objWithDefaults, defaultToApply),
        obj
    ) as T;

export default deepMergeWithDefaults;
