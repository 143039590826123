import React, { useCallback, useMemo } from 'react';
import Multiselect from '@splunk/react-ui/Multiselect';

interface MultiselectDefaultValueEditorProps {
    /**
     * Comma-separated values that list the value of the multiselect.
     */
    value?: string | string[];
    /**
     * List of label-value pairs to list as options for the combo box.
     */
    options: { value: string }[];
    /**
     * Placeholder to display when the combo box is empty.
     */
    placeholder?: string;
    /**
     * Callback invoked when the value of the multiselect changes.
     */
    onChange: (evt: React.SyntheticEvent, updatedValue: string) => void;
}

/**
 * Component to edit default values for Multiselect inputs. Designed to be used with the
 * DefaultSelectedValueEditor component.
 */
const MultiselectDefaultValueEditor = ({
    value,
    options,
    placeholder,
    onChange,
}: MultiselectDefaultValueEditorProps): JSX.Element => {
    const handleChange = useCallback(
        (evt, { values }) => {
            onChange(evt, values);
        },
        [onChange]
    );

    const uniqueValues = useMemo(() => {
        if (!value) {
            return [];
        }

        return Array.from(
            new Set(Array.isArray(value) ? value : value.split(','))
        );
    }, [value]);

    const multiselectOptions = useMemo(
        () =>
            options.map(({ value: v }) => (
                <Multiselect.Option key={v} label={v} value={v} />
            )),
        [options]
    );

    return (
        <Multiselect
            values={uniqueValues}
            onChange={handleChange}
            placeholder={placeholder}
            allowNewValues
        >
            {multiselectOptions}
        </Multiselect>
    );
};

export default MultiselectDefaultValueEditor;
