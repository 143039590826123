import React, {
    useState,
    useCallback,
    useRef,
    useEffect,
    useMemo,
} from 'react';
import styled from 'styled-components';
import Tooltip from '@splunk/react-ui/Tooltip';
import Clickable from '@splunk/react-ui/Clickable';
import { Edit, Remove } from '@splunk/dashboard-icons';
import { _ } from '@splunk/ui-utils/i18n';
import { pick, pickVariant, variables } from '@splunk/themes';

const RemoveIconI18n = () => (
    <Remove screenReaderText={_('Remove')} size="16px" />
);

interface ItemProps {
    dataTest?: string;
    dataTestId?: string;
    isItemClickable?: boolean;
}

const Item = styled.div.attrs<ItemProps>(({ dataTest, dataTestId }) => ({
    'data-test': dataTest,
    'data-test-id': dataTestId,
}))<ItemProps>`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    color: ${variables.contentColorDefault};
    line-height: 35px;
    height: 35px;
    &:hover {
        cursor: ${(prop) => (prop.isItemClickable ? 'pointer' : 'default')};
        background-color: ${variables.backgroundColorHover};
    }
`;

const NameWrapper = styled.div.attrs(() => ({
    'data-test': 'item-name-wrapper',
}))`
    width: calc(100% - 70px);
    border: 2px solid transparent;
`;

const ClickableNameWrapper = styled(Clickable).attrs(() => ({
    'data-test': 'item-name-wrapper',
}))`
    width: calc(100% - 70px);
    border: 2px solid transparent;
    &:focus {
        border: 2px solid ${variables.focusColor};
        color: ${variables.contentColorDefault};
        background-color: ${variables.backgroundColorHover};
    }
`;

interface WrapperProps {
    onClick?: React.MouseEventHandler;
    children: React.ReactNode;
}

const Wrapper = ({ onClick, children }: WrapperProps) => {
    return onClick ? (
        <ClickableNameWrapper onClick={onClick}>
            {children}
        </ClickableNameWrapper>
    ) : (
        <NameWrapper>{children}</NameWrapper>
    );
};

interface RowProps {
    isUsed: boolean;
}

const Row = styled.div`
    line-height: 35px;
    display: flex;
    flex-direction: row;
    color: ${pickVariant<RowProps>('isUsed', {
        true: variables.contentColorDefault,
        false: variables.contentColorMuted,
    })};
`;

const Name = styled.div.attrs(() => ({
    'data-test': 'item-name',
}))`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

const unusedText = _('(Unused)');
const Unused = styled.div`
    margin-left: 5px;
`;

const NameText = ({ name, isUsed }: { name: string; isUsed: boolean }) => (
    <Row isUsed={isUsed}>
        <Name>{name}</Name>
        {!isUsed && <Unused>{unusedText}</Unused>}
    </Row>
);

interface IconProps {
    type: string;
}

const Icon = styled(Clickable).attrs<IconProps>(({ type }) => ({
    'data-test': `item-${type}`,
}))<IconProps>`
    width: 35px;
    text-align: center;
    border-radius: ${pick({
        enterprise: '0px',
        prisma: '24px',
    })};
    color: ${variables.contentColorMuted};
    &:hover {
        cursor: pointer;
        color: ${variables.contentColorDefault};
        background-color: ${variables.backgroundColorHover};
    }
    &:focus {
        border: 2px solid ${variables.focusColor};
        color: ${variables.contentColorDefault};
        background-color: ${variables.backgroundColorHover};
    }
`;

export interface EditableListItemProps {
    name: string;
    onItemClick?: React.MouseEventHandler;
    onEditClick?: React.MouseEventHandler;
    onRemoveClick?: React.MouseEventHandler;
    itemStyle?: Record<string, unknown>;
    RemoveIcon?: typeof Remove;
    'data-test'?: string;
    'data-test-id'?: string;
    isUsed?: boolean;
}

const ItemStyle = { padding: '0 10px' };

const EditableListItem = ({
    name,
    onItemClick,
    onEditClick,
    onRemoveClick,
    itemStyle = ItemStyle,
    RemoveIcon = RemoveIconI18n,
    'data-test': dataTest = 'editable-list-item',
    'data-test-id': dataTestId,
    isUsed = true,
}: EditableListItemProps) => {
    const [showTooltip, setShowTooltip] = useState(false);
    const timer = useRef<ReturnType<typeof setTimeout> | null>(null);

    useEffect(() => {
        return () => {
            if (timer.current) {
                clearTimeout(timer.current);
            }
        };
    }, []);

    const textOverflow = name.length > 15;

    const handleRequestOpen = useCallback(() => {
        if (timer.current) {
            clearTimeout(timer.current);
        }
        timer.current = setTimeout(() => setShowTooltip(true), 250);
    }, []);

    const handleRequestClosed = useCallback(() => {
        if (timer.current) {
            clearTimeout(timer.current);
        }
        timer.current = setTimeout(() => setShowTooltip(false), 100);
    }, []);

    const editButton = useMemo(() => {
        if (!onEditClick) {
            return null;
        }

        return (
            <Icon type="edit" onClick={onEditClick}>
                <Edit screenReaderText={_('Edit')} size="16px" />
            </Icon>
        );
    }, [onEditClick]);

    const removeButton = useMemo(() => {
        if (!onRemoveClick) {
            return null;
        }

        return (
            <Icon type="remove" onClick={onRemoveClick}>
                <RemoveIcon size="24px" />
            </Icon>
        );
    }, [onRemoveClick, RemoveIcon]);

    return (
        <Item style={itemStyle} dataTest={dataTest} dataTestId={dataTestId}>
            <Wrapper onClick={onItemClick}>
                <Tooltip
                    data-test-disabled={!textOverflow}
                    content={name}
                    open={showTooltip && textOverflow}
                    onRequestOpen={handleRequestOpen}
                    onRequestClose={handleRequestClosed}
                    inline={false}
                >
                    <NameText name={name} isUsed={isUsed} />
                </Tooltip>
            </Wrapper>
            {editButton}
            {removeButton}
        </Item>
    );
};

export default EditableListItem;
