import type { Coordinate } from '@splunk/dashboard-types';

/**
 * Computes the Arrow's triangle dimensions
 * @param {Number} w line width
 * @param {Number} h line height
 * @param {Number} l line length
 * @param {Number} L arrow length
 */
export const computeArrowTriangle = (
    w: number,
    h: number,
    l: number,
    L: number
) => {
    if (l === 0) {
        return { arrowW: 0, arrowH: 0, arrowL: 0 };
    }
    const arrowW = (w * L) / l;
    const arrowH = (h * L) / l;
    return { arrowW, arrowH, arrowL: L };
};

/**
 * Computes the To Arrow Angle to place at the From position of the line that is drawn
 * @param {Object} from x and y coordinate
 * @param {Object} to x and y coordinate
 */
export const computeFromArrowAngle = (from: Coordinate, to: Coordinate) => {
    if (to.x - from.x === 0) {
        return from.y > to.y ? 90 : 270;
    }
    const angle =
        Math.atan((to.y - from.y) / (to.x - from.x)) * (180 / Math.PI);
    return from.x > to.x ? angle : 180 + angle;
};

/**
 * Computes the To Arrow Angle to place at the To position of the line that is drawn
 * @param {Object} from x and y coordinate
 * @param {Object} to x and y coordinate
 */
export const computeToArrowAngle = (from: Coordinate, to: Coordinate) => {
    if (to.x - from.x === 0) {
        return from.y > to.y ? 270 : 90;
    }
    const angle =
        Math.atan((to.y - from.y) / (to.x - from.x)) * (180 / Math.PI);
    return from.x > to.x ? 180 + angle : angle;
};

/**
 * Computes the modified From coordinates depending on whether an arrow is present or not
 * @param {Object} from x and y coordinate
 * @param {Object} to x and y coordinate
 * @param {Number} arrowW the width of the drawn arrow, adjusted to the angle
 * @param {Number} arrowH the height of the drawn arrow, adjusted to the angle
 * @param {Boolean} fromArrow whether the From Arrow is present or not
 */
export const getNewFrom = (
    from: Coordinate,
    to: Coordinate,
    arrowW: number,
    arrowH: number,
    fromArrow: boolean
) => {
    let newFromX;
    let newFromY;
    if (fromArrow) {
        newFromX = from.x > to.x ? from.x - arrowW : from.x + arrowW;
        newFromY = from.y > to.y ? from.y - arrowH : from.y + arrowH;
    } else {
        newFromX = from.x;
        newFromY = from.y;
    }
    return { newFrom: { x: newFromX, y: newFromY } };
};

/**
 * Computes the modified To coordinates depending on whether an arrow is present or not
 * @param {Object} from x and y coordinate
 * @param {Object} to x and y coordinate
 * @param {Number} arrowW the width of the drawn arrow, adjusted to the angle
 * @param {Number} arrowH the height of the drawn arrow, adjusted to the angle
 * @param {Boolean} toArrow whether the To Arrow is present or not
 */
export const getNewTo = (
    from: Coordinate,
    to: Coordinate,
    arrowW: number,
    arrowH: number,
    toArrow: boolean
) => {
    let newToX;
    let newToY;
    if (toArrow) {
        newToX = from.x > to.x ? to.x + arrowW : to.x - arrowW;
        newToY = from.y > to.y ? to.y + arrowH : to.y - arrowH;
    } else {
        newToX = to.x;
        newToY = to.y;
    }
    return { newTo: { x: newToX, y: newToY } };
};

/**
 * Computes the Path's (Line drawn) triangle dimensions
 * @param {Object} from x and y coordinate
 * @param {Object} to x and y coordinate
 */
export const computePathTriangle = (from: Coordinate, to: Coordinate) => {
    const w = Math.abs(from.x - to.x);
    const h = Math.abs(from.y - to.y);
    const l = Math.sqrt(w * w + h * h);
    return { w, h, l };
};
