import React, { useContext, useRef } from 'react';
import { useStore } from '@splunk/dashboard-state';
import type { DashboardCoreApi } from '@splunk/dashboard-types';
import { useApiRegistry } from '../ApiRegistryContext';
import { useDataSourceRegistry } from '../DataSourceRegistryContext';
import { createDashboardApi } from './DashboardCoreApi';

export const DashboardCoreApiContext =
    React.createContext<DashboardCoreApi | null>(null);

export const DashboardCoreApiProvider = DashboardCoreApiContext.Provider;
export const DashboardCoreApiConsumer = DashboardCoreApiContext.Consumer;

export const DashboardCoreApiContextWrapper = ({
    children,
}: {
    children?: React.ReactNode;
}): JSX.Element => {
    const store = useStore();
    const dataSourceRegistry = useDataSourceRegistry();
    const apiRegistry = useApiRegistry();

    const apiRef = useRef<DashboardCoreApi | null>(null);

    if (apiRef.current === null) {
        apiRef.current = createDashboardApi({
            store,
            apiRegistry,
            dataSourceRegistry,
        });
    }

    return (
        <DashboardCoreApiProvider value={apiRef.current}>
            {children}
        </DashboardCoreApiProvider>
    );
};

// By checking if the context is null we'll know if we're not within a DashboardCoreApiProvider
export const useDashboardCoreApi = () => {
    const ctx = useContext(DashboardCoreApiContext);
    if (ctx === null) {
        throw new Error(
            'useDashboardCoreApi can only be used in a component that is a child of a DashboardCoreApiProvider'
        );
    }

    return ctx;
};
