import type { BehaviorSubject } from 'rxjs';
import type { MutableRefObject } from 'react';
import type { TelemetryAPI, EmittableEvent } from '@splunk/dashboard-telemetry';
import type {
    AbsoluteLayoutOptions,
    AbsoluteLayoutStructure,
} from '@splunk/dashboard-types';
import {
    computeNewAbsoluteStructureItem,
    isBlockItem,
} from '@splunk/dashboard-utils';
import BaseLayoutApi from '../BaseLayoutApi';
import {
    moveLayoutItem,
    disconnectLine,
    cloneBlockItem,
    cloneLine,
} from '../utils/layoutUtils';
import { computeLineAbsPosition, getAllLineItems } from '../utils/lineUtils';
import { isFromImageRegistry } from '../utils/imageUtils';
import { absoluteLayoutOptions } from '../DefaultOptions';
import type { AddLayoutItemArgs } from '../utils/layoutApiUtils';
import { getAllBlockItems } from '../utils/blockUtils';

interface LayoutApiArgs {
    getZoomObserver: () => BehaviorSubject<number>;
    setFitToWidthAndScrollToTopLeft: (maxScale?: number) => void;
    layoutStructureRef: MutableRefObject<AbsoluteLayoutStructure>;
    options?: AbsoluteLayoutOptions;
    optionsRef?: MutableRefObject<AbsoluteLayoutOptions | undefined>;
    getCanvasDomElement: () => HTMLElement;
    setScale: (scale: number) => void;
    zoomIn: () => void;
    zoomOut: () => void;
    telemetry?: TelemetryAPI;
    getBgImageSrc: () => string | undefined;
}

class AbsoluteLayoutApi extends BaseLayoutApi {
    private layout: LayoutApiArgs;

    private options: AbsoluteLayoutOptions | undefined;

    constructor({ telemetry, ...layout }: LayoutApiArgs) {
        super();
        this.layout = layout;
        this.telemetry = telemetry;

        Object.defineProperty(this, 'options', {
            get(): AbsoluteLayoutOptions {
                return this.layout.optionsRef?.current;
            },
        });
    }

    /**
     * Get layout items in order
     * @method
     * @returns {String} ordered item ids
     * @public
     */
    getLayoutItemOrder = (): string[] => {
        return getAllBlockItems({
            layoutStructure: this.layout.layoutStructureRef.current,
        }).map(({ item }) => item);
    };

    /**
     * Adjust layout item order
     * @method
     * @param {Number} currentOrder
     * @param {Number} newOrder
     * @returns {Promise<Object[]>} updated layout structure
     * @public
     */
    adjustLayoutItemOrder = async (
        currentOrder: number,
        newOrder: number,
        metadata?: EmittableEvent['metadata']
    ): Promise<AbsoluteLayoutStructure> => {
        this.emitTelemetry({
            pageAction: 'adjustLayoutItemOrder',
            metadata,
        });

        const blockItems = getAllBlockItems({
            layoutStructure: this.layout.layoutStructureRef.current,
        });
        const lineItems = getAllLineItems({
            layoutStructure: this.layout.layoutStructureRef.current,
        });
        const updatedBlockItems = moveLayoutItem(
            blockItems,
            currentOrder,
            Math.min(newOrder, blockItems.length)
        );
        // an lazy way of rearrange block item orders. lol
        return [...lineItems, ...updatedBlockItems];
    };

    /**
     * Add a new layout item to the structure
     * @method
     * @param {Object} options
     * @param {String} options.itemId
     * @param {Object} options.vizContract
     * @param {String} [options.type='block']
     * @param {Object} options.config
     * @returns {Promise<Object[]>} updated layout structure
     * @public
     */

    addLayoutItem = async ({
        itemId,
        vizContract,
        type = 'block',
        config,
        metadata,
    }: AddLayoutItemArgs): Promise<AbsoluteLayoutStructure> => {
        this.emitTelemetry({
            pageAction: 'addLayoutItem',
            metadata,
        });

        // generate a proposed layout structure
        // it's up to the consumer to apply this structure via setting layoutStructure prop
        const width = this.options?.width ?? absoluteLayoutOptions.width;
        const height = this.options?.height ?? absoluteLayoutOptions.height;

        return [
            ...this.layout.layoutStructureRef.current,
            computeNewAbsoluteStructureItem({
                itemId,
                type,
                canvasWidth: width,
                canvasHeight: height,
                vizContract,
                layoutItems: this.layout.layoutStructureRef.current,
                config,
            }),
        ];
    };

    /**
     * Remove items from layout structure
     * @method
     * @param {Promise<String[]>} itemIds
     * @public
     */
    removeLayoutItems = async (
        // TODO: remove eslint comment below and update
        // eslint-disable-next-line default-param-last
        itemIds: string[] = [],
        metadata?: EmittableEvent['metadata']
    ): Promise<AbsoluteLayoutStructure> => {
        this.emitTelemetry({
            pageAction: 'removeLayoutItems',
            metadata,
        });

        const updatedItems: AbsoluteLayoutStructure = [];
        this.layout.layoutStructureRef.current.forEach((item) => {
            let iterItem = item;
            if (item.type === 'line') {
                let updatedLine = item;
                // disconnect line if necessary
                const absPos = computeLineAbsPosition({
                    layoutStructure: this.layout.layoutStructureRef.current,
                    position: updatedLine.position,
                });
                if (
                    'item' in updatedLine.position.from &&
                    itemIds.indexOf(updatedLine.position.from.item) >= 0
                ) {
                    updatedLine = disconnectLine({
                        line: updatedLine,
                        dir: 'from',
                        absPos: absPos.from,
                    });
                }
                if (
                    'item' in updatedLine.position.to &&
                    itemIds.indexOf(updatedLine.position.to.item) >= 0
                ) {
                    updatedLine = disconnectLine({
                        line: updatedLine,
                        dir: 'to',
                        absPos: absPos.to,
                    });
                }

                iterItem = updatedLine;
            }
            if (itemIds.indexOf(iterItem.item) < 0) {
                // push into array if it's not been removed
                updatedItems.push(iterItem);
            }
        });
        return updatedItems;
    };

    /**
     * Copies a layout item position and size and offsets by 2 * GRID_SIZE
     * @method
     * @param {Object} config
     * @param {Array} config.from List of original viz ids
     * @param {Array} config.to List of new viz ids
     * @param {Number} [config.offsetMultiplier=1] Number of grids to offset
     * @returns {Promise<Object[]>} Layout structure of all known items
     * @public
     */
    cloneLayoutItems = async ({
        from,
        to,
        offsetMultiplier = 1,
        metadata,
    }: {
        from: string[];
        to: string[];
        offsetMultiplier?: number;
        metadata?: EmittableEvent['metadata'];
    }): Promise<AbsoluteLayoutStructure> => {
        if (
            !Array.isArray(from) ||
            !Array.isArray(to) ||
            from.length !== to.length
        ) {
            throw new Error(
                `Cannot clone viz, incorrect inputs from ${from}, to: ${to}`
            );
        }
        this.emitTelemetry({
            pageAction: 'cloneLayoutItems',
            metadata,
        });

        // generate a proposed layout structure
        // it's up to the consumer to apply this structure via setting layoutStructure prop
        const newLayoutItems: AbsoluteLayoutStructure = [];
        from.forEach((copyItemId, idx) => {
            const item = this.layout.layoutStructureRef.current.find(
                (itm) => itm.item === copyItemId
            );
            if (!item) {
                return;
            }

            if (isBlockItem(item)) {
                const clonedBlockItem = cloneBlockItem({
                    id: to[idx],
                    item,
                    offsetMultiplier,
                });
                newLayoutItems.push(clonedBlockItem);
            } else if (item.type === 'line') {
                // line is a bit complicate as we need to clone base on abs position
                const linePosition = computeLineAbsPosition({
                    layoutStructure: this.layout.layoutStructureRef.current,
                    position: item.position,
                });
                const clonedLine = cloneLine({
                    id: to[idx],
                    item: {
                        item: copyItemId,
                        type: 'line',
                        position: linePosition,
                    },
                    offsetMultiplier,
                });
                newLayoutItems.push(clonedLine);
            }
        });
        return [...this.layout.layoutStructureRef.current, ...newLayoutItems];
    };

    /**
     * get dashboard canvas dom element
     */
    getCanvasDomElement = (): HTMLElement => {
        return this.layout.getCanvasDomElement();
    };

    /**
     * return snapshot of that includes inlined background image
     * @method
     * @returns {Object} Updated Layout options with encoded background image
     * @public
     */
    snapshot = (): AbsoluteLayoutOptions => {
        const { backgroundImage: imgFromOpts, ...opts } = this.options ?? {};

        const srcFromOptions = imgFromOpts?.src ?? '';
        const srcFromImageRegistry = isFromImageRegistry(srcFromOptions);

        const backgroundImage = imgFromOpts
            ? {
                  backgroundImage: {
                      ...imgFromOpts,
                      src: srcFromImageRegistry
                          ? this.layout.getBgImageSrc()
                          : srcFromOptions,
                  },
              }
            : {};

        return {
            options: {
                ...(opts ?? {}),
                ...backgroundImage,
            },
        };
    };

    /**
     * Sets dashboard scale
     * @param {Number} scale New scale as floating point number
     */
    setScale = (scale: number): void => {
        return this.layout.setScale(scale);
    };

    /**
     * Increases dashboard zoom level for one step
     */
    zoomIn = (): void => {
        return this.layout.zoomIn();
    };

    /**
     * Decreases dashboard zoom level for one step
     */
    zoomOut = (): void => {
        return this.layout.zoomOut();
    };

    /**
     * Adjust dashboard's scale to fit the width of the container
     */
    fitToWidth = (maxScale = Infinity): void => {
        return this.layout.setFitToWidthAndScrollToTopLeft(maxScale);
    };

    /**
     * @returns {Object} Observable that tracks zoom level changes or null if zoom is unsupported
     */
    getZoomLevel: LayoutApiArgs['getZoomObserver'] = () => {
        return this.layout.getZoomObserver();
    };
}

export default AbsoluteLayoutApi;
