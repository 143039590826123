import React from 'react';
import styled from 'styled-components';
import Close from '@splunk/react-icons/enterprise/Close';
import Message from './Message';

const MsgContainer = styled.div`
    display: inline-block;
    width: 95%;
    height: 200px;
    position: relative;
`;

const MsgClose = styled.div`
    float: right;
`;

export interface GalleryMessageProps {
    error?: string;
    warning?: string;
    onClose?: () => void;
}

const GalleryMessage = ({ error, warning, onClose }: GalleryMessageProps) => {
    if (!error && !warning) {
        return null;
    }

    const type = error ? 'error' : 'warning';
    const msg = error || warning;

    return (
        <MsgContainer>
            <Message level={type} message={msg} />
            {onClose && (
                <MsgClose onClick={onClose}>
                    <Close size={0.5} />
                </MsgClose>
            )}
        </MsgContainer>
    );
};

export default GalleryMessage;
