import type { PresetMap } from '@splunk/dashboard-types';
import LegacyVisualizations from './LegacyVisualizationPresets';
import PlatformVisualizationPresets from './PlatformVisualizationPresets';

export default {
    visualizations: {
        ...LegacyVisualizations.visualizations,
        ...PlatformVisualizationPresets.visualizations,
    } as PresetMap['visualizations'],
};
