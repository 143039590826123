import React, { useMemo } from 'react';
import { _ } from '@splunk/ui-utils/i18n';
import LineIcon from '@splunk/react-icons/Line';
import { Options as Opts } from '@splunk/visualization-encoding/Options';
import { vizCategories as categories } from '@splunk/dashboard-context';
import { noop, console, uniqueId } from '@splunk/dashboard-utils';
import { useSplunkTheme } from '@splunk/themes';
import type { Coordinate } from '@splunk/dashboard-types';
import optionsSchema from './ConnectedLineOptionsSchema';
import editorConfig from './ConnectedLineEditorConfig';
import {
    computeArrowTriangle,
    computeFromArrowAngle,
    computeToArrowAngle,
    getNewFrom,
    getNewTo,
    computePathTriangle,
} from './utils/lineUtils';
import type { ConnectedLineOptions, ConnectedLineProps } from './types';

const rootSvgStyle: React.CSSProperties = {
    pointerEvents: 'none',
    overflow: 'visible',
};

const lineStyle: React.CSSProperties = {
    pointerEvents: 'auto',
};

const dataContract = {
    requiredDataSources: ['primary'],
    initialRequestParams: {},
};

const vizConfig = {
    optionsSchema,
    editorConfig,
    dataContract,
    key: 'abslayout.line',
    name: _('Connected Line'),
    category: categories.get('shapes'),
    icon: LineIcon,
    events: {}, // no onEventTrigger called by connected line
    supports: ['dynamic-options'], // what capabilities are supported?
    requiredProps: [],
};

const defaultOptions: ConnectedLineOptions = {
    strokeOpacity: 1,
    strokeWidth: 1,
    strokeDasharray: 0,
    strokeDataSeries: '> primary | seriesByType("number")',
    strokeDataPoint: '> strokeDataSeries | lastPoint()',
    fromArrow: false,
    toArrow: false,
};

const defaultFrom: Coordinate = {
    x: 0,
    y: 0,
};

const defaultTo: Coordinate = {
    x: 150,
    y: 0,
};

const empty = {};

const ConnectedLine = ({
    from = defaultFrom,
    to = defaultTo,
    onLineSelect = noop,
    options = defaultOptions,
    dataSources = empty,
    context = empty,
}: ConnectedLineProps) => {
    const { colorScheme } = useSplunkTheme();
    const renderOptions = useMemo<Required<ConnectedLineOptions>>(() => {
        try {
            return Opts.evaluate(
                {
                    context,
                    options: {
                        ...defaultOptions,
                        ...options,
                    },
                },
                dataSources
            );
        } catch (e) {
            if (e instanceof Error) {
                console.error(
                    `Unexpected error evaluating line options: ${e.message}`
                );
            }
        }

        return defaultOptions;
    }, [context, options, dataSources]);

    const themeStrokeColor = colorScheme === 'light' ? '#000000' : '#ffffff';

    const { w, h, l } = computePathTriangle(from, to);

    const arrowL = 5 * renderOptions.strokeWidth;
    const { arrowW, arrowH } = computeArrowTriangle(w, h, l, arrowL);
    const squashedArrows =
        arrowL > l && renderOptions.fromArrow && renderOptions.toArrow;

    const fromArrowAngle = computeFromArrowAngle(from, to);
    const toArrowAngle = computeToArrowAngle(from, to);

    const fromArrowId = uniqueId();
    const toArrowId = uniqueId();

    const newW = w + arrowL;
    const newH = h + 2 * arrowL + 10;

    const { newFrom } = getNewFrom(
        from,
        to,
        arrowW,
        arrowH,
        renderOptions.fromArrow
    );
    const { newTo } = getNewTo(from, to, arrowW, arrowH, renderOptions.toArrow);

    return (
        <svg width={newW} height={newH} style={rootSvgStyle}>
            <path
                data-test="arrows"
                d={
                    squashedArrows
                        ? `M${newTo.x} ${newTo.y} L${newFrom.x} ${newFrom.y}`
                        : `M${newFrom.x} ${newFrom.y} L${newTo.x} ${newTo.y}`
                }
                stroke={renderOptions.strokeColor || themeStrokeColor}
                strokeOpacity={`${renderOptions.strokeOpacity}`}
                strokeWidth={`${renderOptions.strokeWidth}`}
                strokeDasharray={`${renderOptions.strokeDasharray}`}
                fill="none"
                markerStart={
                    renderOptions.fromArrow ? `url(#${fromArrowId})` : ''
                }
                markerEnd={renderOptions.toArrow ? `url(#${toArrowId})` : ''}
            />
            <path
                data-test="line"
                onMouseDown={onLineSelect}
                d={`M${from.x} ${from.y} L${to.x} ${to.y}`}
                strokeWidth={`${renderOptions.strokeWidth + 20}`}
                fill="none"
                stroke="white"
                strokeOpacity={0}
                style={lineStyle}
            />
            <defs>
                <marker
                    id={`${fromArrowId}`}
                    markerWidth="10"
                    markerHeight="10"
                    refX={squashedArrows ? '5' : '0'}
                    refY="2"
                    orient={`${fromArrowAngle}deg`}
                    markerUnits="strokeWidth"
                >
                    <path
                        d="M0,0 L0,4 L5,2 z"
                        fill={renderOptions.strokeColor || themeStrokeColor}
                        fillOpacity={`${renderOptions.strokeOpacity}`}
                    />
                </marker>
                <marker
                    id={`${toArrowId}`}
                    markerWidth="10"
                    markerHeight="10"
                    refX={squashedArrows ? '5' : '0'}
                    refY="2"
                    orient={`${toArrowAngle}deg`}
                    markerUnits="strokeWidth"
                >
                    <path
                        d="M0,0 L0,4 L5,2 z"
                        fill={renderOptions.strokeColor || themeStrokeColor}
                        fillOpacity={`${renderOptions.strokeOpacity}`}
                    />
                </marker>
            </defs>
        </svg>
    );
};

ConnectedLine.config = vizConfig;
export default ConnectedLine;
