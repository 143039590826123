import * as React from 'react';

const getRectangleIcon = props =>
    function NonePreset(): React.ReactElement<SVGElement> {
        return (
            <svg width="108" height="64" viewBox="0 0 108 64" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="0.5" y="0.5" width="107" height="63" rx="2.5" {...props} />
            </svg>
        );
    };

export default {
    'rectangle.none': getRectangleIcon({ fill: '#F2F4F5', stroke: '#C3CBD4' }),
    'rectangle.fill': getRectangleIcon({ stroke: '#C3CBD4', fill: '#00A4FD' }),
    'rectangle.stroke': getRectangleIcon({ stroke: '#00A4FD', fill: '#F2F4F5' }),
    'rectangle.fillAndStroke': getRectangleIcon({ stroke: '#00A4FD', fill: '#00A4FD' }),
};
