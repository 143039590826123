/**
 * Helper function to consolidate `trendDisplayMode` - percent/absolute
 * and `showTrendIndicator` - true/false into `trendDisplay` - off/percent/absolute for Single Value Variations
 * 1. if `showTrendIndicator` is explicitly false, `trendDisplay` is off
 * 2. if `showTrendIndicator` is undefined (defaults to true), return `trendDisplayMode` value if present
 * 3. if `showTrendIndicator` is explicitly true, return 'absolute'
 * 4. if both the old options are undefined, return nothing as their defaults (true and `absolute`)
 * correspond to `trendDisplay` default - absolute
 */
export const consolidateTrendDisplay = (
    trendDisplayMode: string,
    showTrendIndicator: boolean
): string | void => {
    if (showTrendIndicator === false) {
        return 'off';
    }
    if (['absolute', 'percent'].includes(trendDisplayMode)) {
        return trendDisplayMode;
    }
    if (showTrendIndicator === true) {
        return 'absolute';
    }
    return null;
};
