import { _ } from '@splunk/ui-utils/i18n';

const SecondsDescription = {
    abbr: 's',
    singular: _('second'),
    plural: _('seconds'),
};
const MinutesDescription = {
    abbr: 'm',
    singular: _('minute'),
    plural: _('minutes'),
};
const HoursDescription = { abbr: 'h', singular: _('hour'), plural: _('hours') };
const DaysDescription = { abbr: 'd', singular: _('day'), plural: _('days') };
const WeeksDescription = { abbr: 'w', singular: _('week'), plural: _('weeks') };
const MonthsDescription = {
    abbr: 'mon',
    singular: _('month'),
    plural: _('months'),
};
const QuartersDescription = {
    abbr: 'q',
    singular: _('quarter'),
    plural: _('quarters'),
};
const YearsDescription = {
    abbr: 'y',
    singular: _('year'),
    plural: _('years'),
};

const u = {
    s: SecondsDescription,
    sec: SecondsDescription,
    secs: SecondsDescription,
    second: SecondsDescription,
    seconds: SecondsDescription,
    m: MinutesDescription,
    min: MinutesDescription,
    mins: MinutesDescription,
    minute: MinutesDescription,
    minutes: MinutesDescription,
    h: HoursDescription,
    hr: HoursDescription,
    hrs: HoursDescription,
    hour: HoursDescription,
    hours: HoursDescription,
    d: DaysDescription,
    day: DaysDescription,
    days: DaysDescription,
    w: WeeksDescription,
    week: WeeksDescription,
    weeks: WeeksDescription,
    mon: MonthsDescription,
    month: MonthsDescription,
    months: MonthsDescription,
    q: QuartersDescription,
    qtr: QuartersDescription,
    qtrs: QuartersDescription,
    quarter: QuartersDescription,
    quarters: QuartersDescription,
    y: YearsDescription,
    yr: YearsDescription,
    yrs: YearsDescription,
    year: YearsDescription,
    years: YearsDescription,
};

export const timeUnits = u;

// snap units can additionally include days of the week.
export const snapUnits = {
    ...u,
    w0: { abbr: 'w0', singular: _('Sunday'), plural: _('Sundays') },
    w1: { abbr: 'w1', singular: _('Monday'), plural: _('Mondays') },
    w2: { abbr: 'w2', singular: _('Tuesday'), plural: _('Tuesdays') },
    w3: {
        abbr: 'w3',
        singular: _('Wednesday'),
        plural: _('Wednesdays'),
    },
    w4: { abbr: 'w4', singular: _('Thursday'), plural: _('Thursdays') },
    w5: { abbr: 'w5', singular: _('Friday'), plural: _('Fridays') },
    w6: { abbr: 'w6', singular: _('Saturday'), plural: _('Saturdays') },
    w7: { abbr: 'w7', singular: _('Sunday'), plural: _('Sundays') },
};
