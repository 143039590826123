import React, { useCallback } from 'react';
import styled from 'styled-components';
import { pick, variables, pickVariant } from '@splunk/themes';
import { customThemeVariables } from '@splunk/dashboard-ui';
import type { AbsolutePosition } from '@splunk/dashboard-types';
import type { EdgeItem, EdgeAppearance } from '../types';

interface EdgeWrapperProps extends AbsolutePosition {
    orientation: EdgeItem['orientation'];
    appearance: EdgeAppearance;
}

export const EdgeWrapper = styled.div.attrs<EdgeWrapperProps>(
    ({ x, y, w, h }) => {
        return {
            style: {
                width: w,
                height: h,
                transform: `translate(${x}px, ${y}px)`,
            },
        };
    }
)`
    display: block;
    position: absolute;
    background: transparent;
    box-sizing: border-box;

    &:hover {
        cursor: ${(props) =>
            props.orientation === 'vertical' ? 'col-resize' : 'row-resize'};
        background: ${pickVariant<EdgeWrapperProps>('appearance', {
            invalid: customThemeVariables.invalidDropTargetBackgroundColor,
            dropTarget: customThemeVariables.validDropTargetBackgroundColor,
            normal: 'transparent',
            hidden: 'transparent',
        })};
        border: ${pickVariant<EdgeWrapperProps>('appearance', {
            invalid: `1px solid ${customThemeVariables.invalidDropTargetBorderColor}`,
            dropTarget: `1px solid ${customThemeVariables.validDropTargetBorderColor}`,
            normal: 'none',
            hidden: 'none',
        })};
        transition: background 0.2s ease-out;
    }
`;

type EdgeComponentProps = AbsolutePosition & { appearance: EdgeAppearance };

export const EdgeComponent = styled.div.attrs<EdgeComponentProps>(
    ({ x, y, w, h }) => {
        return {
            style: {
                width: w,
                height: h,
                transform: `translate(${x}px, ${y}px)`,
            },
        };
    }
)<EdgeComponentProps>`
    display: block;
    position: absolute;
    opacity: 0.35;
    pointer-events: none;
    background-color: ${(props) =>
        props.appearance === 'dropTarget'
            ? customThemeVariables.validDropTargetBorderColor
            : pick({
                  enterprise: variables.accentColorL10,
                  prisma: variables.interactiveColorPrimary,
              })};
    ${EdgeWrapper}:hover + & {
        opacity: 0.7;
    }
`;

interface EdgeProps {
    itemId: string;
    x: number;
    y: number;
    w: number;
    h: number;
    orientation: EdgeItem['orientation'];
    isCanvasEdge?: boolean;
    padding?: number;
    appearance: EdgeAppearance;
    onMouseDown: (e: React.MouseEvent, { id }: { id: string }) => void;
    'data-test-edge-position': string;
}

export const Edge = ({
    itemId,
    x,
    y,
    h,
    w,
    orientation,
    isCanvasEdge,
    padding = 0,
    appearance = 'normal',
    onMouseDown,
    'data-test-edge-position': edgePositionTestHook,
}: EdgeProps): JSX.Element | null => {
    const handleMouseDown = useCallback(
        (e) => {
            e.preventDefault();
            e.stopPropagation();
            onMouseDown(e, { id: itemId });
        },
        [onMouseDown, itemId]
    );

    /**
     * edges are not rendered if:
     * 1. their appearance is hidden OR
     * 2. edge is a canvas edge AND its appearance is not a dropTarget
     * Exception to this case is the bottom canvas edge which is always visible. Other canvas edges (top, left, right) are visible only when a viz is moving
     * TODO: Follow up with design to re-evaluate display condition for canvas edges as they are currently not discoverable
     */
    if (
        appearance === 'hidden' ||
        (isCanvasEdge &&
            appearance !== 'dropTarget' &&
            (orientation === 'vertical' || y + padding === 0))
    ) {
        return null;
    }

    const coord = { x, y, w, h };
    const shift = padding / 2;

    if (orientation === 'horizontal') {
        coord.y += shift;
        coord.h -= padding;
    }

    if (orientation === 'vertical') {
        coord.x += shift;
        coord.w -= padding;
    }

    return (
        <>
            <EdgeWrapper
                x={x}
                y={y}
                w={w}
                h={h}
                orientation={orientation}
                appearance={appearance}
                onMouseDown={handleMouseDown}
                data-test="edge"
                data-test-edge-position={edgePositionTestHook}
            />
            <EdgeComponent
                x={coord.x}
                y={coord.y}
                w={coord.w}
                h={coord.h}
                appearance={appearance}
            />
        </>
    );
};
