/**
 * Helper to store dom event emitted from visualization or input since we don't
 * want to send them as part of a redux action payload.
 */
// todo: may need to further refine the type.
type DomEvent = MouseEvent;
export default class EventRegistry {
    idSeq = 0;

    storedEvents: Record<string, DomEvent> = {};

    registerEvent(e: DomEvent) {
        this.idSeq =
            this.idSeq + 1 < Number.MAX_SAFE_INTEGER ? this.idSeq + 1 : 0;
        const id = `event${this.idSeq}`;
        this.storedEvents[id] = e;
        return id;
    }

    retrieveEvent(id: string): DomEvent | undefined {
        const event = this.storedEvents[id];
        delete this.storedEvents[id];
        return event;
    }

    teardown() {
        this.cleanup();
    }

    cleanup() {
        this.storedEvents = {};
        this.idSeq = 0;
    }
}
