import { createContext, useContext } from 'react';

const ActionMenusContext = createContext<JSX.Element[] | null>(null);

export const ActionMenusContextProvider = ActionMenusContext.Provider;

export const useActionMenus: () => JSX.Element[] = () => {
    const ctx = useContext(ActionMenusContext);
    if (ctx === null) {
        throw new Error(
            'useActionMenus can only be used in a component that is a child of a ActionMenusContextProvider'
        );
    }

    return ctx;
};

export default ActionMenusContext;
