import type {
    AbsoluteLayoutStructure,
    ConnectedLineItem,
    Coordinate,
    ConnectedLinePosition,
    SelectedItem,
} from '@splunk/dashboard-types';
import type { Offset, LineDirection, LayoutStructureState } from '../types';
import {
    updateLineAbsPosition,
    isLineConnected,
    disconnectLine,
} from './layoutUtils';
import { computePortPosition, getBlockItem } from './blockUtils';

interface ComputeLineAbsPositionArgs {
    layoutStructure: AbsoluteLayoutStructure;
    position: ConnectedLinePosition;
}

export const computeLineAbsPosition = ({
    layoutStructure,
    position,
}: ComputeLineAbsPositionArgs): {
    from: Coordinate;
    to: Coordinate;
} => {
    let { from, to } = position;

    if ('item' in from && 'port' in from) {
        const blockItem = getBlockItem({ layoutStructure, id: from.item });
        from = computePortPosition(blockItem, from.port);
    }
    if ('item' in to && 'port' in to) {
        const blockItem = getBlockItem({ layoutStructure, id: to.item });
        to = computePortPosition(blockItem, to.port);
    }
    return {
        from,
        to,
    };
};

interface FindSelectedLineItemsArgs {
    layoutStructure: AbsoluteLayoutStructure;
    selectedItems: SelectedItem[];
}

export const findSelectedLineItems = ({
    layoutStructure,
    selectedItems,
}: FindSelectedLineItemsArgs): ConnectedLineItem[] =>
    layoutStructure.filter(
        ({ item, type }) =>
            selectedItems.findIndex(
                ({ id }) => item === id && type === 'line'
            ) >= 0
    ) as ConnectedLineItem[];

export interface HandleSingleLineMoveArgs {
    offset: Offset;
    lineId: string;
    layoutStructure: AbsoluteLayoutStructure;
}

export const handleSingleLineMove = ({
    lineId,
    offset,
    layoutStructure,
}: HandleSingleLineMoveArgs): ConnectedLineItem => {
    let line = layoutStructure.find(
        (item) => item.item === lineId
    ) as ConnectedLineItem;

    const linePosition = computeLineAbsPosition({
        layoutStructure,
        position: line.position,
    });

    if (isLineConnected({ line, dir: 'from' })) {
        line = disconnectLine({
            line,
            dir: 'from',
            absPos: linePosition.from,
        });
    }

    if (isLineConnected({ line, dir: 'to' })) {
        line = disconnectLine({
            line,
            dir: 'to',
            absPos: linePosition.to,
        });
    }

    line = updateLineAbsPosition({
        line,
        dir: 'from',
        offset,
    });

    line = updateLineAbsPosition({
        line,
        dir: 'to',
        offset,
    });

    return line;
};

export interface HandleSingleLineDragStartArgs {
    lineId: string;
    layoutStructure: AbsoluteLayoutStructure;
    lineDir: LineDirection;
}

export const handleSingleLineDragStart = ({
    lineId,
    layoutStructure,
    lineDir,
}: HandleSingleLineDragStartArgs): ConnectedLineItem => {
    let line = layoutStructure.find(
        (item) => item.item === lineId
    ) as ConnectedLineItem;

    const linePosition = computeLineAbsPosition({
        layoutStructure,
        position: line.position,
    });

    if (
        isLineConnected({
            line,
            dir: lineDir,
        })
    ) {
        line = disconnectLine({
            line,
            dir: lineDir,
            absPos: linePosition[lineDir],
        });
    }

    return line;
};

interface GetAllLineItemsArgs {
    layoutStructure: AbsoluteLayoutStructure;
    layoutStructureState?: LayoutStructureState;
}

export const getAllLineItems = ({
    layoutStructure,
    layoutStructureState = {},
}: GetAllLineItemsArgs): ConnectedLineItem[] => {
    return layoutStructure
        .map((item) => layoutStructureState[item.item] ?? item)
        .filter(({ type }) => type === 'line') as ConnectedLineItem[];
};
