/* eslint-disable max-classes-per-file */
import * as React from 'react';
import styled from 'styled-components';
import { omit, get, includes } from 'lodash';
import pick from '@splunk/themes/pick';
import variables from '@splunk/themes/variables';
import { withSanitizedProps } from '@splunk/visualizations-shared/SanitizeProps';
import SvgChoropleth, { SvgChoroplethProps } from '../SvgChoropleth';
import { Bounds, DataPoint } from '../SvgChoropleth/src/utils/ICoordinateTransformation';
import GeoJsonFeatureCollection from '../SvgChoropleth/src/utils/GeoJsonFeatureCollection';
import { GeoFeatureGroup } from '../SvgChoropleth/src/utils/GeoTypes';
import BoundsUtils from '../SvgChoropleth/src/utils/BoundsUtils';

export interface GeoJsonChoroplethProps {
    name: string;
    geoJson: { features: Record<string, unknown>[] };
    data: { featureIDs: string[]; values: any[]; fill: string[] };
    projection: string;
    sourceBounds: Bounds;
    logicalBounds: Bounds;
    fillColor: string;
    strokeColor: string;
    strokeHighlightColor?: string;
    backgroundColor?: string;
    selector: string;
    onFeatureHover?: (featureId: string) => void;
    onClick?: (sourceCoordinates: DataPoint, featureId: string) => void;
    geoFeatureGroups?: GeoFeatureGroup[];
    theme?: Record<string, unknown>;
}

export interface GeoJsonChoroplethState {
    featureCollections: GeoJsonFeatureCollection[];
    toolTip: { label; value };
}

export const LABEL_FONT_SIZE = 14;

export const round2 = function round2(value: number): number {
    return Math.round(value * 100) / 100;
};

// todo: maybe can refactor to use `computeTextSize` in @splunk/visualizations-shared/domUtils.js
export const estimateTextWidth = function estimateTextWidth(text: string, fontsize: number): number {
    if (text == null) {
        return 0;
    }
    const avgLowerCaseWidth = 0.55 * fontsize;
    const avgUpperCaseWidth = 0.7 * fontsize;
    let length = 0;
    for (let pos = 0; pos < text.length; pos += 1) {
        const c = text.charAt(pos);
        if (includes('Iij.,;:!lt/', c)) {
            length += 0.2 * fontsize;
        } else if (c === '_') {
            length += 0.8 * fontsize;
        } else {
            length += c === c.toUpperCase() ? avgUpperCaseWidth : avgLowerCaseWidth;
        }
    }
    return length;
};

// todo: maybe can refactor to use `formatNumber` in ../utils/numberUtils
export const valueText = function valueText(value: number): string {
    if (value === 0) {
        return '0';
    }

    if (typeof value === 'string') {
        return value;
    }

    const magnitude = Math.floor(Math.log10(Math.abs(value)) / 3);
    const divisor = 10 ** (3 * magnitude);

    const suffixList = ['', 'k', 'm', 'bn', 'tn', 'qn'];
    const suffix = magnitude < 0 ? `/${suffixList[-magnitude]}` : suffixList[magnitude];

    return `${round2(value / divisor)}${suffix}`;
};

export function createFeatureCollections(props: GeoJsonChoroplethProps): GeoJsonFeatureCollection[] {
    const { sourceBounds, logicalBounds, projection, geoJson, geoFeatureGroups } = props;
    const featureCollections = [];
    if (geoFeatureGroups) {
        geoFeatureGroups.forEach((g: GeoFeatureGroup): void => {
            featureCollections.push(
                new GeoJsonFeatureCollection(
                    `${props.name}.${g.name}`,
                    logicalBounds,
                    projection,
                    geoJson,
                    g,
                    sourceBounds
                )
            );
        });
    } else {
        featureCollections.push(
            new GeoJsonFeatureCollection(
                props.name,
                logicalBounds,
                projection,
                geoJson,
                undefined,
                sourceBounds
            )
        );
    }
    return featureCollections;
}

class TTArgType {
    reference: React.Ref<any>;

    left: number;

    top: number;

    label: string;

    value: any;
}

const TooltipBubble = styled.div.attrs(() => ({
    key: 'bubble',
}))<{
    maxTextWidth: number;
}>`
    background-color: ${pick({
        enterprise: {
            light: variables.white, // #171d21, so enterpriseDark.defaultBackgroundColor
            dark: '#27292e', // enterpriseBackgroundColor
        },
        prisma: variables.backgroundColorPopup,
    })};
    border-radius: 5px;
    padding: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 5px 14px 0 rgba(0, 0, 100, 0.12);
    width: ${props => props.maxTextWidth}px;
    transition: width 100ms;
`;

const TooltipLabel = styled.div.attrs(() => ({
    'data-test': 'geojson-choropleth-tooltip-label',
}))<{
    maxTextWidth: number;
}>`
    margin-bottom: 2px;
    width: ${props => props.maxTextWidth}px;
    text-align: center;
    color: ${pick({
        enterprise: {
            // unfortunately cannot access variables.textGray directly colors since variables are theme-aware
            light: 'rgba(0, 0, 0, 0.65)', // equivalent to enterpriseDark.textGray
            dark: 'rgba(255, 255, 255, 0.7)', // equivalent to enterprise.textGray
        },
        prisma: variables.contentColorDefault,
    })};
`;

const ArrowDown = styled.div`
    position: relative;
    top: -1px;
    width: 0px;
    height: 0px;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-top: 8px solid
        ${pick({
            enterprise: {
                light: variables.white, // #171d21, so enterpriseDark.defaultBackgroundColor
                dark: '#27292e', // enterpriseBackgroundColor
            },
            prisma: variables.backgroundColorPopup,
        })};
`;

const TooltipValue = styled.div.attrs(() => ({
    'data-test': 'geojson-choropleth-tooltip-value',
}))`
    color: ${pick({
        enterprise: {
            // unfortunately cannot access variables.textGray directly colors since variables are theme-aware
            light: '#2c2c2c', // equivalent to enterpriseDark.textGray
            dark: 'rgba(255, 255, 255, 0.98)', // equivalent to enterprise.textGray
        },
        prisma: variables.contentColorActive,
    })};
`;

interface GeoJsonChoroplethContainerProps {
    backgroundColor: string;
}

const GeoJsonChoroplethContainer = styled.div.attrs(() => ({
    'data-test': 'geojson-choropleth-container',
}))<GeoJsonChoroplethContainerProps>`
    background-color: ${props =>
        props.backgroundColor ||
        pick({
            enterprise: {
                dark: variables.black,
                light: variables.backgroundColorSidebar,
            },
            prisma: variables.backgroundColorSidebar,
        })};
    position: relative;
`;

const ToolTip = function ToolTip(arg: TTArgType): React.ReactElement {
    const { reference, left, top, label, value } = arg;

    const maxTextWidth =
        Math.max(estimateTextWidth(label, LABEL_FONT_SIZE), estimateTextWidth(value, LABEL_FONT_SIZE)) + 10;

    return (
        <div
            key="tooltip"
            ref={reference}
            style={{
                position: 'absolute',
                top,
                left,
                pointerEvents: 'none',
                visibility: label || value ? 'visible' : 'hidden',
            }}
        >
            <div
                style={{
                    position: 'relative',
                    left: '-50%',
                    top: '-56px',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'flex-start',
                    fontSize: LABEL_FONT_SIZE,
                }}
            >
                <TooltipBubble maxTextWidth={maxTextWidth}>
                    <TooltipLabel maxTextWidth={maxTextWidth}> {label} </TooltipLabel>
                    <TooltipValue>{valueText(value)}</TooltipValue>
                </TooltipBubble>
                <ArrowDown />
            </div>
        </div>
    );
};

class GeoJsonChoropleth extends React.Component<GeoJsonChoroplethProps, GeoJsonChoroplethState> {
    containerRef: React.RefObject<HTMLDivElement> = React.createRef();

    toolTipRef: React.RefObject<HTMLDivElement> = React.createRef();

    cursorPos: { x: number; y: number };

    constructor(props) {
        super(props);
        this.state = { featureCollections: createFeatureCollections(props), toolTip: null };
        this.cursorPos = { x: 0, y: 0 };

        this.handleMouseMove = this.handleMouseMove.bind(this);
        this.handleFeatureHover = this.handleFeatureHover.bind(this);
    }

    componentDidUpdate(prevProps): void {
        const { sourceBounds, logicalBounds, projection, geoJson } = this.props;
        if (
            prevProps.geoJson !== geoJson ||
            prevProps.sourceBounds !== sourceBounds ||
            prevProps.logicalBounds !== logicalBounds ||
            prevProps.projection !== projection
        ) {
            // eslint-disable-next-line react/no-did-update-set-state
            this.setState({ featureCollections: createFeatureCollections(this.props) });
        }
    }

    handleMouseMove(ev: React.MouseEvent<HTMLDivElement, MouseEvent>): void {
        this.cursorPos = this.getLocalCoords(ev.clientX, ev.clientY);
        if (this.toolTipRef.current) {
            this.toolTipRef.current.style.left = `${this.cursorPos.x}px`;
            this.toolTipRef.current.style.top = `${this.cursorPos.y}px`;
        }
    }

    handleFeatureHover({ featureId, value, label }): void {
        if (featureId) {
            this.setState({ toolTip: { label: label || featureId, value: value || '-no data-' } });
        } else {
            this.setState({ toolTip: null });
        }

        const externalHandler = this.props.onFeatureHover;
        if (externalHandler) {
            externalHandler(featureId);
        }
    }

    getLocalCoords(clientX: number, clientY: number): { x: number; y: number } {
        const rect = this.containerRef.current.getBoundingClientRect();
        const physX = rect.left;
        const physY = rect.top;
        const scaleX = this.containerRef.current.offsetWidth / rect.width;
        const scaleY = this.containerRef.current.offsetHeight / rect.height;
        return { x: (clientX - physX) * scaleX, y: (clientY - physY) * scaleY };
    }

    public render(): React.ReactElement {
        const children = [];
        const relevantProps = omit(this.props, ['sourceBounds', 'logicalBounds', 'projection']);
        const { toolTip } = this.state;

        // create one child SvgChoropleth for each GeoJsonFeatureCollection
        this.state.featureCollections.forEach((f: GeoJsonFeatureCollection): void => {
            const props: SvgChoroplethProps = {
                ...relevantProps,
                onFeatureHover: this.handleFeatureHover,
                featureCollection: f,
            };
            const svgChoropleth = React.createElement(SvgChoropleth, props);
            let style = {};
            const logicalBounds = get(f, ['group', 'logicalBounds']);
            if (logicalBounds) {
                style = BoundsUtils.logicalBoundsToCssStyle(logicalBounds);
            }
            children.push(React.createElement('div', { key: f.name, style }, svgChoropleth));
        });
        return (
            <GeoJsonChoroplethContainer
                ref={this.containerRef}
                onMouseMove={this.handleMouseMove}
                backgroundColor={this.props.backgroundColor}
            >
                {children}
                <ToolTip
                    reference={this.toolTipRef}
                    left={this.cursorPos.x}
                    top={this.cursorPos.y}
                    label={get(toolTip, 'label')}
                    value={get(toolTip, 'value')}
                />
            </GeoJsonChoroplethContainer>
        );
    }
}

export default withSanitizedProps(GeoJsonChoropleth);
