import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { InputLayout } from '@splunk/dashboard-layouts';
import {
    selectAreTokensReadyToSubmit,
    selectLayout,
    submitTokens,
    useDispatch,
    useSelector,
} from '@splunk/dashboard-state';
import { DEFAULT_TOKEN_NAMESPACE } from '@splunk/dashboard-utils';
import { useApiRegistry } from '@splunk/dashboard-context';
import InputContainer from '../connected/ConnectedInputContainer';

// TODO: ConnectedInputContainer doesn't appear to require or use the index prop. Figure this out later.
const renderInput = ({
    inputId,
    index,
}: {
    inputId: string;
    index: number;
}) => <InputContainer key={inputId} id={inputId} index={index} />;

const emptyGlobalInputs: string[] = [];

export default () => {
    const apiRegistry = useApiRegistry();
    const dispatch = useDispatch();

    const inputsRef = useRef<HTMLDivElement | null>(null);

    const globalInputsApi = useMemo(() => {
        return {
            getInputsDomElement: () => {
                return inputsRef.current;
            },
        };
    }, [inputsRef]);

    const onSubmitButtonClick = useCallback(
        () => dispatch(submitTokens(DEFAULT_TOKEN_NAMESPACE)),
        [dispatch]
    );

    const layout = useSelector(selectLayout);
    const submitButtonDisabled = !useSelector(selectAreTokensReadyToSubmit);

    useEffect(() => {
        apiRegistry.registerGlobalInputsApi(globalInputsApi);
        return () => {
            apiRegistry.removeGlobalInputsApi();
        };
    }, [apiRegistry, globalInputsApi]);

    const submitButton = useMemo(
        () => !!layout?.options?.submitButton,
        [layout]
    );
    const globalInputs = useMemo(
        () => layout?.globalInputs ?? emptyGlobalInputs,
        [layout]
    );

    return (
        <InputLayout
            submitButtonDisabled={submitButtonDisabled}
            onSubmitButtonClick={onSubmitButtonClick}
            submitButton={submitButton}
            inputStructure={globalInputs}
            renderInput={renderInput}
            ref={inputsRef}
        />
    );
};
