import { _ } from '@splunk/ui-utils/i18n';
import { map, get } from 'lodash';
import { COLOR_OR_TOKEN_PATTERN_WITH_RGBA, getPattern } from '@splunk/visualizations-shared/schemaUtils';
import { COLOR_EDITOR_PALETTE } from '@splunk/visualizations-shared/colorConstants';
import { Table as TableIcon } from '@splunk/visualization-icons';
import { Table as TablePlaceholderIcon } from '@splunk/visualization-icons/placeholders';
import variables from '@splunk/themes/variables';
import pick from '@splunk/themes/pick';
import { enhanceConfig, generatePrecisionValues } from '../common/utils/configUtils';
import { VizConfig } from '../common/interfaces/VizConfig';
import { DataContract } from '../common/interfaces/DataContract';
import { VizSize } from '../common/interfaces/VizSize';
import { DefaultContext } from '../common/interfaces/DefaultContext';
import { OptionsSchema } from '../common/interfaces/OptionsSchema';
import { EditorConfig } from '../common/interfaces/Editor';
import { VizBehavior } from '../common/interfaces/VizBehavior';
import { ThemedDefaults } from '../common/interfaces/ThemedDefaults';
import {
    validHeaderVisibilities,
    validAlignments,
    validTextOverflows,
    validCellRenderers,
    validSparklineTypes,
    validFontSizes,
} from './consts';
import { VizCategory } from '../common/interfaces/VizCategory';

const dataContract: DataContract = {
    requiredDataSources: [
        {
            name: 'primary',
            description: 'DataSource that powers the visualization',
        },
    ],
    optionalDataSources: [],
    initialRequestParams: {
        primary: (options: { count?: number } = {}): Record<string, unknown> => ({
            offset: 0,
            count: options.count ? options.count : 10,
            requestTotalCount: true,
        }),
    },
};

const size: VizSize = {
    initialWidth: 300,
    initialHeight: 300,
};

const defaultContext: DefaultContext = {
    tableAlignByType: [
        { match: 'string', value: 'left' },
        { match: 'number', value: 'right' },
        { match: 'time', value: 'left' },
        { match: 'color', value: 'left' },
        { match: 'array', value: 'left' },
        { match: 'sparkline', value: 'center' },
        { match: 'unknown', value: 'left' },
        { match: 'null', value: 'left' },
    ],
    tableCellTypeByType: [
        { match: 'string', value: 'TextCell' },
        { match: 'number', value: 'TextCell' },
        { match: 'time', value: 'TextCell' },
        { match: 'color', value: 'TextCell' },
        { match: 'array', value: 'ArrayCell' },
        { match: 'sparkline', value: 'SparklineCell' },
        { match: 'unknown', value: 'TextCell' },
        { match: 'null', value: 'TextCell' },
    ],

    // NOTE: These contexts are used by the table background color editor.
    // Please be careful removing/renaming/altering them.
    // There are unit tests and visual test that will capture breakages.
    tableRowColorMaxContrast: {
        colors: ['> themes.textColor', '> themes.inverseTextColor'],
        default: '> themes.textColor',
    },
    tableRowBackgroundColorEvenConfig: {
        channel: 'hsv.v',
        value: '*0.96',
    },
    tableHeaderBackgroundColorConfig: {
        channel: 'hsv.v',
        value: '*0.9',
    },
    tableRowBackgroundColorsByTheme: ['> themes.rowBackgroundColorOdd'],
    tableAltRowBackgroundColorsByTheme: ['> themes.rowBackgroundColorOdd', '> themes.rowBackgroundColorEven'],
    tableRowBackgroundColorsByBackgroundColor: ['> backgroundColor'],
    tableAltRowBackgroundColorsByBackgroundColor: [
        '> backgroundColor',
        '> backgroundColor | setColorChannel(tableRowBackgroundColorEvenConfig)',
    ],
};

const unitPositionConfig = {
    label: _('Units position'),
    editor: 'editor.select',
    option: 'unitPosition',
    editorProps: {
        values: [
            { label: _('Before'), value: 'before' },
            { label: _('After'), value: 'after' },
        ],
    },
};

const unitLabelConfig = {
    label: _('Unit label'),
    option: 'unit',
    editor: 'editor.text',
};

const timeFormattingConfig = {
    label: _('Date & Time formatting'),
    option: 'format',
    editor: 'editor.text',
    editorProps: {
        tooltip: _('For example: DD-MM-YYYY'),
    },
};

const sequentialColorConfig = [
    { to: 20, value: '#D41F1F' },
    { from: 20, to: 40, value: '#D94E17' },
    { from: 40, to: 60, value: '#CBA700' },
    { from: 60, to: 80, value: '#669922' },
    { from: 80, value: '#118832' },
];

const matchColorConfig = [{ match: '', value: '#5C33FF' }];

const optionsSchema: OptionsSchema = {
    backgroundColor: {
        default: '> themes.defaultBackgroundColor',
        description:
            'Specify the color for the background. You might use a data source to apply the color. The default for enterprise light is "#FFFFFF". The default for enterprise dark is "#000000". The default for prisma dark is "#0B0C0E".',
        pattern: COLOR_OR_TOKEN_PATTERN_WITH_RGBA,
        type: 'string',
    },
    columnFormat: {
        description:
            'Lets you format an individual column in a table. Specify a column in a table as a field, followed by a property. For more information, see the Column Format Align - Left example on the Examples page.',
        type: 'object',
        patternProperties: {
            '.*': {
                description: 'Customization for column property',
                type: 'object',
                properties: {
                    align: {
                        description:
                            'Specify the alignment for each cell in a column. Valid alignments include "left", "center", and "right".',
                        type: 'array',
                        items: {
                            type: 'string',
                            enum: validAlignments.concat(),
                        },
                    },
                    cellTypes: {
                        description:
                            'Specify how to render data in cells. Valid cell renders include "TextCell", "ArrayCell", "SparklineCell".',
                        type: 'array',
                        items: {
                            type: 'string',
                            enum: validCellRenderers.concat(),
                        },
                    },
                    data: {
                        description: 'Specify data points to be displayed in the cells of a column.',
                        type: 'array',
                        items: {
                            oneOf: [
                                { type: 'string' },
                                { type: 'number' },
                                { type: 'array', items: { type: ['string', 'number'] } },
                            ],
                        },
                    },
                    rowBackgroundColors: {
                        description:
                            'Specify a list of colors, in hexadecimal code, for the background color of each column of the table. For example, ["#2C333B", "32373D"].',
                        type: 'array',
                        items: {
                            type: 'string',
                            pattern: COLOR_OR_TOKEN_PATTERN_WITH_RGBA,
                        },
                    },
                    rowColors: {
                        description:
                            'Specify a list of colors, in hexadecimal code, for the text color of the cells of a column. For example, ["#2C333B", "32373D"].',
                        type: 'array',
                        items: {
                            type: 'string',
                            pattern: COLOR_OR_TOKEN_PATTERN_WITH_RGBA,
                        },
                    },
                    sparklineAreaColors: {
                        description:
                            'Specify a list of colors, in hexadecimal code, for the sparkline area of each column. For example, ["#2C333B", "32373D"].',
                        type: 'array',
                        items: {
                            type: 'string',
                            pattern: COLOR_OR_TOKEN_PATTERN_WITH_RGBA,
                        },
                    },
                    sparklineColors: {
                        description:
                            'Specify a list of colors, in hexadecimal code, for the sparkline stroke of each column. For example, ["#2C333B", "32373D"].',
                        type: 'array',
                        items: {
                            type: 'string',
                            pattern: COLOR_OR_TOKEN_PATTERN_WITH_RGBA,
                        },
                    },
                    sparklineTypes: {
                        description:
                            'Choose either area or line formatting for the Sparkline. Valid sparkline types include "line" and "area".',
                        type: 'array',
                        items: {
                            type: 'string',
                            enum: validSparklineTypes.concat(),
                        },
                    },
                    textOverflow: {
                        description:
                            'Controls how the text should be truncated within a particular column. Valid types include "anywhere", "break-word", "ellipsis".  Columns are "break-word" styled by default when "textOverflow" is not configured.',
                        type: 'string',
                        pattern: getPattern(validTextOverflows),
                        enum: validTextOverflows.concat(),
                    },
                    width: {
                        description:
                            'Specify, in pixels, the width of the column. The default displays a minimum width of at least 90, unless the table needs to filled further. The minimum width is 30px.',
                        type: 'number',
                        minimum: 30,
                    },
                },
            },
        },
    },
    count: {
        description: 'Specify the maximum number of rows to display.',
        type: 'number',
        default: 10,
    },
    font: {
        default: 'proportional',
        description: 'Specify the font style to use for table content.',
        type: 'string',
        pattern: getPattern(['proportional', 'monospace']),
    },
    fontSize: {
        default: 'default',
        description:
            'Specify the font size to use for table content. Font sizes range from extra small (10px) to large (16px) with the default being 14px.',
        type: 'string',
        pattern: getPattern(validFontSizes),
    },
    headers: {
        default: '> table | getField()',
        description:
            "Specify an array of headers to display on the table. Though these can be statically listed, it's best to use the dynamic default, as it will return the fields of your search.",
        type: 'array',
        items: {
            type: 'string',
        },
    },
    headerVisibility: {
        default: 'inline',
        description: 'Specify how to display the table header row.',
        pattern: getPattern(validHeaderVisibilities),
        enum: validHeaderVisibilities.concat(),
        type: 'string',
    },
    paginateDataSourceKey: {
        default: 'primary',
        description: 'Specify the data source key for pagination and sorting.',
        type: 'string',
    },
    showInternalFields: {
        default: true,
        description:
            'Specify whether to show internal fields that start with an underscore. The _time field will still be shown if false.',
        type: 'boolean',
    },
    showRowNumbers: {
        default: false,
        description: 'Show row numbers in the first column.',
        type: 'boolean',
    },
    table: {
        default: '> primary',
        description: 'Two dimensional array of data to be displayed in the table.',
        type: 'array',
        items: {
            type: 'array',
            items: {
                oneOf: [
                    { type: 'string' },
                    { type: 'number' },
                    { type: 'array', items: { type: ['string', 'number'] } },
                ],
            },
        },
    },
    tableFormat: {
        description: 'Lets you set global options for a table.',
        type: 'object',
        properties: {
            align: {
                default: '> table | type() | matchValue(tableAlignByType)',
                description:
                    'Specify alignment for each cell in the table. Valid alignments include "left", "center", and "right".',
                type: 'array',
                items: {
                    type: 'array',
                    items: {
                        type: 'string',
                        enum: validAlignments.concat(),
                    },
                },
            },
            cellTypes: {
                default: '> table | type() | matchValue(tableCellTypeByType)',
                description:
                    'Specify how to render data in cells. Valid cell renders include "TextCell", "ArrayCell", "SparklineCell".',
                type: 'array',
                items: {
                    type: 'array',
                    items: {
                        type: 'string',
                        enum: validCellRenderers.concat(),
                    },
                },
            },
            data: {
                description:
                    'Two dimensional data to be displayed in the table. This is usually formatted data instead of raw data from search.',
                type: 'array',
                items: {
                    type: 'array',
                    items: {
                        oneOf: [
                            { type: 'string' },
                            { type: 'number' },
                            { type: 'array', items: { type: ['string', 'number'] } },
                        ],
                    },
                },
            },
            headerBackgroundColor: {
                default: '> themes.defaultHeaderBackgroundColor',
                description:
                    'Specify the table header background color using a hexadecimal code. For example, "#FFFFFF".',
                pattern: COLOR_OR_TOKEN_PATTERN_WITH_RGBA,
                type: 'string',
            },
            headerColor: {
                description:
                    'Specify the table header text color using a hexadecimal code. For example, "#FFFFFF".',
                pattern: COLOR_OR_TOKEN_PATTERN_WITH_RGBA,
                type: 'string',
            },
            rowBackgroundColors: {
                default: '> table | seriesByIndex(0) | pick(tableAltRowBackgroundColorsByTheme)',
                description:
                    'Specify a list of colors, in hexadecimal code, for the background color of each row of the table. For example, ["#2C333B", "32373D"].',
                type: 'array',
                items: {
                    type: 'array',
                    items: {
                        type: 'string',
                        pattern: COLOR_OR_TOKEN_PATTERN_WITH_RGBA,
                    },
                },
            },
            rowColors: {
                description:
                    'Specify a list of colors, in hexadecimal code, for the text color of each row of the table. For example, ["#2C333B", "32373D"].',
                type: 'array',
                items: {
                    type: 'array',
                    items: {
                        type: 'string',
                        pattern: COLOR_OR_TOKEN_PATTERN_WITH_RGBA,
                    },
                },
            },
            sparklineAreaColors: {
                description:
                    'Specify a list of colors, in hexadecimal code, for the sparkline area of each row of the table. For example, ["#2C333B", "32373D"].',
                type: 'array',
                items: {
                    type: 'array',
                    items: {
                        type: 'string',
                        pattern: COLOR_OR_TOKEN_PATTERN_WITH_RGBA,
                    },
                },
            },
            sparklineColors: {
                description:
                    'Specify a list of colors, in hexadecimal code, for the sparkline stroke of each row of the table. For example, ["#2C333B", "32373D"].',
                type: 'array',
                items: {
                    type: 'array',
                    items: {
                        type: 'string',
                        pattern: COLOR_OR_TOKEN_PATTERN_WITH_RGBA,
                    },
                },
            },
            sparklineTypes: {
                description: 'Choose either area or line formatting for the Sparkline.',
                type: 'array',
                items: {
                    type: 'array',
                    items: {
                        type: 'string',
                        enum: validSparklineTypes.concat(),
                    },
                },
            },
        },
    },
};

const themes: ThemedDefaults = {
    defaultBackgroundColor: props =>
        pick({
            enterprise: {
                dark: variables.black(props),
                light: variables.backgroundColor(props),
            },
            prisma: variables.backgroundColorSidebar(props),
        })(props),
    defaultHeaderBackgroundColor: props =>
        pick({
            enterprise: {
                dark: variables.gray20(props),
                light: variables.gray92(props),
            },
            prisma: variables.backgroundColor(props),
        })(props),
    rowBackgroundColorEven: props =>
        pick({
            enterprise: {
                dark: variables.black(props),
                light: variables.gray96(props),
            },
            prisma: variables.backgroundColorPage(props),
        })(props),
    rowBackgroundColorOdd: props =>
        pick({
            enterprise: {
                dark: '#0D1012',
                light: variables.backgroundColor(props),
            },
            prisma: variables.backgroundColorSidebar(props),
        })(props),
    textColor: props =>
        pick({
            enterprise: variables.textColor(props),
            prisma: variables.contentColorDefault(props),
        })(props),
    inverseTextColor: props =>
        pick({
            enterprise: {
                dark: variables.contentColorInverted(props), // equal to textColor in enterprise
                light: variables.white(props), // equal to textColor in enterpriseDark
            },
            prisma: variables.contentColorInverted(props),
        })(props),
};

const backgroundColorPaletteSet = new Set([
    ...COLOR_EDITOR_PALETTE,
    ...map(themes, (t): string => get(t, 'defaultBackgroundColor')),
]);
const EDITOR_LABEL_WIDTH = 100;
const editorConfig: EditorConfig[] = [
    {
        label: _('Data display'),
        layout: [
            [
                {
                    label: _('Rows displayed'),
                    option: 'count',
                    editor: 'editor.number',
                    editorProps: {
                        min: 0,
                        labelWidth: EDITOR_LABEL_WIDTH,
                    },
                },
            ],
            [
                {
                    label: _('Row numbers'),
                    option: 'showRowNumbers',
                    editor: 'editor.checkbox',
                    editorProps: {
                        labelWidth: EDITOR_LABEL_WIDTH,
                    },
                },
            ],
            [
                {
                    label: _('Internal fields'),
                    option: 'showInternalFields',
                    editor: 'editor.checkbox',
                    editorProps: {
                        labelWidth: EDITOR_LABEL_WIDTH,
                    },
                },
            ],
            [
                {
                    label: _('Header row'),
                    option: 'headerVisibility',
                    editor: 'editor.select',
                    editorProps: {
                        values: [
                            { label: _('Inline'), value: 'inline' },
                            { label: _('Fixed'), value: 'fixed' },
                            { label: _('Hidden'), value: 'none' },
                        ],
                        labelWidth: EDITOR_LABEL_WIDTH,
                    },
                },
            ],
        ],
    },
    {
        label: _('Color and style'),
        layout: [
            [
                {
                    key: 'backgroundColor',
                    editor: 'editor.tableBackgroundColor',
                    editorProps: {
                        themes,
                        labelPosition: 'top',
                        labelWidth: EDITOR_LABEL_WIDTH,
                        palette: [...backgroundColorPaletteSet],
                    },
                },
            ],
            [
                {
                    option: 'font',
                    label: _('Font'),
                    editor: 'editor.select',
                    editorProps: {
                        values: [
                            { label: _('Proportional (Default)'), value: 'proportional' },
                            { label: _('Monospace'), value: 'monospace' },
                        ],
                        labelPosition: 'top',
                        labelWidth: EDITOR_LABEL_WIDTH,
                    },
                },
            ],
            [
                {
                    option: 'fontSize',
                    label: _('Font size'),
                    editor: 'editor.select',
                    editorProps: {
                        values: [
                            { label: _('Extra Small (font 10px, row 24px)'), value: 'extraSmall' },
                            { label: _('Small (font 12px, row 24px)'), value: 'small' },
                            { label: _('Default (font 14px, row 32px)'), value: 'default' },
                            { label: _('Large (font 16px, row 36px)'), value: 'large' },
                        ],
                        labelPosition: 'top',
                        labelWidth: EDITOR_LABEL_WIDTH,
                    },
                },
            ],
            [
                {
                    label: _('Column-specific formatting'),
                    editor: 'editor.tableColumnFormatter',
                    editorProps: {
                        // Table column coloring does not adhere to same DSL structure as other dynamically colored options
                        // we use the FormatterEditor directly and not the DynamicColorEditor's provided DSL builders
                        columnColoringConfig: {
                            coloringTypes: [
                                {
                                    label: _('Text'),
                                    value: 'rowColors',
                                },
                                {
                                    label: _('Background'),
                                    value: 'rowBackgroundColors',
                                },
                            ],
                            formatters: [
                                {
                                    label: _('Ranges'),
                                    value: 'rangeValue',
                                    defaults: {
                                        rowBackgroundColors: sequentialColorConfig,
                                        rowColors: sequentialColorConfig,
                                    },
                                },
                                {
                                    label: _('Matches'),
                                    value: 'matchValue',
                                    defaults: {
                                        rowBackgroundColors: matchColorConfig,
                                        rowColors: matchColorConfig,
                                    },
                                },
                            ],
                            themes,
                        },
                        defaultOptionsByType: {
                            number: {
                                thousandSeparated: false,
                                unitPosition: 'after',
                            },
                            string: {
                                unitPosition: 'after',
                            },
                        },
                        flyoutConfig: {
                            number: [
                                [
                                    {
                                        ...unitPositionConfig,
                                    },
                                    {
                                        ...unitLabelConfig,
                                    },
                                ],
                                [
                                    {
                                        label: _('Precision'),
                                        option: 'precision',
                                        editor: 'editor.select',
                                        editorProps: {
                                            values: generatePrecisionValues(20),
                                        },
                                    },
                                    {
                                        label: _('Thousand separators'),
                                        option: 'thousandSeparated',
                                        editor: 'editor.radioBar',
                                        editorProps: {
                                            values: [
                                                { label: _('Off'), value: false },
                                                { label: _('On'), value: true },
                                            ],
                                        },
                                    },
                                ],
                            ],
                            // TODO(fkurniawan): fill in with proper editor components
                            sparkline: [],
                            string: [
                                [
                                    {
                                        ...unitPositionConfig,
                                    },
                                    {
                                        ...unitLabelConfig,
                                    },
                                ],
                            ],
                            time: [
                                [
                                    {
                                        ...timeFormattingConfig,
                                    },
                                ],
                            ],
                        },
                        value: ({ context, options }) => {
                            return {
                                context,
                                columnFormat: options.columnFormat,
                                tableFormat: options.tableFormat,
                            };
                        },
                    },
                    key: 'TableColumnFormatterEditor',
                },
            ],
        ],
    },
];

/**
 * visualization configuration
 */
const config: VizConfig = {
    /**
     * unique viz key
     */
    key: 'splunk.table',
    /**
     * viz name
     */
    name: 'Table',
    category: VizCategory.TABLE,
    /**
     * viz icon
     */
    icon: TableIcon,
    placeholderIcon: TablePlaceholderIcon,
    dataContract,
    size,
    defaultContext,
    optionsSchema,
    editorConfig,
    events: {
        'cell.click': {
            description: 'triggered when user clicks a table cell',
        },
    },
    supports: [
        VizBehavior.DYNAMIC_OPTIONS,
        VizBehavior.EVENTS,
        VizBehavior.PAGE_AND_SORT,
        VizBehavior.PLACEHOLDER,
    ],
    themes,
};

export default enhanceConfig(config);
