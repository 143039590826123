/* eslint-disable max-classes-per-file */
/* eslint-disable class-methods-use-this */
import { GeometryStream } from './GeometryStream';
import ICoordinateTransformation, {
    geoSpace,
    logicalSpace,
    DataPoint,
    Bounds,
} from './ICoordinateTransformation';
import CoordinateTransformation from './CoordinateTransformation';
import BoundsCollector from './BoundsCollector';
import { NodeTreeReactGenerator } from './NodeTreeReactGenerator';

// fixme TODO: should the identity transform really have different source and target spaces? That's a bit confusing
// as one would expect the identity transform could be inserted anywhere in a TransformationChain
export class IdentityTransform extends CoordinateTransformation {
    public constructor() {
        super(geoSpace, logicalSpace);
    }

    public transform(sourceCoords: DataPoint): DataPoint {
        return { x: sourceCoords.long, y: sourceCoords.lat };
    }

    public transformBack(targetCoords: DataPoint): DataPoint {
        return { lat: targetCoords.y, long: targetCoords.x };
    }
}

export class GeometryStreamSVGRenderer extends NodeTreeReactGenerator implements GeometryStream {
    public transformer: ICoordinateTransformation = new IdentityTransform();

    public computeBoundsOnly = false;

    private boundsCollector = new BoundsCollector();

    public constructor(transformer?: ICoordinateTransformation) {
        super();
        if (transformer) {
            this.transformer = transformer;
        }
    }

    public addPoint(p: DataPoint): void {
        this.boundsCollector.addPoint(p);
        if (this.computeBoundsOnly) {
            return;
        }
        const el = super.peek();
        const { points } = el.attributes;
        if (points) {
            el.attributes.points += ' ';
        } else {
            el.attributes.points = ''; // initialize
        }
        const { x, y } = this.transformer.transform(p);
        /*
        if(!x || !y){
            throw new Error("DataPoint did not contain an x and y coordinate (cannot be rendered to SVG).: " + JSON.stringify(p));
        }
         */
        el.attributes.points += `${x},${y}`; // FIXME TODO probably should not look for hard coded x and y in DataPoint
    }

    public beginNode(name: string, attributes = {}): void {
        if (this.computeBoundsOnly) {
            return;
        }
        super.beginNode(name, attributes);
    }

    public endNode(): void {
        if (this.computeBoundsOnly) {
            return;
        }
        super.endNode();
    }

    // automatically compute the logicalBounds based on the points that were added. This is 'auto' style of bounding box.
    public getBounds(): Bounds {
        return this.boundsCollector.getBounds();
    }

    public clear(): void {
        super.clear();
        this.boundsCollector = new BoundsCollector();
    }
}
