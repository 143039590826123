import { createSelector } from '@reduxjs/toolkit';
import { useSelector } from '../providers/StateProvider';
import { selectDataSourceNames } from '../reducers/definition';
import { selectReadOnlyTokenNamespaces } from '../reducers/readOnlyTokenNamespaces';

const selectDisallowedNames = createSelector(
    [selectDataSourceNames, selectReadOnlyTokenNamespaces],
    (existingDataSourceNames, readonlyNamespaces) => {
        return {
            existingDataSourceNames,
            readonlyNamespaces,
        };
    }
);

export const useGetDisallowedDataSourceNames = () =>
    useSelector(selectDisallowedNames);
