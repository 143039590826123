/**
 * check if an icon is considered as remote icon
 * @param {*} icon
 */
export const isRemoteIcon = (icon = '') => icon.indexOf('://') > -1;

/**
 * check if an icon is a dataURI
 * @param {*} icon
 */
export const isDataURI = (icon = '') => icon.startsWith('data:image/svg+xml;base64');

/*
 * check whether need update fill for svg children elements
 * @params {Object} style
 * @params {String} style.fillAttr                     <path fill="red" .../>
 * @params {String} style.fillStyle.                   <path style="fill: red;" />
 * @params {String} style.strokeAttr.                  <path stroke="red" .../>
 * @params {String} style.strokeStyle                  <path style="stroke: red;" .../>
 * @returns {Boolean} true means need
 * remove fill style and change the fill attribute     <path fill="currentColor" />
 */
export const shouldChangeFill = ({ fillAttr, fillStyle, strokeAttr, strokeStyle }) => {
    const noneFillAttr = !!fillAttr && (fillAttr === 'none' || fillAttr === 'transparent');
    const noneFillStyle = !!fillStyle && (fillStyle === 'none' || fillStyle === 'transparent');
    const noFillHasStroke = !fillAttr && !fillStyle && (!!strokeAttr || !!strokeStyle);
    return !(noneFillAttr || noneFillStyle || noFillHasStroke);
};

/*
 * check whether need update stroke for svg children elements
 * @params {Object} style
 * @params {String} style.fillAttr                     <path fill="red" .../>
 * @params {String} style.fillStyle.                   <path style="fill: red;" />
 * @params {String} style.strokeAttr.                  <path stroke="red" .../>
 * @params {String} style.strokeStyle                  <path style="stroke: red;" .../>
 * @returns {Boolean} true means need
 * remove stroke style and change the stroke attribute     <path stroke="currentColor" />
 */
export const shouldChangeStroke = ({ fillAttr, fillStyle, strokeAttr, strokeStyle }) => {
    const noneStrokeAttr = !!strokeAttr && (strokeAttr === 'none' || strokeAttr === 'transparent');
    const noneStrokeStyle = !!strokeStyle && (strokeStyle === 'none' || strokeStyle === 'transparent');
    const hasFill = !!fillAttr || !!fillStyle;
    const noStroke = !strokeAttr && !strokeStyle;
    return !(noneStrokeAttr || noneStrokeStyle || hasFill || noStroke);
};

/*
 * base64 encoding
 * @param {String} str
 * @return {Base64} string using based-64 encoding
 */
export const b64EncodeUnicode = str =>
    // first we use encodeURIComponent to get percent-encoded UTF-8,
    // then we convert the percent encodings into raw bytes which
    // can be fed into btoa.
    btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, (match, p1) => String.fromCharCode(`0x${p1}`)));
