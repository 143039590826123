import { COLOR_OR_TOKEN_PATTERN, getPattern } from '@splunk/visualizations-shared/schemaUtils';

export default {
    'axisTitleX.text': { description: 'Specify the title of the x-axis.', type: 'string' },
    'axisTitleY.text': { description: 'Specify the title of the y-axis.', type: 'string' },
    'axisTitleX.visibility': {
        default: 'visible',
        description: 'Specify whether to show the title of the x-axis.',
        pattern: getPattern(['visible', 'collapsed']),
        type: 'string',
    },
    'axisTitleY.visibility': {
        default: 'visible',
        description: 'Specify whether to show the title of the y-axis.',
        pattern: getPattern(['visible', 'collapsed']),
        type: 'string',
    },
    seriesColors: {
        description: `Specify the Hex code for colors used for a series. For example, ['#FF0000', '#0000FF', '#008000'].
            The default for light and dark mode is ['#1E93C6', '#F2B827', '#D6563C', '#6A5C9E', '#31A35F'].
            The default for SCP mode is ['#A9F5E7', '#A870EF',  '#1E6271', '#45D4BA', '#602CA1',  '#9AE6F7', '#6CD0F0', '#2F8AA7',  '#422879', '#F29BAC', '#E85B79', '#C6335F',  '#FDAF93', '#FB865C', '#E9643A',  '#7EEFDA', '#26AA92', '#207865', '#C093F9', '#8747DA',  '#4EB2D2', '#912344',  '#AD3F20', '#D44C20', '#FFC34E',  '#95651D',  '#C0891E', '#ECAE11', '#FCD9A4', '#78062A'].
            Note: Option type 'string' has been deprecated.`,
        type: ['array', 'string'],
        items: { type: 'string' },
    },
    fieldColors: {
        description: `Specify the Hex code for color used for a field. For example: {'count': '#008000', 'percent': '#FFA500'}. Note: Option type 'string' has been deprecated.`,
        type: ['object', 'string'],
    },
    backgroundColor: {
        description: 'Specify the Hex code for color used for a background. For example: "#0000FF".',
        pattern: COLOR_OR_TOKEN_PATTERN,
        type: 'string',
    },
    fontColor: {
        description:
            'Specify the Hex code for color used for fonts (axis labels, legends). For example: "#0000FF".',
        pattern: COLOR_OR_TOKEN_PATTERN,
        type: 'string',
    },
    foregroundColor: {
        description: 'Specify the Hex code for color used for a foreground. For example: "#0000FF".',
        pattern: COLOR_OR_TOKEN_PATTERN,
        type: 'string',
    },
    'axisY.abbreviation': {
        default: 'none',
        description: 'Specify whether to abbrevite large y-axis values with the closest SI prefix.',
        pattern: getPattern(['none', 'auto']),
        type: 'string',
    },
    'axisX.abbreviation': {
        default: 'none',
        description: 'Specify whether to abbrevite large x-axis values with the closest SI prefix.',
        pattern: getPattern(['none', 'auto']),
        type: 'string',
    },
    'axisY.scale': {
        default: 'linear',
        description: 'Specify the type of scale that applies to a numerical y-axis.',
        pattern: getPattern(['linear', 'log']),
        type: 'string',
    },
    'axisX.scale': {
        default: 'linear',
        description: 'Specify the type of scale that applies to a numerical x-axis.',
        pattern: getPattern(['linear', 'log']),
        type: 'string',
    },
    'axisY.minimumNumber': {
        default: 'auto',
        description: 'Specify the minimum number for the visible y-axis.',
        type: ['number', 'string'],
    },
    'axisX.minimumNumber': {
        default: 'auto',
        description: 'specify the minimum number for the visible x-axis.',
        type: ['number', 'string'],
    },
    'axisY.maximumNumber': {
        default: 'auto',
        description: 'Specify the maximum number for the visible y-axis.',
        type: ['number', 'string'],
    },
    'axisX.maximumNumber': {
        default: 'auto',
        description: 'Specify the maximum number for the visible x-axis.',
        type: ['number', 'string'],
    },
    'axisLabelsY.majorUnit': {
        default: 'auto',
        description:
            'Specify the spacing unit at which to place major tick marks along the numeric y-axis. By default, this value is automatically calculated based on the scale of the related axis.',
        type: ['number', 'string'],
    },
    'axisLabelsX.majorUnit': {
        default: 'auto',
        description:
            'Specify the spacing unit at which to place major tick marks along the numeric x-axis. By default, this value is automatically calculated based on the scale of the related axis.',
        type: ['number', 'string'],
    },
    'axisLabelsX.majorLabelStyle.rotation': {
        default: '0',
        description: 'Specify the rotation of the axis label in degrees.',
        enum: [-90, -45, 0, 45, 90],
        type: 'number',
    },
    'axisLabelsY.axisVisibility': {
        default: 'hide',
        description: 'Specify whether the y-axis line is visible.',
        pattern: getPattern(['show', 'hide']),
        type: 'string',
    },
    'axisLabelsX.axisVisibility': {
        default: 'hide',
        description: 'Specify whether the x-axis line is visible.',
        pattern: getPattern(['show', 'hide']),
        type: 'string',
    },
    'axisLabelsX.majorTickVisibility': {
        default: 'auto',
        description: 'Specify whether major tick marks are visible on the x-axis.',
        pattern: getPattern(['auto', 'show', 'hide']),
        type: 'string',
    },
    'axisLabelsY.majorTickVisibility': {
        default: 'auto',
        description: 'Specify whether major tick marks are visible on the y-axis.',
        pattern: getPattern(['auto', 'show', 'hide']),
        type: 'string',
    },
    'axisLabelsY.minorTickVisibility': {
        default: 'auto',
        description: 'Specify whether minor tick marks are visible on the y-axis.',
        pattern: getPattern(['auto', 'show', 'hide']),
        type: 'string',
    },
    'axisLabelsX.minorTickVisibility': {
        default: 'auto',
        description: 'Specify whether minor tick marks are visible on the x-axis.',
        pattern: getPattern(['auto', 'show', 'hide']),
        type: 'string',
    },
    'axisLabelsY.majorTickSize': {
        default: 6,
        description: 'Specify whether major tick marks are visible on the y-axis.',
        type: 'number',
    },
    'axisLabelsX.majorTickSize': {
        default: 6,
        description: 'Specify whether major tick marks are svisible on the x-axis.',
        type: 'number',
    },
    'axisLabelsY.minorTickSize': {
        default: 6,
        description: 'Specify, in pixles, the size of minor tick marks on the y-axis.',
        type: 'number',
    },
    'axisLabelsX.minorTickSize': {
        default: 6,
        description: 'Specify, in pixles, the size of minor tick marks on the x-axis.',
        type: 'number',
    },
    'axisLabelsX.majorLabelVisibility': {
        default: 'auto',
        description: 'Specify whether major tick mark labels are visible on the x-axis.',
        pattern: getPattern(['auto', 'show', 'hide']),
        type: 'string',
    },
    'axisLabelsY.majorLabelVisibility': {
        default: 'auto',
        description: 'Specify whether major tick mark labels are visible on the y-axis.',
        pattern: getPattern(['auto', 'show', 'hide']),
        type: 'string',
    },
    'axisLabelsY.extendsAxisRange': {
        default: true,
        description: 'Specify whether the y-axis should be extended to snap to whole major tick marks.',
        type: 'boolean',
    },
    'axisLabelsX.extendsAxisRange': {
        default: true,
        description: 'Specify whether the x-axis should be extended to snap to whole major tick marks.',
        type: 'boolean',
    },
    'axisY.includeZero': {
        default: false,
        description: 'Specify whether the y-axis chart includes zero.',
        type: 'boolean',
    },
    'axisX.includeZero': {
        default: false,
        description: 'Specify whether the x-axis chart includes zero.',
        type: 'boolean',
    },
    'axisLabelsX.integerUnits': {
        default: false,
        description: 'Specify whether the major unit should be rounded to the nearest integer on the x-axis.',
        type: 'boolean',
    },
    'gridLinesY.showMajorLines': {
        default: true,
        description: 'Specify whether major grid lines are visible on the y-axis.',
        type: 'boolean',
    },
    'gridLinesY.showMinorLines': {
        default: false,
        description: 'Specify whether minor grid lines are visible on the y-axis.',
        type: 'boolean',
    },
    'gridLinesX.showMinorLines': {
        default: false,
        description: 'Specify whether minor grid lines are visible on the x-axis.',
        type: 'boolean',
    },
    'legend.placement': {
        default: 'right',
        description: 'Specify the location of the legend on the panel.',
        pattern: getPattern(['right', 'left', 'top', 'bottom', 'none']),
        type: 'string',
    },
    'legend.labelStyle.overflowMode': {
        default: 'ellipsisMiddle',
        description:
            'Specify how to display legend labels when they overflow the layout boundaries by replacing overflow text with an ellipsis.',
        pattern: getPattern(['ellipsisEnd', 'ellipsisMiddle', 'ellipsisStart', 'ellipsisNone']),
        type: 'string',
    },
    'chart.markerSize': {
        default: 4,
        description: 'Specify the marker size of the scatter chart.',
        type: 'number',
    },
    'chart.resultTruncationLimit': {
        default: 50000,
        description: 'Specify the number of data points rendered in a chart.',
        type: 'number',
    },
};
