import { isEqual, each, pick, keys, memoize, omit } from 'lodash';
import { _ } from '@splunk/ui-utils/i18n';
import {
    replaceTokens,
    replaceTokensForObject,
    replaceTokensForObjectWithMetadata,
    safeReplaceTokensForObject,
    extractTokensFromObject,
} from '@splunk/dashboard-utils';
import type {
    DataSourceDefinition,
    TokenState,
    VisualizationDefinition,
} from '@splunk/dashboard-types';
import { WAITING_FOR_INPUT_DEFINITION } from '../constants';

// these are optimizations that avoid re-renders
export const noTokens = Object.freeze({});
export const noReadOnlyTokenNamespaces = Object.freeze<string[]>([]);

export const tokenIsDefinedAndSet = (
    tokens: Record<string, Record<string, unknown>>,
    namespace: string,
    tokenName: string
): boolean =>
    // Make sure the namespace and token are both defined
    Object.prototype.hasOwnProperty.call(
        tokens?.[namespace] ?? {},
        tokenName
    ) &&
    // Check that the token isn't explicitly set to undefined (shouldn't be possible)
    typeof tokens[namespace][tokenName] !== 'undefined' &&
    // Check that the token isn't null
    tokens[namespace][tokenName] !== null &&
    // Check that the token isn't an empty string
    tokens[namespace][tokenName] !== '';

/**
 * resolver that takes the memoized function arguments (def, token), extracts the tokens used in the def
 * and returns the stringified def + extracted tokens, which is used as a cache key
 * @param {Object} def Viz/Layout/Input/Datasources definition
 * @param {Object} tokens
 * @returns {String}
 */
export const memoizeResolver = (
    def: unknown,
    tokens: TokenState = {}
): string => {
    const parsedTokens: TokenState = {};
    extractTokensFromObject(def).forEach(
        ({ namespace, name }: { namespace: string; name: string }) => {
            // If the token is defined in the namespace and is truthy, `false`, or `0` then parse it
            if (tokenIsDefinedAndSet(tokens, namespace, name)) {
                parsedTokens[namespace] ??= {};
                parsedTokens[namespace][name] = tokens[namespace][name];
            }
        }
    );
    return JSON.stringify([def, parsedTokens]);
};

export const replaceTokenForDataSources = memoize(
    (
        dataSourceDefs: Record<string, DataSourceDefinition>,
        tokens: TokenState = {}
    ) => {
        const resolved: Record<string, DataSourceDefinition> = {};
        each(dataSourceDefs, (def, id) => {
            const rawOptions = dataSourceDefs[id].options;
            if (rawOptions == null) {
                // only replace token in options
                resolved[id] = {
                    ...def,
                };
                return;
            }

            const { value: resolvedOptions, missedTokens } =
                replaceTokensForObjectWithMetadata(rawOptions, tokens);

            if (missedTokens.length) {
                const extendOptions: DataSourceDefinition['options'] = {};
                if (def.options?.extend) {
                    extendOptions.extend = def.options.extend;
                }
                resolved[id] = {
                    ...WAITING_FOR_INPUT_DEFINITION,
                    options: {
                        ...extendOptions,
                        ...WAITING_FOR_INPUT_DEFINITION.options,
                        meta: {
                            originalDefinition: def,
                            missedTokens,
                        },
                    },
                };
            } else {
                resolved[id] = {
                    ...dataSourceDefs[id],
                    options: resolvedOptions as typeof rawOptions,
                };
            }
        });

        const chainResolved: Record<string, DataSourceDefinition> = {};

        // if a chain data source's parent is '_ds.snapshot_', then the chain data source should be '_ds.snapshot_' too
        each(resolved, (def, id) => {
            let currentId = id;
            let hasToken = false;
            const parentOptionsMeta: DataSourceDefinition = {
                type: '',
                options: {},
            };
            // this is to detect circular reference
            const visited = new Set([currentId]);

            while (typeof resolved[currentId].options?.extend === 'string') {
                // the while condition guarantees it is a string
                const parentId = resolved[currentId].options?.extend as string;

                if (visited.has(parentId)) {
                    throw Error(
                        _(
                            `Data source ${parentId} creates an invalid circular reference; check parent data sources referenced by extend property.`
                        )
                    );
                }

                const parentDef = resolved[parentId];

                if (!parentDef) {
                    break;
                }

                visited.add(parentId);

                if (parentDef.type === '_ds.snapshot_') {
                    // parent has unresolved token
                    hasToken = true;
                    // clone the options of the parent
                    const parentOptions =
                        parentDef.options ||
                        WAITING_FOR_INPUT_DEFINITION.options;
                    parentOptionsMeta.type = parentDef.type;
                    parentOptionsMeta.options = { ...parentOptions };
                    break;
                }

                if (typeof parentDef.options?.extend === 'string') {
                    // traverse up the chain
                    currentId = parentId;
                } else {
                    // has reached the base data source and it doesn't have unresolved token
                    break;
                }
            }

            if (hasToken) {
                chainResolved[id] = {
                    ...WAITING_FOR_INPUT_DEFINITION,
                    options: {
                        extend: def.options?.extend,
                        ...WAITING_FOR_INPUT_DEFINITION.options,
                        meta: {
                            originalDefinition: def,
                            missedTokens: (
                                parentOptionsMeta?.options?.meta as Record<
                                    string,
                                    unknown
                                >
                            )?.missedTokens,
                        },
                    },
                };
            } else {
                chainResolved[id] = {
                    ...def,
                };
            }
        });

        return chainResolved;
    },
    memoizeResolver
);

export const replaceTokenForInput = memoize((inputDef, tokens = {}) => {
    return {
        ...inputDef,
        title: replaceTokens(inputDef.title, tokens),
        options: replaceTokensForObject(inputDef.options, tokens),
        canvasAlignment: replaceTokens(inputDef.canvasAlignment, tokens),
    };
}, memoizeResolver);

export const replaceTokenForVisualization = memoize(
    (
        vizDef: VisualizationDefinition,
        tokens: TokenState = {}
    ): VisualizationDefinition => {
        return {
            ...vizDef,
            title:
                vizDef.title && (replaceTokens(vizDef.title, tokens) as string),
            description:
                vizDef.description &&
                (replaceTokens(vizDef.description, tokens) as string),
            options:
                vizDef.options &&
                (replaceTokensForObject(
                    vizDef.options,
                    tokens
                ) as VisualizationDefinition['options']),
            context:
                vizDef.context &&
                (replaceTokensForObject(
                    vizDef.context,
                    tokens
                ) as VisualizationDefinition['context']),
        };
    },
    memoizeResolver
);

// For security reasons by default we disable using tokens in the layout options backgroundImage.src
export const replaceTokenForLayout = memoize(
    (layout = {}, tokens = {}, denyList = ['backgroundImage.src']) => {
        return {
            ...layout,
            options: safeReplaceTokensForObject(
                layout.options,
                tokens,
                denyList
            ),
        };
    },
    memoizeResolver
);

// This is a separate selector than replaceTokenForLayout because we're using a featureFlag,
// enableTokensInBackgroundImage, which is not in the state
export const replaceTokenForLayoutIncludingBackgroundImage = memoize(
    (layout, tokens) => replaceTokenForLayout(layout, tokens, []),
    memoizeResolver
);

export const contains = (tokens = {}, subset = {}): boolean =>
    isEqual(subset, pick(tokens, keys(subset)));
// Return the key/value pairs in tokens that are not in omitTokens
export const filterExisting = <T>(tokens = {}, omitTokens = {}): Partial<T> =>
    omit(tokens, keys(omitTokens));
