import { mapKeys, mapValues } from 'lodash';
import { hashString } from '@splunk/dashboard-utils';
import type { PlainObject, FilterDefinition } from '../FilterTypes';
import { categoryToPrefix } from '../utils';

const hashDataSourceIdInViz: FilterDefinition = (viz) =>
    mapValues(viz, (optionValue: PlainObject, optionKey) =>
        optionKey === 'dataSources'
            ? mapValues(optionValue, (dsId: string) =>
                  hashString(dsId, categoryToPrefix.dataSources)
              )
            : optionValue
    );

const hashTokenIdsInDefaults = (value: PlainObject, category: string) => {
    const hashNamespaceId = (_value: unknown, key: string) =>
        hashString(key, 'namespace_');

    const hashTokenId = (_value: unknown, key: string) =>
        hashString(key, 'token_');

    if (category === 'tokens') {
        return mapValues(
            mapKeys(value, hashNamespaceId),
            (tokens: PlainObject) => mapKeys(tokens, hashTokenId)
        );
    }

    return value;
};

const filterIds: FilterDefinition = (definition) =>
    mapValues(definition, (value: PlainObject, category) => {
        const hashKey = (_value: unknown, key: string) =>
            hashString(key, categoryToPrefix[category]);

        if (['visualizations', 'inputs'].includes(category)) {
            return mapValues(mapKeys(value, hashKey), hashDataSourceIdInViz);
        }

        if (category === 'dataSources') {
            return mapKeys(value, hashKey);
        }

        if (category === 'layout') {
            return mapValues(value, (v: unknown[], k: string) => {
                if (k === 'globalInputs' && Array.isArray(v)) {
                    return (v as string[]).map((inputId: string) =>
                        hashString(inputId, categoryToPrefix.inputs)
                    );
                }

                if (k === 'structure') {
                    return (v as PlainObject[]).map((item: PlainObject) => ({
                        ...item,
                        item: hashString(
                            item.item as string,
                            categoryToPrefix.visualizations
                        ),
                    }));
                }

                return v;
            });
        }

        if (category === 'defaults') {
            return mapValues(value, hashTokenIdsInDefaults);
        }

        return value;
    });

export default filterIds;
