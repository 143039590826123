import console from './console';

export const printHoistedPropError = ({
    oldPropName,
    oldComponent,
    newComponent = 'DashboardContextProvider',
    provideSubject,
    newPropName,
    updateMessage,
    updateMethod,
}: {
    oldPropName: string;
    oldComponent: string;
    newComponent?: string;
    provideSubject: string;
    newPropName: string;
    updateMessage?: string;
    updateMethod?: string;
}) => {
    const message = `The "${oldPropName}" prop on ${oldComponent} has been removed. To provide ${provideSubject}, use the new prop "${newPropName}" on ${newComponent}.`;
    const updateProcess = updateMessage
        ? ` ${updateMessage}, use the DashboardCoreApi method ${updateMethod} instead.`
        : '';

    console.error(message + updateProcess);
};
