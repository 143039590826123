import React from 'react';
import { _ } from '@splunk/ui-utils/i18n';
import GaugeMarker from '@splunk/react-icons/enterprise/GaugeMarker';

const MarkerGauge = (
    props: React.ComponentProps<typeof GaugeMarker>
): ReturnType<typeof GaugeMarker> => (
    <GaugeMarker {...props} screenReaderText={_('Marker Gauge')} />
);

export default MarkerGauge;
