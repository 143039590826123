import * as React from 'react';
import type { Map } from 'maplibre-gl';
import type { GeoJsonDataType } from '@splunk/visualizations-shared/mapUtils';
import { isEqual, uniq } from 'lodash';
import { ChoroplethLayerType, getScaledOpacities, formatMetadata, DEFAULT_RESULT_LIMIT } from './MapUtils';
import MarkerWrapper from './MarkerWrapper';
import type { TooltipProps } from './Tooltip';

const getShapeId = id => `shape-${id}`;
const getSourceId = id => `source-${id}`;
const getStrokeId = id => `stroke-${id}`;

export const compareGeom = (prev: GeoJsonDataType, next: GeoJsonDataType) => {
    if (prev !== null && next !== null) {
        return prev.type === next.type && isEqual(prev.coordinates, next.coordinates);
    }
    return prev === null && next === null;
};

export const compareChoropleth = (prevProps: ChoroplethProps, nextProps: ChoroplethProps): boolean => {
    return (
        compareGeom(prevProps.geomData, nextProps.geomData) &&
        prevProps.id === nextProps.id &&
        prevProps.map === nextProps.map &&
        prevProps.areaId === nextProps.areaId &&
        prevProps.areaValue === nextProps.areaValue &&
        prevProps.color === nextProps.color &&
        prevProps.opacity === nextProps.opacity &&
        prevProps.strokeColor === nextProps.strokeColor &&
        prevProps.isThemeChanged === nextProps.isThemeChanged &&
        isEqual(prevProps.additionalFields, nextProps.additionalFields) &&
        prevProps.handleMapClick.toString() === nextProps.handleMapClick.toString()
    );
};

export const cleanupChoropleth = (
    shapeId: string,
    sourceId: string,
    strokeId: string,
    map: React.MutableRefObject<Map>
) => {
    // catch errors where map is undefined
    let currLayer;
    let currSource;
    let currStrokeLayer;
    try {
        currLayer = map?.current?.getLayer(shapeId);
        currStrokeLayer = map?.current?.getLayer(strokeId);
        currSource = map?.current?.getSource(sourceId);
    } catch {
        return;
    }
    if (currLayer) {
        map.current.removeLayer(shapeId);
    }
    if (currStrokeLayer) {
        map.current.removeLayer(strokeId);
    }
    if (currSource) {
        map.current.removeSource(sourceId);
    }
};

interface ChoroplethProps {
    id: string;
    map: React.MutableRefObject<Map>;
    areaId: string;
    areaValue: number;
    geomData: GeoJsonDataType;
    color: string;
    opacity?: number;
    strokeColor?: string;
    isThemeChanged: boolean;
    additionalFields?: TooltipProps['additionalFields'];
    handleMapClick?: (e) => void;
}

export const Choropleth = React.memo(
    ({
        id,
        areaId,
        areaValue,
        geomData,
        map,
        color,
        opacity,
        strokeColor,
        isThemeChanged,
        additionalFields,
        handleMapClick,
    }: ChoroplethProps): JSX.Element => {
        const sourceId = getSourceId(id);
        const shapeId = getShapeId(id);
        const strokeId = getStrokeId(id);
        const formattedGeomData = {
            type: 'Feature',
            geometry: geomData,
        };
        const [isTooltipOpen, setTooltipOpenStatus] = React.useState(false);
        const [mouseLat, setmouseLat] = React.useState(0);
        const [mouseLng, setmouseLng] = React.useState(0);

        const openTooltip = React.useCallback(() => {
            setTooltipOpenStatus(true);
        }, []);

        const closeTooltip = React.useCallback(() => {
            setTooltipOpenStatus(false);
        }, []);

        const updateChoropleth = React.useCallback(() => {
            // Apply changes by removing the existing choropleth and creating a new one
            // instead of mutating the current
            cleanupChoropleth(shapeId, sourceId, strokeId, map);

            // In order to set a different color and opacity to each choropleth region
            // we are creating a different layer for each region, and each layer requires a source
            map.current.addSource(sourceId, {
                type: 'geojson',
                data: formattedGeomData,
            });
            map.current.addLayer({
                id: shapeId,
                type: 'fill',
                source: sourceId,
                layout: {},
                paint: {
                    'fill-opacity': opacity,
                    'fill-color': color,
                },
            });

            if (strokeColor !== 'transparent') {
                map.current.addLayer({
                    id: strokeId,
                    type: 'line',
                    source: sourceId,
                    layout: {},
                    paint: {
                        'line-color': strokeColor,
                        'line-width': 1,
                    },
                });
            }

            map.current.on('mousemove', shapeId, e => {
                const mouseLngLat = e.lngLat;
                setmouseLat(mouseLngLat.lat);
                setmouseLng(mouseLngLat.lng);
                openTooltip();
                map.current.getCanvas().style.cursor = 'pointer'; // eslint-disable-line no-param-reassign
            });
            map.current.on('mouseleave', shapeId, () => {
                closeTooltip();
                map.current.getCanvas().style.cursor = ''; // eslint-disable-line no-param-reassign
            });
            map.current.on('click', shapeId, e => {
                handleMapClick(e);
            });
        }, [shapeId, sourceId, map, color, opacity, formattedGeomData]);

        React.useEffect(() => {
            updateChoropleth();
        }, [map, geomData, color, opacity, id, strokeColor]);

        // Clean up on unmount
        React.useEffect(() => () => cleanupChoropleth(shapeId, sourceId, strokeId, map), []);

        if (isThemeChanged) {
            // Theme changes will reset the style layers of the entire map
            // so we need to rerender the choropleths
            updateChoropleth();
        }

        return (
            <MarkerWrapper
                isChoroplethTooltipOpen={isTooltipOpen}
                additionalFields={additionalFields}
                latitude={mouseLat}
                longitude={mouseLng}
                map={map}
                areaId={areaId}
                areaValue={areaValue}
            >
                {/* an invisible div used to anchor the tooltip */}
                <div data-test="choropleth-anchor" style={{ padding: '24px', visibility: 'hidden' }} />
            </MarkerWrapper>
        );
    },
    compareChoropleth
);

Choropleth.displayName = 'MapChoropleth';

interface ChoroplethLayerProps {
    choroplethLayer: ChoroplethLayerType;
    map: React.MutableRefObject<Map>;
    isThemeChanged: boolean;
    setIsThemeChanged: (isThemeChanged: boolean) => void;
    onMapClick?: ({ e, payload }) => void;
}

const ChoroplethLayer = (props: ChoroplethLayerProps): JSX.Element => {
    const { map, choroplethLayer, isThemeChanged, setIsThemeChanged, onMapClick } = props;
    const cfgOpacity = Math.min(Math.max(choroplethLayer?.choroplethOpacity, 0), 1);
    const scaledOpacities = getScaledOpacities(choroplethLayer?.dataColors, cfgOpacity) ?? [];
    const colors = choroplethLayer?.dataColors?.map(color => (color?.length > 7 ? color.slice(0, 7) : color));

    const limit = Math.min(
        choroplethLayer?.areaIds?.length,
        choroplethLayer?.areaValues?.length,
        choroplethLayer?.geom?.length ?? 0,
        choroplethLayer?.resultLimit ? choroplethLayer.resultLimit : DEFAULT_RESULT_LIMIT
    );
    const areaIdSliced = choroplethLayer?.areaIds?.slice(0, limit) ?? [];
    // generate a unique id if areaIds are not unique
    const uniqueAreaIdSliced =
        uniq(areaIdSliced).length !== areaIdSliced.length
            ? areaIdSliced.map((id, i) => `${id}${i}`)
            : areaIdSliced;
    const additionalFields = choroplethLayer?.metadata
        ? choroplethLayer.metadata.map(choroplethMetadata =>
              choroplethLayer.additionalTooltipFields.map(field => ({
                  name: field,
                  value: choroplethMetadata[field],
              }))
          )
        : [];

    const handleMapClick = (e, i) => {
        const payload = formatMetadata(choroplethLayer, i);
        onMapClick({ e, payload });
    };

    if (!map || !choroplethLayer || !choroplethLayer.geom) {
        return <></>;
    }

    if (isThemeChanged) {
        setIsThemeChanged(false);
    }

    return (
        <div>
            {uniqueAreaIdSliced.map((id, i) => {
                const areaId = areaIdSliced[i];
                const areaValue = choroplethLayer.areaValues[i];

                return (
                    <Choropleth
                        key={id}
                        id={id}
                        geomData={choroplethLayer.geom[i]}
                        map={map}
                        color={colors[i]}
                        opacity={scaledOpacities[i]}
                        strokeColor={choroplethLayer.choroplethStrokeColor}
                        isThemeChanged={isThemeChanged}
                        areaId={areaId}
                        areaValue={areaValue}
                        additionalFields={additionalFields[i]}
                        handleMapClick={e => handleMapClick(e, i)}
                    />
                );
            })}
        </div>
    );
};

export default ChoroplethLayer;
