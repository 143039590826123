import React from 'react';
import styled from 'styled-components';
import T from 'prop-types';
import Paginator from '@splunk/react-ui/Paginator';
import pick from '@splunk/themes/pick';
import variables from '@splunk/themes/variables';
import { getSettingsFromThemedProps } from '@splunk/themes';
import SplunkThemeProvider from '@splunk/themes/SplunkThemeProvider';
import { getMeta, getRequestParams } from '@splunk/dashboard-visualizations/utils/propUtils';

export const NormalPaginatorContainer = styled.div`
    color: ${pick({
        enterprise: variables.textColor,
        prisma: variables.contentColorActive,
    })};
    overflow: hidden;
    position: relative;
    text-align: right;
    height: 32px;
    padding: 5px 5px 5px 0px;
`;

export const FixedHeightPaginatorContainer = styled(NormalPaginatorContainer)`
    position: absolute;
    bottom: 0;
    left: 0;
    right: 10px;
`;
/**
 * Returns the current page based on dataset parameters
 * @param {number} offset=0
 * @param {number} perPage=0
 * @returns {number|boolean} current page number based on offset and results per page, or false if perPage is 0
 */
export const getCurrentPage = (offset = 0, perPage = 0) => {
    if (perPage === 0) {
        return false;
    }
    return Math.floor(offset / perPage) + 1;
};

/**
 * Returns the total pages based on dataset parameters
 * @param {number} total=0 total pages in dataset
 * @param {number} perPage=0
 * @returns {number|boolean} total number of pages based on total pages in dataset and results per page, or false if perPage is 0
 */
export const getTotalPages = (total = 0, perPage = 0) => {
    if (perPage === 0) {
        return false;
    }
    return Math.ceil(total / perPage);
};

/**
 * Returns data offset based on dataset parameters
 * @param {number} page=1
 * @param {number} perPage=0
 * @returns {number} data offset based on page and results per page
 */
export const getDataOffset = (page = 1, perPage = 0) => {
    if (page === 1) {
        return 0;
    }
    return (page - 1) * perPage;
};

/**
 * Standard reusable handlePageChange method for usual primary datasource.
 * For standard pagination behavior this can imported and passed to renderPaginator.
 * @param {object} e the event triggering the page change
 * @param {Number} page newly selected page
 * @param {Boolean}  isPaging whether or not the data is paginated
 * @param {Function} onRequestParamsChange function to be executed with new request params for the new page
 * @param {object} requestParams the current requestParams object from the dataSource
 */
export const handlePageChange = (e, page, onRequestParamsChange, requestParams = {}) => {
    onRequestParamsChange('primary', {
        ...requestParams,
        ...{ offset: getDataOffset(page, requestParams.count) },
    });
};

/**
 * Returns all relevant pagination information based on visualization props
 * @param {object} vizProps props passed to the visualization
 * @returns {object} object containing all variables relevant to pagination state
 */
export const getPaginationInformation = vizProps => {
    const meta = getMeta(vizProps);
    const requestParams = getRequestParams(vizProps);

    const totalCount = meta.totalCount || 0;
    const perPage = requestParams.count;
    const totalPages = getTotalPages(totalCount, perPage);
    const { offset } = requestParams;
    const currentPage = getCurrentPage(offset, perPage);
    const isPaging = totalPages > 1;
    return { totalCount, perPage, totalPages, offset, currentPage, isPaging, requestParams };
};

/**
 * Returns the Paginator React Element with parameters properly set based on visualization props
 * @param {Number} totalPages total pages in data
 * @param {Number} currentPage current page the viz is on
 * @param {Boolean}  isPaging whether or not the data is paginated
 * @param {object} requestParams the requestParams object from the dataSource
 * @param {Function} onRequestParamsChange function to be execute when the request parameters to the dataSource change
 * @param {Function} pageChange function to be executed when the user selects a new page
 * @param {React Element} PaginatorContainer Container to wrap the Paginator inside
 * @return {React Element} the Paginator wrapped inside the Paginator Container
 */
export const renderPaginator = ({
    totalPages,
    currentPage,
    isPaging,
    requestParams,
    onRequestParamsChange,
    theme,
    pageChange = handlePageChange,
    PaginatorContainer = NormalPaginatorContainer,
}) =>
    isPaging ? (
        <SplunkThemeProvider {...getSettingsFromThemedProps({ theme })}>
            <PaginatorContainer>
                <Paginator
                    current={currentPage}
                    onChange={(e, { page }) => pageChange(e, page, onRequestParamsChange, requestParams)}
                    totalPages={totalPages}
                />
            </PaginatorContainer>
        </SplunkThemeProvider>
    ) : null;

renderPaginator.propTypes = {
    totalPages: T.number.isRequired,
    currentPage: T.number.isRequired,
    isPaging: T.bool.isRequired,
    requestParams: T.object.isRequired,
    onRequestParamsChange: T.func.isRequired,
    theme: T.object,
    pageChange: T.func,
    PaginatorContainer: T.element,
};
