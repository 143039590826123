/**
 * @author sfishel
 *
 * This is an approximation method for obtaining a pair of lower and upper percentile values from a list.
 *
 * Ported from //python-site/splunk/appserver/mrsparkle/lib/util.py
 *
 * @param {Array} orderedList, must be sorted before calling this method
 * @param {Number} lowerPercentile
 * @param {Number} upperPercentile
 *
 * @return {Object} an object with properties 'upper' and 'lower' corresponding to the upper and lower values.
 */
export function getPercentiles(orderedList, lowerPercentile, upperPercentile) {
    if (orderedList.length === 0) {
        return { upper: null, lower: null };
    }

    const f = (p, ln) => {
        const n = p * (ln - 1) + 1;
        const k = Math.floor(n);
        const d = n - k;
        return [n, k, d];
    };

    const v = (percentile, oList) => {
        const fArray = f(percentile, oList.length);
        const k = fArray[1];
        const d = fArray[2];

        if (k === 0 || oList.length === 1) {
            return oList[0];
        }
        if (k === oList.length - 1) {
            return oList[oList.length - 1];
        }
        return oList[k] + d * (oList[k + 1] - oList[k]);
    };

    return {
        lower: v(lowerPercentile, orderedList),
        upper: v(upperPercentile, orderedList),
    };
}
