import React from 'react';
import T from 'prop-types';
import styled from 'styled-components';
import SingleValueBasic from '../../SingleValueBasic/SingleValueBasic';
import RadialGraph from './RadialGraph';
import { getRadialHelperDimensions } from './utils/displayUtils';

const TextContainer = styled.div`
    position: ${props => (props.smallViz ? 'relative' : 'absolute')};
    left: 0;
    bottom: 0;

    width: 100%;
    display: flex;
    align-items: ${props => props.align || 'center'};
    justify-content: center;
    flex-direction: column;

    // To ensure the major text is flush with the bottom of the text container when radial is shown.
    [data-test='majorValue'] {
        ${props => !props.smallViz && { lineHeight: 'normal' }}

        ::before {
            font-size: inherit;
            vertical-align: unset;
            top: unset;
        }

        ::after {
            font-size: inherit;
            vertical-align: unset;
            top: unset;
        }
    }

    // To ensure the trend text is flush with the bottom of the text container, and prevents weird spacing behavior at very small trend text sizes
    [data-test='trendValueIndicator'] {
        align-items: flex-end;
        ${props => props.smallViz && { marginLeft: '1px' }}
    }

    // Prevents weird spacing behavior at very small sizes.
    [data-test='trendValue'] {
        ${props => props.smallViz && { marginLeft: '1px' }}
    }

    div[class^='TrendValue__Column'] {
        ${props => props.smallViz && { margin: '0 1px' }}
    }
`;

const GraphContainer = styled.div`
    position: relative;
    margin: 0 auto;
    width: ${props => props.width}px;
    height: ${props => props.height}px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
`;

const UnderLabelContainer = styled.div`
    display: flex;
    flex: 0 0 ${props => props.underLabelHeight}px; // Changes depending on height of underLabel.
    align-items: flex-end;
`;

const UnderLabelText = styled.span`
    color: ${props => props.color};
    -webkit-text-stroke: 0.5px ${props => props.strokeColor};
    font-size: 12px;

    // Prevent overflow of text beyond inner arc radius.
    max-width: ${props => props.maxWidth}px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

const RadialContent = ({ ...props }) => {
    const {
        majorColor,
        majorText,
        majorTextFontSize,
        majorValueTitle,
        trendColor,
        trendText,
        trendTextFontSize,
        showTrendIndicator,
        unit,
        unitPosition,
        underLabelColor,
        underLabel,
        onValueClick,
        showRadialGraph,
        progress,
        radialBackgroundColor,
        radialStrokeColor,
        radialStrokeWidth,
        graphWidth,
        graphHeight,
        underLabelStrokeColor,
    } = props;

    const { semicircleRadius, helperArcRadius } = getRadialHelperDimensions({
        height: graphHeight,
        strokeWidth: radialStrokeWidth,
    });

    // Two cases for filling underLabel space: either the semicircle radius is insufficiently sized for text
    //   or the radial visualization has been disabled (first condition reached first when making smaller).
    const fillVacantSpace = semicircleRadius < 50 || !showRadialGraph;

    // If filling the vacant space, no height on underLabel container. Otherwise, the underLabel height is 20% of the helperArcRadius (min 20px).
    const underLabelHeight = fillVacantSpace ? 0 : Math.max(helperArcRadius * 0.2, 20);
    const underLabelWidth = helperArcRadius * 2;

    const basicSingleValues = (
        <SingleValueBasic
            majorColor={majorColor}
            majorText={majorText}
            majorTextFontSize={majorTextFontSize}
            majorValueTitle={majorValueTitle}
            trendColor={trendColor}
            trendText={trendText}
            trendTextFontSize={trendTextFontSize}
            showTrendIndicator={showTrendIndicator}
            unit={unit}
            unitPosition={unitPosition}
            onValueClick={onValueClick}
        />
    );

    const radialGraph = showRadialGraph ? (
        <RadialGraph
            progress={progress}
            backgroundColor={radialBackgroundColor}
            progressColor={radialStrokeColor}
            strokeWidth={radialStrokeWidth}
            width={graphWidth}
            height={graphHeight}
        />
    ) : null;

    const underLabelComponent = (
        <UnderLabelContainer underLabelHeight={underLabelHeight}>
            {!fillVacantSpace && underLabel && (
                <UnderLabelText
                    data-test="underLabel"
                    maxWidth={underLabelWidth * 0.9} // buffer for overflow ellipses
                    color={underLabelColor}
                    strokeColor={underLabelStrokeColor}
                    title={underLabel}
                >
                    {underLabel}
                </UnderLabelText>
            )}
        </UnderLabelContainer>
    );

    return (
        <GraphContainer width={graphWidth} height={graphHeight}>
            {radialGraph}
            <TextContainer smallViz={!showRadialGraph} height={graphHeight}>
                {basicSingleValues}
                {underLabelComponent}
            </TextContainer>
        </GraphContainer>
    );
};

RadialContent.propTypes = {
    ...SingleValueBasic.propTypes,
    trendPosition: T.string,
    showRadialGraph: T.bool,
    progress: T.number,
    radialBackgroundColor: T.string,
    radialStrokeColor: T.string,
    radialStrokeWidth: T.number,
    graphWidth: T.number,
    graphHeight: T.number,
    underLabelStrokeColor: T.string,
};

export default RadialContent;
