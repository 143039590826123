import { COLOR_OR_TOKEN_PATTERN, getPattern } from '@splunk/visualizations-shared/schemaUtils';

export default {
    unit: { description: 'Specify text to show next to the major value.', type: 'string' },
    unitPosition: {
        default: 'after',
        description: 'Specify whether the unit text should appear before or after the major value.',
        pattern: getPattern(['before', 'after']),
        type: 'string',
    },
    underLabel: { description: 'Specify the text that appears below the major value.', type: 'string' },
    backgroundColor: {
        default: 'transparent',
        description: 'Specify the color to use for the background. The hex value format should be #FFFFFF.',
        pattern: COLOR_OR_TOKEN_PATTERN,
        type: 'string',
    },
    showTrendIndicator: {
        default: true,
        description: 'Specify whether to show or hide the trend indicator.',
        type: 'boolean',
    },
    useTrendUnits: {
        default: false,
        description:
            'Specify whether to truncate the trend value to 2 significant digits. A magnitude unit will be displayed.',
        type: 'boolean',
    },
    trendDisplayMode: {
        default: 'absolute',
        description: 'Specify whether the trend value is displayed as a percentage or an absolute count.',
        pattern: getPattern(['percent', 'absolute']),
        type: 'string',
    },
    numberPrecision: {
        default: 0,
        maximum: 20,
        minimum: 0,
        description:
            'Specify the number of decimal places to display. For example, to display 3 decimal places, use a value of 3. The maximum value is 20.',
        type: 'number',
    },
    useThousandSeparators: {
        default: true,
        description: 'Specify whether numeric values use commas as thousandths separaters.',
        type: 'boolean',
    },
    icon: { default: 'default', description: 'Specify an icon.', type: 'string' },
    iconPosition: {
        default: 'before',
        description: 'Specify where to display the icon in relation to the major value.',
        type: 'string',
    },
    iconOpacity: {
        default: 1,
        description: 'Specify the opacity for the icon using a number between 0 and 1 (inclusive).',
        type: 'number',
    },
    showValue: {
        default: true,
        description: 'Specify whether to enable or disable the value and trend indicator displays.',
        type: 'boolean',
    },
    showIcon: {
        default: true,
        description: 'Deprecated. Specify whether to enable or disable the icon display.',
        type: 'boolean',
    },
    color: {
        default: null,
        description: 'Deprecated. Please use iconColor to color icon, majorColor to color major value.',
        pattern: COLOR_OR_TOKEN_PATTERN,
        type: 'string',
    },
    iconColor: {
        default: null,
        description: 'Specify the color for the icon. The hex value format should be #FFFFFF.',
        pattern: COLOR_OR_TOKEN_PATTERN,
        type: 'string',
    },
    majorColor: {
        default: null,
        description: 'Specify the color for the major value. The hex value format should be #FFFFFF.',
        pattern: COLOR_OR_TOKEN_PATTERN,
        type: 'string',
    },
    deltaColor: {
        default: null,
        description: 'Specify the delta value color using a Hex code. For example: "#FF0000".',
        pattern: COLOR_OR_TOKEN_PATTERN,
        type: 'string',
    },
};
