/*
 * base64 encoding
 * @param {String} str
 * @return {Base64} string using based-64 encoding
 */
export const b64EncodeUnicode = (str: string): string => {
    // first we use encodeURIComponent to get percent-encoded UTF-8,
    // then we convert the percent encodings into raw bytes which
    // can be fed into btoa.
    return btoa(
        encodeURIComponent(str).replace(
            /%([0-9A-F]{2})/g,
            function toSolidBytes(match, p1) {
                return String.fromCharCode(parseInt(`0x${p1}`, 16));
            }
        )
    );
};
