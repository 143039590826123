/* eslint-disable dot-notation */
import { parse as baseParse, mergeEncoding } from '@splunk/visualization-encoding-parsers/Base';
import isEmpty from 'lodash/isEmpty';
import isArray from 'lodash/isArray';
import { ErrorMsgs } from './errorMsgs';

export const dataContract = {
    requiredDataSources: [
        {
            name: 'primary',
            description: 'DataSource that powers the visualization',
        },
    ],
    initialRequestParams: {
        primary: (options = {}) => ({
            offset: 0,
            count: options.count ? options.count : 20,
            requestTotalCount: true,
        }),
    },
    encoding: {
        columns: {
            isRequired: true,
            type: ['time', 'number', 'string'], // TODO: In the future, once supported, add 'sparkline' as a type
            default: 'primary[0:]', // TODO: In the future, refactor according to UX designs to show only first N fields
        },
    },
};

/**
 * Table parse function
 * @param {object} dataSources
 * @param {object} userDefinedEncoding
 * @return {object} props that dashboard visualization can use.
 * @public
 */
export const parse = (dataSources, userDefinedEncoding) => {
    if (isEmpty(dataSources)) {
        throw new Error(ErrorMsgs.NO_DATA_SOURCES);
    }

    const encoding = mergeEncoding(dataContract.encoding, userDefinedEncoding);
    const parsedProps = baseParse(dataSources, encoding);

    let { columns } = parsedProps;
    let columnsFieldName = parsedProps['_meta'].fieldNames.columns;
    if (!isArray(columnsFieldName)) {
        columnsFieldName = [columnsFieldName];
        columns = [columns];
    }

    return {
        columns,
        columnsFieldName,
    };
};
