import { GeoJsonDataType } from '@splunk/visualizations-shared/mapUtils';
import { DataType, TypedValue } from './DataPrimitive';
import { isTime, getDataTypeForPoint } from '../src/utils/types';
import { formatTimeWithTimezoneCorrection } from '../src/utils/formatterUtils';

/**
 * @implements {TypedValue}
 * TypeSafeValue implements the TypedValue interface, and adds additional methods
 */
export class TypeSafeValue<T extends DataType> implements TypedValue<T> {
    /**
     * isTypeSafe. This is a marker field. The presence of this field can be used at runtime to determine if TypedValue is rigorous,
     * loose. Loose TypedValue such as {type:'number', value:'cat'} are handy for testing, but can lie. Obviously 'cat'
     * is not a number. If the isTypeSafe field is present then the instance is a TypeSafeValue
     * @type {boolean}
     */
    readonly isTypeSafe: boolean = true;

    /**
     * coercedValue. This field retains the value after coercion.
     */
    readonly coercedValue: any;

    readonly type: T;

    readonly value: any;

    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    constructor(type: T, value: any, coercedValue: any) {
        this.type = type;
        this.value = value;
        this.coercedValue = coercedValue;
    }

    /**
     * returns a TypeSafeValue, either by converting the non TypeSafeValue to
     * a TypeSafeValue, or by simply returning the passed-in TypeSafeValue
     * @param {TypedValue<T>} typedValue
     * @returns {TypeSafeValue<T>}
     */
    public static from<FromValueType extends DataType = DataType>(
        typedValue: TypedValue<FromValueType>
    ): TypeSafeValue<FromValueType> {
        if ((typedValue as any).isTypeSafe) {
            return typedValue as TypeSafeValue<FromValueType>; // just return what was passed in since it is already TypeSafeValue
        }
        const { value, type } = typedValue;
        const [coercedValue] = TypeSafeValue.coerceValue<FromValueType>(typedValue);
        return new TypeSafeValue<FromValueType>(type, value, coercedValue);
    }

    /**
     * Creates a TypeSafeValue from a raw value
     * @param value
     * @returns {TypeSafeValue<DataType>}
     */
    public static fromRaw(value): TypeSafeValue<DataType> {
        const type: DataType = getDataTypeForPoint(value);
        return new TypeSafeValue(type, value, value);
    }

    /**
     * attempts to coerce the provided value to the provided type. Returns tuple
     * of the coerced value and a boolean telling if the coercion was clean (true)
     * or if the coercion was likely produced an unusable result, such as NaN for
     * a number, or '' for a color.
     * @param {TypedValue<T>} typedValue
     * @returns {[any]}
     */
    private static coerceValue<ValueToCoerceT extends DataType = DataType>(
        typedValue: TypedValue<ValueToCoerceT>
    ): [any] {
        const { type, value } = typedValue;
        let coercedValue = null;
        try {
            switch (type) {
                case 'number': {
                    coercedValue = Number(value);
                    break;
                }
                case 'time': {
                    const isOk = isTime(value);
                    if (value instanceof Date) {
                        coercedValue = value;
                    } else if (!isOk) {
                        coercedValue = 'Invalid Date';
                    } else {
                        // coercion to date would be lossy and result in losing time zone information
                        const timeString = formatTimeWithTimezoneCorrection(value);
                        coercedValue = timeString;
                    }

                    break;
                }
                case 'string': {
                    coercedValue = value.toString();
                    break;
                }
                case 'color': {
                    coercedValue = value;
                    break;
                }
                case 'sparkline': {
                    coercedValue = value;
                    break;
                }
                case 'array': {
                    coercedValue = value;
                    break;
                }
                case 'null': {
                    coercedValue = value;
                    break;
                }
                case 'geojson': {
                    coercedValue = value;
                    break;
                }
                default: {
                    coercedValue = '';
                }
            }
        } catch (e) {} // eslint-disable-line no-empty
        return [coercedValue];
    }

    toRawCoercedValue(): string | number | null | GeoJsonDataType {
        switch (this.type) {
            case 'time':
            case 'sparkline':
            case 'array':
            case 'number':
            case 'string':
            case 'color':
            case 'geojson':
            default:
                return this.coercedValue;
        }
    }

    toRawValue(): string | number | null | GeoJsonDataType {
        switch (this.type) {
            case 'time':
            case 'sparkline':
            case 'array':
            case 'number':
            case 'string':
            case 'color':
            case 'geojson':
            default:
                return this.value;
        }
    }
}
