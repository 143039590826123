import { chain } from 'lodash';
import vizConfig from './config';
import withDashboardViz from '../common/withDashboardViz';
import FillerGauge, { FillerGaugeProps } from './PureFillerGauge/FillerGauge';
import { convertToGaugeProperties } from '../common/gaugeUtils';

export const config = vizConfig;
export const PureFillerGauge = FillerGauge;

const validOptions = Object.keys(config.optionsSchema);

type computeVizPropsFn = (options: Record<string, any>) => Partial<FillerGaugeProps>;
export const computeVizProps: computeVizPropsFn = ({ ...props }): Partial<FillerGaugeProps> => {
    const options = chain(props).pick(validOptions).thru(convertToGaugeProperties).value();
    const defaultColors = {
        backgroundBarFillColor: config.themes.defaultBackgroundBarFillColor(props),
        majorTickFillColor: config.themes.majorTickFillColor(props),
        majorTickStrokeColor: config.themes.majorTickStrokeColor(props),
        valueMarkerFillColor: config.themes.valueMarkerFillColor(props),
        valueMarkerLabelFillColor: config.themes.valueMarkerLabelFillColor(props),
    };
    return { ...options, ...defaultColors };
};

const viz = withDashboardViz({
    ReactViz: FillerGauge as any,
    computeVizProps,
    vizConfig,
});

export const { themes } = vizConfig;
export default viz;
