import type { JSONSchemaType } from 'ajv';
import { cloneDeep, each, isEmpty, set } from 'lodash';
import { TOKEN_NAME_CHARS_PATTERN } from '@splunk/dashboard-utils';
import type {
    DashboardJSON,
    EventHandlerDefinition,
    RootDataSourcesDefinition,
    RootVisualizationsDefinition,
    RootInputsDefinition,
    LayoutDefinition,
    DashboardDefaultsDefinition,
    PresetComponent,
    PresetMap,
    RootExpressionsDefinition,
} from '@splunk/dashboard-types';

const keyPattern = '^[a-zA-Z0-9_-]*$';
const validTokenNamePattern = `^${TOKEN_NAME_CHARS_PATTERN}$`;

type NullableSchema<T> = JSONSchemaType<T> & {
    nullable: true;
};

const eventHandlerSchema: NullableSchema<EventHandlerDefinition[]> = {
    type: 'array',
    items: {
        type: 'object',
        properties: {
            type: {
                type: 'string',
            },
            options: {
                type: 'object',
                nullable: true,
                properties: {
                    url: { type: 'string', nullable: true },
                    newTab: { type: 'boolean', nullable: true },
                    key: { type: 'string', nullable: true },
                    value: { type: 'string', nullable: true },
                    app: { type: 'string', nullable: true },
                    dashboard: { type: 'string', nullable: true },
                    tokens: {
                        type: 'array',
                        nullable: true,
                        items: {
                            type: 'object',
                            properties: {
                                token: { type: 'string' },
                                key: {
                                    type: 'string',
                                    nullable: true,
                                    description:
                                        'Fetches the data from the event using this key.',
                                },
                                value: {
                                    type: 'string',
                                    nullable: true,
                                    description: 'Specify a static value.',
                                },
                            },
                            required: ['token'],
                            oneOf: [
                                { required: ['key'] },
                                { required: ['value'] },
                            ],
                        },
                    },
                    tokenNamespace: { type: 'string', nullable: true },
                    events: {
                        type: 'array',
                        items: {
                            type: 'string',
                        },
                        nullable: true,
                    },
                },
            },
        },
        required: ['type'],
        additionalProperties: false,
    },
    nullable: true,
} as const;

const dataSourcesSchema: NullableSchema<RootDataSourcesDefinition> = {
    type: 'object',
    nullable: true,
    required: [],
    additionalProperties: false,
    patternProperties: {
        [keyPattern]: {
            type: 'object',
            properties: {
                type: {
                    type: 'string',
                },
                options: {
                    type: 'object',
                    nullable: true,
                },
                // datasource name
                name: {
                    type: 'string',
                    nullable: true,
                },
                // some metadata for this data source
                meta: {
                    type: 'object',
                },
                extend: {
                    type: 'string',
                },
                eventHandlers: eventHandlerSchema,
            },
            required: ['type'],
            additionalProperties: false,
        },
    },
} as const;

const visualizationsSchema: NullableSchema<RootVisualizationsDefinition> = {
    type: 'object',
    required: [],
    nullable: true,
    additionalProperties: false,
    patternProperties: {
        [keyPattern]: {
            type: 'object',
            properties: {
                context: { type: 'object', nullable: true },
                type: {
                    type: 'string',
                },
                options: {
                    type: 'object',
                    nullable: true,
                },
                encoding: {
                    type: 'object',
                    nullable: true,
                },
                dataSources: {
                    type: 'object',
                    patternProperties: {
                        [keyPattern]: { type: 'string' },
                    },
                    required: [],
                    nullable: true,
                },
                eventHandlers: eventHandlerSchema,
                title: {
                    type: 'string',
                    nullable: true,
                },
                description: {
                    type: 'string',
                    nullable: true,
                },
                showProgressBar: {
                    type: 'boolean',
                    nullable: true,
                },
                showLastUpdated: {
                    type: 'boolean',
                    nullable: true,
                },
                hideWhenNoData: {
                    type: 'boolean',
                    nullable: true,
                },
            },
            required: ['type'],
            additionalProperties: false,
        },
    },
} as const;

const inputsSchema: NullableSchema<RootInputsDefinition> = {
    type: 'object',
    nullable: true,
    required: [],
    additionalProperties: false,
    patternProperties: {
        [keyPattern]: {
            type: 'object',
            properties: {
                context: { type: 'object', nullable: true },
                type: {
                    type: 'string',
                },
                options: {
                    type: 'object',
                    nullable: true,
                },
                encoding: {
                    type: 'object',
                    nullable: true,
                },
                dataSources: {
                    type: 'object',
                    nullable: true,
                    required: [],
                    patternProperties: {
                        [keyPattern]: { type: 'string' },
                    },
                },
                title: {
                    type: 'string',
                    nullable: true,
                },
                eventHandlers: eventHandlerSchema,
                showProgressBar: {
                    type: 'boolean',
                    nullable: true,
                },
                showLastUpdated: {
                    type: 'boolean',
                    nullable: true,
                },
                canvasAlignment: {
                    type: 'string',
                    anyOf: [
                        { enum: ['top', 'bottom', 'center'] },
                        { pattern: '^[$].*[$]$' },
                    ],
                    nullable: true,
                },
                hideWhenNoData: {
                    type: 'boolean',
                    nullable: true,
                },
            },
            required: ['type'],
            additionalProperties: false,
        },
    },
} as const;

const layoutSchema: NullableSchema<LayoutDefinition> = {
    type: 'object',
    nullable: true,
    required: ['type'],
    additionalProperties: false,
    properties: {
        type: {
            type: 'string',
        },
        options: {
            type: 'object',
            nullable: true,
        },
        globalInputs: {
            type: 'array',
            items: {
                type: 'string',
            },
            nullable: true,
        },
        structure: {
            type: 'array',
            items: {
                type: 'object',
                nullable: true,
                required: [],
            },
            nullable: true,
        },
    },
} as const;

const defaultsSchema: NullableSchema<DashboardDefaultsDefinition> = {
    type: 'object',
    nullable: true,
    properties: {
        inputs: {
            type: 'object',
            nullable: true,
            required: [],
        },
        visualizations: {
            type: 'object',
            nullable: true,
            required: [],
            additionalProperties: false,
        },
        dataSources: {
            type: 'object',
            nullable: true,
            required: [],
            properties: {
                global: {
                    type: 'object',
                    nullable: true,
                },
            },
            patternProperties: {
                [keyPattern]: {
                    type: 'object',
                    nullable: true,
                },
            },
        },
        tokens: {
            type: 'object',
            nullable: true,
            required: [],
            patternProperties: {
                // namespaces
                [keyPattern]: {
                    type: 'object',
                    required: [],
                    patternProperties: {
                        // tokens
                        [keyPattern]: {
                            type: 'object',
                            required: ['value'],
                            properties: {
                                value: {
                                    type: 'string',
                                },
                            },
                        },
                    },
                },
            },
        },
    },
} as const;

const expressionsSchema: NullableSchema<RootExpressionsDefinition> = {
    type: 'object',
    nullable: true,
    additionalProperties: false,
    properties: {
        conditions: {
            type: 'object',
            nullable: true,
            additionalProperties: false,
            required: [],
            patternProperties: {
                // Defined conditions must be associated with a valid token-like string
                [validTokenNamePattern]: {
                    type: 'object',
                    additionalProperties: false,
                    required: ['value'],
                    properties: {
                        value: {
                            type: 'string',
                        },
                    },
                },
            },
        },
    },
} as const;

// note, this alone doesn't provide precise schema validation, it is best to use the createSchemaBasedOnPresets().
const defaultDashboardSchema: JSONSchemaType<DashboardJSON> = {
    $id: 'http://www.splunk.com/dashboard.schema.json',
    title: 'Dashboard Definition',
    description: 'Dashboard Definition',
    type: 'object',
    properties: {
        version: {
            type: 'string',
            nullable: true,
        },
        title: {
            type: 'string',
            nullable: true,
        },
        description: {
            type: 'string',
            nullable: true,
        },
        dataSources: dataSourcesSchema,
        visualizations: visualizationsSchema,
        inputs: inputsSchema,
        defaults: defaultsSchema,
        layout: layoutSchema,
        expressions: expressionsSchema,
    },
    required: [],
} as const;

/**
 * create new schema
 * @method createMonacoSchema
 * @param {Object} config
 * @param {Object} config.newSchema
 * @param {String} config.modelUri
 * @returns {Object} monaco schema
 */
export const createMonacoSchema = ({
    newSchema = {},
    modelUri,
}: {
    newSchema?: Record<string, unknown>;
    modelUri: string;
}): Record<string, unknown>[] => {
    const finalSchema = isEmpty(newSchema) ? defaultDashboardSchema : newSchema;
    return [
        {
            // made up fake uri
            uri: 'http://splunk/json-schema.json',
            fileMatch: [modelUri],
            schema: finalSchema,
        },
    ];
};

type ComponentDictionary = Record<string, Record<string, unknown> | undefined>;

/**
 * combine schemas for the same type
 * @method combineSchema
 * @param {Object} componentDict {type : optionSchema}
 * @param {Boolean} [additionalProperties=true] allow extra properties in options object
 * @returns {array} allOf statement of if else statements
 */
export const combineSchema = (
    componentDict: ComponentDictionary,
    additionalProperties = true
): Record<string, unknown>[] => {
    const allOfStatement: Record<string, unknown>[] = [];
    each(componentDict, (optionSchema, type) => {
        if (!isEmpty(optionSchema)) {
            const statement = {
                if: {
                    properties: { type: { const: type } },
                },
                then: {
                    properties: {
                        // if the schema is extended beyond just a list of properties, use it instead
                        options: optionSchema?.extend
                            ? optionSchema.extend
                            : {
                                  type: 'object',
                                  properties: optionSchema,
                                  // TODO: Revisit additionalProperties to disallow unknown keys as a warning
                                  additionalProperties,
                              },
                    },
                },
            };
            allOfStatement.push(statement);
        }
    });
    return allOfStatement;
};

/**
 * @method createSchemaBasedOnDicts
 * @param {Object} config
 * @param {Object} layoutDict {type : optionSchema}
 * @param {Object} dataSourceDict {type : optionSchema}
 * @param {Object} visualizationDict {type : optionSchema}
 * @param {Object} inputDict {type : optionSchema}
 * @returns {Object} schema
 */
export const createSchemaBasedOnDicts = ({
    layoutDict = {},
    dataSourceDict = {},
    visualizationDict = {},
    inputDict = {},
}: {
    layoutDict?: ComponentDictionary;
    dataSourceDict?: ComponentDictionary;
    visualizationDict?: ComponentDictionary;
    inputDict?: ComponentDictionary;
}): JSONSchemaType<DashboardJSON> => {
    const layoutAllOfStatement = combineSchema(layoutDict);
    const dataSourceAllOfStatement = combineSchema(dataSourceDict);
    const visualizationAllOfStatement = combineSchema(visualizationDict);
    const inputAllOfStatement = combineSchema(inputDict, false);
    const newSchema = cloneDeep(defaultDashboardSchema);
    if (!isEmpty(layoutDict)) {
        set(
            newSchema,
            'properties.layout.properties.type.enum',
            Object.keys(layoutDict)
        );
    }
    if (!isEmpty(layoutAllOfStatement)) {
        set(newSchema, 'properties.layout.allOf', layoutAllOfStatement);
    }

    if (!isEmpty(inputDict)) {
        set(
            newSchema,
            [
                'properties',
                'inputs',
                'patternProperties',
                keyPattern,
                'properties',
                'type',
                'enum',
            ],
            Object.keys(inputDict)
        );
    }
    if (!isEmpty(inputAllOfStatement)) {
        set(
            newSchema,
            ['properties', 'inputs', 'patternProperties', keyPattern, 'allOf'],
            inputAllOfStatement
        );
    }

    if (!isEmpty(visualizationDict)) {
        set(
            newSchema,
            [
                'properties',
                'visualizations',
                'patternProperties',
                keyPattern,
                'properties',
                'type',
                'enum',
            ],
            Object.keys(visualizationDict)
        );

        // caveat1: preset is provided at runtime, thus there's no way to let it perfectly match DashboardJSON at compile time. So we put a vague schema into /defaults/visualizations in the defaultDashboardSchema, and modify it at runtime based on the preset.
        // caveat2: `global` cannot be specified at compile time because of AJV limitation https://github.com/ajv-validator/ajv/issues/1588
        // caveat3: use lodash get/set to workaround the typechecking
        set(
            newSchema,
            [
                'properties',
                'defaults',
                'properties',
                'visualizations',
                'properties',
                'global',
            ],
            {
                type: 'object',
                nullable: true,
                additionalProperties: false,
                properties: {
                    context: {
                        type: 'object',
                        nullable: true,
                    },
                    showLastUpdated: {
                        type: 'boolean',
                        default: false,
                        nullable: true,
                    },
                    showProgressBar: {
                        type: 'boolean',
                        default: false,
                        nullable: true,
                    },
                },
            }
        );
        Object.entries(visualizationDict).forEach(([key, optionsSchema]) =>
            set(
                newSchema,
                [
                    'properties',
                    'defaults',
                    'properties',
                    'visualizations',
                    'properties',
                    key,
                ],
                {
                    type: 'object',
                    nullable: true,
                    additionalProperties: false,
                    properties: {
                        context: {
                            type: 'object',
                            nullable: true,
                        },
                        showLastUpdated: {
                            type: 'boolean',
                            default: false,
                            nullable: true,
                        },
                        showProgressBar: {
                            type: 'boolean',
                            default: false,
                            nullable: true,
                        },
                        options: {
                            type: 'object',
                            properties: optionsSchema,
                            nullable: true,
                            additionalProperties: false,
                        },
                    },
                }
            )
        );
    }
    if (!isEmpty(visualizationAllOfStatement)) {
        set(
            newSchema,
            [
                'properties',
                'visualizations',
                'patternProperties',
                keyPattern,
                'allOf',
            ],
            visualizationAllOfStatement
        );
    }

    if (!isEmpty(dataSourceDict)) {
        set(
            newSchema,
            [
                'properties',
                'dataSources',
                'patternProperties',
                keyPattern,
                'properties',
                'type',
                'enum',
            ],
            Object.keys(dataSourceDict)
        );
    }
    if (!isEmpty(dataSourceAllOfStatement)) {
        set(
            newSchema,
            [
                'properties',
                'dataSources',
                'patternProperties',
                keyPattern,
                'allOf',
            ],
            dataSourceAllOfStatement
        );
    }
    return newSchema;
};

/**
 * create component dictionary based on preset
 * @method createComponentDict
 * @param {Object} preset
 * @returns {Object} component object {type : optionSchema}
 */
export const createComponentDict = (
    presetComponents: Record<string, PresetComponent>
): ComponentDictionary => {
    const componentDict: ComponentDictionary = {};
    each(presetComponents, (component, type) => {
        componentDict[type] =
            component?.config?.optionsSchema || component?.schema;
    });
    return componentDict;
};

/**
 * create schema based on presets
 * @method createSchemaBasedOnPresets
 * @param {Object} presets combined with all custom presets
 * @returns {Object} schema
 */
export const createSchemaBasedOnPresets = (
    presets: PresetMap
): ReturnType<typeof createSchemaBasedOnDicts> => {
    const layoutDict = createComponentDict(presets.layouts);
    const dataSourceDict = createComponentDict(
        presets.dataSources as unknown as Record<string, PresetComponent>
    );
    const visualizationDict = createComponentDict(presets.visualizations);
    const inputDict = createComponentDict(presets.inputs);
    return createSchemaBasedOnDicts({
        layoutDict,
        dataSourceDict,
        visualizationDict,
        inputDict,
    });
};
export default defaultDashboardSchema;
