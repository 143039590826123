import { rgbColorPattern, rgbaColorPattern } from './schemaUtils';
import namedColors from './namedColors';

/**
 * Validates if a given string is a valid color
 *
 * @param {string} color
 * @returns {boolean}
 */
export function isColor(color = ''): boolean {
    if (typeof color !== 'string') {
        return false;
    }
    if (color.charAt(0) === '#') {
        color = color.substring(1); // eslint-disable-line no-param-reassign
        return [3, 4, 6, 8].indexOf(color.length) > -1 && !Number.isNaN(parseInt(color, 16));
    }
    const trimmedColor = color.trim();
    if (rgbColorPattern.test(trimmedColor) || rgbaColorPattern.test(trimmedColor)) {
        return true;
    }
    return namedColors.includes(color.toLowerCase());
    // for future when we want to support rgb | hsl
    // return /^(rgb|hsl)a?\((\d+%?(deg|rad|grad|turn)?[,\s]+){2,3}[\s\/]*[\d\.]+%?\)$/i.test(color);
}

/**
 * Validates if a given array of strings is a valid array of colors
 * @param colors
 * @returns
 */
export const isArrayOfColors = (colors: string[]) => Array.isArray(colors) && colors.every(isColor);

/**
 * Sanitizes an array of colors `arr` to replace any non-color values with undefined,
 *  or the value of `fallback` if provided.
 *
 * @param arr
 * @param {string} fallback
 * @returns {string[]} sanitized array any non-color values replaced with `fallback`
 */
export const sanitizeArrayOfColors = (arr: any[], fallback?: string): string[] => {
    return arr.map(entry => (isColor(entry) ? entry : fallback));
};
