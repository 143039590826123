/* eslint-disable class-methods-use-this */
import React, { PureComponent } from 'react';
import T from 'prop-types';
import styled from 'styled-components';
import xmlserializer from 'xmlserializer';
import { _ } from '@splunk/ui-utils/i18n';
import IconContext from '@splunk/visualization-context/IconContext';
import { toDimension } from './style';
import { shouldChangeFill, shouldChangeStroke, b64EncodeUnicode } from './iconUtils';

const IconContainer = styled.div`
    ${props => toDimension({ width: props.width, height: props.height })};
    margin: auto;
    cursor: ${props => (props.onClick ? 'pointer' : 'inherit')};
`;
const ColoredIcon = styled.img`
    ${props => toDimension({ width: props.width, height: props.height })};
    opacity: ${props => props.opacity || 1.0};
`;

class RemoteIcon extends PureComponent {
    static contextType = IconContext;

    static propTypes = {
        iconURL: T.string,
        color: T.string,
        width: T.number,
        height: T.number,
        opacity: T.number,
        onIconClick: T.func,
    };

    static defaultProps = {
        width: 100,
        height: 100,
        opacity: 1.0,
    };

    static BASIC_SHAPES = ['path', 'rect', 'circle', 'ellipse', 'line', 'polyline', 'polygon'];

    constructor(props) {
        super(props);
        this.state = { iconDataURI: null };
    }

    // eslint-disable-next-line camelcase
    UNSAFE_componentWillMount() {
        this.getIcon(this.props.iconURL, this.props.color);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.iconURL !== this.props.iconURL || prevProps.color !== this.props.color) {
            this.getIcon(this.props.iconURL, this.props.color);
        }
    }

    /**
     * Check if the iconURL is a registry URL/dataURI/direct URL and fetch from the registry when required
     * @param {String} iconURL
     * @param {String} color
     */
    async getIcon(iconURL, color) {
        const getIconDataURI = async () => {
            if (this.isRegistryURL(iconURL)) {
                const iconRegistry = this.context;
                if (iconRegistry) {
                    try {
                        const icon = await iconRegistry.getByURL(iconURL);
                        return icon.dataURI;
                    } catch (error) {
                        // eslint-disable-next-line no-console
                        console.error(_(error));
                    }
                }
            } else {
                return iconURL;
            }
            return null;
        };

        const iconDataURI = await getIconDataURI();

        if (color) {
            this.colorIcon(iconDataURI, color);
        } else {
            this.setState({ iconDataURI });
        }
    }

    /**
     * check if icon is a registry url
     * @param {String} src
     */
    isRegistryURL(src = '') {
        const [type] = src.split('://');
        return type !== 'http' && type !== 'https' && !src.startsWith('data:image/svg+xml;base64');
    }

    /*
     * Color the icon:
     * parse svg elements from the dataURI, change color style of svg, and change fill/stroke attribute/style of each svg basic shapes
     * in the svg element to currentColor. At the end, convert the modified svg back to dataURI string.
     *
     * Limitation:
     * if the svg is created with multiple pathes with different fill attributes, this method would
     * convert all pathes' fill attribute to the color prop. It may change the view of the svg icon.
     *
     * @method colorIcon
     * @params {DataURI} dataURI
     * @params {String} color      e.g. 'red' || '#ffffff'
     */
    colorIcon(dataURI, color) {
        fetch(dataURI)
            .then(response => response.text())
            .then(text => {
                const parser = new DOMParser();
                const xmlDoc = parser.parseFromString(text, 'text/xml');
                const svg = xmlDoc.getElementsByTagName('svg')[0];
                const children = svg.querySelectorAll('*');
                svg.style.color = color;

                // Todo: need move this to IconRegistry after figure out the jest issue with XMLSerializer
                for (let i = 0; i < children.length; i += 1) {
                    const child = children[i];

                    if (RemoteIcon.BASIC_SHAPES.includes(child.tagName)) {
                        const fillAttr = child.getAttribute('fill');
                        const fillStyle = child.style && child.style.fill;
                        const strokeAttr = child.getAttribute('stroke');
                        const strokeStyle = child.style && child.style.stroke;

                        if (shouldChangeFill({ fillAttr, fillStyle, strokeAttr, strokeStyle })) {
                            child.style.fill = '';
                            child.setAttribute('fill', 'currentColor');
                        }

                        if (shouldChangeStroke({ fillAttr, fillStyle, strokeAttr, strokeStyle })) {
                            child.style.stroke = '';
                            child.setAttribute('stroke', 'currentColor');
                        }
                    }
                }

                const svgStr = b64EncodeUnicode(xmlserializer.serializeToString(svg));
                const iconDataURI = `data:image/svg+xml;base64,${svgStr}`;
                this.setState({ iconDataURI });
            })
            .catch(() => {
                // eslint-disable-next-line no-console
                console.error(_('The icon cannot be colored properly.'));
            });
    }

    render() {
        const { iconDataURI } = this.state;
        const { width, height, opacity, onIconClick } = this.props;

        if (!iconDataURI) {
            return null;
        }
        return (
            <IconContainer width={width} height={height} onClick={onIconClick}>
                <ColoredIcon
                    src={iconDataURI}
                    alt="Remote Icon"
                    width={width}
                    height={height}
                    opacity={opacity}
                    referrerPolicy="no-referrer"
                />
            </IconContainer>
        );
    }
}

export default RemoteIcon;
