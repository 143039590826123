import type { Subscriber } from 'rxjs';
import type {
    JSONCols,
    DataSourceMeta,
    JobStatus,
    RequestParams,
    ErrorLevel,
} from '@splunk/dashboard-types';
import { DataSet } from '@splunk/datasource-utils';
import type { ObservableData } from './types';
import DataSource from './DataSource';
import TestDataSourceOptionsSchema from './TestDataSourceOptionsSchema';
import SimpleScheduler from './utils/SimpleScheduler';

export interface TestDataSourceOptions {
    data?: JSONCols;
    meta?: DataSourceMeta;
    delay?: number;
    timeToStart?: number;
    errorLevel?: ErrorLevel;
    error?: string;
    timeToComplete?: number;
    increments?: number;
    checkRiskyCommand?: boolean;
}

interface TestDataSourceContext {
    id?: string;
}

/**
 * @class TestDataSource
 */
export default class TestDataSource extends DataSource<
    TestDataSourceOptions,
    TestDataSourceContext
> {
    /**
     * List of valid configuration options
     * @static
     */
    static schema = TestDataSourceOptionsSchema;

    static config = {
        optionsSchema: TestDataSourceOptionsSchema,
    };

    readonly data: JSONCols;

    readonly meta: DataSourceMeta;

    readonly timeToStart: number;

    readonly errorLevel?: ErrorLevel;

    readonly error?: string;

    readonly timePerUpdate: number;

    readonly incrementSize: number;

    readonly checkRiskyCommand: boolean;

    progress: number;

    status: JobStatus;

    /**
     * Test Datasource
     * @param {Object} options.data static data set
     * @param {Number} [options.delay=0] time before first results return (deprecated)
     * @param {Object} [options.meta] Return meta, merged with search progress
     * @param {*} [options.errorLevel] Passthrough data for errors
     * @param {String} [options.error] Passthrough error message - Set to create an erroring datasource
     * @param {Number} [options.timeToStart=0] time before first results return (same as delay)
     * @param {Number} [options.timeToComplete=0] time before all results return (same as delay)
     * @param {Number} [options.timeToStart=0] time before results return (same as delay)
     * @param {Number} [options.increments=10] Number of times to return partial results after initial delay
     * @param {Object} context
     */
    constructor(
        options: TestDataSourceOptions = {},
        context: TestDataSourceContext = {}
    ) {
        super(options, context);
        this.data = options.data ? options.data : { fields: [], columns: [] };
        this.meta = { ...options.meta } ?? {};
        if (typeof this.meta.sid === 'undefined') {
            this.meta.sid = `${context.id || 'TestDataSource'}_sid`;
        }
        this.timeToStart = options.delay || options.timeToStart || 0;
        this.errorLevel = options.errorLevel;
        this.error = options.error;
        const timeToComplete = options.timeToComplete || 0;
        const increments = Math.max(options.increments || 10, 1);
        this.timePerUpdate = timeToComplete / increments;
        this.incrementSize = Math.ceil(100 / increments);
        this.progress =
            options.meta?.percentComplete ?? (timeToComplete ? 0 : 100);
        this.status = 'queued';
        this.checkRiskyCommand = options.checkRiskyCommand ?? true;
    }

    /**
     * Create a DataSet
     * @param {Object} [config]
     * @param {Array} [config.fields=[]] List of field names
     * @param {Array[]} [config.columns=[]] List of column data
     * @return {DataSet}
     */
    // eslint-disable-next-line class-methods-use-this
    toDataSet({ fields = [], columns = [] }: JSONCols): DataSet {
        return DataSet.fromJSONCols(fields, columns);
    }

    /**
     * Calculate the partial results for a dataset
     * @returns {DataSet}
     */
    getData(requestParams?: RequestParams): DataSet {
        if (this.progress >= 100) {
            // for now, we only allow pagination for completed data source
            return this.toDataSet(this.data).getPage({
                count: requestParams?.count,
                offset: requestParams?.offset,
            });
        }

        const data: JSONCols = { fields: this.data.fields, columns: [] };
        const size = Math.floor(
            this.data.columns[0].length * (this.progress / 100)
        );
        this.data.columns.forEach((column) => {
            if (!size) {
                data.columns.push([]);
            } else {
                data.columns.push(column.slice(0, size));
            }
        });

        return this.toDataSet(data);
    }

    /**
     * Calculate the content of meta
     * @return {Object}
     */
    getMeta(): DataSourceMeta {
        const meta = { ...this.meta, status: this.status };

        if (this.status === 'failed') {
            return meta;
        }

        meta.percentComplete = this.progress;
        // Number of results produced so far...
        meta.totalCount = Math.floor(
            (this.data.columns?.[0]?.length ?? 0) * (this.progress / 100)
        );

        if (this.status !== 'queued') {
            meta.lastUpdated = new Date().toISOString();
        }

        return meta;
    }

    isIgnorableRiskyCommand = () =>
        // Ignorable risky command needs to actually be a risky command
        this.error?.startsWith('Found potentially risky commands:') &&
        // and it needs to have checkRiskyCommand = false
        !this.checkRiskyCommand;

    /**
     * Creates loop to request and return results
     * @return {Function} Given an observable, schedule data updates over time
     */
    request(requestParams?: RequestParams) {
        return (observer?: Subscriber<ObservableData>): (() => void) => {
            if (!observer) {
                return () => undefined;
            }

            if (this.error && !this.isIgnorableRiskyCommand()) {
                this.status = 'failed';
                observer.error({
                    level: this.errorLevel,
                    message: this.error,
                    meta: this.getMeta(),
                });
                return () => undefined;
            }
            let resultScheduler: SimpleScheduler;

            const timer = setTimeout(() => {
                this.status = 'running';

                if (this.meta.isRealTimeSearch) {
                    // If isRealTimeSearch is set in `.options.meta` then
                    // preserve that during the observer.next call. Otherwise
                    // run the observer to completion as usual.
                    observer.next({
                        meta: {
                            isRealTimeSearch: true,
                            status: 'running',
                        },
                    });
                } else if (
                    typeof this.meta.percentComplete !== 'undefined' &&
                    this.meta.freezeProgress
                ) {
                    // Allow for data sources to have "frozen" progress values
                    // for rendering static progress bar sizes
                    observer.next({
                        meta: {
                            percentComplete: this.meta.percentComplete,
                            totalCount: this.meta.totalCount || 0,
                            status: 'running',
                        },
                    });
                } else {
                    resultScheduler = SimpleScheduler.createScheduler(
                        async () => {
                            if (this.progress >= 100) {
                                this.status = 'done';
                            }

                            observer.next({
                                data: this.getData(requestParams),
                                meta: this.getMeta(),
                            });

                            if (this.progress >= 100) {
                                observer.complete();
                            }

                            this.progress = Math.min(
                                this.progress + this.incrementSize,
                                100
                            );

                            return this.timePerUpdate;
                        }
                    );
                    // kick off the loop
                    resultScheduler.start();
                }
            }, this.timeToStart);

            return () => {
                clearTimeout(timer);
                if (this.status === 'running') {
                    this.status = 'canceled';
                }
                if (resultScheduler) {
                    resultScheduler.stop();
                }
            };
        };
    }
}
