import { _ } from '@splunk/ui-utils/i18n';
import pick from '@splunk/themes/pick';
import variables from '@splunk/themes/variables';

const editorConfig = [
    {
        label: _('Visualization Settings'),
        open: true,
        layout: [
            [
                {
                    label: _('Pulsate Max Value'),
                    option: 'showMaxValuePulsation',
                    editor: 'editor.toggle',
                },
            ],
            [
                {
                    label: _('Background Color'),
                    option: 'backgroundColor',
                    editor: 'editor.color',
                    editorProps: {
                        pickFromTheme: theme =>
                            pick({
                                enterprise: {
                                    dark: variables.black,
                                    light: variables.backgroundColor,
                                },
                                prisma: variables.backgroundColorSidebar,
                            })({ theme }),
                    },
                },
            ],
            [
                {
                    label: _('Show Bubble Labels'),
                    option: 'showBubbleLabels',
                    editor: 'editor.radioBar',
                    editorProps: {
                        values: [
                            { label: _('All'), value: 'all' },
                            { label: _('Max'), value: 'max' },
                            { label: _('None'), value: 'none' },
                        ],
                    },
                },
            ],
            [
                {
                    label: _('Bubble Row Scale'),
                    option: 'bubbleRowScale',
                    editor: 'editor.radioBar',
                    editorProps: {
                        values: [
                            { label: _('Global'), value: 'global' },
                            { label: _('By Row'), value: 'row' },
                        ],
                    },
                },
            ],
            [
                {
                    label: _('Bubble Scale'),
                    option: 'bubbleScale',
                    editor: 'editor.radioBar',
                    editorProps: {
                        values: [
                            { label: _('Radius'), value: 'radius' },
                            { label: _('Area'), value: 'area' },
                        ],
                    },
                },
            ],
        ],
    },
    {
        // TODO: This should be hidden if there isn't any categorical data passed in
        label: _('Color Mode'),
        open: true,
        layout: [
            [
                {
                    label: _('Color Mode'),
                    option: 'colorMode',
                    editor: 'editor.radioBar',
                    editorProps: {
                        values: [
                            { label: _('Sequential'), value: 'sequential' },
                            { label: _('Categorical'), value: 'categorical' },
                        ],
                    },
                },
            ],
            [
                // This should be hidden in categorical color mode
                {
                    label: _('Bubble Color'),
                    option: 'bubbleColor',
                    editor: 'editor.color',
                    showEditor: ({ options }) => options.colorMode === 'sequential',
                },
            ],
            [
                // This should be hidden in sequential color mode
                {
                    label: _('Show Legend'),
                    option: 'showLegend',
                    editor: 'editor.toggle',
                    showEditor: ({ options }) =>
                        options.colorMode === 'categorical' || options.colorMode === undefined,
                },
            ],
        ],
    },
];

export default editorConfig;
