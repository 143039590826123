import { useEffect } from 'react';
import { isEqual, isEmpty } from 'lodash';

import { useEventCallback } from '@splunk/dashboard-ui';
import {
    getWindowLocationSearch,
    mapURLToTokens,
    mapTokensToURL,
    DEFAULT_TOKEN_NAMESPACE,
} from '@splunk/dashboard-utils';

import {
    selectInputs,
    setInputState,
    selectSubmittedTokens,
    setToken,
    useDispatch,
    useSelector,
    batch,
} from '@splunk/dashboard-state';
import { usePreset } from '@splunk/dashboard-context';

export const useTokensWithUrl = (): void => {
    const dispatch = useDispatch();
    const inputDefs = useSelector(selectInputs);
    const tokens = useSelector(selectSubmittedTokens);
    const preset = usePreset();

    // On popstate we get the new tokens from the url
    // and dispatch setInputState and setToken
    const handlePopState = useEventCallback(() => {
        const newTokens = mapURLToTokens({
            search: getWindowLocationSearch(),
        });

        if (!isEqual(tokens.default, newTokens.default)) {
            batch(() => {
                dispatch(
                    setInputState({
                        currentInputState: {},
                        inputDefs,
                        tokens: newTokens,
                        preset,
                    })
                );
                dispatch(setToken(newTokens.default));
            });
        }
    });

    // Listen for the popstate event
    useEffect(() => {
        window.addEventListener('popstate', handlePopState);
        return () => {
            window.removeEventListener('popstate', handlePopState);
        };
    }, [handlePopState]);

    // When tokens change push a new history item
    useEffect(() => {
        if (isEmpty(tokens?.[DEFAULT_TOKEN_NAMESPACE])) {
            return undefined;
        }

        const search = getWindowLocationSearch();

        const searchWithTokens = mapTokensToURL({
            search,
            tokens,
        });

        if (search !== `?${searchWithTokens}`) {
            const url: URL = new URL(window.location.href);
            url.search = searchWithTokens;
            window.history.pushState({}, '', url.toString());
        }
        return undefined;
    }, [tokens]);
};

export default useTokensWithUrl;
