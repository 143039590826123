import ParallelCoordinates from './PureParallelCoordinates/PureParallelCoordinates';
import withDashboardViz from '../common/withDashboardViz';
import vizConfig from './config';
import { DataSource } from '../common/interfaces/DataSource';
import { isLoadingOrNoColumns } from '../common/utils/singleValueUtils';

export const config = vizConfig;
export const PureParallelCoordinates = ParallelCoordinates;

export const computeVizProps = ({ themeFunc, ...props }) => {
    const defaultThemeColorVariables = [
        'defaultBackgroundColor',
        'axisLineColor',
        'axisLineHighlightColor',
        'axisLabelColor',
        'axisTitleColor',
        'filterButtonDisabledColor',
        'filterButtonActiveColor',
        'filterTextColor',
        'truncatedTextColor',
        'filterSelectionColor',
        'textShadowColor',
    ];
    const defaultThemeColors: Record<string, unknown> = {};
    defaultThemeColorVariables.forEach(variable => {
        defaultThemeColors[variable] = themeFunc(variable);
    });
    if (props.dataSources) {
        const { fields, columns } = props.dataSources.primary.data;
        return {
            fields: fields.map(field => (field.name ? field.name : field)),
            columns,
            ...defaultThemeColors,
        };
    }
    return defaultThemeColors;
};

const viz = withDashboardViz({
    ReactViz: PureParallelCoordinates,
    vizConfig,
    computeVizProps,
    useIconPlaceholder: (dataSources: { [name: string]: DataSource }, loading: boolean) =>
        isLoadingOrNoColumns(dataSources, loading),
});
export const { themes } = vizConfig;
export default viz;
