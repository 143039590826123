import React from 'react';
import T from 'prop-types';
import styled from 'styled-components';
import ArrowUp from '@splunk/react-icons/enterprise/ArrowUp';
import ArrowDown from '@splunk/react-icons/enterprise/ArrowDown';
import { DEFAULT_FONT_FAMILY } from '../common/utils/singleValueUtils';

const Column = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: ${props => props.justify || 'flex-end'};
    height: ${props => props.size || 12}px;
    ${props => (props.size ? `margin: 0 ${parseInt(props.size, 10) * 0.2 || 5}px;` : '')};
`;

const Text = styled.div`
    white-space: nowrap;
    font-size: ${props => props.size || 12}px;
    color: ${props => props.color || '#000'};
    font-family: ${DEFAULT_FONT_FAMILY};
    height: ${props => props.size || 12}px;
    line-height: ${props => props.size || 12}px;
    display: flex;
    align-self: baseline;
    ${props => (props.size ? `margin-left: ${parseInt(props.size, 10) * 0.2 || 5}px;` : '')};
`;

const SingleValueContent = ({ fontSize, color, value, text }) => {
    let TrendIndicator = false;

    if (parseFloat(value, 10) > 0) {
        TrendIndicator = ArrowUp;
    } else if (parseFloat(value, 10) < 0) {
        TrendIndicator = ArrowDown;
    }

    return (
        <Column justify="flex-end" size={fontSize}>
            <Text data-test="trendValueIndicator" size={fontSize} color={color}>
                {TrendIndicator && <TrendIndicator />}
            </Text>
            <Text data-test="trendValue" size={fontSize} color={color}>
                {`${text}`}
            </Text>
        </Column>
    );
};

SingleValueContent.propTypes = {
    fontSize: T.number,
    color: T.string,
    value: T.oneOfType([T.string, T.number]),
    text: T.string,
};

export default SingleValueContent;
