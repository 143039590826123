import { useState, useCallback, useMemo } from 'react';

type Callback = () => void;

export default ({ initialOpen = false }: { initialOpen?: boolean } = {}): {
    open: boolean;
    onRequestOpen: Callback;
    onRequestClose: Callback;
} => {
    const [open, setOpen] = useState(initialOpen);

    const handleOpen: Callback = useCallback(() => setOpen(true), []);
    const handleClose: Callback = useCallback(() => setOpen(false), []);

    return useMemo(
        () => ({
            open,
            onRequestOpen: handleOpen,
            onRequestClose: handleClose,
        }),
        [open, handleOpen, handleClose]
    );
};
