import React, {
    forwardRef,
    useCallback,
    useMemo,
    type MouseEventHandler,
    type ReactNode,
} from 'react';
import styled from 'styled-components';

// SUI
import Resize, {
    type ResizeDirection,
    type ResizeRequestResizeHandler,
} from '@splunk/react-ui/Resize';

// UDF
import { noop } from '@splunk/dashboard-utils';
import { FlyoutHeader } from './FlyoutHeader';
import { useGetFlyoutHeaderHeight } from './useGetFlyoutHeaderHeight';
import { customThemeVariables } from '../customThemeVariables';

// When styled components don't have a prop generic syntax highlighting can be messed up
type NoStyledProps = Record<string, unknown>;

const FlyoutContainer = styled.div.attrs(() => ({
    'data-test': 'flyout-pane',
}))<NoStyledProps>`
    background: ${customThemeVariables.sidebarPanelBackgroundColor};
    border-top: 1px solid ${customThemeVariables.toolbarBorderColor};
`;

const DisabledFlyoutResizeHandles: ResizeDirection[] = [];
const FlyoutResizeHandles: ResizeDirection[] = ['n'];

export type FlyoutPaneProps = {
    children: ReactNode | ReactNode[];
    collapsible?: boolean;
    collapsed?: boolean;
    headerText?: string;
    height: number;
    onClose: () => void;
    onResize: (height: number) => void;
    onToggleExpansion?: () => void;
};

export const FlyoutPane = forwardRef<HTMLDivElement, FlyoutPaneProps>(
    (
        {
            children,
            collapsible = false,
            collapsed = false,
            headerText,
            height,
            onClose,
            onResize,
            onToggleExpansion = noop,
        },
        ref
    ) => {
        const headerHeight = useGetFlyoutHeaderHeight();

        // Even if the collapsed prop indicates the panel should be collapsed
        // always respect the value of collapsible too
        const isCollapsed = collapsible && collapsed;

        const handlePaneClose = useCallback<MouseEventHandler>(() => {
            onClose();
        }, [onClose]);

        // Prevent resizing to smaller than the header height
        const handlePaneResize = useCallback<ResizeRequestResizeHandler>(
            (_event, { height: newHeight }) => {
                if (newHeight < headerHeight) {
                    onResize(headerHeight);
                } else {
                    onResize(newHeight);
                }
            },
            [headerHeight, onResize]
        );

        const paneStyle = useMemo(
            () => ({
                height: isCollapsed ? headerHeight : height,
            }),
            [headerHeight, height, isCollapsed]
        );

        return (
            <FlyoutContainer ref={ref}>
                <Resize
                    onRequestResize={handlePaneResize}
                    resizeHandles={
                        // If collapsed don't allow resizing
                        isCollapsed
                            ? DisabledFlyoutResizeHandles
                            : FlyoutResizeHandles
                    }
                    appearance="overlay"
                    showHandles="on-hover"
                    style={paneStyle}
                >
                    <FlyoutHeader
                        onCloseClick={handlePaneClose}
                        collapsible={collapsible}
                        isCollapsed={isCollapsed}
                        toggleExpansion={onToggleExpansion}
                        text={headerText}
                    />
                    {!isCollapsed && height > headerHeight && children}
                </Resize>
            </FlyoutContainer>
        );
    }
);
