import type { GeoJsonDataType } from '@splunk/visualizations-shared/mapUtils';
import { DataPoint } from './DataPoint';
import { DataSeries } from './DataSeries';
import { getDataTypeForPoint } from './utils/types';
import { DataPrimitive, TypedValue, DataType, IDataFrame } from './DataPrimitive';
import { IDimension } from './Dimensions';

export type ColumnVal = number | string | string[];
export interface ColumnarData {
    data: {
        fields: { name: string; type?: string }[] | string[];
        columns: ColumnVal[][];
    };
}

/**
 * Base DataFrame class and associated DataFrame selectors
 * @implements {DataPrimitive}
 */
export class DataFrame<T extends DataType = DataType> implements IDataFrame<DataType>, DataPrimitive<T> {
    /**
     * List of series comprising the DataFrame
     * @public
     * @instance
     */
    public series: DataSeries<T>[];

    /**
     * @param {any} o
     * @returns {boolean}
     */
    static isDataFrame(o: any): o is DataFrame {
        return o instanceof DataFrame;
    }

    /**
     * @param {array} series list of data series
     */
    constructor(series: DataSeries<T>[]) {
        this.series = Array.isArray(series) ? series : [series];
    }

    /**
     * Loads ColumnarData into a DataFrame
     * @param {ColumnarData} columnarData
     * @returns {DataFrame<T>}
     */
    static fromJsonCols<ColType extends DataType = DataType>(columnarData: ColumnarData): DataFrame<ColType> {
        if (!columnarData || columnarData.data === null) {
            return new DataFrame<ColType>([]);
        }
        // fixme todo why do we expect an object like {data:{fields, columns}}? Why don't we just expect something like {fields, columns}? The nesting inside 'data' feels useless
        const {
            data: { fields = [], columns = [] },
        } = columnarData;
        if (columns.length !== fields.length) {
            throw new Error(
                `number of columns (${columns.length}) does not match number of fields (${fields.length})`
            );
        }
        const dataSeries = [];
        columns.forEach((data: ColumnVal[], idx): void => {
            const dataPoints = [];
            const fieldInfo: any = fields[idx];
            const name: string = fieldInfo.name || fieldInfo;
            const type: string = fieldInfo?.type;
            data.forEach((value): void => {
                const dataType: DataType = getDataTypeForPoint(value, { name, type });
                dataPoints.push(new DataPoint(name, { value, type: dataType }));
            });
            dataSeries.push(new DataSeries(dataPoints));
        });
        return new DataFrame<ColType>(dataSeries);
    }

    static fromRaw(f: any[][]): DataFrame {
        const series: DataSeries[] = [];
        f.forEach((s: any[]): void => {
            series.push(DataSeries.fromRaw(s));
        });
        return new DataFrame(series);
    }

    // eslint-disable-next-line consistent-return
    static fromDataPrimitive(dp: IDimension): DataFrame {
        if (DataFrame.isDataFrame(dp)) {
            return dp;
        }
        if (DataSeries.isDataSeries(dp)) {
            return new DataFrame([dp]);
        }
        if (DataPoint.isDataPoint(dp)) {
            return new DataFrame([new DataSeries([dp])]);
        }
    }

    /**
     * Filter a DataFrame by specifying the indexes of the DataSeries you would like to return.
     * For example, `frameBySeriesIndexes(first_index, second_index, ...)`, where at least one index is required.
     * @public
     * @param {number[]} indexes
     * @returns {DataFrame.<DataType>}
     *
     * The following code sample shows how to use frameBySeriesIndexes to render a table that includes the first, second, and fourth DataSeries from the data source.
     *
     * <Table
     *   options={{
     *     table: '> primary | frameBySeriesIndexes(0,1,3)'
     *   }},
     *   dataSources={{
     *     primary: {
     *       data: {
     *         fields: [
     *           {
     *             name: 'Name'
     *           },
     *           {
     *             name: 'UserID'
     *           },
     *           {
     *             name: 'Money Spent'
     *           },
     *           {
     *             name: 'Most Recent Game'
     *           }
     *         ],
     *         columns: [
     *           [
     *             'Ms. Herman Beer',
     *             'Crystal Ziemann',
     *             'Phil Bartoletti',
     *             'Janis Kiehn V',
     *             'Angel Krajcik',
     *             'Patti Hodkiewicz IV',
     *             'Joanne Emmerich',
     *             'Jay Renner',
     *             'Ora Borer',
     *             'Dr. Bradford Gulgowski'
     *           ],
     *           [
     *             'Enrico98',
     *             'Taylor_Parker83',
     *             'Candice_Carroll',
     *             'Yolanda_McLaughlin95',
     *             'Modesto84',
     *             'Elwin52',
     *             'Francis8',
     *             'Charley.Feeney85',
     *             'Jensen_Jacobson74',
     *             'Dora_Volkman'
     *           ],
     *           [
     *             9740890.83,
     *             2107983.52,
     *             5467223.67,
     *             9529184.93,
     *             9692275.78,
     *             5692737.43,
     *             1001734.82,
     *             3848531.8,
     *             3848531.8
     *           ],
     *           [
     *             '2020-04-12T06:32:08-07:00',
     *             '2020-06-06T16:14:04-07:00',
     *             '2020-02-12T09:43:25-08:00',
     *             '2020-07-25T13:19:49-07:00',
     *             '2020-03-16T21:46:40-07:00',
     *             '2020-08-21T08:38:55-07:00',
     *             '2020-09-26T16:06:03-07:00',
     *             '2020-08-10T14:54:16-07:00',
     *             '2020-08-11T16:49:24-07:00',
     *             '2020-09-29T03:52:51-07:00'
     *           ]
     *         ]
     *       },
     *       meta: {
     *         totalCount: 100
     *       },
     *       requestParams: {
     *         count: 10,
     *         offset: 0
     *       }
     *     }
     *   }}
     * />
     */
    frameBySeriesIndexes(...indexes: number[]): DataFrame<DataType> {
        const indexedSeries: DataSeries<DataType>[] = [];
        indexes.forEach((index): void => {
            const ds = this.series[index]; // should we allow negative indexes?
            if (ds != null) {
                indexedSeries.push(ds);
            }
        });
        return new DataFrame<DataType>(indexedSeries);
    }

    /**
     * Select a DataSeries by specifying the index of the series you want to return.
     * @public
     * @param {number} index
     * @returns {DataSeries<DataType>}
     *
     * The following code sample shows how to use seriesByIndex to display the second series in a data source as a single value.
     *
     * <SingleValue
     *     options={{
     *         sparklineValues: '> primary | seriesByIndex(1)'
     *     }}
     *     dataSources={{
     *         primary: {
     *             data: {
     *                 fields: [
     *                     {
     *                         name: '_time',
     *                     },
     *                     {
     *                         name: 'count',
     *                     },
     *                 ],
     *                 columns: [
     *                     [
     *                         '2018-08-19T00:00:00.000+00:00',
     *                         '2018-08-20T00:00:00.000+00:00',
     *                         '2018-08-21T00:00:00.000+00:00',
     *                         '2018-08-22T00:00:00.000+00:00',
     *                         '2018-08-23T00:00:00.000+00:00',
     *                         '2018-08-24T00:00:00.000+00:00',
     *                         '2018-08-25T00:00:00.000+00:00',
     *                         '2018-08-26T00:00:00.000+00:00',
     *                     ],
     *                     ['1', '62', '103', '308', '587', '876', '930', '1320'],
     *                 ],
     *             },
     *             meta: {},
     *         },
     *     }}
     * />
     *
     */
    // SCP-60632: added a second parameter - empty array as default fallback, hidden from user
    seriesByIndex(index: number, defaultSeries: any = []): DataSeries<DataType> {
        return this.series[index] || defaultSeries;
    }

    /**
     * Filter a DataFrame by specifying the index range [start, end) of the DataSeries you would like to return.
     * For example, `frameBySeriesIndexRange(start_index)` or `frameBySeriesIndexRange(start_index, end_index)`, where at least the starting index is required.
     * @public
     * @param {int} start (inclusive)
     * @param {int=} end (optional, exclusive)
     * @returns {DataFrame<DataType>}
     *
     * The following code sample shows how to use frameBySeriesIndexRange to render a table that includes the first, second, and third series from the data source.
     *
     * <Table
     *   options={{
     *     table: '> primary | frameBySeriesIndexRange(0,2)'
     *   }},
     *   dataSources={{
     *     primary: {
     *       data: {
     *         fields: [
     *           {
     *             name: 'Name'
     *           },
     *           {
     *             name: 'UserID'
     *           },
     *           {
     *             name: 'Money Spent'
     *           },
     *           {
     *             name: 'Most Recent Game'
     *           },
     *         ],
     *         columns: [
     *           [
     *             'Ms. Herman Beer',
     *             'Crystal Ziemann',
     *             'Phil Bartoletti',
     *             'Janis Kiehn V',
     *             'Angel Krajcik',
     *             'Patti Hodkiewicz IV',
     *             'Joanne Emmerich',
     *             'Jay Renner',
     *             'Ora Borer',
     *             'Dr. Bradford Gulgowski'
     *           ],
     *           [
     *             'Enrico98',
     *             'Taylor_Parker83',
     *             'Candice_Carroll',
     *             'Yolanda_McLaughlin95',
     *             'Modesto84',
     *             'Elwin52',
     *             'Francis8',
     *             'Charley.Feeney85',
     *             'Jensen_Jacobson74',
     *             'Dora_Volkman'
     *           ],
     *           [
     *             9740890.83,
     *             2107983.52,
     *             5467223.67,
     *             9529184.93,
     *             9692275.78,
     *             9395814.7,
     *             5692737.43,
     *             1001734.82,
     *             3848531.8,
     *             1691776.38
     *           ],
     *           [
     *             '2020-04-12T06:32:08-07:00',
     *             '2020-06-06T16:14:04-07:00',
     *             '2020-02-12T09:43:25-08:00',
     *             '2020-07-25T13:19:49-07:00',
     *             '2020-03-16T21:46:40-07:00',
     *             '2020-08-21T08:38:55-07:00',
     *             '2020-09-26T16:06:03-07:00',
     *             '2020-08-10T14:54:16-07:00',
     *             '2020-08-11T16:49:24-07:00',
     *             '2020-09-29T03:52:51-07:00'
     *           ]
     *         ]
     *       },
     *       meta: {
     *         totalCount: 100
     *       },
     *       requestParams: {
     *         count: 10,
     *         offset: 0
     *       }
     *     }
     *   }}
     * />
     */
    frameBySeriesIndexRange(start: number, end?: number): DataFrame<DataType> {
        return new DataFrame<DataType>(this.series.slice(start, end));
    }

    /**
     * Filter a DataFrame by specifying the names of the DataSeries you would like to return.
     * For example, `frameBySeriesNames(first_name, second_name, ...)`, where at least one series name is required.
     * @public
     * @param {string[]} names
     * @returns {DataFrame<DataType>}
     *
     * The following code sample shows how to use frameBySeriesNames to render a table with the Name and Money Spent columns in the data source.
     *
     * <Table
     *     options={{
     *         table: '> primary | frameBySeriesNames("Name", "Money Spent")'
     *     }},
     *     dataSources={{
     *         primary: {
     *             data: {
     *                 fields: [
     *                     {
     *                         name: 'Name'
     *                     },
     *                     {
     *                         name: 'UserID'
     *                     },
     *                     {
     *                         name: 'Money Spent'
     *                     },
     *                     {
     *                         name: 'Most Recent Game'
     *                     },
     *                 ],
     *                 columns: [
     *                     [
     *                         'Ms. Herman Beer',
     *                         'Crystal Ziemann',
     *                         'Phil Bartoletti',
     *                         'Janis Kiehn V',
     *                         'Angel Krajcik',
     *                         'Patti Hodkiewicz IV',
     *                         'Joanne Emmerich',
     *                         'Jay Renner',
     *                         'Ora Borer',
     *                         'Dr. Bradford Gulgowski'
     *                     ],
     *                     [
     *                         'Enrico98',
     *                         'Taylor_Parker83',
     *                         'Candice_Carroll',
     *                         'Yolanda_McLaughlin95',
     *                         'Modesto84',
     *                         'Elwin52',
     *                         'Francis8',
     *                         'Charley.Feeney85',
     *                         'Jensen_Jacobson74',
     *                         'Dora_Volkman'
     *                     ],
     *                     [
     *                         9740890.83,
     *                         2107983.52,
     *                         5467223.67,
     *                         9529184.93,
     *                         9692275.78,
     *                         9395814.7,
     *                         5692737.43,
     *                         1001734.82,
     *                         3848531.8,
     *                         1691776.38
     *                     ],
     *                     [
     *                         '2020-04-12T06:32:08-07:00',
     *                         '2020-06-06T16:14:04-07:00',
     *                         '2020-02-12T09:43:25-08:00',
     *                         '2020-07-25T13:19:49-07:00',
     *                         '2020-03-16T21:46:40-07:00',
     *                         '2020-08-21T08:38:55-07:00',
     *                         '2020-09-26T16:06:03-07:00',
     *                         '2020-08-10T14:54:16-07:00',
     *                         '2020-08-11T16:49:24-07:00',
     *                         '2020-09-29T03:52:51-07:00'
     *                     ]
     *                 ]
     *             },
     *             meta: {
     *                 totalCount: 100
     *             },
     *             requestParams {
     *                 count: 10,
     *                 offset: 0
     *             }
     *         }
     *     }}
     * />
     */
    frameBySeriesNames(...names: string[]): DataFrame<DataType> {
        const namedSeries: DataSeries<DataType>[] = [];
        names.forEach((name): void => {
            const series = this.seriesByName(name);
            const found = series && DataSeries.isDataSeries(series);
            if (found) {
                namedSeries.push(series);
            }
        });
        return new DataFrame<DataType>(namedSeries);
    }

    /**
     * Filter a DataFrame by specifying the names of the DataSeries you would like to omit.
     *  e.g. `frameWithoutSeriesNames(first_name, second_name, ...)`, where at least one series name is required.
     * Note: the data columns of internal fields are automatically excluded from the result.
     * @public
     * @param {string[]} names
     * @returns {DataFrame<DataType>}
     */
    frameWithoutSeriesNames(...names: string[]): DataFrame<DataType> {
        if (names == null) {
            return new DataFrame<DataType>(this.series);
        }
        return new DataFrame<DataType>(
            this.series.filter(
                (s: DataSeries<DataType>): boolean => !names.includes(s.field) && !s.field.startsWith('_')
            )
        );
    }

    /**
     * Filter a DataFrame by specifying one or more names or indexes of DataSeries you would like to return.
     * For example,`frameBySeriesNames(first_name_or_index, second_name_or_index, ...)`, where at least one name or index is required.
     * @public
     * @param {...(string|number)} namesOrIndexes
     * @returns {DataFrame<DataType>}
     *
     * The following code sample shows how to use frameBySeriesNamesOrIndexes to render a table with the Name DataSeries and the fourth DataSeries in the data source.
     *
     * <Table
     *     options={{
     *         table: '> primary | frameBySeriesNamesOrIndexes("Name", 3)'
     *     }},
     *     dataSources={{
     *         primary: {
     *             data: {
     *                 fields: [
     *                     {
     *                         name: 'Name'
     *                     },
     *                     {
     *                         name: 'UserID'
     *                     },
     *                     {
     *                         name: 'Money Spent'
     *                     },
     *                     {
     *                         name: 'Most Recent Game'
     *                     },
     *                 ],
     *                 columns: [
     *                     [
     *                         'Ms. Herman Beer',
     *                         'Crystal Ziemann',
     *                         'Phil Bartoletti',
     *                         'Janis Kiehn V',
     *                         'Angel Krajcik',
     *                         'Patti Hodkiewicz IV',
     *                         'Joanne Emmerich',
     *                         'Jay Renner',
     *                         'Ora Borer',
     *                         'Dr. Bradford Gulgowski'
     *                     ],
     *                     [
     *                         'Enrico98',
     *                         'Taylor_Parker83',
     *                         'Candice_Carroll',
     *                         'Yolanda_McLaughlin95',
     *                         'Modesto84',
     *                         'Elwin52',
     *                         'Francis8',
     *                         'Charley.Feeney85',
     *                         'Jensen_Jacobson74',
     *                         'Dora_Volkman'
     *                     ],
     *                     [
     *                         9740890.83,
     *                         2107983.52,
     *                         5467223.67,
     *                         9529184.93,
     *                         9692275.78,
     *                         9395814.7,
     *                         5692737.43,
     *                         1001734.82,
     *                         3848531.8,
     *                         1691776.38
     *                     ],
     *                     [
     *                         '2020-04-12T06:32:08-07:00',
     *                         '2020-06-06T16:14:04-07:00',
     *                         '2020-02-12T09:43:25-08:00',
     *                         '2020-07-25T13:19:49-07:00',
     *                         '2020-03-16T21:46:40-07:00',
     *                         '2020-08-21T08:38:55-07:00',
     *                         '2020-09-26T16:06:03-07:00',
     *                         '2020-08-10T14:54:16-07:00',
     *                         '2020-08-11T16:49:24-07:00',
     *                         '2020-09-29T03:52:51-07:00',
     *                     ]
     *                 ]
     *             },
     *             meta: {
     *                 totalCount: 100
     *             },
     *             requestParams: {
     *                 count: 10,
     *                 offset: 0
     *             }
     *         }
     *     }}
     * />
     */
    frameBySeriesNamesOrIndexes(...mixed: (string | number)[]): DataFrame<DataType> {
        const mixedSeries: DataSeries<DataType>[] = [];
        mixed.forEach((nameOrIndex: string | number): void => {
            const asNumber = Number(nameOrIndex);
            if (Number.isNaN(asNumber)) {
                const found = this.seriesByName(nameOrIndex as string);
                if (found) {
                    mixedSeries.push(found);
                }
            } else {
                const found = this.series[asNumber];
                if (found) {
                    mixedSeries.push(found);
                }
            }
        });
        return new DataFrame<DataType>(mixedSeries);
    }

    /**
     * Filter a DataFrame by specifying one or more types of DataSeries you would like to return.
     * For example, `frameBySeriesNames(first_type, second_type, ...)`, where at least one type is required.
     * @public
     * @param {DataType[]} types
     * @returns {DataFrame<DataType>}
     *
     * The following code sample shows how to use frameBySeriesTypes to render a table that contains only the string columns in the data source.
     *
     * <Table
     *     options={{
     *         table: '> primary | frameBySeriesTypes("string")'
     *     }},
     *     dataSources={{
     *         primary: {
     *             data: {
     *                 fields: [
     *                     {
     *                         name: 'Name'
     *                     },
     *                     {
     *                         name: 'UserID'
     *                     },
     *                     {
     *                         name: 'Money Spent'
     *                     },
     *                     {
     *                         name: 'Most Recent Game'
     *                     },
     *                 ],
     *                 columns: [
     *                     [
     *                         'Ms. Herman Beer',
     *                         'Crystal Ziemann',
     *                         'Phil Bartoletti',
     *                         'Janis Kiehn V',
     *                         'Angel Krajcik',
     *                         'Patti Hodkiewicz IV',
     *                         'Joanne Emmerich',
     *                         'Jay Renner',
     *                         'Ora Borer',
     *                         'Dr. Bradford Gulgowski'
     *                     ],
     *                     [
     *                         'Enrico98',
     *                         'Taylor_Parker83',
     *                         'Candice_Carroll',
     *                         'Yolanda_McLaughlin95',
     *                         'Modesto84',
     *                         'Elwin52',
     *                         'Francis8',
     *                         'Charley.Feeney85',
     *                         'Jensen_Jacobson74',
     *                         'Dora_Volkman'
     *                     ],
     *                     [
     *                         9740890.83,
     *                         2107983.52,
     *                         5467223.67,
     *                         9529184.93,
     *                         9692275.78,
     *                         9395814.7,
     *                         5692737.43,
     *                         1001734.82,
     *                         3848531.8,
     *                         1691776.38
     *                     ],
     *                     [
     *                         '2020-04-12T06:32:08-07:00',
     *                         '2020-06-06T16:14:04-07:00',
     *                         '2020-02-12T09:43:25-08:00',
     *                         '2020-07-25T13:19:49-07:00',
     *                         '2020-03-16T21:46:40-07:00',
     *                         '2020-08-21T08:38:55-07:00',
     *                         '2020-09-26T16:06:03-07:00',
     *                         '2020-08-10T14:54:16-07:00',
     *                         '2020-08-11T16:49:24-07:00',
     *                         '2020-09-29T03:52:51-07:00'
     *                     ]
     *                 ]
     *             },
     *             meta: {
     *                 totalCount: 100
     *             },
     *             requestParams: {
     *                 count: 10,
     *                 offset: 0
     *             }
     *         }
     *     }}
     * />
     */
    frameBySeriesTypes(...types: T[]): DataFrame<T> {
        return (new DataFrame<T>(
            this.series.filter((s: DataSeries<T>): boolean => types.includes(s.getType() as T))
        ) as unknown) as DataFrame<T>;
    }

    /**
     * Select a DataSeries by specifying the name of the DataSeries you would like to return.
     * @public
     * @param {string} field
     * @returns {DataSeries<DataType>}
     *
     * The following code sample shows how to use seriesByName to display the count series in a data source as a single value.
     *
     * <SingleValue
     *     options={{
     *         sparklineValues: '> primary | seriesByName("count")',
     *     }}
     *     dataSources={{
     *         primary: {
     *             data: {
     *                 fields: [
     *                     {
     *                         name: '_time',
     *                     },
     *                     {
     *                         name: 'count',
     *                     },
     *                 ],
     *                 columns: [
     *                     [
     *                         '2018-08-19T00:00:00.000+00:00',
     *                         '2018-08-20T00:00:00.000+00:00',
     *                         '2018-08-21T00:00:00.000+00:00',
     *                         '2018-08-22T00:00:00.000+00:00',
     *                         '2018-08-23T00:00:00.000+00:00',
     *                         '2018-08-24T00:00:00.000+00:00',
     *                         '2018-08-25T00:00:00.000+00:00',
     *                         '2018-08-26T00:00:00.000+00:00',
     *                     ],
     *                     ['1', '62', '103', '308', '587', '876', '930', '1320'],
     *                 ],
     *             },
     *             meta: {},
     *         },
     *     }}
     * />
     */
    // SCP-60632: added a second parameter - empty array as default fallback, hidden from user
    seriesByName(field: string, defaultSeries: any = []): DataSeries<DataType> {
        return this.series.find((dataSeries): boolean => field === dataSeries.field) ?? defaultSeries;
    }

    /**
     * Select a DataSeries by specifying the type of the DataSeries you would like to return. The first DataSeries that matches the first type specified will be returned. If no DataSeries matches the first type specified, no DataSeries will be returned.
     * @public
     * @param {DataType} type
     * @returns {DataSeries<DataType>}
     *
     * The following code sample shows how to use seriesByType to display the first numeric series in a data source as a single value.
     *
     * If no numeric series are found, return nothing and display N/A.
     *
     * <SingleValue
     *     options={{
     *         sparklineValues: '> primary | seriesByType("number")',
     *     }}
     *     dataSources: {{
     *         primary: {
     *             data: {
     *                 fields: [
     *                     {
     *                         name: '_time",
     *                     },
     *                     {
     *                         name: 'count',
     *                     },
     *                 ],
     *                 columns: [
     *                     [
     *                         '2018-08-19T00:00:00.000+00:00',
     *                         '2018-08-20T00:00:00.000+00:00',
     *                         '2018-08-21T00:00:00.000+00:00',
     *                         '2018-08-22T00:00:00.000+00:00',
     *                         '2018-08-23T00:00:00.000+00:00',
     *                         '2018-08-24T00:00:00.000+00:00',
     *                         '2018-08-25T00:00:00.000+00:00',
     *                         '2018-08-26T00:00:00.000+00:00',
     *                     ],
     *                     [ '1', '62', '103', '308', '587', '876', '930', '1320'],
     *                 ],
     *             },
     *             meta: {},
     *         },
     *     }}
     * />
     */
    seriesByType(type: DataType): DataSeries<DataType> {
        return this.series.find(dataSeries => {
            const dataType: DataType = dataSeries.getType() as DataType;
            return dataType === type;
        });
    }

    /**
     * Select a DataSeries by specifying any acceptable types of the DataSeries you would like to return. The first DataSeries that matches any of the types specified will be returned. If no DataSeries matches the type options specified, no DataSeries will be returned.
     * @public
     * @param {DataType[]} types
     * @returns {DataSeries<DataType>}
     */
    seriesByTypes(...types: DataType[]): DataSeries<DataType> {
        return this.series.find(dataSeries =>
            types.some(type => (dataSeries.getType() as DataType) === type)
        );
    }

    /**
     * Select a DataSeries by specifying the prioritized types of the DataSeries you would like to return. The first DataSeries that matches the first type specified will be returned. If no DataSeries matches the first type specified, the first DataSeries that matches the second type will be returned, and so on.
     * @public
     * @param {DataType[]} types
     * @returns {DataSeries<DataType>}
     *
     * The following code sample shows how to use seriesByPrioritizedTypes to display the first numeric series in a data source as a single value.
     *
     * If no numeric series are found in the data, the first series of type string is displayed. If no string series are found, the first series of type time is displayed.
     *
     * <SingleValue
     *     options={{
     *         sparklineValues: '> primary | seriesByPrioritizedTypes'("number", "string", "time"),
     *     }}
     *     dataSources={{
     *         primary: {
     *             data: {
     *                 columns: [
     *                     [
     *                         '2018-08-19T00:00:00.000+00:00',
     *                         '2018-08-20T00:00:00.000+00:00',
     *                         '2018-08-21T00:00:00.000+00:00',
     *                         '2018-08-22T00:00:00.000+00:00',
     *                         '2018-08-23T00:00:00.000+00:00',
     *                         '2018-08-24T00:00:00.000+00:00',
     *                         '2018-08-25T00:00:00.000+00:00',
     *                         '2018-08-26T00:00:00.000+00:00',
     *                     ],
     *                     [ 'INFO', 'ERROR', 'WARN', 'INFO', 'ERROR', 'WARN', 'INFO', 'WARN'],
     *                 ],
     *                 fields: [
     *                     {
     *                         name: '_time',
     *                     },
     *                     {
     *                         name: 'status',
     *                     },
     *                 ],
     *             },
     *             meta: {},
     *         },
     *     }}
     * />
     */
    seriesByPrioritizedTypes(...types: DataType[]): DataSeries<DataType> {
        // revisit this for of loop next time for performance issues (due to regenerator-runtime)
        // eslint-disable-next-line no-restricted-syntax
        for (const type of types) {
            const matchedSeries: DataSeries<DataType> = this.seriesByType(type);
            if (Array.isArray(matchedSeries?.points) && matchedSeries?.points?.length) {
                return matchedSeries;
            }
        }
        return this.series[0];
    }

    /**
     * Set all values in the DataFrame to a static TypedValue.
     * @public
     * @param {TypedValue} v
     */
    setValue(v: TypedValue<T>): void {
        this.series.forEach((s): void => s.setValue(v));
    }

    /**
     * Get all values in the DataFrame. This excludes field names.
     * @public
     * @returns {array}
     */
    getRawValue(): (string | number | GeoJsonDataType)[][] {
        const values: (string | number | GeoJsonDataType)[][] = [];
        this.series.forEach((s): void => {
            values.push(s.getRawValue());
        });
        return values;
    }

    /**
     * Get all values and their types in the DataFrame. This excludes field names.
     * @public
     * @returns {TypedValue[][]}
     */
    getValue(): TypedValue<DataType>[][] {
        const values: TypedValue<DataType>[][] = [];
        this.series.forEach((s): void => {
            values.push(s.getValue());
        });
        return values;
    }

    /**
     * Get the names of each DataSeries in the DataFrame.
     * @public
     * @returns {DataSeries<string>}
     *
     * ```
     * <Table
     *   options={{
     *       headers: '> table | getField()'
     *   }},
     *   dataSources={{
     *     primary: {
     *       data: {
     *         fields: [
     *           {
     *              name: 'Name'
     *           },
     *           {
     *              name: 'UserID'
     *           },
     *           {
     *              name: 'Money Spent'
     *           },
     *           {
     *              name: 'Most Recent Game'
     *           },
     *         ],
     *         columns: [
     *           [
     *              'Ms. Herman Beer',
     *              'Crystal Ziemann',
     *              'Phil Bartoletti',
     *              'Janis Kiehn V',
     *              'Angel Krajcik',
     *              'Patti Hodkiewicz IV',
     *              'Joanne Emmerich',
     *              'Jay Renner',
     *              'Ora Borer',
     *              'Dr. Bradford Gulgowski'
     *           ],
     *           [
     *              'Enrico98',
     *              'Taylor_Parker83',
     *              'Candice_Carroll',
     *              'Yolanda_McLaughlin95',
     *              'Modesto84',
     *              'Elwin52',
     *              'Francis8',
     *              'Charley.Feeney85',
     *              'Jensen_Jacobson74',
     *              'Dore_Volkman'
     *           ],
     *           [
     *              9740890.83,
     *              2107983.52,
     *              5467223.67,
     *              9529184.93,
     *              9692275.78,
     *              9395814.7,
     *              5692737.43,
     *              1001734.82,
     *              3848531.8,
     *              1691776.38
     *           ],
     *           [
     *              '2020-04-12T06:32:08-07:00',
     *              '2020-06-06T16:14:04-07:00',
     *              '2020-02-12T09:43:25-08:00',
     *              '2020-07-25T13:19:49-07:00',
     *              '2020-03-16T21:46:40-07:00',
     *              '2020-08-21T08:38:55-07:00',
     *              '2020-09-26T16:06:03-07:00',
     *              '2020-08-10T14:54:16-07:00',
     *              '2020-08-11T16:49:24-07:00',
     *              '2020-09-29T03:52:51-07:00'
     *           ]
     *         ]
     *      },
     *      meta: {
     *        totalCount: 100
     *      },
     *      requestParams: {
     *        count: 10,
     *        offset: 0
     *      }
     *     }
     *   }}
     * />
     *
     * Display the third and fourth DataSeries in a column chart as overlay fields.
     *
     * <Column
     *   options={{
     *     overlayFields: '> primary | frameBySeriesIndex(2,3) | getField()'
     *   }},
     *   dataSources={{
     *     primary: {
     *       requestParams: { offset: 0, count: 20},
     *       data: {
     *         fields: [
     *             { name: '_time' },
     *             { name: 'splunkd' },
     *             { name: 'splunkd_web_access' },
     *             { name: 'mongod' },
     *         ],
     *         columns: [
     *             [
     *                 '2018-05-02T18:15:46.000-07:00',
     *                 '2018-05-02T18:15:47.000-07:00',
     *                 '2018-05-02T18:15:48.000-07:00',
     *                 '2018-05-02T18:15:49.000-07:00',
     *                 '2018-05-02T18:15:50.000-07:00',
     *             ],
     *             ['67228', '83195', '3145', '19332', '29763'],
     *             ['67228', '83195', '3145', '19332', '29763'],
     *             ['14881', '17341', '18081', '19774', '10467'],
     *         ]
     *       },
     *       meta: {
     *         totalCount: 100,
     *       }
     *     }
     *   }}
     * />
     */
    getField(): DataSeries<'string'> {
        const points = this.series.map((s): DataPoint<'string'> => s.getField());
        return new DataSeries<'string'>(points);
    }

    /**
     * Get the data type of each DataSeries in the DataFrame.
     * @public
     * @returns {string[]}
     */
    getType(): string[] {
        return this.series.map(s => s.getType());
    }

    /**
     * Get the global minimum value from all numeric DataSeries in the DataFrame.
     * @public
     * @returns {number}
     *
     * The following code sample shows how to use min to display the smallest data point in a data source as a single value.
     *
     * <SingleValue
     *     options={{
     *         majorValue: '> primary | min()',
     *         trendDisplay: 'off',
     *         sparklineDisplay: 'off'
     *     }}
     *     dataSources={{
     *         primary: {
     *             data: {
     *                 fields: [
     *                     {
     *                         name: '_time',
     *                     },
     *                     {
     *                         name: 'count',
     *                     },
     *                 ],
     *                 columns: [
     *                     [
     *                         '2018-08-19T00:00:00.000+00:00',
     *                         '2018-08-20T00:00:00.000+00:00',
     *                         '2018-08-21T00:00:00.000+00:00',
     *                         '2018-08-22T00:00:00.000+00:00',
     *                         '2018-08-23T00:00:00.000+00:00',
     *                         '2018-08-24T00:00:00.000+00:00',
     *                         '2018-08-25T00:00:00.000+00:00',
     *                         '2018-08-26T00:00:00.000+00:00',
     *                     ],
     *                     ['1', '62', '103', '308', '587', '876', '930', '1320'],
     *                 ],
     *             },
     *             meta: {},
     *         },
     *     }}
     * />
     */
    min(): DataPoint<DataType> {
        return this.minOrMax('min');
    }

    /**
     * Get the global maximum value from all numeric DataSeries in the DataFrame.
     * @public
     * @returns {number}
     *
     * The following code sample shows how to use max to display the largest data point in a data source as a single value.
     *
     * <SingleValue
     *     options={{
     *         majorValue: '> primary | max()',
     *         trendDisplay: 'off',
     *         sparklineDisplay: 'off',
     *     }}
     *     dataSources={{
     *         primary: {
     *             data: {
     *                 fields: [
     *                     {
     *                         name: '_time',
     *                     },
     *                     {
     *                         name: 'count',
     *                     },
     *                 ],
     *                 columns: [
     *                     [
     *                         '2018-08-19T00:00:00.000+00:00',
     *                         '2018-08-20T00:00:00.000+00:00',
     *                         '2018-08-21T00:00:00.000+00:00',
     *                         '2018-08-22T00:00:00.000+00:00',
     *                         '2018-08-23T00:00:00.000+00:00',
     *                         '2018-08-24T00:00:00.000+00:00',
     *                         '2018-08-25T00:00:00.000+00:00',
     *                         '2018-08-26T00:00:00.000+00:00',
     *                     ],
     *                     [ '1', '62', '103', '308', '587', '876', '930', '1320' ],
     *                 ],
     *             },
     *             meta: {},
     *         },
     *     }}
     * />
     */
    max(): DataPoint<DataType> {
        return this.minOrMax('max');
    }

    /**
     * runs result of min or max function over all the series and returns the data point
     * points
     * @param {string} aggName
     * @returns {DataPoint<T>}
     */
    private minOrMax(funcName: 'min' | 'max'): DataPoint<DataType> {
        const overallSeries = new DataSeries<DataType>();
        this.series.forEach((s): void => {
            const m = s[funcName]();
            if (m) {
                overallSeries.points.push(m);
            }
        });
        return overallSeries[funcName]();
    }
}
