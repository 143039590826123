import * as React from 'react';
import { _ } from '@splunk/ui-utils/i18n';
import SVG from '@splunk/react-icons/SVGEnterprise';

const EditIcon = (props: React.ComponentProps<typeof SVG>): React.ReactElement<SVGElement> => (
    <SVG screenReaderText={_('Edit')} width="16" height="16" viewBox="0 0 16 16" fill="none" {...props}>
        <g clipPath="url(#clip0)">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9.52181 2.86485C10.5206 1.86451 12.1417 1.86474 13.1403 2.86538C14.1373 3.86453 14.1359 5.4827 13.137 6.48004L12.6242 6.99211L11.6821 6.04859L11.6822 6.0485L9.22506 3.58847L9.01185 3.37559L9.01214 3.3753L9.012 3.37516L9.01241 3.37503L9.52181 2.86485ZM2.2961 10.0997L8.07613 4.31412L9.01939 5.25648L9.01604 5.25983L11.6867 7.92415L11.6842 7.92503L11.6863 7.92713L5.9022 13.7058C5.71469 13.8931 5.46048 13.9983 5.19543 13.9983H3.00354C2.45126 13.9983 2.00354 13.5506 2.00354 12.9983V10.8064C2.00354 10.5414 2.10877 10.2872 2.2961 10.0997Z"
                fill="#818D99"
            />
        </g>
        <defs>
            <clipPath id="clip0">
                <rect width="16" height="16" fill="white" />
            </clipPath>
        </defs>
    </SVG>
);

export default EditIcon;
