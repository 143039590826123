import React, { PureComponent } from 'react';
import T from 'prop-types';
import styled from 'styled-components';
import pick from 'lodash/pick';
import { _ } from '@splunk/ui-utils/i18n';
import ImageContext from '@splunk/visualization-context/ImageContext';
import { toDimension } from '@splunk/visualizations-shared/style';

/**
 * check if an image is considered as remote image
 * @param {*} src
 */
export const isRemoteImage = (src = '') => {
    const [type] = src.split('://');
    return src.indexOf('://') > -1 && type !== 'http' && type !== 'https';
};

const toImageDimensions = props => {
    if (props.shouldPreserveAspectRatio) {
        return `
            max-width: 100%;
            max-height: 100%;
            width: auto;
            height: auto;
        `;
    }
    return toDimension(pick(props, ['width', 'height']));
};

const Image = styled.img`
    display: block;
    ${props => toImageDimensions(props)};
`;

class RemoteImage extends PureComponent {
    static contextType = ImageContext;

    static propTypes = {
        imageURL: T.string,
        width: T.oneOfType([T.string, T.number]),
        height: T.oneOfType([T.string, T.number]),
        preserveAspectRatio: T.bool,
        title: T.string,
    };

    static defaultProps = {
        preserveAspectRatio: false,
    };

    constructor(props) {
        super(props);
        this.state = { imageDataURI: null, preserveAspectRatio: props.preserveAspectRatio };
    }

    componentDidMount() {
        this.getImage(this.props.imageURL);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.imageURL !== this.props.imageURL) {
            this.getImage(this.props.imageURL);
        }
        if (prevProps.preserveAspectRatio !== this.props.preserveAspectRatio) {
            // eslint-disable-next-line react/no-did-update-set-state
            this.setState({ preserveAspectRatio: this.props.preserveAspectRatio });
        }
    }

    async getImage(imageURL) {
        const imageRegistry = this.context;
        if (imageRegistry) {
            try {
                const image = await imageRegistry.getByURL(imageURL);
                this.setState({ imageDataURI: image.dataURI });
            } catch (error) {
                // eslint-disable-next-line no-console
                console.error(error);
            }
        }
    }

    render() {
        const { imageDataURI } = this.state;
        const { width, height, title } = this.props;

        if (!imageDataURI) {
            return null;
        }
        return (
            <Image
                src={imageDataURI}
                shouldPreserveAspectRatio={this.state.preserveAspectRatio}
                width={width}
                height={height}
                alt={_(title)}
                onError={e => {
                    e.target.alt = _('Image not found');
                }}
            />
        );
    }
}

export default RemoteImage;
