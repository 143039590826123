import type { VisualizationDefinition } from '@splunk/dashboard-types';
import config from '@splunk/visualizations/SingleValue.config';
import type { EncodingConfig } from '../encoding';
import { encodingToDynamicOptionsDSL } from '../encoding';
import {
    removeInvalidOptions,
    renameVizOptions,
    preserveDataSelectionForValueOption,
} from '../utils/migrationUtils';
import { consolidateTrendDisplay } from '../utils/singlevalue';

const validOptions = {
    ...config.optionsSchema,
};

// old default configurations should be retained in migrated configuration
const defaultsToRetain = {
    showSparklineAreaGraph: false,
    sparklineValues: '> primary | seriesByTypes("number", "string")',
};

const optionRenames = {
    useTrendUnits: 'shouldAbbreviateTrendValue',
    useThousandSeparators: 'shouldUseThousandSeparators',
    sparklineAreaGraph: 'showSparklineAreaGraph',
    sparklineFillColor: 'sparklineAreaColor',
    sparklineAcceptNullData: 'shouldSparklineAcceptNullData',
    deltaFontSize: 'trendFontSize',
};

/**
 * Helper function to consolidate `sparklinePosition` - before/after/below
 * and `showSparkline` - true/false into `sparklineDisplay` - off/before/after/below
 * 1. if `showSparkline` is explicitly false, `sparklineDisplay` is off
 * 2. if `showSparkline` is undefined (defaults to true), return `sparklinePosition` value if present
 * 3. if `showSparkline` is explicitly true, return 'below'
 * 4. if both the old options are undefined, return nothing as their defaults (true and `below`)
 * correspond to `sparklineDisplay` default - below
 */
const consolidateSparklineDisplay = (sparklinePosition: string, showSparkline: boolean): string | void => {
    if (showSparkline === false) {
        return 'off';
    }
    if (['before', 'after', 'below'].includes(sparklinePosition)) {
        return sparklinePosition;
    }
    if (showSparkline === true) {
        return 'below';
    }
    return null;
};

export const migrateVizToSplunkSingleValue = (
    vizDefinition: VisualizationDefinition
): VisualizationDefinition => {
    const { options = {}, encoding = {}, ...otherDefinitionParts } = vizDefinition;
    const migratedVisualization = {
        ...otherDefinitionParts,
        type: 'splunk.singlevalue',
        context: {},
        options: {},
    };

    const { options: dataOptions, context: dataContext } = encodingToDynamicOptionsDSL(
        encoding as Record<string, EncodingConfig>
    );

    const migratedDataOptions = {
        /**
         * in splunk.singlevalue, since we have the defaults
         * trendValue: '> sparklineValues|delta(-2)'
         * majorValue: '> sparklineValues|lastPoint()'
         * computing sparklineValues DSL from trend encoding should be sufficient
         */
        ...(dataOptions?.trend && { sparklineValues: dataOptions.trend }),
        ...(dataOptions?.majorColor && {
            majorColor:
                preserveDataSelectionForValueOption(dataOptions.majorColor as string, 'lastPoint()') ??
                dataOptions.majorColor,
        }),
        ...(dataOptions?.deltaColor && {
            trendColor:
                preserveDataSelectionForValueOption(dataOptions.deltaColor as string, 'lastPoint()') ??
                dataOptions.deltaColor,
        }),
        ...(dataOptions?.fill && {
            backgroundColor:
                preserveDataSelectionForValueOption(dataOptions.fill as string, 'lastPoint()') ??
                dataOptions.fill,
        }),
    };

    const renamedOptions = renameVizOptions(options, optionRenames);

    const { trendDisplayMode, showTrendIndicator, showSparkline, sparklinePosition } = renamedOptions;

    const trendDisplay = consolidateTrendDisplay(trendDisplayMode as string, showTrendIndicator as boolean);
    const sparklineDisplay = consolidateSparklineDisplay(
        sparklinePosition as string,
        showSparkline as boolean
    );

    migratedVisualization.options = {
        ...defaultsToRetain,
        ...renamedOptions,
        ...migratedDataOptions, // fill encoding takes precedence over explicit static backgroundColor
        ...(typeof trendDisplay === 'string' && { trendDisplay }),
        ...(typeof sparklineDisplay === 'string' && { sparklineDisplay }),
    };

    migratedVisualization.context = dataContext;

    // remove invalid (and deprecated) options using the optionsSchema for splunk.singlevalue as reference
    removeInvalidOptions(migratedVisualization.options, validOptions);

    return migratedVisualization;
};
