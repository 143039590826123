import * as T from 'prop-types';
import { has } from 'lodash';
import { DataSourceMeta, SortDirType, validSortDirs } from '../interfaces/DataSource';

export interface SortParams {
    currentSortKey?: undefined | string;
    currentSortDir?: undefined | SortDirType;
    onSort?: (e: MouseEvent, { sortKey, sortDir }: { sortKey: string; sortDir: SortDirType }) => void;
}

export const SortParamsPropTypes = T.shape({
    currentSortKey: T.string,
    currentSortDir: T.oneOf(validSortDirs),
    onSort: T.func,
});

export const getSortingParams = ({ requestParams, onRequestParamsChange }: DataSourceMeta): SortParams => {
    let currentSortKey;
    let currentSortDir;

    const nextSortDirMap = {
        asc: 'desc',
        desc: 'asc',
        none: 'asc',
    };

    let onSort;
    if (typeof onRequestParamsChange === 'function') {
        if (requestParams && has(requestParams, 'sort')) {
            currentSortKey = Object.keys(requestParams.sort).shift();
            currentSortDir = requestParams.sort[currentSortKey];
        }

        // undid memoization since there is a dependency on requestParams, which is a deeply nested object
        onSort = (e, { sortKey, sortDir }): void => {
            if (typeof sortKey === 'string') {
                const nextSortDir = has(nextSortDirMap, sortDir) ? nextSortDirMap[sortDir] : 'none';
                onRequestParamsChange({
                    ...requestParams,
                    ...{ offset: 0, sort: { [sortKey]: nextSortDir } },
                });
            } else {
                onRequestParamsChange(requestParams);
            }
        };
    }

    return {
        currentSortKey,
        currentSortDir,
        onSort,
    };
};
