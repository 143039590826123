import hash from 'hash-it';
import type {
    DataSourceConfigMeta,
    DataSourceDefinition,
    RequestParams,
} from '@splunk/dashboard-types';
import { noop, console } from '@splunk/dashboard-utils';
import { _ } from '@splunk/ui-utils/i18n';
import SearchJob from '@splunk/search-job';
import {
    username as CURRENT_USER,
    app as CURRENT_APP,
} from '@splunk/splunk-utils/config';
import {
    get as getSavedSearch,
    dispatch as dispatchSavedSearch,
    getLatest as getLatestSavedSearch,
    type SavedSearch,
    type DispatchRequestParams,
} from '@splunk/splunk-utils/savedSearch';
import moment from '@splunk/moment';
import DataSource from './DataSource';
import SplunkSavedSearchOptionsSchema from './SplunkSavedSearchOptionsSchema';
import {
    combineResultWhenProgress,
    combineResultWhenFinalized,
    previewFetchPredicate,
    projectFunc,
    transformObserver,
    getEnterpriseSearchContext,
} from './utils/SplunkSearchUtils';

import type {
    EnterpriseSearchContext,
    EnterpriseSearchObservable,
    SplunkSavedSearchOptions,
} from './types';

type Moment = typeof moment;

interface RefreshConfig {
    refresh: string | undefined;
    refreshType: string | undefined;
}

interface SavedSearchMetadata {
    app?: string;
    author?: string;
    earliestTime?: unknown;
    isScheduled?: boolean;
    latestTime?: unknown;
    nextScheduledTime?: unknown;
    savedSearchQuery?: string;
    sharing?: unknown;
    updated?: unknown;
}

type SplunkSavedSearchConfig = DataSourceConfigMeta<
    typeof SplunkSavedSearchOptionsSchema,
    SplunkSavedSearchOptions
>;

export const ownRequestParams: Partial<RequestParams> = {
    output_mode: 'json_cols',
    show_metadata: true,
};

export const defaultParams = {
    // Note: if preview is changed to false, we will not get resultPreviewCount below and
    // may break search status icon behavior
    preview: true,
};

export const defaultRequestParams: Partial<RequestParams> = {
    count: 0,
    offset: 0,
    progress: true,
};

export const getSecondsBetweenDates = (
    date1: Moment,
    date2: Moment
): number => {
    if (!(date1 instanceof moment) || !(date2 instanceof moment)) {
        throw Error('Parameters must be @splunk/moment types');
    }
    return Math.ceil((date2 - date1) / 1000);
};

export const calculateRefreshTime = (nextScheduledTime: string): Moment => {
    // SCP-39715
    // `nextScheduledTime` is in the format like '2021-05-05 01:32:00 CEST' where CEST is a non-standard abbreviation which cannot be recognized by moment() or Date().
    // To handle this issue, we remove the timezone from the timestamp and let `.newSplunkTime()` handle it.
    // Note the timezone depends on Splunk Enterprise user settings, it could be CEST, UTC or some other timezone abbreviations.
    const nextScheduledTimeWithoutTimezone = nextScheduledTime
        .split(' ')
        .slice(0, -1)
        .join(' ');

    const timeOfSavedSearchRetrieval: Moment = moment.newSplunkTime();
    const secondsUntilNextSchedule = getSecondsBetweenDates(
        timeOfSavedSearchRetrieval,
        moment.newSplunkTime({ time: nextScheduledTimeWithoutTimezone })
    );

    // Give a slight buffer of 5 seconds to allow the scheduled job to kick off in an ideal environment,
    // making it less likely to require multiple calls to retrieve the newest job.
    return secondsUntilNextSchedule + 5;
};

export const getSplunkSavedSearchDataSourceName = ({
    dataSource,
}: {
    dataSource?: Partial<DataSourceDefinition>;
}): string => (dataSource?.options?.ref || dataSource?.name || '') as string;

/**
 * A DataSource that talks to splunk enterprise
 * @param {Object} options
 * @param {String} options.ref name of saved search
 * @param {String} options.app name of app saved search belongs to
 * @param {Object} context See [search-job api](https://splunkui.sv.splunk.com/Packages/search-job/API) dispatchOptions object
 * @param {Boolean} [context.keepAlive=true] If true, keep the job alive before teardown
 * @param {Boolean} [context.cache=false] If true, will try and reuse an existing search job if it has the same request params.
 * @param {String} [context.app] The current app in use as defined in the page url and @splunk/splunk-utils/Config
 * @param {String} [context.splunkdPath] splunk rest endpoint path
 * @param {String} [context.sessionKey] current session key
 * @param {String} [context.auditProvenance] provenance value to appear in the splunk audit log
 * @param {String} [context.id] ID of the dashboard performing the query
 * @returns {SplunkSavedSearch} A new SplunkSavedSearch datasource instance.
 * @public
 */
class SplunkSavedSearch extends DataSource<
    SplunkSavedSearchOptions,
    EnterpriseSearchContext
> {
    static schema = SplunkSavedSearchOptionsSchema;

    static config: SplunkSavedSearchConfig;

    savedSearch: Awaited<ReturnType<typeof getSavedSearch>> | null | undefined;

    searchQuery: string | undefined;

    searchJob?: typeof SearchJob;

    setupError: Error | undefined;

    private searchInfo: SavedSearch = { name: '' };

    private results: Record<
        number,
        | ReturnType<typeof combineResultWhenProgress>
        | ReturnType<typeof combineResultWhenFinalized>
    > = {};

    constructor(options = {}, context = {}) {
        super(options, context);

        if (!this.options.ref) {
            throw Error('saved search ref is required!');
        }

        this.context = getEnterpriseSearchContext(
            this.context
        ) as EnterpriseSearchContext;
    }

    /**
     * Creates a search job and returns sid once the job is created on the server.
     * @returns {Promise} The sid of the created job.
     * @public
     */
    async setup(): Promise<string | null> {
        try {
            /**
             * SearchJob can only modify config and fetch params globally.
             */
            const { auditProvenance, id, sessionKey, splunkdPath } =
                this.context;

            if (splunkdPath) {
                SearchJob.setSplunkConfig({ splunkdPath });
            }

            if (sessionKey) {
                SearchJob.setBaseFetchInit({
                    headers: {
                        Authorization: `Splunk ${sessionKey}`,
                    },
                });
            }

            const { app, ref = '', checkRiskyCommand } = this.options;
            this.searchInfo = {
                name: ref,
                app: app || CURRENT_APP,
                owner: CURRENT_USER,
            };

            const requestParams: DispatchRequestParams = {
                'dispatch.check_risky_command': checkRiskyCommand,
                'dispatch.label': id,
            };

            if (auditProvenance) {
                requestParams['dispatch.provenance'] = auditProvenance;
            }

            let sid: string | null = null;

            // fetch report definition
            this.savedSearch = await getSavedSearch(this.searchInfo);

            const { is_scheduled: isScheduled, search: searchQuery } =
                this.savedSearch?.entry?.[0]?.content ?? {};

            this.searchQuery = searchQuery;

            if (isScheduled) {
                const lastSearchJob = await getLatestSavedSearch(
                    this.searchInfo,
                    {
                        search: 'isScheduled=true',
                    },
                    SearchJob.splunkConfig,
                    SearchJob.baseFetchInit
                );

                sid = lastSearchJob?.name ?? null;
                if (!sid) {
                    sid = await this.getSidFromDispatch(requestParams);
                }
            } else {
                sid = await this.getSidFromDispatch(requestParams);
            }

            this.searchJob = SearchJob.fromSid(sid);

            return sid;
        } catch (e) {
            if (e instanceof Error) {
                console.error('failed to retrieve search job:', e);
                this.setupError = e;
            }

            return null;
        }
    }

    private getSidFromDispatch = async (
        requestParams: DispatchRequestParams
    ): Promise<string | null> =>
        (
            await dispatchSavedSearch(
                this.searchInfo,
                requestParams,
                SearchJob.splunkConfig,
                SearchJob.baseFetchInit
            )
        ).sid;

    /**
     * Returns an Observable that emits job information and search results.
     *
     * @param requestParams {Object} - [See documentation for available parameters.](http://docs.splunk.com/Documentation/Splunk/latest/RESTREF/RESTsearch#search.2Fjobs.2F.7Bsearch_id.7D.2Fresults)
     * @returns {Observable}
     * @public
     */
    request(requestParams: RequestParams = {}): EnterpriseSearchObservable {
        if (this.setupError) {
            return (observer) => {
                observer?.error({
                    level: 'error',
                    message: this.setupError?.message,
                    meta: {
                        search: this.searchQuery,
                    },
                });

                return noop;
            };
        }

        const params: RequestParams = {
            ...defaultRequestParams,
            ...requestParams,
            ...ownRequestParams,
        };

        if (params.sort) {
            Object.entries(params.sort).forEach(([sortKey, sortDir]) => {
                if (sortDir) {
                    if (!params.search) {
                        params.search = '| sort 0';
                    }
                    if (sortDir === 'desc') {
                        params.search += ` -"${sortKey}"`;
                    } else if (sortDir === 'asc') {
                        params.search += ` +"${sortKey}"`;
                    }
                }
            });

            delete params.sort;
        }

        const progressObservable = this.searchJob.getProgress();

        // If the requestParams have already been processed then return the existing results
        // else combine results and store the result in the same way ds.search caches data
        const key = hash(requestParams);
        const result =
            this.results[key] ??
            (params.progress
                ? combineResultWhenProgress(
                      this.searchJob.getResultsPreview(params, {
                          fetchPredicate: previewFetchPredicate,
                      }),
                      progressObservable,
                      projectFunc
                  )
                : combineResultWhenFinalized(
                      this.searchJob.getResults(params),
                      progressObservable,
                      projectFunc
                  ));

        this.results[key] = result;
        return (observer) => {
            // note: due to the nature that 'data' and 'meta' are from two different endpoints, there's no
            // way to guarantee that they always match. But they'll eventually match when search is done.
            const subscription = result.subscribe(transformObserver(observer));

            return () => {
                subscription.unsubscribe();
            };
        };
    }

    getRefreshConfig = (): RefreshConfig => {
        let { refresh, refreshType } = this.options;

        if (this.savedSearch) {
            const {
                is_scheduled: isScheduled,
                next_scheduled_time: nextScheduledTime,
            } = this.savedSearch?.entry?.[0]?.content ?? {};

            if (isScheduled) {
                refresh = `${calculateRefreshTime(nextScheduledTime ?? '')}s`;
                refreshType = 'interval';
            }
        }

        return { refresh, refreshType };
    };

    getMetaData(): SavedSearchMetadata {
        const entry = this.savedSearch?.entry?.[0];

        return {
            author: entry?.author,
            updated: entry?.updated,
            app: entry?.acl?.app,
            sharing: entry?.acl?.sharing,
            isScheduled: entry?.content?.is_scheduled,
            nextScheduledTime: entry?.content?.next_scheduled_time,
            savedSearchQuery: entry?.content?.search,
            earliestTime: entry?.content?.['dispatch.earliest_time'],
            latestTime: entry?.content?.['dispatch.latest_time'],
        };
    }

    /**
     * Cancel the search job and returns the server response.
     * Job will not be cancelled if `context.cache` is true.
     * @returns {Promise}
     * @public
     */
    async teardown(): Promise<null> {
        const { cache } = this.context;
        if (!cache && this.searchJob) {
            this.searchJob = null;
            this.savedSearch = null;
        }
        return null;
    }
}

SplunkSavedSearch.config = {
    title: _('Saved search'),
    displayDataSourceItemListByDefault: true,
    canCreateDataSource: false,
    dataSourceRemoveVerb: 'remove',
    isDataSourceNameEditable: false,
    getDataSourceName: getSplunkSavedSearchDataSourceName,
    defaultOptions: {},
    editorConfig: [],
    optionsSchema: SplunkSavedSearchOptionsSchema,
};

export default SplunkSavedSearch;
