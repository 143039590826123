import { isEqual, last } from 'lodash';
import ICoordinateTransformation, { DataPoint, Space } from './ICoordinateTransformation';

export default class TransformationChain implements ICoordinateTransformation {
    public sourceSpace: Space;

    public targetSpace: Space;

    public transformations: ICoordinateTransformation[];

    public constructor(transformations: ICoordinateTransformation[]) {
        this.transformations = [...transformations];
        this.sourceSpace = transformations[0].sourceSpace;
        this.targetSpace = last(transformations).targetSpace;
    }

    public appendTransformation(transformation: ICoordinateTransformation): TransformationChain {
        if (!isEqual(transformation.sourceSpace, last(this.transformations).targetSpace)) {
            throw new Error(`Can't chain transformations with incompatible spaces 
            ${JSON.stringify(last(this.transformations).targetSpace)} -> ${JSON.stringify(
                transformation.sourceSpace
            )}`);
        }
        return new TransformationChain([...this.transformations, transformation]);
    }

    public transform(logicalCoords: DataPoint): DataPoint {
        let currentCoords = logicalCoords;
        this.transformations.forEach((transformation): void => {
            currentCoords = transformation.transform(currentCoords);
        });
        return currentCoords;
    }

    public transformBack(physicalCoords: DataPoint): DataPoint {
        let currentCoords = physicalCoords;
        for (let idx = this.transformations.length - 1; idx >= 0; idx -= 1) {
            currentCoords = this.transformations[idx].transformBack(currentCoords);
        }
        return currentCoords;
    }
}
