import BaseApi from '@splunk/dashboard-visualizations/common/BaseVisualizationApi';

/**
 * Text Api
 */
class TextApi extends BaseApi {
    constructor(viz) {
        super();
        this.viz = viz;
    }

    /**
     * focus on the input text area
     */
    focus() {
        if (this.viz.props.mode === 'edit' && this.viz.inputTextArea) {
            this.viz.inputTextArea.focus();
            const cursor = this.viz.inputTextArea.value.length;
            this.viz.inputTextArea.setSelectionRange(cursor, cursor);
        }
    }
}

export default TextApi;
