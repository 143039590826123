import { get } from 'lodash';
import { isNumerial, formatNumber } from '@splunk/visualizations-shared/numberUtils';
import { computeTextSize } from '@splunk/visualizations-shared/domUtils';
import { formatColor } from '@splunk/visualizations-shared/propUtils';
import { DELTA_GREEN, DELTA_RED } from '@splunk/visualizations-shared/colorConstants';

export const DEFAULT_FONT = 12;
export const DEFAULT_FONT_FAMILY =
    '"Splunk Platform Sans", "Proxima Nova", "Helvetica Neue", Helvetica, Arial, sans-serif';

export const SVR_PADDING = { top: 12, bottom: 12, left: 16, right: 16 };

/**
 * @method shouldShowTrend
 * @param {String} trendDisplay
 * @returns {Bool}
 */
export const shouldShowTrend = trendDisplay => trendDisplay === 'absolute' || trendDisplay === 'percent';

/**
 * @method isLoadingOrNoColumns
 * @param {Object} dataSources
 * @param {Boolean} loading
 * @returns {Boolean} return true if loading or without columns in dataSources
 */
export const isLoadingOrNoColumns = (dataSources, loading) => {
    const data = get(dataSources, 'primary.data.columns[0]', undefined);
    const noColumns = data === undefined;
    return loading || noColumns;
};

/**
 * Retrieve the valid formatted color
 * @method getFormattedColor
 * @param {String} color
 * @param {String} defaultColor
 * @returns {String} the formatted color
 */
export const getFormattedColor = (color, defaultColor) => formatColor(color) || formatColor(defaultColor);

/**
 * Determines if there is a background color
 * @method hasBackground
 * @param {String} backgroundColor
 * @returns true for non-transparent background color
 */
export const hasBackground = backgroundColor => !!backgroundColor && backgroundColor !== 'transparent';

/**
 * @method isIdenticalColor
 * @param {String} colorA
 * @param {String} colorB
 * @returns {Boolean}
 */
export const isIdenticalColor = (colorA, colorB) =>
    !!colorA && !!colorB && formatColor(colorA.toLowerCase()) === formatColor(colorB.toLowerCase());

/*
 * @method getDefaultColorWithBg
 * @param {Object} props
 * @param {String} props.backgroundColor
 * @param {String} props.defaultFontColor
 * @param {String} props.defaultBlockFontColor
 * @returns {String} defaultColor
 */
export const getDefaultColorWithBg = ({ backgroundColor, defaultFontColor, defaultBlockFontColor }) =>
    isIdenticalColor(backgroundColor, defaultBlockFontColor) ? defaultFontColor : defaultBlockFontColor;

/*
 * @method getMajorColor
 * return formatted majorColor.
 * @param {Object} props
 * @param {String} props.majorColor
 * @param {String} props.backgroundColor
 * @param {String} props.defaultFontColor
 * @param {String} props.defaultBlockFontColor
 * @returns {String} majorColor
 */
export const getMajorColor = ({ majorColor, backgroundColor, defaultFontColor, defaultBlockFontColor }) => {
    // If backgroundColor is set, but no majorColor,
    // the default majorColor will turn to be defaultBlockFontColor.
    // eg: major color will turn to be white in enterprise mode.
    // however, if the bgColor is the same as defaultBlockFontColor, use defaultFontColor.
    if (hasBackground(backgroundColor) && !majorColor) {
        return getDefaultColorWithBg({
            backgroundColor,
            defaultFontColor,
            defaultBlockFontColor,
        });
    }

    return getFormattedColor(majorColor, defaultFontColor);
};

/**
 * Get the color for the trend components
 * @method getTrendColor
 * @param {Object} props
 * @param {String} props.trendColor                   The trendColor value determined from encoding
 * @param {Number} props.trendValue
 * @param {Number} props.majorValue
 * @param {String} props.customizedBgColor
 * @param {String} props.trendDisplay  absolute || percent || off
 * @param {Object} defaultColors
 * @returns {String} trendColor
 */
export const getTrendColor = ({
    trendColor,
    trendValue,
    majorValue,
    customizedBgColor,
    trendDisplay,
    defaultColors,
}) => {
    if (trendColor) {
        return trendColor;
    }

    if (Number.isNaN(Number(trendValue))) {
        return undefined;
    }

    const { defaultFontColor, defaultBlockFontColor, defaultBackgroundColor } = defaultColors;

    // if user defined a backgroundColor, the default trend color will be defaultBlockFontColor
    if (
        hasBackground(customizedBgColor) &&
        !isIdenticalColor(customizedBgColor, defaultBackgroundColor) &&
        !isIdenticalColor(customizedBgColor, defaultBlockFontColor)
    ) {
        return defaultBlockFontColor;
    }

    // if trendDisplay is percent, and trendValue === value
    // the trend percent = trendValue / (value - trendValue) will return Infinity
    const isInvalidTrend = trendDisplay === 'percent' && trendValue === parseFloat(majorValue);

    if (trendValue === 0 || isInvalidTrend) {
        return defaultFontColor;
    }

    return trendValue > 0 ? DELTA_GREEN : DELTA_RED;
};

/**
 * Computes visualization properties for display component
 * @method computeColors
 * @param {Object} props    all viz props
 * @param {Object} defaultColors    defaultColors from theme
 * @returns {Object} result
 * @returns {Boolean} backgroundEnabled
 * @returns {String} backgroundColor
 * @returns {String} majorColor
 * @returns {String} trendColor
 * @returns {String} underLabelColor
 */
export const computeColors = (props, defaultColors) => {
    const backgroundColor = getFormattedColor(props.backgroundColor, defaultColors.defaultBackgroundColor);
    const backgroundEnabled = hasBackground(backgroundColor);
    const majorColor = getMajorColor({
        majorColor: props.majorColor,
        backgroundColor,
        defaultFontColor: defaultColors.defaultFontColor,
        defaultBlockFontColor: defaultColors.defaultBlockFontColor,
    });
    const trendColor = getTrendColor({
        trendColor: props.trendColor,
        trendValue: props.trendValue,
        majorValue: props.majorValue,
        customizedBgColor: props.backgroundColor,
        trendDisplay: props.trendDisplay,
        defaultColors,
    });
    // todo: need change it to not depend on majorColor
    const underLabelColor = backgroundEnabled ? majorColor : defaultColors.defaultFontColor;

    return {
        backgroundColor,
        backgroundEnabled,
        majorColor,
        trendColor,
        underLabelColor,
    };
};

/**
 * Format and return the value (major/trend)
 * @method computeText
 * @param {Object} props
 * @param {Number} props.value                    // The major/trend value
 * @param {Number} props.numberPrecision
 * @param {Bool} props.shouldUseThousandSeparators
 * @param {Bool} props.shouldAbbreviateTrendValue
 * @returns {Number} major or trend text
 */
export const computeText = ({
    value,
    numberPrecision,
    shouldUseThousandSeparators,
    shouldAbbreviateTrendValue,
}) => {
    let text = isNumerial(value)
        ? formatNumber(value, numberPrecision, {
              useThousandSeparators: shouldUseThousandSeparators, // todo: need update formatNumber
              useTrendUnits: shouldAbbreviateTrendValue,
          })
        : value;
    // When text is undefined, return empty string
    text = text || '';
    return text;
};

/*
 * @method getMajorText
 * @param {Object} props
 * @param {Number} props.majorValue
 * @param {Number} props.numberPrecision
 * @param {Bool} props.shouldUseThousandSeparators
 * @returns {String} majorText
 */
export const getMajorText = ({ majorValue, numberPrecision, shouldUseThousandSeparators }) =>
    computeText({
        value: majorValue === null ? 'N/A' : majorValue, // if majorValue is null, use `N/A`.
        numberPrecision,
        shouldUseThousandSeparators,
        shouldAbbreviateTrendValue: false,
    });

/*
 * @method getTrendText
 * @param {Object} props
 * @param {Number} props.majorValue
 * @param {Number} props.trendValue
 * @param {String} props.trendDisplay
 * @param {Number} props.numberPrecision
 * @param {Bool} props.shouldUseThousandSeparators
 * @param {Bool} props.shouldAbbreviateTrendValue
 * @returns {String} trendText
 */
export const getTrendText = ({
    majorValue,
    trendValue,
    trendDisplay,
    numberPrecision,
    shouldUseThousandSeparators,
    shouldAbbreviateTrendValue,
}) => {
    const compute = value =>
        computeText({
            value,
            numberPrecision,
            shouldUseThousandSeparators,
            shouldAbbreviateTrendValue,
        });

    if (trendValue === '' || !isNumerial(trendValue)) {
        return '';
    }
    const showPercent = trendDisplay === 'percent';
    if (!showPercent) {
        return compute(trendValue);
    }

    const diff = Math.abs(trendValue - majorValue);
    let val = Math.round((trendValue / diff) * 100);
    if (trendValue === 0 && diff === 0) {
        val = 0;
    }

    if (!Number.isFinite(val)) {
        return 'N/A';
    }
    const text = compute(val);

    return `${text}%`;
};

/**
 * Helper to determine the font size for an element
 * @method computeFontSize
 * @param {String} text                     The string to find a size for
 * @param {Object} dimensions
 * @param {Number} dimensions.width         The width of the container
 * @param {Number} dimensions.height        The height of the container
 * @param {String} [fontFamily='"Splunk Platform Sans" ...'] The font being used
 * @param {String} [fontWeight='normal']    Boldness of font
 * @returns {Number} The size to use for fontSize styles
 */
export const computeFontSize = (
    text,
    { width, height },
    fontFamily = DEFAULT_FONT_FAMILY,
    fontWeight = 'normal'
) => {
    const widthInDefaultFont = computeTextSize(text, `${fontWeight} ${DEFAULT_FONT}px ${fontFamily}`);
    const fontSize = Math.min(
        (width / widthInDefaultFont) * DEFAULT_FONT,
        (height / DEFAULT_FONT) * DEFAULT_FONT
    );
    return parseInt(fontSize, 10);
};

/*
 * @method getMajorFontSize
 * @param {Object} props
 * @param {Number} props.width
 * @param {Number} props.height
 * @param {String} props.unit
 * @param {String} props.majorText
 * @param {Number} props.spaceRatio
 * @returns {Number} majorFontSize
 */
export const getMajorFontSize = ({ width, height, unit, majorText, spaceRatio = 1 }) => {
    const majorTextCalc = unit ? `${unit}${majorText}` : majorText;
    return computeFontSize(majorTextCalc, {
        width: width * spaceRatio,
        height,
    });
};

/*
 * @method getTrendFontSize
 * @param {Object} props
 * @param {Number} props.width
 * @param {Number} props.height
 * @param {String} props.trendText
 * @param {Number} props.spaceRatio
 * @returns {Number} trendFontSize
 */
export const getTrendFontSize = ({ width, height, trendText, spaceRatio = 1 }) => {
    let trendFontSize = 12;
    if (spaceRatio > 0) {
        trendFontSize = computeFontSize(trendText, {
            width: width * spaceRatio,
            height,
        });
        trendFontSize = parseInt(trendFontSize, 10) * spaceRatio;
    }
    return trendFontSize;
};

/*
 * The height of underLabel should no more than 12px
 * @param {Number} height
 * @return {Number} height || DEFAULT_FONT
 */
export const getUnderLabelHeight = height => (height > DEFAULT_FONT ? DEFAULT_FONT : height);

/*
 * @param {Object} config
 * @param {String} underLabel
 * @param {Number} width     width of underLabel
 * @param {Number} height    height of underLabel
 * @param {Number} fond size of underLabel
 */
export const getUnderLabel = ({ underLabel, width, height }) =>
    // todo: may need truncate underLabel later
    computeFontSize(underLabel, {
        width,
        height,
    });

/**
 * Compute value and font size for major and trend
 * @method getMajorAndTrend
 * @param {Object} props
 * @param {Number} props.width                    // Width of the text container
 * @param {Number} props.height                   // Height of the text container
 * @param {Number} props.majorValue               // Major value
 * @param {Number} props.trendValue               // Trend value
 * @param {Number} props.majorFontSize
 * @param {Number} props.trendFontSize
 * @param {String} props.trendDisplay
 * @param {String} props.unit                     // Unit for the value
 * @param {Number} props.numberPrecision
 * @param {Bool} props.shouldUseThousandSeparators
 * @param {Bool} props.shouldAbbreviateTrendValue
 * @returns {Number, Number, Number, Number} Major text, trend text, major text size, trend text size
 */
export const getMajorAndTrend = ({
    width,
    height,
    majorValue,
    trendValue,
    majorFontSize,
    trendFontSize,
    trendDisplay,
    unit,
    numberPrecision,
    shouldUseThousandSeparators,
    shouldAbbreviateTrendValue,
}) => {
    const majorTextSpaceRatio = isNumerial(trendValue) && shouldShowTrend(trendDisplay) ? 0.6 : 1;
    const textContentWidth = width * 0.9;
    const textContentHeight = height * 0.9;
    const majorText = getMajorText({ majorValue, numberPrecision, shouldUseThousandSeparators });
    const majorTextFontSize =
        majorFontSize ||
        getMajorFontSize({
            width: textContentWidth,
            height: textContentHeight,
            unit,
            majorText,
            spaceRatio: majorTextSpaceRatio,
        });
    const trendText = getTrendText({
        majorValue,
        trendValue,
        trendDisplay,
        numberPrecision,
        shouldUseThousandSeparators,
        shouldAbbreviateTrendValue,
    });

    const trendTextFontSize =
        trendFontSize ||
        getTrendFontSize({
            width: textContentWidth,
            height: textContentHeight,
            trendText,
            spaceRatio: 1 - majorTextSpaceRatio,
        });

    return {
        majorText,
        majorTextFontSize,
        trendText,
        trendTextFontSize,
    };
};

/*
 * @method updateVizInSmallSpace
 * @param {Object} props
 * @param {Number} props.width                    // Width of the text container
 * @param {Number} props.height                   // Height of the text container
 * @param {Object} props.toRemoveList
 * @param {Number} props.majorValue               // Major value
 * @param {Number} props.trendValue               // Trend value
 * @param {Number} props.majorFontSize
 * @param {Number} props.trendFontSize
 * @param {Number} props.majorTextFontSize
 * @param {String} props.unit                     // Unit for the value
 * @param {Number} props.numberPrecision
 * @param {Bool} props.shouldUseThousandSeparators
 * @param {Bool} props.shouldAbbreviateTrendValue
 * @returns {Object} results
 * @returns {Object} results.newMajorAndTrend
 * @returns {Object} results.updatedProps
 */
export const updateVizInSmallSpace = ({
    width,
    height,
    toRemoveList,
    majorValue,
    trendValue,
    majorFontSize,
    trendFontSize,
    majorTextFontSize,
    unit,
    numberPrecision,
    shouldUseThousandSeparators,
    shouldAbbreviateTrendValue,
}) => {
    let removeIndex = 0;
    let newProps = {}; // options need to be updated
    let newMajorAndTrend = {};
    let newTextSize = majorTextFontSize;

    // remove info on viz to make textFontSize >= 12px;
    while (newTextSize < 12 && removeIndex < toRemoveList.length) {
        const toRemove = toRemoveList[removeIndex];
        newProps = {
            ...newProps,
            ...{
                [toRemove.key]: toRemove.value,
            },
        };

        newMajorAndTrend = getMajorAndTrend({
            majorValue,
            trendValue,
            majorFontSize,
            trendFontSize,
            width,
            height,
            unit,
            numberPrecision,
            shouldUseThousandSeparators,
            shouldAbbreviateTrendValue,
            ...newProps,
        });

        newTextSize = newMajorAndTrend.majorTextFontSize;
        removeIndex += 1;
    }

    // truncate text if textFontSize is still less than 12
    if (newTextSize < 12) {
        const truncateValue = `${majorValue.toString().slice(0, 3)}...`;
        newMajorAndTrend = getMajorAndTrend({
            majorValue: truncateValue,
            trendValue,
            majorFontSize,
            trendFontSize,
            width,
            height,
            unit,
            numberPrecision,
            shouldUseThousandSeparators,
            shouldAbbreviateTrendValue,
            ...newProps,
        });
    }

    return { newMajorAndTrend, updatedProps: newProps };
};

/*
 * @method computeSingleValueContent
 * @param {Object} props
 * @param {Number} props.contentWidth
 * @param {Number} props.contentHeight
 * @param {Number} props.containerWidth
 * @param {Number} props.containerHeight
 * @param {Number} props.majorValue               // Major value
 * @param {Number} props.trendValue               // Trend value
 * @param {Number} props.majorFontSize
 * @param {Number} props.trendFontSize
 * @param {String} props.trendDisplay
 * @param {String} props.unit                     // Unit for the value
 * @param {Number} props.numberPrecision
 * @param {Bool} props.shouldUseThousandSeparators
 * @param {Bool} props.shouldAbbreviateTrendValue
 * @param {Object} props.toRemoveList
 * @returns {Object} results
 * @returns {String} results.majorText
 * @returns {String} results.trendText
 * @returns {Number} results.majorTextFontSize
 * @returns {Number} results.trendTextFontSize
 * @returns {String} results.majorValueTitle
 * @returns {Object} results.smallVizProps
 */
export const computeSingleValueContent = ({
    contentWidth,
    contentHeight,
    containerWidth,
    containerHeight,
    majorValue,
    trendValue,
    majorFontSize,
    trendFontSize,
    trendDisplay,
    unit,
    numberPrecision,
    shouldUseThousandSeparators,
    shouldAbbreviateTrendValue,
    toRemoveList,
}) => {
    let { majorText, trendText, majorTextFontSize, trendTextFontSize } = getMajorAndTrend({
        majorValue,
        trendValue,
        majorFontSize,
        trendFontSize,
        trendDisplay,
        width: contentWidth,
        height: contentHeight,
        unit,
        numberPrecision,
        shouldUseThousandSeparators,
        shouldAbbreviateTrendValue,
    });
    const majorValueTitle = majorText;
    let smallVizProps = {};

    // update viz in small space
    // if textFontSize is less than 12, remove extra info like sparkline, trendvalue, unit
    // or truncate major value to make the major font size >= 12
    if (majorTextFontSize < 12) {
        const { newMajorAndTrend, updatedProps } = updateVizInSmallSpace({
            width: containerWidth,
            height: containerHeight,
            toRemoveList,
            majorValue,
            trendValue,
            majorFontSize,
            trendFontSize,
            majorTextFontSize,
            unit,
            numberPrecision,
            shouldUseThousandSeparators,
            shouldAbbreviateTrendValue,
        });

        ({ majorText, trendText, majorTextFontSize, trendTextFontSize } = newMajorAndTrend);
        smallVizProps = updatedProps;
    }
    return {
        majorText,
        trendText,
        majorTextFontSize,
        trendTextFontSize,
        majorValueTitle,
        smallVizProps,
    };
};

export const minimallyTruncateStringToWidth = ({
    text,
    fontSize = DEFAULT_FONT,
    fontFamily = DEFAULT_FONT_FAMILY,
    fontWeight = 'normal',
    containerWidth,
}) => {
    let textWidth = computeTextSize(text, `${fontWeight} ${fontSize}px ${fontFamily}`);
    let modifiedText = text;

    const ellipsesLength = computeTextSize('...', `${fontWeight} ${fontSize}px ${fontFamily}`);
    //  `containerWidth - ellipsesLength > 0` condition ensures space even exists for text.
    if (containerWidth - ellipsesLength > 0 && textWidth > containerWidth) {
        // Truncates the content such that the truncated value + ellipses fit within the allocated space for the ellipses.
        //  `textWidth > containerWidth - ellipsesLength` condition checks if text + ellipses exceeds container width.
        //  `text.length > 1` condition included to keep at least the first number.
        while (textWidth + ellipsesLength > containerWidth && modifiedText.length > 1) {
            modifiedText = modifiedText.substring(0, modifiedText.length - 1);
            textWidth = computeTextSize(modifiedText, `normal ${fontSize}px ${fontFamily}`);
        }

        modifiedText = `${modifiedText}...`;
    }

    return modifiedText;
};

/*
 * @method computeSingleValueRadialContent
 * @param {Object} props
 * @param {Number} props.contentWidth
 * @param {Number} props.contentHeight
 * @param {Number} props.containerWidth
 * @param {Number} props.containerHeight
 * @param {Number} props.majorValue               // Major value
 * @param {Number} props.trendValue               // Trend value
 * @param {Number} props.majorFontSize
 * @param {Number} props.trendFontSize
 * @param {String} props.trendDisplay
 * @param {String} props.unit                     // Unit for the value
 * @param {Number} props.numberPrecision
 * @param {Bool} props.shouldUseThousandSeparators
 * @param {Bool} props.shouldAbbreviateTrendValue
 * @param {Object} props.toRemoveList
 * @param {Bool} props.smallViz
 * @returns {Object} results
 * @returns {String} results.majorText
 * @returns {String} results.trendText
 * @returns {Number} results.majorTextFontSize
 * @returns {Number} results.trendTextFontSize
 * @returns {String} results.majorValueTitle
 * @returns {Object} results.smallVizProps
 */
export const computeSingleValueRadialContent = ({
    contentWidth,
    contentHeight,
    containerWidth,
    containerHeight,
    majorValue,
    trendValue,
    majorFontSize,
    trendFontSize,
    trendDisplay,
    unit,
    numberPrecision,
    shouldUseThousandSeparators,
    shouldAbbreviateTrendValue,
    smallViz,
}) => {
    let { majorText, trendText, majorTextFontSize, trendTextFontSize } = getMajorAndTrend({
        majorValue,
        trendValue,
        majorFontSize,
        trendFontSize,
        trendDisplay,
        width: contentWidth,
        height: contentHeight,
        unit,
        numberPrecision,
        shouldUseThousandSeparators,
        shouldAbbreviateTrendValue,
    });

    const majorValueTitle = majorText;
    let smallVizProps = {};

    // custom logic for viz in small space
    // if not showing the radial graph (if `smallViz` is true), then compute truncated major value
    //  and font sizes
    if (smallViz) {
        const truncateValue = minimallyTruncateStringToWidth({
            text: majorValue,
            fontWeight: 'bold',
            fontSize: 12,
            containerWidth,
        });

        ({ majorText, trendText, majorTextFontSize, trendTextFontSize } = getMajorAndTrend({
            majorValue: truncateValue,
            trendValue,
            majorFontSize,
            trendFontSize,
            width: containerWidth,
            height: containerHeight,
            unit,
            trendDisplay,
            numberPrecision,
            shouldUseThousandSeparators,
            shouldAbbreviateTrendValue,
        }));

        smallVizProps = {
            trendDisplay,
            unit,
        };
    }

    return {
        majorText,
        trendText,
        majorTextFontSize,
        trendTextFontSize,
        majorValueTitle,
        smallVizProps,
    };
};

/**
 * Get the colors for a graph
 *
 * @method getGraphColors
 * @param {Object} options
 * @param {Bool} options.backgroundEnabled          // Flag to turn on color mode
 * @param {String} options.backgroundColor          // The current background color, overrides trend background color
 * @param {Object} options.defaultColors            // Default colors by severity
 * @param {Function} options.customColorFormatter   // must return { background, stroke } given a color
 * @returns {Object} { background, stroke } colors
 * @private
 */
export const getGraphColors = ({
    backgroundEnabled,
    backgroundColor,
    defaultColors,
    customColorFormatter,
}) => {
    // Only do custom colors in block mode
    if (backgroundEnabled) {
        // Check if the user defined colors to use
        if (backgroundColor && typeof customColorFormatter === 'function') {
            return customColorFormatter(backgroundColor);
        }
    }

    return defaultColors.none;
};

/**
 * Adjust rgb value if it equals to 0 or 255
 * @method adjustRGB
 * @param {Number} rgb      [0 - 255]
 * @param {Number} delta
 * @returns {Number} adjusted rgb
 * @private
 */
export const adjustRGB = (rgb, delta) => {
    if (rgb === 255) {
        return rgb - delta;
    }

    if (rgb === 0) {
        return rgb + delta;
    }

    return rgb;
};

/**
 * Modifies colors by multiplying by a given ratio
 * @method changeColor
 * @param {String} color    // Color to modify
 * @param {Number} ratio    // Float to modify color
 * @returns {String} new color
 * @private
 */
const changeColor = (color, ratio) => {
    const hex = color.replace(/(0x|#)/, '');
    const r = parseInt(hex.substr(0, 2), 16);
    const g = parseInt(hex.substr(2, 2), 16);
    const b = parseInt(hex.substr(4, 2), 16);

    // Multiply by given ratio, make sure is between 0 and 255;
    let newR = Math.max(Math.min(Math.floor(r * ratio), 255), 0);
    let newG = Math.max(Math.min(Math.floor(g * ratio), 255), 0);
    let newB = Math.max(Math.min(Math.floor(b * ratio), 255), 0);

    // If the color is not changed
    // case 1: the color is (0, 0 0)
    //         lighten color (80, 80, 80)
    //         darken color (40, 40, 40)
    // case 2: the color is (255, 255, 255)
    //         lighten color (235, 235, 235)
    //         darken color (175, 175, 175)
    // case 3: the color is e.g. (0, 255, 0)
    //         new color (20, 235, 20)
    if (newR === r && newG === g && newB === b) {
        if (newR === 0 && newG === 0 && newB === 0) {
            if (ratio > 1) {
                newR = adjustRGB(newR, 80);
                newG = adjustRGB(newG, 80);
                newB = adjustRGB(newB, 80);
            } else {
                newR = adjustRGB(newR, 40);
                newG = adjustRGB(newG, 40);
                newB = adjustRGB(newB, 40);
            }
        } else if (newR === 255 && newG === 255 && newB === 255) {
            if (ratio > 1) {
                newR = adjustRGB(newR, 20);
                newG = adjustRGB(newG, 20);
                newB = adjustRGB(newB, 20);
            } else {
                newR = adjustRGB(newR, 80);
                newG = adjustRGB(newG, 80);
                newB = adjustRGB(newB, 80);
            }
        } else {
            newR = adjustRGB(newR, 20);
            newG = adjustRGB(newG, 20);
            newB = adjustRGB(newB, 20);
        }
    }

    return (
        `#${newR.toString(16).padStart(2, 0)}` +
        `${newG.toString(16).padStart(2, 0)}` +
        `${newB.toString(16).padStart(2, 0)}`
    );
};

/**
 * Lighten a given color
 * @method lighten
 * @param {String} color
 * @returns {String}
 * @private
 */
export const lighten = color => changeColor(color, 1.3);

/**
 * Darkens a given color
 * @method darken
 * @param {String} color
 * @returns {String}
 * @private
 */
export const darken = color => changeColor(color, 0.5);

/** consistent token structure: handle callback to add value and name in event payload
 * @method handleValueClickCallback
 * @param {Object} ev
 * @param {String | Number} majorValue
 * @param {String} majorValueField
 * @param {Number} trendValue
 * @param {Function} onValueClick
 */
export const handleValueClickCallback = (
    ev,
    majorValue,
    majorValueField,
    trendValue,
    onValueClick,
    trellisEnabled = false,
    trellisSplityBy = '',
    trellisKey = ''
) => {
    let payload = {
        trendValue,
        value: majorValue,
        name: majorValueField,
    };
    if (trellisEnabled) {
        payload = { ...payload, 'trellis.name': trellisSplityBy, 'trellis.value': trellisKey };
    }
    return onValueClick({ ...ev, payload });
};
