import React from 'react';
import { _ } from '@splunk/ui-utils/i18n';
import { mapKeys } from 'lodash';
import SplunkwebConnector from '@splunk/react-time-range/SplunkwebConnector';
import { withInputWrapper } from '../utils/enhancer';
import { BaseTimeRangePicker } from '../components/BaseTimeRange';
import TRPEditor from '../components/TRPEditor';

/**
 * Enterprise Time Range Picker
 * This component depends on Splunkweb which is present in any enterprise installation.
 * It requires specific values to be added to the window global, and it makes API calls to a splunkd backend to get preset and timezone information
 * @param {Object} props See BaseTimeRange component
 */
const EnterpriseTimeRangePicker = (props) => {
    return (
        // SplunkwebConnector defines realTimeDisabled, presets, onRequestParseEarliest, onRequestParseLatest, parseEarliest, and parseLatest props for its children
        <SplunkwebConnector validateRTSearchCapability>
            <BaseTimeRangePicker {...props} />
        </SplunkwebConnector>
    );
};

const editor = [
    {
        enableCollapsible: false,
        enableSeparator: false,
        layout: [
            [
                {
                    label: _('Token name'),
                    option: 'token',
                    editor: 'editor.text',
                },
            ],
            [
                {
                    label: _('Default value'),
                    option: 'defaultValue',
                    editor: TRPEditor,
                    editorProps: {
                        enableRealTime: true,
                        Connector: SplunkwebConnector,
                    },
                },
            ],
        ],
    },
];

EnterpriseTimeRangePicker.valueToTokens = BaseTimeRangePicker.valueToTokens;
EnterpriseTimeRangePicker.tokensToValue = BaseTimeRangePicker.tokensToValue;

EnterpriseTimeRangePicker.config = {
    editorConfig: editor,
    optionsSchema: BaseTimeRangePicker.config.schema,
    ...mapKeys(BaseTimeRangePicker.config.meta, (value, key) =>
        key === 'defaultConfig' ? 'baseShape' : key
    ),
};

export default withInputWrapper(EnterpriseTimeRangePicker);
