import React, { useCallback, useMemo } from 'react';
import { zipWith } from 'lodash';
import { _ } from '@splunk/ui-utils/i18n';
import ControlGroup from '@splunk/react-ui/ControlGroup';
import RadioBar from '@splunk/react-ui/RadioBar';
import { ComboBoxEditor, type ComboBoxOptions } from '@splunk/dashboard-ui';
import MultiselectDefaultValueEditor from './MultiselectDefaultValueEditor';

export interface SelectInputOptions {
    items: ComboBoxOptions | string;
    defaultValue?: string;
    selectFirstSearchResult?: boolean;
}

interface SelectInputOptionsToUpdate {
    defaultValue?: string;
    selectFirstSearchResult?: boolean;
}

interface SelectInputContext {
    statics?: [string[], string[]];
}

type DefaultValueType = 'defined' | 'first' | 'none';

const DEFAULT_VALUE_TYPE_DESCRIPTION = {
    defined: _('Assigns the user defined values as the default'),
    first: _(
        'Assigns the first value returned from the data source as the default'
    ),
    none: _('No value will be assigned as the default'),
};

export interface DefaultSelectedValueEditorProps {
    options: SelectInputOptions;
    context: SelectInputContext;
    onChange: (
        event: React.SyntheticEvent,
        updatedConfig: { options: SelectInputOptionsToUpdate }
    ) => void;
    value?: string;
    isMultiselect?: boolean;
}

const DefaultSelectedValueEditor = ({
    options,
    context,
    onChange,
    value: defaultValue,
    isMultiselect,
}: DefaultSelectedValueEditorProps): JSX.Element => {
    let valueType: DefaultValueType = 'none';
    if (options.selectFirstSearchResult) {
        valueType = 'first';
    } else if (defaultValue !== undefined) {
        valueType = 'defined';
    }

    const staticItems = useMemo(() => {
        if (Array.isArray(options.items)) {
            return options.items;
        }
        const { statics = [[], []] } = context;
        return zipWith(...statics, (_l, v) => ({
            value: v,
        }));
    }, [options, context]);

    const handleValueTypeChange = useCallback(
        (event, { value: type }) => {
            const newDefaultValue = type === 'defined' ? '' : undefined;
            const newSelectFirstSearchResult =
                type === 'first' ? true : undefined;

            onChange(event, {
                options: {
                    defaultValue: newDefaultValue,
                    selectFirstSearchResult: newSelectFirstSearchResult,
                },
            });
        },
        [onChange]
    );

    const handleDefaultValueChange = useCallback(
        (event, updatedValue) => {
            onChange(event, {
                options: {
                    defaultValue: updatedValue,
                },
            });
        },
        [onChange]
    );

    const selectedValueInput = useMemo(() => {
        if (valueType !== 'defined') {
            return null;
        }

        const DefaultValueEditor = isMultiselect
            ? MultiselectDefaultValueEditor
            : ComboBoxEditor;
        return (
            <ControlGroup
                label={_('Choose a Default Value')}
                labelPosition="top"
                hideLabel
                controlsLayout="none"
            >
                <DefaultValueEditor
                    value={defaultValue}
                    onChange={handleDefaultValueChange}
                    options={staticItems}
                    placeholder={_('Choose or type a default value...')}
                />
            </ControlGroup>
        );
    }, [
        isMultiselect,
        valueType,
        defaultValue,
        handleDefaultValueChange,
        staticItems,
    ]);

    return (
        <>
            <ControlGroup
                label={_('Default selected value')}
                labelPosition="top"
                hideLabel
                help={DEFAULT_VALUE_TYPE_DESCRIPTION[valueType]}
            >
                <RadioBar
                    data-test="default-selected-value-type"
                    onChange={handleValueTypeChange}
                    value={valueType}
                >
                    <RadioBar.Option
                        label={_('Choose default')}
                        value="defined"
                    />
                    <RadioBar.Option label={_('First value')} value="first" />
                    <RadioBar.Option label={_('None')} value="none" />
                </RadioBar>
            </ControlGroup>
            {selectedValueInput}
        </>
    );
};

export default DefaultSelectedValueEditor;
