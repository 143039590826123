import SingleValuePresetIcons from './SingleValuePresetIcons';
import SingleValueIconPresetIcons from './SingleValueIconPresetIcons';
import SingleValueRadialPresetIcons from './SingleValueRadialPresetIcons';
import EllipsePresetIcons from './EllipsePresetIcons';
import RectanglePresetIcons from './RectanglePresetIcons';
import FillerGaugePresetIcons from './FillerGaugePresetIcons';

export default {
    ...EllipsePresetIcons,
    ...FillerGaugePresetIcons,
    ...SingleValuePresetIcons,
    ...SingleValueIconPresetIcons,
    ...SingleValueRadialPresetIcons,
    ...RectanglePresetIcons,
};
