import { _ } from '@splunk/ui-utils/i18n';

const backgroundColor = {
    label: _('Background Color'),
    layout: [
        [
            {
                label: '', // Per https://cd.splunkdev.com/devplat/vision/merge_requests/592#note_997173
                option: 'backgroundColor',
                editor: 'editor.color',
            },
        ],
    ],
};

export default backgroundColor;
