import * as React from 'react';
import { _ } from '@splunk/ui-utils/i18n';
import SVG from '@splunk/react-icons/SVGEnterprise';

const Events = (props: React.ComponentProps<typeof SVG>): React.ReactElement<SVGElement> => (
    <SVG screenReaderText={_('Events')} viewBox="0 0 24 24" {...props}>
        <path
            d="M5.79289 7.79289C6.18342 7.40237 6.81658 7.40237 7.20711 7.79289L8.6364 9.22218C9.06598 9.65176 9.06597 10.3482 8.6364 10.7778L7.20711 12.2071C6.81658 12.5976 6.18342 12.5976 5.79289 12.2071C5.40237 11.8166 5.40237 11.1834 5.79289 10.7929L6.58579 10L5.79289 9.20711C5.40237 8.81658 5.40237 8.18342 5.79289 7.79289Z"
            fill="currentColor"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1 5.5C1 4.67157 1.67157 4 2.5 4H21.5C22.3284 4 23 4.67157 23 5.5V18.5C23 19.3284 22.3284 20 21.5 20H2.5C1.67157 20 1 19.3284 1 18.5V5.5ZM3 16V18H11V16H3ZM3 14H11V6H3V14ZM13 6V14H21V6H13ZM21 16H13V18H21V16Z"
            fill="currentColor"
        />
    </SVG>
);

export default Events;
