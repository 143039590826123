import { _ } from '@splunk/ui-utils/i18n';

const numberFormatting = {
    label: _('Number Formatting'),
    layout: [
        [
            {
                label: _('Digit Precision'),
                option: 'numberPrecision',
                editor: 'editor.number',
                editorProps: {
                    min: 0,
                },
            },
        ],
        [
            {
                label: _('Unit'),
                option: 'unit',
                editor: 'editor.text',
            },
            {
                label: _('Position'), // todo: we need a new editor combining unit and position
                option: 'unitPosition',
                editor: 'editor.select',
                editorProps: {
                    values: [
                        { label: _('Before'), value: 'before' },
                        { label: _('After'), value: 'after' },
                    ],
                },
            },
        ],
    ],
};

export default numberFormatting;
