import React from 'react';
import { _ } from '@splunk/ui-utils/i18n';
import SVG, {
    type SVGPropsOptionalViewBox,
} from '@splunk/react-icons/SVGEnterprise';

/* eslint-disable max-len */
export default function StatusOk(
    props: SVGPropsOptionalViewBox
): ReturnType<typeof SVG> {
    return (
        <SVG
            viewBox="0 0 8 8"
            fill="none"
            screenReaderText={_('Status ok')}
            {...props}
        >
            <circle
                r="4"
                transform="matrix(-1 0 0 1 4 4)"
                fill="currentColor"
            />
        </SVG>
    );
}
