// WICG Spec: https://wicg.github.io/ua-client-hints
// https://wicg.github.io/ua-client-hints/#navigatorua
interface ChromeNavigator {
    readonly userAgentData?: UALowEntropyJSON;
}

// https://wicg.github.io/ua-client-hints/#dictdef-navigatoruabrandversion
interface NavigatorUABrandVersion {
    readonly brand: string;
    readonly version: string;
}

// https://wicg.github.io/ua-client-hints/#dictdef-ualowentropyjson
interface UALowEntropyJSON {
    readonly brands: NavigatorUABrandVersion[];
    readonly mobile: boolean;
    readonly platform: string;
}

const regex = /Mac/;

export const isMac = () => {
    // userAgentData is experimental and only supported in Chromium-based browsers currently.
    // this is expected to replace all the apis that derive data from the spoofable 'userAgent' string
    // TS doesn't have userAgentData in types because it is experimental
    if ((globalThis.navigator as ChromeNavigator)?.userAgentData?.platform) {
        // could also look for 'iOS' but these are unlikely to need keyboard shortcuts
        return (
            (globalThis.navigator as ChromeNavigator)?.userAgentData
                ?.platform === 'macOS'
        );
    }

    // UserAgent is unreliable, navigator.platform is deprecated, and there are no other OS reporting options that are not also deprecated
    // on M1 mac Chrome macOS 13.3.1: Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/114.0.0.0 Safari/537.36
    // See https://www.whatismybrowser.com/guides/the-latest-user-agent/ for additional examples
    return regex.test(globalThis.navigator?.userAgent || '');
};
