import * as React from 'react';
import * as T from 'prop-types';

import styled from 'styled-components';
import pick from '@splunk/themes/pick';
import variables from '@splunk/themes/variables';
import { AXIS_ORIENTATION_TYPES, truncateAxisLabelText } from './utils/punchcardUtils';

const AxisLabelText = styled.text<{
    muted: boolean;
}>`
    cursor: pointer;
    dominant-baseline: middle;
    fill: ${pick({
        /* was previously named textColor in themeReg */
        enterprise: variables.textColor,
        prisma: variables.contentColorDefault,
    })};
    font-size: 12px;
    text-anchor: ${props => (props.orientation === AXIS_ORIENTATION_TYPES.HORIZONTAL ? 'middle' : 'start')};
    opacity: ${props => (props.muted ? 0.15 : 1)};
    transition: all 0.15s;
`;

interface PunchcardAxisProps {
    labels: (string | number)[];
    handleMouseEnter: (...args: any) => void;
    handleMouseLeave: (...args: any) => void;
    orientation: AXIS_ORIENTATION_TYPES;
    scale: (...args: any) => void;
    selected: string | number;
    startPositionX: number;
    startPositionY: number;
}

const PunchcardAxis = (props: PunchcardAxisProps): React.ReactElement => {
    const {
        labels,
        handleMouseEnter,
        handleMouseLeave,
        orientation,
        scale,
        selected,
        startPositionX,
        startPositionY,
    } = props;

    return (
        <g>
            {labels.map((label, index) => {
                // Space between labels either in x or y direction depending on orientation
                const positionDelta = index * (scale as any).bandwidth() + (scale as any).bandwidth() / 2;
                const xPosition =
                    orientation === AXIS_ORIENTATION_TYPES.VERTICAL
                        ? startPositionX
                        : startPositionX + positionDelta;
                const yPosition =
                    orientation === AXIS_ORIENTATION_TYPES.VERTICAL
                        ? startPositionY + positionDelta
                        : startPositionY;

                // create padding between x-axis labels
                const xAxisWidthScale = (scale as any).copy().paddingInner(0.1).round(true);

                const truncatedLabel =
                    orientation === AXIS_ORIENTATION_TYPES.VERTICAL
                        ? truncateAxisLabelText({ label: `${label}` })
                        : truncateAxisLabelText({
                              label: `${label}`,
                              widthInPX: xAxisWidthScale.bandwidth(),
                          });

                return (
                    <AxisLabelText
                        key={label}
                        x={xPosition}
                        y={yPosition}
                        orientation={orientation}
                        onMouseEnter={() => handleMouseEnter(label)}
                        onMouseLeave={handleMouseLeave}
                        muted={selected && label !== selected}
                    >
                        <title>{label}</title>
                        {truncatedLabel}
                    </AxisLabelText>
                );
            })}
        </g>
    );
};

const propTypes: Record<keyof PunchcardAxisProps, T.Validator<any>> = {
    labels: T.arrayOf(T.oneOfType([T.string, T.number])).isRequired,
    handleMouseEnter: T.func,
    handleMouseLeave: T.func,
    orientation: T.oneOf(Object.values(AXIS_ORIENTATION_TYPES)),
    scale: T.func.isRequired,
    selected: T.oneOfType([T.string, T.number]),
    startPositionX: T.number,
    startPositionY: T.number,
};

const defaultProps: Record<keyof PunchcardAxisProps, any> = {
    // set as undefined as we are using defaultProps in conjunction with ts
    // where it requires all keys of interface to be present in defaultProps
    // eslint-disable-next-line react/default-props-match-prop-types
    labels: undefined,
    handleMouseEnter: () => {},
    handleMouseLeave: () => {},
    orientation: 'vertical',
    // eslint-disable-next-line react/default-props-match-prop-types
    scale: undefined,
    selected: null,
    startPositionX: 0,
    startPositionY: 0,
};

PunchcardAxis.propTypes = propTypes;
PunchcardAxis.defaultProps = defaultProps;

export default PunchcardAxis;
