import * as React from 'react';
import { _ } from '@splunk/ui-utils/i18n';
import SVG from '@splunk/react-icons/SVGEnterprise';

const Pie = (props: React.ComponentProps<typeof SVG>): React.ReactElement<SVGElement> => (
    <SVG screenReaderText={_('Pie')} fill="currentColor" viewBox="0 0 150 100" {...props}>
        <g>
            <path d="M52.9652 82.7149C42.7139 75.6113 36 63.7631 36 50.3468C36 28.8297 53.2694 11.3461 74.704 11V50.15L52.9652 82.7149Z" />
            <path d="M77.1674 50.3472L112.35 36.9099C113.873 41.1027 114.704 45.6277 114.704 50.3468C114.704 55.071 113.872 59.6007 112.345 63.7974L77.1674 50.3472Z" />
            <path d="M75.2752 89.6987C73.4676 89.6952 71.6576 89.5671 69.858 89.3134C64.2213 88.5187 58.8266 86.5109 54.045 83.4316L75.6035 51.1368L111.872 65.004C109.754 70.2822 106.516 75.041 102.378 78.9505C98.083 83.0084 92.9296 86.0471 87.2999 87.841C83.4018 89.0833 79.3446 89.7066 75.2752 89.6987Z" />
            <path d="M76 49.4058L111.878 35.703C108.996 28.5149 104.055 22.332 97.6686 17.9348C91.2821 13.5375 83.7432 11.1278 76 11.0003V49.4058Z" />
        </g>
    </SVG>
);

export default Pie;
