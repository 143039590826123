import { Rectangle as RectangleIcon } from '@splunk/visualization-icons';
import { getPattern } from '@splunk/visualizations-shared/schemaUtils';
import { _ } from '@splunk/ui-utils/i18n';
import { defaultPalettesConfig } from '@splunk/visualization-color-palettes/editors/PresetPalettes';
import { pick, variables } from '@splunk/themes';
import { DataContract } from '../common/interfaces/DataContract';
import { DefaultContext } from '../common/interfaces/DefaultContext';
import { EditorConfig } from '../common/interfaces/Editor';
import { OptionsSchema } from '../common/interfaces/OptionsSchema';
import { ThemedDefaults } from '../common/interfaces/ThemedDefaults';
import { VizBehavior } from '../common/interfaces/VizBehavior';
import { VizCategory } from '../common/interfaces/VizCategory';
import { VizConfig } from '../common/interfaces/VizConfig';
import { VizSize } from '../common/interfaces/VizSize';
import { PresetEntry, enhanceConfig, getInitialPreset } from '../common/utils/configUtils';
import { getDynamicFillColorEditor } from '../common/editorConfig/DynamicFillColorEditor';
import { getDynamicStrokeColorEditor } from '../common/editorConfig/DynamicStrokeColorEditor';
import StaticFillColorEditor from '../common/editorConfig/StaticFillColorEditor';
import StaticStrokeColorEditor from '../common/editorConfig/StaticStrokeColorEditor';

const dataContract: DataContract = {
    requiredDataSources: [],
    optionalDataSources: [
        {
            name: 'primary',
            description: 'DataSource that powers the visualization',
        },
    ],
    initialRequestParams: {
        primary: { offset: 0, count: 10000 },
    },
};

const size: VizSize = {
    initialWidth: 300,
    initialHeight: 300,
};

const defaultContext: DefaultContext = {
    fillColorConfig: [
        {
            from: 100,
            value: '#088F44',
        },
        {
            from: 80,
            to: 100,
            value: '#2EB82E',
        },
        {
            from: 60,
            to: 80,
            value: '#C3CC33',
        },
        {
            from: 40,
            to: 60,
            value: '#FFD442',
        },
        {
            from: 20,
            to: 40,
            value: '#FFA857',
        },
        {
            from: 0,
            to: 20,
            value: '#FF7149',
        },
        {
            to: 0,
            value: '#FE3A3A',
        },
    ],
    strokeColorConfig: [
        {
            from: 100,
            value: '#088F44',
        },
        {
            from: 80,
            to: 100,
            value: '#2EB82E',
        },
        {
            from: 60,
            to: 80,
            value: '#C3CC33',
        },
        {
            from: 40,
            to: 60,
            value: '#FFD442',
        },
        {
            from: 20,
            to: 40,
            value: '#FFA857',
        },
        {
            from: 0,
            to: 20,
            value: '#FF7149',
        },
        {
            to: 0,
            value: '#FE3A3A',
        },
    ],
    fillColorMatchConfig: [{ match: '', value: '#5C33FF' }],
    strokeColorMatchConfig: [{ match: '', value: '#5C33FF' }],
};

export const optionsSchema: OptionsSchema = {
    fillColor: {
        default: '> themes.defaultFillColor',
        description:
            'Specify the fill color. You may use a dataSource to apply the color. The hex value format should be "#FFFFFF". The default for enterprise light mode is "#C3CBD4". The default for enterprise dark mode is "#31373E". The default for prisma dark mode is "#0B0C0E".',
        type: 'string',
    },
    fillOpacity: {
        description:
            'Specify the opacity of the fill. Choose a number in the range of 0 - 1 (inclusive). You can also express the value as a percentage. For example, "0.80" in source or "80%" in UI.',
        type: 'number',
        default: 1,
    },
    rx: {
        description:
            'Specify the curvature of the horizontal corner (or border) radius with an integer or a percent greater than or equal to zero.',
        type: 'number',
        default: 0,
    },
    ry: {
        description:
            'Specify the curvature of the vertical corner (or border) radius with an integer or a percent greater than or equal to zero.',
        type: 'number',
        default: '> rx',
    },
    strokeColor: {
        default: '> themes.defaultStrokeColor',
        description:
            'Specify the stroke color. You may use a dataSource to apply the color. The hex value format should be "#FFFFFF". The default for enterprise light mode is "#3C444D". The default for enterprise dark mode is "#C3CBD4". The default for prisma dark mode is "#ACACAD".',
        type: 'string',
    },
    strokeDashStyle: {
        description:
            'Specify the size, in pixels, of dashes and spaces used to create a custom stitched outline. The value you specify applies to both the dashes and the spaces between them.',
        type: 'number',
        default: 0,
    },
    strokeJoinStyle: {
        description: 'Specifies the shape to be used at the corners of paths when they are joined.',
        type: 'string',
        default: 'miter',
        pattern: getPattern(['arcs', 'bevel', 'miter', 'miter-clip', 'round']),
    },
    strokeOpacity: {
        description:
            'Specify the opacity of the stroke. Choose a number in the range of 0 - 1 (inclusive). You can also express the value as a percentage. For example, "0.80" in source or "80%" in UI.',
        type: 'number',
        default: 1,
    },
    strokeWidth: {
        description: 'Specify the width of the stroke, in pixels, in the range of 1 - 25 (inclusive).',
        type: 'number',
        default: 1,
    },
};

const themes: ThemedDefaults = {
    defaultFillColor: props =>
        pick({
            enterprise: {
                dark: variables.gray25(props),
                light: variables.gray80(props),
            },
            prisma: variables.backgroundColorSidebar(props),
        })(props),
    defaultStrokeColor: props =>
        pick({
            enterprise: {
                dark: variables.gray80(props),
                light: variables.gray30(props),
            },
            prisma: variables.neutral500(props),
        })(props),
};

const basePresetContext = {
    fillColorEditorConfig: undefined,
    strokeColorEditorConfig: undefined,
};

const basePresetOptions = {
    fillColor: undefined,
    strokeColor: undefined,
};

const presets: PresetEntry[] = [
    {
        // dummy context variables to allow for resetting of unused context states
        label: _('None'),
        name: 'rectangle.none',
        value: {
            context: { ...basePresetContext },
            options: { ...basePresetOptions },
        },
    },
    {
        label: _('Fill'),
        name: 'rectangle.fill',
        value: {
            context: {
                ...basePresetContext,
                fillDataValue: '> primary | seriesByType("number") | lastPoint()',
                fillColorEditorConfig: defaultContext.fillColorConfig,
            },
            options: {
                ...basePresetOptions,
                fillColor: '> fillDataValue | rangeValue(fillColorEditorConfig)',
            },
        },
    },
    {
        label: _('Stroke'),
        name: 'rectangle.stroke',
        value: {
            context: {
                ...basePresetContext,
                strokeDataValue: '> primary | seriesByType("number") | lastPoint()',
                strokeColorEditorConfig: defaultContext.strokeColorConfig,
            },
            options: {
                ...basePresetOptions,
                strokeColor: '> strokeDataValue | rangeValue(strokeColorEditorConfig)',
            },
        },
    },
    {
        label: _('Fill and Stroke'),
        name: 'rectangle.fillAndStroke',
        value: {
            context: {
                ...basePresetContext,
                fillDataValue: '> primary | seriesByType("number") | lastPoint()',
                strokeDataValue: '> primary | seriesByType("number") | lastPoint()',
                fillColorEditorConfig: defaultContext.fillColorConfig,
                strokeColorEditorConfig: defaultContext.strokeColorConfig,
            },
            options: {
                ...basePresetOptions,
                fillColor: '> fillDataValue | rangeValue(fillColorEditorConfig)',
                strokeColor: '> strokeDataValue | rangeValue(strokeColorEditorConfig)',
            },
        },
    },
];

const editorConfig: EditorConfig[] = [
    {
        label: _('Color and style'),
        layout: [
            [
                {
                    label: 'Dynamic elements',
                    key: 'presetSelector',
                    editor: 'editor.presetSelector',
                    editorProps: {
                        presets,
                        value: ({ context, options }) => {
                            const initialPreset = getInitialPreset(context, options, presets);
                            return (initialPreset && initialPreset.label) || null;
                        },
                    },
                },
            ],
            [
                {
                    ...StaticFillColorEditor,
                    editorProps: {
                        themes,
                    },
                },
            ],
            [getDynamicFillColorEditor({ defaultContext, defaultPalettesConfig })],
            [
                {
                    ...StaticStrokeColorEditor,
                    editorProps: {
                        themes,
                    },
                },
            ],
            [getDynamicStrokeColorEditor({ defaultContext, defaultPalettesConfig })],
            [
                {
                    label: _('Fill opacity (%)'),
                    option: 'fillOpacity',
                    editor: 'editor.percent',
                    editorProps: {
                        min: 0,
                    },
                },
                {
                    label: _('Stroke opacity (%)'),
                    option: 'strokeOpacity',
                    editor: 'editor.percent',
                    editorProps: {
                        min: 0,
                    },
                },
            ],
            [
                {
                    label: _('Stroke width'),
                    option: 'strokeWidth',
                    editor: 'editor.number',
                    editorProps: {
                        min: 0,
                    },
                },
                {
                    label: _('Stroke dash style'),
                    option: 'strokeDashStyle',
                    editor: 'editor.number',
                    editorProps: {
                        min: 0,
                    },
                },
            ],
            [
                {
                    label: _('Radius (px)'),
                    option: 'rx',
                    editor: 'editor.number',
                    editorProps: {
                        min: 0,
                    },
                },
                {},
            ],
        ],
    },
];

/**
 * visualization configuration
 */
const config: VizConfig = {
    /**
     * unique viz key
     */
    key: 'splunk.rectangle',
    /**
     * viz name
     */
    name: 'Rectangle',
    category: VizCategory.SHAPES,
    /**
     * viz icon
     */
    icon: RectangleIcon,
    dataContract,
    size,
    defaultContext,
    optionsSchema,
    editorConfig,
    supports: [VizBehavior.DYNAMIC_OPTIONS, VizBehavior.EVENTS],
    themes,
    events: {
        'rectangle.click': {
            description: 'trigger when user clicks rectangle',
            payloadKeys: ['strokeColor', 'fillColor'],
        },
    },
};

export default enhanceConfig(config);
