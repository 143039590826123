import React from 'react';
import styled from 'styled-components';
import type { DataSourceEventPayload } from '@splunk/dashboard-types';
import P from '@splunk/react-ui/Paragraph';
import List from '@splunk/react-ui/List';
import { _ } from '@splunk/ui-utils/i18n';

const GenericPopoverContainer = styled.div`
    padding: 12px;
    max-width: 500px;
    word-break: break-all;
`;

const UnresolvedTokensPopoverContent = ({
    message,
    tokens,
}: {
    message?: string;
    tokens: string[];
}) => (
    <GenericPopoverContainer>
        <P>{message}</P>
        <List>
            {tokens.map((token) => (
                <List.Item key={token}>{token}</List.Item>
            ))}
        </List>
    </GenericPopoverContainer>
);

export interface PopoverContentFactoryProps {
    dataSource?: DataSourceEventPayload;
    message?: string;
}

export const GenericPopover = ({ message }: { message?: string }) =>
    message ? (
        <GenericPopoverContainer>{message}</GenericPopoverContainer>
    ) : null;

const GenericPopoverContentFactory = ({
    dataSource,
    message,
}: PopoverContentFactoryProps) => {
    if (message) {
        return <GenericPopover message={message} />;
    }

    if (!dataSource) {
        return null;
    }

    const statusMessage = dataSource.error
        ? dataSource.error?.message
        : dataSource.meta?.statusMessage ||
          dataSource.meta?.serverLog?.[0]?.message;

    if (
        dataSource.meta &&
        Array.isArray(dataSource.meta.missedTokens) &&
        dataSource.meta.missedTokens.length
    ) {
        return (
            <UnresolvedTokensPopoverContent
                message={statusMessage}
                tokens={dataSource.meta.missedTokens}
            />
        );
    }

    if (
        dataSource.meta?.status === 'done' &&
        dataSource.meta?.totalCount === 0
    ) {
        return <GenericPopover message={_('No search results returned')} />;
    }

    return <GenericPopover message={statusMessage} />;
};

export default GenericPopoverContentFactory;
