import React from 'react';
import Table from '@splunk/react-ui/Table';
import { CellPropTypes, CellDefaultProps, CellDefaultStyle } from './CellPropShape';

const StringCell = ({ value, cellOptions: { textColor }, fieldOptions: { align }, onCellClick }) => {
    const style = { ...CellDefaultStyle, color: textColor };
    return (
        <Table.Cell align={align} style={style} title={value} test-cell-type="string" onClick={onCellClick}>
            {value ?? ''}
        </Table.Cell>
    );
};

StringCell.propTypes = CellPropTypes;
StringCell.defaultProps = CellDefaultProps;
StringCell.canRender = () => true;
export default StringCell;
