import { _ } from '@splunk/ui-utils/i18n';
import { isDynamicOption } from '@splunk/visualizations-shared/configUtils';

const StaticTrendColorEditor = {
    label: _('Trend value'),
    option: 'trendColor',
    editor: 'editor.color',
    showEditor: ({ options }) => !isDynamicOption(options.trendColor),
    isDisabled: ({ options }) => options.trendDisplay === 'off',
};

export default StaticTrendColorEditor;
