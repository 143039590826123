import React from 'react';
import styled from 'styled-components';

import { _ } from '@splunk/ui-utils/i18n';
import { variables, mixins, pick } from '@splunk/themes';
import type { Mode } from '@splunk/dashboard-types';

import { customThemeVariables } from '../customThemeVariables';

export const LINE_HEIGHT = '28px';
const fontSize = '24px';

const Title = styled.div<{ isExpanded: boolean }>`
    ${mixins.reset('block')};
    ${(props) => (props.isExpanded ? 'min-height' : 'height')}: LINE_HEIGHT;
    max-width: 100%;
    margin: 0;
    padding: 1px 12px 0px 9px;
    box-sizing: inherit;
    line-height: ${LINE_HEIGHT};
    font-size: ${fontSize};
    font-weight: 500;
    color: ${pick({
        enterprise: {
            light: variables.contentColorActive,
            dark: variables.gray96,
        },
        prisma: variables.contentColorActive,
    })};
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: ${(props) => (props.isExpanded ? 'normal' : 'nowrap')};
`;

const TitleInput = styled.input`
    ${mixins.reset('block')};
    height: ${LINE_HEIGHT};
    width: 100%;
    margin: 0;
    padding: 3px 16px 0px 8px; // Left & Top Padding is intentionally different than the above component for alignment.
    line-height: ${LINE_HEIGHT};
    box-sizing: inherit;
    font-size: ${fontSize};
    font-weight: 500;
    color: ${pick({
        enterprise: {
            light: variables.contentColorActive,
            dark: variables.gray96,
        },
        prisma: variables.contentColorActive,
    })};
    box-shadow: none;
    outline: none;
    background-color: 1px solid transparent;
    border: transparent;

    &:hover,
    &:focus {
        background-color: ${customThemeVariables.selectedInputBackgroundColor};
        border-style: solid;
        border-width: 1px;
        border-color: ${customThemeVariables.selectedInputBorderColor};
        border-radius: 4px;
    }
`;

interface TitleProps {
    handleBlur: React.FocusEventHandler;
    handleChange: React.FormEventHandler;
    handleKeyDown: React.KeyboardEventHandler;
    isExpanded?: boolean;
    mode: Mode;
    value?: string;
}

const DashboardTitle = ({
    handleBlur,
    handleChange,
    handleKeyDown,
    isExpanded = false,
    mode,
    value = '',
}: TitleProps): JSX.Element => {
    if (mode === 'edit') {
        return (
            <TitleInput
                data-test="dashboard-title-input"
                onBlur={handleBlur}
                onChange={handleChange}
                onKeyDown={handleKeyDown}
                placeholder={_('Untitled Dashboard')}
                value={value}
            />
        );
    }

    return (
        <Title isExpanded={isExpanded} data-test="dashboard-title">
            {value}
        </Title>
    );
};

export default React.memo(DashboardTitle);
