import { get, last } from 'lodash';
import { formatterClasses } from '../FormatterPresets';
import EncodingParser from '../EncodingParser';
import { OptionsAST } from '../AST';
import { Options } from '../Options';

const supportedFormatters = Object.keys(formatterClasses);

const isFormatter = (str): boolean => supportedFormatters.indexOf(str) !== -1;
/**
 * Returns a list of data selector strings in a DSL string.
 * Anything that is not a formatter is classified as a dataselector.
 * @param dslString
 */
export const getDataSelectorsFromDSL = (dslString: string): string[] => {
    const ast: OptionsAST = EncodingParser.parseOptions({
        options: {
            value: dslString,
        },
        context: {},
    });
    return (
        get(ast, ['expressions', 'value'], [])
            // filter out formatters, the rest is considered data selectors
            .filter((expression): boolean => !(expression.type === 'method' && isFormatter(expression.name)))
            .map((expression): string => {
                if (expression.type !== 'method') {
                    return `${expression.v}`;
                }
                return `${expression.name}(${get(expression, 'args', [])
                    .map((arg): any => arg.v)
                    .join(',')})`;
            })
    );
};
interface FormatterInfo {
    type: string;
    paramKey: string;
}
/**
 * Returns all formatters used in a DSL string.
 * Checks for formatter existence based on our FormatterPreset
 * @param dslString
 */
export const getFormattersFromDSL = (dslString: string): FormatterInfo[] => {
    const ast: OptionsAST = EncodingParser.parseOptions({
        options: {
            value: dslString,
        },
        context: {},
    });
    return get(ast, ['expressions', 'value'], [])
        .filter((expression): boolean => expression.type === 'method' && isFormatter(expression.name))
        .map(
            (expression): FormatterInfo => ({
                type: expression.name,
                paramKey: get(expression, ['args', '0', 'v'], ''),
            })
        );
};
/**
 * Returns info about the last formatter in a DSL string.
 * {
 *    type: <formatter name>
 *    paramKey: <parameter name the formatter call is using>
 * }
 * @param dslString
 */
export const getLastFormatterFromDSL = (dslString: string): FormatterInfo => {
    return last(getFormattersFromDSL(dslString));
};

/**
 * Helper to build a simple DSL string from data selector, formatter, and formatterParamKey
 * @param dataSelector
 * @param formatter the formatter type (e.g. rangeValue, matchValue, etc)
 * @param formatterParamKey the name of the formatter parameter
 */
export const buildDSLFromDataSelectorAndFormatter = (
    dataSelector,
    formatter,
    formatterParamKey = ''
): string => {
    return `> ${dataSelector} | ${formatter}(${formatterParamKey})`;
};

/**
 * Helper to return a list of fields names based on a DSL string
 * @param dsl
 * @param dataSources
 * @param vizOptions
 * @param vizContext
 */
export const getFieldsFromDSL = (dsl, dataSources, vizOptions = {}, vizContext = {}): string[] => {
    // @TODO(pwied):
    // for seriesByName & seriesByIndex we could skip evaluation completely
    try {
        const { evalFields } = Options.evaluate(
            {
                context: vizContext,
                options: {
                    ...vizOptions,
                    evalFields: `${dsl} | getField()`,
                },
            },
            dataSources,
            (): void => {}
        ) as { evalFields: string[] | string };
        if (Array.isArray(evalFields)) {
            return evalFields.filter(field => field.length > 0);
        }
        if (evalFields.length !== 0) {
            return [evalFields];
        }
    } catch (e) {} // eslint-disable-line no-empty
    return [];
};

/**
 * Helper to return a list of field data types based on a DSL string
 * @param dsl
 * @param dataSources
 * @param vizOptions
 * @param vizContext
 */
export const getDataTypesFromDSL = (dsl, dataSources, vizOptions = {}, vizContext = {}): string[] => {
    try {
        const { dataTypes } = Options.evaluate(
            {
                context: vizContext,
                options: {
                    ...vizOptions,
                    dataTypes: `${dsl} | getType()`,
                },
            },
            dataSources,
            (): void => {}
        ) as { dataTypes: string[] | string };
        if (Array.isArray(dataTypes)) {
            return [...new Set(dataTypes)];
        }
        if (dataTypes.length !== 0) {
            return [dataTypes];
        }
    } catch (e) {} // eslint-disable-line no-empty
    return [];
};
