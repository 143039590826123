/* eslint no-param-reassign: ["error", { "props": false }] */
import { hashString } from '@splunk/dashboard-utils';
import { cloneDeep, get } from 'lodash';
import type { FilterFunction } from './FilterStrategyTypes';

export type SearchMetric = {
    consumerId?: string;
    dataSourceId?: string;
    sid?: string;
    duration: number;
    ttfr: number;
    status?: string;
};

interface FilterSearchMetrics extends FilterFunction {
    (searchMetrics: SearchMetric[]): SearchMetric[];
}

/**
 * Filter datasource id & viz id (as consumer id) which can contain PII from search metrics.
 * @param {Array} searchMetrics
 */
const filterSearchMetrics: FilterSearchMetrics = (searchMetrics: unknown) => {
    if (!Array.isArray(searchMetrics)) {
        return [];
    }

    const clonedMetrics = cloneDeep(searchMetrics);
    return clonedMetrics.map((metric: SearchMetric) => {
        const consumerId = get(metric, 'consumerId');
        if (consumerId) {
            metric.consumerId = hashString(consumerId, 'viz_');
        }

        const dataSourceId = get(metric, 'dataSourceId');
        if (dataSourceId) {
            metric.dataSourceId = hashString(dataSourceId, 'ds_');
        }
        return metric;
    });
};

export default filterSearchMetrics;
