import React, { useCallback, useMemo, useEffect } from 'react';
import T from 'prop-types';
import { without, difference, isEqual, mapKeys } from 'lodash';
import { _ } from '@splunk/ui-utils/i18n';
import { MultiselectInput as MultiselectIcon } from '@splunk/dashboard-icons';
import type {
    DataSourceEventPayload,
    TokenValue,
} from '@splunk/dashboard-types';
import { isValidTokenValue } from '@splunk/dashboard-utils';
import SUIMultiselect from '@splunk/react-ui/Multiselect';
import { withInputWrapper } from '../utils/enhancer';
import {
    dataContract,
    mergeItems,
    hasDynamicOptions,
    fetchDynamicContent,
    getSearchStatus,
} from '../utils/inputItem';
import BaseInput from '../components/BaseInput';
import MultiselectSchema from './MultiselectSchema';
import MultiselectEditor from '../components/MultiselectEditorConfig';
import type { InputProps, StaticItem } from '../types';

const noItems: string[] = [];
const noMenuItems: StaticItem[] = [];

const parseValue = (value: string | string[]): string[] => {
    if (value) {
        return Array.isArray(value) ? value : value.split(',');
    }
    return noItems;
};

/**
 * We use SUI Multiselect under the hood to render the input
 */
interface MultiselectInterface {
    value?: string | string[];
    onValueChange: (...args: unknown[]) => void;
    context?: Record<string, unknown>;
    options?: {
        items?: StaticItem[];
        defaultValue?: string | string[];
        clearDefaultOnSelection?: boolean;
        selectFirstSearchResult?: boolean;
    };
    dataSources?: Record<string, DataSourceEventPayload>;
    encoding?: Record<string, unknown>;
    loading?: boolean;
    isError: boolean;
    errorMessage?: string;
    isDisabled?: boolean;
    disabledMessage?: string;
}
const Multiselect = ({
    value,
    context = {},
    options: {
        items = [],
        defaultValue,
        clearDefaultOnSelection = true,
        selectFirstSearchResult = false,
    } = {},
    dataSources = {},
    encoding,
    onValueChange,
    loading: isLoading,
    isError,
    errorMessage,
    isDisabled: initialDisabledStatus,
    disabledMessage: initialDisabledMessaged,
}: MultiselectInterface): React.ReactElement => {
    const searchHasNoResults = getSearchStatus(dataSources);

    const defaultValueArray = useMemo(
        () => (defaultValue ? parseValue(defaultValue) : noItems),
        [defaultValue]
    );

    // defaultValue should be null as it can evaluate to static items if the values are not presented in the item list
    const isDisabled =
        initialDisabledStatus || (searchHasNoResults && defaultValue == null);
    const disabledMessage =
        isDisabled && (initialDisabledMessaged || _('No search results'));

    // Memoized: Calculate the menuItems and the first search result for dynamic items
    const { menuItems, firstSearchResult } = useMemo(() => {
        if (isError || isLoading || isDisabled) {
            return {
                menuItems: noMenuItems,
                firstSearchResult: noItems,
            };
        }

        // Fetch the set of items from dataSources
        const hasDynamicItems = hasDynamicOptions([items]);
        const dynamicItems = fetchDynamicContent({
            context,
            items,
            dataSources,
            encoding,
        });

        // Get the first result from the dynamic data
        const firstResult =
            selectFirstSearchResult && dynamicItems.length > 0
                ? [dynamicItems[0].value]
                : noItems;

        // Merge dynamic and static values
        const staticItems = hasDynamicItems ? noItems : items;
        const mergedItems: StaticItem[] = mergeItems({
            dynamicItems,
            staticItems,
            defaultValues: defaultValueArray,
        });

        // Filter invalid items from the items list
        // Items are invalid if they do not have a value
        const validItems = mergedItems.filter((item) => !!item.value);

        return { menuItems: validItems, firstSearchResult: firstResult };
    }, [
        isError,
        isLoading,
        isDisabled,
        items,
        defaultValueArray,
        context,
        dataSources,
        encoding,
        selectFirstSearchResult,
    ]);

    // In order to set the token on input creation once the search resolves
    useEffect(() => {
        if (!value && firstSearchResult.length > 0) {
            onValueChange(null, firstSearchResult);
        }
    }, [firstSearchResult, onValueChange, value]);

    const selectedValues = useMemo(() => {
        // SUI Multiselect displays default value regardless of being in disabled or loading state
        if (isDisabled || isError || isLoading) {
            return noItems;
        }
        if (isValidTokenValue(value)) {
            return parseValue(value as TokenValue);
        }
        return selectFirstSearchResult ? firstSearchResult : defaultValueArray;
    }, [
        isDisabled,
        isError,
        isLoading,
        value,
        selectFirstSearchResult,
        defaultValueArray,
        firstSearchResult,
    ]);

    // Memoize creation of multiselect options based on data and configurations
    const multiselectOptions = useMemo(() => {
        return menuItems.map(({ label, value: val }) => (
            <SUIMultiselect.Option label={label} value={val} key={val} />
        ));
    }, [menuItems]);

    const placeholder = useMemo(() => {
        return _(errorMessage || disabledMessage || 'Select a value');
    }, [disabledMessage, errorMessage]);

    const loadingMessage = _('Loading menu items...');

    // onChange handler for multiselect, dereferences the item value from SUI payload
    const handleValueChange = useCallback(
        (evt, { values }) => {
            if (values.length === 0 && selectFirstSearchResult) {
                // If we de-select all options, select firstSearchResult as default
                onValueChange(evt, firstSearchResult);
                return;
            }
            // a hacky way to detect whether user clicked on the "Select All" button
            const isSelectAllClicked = isEqual(
                [...values].sort(),
                menuItems.map((item) => item.value).sort()
            );

            // special case: we want to clear default value when user selects a new value
            const hasDefaultValue = defaultValueArray.length > 0;
            const isNonDefaultValueSelected =
                difference(values, defaultValueArray).length > 0;
            const wasOnlyDefaultValueSelected = isEqual(
                [...selectedValues].sort(),
                [...defaultValueArray].sort()
            );

            const shouldClearDefaultValue =
                clearDefaultOnSelection &&
                hasDefaultValue &&
                isNonDefaultValueSelected &&
                wasOnlyDefaultValueSelected &&
                !isSelectAllClicked;

            const newValues = shouldClearDefaultValue
                ? without(values, ...defaultValueArray)
                : values;

            onValueChange(evt, newValues);
        },
        [
            selectFirstSearchResult,
            menuItems,
            defaultValueArray,
            selectedValues,
            clearDefaultOnSelection,
            onValueChange,
            firstSearchResult,
        ]
    );

    return (
        <SUIMultiselect
            values={selectedValues}
            onChange={handleValueChange}
            placeholder={placeholder}
            disabled={isDisabled || isError}
            isLoadingOptions={isLoading}
            loadingMessage={loadingMessage}
            compact
            defaultPlacement="below"
        >
            {multiselectOptions}
        </SUIMultiselect>
    );
};

/**
 * Transforms the value or values from the input to a set of token: value pairs
 * @param {String} value Select only the selected item value
 * @param {Object} meta
 * @param {String} meta.token The token name
 * @returns {Object}
 */
Multiselect.valueToTokens = (
    value: TokenValue,
    { token }: { token?: string }
) => {
    if (!token) {
        return {};
    }
    if (!value) {
        return {
            [token]: null,
        };
    }
    return {
        [token]: value,
    };
};

Multiselect.propTypes = {
    ...BaseInput.propTypes,
    value: T.oneOfType([T.string, T.arrayOf(T.string)]),
};
Multiselect.defaultProps = {
    ...BaseInput.defaultProps,
};

const meta = {
    label: _('Multiselect'),
    description: _('Select multiple list values'),
    defaultConfig: {
        options: {
            items: [
                { label: _('All'), value: '*' },
                { label: _('Item 1'), value: 'item001' },
                { label: _('Item 2'), value: 'item002' },
                { label: _('Item 3'), value: 'item003' },
            ],
            defaultValue: ['item001', 'item002'],
        },
        title: _('Multiselect Input Title'),
    },
    tokenPrefix: 'ms',
    icon: MultiselectIcon,
};

Multiselect.config = {
    optionsSchema: MultiselectSchema,
    editorConfig: MultiselectEditor,
    dataContract,
    ...mapKeys(meta, (_value, key) =>
        key === 'defaultConfig' ? 'baseShape' : key
    ),
};

export default withInputWrapper(
    Multiselect as React.FunctionComponent<InputProps>
);
