import some from 'lodash/some';
import isString from 'lodash/isString';
import escape from 'lodash/escape';
import mapValues from 'lodash/mapValues';
import isFunction from 'lodash/isFunction';
import get from 'lodash/get';
import set from 'lodash/set';
import map from 'lodash/map';
import isPlainObject from 'lodash/isPlainObject';
import isArray from 'lodash/isArray';
import isEmpty from 'lodash/isEmpty';
import values from 'lodash/values';
import flattenDeep from 'lodash/flattenDeep';
import uniqWith from 'lodash/uniqWith';
import isEqual from 'lodash/isEqual';
import { _ } from '@splunk/ui-utils/i18n';

import type {
    TokenName,
    TokenRecord,
    TokenState,
    TokenValue,
} from '@splunk/dashboard-types';

import hashString from './hashString';
import {
    TOKEN_NAMESPACE_PREFIX_PATTERN,
    TOKEN_OR_DOLLAR_RE,
    TokenRegExp,
    TokenNameRegExp,
} from './regexHelpers';

type PlainObject = Record<string, unknown>;

type UnknownStringOrObject = string | PlainObject | unknown;

export const DEFAULT_TOKEN_NAMESPACE = 'default';

const r = (regexp: RegExp) => {
    let flags = '';
    if (regexp.global) {
        flags += 'g';
    }
    if (regexp.multiline) {
        flags += 'm';
    }
    if (regexp.ignoreCase) {
        flags += 'i';
    }
    return new RegExp(regexp.source, flags);
};

// Check if the provided string starts with something which validates
// against the TOKEN_OR_DOLLAR_RE expression
export const startsWithToken = (str: unknown): boolean =>
    typeof str === 'string' &&
    new RegExp(`^(?:${TOKEN_OR_DOLLAR_RE.source})`).test(str);

export const isValidTokenValue = (tokenValue: unknown): boolean =>
    (typeof tokenValue === 'string' && !!tokenValue) ||
    (Array.isArray(tokenValue) && tokenValue.length > 0);

export const isValidTokenNamespace = (tokenNamespace: unknown): boolean => {
    if (typeof tokenNamespace !== 'string') {
        return false;
    }

    const namespaceWithColon = `${tokenNamespace}:`;
    const matches = new RegExp(TOKEN_NAMESPACE_PREFIX_PATTERN).exec(
        namespaceWithColon
    );

    if (!matches) {
        return false;
    }

    return matches[0] === namespaceWithColon;
};

const VALUE_ESCAPERS: Record<string, (v: TokenValue) => TokenValue> = {
    search(v) {
        if (Array.isArray(v)) {
            return v.map((val) => JSON.stringify(String(val)));
        }
        return JSON.stringify(String(v));
    },
    url(v) {
        if (Array.isArray(v)) {
            return v.map((val) => encodeURIComponent(String(val)));
        }
        return encodeURIComponent(String(v));
    },
    html(v) {
        if (Array.isArray(v)) {
            return v.map((val) => escape(String(val)));
        }
        return escape(String(v));
    },
    noEscape(v) {
        return v;
    },
};

type FilterType = Record<'h' | 'u' | 's' | 'n', (v: TokenValue) => TokenValue>;
export const DEFAULT_FILTERS: FilterType = {
    h: VALUE_ESCAPERS.html,
    u: VALUE_ESCAPERS.url,
    s: VALUE_ESCAPERS.search,
    n: VALUE_ESCAPERS.noEscape,
};

const MAX_RECURSION_LEVEL = 10;

type ExtractedToken = {
    namespace: string;
    name: string;
    filters: string[];
};

type Tokens = ExtractedToken[];

const getTrimmedTokenNamespace = (tokenNamespace: string) =>
    tokenNamespace
        ? tokenNamespace.substring(0, tokenNamespace.length - 1)
        : DEFAULT_TOKEN_NAMESPACE;

/**
 * Replace a single string with tokens with a given regexp
 * @param {*} value
 * @param {*} tokens
 * @param {*} tokenFilters
 * @param {*} regexp
 */
export const replaceTokensWithRegexp = ({
    value,
    tokens,
    tokenFilters = DEFAULT_FILTERS,
    regexp = TOKEN_OR_DOLLAR_RE,
}: {
    value: string | null;
    tokens: PlainObject;
    tokenFilters?: FilterType;
    regexp?: RegExp;
}): string | null => {
    if (value == null) {
        return value;
    }
    return value.replace(
        r(regexp),
        (
            match: string,
            tokenNamespace: string,
            tokenName: string,
            filterChain: string
        ) => {
            const namespace = getTrimmedTokenNamespace(tokenNamespace);
            let v = match;
            const tokenValue = get(tokens, [namespace, tokenName], null);
            if (
                !(Array.isArray(tokenValue) && tokenValue.length === 0) &&
                tokenValue != null
            ) {
                v = tokenValue;
                const filters = filterChain
                    ? filterChain.substring(1).split('|')
                    : [];
                // apply token filters
                filters.forEach((f) => {
                    const key = f as keyof FilterType;
                    if (isFunction(tokenFilters[key])) {
                        v = tokenFilters[key](v) as string;
                    }
                });
            }
            if (Array.isArray(v)) {
                v = (v as unknown as string[]).join(',');
            }
            return v;
        }
    );
};

/**
 * Replace a portion of a string with another string
 * @param {string} original string to be updated
 * @param {number} start start position of portion to be replaced
 * @param {number} end end position of portion to be replaced
 * @param {string} insertion string to be inserted
 */
const replaceBetween = (
    original: string,
    start: number,
    end: number,
    insertion: string
): string =>
    `${original.substring(0, start)}${insertion}${original.substring(end)}`;

interface ResolvedTokenWithMetadata {
    value: string;
    resolvedTokens: string[];
    missedTokens: string[];
}

/**
 * Extracts tokens and associated metadata from a string
 * @param {string} value string to replace tokens in
 * @param {Object} tokens tokens to be used in resolving
 * @param {Object} tokenFilters filters to apply tokens to
 * @param {number} start start pointer for where to check for tokens
 * @param {string[]} resolvedTokens list of resolved tokens
 * @param {string[]} missedTokens list of missed/unresolved tokens
 */
export const replaceTokensWithMetadata = ({
    value,
    tokens = {},
    tokenFilters = DEFAULT_FILTERS,
    start = 0,
    resolvedTokens = [],
    missedTokens = [],
}: {
    value: string;
    tokens?: PlainObject;
    tokenFilters?: FilterType;
    start?: number;
    resolvedTokens?: string[];
    missedTokens?: string[];
}): ResolvedTokenWithMetadata => {
    // move start pointer to first $ occurrence starting from s
    const first = value.indexOf('$', start);

    if (first === -1) {
        return {
            value,
            missedTokens,
            resolvedTokens,
        };
    }

    // if next character after first $ is also a $, then it is not a token and move on
    if (value[first + 1] === '$') {
        // escape $$ and move on
        const newValue = value.slice(0, first) + value.slice(first + 1);
        const nextStart = first + 1;
        return replaceTokensWithMetadata({
            value: newValue,
            tokens,
            tokenFilters,
            start: nextStart,
            resolvedTokens,
            missedTokens,
        });
    }

    // move end pointer to second $ occurrence from start
    const second = value.indexOf('$', first + 1);

    if (second === -1) {
        return {
            value,
            missedTokens,
            resolvedTokens,
        };
    }

    // Needed to handle case for potential attached tokens (i.e. $token1$$token2$)
    const nextFirst = second + 1;
    const nextSecond = value.indexOf('$', nextFirst + 1);
    const nextToken = value.slice(nextFirst, nextSecond + 1);

    // We can resolve current token if either attached to another token or attached to a "$$"
    const tokenAttached =
        TokenRegExp.test(nextToken) ||
        (value[second + 1] === '$' && value[second + 2] === '$');

    // if next character after second $ is not a $ OR a token is attached, resolve and continue
    if (value[second + 1] !== '$' || tokenAttached) {
        const tokenPortion = value.slice(first, second + 1);
        const isValidToken = TokenRegExp.test(tokenPortion);
        let resolvedTokenPortion = tokenPortion;

        if (isValidToken) {
            resolvedTokenPortion = replaceTokensWithRegexp({
                value: tokenPortion,
                tokens,
                tokenFilters,
                regexp: TOKEN_OR_DOLLAR_RE,
            }) as string;
        }

        const notReplaced = tokenPortion === resolvedTokenPortion;

        if (notReplaced) {
            if (isValidToken && !missedTokens.includes(tokenPortion)) {
                missedTokens.push(tokenPortion);
            }
            // update next start depending on if there is an attached token
            const nextStart = tokenAttached ? second + 1 : second;

            return replaceTokensWithMetadata({
                value,
                tokens,
                tokenFilters,
                start: nextStart,
                resolvedTokens,
                missedTokens,
            });
        }

        // current token is replaced and recurse with new value and start
        const newValue = replaceBetween(
            value,
            first,
            second + 1,
            resolvedTokenPortion
        );
        if (!resolvedTokens.includes(tokenPortion) && isValidToken) {
            resolvedTokens.push(tokenPortion);
        }
        const diff = resolvedTokenPortion.length - tokenPortion.length;
        const newStart = second + diff + 1;
        return replaceTokensWithMetadata({
            value: newValue,
            tokens,
            tokenFilters,
            start: newStart,
            resolvedTokens,
            missedTokens,
        });
    }

    return replaceTokensWithMetadata({
        value,
        tokens,
        tokenFilters,
        start: second,
        resolvedTokens,
        missedTokens,
    });
};

interface ResolvedObjectWithMetadata {
    value: string | PlainObject | unknown;
    missedTokens: string[];
}

/**
 * Replace tokens in an object's value with metadata
 * @param {*} value
 * @param {*} tokens
 * @param {*} tokenFilters
 */
export const replaceTokensForObjectWithMetadata = (
    value: UnknownStringOrObject,
    tokens: PlainObject,
    tokenFilters = DEFAULT_FILTERS
): ResolvedObjectWithMetadata => {
    const missedTokens = new Set();

    const tokenObjectRecurser = (
        val: UnknownStringOrObject,
        recursionLevel = 0
    ): UnknownStringOrObject => {
        if (val === null || typeof val === 'undefined') {
            return val;
        }
        if (isString(val)) {
            const result = replaceTokensWithMetadata({
                value: val,
                tokens,
                tokenFilters,
            });
            result.missedTokens.forEach((token) => {
                missedTokens.add(token);
            });
            return result.value;
        }
        if (isPlainObject(val) && recursionLevel <= MAX_RECURSION_LEVEL) {
            return mapValues(val as PlainObject, (v) =>
                tokenObjectRecurser(v, recursionLevel + 1)
            );
        }
        if (isArray(val) && recursionLevel <= MAX_RECURSION_LEVEL) {
            return map(val as UnknownStringOrObject[], (v) =>
                tokenObjectRecurser(v, recursionLevel + 1)
            );
        }
        return val;
    };

    const replacedObj = tokenObjectRecurser(value);

    return {
        value: replacedObj,
        missedTokens: Array.from(missedTokens) as string[],
    };
};

/**
 * Replace a single string with tokens
 * @param {string|null|undefined} value
 * @param {Object} tokens
 * @param {Object} tokenFilters
 */
export const replaceTokens = (
    value: string | null | undefined,
    tokens: PlainObject,
    tokenFilters = DEFAULT_FILTERS
): string | null | undefined =>
    value
        ? replaceTokensWithMetadata({
              value,
              tokens,
              tokenFilters,
          }).value
        : value;

/**
 * Replace token names within a string with a hashed value
 * @param {string} value
 * @returns {string} original string with any token names hashed
 */
export const replaceTokensWithHash = (value: string): string => {
    if (value == null) {
        return value;
    }

    return value.replace(
        r(TOKEN_OR_DOLLAR_RE),
        (
            match: string,
            tokenNamespace: string,
            tokenName: string,
            filterChain: string
        ) => {
            let namespace;
            if (tokenNamespace) {
                // to remove the semicolon at the end
                namespace = tokenNamespace.substring(
                    0,
                    tokenNamespace.length - 1
                );
            }

            let v = match;

            if (namespace) {
                v = v.replace(namespace, hashString(namespace, 'namespace_'));
            }

            if (tokenName) {
                v = v.replace(tokenName, hashString(tokenName, 'token_'));
            }

            if (filterChain) {
                // note that this is NOT hashing each individual filter here (to be able to correlate them)
                v = v.replace(filterChain, hashString(filterChain, '|filter_'));
            }
            return v;
        }
    );
};

/**
 * Replace tokens in an object's value
 * @param {*} value
 * @param {*} tokens
 * @param {*} tokenFilters
 */
export const replaceTokensForObject = (
    value: UnknownStringOrObject,
    tokens: PlainObject,
    tokenFilters = DEFAULT_FILTERS,
    recursionLevel = 0
): UnknownStringOrObject => {
    if (value == null) {
        return value;
    }
    if (isString(value)) {
        return replaceTokens(value, tokens, tokenFilters);
    }
    if (isPlainObject(value) && recursionLevel <= MAX_RECURSION_LEVEL) {
        return mapValues(value as PlainObject, (v) =>
            replaceTokensForObject(v, tokens, tokenFilters, recursionLevel + 1)
        );
    }
    if (isArray(value) && recursionLevel <= MAX_RECURSION_LEVEL) {
        return map(value as PlainObject[], (v) =>
            replaceTokensForObject(v, tokens, tokenFilters, recursionLevel + 1)
        );
    }
    return value;
};

/**
 * extract tokens from value
 * @param {string} value
 */
export const extractTokens = (value: string): Tokens => {
    if (!value) {
        return [];
    }

    return replaceTokensWithMetadata({
        value,
    }).missedTokens.map((token: string): ExtractedToken => {
        const tokenRegex = r(TOKEN_OR_DOLLAR_RE);

        // This looks a little funky with the hanging comma at the start, but
        // RegExpExecArray[0] is the matched string and in the destructuring
        // of the array that value should be ignored or assigned to a dummy var
        const [, namespace, name, filters] = tokenRegex.exec(token) ?? [];

        return {
            namespace: namespace?.slice(0, -1) ?? DEFAULT_TOKEN_NAMESPACE,
            name: name ?? '',
            filters: filters?.slice(1)?.split('|') ?? [],
        };
    });
};

/**
 * Extract tokens from an object
 * @param {*} value
 */
export const extractTokensFromObject = (
    value: UnknownStringOrObject,
    recursionLevel = 0
): Tokens => {
    if (isEmpty(value)) {
        return [];
    }
    if (isString(value)) {
        return extractTokens(value);
    }
    if (isPlainObject(value) && recursionLevel <= MAX_RECURSION_LEVEL) {
        return uniqWith(
            flattenDeep(
                map(values(value), (v) =>
                    extractTokensFromObject(v, recursionLevel + 1)
                )
            ),
            isEqual
        );
    }
    if (isArray(value) && recursionLevel <= MAX_RECURSION_LEVEL) {
        return uniqWith(
            flattenDeep(
                map(value, (v) =>
                    extractTokensFromObject(v, recursionLevel + 1)
                )
            ),
            isEqual
        );
    }
    return [];
};

/**
 * test if a string contains a token
 * @param {String} value
 */
export const hasTokens = (value: string): boolean => {
    if (typeof value !== 'string') {
        throw new TypeError('hasTokens requires a string');
    }
    return replaceTokensWithMetadata({ value }).missedTokens.length > 0;
};

/**
 * test if a object value contains a token
 * @param {Object} obj
 */
export const hasTokensInObject = <T extends PlainObject>(
    obj: T | T[],
    recursionLevel = 0
): boolean => {
    if (obj == null) {
        return false;
    }
    if (isString(obj)) {
        return hasTokens(obj);
    }
    if (
        (isArray(obj) || isPlainObject(obj)) &&
        recursionLevel <= MAX_RECURSION_LEVEL
    ) {
        return some(obj as T[], (v) =>
            hasTokensInObject(v, recursionLevel + 1)
        );
    }
    return false;
};

/**
 * Calls replaceTokensForObject on an object but preserves the
 * properties that were passed in the denyList
 * @param {Object} obj object you want tokenized
 * @param {Object} tokens tokens to replace
 * @param {String[]} denyList array of strings that correspond to object properties you want preserved, used by lodash get/set. ex: ['property1', 'nested.property']
 */
export const safeReplaceTokensForObject = <T extends PlainObject>(
    obj: T,
    tokens: TokenState,
    denyList: string[]
): T => {
    const rawObject: PlainObject = {};
    denyList.forEach((key) => {
        rawObject[key] = get(obj, key);
    });

    // Set the props back to their non-tokenized values
    const tokenizedObj = replaceTokensForObject(obj, tokens) as T;
    denyList.forEach((key) => {
        if (rawObject[key]) {
            set(tokenizedObj, key, rawObject[key]);
        }
    });

    return tokenizedObj;
};

/**
 * Converts url search query into tokens in 'default' namespace
 * Current assumption is url tokens do not have a defined namespace, only default
 * @param {Object} obj
 * @param {String} obj.search url search query (window.location.search) from which token object is created
 * @returns {Object} tokens extracted from search query
 */
export const mapURLToTokens = ({
    search,
}: {
    search: string;
}): {
    default: TokenRecord;
} => {
    const urlSearchParams = new URLSearchParams(search);
    const tokens: TokenRecord = {};
    urlSearchParams.forEach((value, key) => {
        if (!key.startsWith('form.') || !isValidTokenValue(value)) {
            return;
        }
        const tokenName = key.slice(5);
        if (tokens[tokenName]) {
            if (typeof tokens[tokenName] === 'string') {
                tokens[tokenName] = [tokens[tokenName] as string];
            }
            (tokens[tokenName] as string[]).push(value);
        } else {
            tokens[tokenName] = value;
        }
    });
    return { default: tokens };
};

/**
 * Maps tokens to url search params either by updating existing tokens or appending as new ones
 * @param {Object} obj
 * @param {String} obj.search url search query (window.location.search) that's to be augmented
 * @param {Object} obj.tokens derived input token values
 * @returns {String} updated search query
 */
export const mapTokensToURL = ({
    search: initSearch = '?',
    tokens,
}: {
    tokens: TokenState;
    search?: string;
}): string => {
    const initSearchParams = new URLSearchParams(initSearch);
    const updatedSearchParams = new URLSearchParams(initSearch);

    const updateParam = (tokenName: TokenName) => {
        const tokenValue = tokens[DEFAULT_TOKEN_NAMESPACE][tokenName];
        const key = `form.${tokenName}`;
        if (Array.isArray(tokenValue)) {
            tokenValue.forEach((tokenArrayValue) => {
                if (isValidTokenValue(tokenArrayValue)) {
                    updatedSearchParams.append(key, tokenArrayValue);
                }
            });
        } else if (isValidTokenValue(tokenValue)) {
            updatedSearchParams.set(key, tokenValue as string);
        }
    };

    // remove the url tokens that do not exist in the derived token values and update the existing ones if they are not unset
    initSearchParams.forEach((_value, key) => {
        if (key.startsWith('form.')) {
            const tokenName = key.substring(5);
            updatedSearchParams.delete(key);
            if (tokenName in tokens.default) {
                updateParam(tokenName);
            }
        }
    });

    // add derived tokens not present in the url search params
    Object.keys(tokens.default).forEach((key) => {
        if (!updatedSearchParams.has(`form.${key}`)) {
            updateParam(key);
        }
    });

    return updatedSearchParams.toString();
};

export const CANNOT_CONTAIN_TOKEN_MSG = _('Token names cannot contain tokens.');

export const INVALID_FIRST_LAST_CHAR_MSG = _(
    'Token names must start and end with an alphanumeric character, dash, underscore, or period.'
);

export const INVALID_CHAR_MSG = _(
    'Token names can only include letters, numbers, spaces, dashes, underscores, and periods.'
);

export const getTokenNameError = (tokenName: string) => {
    // cannot set token as token name
    if (TokenRegExp.test(tokenName)) {
        return CANNOT_CONTAIN_TOKEN_MSG;
    }

    // must start and end with an alphanumeric, space, dash, or underscore
    const validChars = /[\w.-]/;
    const tokenLength = tokenName.length;
    if (
        tokenLength === 0 ||
        !validChars.test(tokenName[0]) ||
        !validChars.test(tokenName[tokenLength - 1])
    ) {
        return INVALID_FIRST_LAST_CHAR_MSG;
    }

    // must be a valid token name
    if (!TokenNameRegExp.test(tokenName)) {
        return INVALID_CHAR_MSG;
    }

    return null;
};
