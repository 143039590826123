/* eslint-disable no-param-reassign */
import * as React from 'react';
import * as XPath from 'xpath';
import { DOMParser, XMLSerializer } from '@xmldom/xmldom';
import * as jexl from 'jexl';
import { SvgChoroplethDynamicProps, SvgAttributeBinding } from './SvgChoroplethDynamic';

/**
 * An error message indicating that a mutation has attempted to target anything other than SVG attribute(s)
 * @type {string}
 */
export const ERROR_NON_ATTRIBUTE_MUTATION = 'mutations only allowed on attribute nodes';

/**
 * This class takes columnar data and mutates an SVG document by applying rules defined in DataBindings. This class
 * includes capability to place delays between the mutations such that the data can be animated "row by row" with definable
 * animation delays. This class parses an SVG document into an internal dom (NOT THE BROWSER DOM), which is then
 * converted into a ReactNode.
 */
export class SvgMutator {
    private svgDoc: XMLDocument;

    private props: SvgChoroplethDynamicProps;

    /**
     * parses the svg and stores the parsed Document in a local field. Keeps a copy of the props.
     * @param {SvgChoroplethDynamicProps} props
     */
    constructor(props: SvgChoroplethDynamicProps) {
        const { svg } = props;
        this.svgDoc = new DOMParser().parseFromString(svg);
        this.props = { ...props };
    }

    /**
     * Applies all data mutations on a clone of this.svgDoc and returns a ReactNode
     * suitable for displaying the mutated content. Includes callback that can be used to get the actual
     * dom node.
     * @param {(node: Node) => void} refCallback
     * @returns {React.ReactNode}
     */
    mutate(refCallback: (node: Node) => void = () => {}): React.ReactElement {
        const content: Node = this.applyMutations(this.svgDoc);
        return SvgMutator.getSandboxedIframe(refCallback, content, this.props);
    }

    /**
     * This method forms a JEXL context from the data, and a special '$i' variable which tells which row is being processed.
     * The context is passed to JEXL which is used to find the XPathResult which we consider as "hits" in the search of the
     * SVG for attributes to mutate. Once we have these 'found' attribute hits, we mutate each one.
     * @param dataBinding
     * @param data
     * @param {number} i
     * @param {Node} target
     */
    private static applyMutation(dataBinding, data, i: number, target: Node): void {
        const { xPath } = dataBinding;
        if (!xPath) {
            throw new Error('xPath property not defined');
        }
        // use xpath expression to seek out places to make changes
        const hits = SvgMutator.evaluateXPath(xPath, target, { ...data, $i: i });

        let hit;
        for (hit = hits.iterateNext(); hit; hit = hits.iterateNext()) {
            SvgMutator.mutateNode(hit, dataBinding, data, i);
        }
    }

    /**
     * Clones the prototypical Node, applies all data mutations to the cloned target, and returns the mutated clone
     * @param {Node} prototypicalNode
     * @returns {Node}
     */
    private applyMutations(prototypicalNode: Node): Node {
        const { dataBinding, data } = this.props;
        const target = prototypicalNode.cloneNode(true);
        if (data) {
            const anyKey = Object.keys(data)[0];
            const len = data[anyKey].length;
            for (let i = 0; i < len; i += 1) {
                // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
                dataBinding.forEach(db => SvgMutator.applyMutation(db, data, i, target));
            }
        }
        return target;
    }

    /**
     *
     * @param {(node: Node) => void} refCallback called with actual iframe node in real DOM
     * @param {Node} node
     * @param width
     * @param height
     * @returns {React.ReactElement}
     */
    private static getSandboxedIframe(
        refCallback: (node: Node) => void, // fixme get rid of?
        node: Node,
        currentProps: any
    ): React.ReactElement {
        const svgNode: Node = SvgMutator.getSvgElementFromDoc(node);
        (svgNode as Element).setAttribute('width', currentProps.width || '100%');
        (svgNode as Element).setAttribute('height', currentProps.height || '100%');
        const NONCE = window.crypto.getRandomValues(new Uint32Array(4)).join('');
        const PARENT_ID = window.crypto.getRandomValues(new Uint32Array(4)).join('');
        const html = SvgMutator.getIframeHtml(NONCE, PARENT_ID, svgNode);
        /**
         * In the previous implementation, we encoded the html into base64 format and constructed a Data URI from it,
         * but Chrome has a size limit of 2MB for Data URIs, therefore SVGs of size > 2MB do not render (https://splunk.atlassian.net/browse/SCP-54560)
         * Alternatively, Chrome has a higher size limit for Blobs (500Mib? - https://source.chromium.org/chromium/chromium/src/+/main:storage/browser/blob/README.md)
         * and we can create Blob URLs via URL.createObjectURL(), using the URL API together with blobs via the File API
         */
        const blob = new Blob([html], { type: 'text/html' });
        const blobURL = URL.createObjectURL(blob);
        const iframe = React.createElement('iframe', {
            src: blobURL,
            style: {
                position: 'absolute',
                height: '100%',
                width: '100%',
                margin: '0',
                padding: '0',
                pointerEvents: 'auto', // if this isn't 'none' it absorbs all pointer events and none get to enclosing div (credit @hwerner)
                colorScheme: 'light', // iframe adds a white background when using 'dark' theme, hacking to set scheme to 'light'
            },
            frameBorder: 0,
            sandbox: 'allow-scripts',
            id: 'svgChoroplethIframe',
        });
        let intervalID = null;
        let messageListener = null;
        return React.createElement(
            'div',
            {
                style: {
                    width: '100%',
                    height: '100%',
                    left: 0,
                    top: 0,
                    position: 'absolute',
                },
                id: 'svgDiv',
                ref: div => {
                    if (!div) {
                        // called on unmount
                        SvgMutator.removeIframeEventListener(div, messageListener, intervalID, refCallback);
                        if (blobURL) {
                            URL.revokeObjectURL(blobURL);
                        }
                        return;
                    }
                    messageListener = SvgMutator.createIframeMessageListener(NONCE, intervalID, div);
                    window.addEventListener('message', messageListener);
                    function sendSetupMessage() {
                        const svgIframe = div.querySelector('#svgChoroplethIframe') as HTMLIFrameElement;
                        // send single message to iframe content so that content can "learn" the targetOrigin for it's reply messages
                        if (svgIframe) {
                            svgIframe.contentWindow.postMessage({ parentIdentity: PARENT_ID }, '*');
                        }
                    }
                    // continuously send an initialization message to the iframe until it ack's back
                    intervalID = setInterval(sendSetupMessage, 500);
                    refCallback(div);
                },
            },
            iframe // child of div
        );
    }

    private static getSvgElementFromDoc(node: Node): Node {
        if (node.nodeName === '#document') {
            const kids = node.childNodes;
            for (let i = 0; i < kids.length; i += 1) {
                node = kids.item(i);
                // re https://jira.splunk.com/browse/SPL-188348 DOCTYPE nodes that we want to skip would have
                // nodeName 'svg', but only the actualy <svg> element has nodeType 1 (ELEMENT_NODE)
                if (node.nodeName === 'svg' && node.nodeType && node.nodeType === 1) {
                    break;
                }
            }
        }

        if (node.nodeName !== 'svg') {
            throw new Error(`Could not find an <svg> element`);
        }
        return node as Element;
    }

    /**
     * Runs JEXL to evaluate an expression that returns an XPath string, then evaluate the XPath string against the
     * svg document Node (i.e. 'search' the SVG document)
     * @param {string} xpathExpr
     * @param {Node} doc
     * @param {object} context
     * @returns {XPathResult}
     */
    private static evaluateXPath(xpathExpr: string, doc: Node, context = {}): XPathResult {
        const resolver: XPathNSResolver = {
            lookupNamespaceURI(): string | null {
                return 'http://www.w3.org/2000/svg';
            },
        };
        const xpath = jexl.evalSync(xpathExpr, context);
        return XPath.evaluate(xpath, doc, resolver, XPathResult.ANY_TYPE, null);
    }

    private static getTitle(data: any, i: number): string {
        let title = '';
        if (data.featureIDs && data.featureIDs[i]) {
            title += `name: ${data.featureIDs[i]}`;
        }
        if (data.values && data.values[i] !== undefined) {
            title += `\nvalue: ${data.values[i]}`;
        }
        return title;
    }

    /**
     * At this point we have identified an SVG attribute Node that we wish to mutate. That node is passed in here along
     * with the data binding and the data. We have already used the dataBinding's xpath (that is how we found the
     * attribute Node. Now we use the dataBindings 'value' field, which is also an expression to extract a value from
     * the data itself. We then alter the attribute's value (it's nodeValue). If the data has a 'values' column, then we
     * also set data-value attribute of the node, and similarly for the data-name (coming from the data's featureId column)
     * @param {Node} n
     * @param {SvgAttributeBinding} dataBinding
     * @param {{[p: string]: string[]}} data
     * @param {number} i
     * @returns {any}
     */
    private static mutateNode(
        n: Node,
        dataBinding: SvgAttributeBinding,
        data: { [key: string]: string[] },
        i: number
    ): any {
        // eslint-disable-next-line eqeqeq
        if (n.nodeType && n.nodeType != Node.ATTRIBUTE_NODE) {
            throw new Error(ERROR_NON_ATTRIBUTE_MUTATION);
        }
        const context = { $oldValue: n.nodeValue, ...data, $i: i };
        let computed;
        try {
            computed = jexl.evalSync(dataBinding.value, context);
        } catch (error) {
            return;
        }
        // this is the actual mutation: we change the attributes value
        n.nodeValue = computed;
        (n as Attr).value = computed; // So you may ask...why do we set BOTH the nodeValue and the Attr value. The nodeValue seems to be the one that "shows up" as reflecting the change. We use nodeValue down in addSubtree. Yet XMLSerializer will only see the changes from Attr.value. So what the heck, we use BOTH

        // we also add data-* attributes to the node and a title that behaves like a hover
        const parent = (n as Attr).ownerElement;
        const title = SvgMutator.getTitle(data, i);
        if (data.featureIDs && data.featureIDs[i]) {
            parent.setAttribute('data-name', data.featureIDs[i]);
        }
        if (data.values && data.values[i] !== undefined) {
            parent.setAttribute('data-value', data.values[i]);
        }
        if (title !== '') {
            const titleElem = n.ownerDocument.createElementNS('http://www.w3.org/2000/svg', 'title');
            titleElem.textContent = title;
            parent.appendChild(titleElem);
        }
        if (data.featureIDs) {
            parent.setAttribute('data-name', data.featureIDs[i]);
        }
        // eslint-disable-next-line consistent-return
        return computed; // makes this method more testable to return the updated node value, though real callers don't need to use the returned value.
    }

    private static getCssResetStyle(): string {
        return `/* http://meyerweb.com/eric/tools/css/reset/
                   v2.0 | 20110126
                   License: none (public domain)
                */

                html, body, div, span, applet, object, iframe,
                h1, h2, h3, h4, h5, h6, p, blockquote, pre,
                a, abbr, acronym, address, big, cite, code,
                del, dfn, em, img, ins, kbd, q, s, samp,
                small, strike, strong, sub, sup, tt, var,
                b, u, i, center,
                dl, dt, dd, ol, ul, li,
                fieldset, form, label, legend,
                table, caption, tbody, tfoot, thead, tr, th, td,
                article, aside, canvas, details, embed,
                figure, figcaption, footer, header, hgroup,
                menu, nav, output, ruby, section, summary,
                time, mark, audio, video {
                    margin: 0;
                    padding: 0;
                    border: 0;
                    font-size: 100%;
                    font: inherit;
                    vertical-align: baseline;
                }
                /* HTML5 display-role reset for older browsers */
                article, aside, details, figcaption, figure,
                footer, header, hgroup, menu, nav, section {
                    display: block;
                }
                body {
                    line-height: 1;
                }
                ol, ul {
                    list-style: none;
                }
                blockquote, q {
                    quotes: none;
                }
                blockquote:before, blockquote:after,
                q:before, q:after {
                    content: '';
                    content: none;
                }
                table {
                    border-collapse: collapse;
                    border-spacing: 0;
                }
                svg {
                    pointer-events:all;
                }`;
    }

    public static getCsp(nonce: string) {
        return `default-src 'self'; child-src 'none'; style-src 'self' 'unsafe-inline'; img-src data: ; script-src 'nonce-${nonce}'`;
    }

    private static serializer = new XMLSerializer();

    public static getIframeHtml(nonce: string, parentId: string, svgNode: Node) {
        return `<html><head>
                         <meta http-equiv="Content-Security-Policy" content="${this.getCsp(`${nonce}`)}"/>
                         <style>
                            ${SvgMutator.getCssResetStyle()}
                        </style>

                      </head>
                      <body>
                        <div id="svgChoroplethSvgDiv" style="position:absolute;height:100%;width:100%;overflow:hidden">
                            ${SvgMutator.serializer.serializeToString(svgNode)}
                        </div>
                        <script nonce="${nonce}">

                            let parentOrigin = null;
                            let parentWindow = null;

                            function sendMessage(m){

                                if(!parentWindow){
                                    return; // don't send any messages until we know where to send them
                                }
                                m.nonce = '${nonce}'; // set nonce into message.
                                parentWindow.postMessage(m,parentOrigin);
                            }


                            const parentSetupMessageListener = m =>{
                                const {parentIdentity} = m.data;
                                if(parentIdentity !== '${parentId}'){
                                    return;
                                }
                                //set parent origin
                                parentOrigin = m.origin;
                                if(parentOrigin === 'null'){ // yes, this is supposed to be a string 'null'
                                    parentOrigin = '*';
                                }
                                if(!m.source){
                                    return;
                                }
                                parentWindow = m.source;
                                sendMessage({}); //acknowledge
                                // we can now remove ourself since initial setup complete
                                window.removeEventListener('message', parentSetupMessageListener);
                            };

                            function registerListeners(){

                                //listen for a first message to establish the parent origin
                                window.addEventListener('message', parentSetupMessageListener);

                                ['mousemove', 'mouseover', 'mousedown', 'mouseup', 'click'].forEach(eventType => {
                                    const svgElement = document.getElementsByTagName('svg')[0];
                                    svgElement.addEventListener(eventType, (e)=> {
                                        const value = e.target.getAttribute('data-value');
                                        const name = e.target.getAttribute('data-name');
                                        sendMessage({
                                            nonce:'${nonce}',
                                            eventType,
                                            clientX:e.clientX,
                                            clientY:e.clientY,
                                            value,
                                            name,
                                        });
                                    });
                                });


                            }
                            registerListeners();
                        </script>
                      </body></html>`;
    }

    static createIframeMessageListener(
        expectedNonce: string,
        intervalId: any,
        div: HTMLElement,
        jsdom = false
    ) {
        return m => {
            const { nonce, eventType, clientX, clientY, value, name } = m.data;
            if (nonce !== expectedNonce || (!jsdom && m.origin !== 'null')) {
                // yes, m.origin IS actually supposed to be a string 'null'. That is origin for opaque URL
                return; // If message did not contain expected nonce, then ignore (comms will not be stablished).
            }
            if (intervalId) {
                clearInterval(intervalId); // stop sending setup message
            }
            const boundingRect = div.getBoundingClientRect();
            if (eventType && clientX && clientY) {
                let mEvent;
                const realClientX = Math.round(clientX + boundingRect.left);
                const realClientY = Math.round(clientY + boundingRect.top);
                const eventSetting = {
                    bubbles: true,
                    cancelable: true,
                    composed: true,
                };
                // only dispatch customEvent for click because value and name are needed to bubble up
                if (eventType === 'click') {
                    mEvent = new CustomEvent('areaClick', {
                        detail: {
                            clientX: realClientX,
                            clientY: realClientY,
                            value,
                            name,
                        },
                        ...eventSetting,
                    });
                } else {
                    mEvent = new MouseEvent(eventType, {
                        clientX: realClientX,
                        clientY: realClientY,
                        ...eventSetting,
                    });
                }
                div.dispatchEvent(mEvent);
            }
        };
    }

    static removeIframeEventListener(element, messageListener, intervalID, refCallback): void {
        if (messageListener) {
            window.removeEventListener('message', messageListener);
        }
        if (intervalID) {
            clearInterval(intervalID); // stop sending setup message
        }
        refCallback(element);
    }
}
