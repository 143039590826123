import { _ } from '@splunk/ui-utils/i18n';
import { mapValues, mapKeys } from 'lodash';

/**
 * Add a config property to the legacy/custom Viz class to make it consistent with the new splunk viz api
 * If the Viz contains a meta property, extract name, icon and category into config
 * along with the Viz dataContract, editor and optionsSchema
 * @param {Visualization} Viz The visualization to enhance
 */
export const withVizConfig = (Viz, key) => {
    // New splunk.* viz already contain the config property
    if (Viz.config) {
        return Viz;
    }

    const { dataContract = {}, editor = [], schema = {} } = Viz;
    // eslint-disable-next-line no-param-reassign
    Viz.config = {
        dataContract,
        editorConfig: editor,
        optionsSchema: schema,
        key,
    };

    if (Viz.meta) {
        const { label: name, icon, category, key: metaKey } = Viz.meta;
        // eslint-disable-next-line no-param-reassign
        Viz.config = {
            ...Viz.config,
            key: metaKey ?? Viz.config.key,
            name,
            icon,
            category,
        };
    }

    return Viz;
};

/**
 * Add a config property to the custom Input class to make it consistent with the updated input api
 * If the Input contains a meta property, extract all the key/value pairs into config
 * along with the Input dataContract, editor and optionsSchema
 * @param {Input} Input The input to enhance
 */
export const withInputConfig = (Input) => {
    // input already contain the config property
    if (Input.config) {
        return Input;
    }

    const { dataContract = {}, editor = [], schema = {}, meta } = Input;
    // eslint-disable-next-line no-param-reassign
    Input.config = {
        dataContract,
        editorConfig: editor,
        optionsSchema: schema,
    };

    if (meta) {
        // eslint-disable-next-line no-param-reassign
        Input.config = {
            ...Input.config,
            ...mapKeys(meta, (_value, key) =>
                key === 'defaultConfig' ? 'baseShape' : key
            ),
        };
    }

    return Input;
};

/**
 * Util function that updates the preset definition's visualization and input presets
 * Legacy and custom visualizations are updated with the config property to match new splunk viz api
 * Custom inputs are updated with the config property to match the updated input api
 * @param {Object} preset Preset definition
 */
export const updatePreset = (preset) => {
    const { visualizations, inputs } = preset;
    return {
        ...preset,
        visualizations: mapValues(visualizations ?? {}, withVizConfig),
        inputs: mapValues(inputs ?? {}, withInputConfig),
    };
};

// NOTE: the order in this list defines the order in which categories appear in toolbar/viz switcher
// TODO: use category constants from viz package(s)
export const vizCategories = new Map([
    ['table', _('Table')],
    ['singleValue', _('Single Value')],
    ['trends', _('Trends')],
    ['comparisons', _('Comparisons')],
    ['gauge', _('Gauge')],
    ['distributions', _('Distributions')],
    ['choropleth', _('Choropleth Maps')],
    ['image', _('Image')],
    ['text', _('Text')],
    ['shapes', _('Shapes')],
    ['flow', _('Flow')],
]);
