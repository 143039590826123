import React from 'react';
import { _ } from '@splunk/ui-utils/i18n';
import SVG, {
    type SVGPropsOptionalViewBox,
} from '@splunk/react-icons/SVGEnterprise';

/* eslint-disable max-len */
export default function AlignCenterVertical(
    props: SVGPropsOptionalViewBox
): ReturnType<typeof SVG> {
    return (
        <SVG
            viewBox="0 0 24 23"
            screenReaderText={_('Align Center Vertical')}
            {...props}
        >
            <path
                fill="currentColor"
                d="M23.5 10L0.5 10C0.223858 10 1.01779e-07 10.2239 6.55671e-08 10.5C2.93554e-08 10.7761 0.223857 11 0.5 11L23.5 11C23.7761 11 24 10.7761 24 10.5C24 10.2239 23.7761 10 23.5 10Z"
            />
            <path
                fill="currentColor"
                d="M13 4.5L13 16.5C13 16.7761 13.2239 17 13.5 17L19.5 17C19.7761 17 20 16.7761 20 16.5L20 4.5C20 4.22386 19.7761 4 19.5 4L13.5 4C13.2239 4 13 4.22386 13 4.5Z"
            />
            <path
                fill="currentColor"
                d="M4 0.5L4 20.5C4 20.7761 4.22386 21 4.5 21L10.5 21C10.7761 21 11 20.7761 11 20.5L11 0.500001C11 0.223859 10.7761 8.88584e-07 10.5 8.52372e-07L4.5 6.55671e-08C4.22386 2.93554e-08 4 0.223858 4 0.5Z"
            />
        </SVG>
    );
}
