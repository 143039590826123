import type { GlobalState, TokenSlice } from '@splunk/dashboard-types';
import { createSelector } from '@reduxjs/toolkit';
import { noTokens, noReadOnlyTokenNamespaces } from '../utils/token';

// This is defined in the tokens reducer but because `selectDSTokens` is imported
// into that file it's being recreated locally to avoid the circular file dependency
export const selectReadOnlyTokenNamespaces = createSelector(
    (state: GlobalState): TokenSlice => state?.tokens || noTokens,
    (tokens) => tokens?.readOnlyTokenNamespaces || noReadOnlyTokenNamespaces
);
