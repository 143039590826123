import * as React from 'react';

import SUITable from '@splunk/react-ui/Table';
import Line from '@splunk/react-sparkline/Line';
import { TableRowCell, TableCellValueType } from '../hooks';

interface SparklineCellProps {
    cell: TableRowCell;
    useMouseOverEffect: boolean;
    width: number | 'auto';
}

const paddingLeftRight = 5;
const paddingTopBottom = 3;

const parseSparklineData = (data: TableCellValueType): (number | null)[] =>
    (Array.isArray(data) ? data : [data])
        .map((d): number => parseInt(d.toString(), 10))
        .map((d): number | null => (isNaN(d) ? null : d)); // eslint-disable-line no-restricted-globals

export const validSparklineTypes = ['line', 'area'] as const;
export type SparklineType = typeof validSparklineTypes[number];

const noop = () => undefined;
const svgStyle = { height: 0 };
const dummySVG = <svg style={svgStyle} />;

export const SparklineCell = ({
    cell,
    useMouseOverEffect,
    width,
}: SparklineCellProps): React.ReactElement => {
    const [cellEl, setEl] = React.useState<HTMLTableCellElement>();
    const [height, setHeight] = React.useState<number>();
    const data = React.useMemo<number[]>(() => parseSparklineData(cell.value), [cell.value]);

    React.useLayoutEffect(() => {
        setHeight(cellEl?.offsetHeight);
    }, [cellEl]);

    const cellStyle = React.useMemo(
        () => ({
            ...cell.style,
            padding: 0,
            margin: 0,
            width,
            height: `${height}px`,
        }),
        [cell.style, width, height]
    );

    const lineStyle = React.useMemo(
        () => ({
            paddingLeft: `${paddingLeftRight}px`,
            paddingTop: `${paddingTopBottom}px`,
            width: width === 'auto' ? `${cellEl?.offsetWidth - paddingLeftRight * 2}px` : `${width}px`,
            height: `${height - paddingTopBottom * 2}px`,
        }),
        [height, width, cellEl]
    );

    return (
        <SUITable.Cell
            style={cellStyle}
            align={cell.align}
            elementRef={setEl}
            data-test-cell-type="SparklineCell"
            data-row-index={cell.rowIndex}
            data-col-index={cell.columnIndex}
            onClick={useMouseOverEffect ? noop : undefined}
        >
            {height ? (
                <Line
                    style={lineStyle}
                    data={data}
                    width={cellEl?.offsetWidth - paddingLeftRight * 2}
                    height={height - paddingTopBottom * 2}
                    isArea={cell.sparklineType === 'area'}
                    acceptNull
                    lineColor={cell.sparklineColor}
                    fillColor={cell.sparklineAreaColor}
                />
            ) : (
                dummySVG
            )}
        </SUITable.Cell>
    );
};

SparklineCell.displayName = 'SparklineCell';
