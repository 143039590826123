import { COLOR_OR_TOKEN_PATTERN } from '@splunk/visualizations-shared/schemaUtils';

export default {
    toArrow: {
        type: 'boolean',
        default: false,
        description: 'Specify whether the line begins with an arrow.',
    },
    fromArrow: {
        type: 'boolean',
        default: false,
        description: 'Specify whether the line ends in an arrow.',
    },
    strokeColor: {
        anyOf: [
            {
                type: 'string',
                pattern: COLOR_OR_TOKEN_PATTERN,
                description:
                    'Specify the color of the line. The default for light mode is "#000000". The default for dark mode is "#FFFFFF"',
            },
            {
                type: 'string',
                pattern: '^>.*',
            },
        ],
    },
    strokeDataSeries: {
        type: 'string',
        pattern: '^>.*',
        description: 'Data series for dynamic line coloring.',
        default: '> primary | seriesByType("number")',
    },
    strokeDataPoint: {
        type: 'string',
        pattern: '^>.*',
        description: 'Data point that will map to the line color.',
        default: '> strokeDataSeries | lastPoint()',
    },
    strokeDasharray: {
        type: 'number',
        default: 0,
        description:
            'Specify the length, in pixels, of both the line segments and the spaces between the line segments when you choose break up a line so that it is not solid.',
    },
    strokeWidth: {
        type: 'number',
        default: 1,
        description: 'Specify the thickness, in pixels, of the line.',
    },
    strokeOpacity: {
        type: 'number',
        default: 1,
        description:
            'Specify a number less than one to increase the opacity of the line. For example, if you specify 0.50, the line will have an opacity that is half that of no opacity.',
    },
};
