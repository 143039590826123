import hash from 'hash-it';
import type { JSONSchemaType } from 'ajv';
import type { VisualizationDefinition } from '@splunk/dashboard-types';

/**
 * create test schema  for validation
 * @method createTestSchema
 * @param {Object} optionsSchema
 * @returns {Object} schema for validation
 */
export const createTestSchema = (
    options: JSONSchemaType<Record<string, unknown>>
): JSONSchemaType<Record<string, unknown>> => {
    return {
        $schema: 'http://json-schema.org/draft-07/schema#',
        $id: `http://www.splunk.com/dashboard.schema.json?id=${hash(options)}`,
        title: 'Dashboard Definition',
        description: 'Dashboard Definition',
        type: 'object',
        properties: options,
    };
};

export const createSimpleVizSchema = (
    options: JSONSchemaType<Record<string, unknown>>
): JSONSchemaType<VisualizationDefinition> => ({
    $id: `http://www.splunk.com/dashboard.vizSchema.json?id=${hash(options)}`,
    additionalProperties: false,
    title: 'Visualization Definition',
    description: 'Visualization Definition',
    type: 'object',
    properties: {
        context: { type: 'object', nullable: true },
        dataSources: {
            type: 'object',
            nullable: true,
            required: [],
            patternProperties: { '^[a-zA-Z0-9_-]*$': { type: 'string' } },
        },
        description: { type: 'string', nullable: true },
        encoding: { type: 'object', nullable: true },
        eventHandlers: {
            type: 'array',
            nullable: true,
            items: {
                type: 'object',
                properties: {
                    name: { type: 'string' },
                    options: { type: 'object', nullable: true },
                    type: { type: 'string' },
                },
                required: ['type'],
                additionalProperties: false,
            },
        },
        options: { type: 'object', properties: options, nullable: true },
        showLastUpdated: { type: 'boolean', nullable: true },
        showProgressBar: { type: 'boolean', nullable: true },
        title: { type: 'string', nullable: true },
        type: { type: 'string' },
        hideWhenNoData: { type: 'boolean', nullable: true },
    },
    required: ['type'],
});

export const createSimpleDSSchema = (
    options: Record<string, unknown>
): Record<string, unknown> => ({
    $id: `http://www.splunk.com/dashboard.dsSchema.json?id=${hash(options)}`,
    additionalProperties: false,
    title: 'DataSource Definition',
    description: 'DataSource Definition',
    type: 'object',
    properties: {
        type: { type: 'string' },
        options: { type: 'object', properties: options },
        name: { type: 'string' },
        meta: { type: 'object' },
    },
    required: ['type'],
});

export const createSimpleInputSchema = (
    options: Record<string, unknown>
): Record<string, unknown> => ({
    $id: `http://www.splunk.com/dashboard.inputSchema.json?id=${hash(options)}`,
    additionalProperties: false,
    title: 'Input Definition',
    description: 'Input Definition',
    type: 'object',
    properties: {
        dataSources: {
            patternProperties: { '^[a-zA-Z0-9_-]*$': { type: 'string' } },
        },
        encoding: { type: 'object' },
        options: { type: 'object', properties: options },
        context: { type: 'object' },
        title: { type: 'string' },
        type: { type: 'string' },
        canvasAlignment: {
            type: 'string',
            anyOf: [
                { enum: ['top', 'bottom', 'center'] },
                { pattern: '^[$].*[$]$' },
            ],
        },
        hideWhenNoData: { type: 'boolean', nullable: true },
    },
    required: ['type'],
});
