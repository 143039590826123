import * as React from 'react';

// Global provided via webpack
// eslint-disable-next-line no-underscore-dangle
declare const __VISION_VERSION__: string;

// @TODO(pwied): update when API finalized
export interface DataSourceApi {
    subscribe: (bindingType: string, callback: () => void) => () => void;
}
const DataSourceContext = React.createContext<DataSourceApi>({ subscribe: () => () => {} });
DataSourceContext.displayName = `data-splunk-visualization-version-DataSourceContext-${__VISION_VERSION__}`;

export const DataSourceContextProvider = DataSourceContext.Provider;
export const DataSourceContextConsumer = DataSourceContext.Consumer;
export default DataSourceContext;
