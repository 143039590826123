/* eslint-disable no-plusplus */
/* eslint-disable no-bitwise */
/**
 * Copied from https://github.com/ai/nanoid/blob/main/non-secure/index.js wth modifications
 * Removed '-' and '_' symbols from the dictionary as they are not supported in our schema
 */

// This alphabet uses `A-Za-z0-9` symbols.
// The order of characters is optimized for better gzip and brotli compression.
// References to the same file (works both for gzip and brotli):
// `'use`, `andom`, and `rict'`
// References to the brotli default dictionary:
// `-26T`, `1983`, `40px`, `75px`, `bush`, `jack`, `mind`, `very`, and `wolf`
const alphabet =
    'useandom26T198340PX75pxJACKVERYMINDBUSHWOLFGQZbfghjklqvwyzrict';

export const uniqueId = (size = 8) => {
    let id = '';
    // A compact alternative for `for (var i = 0; i < step; i++)`.
    let i = size;
    while (i--) {
        // `| 0` is more compact and faster than `Math.floor()`.
        id += alphabet[(Math.random() * 62) | 0];
    }
    return id;
};
