import { TypeSafeValue } from './TypeSafeValue';
import { DataFrame } from './DataFrame';
import { DataPoint } from './DataPoint';
import { DataSeries } from './DataSeries';
import { IDimension } from './Dimensions';

export interface IDataPoint<T extends DataType> {
    getValue(): TypeSafeValue<T>;
}

export interface IDataSeries<T extends DataType> {
    points: IDataPoint<T>[];
}

export interface IDataFrame<T extends DataType> {
    series: IDataSeries<T>[];
}

export interface DataPrimitive<T extends DataType = DataType> {
    /**
     * set the value
     * @param {TypedValue<T>} v
     */
    setValue(v: TypedValue<T>): void;
}

export interface TypedValue<T extends DataType = DataType> {
    type: T;
    value: any;
}

export type DataType =
    | 'number'
    | 'string'
    | 'color'
    | 'time'
    | 'unknown'
    | 'sparkline'
    | 'array'
    | 'null'
    | 'geojson';

export function isDataPrimitive(o: any): o is IDimension {
    return DataFrame.isDataFrame(o) || DataSeries.isDataSeries(o) || DataPoint.isDataPoint(o);
}
