import type {
    AbsoluteLayoutItem,
    InputDefinition,
    SearchData,
    VisualizationDefinition,
} from '@splunk/dashboard-types';

type ShouldHideItemProps = {
    item: AbsoluteLayoutItem;
    definition: VisualizationDefinition | InputDefinition;
    searchData: SearchData;
};

export const shouldHideItem = ({
    item,
    definition,
    searchData,
}: ShouldHideItemProps): boolean => {
    if (item.type === 'line' || !definition.hideWhenNoData) {
        return false;
    }

    if (searchData === undefined || (searchData.meta?.totalCount ?? 0) === 0) {
        return true;
    }

    return false;
};
