import * as React from 'react';
import { isColor } from '@splunk/visualizations-shared/colorUtils';
import variables from '@splunk/themes/variables';
import IconPlaceholder, { shouldShowIconPlaceholder } from '../components/IconPlaceholder';
import { VizBehavior } from '../interfaces/VizBehavior';
import { DashboardVizProps } from '../interfaces/DashboardVizProps';
import { DataSource } from '../interfaces/DataSource';
import { ExtendedDashViz } from '../interfaces/ExtendedDashViz';
import { extendStaticWrapperProps } from '../utils/hocUtils';

type useIconPlaceholderType = (
    dataSources: { [name: string]: DataSource },
    loading: boolean,
    options: Record<string, unknown>
) => boolean;

const withPlaceholder = (
    Visualization: ExtendedDashViz,
    useIconPlaceholder: useIconPlaceholderType = shouldShowIconPlaceholder
): ExtendedDashViz => {
    const { config } = Visualization;
    const { themes } = config;

    const Wrapper = (props: DashboardVizProps): React.ReactElement<DashboardVizProps> => {
        const { dataSources, loading, options, height, width } = props;

        const showPlaceholder = useIconPlaceholder(dataSources, loading, options);
        const bgColorFromTheme = isColor(options?.backgroundColor)
            ? options?.backgroundColor
            : themes?.defaultBackgroundColor?.(props);

        const icon = config.placeholderIcon ?? config.icon;
        const iconColor = variables.contentColorActive(props);

        if (config.supports.includes(VizBehavior.PLACEHOLDER) && showPlaceholder) {
            return (
                <IconPlaceholder
                    data-test="icon-placeholder"
                    icon={icon}
                    width={width}
                    height={height}
                    backgroundColor={bgColorFromTheme}
                    iconColor={iconColor}
                />
            );
        }

        return <Visualization {...props} />;
    };
    extendStaticWrapperProps({ Wrapper, Visualization });
    return Wrapper as ExtendedDashViz;
};

export default withPlaceholder;
