import React, { useContext } from 'react';
import type { DashboardApi } from '@splunk/dashboard-types';

export const DashboardApiContext = React.createContext<DashboardApi | null>(
    null
);

export const DashboardApiProvider = DashboardApiContext.Provider;
export const DashboardApiConsumer = DashboardApiContext.Consumer;

// By checking if the context is null we'll know if we're not within a DashboardApiProvider
export const useDashboardApi = (): DashboardApi => {
    const ctx = useContext(DashboardApiContext);
    if (ctx === null) {
        throw new Error(
            'useDashboardApi can only be used in a component that is a child of a DashboardApiProvider'
        );
    }

    return ctx;
};
