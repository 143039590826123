import type { VisualizationDefinition } from '@splunk/dashboard-types';

export const getHasDrilldown = (
    eventHandlers?: VisualizationDefinition['eventHandlers']
): boolean => {
    if (!Array.isArray(eventHandlers)) {
        return false;
    }
    // eventHandlers has to be an array here, we don't have to keep looping once we find something.
    return !!eventHandlers.find((eventHandler) => {
        return /^drilldown/.test(eventHandler.type);
    });
};
