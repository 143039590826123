import { chain } from 'lodash';
// Charting imports i18n which needs to be run to instantiate window globals in the tests
// eslint-disable-next-line import/order
import Charting, { ChartingProps } from '../common/Charting';
import jsCharting from '@splunk/charting-bundle';
import { getSimpleXMLOptionsForProps } from '@splunk/visualizations-shared/simpleXMLOptions';
import config from './config';
import withDashboardViz from '../common/withDashboardViz';
import { convertToScatterSCProperties } from './scatterUtils';
import { truncateData } from '../common/chartingUtils';

const validOptions = Object.keys(config.optionsSchema);

export const computeVizProps: any = ({
    dataSources,
    onEventTrigger,
    visualizationApiRef,
    x,
    y,
    category,
    xField,
    yField,
    categoryField,
    ...otherOptions
}): Partial<ChartingProps> => {
    // options to be passed as props
    const computedProps: Record<string, any> = { visualizationApiRef };
    const { hasEventHandlers } = otherOptions;

    computedProps.options = chain(otherOptions)
        .pick(validOptions)
        .thru(convertToScatterSCProperties)
        .thru(options => ({ ...getSimpleXMLOptionsForProps(options), chart: 'scatter' }))
        .value();

    let primaryData = { ...dataSources.primary.data };

    const columns = [x.map(String), y.map(String)];
    const fields = [{ name: xField }, { name: yField }];

    if (category && categoryField) {
        columns.unshift(category.map(String));
        fields.unshift({ name: categoryField });
    }

    primaryData.columns = columns;
    primaryData.fields = fields;

    const truncationLimit = computedProps.options['chart.resultTruncationLimit'];
    if (truncationLimit) {
        primaryData = truncateData(primaryData, truncationLimit);
    }

    computedProps.chartData = jsCharting.extractChartReadyData({
        ...primaryData,
    });

    if (hasEventHandlers) {
        computedProps.onClick = event => {
            onEventTrigger({ ...event, payload: { ...event.payload, name: yField } });
        };
    }

    return computedProps;
};

const Column = withDashboardViz({
    ReactViz: Charting,
    computeVizProps,
    vizConfig: config,
});
const themes = null;

export { themes, config };
export default Column;
