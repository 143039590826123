/* eslint-disable no-sequences */
import * as React from 'react';
import { sanitizeArrayOfColors } from '@splunk/visualizations-shared/colorUtils';
import { getFieldsFromDSL } from '@splunk/visualization-encoding/utils/dsl';
import { deepMergeWithArrayPrimitiveOverrides } from '@splunk/visualizations-shared/hocUtils';
import { pickFieldFromJSONSchema } from '@splunk/visualizations-shared/JSONSchemaUtils';
import { DataSource } from '../common/interfaces/DataSource';
import PureMap from './PureMap/PureMap';
import config from './config';
import withDashboardViz from '../common/withDashboardViz';
import { columnsNullToZeros } from '../common/utils/mapUtils';
import { GEO_BOUND_PREFIX, GEO_LAT_AND_LONG_FIELD } from './PureMap/BubbleLayer';
import { convertToArrOfObjects } from '../common/utils/dataUtils';

const formatLayer = (l, dataFields, dataSourceData, transformedData) => {
    // add key `geobin` to the layer object if the data is returned from geostats commands
    // to distinguish geostats commands from table commands
    const geobinIdx = dataFields.indexOf('geobin');
    const layer = l;

    if (geobinIdx > -1) {
        layer.geobin = dataSourceData.columns[geobinIdx];
    }
    // similarly add key `geom` to layer object for choropleths
    const geomIdx = dataFields.indexOf('geom');
    if (geomIdx > -1) {
        layer.geom = dataSourceData.columns[geomIdx].map(geomItem => {
            return typeof geomItem === 'string' ? JSON.parse(geomItem) : geomItem;
        });
    }
    // Sanitize colors within layers
    if (Object.prototype.hasOwnProperty.call(layer, 'seriesColors')) {
        layer.seriesColors = Array.isArray(layer.seriesColors)
            ? sanitizeArrayOfColors(layer.seriesColors)
            : undefined;
    }
    if (Object.prototype.hasOwnProperty.call(layer, 'dataColors')) {
        layer.dataColors = Array.isArray(layer.dataColors)
            ? sanitizeArrayOfColors(layer.dataColors)
            : undefined;
    }
    layer.metadata = transformedData;
};

export const computeVizProps = (props: {
    themeFunc: any;
    theme: any;
    dataSources: any;
    layers: any;
    onOptionsChange?: any;
}): any => {
    const { themeFunc, theme, layers, dataSources, onOptionsChange } = props;
    const themeColorVariables = ['controlBackgroundColor', 'controlForegroundColor'];
    const themeColors: Record<string, unknown> = {};
    themeColorVariables.forEach(variable => {
        themeColors[variable] = themeFunc(variable);
    });
    // Markers use different icons, Bubbles has different styles for light and dark theme
    // this prop is passed for that
    const themeColorScheme = theme?.splunkThemeV1 ? theme?.splunkThemeV1.colorScheme : 'dark';

    const dataSourceData = dataSources?.primary?.data ?? {};
    // transforms data structure into array of objects to allow for indexing metadata at layer level
    // TODO: explore any potential changes when multiple layers are supported
    const transformedData = convertToArrOfObjects(dataSourceData.columns, dataSourceData.fields);

    const dataFields = (dataSourceData.fields ?? []).map(field =>
        typeof field === 'string' ? field : field.name
    );

    layers?.forEach(l => formatLayer(l, dataFields, dataSourceData, transformedData));

    return { ...themeColors, themeColorScheme, ...layers, onOptionsChange };
};

const withTooltipFields = Viz => {
    const VizWithTooltipFields = ({ dataSources, options, ...otherProps }): JSX.Element => {
        if (!dataSources || !dataSources.primary || !dataSources.primary.data) {
            return <Viz {...otherProps} options={options} />;
        }
        const mergedOptions = deepMergeWithArrayPrimitiveOverrides(
            {},
            options,
            pickFieldFromJSONSchema(config.optionsSchema, 'default')
        );
        if (mergedOptions.layers?.[0]) {
            if (mergedOptions.layers?.[0]?.type === 'bubble') {
                mergedOptions.layers[0].bubbleSizeFields = getFieldsFromDSL(
                    mergedOptions.layers[0].bubbleSize ?? mergedOptions.layers[0].bubbleSize,
                    dataSources
                );
            }
            const { fields } = dataSources.primary.data;
            if (
                Array.isArray(mergedOptions.layers[0].additionalTooltipFields) &&
                Array.isArray(fields) &&
                fields.length
            ) {
                const { bubbleSizeFields = [] } = mergedOptions.layers[0];
                mergedOptions.layers[0].additionalTooltipFields = mergedOptions.layers[0].additionalTooltipFields.filter(
                    (field: string) =>
                        (typeof fields[0] === 'string'
                            ? fields.includes(field)
                            : fields.map((f: { name: string }) => f.name).includes(field)) &&
                        !bubbleSizeFields.includes(field)
                );
            }
        }
        return <Viz dataSources={dataSources} options={mergedOptions} {...otherProps} />;
    };
    VizWithTooltipFields.propTypes = Viz.propTypes;
    VizWithTooltipFields.defaultProps = Viz.defaultProps;
    VizWithTooltipFields.config = Viz.config;
    return VizWithTooltipFields;
};

// HOC for stripping the internal fields for Maps
const withoutInternalFields = Viz => {
    // eslint-disable-next-line react/display-name
    const VizWithoutInternalFields = ({ dataSources, ...otherProps }) => {
        if (!dataSources || !dataSources.primary || !dataSources.primary.data) {
            return <Viz {...otherProps} />;
        }
        const { primary } = dataSources;
        const { columns, fields } = primary.data;
        let newColumns = [];
        let newFields = [];
        if (fields && columns && fields.length === columns.length) {
            // computed sorted index for removal
            const indicesToRemove = new Set(
                fields.reduce((arr, e, i) => {
                    const field = typeof e === 'string' ? e : e.name.toString();
                    if (
                        field.startsWith('_') &&
                        !field.startsWith(GEO_BOUND_PREFIX) &&
                        !GEO_LAT_AND_LONG_FIELD.includes(field)
                    ) {
                        arr.push(i);
                    }
                    return arr;
                }, [])
            );
            newFields = fields.filter((d, i) => !indicesToRemove.has(i));
            newColumns = columns.filter((d, i) => !indicesToRemove.has(i));
        }
        const modifiedDatasources = {
            ...dataSources,
            primary: {
                ...primary,
                data: {
                    ...primary.data,
                    columns: newColumns,
                    fields: newFields,
                },
            },
        };
        return <Viz dataSources={modifiedDatasources} {...otherProps} />;
    };
    VizWithoutInternalFields.propTypes = Viz.propTypes;
    VizWithoutInternalFields.defaultProps = Viz.defaultProps;
    VizWithoutInternalFields.config = Viz.config;
    return VizWithoutInternalFields;
};

// HOC for updating all null values in data columns to zeros
// excluding 'latitude', 'longitude' and 'geobin'
const withNullToZeros = Viz => {
    // eslint-disable-next-line react/display-name
    const VizWithColumnsNullToZeros = ({ dataSources, options, ...otherProps }) => {
        if (!dataSources || !dataSources.primary || !dataSources.primary.data) {
            return <Viz options={options} {...otherProps} />;
        }
        const modifiedDatasources = columnsNullToZeros(config, dataSources, options);
        return <Viz dataSources={modifiedDatasources} options={options} {...otherProps} />;
    };
    VizWithColumnsNullToZeros.propTypes = Viz.propTypes;
    VizWithColumnsNullToZeros.defaultProps = Viz.defaultProps;
    VizWithColumnsNullToZeros.config = Viz.config;
    return VizWithColumnsNullToZeros;
};

export function useIconPlaceholder(dataSources: { [name: string]: DataSource }, loading: boolean): boolean {
    return loading;
}

const Map = withoutInternalFields(
    withTooltipFields(
        withNullToZeros(
            withDashboardViz({
                ReactViz: PureMap,
                vizConfig: config,
                computeVizProps,
                disableEditModeCover: true,
                useIconPlaceholder,
            })
        )
    )
);
const { themes } = config;
export default Map;
export { themes, config, PureMap };
