import React from 'react';
import { noop } from '@splunk/dashboard-utils';
import Button from '@splunk/react-ui/Button';
import { _ } from '@splunk/ui-utils/i18n';

export interface SubmitButtonProps {
    disabled?: boolean;
    label?: string;
    onClick?: typeof noop;
    [key: string]: unknown;
}

const SubmitButton = ({
    disabled = false,
    label = _('Submit'),
    onClick = noop,
    ...rest
}: SubmitButtonProps) => (
    <Button
        {...rest}
        disabled={disabled}
        label={label}
        appearance="primary"
        onClick={onClick}
    />
);

export default SubmitButton;
