import React from 'react';
import { _ } from '@splunk/ui-utils/i18n';
import SVG, {
    type SVGPropsOptionalViewBox,
} from '@splunk/react-icons/SVGEnterprise';

/* eslint-disable max-len */
export default function AlignCenterHorizontal(
    props: SVGPropsOptionalViewBox
): ReturnType<typeof SVG> {
    return (
        <SVG
            viewBox="0 0 23 24"
            screenReaderText={_('Align Center Horizontal')}
            {...props}
        >
            <path
                fill="currentColor"
                d="M11 23.5L11 0.5C11 0.223858 10.7761 1.35705e-07 10.5 8.74228e-08C10.2239 3.91405e-08 10 0.223857 10 0.5L10 23.5C10 23.7761 10.2239 24 10.5 24C10.7761 24 11 23.7761 11 23.5Z"
            />
            <path
                fill="currentColor"
                d="M16.5 13L4.5 13C4.22386 13 4 13.2239 4 13.5L4 19.5C4 19.7761 4.22386 20 4.5 20L16.5 20C16.7761 20 17 19.7761 17 19.5L17 13.5C17 13.2239 16.7761 13 16.5 13Z"
            />
            <path
                fill="currentColor"
                d="M20.5 4L0.5 4C0.223858 4 -3.91405e-08 4.22386 -8.74228e-08 4.5L-1.1365e-06 10.5C-1.18478e-06 10.7761 0.223857 11 0.499999 11L20.5 11C20.7761 11 21 10.7761 21 10.5L21 4.5C21 4.22386 20.7761 4 20.5 4Z"
            />
        </SVG>
    );
}
