import { _ } from '@splunk/ui-utils/i18n';
import type { DashboardJSON, StructureItem } from '@splunk/dashboard-types';
import type { ValidationErrors } from '../types';
import {
    GLOBAL_INPUT_NONEXISTENT,
    GLOBAL_INPUT_NOT_PRESENT,
    DUPLICATE_INPUT,
} from './messages';
import { isEmpty } from './isEmpty';

export const checkInputsInStructure = (
    definition: DashboardJSON
): ValidationErrors => {
    const { inputs = {}, layout: { globalInputs = [], structure = [] } = {} } =
        definition;

    const errors: ValidationErrors = [];

    Object.keys(inputs).forEach((inputId) => {
        if (
            !globalInputs?.includes(inputId) &&
            (structure as StructureItem[])?.findIndex(
                ({ item }) => item === inputId
            ) === -1
        ) {
            errors.push({
                instancePath: GLOBAL_INPUT_NOT_PRESENT,
                message: _(
                    `Input "${inputId}" is defined but not used in either the Global Inputs Structure or in the Layout Structure`
                ),
            });
        }
    });

    globalInputs?.forEach((inputId, idx) => {
        if (isEmpty(inputs[inputId])) {
            errors.push({
                instancePath: GLOBAL_INPUT_NONEXISTENT,
                message: _(
                    `Input item ${idx} references an input "${inputId}" that does not exist`
                ),
            });
        }
        if (
            (structure as StructureItem[])?.findIndex(
                ({ item }) => item === inputId
            ) !== -1
        ) {
            errors.push({
                instancePath: DUPLICATE_INPUT,
                message: _(
                    `Input "${inputId}" exists in both layout structure and global inputs`
                ),
            });
        }
    });

    return errors;
};
