import React from 'react';
import styled from 'styled-components';
import { variables } from '@splunk/themes';
import { PaddedContainer } from './PaddedContainer';

export const OptionContainer = PaddedContainer;
export const OptionLabel = styled.div`
    padding-bottom: ${variables.spacingXSmall};
    font-weight: ${variables.fontWeightSemiBold};
    font-size: ${variables.fontSize};
    display: flex;
    justify-content: space-between;
`;

export const OptionContent = styled.div``;

export interface OptionProps {
    label: React.ReactNode;
    children: React.ReactNode;
    depends?: () => boolean;
    'data-test'?: string;
}

export const Option = ({
    label,
    children,
    depends = () => true,
    'data-test': dataTestId,
}: OptionProps) => {
    if (!depends()) {
        return null;
    }
    return (
        <PaddedContainer data-test="option" data-test-id={dataTestId}>
            <OptionLabel data-test="option-label">{label}</OptionLabel>
            <OptionContent data-test="option-content">{children}</OptionContent>
        </PaddedContainer>
    );
};

export default Option;
