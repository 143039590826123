/* eslint-disable react/no-unused-prop-types */
import T from 'prop-types';
import { noop } from '@splunk/dashboard-utils';
import { COLOR_OR_TOKEN_PATTERN } from '@splunk/visualizations-shared/schemaUtils';

const BaseInput = () => null;

BaseInput.propTypes = {
    // input id
    id: T.string,
    // input title
    title: T.string,
    // dynamic options context
    context: T.object,
    // input format options
    options: T.object,
    // current value, separate by comma input supports multiple value, for example 'foo,bar'
    value: T.string,
    // datasource state which include data and request params, object key indicate the datasource type.
    dataSources: T.objectOf(
        T.shape({
            /**
             * current request params
             */
            requestParams: T.object,
            /**
             * current dataset
             */
            data: T.shape({
                fields: T.array,
                columns: T.array,
            }),
            /**
             * error
             */
            error: T.shape({
                level: T.string,
                message: T.string,
            }),
            /**
             * meta data that came with the dataset
             */
            meta: T.object,
        })
    ),
    /**
     * DEPRECATED: Description the fields and properties of the datasource used when retrieving data
     */
    encoding: T.object,
    /**
     * A callback to trigger event
     */
    onValueChange: T.func,
    /**
     * Indicates whether the input is disabled
     */
    isDisabled: T.bool,
    /**
     * Message to show in disabled state
     */
    disabledMessage: T.string,
    /**
     * Indicates whether there is errors in the input
     */
    isError: T.bool,
    /**
     * Message to show in error state
     */
    errorMessage: T.string,
    /**
     * Indicates whether the input is selected
     */
    isSelected: T.bool,
    /**
     * input container width in pixel
     */
    width: T.number,
    /**
     * indicates whether datasource is still fetching data and hasn't had errors
     */
    loading: T.bool,
    /**
     * dashboard mode - edit/view
     */
    mode: T.string,
    /**
     * Dynamically update the width of the HOC
     */
    onUpdateWidth: T.func,
    /**
     * A callback to trigger input removal from definition
     */
    onRemove: T.func,
    /**
     * DEPRECATED: indicates whether the input should skip using encoding. This is useful when the input options contains dynamic options
     */
    skipEncoding: T.bool,
};

BaseInput.config = {
    /**
     * default data contract
     */
    dataContract: {
        requiredDataSources: ['primary'],
        initialRequestParams: {
            primary: { offset: 0, count: 100 },
        },
    },
};

BaseInput.defaultProps = {
    context: {},
    options: {},
    onValueChange: noop,
    isDisabled: false,
    isError: false,
    isSelected: false,
    width: 180,
    onUpdateWidth: noop,
    onRemove: noop,
    skipEncoding: false,
};

export const baseInputSchema = {
    token: {
        description: 'Name of the token that will store the value',
        type: 'string',
    },
    tokenNamespace: {
        description: 'Scope in which the token exists',
        type: 'string',
        default: 'default',
    },
    backgroundColor: {
        description:
            'The background color of the input field if it is on the canvas',
        type: 'string',
        default: 'transparent',
        pattern: COLOR_OR_TOKEN_PATTERN,
    },
};

BaseInput.valueToTokens = () => ({});
export default BaseInput;
