import React from 'react';
import styled, { css } from 'styled-components';
import SplunkUIMessage from '@splunk/react-ui/Message';

const centerDiv = css`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
`;

interface MessageContainerProps {
    centered: boolean;
}

const MessageContainer = styled.div<MessageContainerProps>`
    ${(props) => (props.centered ? centerDiv : null)}
    font-size: 15px;
`;

export interface MessageProps {
    level?: 'info' | 'warning' | 'error';
    message?: string;
    centered?: boolean;
}

const Message = ({
    level = 'info',
    message = '',
    centered = true,
}: MessageProps): JSX.Element => (
    <MessageContainer data-test="dashboard-message" centered={centered}>
        <SplunkUIMessage type={level}>{message}</SplunkUIMessage>
    </MessageContainer>
);

export default Message;
