import * as React from 'react';
import styled from 'styled-components';
import * as T from 'prop-types';
import { pick, uniq, noop } from 'lodash';

import SizeAwareWrapper from '@splunk/visualizations-shared/SizeAwareWrapper';
import { toDimension } from '@splunk/visualizations-shared/style';
import { CATEGORICAL } from '@splunk/visualization-color-palettes';

import {
    AXIS_ORIENTATION_TYPES,
    BUBBLE_ROW_SCALE_TYPES,
    BUBBLE_SCALE_TYPES,
    COLOR_MODE_TYPES,
    BUBBLE_LABELS_TYPES,
    GRAYSCALE_FILTER_ID,
    getChartAreaDimensions,
    getColorCategoryMapping,
    getScales,
} from './src/utils/punchcardUtils';

import { sortAxis } from './src/utils/sortUtils';

import PunchcardAxis from './src/PunchcardAxis';
import PunchcardChartArea from './src/PunchcardChartArea';
import PunchcardLegend from './src/PunchcardLegend';
import PunchcardGridLines from './src/PunchcardGridLines';

const Container = styled.div<{
    width: string | number;
    height: string | number;
    backgroundColor: string;
}>`
    cursor: default;
    overflow: hidden;
    ${props => toDimension(pick(props, ['width', 'height']))};
    background-color: ${props => props.backgroundColor};
`;

const PunchcardWrapper = styled.div<{
    backgroundColor: string;
}>`
    display: flex;
    justify-content: center;
    flex-direction: row;
    width: 100%;
    height: 100%;
    background-color: ${props => props.backgroundColor};
`;

const PunchcardContainer = styled.div<{
    width: string | number;
    height: string | number;
}>`
    overflow: hidden;
    position: relative;
    ${props => toDimension(pick(props, ['width', 'height']))};
`;

const PunchcardSVG = styled.svg<{
    width: string | number;
    height: string | number;
}>`
    ${props => toDimension(pick(props, ['width', 'height']))};
`;

export interface PunchcardProps {
    backgroundColor: string;
    showBubbleLabels: BUBBLE_LABELS_TYPES;
    bubbleRowScale: BUBBLE_ROW_SCALE_TYPES;
    bubbleScale: BUBBLE_SCALE_TYPES;
    category: string[];
    categoryField: string;
    colorMode: COLOR_MODE_TYPES;
    seriesColors: string[];
    bubbleColor: string | string[];
    minBubbleColorIntensity: number;
    height: string | number;
    isBubbleSizeDynamic: boolean;
    minBubbleSize: number;
    maxBubbleSize: number;
    maxBubbleRadius: number;
    minBubbleRadius: number;
    useDefaultSort: boolean;
    showMaxValuePulsation: boolean;
    showLegend: boolean;
    size: number[];
    sizeField: string;
    width: string | number;
    x: (string | number)[];
    y: (string | number)[];
    xField: string;
    yField: string;
    onPointClick?: (...args: any) => void;
    onLegendClick?: (...args: any) => void;
}

const Punchcard = (props: PunchcardProps): React.ReactElement => {
    const [selectedX, setSelectedX] = React.useState(null);
    const [selectedY, setSelectedY] = React.useState(null);
    const [selectedCategory, setSelectedCategory] = React.useState(null);

    const handleMouseLeave = () => {
        setSelectedX(null);
        setSelectedY(null);
        setSelectedCategory(null);
    };

    const handleLegendHover = category => {
        setSelectedX(null);
        setSelectedY(null);
        setSelectedCategory(category);
    };

    const handleXAxisHover = xValue => {
        setSelectedX(xValue);
        setSelectedY(null);
        setSelectedCategory(null);
    };

    const handleYAxisHover = yValue => {
        setSelectedX(null);
        setSelectedY(yValue);
        setSelectedCategory(null);
    };

    const handleBubbleHover = (xValue, yValue, category) => {
        setSelectedX(xValue);
        setSelectedY(yValue);
        setSelectedCategory(category);
    };

    const {
        backgroundColor,
        showBubbleLabels,
        bubbleRowScale,
        bubbleScale,
        category,
        categoryField,
        colorMode,
        seriesColors,
        bubbleColor,
        minBubbleColorIntensity,
        height,
        showMaxValuePulsation,
        isBubbleSizeDynamic,
        minBubbleSize,
        maxBubbleSize,
        maxBubbleRadius,
        minBubbleRadius,
        useDefaultSort,
        showLegend,
        size,
        sizeField,
        width,
        x,
        y,
        xField,
        yField,
        onPointClick,
        onLegendClick,
    } = props;
    const calculatedColorMode = category ? colorMode : COLOR_MODE_TYPES.SEQUENTIAL;
    const displayLegend = showLegend && calculatedColorMode === COLOR_MODE_TYPES.CATEGORICAL;
    const uniqX = useDefaultSort ? sortAxis(uniq(x.map(v => `${v}`))) : uniq(x);
    const uniqY = useDefaultSort ? sortAxis(uniq(y.map(v => `${v}`))) : uniq(y);
    const uniqCategory = uniq(category);
    const colorCategoryMapping = getColorCategoryMapping({
        category: uniqCategory,
        colorMode: calculatedColorMode,
        seriesColors,
    });

    const handlePointClick = (sizeValue: number): void => {
        const rowData = {
            [`row.${xField}.value`]: selectedX,
            [`row.${yField}.value`]: selectedY,
            [`row.${sizeField}.value`]: sizeValue,
        };
        if (categoryField) rowData[`row.${categoryField}.value`] = selectedCategory;
        onPointClick({
            type: 'point.click',
            payload: {
                value: selectedY,
                name: yField,
                ...rowData,
            },
        });
    };

    const handleLegendClick = (): void => {
        onLegendClick({
            type: 'legend.click',
            payload: {
                name: yField,
            },
        });
    };

    const renderVisualization = ({ width: containerWidth, height: containerHeight }) => {
        const {
            xAxisStartY,
            yAxisStartX,
            chartAreaStartX,
            chartAreaStartY,
            chartAreaEndX,
            chartAreaEndY,
            legendStartX,
        } = getChartAreaDimensions({
            containerWidth,
            containerHeight,
            displayLegend,
        });
        const { scale: xScale, positionScale: xPositionScale } = getScales({
            values: uniqX,
            start: chartAreaStartX,
            end: chartAreaEndX,
        });
        const { scale: yScale, positionScale: yPositionScale } = getScales({
            values: uniqY,
            start: chartAreaStartY,
            end: chartAreaEndY,
        });

        const minBand = Math.min(xScale.bandwidth(), yScale.bandwidth());

        return (
            <PunchcardWrapper backgroundColor={backgroundColor}>
                <PunchcardContainer width={containerWidth} height={containerHeight}>
                    <PunchcardSVG width={containerWidth} height={containerHeight}>
                        <PunchcardGridLines
                            xStartPosition={xPositionScale(uniqX[0])}
                            xEndPosition={xPositionScale(uniqX[uniqX.length - 1])}
                            yStartPosition={yPositionScale(uniqY[0])}
                            yEndPosition={yPositionScale(uniqY[uniqY.length - 1])}
                            xDelta={xScale.bandwidth()}
                            yDelta={yScale.bandwidth()}
                        />
                        <PunchcardAxis
                            labels={uniqX}
                            orientation={AXIS_ORIENTATION_TYPES.HORIZONTAL}
                            handleMouseEnter={handleXAxisHover}
                            handleMouseLeave={handleMouseLeave}
                            startPositionX={chartAreaStartX}
                            startPositionY={xAxisStartY}
                            scale={xScale}
                            selected={selectedX}
                        />
                        <PunchcardAxis
                            labels={uniqY}
                            orientation={AXIS_ORIENTATION_TYPES.VERTICAL}
                            handleMouseEnter={handleYAxisHover}
                            handleMouseLeave={handleMouseLeave}
                            startPositionX={yAxisStartX}
                            startPositionY={chartAreaStartY}
                            scale={yScale}
                            selected={selectedY}
                        />
                        {displayLegend && (
                            <PunchcardLegend
                                category={uniqCategory}
                                colorCategoryMapping={colorCategoryMapping}
                                handleMouseEnter={handleLegendHover}
                                handleMouseLeave={handleMouseLeave}
                                selected={selectedCategory}
                                startPositionX={legendStartX}
                                startPositionY={yPositionScale(uniqY[0])}
                                containerWidth={containerWidth}
                                containerHeight={containerHeight}
                                handleLegendClick={handleLegendClick}
                            />
                        )}
                        <PunchcardChartArea
                            showBubbleLabels={showBubbleLabels}
                            bubbleRowScale={bubbleRowScale}
                            bubbleScale={bubbleScale}
                            category={category}
                            colorCategoryMapping={colorCategoryMapping}
                            colorMode={calculatedColorMode}
                            bubbleColor={bubbleColor}
                            minBubbleColorIntensity={minBubbleColorIntensity}
                            handleMouseEnter={handleBubbleHover}
                            handleMouseLeave={handleMouseLeave}
                            isBubbleSizeDynamic={isBubbleSizeDynamic}
                            minBand={minBand}
                            minBubbleSize={minBubbleSize}
                            maxBubbleSize={maxBubbleSize}
                            minBubbleRadius={minBubbleRadius}
                            maxBubbleRadius={maxBubbleRadius}
                            showMaxValuePulsation={showMaxValuePulsation}
                            selectedCategory={selectedCategory}
                            selectedX={selectedX}
                            selectedY={selectedY}
                            size={size}
                            x={x}
                            xScale={xPositionScale}
                            y={y}
                            yScale={yPositionScale}
                            handlePointClick={handlePointClick}
                        />
                        <filter id={GRAYSCALE_FILTER_ID}>
                            <feColorMatrix
                                type="matrix"
                                values="0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0"
                            />
                        </filter>
                    </PunchcardSVG>
                </PunchcardContainer>
            </PunchcardWrapper>
        );
    };

    return (
        <Container backgroundColor={backgroundColor} height={height} width={width}>
            <SizeAwareWrapper>
                {containerDimension => renderVisualization(containerDimension)}
            </SizeAwareWrapper>
        </Container>
    );
};

const propTypes: Record<keyof PunchcardProps, T.Validator<any>> = {
    backgroundColor: T.string,
    showBubbleLabels: T.oneOf(Object.values(BUBBLE_LABELS_TYPES)),
    bubbleRowScale: T.oneOf(Object.values(BUBBLE_ROW_SCALE_TYPES)),
    bubbleScale: T.oneOf(Object.values(BUBBLE_SCALE_TYPES)),
    category: T.arrayOf(T.string),
    categoryField: T.string,
    colorMode: T.oneOf(Object.values(COLOR_MODE_TYPES)),
    seriesColors: T.arrayOf(T.string),
    bubbleColor: T.oneOfType([T.string, T.arrayOf(T.string)]),
    minBubbleColorIntensity: T.number,
    height: T.oneOfType([T.string, T.number]),
    isBubbleSizeDynamic: T.bool,
    minBubbleSize: T.number,
    maxBubbleSize: T.number,
    maxBubbleRadius: T.number,
    minBubbleRadius: T.number,
    useDefaultSort: T.bool,
    showMaxValuePulsation: T.bool,
    showLegend: T.bool,
    sizeField: T.string,
    width: T.oneOfType([T.string, T.number]),
    // hotfix: isRequired check is removed here for x, y and size props
    // to remove console warnings on early return of this component and
    // implemented in config file using requiredProps
    x: T.arrayOf(T.oneOfType([T.string, T.number])),
    y: T.arrayOf(T.oneOfType([T.string, T.number])),
    size: T.arrayOf(T.number),
    xField: T.string,
    yField: T.string,
    onPointClick: T.func,
    onLegendClick: T.func,
};

const defaultProps: Record<keyof PunchcardProps, any> = {
    backgroundColor: 'transparent',
    showBubbleLabels: 'all',
    bubbleRowScale: 'global',
    bubbleScale: 'area',
    category: null,
    categoryField: null,
    colorMode: 'categorical',
    seriesColors: CATEGORICAL,
    bubbleColor: '#7B56DB',
    minBubbleColorIntensity: 0.4,
    showMaxValuePulsation: true,
    height: 500,
    isBubbleSizeDynamic: true,
    minBubbleSize: 0.25,
    maxBubbleSize: 1,
    maxBubbleRadius: 15,
    minBubbleRadius: 1,
    useDefaultSort: false,
    showLegend: true,
    size: null,
    sizeField: null,
    width: '100%',
    x: null,
    y: null,
    xField: null,
    yField: null,
    onPointClick: noop,
    onLegendClick: noop,
};

Punchcard.propTypes = propTypes;
Punchcard.defaultProps = defaultProps;

export default Punchcard;
