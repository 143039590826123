type methods =
    | 'log'
    | 'debug'
    | 'info'
    | 'warn'
    | 'error'
    | 'group'
    | 'groupCollapsed'
    | 'groupEnd';
const hasWindowConsole = typeof window !== 'undefined' && 'console' in window;
const hasConsoleMethod = (method: methods): boolean =>
    hasWindowConsole && typeof window.console[method] === 'function';
const noop = (): void => undefined;

const console = {
    log: hasConsoleMethod('log')
        ? (...args: unknown[]) => window.console.log(...args)
        : noop,
    debug: hasConsoleMethod('debug')
        ? (...args: unknown[]) => window.console.debug(...args)
        : noop,
    info: hasConsoleMethod('info')
        ? (...args: unknown[]) => window.console.info(...args)
        : noop,
    warn: hasConsoleMethod('warn')
        ? (...args: unknown[]) => window.console.warn(...args)
        : noop,
    error: hasConsoleMethod('error')
        ? (...args: unknown[]) => window.console.error(...args)
        : noop,
    group: hasConsoleMethod('group')
        ? (...args: unknown[]) => window.console.group(...args)
        : noop,
    groupCollapsed: hasConsoleMethod('groupCollapsed')
        ? (...args: unknown[]) => window.console.groupCollapsed(...args)
        : noop,
    groupEnd: hasConsoleMethod('groupEnd')
        ? () => window.console.groupEnd()
        : noop,

    logWithDetails(message: string, lines: unknown[]): void {
        console.groupCollapsed(message);
        lines.forEach((line) =>
            Array.isArray(line) ? console.log(...line) : console.log(line)
        );
        console.groupEnd();
    },
};

export default console;

export function logfmt(strings: string[], ...values: string[]): string[] {
    return [strings.join('%o'), ...(values || [])];
}
