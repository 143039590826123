import { mapValues } from 'lodash';
import { OptionsStanza } from './AST';
import { ColumnarData, DataFrame } from './DataFrame';
import EncodingExecutor, { Frames } from './EncodingExecutor';

export class Options {
    /**
     * Takes the options stanza and the dataSources, and returns a simple properties object. Any options
     * that were expressions are evaluated. The resulting object is suitable to pass to a pure viz as props.
     * @param {OptionsStanza} options
     * @param dataSources
     * @param themeFunc
     * @returns {object}
     */
    static evaluate(
        options: OptionsStanza,
        dataSources: { [key: string]: ColumnarData },
        themeFunc: (themeKey) => any,
        dataFramesIn?: Frames
    ): Record<string, unknown> {
        let dataFrames: Frames;
        if (dataFramesIn) {
            dataFrames = dataFramesIn;
        } else {
            dataFrames = Options.dataFrameFromDataSource(dataSources);
        }
        return new EncodingExecutor().executeOptions(options, dataFrames, themeFunc);
    }

    static dataFrameFromDataSource(dataSources: { [key: string]: ColumnarData }): Frames {
        return Object.freeze(mapValues(dataSources, ds => DataFrame.fromJsonCols(ds)));
    }
}
