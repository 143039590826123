import React from 'react';
import T from 'prop-types';
import styled from 'styled-components';
import { formatNumber } from '@splunk/visualizations-shared/numberUtils';

const MARKER_THICKNESS = 15;
const MARKER_INNER_LENGTH = 30;

const MARKER_LABEL_WIDTH = 60;
const MARKER_LABEL_HEIGHT = 25;
const RADIUS = 2;
const DELTA_LENGTH = 12;

const ValueMarkerRect = styled.path`
    stroke-width: 2px;
    fill: ${props => props.valueMarkerFillColor};
`;

const ValueMarkerLabelRect = styled.rect`
    fill: ${props => props.valueMarkerFillColor};
`;

const ValueMarkerLabel = styled.text`
    fill: ${props => props.valueMarkerLabelFillColor};
    font-size: 14px;
    font-family: Splunk Platform Sans, sans-serif;
    line-height: 16px;
    text-anchor: middle;
    text-align: center;
    dominant-baseline: middle;
`;

/**
 * calculate the path of Value Marker shape
 * @method positionValueMarkerRect
 * @param {Object} input
 * @param {Number} input.x
 * @param {Number} input.y
 * @param {String} input.orientation
 * @param {Number} input.valueMarkerRectLength
 * @return {String} d containing a series of path commands that define the path to be
 */
export const positionValueMarkerRect = ({ x, y, orientation, valueMarkerRectLength }) => {
    let hLength = MARKER_THICKNESS;
    let vLength = valueMarkerRectLength;
    let markerStartX = x - MARKER_THICKNESS;
    const markerStartY = y;

    let innerHLength = hLength / 3;
    let innerVLength = MARKER_INNER_LENGTH;
    let innerStartX = markerStartX + innerHLength;
    let innerStartY = markerStartY + 7;

    if (orientation === 'vertical') {
        hLength = valueMarkerRectLength;
        vLength = MARKER_THICKNESS;
        markerStartX = x;

        innerHLength = MARKER_INNER_LENGTH;
        innerVLength = vLength / 3;
        innerStartX = markerStartX + 7;
        innerStartY = markerStartY + innerVLength;
    }

    return (
        `M${markerStartX},${markerStartY}h${hLength}a${RADIUS},${RADIUS} 0 0 1 ${RADIUS},${RADIUS}v${
            vLength - 2 * RADIUS
        }a${RADIUS},${RADIUS} 0 0 1 ${-RADIUS},${RADIUS}h${-hLength}a${RADIUS},${RADIUS} 0 0 1 ${-RADIUS},${-RADIUS}v${
            2 * RADIUS - vLength
        }a${RADIUS},${RADIUS} 0 0 1 ${RADIUS},${-RADIUS}z` +
        `M${innerStartX},${innerStartY}v${
            innerVLength - RADIUS
        }a${RADIUS},${RADIUS} 0 0 0 ${RADIUS},${RADIUS}h${
            innerHLength - 2 * RADIUS
        }a${RADIUS},${RADIUS} 0 0 0 ${RADIUS},${-RADIUS}v${
            RADIUS - innerVLength
        }a${RADIUS},${RADIUS} 0 0 0 ${-RADIUS},${-RADIUS}h${
            2 * RADIUS - innerHLength
        }a${RADIUS},${RADIUS} 0 0 0 ${-RADIUS},${RADIUS}z`
    );
};

/**
 * calculate the position of value marker label
 * @method positionLabel
 * @param {Object} input
 * @param {Number} input.x
 * @param {Number} input.y
 * @param {Number} input.length   gauge thickness
 * @param {String} input.orientation
 * @return {Object} positions
 * @return {Number} positions.labelRectX  x of value marker label rect
 * @return {Number} positions.labelRectY  y of value marker label rect
 * @return {Number} positions.labelX      x of value marker label text
 * @return {Number} positions.labelY      y of value marker label text
 */
export const positionLabel = ({ x, y, length, orientation }) => {
    // for horizontal view
    let labelRectX = x - MARKER_THICKNESS / 2 - MARKER_LABEL_WIDTH / 2;
    let labelRectY = y + length;
    if (orientation === 'vertical') {
        labelRectX = x + length;
        labelRectY = y - (MARKER_LABEL_HEIGHT - MARKER_THICKNESS) / 2;
    }
    const labelX = labelRectX + MARKER_LABEL_WIDTH / 2;
    const labelY = labelRectY + MARKER_LABEL_HEIGHT / 2;
    return { labelRectX, labelRectY, labelX, labelY };
};

const ValueMarker = ({ ...props }) => {
    const {
        x,
        y,
        length,
        min,
        max,
        value,
        orientation,
        showValue,
        usePercentageValue,
        valueMarkerFillColor,
        valueMarkerLabelFillColor,
    } = props;

    const valueMarkerRectLength = length + DELTA_LENGTH;
    const { labelRectX, labelRectY, labelX, labelY } = positionLabel({
        x,
        y,
        length,
        orientation,
    });

    return (
        <g>
            <ValueMarkerRect
                data-test="value-marker-rect"
                d={positionValueMarkerRect({ x, y, orientation, valueMarkerRectLength })}
                valueMarkerFillColor={valueMarkerFillColor}
            />
            {showValue && (
                <g>
                    <ValueMarkerLabelRect
                        data-test="value-marker-label-rect"
                        x={labelRectX}
                        y={labelRectY}
                        width={MARKER_LABEL_WIDTH}
                        height={MARKER_LABEL_HEIGHT}
                        rx={RADIUS}
                        ry={RADIUS}
                        valueMarkerFillColor={valueMarkerFillColor}
                    />
                    <ValueMarkerLabel
                        data-test="value-marker-label"
                        x={labelX}
                        y={labelY}
                        valueMarkerLabelFillColor={valueMarkerLabelFillColor}
                    >
                        {usePercentageValue
                            ? `${((value / (max - min)) * 100).toFixed(2)}%`
                            : formatNumber(value, 0, {
                                  useThousandSeparators: true,
                                  useTrendUnits: true,
                              })}
                    </ValueMarkerLabel>
                </g>
            )}
        </g>
    );
};

ValueMarker.propTypes = {
    x: T.number,
    y: T.number,
    length: T.number,
    min: T.number,
    max: T.number,
    value: T.number,
    orientation: T.string,
    showValue: T.bool,
    usePercentageValue: T.bool,
    valueMarkerFillColor: T.string,
    valueMarkerLabelFillColor: T.string,
};

ValueMarker.defaultProps = {
    min: 0,
    max: 100,
    orientation: 'horizontal',
    showValue: true,
    usePercentageValue: false,
};

export default ValueMarker;
