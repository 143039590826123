import { _ } from '@splunk/ui-utils/i18n';
import pick from '@splunk/themes/pick';
import variables from '@splunk/themes/variables';
import GaugeConfig from '../../common/editorConfig/Gauge';

const editorConfig = [
    GaugeConfig,
    {
        label: _('Threshold Settings'),
        open: true,
        layout: [
            [
                {
                    label: _('Gauge Color'),
                    encoding: 'gaugeColor', // encoding name
                    formatKey: 'ranges', // key for value in encoding config (gaugeColor.format.ranges)
                    editor: 'editor.threshold',
                    editorProps: {
                        openRanges: false, // Use closed ranges
                        isTogglable: false, // Can not disable thresholds
                    },
                },
            ],
        ],
    },
    {
        label: _('Background Color'),
        layout: [
            [
                {
                    label: '',
                    option: 'backgroundColor',
                    editor: 'editor.color',
                    editorProps: {
                        pickFromTheme: theme =>
                            pick({
                                enterprise: {
                                    dark: variables.black,
                                    light: variables.backgroundColor,
                                },
                                prisma: variables.backgroundColorSidebar,
                            })({ theme }),
                    },
                },
            ],
        ],
    },
];

export default editorConfig;
