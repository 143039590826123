import { _ } from '@splunk/ui-utils/i18n';
import { drop } from 'lodash';
import { EditorConfig } from '../interfaces/Editor';
import { insertLayout } from '../utils/configUtils';

export const AxesChartDataDisplayLine: EditorConfig = {
    label: _('Data display'),
    layout: [
        [
            {
                label: _('Null value display'),
                option: 'nullValueDisplay',
                editor: 'editor.radioBar',
                editorProps: {
                    values: [
                        { label: _('Gaps'), value: 'gaps' },
                        { label: _('Zero'), value: 'zero' },
                        { label: _('Connect'), value: 'connect' },
                    ],
                },
            },
        ],
        [
            {
                label: _('Data value display'),
                option: 'dataValuesDisplay',
                editor: 'editor.radioBar',
                editorProps: {
                    values: [
                        { label: _('Off'), value: 'off' },
                        { label: _('All'), value: 'all' },
                        { label: _('Min/Max'), value: 'minmax' },
                    ],
                },
            },
        ],
        [
            {
                label: _('Overlay fields'),
                option: 'overlayFields',
                editor: 'editor.columnMultiSelectionByFieldNameEditor',
                editorProps: {
                    filterByTypes: ['number'],
                },
            },
        ],
        [
            {
                label: _('Split series'),
                option: 'showSplitSeries',
                editor: 'editor.toggle',
            },
        ],
        [
            {
                label: _('Independent y-axis for split series'),
                option: 'showIndependentYRanges',
                editor: 'editor.checkbox',
                showEditor: ({ options }) => options.showSplitSeries,
            },
        ],
    ],
};

const AxesChartDataDisplay: EditorConfig = {
    label: AxesChartDataDisplayLine.label,
    layout: [
        [
            {
                label: _('Stack mode'),
                option: 'stackMode',
                editor: 'editor.select',
                editorProps: {
                    values: [
                        { label: _('Not stacked'), value: 'auto' },
                        { label: _('Stacked'), value: 'stacked' },
                        { label: _('Stacked 100%'), value: 'stacked100' },
                    ],
                },
            },
        ],
        ...AxesChartDataDisplayLine.layout,
    ],
};

export const AxesChartDataDisplayBar: EditorConfig = {
    label: AxesChartDataDisplayLine.label,
    layout: [
        [
            {
                label: _('Stack mode'),
                option: 'stackMode',
                editor: 'editor.select',
                editorProps: {
                    values: [
                        { label: _('Not stacked'), value: 'auto' },
                        { label: _('Stacked'), value: 'stacked' },
                        { label: _('Stacked 100%'), value: 'stacked100' },
                    ],
                },
            },
        ],
        // remove nullValueDisplay
        ...drop(AxesChartDataDisplayLine.layout),
    ],
};

export const AxesChartDataDisplayColumn: EditorConfig = {
    label: AxesChartDataDisplayBar.label,
    layout: [
        ...insertLayout(AxesChartDataDisplayBar.layout, 2, [
            {
                label: _('Show tooltip on hover'),
                option: 'showTooltip',
                editor: 'editor.checkbox',
            },
        ]),
        [
            {
                label: _('Column grouping mode'),
                option: 'columnGrouping',
                editor: 'editor.radioBar',
                editorProps: {
                    values: [
                        { label: _('Auto'), value: 'auto' },
                        { label: _('Overlay'), value: 'overlay' },
                    ],
                },
            },
        ],
    ],
};

export default AxesChartDataDisplay;
