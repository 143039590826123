import React, { useCallback, useRef } from 'react';
import { isEqual } from 'lodash';
import type {
    PresetUtility,
    RootDataSourcesDefinition,
} from '@splunk/dashboard-types';
import { DataSourceRegistry } from '@splunk/dashboard-search';
import {
    type SagaContext,
    useStore,
    useUpdateMiddlewareContext,
    useSelector,
    selectDataSourceDefinitions,
} from '@splunk/dashboard-state';
import {
    ApiRegistryContextProvider,
    useApiRegistry,
} from './ApiRegistryContext';
import {
    EventRegistryContextProvider,
    useEventRegistry,
} from './EventRegistryContext';
import { PresetContextProvider, usePreset } from './PresetContext';
import {
    DataSourceRegistryContextProvider,
    useDataSourceRegistry,
} from './DataSourceRegistryContext';
import { useBeforeUnloadEvent } from './hooks/useBeforeUnloadEvent';
import { usePrevious } from './hooks';
import {
    type DataSourceContextValue,
    defaultDataSourceContextValue,
} from './DataSourceContext';

export interface RegistryContextProviderProps {
    dataSourceContext?: {
        defaultModule?: string;
        [key: string]: unknown;
    };
    children?: React.ReactNode;
    preset: PresetUtility;
}

const UpdateMiddlewareContext = () => {
    const { updateMiddlewareContext } = useUpdateMiddlewareContext();
    const preset = usePreset();
    const dataSourceRegistry = useDataSourceRegistry();
    const apiRegistry = useApiRegistry();
    const eventRegistry = useEventRegistry();

    const prevPreset = usePrevious(preset);
    const prevDataSourceRegistry = usePrevious(dataSourceRegistry);
    const prevApiRegistry = usePrevious(apiRegistry);
    const prevEventRegistry = usePrevious(eventRegistry);

    const contextToUpdate: SagaContext = {};
    if (preset !== prevPreset) {
        contextToUpdate.preset = preset;
    }
    if (dataSourceRegistry !== prevDataSourceRegistry) {
        contextToUpdate.dataSourceRegistry = dataSourceRegistry;
    }
    if (apiRegistry !== prevApiRegistry) {
        contextToUpdate.apiRegistry = apiRegistry;
    }
    if (eventRegistry !== prevEventRegistry) {
        contextToUpdate.eventRegistry = eventRegistry;
    }

    if (Object.keys(contextToUpdate).length) {
        updateMiddlewareContext(contextToUpdate);
    }

    return null;
};

const RegistryContextProvider = ({
    children,
    dataSourceContext = defaultDataSourceContextValue,
    preset,
}: RegistryContextProviderProps): JSX.Element => {
    const store = useStore();
    const dataSourceDefinitions = useSelector(selectDataSourceDefinitions);

    // Creating a ref to use upon instantiation of datasource registry. Not pushing dsContext into registry upon creation breaks risky search.
    const dsContextRef = useRef<DataSourceContextValue>(dataSourceContext);
    const presetRef = useRef<PresetUtility>(preset);
    const dataSourceDefinitionsRef = useRef<RootDataSourcesDefinition>();

    const dataSourceRegistry = useRef<InstanceType<
        typeof DataSourceRegistry
    > | null>(null);

    // Never recreate the DS registry
    if (dataSourceRegistry.current === null) {
        dataSourceRegistry.current = new DataSourceRegistry({
            preset: presetRef.current,
            dataSourceContext: dsContextRef.current,
        });

        dataSourceRegistry.current.subscribeToStore(store);
    }

    // Update dataSourceContext in the DS registry later when it changes.
    if (!isEqual(dataSourceContext, dsContextRef.current)) {
        dsContextRef.current = dataSourceContext;
        dataSourceRegistry.current.setDataSourceContext(dsContextRef.current);
    }

    // Update preset in the DS registry if it changes
    if (preset !== presetRef.current) {
        presetRef.current = preset;
        dataSourceRegistry.current.setPreset(presetRef.current);
    }

    // update DS definitions when it changes
    if (dataSourceDefinitions !== dataSourceDefinitionsRef.current) {
        dataSourceDefinitionsRef.current = dataSourceDefinitions;
        dataSourceRegistry.current.updateDefinition({
            definition: dataSourceDefinitions,
        });
    }

    const teardownDataSources = useCallback(() => {
        dataSourceRegistry.current?.teardown();
    }, []);

    useBeforeUnloadEvent(teardownDataSources);

    return (
        <PresetContextProvider value={preset}>
            <ApiRegistryContextProvider>
                <EventRegistryContextProvider>
                    <DataSourceRegistryContextProvider
                        value={dataSourceRegistry.current}
                    >
                        <UpdateMiddlewareContext />
                        {children}
                    </DataSourceRegistryContextProvider>
                </EventRegistryContextProvider>
            </ApiRegistryContextProvider>
        </PresetContextProvider>
    );
};

export default RegistryContextProvider;
