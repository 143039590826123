import type { Store } from 'redux';
import { batch } from 'react-redux';
import { isEmpty, merge } from 'lodash';
import type {
    InitialDashboardContextProps,
    TokenState,
} from '@splunk/dashboard-types';
import { getDefaultStaticTokens } from '@splunk/dashboard-definition/DashboardDefinition';
import {
    getWindowLocationSearch,
    mapURLToTokens,
} from '@splunk/dashboard-utils';
import { useIsFirstRender } from './useIsFirstRender';
import { initialize } from '../sagas/sagaActions';
import resetStore from '../reducers/resetStore';

export interface UseInitializeStoreType extends InitialDashboardContextProps {
    store: Store;
}

export const useInitializeStore = ({
    store,
    initialDefinition = {},
    initialTokenBinding = {},
    initialReadOnlyTokenNamespaces,
    initialMode = 'view',
    initialSelectedItems = [],
    initialShowGrid = initialDefinition.layout?.type !== 'grid',
}: UseInitializeStoreType) => {
    const isFirstRender = useIsFirstRender();

    if (!isFirstRender) {
        return;
    }

    // Middle priority (after original token binding)
    const defaultTokens = getDefaultStaticTokens(
        initialDefinition,
        initialReadOnlyTokenNamespaces
    );

    // High Priority
    // TODO: if we ever support non-"default" namespaces in the URL, ensure that readOnlyTokenNamespaces are not read
    const urlTokens = mapURLToTokens({
        search: getWindowLocationSearch(),
    });

    // merge up all the tokens. mutates {}
    const mergedTokens: TokenState = merge(
        {},
        initialTokenBinding,
        defaultTokens,
        urlTokens
    );

    // Prevent default from being an empty object, screwing up the following comparison on initial load (see mapURLToTokens)
    if (isEmpty(mergedTokens.default)) {
        delete mergedTokens.default;
    }

    const initialState = {
        // mode,
        definition: initialDefinition,
        tokens: {
            readOnlyTokenNamespaces: Array.from(
                initialReadOnlyTokenNamespaces || []
            ),
            submitted: mergedTokens,
            stashed: {},
        },
        mode: initialMode,
        editor: {
            selectedItems: initialSelectedItems,
            showGridLines: initialShowGrid,
        },
    };

    batch(() => {
        // Update the store to initial state
        store.dispatch(resetStore(initialState));

        // kickoff the data source update loop
        store.dispatch(initialize());
    });
};
