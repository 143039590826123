import * as React from 'react';
import RemoteIcon from '@splunk/visualizations-shared/RemoteIcon';
import { isRemoteIcon, isDataURI } from '@splunk/visualizations-shared/iconUtils';
import SingleValueIcon from './PureSingleValueIcon/SingleValueIcon';
import withDashboardViz from '../common/withDashboardViz';
import vizConfig from './config';
import withTrellis from '../common/hocs/withTrellis';

export const config = vizConfig;
export const PureSingleValueIcon = SingleValueIcon;

interface ComputeVizPropsInput {
    hasEventHandlers: boolean;
    mode: string;
    icon: string;
    onValueClick: (...args: any[]) => any;
    IconComponent?;
    themeFunc: (any) => any;
    trellisKey: unknown;
    trellisSplitBy: string;
}

interface ComputeVizPropsOutput {
    IconComponent: null | React.Component<any, any>; // todo: should have RemoteIconProps and RemoteIconState generics
    defaultFontColor: string;
    defaultBlockFontColor: string;
    trellisKey: unknown;
    trellisSplitBy: string;
}
export const computeVizProps = ({
    icon,
    IconComponent = null,
    themeFunc,
    trellisKey,
    trellisSplitBy,
}: ComputeVizPropsInput): ComputeVizPropsOutput => {
    if (icon && (isRemoteIcon(icon) || isDataURI(icon))) {
        // eslint-disable-next-line no-param-reassign
        IconComponent = <RemoteIcon iconURL={icon} />;
    }

    return {
        IconComponent,
        defaultFontColor: themeFunc('defaultFontColor'),
        defaultBlockFontColor: themeFunc('defaultBlockFontColor'),
        trellisKey,
        trellisSplitBy,
    };
};
const viz = withTrellis(withDashboardViz({ ReactViz: SingleValueIcon, vizConfig, computeVizProps }));
export const { themes } = vizConfig;
export default viz;
