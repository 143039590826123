import * as React from 'react';

// Global provided via webpack
// eslint-disable-next-line no-underscore-dangle
declare const __VISION_VERSION__: string;

export interface Action {
    label: string;
    eventType: string;
    eventtypeValues?: string[];
}

export type FieldAction = Action;

export type EventAction = Action & {
    fieldFilters?: string[];
};

export type FieldActions = Record<string, FieldAction[]>;

export interface EventsContextInterface {
    eventActions: EventAction[];
    fieldActions: FieldActions;
    isSplunkWebAvailable: boolean;
}

const EventsContext = React.createContext<EventsContextInterface>({
    eventActions: [],
    fieldActions: {},
    isSplunkWebAvailable: false,
});
EventsContext.displayName = `data-splunk-visualization-version-EventsContext-${__VISION_VERSION__}`;

export const EventsContextProvider = EventsContext.Provider;
export const EventsContextConsumer = EventsContext.Consumer;
export default EventsContext;
