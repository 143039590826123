import React from 'react';
import { _ } from '@splunk/ui-utils/i18n';
import SVG, {
    type SVGPropsOptionalViewBox,
} from '@splunk/react-icons/SVGEnterprise';

/* eslint-disable max-len */
export default function AddChartDropdown({
    screenReaderText = _('Add chart'),
    ...otherProps
}: SVGPropsOptionalViewBox): ReturnType<typeof SVG> {
    return (
        <SVG
            viewBox="0 0 24 24"
            screenReaderText={screenReaderText}
            {...otherProps}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M13 4.5C13 3.67157 13.6716 3 14.5 3H17.5C18.3284 3 19 3.67157 19 4.5V17H13V4.5ZM5 9.5C5 8.67157 5.67157 8 6.5 8H9.5C10.3284 8 11 8.67157 11 9.5V17H5V9.5ZM3 19C2.44772 19 2 19.4477 2 20C2 20.5523 2.44772 21 3 21H21C21.5523 21 22 20.5523 22 20C22 19.4477 21.5523 19 21 19H3Z"
                fill="currentColor"
            />
        </SVG>
    );
}
