/* eslint-disable  no-underscore-dangle */
import { console } from '@splunk/dashboard-utils';
import { get, omit } from 'lodash';
import type {
    MetricsCollector,
    CollectorData,
    CollectorPayload,
} from './MetricsCollector';
import type {
    EmittableEvent,
    CountableEvent,
    EmittableEventOptions,
} from './EventTypes';
import {
    filterStrategies,
    type FilterStrategyCollection,
} from './filters/filterStrategies';
import { extractDefinitionInfo } from './utils/extractDefinitionInfo';
import { extractVisualizationInfo } from './utils/extractVisualizationInfo';

// Global provided via webpack
declare const __UDF_VERSION__: string;

const getEnterpriseVersion = () => get(globalThis, '$C.VERSION_LABEL');

// a logging api, e.g. console
type Logger = {
    error: (...value: unknown[]) => void;
};

/**
 * a collection of counted events, e.g. { canvasEvents: { edgeResize: 1 } }
 */
type EventCollection = Record<string, Record<string, number>>;

// Data passed to constructor
type TelemetryAPIProps = {
    metricsCollectors?: MetricsCollector[];
    customFilters?: FilterStrategyCollection;
    logger?: Logger;
};

export const TELEMETRY_ERROR = 'Failed to send telemetry event to collector';

/**
 * This class provides a common telemetry and filtering interface to UDF components,
 * and sends filtered data to MetricsCollectors
 *
 * Example:
 *
 * ```
 * const collector = new MyMetricsCollector();
 * const api = new TelemetryApi({ metricsCollectors: [collector] });
 *
 * // Send data immediately to the metrics service defined in the collector
 * api.emit({ pageAction: "myPageAction", metaData: { myData: 'foo' } });
 *
 * // Send data that should be collected and counted for later use
 * api.collect({ source: "canvas", event: "edge_resize" });
 *
 * // Send data immediately, and send all the collected event counts
 * api.flush({ pageAction: "myFlushAction" });
 * ```
 *
 * @class TelemetryAPI
 */
export class TelemetryAPI {
    // A set of events that are retained until a "flush", e.g. { canvasEvents: { edgeResize: 1 }}
    protected collectedEvents: EventCollection = {};

    // The set of handlers that talk to a metrics backend
    protected metricsCollectors: MetricsCollector[];

    // A collection of payload filters
    private filters: FilterStrategyCollection;

    // A logging utility (e.g. console)
    private logger: Logger;

    /**
     * TelemetryApi constructor
     * @param {Object} config
     * @param {MetricsCollector[]} [config.metricsCollectors=[]] A set of handlers that talk to backend services
     * @param {FilterStrategyCollection} [config.customFilters={}] A set of functions that will process the contents of a key
     * @param {Logger} [config.logger=console] A logging utility
     * @method
     * @constructor
     */
    constructor({
        metricsCollectors = [],
        customFilters = {},
        logger = console,
    }: TelemetryAPIProps = {}) {
        this.metricsCollectors = metricsCollectors;
        // Set up the filters we will use to process event data,
        // omit udfVersion because we will always set version ourselves
        this.filters = omit(
            {
                ...customFilters,
                ...filterStrategies,
            },
            ['udfVersion']
        );
        this.logger = logger;
        this.resetEvents();
    }

    /**
     * Reset the counted event data
     * @method
     * @protected
     */
    protected resetEvents(): void {
        this.collectedEvents = {};
    }

    /**
     * Shared helper method to send data to MetricsCollector instances
     * @param {CollectorData} payload Processed data sent by an event emitter
     * @protected
     */
    protected sendEvent(payload: CollectorData): void {
        this.metricsCollectors.forEach((collector: MetricsCollector) => {
            try {
                collector.sendEvent(<CollectorPayload>{
                    eventType: 'udf.telemetry',
                    level: 'info',
                    data: {
                        ...payload,
                    },
                });
            } catch (err) {
                if (err instanceof Error) {
                    this.logger.error(TELEMETRY_ERROR, err.message);
                }
            }
        });
    }

    /**
     * Filter all the payload information to prevent exposure of PII
     * @param {EmittableEvent} payload The data that was sent by an event emitter
     * @returns {CollectorData} Filtered data
     * @private
     */
    private processEmittedEvent = (
        payload: EmittableEvent,
        { omitKeys = [] }: EmittableEventOptions = {}
    ): CollectorData => {
        const result: CollectorData = {
            pageAction: 'unknown',
            metadata: {},
            udfVersion: __UDF_VERSION__,
            enterpriseVersion: getEnterpriseVersion() || null,
            definitionInfo:
                payload.definition && extractDefinitionInfo(payload.definition),
            visualizationInfo:
                payload.definition &&
                extractVisualizationInfo(payload.definition),
        };

        Object.keys(payload).forEach((key: string) => {
            const fn = this.filters[key];
            // do not further process payload data in omitKeys (e.g. definition)
            if (!omitKeys.includes(key) && fn) {
                result[key] = fn(payload[key]);
            }
        });

        // remove other keys from the result if necessary
        omitKeys.forEach((key) => {
            delete result[key];
        });

        return result;
    };

    /**
     * Add any special handling to process the collected events.
     * In our case, we want to ensure our selected groupings are always in the output (canvas, keyboard, actionMenu).
     * We may add more groupings later
     * @private
     * @method
     */
    private processCountableEvents(): EventCollection {
        return {
            canvasEvents: this.collectedEvents.canvasEvents || {},
            keyboardEvents: this.collectedEvents.keyboardEvents || {},
            actionMenuEvents: this.collectedEvents.actionMenuEvents || {},
        };
    }

    /**
     * Send the current event data along with counts of other collected events
     * @param {EmittableEvent} payload Data from an event emitter
     * @param {EmittableEventOptions} [options] Configuration to exclude keys from payload in the output
     * @param {String[]} [options.omitKeys=[]] list of payload keys to ignore in the final output, e.g. 'definition"
     */
    flush(payload: EmittableEvent, options?: EmittableEventOptions): void {
        const processedPayload = this.processEmittedEvent(payload, options);

        this.sendEvent({
            ...this.processCountableEvents(),
            metadata: processedPayload.metadata,
            udfVersion: processedPayload.udfVersion,
            enterpriseVersion: processedPayload.enterpriseVersion,
            definitionInfo: processedPayload.definitionInfo,
        });
        this.resetEvents();

        this.sendEvent(processedPayload);
    }

    /**
     * Immediately send the current event data
     * @param {EmittableEvent} payload Data from an event emitter
     * @param {EmittableEventOptions} [options] Configuration to exclude keys from payload in the output
     * @param {String[]} [options.omitKeys=[]] list of payload keys to ignore in the final output, e.g. 'definition"
     */
    emit(payload: EmittableEvent, options?: EmittableEventOptions): void {
        this.sendEvent(this.processEmittedEvent(payload, options));
    }

    /**
     * Count the event amongst other counted events for later use
     * @param {CountableEvent} payload Data from an event emitter
     */
    collect(payload: CountableEvent): void {
        const sourceKey = `${payload.source}Events`;
        if (!this.collectedEvents[sourceKey]) {
            this.collectedEvents[sourceKey] = { [payload.event]: 0 };
        }
        if (this.collectedEvents[sourceKey][payload.event] === undefined) {
            this.collectedEvents[sourceKey][payload.event] = 0;
        }
        this.collectedEvents[sourceKey][payload.event] += 1;
    }
}

export default TelemetryAPI;
