import styled from 'styled-components';
import { pick } from 'lodash';
import { toDimension } from '@splunk/visualizations-shared/style';
import css from '!!raw-loader!maplibre-gl/dist/maplibre-gl.css';
import { HexColorObj } from './PureMap';

const PureMapContainer = styled.div<{
    width: number | string;
    height: number | string;
    backgroundColor: string;
    controlBackgroundColor: string;
    controlForegroundColor: string;
    controlIconColorEncoded: HexColorObj;
    showScale: boolean;
    showZoomControls: boolean;
}>`
    ${css}
    overflow: hidden;
    position: relative;
    z-index: 0;
    background-color: ${props => props.backgroundColor};
    ${props => toDimension(pick(props, ['width', 'height']))};

    // overwrite maplibregl zoom control styles
    .maplibregl-ctrl-top-left {
        display: ${props => (props.showZoomControls ? 'block' : 'none')};
        position: absolute;
        top: 12px;
        left: 16px;
        .maplibregl-ctrl-group {
            width: 40px !important;
            height: 87px !important;
            border-radius: 4px;
            margin: 8px 0px;
            background-color: ${props => props.controlBackgroundColor};
            box-shadow: 0px 26px 103px rgba(0, 0, 0, 0.64), 0px 11px 18px rgba(0, 0, 0, 0.32),
                0px 3px 6px rgba(0, 0, 0, 0.3);
            & button {
                width: 40px;
                height: 43px;
                border-top: 0;
            }
            .mapboxgl-ctrl-zoom-in .maplibregl-ctrl-icon {
                background-image: url('data:image/svg+xml;charset=UTF-8,<svg width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg"><path d="M9 1C9 0.447715 8.55229 0 8 0C7.44772 0 7 0.447715 7 1V7H1C0.447715 7 0 7.44772 0 8C0 8.55229 0.447715 9 1 9H7V15C7 15.5523 7.44772 16 8 16C8.55229 16 9 15.5523 9 15V9H15C15.5523 9 16 8.55229 16 8C16 7.44772 15.5523 7 15 7H9V1Z" fill="${props =>
                    props.controlIconColorEncoded.color}" fill-opacity="${props =>
                    props.controlIconColorEncoded.opacity}" /></svg>');
            }

            .maplibregl-ctrl-zoom-out .maplibregl-ctrl-icon {
                background-image: url('data:image/svg+xml;charset=UTF-8,<svg width="14" height="3" viewBox="0 0 14 3" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M0 1.99707C0 1.44479 0.447715 0.99707 1 0.99707H13C13.5523 0.99707 14 1.44479 14 1.99707C14 2.54936 13.5523 2.99707 13 2.99707H1C0.447715 2.99707 0 2.54936 0 1.99707Z" fill="${props =>
                    props.controlIconColorEncoded.color}" fill-opacity="${props =>
                    props.controlIconColorEncoded.opacity}" /></svg>');
            }
        }
    }

    // overwrite maplibregl scale styles
    .maplibregl-ctrl-bottom-left {
        display: ${props => (props.showScale ? 'block' : 'none')};
        position: absolute;
        z-index: 0;
        bottom: 16px;
        left: 12px;
        width: 86px;
        height: 29px;
        border-radius: 4px;
        margin: 0;
        background-color: ${props => props.controlBackgroundColor};
        box-shadow: 0px 26px 103px rgba(0, 0, 0, 0.13), 0px 11px 18px rgba(0, 0, 0, 0.06),
            0px 3px 6px rgba(0, 0, 0, 0.06);
        .maplibregl-ctrl-scale {
            background-color: ${props => props.controlBackgroundColor};
            color: ${props => props.controlForegroundColor};
            border: 2px solid ${props => props.controlForegroundColor};
            border-top: 0;
            border-left: 0;
            border-right: 0;
            padding: 0;
            margin: 6px 8px 8px 8px;
            line-height: 13px;
            letter-spacing: 0.02em;
            font-family: sans-serif;
            font-size: 10px;
        }
    }
`;

export default PureMapContainer;
