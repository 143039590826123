import { take, cancel, fork } from 'redux-saga/effects';
import { teardown } from './sagaActions';
import inputSaga from './inputSaga';
import eventSaga from './eventSaga';
import editingSaga from './editingSaga';

export default (sagaContext = {}, featureFlags = {}) =>
    function* mainSaga() {
        const inputUpdateTask = yield fork(inputSaga, sagaContext);
        const eventHandlingTask = yield fork(
            eventSaga,
            sagaContext,
            featureFlags
        );
        const editingSagaTask = yield fork(editingSaga, sagaContext);
        yield take(teardown);
        yield cancel(inputUpdateTask);
        yield cancel(eventHandlingTask);
        yield cancel(editingSagaTask);
    };
