import React from 'react';
import { withTheme } from 'styled-components';
import T from 'prop-types';
import { PurePunchcard } from '@splunk/visualizations/Punchcard';
import { CATEGORICAL } from '@splunk/visualization-color-palettes';
import { withSanitizedProps } from '@splunk/visualizations-shared/SanitizeProps';

import {
    BUBBLE_ROW_SCALE_TYPES,
    BUBBLE_SCALE_TYPES,
    COLOR_MODE_TYPES,
    BUBBLE_LABELS_TYPES,
} from './src/utils/punchcardUtils';

const ReactPunchcard = ({ ...props }) => <PurePunchcard {...props} />;

ReactPunchcard.propTypes = {
    backgroundColor: T.string,
    showBubbleLabels: T.oneOf(Object.values(BUBBLE_LABELS_TYPES)),
    bubbleRowScale: T.oneOf(Object.values(BUBBLE_ROW_SCALE_TYPES)),
    bubbleScale: T.oneOf(Object.values(BUBBLE_SCALE_TYPES)),
    category: T.arrayOf(T.string),
    colorMode: T.oneOf(Object.values(COLOR_MODE_TYPES)),
    seriesColors: T.arrayOf(T.string),
    bubbleColor: T.string,
    minBubbleColorIntensity: T.number,
    height: T.oneOfType([T.string, T.number]),
    isBubbleSizeDynamic: T.bool,
    minBubbleSize: T.number,
    maxBubbleSize: T.number,
    maxBubbleRadius: T.number,
    minBubbleRadius: T.number,
    useDefaultSort: T.bool,
    showMaxValuePulsation: T.bool,
    showLegend: T.bool,
    size: T.arrayOf(T.number).isRequired,
    width: T.oneOfType([T.string, T.number]),
    x: T.arrayOf(T.oneOfType([T.string, T.number])).isRequired,
    y: T.arrayOf(T.oneOfType([T.string, T.number])).isRequired,
};

ReactPunchcard.defaultProps = {
    backgroundColor: 'transparent',
    showBubbleLabels: BUBBLE_LABELS_TYPES.ALL,
    bubbleRowScale: BUBBLE_ROW_SCALE_TYPES.GLOBAL,
    bubbleScale: BUBBLE_SCALE_TYPES.AREA,
    category: null,
    colorMode: COLOR_MODE_TYPES.CATEGORICAL,
    seriesColors: CATEGORICAL,
    bubbleColor: '#7B56DB',
    minBubbleColorIntensity: 0.25,
    showMaxValuePulsation: true,
    height: 500,
    isBubbleSizeDynamic: true,
    minBubbleSize: 0.25,
    maxBubbleSize: 1,
    maxBubbleRadius: 15,
    minBubbleRadius: 1,
    useDefaultSort: false,
    showLegend: true,
    width: '100%',
};

export default withTheme(withSanitizedProps(ReactPunchcard));
