export default {
    query: {
        type: 'string',
        description: 'An SPL search string.',
    },
    queryParameters: {
        type: 'object',
        properties: {
            earliest: {
                type: 'string',
                description: 'Specify the earliest time to search for events.',
            },
            latest: {
                type: 'string',
                description: 'Specify the latest time to search for events.',
            },
            timezone: {
                type: 'string',
                description: 'Specify the timezone.',
            },
        },
        additionalProperties: false,
        description: 'SPL query parameters.',
    },
    refresh: {
        anyOf: [
            {
                type: 'number',
                minimum: 0,
            },
            {
                type: 'string',
            },
        ],
        description:
            'Specify the refresh interval in seconds or with a time expression.',
    },
    refreshType: {
        type: 'string',
        enum: ['delay', 'interval'],
        default: 'delay',
        description:
            'Indicate the starting time for counting down to a refresh. Use delay to start counting when the search is done. Use interval to count down when the search is dispatched.',
    },
    requiredFreshness: {
        type: 'number',
        description:
            'Specify the freshness range in seconds to enable search job caching',
    },
    module: {
        type: 'string',
        description: 'Dataset module for search isolation',
    },
};
