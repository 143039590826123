import { DataSeries } from '../DataSeries';
import { DataPoint } from '../DataPoint';
import { Default } from './Default';

/**
 * This formatter can be used to rename a series name
 *
 * Example: renameSeries("username").
 *
 * ```js
 * <SampleViz
 *     options={{
 *         original: '> primary | getField()'                                                                    // ['users', 'ids']
 *         dataframe: '> primary | renameSeries("username") | getField()'                                        // ['username', 'ids']
 *         dataSeries: '> primary | seriesByName("users") | renameSeries("username") | getField()'               // 'username'
 *         dataPoint: '> primary | seriesByName("users") | lastPoint() | renameSeries("username") | getField()'  // 'username'
 *     }}
 *     dataSources={{
 *         primary: {
 *             data: {
 *                 columns: [['Maurine', 'Jennings', 'Giuseppe'], ['1', '2', '3']],
 *                 fields: [{ name: 'users' }, { name: 'ids' }]
 *             }
 *         }
 *     }}
 * />
 * ```
 * ## Arguments
 *  * **fieldName**: 'string' new field name for the series
 */
export class RenameSeries extends Default {
    private newFieldName: string;

    constructor(name: string) {
        super(name);
        this.newFieldName = name;
    }

    protected formatPoint(p: DataPoint, s?: DataSeries, i = 0, j = 0): DataPoint {
        return i === 0 && typeof this.newFieldName === 'string' && this.newFieldName.length > 0
            ? new DataPoint(this.newFieldName, this.formatTypedValue(p, s, i, j))
            : super.formatPoint(p, s, i, j);
    }
}
