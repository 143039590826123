import * as _ from 'lodash';
import type { VisualizationDefinition } from '@splunk/dashboard-types';
import config from '@splunk/visualizations/Markdown.config';
import { renameVizOptions, removeInvalidOptions } from '../utils/migrationUtils';

type FontWeight = 'normal' | 'bold' | 'light';

const validOptions = {
    ...config.optionsSchema,
};

const optionsToRename = {
    textColor: 'fontColor',
    content: 'markdown',
};

const convertTextContentToMarkDown = (fontWeight: FontWeight, fontSize: number, textContent = ''): string => {
    if (!_.isNumber(fontSize)) {
        return textContent;
    }
    if (textContent.trim().length === 0) {
        return textContent;
    }
    // Remove any trailing newline chars from the content
    let markdownContent = textContent.replace(/\n*$/, '');
    // Handle text conversion to marker tags based on fontSize and fontWeight
    const regex = /\n/g; // regex to match /n with global scope across the string

    // Convert to paragraph in markdown, if fontSize < 20,
    // If text has fontWeight set to 'bold', add bold tag (**{markdown}**)
    if (fontWeight === 'bold') {
        // Here direct regex replace doesnt work, as we need to remove the
        // extra trailing space if any exist before appending **{markdown}** to the text
        const contentStrings = markdownContent.split('\n');
        const stringsTrimmed = contentStrings.map(str =>
            str && str.trim().length ? `**${str.trim()}**` : ''
        );
        // Remove all blank entries from the end of the trimmed string array,
        // to avoid `\` to get rendered, which happens if following line after `\\n` is empty)
        let idx = stringsTrimmed.length - 1;
        while (idx >= 0 && stringsTrimmed[idx].length === 0) {
            stringsTrimmed.pop();
            idx -= 1;
        }
        return stringsTrimmed.join('\\\n');
    }
    // If fontWeight is not bold, replace '\n' with '\\n` using regex
    markdownContent = markdownContent.replace(regex, '\\\n');
    return markdownContent;
};

export const convertFontSize = (fontSize: number): { fontSize: string; customFontSize?: number } => {
    if (!_.isNumber(fontSize)) {
        return { fontSize: 'default' };
    }
    return { fontSize: 'custom', customFontSize: fontSize };
};

export const migrateVizTextToSplunkMarkdown = (
    vizDefinition: VisualizationDefinition
): VisualizationDefinition => {
    const { options = {}, ...otherDefinitionParts } = vizDefinition;
    const {
        fontSize = 24,
        fontWeight = 'normal',
        fontFamily = 'Splunk Platform Sans',
        rotation = 0,
    } = options;
    const migratedVisualization = { options, ...otherDefinitionParts, type: 'splunk.markdown', context: {} };

    // perform any option renames
    migratedVisualization.options = renameVizOptions(migratedVisualization.options, optionsToRename);
    const migratedVizOptions = migratedVisualization.options;

    // convert text content to respective markdown
    migratedVizOptions.markdown = convertTextContentToMarkDown(
        fontWeight as FontWeight,
        fontSize as number,
        migratedVizOptions.markdown as string
    );
    // convert text fontSize to markdown fontSize
    const { fontSize: fontSizeValue, customFontSize } = convertFontSize(fontSize as number);
    migratedVizOptions.fontSize = fontSizeValue;
    migratedVizOptions.customFontSize = customFontSize;
    migratedVizOptions.fontFamily = fontFamily;
    migratedVizOptions.rotation = rotation;

    // remove invalid options using the optionsSchema for splunk.markdown as reference
    removeInvalidOptions(migratedVizOptions, validOptions);
    return migratedVisualization;
};
