/* eslint-disable */

class Provider {
    constructor() {}

    /**
     * return provider type
     */
    getType() {
        throw new Error('getType() not implemented');
    }

    /**
     * return a list of images
     */
    async listIds({ offset, count }) {
        throw new Error('listIds() not implemented');
    }

    /**
     * add an image to the storage
     */
    async upload(dataURI, metaData) {
        throw new Error('upload() not implemented');
    }

    /**
     * retrieve imageID
     */
    async getById(imageID) {
        throw new Error('get() not implemented');
    }

    /**
     * delete image by imageID
     */
    async deleteById(imageID) {
        throw new Error('delete() not implemented');
    }
}

export default Provider;
