import { _ } from '@splunk/ui-utils/i18n';
import { isDynamicOption } from '@splunk/visualizations-shared/configUtils';

const StaticBackgroundColorEditor = {
    label: _('Background'),
    option: 'backgroundColor',
    editor: 'editor.color',
    showEditor: ({ options }) => !isDynamicOption(options.backgroundColor),
};

export default StaticBackgroundColorEditor;
