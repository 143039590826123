import React, { useCallback, useMemo, useRef, useLayoutEffect } from 'react';
import type { HandleDirection } from '@splunk/dashboard-types';
import ResponsiveBox from './ResponsiveBox';
import { getClientPosition, getOffset } from '../utils/layoutUtils';
import { Port, PORT_DIRECTIONS } from './Port';
import type { PortDirection } from './Port';
import { ResizeHandle } from './ResizeHandle';

const noop = (): void => undefined;

export interface ResponsiveBlockOutlineProps {
    itemId: string;
    x: number;
    y: number;
    w: number;
    h: number;
    scale?: number;
    connectable?: boolean;
    resizable?: boolean;
    onResize?: (
        e: MouseEvent,
        itemId: string,
        offset: { offsetX: number; offsetY: number },
        resizeDir: HandleDirection
    ) => void;
    onResized?: (
        e: MouseEvent,
        itemId: string,
        offset: { offsetX: number; offsetY: number },
        resizeDir: HandleDirection
    ) => void;
    onLineConnect?: (itemId: string, port: PortDirection) => void;
    onLineDisconnect?: (itemId: string, port: PortDirection) => void;
    handleDirections: HandleDirection[];
}

const ResponsiveBlockOutline = ({
    itemId,
    scale = 1,
    connectable = false,
    resizable = false,
    onResize = noop,
    onResized = noop,
    onLineConnect = noop,
    onLineDisconnect = noop,
    handleDirections,
    x,
    y,
    w,
    h,
}: ResponsiveBlockOutlineProps): JSX.Element => {
    const startPosition = useRef<ReturnType<typeof getClientPosition> | null>(
        null
    );
    const resizing = useRef(false);

    const resizeDir = useRef<HandleDirection | null>(null);

    // Setup start of resize operation
    const handleResizeMouseDown = useCallback(
        (e: React.MouseEvent, dir: HandleDirection) => {
            e.preventDefault();
            e.stopPropagation();
            startPosition.current = getClientPosition(e, scale);
            resizing.current = true;
            resizeDir.current = dir;
        },
        [scale]
    );

    // Update size when resizing
    const handleMouseMove = useCallback(
        (e: MouseEvent) => {
            if (
                startPosition.current &&
                resizing.current &&
                resizeDir.current
            ) {
                e.preventDefault();
                const currentPosition = getClientPosition(e, scale);
                const offset = getOffset(
                    currentPosition,
                    startPosition.current
                );
                onResize(e, itemId, offset, resizeDir.current);
            }
        },
        [scale, onResize, itemId]
    );

    // Update final position at end of resize
    const handleMouseUp = useCallback(
        (e: MouseEvent) => {
            if (
                startPosition.current &&
                resizing.current &&
                resizeDir.current
            ) {
                e.preventDefault();
                const currentPosition = getClientPosition(e, scale);
                const offset = getOffset(
                    currentPosition,
                    startPosition.current
                );
                startPosition.current = null;
                resizing.current = false;
                onResized(e, itemId, offset, resizeDir.current);
                resizeDir.current = null;
            }
        },
        [itemId, scale, onResized]
    );

    // Update line when connecting to port
    const handlePortEnter = useCallback(
        (port: PortDirection) => (e: React.MouseEvent) => {
            e.preventDefault();
            onLineConnect(itemId, port);
        },
        [itemId, onLineConnect]
    );

    // Update line when disconnecting from port
    const handlePortLeave = useCallback(
        (port: PortDirection) => (e: React.MouseEvent) => {
            e.preventDefault();
            onLineDisconnect(itemId, port);
        },
        [itemId, onLineDisconnect]
    );

    useLayoutEffect(() => {
        if (resizable) {
            document.addEventListener('mousemove', handleMouseMove);
        }
        return () => {
            document.removeEventListener('mousemove', handleMouseMove);
        };
    }, [resizable, handleMouseMove]);

    useLayoutEffect(() => {
        if (resizable) {
            document.addEventListener('mouseup', handleMouseUp);
        }
        return () => {
            document.removeEventListener('mouseup', handleMouseUp);
        };
    }, [resizable, handleMouseUp]);

    // Memoized resize handles
    const ResizeHandles = useMemo(() => {
        if (!resizable) {
            return null;
        }

        return handleDirections.map((dir) => (
            <ResizeHandle
                key={`handle-${dir}`}
                onMouseDown={handleResizeMouseDown}
                direction={dir}
            />
        ));
    }, [handleDirections, resizable, handleResizeMouseDown]);

    // Memoized connection ports
    const Ports = useMemo(() => {
        if (!connectable) {
            return null;
        }
        return PORT_DIRECTIONS.map((port) => (
            <Port
                key={`port-${port}`}
                port={port}
                onMouseEnter={handlePortEnter(port)}
                onMouseLeave={handlePortLeave(port)}
            />
        ));
    }, [connectable, handlePortEnter, handlePortLeave]);

    return (
        <ResponsiveBox itemId={itemId} x={x} y={y} w={w} h={h}>
            {ResizeHandles}
            {Ports}
        </ResponsiveBox>
    );
};

export default ResponsiveBlockOutline;
