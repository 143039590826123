import * as React from 'react';
import { useContext, SyntheticEvent } from 'react';
import * as T from 'prop-types';
import EventsViewer from '@splunk/react-events-viewer/components/EventsViewer';
import type { EventsViewerOptions } from '@splunk/react-events-viewer/components/EventsViewer';
import {
    EventActionComponentWithFilters,
    FieldActionsComponentsWithFilters,
} from '@splunk/react-events-viewer/common-types';
import { FieldData } from '@splunk/react-field-summary/components/FieldSummary';
import { PaginationParamsType } from '@splunk/react-visualization-utils/propUtils';

import styled from 'styled-components';
import pick from '@splunk/themes/pick';
import variables from '@splunk/themes/variables';
import { isColor } from '@splunk/visualizations-shared/colorUtils';
import { toDimension } from '@splunk/visualizations-shared/style';
import EventsContext from '@splunk/visualization-context/EventsContext';
import type {
    EventsContextInterface,
    EventAction,
    FieldActions,
} from '@splunk/visualization-context/EventsContext';
import { noop, isNumber, get } from 'lodash';
import Menu from '@splunk/react-ui/Menu';

import { EMPTY_DATASOURCE } from '../docs/constants';
import withFixedSizeContainer from '../../common/hocs/FixedSizeContainer';
import { DataSource } from '../../common/interfaces/DataSource';
import {
    convertToPayloadFormat,
    generateTokenReplacementMap,
    getFieldSummaryMapping,
    mergeEventActions,
    mergeFieldActions,
    replaceTokens,
    getEpochTime,
    truncateString,
} from '../EventUtils';
import FieldSummaryTooltip from './FieldSummaryTooltip';
import type { TimeInterval } from '../EventUtils';

import TimeControlsPopover from './TimeControlsPopover';

// Inspired by VizTableContainer in PureTable.tsx
const EventsContainer = styled.div<{
    width: string | number;
    height: string | number;
    backgroundColor: string;
}>`
    width: ${props => (isNumber(props.width) ? `${props.width}px` : props.width)};
    height: ${props => (isNumber(props.height) ? `${props.height}px` : props.height)};
    box-sizing: border-box;
    padding: ${pick({
        enterprise: variables.spacingHalf,
        prisma: variables.spacingSmall,
    })};
    display: flex;
    flex-flow: column nowrap;
    overflow: hidden;

    /* Added, but is technically handled by FixedSizeContainer. */
    background-color: ${(props): string =>
        (isColor(props.backgroundColor) && props.backgroundColor) || props.theme.defaultBackgroundColor};

    /* Required as odd rows have a transparent background color and would appear as the passed background color otherwise */
    table[data-test='main-table'] {
        background-color: ${pick({
            enterprise: variables.backgroundColorPage,
            prisma: variables.backgroundColorPage,
        })};
    }

    /* CSS override for word wrapping on raw event value - prevents needing to scroll excessively in cases where raw event values don't have spaces to break. */
    table[data-test='main-table'] {
        td:nth-child(3) {
            word-break: break-word;
        }
    }
`;

// Inspired by SUITableContainer in PureTable.tsx
const SUIEventsViewerContainer = styled.div<{
    width: string | number;
    height: string | number;
}>`
    box-sizing: border-box;
    flex-grow: 1;
    overflow: hidden;

    ${({ width, height, theme }: any): string => {
        const padding = pick({
            enterprise: variables.spacingHalf({ theme }),
            prisma: variables.spacingSmall({ theme }),
        })({ theme }) as string;
        const paddingOffset = parseInt(padding, 10) * 2;
        return toDimension({
            width: width - paddingOffset,
            height: height - paddingOffset,
        });
    }};
`;

interface EventsProps {
    // width, height, and backgroundColor optional because of withFixedSizeContainer HOC
    width?: string | number;
    height?: string | number;
    backgroundColor?: string;
    mode?: string;
    dataSources?: { [name: string]: DataSource };
    eventActions?: EventAction[];
    fieldActions?: FieldActions;
    showFieldSummary?: boolean;
    footerFields?: string[];
    highlightValuesByField?: Record<string, string>;
    onOptionsChange?: (data: { [key: string]: string | boolean | Array<number> }) => void;
    onRequestParamsChange?: (
        str: string,
        data: PaginationParamsType & { offset: number; count: number }
    ) => void;
    onEventTrigger?: (...args: any[]) => void;
}

// Effectively the pure visualization component for Events, passing through the necessary state and callbacks.
const PureEvents = (props: EventsProps) => {
    const {
        width,
        height,
        backgroundColor,
        dataSources = {},
        eventActions: eventActionsProps = [],
        fieldActions: fieldActionsProps = {},
        showFieldSummary,
        footerFields,
        highlightValuesByField,
        onOptionsChange,
        onRequestParamsChange,
        onEventTrigger,
    } = props;

    const fieldSummaryMap: { [key: string]: FieldData } = React.useMemo(() => {
        // if the `fieldsummary` datasource isn't configured, no field summary can be shown
        if (!showFieldSummary || !get(dataSources, 'fieldsummary')) {
            return {};
        }

        try {
            const fieldSummaryMapping = getFieldSummaryMapping(
                dataSources.fieldsummary?.data?.columns,
                dataSources.fieldsummary?.data?.fields
            );

            return fieldSummaryMapping;
        } catch (e) {
            console.error(`Unexpected error parsing fieldsummary datasource:  + ${e.message}`);
            return {};
        }
    }, [dataSources, showFieldSummary]);

    const {
        eventActions: eventActionsContext = [],
        fieldActions: fieldActionsContext = {},
    } = useContext<EventsContextInterface>(EventsContext);

    const eventActions = mergeEventActions(eventActionsProps, eventActionsContext);
    const fieldActions = mergeFieldActions(fieldActionsProps, fieldActionsContext);
    const [fieldSummaryField, setFieldSummaryField] = React.useState(null);
    const [fieldSummaryAnchor, setFieldSummaryAnchor] = React.useState(null);
    const [timeValue, setTimeValue] = React.useState(null);
    const [timeFieldAnchor, setTimeFieldAnchor] = React.useState(null);

    // An array of objects with eventtypeValues for filtering by eventtype, fieldFilters for filtering by fields,
    // and eventActionComponent (needs to be Menu.Item and will be rendered inside a Menu component)
    // to display in the Event Actions dropdown
    const renderEventActions: EventActionComponentWithFilters[] = React.useMemo(
        () =>
            !Array.isArray(eventActions)
                ? []
                : eventActions.map(({ eventType, label, eventtypeValues = [], fieldFilters = [] }) => {
                      const onMenuItemClick = (e, eventActionProps, tokenReplacementMap) => {
                          const payload = convertToPayloadFormat(eventActionProps.event, {
                              tokenReplacementMap,
                          });
                          onEventTrigger({
                              originalEvent: e,
                              type: eventType,
                              payload,
                          });
                      };

                      const eventActionComponent = eventActionProps => {
                          const { rowIndex, pageNumber, event } = eventActionProps;
                          const tokenReplacementMap = generateTokenReplacementMap({
                              dataSources,
                              rowIndex,
                              pageNumber,
                              event,
                          });

                          const replacedLabel = replaceTokens({
                              text: label,
                              tokenReplacementMap,
                              allowSpecialParametersEscape: false,
                              allowFieldValuesEscape: false,
                          });

                          const truncatedLabel = truncateString(replacedLabel);

                          return (
                              <Menu.Item
                                  key={`event-action-${truncatedLabel}`}
                                  onClick={e => onMenuItemClick(e, eventActionProps, tokenReplacementMap)}
                              >
                                  {truncatedLabel}
                              </Menu.Item>
                          );
                      };

                      return {
                          eventtypeValues,
                          fieldFilters,
                          eventActionComponent,
                      };
                  }),
        [eventActions]
    );

    // A map of fields, each associated with an array of objects with eventtypeValues for filtering by eventtype,
    // and fieldActionComponent (needs to be Menu.Item and will be rendered inside a Menu component)
    // to display in its respective dropdown in the Actions column
    const renderFieldActions: FieldActionsComponentsWithFilters = React.useMemo(() => {
        if (typeof fieldActions !== 'object') return {};

        const fieldActionsMap = {};
        Object.entries(fieldActions).forEach(
            ([currentFieldName, currentFieldActions]: [string, FieldActions[]]) => {
                fieldActionsMap[currentFieldName] = currentFieldActions.map(
                    ({ eventType, label, eventtypeValues = [] }) => {
                        const onMenuItemClick = (e, fieldActionProps, tokenReplacementMap) => {
                            const payload = convertToPayloadFormat(fieldActionProps.event, {
                                name: fieldActionProps.field,
                                value: fieldActionProps.value,
                                tokenReplacementMap,
                            });

                            onEventTrigger({
                                originalEvent: e,
                                type: eventType,
                                payload,
                            });
                        };

                        const fieldActionComponent = fieldActionProps => {
                            const { field, value, rowIndex, pageNumber, event } = fieldActionProps;
                            const tokenReplacementMap = generateTokenReplacementMap({
                                dataSources,
                                rowIndex,
                                pageNumber,
                                field,
                                value,
                                event,
                            });

                            const replacedLabel = replaceTokens({
                                text: label,
                                tokenReplacementMap,
                                allowSpecialParametersEscape: false,
                                allowFieldValuesEscape: false,
                            });
                            const truncatedLabel = truncateString(replacedLabel);

                            return (
                                <Menu.Item
                                    key={`${currentFieldName}-field-action-${truncatedLabel}`}
                                    onClick={e => onMenuItemClick(e, fieldActionProps, tokenReplacementMap)}
                                >
                                    {truncatedLabel}
                                </Menu.Item>
                            );
                        };
                        return {
                            eventtypeValues,
                            fieldActionComponent,
                        };
                    }
                );
            }
        );
        return fieldActionsMap;
    }, [fieldActions]);

    // formulate the options object required by the SUI EventsViewer component
    const suiEventsViewerOptions: EventsViewerOptions = {
        renderEventActions,
        renderFieldActions,
        footerFields,
        fieldValueHighlightMap: highlightValuesByField,
    };

    const handleFieldClicked =
        showFieldSummary &&
        ((e, { field }) => {
            setFieldSummaryField(field);
            setFieldSummaryAnchor(e.currentTarget.parentElement);
        });

    const handleTimeClicked = (e, { time }) => {
        setTimeValue(time);
        setTimeFieldAnchor(e.currentTarget);
    };

    const handleValueClicked = (e: SyntheticEvent, { field, value }: { field: string; value: string }) => {
        onEventTrigger({
            originalEvent: e,
            type: 'value.click',
            payload: {
                name: field,
                value,
            },
        });
    };

    const handleTimeDrilldown = (e: SyntheticEvent, timeInterval: TimeInterval): void => {
        onEventTrigger({
            originalEvent: e,
            type: 'time.click',
            payload: {
                name: '_time',
                value: timeValue,
                epochTime: getEpochTime(timeValue),
                window: timeInterval,
            },
        });
    };

    const handleActionClicked = (event, data) => {
        const { action: actionType, field } = data;

        onEventTrigger({
            originalEvent: event,
            type: `events.search.${actionType}`,
            payload: {
                field,
            },
        });
    };

    return (
        <EventsContainer backgroundColor={backgroundColor} width={width} height={height}>
            <SUIEventsViewerContainer width={width} height={height}>
                <EventsViewer
                    options={suiEventsViewerOptions}
                    onOptionsChange={onOptionsChange}
                    dataSources={dataSources}
                    onRequestParamsChange={onRequestParamsChange}
                    onFieldClicked={handleFieldClicked}
                    onFieldValueClicked={handleValueClicked}
                    onTimeClicked={handleTimeClicked}
                />
                <FieldSummaryTooltip
                    open={!!fieldSummaryAnchor && !!fieldSummaryField}
                    anchor={fieldSummaryAnchor}
                    handleTooltipClose={() => {
                        setFieldSummaryField(null);
                        setFieldSummaryAnchor(null);
                    }}
                    fieldName={fieldSummaryField}
                    fieldSummaryMap={fieldSummaryMap}
                    onActionClicked={handleActionClicked}
                    onFieldValueClicked={handleValueClicked}
                />
                <TimeControlsPopover
                    open={!!timeFieldAnchor}
                    anchor={timeFieldAnchor}
                    time={timeValue}
                    onTimeDrilldown={handleTimeDrilldown}
                    handleTooltipClose={() => {
                        setTimeValue(null);
                        setTimeFieldAnchor(null);
                    }}
                />
            </SUIEventsViewerContainer>
        </EventsContainer>
    );
};

PureEvents.propTypes = {
    width: T.oneOfType([T.string, T.number]),
    height: T.oneOfType([T.string, T.number]),
    // need this to satisfy props interface
    // eslint-disable-next-line react/no-unused-prop-types
    mode: T.string,
    backgroundColor: T.string,
    eventActions: T.arrayOf(
        T.shape({
            // key to identify this event
            eventType: T.string,
            // label for the menu item
            label: T.string,
            eventtypeValues: T.arrayOf(T.string),
            fieldFilters: T.arrayOf(T.string),
        })
    ),
    fieldActions: T.objectOf(
        T.arrayOf(
            T.shape({
                // key to identify this event
                eventType: T.string,
                // label for the menu item
                label: T.string,
                eventtypeValues: T.arrayOf(T.string),
            })
        )
    ),
    showFieldSummary: T.bool,
    footerFields: T.array,
    highlightValuesByField: T.objectOf(T.string),
    onOptionsChange: T.func,
    dataSources: T.any,
    onRequestParamsChange: T.func,
    onEventTrigger: T.func,
};

PureEvents.defaultProps = {
    width: 800,
    height: 500,
    mode: 'view',
    eventActions: [],
    fieldActions: {},
    dataSources: EMPTY_DATASOURCE,
    showFieldSummary: true,
    footerFields: [],
    highlightValuesByField: {},
    onOptionsChange: noop,
    onRequestParamsChange: noop,
    onEventTrigger: noop,
};

export default withFixedSizeContainer(PureEvents);
