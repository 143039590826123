import React, { Component } from 'react';
import BaseVisualization from '@splunk/dashboard-visualizations/common/BaseVisualization';
import { withVisualizationAndThemeHOCs } from '@splunk/dashboard-visualizations/utils/enhancer';
import { enterprise } from '@splunk/visualization-themes/variables';
import { getClickHandler, enableClickHandler } from '@splunk/dashboard-visualizations/utils/eventUtils';
import ReactSingleValue from '@splunk/react-visualizations/SingleValue';
import { parse, dataContract } from '@splunk/visualization-encoding-parsers/SingleValueParser';
import { SingleValue as SingleValueIcon } from '@splunk/visualization-icons';
import IconPlaceholder from '@splunk/dashboard-visualizations/common/IconPlaceholder';
import { isColor } from '@splunk/visualizations-shared/colorUtils';
import optionsSchema from './optionsSchema';
import editor from './editorConfig';

export const INITIAL_WIDTH = 250;
export const INITIAL_HEIGHT = 250;

export class SingleValueComponent extends Component {
    static propTypes = {
        ...BaseVisualization.propTypes,
    };

    static defaultProps = {
        ...BaseVisualization.defaultProps,
        height: INITIAL_HEIGHT,
    };

    static dataContract = dataContract;

    static vizContract = {
        initialDimension: {
            width: INITIAL_WIDTH,
            height: INITIAL_HEIGHT,
        },
    };

    static schema = optionsSchema;

    static editor = editor;

    render() {
        const {
            dataSources,
            options,
            encoding,
            width,
            height,
            style,
            onEventTrigger,
            mode,
            hasEventHandlers,
            onComputedProps,
        } = this.props;
        const {
            value,
            deltaValue,
            deltaColor,
            graphData,
            backgroundColor: encodingBackgroundColor,
            majorColor = null,
        } = parse(dataSources, encoding);
        const showTrendIndicator = !Number.isNaN(deltaValue) && options.showTrendIndicator !== false;
        // Hide the sparkline if the entire graph is non-numeric
        const showSparkline =
            this.props.options.showSparkline &&
            Array.isArray(graphData) &&
            graphData.filter(v => !Number.isNaN(parseFloat(v))).length > 0;
        const backgroundColor =
            (isColor(encodingBackgroundColor) && encodingBackgroundColor) ||
            (isColor(options.backgroundColor) && options.backgroundColor) ||
            null;
        // Click handler
        const onValueClick = enableClickHandler(hasEventHandlers, mode)
            ? getClickHandler(onEventTrigger, { value, deltaValue })
            : null;
        onComputedProps(backgroundColor ? { backgroundColor } : {});
        return (
            <ReactSingleValue
                width={width}
                height={height}
                style={style}
                // viz props from options (options < encoding)
                {...options}
                // viz props from encoding
                majorColor={majorColor}
                value={value}
                deltaValue={deltaValue}
                deltaColor={deltaColor}
                graphData={graphData}
                // viz props based on options and encoding
                backgroundColor={backgroundColor}
                showTrendIndicator={showTrendIndicator}
                showSparkline={showSparkline}
                // event handler
                onValueClick={onValueClick}
            />
        );
    }
}

export default withVisualizationAndThemeHOCs({
    enableBackgroundColorOption: true,
    defaultBackgroundColor: enterprise.defaultBackgroundColor,
    placeholder: <IconPlaceholder icon={<SingleValueIcon />} />,
})(SingleValueComponent);
