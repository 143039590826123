import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { _ } from '@splunk/ui-utils/i18n';
import { sortBy } from 'lodash';
import { noop } from '@splunk/dashboard-utils';
import { useImageRegistry } from '@splunk/dashboard-context';
import Gallery, { type Item, type GalleryProps } from './Gallery';
import GallerySearch from './GallerySearch';
import GalleryMessage from './GalleryMessage';
import ImageGalleryItem from './ImageGalleryItem';
import GalleryUpload from './GalleryUpload';
import type { RegistryDuckType } from './types/context-types';

export interface ImageItem {
    id: string;
    filename?: string;
    metaData: {
        name: string;
    };
}

const getWarning = (images: Item[], msg: string) => {
    if (Array.isArray(images) && images.length) {
        return undefined;
    }

    return msg;
};

interface ImageGalleryProps {
    providerType?: string;
    onClick?: (itemUrl: Item['url']) => void;
    enableSearch?: boolean;
    setHasChildModal?: GalleryProps['setHasChildModal'];
}

const ImageGallery = ({
    providerType: initialProviderType,
    onClick = noop,
    enableSearch = true,
    setHasChildModal,
}: ImageGalleryProps) => {
    const [images, setImages] = useState<Item[]>([]);
    const [providerType, setProviderType] = useState(initialProviderType);
    const [loading, setLoading] = useState(true);
    const [searching, setSearching] = useState(false);
    const [error, setError] = useState<string>();
    const [warning, setWarning] = useState<string>();

    const imageRegistry = useImageRegistry();

    const getImageRegistry = useCallback(() => {
        if (!imageRegistry) {
            throw new Error(_('No image registry found'));
        }
        return imageRegistry as RegistryDuckType<ImageItem>;
    }, [imageRegistry]);

    useEffect(() => {
        if (!providerType) {
            try {
                const provider = getImageRegistry()
                    .listProviders()
                    .find((type) => type.isDefault);

                if (!provider) {
                    throw new Error('No image providers found');
                }

                setProviderType(provider.type);
            } catch (err) {
                if (err instanceof Error) {
                    setError(err.message);
                }
            }
        }
    }, [providerType, getImageRegistry]);

    /**
     * Returns a concatenated array of images from all providers
     * @param {Object} options
     * @returns {Promise<Array>}
     */
    const getImagesFromAllProviders = useCallback(
        async (options?: Record<string, unknown>) => {
            const registry = getImageRegistry();
            const providers = registry.listProviders();
            const imageLists = await Promise.all(
                providers.map(async (provider) => {
                    const { type } = provider;
                    const ids = await registry.listIds(type, {
                        ...options,
                        meta: ['name'],
                    });
                    return ids.map((id) => ({ ...id, providerType: type }));
                })
            );

            return imageLists.reduce(
                (imageList, currentList) => imageList.concat(currentList),
                []
            );
        },
        [getImageRegistry]
    );

    const fetchImages = useCallback(async () => {
        if (!providerType) {
            setLoading(false);
            return;
        }

        try {
            const ids = await getImagesFromAllProviders();

            const newImages: Item[] = ids.map((idObj) => ({
                url: `${idObj.providerType}://${idObj.id}`,
                name: idObj.metaData.name
                    ? idObj.metaData.name.split('.')[0]
                    : _('Unnamed image'),
            }));

            setImages(sortBy(newImages, ['name']));
            setError(undefined);
            setLoading(false);
            setWarning(
                getWarning(newImages, _('No images. Please upload images.'))
            );
        } catch (err) {
            if (err instanceof Error) {
                setError(err.message);
            }
            setLoading(false);
        }
    }, [providerType, getImagesFromAllProviders]);

    useEffect(() => {
        fetchImages();
    }, [fetchImages]);

    const handleImageUpload = useCallback(
        (files: File[]) => {
            if (!providerType) {
                return;
            }
            setLoading(true);
            files.forEach((file) => {
                const fileReader = new FileReader();
                fileReader.onload = async () => {
                    const fileName = file.name;
                    try {
                        const imageURL = await getImageRegistry().upload(
                            fileReader.result,
                            { name: fileName },
                            providerType
                        );
                        setImages((currentImages) =>
                            sortBy(
                                [
                                    ...currentImages,
                                    {
                                        url: imageURL,
                                        name: fileName,
                                    },
                                ],
                                ['name']
                            )
                        );
                        setWarning(undefined);
                        setError(undefined);
                        setLoading(false);
                    } catch (err) {
                        if (err instanceof Error) {
                            setError(err.message);
                        }
                        setLoading(false);
                    }
                };

                fileReader.readAsDataURL(file);
            });
        },
        [getImageRegistry, providerType]
    );

    /**
     * Handle searching images from all providers
     */
    const handleSearch = useCallback(
        async (searchStr = '') => {
            setSearching(true);

            try {
                const ids = await getImagesFromAllProviders({
                    search: searchStr.trim(),
                });

                const newImages = ids.map((idObj) => ({
                    url: `${idObj.providerType}://${idObj.id}`,
                    name: idObj.filename
                        ? idObj.filename.split('.')[0]
                        : idObj.filename || idObj.metaData.name,
                }));

                setImages(sortBy(newImages, ['name']));
                setError(undefined);
                setSearching(false);
                setWarning(getWarning(newImages, _('No images found.')));
            } catch (err) {
                if (err instanceof Error) {
                    setError(err.message);
                }
                setSearching(false);
            }
        },
        [getImagesFromAllProviders]
    );

    const handleDelete = useCallback(
        async (deletedImage: Item) => {
            setLoading(true);
            try {
                await getImageRegistry().deleteByURL(deletedImage.url);
                const newImages = images.filter(
                    (image) => image.url !== deletedImage.url
                );
                setImages(newImages);
                setLoading(false);
                setWarning(
                    getWarning(newImages, _('No images. Please upload images.'))
                );
            } catch (err) {
                if (err instanceof Error) {
                    setError(err.message);
                }
                setLoading(false);
            }
        },
        [images, getImageRegistry]
    );

    const handleItemClick = useCallback(
        (url: string) => onClick(url),
        [onClick]
    );

    const search = useMemo(
        () => (
            <GallerySearch
                placeholder={_('Search Images')}
                onSearch={handleSearch}
            />
        ),
        [handleSearch]
    );

    const message = useMemo(
        () => <GalleryMessage error={error} warning={warning} />,
        [error, warning]
    );

    return (
        <Gallery
            testId="image-gallery"
            isLoading={loading || searching}
            // Do not display search input if it is explicitly disabled, there is an error,
            // or images have not been initialized (null)
            search={enableSearch && !error && images ? search : null}
            message={message}
            items={images}
            Item={ImageGalleryItem}
            upload={
                error ? null : (
                    <GalleryUpload
                        fileTypes=".jpeg,.png,.gif"
                        onUpload={handleImageUpload}
                    />
                )
            }
            onItemClick={handleItemClick}
            onItemRemove={handleDelete}
            setHasChildModal={setHasChildModal}
            galleryType="image"
        />
    );
};

export default ImageGallery;
