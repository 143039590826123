import { mapKeys } from 'lodash';
import styled from 'styled-components';
import pick from '@splunk/themes/pick';
import variables from '@splunk/themes/variables';
import { formatColor } from '@splunk/visualizations-shared/propUtils';

interface ShapeOptions {
    [key: string]: any;
}

// map for new options names to old option names
const shapeOptionsMapping = {
    fillColor: 'fill',
    strokeColor: 'stroke',
    strokeDashStyle: 'strokeDasharray',
    strokeJoinStyle: 'strokeLinejoin',
};

/**
 * Helper method to update the option names to old option names
 * @method mapToOldKey
 * @param {Object} options
 * @returns {Object}
 */
export const mapToOldKey = (options: ShapeOptions): ShapeOptions =>
    mapKeys(options, (val, key) => (shapeOptionsMapping[key] ? shapeOptionsMapping[key] : key));

type Shape = 'ellipse' | 'rect';

/**
 * Helper method to get styled component for ellipse or rectangle
 * @method mapToOldKey
 * @param {Shape} shape
 */
export const getStyledShape = (shape: Shape) => styled(shape)`
    fill: ${props =>
        (props.fill && formatColor(props.fill)) ||
        pick({
            enterprise: {
                light: variables.gray80,
                dark: variables.gray25,
            },
            prisma: variables.backgroundColorSidebar,
        })(props)};
    stroke: ${props =>
        (props.stroke && formatColor(props.stroke)) ||
        pick({
            enterprise: {
                dark: variables.gray80,
                light: variables.textColor,
            },
            prisma: variables.neutral500,
        })(props)};
    cursor: ${props => (props.onClick ? 'pointer' : 'inherit')};
`;
