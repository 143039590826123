import intersection from 'lodash/intersection';
import difference from 'lodash/difference';
import every from 'lodash/every';

interface OptionsArgs {
    includeKeys?: string[];
    omitKeys?: string[];
}

type Comparable = Record<string, unknown>;

export default (
    obj1: Comparable,
    obj2: Comparable,
    { includeKeys = [], omitKeys = [] }: OptionsArgs = {}
): boolean => {
    if (obj1 === obj2) {
        return true;
    }
    let keysA = Object.keys(obj1);
    let keysB = Object.keys(obj2);
    if (includeKeys.length) {
        keysA = intersection(keysA, includeKeys);
        keysB = intersection(keysB, includeKeys);
    }
    if (omitKeys.length) {
        keysA = difference(keysA, omitKeys);
        keysB = difference(keysB, omitKeys);
    }

    return (
        keysA.length === keysB.length &&
        every(keysA, (key) => obj1[key] === obj2[key])
    );
};
