import React from 'react';
import { _ } from '@splunk/ui-utils/i18n';
import SVG, {
    type SVGPropsOptionalViewBox,
} from '@splunk/react-icons/SVGEnterprise';

/* eslint-disable max-len */
export default function Hide(
    props: SVGPropsOptionalViewBox
): ReturnType<typeof SVG> {
    return (
        <SVG viewBox="0 0 24 24" screenReaderText={_('Hide')} {...props}>
            <path
                fill="currentColor"
                d="M14.25 12a2.25 2.25 0 1 1-4.5 0 2.25 2.25 0 0 1 4.5 0z"
            />
            <path
                fill="currentColor"
                fillRule="evenodd"
                d="M3.013 11.86a.748.748 0 0 1 .113-.557c1.31-1.966 3.778-4.383 6.947-5.097 3.261-.735 7.032.382 10.763 5.045.142.178.19.4.15.609a.748.748 0 0 1-.15.609c-3.73 4.662-7.502 5.779-10.763 5.044-3.169-.714-5.636-3.131-6.947-5.097a.748.748 0 0 1-.113-.556zm4.902-3.158c-1.401.855-2.559 2.046-3.35 3.158a11.919 11.919 0 0 0 2.981 2.92A5.225 5.225 0 0 1 6.75 12c0-1.25.436-2.397 1.165-3.298zm8.676 5.847c.91-.658 1.848-1.539 2.806-2.69-1.07-1.285-2.116-2.233-3.125-2.911.616.86.978 1.914.978 3.052 0 .925-.24 1.794-.66 2.55zM15.75 12a3.75 3.75 0 1 1-7.5 0 3.75 3.75 0 0 1 7.5 0z"
            />
            <path
                fill="currentColor"
                d="M21.53 2.47a.75.75 0 0 1 0 1.06l-18 18a.75.75 0 0 1-1.06-1.06l18-18a.75.75 0 0 1 1.06 0z"
            />
        </SVG>
    );
}
