import * as React from 'react';
import { _ } from '@splunk/ui-utils/i18n';
import SVG from '@splunk/react-icons/SVGEnterprise';

const Bubble = (props: React.ComponentProps<typeof SVG>): React.ReactElement<SVGElement> => (
    <SVG screenReaderText={_('Bubble')} fill="currentColor" viewBox="0 0 150 100" {...props}>
        <g>
            <path d="M39 42C45.0751 42 50 37.0751 50 31C50 24.9249 45.0751 20 39 20C32.9249 20 28 24.9249 28 31C28 37.0751 32.9249 42 39 42Z" />
            <path d="M12.5 57C15.5376 57 18 54.5376 18 51.5C18 48.4624 15.5376 46 12.5 46C9.46243 46 7 48.4624 7 51.5C7 54.5376 9.46243 57 12.5 57Z" />
            <path d="M76 50C76 51.6569 74.6569 53 73 53C71.3431 53 70 51.6569 70 50C70 48.3431 71.3431 47 73 47C74.6569 47 76 48.3431 76 50Z" />
            <path d="M13 81C14.6569 81 16 79.6569 16 78C16 76.3431 14.6569 75 13 75C11.3431 75 10 76.3431 10 78C10 79.6569 11.3431 81 13 81Z" />
            <path d="M143 28C143 29.6569 141.657 31 140 31C138.343 31 137 29.6569 137 28C137 26.3431 138.343 25 140 25C141.657 25 143 26.3431 143 28Z" />
            <path d="M55 57C59.4183 57 63 53.4183 63 49C63 44.5817 59.4183 41 55 41C50.5817 41 47 44.5817 47 49C47 53.4183 50.5817 57 55 57Z" />
            <path d="M52 65C52 69.4183 48.4183 73 44 73C39.5817 73 36 69.4183 36 65C36 60.5817 39.5817 57 44 57C48.4183 57 52 60.5817 52 65Z" />
            <path d="M119 55C123.418 55 127 51.4183 127 47C127 42.5817 123.418 39 119 39C114.582 39 111 42.5817 111 47C111 51.4183 114.582 55 119 55Z" />
            <path d="M91 65.5C91 67.9853 88.9853 70 86.5 70C84.0147 70 82 67.9853 82 65.5C82 63.0147 84.0147 61 86.5 61C88.9853 61 91 63.0147 91 65.5Z" />
            <path d="M126.5 66C129.538 66 132 63.5376 132 60.5C132 57.4624 129.538 55 126.5 55C123.462 55 121 57.4624 121 60.5C121 63.5376 123.462 66 126.5 66Z" />
            <path d="M111 40.5C111 50.165 103.165 58 93.5 58C83.835 58 76 50.165 76 40.5C76 39.3598 76.109 38.245 76.3173 37.1656C75.33 37.6979 74.2003 38 73 38C69.134 38 66 34.866 66 31C66 27.134 69.134 24 73 24C76.3684 24 79.1811 26.3791 79.8493 29.5487C83.0568 25.5558 87.9799 23 93.5 23C103.165 23 111 30.835 111 40.5Z" />
        </g>
    </SVG>
);

export default Bubble;
