import React from 'react';
import styled from 'styled-components';
import T from 'prop-types';

const AbsoluteDiv = styled.div.attrs(({ x, y, w, h }) => ({
    style: {
        width: w,
        height: h,
        top: `${y}px`,
        left: `${x}px`,
    },
}))`
    display: block;
    position: absolute;
    background: transparent;
`;

/**
 * Absolute positioned item
 * @param {*} param0
 */
const AbsoluteItem = ({
    itemId,
    type = 'block',
    x,
    y,
    w,
    h,
    renderLayoutItem,
}) => (
    <AbsoluteDiv
        x={x}
        y={y}
        w={w}
        h={h}
        data-test="absolute-item"
        data-x={x}
        data-y={y}
        data-w={w}
        data-h={h}
    >
        {renderLayoutItem(
            itemId,
            {
                width: w,
                height: h,
            },
            type
        )}
    </AbsoluteDiv>
);

AbsoluteItem.propTypes = {
    itemId: T.string.isRequired,
    type: T.string,
    x: T.number.isRequired,
    y: T.number.isRequired,
    w: T.number.isRequired,
    h: T.number.isRequired,
    renderLayoutItem: T.func.isRequired,
};

export default AbsoluteItem;
