/**
 * This file is a central location for all shared utility functions related to Grid Layout.
 * If a certain function requires a dependency that lives in another utility file, then
 * that function should live here. If it does not have any dependencies, then it can live
 * in a separate utility file.
 */

import { isEmpty, isEqual } from 'lodash';
import { _ } from '@splunk/ui-utils/i18n';
import {
    updateRemovedVizNeighbors,
    isLeftNeighbor,
    isRightNeighbor,
    findHorizontalNeighbors,
    isTopNeighbor,
    isBottomNeighbor,
} from '@splunk/dashboard-utils';
import type {
    AbsoluteBlockItem,
    Coordinate,
    AbsolutePosition,
} from '@splunk/dashboard-types';

import { updateBlockItemSize } from './layoutUtils';
import { MIN_WIDTH_PX, MIN_HEIGHT_PX } from '../GridLayoutConstants';

import type { EdgeItem, LayoutError, Quadrant } from '../types';

/**
 * Return a wrapper for the edge, such that this wrapper fills the entire gutter between 2 viz
 * @param {Object} params
 * @param {EdgeItem} params.edge - The edge that this wrapper will go "around"
 * @param {Number} params.padding - The amount of padding between two visualizations
 * @returns {EdgeItem} - Formatted edge such that it fills the gutter space between two visualizations
 */
export const formatEdgeWrapper = ({
    edge,
    padding = 0,
}: {
    edge: EdgeItem;
    padding?: number;
}): EdgeItem => {
    const wrapperStart: Coordinate = { ...edge.edgeStart };
    const wrapperEnd: Coordinate = { ...edge.edgeEnd };
    if (edge.orientation === 'horizontal') {
        // Add padding to the ends of the edge, to make it line up with visualizations
        wrapperStart.x += padding;
        wrapperEnd.x -= padding;
        // make edge y the top of the gutter (so that when thickness === 2*padding it will fill whole gutter)
        wrapperStart.y -= padding;
        wrapperEnd.y -= padding;
    } else {
        // Add padding to the ends of the edge, to make it line up with visualizations
        wrapperStart.y += padding;
        wrapperEnd.y -= padding;
        // make edge x the left-most of the gutter (so that when thickness === 2*padding it will fill whole gutter)
        wrapperStart.x -= padding;
        wrapperEnd.x -= padding;
    }

    return { ...edge, edgeStart: wrapperStart, edgeEnd: wrapperEnd };
};

export const getDimensions = ({
    edge,
    thickness,
}: {
    edge: EdgeItem;
    thickness: number;
}): { width: number; height: number } => {
    return {
        width:
            edge.orientation === 'horizontal'
                ? edge.edgeEnd.x - edge.edgeStart.x
                : thickness,
        height:
            edge.orientation === 'vertical'
                ? edge.edgeEnd.y - edge.edgeStart.y
                : thickness,
    };
};

/**
 * Check whether the mouse is on top of the edge
 * @param {Object} params
 * @param {EdgeItem} params.edge - The edge that is being checked
 * @param {Coordinate} params.mousePosition - The position of the mouse on the screen, scaled
 * @param {Number} params.padding - The amount of padding between two visualizations
 * @returns {boolean}
 */
export const isMouseOnEdge = ({
    edge,
    mousePosition,
    padding = 0,
}: {
    edge: EdgeItem;
    mousePosition: Coordinate;
    padding?: number;
}): boolean => {
    const formattedEdge = formatEdgeWrapper({ edge, padding });
    const { width, height } = getDimensions({
        edge: formattedEdge,
        thickness: 2 * padding,
    });
    return (
        mousePosition.x <= formattedEdge.edgeStart.x + width &&
        mousePosition.x >= formattedEdge.edgeStart.x &&
        mousePosition.y <= formattedEdge.edgeStart.y + height &&
        mousePosition.y >= formattedEdge.edgeStart.y
    );
};

/**
 * When dropping a viz on a full-width edge, shift all the visualizations below that edge down
 * instead of taking size from the nearby visualizations
 * @param {Object}
 * @param {EdgeItem} params.edge - The edge that is being dropped on. Spans full width of canvas
 * @param {AbsoluteBlockItem} params.itemToMove - The item that is being removed
 * @param {AbsoluteBlockItem[]} params.items - All the items on the canvas
 * @returns {AbsoluteBlockItem[]} - Array of updated items, shifted down to create space for the added viz.
 */
export const computeNewVizPositionsGutterCase = ({
    edge,
    itemToMove,
    items,
}: {
    edge: EdgeItem;
    itemToMove: AbsoluteBlockItem;
    items: AbsoluteBlockItem[];
}): AbsoluteBlockItem[] => {
    const updatedVisualizations: AbsoluteBlockItem[] = [];
    items.forEach((item) => {
        if (item.position.y >= edge.edgeStart.y) {
            const viz =
                item.item === itemToMove.item
                    ? { ...item, item: 'itemToRemove' }
                    : item;

            updatedVisualizations.push({
                ...viz,
                position: {
                    ...viz.position,
                    y: viz.position.y + itemToMove.position.h,
                },
            });
        }
    });
    // Now let's push the moved viz on the updatedVisualizations array
    updatedVisualizations.push({
        ...itemToMove,
        position: {
            ...itemToMove.position, // height is preserved
            y: edge.edgeStart.y,
            x: edge.edgeStart.x,
            w: edge.edgeEnd.x - edge.edgeStart.x,
        },
    });

    return updatedVisualizations;
};

type AccumulatedSize = {
    firstHalf: number[];
    secondHalf: number[];
} | null;

/**
 * Determine the amount to take from each viz surrounding an edge that itemToMove is being dropped on
 * @param {Object}
 * @param {EdgeItem} params.edge - The edge that is being dropped on
 * @param {Number} params.amountToTake - The proportion of each viz to take. ex: 1/3
 * @param {Number} params.minWidth - Minimum width a viz can have
 * @param {Number} params.minHeight - Minimum height a viz can have
 * @returns {Object}
 */
export const sizeToTakeFromViz = ({
    amountToTake,
    edge,
    minWidth,
    minHeight,
}: {
    amountToTake: number;
    edge: EdgeItem;
    minWidth: number;
    minHeight: number;
}): {
    firstHalf: number | null;
    secondHalf: number | null;
} => {
    // When a visualization is dropped, it takes `amountToTake` from each visualization. Thus
    //   it gets 1 size (firstHalf) from one side, and another (secondHalf) from the other side of the edge.
    //    It's not entirely accurate to call them halves, as they are not typically equal.
    const reduction = edge.visualizations.reduce<AccumulatedSize>(
        (acc: AccumulatedSize, viz: AbsoluteBlockItem): AccumulatedSize => {
            if (acc === null) {
                return null;
            }
            if (edge.orientation === 'vertical') {
                const widthToTake = Math.ceil(viz.position.w * amountToTake);
                if (viz.position.w - widthToTake < minWidth) {
                    return null; // if viz is too small, return null
                }
                if (viz.position.x < edge.edgeStart.x) {
                    return {
                        ...acc,
                        firstHalf: [...acc.firstHalf, widthToTake],
                    };
                }
                return {
                    ...acc,
                    secondHalf: [...acc.secondHalf, widthToTake],
                };
            }
            // if edge.orientation === horizontal
            const heightToTake = Math.ceil(viz.position.h * amountToTake);
            if (viz.position.h - heightToTake < minHeight) {
                return null;
            }
            if (viz.position.y < edge.edgeStart.y) {
                return {
                    ...acc,
                    firstHalf: [...acc.firstHalf, heightToTake],
                };
            }
            return {
                ...acc,
                secondHalf: [...acc.secondHalf, heightToTake],
            };
        },
        { firstHalf: [], secondHalf: [] }
    );

    if (reduction === null) {
        // then there was an invalid edge drop because a viz is too small to give its size
        // return null to indicate invalid edge drop
        return {
            firstHalf: null,
            secondHalf: null,
        };
    }

    // If there were no viz on one of the sides, return 0 for the amount to take from that side
    // Otherwise, return the minimum amount to take, to respect the smallest viz on that side
    return {
        firstHalf:
            reduction.firstHalf.length === 0
                ? 0
                : Math.min(...reduction.firstHalf),
        secondHalf:
            reduction.secondHalf.length === 0
                ? 0
                : Math.min(...reduction.secondHalf),
    };
};

/**
 * When dropping a viz on a normal edge (not full canvas width), take a proportion from each of the visualizations
 * around the edge, updating those items and inserting the moved viz in the space created
 * @param {Object}
 * @param {EdgeItem} params.edge - The edge that is being dropped on. Spans full width of canvas
 * @param {AbsoluteBlockItem} params.itemToMove - The item that is being moved
 * @param {Number} params.firstHalf - The amount of space moved viz will take up on the left/top sides
 * @param {Number} params.secondHalf - The amount of space moved viz will take up on the right/bottom sides
 * @returns {AbsoluteBlockItem[]} - Array of updated items after the moved viz was added
 */
export const computeNewVizPositions = ({
    edge,
    itemToMove,
    firstHalf,
    secondHalf,
}: {
    edge: EdgeItem;
    itemToMove: AbsoluteBlockItem;
    firstHalf: number;
    secondHalf: number;
}): AbsoluteBlockItem[] => {
    // add width to the visualizations surrounding the edge
    const updatedVisualizations = [];

    // Update the visualizations touching the edge that is being dropped on
    edge.visualizations.forEach((edgeViz) => {
        // Check if the viz is the one that is being moved. This would be the old position of it,
        // since we still need to update the "placeholder".
        const viz =
            edgeViz.item === itemToMove.item
                ? { ...edgeViz, item: 'itemToRemove' }
                : edgeViz;

        if (edge.orientation === 'horizontal') {
            if (viz.position.y < edge.edgeStart.y) {
                updatedVisualizations.push(
                    updateBlockItemSize({
                        item: {
                            ...viz,
                        },
                        offset: { offsetY: -1 * firstHalf, offsetX: 0 },
                        dir: 's',
                    })
                );
            }
            if (viz.position.y >= edge.edgeStart.y) {
                updatedVisualizations.push(
                    updateBlockItemSize({
                        item: {
                            ...viz,
                        },
                        offset: { offsetY: secondHalf, offsetX: 0 },
                        dir: 'n',
                    })
                );
            }
        } else {
            if (viz.position.x < edge.edgeStart.x) {
                updatedVisualizations.push(
                    updateBlockItemSize({
                        item: {
                            ...viz,
                        },
                        offset: { offsetX: -1 * firstHalf, offsetY: 0 },
                        dir: 'e',
                    })
                );
            }
            if (viz.position.x >= edge.edgeStart.x) {
                updatedVisualizations.push(
                    updateBlockItemSize({
                        item: {
                            ...viz,
                        },
                        offset: { offsetX: secondHalf, offsetY: 0 },
                        dir: 'w',
                    })
                );
            }
        }
    });

    // update the moving viz to the new position
    // The offsets represent how much to the left/right (same as up/down)
    //  to offset the new viz from the EDGE position
    updatedVisualizations.push({
        ...itemToMove,
        position: {
            x:
                edge.edgeStart.x -
                (edge.orientation === 'vertical' ? firstHalf : 0),
            y:
                edge.edgeStart.y -
                (edge.orientation === 'horizontal' ? firstHalf : 0),
            w:
                edge.orientation === 'vertical'
                    ? firstHalf + secondHalf
                    : edge.edgeEnd.x - edge.edgeStart.x,
            h:
                edge.orientation === 'horizontal'
                    ? firstHalf + secondHalf
                    : edge.edgeEnd.y - edge.edgeStart.y,
        },
    });
    return updatedVisualizations;
};

const isResultingVizSizeGreaterThanMin = ({
    size,
    edgeOrientation,
}: {
    size: number;
    edgeOrientation: EdgeItem['orientation'];
}): boolean => {
    if (edgeOrientation === 'vertical') {
        return size >= MIN_WIDTH_PX;
    }
    return size >= MIN_HEIGHT_PX;
};

/**
 * updates the Items into a valid Grid Layout Items List
 * @param {Object} params
 * @param {Object} params.updatedVisualizations visualizations that have been updated
 * @param {Object} params.itemToMove visualization that is being moved (removed)
 * @param {Array} params.items list of visualization items
 * @param {Number} params.canvasWidth width of canvas
 * @returns {Array{}} - returns a combined array of Viz Item Objects
 */
export const updateItems = ({
    updatedVisualizations,
    itemToMove,
    items,
    canvasWidth,
}: {
    updatedVisualizations: AbsoluteBlockItem[];
    itemToMove: AbsoluteBlockItem;
    items: AbsoluteBlockItem[];
    canvasWidth: number;
}): AbsoluteBlockItem[] => {
    // make sure to use the up to date item instead of the old
    const updatedItems = [
        ...items.filter(
            (item) =>
                updatedVisualizations.find((viz) => viz.item === item.item) ===
                undefined
        ),
        ...updatedVisualizations,
    ];

    const updatedNeighbors = updateRemovedVizNeighbors({
        itemToRemove: itemToMove,
        items: updatedItems,
        width: canvasWidth,
    });

    // Now that we've updated the neighbors of the removed viz, we need
    // to make sure we use the most up to date viz (since updatedVisualizations could potentially be stale)
    const filteredUpdatedVisualizations = updatedVisualizations.filter(
        (viz) =>
            updatedNeighbors.find((neighbor) => neighbor.item === viz.item) ===
                undefined && viz.item !== 'itemToRemove'
    );

    // items are all visualizations, filter out:
    // 1. the visualizations we updated when we removed the viz from its initial position (updatedNeighbors)
    // 2. the visualizations we updated when we inserted viz into its new position (filteredUpdatedVisualizations)
    // 3. the visualization that was moved to its new position (itemToMove)
    const filteredItems = items.filter(
        (viz) =>
            updatedNeighbors.find((neighbor) => neighbor.item === viz.item) ===
                undefined &&
            filteredUpdatedVisualizations.find(
                (updatedViz) => updatedViz.item === viz.item
            ) === undefined &&
            viz.item !== itemToMove.item
    );
    return [
        ...filteredItems,
        ...filteredUpdatedVisualizations,
        ...updatedNeighbors,
    ];
};

/**
 * check if the moving item is dropping on its own edge and the edge has same width/height as item
 * @param {Object} params
 * @param {Object} edge edge being dropped onto
 * @param {Object} itemToMove visualization item being moved
 * @return {Boolean}
 */
export const isDropOnOwnEdge = ({
    edge,
    itemToMove,
}: {
    edge: EdgeItem;
    itemToMove: AbsoluteBlockItem;
}): boolean =>
    edge.visualizations.find((viz) => viz.item === itemToMove.item) != null &&
    ((edge.orientation === 'horizontal' &&
        itemToMove.position.w === edge.edgeEnd.x - edge.edgeStart.x) ||
        (edge.orientation === 'vertical' &&
            itemToMove.position.h === edge.edgeEnd.y - edge.edgeStart.y));

/**
 * Calculates new position values for visualizations being affected from a Visualization being dropped on an Edge
 * @param {Object} params
 * @param {Object} edge edge being dropped onto
 * @param {Object} itemToMove visualization Item being moved
 * @param {Object[]} items array of all visualization Items
 * @param {String} canvasWidth width of canvas
 * @return {Object[]} object of the list of updated visualizations items and updated itemToMove object
 */
export const updateDropOnEdge = ({
    edge,
    itemToMove,
    items,
    canvasWidth,
}: {
    edge: EdgeItem;
    itemToMove: AbsoluteBlockItem;
    items: AbsoluteBlockItem[];
    canvasWidth: number;
}): {
    updatedVisualizations: AbsoluteBlockItem[];
    updatedItemToMove: AbsoluteBlockItem;
} | null => {
    // Do not let user drop a viz on an edge it owns (exactly spans the width/height of that viz)
    if (isDropOnOwnEdge({ edge, itemToMove })) {
        return null;
    }

    // The amount to take from each surrounding visualization
    const amountToTake = 1 / 3;

    let updatedVisualizations = [];
    if (
        edge.orientation === 'horizontal' &&
        edge.edgeEnd.x - edge.edgeStart.x === canvasWidth
    ) {
        // if we are dropping the visualization on an edge that spans the full width of
        //  the canvas, then we have different behavior: shift all visualizations down
        updatedVisualizations = computeNewVizPositionsGutterCase({
            edge,
            itemToMove,
            items,
        });
    } else {
        // Check if the visualizations surrounding the drop-target edge have enough space to give in order to insert the moving viz
        // The moved viz gets one half of its size from the visualizations on one side of the edge, and the second half from the
        //  visualizations on the other side of the edge
        const { firstHalf, secondHalf } = sizeToTakeFromViz({
            amountToTake,
            edge,
            minWidth: MIN_WIDTH_PX,
            minHeight: MIN_HEIGHT_PX,
        });
        if (
            firstHalf === null ||
            secondHalf === null ||
            // Check if the moved viz resulting size will be greater than the minimum width/height
            // This particular case occurs when adding to the top and bottom canvas edge
            !isResultingVizSizeGreaterThanMin({
                size: firstHalf + secondHalf,
                edgeOrientation: edge.orientation,
            })
        ) {
            // invalid edge drop, one of the visualizations was too small to give up size
            return null;
        }
        updatedVisualizations = computeNewVizPositions({
            edge,
            itemToMove,
            firstHalf,
            secondHalf,
        });
    }

    // If the edge we move to caused an update to the old position of itemToMove, we need to update that
    // position in order to correctly handle removing the viz in that old position.
    const updatedItemToMove =
        updatedVisualizations.find((viz) => viz.item === 'itemToRemove') ||
        itemToMove;

    return { updatedVisualizations, updatedItemToMove };
};

/**
 * Given a rectangular Item, determine whether the position is within bounds of the item
 * @param {Object} params
 * @param {Object} params.item visualization item being hovered over
 * @param {Object} params.position position of the mouse over the visualization
 * @returns {Boolean} whether the position is in bounds of the Item
 */
export const positionInItemBoundary = ({
    item,
    position,
}: {
    item: AbsoluteBlockItem;
    position: Coordinate;
}): boolean => {
    return (
        position.x >= item.position.x &&
        position.x <= item.position.x + item.position.w &&
        position.y >= item.position.y &&
        position.y <= item.position.y + item.position.h
    );
};

/**
 * Given a rectangular Item, find the quadrant where position is located on an item
 * where the quadrants are divided by diagonal lines creating four triangles
 * Dividing Line 1: (start: {top left corner}, end: {bottom right corner}) y = f(x) = slope * x
 * Dividing Line 2: (start: {bottom left corner}, end: {top right corner}) y = f(x) = -slope * x
 * @param {Object} params
 * @param {Object} params.item visualization item being hovered over
 * @param {Object} params.position position of the mouse over the visualization
 * @returns {String} returns the quadrant position is in Item oneOf('n', 's', 'w', 'e'), if not in quadrant it returns null
 */
export const findQuadrant = ({
    item,
    position,
}: {
    item: AbsoluteBlockItem;
    position: Coordinate;
}): Quadrant | null => {
    if (positionInItemBoundary({ item, position })) {
        let { x, y } = position;
        x -= item.position.x;
        y -= item.position.y;
        const slope = item.position.h / item.position.w;
        let quadrant: Quadrant[];
        if (y > slope * x) {
            quadrant = ['w', 's'];
        } else {
            quadrant = ['n', 'e'];
        }
        // If the slope of one diagonal is f(x) = (h/w) * x then the slope of the other (intersecting)
        // diagonal is f(x) = -(h/w) * x + h, so we use the later to determine last quadrant
        return y < -slope * x + item.position.h ? quadrant[0] : quadrant[1];
    }
    return null;
};

/**
 * Calculates the updated position values for the viz being moved and dropped onto
 * @param {Object} params
 * @param {Object} params.itemToMove item that is being moved
 * @param {Object} params.itemToDropOn item that the visualization is being dropped onto
 * @param {String} direction direction item is dropped on oneOf('n', 's', 'w', 'e')
 * @returns {Object[]} an Array containing updated itemToMove and itemToDropOn
 */
export const updateDropOnViz = ({
    itemToMove,
    itemToDropOn,
    direction,
}: {
    itemToMove: AbsoluteBlockItem;
    itemToDropOn: AbsoluteBlockItem;
    direction: Quadrant;
}): [AbsoluteBlockItem, AbsoluteBlockItem] | null => {
    if (
        ((direction === 'e' || direction === 'w') &&
            itemToDropOn.position.w < MIN_WIDTH_PX * 2) ||
        ((direction === 'n' || direction === 's') &&
            itemToDropOn.position.h < MIN_WIDTH_PX * 2)
    ) {
        return null;
    }
    let vizToMove = {
        ...itemToMove,
        position: itemToDropOn.position,
    };
    let vizTarget = { ...itemToDropOn };
    switch (direction) {
        case 'n':
            vizToMove = updateBlockItemSize({
                item: vizToMove,
                offset: {
                    offsetY: -Math.floor(vizToMove.position.h / 2),
                    offsetX: 0,
                },
                dir: 's',
            });
            vizTarget = updateBlockItemSize({
                item: vizTarget,
                offset: {
                    offsetY: Math.ceil(vizTarget.position.h / 2),
                    offsetX: 0,
                },
                dir: 'n',
            });
            break;
        case 's':
            vizToMove = updateBlockItemSize({
                item: vizToMove,
                offset: {
                    offsetY: Math.floor(vizToMove.position.h / 2),
                    offsetX: 0,
                },
                dir: 'n',
            });
            vizTarget = updateBlockItemSize({
                item: vizTarget,
                offset: {
                    offsetY: -Math.ceil(vizTarget.position.h / 2),
                    offsetX: 0,
                },
                dir: 's',
            });
            break;
        case 'w':
            vizToMove = updateBlockItemSize({
                item: vizToMove,
                offset: {
                    offsetX: -Math.floor(vizToMove.position.w / 2),
                    offsetY: 0,
                },
                dir: 'e',
            });
            vizTarget = updateBlockItemSize({
                item: vizTarget,
                offset: {
                    offsetX: Math.ceil(vizTarget.position.w / 2),
                    offsetY: 0,
                },
                dir: 'w',
            });
            break;
        case 'e':
            vizToMove = updateBlockItemSize({
                item: vizToMove,
                offset: {
                    offsetX: Math.floor(vizToMove.position.w / 2),
                    offsetY: 0,
                },
                dir: 'w',
            });
            vizTarget = updateBlockItemSize({
                item: vizTarget,
                offset: {
                    offsetX: -Math.ceil(vizTarget.position.w / 2),
                    offsetY: 0,
                },
                dir: 'e',
            });
            break;
        default:
            // reset viz to original state so nothing changes
            vizToMove = itemToMove;
            break;
    }

    return [vizToMove, vizTarget];
};

/**
 * Calculates new viz positions after the itemToMove viz is dropped on edge
 * @param {Object} params
 * @param {Object} params.edge edge being dropped onto
 * @param {Object} params.itemToMove visualization Item being moved
 * @param {Object[]} params.items array of all visualization Items
 * @param {String} params.canvasWidth width of canvas
 * @return {Object[]} object of the list of updated visualizations items, updated itemToMove object, and the old itemToMove
 */
export const previewDropOnEdge = ({
    edge,
    itemToMove,
    items,
    canvasWidth,
}: {
    edge: EdgeItem;
    itemToMove: AbsoluteBlockItem;
    items: AbsoluteBlockItem[];
    canvasWidth: number;
}): AbsoluteBlockItem[] | null => {
    const updatedItems = updateDropOnEdge({
        edge,
        itemToMove,
        items,
        canvasWidth,
    });

    if (!updatedItems) {
        // If it's an invalid drop, return null
        return null;
    }

    let { updatedVisualizations } = updatedItems;
    const { updatedItemToMove } = updatedItems;

    // Take out the old visualization from the updated (if it was updated). This is so that
    //  we can add it below with a unique id 'preview-old-item'
    updatedVisualizations = updatedVisualizations.filter(
        (viz) => !isEqual(viz, updatedItemToMove)
    );

    // preview-old-item is the position of the initial item before the move. This is so that we render
    //   empty or a custom component in its place during the preview.
    // For edges, we need this item to correctly re-compute edges around it in the preview.
    return [
        ...items.filter(
            (item) =>
                updatedVisualizations.find((viz) => viz.item === item.item) ===
                undefined
        ),
        ...updatedVisualizations,
        {
            ...updatedItemToMove,
            item: 'preview-old-item',
        },
    ];
};

/**
 * Calculates the updated position values for the viz being moved and dropped onto
 * @param {Object} params
 * @param {Object} params.itemToMove item that is being moved
 * @param {Object} params.itemToDropOn item that the visualization is being dropped onto
 * @param {String} direction direction item is dropped on oneOf('n', 's', 'w', 'e')
 * @param {Object[]} items list of all visualizations on canvas
 * @returns {Object[]} an Array containing updated itemToMove and itemToDropOn
 */
export const previewDropOnViz = ({
    itemToDropOn,
    itemToMove,
    items,
    direction,
}: {
    itemToDropOn: AbsoluteBlockItem;
    itemToMove: AbsoluteBlockItem;
    items: AbsoluteBlockItem[];
    direction: Quadrant;
}): AbsoluteBlockItem[] | null => {
    const updatedItems = updateDropOnViz({
        itemToMove,
        itemToDropOn,
        direction,
    });

    if (!updatedItems) {
        // If it's an invalid drop, return null
        return null;
    }

    // preview-old-item is the position of the initial item before the move. This is so that we render
    //   empty or a custom component in its place during the preview
    return [
        ...items.filter(
            (item) =>
                updatedItems.find((viz) => viz.item === item.item) === undefined
        ),
        ...updatedItems,
        {
            ...itemToMove,
            item: 'preview-old-item',
        },
    ];
};

/**
 * Finds items that live outside the canvas boundary, and returns an error message for each item.
 * @param {Object} params
 * @param {Object[]} params.items items in the layout structure of the dashboard
 * @param {Object} params.boundary canvas boundary { x, y, w, h }
 * @returns {Object[]} array of objects out of bounds
 */
export const findItemsOutsideBoundary = ({
    items,
    boundary,
}: {
    items: AbsoluteBlockItem[];
    boundary: AbsolutePosition;
}): LayoutError[] => {
    const invalidItems: LayoutError[] = [];
    const message = _('is outside of canvas bounds');
    items.forEach((item) => {
        if (
            item.position.x < boundary.x ||
            item.position.x + item.position.w > boundary.x + boundary.w ||
            item.position.y < boundary.y ||
            item.position.y + item.position.h > boundary.y + boundary.h
        ) {
            invalidItems.push({
                itemId: item.item,
                messages: [`"${item.item}" ${message}`],
            });
        }
    });
    return invalidItems;
};

// TODO: Should this be more aligned with EdgeItem?
interface EdgeValidationItem {
    start: Coordinate;
    end: Coordinate;
    orientation: EdgeItem['orientation'];
    type: 'above' | 'below' | 'left' | 'right';
    belongsTo: AbsolutePosition;
}

/**
 * Gets the 4 edges of every item (top, bottom, left, and right)
 * @param {Object} params
 * @param {Object} params.item item positions { x, y, w, h }
 * @param {Object} [params.belongsTo={}] item to which these edges belong
 * @returns {Object[]} array of edges where edge = { start: { x, y }, end: { x, y } }
 */
export const getItemEdges = ({
    item,
    belongsTo = {} as AbsolutePosition,
}: {
    item: AbsolutePosition;
    belongsTo?: AbsolutePosition;
}): EdgeValidationItem[] => {
    // create edges in the form: edge.start.{x,y} and edge.end.{x,y}
    const topEdge: EdgeValidationItem = {
        start: { x: item.x, y: item.y },
        end: { x: item.x + item.w, y: item.y },
        orientation: 'horizontal',
        type: 'above',
        belongsTo,
    };
    const bottomEdge: EdgeValidationItem = {
        start: { x: item.x, y: item.y + item.h },
        end: { x: item.x + item.w, y: item.y + item.h },
        orientation: 'horizontal',
        type: 'below',
        belongsTo,
    };
    const leftEdge: EdgeValidationItem = {
        start: { x: item.x, y: item.y },
        end: { x: item.x, y: item.y + item.h },
        orientation: 'vertical',
        type: 'left',
        belongsTo,
    };
    const rightEdge: EdgeValidationItem = {
        start: { x: item.x + item.w, y: item.y },
        end: { x: item.x + item.w, y: item.y + item.h },
        orientation: 'vertical',
        type: 'right',
        belongsTo,
    };

    return [topEdge, bottomEdge, leftEdge, rightEdge];
};

/**
 * Create all necessary edges for every item in layout as well as for the canvas
 * @param {Object} params
 * @param {Object[]} params.items items in the layout structure of the dashboard
 * @param {Object} params.canvasBounds canvas boundary { x, y, w, h }
 * @returns {Object[]} an array of edge objects as returned from getItemEdges
 */
const createEdges = ({
    items,
    canvasBounds,
}: {
    items: AbsoluteBlockItem[];
    canvasBounds: AbsolutePosition;
}): EdgeValidationItem[] => {
    const edges = [];

    // first, add every item edge
    items.forEach((item) => {
        edges.push(
            ...getItemEdges({ item: item.position, belongsTo: item.position })
        );
    });

    // second, need to add canvas edges as well
    edges.push(...getItemEdges({ item: canvasBounds }));

    return edges;
};

/**
 * Find the provided item's invalid edges, which indicate there is a gap or overlap between visualizations
 * @param {Object} params
 * @param {Object} params.item item to find invalid edges for
 * @param {Object[]} params.edges all viz and canvas edges
 * @returns {String[]} an array of error message for each invalid edge
 */
const findInvalidItemEdges = ({
    item,
    edges,
}: {
    item: AbsoluteBlockItem;
    edges: EdgeValidationItem[];
}): string[] => {
    const invalidEdges: string[] = [];
    // the current edges of the item we're looking at
    const curEdges = getItemEdges({
        item: item.position,
        belongsTo: item.position,
    });
    curEdges.forEach((curEdge) => {
        // find one edge which it overlaps. The idea is as follows:
        //   if there is an edge that it overlaps, it means that it is adjacent, and thus this edge is valid
        //   if no adjacent edge is found, it means either there is extra space (gap) or the viz are overlapping
        const overlappingEdge = edges.find((edge) => {
            if (
                curEdge.orientation !== edge.orientation ||
                isEqual(curEdge.belongsTo, edge.belongsTo)
            ) {
                // if the edge is not the same orientation, it can't be overlapping
                // or if the edge is the same, meaning it belongs to same item
                // or if the two items are perfectly overlapping
                return false;
            }
            // if the edges are horizontal and on the same y co-ordinate
            if (
                curEdge.orientation === 'horizontal' &&
                curEdge.start.y === edge.start.y
            ) {
                // if the edges are not overlapping
                if (
                    curEdge.start.x >= edge.end.x ||
                    edge.start.x >= curEdge.end.x
                ) {
                    return false;
                }
                // otherwise they are overlapping
                return true;
            }
            // if the edges are vertical and on the same x co-ordinate
            if (
                curEdge.orientation === 'vertical' &&
                curEdge.start.x === edge.start.x
            ) {
                // if the edges are not overlapping
                if (
                    curEdge.start.y >= edge.end.y ||
                    edge.start.y >= curEdge.end.y
                ) {
                    return false;
                }
                return true;
            }
            // this means edge is not on the same co-ordinate/line, can't be overlapping
            return false;
        });
        if (overlappingEdge === undefined) {
            // If no edge was found, this means there is an error in the layout structure
            const positionString =
                curEdge.orientation === 'horizontal'
                    ? `y=${curEdge.start.y}`
                    : `x=${curEdge.start.x}`;
            // differentiate between "above/below" and "to its right/left"
            const positionType =
                curEdge.type === 'below' || curEdge.type === 'above'
                    ? curEdge.type
                    : `to its ${curEdge.type}`;
            invalidEdges.push(
                _(
                    `"${item.item}" expected a viz or canvas edge directly ${positionType} at ${positionString}`
                )
            );
        }
    });
    return invalidEdges;
};

/**
 * Find items that have invalid edges indicating a gap/overlap between visualizations
 * @param {Object} params
 * @param {Object[]} params.items items in the layout structure of the dashboard
 * @param {Object} params.canvasBounds canvas boundary { x, y, w, h }
 * @returns {Object[]} Array of objects containing itemId and error messages
 */
const findInvalidItems = ({
    items,
    canvasBounds,
}: {
    items: AbsoluteBlockItem[];
    canvasBounds: AbsolutePosition;
}) => {
    const invalidItems: LayoutError[] = [];
    const edges = createEdges({ items, canvasBounds });
    items.forEach((item) => {
        const invalidEdges = findInvalidItemEdges({ item, edges });
        if (invalidEdges.length > 0) {
            invalidItems.push({
                itemId: item.item,
                messages: invalidEdges,
            });
        }
    });
    return invalidItems;
};

/**
 * validate if layout is valid and return the appropriate error object if it is not
 * @param {Object} params
 * @param {Object[]} params.layout layout structure of the dashboard
 * @param {Object} params.canvasBounds canvas boundary { x, y, w, h }
 * @returns {Object[]} Array of objects containing itemId and error messages
 */
export const validateLayoutStructure = ({
    layout,
    canvasBounds,
}: {
    layout?: AbsoluteBlockItem[];
    canvasBounds: AbsolutePosition;
}): LayoutError[] => {
    if (!layout) {
        return []; // maybe return object with bool false and message?
    }

    const res = findItemsOutsideBoundary({
        items: layout,
        boundary: canvasBounds,
    });

    // if items are out of boundary, return early
    if (!isEmpty(res)) {
        return res;
    }

    // if all items are in boundary, check which are invalid
    return findInvalidItems({ items: layout, canvasBounds });
};

const isVerticallyAligned = (
    viz: AbsoluteBlockItem,
    another: AbsoluteBlockItem
) =>
    viz.position.y === another.position.y &&
    viz.position.h === another.position.h;

const isHorizontallyAligned = (
    viz: AbsoluteBlockItem,
    another: AbsoluteBlockItem
) =>
    viz.position.x === another.position.x &&
    viz.position.w === another.position.w;

export const isInvalidAdjacentVizDrop = ({
    itemToMove,
    itemToDropOn,
    direction,
    visualizations,
}: {
    itemToMove: AbsoluteBlockItem;
    itemToDropOn: AbsoluteBlockItem;
    direction: Quadrant;
    visualizations: AbsoluteBlockItem[];
}): boolean => {
    /* scenario 1: drop on left/right neighbor */

    if (
        direction === 'e' &&
        isLeftNeighbor(itemToMove, itemToDropOn) &&
        isVerticallyAligned(itemToMove, itemToDropOn)
    ) {
        return true;
    }

    if (
        direction === 'w' &&
        isRightNeighbor(itemToMove, itemToDropOn) &&
        isVerticallyAligned(itemToMove, itemToDropOn)
    ) {
        return true;
    }

    if (direction === 'e' || direction === 'w') {
        return false;
    }

    /* scenario 2: drop on top/bottom neighbor */

    const { leftNeighbors, rightNeighbors } = findHorizontalNeighbors({
        item: itemToMove,
        visualizations,
    });

    // neighbors will occupy the vacant space, so it is valid to move the item
    if (leftNeighbors.length > 0 || rightNeighbors.length > 0) {
        return false;
    }

    if (
        direction === 'n' &&
        isBottomNeighbor(itemToMove, itemToDropOn) &&
        isHorizontallyAligned(itemToMove, itemToDropOn)
    ) {
        return true;
    }

    if (
        direction === 's' &&
        isTopNeighbor(itemToMove, itemToDropOn) &&
        isHorizontallyAligned(itemToMove, itemToDropOn)
    ) {
        return true;
    }

    return false;
};
