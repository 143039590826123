import { _ } from '@splunk/ui-utils/i18n';

const editorConfig = [
    {
        label: _('Appearance'),
        open: true,
        layout: [
            [
                {
                    label: _(''),
                    option: 'hasDonutHole',
                    editor: 'editor.radioBar',
                    editorProps: {
                        values: [
                            { label: _('Pie'), value: false },
                            { label: _('Donut'), value: true },
                        ],
                    },
                },
            ],
        ],
    },
    {
        label: _('Labels'),
        layout: [
            [
                {
                    label: _('Show Percent'),
                    option: 'chart.showPercent',
                    editor: 'editor.toggle',
                },
            ],
            [
                {
                    label: _('Show Labels'),
                    option: 'chart.showLabels',
                    editor: 'editor.toggle',
                    showEditor: ({ options }) =>
                        options.hasDonutHole === false || options.hasDonutHole === undefined,
                },
            ],
        ],
    },
    {
        label: _('Collapse to Summary'),
        layout: [
            [
                {
                    label: _('Collapse Threshold'),
                    option: 'chart.sliceCollapsingThreshold',
                    editor: 'editor.number',
                    editorProps: {
                        min: 0,
                        max: 1,
                        step: 0.01,
                    },
                },
            ],
            [
                {
                    label: _('Collapsed Slice Label'),
                    option: 'chart.sliceCollapsingLabel',
                    editor: 'editor.text',
                },
            ],
        ],
    },
];

export default editorConfig;
