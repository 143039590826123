import React, { useMemo } from 'react';
import type {
    ConnectedLineItem,
    AbsoluteBlockItem,
    AbsoluteLayoutOptions,
    AbsoluteLayoutStructure,
} from '@splunk/dashboard-types';
import { getScrollbarWidth } from '@splunk/dashboard-utils';
import { AbsoluteItem, Canvas, ResponsiveLine } from './components';
import { absoluteLayoutOptions } from './DefaultOptions';
import { validateBackgroundImage } from './utils/imageUtils';
import { computeScaleToFit } from './utils/layoutUtils';
import { computeLineAbsPosition } from './utils/lineUtils';
import type { RenderLayoutItem } from './types';

// TODO: add layoutApiRef once AbsoluteLayoutApi and GridLayoutApi have been refactored
export interface AbsoluteLayoutViewerProps {
    containerWidth: number;
    containerHeight: number;
    renderLayoutItem: RenderLayoutItem;
    options?: AbsoluteLayoutOptions;
    layoutStructure?: AbsoluteLayoutStructure;
}

const AbsoluteLayoutViewer = ({
    containerWidth,
    containerHeight,
    layoutStructure = [],
    renderLayoutItem,
    options = {},
}: AbsoluteLayoutViewerProps): JSX.Element => {
    const {
        width,
        height,
        backgroundColor,
        backgroundImage = {},
        display,
    } = {
        ...absoluteLayoutOptions,
        ...options,
    };

    const scrollbarWidth = useMemo(() => getScrollbarWidth(), []);

    const scale = useMemo(() => {
        switch (display) {
            case 'fit-to-width':
            case 'auto-scale':
                return computeScaleToFit({
                    canvasWidth: width,
                    canvasHeight: height,
                    containerWidth,
                    containerHeight,
                    scrollbarWidth,
                });
            case 'actual-size':
                return 1;
            default:
                return computeScaleToFit({
                    canvasWidth: width,
                    canvasHeight: height,
                    containerWidth,
                    containerHeight,
                    scrollbarWidth,
                    max: 1,
                });
        }
    }, [
        display,
        height,
        width,
        containerWidth,
        containerHeight,
        scrollbarWidth,
    ]);
    const image = useMemo(
        () =>
            backgroundImage
                ? validateBackgroundImage({
                      backgroundImage,
                      canvasWidth: width,
                      canvasHeight: height,
                  })
                : {},
        [backgroundImage, height, width]
    );

    const renderLines = useMemo(() => {
        // TODO: replace with getAllLineItems() from lineUtils after AbsLayout refactor is merged
        const lineItems = layoutStructure.filter(
            ({ type }) => type === 'line'
        ) as ConnectedLineItem[];
        return lineItems.map(({ item, position }) => {
            const absPosition = computeLineAbsPosition({
                layoutStructure,
                position,
            });
            return (
                <ResponsiveLine
                    key={item}
                    lineId={item}
                    scale={scale}
                    fromX={absPosition.from.x}
                    toX={absPosition.to.x}
                    fromY={absPosition.from.y}
                    toY={absPosition.to.y}
                    renderLayoutItem={renderLayoutItem}
                />
            );
        });
    }, [layoutStructure, scale, renderLayoutItem]);

    const renderBlockItems = useMemo(() => {
        // TODO: replace with getAllBlockItems() from lineUtils after AbsLayout refactor is merged
        const blockItems = layoutStructure.filter(
            ({ type }) => type !== 'line'
        ) as AbsoluteBlockItem[];
        return blockItems.map(({ item, type, position }) => {
            const { x, y, w, h } = position;
            return (
                <AbsoluteItem
                    itemId={item}
                    type={type}
                    key={item}
                    x={x}
                    y={y}
                    w={w}
                    h={h}
                    renderLayoutItem={renderLayoutItem}
                />
            );
        });
    }, [renderLayoutItem, layoutStructure]);

    return (
        <Canvas
            data-layout-type="absolute"
            width={width}
            height={height}
            scale={scale}
            showGrid={false}
            backgroundColor={backgroundColor}
            backgroundImageSrc={image.src}
            backgroundImageSizeType={image.sizeType}
            backgroundImageWidth={image.w}
            backgroundImageHeight={image.h}
            backgroundImagePositionX={image.x}
            backgroundImagePositionY={image.y}
        >
            {renderBlockItems}
            {renderLines}
        </Canvas>
    );
};

export default AbsoluteLayoutViewer;
