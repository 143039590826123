import type { VisualizationDefinition } from '@splunk/dashboard-types';
import config from '@splunk/visualizations/Rectangle.config';
import { encodingToDynamicOptionsDSL } from '../encoding';
import type { EncodingConfig } from '../encoding';
import { renameVizOptions, removeInvalidOptions, addsDataSelectorToOptions } from '../utils/migrationUtils';

const optionsToRename = {
    fill: 'fillColor',
    stroke: 'strokeColor',
    strokeDasharray: 'strokeDashStyle',
    strokeLinejoin: 'strokeJoinStyle',
};

const validOptions = {
    ...config.optionsSchema,
};

const migrateVizToSplunkRectangle = (vizDefinition: VisualizationDefinition): VisualizationDefinition => {
    const { encoding = {}, options, ...otherDefinitionParts } = vizDefinition;

    const migratedDefinition: VisualizationDefinition = {
        ...otherDefinitionParts,
        type: 'splunk.rectangle',
        options: {},
        context: {},
    };

    // translate encoding to dynamic options equivalent
    const { options: dataOptions, context: dataContext } = encodingToDynamicOptionsDSL(
        encoding as Record<string, EncodingConfig>
    );

    // To simulate the implicit logic in the shape parser that Rectangle utilizes, append
    //  the lastPoint() DataSeries selector.
    const updatedDataOptions = addsDataSelectorToOptions(dataOptions, 'lastPoint()');

    migratedDefinition.options = { ...options, ...updatedDataOptions };

    // perform option rename
    migratedDefinition.options = renameVizOptions(migratedDefinition.options, optionsToRename);

    // remove invalid options, validOptions is taken from the config.optionsSchema
    removeInvalidOptions(migratedDefinition.options, validOptions);
    migratedDefinition.context = dataContext;
    return migratedDefinition;
};

export { migrateVizToSplunkRectangle };
