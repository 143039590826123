import type {
    DispatchedMiddlewareAction,
    Middleware,
} from '@splunk/dashboard-types';
import { setSmartSources } from '../reducers/smartSources';

const DEQUEUE_FREQ_MS = 1_000 / 30;

const batchedDispatch: Middleware = () => (next) => {
    let timeout: ReturnType<typeof setTimeout> | null = null;
    let queue: unknown[] = [];

    // Continue middleware execution, clear the processed queue, and clear the timeout
    const dispatchAction = (): void => {
        next({ type: setSmartSources.type, payload: queue });
        queue = [];
        timeout = null;
    };

    // Return an action handler to queue setSmartSources payloads and dispatch
    // them as an array of payload data. To support this, the target reducer
    // needs to support both `PayloadType` and `PayloadType[]` in the handled
    // actions. ex `(state, action: PayloadAction<SetPayload | SetPayload[]>) => { ... }`
    return (action: DispatchedMiddlewareAction) => {
        if (action.type !== setSmartSources.type) {
            return next(action);
        }

        // Track the payload in the queue and schedule a timeout if needed
        if (Array.isArray(action.payload)) {
            queue.push(...action.payload);
        } else {
            queue.push(action.payload);
        }

        if (!timeout) {
            timeout = setTimeout(dispatchAction, DEQUEUE_FREQ_MS);
        }

        // Return a cancellation function (unmount clear pending timeouts?)
        return () => {
            if (timeout) {
                clearTimeout(timeout);
                timeout = null;
            }
        };
    };
};

export default batchedDispatch;
