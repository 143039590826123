import { Map as MapIcon } from '@splunk/visualization-icons';
import { Map as MapPlaceholderIcon } from '@splunk/visualization-icons/placeholders';
import { getPattern, COLOR_OR_TOKEN_PATTERN_WITH_RGBA } from '@splunk/visualizations-shared/schemaUtils';
import { VIZ_CATEGORICAL } from '@splunk/visualization-color-palettes';
import { pick, variables } from '@splunk/themes';
import { _ } from '@splunk/ui-utils/i18n';

import { DataContract } from '../common/interfaces/DataContract';
import { DefaultContext } from '../common/interfaces/DefaultContext';
import { EditorConfig } from '../common/interfaces/Editor';
import { OptionsSchema } from '../common/interfaces/OptionsSchema';
import { ThemedDefaults } from '../common/interfaces/ThemedDefaults';
import { VizBehavior } from '../common/interfaces/VizBehavior';
import { VizCategory } from '../common/interfaces/VizCategory';
import { VizConfig } from '../common/interfaces/VizConfig';
import { VizSize } from '../common/interfaces/VizSize';
import { enhanceConfig } from '../common/utils/configUtils';
import { LAYER_TYPE } from './PureMap/MapUtils';
import getBackgroundColorEditor from '../common/editorConfig/BackgroundColor';

const defaultContext: DefaultContext = {
    colorRangeConfig: [
        { to: 20, value: '#D41F1F' },
        { from: 20, to: 40, value: '#D94E17' },
        { from: 40, to: 60, value: '#CBA700' },
        { from: 60, to: 80, value: '#669922' },
        { from: 80, value: '#118832' },
    ],
};

const dataContract: DataContract = {
    requiredDataSources: [],
    optionalDataSources: [
        {
            name: 'primary',
            description: 'DataSource that powers the visualization.',
        },
    ],
    initialRequestParams: {
        primary: { offset: 0, count: 10000 },
    },
};

const size: VizSize = {
    initialWidth: 800,
    initialHeight: 600,
};

const optionsSchema: OptionsSchema = {
    backgroundColor: {
        default: '> themes.defaultBackgroundColor',
        description:
            'Specify a color for the background. You might use a dataSource to apply the color. The default for enterprise light is "#FFFFFF". The default for enterprise dark is "#000000". The default for prisma dark is "#0B0C0E".',
        pattern: COLOR_OR_TOKEN_PATTERN_WITH_RGBA,
        type: 'string',
    },
    baseLayerTileServer: {
        type: 'string',
        description: 'Specify the URL for the set of tiles that make up the base map.',
    },
    baseLayerTileServerType: {
        type: 'string',
        description: 'Specify the type of tile set for the base map.',
        pattern: getPattern(['vector', 'raster']),
    },
    center: {
        type: 'array',
        description:
            'Specify the center of the map in coordinates, in the form of [latitude, longitude]. If you don’t provide coordinates, the map automatically centers, based on available location data. If there is no available location data, the center of the map defaults to [37.7749, -122.4194].',
        items: { type: 'number' },
    },
    layers: {
        // The option merging of defaults and data binding with editing UI happens with the default defined below.
        // The inner defaults for properties within anyOf objects is used only for code intellisense in UDF to populate options.
        default: [
            {
                type: LAYER_TYPE.Bubble,
                latitude: '> primary | seriesByName("latitude")',
                longitude: '> primary | seriesByName("longitude")',
                seriesColors: VIZ_CATEGORICAL,
                bubbleSize:
                    '> primary | frameWithoutSeriesNames("geobin", "latitude", "longitude") | frameBySeriesTypes("number")',
                additionalTooltipFields: [],
                resultLimit: 1000,
            },
            {
                type: LAYER_TYPE.Marker,
                latitude: '> primary | seriesByName("latitude")',
                longitude: '> primary | seriesByName("longitude")',
                seriesColors: VIZ_CATEGORICAL,
                additionalTooltipFields: [],
                resultLimit: 1000,
            },
            {
                type: LAYER_TYPE.Choropleth,
                areaIds: '> primary | seriesByType("string")',
                areaValues: '> primary | seriesByType("number")',
                dataColors: '> areaValues | gradient()',
                additionalTooltipFields: [],
                resultLimit: 1000,
                choroplethEmptyAreaColor: '> themes.defaultChoroplethEmptyAreaColor',
                choroplethStrokeColor: 'transparent',
                choroplethOpacity: 0.8,
            },
        ],
        type: 'array',
        description:
            'Specify the optional data visualization layers over the base map. If empty, only the base map appears.',
        items: {
            anyOf: [
                {
                    type: 'object',
                    properties: {
                        type: {
                            description:
                                'Specify the type of data layer method to display points on the map. For example, “bubble”, “marker”, or "choropleth".',
                            type: 'string',
                            pattern: getPattern([
                                LAYER_TYPE.Marker,
                                LAYER_TYPE.Bubble,
                                LAYER_TYPE.Choropleth,
                            ]),
                            default: LAYER_TYPE.Marker,
                        },
                        latitude: {
                            description:
                                'Specify a dataSource series to apply latitude coordinates for data points displayed on the map. This applies to layers where type: "bubble" or type: "marker".',
                            type: ['string', 'array'],
                            items: { type: 'number' },
                            default: '> primary | seriesByName("latitude")',
                        },
                        longitude: {
                            description:
                                'Specify a dataSource series to apply longitude coordinates for data points displayed on the map. This applies to layers where type: "bubble" or type: "marker".',
                            type: ['string', 'array'],
                            items: { type: 'number' },
                            default: '> primary | seriesByName("longitude")',
                        },
                        seriesColors: {
                            description:
                                'Specify the colors to use for data points. If the dataColors option is specified, the seriesColors option is ignored. The seriesColors option applies to layers where type: "bubble" or type: "marker".',
                            type: 'array',
                            items: { type: 'string', pattern: COLOR_OR_TOKEN_PATTERN_WITH_RGBA },
                            default: VIZ_CATEGORICAL,
                        },
                        dataColors: {
                            description:
                                'Specify the colors to use for data points. You can use an array of colors to be picked from, or you can use a dataSource to apply dynamic coloring. For example, “> primary | seriesByName("status") | matchValue(colorMatchConfig)”. When type: "choropleth", dataColors is defined by default, and the opacities are shown at 80% of maximum to allow textual information to be visible in the map. By default, the dataColors option is not defined for type "bubble" or "marker". If type: "bubble", dataColors requires bubbleSize to have only a single series configured.',
                            type: ['string', 'array'],
                            items: { type: 'string' },
                        },
                        additionalTooltipFields: {
                            description:
                                'Specify the fields to add to the default set of tooltips. Tooltips appear when you hover over data points. These fields and their corresponding values are shown in addition to the ones displayed by default.',
                            type: 'array',
                            items: { type: 'string' },
                            default: [],
                        },
                        resultLimit: {
                            description:
                                'Specify the maximum number of data points to render on the map. Adjust the value to improve performance.',
                            type: 'number',
                            default: 1000,
                        },
                    },
                },
                {
                    type: 'object',
                    properties: {
                        type: {
                            description:
                                'Specify the type of data layer method to display points on the map. For example, “bubble”, “marker”, or "choropleth".',
                            type: 'string',
                            pattern: getPattern([
                                LAYER_TYPE.Marker,
                                LAYER_TYPE.Bubble,
                                LAYER_TYPE.Choropleth,
                            ]),
                            default: LAYER_TYPE.Choropleth,
                        },
                        areaIds: {
                            description:
                                'Specify a dataSource series to identify each area in the choropleth map layer. This applies to layers where type: "choropleth".',
                            type: 'array',
                            items: { type: 'string' },
                            default: '> primary | seriesByType("string")',
                        },
                        areaValues: {
                            description:
                                'Specify a dataSource series that indicates the values for the choropleth map areas. This applies to layers where type: "choropleth".',
                            type: 'array',
                            items: { type: 'number' },
                            default: '> primary | seriesByType("number")',
                        },
                        dataColors: {
                            description:
                                'Specify the colors to use for data points. You can use an array of colors to be picked from, or you can use a dataSource to apply dynamic coloring. For example, “> primary | seriesByName("status") | matchValue(colorMatchConfig)”. When type: "choropleth", dataColors is defined by default. By default, the dataColors option is not defined for type "bubble" or "marker".',
                            type: ['string', 'array'],
                            items: { type: 'string' },
                            default: '> areaValues | gradient()',
                        },
                        additionalTooltipFields: {
                            description:
                                'Specify the fields to add to the default set of tooltips. Tooltips appear when you hover over data points. These fields and their corresponding values are shown in addition to the ones displayed by default.',
                            type: 'array',
                            items: { type: 'string' },
                            default: [],
                        },
                        resultLimit: {
                            description:
                                'Specify the maximum number of data points to render on the map. Adjust the value to improve performance.',
                            type: 'number',
                            default: 1000,
                        },
                        source: {
                            description:
                                'Source for the geodata for choropleth layer: "us" or "world", and will override data specified in the SPL geom command.',
                            pattern: getPattern(['geo://default/us', 'geo://default/world']),
                            type: 'string',
                        },
                        choroplethEmptyAreaColor: {
                            description:
                                'Specify the color used for empty choropleth areas when "type": "choropleth", and will only work when "source" option is correctly configured.',
                            pattern: COLOR_OR_TOKEN_PATTERN_WITH_RGBA,
                            type: 'string',
                            default: '> themes.defaultChoroplethEmptyAreaColor',
                        },
                        choroplethStrokeColor: {
                            description:
                                'Specify the stroke color for choropleth area outlines. The hex value format should be "#FFFFFF".',
                            pattern: COLOR_OR_TOKEN_PATTERN_WITH_RGBA,
                            type: 'string',
                            default: 'transparent',
                        },
                        choroplethOpacity: {
                            description:
                                'Specify the opacity of choropleth areas. Choose a number in the range of 0 - 1 (inclusive). You can also express the value as a percentage. For example, "0.8" in code or "80%" in UI.',
                            type: 'number',
                            default: 0.8,
                        },
                    },
                },
                {
                    type: 'object',
                    properties: {
                        type: {
                            description:
                                'Specify the type of data layer method to display points on the map. For example, “bubble”, “marker”, or "choropleth".',
                            type: 'string',
                            pattern: getPattern([
                                LAYER_TYPE.Marker,
                                LAYER_TYPE.Bubble,
                                LAYER_TYPE.Choropleth,
                            ]),
                            default: LAYER_TYPE.Bubble,
                        },
                        bubbleSize: {
                            description:
                                'Specify the data column to encode bubble size. This applies to layers where type: "bubble".',
                            type: ['string', 'array'],
                            items: { type: 'number' },
                            default:
                                '> primary | frameWithoutSeriesNames("geobin", "latitude", "longitude") | frameBySeriesTypes("number")',
                        },
                        latitude: {
                            description:
                                'Specify a dataSource series to apply latitude coordinates for data points displayed on the map. This applies to layers where type: "bubble" or type: "marker".',
                            type: ['string', 'array'],
                            items: { type: 'number' },
                            default: '> primary | seriesByName("latitude")',
                        },
                        longitude: {
                            description:
                                'Specify a dataSource series to apply longitude coordinates for data points displayed on the map. This applies to layers where type: "bubble" or type: "marker".',
                            type: ['string', 'array'],
                            items: { type: 'number' },
                            default: '> primary | seriesByName("longitude")',
                        },
                        seriesColors: {
                            description:
                                'Specify the colors to use for data points. If the dataColors option is specified, the seriesColors option is ignored. The seriesColors option applies to layers where type: "bubble" or type: "marker".',
                            type: 'array',
                            items: { type: 'string', pattern: COLOR_OR_TOKEN_PATTERN_WITH_RGBA },
                            default: VIZ_CATEGORICAL,
                        },
                        dataColors: {
                            description:
                                'Specify the colors to use for data points. You can use an array of colors to be picked from, or you can use a dataSource to apply dynamic coloring. For example, “> primary | seriesByName("status") | matchValue(colorMatchConfig)”. When type: "choropleth", dataColors is defined by default, and the opacities are shown at 80% of maximum to allow textual information to be visible in the map. By default, the dataColors option is not defined for type "bubble" or "marker". If type: "bubble", dataColors requires bubbleSize to have only a single series configured.',
                            type: ['string', 'array'],
                            items: { type: 'string' },
                        },
                        additionalTooltipFields: {
                            description:
                                'Specify the fields to add to the default set of tooltips. Tooltips appear when you hover over data points. These fields and their corresponding values are shown in addition to the ones displayed by default.',
                            type: 'array',
                            items: { type: 'string' },
                            default: [],
                        },
                        resultLimit: {
                            description:
                                'Specify the maximum number of data points to render on the map. Adjust the value to improve performance.',
                            type: 'number',
                            default: 1000,
                        },
                    },
                },
            ],
        },
    },
    scaleUnit: {
        default: 'metric',
        type: 'string',
        description: 'Specify the scale unit to use.',
        pattern: getPattern(['metric', 'imperial']),
    },
    showBaseLayer: {
        default: true,
        type: 'boolean',
        description: 'Specify if showing the base map.',
    },
    showScale: {
        default: true,
        type: 'boolean',
        description: 'Specify whether to display the scale on the bottom left.',
    },
    showZoomControls: {
        default: true,
        type: 'boolean',
        description: 'Specify whether to display the control panel of zoom on the top left.',
    },
    zoom: {
        description: 'Specify the zoom level of the map.',
        type: 'number',
    },
};

const themes: ThemedDefaults = {
    defaultBackgroundColor: props =>
        pick({
            enterprise: {
                dark: variables.black(props),
                light: variables.backgroundColor(props),
            },
            prisma: variables.backgroundColorSidebar(props),
        })(props),
    controlBackgroundColor: props =>
        pick({
            enterprise: {
                dark: variables.gray22(props),
                light: variables.white(props),
            },
            prisma: variables.backgroundColorPopup(props),
        })(props),
    controlForegroundColor: props =>
        pick({
            enterprise: variables.textColor(props),
            prisma: variables.contentColorDefault(props),
        })(props),
    defaultChoroplethEmptyAreaColor: props =>
        pick({
            enterprise: {
                dark: variables.gray22(props),
                light: variables.gray92(props),
            },
            prisma: {
                dark: variables.interactiveColorBackground(props),
                light: variables.neutral300(props),
            },
        })(props),
    defaultGradientMigrationConfig: props =>
        pick({
            enterprise: {
                dark: ['#4D4A5D', variables.syntaxPurple(props)],
                light: ['#D2CFDC', variables.syntaxPurple(props)],
            },
            prisma: {
                dark: ['#484558', variables.syntaxPurple(props)],
                light: ['#D8D4E1', variables.syntaxPurple(props)],
            },
        })(props),
};

const checkTypeIsChoropleth = options =>
    options.layers && options.layers.length && options.layers[0].type === LAYER_TYPE.Choropleth;

const checkTypeIsLocalChoropleth = options =>
    options.layers &&
    options.layers.length &&
    options.layers[0].type === LAYER_TYPE.Choropleth &&
    (options.layers[0].source === 'geo://default/us' || options.layers[0].source === 'geo://default/world');

const EDITOR_LABEL_WIDTH = 140;
const editorConfig: EditorConfig[] = [
    {
        label: _('Data configurations'),
        layout: [
            [
                {
                    label: _('Layer type'),
                    option: 'layers[0].type',
                    editor: 'editor.select',
                    editorProps: {
                        values: [
                            { label: _('Bubble'), value: LAYER_TYPE.Bubble },
                            { label: _('Marker'), value: LAYER_TYPE.Marker },
                            { label: _('Choropleth'), value: LAYER_TYPE.Choropleth },
                        ],
                    },
                },
            ],
            [
                {
                    label: _('Area IDs'),
                    option: 'layers[0].areaIds',
                    editor: 'editor.columnSelector',
                    showEditor: ({ options }) => checkTypeIsChoropleth(options),
                    editorProps: {
                        dataSourceKey: 'primary',
                        shouldShowInternalFields: false,
                        filterByTypes: ['string'],
                    },
                },
            ],
            [
                {
                    label: _('Area values'),
                    option: 'layers[0].areaValues',
                    editor: 'editor.columnSelector',
                    showEditor: ({ options }) => checkTypeIsChoropleth(options),
                    editorProps: {
                        dataSourceKey: 'primary',
                        shouldShowInternalFields: false,
                        filterByTypes: ['number'],
                    },
                },
            ],
            [
                {
                    label: _('Latitude'),
                    option: 'layers[0].latitude',
                    editor: 'editor.columnSelector',
                    showEditor: ({ options }) =>
                        !(options.layers && options.layers.length && options.layers[0].type === 'choropleth'),
                    editorProps: {
                        dataSourceKey: 'primary',
                        shouldShowInternalFields: false,
                    },
                },
            ],
            [
                {
                    label: _('Longitude'),
                    option: 'layers[0].longitude',
                    editor: 'editor.columnSelector',
                    showEditor: ({ options }) =>
                        !(options.layers && options.layers.length && options.layers[0].type === 'choropleth'),
                    editorProps: {
                        dataSourceKey: 'primary',
                        shouldShowInternalFields: false,
                    },
                },
            ],
            [
                {
                    label: _('Bubble size'),
                    option: 'layers[0].bubbleSize',
                    editor: 'editor.columnMultiSelector',
                    showEditor: ({ options }) =>
                        !(
                            options.layers &&
                            options.layers.length &&
                            (options.layers[0].type === 'marker' || options.layers[0].type === 'choropleth')
                        ),
                    editorProps: {
                        dataSourceKey: 'primary',
                        shouldShowInternalFields: false,
                        excludeByNames: ['latitude', 'longitude', 'geobin'],
                    },
                },
            ],
            [
                {
                    label: _('Additional tooltip fields'),
                    option: 'layers[0].additionalTooltipFields',
                    editor: 'editor.columnMultiSelectionByFieldNameEditor',
                },
            ],
            [
                {
                    label: _('Result limit'),
                    option: 'layers[0].resultLimit',
                    editor: 'editor.number',
                    showEditor: ({ options }) => !checkTypeIsLocalChoropleth(options),
                    editorProps: {
                        themes,
                        labelPosition: 'top',
                        min: 0,
                    },
                },
            ],
        ],
    },
    {
        label: _('Data display'),
        layout: [
            [
                {
                    label: _('Latitude (initial map location)'),
                    option: 'center[0]',
                    editor: 'editor.number',
                    editorProps: {
                        themes,
                        labelPosition: 'top',
                        min: -90,
                        max: 90,
                    },
                },
            ],
            [
                {
                    label: _('Longitude (initial map location)'),
                    option: 'center[1]',
                    editor: 'editor.number',
                    editorProps: {
                        themes,
                        labelPosition: 'top',
                        min: -180,
                        max: 180,
                    },
                },
            ],
            [
                {
                    label: _('Zoom (initial map location)'),
                    option: 'zoom',
                    editor: 'editor.number',
                    editorProps: {
                        themes,
                        labelPosition: 'top',
                        min: 0,
                    },
                },
            ],
            [
                {
                    label: _('Show zoom controls'),
                    option: 'showZoomControls',
                    editor: 'editor.checkbox',
                    editorProps: {
                        labelPosition: 'right',
                        labelWidth: EDITOR_LABEL_WIDTH,
                    },
                },
            ],
            [
                {
                    label: _('Show scale'),
                    option: 'showScale',
                    editor: 'editor.toggle',
                    editorProps: {
                        labelPosition: 'right',
                        labelWidth: EDITOR_LABEL_WIDTH,
                    },
                },
            ],
            [
                {
                    label: _('Scale unit'),
                    option: 'scaleUnit',
                    editor: 'editor.radioBar',
                    showEditor: ({ options }) => options.showScale !== false,
                    editorProps: {
                        values: [
                            { label: _('Metric'), value: 'metric' },
                            { label: _('Imperial'), value: 'imperial' },
                        ],
                    },
                },
            ],
        ],
    },
    {
        label: _('Color and style'),
        layout: [
            // TODO: add support for color mode option and its corresponding editor
            // [
            //     {
            //         label: _('Color mode'),
            //         option: 'colorMode',
            //         editor: 'editor.radioBar',
            //         editorProps: {
            //             values: [
            //                 { label: _('Static'), value: 'static' },
            //                 { label: _('Dynamic'), value: 'dynamic' },
            //             ],
            //         },
            //     },
            // ],
            [
                {
                    label: _('Series colors'),
                    option: 'layers[0].seriesColors',
                    editor: 'editor.seriesColors',
                    showEditor: ({ options }) =>
                        !(options.layers && options.layers.length && options.layers[0].type === 'choropleth'),
                },
            ],
            // TODO: add editor for data colors
            // [
            //     {
            //         label: _('Data colors'),
            //         option: 'layers[0].dataColors',
            //         editor: 'editor.text',
            //         editorProps: {
            //             themes,
            //             labelPosition: 'top',
            //         },
            //     },
            // ],
            [
                {
                    label: _('Choropleth empty area'),
                    option: 'layers[0].choroplethEmptyAreaColor',
                    editor: 'editor.color',
                    showEditor: ({ options }) => checkTypeIsLocalChoropleth(options),
                    editorProps: {
                        themes,
                    },
                },
            ],
            [
                {
                    label: _('Choropleth stroke'),
                    option: 'layers[0].choroplethStrokeColor',
                    editor: 'editor.color',
                    showEditor: ({ options }) => checkTypeIsChoropleth(options),
                    editorProps: {
                        themes,
                    },
                },
            ],
            [
                {
                    label: _('Choropleth opacity (%)'),
                    option: 'layers[0].choroplethOpacity',
                    editor: 'editor.percent',
                    showEditor: ({ options }) => checkTypeIsChoropleth(options),
                    editorProps: {
                        themes,
                        min: 0,
                    },
                },
            ],
            // TODO: add support for seriesColorsByField option
            // [
            //     {
            //         label: _('Series colors by field name'),
            //         option: 'seriesColorsByField',
            //         editor: 'editor.seriesColorsByField',
            //     },
            // ],
            getBackgroundColorEditor({ themes }),
            [
                {
                    label: _('Show base layer'),
                    option: 'showBaseLayer',
                    editor: 'editor.toggle',
                },
            ],
            [
                {
                    label: _('Base layer tile server'),
                    option: 'baseLayerTileServer',
                    editor: 'editor.text',
                    showEditor: ({ options }) => options.showBaseLayer !== false,
                },
            ],
            [
                {
                    label: _('Base layer tile server type'),
                    option: 'baseLayerTileServerType',
                    editor: 'editor.radioBar',
                    showEditor: ({ options }) =>
                        options.showBaseLayer === false
                            ? false
                            : !(
                                  typeof options.baseLayerTileServer === 'undefined' ||
                                  options.baseLayerTileServer === ''
                              ),
                    editorProps: {
                        values: [
                            { label: _('Vector'), value: 'vector' },
                            { label: _('Raster'), value: 'raster' },
                        ],
                    },
                },
            ],
        ],
    },
];

/**
 * visualization configuration
 */
const config: VizConfig = {
    /**
     * unique viz key
     */
    key: 'splunk.map',
    /**
     * viz name
     */
    name: 'Map',
    category: VizCategory.CHOROPLETH,
    status: 'preview',
    /**
     * viz icon
     */
    icon: MapIcon,
    placeholderIcon: MapPlaceholderIcon,
    dataContract,
    size,
    defaultContext,
    optionsSchema,
    editorConfig,
    events: {
        'map.load': {
            description: 'triggered when map is fully loaded',
        },
        'map.click': {
            description: 'trigger when user clicks map layer',
        },
    },
    supports: [VizBehavior.DYNAMIC_OPTIONS, VizBehavior.EVENTS, VizBehavior.PLACEHOLDER],
    themes,
    requiredProps: [],
};

export default enhanceConfig(config);
