import { createContext, useContext } from 'react';
import type { PresetUtility } from '@splunk/dashboard-types';

const PresetContext = createContext<PresetUtility | null>(null);

export const PresetContextProvider = PresetContext.Provider;

export const usePreset = () => {
    const ctx = useContext(PresetContext);
    if (ctx === null) {
        throw new Error(
            'usePreset can only be used in a component that is a child of a PresetContextProvider'
        );
    }

    return ctx;
};

export default PresetContext;
