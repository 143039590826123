import { useMemo } from 'react';
import type {
    AbsoluteBlockItem,
    AbsoluteLayoutStructure,
    GridLayoutStructure,
} from '@splunk/dashboard-types';
import {
    selectInputs,
    selectMode,
    selectVisualizations,
    useSelector,
} from '@splunk/dashboard-state';
import { useBatchSubscribeToSearches } from '@splunk/dashboard-ui';
import { useFeatureFlags } from '@splunk/dashboard-context';
import { shouldHideItem } from '../utils/showHideUtils';

type LayoutStructure = AbsoluteLayoutStructure | GridLayoutStructure;

export type ReflowFn = (
    initialStructure: LayoutStructure,
    itemsToRemove: LayoutStructure
) => LayoutStructure;

type UseLayoutShowHideFn = (
    layoutStructure: LayoutStructure,
    handleReflow?: ReflowFn
) => LayoutStructure;

export const useLayoutShowHide: UseLayoutShowHideFn = (
    layoutStructure,
    handleReflow
) => {
    const allVizs = useSelector(selectVisualizations);
    const allInputs = useSelector(selectInputs);
    const mode = useSelector(selectMode);

    const subscriptions = useBatchSubscribeToSearches({
        consumerId: '<layout>',
    });

    const { enableShowHide } = useFeatureFlags();

    return useMemo(() => {
        if (!enableShowHide || mode === 'edit') {
            return layoutStructure;
        }

        const itemsToRemove: LayoutStructure = [];
        const filteredStructure = layoutStructure.filter((item) => {
            if (
                shouldHideItem({
                    item,
                    definition: allVizs[item.item] ?? allInputs?.[item.item],
                    searchData: subscriptions[item.item],
                })
            ) {
                itemsToRemove.push(item as AbsoluteBlockItem);
                return false;
            }
            return true;
        });

        return itemsToRemove.length && handleReflow
            ? handleReflow(layoutStructure, itemsToRemove)
            : filteredStructure;
    }, [
        allInputs,
        allVizs,
        enableShowHide,
        mode,
        subscriptions,
        handleReflow,
        layoutStructure,
    ]);
};
