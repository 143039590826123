import React, { forwardRef, useCallback, useMemo } from 'react';
import styled from 'styled-components';
import { useDroppable } from '@dnd-kit/core';
import { mixins } from '@splunk/themes';
import { noop } from '@splunk/dashboard-utils';
import { customThemeVariables, SubmitButton } from '@splunk/dashboard-ui';

interface InputContainerProps {
    backgroundColor?: string;
}

const InputContainer = styled.div<InputContainerProps>`
    ${mixins.reset('block')};
    padding: 8px 0px;
    background: ${(prop) =>
        prop.backgroundColor || customThemeVariables.dashboardBackgroundColor};
    &:empty {
        padding: 0;
    }
`;

interface InputLayoutProps {
    submitButton?: boolean;
    submitButtonDisabled?: boolean;
    inputStructure?: string[];
    renderInput?: (arg: { inputId: string; index: number }) => React.ReactNode;
    onSubmitButtonClick?: () => void;
}

const defaultInputRender = () => null;
const defaultStructure: string[] = [];

/**
 * Simple Input Layout
 * @param {*} param0
 */
const InputLayout = forwardRef<HTMLDivElement, InputLayoutProps>(
    (
        {
            submitButton = false,
            submitButtonDisabled = false,
            inputStructure = defaultStructure,
            renderInput = defaultInputRender,
            onSubmitButtonClick = noop,
        },
        ref
    ) => {
        const { setNodeRef } = useDroppable({
            id: '__dnd-inputs-dropzone__',
        });

        const composedRef = useCallback(
            (element: HTMLDivElement | null) => {
                if (ref) {
                    if (typeof ref === 'function') {
                        ref(element);
                    } else {
                        // eslint-disable-next-line no-param-reassign
                        ref.current = element;
                    }
                }

                setNodeRef(element);
            },
            [ref, setNodeRef]
        );

        const submit = useMemo(
            () =>
                inputStructure.length && submitButton ? (
                    <SubmitButton
                        data-test="input-item-submit-button"
                        key="submit_button"
                        disabled={submitButtonDisabled}
                        onClick={onSubmitButtonClick}
                    />
                ) : null,
            [
                inputStructure.length,
                submitButton,
                submitButtonDisabled,
                onSubmitButtonClick,
            ]
        );

        if (inputStructure.length === 0) {
            return null;
        }

        return (
            <InputContainer
                ref={composedRef}
                data-test="input-layout-container"
            >
                {inputStructure.map((inputId, index) =>
                    renderInput({ inputId, index })
                )}
                {submit}
            </InputContainer>
        );
    }
);

export default InputLayout;
