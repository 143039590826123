import isPlainObject from 'lodash/isPlainObject';

/**
 * convert props of type 'array/object' to 'string', based on the list of props to be converted in vizProps
 * @method convertPropsToString
 * @param {Object} vizProps
 * @param {Array} propNames
 * @return {Object} convertedVizProps
 */
export const convertPropsToString = ({ vizProps = {}, propNames = [] }) => {
    const convertedVizProps = {};

    propNames.forEach(propName => {
        const propValue = vizProps[propName];
        if (Array.isArray(propValue) || isPlainObject(propValue)) {
            convertedVizProps[propName] = JSON.stringify(propValue);
        } else if (propValue && typeof propValue === 'string') {
            // eslint-disable-next-line no-console
            console.warn(`The option type 'string' for '${propName}' has been deprecated.`);
        }
    });

    return convertedVizProps;
};

/**
 * Convert a hex value to a color
 * @method formatColor
 * @param {String} color
 * @returns {String}
 */
export const formatColor = color => {
    if (!color || typeof color !== 'string') {
        return '';
    }

    return color.replace('0x', '#');
};
