import type {
    DashboardJSON,
    VisualizationDefinition,
} from '@splunk/dashboard-types';
import { isDynamicOption } from '@splunk/dashboard-utils';
import validDSL from './validDSL';

interface VizInfo {
    count?: number;
    titles?: number;
    descriptions?: number;
    dslStatements?: Record<string, number>;
    conditionallyVisible?: number;
    numOptions?: number;
    options?: Record<string, number>;
}

export const flattenDSLOptions = (
    options?: Record<string, unknown>
): string[] => {
    const dslOptions: string[] = [];
    const queue = [...Object.values(options || {})];
    // TODO convert to use recursive TCO
    while (queue.length) {
        const value: unknown = queue.shift();
        if (typeof value === 'string' && isDynamicOption(value)) {
            dslOptions.push(value);
        } else if (Array.isArray(value)) {
            value.forEach((val) => queue.push(val));
        } else if (value && typeof value === 'object') {
            Object.values(value).forEach((val) => queue.push(val));
        }
    }
    return dslOptions;
};

export const getDSLCounts = (dsls: string[]): Record<string, number> => {
    const count: Record<string, number> = {};

    const findDSL = /([\w]+)\(/g;

    dsls.forEach((dsl) => {
        let r = findDSL.exec(dsl);
        while (r !== null) {
            if (r.length >= 2 && validDSL.has(r[1])) {
                const dslStr = r[1];
                count[dslStr] ??= 0;
                count[dslStr] += 1;
            }
            r = findDSL.exec(dsl);
        }
    });

    return count;
};

export const extractVisualizationInfo = (
    definition: DashboardJSON = {}
): Record<string, VizInfo> => {
    const visualizations: VisualizationDefinition[] = Object.values(
        definition.visualizations || {}
    );
    const vizInfo: Record<string, VizInfo> = {};

    visualizations.forEach(
        ({ type, options, description, title, hideWhenNoData }) => {
            const dslOptions: string[] = flattenDSLOptions(options);
            vizInfo[type] ??= {};

            if (dslOptions.length) {
                const dslStatements = getDSLCounts(dslOptions);
                if (vizInfo[type].dslStatements) {
                    Object.entries(dslStatements).forEach(
                        ([dslStatement, usage]) => {
                            // TS: Non-null assertion is done in the wrapping if statement
                            // but is not maintained when entering the forEach callback

                            /* eslint-disable @typescript-eslint/no-non-null-assertion */
                            vizInfo[type].dslStatements![dslStatement] ??= 0;
                            vizInfo[type].dslStatements![dslStatement] += usage;
                            /* eslint-enable @typescript-eslint/no-non-null-assertion */
                        }
                    );
                } else {
                    vizInfo[type].dslStatements = dslStatements;
                }
            }

            if (title) {
                vizInfo[type].titles ??= 0;
                (vizInfo[type].titles as number) += 1;
            }

            if (description) {
                vizInfo[type].descriptions ??= 0;
                (vizInfo[type].descriptions as number) += 1;
            }

            if (hideWhenNoData) {
                vizInfo[type].conditionallyVisible ??= 0;
                (vizInfo[type].conditionallyVisible as number) += 1;
            }

            vizInfo[type].count ??= 0;
            (vizInfo[type].count as number) += 1;

            if (options) {
                vizInfo[type].numOptions ??= 0;
                (vizInfo[type].numOptions as number) +=
                    Object.keys(options).length;

                vizInfo[type].options ??= {};
                Object.keys(options).forEach((option) => {
                    (vizInfo[type].options as Record<string, number>)[
                        option
                    ] ??= 0;
                    (vizInfo[type].options as Record<string, number>)[
                        option
                    ] += 1;
                });
            }
        }
    );

    return vizInfo;
};
