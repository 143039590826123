/**
 * isColor
 * invalid CSS property values don't persist, by setting style.color to the input value,
 * if we get an invalid value, input value is not a color
 *
 * @param {String} strColor
 * @return {Boolean}
 */
export const isColor = (strColor: string): boolean => {
    if (typeof strColor !== 'string') {
        return false;
    }
    const s = new Option().style;
    s.color = strColor.toLowerCase();
    return s.color !== '';
};

/**
 * sanitizeColor
 *
 * If the color isn't valid we return null
 *
 * @param {String} color
 * @return {String} color or null
 */
export const sanitizeColor = (color: string): string | null =>
    isColor(color) ? color : null;
