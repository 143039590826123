import * as React from 'react';
import * as T from 'prop-types';
import { get } from 'lodash';
import FeatureFlagContext from '@splunk/visualization-context/FeatureFlagContext';
import ImageContext from '@splunk/visualization-context/ImageContext';
import { FileDownloader } from '@splunk/visualizations-shared/FileDownloader';
import { URLDownloader, ImageRegistryFetcher, IDownloader } from '@splunk/visualizations-shared/Downloader';
import withDashboardViz, { DashboardVizOpts } from '../common/withDashboardViz';
import PureChoroplethSvgComponent from './PureChoroplethSvg/PureChoroplethSvg';
import SvgChoroplethDynamic from './PureChoroplethSvg/SvgChoroplethDynamic';
import vizConfig from './config';
import { DataSource } from '../common/interfaces/DataSource';
import { shouldShowIconPlaceholder } from '../common/components/IconPlaceholder';

export const config = vizConfig;
export const PureChoroplethSvg = PureChoroplethSvgComponent;
// todo: need remove when deleting @splunk/react-visualizations package
export const PureSvgChoroplethDynamic = SvgChoroplethDynamic;
/**
 * This function generates the protocol handlers
 * by default, svg supports data and local handler
 * if feature flag enableSvgHttpDownloader is turned on, then http and https also added into handlers
 * @param {FeatureFlagContext} featureFlagContext
 * @param {ImageContext} imageContext
 * @returns {object}
 */
export const createProtocolHandlers = (
    featureFlagContext: FeatureFlagContext = {},
    imageContext: ImageContext
): { [key: string]: IDownloader } => {
    const handlers: Record<string, any> = {
        data: new URLDownloader(),
    };
    if (imageContext) {
        imageContext.listProviders().forEach(({ type }): void => {
            handlers[type] = new ImageRegistryFetcher(imageContext);
        });
    }
    const { enableSvgHttpDownloader } = featureFlagContext;
    if (enableSvgHttpDownloader) {
        handlers.http = new URLDownloader();
        handlers.https = new URLDownloader();
    }

    return handlers;
};

const ChoroplethSvg = ({ ...props }) => {
    const { svg } = props;
    // note that the FileDownloader will just immediately pass through literal (non-url) content in the case that
    // the svg argument is not a url.
    return (
        <ImageContext.Consumer>
            {(imageContext: ImageContext): React.ReactElement => (
                <FeatureFlagContext.Consumer>
                    {(featureFlagContext: FeatureFlagContext): React.ReactElement => (
                        <FileDownloader
                            url={svg}
                            protocolHandlers={createProtocolHandlers(featureFlagContext, imageContext)}
                            renderContent={(svgString: string): React.ReactElement => {
                                const pureVizProps = { ...props, svg: svgString };
                                return <PureChoroplethSvg {...pureVizProps} />;
                            }}
                        />
                    )}
                </FeatureFlagContext.Consumer>
            )}
        </ImageContext.Consumer>
    );
};

ChoroplethSvg.propTypes = {
    svg: T.string,
};

export function useIconPlaceholder(
    dataSources: { [name: string]: DataSource },
    loading: boolean,
    options: Record<string, unknown>
): boolean {
    const svg = get(options, 'svg');
    const missingData = shouldShowIconPlaceholder(dataSources, loading);
    return missingData && !svg;
}

const platformViz = withDashboardViz({
    ReactViz: ChoroplethSvg,
    vizConfig,
    useIconPlaceholder,
} as DashboardVizOpts);
// (platformViz as any).defaultProps.width = '100%';
// (platformViz as any).defaultProps.height = '100%';
export default platformViz;
