import { COLOR_OR_TOKEN_PATTERN } from '@splunk/visualizations-shared/schemaUtils';

export default {
    svg: {
        description: 'Literal svg, or svg data URI.',
        type: 'string',
    },
    backgroundColor: {
        description: 'the background color behind the SVG, title, and description.',
        type: 'string',
        pattern: COLOR_OR_TOKEN_PATTERN,
        default: `Defaults by theme are #ffffff for enterprise, #1712d1 for enterprise dark, and #1a1c20 for prisma dark`,
    },
};
