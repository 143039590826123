import { chain, mapKeys, omit, uniqBy } from 'lodash';
import {
    SEMANTIC_SUCCESS,
    SEMANTIC_WARNING,
    SEMANTIC_ERROR,
} from '@splunk/visualizations-shared/colorConstants';
import { _ } from '@splunk/ui-utils/i18n';

export const GAUGE_THICKNESS = 50;
export const FILL_THICKNESS = 38;
const GRADIENT_STOP_OFFSET_MIN = 0;
const GRADIENT_STOP_OFFSET_MAX = 100;

/**
 * get width for the whole gauge viz for horizontal or vertical view
 * @method getGaugeWidth
 * @param {Object} input
 * @param {Number} containerWidth
 * @param {String} orientation
 * @return {Number} width
 */
export const getGaugeWidth = ({ containerWidth, orientation }) =>
    orientation === 'vertical' ? GAUGE_THICKNESS : containerWidth * 0.8;

/**
 * get height for the whole gauge viz for horizontal or vertical view
 * @method getGaugeHeight
 * @param {Object} input
 * @param {Number} containerHeight
 * @param {String} orientation
 * @return {Number} height
 */
export const getGaugeHeight = ({ containerHeight, orientation }) =>
    orientation === 'vertical' ? containerHeight * 0.8 : GAUGE_THICKNESS;

/**
 * get how long the gauge shoud be.
 * In horizontal view, gauge length equals to gauge width. In vertical view, it equals to gauge height.
 * @method getGaugeLength
 * @param {Object} input
 * @param {Number} input.containerWidth
 * @param {Number} input.containerHeight
 * @param {String} input.orientation
 * @return {Number} gaugeLength
 */
export const getGaugeLength = ({ containerWidth, containerHeight, orientation }) =>
    orientation === 'vertical'
        ? getGaugeHeight({ containerHeight, orientation })
        : getGaugeWidth({ containerWidth, orientation });

/**
 * get start x and y for rendering gauge
 * @method getGaugeStartPosition
 * @param {Object} input
 * @param {Number} containerWidth
 * @param {Number} containerHeight
 * @param {Number} gaugeLength
 * @param {String} orientation
 * @return {Object} position
 * @return {Number} position.gaugeStartX
 * @return {Number} position.gaugeStartY
 */
export const getGaugeStartPosition = ({ containerWidth, containerHeight, gaugeLength, orientation }) => {
    // for horizontal view
    let gaugeStartX = (containerWidth - gaugeLength) / 2.0;
    let gaugeStartY = (containerHeight - GAUGE_THICKNESS) / 2.0;

    if (orientation === 'vertical') {
        gaugeStartX = (containerWidth - GAUGE_THICKNESS) / 2.0;
        gaugeStartY = (containerHeight - gaugeLength) / 2.0;
    }

    return { gaugeStartX, gaugeStartY };
};

/**
 * get dimensions for the whole gauge viz,
 * which can be useful to calculate background bar and major ticks positions.
 * @method getGaugeDimensions
 * @param {Object} input
 * @param {Number} input.containerWidth
 * @param {Number} input.containerHeight
 * @param {String} input.orientation  'horizontal' or 'vertical'
 * @return {Object} gaugeDimensions
 * @return {Number} gaugeDimensions.gaugeLength  how long the gauge shoud be in horizontal or vertical orientation.
 * @return {Number} gaugeDimensions.gaugeStartX  start x to render gauge
 * @return {Number} gaugeDimensions.gaugeStartY  start y to render gauge
 * @return {Number} gaugeDimensions.gaugeWidth   natural width of gauge
 * @return {Number} gaugeDimensions.gaugeHeight  natural height of gauge
 */
export const getGaugeDimensions = ({ containerWidth, containerHeight, orientation }) => {
    const gaugeLength = getGaugeLength({ containerWidth, containerHeight, orientation });
    const { gaugeStartX, gaugeStartY } = getGaugeStartPosition({
        containerWidth,
        containerHeight,
        gaugeLength,
        orientation,
    });
    const gaugeWidth = getGaugeWidth({ containerWidth, orientation });
    const gaugeHeight = getGaugeHeight({ containerHeight, orientation });

    return {
        gaugeLength,
        gaugeStartX,
        gaugeStartY,
        gaugeWidth,
        gaugeHeight,
    };
};

/**
 * if gaugeColor is not specified, auto assign a gauge color to the fill bar
 * based on how much percentage the value is taken between min and max
 * @method assignGaugeColor
 * @params
 * */
export const assignGaugeColor = ({ value, min, max }) => {
    const rate = (value - min) / (max - min);

    if (rate <= 0.3) {
        return SEMANTIC_SUCCESS;
    }
    if (rate > 0.3 && rate <= 0.6) {
        return SEMANTIC_WARNING;
    }

    return SEMANTIC_ERROR;
};

/**
 * get inner bar length in gauge
 * @method getGaugeBarLength
 * @param {Object} input
 * @param {Number} input.gaugeLength
 * @param {Number} input.value
 * @param {Number} input.min
 * @param {Number} input.max
 * @return {Number} gaugeBarLength
 */
export const getGaugeBarLength = ({ gaugeLength, value, min, max }) => {
    if (max < min) {
        return null;
    }

    if (value >= max) {
        return gaugeLength;
    }

    if (value < min) {
        return 0;
    }

    return (gaugeLength * (value - min)) / (max - min);
};

/**
 * get x and y position for rendering inner bar in gauge
 * @method getGaugeBarStartPositions
 * @param {Object} input
 * @param {Number} input.gaugeStartX
 * @param {Number} input.gaugeStartY
 * @param {Number} input.gaugeWidth
 * @param {Number} input.gaugeHeight
 * @param {Number} input.gaugeBarLength
 * @param {String} input.orientation
 * @return {Object} position
 * @return {Number} position.gaugeBarX
 * @return {Number} position.gaugeBarY
 */
export const getGaugeBarStartPositions = ({
    gaugeStartX,
    gaugeStartY,
    gaugeWidth,
    gaugeHeight,
    gaugeBarLength,
    orientation,
}) => {
    let gaugeBarX = gaugeStartX;
    let gaugeBarY = gaugeStartY;

    if (orientation === 'vertical') {
        gaugeBarX += (gaugeWidth - FILL_THICKNESS) / 2;
        gaugeBarY += gaugeHeight - gaugeBarLength;
    } else {
        gaugeBarY += (gaugeHeight - FILL_THICKNESS) / 2;
    }

    return {
        gaugeBarX,
        gaugeBarY,
    };
};

/**
 * get width of inner bar in gauge
 * @method getGaugeBarWidth
 * @param {Object} input
 * @param {Number} input.gaugeBarLength
 * @param {String} orientation
 * @return {Number} gaugeBarWidth
 */
export const getGaugeBarWidth = ({ gaugeBarLength, orientation }) =>
    orientation === 'vertical' ? FILL_THICKNESS : gaugeBarLength;

/**
 * get height of inner bar in gauge
 * @method getGaugeBarWidth
 * @param {Object} input
 * @param {Number} input.gaugeBarLength
 * @param {String} orientation
 * @return {Number} gaugeBarHeight
 */
export const getGaugeBarHeight = ({ gaugeBarLength, orientation }) =>
    orientation === 'vertical' ? gaugeBarLength : FILL_THICKNESS;

/**
 * get dimensions for the fill bar in the gauge
 * @method getFillBarDimensions
 * @param {Object} input
 * @param {Number} input.gaugeLength
 * @param {Number} input.gaugeStartX
 * @param {Number} input.gaugeStartY
 * @param {Number} input.gaugeWidth,
 * @param {Number} input.gaugeHeight,
 * @param {Number} input.value,
 * @param {Number} input.min,
 * @param {Number} input.max,
 * @param {String} input.orientation
 * @return {Object} fillBarDimensions
 * @return {Number} fillBarDimensions.fillBarLength
 * @return {Number} fillBarDimensions.fillBarX
 * @return {Number} fillBarDimensions.fillBarY
 * @return {Number} fillBarDimensions.fillBarWidth
 * @return {Number} fillBarDimensions.fillBarHeight
 */
export const getFillBarDimensions = ({
    gaugeLength,
    gaugeStartX,
    gaugeStartY,
    gaugeWidth,
    gaugeHeight,
    value,
    min,
    max,
    orientation,
}) => {
    const gaugeBarLength = getGaugeBarLength({ gaugeLength, value, max, min });
    const { gaugeBarX: fillBarX, gaugeBarY: fillBarY } = getGaugeBarStartPositions({
        gaugeStartX,
        gaugeStartY,
        gaugeWidth,
        gaugeHeight,
        gaugeBarLength,
        orientation,
    });
    const fillBarWidth = getGaugeBarWidth({ gaugeBarLength, orientation });
    const fillBarHeight = getGaugeBarHeight({ gaugeBarLength, orientation });

    return {
        fillBarLength: gaugeBarLength,
        fillBarX,
        fillBarY,
        fillBarWidth,
        fillBarHeight,
    };
};

/**
 * get position x and y for value marker in filler gauge
 * @method getFillerGaugeValueMarkerPositions
 * @param {Object} input
 * @param {Number} input.fillBarX
 * @param {Number} input.fillBarY
 * @param {Number} input.fillBarLength
 * @param {String} input.orientation
 * @return {Object} position
 * @return {Number} position.valueMarkerX
 * @return {Number} position.valueMarkerY
 */
export const getFillerGaugeValueMarkerPositions = ({ fillBarX, fillBarY, fillBarLength, orientation }) => {
    let valueMarkerX = fillBarX;
    let valueMarkerY = fillBarY;

    if (orientation === 'vertical') {
        valueMarkerX -= (GAUGE_THICKNESS - FILL_THICKNESS) / 4;
    } else {
        valueMarkerX += fillBarLength;
        valueMarkerY -= (GAUGE_THICKNESS - FILL_THICKNESS) / 4;
    }

    return {
        valueMarkerX,
        valueMarkerY,
    };
};

/**
 * get dimensions for the marker bar in the gauge
 * @method getMarkerBarDimensions
 * @param {Object} input
 * @param {Number} input.gaugeLength
 * @param {Number} input.gaugeStartX
 * @param {Number} input.gaugeStartY
 * @param {Number} input.gaugeWidth,
 * @param {Number} input.gaugeHeight,
 * @param {String} input.orientation
 * @return {Object} markerBarDimensions
 * @return {Number} markerBarDimensions.markerBarLength
 * @return {Number} markerBarDimensions.markerBarX
 * @return {Number} markerBarDimensions.markerBarY
 * @return {Number} markerBarDimensions.markerBarWidth
 * @return {Number} markerBarDimensions.markerBarHeight
 */
export const getMarkerBarDimensions = ({
    gaugeLength,
    gaugeStartX,
    gaugeStartY,
    gaugeWidth,
    gaugeHeight,
    orientation,
}) => {
    const gaugeBarLength = gaugeLength; // For marker gauge, inner marker bar spans the entire gauge
    const { gaugeBarX: markerBarX, gaugeBarY: markerBarY } = getGaugeBarStartPositions({
        gaugeStartX,
        gaugeStartY,
        gaugeWidth,
        gaugeHeight,
        gaugeBarLength,
        orientation,
    });
    const markerBarWidth = getGaugeBarWidth({ gaugeBarLength, orientation });
    const markerBarHeight = getGaugeBarHeight({ gaugeBarLength, orientation });

    return {
        markerBarLength: gaugeBarLength,
        markerBarX,
        markerBarY,
        markerBarWidth,
        markerBarHeight,
    };
};

/**
 * get position x and y for value marker in marker gauge
 * @method getMarkerGaugeValueMarkerPositions
 * @param {Object} input
 * @param {Number} input.markerBarX
 * @param {Number} input.markerBarY
 * @param {Number} input.markerBarHeight
 * @param {Number} input.markerBarLength
 * @param {Number} input.value
 * @param {Number} input.min
 * @param {Number} input.max
 * @param {String} input.orientation
 * @return {Object} position
 * @return {Number} position.valueMarkerX
 * @return {Number} position.valueMarkerY
 */
export const getMarkerGaugeValueMarkerPositions = ({
    markerBarX,
    markerBarY,
    markerBarHeight,
    markerBarLength: gaugeLength,
    orientation,
    value,
    min,
    max,
}) => {
    let valueMarkerX = markerBarX;
    let valueMarkerY = markerBarY;
    if (orientation === 'vertical') {
        valueMarkerY += markerBarHeight - getGaugeBarLength({ gaugeLength, value, max, min });
        valueMarkerX -= (GAUGE_THICKNESS - FILL_THICKNESS) / 4;
    } else {
        valueMarkerX += getGaugeBarLength({ gaugeLength, value, max, min });
        valueMarkerY -= (GAUGE_THICKNESS - FILL_THICKNESS) / 4;
    }

    return {
        valueMarkerX,
        valueMarkerY,
    };
};

/**
 * sort the ranges in ascending order
 * @method getSortedRanges
 * @param {Array} ranges
 * @return {Array} sortedRanges
 */
export const getSortedRanges = ranges => ranges.sort((a, b) => a.from - b.from);

/**
 * validate whether ranges prop is valid - continuous ranges, range.to < range.from, range.from != range.to
 * @method validateRanges
 * @param {Array} ranges
 * @return {String} error
 */
export const validateRanges = ranges => {
    if (!ranges.length) {
        return _('Prop "ranges" is missing entries');
    }

    // check whether a range has "from" >= "to" eg: 10 - 10, 100 - 10, -20 - -10 are invalid
    if (ranges.some(range => range.from >= range.to)) {
        return _('Prop "ranges" has invalid entries: invalid range');
    }

    // check whether ranges have duplicate "from/to" eg: 0 - 50, 25 - 50
    if (ranges.length !== uniqBy(ranges, 'to').length || ranges.length !== uniqBy(ranges, 'from').length) {
        return _('Prop "ranges" has invalid entries: duplicate values');
    }

    const sortedRanges = getSortedRanges(ranges);

    // check whether ranges are continuous eg: 0 - 10, 10 - 20, 20 - 30
    for (let i = 1; i < sortedRanges.length; i += 1) {
        if (sortedRanges[i].from !== sortedRanges[i - 1].to) {
            return _('Prop "ranges" has invalid entries: discontinuous ranges');
        }
    }

    return null;
};

/**
 * calculate min and max value of gauge
 * @method getGaugeRange
 * @param {Array} ranges
 * @return {Object} gaugeRange
 * @return {Number} gaugeRange.min
 * @return {Number} gaugeRange.max
 */
export const getGaugeRange = ranges => {
    const min = Math.min(...ranges.map(range => range.from));
    const max = Math.max(...ranges.map(range => range.to));
    return { min, max };
};

/**
 * calculate stop offsets for linear gradient
 * @method getGradientStopOffsets
 * @param {Array} ranges
 * @param {String} orientation
 * @return {Array} cumulativeOffsets
 */
export const getGradientStopOffsets = ({ ranges, orientation }) => {
    const { min, max } = getGaugeRange(ranges);
    const offsetScale = (GRADIENT_STOP_OFFSET_MAX - GRADIENT_STOP_OFFSET_MIN) / (max - min);
    let offsets = ranges.map(range => Math.abs(range.from - range.to) * offsetScale);
    // When orientation is vertical, since the gauge starts from bottom to top, linear gradient should be reversed
    offsets = orientation === 'vertical' ? offsets.reverse() : offsets;
    const cumulativeOffsets = [];
    offsets.reduce((accumulator, currentValue, idx) => {
        cumulativeOffsets[idx] = accumulator + currentValue;
        return cumulativeOffsets[idx];
    }, 0);
    return cumulativeOffsets;
};

/**
 * calculate stop colors for linear gradient
 * @method getGradientStopColors
 * @param {Array} ranges
 * @param {String} orientation
 * @return {Array} stopColors
 */
export const getGradientStopColors = ({ ranges, orientation }) => {
    const stopColors = ranges.map(range => range.value);
    // When orientation is vertical, since the gauge starts from bottom to top, linear gradient should be reversed
    return orientation === 'vertical' ? stopColors.reverse() : stopColors;
};

/**
 * calculate linear gradient stops from ranges
 * @method getGradientStops
 * @param {Object} input
 * @param {Array} ranges
 * @param {String} orientation
 * @return {Array} stops
 */
export const getGradientStops = ({ ranges, orientation }) => {
    const stops = [];
    const offsets = getGradientStopOffsets({ ranges, orientation });
    const stopColors = getGradientStopColors({ ranges, orientation });
    // Initialize stops with the first color stop
    stops.push({ offset: `${offsets[0]}%`, stopColor: stopColors[0] });
    // Add the remaining color stops with gradient change at each stop
    for (let i = 1; i < ranges.length; i += 1) {
        stops.push({ offset: `${offsets[i - 1]}%`, stopColor: stopColors[i] });
        stops.push({ offset: `${offsets[i]}%`, stopColor: stopColors[i] });
    }
    return stops;
};

/**
 * calculate linear gradient dimensions
 * @method getGradientDimensions
 * @param {String} orientation
 * @return {Object} dimensions
 * @return {String} dimensions.x1
 * @return {String} dimensions.y1
 * @return {String} dimensions.x2
 * @return {String} dimensions.y2
 */
export const getGradientDimensions = orientation => {
    const x1 = '0%';
    const y1 = '0%';
    const x2 = orientation === 'horizontal' ? '100%' : '0%';
    const y2 = orientation === 'horizontal' ? '0%' : '100%';
    return { x1, y1, x2, y2 };
};

interface GaugeOptions {
    [key: string]: any;
}

// map for new options names to old option names
const gaugeOptionsMapping = {
    majorTickInterval: 'majorUnit',
    gaugeRanges: 'ranges',
};

/**
 * Helper method to update the option names which can be mapped to pure component options
 * @method mapToOldKey
 * @param {Object} options
 * @returns {Object}
 */
export const mapToOldKey = (options: GaugeOptions): GaugeOptions =>
    mapKeys(options, (val, key) => (gaugeOptionsMapping[key] ? gaugeOptionsMapping[key] : key));

type LabelOrValue = 'label' | 'value';

/**
 * Helper method to map labelDisplay and valueDisplay options to pure component option names
 * @method mapToOldKey
 * @param {string} labelOrValue
 * @param {Object} options
 * @returns {Object}
 */
export const mapValueLabelDisplay = (labelOrValue: LabelOrValue, options: GaugeOptions): GaugeOptions => {
    const rangeOrValue: string = labelOrValue === 'value' ? 'Value' : 'Range';
    const showKey: string = labelOrValue === 'value' ? 'showValue' : 'showLabels';
    const percentKey = `usePercentage${rangeOrValue}`;
    const optionString = `${labelOrValue}Display`;
    const newOptions = { ...options };
    if (newOptions[optionString]) {
        if (newOptions[optionString] === 'number') {
            newOptions[showKey] = true;
            newOptions[percentKey] = false;
        } else if (newOptions[optionString] === 'percentage') {
            newOptions[showKey] = true;
            newOptions[percentKey] = true;
        } else {
            newOptions[showKey] = false;
            newOptions[percentKey] = false;
        }
    }
    return omit(newOptions, [optionString]);
};

/**
 * Chained helper for mapping the option properties (and values) we expose in config.ts into property values that pure component accepts
 * This is for options that has new key name and can not directly be mapped with pure component props
 * @method convertToGaugeProperties
 * @param {Object} originalOptions
 * @returns {Object}
 */
export const convertToGaugeProperties = (originalOptions: GaugeOptions): GaugeOptions =>
    chain(mapToOldKey(originalOptions))
        .thru(options => mapValueLabelDisplay('label', options))
        .thru(options => mapValueLabelDisplay('value', options))
        .value();
