import { useCallback, useEffect, useReducer, useRef, useMemo } from 'react';
import { omit } from 'lodash';
import {
    useSearchContext,
    type SearchContextValue,
} from '@splunk/dashboard-context';
import type {
    SearchData,
    RequestParams,
    RefreshFunction,
    DataSourceBindingMap,
    BindingType,
} from '@splunk/dashboard-types';
import usePrevious from './usePrevious';
import useEventCallback from './useEventCallback';
import { useHandleItemTypeChange } from './useHandleItemTypeChange';

type UnsubscribeCallback = ReturnType<SearchContextValue['subscribe']>;

type Action =
    | { type: 'binding/remove'; bindingType: string }
    | { type: 'binding/reset'; bindingType: string }
    | {
          type: 'binding/update';
          bindingType: BindingType;
          payload: SearchData;
      };

export type DataSources = Record<BindingType, SearchData>;

export interface UseSubscribeToSearchesReturnType {
    dataSources: DataSources;
    loading: boolean;
    updateRequestParams: (
        bindingType: BindingType,
        newRequestParams: RequestParams
    ) => void;
    refresh: RefreshFunction;
}

type UseSubscribeToSearchesType = ({
    consumerId,
    bindings,
    subscribeFn,
}: {
    consumerId: string;
    bindings: DataSourceBindingMap;
    subscribeFn?: SearchContextValue['subscribe'];
}) => UseSubscribeToSearchesReturnType;

const EMPTY_STATE = {};

const getUnneededConsumers = ({
    previousBindings,
    newBindings,
    isNewConsumerId,
}: {
    previousBindings: DataSourceBindingMap;
    newBindings: DataSourceBindingMap;
    isNewConsumerId: boolean;
}) => {
    const previousBindingTypes = Object.keys(previousBindings);
    if (isNewConsumerId) {
        // if the consumer id changes, all subscriptions must be recreated
        return previousBindingTypes;
    }

    const unneededConsumers: string[] = [];
    previousBindingTypes.forEach((prevBindingType) => {
        const prevDsId = previousBindings[prevBindingType];
        const newDsId = newBindings[prevBindingType];
        // if either the bindingType does not exist OR if it exists the and the dsId changed
        if (typeof newDsId === 'undefined' || prevDsId !== newDsId) {
            unneededConsumers.push(prevBindingType);
        }
    });

    return unneededConsumers;
};

const reducer = (state: DataSources, action: Action): DataSources => {
    switch (action.type) {
        case 'binding/remove': {
            const { bindingType } = action;

            return omit(state, [bindingType]);
        }
        case 'binding/reset': {
            const { bindingType } = action;

            return {
                ...state,
                [bindingType]: {
                    error: undefined,
                    data: undefined,
                    meta: undefined,
                    requestParams: undefined,
                },
            };
        }
        case 'binding/update': {
            const { bindingType, payload } = action;

            return {
                ...state,
                [bindingType]: payload,
            };
        }
        default:
            return state ?? EMPTY_STATE;
    }
};

export const useSubscribeToSearches: UseSubscribeToSearchesType = ({
    consumerId,
    bindings,
    subscribeFn,
}) => {
    const [results, dispatch] = useReducer(reducer, {});
    const prevProps = usePrevious({ consumerId, bindings });
    const searchApi = useSearchContext();
    const subscribe = subscribeFn ?? searchApi.subscribe;

    const unsubscribeCallbacksRef = useRef<Record<string, UnsubscribeCallback>>(
        {}
    );

    const handleResultsFactory = (bindingType: string) => {
        return (payload: SearchData) => {
            dispatch({
                type: 'binding/update',
                bindingType,
                payload,
            });
        };
    };

    useEffect(() => {
        const isNewConsumerId = prevProps?.consumerId !== consumerId;
        // Prune out consumers that are no longer in the binding list
        //   if the consumerId changed, all the subscriptions have to be cancelled
        const unneededConsumers = getUnneededConsumers({
            previousBindings: prevProps?.bindings ?? {},
            newBindings: bindings,
            isNewConsumerId,
        });

        unneededConsumers.forEach((bindingType) => {
            unsubscribeCallbacksRef.current[bindingType]?.();
            delete unsubscribeCallbacksRef.current[bindingType];
            dispatch({ type: 'binding/remove', bindingType });
        });

        Object.entries(bindings).forEach(([bindingType, dsId]) => {
            if (
                typeof unsubscribeCallbacksRef.current[bindingType] !==
                'undefined'
            ) {
                // if the subscription already exists, don't create it again
                return;
            }

            /**
             * Initialize the bound resource with dummy data so that things like progress bars will appear
             * even if the DS hasn't started passing data back
             */
            dispatch({
                type: 'binding/reset',
                bindingType,
            });

            const unsubscribe = subscribe({
                dsId,
                consumerId,
                bindingType,
                subscriberId: consumerId,
                onUpdate: handleResultsFactory(bindingType),
            });

            // store the callbacks for later use
            unsubscribeCallbacksRef.current[bindingType] = unsubscribe;
        });
    }, [
        bindings,
        consumerId,
        prevProps?.bindings,
        prevProps?.consumerId,
        subscribe,
    ]);

    useEffect(() => {
        // clean-up any subscriptions on unmount.
        return () => {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            Object.values(unsubscribeCallbacksRef.current).forEach(
                (unsubscribe) => unsubscribe?.()
            );
        };
    }, []);

    const updateRequestParams = useCallback(
        (bindingType: string, newRequestParams: RequestParams) => {
            searchApi.updateRequestParams({
                consumerId,
                dsId: bindings[bindingType],
                bindingType,
                requestParams: newRequestParams,
            });
        },
        [bindings, consumerId, searchApi]
    );

    const refresh = useCallback(
        (options) => {
            Object.entries(bindings).forEach(([bindingType, dsId]) => {
                dispatch({
                    type: 'binding/reset',
                    bindingType,
                });
                searchApi.refresh({ consumerId, bindingType, dsId, options });
            });
            return Promise.resolve();
        },
        [bindings, consumerId, searchApi]
    );

    const handleClearResults = useCallback(
        (bindingType) =>
            dispatch({
                type: 'binding/reset',
                bindingType,
            }),
        []
    );

    useHandleItemTypeChange({
        consumerId,
        bindings,
        updateRequestParams,
        clearResults: handleClearResults,
    });

    // TODO: let's revisit this logic. What does loading really mean? What if there's data but the search status is 'running'?
    // results.primary initially does not exist, but if there is a valid dataSourceId we can assume it is loading
    const loading = useMemo(() => {
        if (Object.keys(bindings).length) {
            return results.primary
                ? !results.primary?.error && !results.primary?.data
                : true;
        }
        return false;
    }, [results.primary, bindings]);

    return {
        loading,
        dataSources: results,
        // by using useEventCallback, we avoid triggering multiple re-renders when these callbacks change
        updateRequestParams: useEventCallback(updateRequestParams),
        refresh: useEventCallback(refresh),
    };
};
