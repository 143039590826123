import type { Map } from 'maplibre-gl';
import { Marker } from 'maplibre-gl';
import * as React from 'react';
import type { TooltipProps } from './Tooltip';
import Tooltip from './Tooltip';

export interface MarkerWrapperProps extends React.HTMLAttributes<HTMLDivElement> {
    latitude: number;
    longitude: number;
    areaId?: string;
    areaValue?: number;
    additionalFields?: TooltipProps['additionalFields'];
    map: React.MutableRefObject<Map>;
    isChoroplethTooltipOpen?: boolean;
}

const MarkerWrapper = ({
    longitude,
    latitude,
    areaId,
    areaValue,
    additionalFields = [],
    map,
    isChoroplethTooltipOpen,
    children,
    ...otherProps
}: MarkerWrapperProps): JSX.Element => {
    const container = React.useRef<HTMLDivElement>();
    const marker = React.useRef<Marker>();
    const [isTooltipOpen, setTooltipOpenStatus] = React.useState(false);

    const isChoroplethTooltip = React.useCallback(() => isChoroplethTooltipOpen !== undefined, [
        isChoroplethTooltipOpen,
    ]);

    const openTooltip = React.useCallback(() => setTooltipOpenStatus(true), []);
    const closeTooltip = React.useCallback(() => setTooltipOpenStatus(false), []);

    const cleanupMarker = React.useCallback(() => {
        if (marker.current) {
            marker.current.remove();
            marker.current = undefined;
        }
    }, []);

    React.useEffect(() => {
        try {
            if (marker.current) {
                marker.current.setLngLat([longitude, latitude]);
            } else {
                marker.current = new Marker(container.current);
                marker.current.setLngLat([longitude, latitude]);
                marker.current.addTo(map.current);
            }
        } catch (error) {
            console.error(error);
            cleanupMarker();
        }
    }, [latitude, longitude, map]);

    React.useEffect(() => cleanupMarker, []);

    return (
        <div style={{ visibility: isChoroplethTooltip() ? 'hidden' : 'visible' }}>
            <div
                style={{ visibility: isChoroplethTooltip() ? 'hidden' : 'visible' }}
                ref={container}
                data-test="map-marker"
                onMouseOver={openTooltip}
                onMouseLeave={closeTooltip}
                onFocus={openTooltip}
                onBlur={closeTooltip}
                {...otherProps}
            >
                {children}
            </div>

            <div>
                <Tooltip
                    open={isChoroplethTooltip() ? isChoroplethTooltipOpen : isTooltipOpen}
                    anchor={container.current}
                    latitude={isChoroplethTooltip() ? null : latitude}
                    longitude={isChoroplethTooltip() ? null : longitude}
                    areaId={areaId}
                    areaValue={areaValue}
                    additionalFields={additionalFields}
                />
            </div>
        </div>
    );
};

export default MarkerWrapper;
