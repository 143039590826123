import { get, pickBy, some, each, uniq } from 'lodash';
import type {
    ExtendableDataSourceDefinition,
    TokenState,
} from '@splunk/dashboard-types';
import type { DashboardDefinition } from '@splunk/dashboard-definition';
import {
    MAX_CHAIN_LENGTH,
    replaceTokens,
    replaceTokensForObject,
} from '@splunk/dashboard-utils';

type RootDataSourcesDefinition = Record<string, ExtendableDataSourceDefinition>;

/**
 *
 * @param {String} dataSourceId
 * @param {Object} definition data source definition
 * @returns {Boolean}
 */
export const isBaseDataSource = (
    dataSourceId: string,
    definition: RootDataSourcesDefinition = {}
): boolean => {
    const dataSourceDef = definition[dataSourceId];

    if (!dataSourceDef || get(dataSourceDef, ['options', 'extend'])) {
        return false;
    }
    return some(
        Object.values(definition),
        (dsDef) => get(dsDef, ['options', 'extend']) === dataSourceId
    );
};

/**
 *
 * @param {String} dataSourceId
 * @param {Object} definition data source definition
 * @returns {Boolean}
 */
export const isChainDataSource = (
    dataSourceId: string,
    definition: RootDataSourcesDefinition = {}
): boolean => {
    const dataSourceDef = definition[dataSourceId];
    if (!dataSourceDef) {
        return false;
    }

    return !!get(dataSourceDef, ['options', 'extend']);
};

/**
 *
 * @param {String} dataSourceId
 * @param {Object} definition data source definition
 * @returns {Object} chain data sources
 */
export const getChainDataSources = (
    dataSourceId: string,
    definition: RootDataSourcesDefinition = {}
): RootDataSourcesDefinition => {
    return pickBy(
        definition,
        (dataSourceDef) =>
            get(dataSourceDef, ['options', 'extend']) === dataSourceId
    );
};

/**
 *
 * @param {String} dataSourceId
 * @param {Object} definition data source definition
 * @returns {Object} parent data source definition
 */
export const getParentDataSource = (
    dataSourceId: string,
    definition: RootDataSourcesDefinition = {}
): ExtendableDataSourceDefinition => {
    const extend = get(definition, [
        dataSourceId,
        'options',
        'extend',
    ]) as string;
    return definition[extend];
};

/**
 * Merge base and sub searches with pipe '|'
 * @param {String} base
 * @param {String} sub
 */
export const mergeSearches = (base = '', sub = ''): string =>
    [base.replace(/\s*\|\s*$/g, ''), sub.replace(/^\s*\|\s*/g, '')].join(' | ');

/**
 *
 * @param {String} baseDataSourceId
 * @param {Object} definition data source definition
 * @param {Object} parentQuery used for function recursion
 * @returns {Array} Post Search query array
 */
export const createPostSearchQueryArray = (
    baseDataSourceId: string,
    definition: RootDataSourcesDefinition = {},
    parentQuery = ''
): string[] => {
    const baseDataSourceDef = definition[baseDataSourceId];

    if (!baseDataSourceDef) {
        return [];
    }
    const results: string[] = [];
    const chainDataSources = getChainDataSources(baseDataSourceId, definition);

    each(chainDataSources, (chainDataSourceDef, chainDataSourceId) => {
        const query = get(chainDataSourceDef, ['options', 'query']) as string;

        const fullQuery = parentQuery
            ? mergeSearches(parentQuery, query)
            : query;

        results.push(
            fullQuery,
            ...createPostSearchQueryArray(
                chainDataSourceId,
                definition,
                fullQuery
            )
        );
    });

    return uniq(results);
};

/**
 * Reducer to filter out subset of searches that chain from the given search
 * @param {String} baseDataSourceId
 * @param {Object} definition data source definition
 * @returns {Object} baseChainModel
 */
/* eslint no-param-reassign: ["error", { "props": true, "ignorePropertyModificationsFor": ["result"] }] */
export const createBaseChainModel = (
    baseDataSourceId: string,
    definition: RootDataSourcesDefinition = {},
    result: RootDataSourcesDefinition = {}
): RootDataSourcesDefinition => {
    result[baseDataSourceId] = definition[baseDataSourceId];

    const chainDataSources = getChainDataSources(baseDataSourceId, definition);

    Object.keys(chainDataSources).forEach((chainDataSourceId) =>
        createBaseChainModel(chainDataSourceId, definition, result)
    );

    return result;
};

/**
 *
 * @param {String} dataSourceId
 * @param {Object} definition ata source definition
 * @returns {String}
 */
export const getBaseDataSourceId = (
    dataSourceId: string,
    definition: RootDataSourcesDefinition = {}
): string | null => {
    if (isBaseDataSource(dataSourceId, definition)) {
        return dataSourceId;
    }

    const extend = get(definition, [dataSourceId, 'options', 'extend']);

    return extend ? getBaseDataSourceId(extend, definition) : null;
};

interface DataSourceMeta {
    savedSearchQuery?: string;
    earliestTime?: string;
    latestTime?: string;
    [key: string]: unknown;
}

interface GetCompleteSearchQueryProps {
    definition: DashboardDefinition;
    dataSourceId: string;
    getMetaData?: (dataSourceId: string) => DataSourceMeta;
    count?: number;
    submittedTokens?: TokenState;
}

/**
 * Fetch the complete SPL search of a datasource including its ancestors
 * @param {Object} definition DashboardDefinition
 * @param {String} dataSourceId
 * @param {Function} getMetaData A method used to get the query for a saved search
 * @param {Number} count
 * @param {Object} submittedTokens to replace $tokens$ in the query with current values
 * @returns {String} SPL search query of a data source and its ancestors
 */
export const getCompleteSearchQuery = ({
    definition,
    dataSourceId,
    getMetaData,
    count = 0,
    submittedTokens,
}: GetCompleteSearchQueryProps): string => {
    const ds = definition.getDataSource(dataSourceId);
    // if the base search is a saved search use getMetaData to get the query
    const rawQuery =
        get(ds, 'type', '') === 'ds.savedSearch' && getMetaData
            ? get(getMetaData(dataSourceId), 'savedSearchQuery', '')
            : get(ds, 'options.query', '');

    const query = submittedTokens
        ? (replaceTokens(rawQuery, submittedTokens) as string)
        : rawQuery;

    const parentDsId = get(ds, 'options.extend', '') as string;
    return parentDsId && count < MAX_CHAIN_LENGTH
        ? `${getCompleteSearchQuery({
              definition,
              dataSourceId: parentDsId,
              getMetaData,
              count: count + 1,
              submittedTokens,
          })} \n| ${query.trim().replace(/^\|\s*/g, '')}`
        : query;
};

interface GetBaseTimeProps {
    definition: DashboardDefinition;
    dataSourceId?: string;
    getMetaData?: (dataSourceId: string) => DataSourceMeta;
}

/**
 * Get earliest and latest query parameters of the highest ancestor in datasource chain
 * @param {Object} definition DashboardDefinition
 * @param {String} dataSourceId
 * @param {Function} getMetaData A method used to get the query for a saved search
 * @returns {Object} The search times of the base search of this datasource chain
 */
export const getBaseTime = ({
    definition,
    dataSourceId,
    getMetaData,
}: GetBaseTimeProps): { earliest: string; latest: string } => {
    const defaultEarliest = '-24h@h';
    const defaultLatest = 'now';

    if (!dataSourceId) {
        return {
            earliest: defaultEarliest,
            latest: defaultLatest,
        };
    }
    let parentDsId = dataSourceId;
    let highestAncestorDsId = dataSourceId;
    let count = 0;
    while (parentDsId && count <= MAX_CHAIN_LENGTH) {
        highestAncestorDsId = parentDsId;
        parentDsId = get(
            definition.getDataSource(parentDsId),
            'options.extend',
            ''
        );
        count += 1;
    }
    const highestAncestorDs = definition.getDataSource(highestAncestorDsId);

    // if the base search is a saved search use getMetaData to get the query
    if (
        highestAncestorDs &&
        highestAncestorDs.type === 'ds.savedSearch' &&
        getMetaData
    ) {
        const { earliestTime, latestTime } = getMetaData(highestAncestorDsId);
        return {
            earliest: earliestTime || defaultEarliest,
            latest: latestTime || defaultLatest,
        };
    }

    const baseSearchDefaultQueryParameters =
        (definition.getDataSourceDefaultOptions(highestAncestorDsId)
            ?.queryParameters ?? {}) as Record<string, string>;
    const baseSearchQueryParameters = (highestAncestorDs?.options
        ?.queryParameters ?? {}) as Record<string, string>;
    return {
        earliest:
            baseSearchQueryParameters.earliest ||
            baseSearchDefaultQueryParameters.earliest ||
            defaultEarliest,
        latest:
            baseSearchQueryParameters.latest ||
            baseSearchDefaultQueryParameters.latest ||
            defaultLatest,
    };
};

type GetCompleteSearchQueryAndParametersProps = GetBaseTimeProps & {
    submittedTokens?: TokenState;
};

/**
 * Fetch the complete SPL search query and query parameters of a datasource including its ancestors
 * @param {DashboardDefinition} definition DashboardDefinition
 * @param {String} dataSourceId
 * @param {Function} getMetaData A method used to get the query for a saved search
 * @param {Object} submittedTokens Tokens we use in the query
 * @returns {Object} Search options including the query and query parameters
 */
export const getCompleteSearchQueryAndParameters = ({
    definition,
    dataSourceId,
    getMetaData,
    submittedTokens,
}: GetCompleteSearchQueryAndParametersProps): {
    query?: string;
    queryParameters?: Record<string, unknown>;
} => {
    if (!dataSourceId) {
        return {};
    }

    const queryParameters = getBaseTime({
        definition,
        dataSourceId,
        getMetaData,
    });

    return {
        query: getCompleteSearchQuery({
            definition,
            dataSourceId,
            getMetaData,
            submittedTokens,
        }),
        queryParameters: submittedTokens
            ? (replaceTokensForObject(
                  queryParameters,
                  submittedTokens
              ) as ReturnType<typeof getBaseTime>)
            : queryParameters,
    };
};
