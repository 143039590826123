import filterTitleAndDescriptions from './definitionSubFilters/filterTitleAndDescriptions';
import filterIds from './definitionSubFilters/filterIds';
import filterEncodings from './definitionSubFilters/filterEncodings';
import filterOptions from './definitionSubFilters/filterOptions';
import filterEventHandlers from './definitionSubFilters/filterEventHandlers';
import filterDataSources from './definitionSubFilters/filterDataSources';
import type { Definition } from './FilterTypes';
import type { FilterFunction } from './FilterStrategyTypes';

// this is to make the type compatible with filterStrategies.ts
interface FilterDefinition extends FilterFunction {
    (definition: Definition): Definition;
}

const filterDefinition: FilterDefinition = (definition: unknown = {}) =>
    filterDataSources(
        filterEventHandlers(
            filterEncodings(
                filterOptions(
                    filterIds(
                        filterTitleAndDescriptions(definition as Definition)
                    )
                )
            )
        )
    );

export default filterDefinition;
