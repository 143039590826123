import { parse as baseParse, mergeEncoding } from '@splunk/visualization-encoding-parsers/Base';
import isEmpty from 'lodash/isEmpty';
import { ErrorMsgs } from './errorMsgs';

export const dataContract = {
    requiredDataSources: [
        {
            name: 'primary',
            description: 'DataSource that powers the visualization',
        },
    ],
    initialRequestParams: {
        primary: (options = {}) => ({
            offset: 0,
            count: options.count || 1000,
            requestTotalCount: options.pageable,
        }),
    },
    encoding: {
        x: {
            isRequired: true,
            type: ['time', 'number', 'string'],
            default: 'primary[0]',
        },
        y: {
            isRequired: true,
            type: ['number', 'string'],
            default: 'primary[1:]',
        },
        y2: {
            isRequired: false,
            type: ['number', 'string'],
        },
    },
};

/**
 * Bar parse function
 * @param {object} dataSources
 * @param {object} userDefinedEncoding
 * @return {object} props that dashboard/react visualizations can be used.
 * @public
 */
export const parse = (dataSources, userDefinedEncoding) => {
    if (isEmpty(dataSources)) {
        throw new Error(ErrorMsgs.NO_DATA_SOURCES);
    }

    const encoding = mergeEncoding(dataContract.encoding, userDefinedEncoding);
    const parsedProps = baseParse(dataSources, encoding);

    return {
        x: parsedProps.x,
        y: parsedProps.y,
        y2: parsedProps.y2,
        xFieldName: parsedProps['_meta'].fieldNames.x, // eslint-disable-line dot-notation
        yFieldName: parsedProps['_meta'].fieldNames.y, // eslint-disable-line dot-notation
        y2FieldName: parsedProps['_meta'].fieldNames.y2, // eslint-disable-line dot-notation
    };
};
