import { useEffect, useRef } from 'react';
import { selectSelectedItems, useSelector } from '@splunk/dashboard-state';
import type { UseSelectedItemsChangeCallbackProps } from './onChange.types';

export const useSelectedItemsChangeCallback = ({
    onItemsSelect: onChange,
}: UseSelectedItemsChangeCallbackProps) => {
    const selectedItems = useSelector(selectSelectedItems);
    const firstRender = useRef(true);

    useEffect(() => {
        if (!onChange || firstRender.current) {
            firstRender.current = false;
            return;
        }

        onChange(selectedItems);
    }, [selectedItems, onChange]);
};
