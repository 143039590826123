import { _ } from '@splunk/ui-utils/i18n';

const editorConfig = [
    {
        label: _('Image'),
        open: true,
        layout: [
            [
                {
                    label: _(''),
                    option: 'src',
                    editor: 'editor.image',
                },
            ],
            [
                {
                    label: _('Preserve Aspect Ratio'),
                    option: 'preserveAspectRatio',
                    editor: 'editor.checkbox',
                    showEditor: ({ options }) => !!options.src,
                },
            ],
        ],
    },
];

export default editorConfig;
