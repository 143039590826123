import invert from 'lodash/invert';

const simpleXMLOptionsToPropsMap = {
    'annotation.categoryColors': 'annotationColor',
    'axisLabelsX.axisVisibility': 'xAxisVisibility',
    'axisLabelsY.axisVisibility': 'yAxisVisibility',
    'axisLabelsX.integerUnits': 'roundXAxisLabelsToInteger',
    'axisLabelsY.integerUnits': 'roundYAxisLabelsToInteger',
    'axisLabelsY2.integerUnits': 'roundY2AxisLabelsToInteger',
    'axisLabelsY2.axisVisibility': 'y2AxisVisibility',
    'axisLabelsX.extendsAxisRange': 'xAxisExtendsAxisRange',
    'axisLabelsY.extendsAxisRange': 'yAxisExtendsAxisRange',
    'axisLabelsX.majorLabelStyle.rotation': 'xAxisMajorLabelRotation',
    'axisLabelsX.majorLabelVisibility': 'xAxisMajorLabelVisibility',
    'axisLabelsY.majorLabelVisibility': 'yAxisMajorLabelVisibility',
    'axisLabelsY2.majorLabelVisibility': 'y2AxisMajorLabelVisibility',
    'axisLabelsX.majorTickSize': 'xAxisMajorTickSize',
    'axisLabelsY.majorTickSize': 'yAxisMajorTickSize',
    'axisLabelsY2.majorTickSize': 'y2AxisMajorTickSize',
    'axisLabelsX.minorTickSize': 'xAxisMinorTickSize',
    'axisLabelsY.minorTickSize': 'yAxisMinorTickSize',
    'axisLabelsY2.minorTickSize': 'y2AxisMinorTickSize',
    'axisLabelsX.majorTickVisibility': 'xAxisMajorTickVisibility',
    'axisLabelsY.majorTickVisibility': 'yAxisMajorTickVisibility',
    'axisLabelsY2.majorTickVisibility': 'y2AxisMajorTickVisibility',
    'axisLabelsX.majorUnit': 'xAxisMajorUnit',
    'axisLabelsY.majorUnit': 'yAxisMajorUnit',
    'axisLabelsY2.majorUnit': 'y2AxisMajorUnit',
    'axisLabelsX.minorTickVisibility': 'xAxisMinorTickVisibility',
    'axisLabelsY.minorTickVisibility': 'yAxisMinorTickVisibility',
    'axisLabelsY2.minorTickVisibility': 'y2AxisMinorTickVisibility',
    'axisLabelsX.maxLabelParts': 'xAxisMaxLabelParts',
    'axisX.abbreviation': 'xAxisAbbreviation',
    'axisY.abbreviation': 'yAxisAbbreviation',
    'axisY2.abbreviation': 'y2AxisAbbreviation',
    'axisX.includeZero': 'xAxisIncludeZero',
    'axisY.includeZero': 'yAxisIncludeZero',
    'axisY2.includeZero': 'y2AxisIncludeZero',
    'axisX.maximumNumber': 'xAxisMax',
    'axisY.maximumNumber': 'yAxisMax',
    'axisY2.maximumNumber': 'y2AxisMax',
    'axisX.minimumNumber': 'xAxisMin',
    'axisY.minimumNumber': 'yAxisMin',
    'axisY2.minimumNumber': 'y2AxisMin',
    'axisX.scale': 'xAxisScale',
    'axisY.scale': 'yAxisScale',
    'axisY2.scale': 'y2AxisScale',
    'axisTitleX.text': 'xAxisTitleText',
    'axisTitleY.text': 'yAxisTitleText',
    'axisTitleY2.text': 'y2AxisTitleText',
    'axisTitleX.alignment': 'xAxisTitleAlignment',
    'axisTitleY.alignment': 'yAxisTitleAlignment',
    'axisTitleY2.alignment': 'y2AxisTitleAlignment',
    'axisTitleX.visibility': 'xAxisTitleVisibility',
    'axisTitleY.visibility': 'yAxisTitleVisibility',
    'axisTitleY2.visibility': 'y2AxisTitleVisibility',
    'chart.resultTruncationLimit': 'resultTruncationLimit',
    'chart.overlayFields': 'overlayFields',
    'axisY2.enabled': 'enableY2Axis',
    'axisY2.fields': 'y2AxisField',
    'chart.barSpacing': 'barSpacing',
    'chart.columnSpacing': 'columnSpacing',
    'chart.seriesSpacing': 'seriesSpacing',
    'chart.nullValueMode': 'nullValueMode',
    'chart.markerSize': 'markerSize',
    'chart.showDataLabels': 'showDataLabels',
    'chart.showLegend': 'showLegend',
    'chart.showLines': 'showLines',
    'chart.showMarkers': 'showMarkers',
    'chart.stackMode': 'stackMode',
    'data.fieldListMode': 'fieldListMode',
    'data.fieldShowList': 'fieldShowList',
    'data.fieldHideList': 'fieldHideList',
    'gridLinesX.showMajorLines': 'showMajorXGridLines',
    'gridLinesY.showMajorLines': 'showMajorYGridLines',
    'gridLinesY2.showMajorLines': 'showMajorY2GridLines',
    'gridLinesX.showMinorLines': 'showMinorXGridLines',
    'gridLinesY.showMinorLines': 'showMinorYGridLines',
    'gridLinesY2.showMinorLines': 'showMinorY2GridLines',
    'layout.splitSeries': 'splitSeries',
    'layout.splitSeries.allowIndependentYRanges': 'allowIndependentYRanges',
    'legend.labels': 'legendLabels',
    'legend.labelStyle.overflowMode': 'legendLabelOverflowMode',
    'legend.masterLegend': 'masterLegend',
    'legend.mode': 'legendMode',
    'legend.placement': 'legendPlacement',
    'chart.bubbleMaximumSize': 'bubbleMaxSize',
    'chart.bubbleMinimumSize': 'bubbleMinSize',
    'chart.bubbleSizeBy': 'bubbleSizeBy',
};

const propsToSimpleXMLOptionsMap = invert(simpleXMLOptionsToPropsMap);

// export to make it testable
export const replaceObjectKeysFromMap = (object = {}, map = {}, isValidKey = () => true) => {
    const nuObject = {};
    Object.keys(object).forEach(key => {
        const nuKey = map[key];
        if (!nuKey) {
            if (isValidKey(key)) {
                // @TODO(pwied):
                // get rid of this and ensure all known props are passed down to visualizations explicitly
                // captured here: SCP-14634
                nuObject[key] = object[key];
            }
        } else {
            nuObject[nuKey] = object[key];
        }
    });
    return nuObject;
};

export const getPropsForSimpleXMLOptions = (options = {}) =>
    replaceObjectKeysFromMap(options, simpleXMLOptionsToPropsMap, value => value.indexOf('.') === -1);

export const getSimpleXMLOptionsForProps = (props = {}) =>
    replaceObjectKeysFromMap(props, propsToSimpleXMLOptionsMap);
