import {
    createSelector,
    createSlice,
    type PayloadAction,
} from '@reduxjs/toolkit';
import type {
    InputValue,
    InputValueSlice,
    GlobalState,
} from '@splunk/dashboard-types';
import { createInputState } from '../utils/input';

interface UpdateInputStatePayload {
    id: string;
    inputState: InputValue;
}

const initialState: InputValueSlice = {};

export const selectInputState = (state: GlobalState): InputValueSlice =>
    state.inputState;

const selectInputId = (_state: GlobalState, inputId: string) => inputId;

export const selectInputValue = createSelector(
    [selectInputState, selectInputId],
    (inputState, inputId) => inputState?.[inputId]?.value
);

const inputSlice = createSlice({
    name: 'inputState',
    initialState,
    reducers: {
        reset: {
            reducer: (_state, action: PayloadAction<InputValueSlice>) =>
                action.payload,
            prepare: ({
                currentInputState,
                tokens,
                inputDefs,
                preset,
                isReset,
            }) => ({
                payload: createInputState({
                    currentInputState,
                    tokens,
                    inputDefs,
                    preset,
                    isReset,
                }),
            }),
        },
        update: {
            reducer: (
                state,
                action: PayloadAction<UpdateInputStatePayload>
            ) => {
                const { id, inputState } = action.payload;
                state[id] = inputState;
            },
            prepare: (id: string, inputState: InputValue) => ({
                payload: { id, inputState },
            }),
        },
    },
});

export const { reset: setInputState, update: updateInputState } =
    inputSlice.actions;
export default inputSlice.reducer;
