import React from 'react';
import { _ } from '@splunk/ui-utils/i18n';
import GaugeRadial from '@splunk/react-icons/enterprise/GaugeRadial';

const RadialGauge = (
    props: React.ComponentProps<typeof GaugeRadial>
): ReturnType<typeof GaugeRadial> => (
    <GaugeRadial {...props} screenReaderText={_('Radial Gauge')} />
);

export default RadialGauge;
