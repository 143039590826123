import React from 'react';
import SVG, {
    type SVGPropsOptionalViewBox,
} from '@splunk/react-icons/SVGEnterprise';

/* eslint-disable max-len */
export default function Firewall(
    props: SVGPropsOptionalViewBox
): ReturnType<typeof SVG> {
    return (
        <SVG viewBox="0 0 60 56" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path d="m3 0c-1.65685 0-3 1.34315-3 3v38c0 1.6569 1.34315 3 3 3h15.4103c2.7175 4.7725 6.6035 8.8836 11.4113 11.8885.1091.0682.2477.0682.3568 0 4.8078-3.0049 8.6938-7.116 11.4113-11.8885h15.4103c1.6569 0 3-1.3431 3-3v-38c0-1.65685-1.3431-3-3-3zm39.6414 42h14.3586c.5523 0 1-.4477 1-1v-27h-56v27c0 .5523.44772 1 1 1h14.3586c-2.1771-4.5005-3.3586-9.4982-3.3586-14.6576v-1.0954c0-.1486.0922-.2816.2313-.3337l15.4176-5.7816c.2264-.0849.4758-.0849.7022 0l15.4176 5.7816c.1391.0521.2313.1851.2313.3337v1.0954c0 5.1594-1.1815 10.1571-3.3586 14.6576zm-40.6414-39c0-.55228.44772-1 1-1h54c.5523 0 1 .44772 1 1v9h-56zm16 4c0 1.10457-.8954 2-2 2s-2-.89543-2-2 .8954-2 2-2 2 .89543 2 2zm-10 2c1.10457 0 2-.89543 2-2s-.89543-2-2-2-2 .89543-2 2 .89543 2 2 2zm16.9788 38.114.7547.677 2.3233 1.6758-.4248-1.688c-.168-.6677-.028-1.3757.3815-1.9292.204-.2758.4672-.5025.7702-.6635l1.3758-.7307c.5697-.3026 1.1059-.6644 1.5997-1.0794l1.7628-1.4816-.1738 1.7132c-.0514.5064-.1543 1.0063-.3072 1.4918-.2735.8688-.7034 1.6804-1.2685 2.3948l-1.5604 1.9726 1.9202-.631c1.2434-.4087 2.3436-1.165 3.1704-2.1795.3013-.3698.5632-.7701.7814-1.1943l.2825-.5494c.3108-.6042.5219-1.2547.6253-1.9264.1344-.874.0837-1.7666-.149-2.6197l-.1826-.6695c-.1228-.4502-.2783-.8908-.4654-1.3184l-.1495-.3418c-.5502-1.2576-1.2742-2.4318-2.1508-3.4881l-1.2674-1.5273v1.7958c0 .6437-.1363 1.28-.4001 1.8672-.4309.9594-1.1803 1.7401-2.1213 2.2099l-.1372.0686c-.6918.3454-1.4545.5252-2.2277.5252h-.7042l.0869-.2552c.3276-.9621.8893-1.8277 1.6347-2.5187.4102-.3803.7664-.815 1.0587-1.2919l.7058-1.1517c.3268-.5333.5625-1.1174.6973-1.7282.2228-1.0094.1635-2.0607-.1715-3.0386l-.8365-2.4416c-.8394 2.383-2.4526 4.4163-4.5824 5.7755l-.2877.1837-.638.4522c-1.3784.977-2.4343 2.3427-3.0328 3.9226l-.1005.2651c-.221.5835-.3573 1.1958-.4047 1.818-.1077 1.4155.2487 2.8276 1.0153 4.0224l.8472 1.3204c.5442.8481 1.2002 1.619 1.9503 2.2919z" />
        </SVG>
    );
}
