export default {
    $schema: 'http://json-schema.org/draft-07/schema#',
    $id: 'http://json-schema.org/draft-07/schema#',
    title: 'Core schema meta-schema',
    definitions: {
        schemaArray: {
            type: 'array',
            minItems: 1,
            items: { $ref: '#' },
        },
        nonNegativeInteger: {
            type: 'integer',
            minimum: 0,
        },
        nonNegativeIntegerDefault0: {
            allOf: [{ $ref: '#/definitions/nonNegativeInteger' }, { default: 0 }],
        },
        simpleTypes: {
            enum: ['array', 'boolean', 'integer', 'null', 'number', 'object', 'string'],
        },
        stringArray: {
            type: 'array',
            items: { type: 'string' },
            uniqueItems: true,
            default: [],
        },
    },
    type: ['object', 'boolean'],
    properties: {
        $id: {
            type: 'string',
            format: 'uri-reference',
        },
        $schema: {
            type: 'string',
            format: 'uri',
        },
        $ref: {
            type: 'string',
            format: 'uri-reference',
        },
        $comment: {
            type: 'string',
        },
        title: {
            type: 'string',
        },
        description: {
            type: 'string',
        },
        default: true,
        readOnly: {
            type: 'boolean',
            default: false,
        },
        examples: {
            type: 'array',
            items: true,
        },
        multipleOf: {
            type: 'number',
            exclusiveMinimum: 0,
        },
        maximum: {
            type: 'number',
        },
        exclusiveMaximum: {
            type: 'number',
        },
        minimum: {
            type: 'number',
        },
        exclusiveMinimum: {
            type: 'number',
        },
        maxLength: { $ref: '#/definitions/nonNegativeInteger' },
        minLength: { $ref: '#/definitions/nonNegativeIntegerDefault0' },
        pattern: {
            type: 'string',
            format: 'regex',
        },
        additionalItems: { $ref: '#' },
        items: {
            anyOf: [{ $ref: '#' }, { $ref: '#/definitions/schemaArray' }],
            default: true,
        },
        maxItems: { $ref: '#/definitions/nonNegativeInteger' },
        minItems: { $ref: '#/definitions/nonNegativeIntegerDefault0' },
        uniqueItems: {
            type: 'boolean',
            default: false,
        },
        contains: { $ref: '#' },
        maxProperties: { $ref: '#/definitions/nonNegativeInteger' },
        minProperties: { $ref: '#/definitions/nonNegativeIntegerDefault0' },
        required: { $ref: '#/definitions/stringArray' },
        additionalProperties: { $ref: '#' },
        definitions: {
            type: 'object',
            additionalProperties: { $ref: '#' },
            default: {},
        },
        properties: {
            type: 'object',
            additionalProperties: { $ref: '#' },
            default: {},
        },
        patternProperties: {
            type: 'object',
            additionalProperties: { $ref: '#' },
            propertyNames: { format: 'regex' },
            default: {},
        },
        dependencies: {
            type: 'object',
            additionalProperties: {
                anyOf: [{ $ref: '#' }, { $ref: '#/definitions/stringArray' }],
            },
        },
        propertyNames: { $ref: '#' },
        const: true,
        enum: {
            type: 'array',
            items: true,
            minItems: 1,
            uniqueItems: true,
        },
        type: {
            anyOf: [
                { $ref: '#/definitions/simpleTypes' },
                {
                    type: 'array',
                    items: { $ref: '#/definitions/simpleTypes' },
                    minItems: 1,
                    uniqueItems: true,
                },
            ],
        },
        format: { type: 'string' },
        contentMediaType: { type: 'string' },
        contentEncoding: { type: 'string' },
        if: { $ref: '#' },
        then: { $ref: '#' },
        else: { $ref: '#' },
        allOf: { $ref: '#/definitions/schemaArray' },
        anyOf: { $ref: '#/definitions/schemaArray' },
        oneOf: { $ref: '#/definitions/schemaArray' },
        not: { $ref: '#' },
    },
    default: true,
};

export const createTestSchema = optionsSchema => ({
    $schema: 'http://json-schema.org/draft-07/schema#',
    $id: 'http://www.splunk.com/dashboard.schema.json',
    title: 'Dashboard Definition',
    description: 'Dashboard Definition',
    type: 'object',
    properties: optionsSchema,
});

// Below 2 regexs are copied from dashboard-framework/packages/dashboard-utils/src/token.jsc
const TOKEN_NAMESPACE_PREFIX_PATTERN = /(\w+:)?/.source;
// ex: token.name|suh               (old-style filter chain)
// ex: token.name|lower|capitalize  (new-style filter chain)
const TOKEN_NAME_CHARS_PATTERN = /([^$|:]+?)(\|[|\w]+)?/.source;

export const TOKEN_PATTERN =
    /\$/.source + TOKEN_NAMESPACE_PREFIX_PATTERN + TOKEN_NAME_CHARS_PATTERN + /\$/.source;
// rgb and rgba regexes were derived from:
// https://stackoverflow.com/questions/9585973/javascript-regular-expression-for-rgb-values/9586150
// https://github.com/regexhq/rgba-regex
// NOTE: this will incorrectly accept values like rgb(999,999,999), but may be overkill to specify a regex that limits values to <= 255
export const rgbColorPattern = /^rgb\(\s*(\d{1,3}),\s*(\d{1,3}),\s*(\d{1,3})\)$/;
export const rgbaColorPattern = /^rgba\(\s*(\d{1,3}%?),\s*(\d{1,3}%?),\s*(\d{1,3}%?),\s*(\d*(?:\.\d+)?)\)$/;
// todo: rename this to COLOR_PATTERN and replace all occurences.
export const colorPattern = '^#[0-9a-fA-F]{6}$|transparent';
export const colorPatternWithRGBA = `${colorPattern}|${rgbColorPattern.source}|${rgbaColorPattern.source}`;
export const COLOR_OR_TOKEN_PATTERN = `${colorPattern}|${TOKEN_PATTERN}`;
export const COLOR_OR_TOKEN_PATTERN_WITH_RGBA = `${colorPatternWithRGBA}|${TOKEN_PATTERN}`;
// dynamic option DSL expressions always start with a >
export const DYNAMIC_OPTIONS_DSL_PATTERN = /^>.*/.source;
/**
 * Returns the regex string that matches each passed in value as well as tokens.
 * @param {Array.<string>} values
 * @returns {string}
 */
export const getPattern = values =>
    values.reduce((accumulator, value) => `${accumulator}|^${value}$`, TOKEN_PATTERN);
