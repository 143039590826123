import React from 'react';
import T from 'prop-types';
import { RADIAL_BACKGROUND_COLOR, RADIAL_STROKE_COLOR } from '@splunk/visualizations-shared/colorConstants';

const RadialGraph = ({
    progress,
    strokeWidth,
    backgroundColor = RADIAL_BACKGROUND_COLOR,
    progressColor = RADIAL_STROKE_COLOR,
    width = 200,
    height = 200,
}) => {
    const semicircleRadius = height - strokeWidth / 2;

    const circumference = semicircleRadius * 2 * Math.PI;
    const bgCircleOffset = 0.5 * circumference;

    const progress50 = (progress / 100) * 50;
    const progressWidth = (progress50 / 100) * circumference;
    const strokeDashoffset = circumference * 0.5;

    return (
        <svg height={height} width={width} data-test="radial-visualization">
            <g>
                <circle
                    data-test="background-radial"
                    stroke={backgroundColor}
                    fill="transparent"
                    strokeWidth={strokeWidth}
                    strokeDasharray={`${bgCircleOffset} ${circumference - bgCircleOffset}`}
                    style={{ strokeDashoffset }}
                    r={semicircleRadius}
                    cx={width / 2}
                    cy={semicircleRadius + strokeWidth / 2}
                />
                <circle
                    data-test="progress-radial"
                    stroke={progressColor}
                    fill="transparent"
                    strokeWidth={strokeWidth}
                    strokeDasharray={`${progressWidth} ${circumference - progressWidth}`}
                    style={{ strokeDashoffset }}
                    r={semicircleRadius}
                    cx={width / 2}
                    cy={semicircleRadius + strokeWidth / 2}
                />
            </g>
        </svg>
    );
};

RadialGraph.propTypes = {
    progress: T.number,
    strokeWidth: T.number,
    backgroundColor: T.string,
    progressColor: T.string,
    width: T.number,
    height: T.number,
};

RadialGraph.defaultProps = {
    progress: 0,
    strokeWidth: 12,
};

export default RadialGraph;
