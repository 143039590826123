import React from 'react';
import type {
    AbsoluteLayoutStructure,
    GridLayoutStructure,
    SchemaObject,
} from '@splunk/dashboard-types';
import { useLayoutShowHide, type ReflowFn } from '../hooks';

type GenericLayoutProps = {
    layoutStructure?: AbsoluteLayoutStructure | GridLayoutStructure;
};

export interface EnhancedLayout<P extends GenericLayoutProps> {
    (props: P): JSX.Element;
    schema: SchemaObject;
}

const EmptyStructure = [] as Parameters<typeof useLayoutShowHide>[0];

export const withLayoutShowHide = <P extends GenericLayoutProps>(
    Layout: React.ComponentType<P>,
    { schema, reflowFn }: { schema: SchemaObject; reflowFn?: ReflowFn }
): EnhancedLayout<P> => {
    const EnhancedLayout = (props: P) => {
        const layoutStructure = useLayoutShowHide(
            props.layoutStructure ?? EmptyStructure,
            reflowFn
        );

        return (
            <Layout
                {...props}
                layoutStructure={layoutStructure}
                rawLayoutStructure={props.layoutStructure}
            />
        );
    };

    (EnhancedLayout as EnhancedLayout<P>).schema = schema;
    return EnhancedLayout as EnhancedLayout<P>;
};
