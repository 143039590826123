import * as React from 'react';
import type { StyleSpecification, LayerSpecification } from 'maplibre-gl';

// Global provided via webpack
// eslint-disable-next-line no-underscore-dangle
declare const __VISION_VERSION__: string;

/**
 * We are omitting the `sources`, `layers`, `verison` properties, and re-adding them with some constraint
 * 1. Changing `sources` and `layers` from optional to required
 * 2. Limiting `sources` type to `VectorSource` and `RasterSource` for MVP
 * 3. `version` property is specific to MapLibre and is always an 8. We will add this internally in the
 * Viz map component and not let the consumers bother
 */
export interface BaseTileConfig extends Omit<StyleSpecification, 'sources' | 'layers' | 'version'> {
    name: string; // used to populate the tile server dropdown in editor, eg: 'Mapbox Streets V8 tileset'
    key: string; // sets the Viz map option - 'baseLayerTileServer'
    theme?: 'light' | 'dark'; // if no theme is specified, the tileserver is available for all themes
    sources: {
        [sourceName: string]: Record<string, unknown>;
    };
    layers: LayerSpecification[];
    version?: number;
}

export interface MapContextInterface {
    defaultTileConfig: BaseTileConfig[];
    customTileConfig?: BaseTileConfig[];
    mapboxAccessToken?: string;
}

const MapContext = React.createContext<MapContextInterface>({ defaultTileConfig: [] });
MapContext.displayName = `data-splunk-visualization-version-MapContext-${__VISION_VERSION__}`;

export const MapContextProvider = MapContext.Provider;
export const MapContextConsumer = MapContext.Consumer;
export default MapContext;

export const testTileConfig: BaseTileConfig[] = [
    {
        name: 'Carto Base Map - Light',
        key: 'carto.base.map.light',
        theme: 'light',
        sources: {
            'base-tiles': {
                type: 'raster',
                tiles: ['https://a.basemaps.cartocdn.com/rastertiles/voyager_labels_under/{z}/{x}/{y}.png'],
                tileSize: 256,
            },
        },
        layers: [
            {
                id: 'base-layer',
                type: 'raster',
                source: 'base-tiles',
                minzoom: 0,
                maxzoom: 12,
                paint: {
                    'raster-fade-duration': 0,
                },
            },
        ],
    },
    {
        name: 'Cart Base Map - dark',
        key: 'carto.base.map.dark',
        theme: 'dark',
        sources: {
            'base-tiles': {
                type: 'raster',
                tiles: ['https://a.basemaps.cartocdn.com/rastertiles/dark_all/{z}/{x}/{y}.png'],
                tileSize: 256,
            },
        },
        layers: [
            {
                id: 'base-layer',
                type: 'raster',
                source: 'base-tiles',
                minzoom: 0,
                maxzoom: 12,
                paint: {
                    'raster-fade-duration': 0,
                },
            },
        ],
    },
];
