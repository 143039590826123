import type {
    GlobalState,
    FullscreenElementId,
    FullscreenSlice,
} from '@splunk/dashboard-types';
import { createSlice } from '@reduxjs/toolkit';
import resetStore from './resetStore';

const initialState: FullscreenSlice = {
    fullscreenElement: null,
    isDashboardFullscreen: false,
};

export const selectFullscreenElement = (
    state: GlobalState
): FullscreenElementId => state.fullscreen?.fullscreenElement ?? null;

export const selectIsDashboardFullscreen = (state: GlobalState): boolean =>
    state.fullscreen?.isDashboardFullscreen ?? false;

const fullscreenSlice = createSlice({
    name: 'fullscreen',
    initialState,
    extraReducers(builder) {
        builder.addCase(resetStore, (state, action) => {
            const { fullscreen } = action.payload;
            if (fullscreen) {
                Object.assign(state, fullscreen);
            }
        });
    },
    reducers: {
        setFullscreenItem(
            state,
            { payload }: { payload: FullscreenElementId }
        ) {
            state.fullscreenElement = payload;
        },
        setIsDashboardFullscreen(state, { payload }: { payload: boolean }) {
            state.isDashboardFullscreen = payload;
        },
    },
});

export const { setFullscreenItem, setIsDashboardFullscreen } =
    fullscreenSlice.actions;
export default fullscreenSlice.reducer;
