import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { sortBy } from 'lodash';
import { noop } from '@splunk/dashboard-utils';

import { _ } from '@splunk/ui-utils/i18n';
import { useIconRegistry, useFeatureFlags } from '@splunk/dashboard-context';

import type { RegistryDuckType } from './types/context-types';
import Gallery, { type Item, type GalleryProps } from './Gallery';
import GallerySearch from './GallerySearch';
import GalleryMessage from './GalleryMessage';
import GalleryUpload from './GalleryUpload';
import IconGalleryItem from './IconGalleryItem';

export interface IconItem {
    id: string;
    metaData: {
        name: string;
    };
}

interface IconGalleryProps {
    providerType?: string;
    onClick?: (itemUrl: Item['url']) => void;
    enableSearch?: boolean;
    setHasChildModal?: GalleryProps['setHasChildModal'];
}

const getWarning = (icons: Item[], msg: string): string | undefined => {
    if (Array.isArray(icons) && icons.length) {
        return undefined;
    }

    return msg;
};

const IconGallery = ({
    providerType: initialProviderType,
    onClick = noop,
    enableSearch = true,
    setHasChildModal,
}: IconGalleryProps) => {
    const [icons, setIcons] = useState<Item[]>([]);
    const [providerType, setProviderType] = useState(initialProviderType);
    const [loading, setLoading] = useState(true);
    const [searching, setSearching] = useState(false);
    const [error, setError] = useState<string>();
    const [warning, setWarning] = useState<string>();

    const iconRegistry = useIconRegistry();
    const { enableIconUploads, enableIconDelete, enableGallerySharingWarning } =
        useFeatureFlags();

    const getIconRegistry = useCallback(() => {
        if (!iconRegistry) {
            throw new Error(_('No icon registry found'));
        }
        return iconRegistry as RegistryDuckType<IconItem>;
    }, [iconRegistry]);

    useEffect(() => {
        if (!providerType) {
            try {
                const provider = getIconRegistry()
                    .listProviders()
                    .find((type) => type.isDefault);

                if (!provider) {
                    throw new Error('No icon providers found');
                }

                setProviderType(provider.type);
            } catch (err) {
                if (err instanceof Error) {
                    setError(err.message);
                }
            }
        }
    }, [providerType, getIconRegistry]);

    // TODO: the registry API doesn't provide a mechanism to cancel loading content,
    // so this will result in memory leaks if the component is unmounted during the load operation
    /**
     * Returns a concatenated array of icons from all providers
     * @param {Object} options
     * @returns {Promise<Array>}
     */
    const getIconsFromAllProviders = useCallback(
        async (options?: Record<string, unknown>) => {
            const registry = getIconRegistry();
            const providers = registry.listProviders();
            const iconLists = await Promise.all(
                providers.map(async (provider) => {
                    const { type } = provider;
                    const ids = await registry.listIds(type, {
                        ...options,
                        meta: ['name'],
                    });
                    return ids.map((id) => ({ ...id, providerType: type }));
                })
            );

            return iconLists.reduce(
                (iconList, currentList) => iconList.concat(currentList),
                []
            );
        },
        [getIconRegistry]
    );

    const fetchIcons = useCallback(async () => {
        if (!providerType) {
            setLoading(false);
            return;
        }

        try {
            const ids = await getIconsFromAllProviders();
            const newIcons: Item[] = ids.map((idObj) => ({
                url: `${idObj.providerType}://${idObj.id}`,
                name: idObj.metaData.name.split('.')[0],
            }));

            setIcons(sortBy(newIcons, ['name']));
            setError(undefined);
            setLoading(false);
            setWarning(
                getWarning(newIcons, _('No icons. Please upload icons.'))
            );
        } catch (err) {
            if (err instanceof Error) {
                setError(err.message);
            }
            setLoading(false);
        }
    }, [providerType, getIconsFromAllProviders]);

    useEffect(() => {
        fetchIcons();
    }, [fetchIcons]);

    const handleIconUpload = useCallback(
        (files: File[]) => {
            if (!enableIconUploads || !providerType) {
                return;
            }

            setLoading(true);
            files.forEach((file) => {
                const fileReader = new FileReader();
                fileReader.onload = async () => {
                    const fileName = file.name;
                    try {
                        const iconURL = await getIconRegistry().upload(
                            fileReader.result,
                            { name: fileName },
                            providerType
                        );
                        setIcons((currentIcons) =>
                            sortBy(
                                [
                                    ...currentIcons,
                                    { url: iconURL, name: fileName },
                                ],
                                ['name']
                            )
                        );
                        setWarning(undefined);
                        setError(undefined);
                        // NOTE: this is a pre-existing bug that if multiple files are uploaded, the first successful upload will disable the loading indication
                        setLoading(false);
                    } catch (err) {
                        if (err instanceof Error) {
                            setError(err.message);
                        }
                        setLoading(false);
                    }
                };

                fileReader.readAsDataURL(file);
            });
        },
        [providerType, enableIconUploads, getIconRegistry]
    );

    /**
     * Handle searching icons from all providers
     */
    const handleSearch = useCallback(
        async (searchStr = '') => {
            setSearching(true);
            try {
                const ids = await getIconsFromAllProviders({
                    meta: ['name'],
                    search: searchStr.trim(),
                });
                const newIcons = ids.map((idObj) => ({
                    url: `${idObj.providerType}://${idObj.id}`,
                    name: idObj.metaData.name.split('.')[0],
                }));

                setIcons(sortBy(newIcons, ['name']));
                setError(undefined);
                setWarning(getWarning(newIcons, _('No icons found.')));
                setSearching(false);
            } catch (err) {
                if (err instanceof Error) {
                    setError(err.message);
                }
                setSearching(false);
            }
        },
        [getIconsFromAllProviders]
    );

    const handleDelete = useCallback(
        async (deletedIcon: Item) => {
            if (!enableIconDelete) {
                return;
            }
            setLoading(true);

            try {
                await getIconRegistry().deleteByURL(deletedIcon.url);
                const newIcons = icons.filter(
                    (icon) => icon.url !== deletedIcon.url
                );
                setIcons(newIcons);
                setLoading(false);
                setWarning(
                    getWarning(newIcons, _('No Icons. Please upload icons.'))
                );
            } catch (err) {
                if (err instanceof Error) {
                    setError(err.message);
                }
                setLoading(false);
            }
        },
        [icons, getIconRegistry, enableIconDelete]
    );

    const handleItemClick = useCallback(
        (url: string) => onClick(url),
        [onClick]
    );

    const search = useMemo(
        () => (
            <GallerySearch
                placeholder={_('Search Icons')}
                onSearch={handleSearch}
            />
        ),
        [handleSearch]
    );

    const message = useMemo(() => {
        return <GalleryMessage error={error} warning={warning} />;
    }, [error, warning]);

    return (
        <Gallery
            testId="icon-gallery"
            isLoading={loading || searching}
            // Do not display search input if it is explicitly disabled, there is an error,
            // or icons have not been initialized (null)
            search={enableSearch && !error && icons ? search : null}
            message={message}
            items={icons}
            Item={IconGalleryItem}
            upload={
                error || !enableIconUploads ? null : (
                    <GalleryUpload
                        fileTypes=".svg"
                        onUpload={handleIconUpload}
                        enableGallerySharingWarning={
                            enableGallerySharingWarning
                        }
                    />
                )
            }
            onItemClick={handleItemClick}
            onItemRemove={enableIconDelete ? handleDelete : undefined}
            setHasChildModal={setHasChildModal}
            galleryType="icon"
        />
    );
};

export default IconGallery;
