import { _ } from '@splunk/ui-utils/i18n';
import jsPDF from 'jspdf';
import moment from '@splunk/moment';
import { zipWith } from 'lodash';
import type { ColumnValue, SearchData } from '@splunk/dashboard-types';
import console from './console';
import { wait } from './wait';

const TEMP_CANVAS_IMG_ATTRIBUTE = 'temp-canvas-image';

// export the following functions for test purpose only

/**
 * Trigger in-browser download action
 * @param {String} url
 * @param {String} name file name
 */
export const downloadPng = (url: string, name: string) => {
    const evt = new MouseEvent('click', {
        view: window,
        bubbles: false,
        cancelable: true,
    });

    const link = document.createElement('a');
    link.setAttribute('download', `${name}.png`);
    link.setAttribute('href', url);
    link.dispatchEvent(evt);
};

/**
 * Trigger in-browser download action for csv file
 * @param {String} uri
 * @param {String} filename
 */
export const downloadCsv = (uri: string, filename: string) => {
    const evt = new MouseEvent('click', {
        view: window,
        bubbles: false,
        cancelable: true,
    });

    const link = document.createElement('a');
    link.setAttribute('download', `${filename}.csv`);
    link.setAttribute('href', uri);
    link.dispatchEvent(evt);
};

/**
 * Adds the given image to a new PDF and downloads it
 * @param {Number} clientWidth
 * @param {Number} clientHeight
 * @param {String} imageUri
 * @param {String} fileName
 */
export const exportPdf = (
    clientWidth: number,
    clientHeight: number,
    imageUri: string,
    fileName: string
) => {
    const orientation = clientWidth > clientHeight ? 'landscape' : 'portrait';
    // eslint-disable-next-line new-cap
    const pdf = new jsPDF({
        orientation,
        unit: 'px',
        format: [clientWidth, clientHeight],
        // https://github.com/parallax/jsPDF/blob/master/HOTFIX_README.md#px_scaling
        // When using px units, jsPDF recommends adding this px_scaling hotfix
        hotfixes: ['px_scaling'],
    });
    pdf.addImage(
        imageUri,
        'PNG',
        0,
        0,
        pdf.internal.pageSize.getWidth(),
        pdf.internal.pageSize.getHeight(),
        '',
        'FAST'
    );
    pdf.save(`${fileName}.pdf`);
};

/**
 * Function to check if the Uri is data Uri
 * @param {String} uri
 * @returns {boolean}
 */
export const isDataUri = (uri: string) => /^(data:)/.test(uri);

/**
 * Convert response blob to data Uri
 * @param {blob} blob
 * @returns {Object} data Uri
 */
export const blobToDataUri = (blob: Blob) =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => {
            resolve(reader.result);
        };
        reader.onerror = reject;
        reader.readAsDataURL(blob);
    });

/**
 * Fetch same origin resources
 * @param {String} url
 * @returns {blob} data blob
 */
export const fetchExternalResources = async (url: string) => {
    // TODO: Handle errors due to CORS
    const response = await fetch(url);
    if (!response.ok) {
        throw new Error(`Unable to fetch resources at: ${url}`);
    }

    const responseBodyBlob = await response.blob();
    return blobToDataUri(responseBodyBlob) as Promise<string>;
};

/**
 * Get all css styles as string for a given dom element
 * @param {HTMLElement} scaledDomNode
 * @returns {String} cssStrings
 */
/**
 * Get all css styles as string for a given dom element
 * @param {HTMLElement} scaledDomNode
 * @returns {String} cssStrings
 */
export const getCssStyles = async (
    scaledDomNode: HTMLElement
): Promise<string> => {
    const FONTS_URL_REGEX = /url\(['"]?([^'"]+?)['"]?\)/g;
    const sheets = Array.from(scaledDomNode.ownerDocument.styleSheets);
    const cssStylesList: string[] = [];

    await Promise.all(
        sheets.map(async (sheet) => {
            let cssRules: CSSRuleList | never[];
            try {
                // accessing CSSStyleSheet.cssRules will load the stylesheet
                //   if the stylsheet is loaded from a different domain, results in a SecurityError
                cssRules = sheet.cssRules;
            } catch (e) {
                console.error(e);
                cssRules = [];
            }
            await Promise.all(
                Array.from(cssRules).map(async (cssRule) => {
                    if (cssRule.constructor.name !== 'CSSFontFaceRule') {
                        cssStylesList.push(cssRule.cssText);
                        return;
                    }
                    const { style, cssText } = cssRule as CSSStyleRule;
                    const fontFaces = style.getPropertyValue('src');
                    const fontFaceUrls = [];
                    let match = FONTS_URL_REGEX.exec(fontFaces);
                    while (match !== null) {
                        fontFaceUrls.push(match[1]);
                        match = FONTS_URL_REGEX.exec(fontFaces);
                    }
                    const filter = (url: string) => !isDataUri(url);
                    const map = async (url: string): Promise<void> => {
                        try {
                            const fontData: string =
                                await fetchExternalResources(url);
                            cssStylesList.push(cssText.replace(url, fontData));
                        } catch (e) {
                            console.error(e);
                        }
                    };
                    await Promise.all(fontFaceUrls.filter(filter).map(map));
                })
            );
        })
    );
    return cssStylesList.map((rule: string) => `${rule}\n`).join('');
};

/**
 * Copy all css styles from given dom element to a <style> dom element
 * @param {HTMLElement} scaledDomNode
 * @returns {HTMLElement} style
 */
export const copyStyles = async (scaledDomNode: HTMLElement) => {
    const style = document.createElementNS(
        'http://www.w3.org/2000/svg',
        'style'
    );
    const cssStyles = await getCssStyles(scaledDomNode);
    style.appendChild(document.createTextNode(cssStyles));
    return style;
};

/**
 * Table is possibly scrolled, this calculation is to get the difference of scrolling table
 * And then use transform to shift the place of the table
 * @param {HTMLElement} vizNode
 * @param {HTMLElement} clonedNode
 * @param {String} type
 */
export const applyTableScroll = (
    vizNode: HTMLElement,
    clonedNode: HTMLElement,
    type: string
) => {
    if (
        (type !== 'viz.table' && type !== 'splunk.table') ||
        !vizNode.getElementsByTagName('tbody').length
    ) {
        return;
    }

    // Using parentNode might not be safe because DOM structure can be possibly changed
    // Using html2canvas might be better to handle this https://jira.splunk.com/browse/SPL-188417
    const table = vizNode.querySelector('[data-test=main-table]')
        ?.parentNode as HTMLElement;
    const { scrollLeft = 0, scrollTop = 0 } = table;

    // correct the place based on scroll properties of the table
    const translateX = 0 - scrollLeft;
    const translateY = 0 - scrollTop;
    // eslint-disable-next-line no-param-reassign
    clonedNode.getElementsByTagName(
        'tbody'
    )[0].style.transform = `translate(${translateX}px, ${translateY}px)`;

    // clean-up: remove scroll bars for downloads since they can show incorrect scroll position
    const clonedTableBody = clonedNode.querySelector('[data-test=main-table]')
        ?.parentNode as HTMLElement;
    const clonedTable = clonedNode.querySelector(
        '[data-test=table]'
    ) as HTMLElement;
    clonedTableBody.style.overflow = 'hidden';
    clonedTable.style.width = '100%';
    clonedTable.style.height = '100%';
};

/**
 * Calls applyTableScroll on each table viz in the dashboard
 * @param {HTMLElement} canvasDomNode
 * @param {HTMLElement} clonedCanvasNode
 */
export const applyTableScrolls = (
    canvasDomNode: HTMLElement,
    clonedCanvasNode: HTMLElement
) => {
    const tables = canvasDomNode.querySelectorAll<HTMLElement>(
        'div[data-viz-type="viz.table"], div[data-viz-type="splunk.table"]'
    );

    const clonedTables = clonedCanvasNode.querySelectorAll<HTMLElement>(
        'div[data-viz-type="viz.table"], div[data-viz-type="splunk.table"]'
    );

    tables.forEach((table, index) => {
        // It doesn't really matter if the correct viz type is provided
        // because both viz.table and splunk.table will pass the same check
        applyTableScroll(table, clonedTables[index], 'viz.table');
    });
};

/**
 * Parses a choropleth svg's iframe and extracts the svg node from its src
 * @param {HTMLIFrameElement} iframeNode
 * @returns
 */
const extractSvgFromIframe = async (
    iframeNode: HTMLIFrameElement
): Promise<SVGSVGElement | undefined> => {
    const { src } = iframeNode;
    if (!src) {
        return undefined;
    }
    const b64Regex = /data:[^;]+;base64,(.*)$/;
    let regexResult: RegExpExecArray | null = null;

    // Decode the dataURI used in the SvgChoro <iframe>
    if (isDataUri(src)) {
        regexResult = b64Regex.exec(src);
    } else {
        // The iframe src is encoded as a blob rather than a data uri
        const blobAsDataUrl = await fetchExternalResources(src);
        regexResult = b64Regex.exec(blobAsDataUrl);
    }

    if (regexResult?.[1]) {
        const b64EncodedHtmlContents = regexResult[1];
        const htmlContentsString = atob(b64EncodedHtmlContents);
        const parser = new DOMParser();
        const htmlNode = parser.parseFromString(
            htmlContentsString,
            'application/xml'
        );
        return htmlNode.getElementsByTagName('svg')[0];
    }

    return undefined;
};

/**
 * Parses a choropleth svg's iframe and replaces it with an image node with its inlined svg contents.
 * Noop if the element is not a choropleth svg.
 * @param {Element} clonedNode
 * @param {string} type
 */
export const replaceSvgChoroIframe = async (
    clonedNode: Element,
    type: string
) => {
    if (
        !type.includes('choropleth') ||
        !clonedNode.getElementsByTagName('iframe').length
    ) {
        return;
    }
    const serializer = new XMLSerializer();
    const iframeNode = clonedNode.getElementsByTagName('iframe')[0];
    const { parentNode } = iframeNode;

    // Decode the dataURI used in the SvgChoro <iframe> and extract just the <svg>
    const svgNode = await extractSvgFromIframe(iframeNode);

    if (svgNode) {
        // encode the SVG and put it in an <img> node
        const svg = serializer.serializeToString(svgNode);
        const imageNode = new Image();
        imageNode.src = `data:image/svg+xml;base64,${btoa(svg)}`;

        // replace the <iframe> with the <img>
        parentNode?.replaceChild(imageNode, iframeNode);
    }
};

export const replaceSvgChoroIframes = async (clonedCanvasNode: HTMLElement) => {
    const vizSvgChoros = clonedCanvasNode.querySelectorAll(
        'div[data-viz-type="viz.choropleth.svg"]'
    );

    const replaceVizChoroSvgPromises = Array.from(vizSvgChoros).map(
        (svgChoro) => replaceSvgChoroIframe(svgChoro, 'viz.choropleth.svg')
    );

    const splunkSvgChoros = clonedCanvasNode.querySelectorAll(
        'div[data-viz-type="splunk.choropleth.svg"]'
    );

    const replaceSplunkChoroSvgPromises = Array.from(splunkSvgChoros).map(
        (svgChoro) => replaceSvgChoroIframe(svgChoro, 'splunk.choropleth.svg')
    );

    await Promise.all([
        ...replaceVizChoroSvgPromises,
        ...replaceSplunkChoroSvgPromises,
    ]);
};

/**
 * search for the canvas element and apply it's backgroundColor to domNode
 * @param {HTMLElement} domNode
 */
export const applyCanvasBackgroundColor = (domNode: HTMLElement) => {
    const canvasNode = document.querySelector('[data-test="canvas"]');
    if (canvasNode) {
        // eslint-disable-next-line no-param-reassign
        domNode.style.backgroundColor =
            window.getComputedStyle(canvasNode).backgroundColor;
    }
};

/**
 * Convert the given dom element string to a Svg foreignObject string
 * @param {String} vizDomString
 * @param {integer} width
 * @param {integer} height
 * @returns {String} svg
 */
export const domNodeToSvg = (
    vizDomString: string,
    width: number,
    height: number
) =>
    `<svg xmlns='http://www.w3.org/2000/svg' width='${width}' height='${height}'><foreignObject width='100%' height='100%' externalResourcesRequired ='true'> ${vizDomString} </foreignObject></svg>`;

/**
 * Convert the given element to Svg data Uri
 * @param {HTMLElement} scaledDomNode
 * @param {integer} scaledWidth
 * @param {integer} scaledHeight
 * @returns {String} SvgDataUri
 */
export const domNodeToSvgDataUri = async (
    scaledDomNode: HTMLElement,
    scaledWidth: number,
    scaledHeight: number
) => {
    const styleNode = await copyStyles(scaledDomNode);
    scaledDomNode.insertBefore(styleNode, scaledDomNode.firstChild);
    const svg = new XMLSerializer().serializeToString(scaledDomNode);
    return `data:image/svg+xml;charset=utf-8,${encodeURIComponent(
        domNodeToSvg(svg, scaledWidth, scaledHeight)
    )}`;
};

/**
 * Copy dom element css styles from source to target
 * @param {HTMLElement} clonedDomNode
 * @param {HTMLElement} originalDomNode
 */
export const cloneNodeStyles = (
    clonedDomNode: HTMLElement,
    originalDomNode: HTMLElement
) => {
    if (!(clonedDomNode instanceof HTMLElement)) {
        return;
    }
    const sourceStyles = window.getComputedStyle(originalDomNode);
    const targetStyles = clonedDomNode.style;
    if (sourceStyles.cssText) {
        targetStyles.cssText = sourceStyles.cssText;
    } else {
        Array.from(sourceStyles).forEach((name) => {
            targetStyles.setProperty(
                name,
                sourceStyles.getPropertyValue(name),
                sourceStyles.getPropertyPriority(name)
            );
        });
    }
};

/**
 * Creates a image based off the canvas contents to place over the canvas. We do this BEFORE we clone the nodes
 * because a canvas elements's painted images are not cloned https://developer.mozilla.org/en-US/docs/Web/API/Node/cloneNode
 * @param {HTMLElement} domNode
 * @param {HTMLElement} clonedNode
 */
export const addTempCanvasImage = (domNode: HTMLElement) => {
    Array.from(domNode.getElementsByTagName('canvas')).forEach((canvas) => {
        const image = document.createElement('img');

        image.src = canvas.toDataURL('image/png');
        cloneNodeStyles(image, canvas);
        image.setAttribute('data-test', TEMP_CANVAS_IMG_ATTRIBUTE);

        canvas.parentNode?.insertBefore(image, canvas.nextElementSibling);
    });
};

/**
 * Removes the image tag we placed above the canvas.
 * @param {HTMLElement} domNode
 */
export const removeTempCanvasImage = (domNode: HTMLElement) => {
    domNode
        .querySelectorAll(`[data-test=${TEMP_CANVAS_IMG_ATTRIBUTE}]`)
        .forEach((image) => image.parentElement?.removeChild(image));
};

/**
 * Recursively clone dom element children from original
 * @param {HTMLElement} clonedParentDomNode
 * @param {HTMLElement} originalParentDomNode
 */
export const cloneNodeChildren = (
    clonedParentDomNode: HTMLElement,
    originalParentDomNode: HTMLElement
) => {
    const { childNodes } = originalParentDomNode;
    childNodes.forEach((childNode) => {
        const clonedChildNode = childNode.cloneNode() as HTMLElement;
        cloneNodeStyles(clonedChildNode, childNode as HTMLElement);
        clonedParentDomNode.appendChild(clonedChildNode);
        cloneNodeChildren(clonedChildNode, childNode as HTMLElement);
    });
};

/**
 * Create a cloned dom element and copy all the styles and children
 * @param {HTMLElement} vizDomNode
 * @returns {HTMLElement} clonedDomNode
 */
export const cloneNodeWithStyles = (vizDomNode: HTMLElement) => {
    addTempCanvasImage(vizDomNode);
    const clonedDomNode = vizDomNode.cloneNode() as HTMLElement;
    cloneNodeStyles(clonedDomNode, vizDomNode);
    cloneNodeChildren(clonedDomNode, vizDomNode);
    removeTempCanvasImage(vizDomNode);
    return clonedDomNode;
};

export const drawImageOnCanvas = (
    img: HTMLImageElement,
    canvas: HTMLCanvasElement
) => {
    const context = canvas.getContext('2d') as CanvasRenderingContext2D;
    context.drawImage(
        img,
        0,
        0,
        img.width,
        img.height,
        0,
        0,
        canvas.width,
        canvas.height
    );
    context.imageSmoothingQuality = 'high';
    return canvas.toDataURL('image/png');
};

/**
 * Draw the svgUrl on the html canvas and export as DataUrl
 * @param {String} imageUrl
 * @param {integer} width original dom element width
 * @param {integer} height original dom element height
 */
export const drawImage = (
    imageUrl: string,
    width: number,
    height: number
): Promise<string> =>
    new Promise((resolve, reject) => {
        const canvas = document.createElement('canvas');
        if (!canvas.getContext) {
            reject(_('Canvas is not supported'));
        }
        const img = new Image();
        img.crossOrigin = 'anonymous';

        canvas.width = width;
        canvas.height = height;
        img.addEventListener('load', async () => {
            try {
                // On first export in Safari, the fonts in the image will
                // not be rendered yet, so we should wait until the image
                // is fully rendered before adding it to the canvas.
                await wait(0);

                resolve(drawImageOnCanvas(img, canvas));
            } catch (e) {
                // canvas is tainted
                reject(e);
            }
        });
        img.addEventListener('error', reject);
        img.src = imageUrl;
    });

/**
 * Util function to create download file name
 * @param {String} name
 * @returns {String
 */
export const createExportFileName = (name: string) => {
    const formattedDateTime = moment().format('YYYY-MM-DD [at] hh.mm.ssZZ');
    return `${name}_${formattedDateTime}_Splunk`;
};

/**
 * Replaces the backgroundImage URL with dataUri
 * @param {HTMLElement} clonedCanvasNode
 */
export const processCanvasBackgroundImage = async (
    clonedCanvasNode: HTMLElement
) => {
    const canvasNode = clonedCanvasNode.querySelector(
        'div[data-test=canvas]'
    ) as HTMLElement;
    const canvasBackgroundImage = canvasNode?.style?.backgroundImage;
    const urlRegex = /url\(['"]?([^'"]+?)['"]?\)/g;
    const url = urlRegex.exec(canvasBackgroundImage);
    if (url) {
        try {
            const dataUri = await fetchExternalResources(url[1]);
            canvasNode.style.backgroundImage = `url(${dataUri})`;
        } catch (e) {
            console.error(e);
        }
    }
};

/**
 * Convert all external same origin image to image data Uri.
 * During drawing on html canvas, it does not allow to fetch external images,
 * This function helps to fetch those images and embedded as inline data Url image
 * @param {*} imageNode
 */
export const processImageNode = async (imageNode: HTMLImageElement) => {
    const { src } = imageNode;
    let dataUri = '';
    if (!isDataUri(src)) {
        dataUri = await fetchExternalResources(src);
        // eslint-disable-next-line no-param-reassign
        imageNode.src = dataUri;
    }
};

/**
 * Convert all external same origin images to image data Uris.
 * @param {HTMLElement} domNode
 */
export const processImages = async (domNode: HTMLElement, vizType?: string) => {
    const imageNodes = domNode.getElementsByTagName('img');
    let imageErrors = 0;
    // This only process same origin images.
    if (imageNodes.length > 0) {
        await Promise.all(
            Array.from(imageNodes).map(async (imageNode) => {
                try {
                    await processImageNode(imageNode);
                } catch (error) {
                    imageErrors += 1;
                    if (vizType === 'viz.img') {
                        throw error;
                    }
                }
            })
        );
    }

    if (imageErrors > 0) {
        throw new Error(_('Some external images can not be downloaded.'));
    }
};

/**
 * Apply special effects on viz dom element
 * @param {HTMLElement} vizNode
 * @param {HTMLElement} clonedNode
 * @param {String} type
 */
export const applyVizEffect = (
    vizNode: HTMLElement,
    clonedNode: HTMLElement,
    type: string
) => {
    applyTableScroll(vizNode, clonedNode, type);
    applyCanvasBackgroundColor(clonedNode);
    replaceSvgChoroIframe(clonedNode, type);
};

/**
 * Apply special effects on canvas dom element
 * @param {HTMLElement} canvasNode
 * @param {HTMLElement} clonedCanvasNode
 */
export const applyCanvasEffects = async (
    canvasNode: HTMLElement,
    clonedCanvasNode: HTMLElement
) => {
    await processCanvasBackgroundImage(clonedCanvasNode);
    applyTableScrolls(canvasNode, clonedCanvasNode);
    await replaceSvgChoroIframes(clonedCanvasNode);
};

export const scaleWidthAndHeight = (
    scaledDomNode: HTMLElement,
    scale: number,
    width: number,
    height: number
) => {
    let scaledWidth = width;
    let scaledHeight = height;
    if (scale && scale !== 1) {
        // eslint-disable-next-line no-param-reassign
        scaledDomNode.style.cssText = `transform: scale(${scale}); transform-origin: 0 0;`;
        scaledWidth = width * scale;
        scaledHeight = height * scale;
    }

    return { scaledWidth, scaledHeight };
};

/**
 * Formats the given value to be used in a CSV file, as per RFC 4180:
 *  - Wraps value in double quotes if it contains a newline, double quote, or comma
 *  - Replaces any double quotes with two double quotes
 * @param {ColumnValue} value
 * @returns {string} value formatted for use in a CSV file
 */
export const formatValueForCsv = (value: ColumnValue) => {
    if (
        typeof value === 'string' &&
        (value.search(/["\r\n]/g) >= 0 || value.includes(','))
    ) {
        return `"${value.replace(/"/g, '""')}"`;
    }
    return value;
};

// export above functions for test purpose only

/**
 * Main function to download visualization search data as csv
 * @param {SearchData} searchData
 * @param {String} filename
 */
export const exportSearchDataAsCsv = async (
    searchData: SearchData | undefined,
    filename: string
) => {
    if (!searchData?.data?.fields || !searchData?.data?.columns) {
        throw new Error(_('Unable to export CSV due to invalid search data'));
    }

    const fieldNames = searchData.data.fields.map((field) =>
        formatValueForCsv(field.name)
    );
    const columnData = zipWith(...searchData.data.columns, (...values) =>
        values.map(formatValueForCsv)
    );
    const csvData = [fieldNames, ...columnData].join('\n');
    const encodedUri = URL.createObjectURL(
        new Blob([csvData], { type: 'text/csv;charset=utf-8' })
    );

    downloadCsv(encodedUri, createExportFileName(filename));
};

/**
 * Main function to export a viz dom element as png
 * @param {HTMLElement} vizDomNode
 * @param {String} vizId
 * @param {String} vizType
 * @param {Number} scale
 * @param {String} filename
 */
export const exportVizAsPng = async ({
    vizDomNode,
    vizId,
    vizType,
    scale = 1,
    filename,
}: {
    vizDomNode: HTMLElement;
    vizId: string;
    vizType: string;
    scale: number;
    filename: string;
}) => {
    if (!vizDomNode) {
        throw new Error(_(`Visualization ${vizType} not found.`));
    }

    const clonedDomNode = cloneNodeWithStyles(vizDomNode);
    const { clientWidth, clientHeight } = vizDomNode;
    const { scaledWidth, scaledHeight } = scaleWidthAndHeight(
        clonedDomNode,
        scale,
        clientWidth,
        clientHeight
    );

    applyVizEffect(vizDomNode, clonedDomNode, vizType);

    try {
        await processImages(clonedDomNode);
    } catch (error) {
        throw new Error(_('Images from external URLs can not be downloaded.'));
    }

    try {
        const imageUri = await domNodeToSvgDataUri(
            clonedDomNode,
            scaledWidth,
            scaledHeight
        );
        const downloadUri = await drawImage(
            imageUri,
            scaledWidth || clientWidth,
            scaledHeight || clientHeight
        );
        downloadPng(downloadUri, createExportFileName(filename || vizType));
    } catch (e) {
        throw new Error(
            _(`Unable to export the visualization with id of ${vizId} as PNG.`)
        );
    }
};

/**
 * Appends the child to the source and returns the child clientHeight
 * @param {HTMLElement} source
 * @param {HTMLElement} child
 * @returns {Number} child.clientHeight
 */
export const appendClonedChild = (source: HTMLElement, child?: HTMLElement) => {
    if (!child) {
        return 0;
    }

    source.appendChild(cloneNodeWithStyles(child));
    return child.clientHeight;
};

/**
 * Main function to export a dashboard as png or pdf
 * @param {string} fileType
 * @param {HTMLElement} dashboardHeaderDomNode
 * @param {HTMLElement} dashboardInputsDomNode
 * @param {HTMLElement} dashboardCanvasDomNode
 * @param {Number} scale
 * @param {Function} showToast
 */
export const exportDashboardToFile = async ({
    fileType,
    dashboardTitle,
    dashboardHeaderDomNode,
    dashboardInputsDomNode,
    dashboardCanvasDomNode,
    scale = 1,
    showToast,
    drawImage: drawImageFn = drawImage,
}: {
    fileType?: string;
    dashboardTitle?: string;
    dashboardHeaderDomNode?: HTMLElement;
    dashboardInputsDomNode?: HTMLElement;
    dashboardCanvasDomNode: HTMLElement;
    scale?: number;
    showToast?: (message: string) => void;
    // testing purposes only
    drawImage?: typeof drawImage;
}) => {
    const clonedCanvasNode = cloneNodeWithStyles(dashboardCanvasDomNode);
    let { clientHeight } = dashboardCanvasDomNode;
    const { clientWidth } = dashboardCanvasDomNode;

    const nodeToExport = document.createElement('div');
    clientHeight += appendClonedChild(nodeToExport, dashboardHeaderDomNode);
    clientHeight += appendClonedChild(nodeToExport, dashboardInputsDomNode);

    nodeToExport.appendChild(clonedCanvasNode);
    const { scaledWidth, scaledHeight } = scaleWidthAndHeight(
        nodeToExport,
        scale,
        clientWidth,
        clientHeight
    );

    if (dashboardHeaderDomNode || dashboardInputsDomNode) {
        nodeToExport.style.backgroundColor = window.getComputedStyle(
            (dashboardHeaderDomNode || dashboardInputsDomNode) as HTMLElement
        ).backgroundColor;
    }
    await applyCanvasEffects(dashboardCanvasDomNode, clonedCanvasNode);

    try {
        await processImages(nodeToExport);
    } catch (error) {
        if (showToast) {
            showToast('Images from external URLs cannot be downloaded.');
        }
    }

    try {
        const imageUri = await domNodeToSvgDataUri(
            nodeToExport,
            scaledWidth,
            scaledHeight
        );
        const downloadUri = await drawImageFn(
            imageUri,
            scaledWidth || clientWidth,
            scaledHeight || clientHeight
        );

        const fileName = createExportFileName(
            dashboardTitle || _('My Dashboard')
        );
        if (fileType?.toLowerCase() === 'png') {
            downloadPng(downloadUri, fileName);
        } else {
            exportPdf(clientWidth, clientHeight, downloadUri, fileName);
        }
    } catch (e) {
        throw new Error(_(`Unable to export the dashboard as ${fileType}`));
    }
};
