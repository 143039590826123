import * as React from 'react';
import * as T from 'prop-types';
import {
    BUBBLE_ROW_SCALE_TYPES,
    BUBBLE_SCALE_TYPES,
    COLOR_MODE_TYPES,
    BUBBLE_LABELS_TYPES,
    getDataByRows,
} from './utils/punchcardUtils';
import PunchcardRow from './PunchcardRow';

interface PunchcardChartAreaProps {
    showBubbleLabels: BUBBLE_LABELS_TYPES;
    bubbleRowScale: BUBBLE_ROW_SCALE_TYPES;
    bubbleScale: BUBBLE_SCALE_TYPES;
    category: string[];
    colorCategoryMapping: (...args: any) => void;
    colorMode: COLOR_MODE_TYPES;
    bubbleColor: string | string[];
    handleMouseEnter: (...args: any) => void;
    handleMouseLeave: (...args: any) => void;
    isBubbleSizeDynamic: boolean;
    minBubbleColorIntensity: number;
    minBand: number;
    minBubbleSize: number;
    maxBubbleSize: number;
    maxBubbleRadius: number;
    minBubbleRadius: number;
    showMaxValuePulsation: boolean;
    selectedCategory: string | number;
    selectedX: string | number;
    selectedY: string | number;
    size: number[];
    x: (string | number)[];
    xScale: (...args: any) => void;
    y: (string | number)[];
    yScale: (...args: any) => void;
    handlePointClick?: (...args: any) => void;
}

const PunchcardChartArea = (props: PunchcardChartAreaProps): React.ReactElement => {
    const {
        showBubbleLabels,
        bubbleRowScale,
        bubbleScale,
        category,
        colorCategoryMapping,
        colorMode,
        bubbleColor,
        handleMouseEnter,
        handleMouseLeave,
        isBubbleSizeDynamic,
        minBubbleColorIntensity,
        minBand,
        minBubbleSize,
        maxBubbleSize,
        maxBubbleRadius,
        minBubbleRadius,
        showMaxValuePulsation,
        selectedCategory,
        selectedX,
        selectedY,
        size,
        x,
        xScale,
        y,
        yScale,
        handlePointClick,
    } = props;

    /** @type {Object<String\|Number, import('./utils/punchcardUtils').RowData>} */
    const dataByRows = getDataByRows({
        bubbleRowScale,
        bubbleScale,
        category,
        colorCategoryMapping,
        colorMode,
        bubbleColor,
        minBubbleColorIntensity,
        size,
        x,
        y,
        isBubbleSizeDynamic,
        minBand,
        minBubbleSize,
        maxBubbleSize,
        maxBubbleRadius,
        minBubbleRadius,
    });

    return (
        <g>
            {Object.entries(dataByRows).map(([row, rowData]) => {
                const {
                    rowXValues,
                    rowSizeValues,
                    rowCategoryValues,
                    rowColorValues,
                    rowFillOpacityValues,
                    rowSizeScale,
                    rowRadiusValues,
                } = rowData;
                const yPosition = yScale(row);

                return (
                    <PunchcardRow
                        key={row}
                        showBubbleLabels={showBubbleLabels}
                        category={rowCategoryValues}
                        colors={rowColorValues}
                        handleMouseEnter={handleMouseEnter}
                        handleMouseLeave={handleMouseLeave}
                        maxSizeValue={Math.max(...size)}
                        opacities={rowFillOpacityValues}
                        radiusValues={rowRadiusValues}
                        selectedCategory={selectedCategory}
                        selectedX={selectedX}
                        selectedY={selectedY}
                        showMaxValuePulsation={showMaxValuePulsation}
                        sizeScale={rowSizeScale}
                        size={rowSizeValues}
                        x={rowXValues}
                        xScale={xScale}
                        yPosition={yPosition}
                        yValue={row}
                        handlePointClick={handlePointClick}
                    />
                );
            })}
        </g>
    );
};

const propTypes: Record<keyof PunchcardChartAreaProps, T.Validator<any>> = {
    showBubbleLabels: T.oneOf(Object.values(BUBBLE_LABELS_TYPES)),
    bubbleRowScale: T.oneOf(Object.values(BUBBLE_ROW_SCALE_TYPES)),
    bubbleScale: T.oneOf(Object.values(BUBBLE_SCALE_TYPES)),
    category: T.arrayOf(T.string),
    colorCategoryMapping: T.func,
    colorMode: T.oneOf(Object.values(COLOR_MODE_TYPES)),
    bubbleColor: T.oneOfType([T.string, T.arrayOf(T.string)]),
    handleMouseEnter: T.func,
    handleMouseLeave: T.func,
    isBubbleSizeDynamic: T.bool,
    minBubbleColorIntensity: T.number,
    minBand: T.number,
    minBubbleSize: T.number,
    maxBubbleSize: T.number,
    maxBubbleRadius: T.number,
    minBubbleRadius: T.number,
    showMaxValuePulsation: T.bool,
    selectedCategory: T.oneOfType([T.string, T.number]),
    selectedX: T.oneOfType([T.string, T.number]),
    selectedY: T.oneOfType([T.string, T.number]),
    size: T.arrayOf(T.number).isRequired,
    x: T.arrayOf(T.oneOfType([T.string, T.number])).isRequired,
    xScale: T.func,
    y: T.arrayOf(T.oneOfType([T.string, T.number])).isRequired,
    yScale: T.func,
    handlePointClick: T.func,
};

const defaultProps: Record<keyof PunchcardChartAreaProps, any> = {
    showBubbleLabels: BUBBLE_LABELS_TYPES.ALL,
    bubbleRowScale: BUBBLE_ROW_SCALE_TYPES.ROW,
    bubbleScale: BUBBLE_SCALE_TYPES.AREA,
    category: null,
    colorCategoryMapping: null,
    colorMode: COLOR_MODE_TYPES.CATEGORICAL,
    bubbleColor: '#A870EF',
    handleMouseEnter: () => {},
    handleMouseLeave: () => {},
    isBubbleSizeDynamic: true,
    minBubbleColorIntensity: 0.25,
    minBand: 15,
    minBubbleSize: 0.01,
    maxBubbleSize: 1,
    maxBubbleRadius: 15,
    minBubbleRadius: 1,
    showMaxValuePulsation: true,
    selectedCategory: null,
    selectedX: null,
    selectedY: null,
    // eslint-disable-next-line react/default-props-match-prop-types
    size: null,
    // eslint-disable-next-line react/default-props-match-prop-types
    x: null,
    xScale: null,
    // eslint-disable-next-line react/default-props-match-prop-types
    y: null,
    yScale: null,
    handlePointClick: () => {},
};

PunchcardChartArea.propTypes = propTypes;
PunchcardChartArea.defaultProps = defaultProps;

export default PunchcardChartArea;
