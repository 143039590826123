/* eslint-disable class-methods-use-this,no-useless-constructor */
import uuid from 'uuid-v4';
import { _ } from '@splunk/ui-utils/i18n';
import Provider from '@splunk/visualization-context/Provider';

export const b64EncodeUnicode = str =>
    // first we use encodeURIComponent to get percent-encoded UTF-8,
    // then we convert the percent encodings into raw bytes which
    // can be fed into btoa.
    btoa(
        encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, (match, p1) =>
            String.fromCharCode(parseInt(`0x${p1}`, 16))
        )
    );

export const KEY = '__icons__';

/**
 * a local icon provider
 */
class LocalIconProvider extends Provider {
    constructor() {
        super();
    }

    /**
     * return provider type
     * @return {String} 'local'
     */
    getType() {
        return 'local';
    }

    /**
     * upload one new icon
     */
    async upload(dataURI, metaData) {
        // get svg from dataURI
        const response = await fetch(dataURI);
        const text = await response.text();
        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(text, 'text/xml');
        const svg = xmlDoc.getElementsByTagName('svg')[0];

        if (!svg.getAttribute('viewBox')) {
            throw new Error(_('Please upload a valid svg with viewBox attribute.'));
        }

        // reset svg attributes
        svg.setAttribute('preserveAspectRatio', 'none');
        // convert back to dataURI
        const serializer = new XMLSerializer();
        const svgStr = b64EncodeUnicode(serializer.serializeToString(svg));
        const iconDataURI = `data:image/svg+xml;base64,${svgStr}`;

        // save to localStorage
        try {
            const icon = { dataURI: iconDataURI, metaData };
            const id = uuid();
            let icons = JSON.parse(localStorage.getItem(KEY) || '{}');
            icons = { ...icons, [id]: icon };
            localStorage.setItem(KEY, JSON.stringify(icons));
            return id;
        } catch (error) {
            throw new Error(
                _('The icon cannot be uploaded. Please check whether your localStorage is full.')
            );
        }
    }

    /**
     * get one icon by icon id
     */
    getById(id) {
        const icons = JSON.parse(localStorage.getItem(KEY) || '{}');
        if (icons[id]) {
            return icons[id];
        }
        throw new Error(_('The icon is not found.'));
    }

    /**
     * get all icon ids
     * @params options
     * @params options.meta   a list of meta data attribute that would be returned with each id
     * @params options.search  a search string to filter the returned icons
     */
    listIds({ offset = 0, count = 100, meta = [], search = null }) {
        try {
            const icons = JSON.parse(localStorage.getItem(KEY) || '{}');
            const results = [];

            Object.keys(icons)
                .slice(offset, count)
                .forEach(id => {
                    const icon = { id };
                    const iconMeta = icons[id].metaData || {};
                    // If search is a valid string, return the icon with matched meta data
                    const isSearchedIcon =
                        search != null &&
                        Object.keys(iconMeta).some(attr => {
                            const iconMetaValue = iconMeta[attr].toLowerCase();
                            return iconMetaValue.indexOf(search.toLowerCase()) > -1;
                        });

                    if (search == null || isSearchedIcon) {
                        if (meta && meta.length) {
                            icon.metaData = {};
                            meta.forEach(attr => {
                                if (iconMeta[attr] != null) {
                                    icon.metaData[attr] = iconMeta[attr];
                                }
                            });
                        }

                        results.push(icon);
                    }
                });

            return results;
        } catch (error) {
            throw new Error(_('The icon ids cannot be listed.'));
        }
    }

    /**
     * delete an icon by icon id
     */
    deleteById(id) {
        try {
            const icon = this.getById(id);
            const icons = JSON.parse(localStorage.getItem(KEY));
            delete icons[id];
            localStorage.setItem(KEY, JSON.stringify(icons));
            return icon;
        } catch (error) {
            throw new Error(_('The icon cannot be deleted.'));
        }
    }
}

export default LocalIconProvider;
