/**
 * checks whether data is multi series data
 * @param {array} data array
 * @return {Boolean}
 */
export const isMultiSeriesData = data => Array.isArray(data) && Array.isArray(data[0]);
/**
 * checks whether data is multi series data and returns value as array
 * @param {array} data array
 * @param {*} value to return as array
 * @return {array}
 */
export const toArrayForSeries = (data, value) => (isMultiSeriesData(data) ? value : [value]);
