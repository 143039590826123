import type { VisualizationDefinition } from '@splunk/dashboard-types';
import config from '@splunk/visualizations/Punchcard.config';
import type { EncodingConfig } from '../encoding';
import { encodingToDynamicOptionsDSL } from '../encoding';
import { renameVizOptions, removeInvalidOptions } from '../utils/migrationUtils';

const validOptions = {
    ...config.optionsSchema,
};

const defaultContext = { ...config.defaultContext };

const optionsToRename = {
    showBubbleLabels: 'bubbleLabelDisplay',
    bubbleScale: 'bubbleSizeMethod',
    isBubbleSizeDynamic: 'showDynamicBubbleSize',
    maxBubbleRadius: 'bubbleRadiusMax',
    minBubbleRadius: 'bubbleRadiusMin',
    maxBubbleSize: 'bubbleSizeMax',
    minBubbleSize: 'bubbleSizeMin',
    showLegend: 'legendDisplay',
    useDefaultSort: 'showDefaultSort',
};

const consolidateLegendDisplay = (vizOptions: Record<string, unknown>): void => {
    if (!vizOptions) {
        return;
    }
    const options = vizOptions;
    if (options.legendDisplay) {
        options.legendDisplay = 'right';
    } else if (options.legendDisplay === false) {
        options.legendDisplay = 'off'; // legenddisplay set explicitly to false
    }
};

const consolidateBubbleLabelDisplay = (vizOptions: Record<string, unknown>): void => {
    if (!vizOptions) {
        return;
    }
    const options = vizOptions;
    if (options.bubbleLabelDisplay === 'none') {
        options.bubbleLabelDisplay = 'off';
    }
};

// Updates the first color of the bubbleColorConfig with the minBubbleColorIntensity
const convertGradientConfig = (
    minBubbleColorIntensity: number,
    defaultBubbleColorConfig: Record<string, unknown>
): Record<string, unknown> => {
    if (
        !defaultBubbleColorConfig ||
        !defaultBubbleColorConfig.colors ||
        !defaultBubbleColorConfig.colors[0] ||
        typeof minBubbleColorIntensity !== 'number'
    ) {
        return null;
    }
    const firstColor = defaultBubbleColorConfig.colors[0] as string;
    const firstColorUpdated = `${firstColor.substring(
        0,
        firstColor.lastIndexOf(',')
    )},${minBubbleColorIntensity})`;
    const colors = [...(defaultBubbleColorConfig.colors as Array<string>)];
    colors[0] = firstColorUpdated;
    const bubbleColorConfigUpdated = { ...defaultBubbleColorConfig, colors };
    return bubbleColorConfigUpdated;
};

const convertColorModeOption = (visualizationDefinition: VisualizationDefinition): void => {
    const visualizationDef = visualizationDefinition;
    if (!visualizationDef || !visualizationDef.options) {
        return;
    }

    // Rename the colorMode option from 'sequential' to 'dynamic' if specified
    if (visualizationDef.options.colorMode === 'sequential') {
        visualizationDef.options.colorMode = 'dynamic';
    }

    // If no colorMode was specified the default in viz.punchcard used to be 'categorical'.
    // But in splunk.punchcard default is 'dynamic', we need to preserve the original default.
    // Set colorMode to 'categorical' if no colorMode was specified in viz
    if (!visualizationDef.options.colorMode) {
        visualizationDef.options.colorMode = 'categorical';
    }

    const { minBubbleColorIntensity } = visualizationDef.options;
    // If there is no category option set or if the colorMode is not categorical, then the colorMode will to 'dynamic'
    // In both cases we need to update the gradient config bubbleColorConfig with the minBubbleColorIntensity for first color
    if (
        (!visualizationDef.options.category || visualizationDef.options.colorMode !== 'categorical') &&
        minBubbleColorIntensity &&
        typeof minBubbleColorIntensity === 'number'
    ) {
        const bubbleColorConfig = convertGradientConfig(
            minBubbleColorIntensity,
            defaultContext.bubbleColorConfig
        );
        if (bubbleColorConfig) {
            visualizationDef.context = { ...visualizationDef.context, bubbleColorConfig };
        }
    }
};

export const migrateVizToSplunkPunchcard = (
    vizDefinition: VisualizationDefinition
): VisualizationDefinition => {
    const { options = {}, encoding = {}, ...otherDefinitionParts } = vizDefinition;
    const migratedVisualization = { options, ...otherDefinitionParts, type: 'splunk.punchcard', context: {} };
    const { options: dataOptions, context: dataContext } = encodingToDynamicOptionsDSL(
        encoding as Record<string, EncodingConfig>
    );
    // perform any option renames
    const renamedOptions = renameVizOptions(migratedVisualization.options, optionsToRename);

    consolidateLegendDisplay(renamedOptions);
    consolidateBubbleLabelDisplay(renamedOptions);

    migratedVisualization.options = {
        ...renamedOptions,
        ...dataOptions,
    };
    migratedVisualization.context = dataContext;

    convertColorModeOption(migratedVisualization);
    // remove invalid options using the optionsSchema for splunk.punchcard as reference
    removeInvalidOptions(migratedVisualization.options, validOptions);
    return migratedVisualization;
};
