import { useEffect, useRef } from 'react';
import { cloneDeep } from 'lodash';
import { selectDefinition, useSelector } from '@splunk/dashboard-state';
import type { UseDefinitionChangeCallbackProps } from './onChange.types';

export const useDefinitionChangeCallback = ({
    onDefinitionChange: onChange,
}: UseDefinitionChangeCallbackProps) => {
    const definition = useSelector(selectDefinition);
    const firstRender = useRef(true);

    useEffect(() => {
        if (!onChange || firstRender.current) {
            firstRender.current = false;
            return;
        }

        // TODO: potentially don't clone for performance reasons (but would introduce potential state mutation bug)
        onChange(cloneDeep(definition));
    }, [definition, onChange]);
};
