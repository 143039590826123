import type {
    FocusEventHandler,
    FormEventHandler,
    KeyboardEventHandler,
} from 'react';
import { useEffect, useState, useCallback } from 'react';
import isString from 'lodash/isString';

export interface UseTextInputProps {
    initialValue?: string;
    handleCommit: (str: string) => void;
    uniqKey?: unknown;
    defaultValue?: string;
}

export interface UseTextInputReturn {
    onHandleBlur: FocusEventHandler;
    onHandleChange: FormEventHandler;
    onHandleKeyDown: KeyboardEventHandler;
    value: string;
}

const empty = Object.freeze({});

/**
 * Custom hook for Text input property getter and Synthetic event handlers.
 *
 * @param {String} initialValue Initial value of the text input.
 * @param {Function} handleCommit Function to execute that will commit the change to UDF definition.
 * @param {String} uniqKey A unique key to shallow compare that determines if we revert back to `initialValue` for the returned state `value`.
 * @returns {Object} useTextInput
 * @returns {Function} useTextInput.value.onHandleBlur React SyntheticEvent handler.
 * @returns {Function} useTextInput.value.onHandleChange React SyntheticEvent handler.
 * @returns {Function} useTextInput.value.onHandleKeyDown React SyntheticEvent handler.
 * @returns {String} useTextInput.value The value to be rendered in the controlled input.
 */
export const useTextInput = ({
    initialValue = '',
    handleCommit,
    uniqKey = empty,
    defaultValue = '',
}: UseTextInputProps): UseTextInputReturn => {
    const [value, setValue] = useState(initialValue);

    useEffect(() => {
        setValue(initialValue);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [uniqKey]); // Don't list initialValue as a dependency. Two objects could have the same initialValue, but we want to use a different handleCommit.

    const safeHandleChange = useCallback(
        (val) => {
            if (val !== initialValue) {
                // no need to check val type, because it is always a String https://developer.mozilla.org/en-US/docs/Web/API/HTMLInputElement
                const v = val.trim();
                handleCommit(v);
                // If the value has been cleared using SUI Text's clear button or selectAll + delete, reset to defaultValue
                if (v === '') {
                    setValue(defaultValue);
                } else {
                    setValue(v);
                }
            }
        },
        [handleCommit, initialValue, defaultValue]
    );

    const onHandleKeyDown = useCallback(
        (evt) => {
            if (isString(evt.key) && evt.key.toLowerCase() === 'enter') {
                safeHandleChange(evt.target.value);
            }
            if (isString(evt.key) && evt.key.toLowerCase() === 'escape') {
                // This key doesn't work with Chrome 75
                setValue(initialValue);
            }
        },
        [initialValue, safeHandleChange]
    );

    const onHandleBlur = useCallback(
        (evt) => {
            safeHandleChange(evt.target.value);
        },
        [safeHandleChange]
    );

    const onHandleChange = useCallback(
        (evt) => {
            const { value: evtVal } = evt.target;

            // Allow the clear ("X") button to propagate the change
            // without requiring the onBlur callback to execute
            if (typeof evtVal === 'undefined') {
                safeHandleChange('');
                return;
            }

            setValue(evtVal || '');
        },
        [safeHandleChange]
    );

    return {
        onHandleBlur,
        onHandleChange,
        onHandleKeyDown,
        value,
    };
};
