import * as React from 'react';
import * as T from 'prop-types';

import styled, { keyframes } from 'styled-components';
import pick from '@splunk/themes/pick';
import variables from '@splunk/themes/variables';
import { formatNumber } from '@splunk/visualizations-shared/numberUtils';
import { GRAYSCALE_FILTER_ID } from './utils/punchcardUtils';

const BubbleCircle = styled.circle<{
    hover: boolean | null;
    outline: boolean;
}>`
    fill: ${props =>
        props.outline
            ? pick({
                  // bubbleTextColor
                  enterprise: {
                      light: variables.white, // equal to enterpriseDark.textColor
                      dark: variables.contentColorInverted, // enterprise.textColor
                  },
                  prisma: variables.contentColorActive,
              })(props)
            : props.color};
    fill-opacity: 1;
    opacity: ${props => (props.hover ? 0 : props.fillOpacity)};
    stroke: ${props => (props.outline ? props.color : 'none')};
`;

const pulsateAnimation = r => keyframes`
    41% {
        opacity: 0;
        r: ${r + 5};
    }
    100% {
        opacity: 0;
        r: ${r + 5};
    }
`;

const PulsateBubble = styled(BubbleCircle)`
    animation: ${props => pulsateAnimation(props.r)} 2.4s linear 0.4s infinite;
`;

const BubbleGroup = styled.g<{
    muted: boolean;
    onMouseEnter: (...args: any) => void;
    onMouseLeave: (...args: any) => void;
}>`
    cursor: pointer;
    opacity: ${props => (props.muted ? 0.15 : 1)};
    filter: ${props => (props.muted ? `url(#${GRAYSCALE_FILTER_ID})` : '')};
    transition: opacity 0.15s;
`;

const BubbleLabelText = styled.text<{
    hover: boolean;
    outline: boolean;
    displayLabel: boolean;
}>`
    dominant-baseline: middle;
    opacity: ${props => (props.displayLabel ? 1 : 0)};
    fill: ${props =>
        props.hover || props.outline
            ? pick({
                  // bubbleAltTextColor
                  enterprise: variables.textColor,
                  prisma: variables.contentColorActive,
              })(props)
            : pick({
                  enterprise: {
                      dark: variables.textColor,
                      light: variables.white,
                  },
                  prisma: variables.contentColorActive,
              })(props)}};
    font-size: ${props => (props.hover ? '12px' : '10px')};
    font-weight: ${props => (props.hover ? 500 : 'normal')};
    text-anchor: middle;
`;

const MIN_BUBBLE_SIZE_TO_DISPLAY_LABEL = 13;
const MAX_DISPLAYABLE_VALUE = 99499999999999;
const VALUE_OVERFLOW_TEXT = '99T+';

interface PunchcardBubbleProps {
    showBubbleLabel: boolean;
    color: string;
    cx: number;
    cy: number;
    fillOpacity: number;
    handleMouseEnter: (...args: any) => void;
    handleMouseLeave: (...args: any) => void;
    hover: boolean;
    muted: boolean;
    pulsate: boolean;
    value: number;
    r: number;
    handlePointClick?: (...args: any) => void;
}

const PunchcardBubble = (props: PunchcardBubbleProps): React.ReactElement => {
    const {
        showBubbleLabel,
        color,
        cx,
        cy,
        fillOpacity,
        handleMouseEnter,
        handleMouseLeave,
        hover,
        muted,
        pulsate,
        value,
        r,
        handlePointClick,
    } = props;

    const outline = value < 0;
    const showPulsateBubble = pulsate && !muted && !hover;
    const displayLabel = hover || (showBubbleLabel && r >= MIN_BUBBLE_SIZE_TO_DISPLAY_LABEL);

    let bubbleLabelValueText =
        value <= MAX_DISPLAYABLE_VALUE
            ? formatNumber(value, 0, { useTrendUnits: true })
            : VALUE_OVERFLOW_TEXT;

    if (hover) {
        bubbleLabelValueText = formatNumber(value, 2);
    }

    return (
        <BubbleGroup
            muted={muted}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            onClick={() => handlePointClick(value)}
        >
            {showPulsateBubble && <PulsateBubble cx={cx} cy={cy} r={r} color={color} outline hover={null} />}
            <BubbleCircle
                cx={cx}
                cy={cy}
                r={r}
                color={color}
                hover={hover}
                fillOpacity={fillOpacity}
                outline={outline}
            />
            <BubbleLabelText x={cx} y={cy} hover={hover} outline={outline} displayLabel={displayLabel}>
                {bubbleLabelValueText}
            </BubbleLabelText>
        </BubbleGroup>
    );
};

const propTypes: Record<keyof PunchcardBubbleProps, T.Validator<any>> = {
    showBubbleLabel: T.bool,
    color: T.string,
    cx: T.number,
    cy: T.number,
    fillOpacity: T.number,
    handleMouseEnter: T.func,
    handleMouseLeave: T.func,
    hover: T.bool,
    muted: T.bool,
    pulsate: T.bool,
    value: T.number,
    r: T.number,
    handlePointClick: T.func,
};

const defaultProps: Record<keyof PunchcardBubbleProps, any> = {
    showBubbleLabel: true,
    color: '#46D3BB',
    cx: 0,
    cy: 0,
    fillOpacity: 1,
    handleMouseEnter: () => {},
    handleMouseLeave: () => {},
    hover: false,
    muted: false,
    pulsate: false,
    r: 1,
    value: undefined,
    handlePointClick: () => {},
};

PunchcardBubble.propTypes = propTypes;
PunchcardBubble.defaultProps = defaultProps;

export default PunchcardBubble;
