import { _ } from '@splunk/ui-utils/i18n';
import MarkerGaugeIcon from '@splunk/react-icons/enterprise/GaugeMarker';
import { MarkerGauge as MarkerGaugePlaceholderIcon } from '@splunk/visualization-icons/placeholders';
import { COLOR_OR_TOKEN_PATTERN_WITH_RGBA, getPattern } from '@splunk/visualizations-shared/schemaUtils';
import variables from '@splunk/themes/variables';
import pick from '@splunk/themes/pick';
import GaugeConfig from '../common/editorConfig/Gauge';
import { DataContract } from '../common/interfaces/DataContract';
import { DefaultContext } from '../common/interfaces/DefaultContext';
import { EditorConfig } from '../common/interfaces/Editor';
import { OptionsSchema } from '../common/interfaces/OptionsSchema';
import { ThemedDefaults } from '../common/interfaces/ThemedDefaults';
import { VizBehavior } from '../common/interfaces/VizBehavior';
import { VizCategory } from '../common/interfaces/VizCategory';
import { VizConfig } from '../common/interfaces/VizConfig';
import { VizSize } from '../common/interfaces/VizSize';
import { enhanceConfig } from '../common/utils/configUtils';
import getBackgroundColorEditor from '../common/editorConfig/BackgroundColor';

const dataContract: DataContract = {
    requiredDataSources: [
        {
            name: 'primary',
            description: 'DataSource that powers the visualization',
        },
    ],
    optionalDataSources: [],
    initialRequestParams: {
        primary: { offset: 0, count: 10000 },
    },
};

const size: VizSize = {
    initialWidth: 300,
    initialHeight: 300,
};

const defaultContext: DefaultContext = {};

const optionsSchema: OptionsSchema = {
    backgroundColor: {
        description:
            'Specify the color used for the background. The default for enterprise light is "#ffffff". The default for enterprise dark is "#000000". The default for prisma dark is "#0b0c0e".',
        type: 'string',
        pattern: COLOR_OR_TOKEN_PATTERN_WITH_RGBA,
        default: '> themes.defaultBackgroundColor',
    },
    gaugeRanges: {
        description:
            'Specify the overall range of the gauge as a series of continuous, color-coded subranges. For example, [{"from": 0, "to": 20, "value": "#4BEBA8"}, {"from": 20, "to": 50, "value": "#F4DF7A"}].',
        default: [
            { from: 0, to: 50, value: '#118832' },
            { from: 50, to: 90, value: '#CBA700' },
            { from: 90, to: 100, value: '#D41F1F' },
        ],
        type: 'array',
        items: {
            type: 'object',
            properties: {
                from: {
                    type: 'number',
                    description: 'Starting value for range.',
                },
                to: {
                    type: 'number',
                    description: 'Ending value for range.',
                },
                value: {
                    description: 'Color for sub range.',
                    type: 'string',
                    pattern: COLOR_OR_TOKEN_PATTERN_WITH_RGBA,
                },
            },
            required: ['from', 'to', 'value'],
            additionalProperties: false,
        },
    },
    labelDisplay: {
        description: 'Specify whether to display the labels as numbers, percentages, or off.',
        default: 'number',
        type: 'string',
        pattern: getPattern(['number', 'percentage', 'off']),
    },
    majorTickInterval: {
        description: 'Specify, in pixels, the spacing between major tick marks.',
        default: 'auto',
        type: ['string', 'number'],
    },
    orientation: {
        description: 'Specify the axis orientation of the gauge.',
        default: 'vertical',
        type: 'string',
        pattern: getPattern(['horizontal', 'vertical']),
    },
    value: {
        description: 'Specify the dataSource applied to the value of the marker gauge.',
        type: 'string',
        default: '> primary | seriesByType("number") | lastPoint()',
    },
    valueDisplay: {
        description: 'Specify whether to display the value as a number, percentage, or off.',
        default: 'number',
        type: 'string',
        pattern: getPattern(['number', 'percentage', 'off']),
    },
};

const themes: ThemedDefaults = {
    defaultFillColor: props =>
        pick({
            enterprise: variables.backgroundColorHover(props),
            prisma: variables.neutral100(props),
        })(props),
    defaultBackgroundColor: props =>
        pick({
            enterprise: {
                dark: variables.black(props),
                light: variables.backgroundColor(props),
            },
            prisma: variables.backgroundColorSidebar(props),
        })(props),
    majorTickFillColor: props =>
        pick({
            enterprise: {
                light: variables.gray30(props), // was '#33343B'
                dark: variables.textGray(props), // was #D7DAE0
            },
            prisma: {
                // TODO: missed colors :(
                light: variables.neutral100(props), // TBD
                dark: variables.contentColorMuted(props), // was #D7DAE0; contentColor
            },
        })(props),
    majorTickStrokeColor: props =>
        pick({
            enterprise: {
                light: variables.textGray(props),
                dark: variables.contentColorMuted(props),
            },
            prisma: variables.contentColorMuted(props),
        })(props),
    valueMarkerFillColor: props =>
        pick({
            enterprise: variables.textColor(props),
            prisma: {
                dark: variables.white(props),
                light: variables.black(props),
            },
        })(props),
    valueMarkerLabelFillColor: props =>
        pick({
            enterprise: {
                light: variables.gray96(props),
                dark: variables.black(props),
            },
            prisma: variables.black(props),
        })(props),
};

const editorConfig: EditorConfig[] = [
    {
        label: _('Data configurations'),
        layout: [
            [
                {
                    label: _('Value'),
                    editor: 'editor.columnSelector',
                    option: 'value',
                    context: 'defaultContext',
                    editorProps: {
                        dataSourceKey: 'primary',
                        prepareValue: (
                            definition
                        ): { context: Record<string, any>; options: Record<string, any> } => {
                            const {
                                options: { value },
                            } = definition;
                            return {
                                context: {},
                                options: {
                                    value: `${value} | lastPoint()`,
                                },
                            };
                        },
                    },
                },
            ],
        ],
    },
    GaugeConfig,
    {
        label: _('Color and style'),
        layout: [
            [
                {
                    label: _('Gauge ranges'),
                    option: 'gaugeRanges',
                    editor: 'editor.threshold',
                    editorProps: {
                        openRanges: false, // Use closed ranges
                        isTogglable: false, // Can not disable thresholds
                    },
                },
            ],
            getBackgroundColorEditor({ themes, labelPosition: 'top' }),
        ],
    },
];

/**
 * visualization configuration
 */
const config: VizConfig = {
    /**
     * unique viz key
     */
    key: 'splunk.markergauge',
    /**
     * viz name
     */
    name: 'MarkerGauge',
    category: VizCategory.GAUGE,
    /**
     * viz icon
     */
    icon: MarkerGaugeIcon,
    placeholderIcon: MarkerGaugePlaceholderIcon,
    dataContract,
    size,
    defaultContext,
    optionsSchema,
    editorConfig,
    supports: [VizBehavior.DYNAMIC_OPTIONS, VizBehavior.PLACEHOLDER],
    themes,
};

export default enhanceConfig(config);
