import { _ } from '@splunk/ui-utils/i18n';
import pick from '@splunk/themes/pick';
import variables from '@splunk/themes/variables';
import { EditorConfig } from '../interfaces/Editor';
import { ThemedDefaults } from '../interfaces/ThemedDefaults';
import getBackgroundColorEditor from '../editorConfig/BackgroundColor';

const themes: ThemedDefaults = {
    defaultBackgroundColor: props =>
        pick({
            enterprise: {
                dark: variables.black(props),
                light: variables.backgroundColor(props),
            },
            prisma: variables.backgroundColorSidebar(props),
        })(props),
};

const AxesChartColorAndStyle: EditorConfig = {
    label: _('Color and style'),
    layout: [
        getBackgroundColorEditor({ themes }),
        [
            {
                label: _('Series colors'),
                option: 'seriesColors',
                editor: 'editor.seriesColors',
            },
        ],
        [
            {
                label: _('Series colors by field name'),
                option: 'seriesColorsByField',
                editor: 'editor.seriesColorsByField',
            },
        ],
    ],
};

export default AxesChartColorAndStyle;
