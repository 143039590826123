import { useCallback, useEffect, useState } from 'react';
import { useDndMonitor } from '@dnd-kit/core';
import { arrayMove } from '@dnd-kit/sortable';
import { selectGlobalInputs, useSelector } from '@splunk/dashboard-state';
import { useDashboardCoreApi } from '@splunk/dashboard-context';

const EMPTY_GLOBAL_INPUTS = Object.freeze<string[]>([]);

export const useDashboardDragMonitor = (): string[] => {
    const dashboardCoreApi = useDashboardCoreApi();
    const globalInputs = useSelector(selectGlobalInputs);

    const [activeDragId, setActiveDragId] = useState<string>();

    useEffect(() => {
        setActiveDragId(undefined);
    }, [globalInputs]);

    const onDragStart = useCallback(({ active }) => {
        if (!active?.id) {
            setActiveDragId(undefined);
            return;
        }

        setActiveDragId(active.id);
    }, []);

    const onDragCancel = useCallback(() => {
        setActiveDragId(undefined);
    }, []);

    const onDragEnd = useCallback(
        ({ active, over }) => {
            if (!activeDragId || active?.id === over?.id) {
                return;
            }

            // If there's fewer than 2 elements then there's nothing to reorder
            if (!globalInputs || globalInputs.length < 2) {
                return;
            }

            // Reorder global bar
            dashboardCoreApi.updateInputStructure(
                arrayMove(
                    globalInputs,
                    globalInputs.indexOf(active.id),
                    globalInputs.indexOf(over.id)
                )
            );

            setActiveDragId(undefined);
        },
        [activeDragId, dashboardCoreApi, globalInputs]
    );

    useDndMonitor({ onDragStart, onDragCancel, onDragEnd });
    return (globalInputs ?? EMPTY_GLOBAL_INPUTS) as unknown as string[];
};
