export interface GeoJsonDataType {
    type: string;
    coordinates: Array<Array<Array<[number, number]>>>;
}

/**
 *  Finds the closest matching object from the list, which has the max same key value pairs.
 *  Returns the index of match if found, or -1
 *  For eg :- 
   const list = [{
        type: "marker",
        lat: "value1"
    }, {
        type: "bubble",
        size: "value2"
    }];

    const obj = {
        type: 'bubble'
    };

    findClosestMatch(list, obj) -> returns index 1.
 */
export const findClosestMatchFromDefaultList = (
    searchList: Array<Record<string, unknown>>,
    inputObj: Record<string, unknown>
): number => {
    if (!Array.isArray(searchList) || searchList.length === 0) {
        return -1;
    }
    const keys = Object.keys(inputObj);
    let maxMatchCnt = 0;
    let maxMatchIdx = 0;

    searchList.forEach((item, idx) => {
        let matchCnt = 0;
        keys.forEach(key => {
            if (item[key] === inputObj[key]) {
                matchCnt += 1;
            }
        });
        if (Math.max(maxMatchCnt, matchCnt) > maxMatchCnt) {
            maxMatchCnt = matchCnt;
            maxMatchIdx = idx;
        }
    });
    return maxMatchIdx;
};
