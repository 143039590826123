import { cloneDeep, get } from 'lodash';
import { Dimensions } from './Dimensions';
import EncodingExecutor, { Frames } from './EncodingExecutor';
import { DataPrimitive } from './DataPrimitive';
import { OptionsStanza } from './AST';

type ScopeName = 'literal' | 'context' | 'datasources' | 'themes' | 'options';

export interface ScopedValue {
    location: ScopeName;
    val: DataPrimitive;
}

export class OptionScopes {
    executedOptions: Record<string, unknown>; // gets populated by DSL evaluation

    context: Record<string, unknown>; // also gets populated by DSL evaluation

    frames: Frames; // the data sources

    themeFunc: (themeVar) => any; // a function that must be provided, which resolves theme variables

    private local: Record<string, any>[] = []; // unqualified identifiers can be resolved against local context. This is a stack of scopes.

    private visitedPaths: Set<string> = new Set(); // Set of object paths we have visited (used for circular reference detection)

    private static SCOPES = ['local', 'context', 'datasources', 'options', 'themes'];

    constructor(optionsStanza: OptionsStanza, frames: Frames, themeFunc: (themeVar) => any) {
        this.context = optionsStanza.context;
        this.executedOptions = optionsStanza.options;
        this.frames = frames;
        this.themeFunc = themeFunc;
        this.local.push(this.executedOptions); // Stack. local starts off pointing to top level options
    }

    resolve(identifier: string): ScopedValue {
        const scopeParts = identifier.split('.');
        let scopedValue: ScopedValue = null;
        if (OptionScopes.isQualified(scopeParts)) {
            scopedValue = this.resolveQualifiedIdentifier(scopeParts);
        } else {
            scopedValue = this.resolveUnqualifiedIdentifier(scopeParts);
        }
        return cloneDeep(scopedValue); // return defensive copy. Prevents targets of identifiers from getting mutated
    }

    private static isQualified(scopeParts: string[]): boolean {
        const firstPart = scopeParts[0];
        return OptionScopes.SCOPES.includes(firstPart);
    }

    private resolveUnqualifiedIdentifier(scopeParts: string[]): any {
        // no qualifier has been given so we create qualified identifiers from all
        // that point to all possible scopes
        const qualifiers = OptionScopes.SCOPES;
        let scopedValue: ScopedValue;
        for (let i = 0; i < qualifiers.length; i += 1) {
            const qualifiedIdentifier = [qualifiers[i], ...scopeParts];
            scopedValue = this.resolveQualifiedIdentifier(qualifiedIdentifier);
            if (scopedValue.val) {
                break; // found it
            }
        }
        return scopedValue;
    }

    private resolveQualifiedIdentifier(scopeParts: string[]): ScopedValue {
        const qualifiedTargets = {
            context: this.context,
            options: this.executedOptions,
            datasources: this.frames,
            local: this.local[this.local.length - 1],
        };
        let val = null;
        const location = scopeParts[0] as ScopeName;
        if (location === 'themes' && this.themeFunc) {
            // themes must be looked up in themeRegistry
            const themeKey = scopeParts[1];
            val = this.themeFunc(themeKey);
        } else {
            val = get(qualifiedTargets, scopeParts);
            if (val) {
                val = new EncodingExecutor().eval(val, this, scopeParts);
            }
        }
        return { location, val };
    }

    public pushLocalScope(o: Record<string, unknown>) {
        this.local.push(o);
    }

    public popLocalScope() {
        this.local.pop();
    }

    addToVisitedList(path: string[]) {
        const pathStr = path.join('.');
        if (this.visitedPaths.has(pathStr)) {
            throw new Error(
                `Circular reference ${pathStr}, path history: ${new Array(...this.visitedPaths).toString()}`
            );
        }
        this.visitedPaths.add(pathStr);
    }

    removeFromVisitedList(path: string[]) {
        this.visitedPaths.delete(path.join('.'));
    }
}

export interface Scopes {
    context: Record<string, unknown>;
    vizDims: Dimensions;
    frames: Frames;
}
