import React from 'react';
import styled from 'styled-components';
import { _ } from '@splunk/ui-utils/i18n';
import File from '@splunk/react-ui/File';

const UploadItem = styled.div`
    padding: 5px 0px;
    width: 100%;
`;

const SharingWarning = styled.span`
    height: 20px;
    font-size: smaller;
    font-style: italic;
`;

export const warningText = _(
    'Note: Uploaded image files can be accessed and deleted by others in your organization'
);

export interface GalleryUploadProps {
    fileTypes: string;
    onUpload: (files: File[]) => void;
    enableGallerySharingWarning?: boolean;
}

const GalleryUpload = ({
    fileTypes,
    onUpload,
    enableGallerySharingWarning,
}: GalleryUploadProps) => {
    return (
        <UploadItem>
            <File onRequestAdd={onUpload} accept={fileTypes} allowMultiple>
                {enableGallerySharingWarning ? (
                    <SharingWarning data-test="gallery-sharing-warning">
                        {warningText}
                    </SharingWarning>
                ) : null}
            </File>
        </UploadItem>
    );
};

export default GalleryUpload;
