import definitionReducer from './definition';
import tokensReducer from './tokens';
import modeReducer from './mode';
import fullscreenReducer from './fullscreen';
import editorReducer from './editor';
import globalReducer from './global';
import inputStateReducer from './inputState';
import smartSourcesReducer from './smartSources';
import migrationReducer from './migration';

export const rootReducer = {
    message: globalReducer,
    mode: modeReducer,
    fullscreen: fullscreenReducer,
    definition: definitionReducer,
    tokens: tokensReducer,
    editor: editorReducer,
    inputState: inputStateReducer,
    smartSources: smartSourcesReducer,
    migration: migrationReducer,
};
