/* eslint-disable max-classes-per-file */
/* eslint-disable class-methods-use-this */
import { _ } from '@splunk/ui-utils/i18n';
import ImageRegistry from '@splunk/visualization-context/ImageRegistry';

export interface DownloadProgress {
    bytesDownloaded: number;
    contentLength?: number;
    message: string;
}
export interface IDownloader {
    downloadWithProgress(
        url: string,
        complete: (svg: string) => void,
        progress?: (p: DownloadProgress) => void,
        error?: (msg: string, error?: Error) => void
    ): void;
}

export const b64DecodeUnicode = (base64Data: string): string => {
    // from bytestream, to percent-encoding, to original string.
    return decodeURIComponent(
        atob(base64Data)
            .split('')
            .map((c: string): string => {
                return `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`;
            })
            .join('')
    );
};

export class ImageRegistryFetcher implements IDownloader {
    context: ImageRegistry;

    constructor(context?: ImageRegistry) {
        this.context = context;
    }

    async downloadWithProgress(
        url: string,
        complete: (svg: string) => void,
        progress?: (p: DownloadProgress) => void,
        error?: (msg: string, error?: Error) => void
    ): Promise<void> {
        const imageRegistry = this.context;
        if (!imageRegistry) {
            error(_('Missing Image Context'));
        }
        try {
            const imageData = await imageRegistry.getByURL(url);
            const [mime, svgBase64] = imageData.dataURI.split('base64,');
            if (mime && mime.toLowerCase().indexOf('image/svg+xml') > -1) {
                complete(b64DecodeUnicode(svgBase64));
            } else {
                error(_(`Unsupported MIME type ${mime} for url ${url}`));
            }
        } catch (e) {
            error(_(`Error retrieving ${url} from Image Registry, error was '${e.message}'`), e);
        }
    }
}

export class URLDownloader implements IDownloader {
    downloadWithProgress(
        url: string,
        complete: (svg: string) => void,
        progress?: (p: DownloadProgress) => void,
        error?: (msg: string, error?: Error) => void
    ): void {
        // Step 1: fetch content from URL, get reader
        fetch(url)
            .then(
                async (response: Response): Promise<void> => {
                    if (!response.ok) {
                        const msg = `Fetch not Ok for ${url}, status was '${response.statusText}'`;
                        console.warn(msg);
                        error(msg);
                        return;
                    }
                    const reader = response.body.getReader();

                    // Step 2: get total length
                    const contentLength = +response.headers.get('Content-Length');

                    // Step 3: read the data
                    let bytesDownloaded = 0; // length at the moment
                    const chunks = []; // array of received binary chunks (comprises the body)
                    let done = false;
                    while (!done) {
                        // eslint-disable-next-line no-await-in-loop
                        const res = await reader.read();
                        done = res.done;
                        const { value } = res;
                        if (value) {
                            chunks.push(value);
                            bytesDownloaded += value.length;
                            progress({
                                // call the progress callback...can enable the UI to show progress
                                bytesDownloaded,
                                contentLength,
                                message: `Received ${bytesDownloaded} bytes of ${
                                    contentLength || 'unknown size'
                                }`,
                            });
                        }
                    }

                    // Step 4: concatenate chunks into single Uint8Array
                    const chunksAll = new Uint8Array(bytesDownloaded);
                    let position = 0;
                    // eslint-disable-next-line no-restricted-syntax
                    for (const chunk of chunks) {
                        chunksAll.set(chunk, position);
                        position += chunk.length;
                    }

                    progress({
                        bytesDownloaded,
                        contentLength,
                        message: 'decoding chunks',
                    });

                    // Step 5: decode into a string
                    const svg = new TextDecoder('utf-8').decode(chunksAll);
                    complete(svg); // show the downloaded svg back to the caller via its completion callback
                }
            )
            .catch((e: Error): void => {
                console.error(e.toString());
                error(`Fetch error for '${url}', error was '${e.toString()}'`, e); // call the supplied error handling function
            });
    }
}

export default class Downloader {
    private downloaders = {};

    constructor(downloaders: { [protocol: string]: IDownloader }) {
        this.downloaders = downloaders;
    }

    downloadWithProgress(
        url: string,
        complete: (svg: string) => void,
        progress?: (p: DownloadProgress) => void,
        error?: (msg: string, error?: Error) => void
    ): void {
        // url is the following:
        // data:image/svg+xml;base64....
        // http://
        // https://
        // local://
        // or otherwise return errors

        let protocol = '';
        let isUrl = true;
        try {
            protocol = new URL(url).protocol;
        } catch (e) {
            isUrl = false;
        }

        if (isUrl) {
            try {
                const [type = ''] = protocol.split(':');
                if (this.downloaders[type]) {
                    // data, http(s), local....
                    this.downloaders[type].downloadWithProgress(url, complete, progress, error);
                } else {
                    // unknown type
                    error(`Unsupported URL protocol '${type}' for SVG download: ${url}`);
                }
            } catch (e) {
                error(`Unexpected error downloading SVG: ${e.message}`);
            }
        } else {
            // the url might be literal content. <svg>...</svg>. This is kindof a hack where if you pass the downloader something
            // that is literal content, it will just return the content. Makes the code flow cleaner in layers above.
            complete(url);
        }
    }
}
