import { SPARKLINE_WHITE } from '@splunk/visualizations-shared/colorConstants';
import { parseNumber } from '@splunk/visualizations-shared/numberUtils';
import { getGraphColors, darken } from '../../../common/utils/singleValueUtils';

export const CONTENT_NEXT_TO_SPARKLINE_WIDTH_ALLOCATION_RATIO = 0.4;
export const LINEHEIGHT_FACTOR = 1.2;
export const CONTENT_SPARKLINE_SPACING = 16;
export const VALID_SPARKLINE_POSITIONS = ['below', 'before', 'after', 'off'];

/*
 * @method getUnderLabelHeight
 * @param {Object} props
 * @param {String} underLabel
 * @param {Number} underLabelFontSize
 * @returns {Number} underLabelHeight
 */
export const getUnderLabelHeight = ({ underLabel, underLabelFontSize }) =>
    underLabel && underLabel.length ? underLabelFontSize * LINEHEIGHT_FACTOR : 0;

/**
 * Get the available width for the Single Value content part
 * @method getAvailableContentWidth
 * @param {Object} config
 * @param {Boolean} config.hasSparkline
 * @param {String} config.sparklinePosition
 * @param {Number} config.containerWidth
 * @param {Number} config.overflowArea
 * @returns {Number} available content width to use
 */
export const getAvailableContentWidth = ({
    hasSparkline,
    isSparklinePositionBelow,
    containerWidth,
    overflowArea,
}) => {
    const overflowTotalArea = 2 * overflowArea; // left and right overflow
    if (hasSparkline && !isSparklinePositionBelow) {
        return CONTENT_NEXT_TO_SPARKLINE_WIDTH_ALLOCATION_RATIO * containerWidth - overflowTotalArea;
    }
    return containerWidth - overflowTotalArea;
};

/*
 * @method getAvailableContentHeight
 * @param {Object} props
 * @param {Number} props.height
 * @param {Bool} props.hasSparkline
 * @param {Bool} props.isSparklinePositionBelow
 * @param {Number} props.majorTextFontSize
 * @param {Number} props.underLabelHeight
 * @returns {Number} contentHeight
 */
export const getAvailableContentHeight = ({
    height,
    hasSparkline,
    isSparklinePositionBelow,
    majorTextFontSize,
    underLabelHeight = 0,
}) => {
    if (hasSparkline && !isSparklinePositionBelow) {
        return height;
    }

    return majorTextFontSize + CONTENT_SPARKLINE_SPACING + underLabelHeight;
};

/*
 * @method isValidPosition
 * @param {String} sparklinePosition
 * @param {Bool} isValid
 */
export const isValidPosition = sparklinePosition => VALID_SPARKLINE_POSITIONS.indexOf(sparklinePosition) > -1;

/**
 * Sanitize sparklineValues, convert all values to numbers
 * @method parseSparklineValues
 * @params {Array} sparklineValues
 * @params {Array}
 */
export const parseSparklineValues = ({ sparklineValues }) =>
    // todo: need this function to maitain the behavior for dashboard-viz and react-viz.
    // can refactor it after we discard dashboard-viz and react-viz repo
    Array.isArray(sparklineValues)
        ? sparklineValues.map(v => {
              const numV = parseNumber(v);
              if (numV === 0) {
                  return null;
              }

              return numV || v;
          })
        : undefined;

/**
 * Determine if a sparkline graph should be displayed
 * @method shouldShowSparkline
 * @param {Object} props
 * @param {Bool} props.showSparkline       Flag to turn graph on/off
 * @param {Array} props.sparklineValues       List of values to display in graph
 * @returns {Bool} true if flag is on and sparklineValues has data to show
 */
export const shouldShowSparkline = ({ showSparkline, sparklineValues }) =>
    showSparkline &&
    Array.isArray(sparklineValues) &&
    sparklineValues.length > 1 &&
    sparklineValues.every(v => v === null || !Number.isNaN(parseNumber(v))) &&
    sparklineValues.filter(v => v !== null).length > 0; // don't show sparkline if all values are null

/*
 * getSparklineWith
 * @param {Object} props
 * @param {Number} props.width
 * @param {Bool} props.isSparklinePositionBelow
 * @returns {Number} sparklineWidth
 */
export const getSparklineWidth = ({ width, isSparklinePositionBelow }) => {
    const spaceRatio = isSparklinePositionBelow ? 1 : 0.6;
    return width * spaceRatio;
};

/*
 * getSparklineHeight
 * @param {Object} props
 * @param {Number} props.height
 * @param {Number} props.contentHeight
 * @param {Bool} props.isSparklinePositionBelow
 * @returns {Number} sparklineHeight
 */
export const getSparklineHeight = ({ height, contentHeight, isSparklinePositionBelow }) => {
    const belowPositionHeight = height - contentHeight;
    const sanitizedBelowPositionHeight = belowPositionHeight < 0 ? 0 : belowPositionHeight;
    return isSparklinePositionBelow ? sanitizedBelowPositionHeight : height;
};

/*
 * getSparklineSegments
 * @param {Object} props
 * @param {Bool} props.hasSparkline
 * @param {Number} props.sparklineHighlightSegments
 * @returns {Number} sparkline segments
 */
export const getSparklineSegments = ({ hasSparkline, sparklineHighlightSegments }) =>
    hasSparkline && typeof sparklineHighlightSegments === 'number' && sparklineHighlightSegments > 0
        ? sparklineHighlightSegments + 1
        : 0;

/*
 * getEndDots
 * @param {Object} props
 * @param {Bool} props.hasSparkline
 * @param {Number} props.sparklineHighlightDots
 * @returns {Number} sparkline end dots
 */
export const getEndDots = ({ hasSparkline, sparklineHighlightDots }) =>
    !hasSparkline || typeof sparklineHighlightDots === 'string' || sparklineHighlightDots < 0
        ? 0
        : sparklineHighlightDots;

/**
 * Get the colors for a sparkline graph based on severity
 * @method getSparklineColors
 * @param {Object} props
 * @param {Bool} props.backgroundEnabled          // Flag to turn on color mode
 * @param {String} props.backgroundColor          // The current background color, used in trend mode
 * @param {Object} props.sparklineSeverityColors
 * @returns {Object} { background, stroke } colors
 */
export const getSparklineColors = ({ backgroundEnabled, backgroundColor, sparklineSeverityColors }) =>
    getGraphColors({
        backgroundEnabled,
        backgroundColor,
        defaultColors: sparklineSeverityColors,
        customColorFormatter: color => {
            if (color === SPARKLINE_WHITE) {
                return sparklineSeverityColors.none;
            }
            return { background: darken(color), stroke: SPARKLINE_WHITE };
        },
    });

/*
 * getSparklines
 * @param {Object} props
 * @param {Number} props.width
 * @param {Number} props.height
 * @param {Number} props.contentHeight
 * @param {Bool} props.hasSparkline
 * @param {Bool} props.isSparklinePositionBelow
 * @param {Number} props.sparklineHighlightSegments
 * @param {Number} props.sparklineHighlightDots
 * @returns {Object} { sparklineWidth, sparklineHeight, sparklineSegments, endDots }
 */
export const getSparklines = ({
    width,
    height,
    contentHeight,
    hasSparkline,
    isSparklinePositionBelow,
    sparklineHighlightSegments,
    sparklineHighlightDots,
}) => {
    const sparklineWidth = getSparklineWidth({
        width,
        isSparklinePositionBelow,
    });
    const sparklineHeight = getSparklineHeight({
        height,
        contentHeight,
        isSparklinePositionBelow,
    });
    const sparklineSegments = getSparklineSegments({ hasSparkline, sparklineHighlightSegments });
    const endDots = getEndDots({ hasSparkline, sparklineHighlightDots });

    return {
        sparklineWidth,
        sparklineHeight,
        sparklineSegments,
        endDots,
    };
};
