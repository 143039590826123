import SingleValue from './PureSingleValue/SingleValue';
import withDashboardViz from '../common/withDashboardViz';
import { isLoadingOrNoColumns } from '../common/utils/singleValueUtils';
import vizConfig from './config';
import { DataSource } from '../common/interfaces/DataSource';
import withTrellis from '../common/hocs/withTrellis';

export const config = vizConfig;
export const PureSingleValue = SingleValue;

export const computeVizProps = ({ themeFunc, ...props }) => {
    const defaultThemeColorVariables = [
        'defaultFontColor',
        'defaultBackgroundColor',
        'defaultBlockFontColor',
        'defaultSparklineStrokeColor',
        'sparklineSeverityColors',
    ];
    const defaultThemeColors: Record<string, unknown> = {};
    defaultThemeColorVariables.forEach(variable => {
        defaultThemeColors[variable] = themeFunc(variable);
    });
    if (typeof defaultThemeColors.sparklineSeverityColors === 'string') {
        // sparkline severity color defaults are stored as a stringified object inside platformViz themes, since @splunk/themes/pick does not accept objects for theme variable values
        // convert stringified object into object if using platformviz themes
        try {
            defaultThemeColors.sparklineSeverityColors = JSON.parse(
                defaultThemeColors.sparklineSeverityColors
            );
        } catch (e) {} // eslint-disable-line no-empty
    }
    return { defaultThemeColors, ...props };
};

const viz = withTrellis(
    withDashboardViz({
        ReactViz: SingleValue,
        vizConfig,
        computeVizProps,
        useIconPlaceholder: (dataSources: { [name: string]: DataSource }, loading: boolean) =>
            isLoadingOrNoColumns(dataSources, loading),
    })
);

export const { themes } = vizConfig;
export default viz;
