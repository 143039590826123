import React from 'react';
import { _ } from '@splunk/ui-utils/i18n';
import SVG, {
    type SVGPropsOptionalViewBox,
} from '@splunk/react-icons/SVGEnterprise';

/* eslint-disable max-len */
export default function DropdownIcon({
    screenReaderText = _('Dropdown Icon'),
    ...otherProps
}: SVGPropsOptionalViewBox): ReturnType<typeof SVG> {
    return (
        <SVG
            viewBox="0 0 14 24"
            screenReaderText={screenReaderText}
            {...otherProps}
        >
            <path fill="currentColor" d="M3 10L7 14L11 10H3Z" />
        </SVG>
    );
}
