import React, { useMemo, useCallback } from 'react';
import styled from 'styled-components';
import type {
    DataSourceBindingMap,
    LayoutItemType,
} from '@splunk/dashboard-types';
import {
    Message,
    ErrorBoundary,
    useDragHandleComponent,
    EVENT_MOUSE_DOWN_ON_VIZ_WITH_HANDLE,
    SelectableContainer,
    getBorderStyle,
    useSubscribeToSearches,
} from '@splunk/dashboard-ui';
import {
    selectFullscreenElement,
    selectMode,
    makeSelectIsSelected,
    selectAreMultipleVizSelected, // Rename to selectIsMultiselect?
    selectItemDefinitionFactory,
    selectDataSourceDefinitionFactory,
    useSelector,
} from '@splunk/dashboard-state';
import {
    useLayoutLayers,
    type LayersInfo,
    usePreset,
} from '@splunk/dashboard-context';

import { getHasDrilldown } from '../utils/visualization';
import { getActionMenuPosition, useActionMenu } from '../hooks/useActionMenu';
import { useCanItemBeHidden } from '../hooks/useCanItemBeHidden';

import VizContainer from './VisualizationContainer';
import InputContent from './InputContent';

const empty = {};

// TODO: width and height also become HTML attributes, and we may want these to be hidden, on the other hand, we add an inline style with these, so it really doesn't matter
interface ItemWrapperProps {
    width: string | number;
    height?: string | number;
    layoutItemType: LayoutItemType;
    itemId: string;
    $itemType: string;
    children: React.ReactNode;
}

const toAttrs = ({
    itemId,
    $itemType,
    layoutItemType,
    width,
    height,
}: Omit<ItemWrapperProps, 'children'>) => {
    let base: Record<string, unknown>;
    if (layoutItemType === 'input') {
        base = {
            'data-test': 'input-item',
            'data-input-type': $itemType,
            'data-input-id': itemId,
        };
    } else {
        base = {
            'data-test': 'viz-item',
            'data-viz-type': $itemType,
            'data-id': itemId,
        };
    }

    base.style = height ? { width, height } : { width };

    return base;
};

type ItemProps = Omit<ItemWrapperProps, 'height'>;
const Item = styled.div.attrs<ItemProps>((props) => toAttrs(props))<ItemProps>`
    position: relative;
    pointer-events: auto;
`;

type FixSizeItemProps = Required<ItemWrapperProps>;
const FixSizeItem = styled.div.attrs<FixSizeItemProps>((props) =>
    toAttrs(props)
)<FixSizeItemProps>`
    flex-direction: column;
    pointer-events: auto;
`;

const ItemWrapper = React.memo(
    ({
        width,
        height,
        children,
        itemId,
        $itemType,
        layoutItemType,
    }: ItemWrapperProps) => {
        if (!height) {
            return (
                <Item
                    width={width}
                    itemId={itemId}
                    $itemType={$itemType}
                    layoutItemType={layoutItemType}
                >
                    {children}
                </Item>
            );
        }
        return (
            <FixSizeItem
                width={width}
                height={height}
                itemId={itemId}
                $itemType={$itemType}
                layoutItemType={layoutItemType}
            >
                {children}
            </FixSizeItem>
        );
    }
);

// Wrapper for the ProgressBar, Visualization, Title, and Status
interface ItemLayerProps {
    zIndex?: number;
}
const ItemLayer = styled.div.attrs<ItemLayerProps>(() => ({
    'data-test': 'item-layer',
}))<ItemLayerProps>`
    z-index: ${(props) => props.zIndex};
    height: 100%;
`;

export interface BaseItemContainerProps {
    id: string;
    width: string | number;
    height?: string | number;
    y: number;
    canvasHeight: number;
    // TODO: don't pass a flag and an optional parameter. If the portal is provided, use it
    renderActionMenuInPortal: boolean;
    actionMenuPortal?: React.MutableRefObject<HTMLDivElement | null>;
    layoutItemType?: LayoutItemType;
    // TODO: investigate - can this be handled with useDispatch so it doesn't need to drill through 3 layers?
    onSelected: (
        e: React.MouseEvent,
        visualizationIds: { id: string; type: string }[]
    ) => void;
}

export const BaseItemContainer = ({
    id,
    width,
    height,
    layoutItemType = 'block',
    y,
    canvasHeight,
    renderActionMenuInPortal,
    actionMenuPortal,
    onSelected,
}: BaseItemContainerProps) => {
    const preset = usePreset();

    // Selector factory for checking if a vizId is in global state selectedItems array
    const selectIsSelected = useMemo(makeSelectIsSelected, []);
    const isSelected = useSelector((state) => selectIsSelected(state, id));
    const isMultiSelect = useSelector(selectAreMultipleVizSelected);

    const mode = useSelector(selectMode);
    const isFullscreen = useSelector(selectFullscreenElement) === id;
    const containerWidth = isFullscreen ? '100vw' : width;
    const containerHeight = isFullscreen ? '100vh' : height;

    const layerData = useLayoutLayers();
    const { layer: itemLayer, selection: selectionLayer } = useMemo<
        Partial<LayersInfo>
    >(() => layerData?.[id] ?? (empty as Partial<LayersInfo>), [layerData, id]);

    const selectItemDefinition = useMemo(
        () => selectItemDefinitionFactory(layoutItemType)(),
        [layoutItemType]
    );
    const selectDSDef = useMemo(
        () => selectDataSourceDefinitionFactory(layoutItemType)(),
        [layoutItemType]
    );

    const itemDefinition = useSelector((state) =>
        selectItemDefinition(state, id)
    );

    const dataSourceDefinitions = useSelector((state) =>
        selectDSDef(state, id)
    );

    const itemType = itemDefinition.type;
    const dataSourceBindings: DataSourceBindingMap =
        itemDefinition.dataSources ?? empty;

    const canBeHidden = useCanItemBeHidden({ itemDefinition });

    const hasDrilldown = getHasDrilldown(itemDefinition.eventHandlers);
    const outlineStyle = getBorderStyle({
        isSelected,
        mode,
        hasDrilldown,
        canBeHidden,
        layoutItemType,
    });

    const { loading, dataSources, refresh, updateRequestParams } =
        useSubscribeToSearches({
            consumerId: id,
            bindings: dataSourceBindings,
        });

    const actionMenuPosition = getActionMenuPosition({
        height,
        y,
        canvasHeight,
        shouldUsePortal: renderActionMenuInPortal,
    });
    const actionMenu = useActionMenu({
        itemId: id,
        itemDefinition,
        layoutItemType,
        portalRef: actionMenuPortal,
        shouldUsePortal: renderActionMenuInPortal,
        dataSourceMeta: dataSources?.primary?.meta,
        dataSourceDefinitions,
        isSelected,
        menuPosition: actionMenuPosition,
        mode,
    });

    const handleError = useCallback(
        (message: string) => <Message level="error" message={message} />,
        []
    );

    const shouldShowDragHandle =
        mode === 'edit' && preset.shouldShowDragHandle(itemType);

    const DragHandle = useDragHandleComponent({
        id,
        shouldShowDragHandle,
        type: layoutItemType,
    });

    const handleMouseDown = useCallback(
        (event) => {
            if (shouldShowDragHandle) {
                // any click in the viz should not bubble up to canvas
                //   can only move viz via drag handle now
                event.stopPropagation();

                // We still want ability to select a viz. This is the case where
                //   we select viz but do not setPosition and thus it can't be moved
                const newEvent = new CustomEvent(
                    EVENT_MOUSE_DOWN_ON_VIZ_WITH_HANDLE,
                    {
                        detail: {
                            vizId: id,
                            initialEvent: event,
                            type: layoutItemType,
                        },
                    }
                );
                document.dispatchEvent(newEvent);
            }
        },
        [shouldShowDragHandle, id, layoutItemType]
    );

    const ItemContainer = useMemo(() => {
        const commonProps = {
            id,
            width,
            height,
            mode,
            itemDefinition,
            loading,
            refresh,
            updateRequestParams,
            dataSources,
        };
        if (layoutItemType === 'block') {
            return (
                <VizContainer
                    {...commonProps}
                    isFullscreen={isFullscreen}
                    onSelected={onSelected}
                />
            );
        }

        return (
            <InputContent
                {...commonProps}
                isSelected={isSelected && !isMultiSelect}
                isOnCanvas
            />
        );
    }, [
        dataSources,
        height,
        id,
        isFullscreen,
        isSelected,
        itemDefinition,
        layoutItemType,
        loading,
        mode,
        onSelected,
        refresh,
        updateRequestParams,
        width,
        isMultiSelect,
    ]);

    return (
        <ErrorBoundary render={handleError}>
            <ItemWrapper
                width={width}
                height={height}
                layoutItemType={layoutItemType}
                $itemType={itemType}
                itemId={id}
            >
                <SelectableContainer
                    itemId={id}
                    width={containerWidth}
                    height={containerHeight}
                    outlineStyle={outlineStyle}
                    onMouseDown={handleMouseDown}
                    zIndex={selectionLayer}
                    canBeHidden={mode === 'edit' && canBeHidden}
                    tabIndex={0}
                >
                    <ItemLayer zIndex={itemLayer}>
                        {DragHandle}
                        {ItemContainer}
                    </ItemLayer>
                    {!isMultiSelect && actionMenu}
                </SelectableContainer>
            </ItemWrapper>
        </ErrorBoundary>
    );
};
