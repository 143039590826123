import { isEqual, isString, zip, zipObject, findIndex } from 'lodash';
import type {
    FieldObj,
    ColumnValue,
    RowItem,
    JSONArray,
    JSONCols,
    JSONRows,
} from '@splunk/dashboard-types';

export type Field = string | FieldObj;

/**
 * @class
 * DataSet represent a set of 2 dimensional data
 */
export default class DataSet {
    fields: FieldObj[];

    columns: ColumnValue[][];

    /**
     * @constructor
     * @param {Field[]} [fields= []] List of field name or field objects
     * @param {ColumnValue[][]} [columns=[]] List of column values related to fields
     * @returns DataSet
     */
    constructor(fields: Field[] = [], columns: ColumnValue[][] = []) {
        this.fields = fields.map((field) => {
            if (isString(field)) {
                return {
                    name: field,
                };
            }
            return field;
        });
        this.columns = columns;
    }

    /**
     * Returns a empty Dataset
     *
     * Examples:
     * ```js
     * const empty = DataSet.empty();
     * ```
     * @returns {DataSet} DataSet
     * @public
     */
    static empty(): DataSet {
        return new DataSet();
    }

    /**
     * Construct a Dataset with data in json array format
     *
     * Examples:
     * ```js
     *  const dataset = DataSet.fromJSONArray(
     *     [{ name: 'x' }, { name: 'y' }, { name: 'z' }],
     *     [{ x: 'a', y: 4, z: 70 }, { x: 'b', y: 5, z: 80 }, { x: 'c', y: 6, z: 90 }]
     *  );
     * ```
     * @param {FieldObj[]} [fields=[]] List of objects containing field names
     * @param {RowItem[]} [results=[]] List of objects containing results for each field
     * @returns {DataSet}
     * @public
     */
    static fromJSONArray(
        fields: FieldObj[] | null = [],
        results: RowItem[] = []
    ): DataSet {
        let fieldList = fields;
        if (fieldList == null || fieldList.length === 0) {
            if (results.length > 0) {
                const rowSample = results[0];
                fieldList = Object.keys(rowSample).map((field) => ({
                    name: field,
                }));
            } else {
                fieldList = [];
            }
        }

        const columns = fieldList.map(({ name }) =>
            results.reduce<ColumnValue[]>((col, row) => {
                // if a field is not present on a result entry we set it to null
                col.push(row[name] === undefined ? null : row[name]);
                return col;
            }, [])
        );

        return new DataSet(fieldList, columns);
    }

    /**
     * Construct a Dataset with data in json columns format
     *
     * Examples:
     * ```js
     *  const dataset = DataSet.fromJSONCols(
     *     [{ name: 'x' }, { name: 'y' }, { name: 'z' }],
     *     [['a', 'b', 'c'], [4, 5, 6], [70, 80, 90]];
     *  );
     * ```
     * @param {Field[]} [fields=[]] List of fields
     * @param {ColumnValue[][]} [columns=[]] list of column values
     * @returns {DataSet}
     * @public
     */
    static fromJSONCols(
        fields: Field[] = [],
        columns: ColumnValue[][] = []
    ): DataSet {
        return new DataSet(fields, columns);
    }

    /**
     * Construct a Dataset with data in json rows format
     *
     * Examples:
     * ```js
     *  const dataset = DataSet.fromJSONRows(
     *     [{ name: 'x' }, { name: 'y' }, { name: 'z' }],
     *     [['a', 4, 70], ['b', 5, 80], ['c', 6, 90]];
     *  );
     * ```
     * @param {Field[]} [fields=[]] List of fields
     * @param {ColumnValue[][]} [columns=[]] list of column values
     * @returns {DataSet}
     * @public
     */
    static fromJSONRows(
        fields: Field[] = [],
        rows: ColumnValue[][] = []
    ): DataSet {
        return new DataSet(fields, zip(...rows));
    }

    /**
     * Convert data to json array
     * @return {JSONArray} data in json array format
     * @public
     */
    toJSONArray(): JSONArray {
        return {
            fields: this.fields,
            results: zip(...this.columns).map((row) =>
                zipObject(
                    this.fields.map((field) => field.name),
                    row
                )
            ),
        };
    }

    /**
     * Convert data to json columns
     * @return {JSONCols} data in json columns format
     * @public
     */
    toJSONCols(): JSONCols {
        const { fields, columns } = this;

        return { fields, columns };
    }

    /**
     * Convert data to json rows
     * @return {JSONRows} data in json rows format
     * @public
     */
    toJSONRows(): JSONRows {
        return {
            fields: this.fields,
            rows: zip(...this.columns),
        };
    }

    /**
     * List all fields
     * @return {FieldObj[]} fields array
     * @public
     */
    getFields(): FieldObj[] {
        return this.fields;
    }

    /**
     * List data columns
     * @return {Object} columns array
     * @public
     */
    getColumns(): ColumnValue[][] {
        return this.columns;
    }

    /**
     *
     * @param {String} fieldName
     * @return {ColumnValue[]} column data
     * @public
     */
    getColumnByField(fieldName: string): ColumnValue[] {
        const index = findIndex(this.fields, ({ name }) => name === fieldName);
        return this.columns[index];
    }

    /**
     *
     * @param {String} fieldName
     * @return {Boolean}
     * @public
     */
    hasField(fieldName: string): boolean {
        return findIndex(this.fields, ({ name }) => name === fieldName) !== -1;
    }

    /**
     * @return {Boolean} true if DataSet has no data
     * @public
     */
    isEmpty(): boolean {
        return this.columns.length === 0;
    }

    /**
     *
     * @param {DataSet} dataSet DataSet to compare
     * @return {Boolean} true if another dataset is equals to current one
     * @public
     */
    equals(dataSet: DataSet): boolean {
        return (
            isEqual(this.fields, dataSet.fields) &&
            isEqual(this.columns, dataSet.columns)
        );
    }

    /**
     * Returns a slice of the dataset, useful for pagination.
     * @param options {Object}
     * @param options.count {Number} number of rows
     * @param options.offset {Number} starting row index
     * @returns {DataSet}
     */
    getPage({
        count = 0,
        offset = 0,
    }: {
        count?: number;
        offset?: number;
    }): DataSet {
        const end = count <= 0 ? undefined : offset + count;
        return DataSet.fromJSONCols(
            this.getFields(),
            this.getColumns().map((column) => column.slice(offset, end))
        );
    }

    /**
     * Get total number of rows, note this is not affected by the pagination.
     */
    getTotalCount(): number {
        return this.columns[0]?.length ?? 0;
    }
}
