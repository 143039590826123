import type { VisualizationDefinition } from '@splunk/dashboard-types';
import config from '@splunk/visualizations/Map.config';
import type { EncodingConfig } from '../encoding';
import { encodingToDynamicOptionsDSL } from '../encoding';
import { removeInvalidOptions, renameVizOptions } from '../utils/migrationUtils';

const validOptions = {
    ...config.optionsSchema,
};

const optionRenames = {
    fillColor: 'choroplethEmptyAreaColor',
    strokeColor: 'choroplethStrokeColor',
    featureId: 'areaIds',
    fill: 'dataColors',
    value: 'areaValues',
};

const defaultContextChanges = {
    fillGradientContext: {
        colors: '> themes.defaultGradientMigrationConfig',
    },
};

export const defaultStrokeColor = '#689C8D';
export const defaultEmptyAreaColor = '#EAEFF2';

export const migrateVizToSplunkMapChoropleth = (
    vizDefinition: VisualizationDefinition
): VisualizationDefinition => {
    const { encoding = {}, options = {}, ...otherDefinitionParts } = vizDefinition;

    const migratedDefinition: VisualizationDefinition = {
        ...otherDefinitionParts,
        type: 'splunk.map',
        options: {},
        context: {},
    };

    const { options: dataOptions, context: dataContext } = encodingToDynamicOptionsDSL(
        encoding as Record<string, EncodingConfig>
    );

    const dynamicOptions = renameVizOptions(dataOptions, optionRenames);
    const renamedOptions = renameVizOptions(options, optionRenames);

    if (!('dataColors' in dynamicOptions)) {
        Object.assign(dynamicOptions, {
            dataColors: '> areaValues | gradient(fillGradientContext)',
        });
    }

    let { choroplethEmptyAreaColor, choroplethStrokeColor } = renamedOptions;
    const { source } = renamedOptions;

    const layerOptions = ['choroplethEmptyAreaColor', 'choroplethStrokeColor', 'source'];

    const renamedOptionsFiltered = Object.keys(renamedOptions)
        .filter(key => !layerOptions.includes(key))
        .reduce((obj, key) => {
            /* eslint-disable-next-line no-param-reassign */
            obj[key] = renamedOptions[key];
            return obj;
        }, {});

    /* migrate default strokeColor in viz.geojson to either the backgroundColor if configured and non-transparent or defaultBackgroundColor in splunk.map */
    if (choroplethStrokeColor === defaultStrokeColor && choroplethEmptyAreaColor !== 'transparent') {
        if ('backgroundColor' in renamedOptionsFiltered) {
            /* eslint-disable-next-line dot-notation */
            if (renamedOptionsFiltered['backgroundColor'] !== 'transparent') {
                /* eslint-disable-next-line dot-notation */
                choroplethStrokeColor = renamedOptionsFiltered['backgroundColor'];
            } else {
                choroplethStrokeColor = '> themes.defaultBackgroundColor';
            }
        } else {
            choroplethStrokeColor = '> themes.defaultBackgroundColor';
        }
    }

    if (choroplethEmptyAreaColor === defaultEmptyAreaColor) {
        choroplethEmptyAreaColor = '> themes.defaultChoroplethEmptyAreaColor';
    }

    // update migrated options and context
    migratedDefinition.options = {
        ...renamedOptionsFiltered,
        layers: [
            {
                type: 'choropleth',
                source,
                ...dynamicOptions,
                choroplethEmptyAreaColor,
                choroplethStrokeColor,
                choroplethOpacity: 1.0,
            },
        ],
        showScale: false,
        showBaseLayer: false,
        showZoomControls: false,
    };

    if (vizDefinition.type === 'viz.geojson.us') {
        migratedDefinition.options.center = [39.83, -97];
        migratedDefinition.options.zoom = 3;
    } else {
        migratedDefinition.options.center = [46.56, 10.35];
        migratedDefinition.options.zoom = 0;
    }

    migratedDefinition.context = encoding.fill ? dataContext : defaultContextChanges;

    if (
        JSON.stringify(dataContext) ===
        JSON.stringify({
            fillGradientContext: {},
        })
    ) {
        Object.assign(migratedDefinition, {
            context: defaultContextChanges,
        });
    }

    removeInvalidOptions(migratedDefinition.options, validOptions);

    return migratedDefinition;
};
