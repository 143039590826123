/* eslint-disable react/no-unused-prop-types */
import { pick, debounce, noop } from 'lodash';
import { _ } from '@splunk/ui-utils/i18n';
import { VIZ_CATEGORICAL } from '@splunk/visualization-color-palettes';
import { useEffect, useRef, useContext } from 'react';
import * as React from 'react';
import * as T from 'prop-types';
import EventListener from 'react-event-listener';
import '@splunk/visualizations-shared/i18n'; // fixme, @splunk/charting should be refactor to use locale & timezone instead of i18n functions
import jsCharting from '@splunk/charting-bundle';
import TimezoneContext, { TimezoneContextInterface } from '@splunk/visualization-context/TimezoneContext';
import { toDimension } from '@splunk/visualizations-shared/style';
import styled from 'styled-components';
import Message from '@splunk/visualizations-shared/Message';
import useSplunkTheme from '@splunk/themes/useSplunkTheme';
import { getChartingThemeKey } from '@splunk/visualizations-shared/themeUtils';
import { ChartingApiFunctional } from './ChartingApi';

export const NO_INPLACE_PROPS = [
    'chart',
    'chart.orientation',
    'layout.splitSeries',
    'chart.style',
    'legend.placement',
];

export const changedOptions = (curOptions: Record<string, any>, nextOptions: Record<string, any>): string[] =>
    Object.keys(curOptions)
        .filter(key => !(key in nextOptions))
        .concat(
            Object.keys(nextOptions).filter(
                key => !(key in curOptions) || curOptions[key] !== nextOptions[key]
            )
        );

jsCharting.enableScaledEvents();

function usePrevious(value): Record<string, any> {
    const ref = useRef();
    useEffect(() => {
        ref.current = value;
    });
    return ref.current;
}

export const canUpdateInPlace = (
    curOptions: Record<string, any>,
    nextOptions: Record<string, any>
): boolean => {
    // if either of the options are undefined
    // it cannot update in place.
    if (!curOptions || !nextOptions) {
        return false;
    }
    return (
        !NO_INPLACE_PROPS.some(p => curOptions[p] !== nextOptions[p]) &&
        !changedOptions(curOptions, nextOptions).some(p => p.toLowerCase().endsWith('color'))
    );
};

// create 'row' payloads from 'rowContext' in the flattened format:
// row.<fieldname>.value
export const updateRowPayload = (
    chartEventRowContext: Record<string, any>,
    payload: Record<string, any>
): Record<string, any> => {
    Object.keys(chartEventRowContext || {}).forEach(key => {
        // eslint-disable-next-line no-param-reassign
        payload[`${key}.value`] = chartEventRowContext[key];
    });

    return payload;
};

export interface ContainerProps {
    width: string | number;
    height: string | number;
}

type ChartEventType = 'pointClick' | 'pointMouseOver' | 'pointMouseOut' | 'chartRangeSelect' | 'legendClick';
enum VizChartEventType {
    POINT_CLICK = 'point.click',
    POINT_MOUSE_OUT = 'point.mouseout',
    POINT_MOUSE_OVER = 'point.mouseover',
    LEGEND_CLICK = 'legend.click',
}

interface ChartEvent<T extends ChartEventType> extends Event {
    name: string;
    name2: string;
    value?: unknown;
    value2?: unknown;
    rowContext?: Record<string, any>;
    span?: number;
    type: T;
}

// @TODO(pwied): remove after HC upgrade to 8+
// .highcharts-a11y-proxy-container rule addresses a HighCharts < 8 bug
// caused by the accessibility plugin
// https://github.com/highcharts/highcharts/issues/12883
const ChartingContainer = styled.div.attrs((props: ContainerProps) => ({
    'data-test': 'charting-container',
    width: props.width,
    height: props.height,
}))`
    & .highcharts-a11y-proxy-container {
        pointer-events: none;
    }
    overflow: hidden;
    position: relative;
    ${props => toDimension(pick(props, ['width', 'height']))};
`;

export interface ChartingProps {
    // width in pixel or string, defaults to 100%
    width: string | number;
    // height in pixel or string
    height: string | number;
    // visualization formatting options
    options?: Record<string, any>;
    // charting dataset from jsCharting.extractChartReadyData
    chartData: Record<string, any>;
    // callbacks to trigger event
    onEventTrigger?: (...args: any[]) => void;
    onSelect?: (...args: any[]) => void;
    onClick?: (...args: any[]) => void;
    onPointMouseOver?: (...args: any[]) => void;
    onPointMouseOut?: (...args: any[]) => void;
    // Custom style on visualization
    style?: Record<string, any>;
    colorPalette?: Array<string>;
    visualizationApiRef?: (...args: any[]) => void;
    themeKey?: string;
}

const propTypes: Record<keyof ChartingProps, T.Validator<any>> = {
    width: T.oneOfType([T.string, T.number]),
    height: T.oneOfType([T.string, T.number]),
    options: T.object,
    chartData: T.object,
    onEventTrigger: T.func,
    onClick: T.func,
    onSelect: T.func,
    onPointMouseOver: T.func,
    onPointMouseOut: T.func,
    style: T.object,
    colorPalette: T.array,
    visualizationApiRef: T.func,
    themeKey: T.string,
};

const Charting = (props: ChartingProps): React.ReactElement => {
    const container = useRef<HTMLDivElement>(null);
    const needsInit = useRef<boolean>(true);
    const chart = useRef<jsCharting>();
    const needsRedraw = useRef<boolean>(true);
    const api = useRef(new ChartingApiFunctional(chart));
    const timezone = useContext<TimezoneContextInterface>(TimezoneContext);

    const computeChartingSize = () => {
        if (container.current) {
            const rect = container.current.getBoundingClientRect();
            return {
                height: rect.height,
                width: rect.width,
            };
        }
        return {};
    };
    const splunkTheme = useSplunkTheme();
    const themeKey = props.themeKey ? props.themeKey : getChartingThemeKey({ splunkThemeV1: splunkTheme });
    const {
        width,
        height,
        style,
        chartData,
        options,
        colorPalette,
        onClick,
        onSelect,
        onPointMouseOut,
        onPointMouseOver,
        onEventTrigger,
    } = props;
    const prevOptions = usePrevious(options);
    const prevTimezone = usePrevious(timezone);

    const handleClick = (
        chartEvent: ChartEvent<'pointClick' | 'legendClick'>,
        type: VizChartEventType
    ): void => {
        let payload: Partial<ChartEvent<'pointClick' | 'legendClick'>> = pick(
            chartEvent,
            'name2',
            'value2',
            '_span',
            'modifierKey'
        );

        payload.name = payload.name2;
        delete payload.name2;

        if (type === VizChartEventType.POINT_CLICK) {
            payload.value = payload.value2;
            delete payload.value2;

            payload = updateRowPayload(chartEvent.rowContext, payload);
        }

        onClick({
            type,
            originalEvent: chartEvent,
            payload,
        });
    };

    const destroyChart = (): void => {
        if (chart.current) {
            chart.current.destroy();
            chart.current = null;
        }
        needsRedraw.current = true;
        needsInit.current = true;
    };

    const handleSelect = (chartEvent: ChartEvent<'chartRangeSelect'>): void => {
        // todo: need change this after breaking all visualizations
        if (onSelect && typeof onSelect === 'function') {
            onSelect({
                type: 'range.select',
                originalEvent: chartEvent,
                payload: pick(chartEvent, 'startXIndex', 'endXIndex', 'startXValue', 'endXValue'),
            });

            return;
        }

        // the chartEvent here is a jQuery event
        onEventTrigger({
            type: 'range.select',
            originalEvent: chartEvent,
            payload: pick(chartEvent, 'startXIndex', 'endXIndex', 'startXValue', 'endXValue'),
        });
    };

    const handlePointMouseOut = (chartEvent: ChartEvent<'pointMouseOut'>, type: VizChartEventType): void => {
        let payload: Partial<ChartEvent<'pointMouseOut'>> = pick(
            chartEvent,
            'name2',
            'value2',
            '_span',
            'modifierKey',
            'tooltipContext'
        );

        payload.name = payload.name2;
        delete payload.name2;
        payload.value = payload.value2;
        delete payload.value2;

        payload = updateRowPayload(chartEvent.rowContext, payload);

        onPointMouseOut({
            type,
            originalEvent: chartEvent,
            payload,
        });
    };

    const handlePointMouseOver = (
        chartEvent: ChartEvent<'pointMouseOver'>,
        type: VizChartEventType
    ): void => {
        let payload: Partial<ChartEvent<'pointMouseOver'>> = pick(
            chartEvent,
            'name2',
            'value2',
            '_span',
            'modifierKey',
            'tooltipContext'
        );

        payload.name = payload.name2;
        delete payload.name2;
        payload.value = payload.value2;
        delete payload.value2;

        payload = updateRowPayload(chartEvent.rowContext, payload);

        onPointMouseOver({
            type,
            originalEvent: chartEvent,
            payload,
        });
    };

    const drawChart = (): void => {
        const updatedOptions = { ...options, shouldColorizeTooltipData: false };
        const computedChartSize = computeChartingSize();
        const computedWidth = computedChartSize.width;
        const computedHeight = computedChartSize.height;
        const serializedTimezone = timezone?.serializedTimezone;
        const ianaTimezone = timezone?.ianaTimezone;
        const utcOffset = timezone?.utcOffset;
        if (!computedWidth || !computedHeight) {
            return;
        }
        if (!chartData || !Object.keys(chartData).length) {
            return;
        }

        // splunk-charting layers configuration option updates.
        // when an option is removed it needs to be set to an empty string
        // to override the previous configuration
        if (prevOptions) {
            Object.keys(prevOptions).forEach(key => {
                if (updatedOptions[key] === undefined) {
                    updatedOptions[key] = '';
                }
            });
        }

        try {
            if (needsInit.current) {
                destroyChart();
                jsCharting.setTheme(themeKey);
                if (colorPalette.length > 0) {
                    jsCharting.setColorPalette(colorPalette);
                }
                chart.current = jsCharting.createChart(container.current, {
                    ...updatedOptions,
                    drilldown: 'all',
                    ...(typeof serializedTimezone === 'string' && {
                        'time.serializedTz': serializedTimezone,
                    }),
                    ...(typeof ianaTimezone === 'string' && {
                        'time.ianaTimezone': ianaTimezone,
                    }),
                    ...(typeof utcOffset === 'number' && {
                        'time.timezoneOffset': utcOffset,
                    }),
                });
                // bind event handlers
                chart.current.on('pointClick', e => {
                    handleClick(e, VizChartEventType.POINT_CLICK);
                });
                chart.current.on('pointMouseOver', e => {
                    handlePointMouseOver(e, VizChartEventType.POINT_MOUSE_OVER);
                });
                chart.current.on('pointMouseOut', e => {
                    handlePointMouseOut(e, VizChartEventType.POINT_MOUSE_OUT);
                });
                chart.current.on('legendClick', e => {
                    handleClick(e, VizChartEventType.LEGEND_CLICK);
                });
                chart.current.on('chartRangeSelect', handleSelect);
            }
            if (needsRedraw.current) {
                jsCharting.setTheme(themeKey);
                if (colorPalette.length > 0) {
                    jsCharting.setColorPalette(colorPalette);
                }
                chart.current.prepareAndDraw(chartData, updatedOptions, () => {});
            }
            if (chart.current) {
                if (
                    Math.round(chart.current.height) !== Math.round(computedHeight) ||
                    Math.round(chart.current.width) !== Math.round(computedWidth)
                ) {
                    chart.current.resize(computedWidth, computedHeight);
                }
            }
            needsInit.current = false;
            needsRedraw.current = false;
        } catch (e) {
            window.console.error('Caught error rendering chart:', e);
        }
    };

    const debouncedDrawChart = debounce(drawChart, 5);

    const onContainerMount = chartingContainer => {
        container.current = chartingContainer;
        debouncedDrawChart();
    };

    const prevTheme = usePrevious(themeKey);
    const prevOnClick = usePrevious(onClick);

    useEffect(() => {
        debouncedDrawChart();
        props.visualizationApiRef(api.current);
        return () => {
            props.visualizationApiRef(null);
            destroyChart();
        };
    }, []);

    useEffect(() => {
        // TODO: figure out why re-init is needed for functional components (i.e. PlatformViz) when changing theme
        // seems like redraw doesn't suffice to update the background colors
        if (
            !chart.current ||
            !canUpdateInPlace(prevOptions, options) ||
            // timezone is a simple object so this comparison should be fast
            JSON.stringify(prevTimezone) !== JSON.stringify(timezone) ||
            (!props.themeKey && prevTheme !== themeKey) ||
            prevOnClick !== onClick
        ) {
            needsInit.current = true;
        }
        needsRedraw.current = true;
        // need to immediately call drawChart upon an option or chartData change
        // otherwise a noop would occur if the onContainerMount drawChart is re-invoked when dimensions change
        drawChart();
    }, [
        JSON.stringify(options),
        JSON.stringify(chartData.toJSON()),
        JSON.stringify(chartData.annotations),
        timezone?.ianaTimezone,
        timezone?.utcOffset,
        timezone?.serializedTimezone,
        themeKey,
        onClick,
    ]);
    return (
        <ChartingContainer style={style} ref={onContainerMount} width={width} height={height}>
            <EventListener target={window} onResize={debouncedDrawChart} />
        </ChartingContainer>
    );
};

const defaultProps: Record<keyof ChartingProps, any> = {
    width: '100%',
    height: 250,
    options: {},
    style: {},
    // todo: need remove it
    onEventTrigger: noop,
    onClick: noop,
    onSelect: noop,
    onPointMouseOver: noop,
    onPointMouseOut: noop,
    colorPalette: VIZ_CATEGORICAL,
    visualizationApiRef: noop,
    chartData: null,
    themeKey: null,
};

Charting.propTypes = propTypes;
Charting.defaultProps = defaultProps;

// allow for early return in case of empty chartData while still following hook rules
const withEmptyChartMessage = (Chart): React.FunctionComponent<ChartingProps> => {
    const Wrapper = (props: ChartingProps): React.ReactElement => {
        const { chartData } = props;

        if (!chartData) {
            return <Message message={_('No DataSource')} level="warning" />;
        }

        if (chartData && !Object.keys(chartData).length) {
            return <Message message={_('No Result')} level="info" />;
        }

        return <Chart {...props} />;
    };

    Wrapper.displayName = 'ChartingWrapper';
    Wrapper.propTypes = propTypes;
    Wrapper.defaultProps = defaultProps;
    return Wrapper;
};

// export default Charting;
export default withEmptyChartMessage(Charting as any);
