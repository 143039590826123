import { get, range } from 'lodash';
import { DYNAMIC_OPTIONS_DSL_PATTERN } from '@splunk/visualizations-shared/schemaUtils';
import type { JSONSchema7Definition } from 'json-schema';
import { isDynamicOption } from '@splunk/visualizations-shared/configUtils';
import { VizBehavior } from '../interfaces/VizBehavior';
import { VizConfig } from '../interfaces/VizConfig';
import { OptionsSchema } from '../interfaces/OptionsSchema';
import { EditorLayoutConfig, EditorConfig } from '../interfaces/Editor';

export interface PresetEntry {
    name: string;
    value: any;
    label: string;
}

const dynamicOptionsDSLType = {
    type: 'string',
    pattern: DYNAMIC_OPTIONS_DSL_PATTERN,
} as const;

/**
 * Helper function to extend an options schema with dynamic options DSL
 * @param optionsSchema a visualization's options schema
 * @returns an updated options schema that additionally allows strings starting with > for each option
 */
function enhanceOptionsSchemaWithDynamicOptions(
    optionsSchema: OptionsSchema | JSONSchema7Definition
): OptionsSchema {
    const enhancedOptionsSchema: OptionsSchema = {};
    Object.keys(optionsSchema).forEach((key): void => {
        const {
            // The next a few are schema annotations, we should leave them as is.
            // Note: in reality we don't use `readOnly`, `writeOnly` or `examples`, but they are listed here for completeness.
            // Ref: https://datatracker.ietf.org/doc/html/draft-handrews-json-schema-validation-01
            title,
            description,
            readOnly,
            writeOnly,
            examples,
            default: defaultValue,
            // The next a few need special care
            type,
            properties,
            patternProperties,
            // The rest should be handled together
            ...remainingSchemaEntry
        } = optionsSchema[key];

        const annotations = {
            ...(title !== undefined && { title }),
            ...(description !== undefined && { description }),
            ...(readOnly !== undefined && { readOnly }),
            ...(writeOnly !== undefined && { writeOnly }),
            ...(examples !== undefined && { examples }),
            ...(defaultValue !== undefined && { default: defaultValue }),
        };

        if (type === 'object') {
            enhancedOptionsSchema[key] = {
                ...remainingSchemaEntry,
                ...annotations,
                type,
                ...(properties && { properties: enhanceOptionsSchemaWithDynamicOptions(properties) }),
            };
            if (patternProperties) {
                let enhancedPatternProperties = {};
                Object.keys(patternProperties).forEach((opt): void => {
                    enhancedPatternProperties = {
                        ...enhancedPatternProperties,
                        ...enhanceOptionsSchemaWithDynamicOptions({ [opt]: patternProperties[opt] }),
                    };
                });
                enhancedOptionsSchema[key].patternProperties = enhancedPatternProperties;
            }
        } else {
            enhancedOptionsSchema[key] = {
                ...annotations,
                anyOf: [
                    // we can't assume `type` is always there because there are `enum` and `const`.
                    { ...(type !== undefined && { type }), ...remainingSchemaEntry },
                    dynamicOptionsDSLType,
                ],
            };
        }
    });
    return enhancedOptionsSchema;
}

/**
 * Helper function to enhance a Visualization Config as needed based on behaviors specified in supports block
 * this function also collapse all editor panels
 * @param vizConfig a Visualization Config
 * @returns an enhanced Visualization Config containing updated config entries based on behaviors it supports
 * and containing `open: false` for all editor panels in editorConfig
 */
function enhanceConfig(vizConfig: VizConfig): VizConfig {
    const enhancedConfig: VizConfig = { ...vizConfig };
    if (vizConfig.supports.indexOf(VizBehavior.DYNAMIC_OPTIONS) !== -1) {
        enhancedConfig.optionsSchema = enhanceOptionsSchemaWithDynamicOptions(vizConfig.optionsSchema);
    }
    enhancedConfig.editorConfig = vizConfig.editorConfig.map(
        (config: EditorConfig): EditorConfig => {
            return { ...config, open: config.open || false };
        }
    );
    return enhancedConfig;
}

/**
 * Helper function to uncollapse all editor panels by adding `open: true` to the editorConfig
 * Created for editor unit tests
 * @param vizConfig a Visualization Config
 * @returns a Visualization Config containing `open: true` for all editor panels in editorConfig
 */
function uncollapseEditorConfig(vizConfig: VizConfig): VizConfig {
    const editorPanelUncollapsedConfig: VizConfig = { ...vizConfig };
    editorPanelUncollapsedConfig.editorConfig = vizConfig.editorConfig.map(
        (config: EditorConfig): EditorConfig => {
            return { ...config, open: true };
        }
    );
    return editorPanelUncollapsedConfig;
}

function isDynamicOption(option?: string): boolean {
    return (option && typeof option === 'string' && option.trim().startsWith('>')) || false;
}

// private helpers to improve readability of getInitialPreset
// this shouldn't be tested since this util isn't something that we expect to use outside of getInitialPreset

/**
 * this checks to make sure that all values within vizContext are compatible with the given preset's context to determine the initialPreset
 * if a preset has explicitly specified a key as being undefined, the vizContext must also has that key as undefined, or else this CANNOT be the initialPreset
 * the inverse is true; if a preset has key is NOT undefined, it must also be NOT undefined in the corresponding vizContext
 * the internal config contents are irrelevant (as the user could customize them in source), just as long as the presence of `undefined`s match up
 *
 * Since the presetConfigs have explicit undefined keys to override the existing config, we need to explicitly check the undefined values
 */
function hasCompatibleContexts(
    vizContext: { [configKey: string]: any },
    presetContext: { [configKey: string]: any }
): boolean {
    const presetContextKeys = Object.keys(presetContext);
    return presetContextKeys.every(
        (contextKey): boolean =>
            (presetContext[contextKey] === undefined && vizContext[contextKey] === undefined) ||
            (presetContext[contextKey] !== undefined && vizContext[contextKey] !== undefined)
    );
}

/**
 * Checks to make sure that dynamic (non-dynamic) options in a given preset are also dynamic (non-dynamic) in the vizOptions
 */
function hasCompatibleOptions(
    vizOptions: { [configKey: string]: any },
    presetOptions: { [configKey: string]: any }
): boolean {
    const presetOptionKeys = Object.keys(presetOptions);
    return presetOptionKeys.every(
        (optionKey): boolean =>
            isDynamicOption(presetOptions[optionKey]) === isDynamicOption(vizOptions[optionKey])
    );
}

/**
 * Evaluates whether there is an initialPreset that fits the current state of vizContext + vizOptions
 * This function ignores extraneous vizOptions or vizContext keys, as they could be outside the scope of what the preset is intended to configure
 *
 * @param {Object} vizContext - the current vizContext definition
 * @param {Object} vizOptions - the current vizOptions definition
 * @param {PresetEntry[]} presets - the list of presets, one of which can be considered an initialPreset
 */
function getInitialPreset(
    vizContext: { [configKey: string]: any },
    vizOptions: { [configKey: string]: any },
    presets: PresetEntry[]
): PresetEntry | null {
    return presets.find((preset): boolean => {
        const presetContext = get(preset, ['value', 'context'], {});
        const presetOptions = get(preset, ['value', 'options'], {});
        const presetContextKeys = Object.keys(presetContext);
        const presetOptionKeys = Object.keys(presetOptions);

        // all presets have to explicitly specify which options to reset (i.e. need explicitly undefined keys)
        // if not, then the preset is invalid in config.ts
        if (presetContextKeys.length === 0 || presetOptionKeys.length === 0) return false;

        return (
            hasCompatibleContexts(vizContext, presetContext) &&
            hasCompatibleOptions(vizOptions, presetOptions)
        );
    });
}

interface PrecisionOption {
    label: string;
    value: number;
}

/**
 * generates number precision values for a Select editor
 *
 * @param {Number} numPrecisionValues - number of integer precision values to generate
 * @returns {{ label: string; value; string  }[]} array of { label, value } tuples
 */
function generatePrecisionValues(numPrecisionValues: number): PrecisionOption[] {
    return range(0, numPrecisionValues + 1).map(
        (i): PrecisionOption => ({
            label: `${i} (0${i > 0 ? '.' : ''}${'0'.repeat(i)})`, // e.g. 0.000 for numPrecisionValues = 3
            value: i,
        })
    );
}

export {
    enhanceConfig,
    enhanceOptionsSchemaWithDynamicOptions,
    getInitialPreset,
    generatePrecisionValues,
    uncollapseEditorConfig,
    isDynamicOption,
};

/**
 * insert layout in layout array, return a new layout array instead of updating the original
 *
 * @param {EditorLayoutConfig[][]} layout - editor layout
 * @param {Number} index - the index to insert layout item(s)
 * @param {EditorLayoutConfig[][]} layoutItems - the item(s) to insert
 * @returns {EditorLayoutConfig[][]} new editor layout with insertion
 */
export const insertLayout = (
    layout: EditorLayoutConfig[][],
    index: number,
    ...layoutItems: EditorLayoutConfig[][]
): EditorLayoutConfig[][] => [
    // copy part of the array before the specified index
    ...layout.slice(0, index),
    // inserted layout item
    ...layoutItems,
    // copy part of the array after the specified index
    ...layout.slice(index),
];
