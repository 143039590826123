import { mapKeys, mapValues } from 'lodash';
import type { PlainObject, FilterDefinition } from '../FilterTypes';

type HashTitleOrDescription = (item: PlainObject) => PlainObject;

const titleDescriptionNameMapping = {
    title: 'titleLength',
    description: 'descriptionLength',
    name: 'titleLength',
};

/**
 * A helper function designed to work with `lodash.mapValues` to hash title and description in a viz, dataSource or input.
 */
const hashTitleOrDescription: HashTitleOrDescription = (item) => {
    const itemWithValueReplaced = mapValues(item, (value, key) => {
        if (key === 'title' || key === 'description' || key === 'name') {
            return typeof value === 'string' ? value.length : 0;
        }
        return value;
    });

    return mapKeys(itemWithValueReplaced, (value, key) => {
        if (key === 'title' || key === 'description' || key === 'name') {
            return titleDescriptionNameMapping[key];
        }
        return key;
    });
};

const filterTitleAndDescriptions: FilterDefinition = (definition) => {
    const definitionWithValueReplaced = mapValues(
        definition,
        (value: PlainObject | string, key) => {
            if (key === 'title' || key === 'description') {
                return typeof value === 'string' ? value.length : 0;
            }

            if (['dataSources', 'visualizations', 'inputs'].includes(key)) {
                return mapValues(value, hashTitleOrDescription);
            }

            if (key === 'defaults') {
                return mapValues(value, (v: PlainObject) =>
                    mapValues(v, hashTitleOrDescription)
                );
            }

            return value;
        }
    );

    return mapKeys(definitionWithValueReplaced, (_value, key: string) => {
        if (key === 'title' || key === 'description') {
            return titleDescriptionNameMapping[key];
        }

        return key;
    });
};

export default filterTitleAndDescriptions;
