import * as React from 'react';
import styled from 'styled-components';

export const TRELLIS_TITLE_HEIGHT = 28;

const TrellisTitleText = styled.div`
    margin-left: 10px;
    margin-right: 10px;
    padding-top: 8px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
`;

const TrellisTitleDiv = styled.div<{ fontColor: string; backgroundColor: string }>`
    height: ${TRELLIS_TITLE_HEIGHT}px;
    font-size: 14px;
    font-weight: 700;
    color: ${props => props.fontColor};
    background-color: ${props => props.backgroundColor};
    box-shadow: 0 1px ${props => props.backgroundColor}; // this is added to avoid a 1px gap between title and viz divs that showed up intermittently because of pixel rounding error in chrome.
`;

interface TrellisTitleProps {
    fontColor: string;
    backgroundColor: string;
    trellisKey: unknown;
}

export const TrellisTitle = (props: TrellisTitleProps) => {
    const titleText = String(props.trellisKey).toString();
    return (
        <TrellisTitleDiv
            fontColor={props.fontColor}
            backgroundColor={props.backgroundColor}
            data-test="trellis-title"
            title={titleText}
        >
            <TrellisTitleText>{titleText}</TrellisTitleText>
        </TrellisTitleDiv>
    );
};
