/* eslint-disable class-methods-use-this */
import { DataPoint, geoSpace, logicalSpace } from './ICoordinateTransformation';
import CoordinateTransformation from './CoordinateTransformation';

// transform converts degrees to an x,y mercator map whose units are radians. InverseTransform goes from radians on the x,y map back to degrees.

export class MercatorTransformation extends CoordinateTransformation {
    public constructor() {
        super(geoSpace, logicalSpace);
    }

    public transformBack(coord: DataPoint): DataPoint {
        const { x, y } = coord;
        return {
            long: (x * 180) / Math.PI,
            lat: ((2 * Math.atan(Math.exp(y)) - Math.PI / 2) * 180) / Math.PI,
        };
    }

    public transform(coord: DataPoint): DataPoint {
        let { lat, long } = coord;
        if (long > 180) {
            console.warn(`Mercator projection: Truncating illegal longitude to 180: long`);
            long = 180;
        }
        if (long < -180) {
            console.warn(`Mercator projection: Truncating illegal longitude to -180: long`);
            long = -180;
        }
        if (lat > 85) {
            console.warn(`Mercator projection: Truncating illegal latitude to 85: lat`);
            lat = 85;
        }
        if (lat < -85) {
            console.warn(`Mercator projection: Truncating illegal latitude to -85: lat`);
            lat = -85;
        }
        return {
            x: (Math.PI * long) / 180,
            y: Math.log(Math.tan(Math.PI / 4 + ((lat / 180) * Math.PI) / 2)),
        };
    }
}
