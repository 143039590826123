import each from 'lodash/each';

import type {
    InputDefinition,
    InputValue,
    InputValueSlice,
    PresetUtility,
    RootInputsDefinition,
    TokenName,
    TokenNamespace,
    TokenState,
} from '@splunk/dashboard-types';
import { DEFAULT_TOKEN_NAMESPACE } from '@splunk/dashboard-utils';
import type { DashboardDefinition } from '@splunk/dashboard-definition';
import { getTokenNamespace } from '../sagas/inputSaga';
import { isNullOrUndefined } from './isNullOrUndefined';

export const isInputEmpty = (value: unknown): boolean =>
    value === null ||
    value === undefined ||
    (typeof value === 'string' && value === '') ||
    (Array.isArray(value) && value.length === 0);

interface CreateInputStateInterface {
    inputDefs: RootInputsDefinition;
    currentInputState: InputValueSlice | null;
    tokens?: TokenState;
    preset?: PresetUtility;
    isReset?: boolean;
}

/**
 * compute new input state given the new input definition, for input has no changes, this is a noop.
 * removed input will be removed from state.
 * newly added input will be add to state with initial value computed.
 * @param {*} currentInputState
 * @param {Object[]} inputDefs
 * @param {Object} tokens object containing namespaces with their respective set tokens
 * @param {PresetUtility} preset
 * @param {Boolean} isReset if we're resetting we want to set the input to the tokenValue
 */
export const createInputState = ({
    currentInputState,
    inputDefs,
    tokens,
    preset,
    isReset = false, // If we're resetting it means we may want the value from the inputs submittedToken
}: CreateInputStateInterface): InputValueSlice => {
    const newState: InputValueSlice = {};
    each(inputDefs, ({ options = {}, type }, inputKey): void => {
        const input = preset ? preset.findInput(type) : null;
        let inputValue = currentInputState?.[inputKey]?.value;
        const {
            defaultValue,
            tokenNamespace = DEFAULT_TOKEN_NAMESPACE,
            token: tokenName,
        } = options;

        // If there is a token binding present, set input value from that binding
        const tokenValue =
            input && input.tokensToValue
                ? input.tokensToValue({
                      tokens,
                      tokenName,
                      tokenNamespace,
                  })
                : tokenName && tokens?.[tokenNamespace]?.[tokenName];

        if ((isInputEmpty(inputValue) || isReset) && tokenValue) {
            // if input is empty or we're resetting AND there is a token binding for the input's token
            inputValue = tokenValue;
        } else if (
            isInputEmpty(inputValue) &&
            // Ignore empty string defaults but permit defaults like false or 0
            !isNullOrUndefined(defaultValue) &&
            defaultValue !== ''
        ) {
            // if there is no token binding, use default if it exists
            inputValue = defaultValue;
        }
        newState[inputKey] = {
            value: inputValue,
        };
    });
    return newState;
};

/**
 * Compute the new state given a new value triggered from input
 * @method createNextState
 * @param {*} currentState
 * @param {*} value
 * @param {Object} [inputDef={}]
 * @param {Object} [inputDef.options={}]
 */
export const createNextState = ({
    value,
    inputDef: { options = {} },
}: {
    value?: string | string[] | number;
    inputDef: InputDefinition;
}): InputValue => {
    let nextvalue = value;
    if (isInputEmpty(nextvalue) && options.defaultValue != null) {
        nextvalue = options.defaultValue;
    }
    return {
        value: nextvalue,
    };
};

export interface SagaContextType {
    preset: PresetUtility;
}
/**
 * Compute tokens to unset from list of inputs
 * @method getTokensToUnset
 * @param {Object[]} inputIds
 * @param {Object} definition
 * @param {Object} sagaContext
 * @param {*} currentInputState
 */
export const getTokensToUnset = ({
    inputIds,
    definition,
    sagaContext,
    currentInputState,
}: {
    inputIds: string[];
    definition: DashboardDefinition;
    sagaContext: SagaContextType;
    currentInputState: InputValueSlice;
}) => {
    const tokensToUnset: Record<TokenNamespace, TokenName[]> = {};
    inputIds.forEach((inputId) => {
        const inputDef = definition.getInput(inputId);
        if (inputDef) {
            const { type, options = {} } = inputDef;
            const { token: defaultTokenName } = options;
            const tokenNamespace = getTokenNamespace(inputDef);
            tokensToUnset[tokenNamespace] ??= [];
            const input = sagaContext.preset?.findInput(type);

            // run the inputs valueToTokens to see if multiple tokens
            // were generated, we'll have to remove them all.
            if (currentInputState && input && input.valueToTokens) {
                const lastValue = currentInputState[inputId]?.value;
                if (lastValue) {
                    const tokens = input.valueToTokens(lastValue, options);
                    tokensToUnset[tokenNamespace].push(...Object.keys(tokens));
                }
            } else if (defaultTokenName) {
                tokensToUnset[tokenNamespace].push(defaultTokenName);
            }
        }
    });

    return tokensToUnset;
};
