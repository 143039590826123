import type { TelemetryAPI, EmittableEvent } from '@splunk/dashboard-telemetry';
import type {
    AbsoluteLayoutOptions,
    AbsoluteLayoutStructure,
    GridLayoutStructure,
} from '@splunk/dashboard-types';

/**
 *
 */
abstract class BaseLayoutApi {
    telemetry?: TelemetryAPI;

    emitTelemetry = (payload: EmittableEvent): boolean => {
        if (!this.telemetry) {
            return false;
        }

        this.telemetry.emit({
            source: 'layoutApi',
            ...payload,
        });

        return true;
    };

    /**
     * add new layout item
     */
    abstract addLayoutItem(
        ...args: unknown[]
    ): Promise<AbsoluteLayoutStructure> | GridLayoutStructure;

    /**
     * copies item w/h and position with an offset
     */
    abstract cloneLayoutItems({
        from,
        to,
    }: {
        from: string[];
        to: string[];
    }): Promise<AbsoluteLayoutStructure> | GridLayoutStructure;

    /**
     * remove an item from layout
     */
    abstract removeLayoutItems(
        vizIds: string[]
    ): Promise<AbsoluteLayoutStructure> | GridLayoutStructure;

    /**
     * get dashboard canvas dom element
     */
    abstract getCanvasDomElement(): HTMLElement;

    /**
     * compute snapshot of this layout
     */
    abstract snapshot(): AbsoluteLayoutOptions;
}

export default BaseLayoutApi;
