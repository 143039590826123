import { useMemo, useRef, useCallback, useState } from 'react';
import type {
    AbsoluteLayoutStructure,
    DataSourceBindingMap,
} from '@splunk/dashboard-types';
import { useSearchContext } from '@splunk/dashboard-context';
import { useSelector, selectDefinition } from '@splunk/dashboard-state';
import { debounce, isEqual } from 'lodash';
import {
    useSubscribeToSearches,
    type DataSources,
} from './useSubscribeToSearches';

const initialState: DataSources = {};
const initialBindings: DataSourceBindingMap = {};
// TODO: nail down the best timeout
const throttleTimeout = 500;

export const useBatchSubscribeToSearches = ({
    consumerId,
}: {
    consumerId: string;
}): DataSources => {
    const definition = useSelector(selectDefinition);
    const prevBindings = useRef<DataSourceBindingMap>(initialBindings);
    const searchApi = useSearchContext();
    const [throttledResults, setResults] = useState<DataSources>(initialState);

    // Update less frequently
    const setThrottledResults = useMemo(
        () =>
            debounce(setResults, throttleTimeout, { maxWait: throttleTimeout }),
        []
    );

    // dsBindings are { viz1: 'viz1PrimaryDS', input1: 'input1PrimaryDS' }
    const dsBindings = useMemo<DataSourceBindingMap>(() => {
        const bindings: DataSourceBindingMap = {};
        // get visualizations, inputs, layoutStructure from this instead of passing structure in.
        const {
            inputs,
            visualizations,
            layout: { structure = [] } = {},
        } = definition;

        if (!Array.isArray(structure)) {
            return prevBindings.current;
        }

        // naively casting as we only expect this to be used in our layouts
        (structure as AbsoluteLayoutStructure).forEach(({ item, type }) => {
            const primaryDS =
                type === 'input'
                    ? inputs?.[item]?.dataSources?.primary
                    : visualizations?.[item]?.dataSources?.primary;
            if (primaryDS) {
                bindings[item] = primaryDS;
            }
        });

        // prevent definition and layout changes from creating a new binding object unless they are actually different.
        if (!isEqual(bindings, prevBindings.current)) {
            prevBindings.current = bindings;
        }

        return prevBindings.current;
    }, [definition]);

    // Remap bindings in subscriptions to utilize existing searches
    const subscribeFn = useCallback<typeof searchApi.subscribe>(
        ({ dsId, consumerId: subscriberId, bindingType, onUpdate }) =>
            searchApi.subscribe({
                dsId,
                consumerId: bindingType,
                bindingType: 'primary',
                subscriberId,
                onUpdate,
            }),
        [searchApi]
    );

    // fetch results
    const { dataSources } = useSubscribeToSearches({
        consumerId,
        bindings: dsBindings,
        subscribeFn,
    });

    // throttle return values
    setThrottledResults(dataSources);

    return throttledResults;
};
