import type {
    AbsoluteBlockItem,
    AbsolutePosition,
    GridLayoutStructure,
} from '@splunk/dashboard-types';
import type { SplitGrid, SplitGridEntry } from './gridOrderUtils';
import { splitGrid, translateGridSplit } from './splitGridStructure';

type ContainingRowColumn = SplitGridEntry | undefined;

type FindContainerFn = (args: {
    splits: SplitGrid;
    itemPosition: AbsolutePosition;
}) => ContainingRowColumn;

/**
 * Internal utility for finding a row split which contains a given item
 * @param {SplitGrid} param0.splits A grid structure split by rows
 * @param {AbsolutePosition} param0.itemPosition A block item position describing what the target row should contain
 * @returns {ContainingRowColumn} Grid row which contains the target item
 */
const findContainingRow: FindContainerFn = ({ splits, itemPosition }) => {
    const edgeTop = itemPosition.y;
    const edgeBottom = itemPosition.y + itemPosition.h;

    return splits.find(
        ({ origin, content }) =>
            // Find the first row split which starts above the item in question and ends below it
            origin.y <= edgeTop && origin.y + content.height >= edgeBottom
    );
};

/**
 * Internal utility for finding a column split which contains a given item
 * @param {SplitGrid} param0.splits A grid structure split by columns
 * @param {AbsolutePosition} param0.itemPosition A block item position describing what the target column should contain
 * @returns {ContainingRowColumn} Grid column which contains the target item
 */
const findContainingColumn: FindContainerFn = ({ splits, itemPosition }) => {
    const edgeLeft = itemPosition.x;
    const edgeRight = itemPosition.x + itemPosition.w;

    return splits.find(
        ({ origin, content }) =>
            // Find the first column split which starts before the item in question and ends after it
            origin.x <= edgeLeft && origin.x + content.width >= edgeRight
    );
};

/**
 * Internal utility for recursively searching for the smallest row/column containing a target item
 * @param {FindContainerFn} param0.findContainerFn A function for filtering the split grid structure
 * @param {AbsoluteBlockItem} param0.itemToContain The block item which should be contained
 * @param {ContainingRowColumn} [param0.previousResult] The previous result. When the base case is reached (no container found, or the container contains a single item) then the previous value is returned
 * @param {GridLayoutStructure} param0.structure The layout structure to search. The structure origin must be (0, 0)
 * @returns {ContainingRowColumn} The smallest grid row or column which contains the target item, but is not just the target item
 */
const getContainingRowColumnRecursive = ({
    findContainerFn,
    itemToContain,
    previousResult,
    structure,
}: {
    findContainerFn: FindContainerFn;
    itemToContain: AbsoluteBlockItem;
    previousResult?: ContainingRowColumn;
    structure: GridLayoutStructure;
}): ContainingRowColumn => {
    const splittingByRows = findContainerFn === findContainingRow;

    const splitGridInfo = splitGrid({
        layoutStructure: structure,
        idToRemove: itemToContain.item,
        splitConfig: splittingByRows ? 'rows' : 'columns',
    });

    // When finding the next container use the item position from splitGrid
    // because the layout structure is translated and itemToContain.position
    // can become outdated as recursion occurs.
    const container = findContainerFn({
        splits: splittingByRows
            ? splitGridInfo.splitByRows
            : splitGridInfo.splitByColumns,
        itemPosition: splitGridInfo.itemToRemove.position,
    });

    if (!container || container.content.items.length <= 1) {
        // If no container was found, or the container is only one element in size
        // (it only contains the target item) then return the previous result
        return previousResult;
    }

    // The methods called in splitGrid require the grid have (0, 0) for the origin
    // so during processing the container will be translated to that origin and
    // then restored in the final return statement
    const containerTranslation = {
        x: -container.origin.x,
        y: -container.origin.y,
    };

    const translatedContainer = translateGridSplit(
        container,
        containerTranslation
    );

    // If the current recursion is looking for a containing row then the next
    // call should look for a containing column. Else look for a containing row.
    const nextFindContainerFn = splittingByRows
        ? findContainingColumn
        : findContainingRow;

    // Recurse until the base case is reached and then revert the container translation
    return translateGridSplit(
        getContainingRowColumnRecursive({
            structure: translatedContainer.content.items,
            itemToContain,
            findContainerFn: nextFindContainerFn,
            previousResult: translatedContainer,
        }) as SplitGridEntry,
        container.origin
    );
};

/**
 * Get the smallest row or column (not necessarily full-width or full-height) which
 * contains a specified item, but is not solely the specified item. If no such container
 * is found then undefined is returned
 * @param {GridLayoutStructure} param0.structure The layout structure to be searched
 * @param {AbsoluteBlockItem} param0.itemToContain The block item to be contained by the resulting row/column
 * @returns The smallest row or column containing the target item and at least one other block item, or undefined
 */
export const getContainingRowColumn = ({
    structure,
    itemToContain,
}: {
    structure: GridLayoutStructure;
    itemToContain: AbsoluteBlockItem;
}): ContainingRowColumn =>
    getContainingRowColumnRecursive({
        structure,
        itemToContain,
        findContainerFn: findContainingRow,
    });
