import { baseInputSchema } from '../components/BaseInput';

const commonProperties = {
    ...baseInputSchema,
    items: {
        description: 'Specify the label text that appears below the value.',
        anyOf: [
            {
                type: 'array',
                items: {
                    type: 'object',
                    properties: {
                        label: {
                            description:
                                'The text displayed in the dropdown menu',
                            type: 'string',
                            minLength: 1,
                            pattern: '.*\\S.*',
                        },
                        value: {
                            description:
                                'The value used when the menu item is selected',
                            type: 'string',
                            minLength: 1,
                            pattern: '.*\\S.*',
                        },
                    },
                    required: ['value'],
                },
            },
            {
                type: 'string',
                pattern: '^>.*',
            },
        ],
    },
    clearDefaultOnSelection: {
        description: 'Clear the default value when selecting another value.',
        type: 'boolean',
        default: true,
    },
};

export default {
    // extend means we are defining not just the properties, but the entire option schema itself
    extend: {
        type: 'object',
        anyOf: [
            {
                properties: {
                    selectFirstSearchResult: {
                        description:
                            'Select the first result of an attached search as default.',
                        type: 'boolean',
                        default: false,
                        const: false,
                    },
                    defaultValue: {
                        description:
                            'Default value of the input when no value is specified',
                        anyOf: [
                            { type: 'string' },
                            { type: 'array', items: { type: 'string' } },
                        ],
                    },
                    ...commonProperties,
                },
                additionalProperties: false,
            },
            {
                properties: {
                    selectFirstSearchResult: {
                        description:
                            'Select the first result of an attached search as default.',
                        type: 'boolean',
                        default: false,
                        const: true,
                    },
                    // setting a property to false means it is disallowed
                    defaultValue: false,
                    ...commonProperties,
                },
                additionalProperties: false,
            },
        ],
    },
};
