import { memoize } from 'lodash';
import { DashboardDefinition } from '@splunk/dashboard-definition';

export const getDefaultOptionsForSearchQuery = memoize(
    (definition) => {
        const def = DashboardDefinition.fromJSON(definition);

        const timeRange = def.getDefaultTimeRangeForNewSearch();

        if (timeRange.earliest || timeRange.latest) {
            return {
                queryParameters: {
                    ...timeRange,
                },
            };
        }

        return {};
    },
    // note: this is the resolver function that uses a dirty trick to avoid re-creating the object
    (definition) => {
        const def = DashboardDefinition.fromJSON(definition);

        const timeRange = def.getDefaultTimeRangeForNewSearch();
        return JSON.stringify(timeRange);
    }
);
