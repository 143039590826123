import * as React from 'react';
import { _ } from '@splunk/ui-utils/i18n';
import SVG from '@splunk/react-icons/SVGEnterprise';

const ChoroplethSvg = (props: React.ComponentProps<typeof SVG>): React.ReactElement<SVGElement> => (
    <SVG
        screenReaderText={_('SVG Choropleth')}
        viewBox="0 0 150 100"
        fill="currentColor"
        {...props}
        style={{ ...props.style, overflow: 'hidden' }}
    >
        <path d="M5 25.4684H11.7534V15.3671H45.3858V24.9873H45.3666V47.1235H51.1676V55.8972H23.4228V44.6992H5V25.4684ZM131.968 87.519V48.9129H105.532V54.8101H77.6329V62.5063H95.9114V87.519H131.968ZM5 65.2673H17.9681V88H51.1772V56.8689H22.4608V45.6709H5V65.2673V65.2673ZM132.439 47.9509H144.965V25.9494H128.13V12H105.541V47.9509H132.449H132.439ZM94.9494 63.4684H76.6709V34.1362H70.3889V19.6962H59.3448V25.4684H46.3382V46.1615H52.1392V88H81.9813V75.4937H94.9494V63.4684ZM104.57 53.8481V12H71.3605V19.6962H71.3413V33.1742H77.6233V53.8481H104.56H104.57Z" />
    </SVG>
);

export default ChoroplethSvg;
