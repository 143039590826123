import jsCharting from '@splunk/charting-bundle';

interface Point {
    /**
     * Valid xValue of the chart
     *
     * @type {string|number}
     * @memberof Point
     */
    xValue?: string | number;

    /**
     * Valid seriesName for a chart
     *
     * @type {string}
     * @memberof Point
     */
    seriesName?: string;
}

export class ChartingApiFunctional {
    // eslint-disable-next-line no-useless-constructor
    constructor(private chartRef: any) {}

    /**
     * Allows users to programmatically highlight a point on the chart
     *
     * @memberof ChartingApiFunctional
     */
    highlight({ xValue, seriesName }: Point = { xValue: '', seriesName: '' }): void {
        this.getChart().highlight(xValue, seriesName);
    }

    /**
     * Allows users to programmatically unhighlight all points on the chart
     *
     * @memberof ChartingApiFunctional
     */
    unHighlight(): void {
        this.getChart().unHighlight();
    }

    // returns chart object
    getChart(): jsCharting {
        return this.chartRef?.current;
    }
}
