// precedences refer to the order in which a given palette would insert colors
const precedences = {
    categorical: [
        [0, 1],
        [0, 1, 2],
        [0, 1, 2, 3],
        [0, 1, 2, 3, 4],
        [0, 1, 2, 3, 4, 5],
        [0, 1, 2, 3, 4, 5, 6],
    ],
    divergent: [
        [1, 5],
        [1, 3, 5],
        [1, 2, 4, 5],
        [1, 2, 3, 4, 5],
        [0, 1, 2, 4, 5, 6],
        [0, 1, 2, 3, 4, 5, 6],
    ],
    sequential: [
        [0, 6],
        [0, 3, 6],
        [0, 2, 4, 6],
        [0, 1, 3, 5, 6],
        [0, 1, 2, 4, 5, 6],
        [0, 1, 2, 3, 4, 5, 6],
    ],
};

const categoricalColors = {
    swatches: [
        ['#9980FF', '#45D4BA', '#FB865C', '#66AAF9', '#E85B79', '#88EE66', '#F0B000'],
        ['#5599BE', '#FB9DFB', '#00BBEE', '#EE3399', '#9980FF', '#5FBF7F', '#F58B00'],
    ],
    precedence: precedences.categorical,
};

const categoricalColorsDark = {
    swatches: [
        ['#5C33FF', '#207865', '#AD3F20', '#003E80', '#78062A', '#2f8811', '#555555'],
        ['#1F4D5B', '#CC0AD6', '#017FA2', '#D81E5B', '#621FFF', '#348350', '#555555'],
    ],
    precedence: precedences.categorical,
};

const divergentColors = {
    swatches: [
        ['#FF5E5E', '#F98C83', '#F4BAA9', '#EEE8CE', '#A1D59C', '#55C169', '#08AE37'],
        ['#FF5E5E', '#F98C83', '#F4BAA9', '#EEE8CE', '#ADCCDD', '#6BB3EE', '#2A99FF'],
        ['#9156DD', '#C093F9', '#D7BEE4', '#EEE8CE', '#9ADEC4', '#45D4BA', '#14846C'],
        ['#DA4325', '#E3723A', '#ECA14E', '#EEE8CE', '#5CC3AF', '#2EA39B', '#008287'],
    ],
    precedence: precedences.divergent,
};

const divergentColorsDark = {
    swatches: [
        ['#D41F1F', '#9E2520', '#692A21', '#333022', '#284D27', '#1C6B2D', '#118832'],
        ['#D41F1F', '#9E2520', '#692A21', '#333022', '#224468', '#115BAD', '#0070F3'],
        ['#8747DA', '#602CA1', '#422879', '#333022', '#24551F', '#277C52', '#299986'],
        ['#D54124', '#9F3B23', '#693623', '#333022', '#264C44', '#1A6765', '#0D8387'],
    ],
    precedence: precedences.divergent,
};

const sequentialColors = {
    swatches: [
        ['#FE3A3A', '#FF7149', '#FFA857', '#FFD442', '#C3CC33', '#2EB82E', '#088F44'],
        ['#EEE8CE', '#E8C7CE', '#E1A6CD', '#DB86CD', '#D465CD', '#CE44CC', '#C723CC'],
        ['#EEE8CE', '#B6ECD4', '#7EEFDA', '#45D4BA', '#35B9A0', '#249F86', '#14846C'],
        ['#EEE8CE', '#F5CEBF', '#FCB4B0', '#F99C96', '#F6847C', '#DF564D', '#DD2E2E'],
        ['#EEE8CE', '#F2DD88', '#FFC355', '#FF9D66', '#FF7777', '#EE4477', '#C723CC'],
        ['#EEE8CE', '#E7E755', '#A3E052', '#0AD647', '#00BBBB', '#1182F3', '#6666DD'],
    ],
    precedence: precedences.sequential,
};

const sequentialColorsDark = {
    swatches: [
        ['#D41F1F', '#D94E17', '#D97A0D', '#CBA700', '#9D9F0D', '#669922', '#118832'],
        ['#252214', '#3D2830', '#562E4C', '#6F3468', '#873A83', '#A0409F', '#B846BB'],
        ['#252214', '#253223', '#244333', '#245442', '#246451', '#237561', '#238570'],
        ['#252214', '#442519', '#64271F', '#832A24', '#A0312E', '#BD3737', '#DA3B30'],
        ['#222233', '#4B1773', '#77136A', '#A81A45', '#D24620', '#D97A0D', '#CBA700'],
        ['#192E4D', '#005580', '#007575', '#118832', '#669922', '#9D9F0D', '#CBA700'],
    ],
    precedence: precedences.sequential,
};

const gradientFormatterDefaultColorSwatch = [
    '#7B56DB66',
    '#7B56DB7C',
    '#7B56DB92',
    '#7B56DBA8',
    '#7B56DBBD',
    '#7B56DBD3',
    '#7B56DB',
];

export const defaultPalettesConfig = {
    paletteTheme: 'DARK_COLORS',
    paletteEntry: {
        type: 'sequential',
        index: 0,
    },
};

const palettes = {
    DARK_COLORS: {
        _meta: {
            label: 'Dark Colors',
        },
        categorical: categoricalColorsDark,
        divergent: divergentColorsDark,
        sequential: sequentialColorsDark,
    },
    LIGHT_COLORS: {
        _meta: {
            label: 'Light Colors',
        },
        categorical: categoricalColors,
        divergent: divergentColors,
        sequential: sequentialColors,
    },
};

export const gradientFormatterPalettes = {
    DARK_COLORS: {
        ...palettes.DARK_COLORS,
        sequential: {
            ...sequentialColorsDark,
            swatches: [gradientFormatterDefaultColorSwatch, ...sequentialColorsDark.swatches],
        },
    },
    LIGHT_COLORS: {
        ...palettes.LIGHT_COLORS,
        sequential: {
            ...sequentialColors,
            swatches: [gradientFormatterDefaultColorSwatch, ...sequentialColors.swatches],
        },
    },
};

export default palettes;
