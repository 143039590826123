import type { VisualizationDefinition } from '@splunk/dashboard-types';
import config from '@splunk/visualizations/MarkerGauge.config';
import type { EncodingConfig } from '../encoding';
import { encodingToDynamicOptionsDSL } from '../encoding';
import {
    preserveDataSelectionForValueOption,
    removeInvalidOptions,
    renameVizOptions,
} from '../utils/migrationUtils';
import { consolidateDisplayOption } from '../utils/gauges';

const validOptions = {
    ...config.optionsSchema,
};

// old default configurations should be retained in migrated configuration
const defaultChanges = {
    gaugeRanges: [
        { from: 90, to: 100, value: '#CB3B43' },
        { from: 50, to: 90, value: '#F4DF7A' },
        { from: 0, to: 50, value: '#4BEBA8' },
    ],
    value: '> primary | seriesByIndex(0) | lastPoint()',
};

const optionRenames = {
    majorUnit: 'majorTickInterval',
    ranges: 'gaugeRanges',
};

export const migrateVizToSplunkMarkerGauge = (
    vizDefinition: VisualizationDefinition
): VisualizationDefinition => {
    const { options = {}, encoding = {}, ...otherDefinitionParts } = vizDefinition;
    const migratedVisualization = {
        options,
        ...otherDefinitionParts,
        type: 'splunk.markergauge',
        context: {},
    };

    const renamedOptions = renameVizOptions(options, optionRenames);

    const { options: dataOptions } = encodingToDynamicOptionsDSL(encoding as Record<string, EncodingConfig>);
    // viz.markergauge has behavior where if a column without a specific point (e.g. primary[1]) is selected,
    // the markerGauge will only display the first point of the column
    // the following logic accounts for this and appends firstPoint() to the DSL statement
    const originalGaugeValue = dataOptions.value;
    if (typeof originalGaugeValue === 'string') {
        dataOptions.value =
            preserveDataSelectionForValueOption(originalGaugeValue, 'firstPoint()') ?? originalGaugeValue;
    }

    const { showLabels, usePercentageRange, showValue, usePercentageValue } = renamedOptions;
    const labelDisplay = consolidateDisplayOption(showLabels as boolean, usePercentageRange as boolean);
    const valueDisplay = consolidateDisplayOption(showValue as boolean, usePercentageValue as boolean);

    migratedVisualization.options = {
        ...defaultChanges,
        ...renamedOptions,
        ...dataOptions,
        ...(typeof labelDisplay === 'string' && { labelDisplay }),
        ...(typeof valueDisplay === 'string' && { valueDisplay }),
    };

    // remove invalid options using the optionsSchema for splunk.markergauge as reference
    removeInvalidOptions(migratedVisualization.options, validOptions);

    return migratedVisualization;
};
