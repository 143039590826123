import type { DashboardJSON } from '@splunk/dashboard-types';
import Ajv from 'ajv';
import type { JSONSchemaType, ValidateFunction } from 'ajv';
import { memoize } from './memoize';

const ajv = new Ajv({
    allErrors: true,
    // This is to make sure the definition `defaults` can have both `properties` and `patternProperties`
    allowMatchingProperties: true,
    // todo: lots of viz schemas violate this rule, will fix them later
    allowUnionTypes: true,
    code: {
        // Don't optimize when web worker support isn't detected. All supported browser targets do support
        // web workers, so this likely indicates a test environment where the code optimization will error
        optimize: typeof Worker === 'undefined' ? false : 1,
    },
});

// We hash the string representation of the the schema to use as the memoize resolver.
export const memoizedSchemaCompile = memoize(
    (
        newSchema: JSONSchemaType<DashboardJSON>
    ): ValidateFunction<DashboardJSON> => {
        // Prevent warnings when adding a new schema with the same id
        if (newSchema?.$id && ajv.getSchema(newSchema.$id)) {
            ajv.removeSchema(newSchema.$id);
        }

        return ajv.compile(newSchema);
    },
    JSON.stringify
);
