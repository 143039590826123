import React from 'react';
import styled from 'styled-components';
import { pick } from '@splunk/themes';
import Switch from '@splunk/react-ui/Switch';
import type { SwitchClickHandler } from '@splunk/react-ui/Switch';
import Tooltip from '@splunk/react-ui/Tooltip';

const Container = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: flex-start;
    align-items: center;
`;

const PaddedSwitch = styled(Switch)`
    padding-right: ${pick({
        enterprise: '12px',
    })};
`;

interface CheckboxEditorType {
    onChange: SwitchClickHandler;
    label?: string;
    tooltipContent?: React.ReactNode;
    checked?: boolean;
    dataTest?: string;
}

export const CheckboxEditor = ({
    onChange,
    label,
    checked = false,
    tooltipContent = null,
    dataTest,
}: CheckboxEditorType) => {
    return (
        <Container data-test={dataTest}>
            <PaddedSwitch
                onClick={onChange}
                appearance="checkbox"
                selected={checked}
                inline
            >
                {!!label && label}
            </PaddedSwitch>
            {!!tooltipContent && (
                <Tooltip
                    openDelay={100}
                    closeDelay={500}
                    content={tooltipContent}
                    inline
                />
            )}
        </Container>
    );
};
