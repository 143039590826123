import { parse, dataContract } from '@splunk/visualization-encoding-parsers/ChoroplethParser';
import { _ } from '@splunk/ui-utils/i18n';
import Message from '@splunk/visualizations-shared/Message';
import * as React from 'react';
import { get, omit } from 'lodash';
import { enterprise } from '@splunk/visualization-themes/variables';
import { ChoroplethSvg as ChoroplethSvgIcon } from '@splunk/visualization-icons';
import { withTheme } from 'styled-components';
import { createProtocolHandlers } from '@splunk/visualizations/ChoroplethSvg';
import ImageContext from '@splunk/visualization-context/ImageContext';
import FeatureFlagContext from '@splunk/visualization-context/FeatureFlagContext';
import SvgChoroplethDynamic from '@splunk/react-visualizations/SvgChoroplethDynamic';
import { FileDownloader } from '@splunk/visualizations-shared/FileDownloader';
import { withBackgroundColor, withPlaceholder, withEventBlocker } from '../../utils/enhancer';
import IconPlaceholder from '../../common/IconPlaceholder';
import editor from './editorConfig';
import optionsSchema from './optionsSchema';
import VisualizationEvent from '../../common/VisualizationEvent';

export interface ChoroplethSvgProps {
    /**
     * true indicate the visualization is loading data
     */
    loading?: boolean;
    /**
     * display mode
     */
    mode?: string;
    /**
     * width in pixel or string, defaults to 100%
     */
    width?: any;
    /**
     * height in pixel or string
     */
    height?: any;
    /**
     * Custom style on visualization
     */
    style?: Record<string, unknown>;
    /**
     * visualization formatting options
     */
    options?: { svg: string; backgroundColor?: string };

    encoding?: Record<string, unknown>;
    /**
     * datasource state which include data and request params, object key indicate the datasource type.
     */
    dataSources?: Record<string, any>;
    /**
     * A callback to update formatting options
     */
    onOptionsChange?(): void;
    /*
     * Inform viz if there are handlers listening to events
     */
    hasEventHandlers?: boolean;
    /**
     * A callback to trigger event
     */
    onEventTrigger?(event: VisualizationEvent): void;
    /**
     * A callback to request new data with updated request params
     */
    onRequestParamsChange?(): void;
}

export class ChoroplethSvgComponent extends React.Component<ChoroplethSvgProps> {
    static dataContract = dataContract;

    static schema = optionsSchema;

    static editor = editor;

    static vizContract = {
        initialDimension: {
            width: 300,
            height: 300,
        },
    };

    render(): React.ReactElement {
        const {
            encoding,
            dataSources,
            options: { svg },
            width,
            height,
        } = this.props;

        if (!svg) {
            return (
                <Message
                    data-test="choropleth-svg-message"
                    width={width}
                    height={height}
                    message={_('No literal SVG or URL to SVG is provided')}
                />
            );
        }

        let data = { featureIDs: [], values: [], fill: [] };
        if (dataSources && get(dataSources, ['primary', 'data'])) {
            const dataFromEncoding = omit(parse(dataSources, encoding), '_meta');
            data = {
                featureIDs: dataFromEncoding.featureId || [],
                values: dataFromEncoding.value || [],
                fill: dataFromEncoding.fill || [],
            };
        }
        const dataBinding = [
            {
                xPath: "'//*[@id=\"'+featureIDs[$i]+'\"]/@fill'",
                value: 'fill[$i]',
            },
        ];

        return (
            <ImageContext.Consumer>
                {(imageContext: ImageContext): React.ReactElement => (
                    <FeatureFlagContext.Consumer>
                        {(featureFlagContext: FeatureFlagContext): React.ReactElement => (
                            <FileDownloader
                                url={svg}
                                protocolHandlers={createProtocolHandlers(featureFlagContext, imageContext)}
                                renderContent={(svgContent: string): React.ReactElement => {
                                    const options = { svg: svgContent, data, dataBinding, width, height };
                                    return <SvgChoroplethDynamic {...options} />;
                                }}
                            />
                        )}
                    </FeatureFlagContext.Consumer>
                )}
            </ImageContext.Consumer>
        );
    }
}

export const shouldShowPlaceholder = (props: ChoroplethSvgProps): boolean => {
    const {
        dataSources,
        options: { svg },
    } = props;
    return !get(dataSources, ['primary']) && !svg;
};

export default withTheme(
    withBackgroundColor({
        defaultBackgroundColor: enterprise.defaultBackgroundColor,
        enableBackgroundColorOption: true,
    })(
        withPlaceholder({
            placeholder: (
                <IconPlaceholder icon={<ChoroplethSvgIcon data-test="ChoroplethPlaceholderIcon" />} />
            ),
            shouldShowPlaceholder,
        })(withEventBlocker(ChoroplethSvgComponent))
    )
);
