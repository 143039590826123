import { get, isEqual } from 'lodash';
import { getDataSourceStateInfo, DataSourceState } from '@splunk/visualizations-shared/dataSourceUtils';
import { isArrayOfColors, sanitizeArrayOfColors } from '@splunk/visualizations-shared/colorUtils';
import withDashboardViz, { DashboardVizOpts } from '../common/withDashboardViz';
import PureSankey from './PureSankey/PureSankey';
import config from './config';
import { DataSource } from '../common/interfaces/DataSource';

/**
 * @method showPlaceHolderIcon
 * @param {Object} dataSources
 * @returns {Boolean} return true if loading, error, non existent state or without columns in dataSources
 */
export const showPlaceHolderIcon = dataSources => {
    const columns = get(dataSources, 'primary.data.columns', undefined);
    const noColumns = columns === undefined;
    const dataSourceState = getDataSourceStateInfo(dataSources);
    return (
        dataSourceState === DataSourceState.ERROR ||
        dataSourceState === DataSourceState.LOADING ||
        dataSourceState === DataSourceState.NONEXISTENT ||
        noColumns
    );
};

export const computeVizProps = ({ themeFunc, dataSources, linkValues, seriesColors, linkColors }) => {
    // Compute all themeColor variables needed for PureSankey
    const themeColorVariables = [
        'defaultBackgroundColor',
        'nodeTextColor',
        'tooltipRowColor',
        'tooltipLinkHeaderColor',
        'tooltipHeaderColor',
    ];
    const themeColors: Record<string, unknown> = {};
    themeColorVariables.forEach(variable => {
        themeColors[variable] = themeFunc(variable);
    });

    // Sanitize linkColors prop
    // eslint-disable-next-line no-param-reassign
    linkColors = sanitizeArrayOfColors(linkColors);

    // Sanitize seriesColors prop
    // (Note:- This is handled outside SanitizeProps because it would impact seriesColors option used in splunk-charting viz as the types are different.
    // The type used in splunk-charting viz expects a string of comma separated colors, but here the type is an array of string)
    // eslint-disable-next-line no-param-reassign
    seriesColors = isArrayOfColors(seriesColors) ? seriesColors : undefined;

    // Compute the columns props and linkValueField props for pure Sankey
    // The columns props is computed as [primary.data.columns[0], primary.data.columns[1], linkValues].
    // The source and target columns are the default as columns[0] and columns[1] respectively
    // The linkValueField props is the fieldName which is set as the source of linkValues
    let columnsFromDataSource = [];
    let linkValueField = '';
    if (
        dataSources?.primary?.data?.columns &&
        dataSources?.primary?.data?.columns?.length >= 2 &&
        Array.isArray(linkValues)
    ) {
        const { columns } = dataSources.primary.data;
        columnsFromDataSource = columns ? [columns[0], columns[1], linkValues] : null;
        const linkIndex = columns.findIndex(col => isEqual(col, linkValues));
        if (dataSources?.primary?.data?.fields) {
            const linkField = dataSources?.primary?.data?.fields[linkIndex];
            if (typeof linkField === 'object' && linkField !== null) {
                linkValueField = linkField.name;
            } else {
                linkValueField = linkField ?? '';
            }
        }
    }
    return { ...themeColors, columns: columnsFromDataSource, linkValueField, seriesColors, linkColors };
};

const Sankey = withDashboardViz({
    ReactViz: PureSankey,
    vizConfig: config,
    computeVizProps,
    useIconPlaceholder: (dataSources: { [name: string]: DataSource }) => showPlaceHolderIcon(dataSources),
} as DashboardVizOpts);
const { themes } = config;
export default Sankey;
export { themes, config, PureSankey };
