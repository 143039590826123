import { useEffect, useMemo } from 'react';
import { usePreset } from '@splunk/dashboard-context';
import { selectDefinition, useSelector } from '@splunk/dashboard-state';
import { DashboardDefinition } from '@splunk/dashboard-definition';
import { findInitialRequestParams } from '@splunk/datasource-utils';
import type {
    RequestParams,
    DataSourceBindingMap,
    BindingType,
} from '@splunk/dashboard-types';
import usePrevious from './usePrevious';

type UseHandleItemTypeChangeTypes = ({
    consumerId,
    bindings,
    updateRequestParams,
    clearResults,
}: {
    consumerId: string;
    bindings: DataSourceBindingMap;
    updateRequestParams: (
        bindingType: BindingType,
        newRequestParams: RequestParams
    ) => void;
    clearResults: (bindingType: BindingType) => void;
}) => void;

export const useHandleItemTypeChange: UseHandleItemTypeChangeTypes = ({
    consumerId,
    bindings,
    updateRequestParams,
    clearResults,
}) => {
    const preset = usePreset();
    const definition = useSelector(selectDefinition);
    const definitionClass = useMemo(
        () => DashboardDefinition.fromJSON(definition),
        [definition]
    );
    const itemType = definitionClass.getItemPresetType(consumerId);
    const prevItemType = usePrevious(itemType);

    useEffect(() => {
        if (prevItemType && itemType !== prevItemType) {
            Object.keys(bindings).forEach((bindingType) => {
                // Clear any existing data so that we are ready for the new data
                clearResults(bindingType);

                const itemLayoutType = definitionClass.getItemType(consumerId);
                const newRequestParams = findInitialRequestParams({
                    bindingType,
                    consumerModule: preset.findItem(
                        itemType as string,
                        itemLayoutType
                    ),
                });
                updateRequestParams(bindingType, newRequestParams);
            });
        }
    }, [
        bindings,
        clearResults,
        consumerId,
        definitionClass,
        itemType,
        preset,
        prevItemType,
        updateRequestParams,
    ]);
};
