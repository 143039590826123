import React, {
    createContext,
    useContext,
    useEffect,
    useMemo,
    useRef,
    useCallback,
} from 'react';
import { DashboardDefinition } from '@splunk/dashboard-definition';
import {
    SearchModule as DefaultSearchModule,
    type ConstructableSearchModule,
} from '@splunk/dashboard-search';
import {
    useSelector,
    selectDataSourceDefinitions,
    selectVisualizations,
    selectInputs,
} from '@splunk/dashboard-state';
import { noop } from '@splunk/dashboard-utils';
import { findInitialRequestParams } from '@splunk/datasource-utils';
import type { BindingType } from '@splunk/dashboard-types';
import { useDataSourceRegistry } from './DataSourceRegistryContext';
import { usePreset } from './PresetContext';
import {
    useManageSearches,
    forEachSearchBinding,
} from './hooks/useManageSearches';
import {
    getSearchesToExecute as defaultGetSearchesToExecute,
    UnboundSmartSourceConsumerId,
    UnboundSmartSourceRequestParams,
    type GetSearchesToExecuteType,
} from './utils';
import { useFeatureFlags } from './DashboardContext';
import { SearchProvider } from './SearchProvider';
import { usePrevious } from './hooks';

export interface SearchContextProps {
    children: React.ReactNode;
    getSearchesToExecute?: GetSearchesToExecuteType;
    searchModule?: ConstructableSearchModule;
}

export interface SearchContextValue {
    createSearchAndSubscribe: SearchProvider['createSearchAndSubscribe'];
    refresh: SearchProvider['refresh'];
    subscribe: SearchProvider['subscribe'];
    updateRequestParams: SearchProvider['updateRequestParams'];
}

const DefaultSearchContextValue: SearchContextValue = {
    subscribe: () => noop,
    refresh: noop,
    updateRequestParams: noop,
    createSearchAndSubscribe: () => noop,
};

export const SearchContext = createContext<SearchContextValue>(
    DefaultSearchContextValue
);

export const SearchContextProvider = ({
    children,
    getSearchesToExecute = defaultGetSearchesToExecute,
    searchModule: SearchModule = DefaultSearchModule as NonNullable<
        SearchContextProps['searchModule']
    >,
}: SearchContextProps) => {
    const featureFlags = useFeatureFlags();

    const preset = usePreset();
    const dataSourceRegistry = useDataSourceRegistry();
    const dataSourceDefinitions = useSelector(selectDataSourceDefinitions);
    const visualizations = useSelector(selectVisualizations);
    const inputs = useSelector(selectInputs);

    const searchProviderRef = useRef<SearchProvider>();
    if (!searchProviderRef.current) {
        searchProviderRef.current = new SearchProvider({
            searchModule: new SearchModule({ registry: dataSourceRegistry }),
        });
    }

    const { definition, definitionClass } = useMemo(() => {
        const def = {
            dataSources: dataSourceDefinitions,
            visualizations,
            inputs,
        };
        return {
            definition: def,
            definitionClass: DashboardDefinition.fromJSON(def),
        };
    }, [dataSourceDefinitions, inputs, visualizations]);

    const searchesToExecute = useMemo(
        () =>
            getSearchesToExecute({
                dataSources: definition.dataSources,
                inputs: definition.inputs,
                visualizations: definition.visualizations,
                featureFlags,
            }),
        [
            definition.dataSources,
            definition.inputs,
            definition.visualizations,
            featureFlags,
            getSearchesToExecute,
        ]
    );

    const { searchesToCreate, searchesToUpdate, searchesToRemove } =
        useManageSearches({
            searchesToExecute,
            dataSourceDefinitions,
        });

    const getInitialRequestParams = useCallback(
        ({
            consumerId,
            bindingType,
        }: {
            consumerId: string;
            bindingType: BindingType;
        }) => {
            if (consumerId === UnboundSmartSourceConsumerId) {
                return UnboundSmartSourceRequestParams;
            }

            const itemPresetType =
                definitionClass.getItemPresetType(consumerId);

            if (!itemPresetType) {
                // This is very unlikely. Not having a type would break a lot more than just executing a search
                return {};
            }
            const itemLayoutType = definitionClass.getItemType(consumerId);
            const initialRequestParams = findInitialRequestParams({
                bindingType,
                consumerModule: preset.findItem(itemPresetType, itemLayoutType),
                options: definitionClass.getItemOptions(consumerId),
            });

            return initialRequestParams;
        },
        [definitionClass, preset]
    );

    /* TODO: we probably want to avoid creating a search if it's already created
     * this wasn't possible before, but now someone could use `createSearchAndSubscribe` to create a search
     * that will then become part of the definition. Impossible for now unless we allow users to manually call
     * createSearchAndSubscribe directly
     */
    // deal with changes to `searchesToCreate`
    const prevSearchesToCreate = usePrevious(searchesToCreate);
    if (prevSearchesToCreate !== searchesToCreate) {
        forEachSearchBinding(
            searchesToCreate,
            ({ dsId, consumerId, bindingTypes }) => {
                bindingTypes.forEach((bindingType) => {
                    searchProviderRef.current?.createSearch({
                        dsId,
                        consumerId,
                        bindingType,
                        initialRequestParams: getInitialRequestParams({
                            consumerId,
                            bindingType,
                        }),
                    });
                });
            }
        );
    }

    const prevSearchesToUpdate = usePrevious(searchesToUpdate);
    if (prevSearchesToUpdate !== searchesToUpdate) {
        forEachSearchBinding(
            searchesToUpdate,
            ({ dsId, consumerId, bindingTypes }) => {
                bindingTypes.forEach((bindingType) => {
                    searchProviderRef.current?.updateSearch({
                        dsId,
                        consumerId,
                        bindingType,
                        initialRequestParams: getInitialRequestParams({
                            consumerId,
                            bindingType,
                        }),
                    });
                });
            }
        );
    }

    // deal with changes to `searchesToRemove`
    const prevSearchesToRemove = usePrevious(searchesToRemove);
    if (prevSearchesToRemove !== searchesToRemove) {
        forEachSearchBinding(
            searchesToRemove,
            ({ dsId, consumerId, bindingTypes }) => {
                bindingTypes.forEach((bindingType) => {
                    searchProviderRef.current?.removeSearch({
                        dsId,
                        consumerId,
                        bindingType,
                    });
                });
            }
        );
    }

    // cleanup all subscriptions on unmount of SearchProvider
    // NOTE: there are cases where this useEffect cleanup will run before the useEffect cleanups of child
    //  components. Therefore, whatever cleanup is done in children must not depend on something that this cleanup could remove.
    useEffect(() => {
        return () => {
            searchProviderRef.current?.teardown();
        };
    }, []);

    const searchApi = useMemo<SearchContextValue>(() => {
        // cast because TS doesn't recognize that this will always have a value
        const searchProvider = searchProviderRef.current as SearchProvider;

        return {
            createSearchAndSubscribe: (args) =>
                searchProvider.createSearchAndSubscribe(args),
            subscribe: (args) => searchProvider.subscribe(args),
            refresh: (args) => searchProvider.refresh(args),
            updateRequestParams: (args) =>
                searchProvider.updateRequestParams(args),
        };
    }, []);

    return (
        <SearchContext.Provider value={searchApi}>
            {children}
        </SearchContext.Provider>
    );
};

export const useSearchContext = () => useContext(SearchContext);

export default SearchContext;
