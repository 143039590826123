import React, { createContext, useContext, useEffect, useMemo } from 'react';
import ApiRegistry from './registries/extensions/ApiRegistry';

const defaultRegistry = new ApiRegistry();
const ApiRegistryContext = createContext(defaultRegistry);

export const ApiRegistryContextProvider = ({
    children,
}: {
    children: React.ReactNode;
}) => {
    const registry = useMemo(() => new ApiRegistry(), []);

    // Cleanup anything left over before unmounting
    useEffect(() => {
        // teardown is currently non-destructive
        return () => registry.teardown();
    }, [registry]);

    return (
        <ApiRegistryContext.Provider value={registry}>
            {children}
        </ApiRegistryContext.Provider>
    );
};

// Can't run cleanup here or every component unmount will eliminate all the other component apis
export const useApiRegistry = () => {
    return useContext(ApiRegistryContext);
};

export default ApiRegistryContext;
