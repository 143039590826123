import * as React from 'react';
import { _ } from '@splunk/ui-utils/i18n';
import SVG from '@splunk/react-icons/SVGEnterprise';

const SingleValueRadial = (props: React.ComponentProps<typeof SVG>): React.ReactElement<SVGElement> => (
    <SVG screenReaderText={_('Single Value Radial')} fill="currentColor" viewBox="0 0 150 100" {...props}>
        <g>
            <path d="M15.8839 60.607C12.6595 68.3913 11 76.7344 11 85.16H5C5 75.9465 6.81474 66.8231 10.3406 58.3109C13.8665 49.7987 19.0344 42.0643 25.5494 35.5494C32.0643 29.0344 39.7987 23.8665 48.3109 20.3406C56.8231 16.8147 65.9465 15 75.16 15C84.3736 15 93.4969 16.8147 102.009 20.3406C110.521 23.8665 118.256 29.0344 124.771 35.5494C131.286 42.0644 136.454 49.7987 139.979 58.3109C143.505 66.8232 145.32 75.9465 145.32 85.16H139.32C139.32 76.7344 137.66 68.3913 134.436 60.607C131.212 52.8228 126.486 45.7498 120.528 39.792C114.57 33.8342 107.497 29.1082 99.713 25.8839C91.9287 22.6595 83.5856 21 75.16 21C66.7344 21 58.3913 22.6595 50.607 25.8839C42.8228 29.1082 35.7498 33.8342 29.792 39.792C23.8342 45.7498 19.1082 52.8228 15.8839 60.607Z" />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M43.7774 74.5395L44.5174 68.9895H41.4834V65.1415H45.0354L45.8494 58.7775H42.7414V54.9295H46.3674L47.1074 49.3795H51.3624L50.6224 54.9295H55.9134L56.6534 49.3795H60.9084L60.1684 54.9295H63.2024V58.7775H59.6504L58.8364 65.1415H61.9444V68.9895H58.3184L57.5784 74.5395H53.3234L54.0634 68.9895H48.7724L48.0324 74.5395H43.7774ZM49.2904 65.1415H54.5814L55.3954 58.7775H50.1044L49.2904 65.1415Z"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M66.9486 74.5395L67.6886 68.9895H64.6546V65.1415H68.2066L69.0206 58.7775H65.9126V54.9295H69.5387L70.2786 49.3795H74.5336L73.7937 54.9295H79.0847L79.8246 49.3795H84.0797L83.3396 54.9295H86.3736V58.7775H82.8217L82.0076 65.1415H85.1156V68.9895H81.4897L80.7496 74.5395H76.4947L77.2346 68.9895H71.9436L71.2036 74.5395H66.9486ZM72.4616 65.1415H77.7527L78.5667 58.7775H73.2757L72.4616 65.1415Z"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M90.8599 68.9895L90.1199 74.5395H94.3749L95.1149 68.9895H100.406L99.6659 74.5395H103.921L104.661 68.9895H108.287V65.1415H105.179L105.993 58.7775H109.545V54.9295H106.511L107.251 49.3795H102.996L102.256 54.9295H96.9649L97.7049 49.3795H93.4499L92.7099 54.9295H89.0839V58.7775H92.1919L91.3779 65.1415H87.8259V68.9895H90.8599ZM100.924 65.1415H95.6329L96.4469 58.7775H101.738L100.924 65.1415Z"
            />
        </g>
    </SVG>
);

export default SingleValueRadial;
