import * as React from 'react';
import { _ } from '@splunk/ui-utils/i18n';
import SVG from '@splunk/react-icons/SVGEnterprise';

const Column = (props: React.ComponentProps<typeof SVG>): React.ReactElement<SVGElement> => (
    <SVG screenReaderText={_('Column')} viewBox="0 0 18 16" {...props}>
        <path
            d="M9.83366 1.75C9.83366 1.05964 10.3933 0.5 11.0837 0.5H13.5837C14.274 0.5 14.8337 1.05964 14.8337 1.75V12.1667H9.83366V1.75Z"
            fill="currentColor"
        />
        <path
            d="M3.16699 5.91667C3.16699 5.22631 3.72664 4.66667 4.41699 4.66667H6.91699C7.60735 4.66667 8.16699 5.22631 8.16699 5.91667V12.1667H3.16699V5.91667Z"
            fill="currentColor"
        />
        <path
            d="M1.50033 13.8333C1.04009 13.8333 0.666992 14.2064 0.666992 14.6667C0.666992 15.1269 1.04009 15.5 1.50033 15.5H16.5003C16.9606 15.5 17.3337 15.1269 17.3337 14.6667C17.3337 14.2064 16.9606 13.8333 16.5003 13.8333H1.50033Z"
            fill="currentColor"
        />
    </SVG>
);

export default Column;
