/* istanbul ignore file */

/* eslint-disable @typescript-eslint/no-empty-function,@typescript-eslint/no-explicit-any,@typescript-eslint/no-unused-vars */

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * Ignore test coverage, no-empty-function, no-explicit-any, and no-unused-vars
 * in this file. `noop` is intentionally and explicitly a universal no-op with
 * no code content to be tested.
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

// allow `import { noop } from '@splunk/dashboard-utils';`
export function noop(...args: any[]): any {}

// allow `import noop from '@splunk/dashboard-utils/noop';`
export default noop;
