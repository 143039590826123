import { _ } from '@splunk/ui-utils/i18n';

export const CLONE_LAYOUT_ITEMS_MSG_PANEL_TOO_SMALL = _(
    'This panel is too small to be split into duplicates. Stretch or move the panel to make it bigger first.'
);
export const EDGE_THICKNESS_PX = 4;
export const GRID_PADDING_PX = 1;
export const GRID_SIZE_PX = 10;
export const MIN_HEIGHT_PX = 64;
export const MIN_WIDTH_PX = 64;
export const SNAP_RANGE_PX = 8;
export const PLACEHOLDER_SIZE_PX = 128;
export const ITEM_DROP_TARGET_PX = 12;
export const VIZ_DEFAULT_HEIGHT_PX = 400;
export const VIZ_PREVIEW_DELAY_MS = 50;
export const EDGE_PREVIEW_DELAY_MS = 500;
