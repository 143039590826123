import { some } from 'lodash';
import type { AnyAction, Store } from 'redux';
import type { BehaviorSubject } from 'rxjs';

import type {
    DashboardJSON,
    DashboardCoreApi as IDashboardCoreApi,
    VisualizationDefinition,
    InputDefinition,
    Snapshot,
    DataSourceExtendOptions,
    CreateVisualizationActionPayload,
    DataSourceMeta,
    LayoutApi,
    TokenNamespace,
    TokenName,
    ReplaceDefinitionParams,
    ReplaceReadOnlyTokenNamespacesParams,
    ReplaceTokenBindingParams,
    Mode,
    SelectedItem,
} from '@splunk/dashboard-types';
import {
    DEFAULT_TOKEN_NAMESPACE,
    deprecated,
    runningSearchStatuses,
} from '@splunk/dashboard-utils';
import { getCompleteSearchQueryAndParameters as getCompleteSearchQueryAndParams } from '@splunk/datasource-utils';
import { DashboardDefinition } from '@splunk/dashboard-definition';

import {
    selectDataSourceDefinitions,
    selectDefinition,
    updateVisualization,
    updateDefinition,
    removeDataSourceFromVisualization,
    updateLayoutStructure,
    updateInput,
    updateLayoutOptions,
    updateInputStructure as updateInputStructureAction,
    showGridLines,
    setFullscreenItem,
    selectSubmittedTokens,
    replaceReadOnlyTokenNamespaces,
    replaceTokenBinding,
    setToken,
    unsetToken,
    cloneDashboardItems as cloneDashboardItemsAction,
    removeDashboardItems as removeDashboardItemsAction,
    createVisualization as createVisualizationAction,
    adjustVisualizationOrder as adjustVisualizationOrderAction,
    createInput as createInputAction,
    adjustInputOrder as moveInputAction,
    removeInput as removeInputAction,
    moveInputToCanvas as moveInputToCanvasAction,
    moveInputToGlobalInputs as moveInputToGlobalInputsAction,
    resetStore,
    switchMode,
    updateSelectedItems,
} from '@splunk/dashboard-state';
import type { DataSourceRegistry } from '@splunk/dashboard-search';
import type ApiRegistry from '../registries/extensions/ApiRegistry';
import { assembleSnapshot } from './snapshot';

const emptyDatasourceMeta: DataSourceMeta = {};

export default class DashboardCoreApi implements IDashboardCoreApi {
    store: Store;

    apiRegistry: ApiRegistry;

    dataSourceRegistry: DataSourceRegistry;

    constructor({
        store,
        apiRegistry,
        dataSourceRegistry,
    }: {
        store: Store;
        apiRegistry: ApiRegistry;
        dataSourceRegistry: DataSourceRegistry;
    }) {
        this.store = store;
        this.apiRegistry = apiRegistry;
        this.dataSourceRegistry = dataSourceRegistry;
    }

    /**
     * Replace the dashboard definition. This method causes a lot of redux state to recompute, use sparingly
     * ```js
     * dashboardCoreApi.replaceDefinition({ definition });
     * ```
     * @method replaceDefinition
     * @param {ReplaceDefinitionParams} options
     * @param {DashboardJSON} options.definition dashboard definition
     * @public
     */
    replaceDefinition({ definition }: ReplaceDefinitionParams): void {
        this.store.dispatch(
            resetStore({
                definition,
            })
        );
    }

    /**
     * Replace read-only token namespaces with the ones provided
     * ```ts
     * const namespaces = new Set<string>(['readonlyTokens']);
     * dashboardCoreApi.replaceReadOnlyTokenNamespaces({ namespaces });
     * ```
     * @method replaceReadOnlyTokenNamespaces
     * @param {ReplaceReadOnlyTokenNamespacesParams} options
     * @param {Set<TokenNamespace>} options.namespaces read-only token namespaces
     * @public
     */
    replaceReadOnlyTokenNamespaces({
        namespaces,
    }: ReplaceReadOnlyTokenNamespacesParams): void {
        this.store.dispatch(replaceReadOnlyTokenNamespaces({ namespaces }));
    }

    /**
     * Replace the token binding.
     * This will replace all tokens in state with the tokens provided in the tokenBinding object
     * ```js
     * dashboardCoreApi.replaceTokenBinding({ tokenBinding: { default: { token1: 'foo' } } });
     * ```
     * @method replaceTokenBinding
     * @param {ReplaceTokenBindingParams} options
     * @param {TokenState} options.tokenBinding new token binding
     * @public
     */
    replaceTokenBinding({ tokenBinding }: ReplaceTokenBindingParams): void {
        this.store.dispatch(replaceTokenBinding({ tokenBinding }));
    }

    /**
     * Update the dashboard definition. This method causes a lot of redux state to recompute, use sparingly
     * ```js
     * dashboardCoreApi.updateDefinition({
     *     layout: {
     *         type: 'absolute',
     *     },
     * });
     * ```
     * @method updateDefinition
     * @param {DashboardJSON} definition dashboard definition
     * @public
     */
    updateDefinition(definition: DashboardJSON): void {
        this.store.dispatch(updateDefinition(definition));
    }

    /**
     * Fetch the current definition from state
     * @returns {DashboardJSON} The current definition
     */
    getDefinition(): DashboardJSON {
        return selectDefinition(this.store.getState());
    }

    /**
     * Set token bindings.
     * This will only update the tokens provided in the tokenBinding object
     * @param {Object} tokenBindingsConfig
     * @param {Object} tokenBindingsConfig.tokenBindings token bindings
     * @param {TokenNamespace} [tokenBindingsConfig.namespace = 'default'] - namespace for token bindings
     * ```js
     * dashboardCoreApi.setTokenBindings({
     *     tokenBindings: { myToken: 'myTokenValue' },
     * })
     * ```
     * @method setTokenBindings
     * @param {Object} options
     * @param {Object} options.tokenBindings token bindings
     * @param {TokenNamespace} [options.namespace = 'default'] - namespace for token bindings
     * @public
     */
    setTokenBindings({
        tokenBindings,
        namespace = DEFAULT_TOKEN_NAMESPACE,
    }: {
        tokenBindings: Record<string, unknown>;
        namespace: TokenNamespace;
    }): void {
        this.store.dispatch(
            (
                setToken as (
                    tokenBindings: unknown,
                    namespace?: TokenNamespace,
                    submit?: boolean
                ) => AnyAction
            )(tokenBindings, namespace)
        );
    }

    /**
     * Unset token bindings. This will not unset any tokens other than those provided and no error is thrown if the token to be unset does not exist.
     * ```js
     * dashboardCoreApi.unsetTokenBinding({
     *     tokenName: 'myToken',
     * })
     *
     * // This method is capable of unsetting multiple tokens within a single namespace if an array of token names is provided:
     * dashboardCoreApi.unsetTokenBinding({
     *     tokenName: ['myToken', 'myToken2', 'myToken3'],
     * })
     * ```
     * @method unsetTokenBinding
     * @param {Object} options
     * @param {TokenName} options.tokenName token name
     * @param {TokenNamespace} [options.namespace = 'default'] - namespace for token bindings
     * @public
     */
    unsetTokenBinding({
        tokenName,
        namespace = DEFAULT_TOKEN_NAMESPACE,
    }: {
        tokenName: TokenName | TokenName[];
        namespace: TokenNamespace;
    }): void {
        this.store.dispatch(unsetToken({ namespace, tokenName }));
    }

    /**
     * Retrieve a promise which resolves with the current layout's API once it is available
     * ```js
     * dashboardCoreApi.isLayoutApiReady().then((layoutApi) => {
     *     this.layoutApi = layoutApi;
     * });
     * ```
     * @method getLayoutApi
     * @returns {Promise} A Promise that resolves to layoutApi object
     * @public
     */
    getLayoutApi(): Promise<LayoutApi> {
        return new Promise((resolve) => {
            // If the LayoutApi is ready just instantly resolve the promise
            const api = this.apiRegistry.getLayoutApi();
            if (api) {
                resolve(api);
            }

            this.apiRegistry.onLayoutApiReady = () =>
                resolve(this.apiRegistry.getLayoutApi() as LayoutApi);
        });
    }

    /**
     * Retrieve a promise which resolves with the current layout's API once it is available
     * ```js
     * dashboardCoreApi.isLayoutApiReady().then((layoutApi) => {
     *     this.layoutApi = layoutApi;
     * });
     * ```
     * @method isLayoutApiReady
     * @returns {Promise} A Promise that resolves to layoutApi object
     * @deprecated This method has been renamed to `getLayoutApi`. The `isLayoutApiReady` name will be removed in a future version
     * @see {@link getLayoutApi}
     */
    isLayoutApiReady(): Promise<LayoutApi> {
        deprecated(
            'The isLayoutApiReady method has been renamed to getLayoutApi'
        );
        return this.getLayoutApi();
    }

    /**
     * Create a visualization and add it to the dashboard. If the visualization requires a new data source it can be created and bound in the same call by providing values for `dataSourceType` and `dataSourceDefinition`.
     * ```js
     * const myTableDefinition = {
     *     type: 'splunk.table',
     *     options: {
     *         count: 25,
     *         showRowNumbers: true,
     *         showInternalFields: false
     *     },
     * };
     *
     * const myTableDataDefinition = {
     *     type': 'ds.search',
     *     options': {
     *         "query": `| makeresults count=100
     * | streamstats count as row
     * | eval value=random() % 4000
     * | table row, value`,
     *     },
     *     name: 'My Table Data',
     * };
     *
     * dashboardCoreApi.createVisualization({
     *     visualizationId: 'viz_myNewTable',
     *     visualizationDefinition: myTableDefinition,
     *     layoutItemType: 'block',
     *     dataSourceType: 'primary',
     *     dataSourceDefinition: myTableDataDefinition,
     * });
     * ```
     * @method createVisualization
     * @param {CreateVisualizationActionPayload} options
     * @param {string} options.visualizationId A unique identifier for the visualization
     * @param {VisualizationDefinition} options.visualizationDefinition The JSON definition for the visualization
     * @param {StructureItemType} [options.layoutItemType='block'] An optional layout type to tell the layout how to render the visualization
     * @param {string | null} [options.dataSourceType] An optional data source binding type (i.e. 'primary')
     * @param {DataSourceDefinition | null} [options.dataSourceDefinition] An optional definition for the data source to be created
     * @public
     */
    createVisualization({
        visualizationId,
        visualizationDefinition,
        layoutItemType = 'block',
        dataSourceType,
        dataSourceDefinition,
    }: CreateVisualizationActionPayload): void {
        this.store.dispatch(
            createVisualizationAction({
                visualizationId,
                visualizationDefinition,
                layoutItemType,
                dataSourceType,
                dataSourceDefinition,
            })
        );
    }

    /**
     * Clone one or more existing dashboard items (input or visualization)
     * ```js
     * dashboardCoreApi.cloneDashboardItems({
     *     from: ['viz_1', 'viz_2', 'input_1'],
     *     to: ['viz_clone1', 'viz_clone2', 'input_clone1'],
     * });
     * ```
     * @method cloneDashboardItems
     * @param {Object} options
     * @param {String[]} options.from Original item Ids
     * @param {String[]} options.to Ids for cloned items
     * @param {Number} [options.offsetMultiplier=1] Multiplier for the position offset of the cloned items
     * @public
     */
    cloneDashboardItems({
        from,
        to,
        offsetMultiplier = 1,
    }: {
        from: string[];
        to: string[];
        offsetMultiplier: number;
    }): void {
        this.store.dispatch(
            cloneDashboardItemsAction({ from, to, offsetMultiplier })
        );
    }

    /**
     * Clone one or more existing visualizations
     * @method cloneVisualizations
     * @param {Object} options
     * @param {String[]} options.from Original VizIds
     * @param {String[]} options.to Ids for cloned viz
     * @param {Number} [options.offsetMultiplier=1] Multiplier to offset cloned visualization
     * @deprecated Deprecated in favor of `cloneDashboardItems` API method.
     * @see {@link cloneDashboardItems}
     */
    cloneVisualizations(args: {
        from: string[];
        to: string[];
        offsetMultiplier: number;
    }): void {
        deprecated(
            'The cloneVisualizations method has been deprecated. Please use the cloneDashboardItems API method'
        );
        this.cloneDashboardItems(args);
    }

    /**
     * Remove one or more dashboard items (visualizations or inputs)
     * ```js
     * // Remove single item `viz_5`
     * dashboardCoreApi.removeDashboardItems(['viz_5']);
     *
     * // Remove multiple items `viz_2` and `input_1`
     * dashboardCoreApi.removeDashboardItems(['viz_2', 'input_1']);
     * ```
     * @method removeDashboardItems
     * @param {String[]} ids list of item IDs to remove
     * @public
     */
    removeDashboardItems(ids: string[]): void {
        this.store.dispatch(removeDashboardItemsAction(ids));
    }

    /**
     * Remove one or more visualizations from the dashboard
     * @method removeVisualizations
     * @param {String[]} vizIds list of visualization IDs to remove
     * @deprecated Deprecated in favor of `removeDashboardItems` API method.
     * @see {@link removeDashboardItems}
     */
    removeVisualizations(vizIds: string[]): void {
        deprecated(
            'The removeVisualizations method has been deprecated. Please use the removeDashboardItems API method'
        );

        this.removeDashboardItems(vizIds);
    }

    /**
     * Replace a visualization's definition.
     * ```js
     * dashboardCoreApi.updateVisualization({
     *     id: 'viz_1',
     *     vizDefinition: {
     *         options: {
     *             count: 25,
     *             showRowNumbers: true,
     *             showInternalFields: false,
     *         },
     *     },
     * });
     * ```
     * @method updateVisualization
     * @param {Object} options
     * @param {String} options.id ID of the visualization to be updated
     * @param {Object} options.vizDefinition A new visualization definition to be applied
     * @public
     */
    updateVisualization({
        id,
        vizDefinition,
    }: {
        id: string;
        vizDefinition: VisualizationDefinition;
    }): void {
        this.store.dispatch(
            updateVisualization({
                id,
                vizDefinition,
            })
        );
    }

    /**
     * Remove a data source from a visualization definition
     * ```js
     * dashboardCoreApi.removeDataSourceFromVisualization({
     *     vizId: 'viz_1',
     *     dsBindingType: 'primary',
     * });
     * ```
     * @method removeDataSourceFromVisualization
     * @param {Object} options
     * @param {String} options.vizId ID of the visualization to be updated
     * @param {String} options.dsBindingType Type of the data source binding (e.g. 'primary' or 'annotation') to be removed
     * @public
     */
    removeDataSourceFromVisualization({
        vizId,
        dsBindingType,
    }: {
        vizId: string;
        dsBindingType: string;
    }): void {
        this.store.dispatch(
            removeDataSourceFromVisualization({
                vizId,
                dsBindingType,
            })
        );
    }

    /**
     * Get the DOM element for the global inputs container
     * ```ts
     * const globalInputContainer: HTMLElement | null = dashboardCoreApi.getInputsDomElement();
     * ```
     * @method getInputsDomElement
     * @returns {HTMLElement | null} DOM element for the global inputs container. If no global inputs exist in the dashboard then this will return `null`
     * @public
     */
    getInputsDomElement(): HTMLElement | null {
        const globalInputsApi = this.apiRegistry.getGlobalInputsApi();
        return globalInputsApi ? globalInputsApi.getInputsDomElement() : null;
    }

    /**
     * Get the DOM element for the dashboard canvas
     * ```ts
     * const dashboardCanvas: HTMLElement | null = dashboardCoreApi.getCanvasDomElement();
     * ```
     * @method getCanvasDomElement
     * @returns {HTMLElement | null} DOM element for the dashboard canvas. If the layout API is not yet ready then this will return `null`
     * @public
     */
    getCanvasDomElement(): HTMLElement | null {
        const layoutApi = this.apiRegistry.getLayoutApi();
        return layoutApi?.getCanvasDomElement() || null;
    }

    /**
     * Get the DOM element for a dashboard visualization.
     * ```ts
     * const visualizationElement: HTMLElement | null = dashboardCoreApi.getVisualizationDomElement('viz_1');
     * ```
     * @method getVisualizationDomElement
     * @returns {HTMLElement | null} DOM element for the visualization. If the visualization API is not yet
     * ready, the provided visualization ID does not correspond to an item in the dashboard, or the provided
     * ID corresponds to a global or in-canvas input, then this will return `null`
     * @public
     */
    getVisualizationDomElement(vizId: string): HTMLElement | null {
        const vizActionsApi = this.apiRegistry.getVisualizationApi(vizId);
        return vizActionsApi?.getDomElement() || null;
    }

    /**
     * Pause all data sources in the dashboard
     * ```js
     * dashboardCoreApi.pauseAllDataSources();
     * ```
     * @method pauseAllDataSources
     * @public
     */
    pauseAllDataSources(): void {
        this.dataSourceRegistry.pauseDataSources();
    }

    /**
     * Get the metadata for a data source
     * ```js
     * dashboardCoreApi.getDataSourceMetaData('ds_search1');
     * ```
     * @method getDataSourceMetaData
     * @param {String} dataSourceId ID of a data source
     * @returns {DataSourceMeta} Metadata for the data source
     * @public
     */
    getDataSourceMetaData(dataSourceId: string): DataSourceMeta {
        const state = this.store.getState();
        const dataSources = selectDataSourceDefinitions(state);
        const dataSourceDef = dataSources[dataSourceId];
        const dataSourceController = dataSourceDef
            ? this.dataSourceRegistry.getDataSourceController(dataSourceId)
            : null;
        return (
            dataSourceController?.getDataSourceMetaData() || emptyDatasourceMeta
        );
    }

    /**
     * Return visualization IDs in the order they are defined in the `.layout.structure` section of the definition
     * ```js
     * dashboardCoreApi.getVisualizationOrder();
     * ```
     * @method getVisualizationOrder
     * @returns {String[] | null} An array of visualization IDs, or `null` if the layout API is not yet available
     * @public
     */
    getVisualizationOrder(): string[] | null {
        const layoutApi = this.apiRegistry.getLayoutApi();
        return typeof layoutApi?.getLayoutItemOrder === 'function'
            ? layoutApi.getLayoutItemOrder()
            : null;
    }

    /**
     * Adjust the order of visualizations in the dashboard. This will produce a new definition.
     * If `fromIdx` is negative or geq the number of visualizations in the layout structure then this is a no-op. Negative `toIdx` values are treated as 0.
     * ```js
     * dashboardCoreApi.getVisualizationOrder();
     * // > ['viz_1', 'viz_2', 'viz_3', 'viz_4']
     *
     * dashboardCoreApi.adjustVisualizationOrder(1, 3);
     *
     * dashboardCoreApi.getVisualizationOrder();
     * // > ['viz_1', 'viz_3', 'viz_4', 'viz_2']
     * ```
     * @method adjustVisualizationOrder
     * @param {Number} fromIdx The numeric index in the current visualization order of the visualization to be moved
     * @param {Number} toIdx The new visualization order index
     * @public
     */
    adjustVisualizationOrder(fromIdx: number, toIdx: number): void {
        this.store.dispatch(
            (
                adjustVisualizationOrderAction as (
                    fromIdx: number,
                    toIdx: number
                ) => AnyAction
            )(fromIdx, toIdx)
        );
    }

    /**
     * Take snapshot of current dashboard
     * ```js
     * const snapshot = dashboardCoreApi.takeSnapshotSync();
     * ```
     * @method takeSnapshotSync
     * @returns {Snapshot} The dashboard snapshot object
     * @public
     */
    takeSnapshotSync(): Snapshot {
        const state = this.store.getState();
        const definition = selectDefinition(state);
        const tokens = selectSubmittedTokens(state);

        return assembleSnapshot({
            definition,
            tokens,
            apiRegistry: this.apiRegistry,
            dataSourceRegistry: this.dataSourceRegistry,
        });
    }

    /**
     * Take snapshot of current dashboard
     * ```js
     * const snapshot = await dashboardCoreApi.takeSnapshot();
     * ```
     * @method takeSnapshot
     * @returns {Promise<Snapshot>} A promise that resolves to a dashboard snapshot object
     * @deprecated This method exists only for backwards compatibility with the legacy async API.
     * @see {@link takeSnapshotSync}
     */
    async takeSnapshot(): Promise<Snapshot> {
        return this.takeSnapshotSync();
    }

    /**
     * Focus on a visualization.
     * This will call the visualization's `focus` function if implemented
     * ```js
     * dashboardCoreApi.focusOnVisualization('viz_1');
     * ```
     * @method focusOnVisualization
     * @param {String} vizId The ID of the visualization to be focused
     * @public
     */
    focusOnVisualization(vizId: string): void {
        const vizApi = this.apiRegistry.getVisualizationApi(vizId);
        if (typeof vizApi?.focus === 'function') {
            vizApi.focus();
        }
    }

    /**
     * Refresh all searches associated with the given visualization
     * ```js
     * dashboardCoreApi.refreshVisualization('viz_1', { checkRiskyCommand: false });
     * ```
     * @method refreshVisualization
     * @param {String} vizId ID of the visualization
     * @param {Object} options
     * @param {Boolean} [options.checkRiskyCommand=true] Sets the check_risky_command option. Set to `false` to continue searches with risky commands
     * @public
     */
    refreshVisualization(
        vizId: string,
        { checkRiskyCommand = true } = {}
    ): void {
        const vizActionsApi =
            this.apiRegistry.getVisualizationActionsApi(vizId);
        vizActionsApi?.refresh({ checkRiskyCommand });
    }

    /**
     * refresh all searches associated with the given input
     * ```js
     * dashboardCoreApi.refreshInput('input_1', { checkRiskyCommand: true });
     * ```
     * @method refreshInput
     * @param {String} inputId ID of the input
     * @param {Object} options
     * @param {Boolean} [options.checkRiskyCommand=true] Sets the check_risky_command option. Set to `false` to continue searches with risky commands
     * @public
     */
    refreshInput(inputId: string, { checkRiskyCommand = true } = {}): void {
        const inputActionsApi = this.apiRegistry.getInputActionsApi(inputId);
        inputActionsApi?.refresh({ checkRiskyCommand });
    }

    /**
     * Set if grid lines should be shown when in edit mode
     * ```js
     * dashboardCoreApi.toggleGridLines(false);
     * ```
     * @method toggleGridLines
     * @param gridToggleValue
     * @private
     */
    toggleGridLines(gridToggleValue: boolean): void {
        this.store.dispatch(showGridLines(gridToggleValue));
    }

    /**
     * Set the current fullscreen item to be the provided visualization.
     * This call must be done in response to a user interaction or it will likely fail due to browser security rules.
     * ```jsx
     * <button onClick={() => dashboardCoreApi.toggleVisualizationFullscreen('viz_1')}>
     *     Fullscreen Visualization
     * </button>
     * ```
     * @method toggleVisualizationFullscreen
     * @param {String} vizId ID of the visualization to fullscreen
     * @public
     */
    toggleVisualizationFullscreen(vizId: string | null): void {
        this.store.dispatch(setFullscreenItem(vizId));
    }

    /**
     * Check if the dashboard has any running searches
     * ```js
     * if (dashboardCoreApi.hasRunningSearches()) {
     *     console.info("The dashboard has running searches");
     * }
     * ```
     * @method hasRunningSearches
     * @returns {boolean} Boolean indicating if at least one search is running
     * i.e. status is one of 'queued', 'parsing', 'running', 'paused' or 'finalizing'.
     * @public
     */
    hasRunningSearches(): boolean {
        const snapshotSync = this.takeSnapshotSync();
        const { dataSources = {} } = snapshotSync?.snapshotDefinition || {};
        return some(dataSources, (datasource) => {
            const status = datasource.state?.meta?.status as string;
            return runningSearchStatuses.includes(status);
        });
    }

    /**
     * Applies new scale to the dashboard
     * ```js
     * dashboardCoreApi.setScale(2.5);
     * ```
     * @method setScale
     * @param {Number} scale Scale factor as a floating point number
     * @public
     */
    setScale(scale: number) {
        const layoutApi = this.apiRegistry.getLayoutApi();
        if (typeof layoutApi?.setScale === 'function') {
            layoutApi.setScale(scale);
        }
    }

    /**
     * Increases dashboard zoom level for one step
     * ```js
     * dashboardCoreApi.zoomIn();
     * ```
     * @method zoomIn
     * @public
     */
    zoomIn() {
        const layoutApi = this.apiRegistry.getLayoutApi();
        if (typeof layoutApi?.zoomIn === 'function') {
            layoutApi.zoomIn();
        }
    }

    /**
     * Decreases dashboard zoom level for one step
     * ```js
     * dashboardCoreApi.zoomOut();
     * ```
     * @method zoomOut
     * @public
     */
    zoomOut() {
        const layoutApi = this.apiRegistry.getLayoutApi();
        if (typeof layoutApi?.zoomOut === 'function') {
            layoutApi.zoomOut();
        }
    }

    /**
     * Adjusts dashboard's width to fit its parent element.
     * ```js
     * dashboardCoreApi.fitToWidth();
     * ```
     * @method fitToWidth
     * @param {Number} [maxScale=Infinity] The maximum scale factor at which the dashboard stops adjusting its width
     * @public
     */
    fitToWidth(maxScale = Infinity) {
        const layoutApi = this.apiRegistry.getLayoutApi();
        if (typeof layoutApi?.fitToWidth === 'function') {
            layoutApi.fitToWidth(maxScale);
        }
    }

    /**
     * Get an observable to which a listener can subscribe for notifications when the dashboard zoom level changes
     * ```js
     * React.useEffect(() => {
     *     let subscription = null;
     *     dashboardCoreApi.getZoomLevel().then((observable) => {
     *         if (subscription === null) {
     *             subscription = observable.subscribe((zoomLevel) => {
     *                 console.log(`Dashboard zoom changed to [${zoomLevel}]`);
     *             });
     *         }
     *     });
     *
     *     return () => {
     *         subscription?.unsubscribe();
     *         subscription = undefined;
     *     };
     * }, [dashboardCoreApi]);
     * ```
     * @method getZoomLevel
     * @returns {Promise<BehaviorSubject<Number> | null>} Promise that resolves to an observable that yields zoom level
     * @public
     */
    async getZoomLevel(): Promise<BehaviorSubject<number> | null> {
        const layoutApi = await this.getLayoutApi();
        return layoutApi?.getZoomLevel() || null;
    }

    /**
     * Get input definition given a token ID
     * ```js
     * dashboardCoreApi.getInputByToken('input1_token');
     * ```
     * @method getInputByToken
     * @param {String} tokenId ID of the token associated with an input
     * @returns {InputDefinition | null} The definition of the associated input if it exists, else `null`
     * @public
     */
    getInputByToken(tokenId: string): InputDefinition | null {
        const state = this.store.getState();
        const definition = new DashboardDefinition(selectDefinition(state));
        return definition.getInputByToken(tokenId);
    }

    /**
     * Add an input to the end of the global inputs bar
     * ```js
     * const myInputDefinition = {
     *     type: 'input.text',
     *     title: 'my text input',
     *     options: {
     *         token: 'input2_token',
     *     },
     * };
     *
     * dashboardCoreApi.addInput('input_2', myInputDefinition);
     * ```
     * @method addInput
     * @param {String} inputId The ID of the input being added
     * @param {Object} inputDefinition The definition of the input being added
     * @public
     */
    addInput(inputId: string, inputDefinition: InputDefinition): void {
        this.store.dispatch(
            createInputAction({
                inputId,
                inputDefinition,
            })
        );
    }

    /**
     * Reorder the global inputs in the dashboard. Invalid `from` and/or `to` indexes cause this to be a no-op.
     * ```js
     * dashboardCoreApi.moveInput(0, 2);
     * ```
     * @method moveInput
     * @param {Number} from array index of the input to move
     * @param {Number} to array index of where the input should move to
     * @public
     */
    moveInput(from: number, to: number): void {
        this.store.dispatch(
            moveInputAction({
                from,
                to,
            })
        );
    }

    /**
     * Remove an input from the dashboard. It does not matter if the input is global or in the canvas.
     * ```js
     * dashboardCoreApi.removeInput('input_1');
     * ```
     * @method removeInput
     * @param {String} inputId The ID of the input to be removed
     * @public
     */
    removeInput(inputId: string): void {
        this.store.dispatch(removeInputAction(inputId));
    }

    /**
     * Update an input definition
     * ```js
     * const myUpdatedInputDefinition = {
     *     type: 'input.text',
     *     title: 'Updated Input Title',
     *     options: {
     *         token: 'input2_token',
     *         defaultValue: 'Default Text Value',
     *     },
     * };
     *
     * dashboardCoreApi.updateInput({
     *     id: 'input_2',
     *     inputDefinition: myUpdatedInputDefinition,
     * });
     * ```
     * @method updateInput
     * @param {Object} options
     * @param {String} options.id The ID of the input to be updated
     * @param {Object} options.inputDefinition New input definition to be applied
     * @public
     */
    updateInput({
        id,
        inputDefinition,
    }: {
        id: string;
        inputDefinition: InputDefinition;
    }): void {
        this.store.dispatch(
            updateInput({
                id,
                inputDefinition,
            })
        );
    }

    /**
     * Update the layout.globalInputs section in the definition
     * ```js
     * dashboardCoreApi.updateInputStructure(['input_2', 'input_1']);
     * ```
     * @method updateInputStructure
     * @param {String[]} inputStructure input ids
     * @public
     */
    updateInputStructure(inputStructure: string[]): void {
        this.store.dispatch(updateInputStructureAction(inputStructure));
    }

    /**
     * Fetch the complete SPL search query and query parameters of a data source, including its ancestors
     * ```js
     * dashboardCoreApi.getCompleteSearchQueryAndParameters('ds_search1');
     * ```
     * @method getCompleteSearchQueryAndParameters
     * @param {String} dataSourceId ID of the data source to fetch
     * @returns {Object} Search options including the query and query parameters
     * @public
     */
    getCompleteSearchQueryAndParameters(
        dataSourceId: string
    ): DataSourceExtendOptions {
        const state = this.store.getState();
        const definition = DashboardDefinition.fromJSON(
            selectDefinition(state)
        );
        return getCompleteSearchQueryAndParams({
            definition,
            dataSourceId,
            getMetaData: this.getDataSourceMetaData.bind(this),
            submittedTokens: selectSubmittedTokens(state),
        });
    }

    /**
     * Update the .layout.structure section of the current dashboard definition
     * ```js
     * dashboardCoreApi.updateLayoutStructure([
     *     {
     *         "item": "viz_2",
     *         "type": "block",
     *         "position": {
     *             "x": 20,
     *             "y": 20,
     *             "w": 500,
     *             "h": 200
     *         }
     *     },
     *     {
     *         "item": "viz_1",
     *         "type": "block",
     *         "position": {
     *             "x": 550,
     *             "y": 20,
     *             "w": 500,
     *             "h": 200
     *         }
     *     },
     * ]);
     * ```
     * @method updateLayoutStructure
     * @param {Object} layoutStructure layout structure to be used
     * @public
     */
    updateLayoutStructure(layoutStructure: unknown): void {
        this.store.dispatch(updateLayoutStructure(layoutStructure));
    }

    /**
     * Update the .layout.options section of the current dashboard definition
     * ```js
     * dashboardCoreApi.updateLayoutOptions({
     *     submitButton: true,
     *     submitOnDashboardLoad: true,
     * });
     * ```
     * @method updateLayoutOptions
     * @param {Object} options layout options to be used
     * @public
     */
    updateLayoutOptions(options: Record<string, unknown>): void {
        this.store.dispatch(updateLayoutOptions(options));
    }

    /**
     * Moves an input from the global input container to the dashboard canvas
     * ```js
     * dashboardCoreApi.moveInputToCanvas('input_2');
     * ```
     * @method moveInputToCanvas
     * @param {string} inputId The ID of the input to be moved to the dashboard canvas
     * @public
     */
    moveInputToCanvas(inputId: string): void {
        this.store.dispatch(moveInputToCanvasAction(inputId));
    }

    /**
     * Moves an input from the dashboard canvas to the global input container
     * ```js
     * dashboardCoreApi.moveInputToGlobalInputs('input_2');
     * ```
     * @method moveInputToGlobalInputs
     * @param {string} inputId The ID of the input to be moved to the global input container
     * @public
     */
    moveInputToGlobalInputs(inputId: string): void {
        this.store.dispatch(moveInputToGlobalInputsAction(inputId));
    }

    /**
     * Switch between view and edit modes
     * ```
     * dashboardCoreApi.switchMode('edit');
     * ```
     * @method switchMode
     * @param {String} mode New mode, either `edit` or `view`
     * @public
     */
    switchMode(mode: Mode): void {
        this.store.dispatch(switchMode(mode));
    }

    /**
     * Select items on the dashboard
     * @param {SelectedItem[]} [selectedItems=[]] The items to select
     * @public
     * ```js
     * dashboardCoreApi.updateSelectedItems([{ item: 'viz_1', type: 'block' }, { item: 'input_1', type: 'input' }]);
     * ```
     */
    updateSelectedItems(selectedItems: SelectedItem[] = []) {
        this.store.dispatch(updateSelectedItems(selectedItems));
    }
}

/**
 * Create a new instance of the `DashboardCoreApi` class
 * @param {Object} options
 * @param {Store} options.store Global redux store
 * @param {ApiRegistry} options.apiRegistry A registry that holds layout, input, and viz APIs
 * @param {DataSourceRegistry} options.dataSourceRegistry A module that creates reusable DataSource controllers
 * @param {LayoutLayersContextApi} [options.layoutLayerApi] Optional API for management and manipulation of item layering
 * @returns {DashboardCoreApi} An instance of the `DashboardCoreApi` class
 * @see {@link DashboardCoreApi}
 * @private
 */
export const createDashboardApi = (options: {
    store: Store;
    apiRegistry: ApiRegistry;
    dataSourceRegistry: DataSourceRegistry;
}): DashboardCoreApi => new DashboardCoreApi(options);
