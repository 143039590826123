import { Bubble as BubbleIcon } from '@splunk/visualization-icons';
import { Bubble as BubblePlaceholderIcon } from '@splunk/visualization-icons/placeholders';
import { getPattern, COLOR_OR_TOKEN_PATTERN_WITH_RGBA } from '@splunk/visualizations-shared/schemaUtils';
import { VIZ_CATEGORICAL } from '@splunk/visualization-color-palettes';
import pick from '@splunk/themes/pick';
import variables from '@splunk/themes/variables';
import { _ } from '@splunk/ui-utils/i18n';
import { AxesChartDataConfigBubble } from '../common/editorConfig/AxesChartDataConfig';
import { AxesChartXGridAndLabelsBubbleScatter } from '../common/editorConfig/AxesChartXGridAndLabels';
import AxesChartColorAndStyle from '../common/editorConfig/AxesChartColorAndStyle';
import AxesChartLegend from '../common/editorConfig/AxesChartLegend';
import AxesChartYGridAndLabels from '../common/editorConfig/AxesChartYGridAndLabels';

import { DataContract } from '../common/interfaces/DataContract';
import { DefaultContext } from '../common/interfaces/DefaultContext';
import { EditorConfig } from '../common/interfaces/Editor';
import { OptionsSchema } from '../common/interfaces/OptionsSchema';
import { ThemedDefaults } from '../common/interfaces/ThemedDefaults';
import { VizBehavior } from '../common/interfaces/VizBehavior';
import { VizCategory } from '../common/interfaces/VizCategory';
import { VizConfig } from '../common/interfaces/VizConfig';
import { VizSize } from '../common/interfaces/VizSize';
import { enhanceConfig } from '../common/utils/configUtils';

const dataContract: DataContract = {
    requiredDataSources: [
        {
            name: 'primary',
            description: 'DataSource that powers the visualization',
        },
    ],
    optionalDataSources: [
        // Note: SCP-14090 disable ui for optional datasources. We may need to add it back later
        // {
        //     name: 'annotation',
        //     description: 'DataSource that populates event annotations',
        // },
    ],
    initialRequestParams: {
        primary: { offset: 0, count: 10000 },
    },
};

const size: VizSize = {
    initialWidth: 300,
    initialHeight: 300,
};

const defaultContext: DefaultContext = {};

const optionsSchema: OptionsSchema = {
    x: {
        default: '> primary | seriesByIndex(0)',
        description: 'Specify the dataSource applied to the x-axis.',
        type: 'array',
        items: { type: ['string', 'number'] },
    },
    y: {
        default: '> primary | seriesByIndex(1)',
        description: 'Specify the dataSource applied to the y-axis.',
        type: 'array',
        items: { type: ['string', 'number'] },
    },
    category: {
        description: 'Specify a sequence of dataSource events to be plotted on the chart.',
        type: 'array',
        items: { type: ['string', 'number'] },
    },
    size: {
        default: '> primary | seriesByIndex(2)',
        description: 'Specify the dataSource events that are represented by the bubble size in the chart.',
        type: 'array',
        items: { type: 'number' },
    },
    xField: {
        default: '> x | getField()',
        description: 'Specify the field that should be mapped to the x-axis.',
        type: 'string',
    },
    yField: {
        default: '> y | getField()',
        description: 'Specify the field that should be mapped to the y-axis.',
        type: 'string',
    },
    categoryField: {
        default: '> category | getField()',
        description: 'Specify the field that should be mapped to the series categories.',
        type: 'string',
    },
    sizeField: {
        default: '> size | getField()',
        description: 'Specify the field that should be mapped to the bubble size in the chart.',
        type: 'string',
    },
    backgroundColor: {
        default: '> themes.defaultBackgroundColor',
        description:
            'Specify the color used for the chart background by using a hexadecimal code. For example, #0000FF.',
        pattern: COLOR_OR_TOKEN_PATTERN_WITH_RGBA,
        type: 'string',
    },
    bubbleSizeMax: {
        default: 50,
        description: 'Specify, in pixels, the maximum size of each bubble.',
        type: 'number',
    },
    bubbleSizeMin: {
        default: 10,
        description: 'Specify, in pixels, the minimum size of each bubble.',
        type: 'number',
    },
    bubbleSizeMethod: {
        default: 'area',
        description: 'Specify how bubble size is measured.',
        pattern: getPattern(['area', 'diameter']),
        type: 'string',
    },
    legendDisplay: {
        default: 'right',
        description: 'Specify the location of the legend on the panel.',
        pattern: getPattern(['right', 'left', 'top', 'bottom', 'off']),
        type: 'string',
    },
    legendTruncation: {
        default: 'ellipsisEnd',
        description: 'Specify where to use ellipsis to replace legend labels that overflow the layout.',
        pattern: getPattern(['ellipsisEnd', 'ellipsisMiddle', 'ellipsisStart', 'ellipsisOff']),
        type: 'string',
    },
    resultLimit: {
        default: 50000,
        description: 'Specify the number of data points rendered in a chart.',
        type: 'number',
    },
    seriesColors: {
        default: VIZ_CATEGORICAL,
        description:
            'Specify the hexadecimal color codes for the bubble order from largest to smallest. For example, ["#FF0000", "#0000FF", "#008000"].',
        type: 'array',
        items: { type: 'string' },
    },
    seriesColorsByField: {
        description:
            'Specify the colors to use for specific categories, or series names, derived from distinct values in the selected category field. For example, {"user1": "#008000", "user2": "#FFA500"}.',
        type: 'object',
    },
    showXAxisExtendedRange: {
        default: true,
        description: 'Specify whether the x-axis should be extended to snap to whole major tick marks.',
        type: 'boolean',
    },
    showYAxisExtendedRange: {
        default: true,
        description: 'Specify whether the y-axis should be extended to snap to whole major tick marks.',
        type: 'boolean',
    },
    showXAxisWithZero: {
        default: false,
        description: 'Specify whether the x-axis range includes zero.',
        type: 'boolean',
    },
    showYAxisWithZero: {
        default: false,
        description: 'Specify whether the y-axis range includes zero.',
        type: 'boolean',
    },
    showRoundedXAxisLabels: {
        default: false,
        description: 'Specify whether to round x-axis values to the nearest integer.',
        type: 'boolean',
    },
    // TODO: figure out if we want to change splunk-charting behavior for Bubble/Scatter, which hardcodes XMajorGridlines to false
    // showXMajorGridLines: {
    //     default: false,
    //     description: 'Specify whether major grid lines are visible on the x-axis.',
    //     type: 'boolean',
    // },
    showYMajorGridLines: {
        default: true,
        description: 'Specify whether major grid lines are visible on the y-axis.',
        type: 'boolean',
    },
    showXMinorGridLines: {
        default: false,
        description: 'Specify whether minor grid lines are visible on the x-axis.',
        type: 'boolean',
    },
    showYMinorGridLines: {
        default: false,
        description: 'Specify whether minor grid lines are visible on the y-axis.',
        type: 'boolean',
    },
    xAxisLabelRotation: {
        default: 0,
        description: 'Specify the rotation of the x-axis labels in degrees.',
        type: 'number',
        enum: [-90, -45, 0, 45, 90],
    },
    xAxisAbbreviation: {
        default: 'off',
        description:
            'Specify whether to abbreviate large x-axis values with the closest International System of Units (SI) prefix.',
        enum: ['off', 'auto'],
        pattern: getPattern(['auto', 'off']),
        type: 'string',
    },
    yAxisAbbreviation: {
        default: 'auto',
        description:
            'Specify whether to abbreviate large y-axis values with the closest International System of Units (SI) prefix.',
        enum: ['off', 'auto'],
        pattern: getPattern(['auto', 'off']),
        type: 'string',
    },
    xAxisLabelVisibility: {
        default: 'auto',
        description: 'Specify whether to show or hide labels on the x-axis.',
        pattern: getPattern(['auto', 'show', 'hide']),
        type: 'string',
    },
    yAxisLabelVisibility: {
        default: 'auto',
        description: 'Specify whether to show or hide labels on the y-axis.',
        pattern: getPattern(['auto', 'show', 'hide']),
        type: 'string',
    },
    xAxisLineVisibility: {
        default: 'hide',
        description: 'Specify whether to show the x-axis line.',
        enum: ['hide', 'show'],
        pattern: getPattern(['show', 'hide']),
        type: 'string',
    },
    yAxisLineVisibility: {
        default: 'hide',
        description: 'Specify whether to show the y-axis line.',
        enum: ['hide', 'show'],
        pattern: getPattern(['show', 'hide']),
        type: 'string',
    },
    xAxisMajorTickInterval: {
        default: 'auto',
        description:
            'Specify the spacing interval between major tick marks along the x-axis. By default, the spacing value is automatically calculated based on the scale of the related axis.',
        type: ['string', 'number'],
    },
    yAxisMajorTickInterval: {
        default: 'auto',
        description:
            'Specify the spacing interval between major tick marks along the y-axis. By default, the spacing value is automatically calculated based on the scale of the related axis.',
        type: ['string', 'number'],
    },
    xAxisMajorTickSize: {
        default: 6,
        description: 'Specify the size, in pixels, of major tick marks on the x-axis.',
        type: 'number',
    },
    yAxisMajorTickSize: {
        default: 6,
        description: 'Specify the size, in pixels, of major tick marks on the y-axis.',
        type: 'number',
    },
    xAxisMinorTickSize: {
        default: 6,
        description: 'Specify the size, in pixels, of minor tick marks on the x-axis.',
        type: 'number',
    },
    yAxisMinorTickSize: {
        default: 6,
        description: 'Specify the size, in pixels, of minor tick marks on the y-axis.',
        type: 'number',
    },
    xAxisMajorTickVisibility: {
        default: 'auto',
        description: 'Specify whether to show or hide major tick marks on the x-axis.',
        pattern: getPattern(['auto', 'show', 'hide']),
        type: 'string',
    },
    yAxisMajorTickVisibility: {
        default: 'auto',
        description: 'Specify whether to show or hide major tick marks on the y-axis.',
        pattern: getPattern(['auto', 'show', 'hide']),
        type: 'string',
    },
    xAxisMinorTickVisibility: {
        default: 'auto',
        description: 'Specify whether to show or hide minor tick marks on the x-axis .',
        pattern: getPattern(['auto', 'show', 'hide']),
        type: 'string',
    },
    yAxisMinorTickVisibility: {
        default: 'auto',
        description: 'Specify whether to show or hide minor tick marks on the y-axis.',
        pattern: getPattern(['auto', 'show', 'hide']),
        type: 'string',
    },
    xAxisMax: {
        default: 'auto',
        description: 'Specify the maximum value for the visible x-axis range.',
        type: ['string', 'number'],
    },
    yAxisMax: {
        default: 'auto',
        description: 'Specify the maximum value for the visible y-axis range.',
        type: ['string', 'number'],
    },
    xAxisMin: {
        default: 'auto',
        description: 'Specify the minimum value for the visible x-axis range.',
        type: ['string', 'number'],
    },
    yAxisMin: {
        default: 'auto',
        description: 'Specify the minimum value for the visible y-axis range.',
        type: ['string', 'number'],
    },
    xAxisScale: {
        default: 'linear',
        description: 'Specify the type of scale that applies to a numerical x-axis.',
        pattern: getPattern(['linear', 'log']),
        type: 'string',
    },
    yAxisScale: {
        default: 'linear',
        description: 'Specify the type of scale that applies to a numerical y-axis.',
        pattern: getPattern(['linear', 'log']),
        type: 'string',
    },
    xAxisTitleText: {
        description: 'Specify the title of the x-axis.',
        type: 'string',
    },
    yAxisTitleText: {
        description: 'Specify the title of the y-axis.',
        type: 'string',
    },
    xAxisTitleVisibility: {
        default: 'show',
        description: 'Specify whether to hide the title of the x-axis.',
        enum: ['hide', 'show'],
        pattern: getPattern(['show', 'hide']),
        type: 'string',
    },
    yAxisTitleVisibility: {
        default: 'show',
        description: 'Specify whether to hide the title of the y-axis.',
        enum: ['hide', 'show'],
        pattern: getPattern(['show', 'hide']),
        type: 'string',
    },
};

const themes: ThemedDefaults = {
    defaultBackgroundColor: props =>
        pick({
            enterprise: {
                dark: variables.black(props),
                light: variables.backgroundColor(props),
            },
            prisma: variables.backgroundColorSidebar(props),
        })(props),
};

const editorConfig: EditorConfig[] = [
    AxesChartDataConfigBubble,
    {
        label: AxesChartColorAndStyle.label,
        layout: [
            ...AxesChartColorAndStyle.layout.slice(0, 2),
            [
                {
                    label: _('Series colors by category name'),
                    option: 'seriesColorsByField',
                    editor: 'editor.seriesColorsByField',
                    editorProps: {
                        isCategorical: true,
                    },
                },
            ],
            [
                {
                    label: _('Maximum bubble size'),
                    option: 'bubbleSizeMax',
                    editor: 'editor.slider',
                    editorProps: {
                        min: 0,
                        max: 200,
                        step: 1,
                    },
                },
            ],
            [
                {
                    label: _('Minimum bubble size'),
                    option: 'bubbleSizeMin',
                    editor: 'editor.slider',
                    editorProps: {
                        min: 0,
                        max: 200,
                        step: 1,
                    },
                },
            ],
            [
                {
                    label: _('Calculation method for bubble size'),
                    option: 'bubbleSizeMethod',
                    editor: 'editor.radioBar',
                    editorProps: {
                        values: [
                            { label: _('Area'), value: 'area' },
                            { label: _('Diameter'), value: 'diameter' },
                        ],
                    },
                },
            ],
        ],
    },
    AxesChartLegend,
    AxesChartXGridAndLabelsBubbleScatter,
    AxesChartYGridAndLabels,
];

/**
 * visualization configuration
 */
const config: VizConfig = {
    /**
     * unique viz key
     */
    key: 'splunk.bubble',
    /**
     * viz name
     */
    name: 'Bubble',
    category: VizCategory.COMPARISONS,
    /**
     * viz icon
     */
    icon: BubbleIcon,
    placeholderIcon: BubblePlaceholderIcon,
    dataContract,
    size,
    defaultContext,
    optionsSchema,
    editorConfig,
    events: {
        'legend.click': {
            description: 'triggered when user clicks on chart legend',
        },
        'point.click': {
            description: 'triggered when user clicks on a point in the bubble chart',
        },
    },
    supports: [VizBehavior.DYNAMIC_OPTIONS, VizBehavior.PLACEHOLDER, VizBehavior.EVENTS],
    themes,
};

export default enhanceConfig(config);
