import type { AbsoluteBlockItem, Coordinate } from '@splunk/dashboard-types';

import { getNodes } from './edgeUtils';
import type { Intersections } from './edgeUtils';

export type SplitGridEntry = {
    // nodes provided as argument
    nodes: Intersections;
    // layout provided as argument
    layout: AbsoluteBlockItem[];
    // horizontal distance from origin.x to row/column right edge
    gridWidth: number;
    // vertical distance from origin.y to row/column bottom edge
    gridHeight: number;
    // (X, Y) of top-left corner of row/column
    origin: Coordinate;
    content: {
        // width of just the row or column
        width: number;
        // height of just the row or column
        height: number;
        // items comprising the row or column
        items: AbsoluteBlockItem[];
    };
};

export type SplitGrid = SplitGridEntry[];

type ItemXPos = number;
type ItemYPos = number;
export type ItemCoordinateMap = Record<
    ItemYPos,
    Record<ItemXPos, AbsoluteBlockItem>
>;

export const trackItemsByCoordinate = (
    layout: AbsoluteBlockItem[]
): ItemCoordinateMap => {
    const result: ItemCoordinateMap = {};

    // Create a mapping which can be used to get block items given an X and/or Y coordinate
    layout.forEach((item) => {
        result[item.position.y] ??= {};
        result[item.position.y][item.position.x] = item;
    });

    return result;
};

export const getItemsInCoordinateRange = ({
    itemCoordinateMap,
    range,
}: {
    itemCoordinateMap: ItemCoordinateMap;
    range?: {
        from?: Partial<Coordinate>;
        to?: Partial<Coordinate>;
    };
}): AbsoluteBlockItem[] => {
    if (!range) {
        return Object.values(itemCoordinateMap).flatMap(Object.values);
    }

    const { x: fromX = 0, y: fromY = 0 } = range.from || {};
    const {
        x: toX = Number.POSITIVE_INFINITY,
        y: toY = Number.POSITIVE_INFINITY,
    } = range.to || {};

    return Object.keys(itemCoordinateMap)
        .map((key) => parseInt(key, 10)) // Object.keys returns strings. Convert to numbers for comparisons
        .filter((yPos) => yPos >= fromY && yPos < toY) // Filter to only [fromY..toY)
        .reduce((acc, yPos) => {
            Object.keys(itemCoordinateMap[yPos]).forEach((key) => {
                const xPos = parseInt(key, 10); // Object.keys returns strings. Convert to numbers
                if (xPos >= fromX && xPos < toX) {
                    acc.push(itemCoordinateMap[yPos][xPos]); // Track if in range [fromX..toX)
                }
            });

            return acc;
        }, [] as AbsoluteBlockItem[]);
};

const getVizAtCoord = ({
    x,
    y,
    nodes,
}: {
    x: number;
    y: number;
    nodes: Intersections;
}) => nodes[x][y].find((viz) => viz.position.x === x && viz.position.y === y);

const isFullEdge = ({
    x,
    y,
    nodes,
    gridWidth,
    gridHeight,
    type,
}: {
    x: number;
    y: number;
    nodes: Intersections;
    gridWidth: number;
    gridHeight: number;
    type: 'horizontal' | 'vertical';
}): boolean => {
    // base case, we've reached the end of the edge and it spans across entire grid
    //   or we're checking the EDGE of the grid, which by definition spans entire grid
    if (x === gridWidth || y === gridHeight) {
        return true;
    }

    const originViz = getVizAtCoord({ x, y, nodes });
    // if no viz at the next X, it means we've hit dead end before reaching the gridWidth
    if (!originViz) {
        return false;
    }

    // keep traversing right OR down
    return isFullEdge({
        x: type === 'horizontal' ? x + originViz.position.w : x,
        y: type === 'vertical' ? y + originViz.position.h : y,
        nodes,
        gridWidth,
        gridHeight,
        type,
    });
};

export const splitGridByRows = ({
    nodes,
    layout,
    gridWidth,
    gridHeight,
    originViz,
}: {
    nodes: Intersections;
    layout: AbsoluteBlockItem[];
    gridWidth: number;
    gridHeight: number;
    originViz: AbsoluteBlockItem;
}): SplitGrid => {
    const { x, y, h } = originViz.position;

    const itemCoordinateMap = trackItemsByCoordinate(layout);

    const newGrids: SplitGrid = [];
    let lastHorizontalEdgeY = y;
    let nextViz = getVizAtCoord({ x, y: y + h, nodes });
    while (nextViz && nextViz.position.y < gridHeight) {
        if (
            isFullEdge({
                x,
                y: nextViz.position.y,
                nodes,
                gridWidth,
                gridHeight,
                type: 'horizontal',
            })
        ) {
            // Given the top and bottom of the row split get the grid content within
            // that range so the newGrids array entry also includes contained items
            const rowItems = getItemsInCoordinateRange({
                itemCoordinateMap,
                range: {
                    from: { y: lastHorizontalEdgeY },
                    to: { y: nextViz.position.y },
                },
            });

            newGrids.push({
                nodes,
                layout,
                gridWidth,
                gridHeight: nextViz.position.y,
                origin: { x, y: lastHorizontalEdgeY },
                content: {
                    // the width of the split is the full grid width less the origin of the split
                    width: gridWidth - x,
                    // the height of the split is the top of the next split less the origin of the current split
                    height: nextViz.position.y - lastHorizontalEdgeY,
                    items: rowItems,
                },
            });

            lastHorizontalEdgeY = nextViz.position.y;
        }
        nextViz = getVizAtCoord({
            x,
            y: nextViz.position.y + nextViz.position.h,
            nodes,
        });
    }

    if (newGrids.length === 0) {
        // if no grids have been added, don't add the special case, return instead.
        return [];
    }

    // Get any remaining items for the last row of the split grid
    const lastRowItems = getItemsInCoordinateRange({
        itemCoordinateMap,
        range: {
            from: { y: lastHorizontalEdgeY },
        },
    });

    // special case to handle the last row (the bottom grid edge)
    newGrids.push({
        nodes,
        layout,
        gridWidth,
        gridHeight,
        origin: { x, y: lastHorizontalEdgeY },
        content: {
            width: gridWidth - x,
            height: gridHeight - lastHorizontalEdgeY,
            items: lastRowItems,
        },
    });

    return newGrids;
};

export const splitGridByColumns = ({
    nodes,
    layout,
    gridWidth,
    gridHeight,
    originViz,
}: {
    nodes: Intersections;
    layout: AbsoluteBlockItem[];
    gridWidth: number;
    gridHeight: number;
    originViz: AbsoluteBlockItem;
}): SplitGrid => {
    const { x, y, w } = originViz.position;

    const itemCoordinateMap = trackItemsByCoordinate(layout);

    const newGrids: SplitGrid = [];
    let lastVerticalEdgeX = x;
    // the next visualization to the right of origin
    let nextViz = getVizAtCoord({ x: x + w, y, nodes });
    while (nextViz && nextViz.position.x < gridWidth) {
        // if the edge starting from the nextViz goes all the way down, it's a full grid edge
        if (
            isFullEdge({
                x: nextViz.position.x,
                y,
                nodes,
                gridWidth,
                gridHeight,
                type: 'vertical',
            })
        ) {
            // Given the left and right of the column split get the grid content within
            // that range so the newGrids array entry also includes contained items
            const colItems = getItemsInCoordinateRange({
                itemCoordinateMap,
                range: {
                    from: { x: lastVerticalEdgeX },
                    to: { x: nextViz.position.x },
                },
            });

            newGrids.push({
                nodes,
                layout,
                gridWidth: nextViz.position.x,
                gridHeight,
                origin: { x: lastVerticalEdgeX, y },
                content: {
                    // the width of the split is the left-edge of the next column less the origin of the current column
                    width: nextViz.position.x - lastVerticalEdgeX,
                    // the height of the split is the height of the grid less the origin of the column
                    height: gridHeight - y,
                    items: colItems,
                },
            });
            lastVerticalEdgeX = nextViz.position.x;
        }
        nextViz = getVizAtCoord({
            x: nextViz.position.x + nextViz.position.w,
            y,
            nodes,
        });
    }

    if (newGrids.length === 0) {
        // if no grids have been added, don't add the special case, return instead.
        return [];
    }

    // Get any remaining items for the last column of the split grid
    const lastColumnItems = getItemsInCoordinateRange({
        itemCoordinateMap,
        range: {
            from: { x: lastVerticalEdgeX },
        },
    });

    // special case to handle the last column (the right-most grid edge)
    newGrids.push({
        nodes,
        layout,
        gridWidth,
        gridHeight,
        origin: { x: lastVerticalEdgeX, y },
        content: {
            width: gridWidth - lastVerticalEdgeX,
            height: gridHeight - y,
            items: lastColumnItems,
        },
    });

    return newGrids;
};

const splitGridByRowsAndColumns = ({
    origin,
    nodes,
    layout,
    newLayout,
    gridWidth,
    gridHeight,
}: {
    origin: { x: number; y: number };
    nodes: Intersections;
    layout: AbsoluteBlockItem[];
    newLayout: AbsoluteBlockItem[];
    gridWidth: number;
    gridHeight: number;
}) => {
    // the viz that exists at exactly at the origin
    const originViz = getVizAtCoord({ x: origin.x, y: origin.y, nodes });
    if (!originViz) {
        throw new Error('Getting grid layout order failed');
    }

    // base recursion case: if the visualization IS the entire grid
    if (
        originViz.position.x + originViz.position.w === gridWidth &&
        originViz.position.y + originViz.position.h === gridHeight
    ) {
        newLayout.push(originViz);
        return;
    }

    // split by rows first
    let newGrids = splitGridByRows({
        nodes,
        layout,
        originViz,
        gridHeight,
        gridWidth,
    });
    newGrids.forEach((gridInfo) =>
        splitGridByRowsAndColumns({ ...gridInfo, newLayout })
    );

    // we either split into rows OR columns, not both
    if (newGrids.length > 0) {
        return;
    }

    // only if no splits were caused by the rows do we want to split by columns (due to window case)
    newGrids = splitGridByColumns({
        nodes,
        layout,
        originViz,
        gridHeight,
        gridWidth,
    });
    newGrids.forEach((gridInfo) =>
        splitGridByRowsAndColumns({ ...gridInfo, newLayout })
    );

    // edge case: the grid is an irregular grid, aka has NO rows or columns and thus cannot split at all
    if (newGrids.length === 0) {
        const vizInCurrentGrid: AbsoluteBlockItem[] = [];
        // get all the visualizations contained in the current grid
        layout.forEach((viz) => {
            const { x, y, w, h } = viz.position;
            if (
                x >= origin.x &&
                x + w <= gridWidth &&
                y >= origin.y &&
                y + h <= gridHeight
            ) {
                vizInCurrentGrid.push(viz);
            }
        });

        const sortedViz = vizInCurrentGrid.sort((viz1, viz2) => {
            if (viz1.position.y < viz2.position.y) {
                return -1;
            }
            if (viz1.position.y > viz2.position.y) {
                return 1;
            }
            if (viz1.position.x < viz2.position.x) {
                return -1;
            }
            if (viz1.position.x > viz2.position.x) {
                return 1;
            }
            return 0;
        });

        // if the grid is irregular, sort by (x,y) instead
        newLayout.push(...sortedViz);
    }
};

export const getGridLayoutOrder = ({
    layout,
    canvasWidth,
    canvasHeight,
}: {
    layout: AbsoluteBlockItem[];
    canvasWidth: number;
    canvasHeight: number;
}) => {
    const nodes = getNodes(layout);
    if (nodes === null || nodes[0] === undefined || nodes[0][0] === undefined) {
        // When no visualizations in the layout structure
        // OR when there is no visualization at (0,0), which this algorithm assumes
        return layout;
    }

    const newLayout: AbsoluteBlockItem[] = [];

    try {
        splitGridByRowsAndColumns({
            origin: { x: 0, y: 0 },
            nodes,
            layout,
            newLayout,
            gridWidth: canvasWidth,
            gridHeight: canvasHeight,
        });
    } catch (e) {
        return layout;
    }

    if (newLayout.length !== layout.length) {
        // an error occurred - return original layout
        return layout;
    }
    return newLayout;
};
