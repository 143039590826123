import { pick, variables } from '@splunk/themes';
import { _ } from '@splunk/ui-utils/i18n';
import { COLOR_OR_TOKEN_PATTERN_WITH_RGBA, getPattern } from '@splunk/visualizations-shared/schemaUtils';
import { DataContract } from '../common/interfaces/DataContract';
import { DefaultContext } from '../common/interfaces/DefaultContext';
import { EditorConfig } from '../common/interfaces/Editor';
import { OptionsSchema } from '../common/interfaces/OptionsSchema';
import { ThemedDefaults } from '../common/interfaces/ThemedDefaults';
import { VizBehavior } from '../common/interfaces/VizBehavior';
import { VizCategory } from '../common/interfaces/VizCategory';
import { VizConfig } from '../common/interfaces/VizConfig';
import { VizSize } from '../common/interfaces/VizSize';
import { enhanceConfig } from '../common/utils/configUtils';
import getBackgroundColorEditor from '../common/editorConfig/BackgroundColor';

const FONT_OPTIONS = new Set(['extraSmall', 'small', 'default', 'large', 'extraLarge']);

const dataContract: DataContract = {
    requiredDataSources: [],
    optionalDataSources: [
        // Note: SCP-14090 disable ui for optional datasources. We may need to add it back later
        // {
        //     name: 'annotation',
        //     description: 'DataSource that populate event annotations',
        // },
    ],
    initialRequestParams: {
        primary: { offset: 0, count: 10000 },
    },
};

const size: VizSize = {
    initialWidth: 300,
    initialHeight: 300,
};

const defaultContext: DefaultContext = {};

const optionsSchema: OptionsSchema = {
    backgroundColor: {
        description: 'Specify the background color using a Hex code such as "#FF0000".',
        type: 'string',
        default: 'transparent',
        pattern: COLOR_OR_TOKEN_PATTERN_WITH_RGBA,
    },
    customFontSize: {
        description:
            'Specify the font size (in pixels) you would like to use for unformatted text when `fontSize` is set to `custom`. Header elements are calculated with the following formula: h1 = 2 × `customFontSize`, h2 = 1.5 × `customFontSize`, h3 = 1.25 × `customFontSize`',
        default: 14,
        type: 'number',
    },
    fontColor: {
        description:
            'Specify the text color using Hex codes or RGBA values, such as "#FF0000" or "rgba(25,12,13,0.1)". The default for enterprise light is "#3c444d". The default for enterprise dark is "#FFFFFF". The default for prisma dark is "rgba(255, 255, 255, 0.7)".',
        type: 'string',
        default: '> themes.defaultFontColor',
        pattern: COLOR_OR_TOKEN_PATTERN_WITH_RGBA,
    },
    fontFamily: {
        default: 'Splunk Platform Sans',
        pattern: getPattern([
            'Splunk Platform Sans',
            'Splunk Data Sans',
            'Splunk Platform Mono',
            'Arial',
            'Helvetica',
            'Times New Roman',
            'Comic Sans MS',
        ]),
        description:
            'Specify the font family you would like to use for markdown content, such as "Comic Sans MS". Note: the font needs to be available to your end user.',
        type: 'string',
    },
    fontSize: {
        description:
            'Specify the font size you would like to use for markdown content. Font sizes range from extra small (10px for unformatted text) to extra large (18px for unformatted text) with the default being 14px for unformatted text.',
        type: 'string',
        default: 'default',
        pattern: getPattern(['extraSmall', 'small', 'default', 'large', 'extraLarge', 'custom']),
    },
    markdown: {
        description: 'Add text using basic markdown syntax.',
        type: 'string',
    },
    rotation: {
        description: 'Specify the angle of rotation of the markdown content in degrees.',
        type: 'number',
        default: 0,
    },
};

const themes: ThemedDefaults = {
    defaultFontColor: props =>
        pick({
            enterprise: {
                dark: variables.textColor(props),
                light: variables.textColor(props),
            },
            prisma: variables.contentColorDefault(props),
        })(props),
};

const editorConfig: EditorConfig[] = [
    {
        label: _('Color and style'),
        layout: [
            [
                {
                    label: _('Font'),
                    option: 'fontColor',
                    editor: 'editor.color',
                    editorProps: {
                        labelPosition: 'top',
                        themes,
                    },
                },
                ...getBackgroundColorEditor({ label: 'Background', themes }),
            ],
            [
                {
                    label: _('Font family'),
                    option: 'fontFamily',
                    editor: 'editor.select',
                    editorProps: {
                        values: [
                            { value: 'Splunk Platform Sans' },
                            { value: 'Splunk Data Sans' },
                            { value: 'Splunk Platform Mono' },
                            { value: 'Arial' },
                            { value: 'Helvetica' },
                            { value: 'Times New Roman' },
                            { value: 'Comic Sans MS' },
                        ],
                    },
                },
            ],
            [
                {
                    label: _('Font size'),
                    option: 'fontSize',
                    editor: 'editor.select',
                    editorProps: {
                        values: [
                            { label: _('Extra Small'), value: 'extraSmall' },
                            { label: _('Small'), value: 'small' },
                            { label: _('Default'), value: 'default' },
                            { label: _('Large'), value: 'large' },
                            { label: _('Extra Large'), value: 'extraLarge' },
                            { label: _('Custom'), value: 'custom' },
                        ],
                    },
                },
                {
                    label: _('Custom font size (px)'),
                    option: 'customFontSize',
                    editor: 'editor.number',
                    editorProps: {
                        min: 0,
                        labelPosition: 'top',
                    },
                    showEditor: ({ options }) => options.fontSize && !FONT_OPTIONS.has(options.fontSize),
                },
            ],
            [
                {
                    label: _('Rotation (degrees)'),
                    option: 'rotation',
                    editor: 'editor.number',
                    editorProps: {
                        min: -360,
                        max: 360,
                    },
                },
            ],
        ],
    },
    {
        label: _('Markdown content'),
        open: true,
        layout: [
            [
                {
                    label: '',
                    option: 'markdown',
                    editor: 'editor.markdown',
                },
            ],
        ],
    },
];

/**
 * visualization configuration
 */
const config: VizConfig = {
    /**
     * unique viz key
     */
    key: 'splunk.markdown',
    /**
     * viz name
     */
    name: 'Markdown',
    category: VizCategory.COMPARISONS,
    /**
     * viz icon
     */
    icon: null,
    dataContract,
    size,
    defaultContext,
    optionsSchema,
    editorConfig,
    events: {
        'markdown.click': {
            description: 'triggered when user clicks on markdown area not formatted as a link',
        },
    },
    supports: [VizBehavior.DYNAMIC_OPTIONS, VizBehavior.EVENTS],
    themes,
};

export default enhanceConfig(config);
