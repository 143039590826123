import * as React from 'react';
import { _ } from '@splunk/ui-utils/i18n';
import SVG from '@splunk/react-icons/SVGEnterprise';

const MarkerGauge = (props: React.ComponentProps<typeof SVG>): React.ReactElement<SVGElement> => (
    <SVG screenReaderText={_('Marker Gauge')} fill="currentColor" viewBox="0 0 150 100" {...props}>
        <g>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M59 34C56.7909 34 55 35.7909 55 38L55 40C55 42.2091 56.7909 44 59 44L59 55H91V46.6458C91.7329 47.4762 92.8053 48 94 48L114 48C116.209 48 118 46.2091 118 44V34C118 31.7909 116.209 30 114 30L94 30C92.8053 30 91.7329 30.5238 91 31.3542V23L59 23V34ZM59 36C57.8954 36 57 36.8954 57 38V40C57 41.1046 57.8954 42 59 42H92V44C92 45.1046 92.8954 46 94 46L114 46C115.105 46 116 45.1046 116 44V34C116 32.8954 115.105 32 114 32L94 32C92.8954 32 92 32.8954 92 34V36L59 36Z"
            />
            <path d="M59 70L91 70L91 91H59L59 70Z" />
            <path d="M91 57H59L59 68L91 68V57Z" />
            <path d="M91 10L59 10V21L91 21V10Z" />
        </g>
    </SVG>
);

export default MarkerGauge;
