import React from 'react';
import { isRiskyCommandError } from '@splunk/dashboard-utils';
import RiskyWarningPopoverContent from './RiskyWarningPopoverContent';
import GenericPopoverContentFactory from './PopoverContentFactory';
import type { PopoverContentFactoryProps } from './PopoverContentFactory';

interface EnterpriseContentFactoryProps extends PopoverContentFactoryProps {
    componentId: string;
    onRequestClose: () => void;
    type?: string;
}

export default ({
    dataSource,
    message,
    componentId,
    onRequestClose,
    type = 'visualization',
}: EnterpriseContentFactoryProps) => {
    if (!dataSource) {
        return <GenericPopoverContentFactory message={message} />;
    }

    const statusMessage = dataSource.error
        ? dataSource.error?.message
        : dataSource.meta?.statusMessage;

    if (statusMessage && isRiskyCommandError(statusMessage)) {
        return (
            <RiskyWarningPopoverContent
                dataSource={dataSource}
                componentId={componentId}
                onRequestClose={onRequestClose}
                type={type}
            />
        );
    }

    return (
        <GenericPopoverContentFactory
            dataSource={dataSource}
            message={message}
        />
    );
};
