import React from 'react';
import T from 'prop-types';
import { _ } from '@splunk/ui-utils/i18n';
import SVG, {
    type SVGPropsOptionalViewBox,
} from '@splunk/react-icons/SVGEnterprise';

/* eslint-disable max-len */
export default function NumberInput({
    screenReaderText,
    ...props
}: SVGPropsOptionalViewBox): ReturnType<typeof SVG> {
    return (
        <SVG viewBox="0 0 25 24" screenReaderText={screenReaderText} {...props}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M11.4928 4.2519C11.5617 3.70393 11.1734 3.20383 10.6254 3.13488C10.0775 3.06594 9.57736 3.45426 9.50842 4.00222L8.996 8.07471H4.55273C4.00045 8.07471 3.55273 8.52242 3.55273 9.07471C3.55273 9.62699 4.00045 10.0747 4.55273 10.0747H8.74436L8.24107 14.0747H4.55273C4.00045 14.0747 3.55273 14.5224 3.55273 15.0747C3.55273 15.627 4.00045 16.0747 4.55273 16.0747H7.98942L7.50842 19.8975C7.43947 20.4455 7.82779 20.9456 8.37576 21.0146C8.92372 21.0835 9.42383 20.6952 9.49277 20.1472L10.0052 16.0747H13.9894L13.5084 19.8975C13.4395 20.4455 13.8278 20.9456 14.3758 21.0146C14.9237 21.0835 15.4238 20.6952 15.4928 20.1472L16.0052 16.0747H20.4481C21.0003 16.0747 21.4481 15.627 21.4481 15.0747C21.4481 14.5224 21.0003 14.0747 20.4481 14.0747H16.2568L16.7601 10.0747H20.4481C21.0003 10.0747 21.4481 9.62699 21.4481 9.07471C21.4481 8.52242 21.0003 8.07471 20.4481 8.07471H17.0118L17.4928 4.2519C17.5617 3.70393 17.1734 3.20383 16.6254 3.13488C16.0775 3.06594 15.5774 3.45426 15.5084 4.00222L14.996 8.07471H11.0118L11.4928 4.2519ZM14.2411 14.0747L14.7444 10.0747H10.7601L10.2568 14.0747H14.2411Z"
                fill="currentColor"
            />
        </SVG>
    );
}

NumberInput.propTypes = {
    screenReaderText: T.string,
};

NumberInput.defaultProps = {
    screenReaderText: _('Number Input'),
};
