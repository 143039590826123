export interface Interval {
    min: number;
    max: number;
    resolution?: number;
}
const isInterval = function isInterval(object: Interval | number): boolean {
    return (object as Interval).min != null && (object as Interval).max != null;
};

export const toInterval = function toInterval(object: Interval | number): Interval {
    if (isInterval(object)) {
        return object as Interval;
    }

    return { min: 0, max: object as number };
};

export class Scale {
    /**
     * beginning of the target window in source coordinates
     */
    public offset: number;

    public scaleFactor: number;

    public constructor() {
        this.offset = 0;
        this.scaleFactor = 1;
    }

    /**
     *
     * @param {number} scaleFactor the ratio of target per source
     * @param {number} targetStart The source coordinate for which the target coordinate is 0
     * @return {this}
     */
    public update(scaleFactor: number, targetStart: number): Scale {
        this.offset = targetStart;
        this.scaleFactor = scaleFactor;
        return this;
    }

    public source2Target(sourceCoord: number): number {
        return (sourceCoord - this.offset) * this.scaleFactor;
    }

    public target2Source(targetCoord: number): number {
        return targetCoord / this.scaleFactor + this.offset;
    }

    /**
     * updates the scale and offset so that the specified source range is mapped to the target range.
     * If a number is specified as the target range, it is interpreted as the width of an interval starting at 0.
     * @param {Interval} sourceRange
     * @param {Interval | number} targetRange
     */
    public fit(sourceRange: Interval, targetRange: Interval | number): void {
        const targetDistance = sourceRange.max - sourceRange.min;
        const interval = toInterval(targetRange);

        const sourceDistance = interval.max - interval.min;
        const sourceOffset = interval.min;

        const scale = sourceDistance / targetDistance;
        this.update(scale, sourceRange.min - (sourceOffset || 0) / scale);
    }

    /**
     * adjusts scale factor and offset so that the target value for the specified source center does not change
     * @param {number} sourceCenter
     * @param {number} newScale
     */
    public zoomAroundSource(sourceCenter: number, newScale: number): void {
        this.offset =
            (sourceCenter * (newScale - this.scaleFactor) + this.offset * this.scaleFactor) / newScale;
        this.scaleFactor = newScale;
    }

    /**
     * moves the target range by the specified amount in target coordinates
     * @param {number} deltaTarget
     */
    public moveTargetBy(deltaTarget: number): void {
        this.offset += deltaTarget / this.scaleFactor;
    }
}
