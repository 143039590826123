import * as React from 'react';
import { SyntheticEvent } from 'react';

import Popover from '@splunk/react-ui/Popover';
import ControlGroup from '@splunk/react-ui/ControlGroup';
import Button from '@splunk/react-ui/Button';
import Text from '@splunk/react-ui/Text';
import Message from '@splunk/react-ui/Message';
import Select from '@splunk/react-ui/Select';
import Link from '@splunk/react-ui/Link';
import Heading from '@splunk/react-ui/Heading';
import { noop } from 'lodash';
import { _ } from '@splunk/ui-utils/i18n';
import styled from 'styled-components';
import { parseNumber } from '@splunk/visualizations-shared/numberUtils';
import pick from '@splunk/themes/pick';
import type { TimeInterval } from '../EventUtils';
import { getCustomTimeInterval, getPredefinedTimeInterval } from '../EventUtils';

export interface TimeControlsPopoverProps {
    open: boolean;
    anchor?: HTMLElement;
    time: string;
    handleTooltipClose: () => void;
    onTimeDrilldown: (e: SyntheticEvent, timeInterval: TimeInterval) => void;
}

// custom popover sizing for Enterprise vs. Prisma themes - additional px for Prisma theme to fit controls
const TimePopoverContainer = styled.div`
    padding: 20px;
    width: ${props =>
        pick({
            enterprise: '372px',
            prisma: '422px',
        })(props)};

    .time-drilldown-header {
        margin: 0px;
    }

    [data-test='nearby-controls'] {
        margin: 0px;
        width: ${props =>
            pick({
                enterprise: '289.75px',
                prisma: '329.75px',
            })(props)};
    }
`;

const TimeTable = styled.table`
    width: 100%;
    display: table;
    border-collapse: separate;
    box-sizing: border-box;
    text-indent: initial;
    border-spacing: 2px;
    border-color: grey;
`;

const NearbyValueRow = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: center;
`;

const SectionHeading = styled(Heading)`
    margin-top: 20px;
`;

const rangeTypeInputStyle: React.CSSProperties = { minWidth: 80 };
const rangeAmountInputStyle: React.CSSProperties = { width: 54.88, minWidth: 48 };
const rangeUnitInputStyle: React.CSSProperties = { width: 154.88, textAlign: 'center' };
const applyButtonStyle: React.CSSProperties = { marginLeft: 10 };

const TimeControlsPopover = (props: TimeControlsPopoverProps): React.ReactElement => {
    const { open, anchor, time, handleTooltipClose, onTimeDrilldown } = props;

    // nearby events controls default to +/- 5 second(s)
    const [rangeType, setRangeType] = React.useState('plusminus');
    const [rangeAmount, setRangeAmount] = React.useState('5');
    const [rangeUnit, setRangeUnit] = React.useState('s');

    // if validation fails, this triggers the error state on the controls to show
    const [isInvalidInput, setInvalidInput] = React.useState(false);

    const errorMessage = _('Value must be an integer greater than 0');

    const handleRangeTypeChange = (e, { value }) => {
        setRangeType(value);
    };

    const handleRangeAmountChange = (e, { value }) => {
        setRangeAmount(value);
    };

    const handleRangeUnitChange = (e, { value }) => {
        setRangeUnit(value);
    };

    const handleSubmit = e => {
        // if the entered range amount is not a number, is not greater than 0, or is not an integer, then it is invalid
        const parsedIntervalAmount = parseNumber(rangeAmount);
        if (
            Number.isNaN(parsedIntervalAmount) ||
            (parsedIntervalAmount as number) <= 0 ||
            !Number.isInteger(parsedIntervalAmount)
        ) {
            setInvalidInput(true);
            return;
        }

        setInvalidInput(false);

        const timeInterval = getCustomTimeInterval({
            time,
            rangeType,
            rangeAmount,
            rangeUnit,
        });

        onTimeDrilldown(e, timeInterval);
    };

    const handleEventIntervalClick = (e, when): void => {
        e.preventDefault();

        const timeInterval = getPredefinedTimeInterval({ time, when });

        onTimeDrilldown(e, timeInterval);
    };

    return (
        <Popover
            open={open}
            defaultPlacement="below"
            anchor={anchor}
            takeFocus
            onRequestClose={handleTooltipClose}
        >
            <TimePopoverContainer data-test="time-controls">
                <Heading level={2} className="time-drilldown-header">
                    _time
                </Heading>
                {isInvalidInput && (
                    <Message type="error" data-test="invalid-time-input-message">
                        {errorMessage}
                    </Message>
                )}
                <SectionHeading level={4}>{_('Events Before or After')}</SectionHeading>
                <TimeTable>
                    <tbody>
                        <tr className="before-after-values">
                            <td>
                                <Link
                                    data-test="before-time"
                                    to="#"
                                    onClick={(e: SyntheticEvent): void => {
                                        handleEventIntervalClick(e, 'before');
                                    }}
                                >
                                    {_('Before this time')}
                                </Link>
                            </td>
                            <td>
                                <Link
                                    data-test="after-time"
                                    to="#"
                                    onClick={(e: SyntheticEvent): void => {
                                        handleEventIntervalClick(e, 'after');
                                    }}
                                >
                                    {_('After this time')}
                                </Link>
                            </td>
                            <td>
                                <Link
                                    data-test="at-time"
                                    to="#"
                                    onClick={(e: SyntheticEvent): void => {
                                        handleEventIntervalClick(e, 'at');
                                    }}
                                >
                                    {_('At this time')}
                                </Link>
                            </td>
                        </tr>
                    </tbody>
                </TimeTable>
                <SectionHeading level={4} className="nearby-header">
                    {_('Nearby Events')}
                </SectionHeading>
                <NearbyValueRow className="nearby-value">
                    <ControlGroup
                        data-test="nearby-controls"
                        controlsLayout="fillJoin"
                        label="Time controls"
                        labelPosition="top"
                        hideLabel
                    >
                        <Select
                            inline
                            value={rangeType}
                            onChange={handleRangeTypeChange}
                            error={isInvalidInput}
                            style={rangeTypeInputStyle}
                            data-test="type-selection"
                        >
                            <Select.Option label="+" value="plus" />
                            <Select.Option label="-" value="minus" />
                            <Select.Option label="+/-" value="plusminus" />
                        </Select>
                        <Text
                            inline
                            value={rangeAmount}
                            onChange={handleRangeAmountChange}
                            error={isInvalidInput}
                            style={rangeAmountInputStyle}
                            data-test="amount-input"
                        />
                        <Select
                            data-test="unit-selection"
                            inline
                            value={rangeUnit}
                            onChange={handleRangeUnitChange}
                            error={isInvalidInput}
                            style={rangeUnitInputStyle}
                        >
                            <Select.Option label="week(s)" value="w" />
                            <Select.Option label="day(s)" value="d" />
                            <Select.Option label="hour(s)" value="h" />
                            <Select.Option label="minute(s)" value="m" />
                            <Select.Option label="second(s)" value="s" />
                            <Select.Option label="millisecond(s)" value="ms" />
                        </Select>
                    </ControlGroup>
                    <Button
                        label={_('Apply')}
                        onClick={handleSubmit}
                        style={applyButtonStyle}
                        data-test="apply-custom-time"
                    />
                </NearbyValueRow>
            </TimePopoverContainer>
        </Popover>
    );
};

TimeControlsPopover.defaultProps = {
    open: false,
    anchor: null,
    time: null,
    handleTooltipClose: noop,
    onTimeDrilldown: noop,
};

export default TimeControlsPopover;
