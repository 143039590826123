import React from 'react';

/**
 * A placeholder based on icon
 */
const IconPlaceholder = ({ icon }) =>
    React.cloneElement(icon, {
        style: {
            left: '50%',
            top: '50%',
            color: 'grey',
            position: 'absolute',
            transform: 'translate(-50%, -50%)',
        },
        width: '50%',
        height: '50%',
    });

export default IconPlaceholder;
