import { _ } from '@splunk/ui-utils/i18n';
import { EditorLayoutConfig } from '../interfaces/Editor';
import { ThemedDefaults } from '../interfaces/ThemedDefaults';

interface RadialBackgroundEditorProps {
    label?: string;
    themes: ThemedDefaults;
    labelPosition?: string;
}

const radialBackground: ({
    label,
    themes,
    labelPosition,
}: RadialBackgroundEditorProps) => EditorLayoutConfig[] = ({ label, themes, labelPosition }) => [
    {
        label: _(label || 'Radial background'),
        option: 'radialBackgroundColor',
        editor: 'editor.color',
        editorProps: {
            labelPosition: labelPosition || 'top',
            themes,
        },
    },
];

export default radialBackground;
