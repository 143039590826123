import type { VisualizationDefinition } from '@splunk/dashboard-types';
import config from '@splunk/visualizations/SingleValueIcon.config';
import { encodingToDynamicOptionsDSL } from '../encoding';
import type { EncodingConfig } from '../encoding';
import {
    removeInvalidOptions,
    renameVizOptions,
    preserveDataSelectionForValueOption,
} from '../utils/migrationUtils';
import { consolidateTrendDisplay } from '../utils/singlevalue';

const validOptions = {
    ...config.optionsSchema,
};

const defaultsToRetain = {
    majorValue: '> primary | seriesByIndex(0) | lastPoint()',
    trendValue: '> primary | seriesByIndex(0) | delta(-2)',
};

const optionsToRename = {
    useTrendUnits: 'shouldAbbreviateTrendValue',
    useThousandSeparators: 'shouldUseThousandSeparators',
    deltaColor: 'trendColor',
    color: 'iconColor',
};

export const migrateVizToSplunkSingleValueIcon = (
    vizDefinition: VisualizationDefinition
): VisualizationDefinition => {
    const { options = {}, encoding = {}, ...otherDefinitionParts } = vizDefinition;
    const migratedVisualization = {
        ...otherDefinitionParts,
        type: 'splunk.singlevalueicon',
        context: {},
        options: {},
    };

    const { options: dataOptions, context: dataContext } = encodingToDynamicOptionsDSL(
        encoding as Record<string, EncodingConfig>
    );

    const migratedDataOptions = {
        ...(dataOptions?.trend && {
            majorValue:
                preserveDataSelectionForValueOption(dataOptions.trend as string, 'lastPoint()') ??
                dataOptions.trend,
            trendValue:
                preserveDataSelectionForValueOption(dataOptions.trend as string, 'delta(-2)') ??
                dataOptions.trend,
        }),
        ...(dataOptions?.fill && {
            backgroundColor:
                preserveDataSelectionForValueOption(dataOptions.fill as string, 'lastPoint()') ??
                dataOptions.fill,
        }),
    };

    const renamedOptions = renameVizOptions(options, optionsToRename);

    const { trendDisplayMode, showTrendIndicator } = renamedOptions;

    const trendDisplay = consolidateTrendDisplay(trendDisplayMode as string, showTrendIndicator as boolean);

    migratedVisualization.options = {
        ...defaultsToRetain,
        ...renamedOptions,
        ...migratedDataOptions, // fill encoding takes precedence over explicit static backgroundColor
        ...(typeof trendDisplay === 'string' && { trendDisplay }),
    };

    migratedVisualization.context = dataContext;

    // remove invalid (and deprecated) options using the optionsSchema for splunk.singlevalueicon as reference
    removeInvalidOptions(migratedVisualization.options, validOptions);

    return migratedVisualization;
};
