import React, { useCallback } from 'react';
import { useFeatureFlags } from '@splunk/dashboard-context';
import {
    selectIsDashboardFullscreen,
    selectMode,
    selectResolvedLayout,
    selectResolvedLayoutIncludingBackgroundImage,
    selectSelectedItems,
    triggerEvent,
    updateLayoutStructure,
    updateSelectedItems,
    useDispatch,
    useSelector,
} from '@splunk/dashboard-state';
import LayoutContainer, {
    type LayoutContainerProps,
} from '../layouts/LayoutContainer';

type ConnectedLayoutProps = Omit<
    LayoutContainerProps,
    | 'handleEvent'
    | 'onLayoutItemsSelect'
    | 'onLayoutStructureChange'
    | 'layout'
    | 'mode'
    | 'selectedItems'
    | 'isDashboardFullscreen'
>;

export default (props: ConnectedLayoutProps) => {
    const dispatch = useDispatch();
    const { enableTokensInBackgroundImage } = useFeatureFlags();

    // mapDispatchToProps
    const handleEvent = useCallback<
        NonNullable<LayoutContainerProps['handleEvent']>
    >(
        (eventType, payload, eventId) =>
            dispatch(triggerEvent(null, eventType, payload, eventId)),
        [dispatch]
    );
    const onLayoutItemsSelect = useCallback<
        LayoutContainerProps['onLayoutItemsSelect']
    >(
        (newSelectedItems) => dispatch(updateSelectedItems(newSelectedItems)),
        [dispatch]
    );
    const onLayoutStructureChange = useCallback<
        NonNullable<LayoutContainerProps['onLayoutStructureChange']>
    >(
        (layoutStructure) => dispatch(updateLayoutStructure(layoutStructure)),
        [dispatch]
    );

    // mapStateToProps
    const layout = useSelector<LayoutContainerProps['layout']>(
        enableTokensInBackgroundImage
            ? selectResolvedLayoutIncludingBackgroundImage
            : selectResolvedLayout
    );
    const mode = useSelector(selectMode);
    // TODO: verify if layouts still need selected items (remove if not)
    const selectedItems = useSelector(selectSelectedItems);

    const isDashboardFullscreen = useSelector(selectIsDashboardFullscreen);

    return (
        <LayoutContainer
            {...props}
            handleEvent={handleEvent}
            onLayoutItemsSelect={onLayoutItemsSelect}
            onLayoutStructureChange={onLayoutStructureChange}
            layout={layout}
            mode={mode}
            selectedItems={selectedItems}
            isDashboardFullscreen={isDashboardFullscreen}
        />
    );
};
