import {
    DEFAULT_CANVAS_WIDTH,
    DEFAULT_CANVAS_HEIGHT,
} from '@splunk/dashboard-utils';
import type {
    AbsoluteLayoutOptions,
    GridLayoutOptions,
} from '@splunk/dashboard-types';

export const absoluteLayoutOptions: Required<
    Pick<AbsoluteLayoutOptions, 'width' | 'height' | 'display'>
> = {
    width: DEFAULT_CANVAS_WIDTH,
    height: DEFAULT_CANVAS_HEIGHT,
    display: 'auto',
};

export const gridLayoutOptions: Required<
    Pick<GridLayoutOptions, 'width' | 'gutterSize'>
> = {
    width: DEFAULT_CANVAS_WIDTH,
    gutterSize: 8,
};
