import type {
    ActionsApi,
    GlobalInputsApi,
    LayoutApi,
    VisualizationApi,
} from '@splunk/dashboard-types';

/**
 * A registry that holds layout, input, and viz apis
 */
export default class ApiRegistry {
    onLayoutApiReady?: () => void;

    private layoutApi?: LayoutApi | null;

    private visualizationApis: Record<string, VisualizationApi>;

    private visualizationActionApis: Record<string, ActionsApi>;

    private globalInputsApi: GlobalInputsApi | null;

    private inputApis: Record<string, HTMLDivElement>;

    private inputActionApis: Record<string, ActionsApi>;

    constructor() {
        this.layoutApi = null;
        this.visualizationApis = {};
        this.visualizationActionApis = {};
        this.globalInputsApi = null;
        this.inputApis = {};
        this.inputActionApis = {};
    }

    registerLayoutApi(layoutApi: LayoutApi) {
        this.layoutApi = layoutApi;
        this.notifyLayoutApiReady();
    }

    registerVisualizationActionsApi(vizId: string, api: ActionsApi) {
        this.visualizationActionApis[vizId] = api;
    }

    registerVisualizationApi(vizId: string, api: VisualizationApi) {
        this.visualizationApis[vizId] = api;
    }

    registerGlobalInputsApi(api: GlobalInputsApi) {
        this.globalInputsApi = api;
    }

    registerInputApi(inputId: string, api: HTMLDivElement) {
        this.inputApis[inputId] = api;
    }

    registerInputActionsApi(inputId: string, api: ActionsApi) {
        this.inputActionApis[inputId] = api;
    }

    removeLayoutApi() {
        this.layoutApi = null;
    }

    removeVisualizationApi(vizId: string) {
        delete this.visualizationApis[vizId];
    }

    removeVisualizationActionsApi(vizId: string) {
        delete this.visualizationActionApis[vizId];
    }

    removeGlobalInputsApi() {
        this.globalInputsApi = null;
    }

    removeInputApi(inputId: string) {
        delete this.inputApis[inputId];
    }

    removeInputActionsApi(inputId: string) {
        delete this.inputActionApis[inputId];
    }

    getLayoutApi() {
        return this.layoutApi;
    }

    getVisualizationApi(vizId: string): VisualizationApi | undefined {
        return this.visualizationApis[vizId];
    }

    getVisualizationActionsApi(vizId: string): ActionsApi | undefined {
        return this.visualizationActionApis[vizId];
    }

    getGlobalInputsApi() {
        return this.globalInputsApi;
    }

    getInputApi(inputId: string): HTMLDivElement | undefined {
        return this.inputApis[inputId];
    }

    getInputActionsApi(inputId: string): ActionsApi | undefined {
        return this.inputActionApis[inputId];
    }

    private notifyLayoutApiReady() {
        if (typeof this.onLayoutApiReady === 'function') {
            this.onLayoutApiReady();
        }
    }

    // teardown is a bit of a strong word as it has a connotation of self-destruction, which this doesn't do.
    teardown() {
        this.cleanup();
    }

    cleanup() {
        this.removeLayoutApi();
        this.removeGlobalInputsApi();
        this.visualizationApis = {};
        this.visualizationActionApis = {};
        this.inputApis = {};
        this.inputActionApis = {};
    }
}
