import React, { useCallback } from 'react';
import styled, { css } from 'styled-components';

import SUICollapsiblePanel from '@splunk/react-ui/CollapsiblePanel';
import { pick, pickVariant, variables } from '@splunk/themes';
import { useSidebarContext } from '@splunk/dashboard-context';
import { customThemeVariables } from '.';

// handle SUI not exporting props types and default props breaking ComponentProps
type ComponentProps<T> = T extends
    | React.ComponentType<infer P>
    | React.Component<infer P>
    ? JSX.LibraryManagedAttributes<T, P>
    : never;

const isPadded = css`
    padding: ${variables.spacingSmall}
        ${pick({
            enterprise: variables.spacingMedium,
            prisma: variables.spacingLarge,
        })};
`;

const StyledContainer = styled.div<{ padded: boolean }>`
    background-color: ${customThemeVariables.sidebarPanelBackgroundColor};
    ${(props) => (props.padded ? isPadded : '')}
`;

// Adding a border to the bottom of the collapsible panel when it is open
const StyledCP = styled(SUICollapsiblePanel)`
    border-bottom: solid
        ${pick({
            enterprise: variables.borderColor,
            prisma: variables.contentColorDisabled,
        })};

    border-bottom-width: ${pickVariant('open', {
        true: {
            enterprise: '1px',
            prisma: 0,
        },
        false: 0,
    })};
`;

export type SidebarCollapsiblePanelProps = ComponentProps<
    typeof SUICollapsiblePanel
> & { padded?: boolean; panelId: string | number };

export const SidebarCollapsiblePanel = ({
    children,
    padded = true,
    panelId,
    defaultOpen,
    ...rest
}: SidebarCollapsiblePanelProps) => {
    const { collapsibleState, onCollapsibleOpen, onCollapsibleClose } =
        useSidebarContext() ?? {};

    const openState = collapsibleState?.[panelId];
    // if this is the initial sidebar load, use the defaultOpen value
    //   if there's already saved collapsible state, use that
    const isOpen = typeof openState === 'undefined' ? !!defaultOpen : openState;

    const handleCollapsibleOpen = useCallback(
        ({ panelId: id }) => {
            onCollapsibleOpen?.(id);
        },
        [onCollapsibleOpen]
    );

    const handleCollapsibleClose = useCallback(
        ({ panelId: id }) => {
            onCollapsibleClose?.(id);
        },
        [onCollapsibleClose]
    );

    return (
        <StyledCP
            {...rest}
            panelId={panelId}
            open={isOpen}
            onRequestOpen={handleCollapsibleOpen}
            onRequestClose={handleCollapsibleClose}
            data-test-open={isOpen}
        >
            <StyledContainer padded={padded}>{children}</StyledContainer>
        </StyledCP>
    );
};
