import { COLOR_OR_TOKEN_PATTERN, getPattern } from '@splunk/visualizations-shared/schemaUtils';

export default {
    unit: { description: 'Specify text to show next to the major value.', type: 'string' },
    unitPosition: {
        default: 'after',
        description: 'Specify whether the unit text should appear before or after the major value.',
        pattern: getPattern(['before', 'after']),
        type: 'string',
    },
    underLabel: { description: 'Specify the text that appears below the major value.', type: 'string' },
    backgroundColor: {
        description:
            'Specify the color to use for the background. The hex value format should be #FFFFFF. The default for light mode is "#ffffff". The default for dark mode is "#171d21". The default for prisma dark mode is "#0b0c0e".',
        pattern: COLOR_OR_TOKEN_PATTERN,
        type: 'string',
    },
    showTrendIndicator: {
        default: true,
        description: 'Specify whether to show or hide the trend indicator.',
        type: 'boolean',
    },
    trendIndicatorPosition: {
        default: 'standard',
        description:
            'Specify where the trend indicator is displayed on a radial graph. Note: When the major value or trend value is too large to fit inside of the graph, the trend is displayed below the graph.',
        pattern: getPattern(['standard', 'below', 'outside']),
        type: 'string',
    },
    useTrendUnits: {
        default: false,
        description:
            'Specify whether to truncate the trend value to 2 significant digits. A magnitude unit will be displayed.',
        type: 'boolean',
    },
    trendDisplayMode: {
        default: 'absolute',
        description: 'Specify whether the trend value is displayed as a percentage or an absolute count.',
        pattern: getPattern(['percent', 'absolute']),
        type: 'string',
    },
    numberPrecision: {
        default: 0,
        maximum: 20,
        minimum: 0,
        description:
            'Specify the number of decimal places to display. For example, to display 3 decimal places, use a value of 3. The maximum value is 20.',
        type: 'number',
    },
    useThousandSeparators: {
        default: true,
        description: 'Specify whether numeric values use commas as thousandths separaters.',
        type: 'boolean',
    },
    radialBackgroundColor: {
        description:
            'Specify the color for the radial background color. The hex value format should be #FFFFFF.',
        pattern: COLOR_OR_TOKEN_PATTERN,
        type: 'string',
    },
    radialStrokeColor: {
        description: 'Specify the color for the radial stroke color. The hex value format should be #FFFFFF.',
        pattern: COLOR_OR_TOKEN_PATTERN,
        type: 'string',
    },
};
