import moment from '@splunk/moment';
import { isNumber as lodashIsNumber, isBoolean, isFinite, isObject, memoize } from 'lodash';
import { isColor } from '@splunk/visualizations-shared/colorUtils';
import { DataType } from '../DataPrimitive';
import { DataPoint } from '../DataPoint';

/**
 * returns true if this dataPoint is a finite number
 * @param dataPoint
 * @returns {*}
 */
export function isNumber(dataPoint): boolean {
    return (
        dataPoint !== null &&
        !isBoolean(dataPoint) &&
        dataPoint !== '' &&
        isFinite(+dataPoint) &&
        lodashIsNumber(+dataPoint)
    );
}

export function isGeoJsonObject(dataPoint): boolean {
    if (!dataPoint) return false;
    let parsedDataPoint;
    try {
        parsedDataPoint = isObject(dataPoint) ? dataPoint : JSON.parse(dataPoint);
    } catch {
        // if JSON.parse fails or throws an error, return false
        return false;
    }
    if (parsedDataPoint == null) {
        // JSON.parse('null'); returns null and does not throw an error
        return false;
    }
    return (
        Object.prototype.hasOwnProperty.call(parsedDataPoint, 'coordinates') &&
        parsedDataPoint.coordinates != null &&
        Array.isArray(parsedDataPoint.coordinates) &&
        Object.prototype.hasOwnProperty.call(parsedDataPoint, 'type') &&
        parsedDataPoint.type === 'MultiPolygon'
    );
}

/**
 *returns OK if data is time
 * @param dataPoint
 * @returns {boolean}
 */
export function isTime(dataPoint): boolean {
    if (!dataPoint) {
        return false;
    }

    // only support time string in following format: https://www.w3.org/TR/NOTE-datetime
    const supportedDateFormats = [
        'YYYY-MM-DD', // HTML5 date
        moment.ISO_8601,
        'YYYY-MM-DDTHH:mm', // HTML5 date local
        'YYYY-MM-DDTHH:mm:ss.SSS', // HTML5 date local milliseconds
        'YYYY-MM-DDTHH:mm:ss', // HTML5 date local seconds
        'YYYY-MM-DD HH:MM',
        'YYYY-MM-DD HH:MM:SS',
        'YYYY-MM-DD HH:MM:SS.SSS',
    ];
    return typeof dataPoint === 'string'
        ? moment(dataPoint, supportedDateFormats, true).isValid()
        : moment(dataPoint).isValid();
}

/**
 * returns OK if data is string
 * @param dataPoint
 * @returns {boolean}
 */
export function isString(dataPoint): boolean {
    return typeof dataPoint === 'string' && dataPoint.length > 0;
}

/**
 * getDataTypeForPoint
 * naive implementation of checking for the data type of a single data point
 * number > time > string > unknown
 * starting with number because a Date.parse(number) is a valid date
 *
 * @param {any} dataPoint
 * @param {object} metaData meta data about the data field
 * @return {string} type
 */
export const getDataTypeForPoint = <T>(dataPoint?: T, metaData?: MetaData): DataType => {
    if (canInferTypeFromMeta(metaData)) {
        return getDataTypeForMeta(metaData);
    }
    return memoizedGetDataTypeForValue(dataPoint);
};

const getDataTypeForValue = <T>(dataPoint?: T): DataType => {
    if (Array.isArray(dataPoint)) {
        if (dataPoint.length > 1 && dataPoint[0] === '##__SPARKLINE__##') {
            return 'sparkline';
        }
        return 'array';
    }
    if (isGeoJsonObject(dataPoint)) {
        return 'geojson';
    }
    if (isObject(dataPoint)) {
        return 'unknown';
    }
    if (isNumber(dataPoint)) {
        return 'number';
    }
    if (isColor(dataPoint)) {
        return 'color';
    }
    if (isTime(dataPoint)) {
        return 'time';
    }
    if (isString(dataPoint)) {
        return 'string';
    }
    if (dataPoint === null) {
        return 'null';
    }
    // objects, etc
    return 'unknown';
};
const memoizedGetDataTypeForValue = memoize(getDataTypeForValue);

export interface MetaData {
    name?: string;
    type?: string;
}

const splTypesToDSLDataTypes = {
    str: 'string',
    num: 'number',
};

/**
 * canInferTypeFromMeta
 * verifies whether a data type can be inferred from meta data
 * @param {object} metaData
 * @return {boolean} whether the data type can be inferred from meta
 */
export const canInferTypeFromMeta = (metaData: MetaData = {}): boolean => {
    const { name, type = '' } = metaData;
    return splTypesToDSLDataTypes[type] !== undefined || name === '_time';
};

/**
 * getDataTypeForMeta
 * returns a data type based on meta data
 * @param {object} metaData
 * @return {string} type
 */
export const getDataTypeForMeta = (metaData: MetaData = {}): DataType => {
    const { name, type } = metaData;
    if (name === '_time') {
        return 'time';
    }
    const typeMatch = splTypesToDSLDataTypes[type];
    if (typeMatch !== undefined) {
        return typeMatch;
    }
    // this case should never be reached
    return 'unknown';
};

/**
 * Apply the following prioritization rule as there could be mixed types in column data based on SPL cmd
 * Type priority :-  geojson > sparkline > array > string > color > number > time > null > unknown
 * @param typeMatches
 * @returns type, the most applicable type for the datapoints
 */
const applyTypePrioritization = (typeMatches: { [k in DataType]: number }): DataType => {
    const typesWithCount: DataType[] = Object.keys(typeMatches).filter(
        key => typeMatches[key] > 0
    ) as DataType[];

    if (typesWithCount.length === 0) {
        return 'unknown';
    }

    if (typesWithCount.length === 1) {
        return typesWithCount[0];
    }
    // Type priority ordered from top to bottom
    const typeOrder = [
        'geojson',
        'sparkline',
        'array',
        'string',
        'color',
        'number',
        'time',
        'null',
        'unknown',
    ] as DataType[];

    for (let i = 0; i < typeOrder.length; i += 1) {
        const key = typeOrder[i];
        if (typesWithCount.find(k => k === key)) {
            return key;
        }
    }
    return 'unknown';
};

/**
 * inferDataTypeFromDataPoints
 * based on the input data points this function determines a hashmap of type and its count
 * and returns type based on a type prioritization logic
 *
 * @param {Array} dataPoints an array of arbitrary size containing data points of any data type
 * @return {string} type, the most applicable type for the datapoints
 */
export const inferDataTypeFromDataPoints = (dataPoints: DataPoint<DataType>[]): DataType => {
    if (!dataPoints) {
        return 'unknown';
    }
    const typeMatches: { [k in DataType]: number } = {
        time: 0,
        number: 0,
        string: 0,
        color: 0,
        unknown: 0,
        array: 0,
        sparkline: 0,
        null: 0,
        geojson: 0,
    };
    dataPoints.forEach((point): void => {
        typeMatches[point.getType()] += 1;
    });
    return applyTypePrioritization(typeMatches);
};

export const dataPointValues = <T>(data: T[]): DataPoint<DataType>[] => {
    return data.map((point: T) => (DataPoint.isDataPoint(point) ? point : DataPoint.fromRaw(point)));
};

/**
 * inferDataTypeFromData
 * @param {*} data
 * @return {string} type of a data set
 */
export const inferDataTypeFromData = <T>(data: T[]): DataType =>
    inferDataTypeFromDataPoints(dataPointValues(data));
