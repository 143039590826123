import * as React from 'react';
import { _ } from '@splunk/ui-utils/i18n';
import SVG from '@splunk/react-icons/SVGEnterprise';

const Bar = (props: React.ComponentProps<typeof SVG>): React.ReactElement<SVGElement> => (
    <SVG screenReaderText={_('Bar')} fill="currentColor" viewBox="0 0 150 100" {...props}>
        <g>
            <path d="M117.444 24H1V30.2316H117.444V24Z" />
            <path d="M149 33.3474H1V39.5789H149V33.3474Z" />
            <path d="M1 42.6947H103.432V48.9263H1V42.6947Z" />
            <path d="M131.863 52.0421H1V58.2737H131.863V52.0421Z" />
            <path d="M1 61.3895H67.2105V67.621H1V61.3895Z" />
            <path d="M99.5368 70.7368H1V76.9684H99.5368V70.7368Z" />
        </g>
    </SVG>
);

export default Bar;
