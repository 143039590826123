import { isEmpty, set } from 'lodash';
import type { VisualizationDefinition } from '@splunk/dashboard-types';
import config from '@splunk/visualizations/Table.config';
import { deepMergeWithArrayPrimitiveOverrides } from '@splunk/visualizations-shared/hocUtils';
import type { EncodingConfig } from '../encoding';
import { encodingToDynamicOptionsDSL } from '../encoding';
import { renameVizOptions } from '../utils/migrationUtils';

interface CellFormatting {
    numberPrecision?: number;
    unit?: string;
    unitPosition?: string;
    useThousandSeparators?: boolean;
}

interface VizTableFormattingOpts {
    headerTextColor?: string;
    headerBackgroundColor?: string;
    numberPrecision?: number;
    rowBackgroundColorEven?: string;
    rowBackgroundColorOdd?: string;
    rowTextColorOdd?: string;
    rowTextColorEven?: string;
    unit?: string;
    unitPosition?: string;
    useThousandSeparators?: boolean;
}

const validOptions = {
    ...config.optionsSchema,
};

// old default configurations should be retained in migrated configuration
const defaultChanges = {
    count: 20,
};

const optionRenames = {
    rowNumbers: 'showRowNumbers',
};

const migrateHeaderVisibility = (oldHeaderVisibility: boolean): string => {
    if (oldHeaderVisibility === undefined) {
        // headerVisibility: 'inline' is the default in splunk.table
        // this corresponds to `showHeader: true`, which is the default in viz.table
        return undefined;
    }
    return oldHeaderVisibility ? 'inline' : 'none';
};

interface Colors {
    oddColor?: string;
    evenColor?: string;
}

/**
 * Helper to migrate alternating color options (text and background) to their corresponding DSL and context configs
 */
const migrateAlternatingColors = (
    colors: Colors,
    optionToConvert: string,
    contextConfigName: string
): {
    contextConfigName?: string;
    context?: string[];
    dsl?: string;
} => {
    // default alternating themed color values as defined in table config
    const themedAlternatingColors = {
        rowBackgroundColors: {
            even: '> themes.rowBackgroundColorEven',
            odd: '> themes.rowBackgroundColorOdd',
        },
        rowColors: {
            even: '> themes.inverseTextColor',
            odd: '> themes.textColor',
        },
    };
    const colorsContext: string[] = [];
    const { oddColor, evenColor } = colors;
    if (oddColor && evenColor) {
        colorsContext.push(...[oddColor, evenColor]);
    } else if (oddColor) {
        colorsContext.push(...[oddColor, themedAlternatingColors[optionToConvert].even]);
    } else if (evenColor) {
        colorsContext.push(...[themedAlternatingColors[optionToConvert].odd, evenColor]);
    }

    if (colorsContext.length) {
        return {
            contextConfigName,
            context: colorsContext,
            dsl: `> table | pick(${contextConfigName})`,
        };
    }

    return {};
};

/**
 * Intermediate helper to translate column-specific formatting in viz.table to their DSL equivalents
 * viz.table defines column-specific formatting via entries specified in options.fields.<columnName>
 *
 * This requires moving numberPrecision, useThousandSeparators, unitPosition, and other formatting options to the config
 * The resultant config is used as the argument for formatByType
 */
const migrateToColumnFormat = (
    fieldsOption: Record<string, CellFormatting>
): {
    columnFormat: Record<string, unknown>;
    columnFormatContext: Record<string, unknown>;
} => {
    const columnFormat: Record<string, unknown> = {};
    const columnFormatContext: Record<string, unknown> = {};

    if (!fieldsOption) {
        return {
            columnFormat,
            columnFormatContext,
        };
    }

    Object.entries(fieldsOption).forEach(([column, formatting]) => {
        // need to prepend `formatted` in case the column has leading digits, which the nearley parser cannot account for
        const contextEntry = `formatted${column}Config`;
        columnFormatContext[contextEntry] = {};
        columnFormat[column] = {
            data: `> table | seriesByName("${column}") | formatByType(${contextEntry})`,
        };

        // all columnFormatting options are optional; if they are not present in the original config, tableFormat should take precedence
        const { unit, unitPosition, numberPrecision, useThousandSeparators } = formatting;
        if (typeof unitPosition === 'string') {
            set(columnFormatContext, [contextEntry, 'number', 'unitPosition'], unitPosition);
            set(columnFormatContext, [contextEntry, 'string', 'unitPosition'], unitPosition);
        }
        if (typeof unit === 'string' || typeof unit === 'number') {
            set(columnFormatContext, [contextEntry, 'number', 'unit'], unit);
            set(columnFormatContext, [contextEntry, 'string', 'unit'], unit);
        }
        if (numberPrecision != null) {
            set(columnFormatContext, [contextEntry, 'number', 'precision'], numberPrecision);
        }
        if (useThousandSeparators != null) {
            set(columnFormatContext, [contextEntry, 'number', 'thousandSeparated'], useThousandSeparators);
        }
    });

    return {
        columnFormat,
        columnFormatContext,
    };
};

/**
 * Intermediate helper to translate table-wide formatting and coloring in viz.table to their DSL equivalents
 *
 * This requires moving numberPrecision, useThousandSeparators, unitPosition, and other formatting/coloring options to the config
 * The resultant formatting and coloring config is used as the argument for the formatByType() and pick() DSL formatters respectively
 */
const migrateToTableFormat = (
    oldOptions: VizTableFormattingOpts = {}
): {
    tableFormat: Record<string, unknown>;
    tableFormatContext: Record<string, unknown>;
} => {
    const tableFormat: Record<string, unknown> = {};
    const tableFormatContext: Record<string, unknown> = {
        formattedConfig: {},
    };

    const {
        unit,
        unitPosition: oldUnitPosition,
        numberPrecision: oldNumberPrecision,
        useThousandSeparators: oldUseThousandSeparators,
    } = oldOptions;
    const unitPosition = typeof oldUnitPosition === 'string' ? oldUnitPosition : 'after';
    const numberPrecision = typeof oldNumberPrecision === 'number' ? oldNumberPrecision : 0;
    const useThousandSeparators =
        typeof oldUseThousandSeparators === 'boolean' ? oldUseThousandSeparators : false;

    set(tableFormatContext, ['formattedConfig', 'number', 'precision'], numberPrecision);
    set(tableFormatContext, ['formattedConfig', 'number', 'thousandSeparated'], useThousandSeparators);
    set(tableFormatContext, ['formattedConfig', 'number', 'unitPosition'], unitPosition);
    set(tableFormatContext, ['formattedConfig', 'string', 'unitPosition'], unitPosition);
    if (typeof unit === 'string' || typeof unit === 'number') {
        set(tableFormatContext, ['formattedConfig', 'number', 'unit'], unit);
        set(tableFormatContext, ['formattedConfig', 'string', 'unit'], unit);
    }

    tableFormat.data = `> table | formatByType(formattedConfig)`;

    const {
        rowBackgroundColorEven,
        rowBackgroundColorOdd,
        rowTextColorOdd,
        rowTextColorEven,
        headerTextColor,
        headerBackgroundColor,
    } = oldOptions;

    const { context: rowColorsContext, dsl: rowColorsDSL } = migrateAlternatingColors(
        { oddColor: rowTextColorOdd, evenColor: rowTextColorEven },
        'rowColors',
        'rowColorsConfig'
    );
    if (rowColorsContext && rowColorsDSL) {
        tableFormatContext.rowColorsConfig = rowColorsContext;
        tableFormat.rowColors = rowColorsDSL;
    }

    const { context: rowBackgroundColorsContext, dsl: rowBackgroundColorsDSL } = migrateAlternatingColors(
        { oddColor: rowBackgroundColorOdd, evenColor: rowBackgroundColorEven },
        'rowBackgroundColors',
        'rowBackgroundColorsConfig'
    );
    if (rowBackgroundColorsContext && rowBackgroundColorsDSL) {
        tableFormat.rowBackgroundColors = rowBackgroundColorsDSL;
        tableFormatContext.rowBackgroundColorsConfig = rowBackgroundColorsContext;
    }
    if (headerTextColor) {
        tableFormat.headerColor = headerTextColor;
    }
    if (headerBackgroundColor) {
        tableFormat.headerBackgroundColor = headerBackgroundColor;
    }

    return {
        tableFormat,
        tableFormatContext,
    };
};

const migrateVizToSplunkTable = (vizDefinition: VisualizationDefinition): VisualizationDefinition => {
    const { encoding = {}, options = {}, ...otherDefinitionParts } = vizDefinition;
    const migratedDefinition: VisualizationDefinition = {
        ...otherDefinitionParts,
        type: 'splunk.table',
        options: {},
        context: {},
    };

    const { options: dataOptions, context: dataContext } = encodingToDynamicOptionsDSL(
        encoding as Record<string, EncodingConfig>
    );

    const renamedOptions = renameVizOptions(options, optionRenames);
    const headerVisibility = migrateHeaderVisibility(renamedOptions.showHeader as boolean);
    const { tableFormat, tableFormatContext } = migrateToTableFormat(renamedOptions);
    const { columnFormat, columnFormatContext } = migrateToColumnFormat(
        renamedOptions.fields as Record<string, CellFormatting>
    );
    const migratedDataOptions = {
        ...(dataOptions?.columns && { table: dataOptions.columns }),
    };
    const formattingOptions = {
        ...(!isEmpty(columnFormat) && { columnFormat }),
        ...(typeof headerVisibility === 'string' && { headerVisibility }),
        tableFormat,
    };
    const migratedOptions = deepMergeWithArrayPrimitiveOverrides(
        {},
        defaultChanges,
        formattingOptions,
        migratedDataOptions,
        renamedOptions
    );

    // remove invalid options
    Object.keys(migratedOptions).forEach(key => {
        if (!validOptions[key]) {
            delete migratedOptions[key];
        }
    });

    migratedDefinition.context = {
        ...dataContext,
        // columnFormat is optional, but tableFormat is required all cases to preserve some viz.table behavior
        ...(!isEmpty(columnFormatContext) && { ...columnFormatContext }),
        ...tableFormatContext,
    };
    migratedDefinition.options = migratedOptions;
    return migratedDefinition;
};

export { migrateVizToSplunkTable };
