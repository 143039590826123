import { AbstractFormatter } from '../Formatter';
import { TypedValue } from '../DataPrimitive';
import { DataSeries } from '../DataSeries';
import { DataPoint } from '../DataPoint';
import { TypeSafeValue } from '../TypeSafeValue';
import EncodingExecutor from '../EncodingExecutor';

/**
 * Formatter which can accept a map or array config.
 *
 * If a map is provided, it will use the DataPoint's field name to pick the corresponding entry in the config.
 *
 * If an array is provided, it cycles through the array of values passed in via the config n times (where n = DataSeries.length) and returns this as the output.
 *
 * For example, if the config contains 2 elements and the DataSeries contains 10 elements, it will loop through the config 5 times, alternating between which element is appended to the output.
 *
 * ```js
 * <SampleViz
 *     context={{
 *         mapConfig: {
 *             bar: 'barLabel',
 *             foo: 'fooLabel',
 *         },
 *         rowBackgroundColors: ['#FF0000', '#00FF00', '#0000FF'],
 *         rowTextColors: ['#110000', '#000011'],
 *     }}
 *     options={{
 *         rowBackgroundColor: '> primary | seriesByIndex(0) | pick(rowBackgroundColors)', // returns ['#FF0000', '#00FF00', '#0000FF', '#FF0000', '#0000FF']
 *         rowTextColor: '> primary | seriesByIndex(0) | pick(rowTextColors)', // returns ['#110000', '#000011', '#110000', '#000011', '#110000']
 *         barOption: '> primary | seriesByIndex(1) | lastPoint() | pick(mapConfig)', // returns 'barLabel'
 *         fooOption: '> primary | seriesByIndex(0) | lastPoint() | pick(mapConfig)', // returns 'fooLabel'
 *     }}
 *     dataSources={{
 *         primary: {
 *             data: {
 *                 columns: [[100, 200, 300, 400, 500], [600, 700, 800, 900, 1000]]
 *                 fields: [{ name: 'foo' }, { name: 'bar' }],
 *             }
 *         }
 *     }}
 * />
 * ```
 */
export class Pick extends AbstractFormatter {
    private config: Record<string, unknown>;

    constructor(formatConfig: any[] | { [key: string]: any }) {
        super();
        // insure that the config is plain old JS objects (does not have DataPrimitives inside it)
        this.config = EncodingExecutor.rawTree(formatConfig);
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    protected formatTypedValue(p: DataPoint, s?: DataSeries, i = 0, j = 0): TypedValue {
        let val;
        if (Array.isArray(this.config)) {
            val = this.config[j % this.config.length];
        } else {
            val = this.config[s.field];
        }
        return TypeSafeValue.fromRaw(val);
    }
}
