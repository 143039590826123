import * as React from 'react';
import Downloader, { IDownloader, DownloadProgress } from './Downloader';

/**
 * The FileDownloader is passed a URL string, and render prop which allows a caller to control what is rendered
 * When the file is completely downloaded. This FileDownloader also displays basic text showing download progress.
 * If you want to supply your own custom renderer or progress and error you can do so as well.
 *
 */
export interface FileDownloaderProps {
    /**
     * The URL to download
     */
    url: string;
    /**
     * The render function that will render when the content is downloaded
     * @returns {React.Component}
     */
    renderContent: (content: string) => React.ReactElement;
    /**
     * An optional function to render progress (defaults to basic text display of progress)
     * @param {DownloadProgress} progress
     * @returns {React.Component}
     */
    renderProgress?: (progress: DownloadProgress) => React.Component;
    /**
     * An optional function to render errors (defaults to basic text display of errors)
     * @param {string} msg
     * @param {Error} error
     * @returns {React.Component}
     */
    renderError?: (msg: string, error?: Error) => React.Component;
    /**
     * A <key>:<Downloader> map for Downloader Component
     */
    protocolHandlers: { [key: string]: IDownloader };
}

export class FileDownloader extends React.PureComponent<
    FileDownloaderProps,
    { content?: string; progress?: DownloadProgress; error?: string }
> {
    constructor(props: FileDownloaderProps) {
        super(props);
        this.state = {};
    }

    componentDidMount(): void {
        this.initiateDownload();
    }

    componentDidUpdate(prevProps: FileDownloaderProps): void {
        if (this.props.url !== prevProps.url) {
            this.initiateDownload();
        }
    }

    initiateDownload(): void {
        this.setState({});
        const { url } = this.props;

        const downloader = new Downloader(this.props.protocolHandlers);
        downloader.downloadWithProgress(
            url,
            (content: string): void => this.setState({ content }), // done downloading
            (progress: DownloadProgress): void => {
                this.setState({ progress });
            },
            (msg: string): void => {
                this.setState({ error: msg });
            }
        );
    }

    render(): JSX.Element | React.Component {
        const {
            url,
            renderContent,
            renderProgress,
            renderError = (msg: string): JSX.Element => <div>{msg}</div>,
        } = this.props;
        const {
            content,
            progress = { message: `Downloading ${url}...`, bytesDownloaded: 0 },
            error,
        } = this.state;
        if (content) {
            return renderContent(content); // render prop
        }
        if (error) {
            return renderError(error);
        }
        return typeof renderProgress === 'function' ? renderProgress(progress) : <></>;
    }
}
