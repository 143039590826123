import { _ } from '@splunk/ui-utils/i18n';

/**
 * getInflection
 * calculate and return correct singular, plural, or none case depending on the amount of the object
 *
 * @param {Number} amount
 * @param {String} singular
 * @param {String} plural
 * @param {String} none
 * @return {String}
 */
export const getInflection = (
    amount: number,
    singular: string,
    plural: string,
    none = ''
): string => {
    let text = none;
    if (amount === 1) {
        text = `${amount} ${singular}`;
    } else if (amount > 1) {
        text = `${amount} ${plural}`;
    }
    return text;
};

/**
 * getWarningTextForDataSource
 * Returns warning text for a datasource based on visualizations and chain searches attached
 *
 * @param {Number} numViz
 * @param {Number} numChainSearch
 * @param {Number} numInputsInUse
 * @return {Object}
 */
export const getWarningTextForDataSource = (
    numViz: number,
    numChainSearch: number,
    numInputsInUse = 0
) => {
    const vizText = getInflection(
        numViz,
        _('visualization'),
        _('visualizations')
    );
    const chainSearchText = getInflection(
        numChainSearch,
        _('chain search'),
        _('chain searches')
    );
    const inputText = getInflection(numInputsInUse, _('input'), _('inputs'));
    return { vizText, chainSearchText, inputText };
};
