/* eslint-disable class-methods-use-this */
import { AbstractFormatter } from '../Formatter';
import { DataType, TypedValue } from '../DataPrimitive';
import { TypeSafeValue } from '../TypeSafeValue';
import { DataPoint } from '../DataPoint';

/**
 * Formatter that returns the DataType for each element within the given DataSeries.
 *
 * ```js
 * <SampleViz
 *     options={{
 *         option1: '> primary | seriesByIndex(0) | type()' // returns ['number', 'number', 'number']
 *         option2: '> primary | seriesByIndex(1) | type()' // returns ['string', 'string', 'string']
 *     }}
 *     dataSources={{
 *         data: {
 *             primary: {
 *                 columns: [[100, 200, 300], ['string1', 'string2', 'string3']]
 *                 fields: [{ name: 'foo' }, { name: 'bar' }]
 *             }
 *         }
 *     }}
 * />
 * ```
 */
export class Type extends AbstractFormatter {
    protected formatTypedValue(p: DataPoint<'number' | 'string'>): TypedValue<DataType> {
        if (p && p.getRawValue() === null) {
            // For null datapoint value return 'null' type
            return TypeSafeValue.from({ type: 'string', value: 'null' });
        }
        return TypeSafeValue.fromRaw(TypeSafeValue.fromRaw(p.getRawValue()).type);
    }
}
