import * as React from 'react';
import * as T from 'prop-types';
import { withTheme } from 'styled-components';

import PunchcardBubble from './PunchcardBubble';

import { BUBBLE_LABELS_TYPES, isSomethingSelected, isBubbleSelected } from './utils/punchcardUtils';

interface PunchcardRowProps {
    showBubbleLabels: BUBBLE_LABELS_TYPES;
    category: string[];
    colors: string[];
    handleMouseEnter: (...args: any) => void;
    handleMouseLeave: (...args: any) => void;
    maxSizeValue: number;
    opacities: number[];
    radiusValues: number[];
    selectedCategory: string | number;
    selectedX: string | number;
    selectedY: string | number;
    showMaxValuePulsation: boolean;
    size: number[];
    x: (string | number)[];
    xScale: (...args: any) => number;
    yPosition: number;
    yValue: string | number;
    handlePointClick?: (...args: any) => void;
}

const PunchcardRow = (props: PunchcardRowProps): React.ReactElement => {
    const {
        showBubbleLabels,
        category,
        colors,
        handleMouseEnter,
        handleMouseLeave,
        maxSizeValue,
        opacities,
        radiusValues,
        selectedCategory,
        selectedX,
        selectedY,
        showMaxValuePulsation,
        size,
        x,
        xScale,
        yPosition,
        yValue,
        handlePointClick,
    } = props;

    return (
        <g>
            {x.map((xValue, index) => {
                const sizeValue = size[index];
                const categoryValue = category[index];
                const colorValue = colors[index];
                const cx = xScale(xValue);
                const r = radiusValues[index];
                const fillOpacity = opacities[index];

                const hover =
                    isSomethingSelected({ selectedX, selectedY, selectedCategory }) &&
                    isBubbleSelected({
                        selectedX,
                        selectedY,
                        selectedCategory,
                        xValue,
                        yValue,
                        categoryValue,
                    });
                const muted = isSomethingSelected({ selectedX, selectedY, selectedCategory }) && !hover;

                const showBubbleLabel =
                    showBubbleLabels === BUBBLE_LABELS_TYPES.ALL ||
                    (showBubbleLabels === BUBBLE_LABELS_TYPES.MAX && maxSizeValue === sizeValue);

                return (
                    <PunchcardBubble
                        key={`${xValue}-${yValue}-${sizeValue}-${categoryValue}`}
                        showBubbleLabel={showBubbleLabel}
                        color={colorValue}
                        cx={cx}
                        cy={yPosition}
                        fillOpacity={fillOpacity}
                        handleMouseEnter={() => {
                            handleMouseEnter(xValue, yValue, categoryValue);
                        }}
                        handleMouseLeave={handleMouseLeave}
                        hover={hover}
                        muted={muted}
                        pulsate={showMaxValuePulsation && sizeValue === maxSizeValue}
                        value={sizeValue}
                        r={r}
                        handlePointClick={handlePointClick}
                    />
                );
            })}
        </g>
    );
};

const propTypes: Record<keyof PunchcardRowProps, T.Validator<any>> = {
    showBubbleLabels: T.oneOf(Object.values(BUBBLE_LABELS_TYPES)),
    category: T.arrayOf(T.string),
    colors: T.arrayOf(T.string),
    handleMouseEnter: T.func,
    handleMouseLeave: T.func,
    maxSizeValue: T.number,
    opacities: T.arrayOf(T.number),
    radiusValues: T.arrayOf(T.number),
    selectedCategory: T.oneOfType([T.string, T.number]),
    selectedX: T.oneOfType([T.string, T.number]),
    selectedY: T.oneOfType([T.string, T.number]),
    showMaxValuePulsation: T.bool,
    size: T.arrayOf(T.number),
    x: T.arrayOf(T.oneOfType([T.string, T.number])).isRequired,
    xScale: T.func,
    yPosition: T.number,
    yValue: T.oneOfType([T.string, T.number]),
    handlePointClick: T.func,
};

const defaultProps: Record<keyof PunchcardRowProps, any> = {
    showBubbleLabels: null,
    category: null,
    colors: null,
    handleMouseEnter: () => {},
    handleMouseLeave: () => {},
    maxSizeValue: null,
    opacities: null,
    radiusValues: null,
    selectedCategory: null,
    selectedX: null,
    selectedY: null,
    showMaxValuePulsation: true,
    size: null,
    // eslint-disable-next-line react/default-props-match-prop-types
    x: null,
    xScale: null,
    yPosition: null,
    yValue: null,
    handlePointClick: () => {},
};

PunchcardRow.propTypes = propTypes;
PunchcardRow.defaultProps = defaultProps;

export default withTheme(PunchcardRow as any);
