import React from 'react';
import { _ } from '@splunk/ui-utils/i18n';
import SVG, {
    type SVGPropsOptionalViewBox,
} from '@splunk/react-icons/SVGEnterprise';

/* eslint-disable max-len */
export default function Fullscreen(
    props: SVGPropsOptionalViewBox
): ReturnType<typeof SVG> {
    return (
        <SVG screenReaderText={_('Fullscreen')} viewBox="0 0 24 24" {...props}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M18 11C18 11.5523 18.4477 12 19 12C19.5523 12 20 11.5523 20 11V5.5C20 4.67157 19.3284 4 18.5 4H13.0014C12.4491 4 12.0014 4.44772 12.0014 5C12.0014 5.55228 12.4491 6 13.0014 6H18V11ZM6.00146 13C6.00146 12.4477 5.55375 12 5.00146 12C4.44918 12 4.00146 12.4477 4.00146 13V18.5C4.00146 19.3284 4.67304 20 5.50146 20H11.0001C11.5524 20 12.0001 19.5523 12.0001 19C12.0001 18.4477 11.5524 18 11.0001 18H6.00146V13Z"
                fill="currentColor"
            />
        </SVG>
    );
}
