import type {
    Dispatch,
    MutableRefObject,
    Ref,
    SetStateAction,
    MouseEvent,
} from 'react';
import type {
    Coordinate,
    SelectedItem,
    StructureItemType,
} from '@splunk/dashboard-types';
import {
    EVENT_MOUSE_DOWN_ON_HANDLE,
    EVENT_MOUSE_DOWN_ON_VIZ_WITH_HANDLE,
    useEventCallback,
    useEventListener,
    type DragHandleEvent,
} from '@splunk/dashboard-ui';
import { computeRelativePosition } from '../utils/layoutUtils';

export interface MouseDownWithHandleProps {
    handleMouseDownOnItem: (event: MouseEvent, blockItem: SelectedItem) => void;
    canvasRef: Ref<HTMLDivElement>;
    scale?: number;
    setStartPosition: Dispatch<SetStateAction<null | Coordinate>>;
    movable: boolean;
    setIsMoving: Dispatch<SetStateAction<boolean>>;
    isMovingRef?: MutableRefObject<boolean>;
}

export const useMouseDownWithHandleEventListeners = ({
    handleMouseDownOnItem,
    canvasRef,
    scale,
    setStartPosition,
    movable,
    setIsMoving,
    isMovingRef,
}: MouseDownWithHandleProps) => {
    // The two handle functions regarding handle are literally the same as
    // handleMouseDown above but split up by moving logic vs selecting logic
    const handleMouseDownOnVizWithHandle = useEventCallback(
        (e: DragHandleEvent) => {
            const { vizId, initialEvent, type } = e.detail;
            const pos = computeRelativePosition(initialEvent, canvasRef, scale);
            // always track start position
            setStartPosition(pos);

            const block = {
                id: vizId,
                type: type as StructureItemType,
            };

            handleMouseDownOnItem(initialEvent, block);
        }
    );

    const handleMouseDownOnHandle = useEventCallback((e: DragHandleEvent) => {
        const { vizId, initialEvent, type } = e.detail;
        const pos = computeRelativePosition(initialEvent, canvasRef, scale);
        // always track start position
        setStartPosition(pos);

        const block = {
            id: vizId,
            type: type as StructureItemType,
        };

        // mouse down on an item, set it to selected and start moving.
        handleMouseDownOnItem(initialEvent, block);

        if (movable) {
            setIsMoving(true);
            if (isMovingRef) {
                // eslint-disable-next-line no-param-reassign
                isMovingRef.current = true;
            }
        }
    });

    useEventListener({
        eventName: EVENT_MOUSE_DOWN_ON_VIZ_WITH_HANDLE,
        target: document,
        listener:
            handleMouseDownOnVizWithHandle as EventListenerOrEventListenerObject,
    });

    useEventListener({
        eventName: EVENT_MOUSE_DOWN_ON_HANDLE,
        target: document,
        listener: handleMouseDownOnHandle as EventListenerOrEventListenerObject,
    });
};
