import * as React from 'react';
import * as T from 'prop-types';
import { PureSvgChoroplethDynamic } from '@splunk/visualizations/ChoroplethSvg';
import Message from '@splunk/visualizations-shared/Message';
import { _ } from '@splunk/ui-utils/i18n';

export const SvgChoroplethDynamic = props => {
    const { width, height, svg } = props;
    if (!svg) {
        return (
            <Message
                data-test="choropleth-svg-message"
                width={width}
                height={height}
                message={_('No literal SVG is provided')}
            />
        );
    }
    return <PureSvgChoroplethDynamic {...props} />;
};
SvgChoroplethDynamic.propTypes = {
    width: T.oneOfType([T.string, T.number]),
    height: T.oneOfType([T.string, T.number]),
    svg: T.string,
};

SvgChoroplethDynamic.defaultProps = {
    width: '100%',
    height: 250,
};

export default SvgChoroplethDynamic;
