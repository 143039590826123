import T from 'prop-types';
import { chain, each, filter, get, max, min, reduce } from 'lodash';
import React, { Component } from 'react';
import styled, { css } from 'styled-components';
import chroma from 'chroma-js';
import Table from '@splunk/react-ui/Table';
import BaseVisualization from '@splunk/dashboard-visualizations/common/BaseVisualization';
import { getRequestParams } from '@splunk/dashboard-visualizations/utils/propUtils';
import {
    isTimeField,
    isNumericArray,
    isSparklineArray,
} from '@splunk/dashboard-visualizations/utils/dataUtils';
import { getPaginationInformation } from '@splunk/dashboard-visualizations/utils/paginationUtils';
import { computeGranularity } from '@splunk/dashboard-visualizations/utils/timeUtils';
import DataSet from '@splunk/dashboard-visualizations/utils/DataSet';
import { getPercentiles } from '@splunk/dashboard-visualizations/utils/numberUtils';
import { isNumerial, parseNumber } from '@splunk/react-visualizations/utils/numberUtils';
import VisualizationEvent from '@splunk/dashboard-visualizations/common/VisualizationEvent';
import { toMargin, toPx } from '@splunk/visualizations-shared/style';
import variables from '@splunk/themes/variables';
import { enterprise } from '@splunk/visualization-themes/variables';
import IconPlaceholder from '@splunk/dashboard-visualizations/common/IconPlaceholder';
import { Table as TableIcon } from '@splunk/visualization-icons';
import { pick } from '@splunk/themes';
import { parse, dataContract } from '@splunk/visualization-encoding-parsers/TableParser';
import { withVisualizationAndThemeHOCs, withPaginator, withSanitizedOptions } from '../../utils/enhancer';
import SparklineCell from './cells/SparklineCell';
import TimeCell from './cells/TimeCell';
import NumberCell from './cells/NumberCell';
import StringCell from './cells/StringCell';
import ArrayCell from './cells/ArrayCell';
import optionsSchema from './optionsSchema';
import editorConfig from './editorConfig';

/**
 * order matters, they will be eveluated from left to right
 */
const DefaultCellRenders = [TimeCell, SparklineCell, ArrayCell, NumberCell, StringCell];

// if only one alternating color is defined, override row number defaults so that they are in sync (since they will likely appear as alternating)
// TODO: remove after SUI refactor that removes need to define alternating colors inline (should be done via Theme)
const RowNumberCell = styled(Table.Cell)`
    color: ${props =>
        props.hasCustomTextColors
            ? props.textColor
            : pick({
                  enterprise: variables.textColor,
                  prisma: {
                      light: variables.black,
                      dark: variables.white,
                  },
              })(props)};
    background-color: ${props => props.backgroundColor};
`;

const NormalTableContainer = styled.div`
    position: relative;
    overflow: hidden;
    ${props => toMargin(props.tableMargin)};
`;
const FixedHeightTableContainer = styled(NormalTableContainer)`
    position: absolute;
    margin: 0px;
    top: ${props => toPx(props.tableMargin, 15)};
    left: ${props => toPx(props.tableMargin, 15)};
    right: ${props => toPx(props.tableMargin, 15)};
    bottom: ${props => toPx(props.isPaging ? 42 : props.tableMargin)};
`;

const NonVisibleTableHead = styled(Table.Head)`
    display: none;
`;

// TODO: Replace unsupported hacky style overrides
const StyledTable = styled(Table)`
    ${({ $headerBackgroundColor, $headerTextColor }) => css`
        & [data-test='head-cell'] {
            background-color: ${$headerBackgroundColor};
        }

        & [data-test='head-cell'] > div > span {
            color: ${$headerTextColor};
        }
    `}

    ${pick({
        enterprise: {
            light: css`
                & [data-test='head-cell'] + [data-test='head-cell'] {
                    border-left-color: ${variables.white};
                }
            `,
            dark: css`
                & [data-test='head-cell'] + [data-test='head-cell'] {
                    border-left-color: ${variables.gray20};
                }
            `,
        },
        // prisma: css`
        //     & [data-test='row'] {
        //         border-bottom: 1px solid #43454b; /* TODO: prisma no longer has this border */
        //     }
        // `,
    })};
`;

const defaultPageable = true;

/**
 * Compute data dataOverlayMode for numeric field
 * @param {Object} fieldOptions current field options
 * @param {Object} dataSet current dataset
 * @param {Object} options table formatting options
 */
export const computeOverlay = (fieldOptions, dataSet, options) => {
    const newFieldOptions = {
        ...fieldOptions,
    };
    if (options.dataOverlayMode) {
        const numericFields = filter(
            dataSet.getFields(),
            ({ name }) => newFieldOptions[name].type === 'number'
        );
        // compute data overlay for numeric fields
        const allNumericValues = chain(numericFields)
            .map(({ name }) => dataSet.getColumnByField(name))
            .flatten()
            // be aware that not all value here is numeric
            .filter(isNumerial)
            .map(parseNumber)
            .value();
        let minNumber;
        let maxNumber;
        let bounds;
        // compute overlay
        switch (options.dataOverlayMode) {
            case 'heatmap':
                bounds = getPercentiles(
                    allNumericValues.sort((a, b) => a - b),
                    0.05,
                    0.95
                );
                each(numericFields, ({ name }) => {
                    newFieldOptions[name] = {
                        ...newFieldOptions[name],
                        heatRange: bounds.upper - bounds.lower,
                        heatOffset: bounds.lower,
                    };
                });
                break;
            case 'highlow':
                minNumber = min(allNumericValues);
                maxNumber = max(allNumericValues);
                each(numericFields, ({ name }) => {
                    newFieldOptions[name] = {
                        ...newFieldOptions[name],
                        min: minNumber === maxNumber ? null : minNumber,
                        max: minNumber === maxNumber ? null : maxNumber,
                    };
                });
                break;
            default:
                break;
        }
    }
    return newFieldOptions;
};

/**
 * Apply time granularity for 'time' field
 * @param {*} fieldOptions
 * @param {*} dataSet
 */
export const computeTimeGranularity = (fieldOptions, dataSet) =>
    reduce(
        dataSet.getFields(),
        (mergedOptions, { name }) => {
            if (mergedOptions[name].type === 'time') {
                const dataColumn = dataSet.getColumnByField(name);
                // eslint-disable-next-line no-param-reassign
                mergedOptions[name] = {
                    ...mergedOptions[name],
                    timeGranularity: computeGranularity(dataColumn),
                };
            }
            return mergedOptions;
        },
        fieldOptions
    );
/**
 * Given a dataset, determine type of each field
 * @param {*} dataSet
 */
export const determineFieldType = dataSet =>
    reduce(
        dataSet.getFields(),
        (fieldOptions, { name }) => {
            let option = {
                type: 'string',
                align: 'left',
            };
            if (isTimeField(name)) {
                option = {
                    type: 'time',
                    align: 'left',
                };
            } else if (isNumericArray(dataSet.getColumnByField(name))) {
                option = {
                    type: 'number',
                    align: 'right',
                };
            } else if (isSparklineArray(dataSet.getColumnByField(name))) {
                option = {
                    type: 'sparkline',
                    align: 'left',
                };
            }
            // eslint-disable-next-line no-param-reassign
            fieldOptions[name] = option;
            return fieldOptions;
        },
        {}
    );
/**
 * Compute runtime formatting options for each field
 * @param {DataSet} dataSet
 */
export const computeFieldsOptions = (dataSet, options = {}) => {
    let fieldsOptions = determineFieldType(dataSet);
    fieldsOptions = computeOverlay(fieldsOptions, dataSet, options);
    fieldsOptions = computeTimeGranularity(fieldsOptions, dataSet);
    return fieldsOptions;
};

/**
 * Returns one of the input colors based on row index parity (i.e. even/odd)
 * NOTE: rowIndex + 1 is being used, since we are using zero-based numbering when passing in rowIndex.
 * However, a user defining these colors would interpret row numbers using 1-based numbering, so we have to offset rowIndex by 1.
 * For example, if rowIndex is 0, this would correspond to the first row number, so colorOdd would be returned.
 * TODO: remove upon table SUI refactor that should remove need to explicitly define alternating text colors for striped tables
 * @param {number} rowIndex
 * @param {string} colorEven
 * @param {string} colorOdd
 * @returns {string} color
 */
export function getAlternatingColor(rowIndex, colorEven, colorOdd) {
    try {
        return (rowIndex + 1) % 2 === 0 ? chroma(colorEven).hex() : chroma(colorOdd).hex();
    } catch (_ignore) {
        return 'transparent';
    }
}

class TableVisualization extends Component {
    static propTypes = {
        ...BaseVisualization.propTypes,
        // an array of custom cell renderers.
        cellRenderers: T.array,
    };

    static defaultProps = {
        ...BaseVisualization.defaultProps,
        // default for empty array
        cellRenderers: [],
    };

    static dataContract = dataContract;

    static vizContract = {
        initialDimension: {
            width: 300,
            height: 300,
        },
    };

    static schema = optionsSchema;

    static editor = editorConfig;

    /**
     * return cell renderer list
     */
    getCellRenderers = () => {
        const customRenderers = this.props.cellRenderers || [];
        // custom rendereres will supersede default renderers
        return [...customRenderers, ...DefaultCellRenders];
    };

    handleCellClick = (e, fieldValue, cellIndex, cellValue, row) => {
        this.props.onEventTrigger(
            new VisualizationEvent({
                originalEvent: e,
                payload: {
                    fieldValue,
                    cellIndex,
                    cellValue,
                    rowValue: row,
                },
                type: 'cell.click',
            })
        );
    };

    handleSort = (e, field) => {
        const requestParams = getRequestParams(this.props) || {};
        const currentSort = requestParams.sort?.field ?? 'none';
        let nextSort = 'asc';
        if (currentSort === 'none') {
            nextSort = 'asc';
        } else if (currentSort === 'asc') {
            nextSort = 'desc';
        }
        this.props.onRequestParamsChange('primary', {
            ...requestParams,
            sort: {
                [field]: nextSort,
            },
        });
    };

    renderCell({ key, row, cellValue, fieldName, customStyles, onCellClick, fieldOptions = {} }) {
        const Cell = this.getCellRenderers().find(renderer =>
            renderer.canRender(fieldName, fieldOptions, cellValue)
        );
        const { options = {} } = this.props;
        const { textColor } = customStyles;
        const cellOptions = {
            ...options,
            ...get(options, ['fields', fieldName], {}),
            rowContext: row,
            textColor,
        };
        return (
            <Cell
                key={key}
                value={cellValue}
                cellOptions={cellOptions}
                fieldOptions={fieldOptions}
                onCellClick={onCellClick}
            />
        );
    }

    render() {
        const { dataSources, encoding, options = {} } = this.props;
        const { columnsFieldName, columns } = parse(dataSources, encoding);
        const dataSet = new DataSet(columnsFieldName, columns);
        const fieldsOptions = computeFieldsOptions(dataSet, options);
        const { perPage, currentPage, isPaging, requestParams } = getPaginationInformation(this.props);

        const fixedHeight = this.props.height != null;
        const TableContainer = fixedHeight ? FixedHeightTableContainer : NormalTableContainer;

        const hasCustomTextColors = options.rowTextColorEven || options.rowTextColorOdd;

        const { fields, rows } = dataSet.toJSONRows();
        const {
            margin = pick({
                enterprise: variables.spacingHalf(this.props),
                prisma: variables.spacingSmall(this.props),
            })(this.props),
            rowNumbers = false,
            showHeader = true,
            rowTextColorEven = pick({
                enterprise: variables.linkColor(this.props),
                prisma: variables.neutral500(this.props),
            })(this.props),
            rowTextColorOdd = pick({
                enterprise: variables.linkColor(this.props),
                prisma: variables.neutral500(this.props),
            })(this.props),

            // from previous style override:
            // // TODO: add stripeOdd and stripeEvenTextColor that removes need to define alternating text colors inline (i.e. should be done via Theme)
            headerTextColor = pick({
                enterprise: variables.textColor(this.props),
                prisma: {
                    light: variables.contentColorDefault(this.props),
                    dark: variables.neutral500(this.props),
                },
            })(this.props),
            headerBackgroundColor = pick({
                enterprise: {
                    light: variables.gray92(this.props),
                    dark: variables.gray20(this.props),
                },
                prisma: variables.interactiveColorBackground(this.props),
            })(this.props),
            rowBackgroundColorOdd = pick({
                enterprise: {
                    light: variables.backgroundColor(this.props),
                    dark: variables.black(this.props),
                },
                prisma: variables.backgroundColorPage(this.props),
            })(this.props),
            rowBackgroundColorEven = pick({
                enterprise: {
                    light: variables.gray96(this.props),
                    dark: '#0D1012',
                },
                prisma: variables.backgroundColorPage(this.props),
            })(this.props),
        } = options;
        const TableHead = showHeader ? Table.Head : NonVisibleTableHead;

        return (
            <TableContainer tableMargin={margin} isPaging={isPaging}>
                <StyledTable
                    headType="fixed"
                    innerStyle={{ height: '100%' }}
                    outerStyle={{ height: '100%' }}
                    stripeRows
                    $headerBackgroundColor={headerBackgroundColor}
                    $headerTextColor={headerTextColor}
                >
                    <TableHead>
                        {!!rowNumbers && <Table.HeadCell width={60} key="rowNumbers" />}
                        {fields.map(({ name }, fieldIndex) => (
                            <Table.HeadCell
                                sortKey={name}
                                sortDir={get(requestParams, ['sort', name], 'none')}
                                key={`field${fieldIndex}`} // eslint-disable-line react/no-array-index-key
                                onSort={e => {
                                    this.handleSort(e, name);
                                }}
                                align={fieldsOptions[name].align}
                            >
                                {name}
                            </Table.HeadCell>
                        ))}
                    </TableHead>
                    <Table.Body>
                        {rows.map((row, rowIndex) => (
                            <Table.Row
                                // eslint-disable-next-line react/no-array-index-key
                                key={`row${rowIndex}`}
                                style={{
                                    backgroundColor:
                                        rowIndex % 2 ? rowBackgroundColorEven : rowBackgroundColorOdd,
                                }}
                            >
                                {!!rowNumbers && (
                                    <RowNumberCell
                                        align="right"
                                        key="rowNumbers"
                                        hasCustomTextColors={hasCustomTextColors}
                                        textColor={getAlternatingColor(
                                            rowIndex,
                                            rowTextColorEven,
                                            rowTextColorOdd
                                        )}
                                    >
                                        {perPage * (currentPage - 1) + rowIndex + 1}
                                    </RowNumberCell>
                                )}
                                {row.map((cellValue, cellIndex) =>
                                    this.renderCell({
                                        key: cellIndex,
                                        row,
                                        cellValue,
                                        fieldName: fields[cellIndex].name,
                                        customStyles: {
                                            textColor: getAlternatingColor(
                                                rowIndex,
                                                rowTextColorEven,
                                                rowTextColorOdd
                                            ),
                                        },
                                        onCellClick: e => {
                                            this.handleCellClick(
                                                e,
                                                fields[cellIndex].name,
                                                cellIndex,
                                                cellValue,
                                                row
                                            );
                                        },
                                        fieldOptions: fieldsOptions[fields[cellIndex].name],
                                    })
                                )}
                            </Table.Row>
                        ))}
                    </Table.Body>
                </StyledTable>
            </TableContainer>
        );
    }
}

export default withVisualizationAndThemeHOCs({
    defaultBackgroundColor: enterprise.defaultBackgroundColor,
    placeholder: <IconPlaceholder icon={<TableIcon />} />,
})(withPaginator({ defaultPageable })(withSanitizedOptions(TableVisualization)));
