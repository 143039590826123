/* eslint-disable react/prop-types */
import React from 'react';
import T from 'prop-types';
import styled, { withTheme } from 'styled-components';
import get from 'lodash/get';
import pick from 'lodash/pick';
import each from 'lodash/each';
import isUndefined from 'lodash/isUndefined';
import isEmpty from 'lodash/isEmpty';

import { _ } from '@splunk/ui-utils/i18n';
import { parse } from '@splunk/visualization-encoding-parsers/SingleValueParser';
import Message from '@splunk/visualizations-shared/Message';
import { toDimension, toPx } from '@splunk/visualizations-shared/style';
import SizeAwareWrapper from '@splunk/visualizations-shared/SizeAwareWrapper';
import {
    renderPaginator,
    getPaginationInformation,
    FixedHeightPaginatorContainer,
} from '@splunk/dashboard-visualizations/utils/paginationUtils';
import { sanitize } from '@splunk/visualizations-shared/SanitizeProps';
import { isColor } from '@splunk/visualizations-shared/colorUtils';
import getTheme from '@splunk/themes/getTheme';
import getSettingsFromThemedProps from '@splunk/themes/getSettingsFromThemedProps';

const Item = styled.div.attrs(({ width }) => ({
    style: {
        width: toPx(width),
    },
}))`
    position: relative;
    background-color: ${props => props.backgroundColor};
`;

// Use `.attrs()` for highly dynamic styles. Ref: https://github.com/styled-components/styled-components/issues/134#issuecomment-312415291
const FixSizeItem = styled.div.attrs(({ width, height }) => ({
    style: {
        ...(width && { width: toPx(width) }),
        ...(height && { height: toPx(height) }),
    },
}))`
    position: relative;
    flex-direction: column;
    flex: 1;
    display: flex;
    background-color: ${props => props.backgroundColor};
`;

const ItemContent = styled.div`
    position: relative;
    min-height: 100px;
    width: 100%;
`;

const FixSizeItemContent = styled.div`
    min-height: 0px;
    flex: 1;
    position: relative;
`;

const Blocker = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
`;

const Cover = styled.div`
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
`;

/**
 * Todo: Need remove this function when we refactor `fill` encoding of Single Value not for background color.
 * Helper function. Necessary since not all Viz will have a background color derived from encoding.
 *
 * @param {*} dataSources
 * @param {*} encoding
 * @param {String} type Viz type
 * @returns {String} color
 */
const getEncodingBackgroundColor = (dataSources, encoding, type) => {
    const isTypeOfSingleValue = vizType => ['viz.singlevalue', 'viz.singlevalueradial'].includes(vizType);
    if (isTypeOfSingleValue(type) && !isEmpty(dataSources) && encoding) {
        const parsed = parse(dataSources, encoding);
        return parsed.backgroundColor || '';
    }
    return '';
};

/**
 * Internal helper to get the default themed background color for enhancer
 * enterprise.dark is using black; however enterprise.light and prisma use backgroundColorSidebar as per design
 * @param {Object} params.theme - theme context object containing the theme family, colorScheme, and density
 * @param {Object} params.themeVariables - all variables available to use for the given theme
 */
const getDefaultThemedBackgroundColor = ({ theme, themeVariables }) => {
    const { family, colorScheme } = getSettingsFromThemedProps({ theme });
    return family === 'enterprise' && colorScheme === 'dark'
        ? themeVariables?.black
        : themeVariables?.backgroundColorSidebar;
};

/**
 * default empty datasource placeholder
 * @param {Number} width
 * @param {Number} height
 */
const DefaultPlaceHolder = ({ width, height }) => (
    <Message width={width} height={height} level="warning" message={_('No DataSource Connected')} />
);
DefaultPlaceHolder.propTypes = {
    /**
     * width in pixel or string, defaults to 100%
     */
    width: T.oneOfType([T.string, T.number]),
    /**
     * height in pixel or string
     */
    height: T.oneOfType([T.string, T.number]),
};

/**
 * pass static props to new viz
 * @param {ReactElement} Base
 * @param {ReactElement} Viz
 */
const extendViz = (Base, Viz) => {
    const extendProperties = ['dataContract', 'vizContract', 'propTypes', 'defaultProps', 'schema', 'editor'];
    each(pick(Base, extendProperties), (v, k) => {
        if (v) {
            Viz[k] = v; // eslint-disable-line no-param-reassign
        }
    });
};

/**
 * display placeholder if no primary datasource is connected or
 * primary datasource does not have any search data
 * @param {Object} props
 */
export const defaultShowPlaceholder = props => {
    const { dataSources, loading } = props;
    if (loading || !dataSources || !dataSources.primary) {
        return true;
    }
    const data = get(dataSources, 'primary.data');
    return !data || !data.columns || !data.columns.length;
};

/**
 * HOC that renders a placeholder if viz does not connect to datasource
 * or datasource does not have any data
 * @param {ReactElement} placeholder placeholder react element
 * @param {Function} shouldShowPlaceholder a function return true/false to indicate if a placeholder should be rendered
 */
export const withPlaceholder = ({
    placeholder = <DefaultPlaceHolder />,
    shouldShowPlaceholder = defaultShowPlaceholder,
}) => Visualization => {
    const Wrapper = props => {
        const show = shouldShowPlaceholder(props);
        if (show) {
            return React.cloneElement(placeholder, {
                width: props.width,
                height: props.height,
            });
        }
        return <Visualization {...props} />;
    };
    extendViz(Visualization, Wrapper);
    return Wrapper;
};

/**
 * HOC that renders the visualization with a Paginator component
 * @param {ReactElement} Visualization Visualization Element
 * @param {Number} VizHeightOffset Visualization specific value to reduce height by in order to make room for the Paginator
 * @param {Boolean} defaultPageable Visualization specific default value for whether pagination should be enabled
 */
export const withPaginator = ({ VizHeightOffset = 0, defaultPageable = false }) => Visualization => {
    const Container = styled.div`
        overflow: hidden;
        position: relative;
        ${props => toDimension(pick(props, ['width', 'height']))};
    `;
    const Wrapper = props => {
        const { totalPages, currentPage, isPaging, requestParams } = getPaginationInformation(props);
        const pageable =
            props.options && !isUndefined(props.options.pageable) ? props.options.pageable : defaultPageable;
        const { height, width, onRequestParamsChange } = props;

        const fixedHeight = height != null;
        const PaginatorContainer = fixedHeight ? FixedHeightPaginatorContainer : null;
        const vizHeight = isPaging && pageable && VizHeightOffset ? height - VizHeightOffset : height;
        const vizProps = { ...props, height: vizHeight };

        return (
            <Container data-test="paginator-wrapper" width={width} height={height}>
                <Visualization {...vizProps} />
                {pageable &&
                    renderPaginator({
                        totalPages,
                        currentPage,
                        isPaging,
                        requestParams,
                        theme: props.theme,
                        onRequestParamsChange,
                        ...(fixedHeight && { PaginatorContainer }),
                    })}
            </Container>
        );
    };
    extendViz(Visualization, Wrapper);
    return Wrapper;
};

/**
 * HOC that renders a container that provides a background color and size
 * @param {Function|String} defaultBackgroundColor default background color for a viz
 * @param {Function|Boolean} enableBackgroundColorOption indicate whether backgroundColor in option should be applied if present
 * @param {ReactElement} Visualization
 */
export const withBackgroundColor = ({
    defaultBackgroundColor,
    enableBackgroundColorOption,
}) => Visualization => {
    const Wrapper = props => {
        const { dataSources, encoding, options = {}, width, height, theme, type } = props;
        // due to the computational nature of backgroundColor,
        // we use getTheme and inspect the hardcoded internal to unconditionally pass dark prisma themes variables if no SplunkThemeProvider is present
        // in this case, we default to transparent if no theme is present
        // TODO: move away from hardcoded internal that may change
        const themeVariables = theme?.splunkThemeV1 ? getTheme(theme?.splunkThemeV1) : {};
        const defaultThemedBackgroundColor = getDefaultThemedBackgroundColor({ theme, themeVariables });
        const encodingBackgroundColor = getEncodingBackgroundColor(dataSources, encoding, type);
        const backgroundColor =
            (isColor(encodingBackgroundColor) && encodingBackgroundColor) ||
            (enableBackgroundColorOption && isColor(options.backgroundColor) && options.backgroundColor) ||
            defaultThemedBackgroundColor ||
            defaultBackgroundColor ||
            'transparent';

        return !height ? (
            <Item data-test="item" width={width} backgroundColor={backgroundColor}>
                <ItemContent>
                    <Visualization {...props} />
                </ItemContent>
            </Item>
        ) : (
            <FixSizeItem
                data-test="fix-size-item"
                width={width}
                height={height}
                backgroundColor={backgroundColor}
            >
                <FixSizeItemContent>
                    <SizeAwareWrapper>
                        {containerDimension => {
                            const overrideProps = {
                                ...props,
                                width: containerDimension.width,
                                height: containerDimension.height,
                            };
                            return <Visualization {...overrideProps} />;
                        }}
                    </SizeAwareWrapper>
                </FixSizeItemContent>
            </FixSizeItem>
        );
    };
    extendViz(Visualization, Wrapper);
    return Wrapper;
};

/**
 * HOC that renders a cover layer in edit mode
 * @param {ReactElement} Visualization
 */
export const withEventBlocker = Visualization => {
    const Wrapper = props => {
        const { mode } = props;
        return (
            <Blocker>
                <Visualization {...props} />
                {mode === 'edit' && <Cover />}
            </Blocker>
        );
    };
    extendViz(Visualization, Wrapper);
    return Wrapper;
};

/**
 * HOC combination for a generic viz
 * @param {Function|String} defaultBackgroundColor default background color for a viz
 * @param {Function|Boolean} enableBackgroundColorOption indicate whether backgroundColor in option should be applied if present-
 * @param {ReactElement} placeholder
 */
export const withVisualizationHOCs = ({
    defaultBackgroundColor,
    enableBackgroundColorOption = true,
    placeholder,
}) => Viz =>
    withBackgroundColor({ defaultBackgroundColor, enableBackgroundColorOption })(
        withPlaceholder({
            placeholder,
        })(withEventBlocker(Viz))
    );

/**
 * HOC combination for viz with theme information
 * @param {Function|Boolean} enableBackgroundColorOption indicate whether backgroundColor in option should be applied if present-
 * @param {Function|String} defaultBackgroundColor default background color for a viz
 * @param {ReactElement} placeholder
 */
export const withVisualizationAndThemeHOCs = ({
    enableBackgroundColorOption,
    defaultBackgroundColor,
    placeholder,
}) => Viz =>
    // splunk-charting need to access theme prop, so we use additional withTheme hoc.
    withTheme(
        withVisualizationHOCs({ enableBackgroundColorOption, defaultBackgroundColor, placeholder })(Viz)
    );

/**
 * HOC that sanitizes options.
 * @param {ReactElement} Visualization
 */
export const withSanitizedOptions = Visualization => {
    const Wrapper = props => {
        const { options, ...rest } = props;
        const sanitizedOptions = sanitize(options);
        return <Visualization options={sanitizedOptions} {...rest} />;
    };
    extendViz(Visualization, Wrapper);
    return Wrapper;
};
