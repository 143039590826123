import { _ } from '@splunk/ui-utils/i18n';
import pick from '@splunk/themes/pick';
import variables from '@splunk/themes/variables';
import { COLOR_OR_TOKEN_PATTERN_WITH_RGBA } from '@splunk/visualizations-shared/schemaUtils';
import { defaultPalettesConfig } from '@splunk/visualization-color-palettes/editors/PresetPalettes';
import { ChoroplethSvg as ChoroplethSvgIcon } from '@splunk/visualization-icons';
import { ChoroplethSvg as ChoroplethSvgPlaceholderIcon } from '@splunk/visualization-icons/placeholders';
import { VizConfig } from '../common/interfaces/VizConfig';
import { DataContract } from '../common/interfaces/DataContract';
import { VizSize } from '../common/interfaces/VizSize';
import { DefaultContext } from '../common/interfaces/DefaultContext';
import { OptionsSchema } from '../common/interfaces/OptionsSchema';
import { EditorConfig } from '../common/interfaces/Editor';
import { VizBehavior } from '../common/interfaces/VizBehavior';
import { enhanceConfig } from '../common/utils/configUtils';
import { VizCategory } from '../common/interfaces/VizCategory';
import { ThemedDefaults } from '../common/interfaces/ThemedDefaults';
import StaticBackgroundColorEditor from '../common/editorConfig/StaticBackgroundColorEditor';

const dataContract: DataContract = {
    requiredDataSources: [],
    optionalDataSources: [
        {
            name: 'primary',
            description: 'DataSource that powers the visualization',
        },
    ],
    initialRequestParams: {
        primary: { offset: 0, count: 10000 },
    },
};

const size: VizSize = {
    initialWidth: 300,
    initialHeight: 300,
};

const defaultContext: DefaultContext = {
    areaColorsGradientConfig: { colors: ['#7EEFDA', '#6484F6', '#C093F9', '#293873'] },
    areaColorsRangeConfig: [
        { to: 20, value: '#D41F1F' },
        { from: 20, to: 40, value: '#D94E17' },
        { from: 40, to: 60, value: '#CBA700' },
        { from: 60, to: 80, value: '#669922' },
        { from: 80, value: '#118832' },
    ],
    areaColorsMatchConfig: [{ match: '', value: '#5C33FF' }],
};
const optionsSchema: OptionsSchema = {
    areaColors: {
        default: '> areaValues|rangeValue(areaColorsRangeConfig)',
        description:
            'Specify the dataSource field that should be used to set the svg fill attribute of identified areas.',
        type: 'array',
        items: {
            type: 'string',
            pattern: COLOR_OR_TOKEN_PATTERN_WITH_RGBA,
        },
    },
    areaIds: {
        default: '> primary|seriesByType("string")',
        description:
            'Specify the dataSource field that should be used to identify each area in the Choropleth svg, where the area has been tagged with an id field.',
        type: 'array',
        items: { type: 'string' },
    },
    areaValues: {
        default: '> primary|seriesByType("number")',
        description: 'Specify the dataSource field that indicates the values for the areas.',
        type: 'array',
        items: { type: 'number' },
    },
    backgroundColor: {
        default: '> themes.defaultBackgroundColor',
        description: 'the background color behind the SVG, title, and description.',
        type: 'string',
        pattern: COLOR_OR_TOKEN_PATTERN_WITH_RGBA,
    },
    svg: {
        description: 'Literal svg, or svg data URI.',
        type: 'string',
    },
};

const themes: ThemedDefaults = {
    defaultBackgroundColor: props =>
        pick({
            enterprise: {
                dark: variables.black(props),
                light: variables.backgroundColor(props),
            },
            prisma: variables.backgroundColorSidebar(props),
        })(props),
};

const editorConfig: EditorConfig[] = [
    {
        label: _('Data configurations'),
        layout: [
            [
                {
                    label: 'Area IDs',
                    editor: 'editor.columnSelector',
                    option: 'areaIds',
                    context: 'areaIdContext', // todo: need refactor this
                    editorProps: {
                        dataSourceKey: 'primary',
                    },
                },
            ],
            [
                {
                    label: 'Area values',
                    editor: 'editor.columnSelector',
                    option: 'areaValues',
                    context: 'areaValuesContext', // todo: need refactor this
                    editorProps: {
                        dataSourceKey: 'primary',
                    },
                },
            ],
        ],
    },
    {
        label: _('Color and style'),
        layout: [
            [
                {
                    label: _('Areas'),
                    option: 'areaColors',
                    context: 'defaultContext',
                    editor: 'editor.dynamicColor',
                    editorProps: {
                        labelPosition: 'top',
                        flyoutTitle: _('Dynamic coloring: value'),
                        formatters: [
                            {
                                label: _('Ranges'),
                                value: 'rangeValue',
                                isDefault: true, // show it by default when open dynamic color flyout
                                defaults: {
                                    areaValues: defaultContext.areaColorsRangeConfig,
                                },
                            },
                            {
                                label: _('Matches'),
                                value: 'matchValue',
                                defaults: {
                                    areaValues: defaultContext.areaColorsMatchConfig,
                                },
                            },
                        ],
                        dataSelectors: [
                            {
                                label: _('Value'),
                                value: 'areaValues',
                            },
                        ],
                        defaultPalettesConfig,
                    },
                },
            ],
            [
                {
                    ...StaticBackgroundColorEditor,
                    editorProps: {
                        themes,
                    },
                },
            ],
        ],
    },
    {
        label: _('SVG content'),
        layout: [
            [
                {
                    label: _('SVG file'),
                    option: 'svg',
                    editor: 'editor.image',
                    editorProps: {
                        validMediaTypes: ['svg'],
                        svgRenderAsDom: true,
                    },
                },
            ],
        ],
    },
];

/**
 * visualization configuration
 */
const config: VizConfig = {
    /**
     * unique viz key
     */
    key: 'splunk.choropleth.svg',
    /**
     * viz name
     */
    name: 'Choropleth Svg',
    category: VizCategory.CHOROPLETH,
    /**
     * viz icon
     */
    icon: ChoroplethSvgIcon,
    placeholderIcon: ChoroplethSvgPlaceholderIcon,
    dataContract,
    size,
    defaultContext,
    optionsSchema,
    requiredProps: ['svg'],
    editorConfig,
    events: {
        'area.click': {
            description: 'trigger when user clicks one area',
            payloadKeys: ['value', 'name'],
        },
    },
    supports: [VizBehavior.DYNAMIC_OPTIONS, VizBehavior.EVENTS, VizBehavior.PLACEHOLDER],
    themes,
};

export default enhanceConfig(config);
