import { mapKeys, chain } from 'lodash';
import { convertPropsToString } from '@splunk/visualizations-shared/propUtils';
import { mapToValues, convertY2AxisOptions, convertOverlayFields } from '../common/chartingUtils';

// map for key names which are either new or not present in SimpleXML options mapping
const columnOptionsSCMapping = {
    dataValuesDisplay: 'showDataLabels',
    showYAxisWithZero: 'yAxisIncludeZero',
    showY2AxisWithZero: 'y2AxisIncludeZero',
    showYAxisExtendedRange: 'yAxisExtendsAxisRange',
    showSplitSeries: 'splitSeries',
    showIndependentYRanges: 'allowIndependentYRanges',
    xAxisLabelRotation: 'xAxisMajorLabelRotation',
    xAxisLabelVisibility: 'xAxisMajorLabelVisibility',
    showOverlayY2Axis: 'enableY2Axis',
    resultLimit: 'resultTruncationLimit',
    seriesColors: 'seriesColors',
    seriesColorsByField: 'fieldColors',
    legendDisplay: 'legendPlacement',
    legendTruncation: 'legendLabelOverflowMode',
    yAxisMajorTickInterval: 'yAxisMajorUnit',
    yAxisLabelVisibility: 'yAxisMajorLabelVisibility',
    showXMajorGridLines: 'showMajorXGridLines',
    showYMajorGridLines: 'showMajorYGridLines',
    showYMinorGridLines: 'showMinorYGridLines',
    showY2MajorGridLines: 'showMajorY2GridLines',
    showY2MinorGridLines: 'showMinorY2GridLines',
    y2AxisLabelVisibility: 'y2AxisMajorLabelVisibility',
    y2AxisMajorTickInterval: 'y2AxisMajorUnit',
    showRoundedY2AxisLabels: 'roundY2AxisLabelsToInteger',
    xAxisLineVisibility: 'xAxisVisibility',
    yAxisLineVisibility: 'yAxisVisibility',
    y2AxisLineVisibility: 'y2AxisVisibility',
};

// key value mapping of what options (and their corresponding values) which we expose in config.ts to property values SC understands
export const columnOptionValuesSCMapping = {
    xAxisTitleVisibility: {
        hide: 'collapsed',
    },
    yAxisTitleVisibility: {
        hide: 'collapsed',
    },
    y2AxisTitleVisibility: {
        hide: 'collapsed',
    },
    legendPlacement: {
        off: 'none',
    },
    legendLabelOverflowMode: {
        ellipsisOff: 'ellipsisNone',
    },
    yAxisAbbreviation: {
        off: 'none',
    },
    columnGrouping: {
        auto: 'default',
    },
    showDataLabels: {
        off: 'none',
    },
    y2AxisAbbreviation: {
        off: 'none',
    },
};

/**
 * Helper function to convert values for legend options
 * @method convertLegendDisplayOptions
 * @param {Object} originalOptions
 * @returns {Object}
 */
export const convertLegendDisplayOptions = (originalOptions = {}): Record<string, unknown> => {
    const convertedOptions: Record<string, any> = { ...originalOptions };
    ['legendPlacement', 'legendLabelOverflowMode'].forEach(key => {
        const updatedValue = columnOptionValuesSCMapping[key][originalOptions[key]];
        if (updatedValue) {
            convertedOptions[key] = updatedValue;
        }
    });
    return convertedOptions;
};

/**
 * Helper function to convert values for Y axis label options
 * @method convertYAxisLabelOptions
 * @param {Object} originalOptions
 * @returns {Object}
 */
export const convertYAxisLabelOptions = (originalOptions): Record<string, unknown> => {
    const convertedOptions: Record<string, any> = { ...originalOptions };
    const updatedValue = columnOptionValuesSCMapping.yAxisAbbreviation[originalOptions.yAxisAbbreviation];
    if (updatedValue) {
        convertedOptions.yAxisAbbreviation = updatedValue;
    }
    return convertedOptions;
};

/**
 * Helper method to process options related to title visibility
 * @method convertTitleVisibilityOptions
 * @param {Object} originalOptions
 * @returns {Object}
 */
export const convertTitleVisibilityOptions = (originalOptions): Record<string, unknown> => {
    const convertedOptions: Record<string, any> = { ...originalOptions };
    ['xAxisTitleVisibility', 'yAxisTitleVisibility', 'y2AxisTitleVisibility'].forEach(key => {
        if (convertedOptions[key] === 'hide') {
            convertedOptions[key] = 'collapsed';
        }
    });
    return convertedOptions;
};

/**
 * Helper method to convert 'auto' to default
 * @method convertAuto
 * @param {Object} originalOptions
 * @returns {Object}
 */
export const convertAuto = (originalOptions): Record<string, unknown> => {
    const convertedOptions: Record<string, any> = { ...originalOptions };
    ['stackMode'].forEach(key => {
        if (convertedOptions[key] === 'auto') {
            convertedOptions[key] = 'default';
        }
    });
    return convertedOptions;
};

/**
 * Converts defined option values to string if present
 * @method convertToStringValues
 * @param {Object} options
 * @returns {Object}
 */
export const convertToStringValues = (options): Record<string, unknown> => {
    const updatedOptions = convertPropsToString({
        vizProps: options,
        propNames: ['seriesColors', 'fieldColors', 'legendLabels'],
    });
    return { ...options, ...updatedOptions };
};

/**
 * Helper method to update the option names which can be mapped to SimpleXML options or
 * can be used directly with SC if don't have a 1-1 mapping in SimpleXML
 *
 * @param options
 * @returns
 */
export const mapToOldKey = options =>
    mapKeys(options, (val, key) => (columnOptionsSCMapping[key] ? columnOptionsSCMapping[key] : key));

/**
 * Chained helper for mapping the option properties (and values) we expose in config.ts into property values that splunk-charting accepts
 * This is for options that has new key name and can not directly be mapped with SimpleXML or
 * don't have a 1-1 mapping in SimpleXML (i.e. a straightforward name mapping where values are preserved)
 *
 * @param originalOptions
 * @returns
 */
export const convertToColumnSCProperties = (
    originalOptions: Record<string, unknown>
): Record<string, unknown> =>
    chain(mapToOldKey(originalOptions))
        .thru(mapToValues(columnOptionValuesSCMapping))
        .thru(convertY2AxisOptions)
        .thru(convertOverlayFields)
        .thru(convertTitleVisibilityOptions)
        .thru(convertLegendDisplayOptions)
        .thru(convertToStringValues)
        .thru(convertAuto)
        .thru(convertYAxisLabelOptions)
        .value();
