import commonLayoutOptionsSchema from './commonLayoutOptionsSchema';

export default {
    ...commonLayoutOptionsSchema,
    gutterSize: {
        type: 'number',
        default: 8,
        minimum: 8,
        maximum: 16,
        description:
            'A number that represents the size of the gutter between visualizations in pixels. The minimum value is 8 and maximum value is 16.',
    },
};
