import * as React from 'react';
import { _ } from '@splunk/ui-utils/i18n';
import SVG from '@splunk/react-icons/SVGEnterprise';

const Line = (props: React.ComponentProps<typeof SVG>): React.ReactElement<SVGElement> => (
    <SVG screenReaderText={_('Line')} viewBox="0 0 16 14" {...props}>
        <path
            d="M2.16667 1.16671C2.16667 0.70647 1.79357 0.333374 1.33333 0.333374C0.873096 0.333374 0.5 0.70647 0.5 1.16671V12.4167C0.5 13.107 1.05964 13.6667 1.75 13.6667H14.6667C15.1269 13.6667 15.5 13.2936 15.5 12.8333C15.5 12.3731 15.1269 12 14.6667 12H2.16667V1.16671Z"
            fill="currentColor"
        />
        <path
            d="M15.2559 3.42263C15.5814 3.09719 15.5814 2.56956 15.2559 2.24412C14.9305 1.91868 14.4029 1.91868 14.0774 2.24412L10.5 5.82153L8.88388 4.20541C8.39573 3.71726 7.60427 3.71726 7.11612 4.20541L4.07741 7.24412C3.75197 7.56955 3.75197 8.09719 4.07741 8.42263C4.40285 8.74807 4.93049 8.74807 5.25592 8.42263L8 5.67855L9.61612 7.29467C10.1043 7.78282 10.8957 7.78282 11.3839 7.29467L15.2559 3.42263Z"
            fill="currentColor"
        />
    </SVG>
);

export default Line;
