/* eslint-disable class-methods-use-this */
import { _ } from '@splunk/ui-utils/i18n';
import BaseRegistry from './BaseRegistry';

/**
 * Image Registry
 */
class ImageRegistry extends BaseRegistry {
    static create() {
        return new ImageRegistry();
    }

    static VALID_MEDIA_TYPES = ['png', 'jpeg', 'svg', 'gif', 'webp'];

    /**
     * check if the the media type from the dataURI belongs to the supported types
     * @param {DataURI} dataURI
     * @returns {Boolean}
     */
    isValidMediaType(dataURI, validMediaType = ImageRegistry.VALID_MEDIA_TYPES) {
        return validMediaType.some(type => {
            const regex = `^data:image\\/${type === 'svg' ? `${type}\\+xml` : type};base64,.*$`;
            return new RegExp(regex).test(dataURI);
        });
    }

    /**
     * upload image if the image format is supported
     * @param {DataURI} dataURI
     * @param {Object} metaData
     * @param {String} providerType   e.g. 'kvstore', or 'sui'
     * @returns {String} id           e.g. 'kvstore://image123'
     */
    upload(dataURI, metaData, providerType, validMediaType = ImageRegistry.VALID_MEDIA_TYPES) {
        if (!this.isValidMediaType(dataURI, validMediaType)) {
            throw new Error(
                _(
                    `The format of the uploaded file is not supported. Please upload ${validMediaType.join(
                        ', '
                    )}.`
                )
            );
        }

        return super.upload(dataURI, metaData, providerType);
    }
}

export default ImageRegistry;
