import React, { useRef } from 'react';
import Popover from '@splunk/react-ui/Popover';
import SimpleStatusIcon from './SimpleStatusIcon';
import type { SimpleStatusIconProps } from './SimpleStatusIcon';

export interface PopoverStatusIconProps
    extends Omit<SimpleStatusIconProps, 'elementRef' | 'onClick'> {
    open: boolean;
    onRequestOpen?: () => void | null;
    onRequestClose?: () => void | null;
    children: React.ReactNode;
}

const PopoverStatusIcon = ({
    status,
    height,
    iconSize,
    iconSizeOnHover,
    open,
    onRequestOpen,
    onRequestClose,
    children,
}: PopoverStatusIconProps): JSX.Element | null => {
    /**
     * TODO: this is a possible bug. Since this is a ref,
     * starting with a state of open won't actually open the popover since
     * the ref hasn't resolved to an actual element yet, and the change to the ref
     * doesn't cause the popover to rerender
     * This works fine after initial mount, though.
     */
    const anchor = useRef<HTMLButtonElement>(null);

    if (!status) {
        return null;
    }

    const hasContent = !!children;

    return (
        <>
            <SimpleStatusIcon
                elementRef={anchor}
                status={status}
                height={height}
                iconSize={iconSize}
                iconSizeOnHover={iconSizeOnHover}
                onClick={hasContent ? onRequestOpen : null}
            />
            {hasContent && (
                <Popover
                    open={open}
                    anchor={anchor.current}
                    onRequestClose={onRequestClose}
                    takeFocus
                    defaultPlacement="below"
                >
                    {children}
                </Popover>
            )}
        </>
    );
};

export default PopoverStatusIcon;
