import React, { forwardRef } from 'react';
import styled from 'styled-components';
import { mixins, pick, variables } from '@splunk/themes';
import { customThemeVariables, sanitizeColor } from '@splunk/dashboard-ui';
import { toPx, toDimension } from '@splunk/dashboard-utils';
import { getImageDimension } from '../utils/imageUtils';
import type { CanvasProps } from '../types';
import { useBackgroundImage } from '../hooks';

const GRID_LINE_OPACITY = 0.1;

interface BackgroundProps {
    width: number;
    height: number;
    scale?: number;
    bgColor?: string | null;
    bgImageSrc?: string;
    bgImageWidth?: number;
    bgImageHeight?: number;
    bgImageSizeType?: string;
    bgImagePositionX?: number;
    bgImagePositionY?: number;
}

interface GridLinesProps {
    width: number;
    height: number;
    gridWidth: number;
    gridHeight: number;
    gridPadding: number;
    gridLineWidth: number;
    gridLineColor?: string;
    gridLineOpacity?: number;
}

interface CanvasContainerProps {
    width: number;
    height: number;
    userSelect: boolean;
    showOverflowContent: boolean;
}

/**
 * logic for background image size css.
 * @param {BackgroundProps} props
 */
const toBackgroundImageSize = (props: BackgroundProps) => {
    const { width, height } = getImageDimension(props.bgImageSrc ?? '');
    if (props.bgImageWidth && props.bgImageHeight) {
        return `${toPx(props.bgImageWidth)} ${toPx(props.bgImageHeight)}`;
    }
    if (props.bgImageWidth || props.bgImageHeight) {
        return `${toPx(props.bgImageWidth || width)} ${toPx(
            props.bgImageHeight || height
        )}`;
    }
    if (props.bgImageSizeType) {
        return props.bgImageSizeType;
    }
    return 'contain';
};

/**
 * make sure to only include image related css when backgroundImageSrc is specified.
 * @param {BackgroundProps} props
 */
const toBackgroundImage = (props: BackgroundProps) => {
    if (props.bgImageSrc) {
        return `
            background-repeat: no-repeat;
            background-image: url("${props.bgImageSrc}");
            background-size: ${toBackgroundImageSize(props)};
            background-position:
                ${toPx(props.bgImagePositionX)} ${toPx(props.bgImagePositionY)};
        `;
    }
    return '';
};

const toScale = (scale?: number) => {
    if (scale) {
        return `
            transform: scale(${scale});
            transform-origin: 0 0;
        `;
    }
    return '';
};

const CanvasContainer = styled.div.attrs((props: CanvasContainerProps) => ({
    style: { width: props.width, height: props.height },
}))<CanvasContainerProps>`
    ${mixins.reset('block')};
    overflow: ${(prop) => (prop.showOverflowContent ? 'visible' : 'hidden')};
    user-select: ${(prop) => (prop.userSelect ? 'text' : 'none')};
    position: relative;
`;

/**
 * A layer that renders canvas with background color/image
 */
const Background = styled.div.attrs((props: BackgroundProps) => ({
    style: { width: props.width, height: props.height },
}))<BackgroundProps>`
    ${mixins.reset('inline-block')};
    position: relative;
    background: ${(prop) =>
        prop.bgColor ||
        // NOTE: this needs to match packages/dashboard-editors/src/layouts/AbsoluteLayoutEditor.jsx
        customThemeVariables.dashboardBackgroundColor};
    ${(prop) => toBackgroundImage(prop)};
    ${(prop) => toScale(prop.scale)};
`;

/**
 * A layer that renders border
 */
const Border = styled.div.attrs((props: { width: number; height: number }) => ({
    style: { width: props.width, height: props.height },
}))<{ width: number; height: number }>`
    position: absolute;
    box-sizing: border-box;
    border: 2px dashed
        ${pick({
            enterprise: {
                light: variables.gray80,
                dark: variables.gray30,
            },
            prisma: variables.interactiveColorBorder,
        })};
    left: 0px;
    top: 0px;
    right: 0px;
    bottom: 0px;
`;

const getGridLineColor = (props: GridLinesProps) =>
    props.gridLineColor ||
    pick({
        light: '#D8D8D8',
        dark: '#9B9B9B',
    });

/**
 * A layer that renders grid
 */
const GridLines = styled.div<GridLinesProps>`
    position: absolute;
    box-sizing: border-box;
    left: 0px;
    top: 0px;
    right: 0px;
    bottom: 0px;
    opacity: ${(prop) => prop.gridLineOpacity || GRID_LINE_OPACITY};
    ${(prop) => toDimension({ width: prop.width, height: prop.height })};
    background-size: ${(prop) => prop.gridWidth + prop.gridLineWidth}px
        ${(prop) => prop.gridHeight + prop.gridLineWidth}px;
    background-image: repeating-linear-gradient(
            0deg,
            ${getGridLineColor},
            ${getGridLineColor} ${(prop) => toPx(prop.gridLineWidth)},
            transparent ${(prop) => prop.gridLineWidth}px,
            transparent ${(prop) => prop.gridHeight + prop.gridLineWidth}px
        ),
        repeating-linear-gradient(
            -90deg,
            ${getGridLineColor},
            ${getGridLineColor} ${(prop) => toPx(prop.gridLineWidth)},
            transparent ${(prop) => prop.gridLineWidth}px,
            transparent ${(prop) => prop.gridWidth + prop.gridLineWidth}px
        );
`;

/**
 * Canvas is a component that render background for a layout.
 * All visualizations will be displayed on top of a canvas
 */
const Canvas = (
    {
        width,
        height,
        scale,
        backgroundColor: unsanitizedBgColor = '',
        backgroundImageSrc,
        backgroundImageSizeType,
        backgroundImageWidth,
        backgroundImageHeight,
        backgroundImagePositionX,
        backgroundImagePositionY,
        gridLineOpacity,
        children,
        showOverflowContent = false,
        userSelect = false,
        showGrid = false,
        gridPadding = 0,
        gridLineWidth = 1,
        gridLineColor,
        gridWidth = 9,
        gridHeight = 9,
        showBorder = false,
        cssScaling = true,
        ...others
    }: CanvasProps,
    canvasRef: React.Ref<HTMLDivElement>
): JSX.Element => {
    const imageSrc = useBackgroundImage(backgroundImageSrc);

    const containerWidth = scale != null ? width * scale : width;
    const containerHeight =
        scale != null && cssScaling ? height * scale : height;
    const backgroundColor = sanitizeColor(unsanitizedBgColor);

    return (
        <CanvasContainer
            data-test="canvas-container"
            data-width={containerWidth}
            data-height={containerHeight}
            width={cssScaling ? containerWidth : width}
            height={containerHeight}
            showOverflowContent={showOverflowContent}
            userSelect={userSelect}
            ref={canvasRef}
            {...others}
        >
            <Background
                data-test="canvas"
                data-width={width}
                data-height={height}
                data-scale={scale}
                width={cssScaling ? width : containerWidth}
                height={height}
                scale={cssScaling ? scale : undefined}
                bgColor={backgroundColor}
                bgImageSrc={imageSrc}
                bgImageSizeType={backgroundImageSizeType}
                bgImageWidth={backgroundImageWidth}
                bgImageHeight={backgroundImageHeight}
                bgImagePositionX={backgroundImagePositionX}
                bgImagePositionY={backgroundImagePositionY}
            >
                {showBorder && <Border width={width} height={height} />}
                {showGrid && (
                    <GridLines
                        width={width}
                        height={height}
                        gridLineOpacity={gridLineOpacity}
                        gridPadding={gridPadding}
                        gridLineWidth={gridLineWidth}
                        gridLineColor={gridLineColor}
                        gridWidth={gridWidth}
                        gridHeight={gridHeight}
                    />
                )}
                {children}
            </Background>
        </CanvasContainer>
    );
};

export default forwardRef(Canvas);
