import React from 'react';
import styled from 'styled-components';
import Progress from '@splunk/react-ui/Progress';
import { customThemeVariables } from '@splunk/dashboard-ui';

export const PROGRESSBAR_HEIGHT = 3;

interface ProgressBarContainerProps {
    backgroundColor?: string | null;
}

export const ProgressBarContainer = styled.div.attrs(() => ({
    'data-test': 'progress-bar-container',
}))<ProgressBarContainerProps>`
    height: ${PROGRESSBAR_HEIGHT}px;
    background-color: ${(props) =>
        props.backgroundColor || customThemeVariables.vizPanelBackgroundColor};
    width: 100%;
    display: block;
    /* Fix sub-pixel gaps between elements when scaled */
    box-shadow: 0 1px 1px 0
        ${(props) =>
            props.backgroundColor ||
            customThemeVariables.vizPanelBackgroundColor};
`;

interface ProgressBarProps extends ProgressBarContainerProps {
    percentage?: number;
    hasData?: boolean;
}

const VizProgressBar = ({
    backgroundColor,
    percentage = 0,
    hasData = true,
}: ProgressBarProps) => {
    if (percentage >= 100 || !hasData) {
        return <ProgressBarContainer backgroundColor={backgroundColor} />;
    }
    const progressBarPercent = Math.max(percentage, 5);

    return (
        <ProgressBarContainer backgroundColor={backgroundColor}>
            <Progress
                percentage={progressBarPercent}
                tooltip={`${percentage}% complete`}
            />
        </ProgressBarContainer>
    );
};

export default VizProgressBar;
