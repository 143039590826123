import * as React from 'react';
import { _ } from '@splunk/ui-utils/i18n';
import SVG from '@splunk/react-icons/SVGEnterprise';

export const RemoveIcon = (props: React.ComponentProps<typeof SVG>): React.ReactElement<SVGElement> => (
    <SVG screenReaderText={_('Remove')} width="16" height="16" viewBox="0 0 16 16" fill="none" {...props}>
        <g clipPath="url(#clip0)">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M6.66667 4.00016V2.66683H9.33333V4.00016H6.66667ZM11.3333 4.00016H10.6667V2.3335C10.6667 1.78121 10.219 1.3335 9.66667 1.3335H6.33333C5.78105 1.3335 5.33333 1.78121 5.33333 2.3335V4.00016H4.66667H3.33333H2.66667C2.29848 4.00016 2 4.29864 2 4.66683C2 5.03502 2.29848 5.3335 2.66667 5.3335H3.33333V13.6668C3.33333 14.2191 3.78105 14.6668 4.33333 14.6668H11.6667C12.219 14.6668 12.6667 14.2191 12.6667 13.6668V5.3335H13.3333C13.7015 5.3335 14 5.03502 14 4.66683C14 4.29864 13.7015 4.00016 13.3333 4.00016H12.6667H11.3333ZM8 8.39077L7.13807 7.52884C6.87772 7.26849 6.45561 7.26849 6.19526 7.52884C5.93491 7.78919 5.93491 8.2113 6.19526 8.47165L7.05719 9.33358L6.19526 10.1955C5.93491 10.4559 5.93491 10.878 6.19526 11.1383C6.45561 11.3987 6.87772 11.3987 7.13807 11.1383L8 10.2764L8.86193 11.1383C9.12228 11.3987 9.54439 11.3987 9.80474 11.1383C10.0651 10.878 10.0651 10.4559 9.80474 10.1955L8.94281 9.33358L9.80474 8.47165C10.0651 8.2113 10.0651 7.78919 9.80474 7.52884C9.54439 7.26849 9.12228 7.26849 8.86193 7.52884L8 8.39077Z"
                fill="#818D99"
            />
        </g>
        <defs>
            <clipPath id="clip0">
                <rect width="16" height="16" fill="white" />
            </clipPath>
        </defs>
    </SVG>
);

export default RemoveIcon;
