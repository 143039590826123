/* eslint-disable class-methods-use-this */
export interface VizActionHandler {
    /**
     * put focus on the visualization
     */
    focus(): void;

    /**
     * compute snapshot of this visualization
     */
    snapshot(): Record<string, unknown>; // (This is for pdf export)
}

export class DefaultVizActionHandler {
    focus(): void {
        // noop
    }

    snapshot(): Record<string, unknown> {
        return null; // noop
    }
}
