import React, { type MouseEventHandler } from 'react';
import styled from 'styled-components';
import { pick, variables } from '@splunk/themes';
import { noop } from '@splunk/dashboard-utils';
import { headerHeightInterpolationTree } from './themeInterpolation';
import {
    ButtonGroup,
    CloseButton,
    CollapseButton,
    ExpandButton,
} from './FlyoutHeaderButtons';
import { customThemeVariables } from '../customThemeVariables';

const HeaderContainer = styled.div.attrs(() => ({
    'data-test': 'flyout-header-container',
}))`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0 24px;
    height: ${pick(headerHeightInterpolationTree)}px;
    color: ${variables.contentColorActive};
    border-bottom: 1px solid ${customThemeVariables.toolbarBorderColor};
    background-color: ${customThemeVariables.sidebarPanelBackgroundColor};
`;

// Font size matches `Title` (packages/dashboard/src/components/sidebars/NormalSidebar.jsx)
const HeaderTitle = styled.span`
    font-size: 16px;
`;

export type FlyoutHeaderProps = {
    text?: string;
    onCloseClick: MouseEventHandler;
    collapsible?: boolean;
    isCollapsed?: boolean;
    toggleExpansion?: () => void;
};

export const FlyoutHeader = ({
    collapsible = false,
    isCollapsed = false,
    text,
    toggleExpansion = noop,
    onCloseClick,
}: FlyoutHeaderProps) => {
    return (
        <HeaderContainer>
            <HeaderTitle>{text}</HeaderTitle>
            <ButtonGroup>
                {collapsible && isCollapsed && (
                    <ExpandButton onClick={toggleExpansion} />
                )}
                {collapsible && !isCollapsed && (
                    <CollapseButton onClick={toggleExpansion} />
                )}
                <CloseButton onClick={onCloseClick} />
            </ButtonGroup>
        </HeaderContainer>
    );
};
