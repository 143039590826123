/* eslint-disable no-restricted-syntax */
import get from 'lodash/get';
import { put, takeEvery, cancelled, select } from 'redux-saga/effects';

import {
    console,
    logfmt,
    replaceTokensForObject,
    isValidUrl,
    navigateToUrl,
} from '@splunk/dashboard-utils';

import {
    setToken,
    unsetToken,
    selectSubmittedTokens,
} from '../reducers/tokens';
import { selectDefinition } from '../reducers/definition';
import { setSmartSources } from '../reducers/smartSources';
import DefaultInputHandler from '../events/DefaultInputHandler';
import { getSmartSourceDSEventHandler } from '../events/smartSourceDSEvents';
import { SMART_SOURCE_SAGA_EVENT } from '../constants';
import { triggerEvent } from './sagaActions';

const defaultInputHandler = new DefaultInputHandler();
const defaultHandlers = [defaultInputHandler];

/**
 * find event handlers that can handle the given event
 * @param {*} definition
 * @param {*} componentId
 * @param {*} event
 * @param {*} preset
 */
export const findEventHandler = (definition, event, preset, tokens) => {
    const mergedTokens = {
        ...tokens,
        default: {
            ...(tokens?.default || {}),
            ...(event.payload || {}),
        },
    };

    let handlers = [];
    if (event.targetId != null) {
        ['inputs', 'dataSources', 'visualizations'].forEach((type) => {
            const matchedHandlers = get(
                definition,
                [type, event.targetId, 'eventHandlers'],
                []
            )
                .map(({ type: handlerType, options }) => {
                    const replacedTokensOptions =
                        handlerType === 'drilldown.setToken'
                            ? options
                            : replaceTokensForObject(options, mergedTokens);
                    // treat eventhandler as immutable module, always create new instance here
                    return preset.createEventHandler(
                        handlerType,
                        replacedTokensOptions
                    );
                })
                .filter((h) => h.canHandle(event));
            handlers = handlers.concat(matchedHandlers);
        });
    }
    return handlers;
};

export function* executeActions({ actions = [], sagaContext, event }) {
    for (const action of actions) {
        const { payload = {}, type } = action;
        const { dashboardPlugin } = sagaContext;
        let cancel;
        switch (type) {
            case 'linkTo':
                cancel = dashboardPlugin.invokeCancellablePluginCallback(
                    'onLinkToUrl',
                    {
                        dispatchedEvent: event,
                        url: payload.url,
                        newTab: payload.newTab,
                    }
                );
                if (!cancel && isValidUrl(payload.url)) {
                    navigateToUrl(payload.url, payload.newTab);
                }
                break;
            case 'setToken':
                yield put.resolve(
                    setToken(payload.tokens, payload.namespace, payload.submit)
                );
                break;
            case SMART_SOURCE_SAGA_EVENT:
                yield put.resolve(setSmartSources(payload));
                break;
            case 'unsetToken':
                yield put.resolve(
                    unsetToken({
                        tokenName: payload.tokenName,
                        namespace: payload.namespace,
                        submit: payload.submit,
                    })
                );
                break;
            default:
        }
    }
}

export function* handleEvent(sagaContext, action) {
    const definition = yield select(selectDefinition);
    const { targetId, eventType, eventPayload, eventId } = action.payload || {};
    const event = {
        targetId,
        type: eventType,
        payload: eventPayload,
        originalEvent: eventId
            ? sagaContext.eventRegistry.retrieveEvent(eventId)
            : null,
    };
    // Handle pluginCallback first to make sure we always have updated token bindings
    // TODO: remove the `?`, plugin should be defined after hoisting it from core
    sagaContext.dashboardPlugin?.invokePluginCallback('onEventTrigger', event);
    const tokens = yield select(selectSubmittedTokens);
    /**
     * for one particular event, we will execute its default handler first and then the custom handlers.
     * Custom handlers will be executed strictly follow their defined order in dashboard definition.
     */
    const matchedDefaultHandlers = defaultHandlers.filter((h) =>
        h.canHandle(event)
    );
    const matchedCustomHandlers = findEventHandler(
        definition,
        event,
        sagaContext.preset,
        tokens
    );
    const handlers = matchedDefaultHandlers.concat(matchedCustomHandlers);

    // loop through every handler and execute their returned actions in a sequential way
    for (const handler of handlers) {
        const actions = yield handler.handle(event);
        yield executeActions({
            actions,
            sagaContext,
            event,
        });
    }
}

export default function* eventSaga(sagaContext, featureFlags = {}) {
    if (featureFlags?.enableSmartSourceDS) {
        defaultHandlers.push(getSmartSourceDSEventHandler());
    }

    try {
        yield takeEvery(triggerEvent, handleEvent, sagaContext);
    } catch (error) {
        if (!(yield cancelled())) {
            console.error(...logfmt`Caught error: ${error}`);
        }
    } finally {
        // do nothing
    }
}
