import findIndex from 'lodash/findIndex';
import isNumber from 'lodash/isNumber';
import isEmpty from 'lodash/isEmpty';
import clone from 'lodash/clone';
import { getFormatter } from './util/format';
import { inferDataTypeFromSample, drawSample } from './util/types';

const getDataForDataSourceType = (data, dataSourceType) => {
    if (!dataSourceType || !data[dataSourceType] || !data[dataSourceType].data) {
        // @TODO: warn about datasource type not being found
        return {
            fields: [],
            columns: [],
        };
    }
    return data[dataSourceType].data;
};
/*
 * parse fieldIndexRangeStr
 * @param {String} fieldIndexRangeStr   '0:', '0:5', ':5'
 * @returns {Object} info about parsed range if there is any
 */
export const parseFieldRangeReference = fieldIndexRangeStr => {
    if (!fieldIndexRangeStr || fieldIndexRangeStr.indexOf(':') === -1) {
        return {
            isFieldIndexRange: false,
        };
    }
    const [fromFieldIndexStr, toFieldIndexStr] = fieldIndexRangeStr.split(':');
    return {
        isFieldIndexRange: true,
        fromFieldIndex:
            Number.isNaN(Number(fromFieldIndexStr)) || !fromFieldIndexStr ? 0 : Number(fromFieldIndexStr),
        toFieldIndex:
            Number.isNaN(Number(toFieldIndexStr)) || !toFieldIndexStr ? -1 : Number(toFieldIndexStr),
    };
};
/*
 * parse fieldReferenceStr to get field index and column index for user defined encoding. e.g. 'primary[1][123]'
 * @param {String} fieldReferenceStr   'primary[1][123]'
 * @returns {Object} { fieldIndex: 1, columnIndex: 123, isFieldIndexRange: true, fromFieldIndexRange: 0, toFieldIndexRange: 3 }
 */
export const parseIndexBasedFieldReference = fieldReferenceStr => {
    const indices = fieldReferenceStr
        .replace(/^[A-Za-z0-9]+/, '')
        .replace(/\[/g, '')
        .replace(/\]/g, ',')
        .split(',');
    const dataSourceName = fieldReferenceStr.match(/^[A-Za-z0-9]+/)[0];
    const fieldRange = parseFieldRangeReference(indices[0]);
    return {
        dataSourceName,
        fieldIndex: Number.isNaN(Number(indices[0])) || !indices[0] ? null : Number(indices[0]),
        columnIndex: Number.isNaN(Number(indices[1])) || !indices[1] ? null : Number(indices[1]),
        ...fieldRange,
    };
};

/*
 * parse fieldReferenceStr to get field name and column index for user defined encoding. e.g. 'primary.color[123]'
 * @param {String} fieldReferenceStr   'primary.color[123]'
 * @returns {Object} { fieldName: 'color', columnIndex: 123 }
 */
export const parseNameBasedFieldReference = fieldReferenceStr => {
    const dataSourceName = fieldReferenceStr.match(/^[A-Za-z0-9]+/)[0];
    const fieldNameStr = fieldReferenceStr.split('.')[1];
    const splitedStr = fieldNameStr.split('[');
    const fieldName = splitedStr[0];
    const columnIndex = splitedStr[1] ? Number(splitedStr[1].replace(']', '')) : null;

    return { dataSourceName, fieldName, columnIndex };
};

const getDataForIndexBasedFieldReference = (data, fieldReferenceStr) => {
    const { fields, columns } = data;
    const {
        dataSourceName,
        isFieldIndexRange,
        fromFieldIndex,
        toFieldIndex,
        fieldIndex,
        columnIndex,
    } = parseIndexBasedFieldReference(fieldReferenceStr);

    if (isFieldIndexRange) {
        const dataForRange = [];
        if (fromFieldIndex > -1) {
            const endIndex =
                toFieldIndex > -1 ? Math.min(fields.length - 1, toFieldIndex) : fields.length + toFieldIndex;
            for (let i = fromFieldIndex; i <= endIndex; i += 1) {
                dataForRange.push(
                    getDataForIndexBasedFieldReference(data, `${dataSourceName}[${i}][${columnIndex}]`)
                );
            }
            return dataForRange;
        }
    }

    if (isNumber(fieldIndex)) {
        const isNegative = fieldIndex < 0;
        const absoluteIndex = Math.abs(fieldIndex);
        if (
            (isNegative && absoluteIndex > fields.length) ||
            (!isNegative && fieldIndex > fields.length - 1)
        ) {
            // The field index is out of bounds
            return {};
        }
        const correctedFieldIndex = isNegative ? fields.length - absoluteIndex : fieldIndex;
        const fieldName =
            typeof fields[correctedFieldIndex] === 'object'
                ? fields[correctedFieldIndex].name
                : fields[correctedFieldIndex];

        let fieldData;
        if (isNumber(columnIndex)) {
            const isNegativeColumnIndex = columnIndex < 0;

            if (
                (isNegativeColumnIndex && columnIndex < -columns[correctedFieldIndex].length) ||
                columnIndex >= columns[correctedFieldIndex].length
            ) {
                // The column index is out of bounds
                return {};
            }
            const correctColumnIndex = isNegativeColumnIndex
                ? columns[correctedFieldIndex].length + columnIndex
                : columnIndex;
            fieldData = columns[correctedFieldIndex].map(
                () => columns[correctedFieldIndex][correctColumnIndex]
            );
        } else {
            fieldData = columns[correctedFieldIndex];
        }
        return {
            fieldName,
            data: fieldData,
        };
    }
    return {};
};

const getDataForNameBasedFieldReference = (data, fieldReferenceStr) => {
    const { fields, columns } = data;
    const { fieldName, columnIndex } = parseNameBasedFieldReference(fieldReferenceStr); // <datasource>.<fieldname>[0]

    if (fieldName) {
        const fieldIndex = findIndex(fields, field =>
            typeof field === 'object' ? field.name === fieldName : field === fieldName
        );
        if (fieldIndex > -1) {
            let fieldData;
            if (isNumber(columnIndex)) {
                const isNegativeColumnIndex = columnIndex < 0;

                if (
                    (isNegativeColumnIndex && columnIndex < -columns[fieldIndex].length) ||
                    columnIndex >= columns[fieldIndex].length
                ) {
                    // The column index is out of bounds
                    return {};
                }
                const correctColumnIndex = isNegativeColumnIndex
                    ? columns[fieldIndex].length + columnIndex
                    : columnIndex;
                fieldData = columns[fieldIndex].map(() => columns[fieldIndex][correctColumnIndex]);
            } else {
                fieldData = columns[fieldIndex];
            }
            return {
                fieldName,
                data: fieldData,
            };
        }
        // eslint-disable-next-line
        console.warn(`field '${fieldName}' not available.`);
        return {};
    }
    return {};
};

const getDataForFieldReference = (dataSources, fieldReference) => {
    let fieldReferenceStr = fieldReference;
    let formatValue;
    if (Array.isArray(fieldReference) && !fieldReference.isDefault) {
        return fieldReference.map(ref => getDataForFieldReference(dataSources, ref));
    }
    // if the reference is a default and there is multiple default fields to fall back
    if (fieldReference.isDefault && Array.isArray(fieldReference.field)) {
        // iterate through all defaults and check whether the data type matches the expected type
        for (let i = 0; i < fieldReference.field.length; i += 1) {
            fieldReferenceStr = fieldReference.field[i];
            const dataFromDefault = getDataForFieldReference(dataSources, fieldReferenceStr);
            if (Array.isArray(dataFromDefault.data)) {
                const type = inferDataTypeFromSample(drawSample(dataFromDefault.data));
                if (fieldReference.type.indexOf(type) > -1) {
                    return dataFromDefault;
                }
            }
        }
        // if none of the defaults matched the data type use the last default
    }
    if (typeof fieldReference === 'object') {
        const { field } = fieldReference;
        if (field && !Array.isArray(field)) {
            fieldReferenceStr = field;
        }
    }

    const indexBasedRegx = /^[A-Za-z0-9_]+\[.*\].*$/;
    const nameBasedRegx = /^[A-Za-z0-9_]+\..*$/;
    const isIndexBasedReference = indexBasedRegx.test(fieldReferenceStr);
    const isNameBasedReference = nameBasedRegx.test(fieldReferenceStr);

    if (!isIndexBasedReference && !isNameBasedReference) {
        // Unable to parse the encoding. Please verify the encoding format is correct
        return {};
    }

    const dataSourceType = fieldReferenceStr.match(/^(.*?)(\.|\[)/)[1];
    const data = getDataForDataSourceType(dataSources, dataSourceType);
    let fieldData;

    if (isIndexBasedReference) {
        fieldData = getDataForIndexBasedFieldReference(data, fieldReferenceStr);
    } else {
        fieldData = getDataForNameBasedFieldReference(data, fieldReferenceStr);
    }

    if (typeof fieldReference === 'object') {
        const { format } = fieldReference;
        if (format) {
            formatValue = getFormatter(fieldData.data, format);
        }
    }

    if (formatValue && !isEmpty(fieldData)) {
        return {
            fieldName: fieldData.fieldName,
            data: fieldData.data.map(formatValue),
        };
    }
    return fieldData;
};

export const mergeEncoding = (defaultEncoding, userDefinedEncoding = {}) => {
    const mergedEncoding = { ...userDefinedEncoding };
    Object.keys(defaultEncoding).forEach(key => {
        if (
            isEmpty(userDefinedEncoding[key]) &&
            defaultEncoding[key].isRequired &&
            defaultEncoding[key].default
        ) {
            mergedEncoding[key] =
                typeof defaultEncoding[key].default === 'object' &&
                !Array.isArray(defaultEncoding[key].default)
                    ? {
                          ...defaultEncoding[key].default,
                          isDefault: true,
                          type: defaultEncoding[key].type,
                      }
                    : {
                          field: defaultEncoding[key].default,
                          isDefault: true,
                          type: defaultEncoding[key].type,
                      };
        }
    });
    return mergedEncoding;
};

export const parse = (dataSources, encoding = {}) => {
    const parsedOutput = {
        _meta: {
            fieldNames: {},
            types: {},
        },
    };

    Object.keys(encoding).forEach(key => {
        let fieldReference = clone(encoding[key]);

        // handle the case for encoding field reference, for example fill: 'encoding.trend'
        if (
            typeof fieldReference === 'object' &&
            fieldReference.field &&
            fieldReference.field.indexOf('encoding') === 0
        ) {
            const replacedKey = fieldReference.field.split('.')[1];

            // return for the below invalid cases
            // `encoding.z` if `z` is not a valid field in encoding
            if (!encoding[replacedKey]) {
                // eslint-disable-next-line
                console.warn(`${key} field cannot refer to 'encoding.${replacedKey}'.`);
            }
            // if `z` is an object configuration in encoding simply extract the field when refering `encoding.z`
            const field =
                typeof encoding[replacedKey] === 'object'
                    ? encoding[replacedKey].field
                    : encoding[replacedKey];
            fieldReference = {
                ...fieldReference,
                field,
            };
        }

        const dataForFieldReference = getDataForFieldReference(dataSources, fieldReference);

        if (Array.isArray(dataForFieldReference)) {
            parsedOutput[key] = dataForFieldReference.map(entry => entry.data);
            parsedOutput._meta.fieldNames[key] = dataForFieldReference.map(entry => entry.fieldName); // eslint-disable-line no-underscore-dangle
            // eslint-disable-next-line no-underscore-dangle
            parsedOutput._meta.types[key] = dataForFieldReference.map(entry =>
                inferDataTypeFromSample(drawSample(entry.data))
            );
        } else {
            const { fieldName, data } = dataForFieldReference;
            if (fieldName) {
                parsedOutput[key] = data;
                parsedOutput._meta.fieldNames[key] = fieldName; // eslint-disable-line no-underscore-dangle
                // eslint-disable-next-line no-underscore-dangle
                parsedOutput._meta.types[key] = inferDataTypeFromSample(drawSample(data));
            }
        }
    });

    return parsedOutput;
};
