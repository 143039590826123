/* eslint-disable class-methods-use-this,no-useless-constructor */
import { _ } from '@splunk/ui-utils/i18n';
import Provider from '@splunk/visualization-context/Provider';
import USGeoJson from './geoAssets/us.geo.json';
import WorldGeoJson from './geoAssets/world.geo.json';

class GeoJsonProvider extends Provider {
    constructor() {
        super();
    }

    /*
     * return provider type
     * @return {String} 'geo'
     */
    getType() {
        return 'geo';
    }

    /*
     * getById
     * Todo: if storageType === 'default', fetch geoJson from s3, else fetch from kvstore in scp provider
     * @params {Object} config.mapId  'us' or 'world'
     * @return {Json} USGeoJson or WorldGeoJson
     */
    getById({ mapId }) {
        if (!mapId) {
            throw new Error(_('Invalid mapId.'));
        }

        if (mapId.toLowerCase() === 'us') {
            return USGeoJson;
        }
        if (mapId.toLowerCase() === 'world') {
            return WorldGeoJson;
        }

        throw new Error(_('The geoJson is not found.'));
    }

    /*
     * Todo: link to storage service in the future
     * @params {Object} config
     * @params {Number} offset
     * @params {Number} count
     * @params {Array} [{id: "us", url: 'geo://default/us'}, {id: 'world', url: 'geo://default/world'}]
     */
    listIds({ offset = 0, count = 100 }) {
        const lists = [
            {
                id: 'world',
                url: 'geo://default/world',
            },
            {
                id: 'us',
                url: 'geo://default/us',
            },
        ];

        return lists.slice(offset, offset + count);
    }

    // Todo: be able to upload geoJson in the future
    upload() {}

    // Todo: be able to delete geoJson in the future
    deleteById() {}
}

export default GeoJsonProvider;
