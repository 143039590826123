import React from 'react';
import { _ } from '@splunk/ui-utils/i18n';
import SVG, {
    type SVGPropsOptionalViewBox,
} from '@splunk/react-icons/SVGEnterprise';

/* eslint-disable max-len */
export default function AlignRight(
    props: SVGPropsOptionalViewBox
): ReturnType<typeof SVG> {
    return (
        <SVG viewBox="0 0 24 24" screenReaderText={_('Align Right')} {...props}>
            <path
                fill="currentColor"
                d="M23 0.499998L23 23.5C23 23.7761 23.2239 24 23.5 24C23.7761 24 24 23.7761 24 23.5L24 0.5C24 0.223858 23.7761 -1.95703e-08 23.5 -4.37114e-08C23.2239 -6.78525e-08 23 0.223856 23 0.499998Z"
            />
            <path
                fill="currentColor"
                d="M8.5 11L20.5 11C20.7761 11 21 10.7761 21 10.5L21 4.5C21 4.22386 20.7761 4 20.5 4L8.5 4C8.22386 4 8 4.22386 8 4.5L8 10.5C8 10.7761 8.22386 11 8.5 11Z"
            />
            <path
                fill="currentColor"
                d="M0.5 20L20.5 20C20.7761 20 21 19.7761 21 19.5L21 13.5C21 13.2239 20.7761 13 20.5 13L0.500001 13C0.223858 13 5.92389e-07 13.2239 5.68248e-07 13.5L4.37114e-08 19.5C1.95703e-08 19.7761 0.223858 20 0.5 20Z"
            />
        </SVG>
    );
}
