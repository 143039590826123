// This is very similar to the @dnd-kit/modifiers snapCenterToCursor modifier
// but can conditionally perform the snap transformation unlike the OOTB version
// and will only snap the horizontal center to the cursor

import type { Modifier } from '@dnd-kit/core';
import { getEventCoordinates } from '@dnd-kit/utilities';

export const SnapCenterXToCursor: Modifier = ({
    activatorEvent,
    active,
    draggingNodeRect,
    transform,
}) => {
    if (active?.data?.current?.snapCenterToCursor) {
        if (draggingNodeRect && activatorEvent) {
            const activatorCoordinates = getEventCoordinates(activatorEvent);

            if (!activatorCoordinates) {
                return transform;
            }

            const offsetX = activatorCoordinates.x - draggingNodeRect.left;

            return {
                ...transform,
                x: transform.x + offsetX - draggingNodeRect.width / 2,
            };
        }
    }

    return transform;
};
