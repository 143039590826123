import findIndex from 'lodash/findIndex';
import find from 'lodash/find';
import sortBy from 'lodash/sortBy';
import flattenDeep from 'lodash/flattenDeep';
import { every } from 'lodash';

const TIME_RANGES = {
    month: [
        { january: true, jan: true, 'jan.': true },
        { february: true, feb: true, 'feb.': true },
        { march: true, mar: true, 'mar.': true },
        { april: true, apr: true, 'apr.': true },
        { may: true },
        { june: true, jun: true, 'jun.': true },
        { july: true, jul: true, 'jul.': true },
        { august: true, aug: true, 'aug.': true },
        { september: true, sep: true, sept: true, 'sep.': true, 'sept.': true },
        { october: true, oct: true, 'oct.': true },
        { november: true, nov: true, 'nov.': true },
        { december: true, dec: true, 'dec.': true },
    ],
    wday: [
        { monday: true, mon: true, 'mon.': true },
        { tuesday: true, tu: true, 'tu.': true, tue: true, 'tue.': true, tues: true, 'tues.': true },
        { wednesday: true, wed: true, 'wed.': true },
        { thursday: true, th: true, 'th.': true, thur: true, 'thur.': true, thurs: true, 'thurs.': true },
        { friday: true, fri: true, 'fri.': true },
        { saturday: true, sat: true, 'sat.': true },
        { sunday: true, sun: true, 'sun.': true },
    ],
};

/**
 * Sort axis labels by weekday
 * @param {array} axis - string array
 * @return {array} - string array. If those are not weekday strings, return []
 */
export const sortedByWeekday = axis => {
    const sortedAxis = [];
    const notWeekDay = find(axis, value => {
        const lowerValue = value.toLowerCase();
        const index = findIndex(TIME_RANGES.wday, lowerValue);
        if (index === -1) {
            // if the axis contains any value not inside weekdays, then don't treat it as weekdays
            return true;
        }
        if (sortedAxis[index]) {
            sortedAxis[index] = [sortedAxis[index], value];
        } else {
            sortedAxis[index] = value;
        }
        return false;
    });

    return notWeekDay ? [] : flattenDeep(sortedAxis.filter(Boolean));
};

/**
 * Sort axis labels by month
 * @param {array} axis - string array
 * @return {array} - string array. If those are not month strings, return []
 */
export const sortedByMonth = axis => {
    const sortedAxis = [];
    const notMonth = find(axis, value => {
        const lowerValue = value.toLowerCase();
        const index = findIndex(TIME_RANGES.month, lowerValue);
        if (index === -1) {
            // if the axis contains any value not inside month, then don't treat it as month
            return true;
        }
        if (sortedAxis[index]) {
            sortedAxis[index] = [sortedAxis[index], value];
        } else {
            sortedAxis[index] = value;
        }
        return false;
    });
    return notMonth ? [] : flattenDeep(sortedAxis.filter(Boolean));
};

/**
 * Sort axis labels by date time
 * @param {array} axis - string array
 * @return {array} - string array. If those are not in acceptTimeFormat or formatted date, return []
 */
export const sortedByDTime = axis => {
    // valid time format 01:00 am, 01:00:00, 01:00
    const acceptTimeFormat = [
        /^([0-1])?\d( )?(am|pm)$/i, // 1am, 1 am, 12am, 12 am
        /^([0-1])?\d:[0-5]\d( )?(am|pm)$/i, // 01:00 am, 01:00am, 1:00am
        /^([0-2])?\d:([0-5]\d:)?[0-5]\d$/, // 01:00:00, 1:00:00, 23:00
        /^([0-2])?\d:[0-5]\d:[0-5]\d( )?(am|pm)$/i, // 01:00:00 am, 1:00:00am
    ];

    const sortArr = [];
    const notDate = find(axis, value => {
        if (!Number.isNaN(Number(value))) {
            return true;
        }
        // should accept long date: 2019-03-02, Mar 2 2019, ..., 2019-01-01T00:00:00.000Z
        let convertedDate = new Date(value);
        if (!Number.isNaN(Number(convertedDate))) {
            sortArr.push({ epoch: convertedDate, original: value });
            return false;
        }

        const acceptedTime = acceptTimeFormat.find(format => format.test(value));
        if (acceptedTime) {
            const midday = /(am|pm)$/i;
            let reformatTime = value;
            const containsMidday = value.match(midday);
            if (containsMidday) {
                // for time format like 01:00 am and 1 am
                reformatTime = value.split(midday).slice(0, -1);
                reformatTime[0] = `${reformatTime[0].trim()}:00`;
                reformatTime = reformatTime.join(' ');
            }
            convertedDate = new Date(`1970/01/01 ${reformatTime}`);
            sortArr.push({ epoch: convertedDate, original: value });
            return false;
        }
        return true;
    });

    if (notDate) {
        return [];
    }
    return sortBy(sortArr, val => val.epoch).map(val => val.original);
};

/**
 * Sort axis labels by digits or alphabetical order
 * @param {array} axis - string array
 * @return {array} - string array.
 * If those inputs are numbers, then return in digits order. Otherwise, return array based on alphabetical order
 */
export const sortByDigitOrAlpha = axis => {
    // 10, 10.0, 10.1, not accept 10,000.0
    const coercedIsFinite = num => Number.isFinite(Number(num));
    if (every(axis, coercedIsFinite)) {
        return axis.sort((a, b) => Number(a) - Number(b));
    }
    return axis.sort();
};

/**
 * Sort axis based on the priority: time measures, numbers, strings in ascending order
 * @param {array} axis - string array
 * @return {array} - string array
 */
export const sortAxis = axis => {
    const sortedTimeAxis = sortedByDTime(axis);
    if (sortedTimeAxis.length) {
        return sortedTimeAxis;
    }
    const sortedWeekdays = sortedByWeekday(axis);
    if (sortedWeekdays.length) {
        return sortedWeekdays;
    }
    const sortedMonths = sortedByMonth(axis);
    if (sortedMonths.length) {
        return sortedMonths;
    }

    return sortByDigitOrAlpha(axis);
};
