import React, {
    createContext,
    useMemo,
    useState,
    useCallback,
    useContext,
} from 'react';

interface SidebarContextValue {
    collapsibleState: Record<string, boolean>;
    onCollapsibleOpen: (label: string) => void;
    onCollapsibleClose: (label: string) => void;
}

export const SidebarContext = createContext<SidebarContextValue | null>(null);

export const SidebarContextProvider = ({
    children,
}: {
    children?: React.ReactNode;
}): JSX.Element => {
    const [collapsibleState, setCollapsibleState] = useState({});

    const onCollapsibleOpen = useCallback(
        (label) =>
            setCollapsibleState((prevState) => ({
                ...prevState,
                [label]: true,
            })),
        []
    );

    const onCollapsibleClose = useCallback(
        (label) =>
            setCollapsibleState((prevState) => ({
                ...prevState,
                [label]: false,
            })),
        []
    );

    const contextValue = useMemo(
        () => ({
            collapsibleState,
            onCollapsibleOpen,
            onCollapsibleClose,
        }),
        [collapsibleState, onCollapsibleClose, onCollapsibleOpen]
    );

    return (
        <SidebarContext.Provider value={contextValue}>
            {children}
        </SidebarContext.Provider>
    );
};

export const useSidebarContext = () => useContext(SidebarContext);
