import type { GridLayoutStructure } from '@splunk/dashboard-types';

interface GridLine {
    pos: number;
    names: string[];
}

/**
 * Returns a template for the rows/columns of a CSS grid given a list of grid lines along an axis
 * with range [0, axisLength].
 */
const getTemplateItems = (
    [currGridLine, nextGridLine, ...rest]: GridLine[],
    axisLength: number,
    templateItems: string[] = []
): string => {
    // add the names for the current grid line
    templateItems.push(`[${currGridLine.names.join(' ')}]`);

    if (!nextGridLine) {
        return templateItems.join(' ');
    }

    // add the space between the current grid line and the next grid line
    const gridTrackWidth = Math.round(
        ((nextGridLine.pos - currGridLine.pos) / axisLength) * 100
    );
    templateItems.push(`minmax(0, ${gridTrackWidth}fr)`);

    return getTemplateItems([nextGridLine, ...rest], axisLength, templateItems);
};

/**
 * Wrapper function for getTemplateItems.
 */
const getTemplateItemsFromGridLines = (
    gridLines: GridLine[],
    axisLength: number
): string => {
    if (gridLines.length === 0) {
        return '';
    }
    return getTemplateItems(gridLines, axisLength);
};

interface GetCSSGridTemplateArgs {
    layoutStructure: GridLayoutStructure;
    width: number;
    height: number;
}

/**
 * Returns strings to be used as the `grid-template-columns` and `grid-layout-rows` properties of
 * a CSS Grid. Derived from the layout structure.
 */
export const getCSSGridTemplate = ({
    layoutStructure,
    width,
    height,
}: GetCSSGridTemplateArgs): {
    gridRowTemplate: string;
    gridColTemplate: string;
} => {
    const xCoords: string[][] = [];
    const yCoords: string[][] = [];

    layoutStructure.forEach(({ position: { x, y, w, h }, item }) => {
        // create an empty list of breakpoints for a given point if it does not exist
        xCoords[x] = xCoords[x] ?? [];
        xCoords[x + w] = xCoords[x + w] ?? [];
        yCoords[y] = yCoords[y] ?? [];
        yCoords[y + h] = yCoords[y + h] ?? [];

        // add breakpoint to the list of breakpoints for a given point
        xCoords[x].push(`${item}-start`);
        xCoords[x + w].push(`${item}-end`);
        yCoords[y].push(`${item}-start`);
        yCoords[y + h].push(`${item}-end`);
    });

    const xGridLines = Object.entries(xCoords).map(([pos, names]) => ({
        pos: Number(pos),
        names,
    }));
    const yGridLines = Object.entries(yCoords).map(([pos, names]) => ({
        pos: Number(pos),
        names,
    }));

    const gridRowTemplate = getTemplateItemsFromGridLines(yGridLines, height);
    const gridColTemplate = getTemplateItemsFromGridLines(xGridLines, width);

    return { gridRowTemplate, gridColTemplate };
};
