import { useRef, type ReactNode } from 'react';
import { useDefinitionChangeCallback } from './useDefinitionChangeCallback';
import { useTokenBindingChangeCallback } from './useTokenBindingChangeCallback';
import { useModeChangeCallback } from './useModeChangeCallback';
import { useSelectedItemsChangeCallback } from './useSelectedItemsChangeCallback';
import type { OnChangeCallbacksProps } from './onChange.types';

export const OnChangeCallbacks = <T extends ReactNode>({
    children,
    initialTokenBinding,
    onDefinitionChange,
    onTokenBindingChange,
    onModeChange,
    onItemsSelect,
}: OnChangeCallbacksProps<T>) => {
    useDefinitionChangeCallback({
        onDefinitionChange,
    });

    const initialTokenBindingRef = useRef(initialTokenBinding);
    useTokenBindingChangeCallback({
        initialTokenBinding: initialTokenBindingRef.current,
        onTokenBindingChange,
    });

    useModeChangeCallback({ onModeChange });

    useSelectedItemsChangeCallback({
        onItemsSelect,
    });

    return children;
};
