import { _ } from '@splunk/ui-utils/i18n';
import { defaultPalettesConfig } from '@splunk/visualization-color-palettes/editors/PresetPalettes';
import pick from '@splunk/themes/pick';
import variables from '@splunk/themes/variables';
import { DataContract } from '../common/interfaces/DataContract';
import { DefaultContext } from '../common/interfaces/DefaultContext';
import { EditorConfig } from '../common/interfaces/Editor';
import { OptionsSchema } from '../common/interfaces/OptionsSchema';
import { ThemedDefaults } from '../common/interfaces/ThemedDefaults';
import { VizBehavior } from '../common/interfaces/VizBehavior';
import { VizCategory } from '../common/interfaces/VizCategory';
import { VizConfig } from '../common/interfaces/VizConfig';
import { VizSize } from '../common/interfaces/VizSize';
import { PresetEntry, enhanceConfig, getInitialPreset } from '../common/utils/configUtils';
import { getDynamicFillColorEditor } from '../common/editorConfig/DynamicFillColorEditor';
import { getDynamicStrokeColorEditor } from '../common/editorConfig/DynamicStrokeColorEditor';
import StaticFillColorEditor from '../common/editorConfig/StaticFillColorEditor';
import StaticStrokeColorEditor from '../common/editorConfig/StaticStrokeColorEditor';

const dataContract: DataContract = {
    requiredDataSources: [],
    optionalDataSources: [
        {
            name: 'primary',
            description: 'DataSource that powers the visualization',
        },
    ],
    initialRequestParams: {
        primary: { offset: 0, count: 10000 },
    },
};

const size: VizSize = {
    initialWidth: 300,
    initialHeight: 300,
};

const defaultContext: DefaultContext = {
    fillColorConfig: [
        {
            from: 100,
            value: '#118832',
        },
        {
            from: 80,
            to: 100,
            value: '#669922',
        },
        {
            from: 60,
            to: 80,
            value: '#9D9F0D',
        },
        {
            from: 40,
            to: 60,
            value: '#CBA700',
        },
        {
            from: 20,
            to: 40,
            value: '#D97A0D',
        },
        {
            from: 0,
            to: 20,
            value: '#D94E17',
        },
        {
            to: 0,
            value: '#D41F1F',
        },
    ],
    strokeColorConfig: [
        {
            from: 100,
            value: '#118832',
        },
        {
            from: 80,
            to: 100,
            value: '#669922',
        },
        {
            from: 60,
            to: 80,
            value: '#9D9F0D',
        },
        {
            from: 40,
            to: 60,
            value: '#CBA700',
        },
        {
            from: 20,
            to: 40,
            value: '#D97A0D',
        },
        {
            from: 0,
            to: 20,
            value: '#D94E17',
        },
        {
            to: 0,
            value: '#D41F1F',
        },
    ],
    fillColorMatchConfig: [{ match: '', value: '#5C33FF' }],
    strokeColorMatchConfig: [{ match: '', value: '#5C33FF' }],
};

const optionsSchema: OptionsSchema = {
    fillColor: {
        default: '> themes.defaultFillColor',
        description:
            'Specify the fill color. You may use a dataSource to apply the color. The hex value format should be "#FFFFFF". The default for enterprise light is "#C3CBD4". The default for enterprise dark is "#31373E". The default for prisma dark is "#0B0C0E".',
        type: 'string',
    },
    fillOpacity: {
        default: 1,
        description:
            'Specify the opacity of the fill. Choose a number in the range of 0 - 1 (inclusive). You can also express the value as a percentage. For example, "0.80" in source or "80%" in UI.',
        type: 'number',
    },
    strokeColor: {
        default: '> themes.defaultStrokeColor',
        description:
            'Specify the stroke color. You may use a dataSource to apply the color. The hex value format should be "#FFFFFF". The default for enterprise light is "#3C444D". The default for enterprise dark is "#C3CBD4". The default for prisma dark is "#ACACAD".',
        type: 'string',
    },
    strokeDashStyle: {
        default: 0,
        description:
            'Specify the size, in pixels, of dashes and spaces used to create a custom stitched outline. The value you specify applies to both the dashes and the spaces between them.',
        type: 'number',
    },
    strokeOpacity: {
        default: 1,
        description:
            'Specify the opacity of the stroke. Choose a number in the range of 0 - 1 (inclusive). You can also express the value as a percentage. For example, "0.80" in source or "80%" in UI.',
        type: 'number',
    },
    strokeWidth: {
        default: 1,
        description: 'Specify the width of the stroke, in pixels, in the range of 1 - 25 (inclusive).',
        type: 'number',
    },
};

const themes: ThemedDefaults = {
    defaultFillColor: props =>
        pick({
            enterprise: {
                dark: variables.gray25(props),
                light: variables.gray80(props),
            },
            prisma: variables.backgroundColorSidebar(props),
        })(props),
    defaultStrokeColor: props =>
        pick({
            enterprise: {
                dark: variables.gray80(props),
                light: variables.gray30(props),
            },
            prisma: variables.neutral500(props),
        })(props),
};

const basePresetContext = {
    fillColorEditorConfig: undefined,
    strokeColorEditorConfig: undefined,
};

const basePresetOptions = {
    fillColor: undefined,
    strokeColor: undefined,
};

const presets: PresetEntry[] = [
    {
        // dummy context variables to allow for resetting of unused context states
        label: _('None'),
        name: 'ellipse.none',
        value: {
            context: { ...basePresetContext },
            options: { ...basePresetOptions },
        },
    },
    {
        label: _('Fill'),
        name: 'ellipse.fill',
        value: {
            context: {
                ...basePresetContext,
                fillDataValue: '> primary | seriesByType("number") | lastPoint()',
                fillColorEditorConfig: defaultContext.fillColorConfig,
            },
            options: {
                ...basePresetOptions,
                fillColor: '> fillDataValue | rangeValue(fillColorEditorConfig)',
            },
        },
    },
    {
        label: _('Stroke'),
        name: 'ellipse.stroke',
        value: {
            context: {
                ...basePresetContext,
                strokeDataValue: '> primary | seriesByType("number") | lastPoint()',
                strokeColorEditorConfig: defaultContext.strokeColorConfig,
            },
            options: {
                ...basePresetOptions,
                strokeColor: '> strokeDataValue | rangeValue(strokeColorEditorConfig)',
            },
        },
    },
    {
        label: _('Fill and Stroke'),
        name: 'ellipse.fillAndStroke',
        value: {
            context: {
                ...basePresetContext,
                fillDataValue: '> primary | seriesByType("number") | lastPoint()',
                strokeDataValue: '> primary | seriesByType("number") | lastPoint()',
                fillColorEditorConfig: defaultContext.fillColorConfig,
                strokeColorEditorConfig: defaultContext.strokeColorConfig,
            },
            options: {
                ...basePresetOptions,
                fillColor: '> fillDataValue | rangeValue(fillColorEditorConfig)',
                strokeColor: '> strokeDataValue | rangeValue(strokeColorEditorConfig)',
            },
        },
    },
];

const editorConfig: EditorConfig[] = [
    {
        label: _('Color and style'),
        layout: [
            [
                {
                    label: 'Dynamic elements',
                    key: 'presetSelector',
                    editor: 'editor.presetSelector',
                    editorProps: {
                        presets,
                        value: ({ context, options }) => {
                            const initialPreset = getInitialPreset(context, options, presets);
                            return (initialPreset && initialPreset.label) || null;
                        },
                    },
                },
            ],
            [
                {
                    ...StaticFillColorEditor,
                    editorProps: {
                        themes,
                    },
                },
            ],
            [getDynamicFillColorEditor({ defaultContext, defaultPalettesConfig })],
            [
                {
                    ...StaticStrokeColorEditor,
                    editorProps: {
                        themes,
                    },
                },
            ],
            [getDynamicStrokeColorEditor({ defaultContext, defaultPalettesConfig })],
            [
                {
                    label: _('Fill opacity (%)'),
                    option: 'fillOpacity',
                    editor: 'editor.percent',
                    editorProps: {
                        min: 0,
                    },
                },
                {
                    label: _('Stroke opacity (%)'),
                    option: 'strokeOpacity',
                    editor: 'editor.percent',
                    editorProps: {
                        min: 0,
                    },
                },
            ],
            [
                {
                    label: _('Stroke width'),
                    option: 'strokeWidth',
                    editor: 'editor.number',
                    editorProps: {
                        min: 0,
                    },
                },
                {
                    label: _('Stroke dash style'),
                    option: 'strokeDashStyle',
                    editor: 'editor.number',
                    editorProps: {
                        min: 0,
                    },
                },
            ],
        ],
    },
];

/**
 * visualization configuration
 */
const config: VizConfig = {
    /**
     * unique viz key
     */
    key: 'splunk.ellipse',
    /**
     * viz name
     */
    name: 'Ellipse',
    category: VizCategory.SHAPES,
    /**
     * viz icon
     */
    icon: null,
    dataContract,
    size,
    defaultContext,
    optionsSchema,
    editorConfig,
    events: {
        'ellipse.click': {
            description: 'triggered when user clicks ellipse',
            payloadKeys: ['strokeColor', 'fillColor'],
        },
    },
    supports: [VizBehavior.DYNAMIC_OPTIONS, VizBehavior.EVENTS],
    themes,
};

export default enhanceConfig(config);
