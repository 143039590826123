import * as React from 'react';
import { _ } from '@splunk/ui-utils/i18n';
import SVG from '@splunk/react-icons/SVGEnterprise';

const Table = (props: React.ComponentProps<typeof SVG>): React.ReactElement<SVGElement> => (
    <SVG screenReaderText={_('Table')} viewBox="0 0 20 12" {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M0.833008 1.41663C0.833008 0.726271 1.39265 0.166626 2.08301 0.166626H17.9163C18.6067 0.166626 19.1663 0.726271 19.1663 1.41663V10.5833C19.1663 11.2736 18.6067 11.8333 17.9163 11.8333H2.08301C1.39265 11.8333 0.833008 11.2736 0.833008 10.5833V1.41663ZM2.49967 1.83329V10.1666H9.16634V6.88863H2.50374V5.22197H9.16634V1.83329H2.49967ZM10.833 6.88863V10.1666H17.4997V6.88863H10.833ZM17.4997 5.22197H10.833V1.83329H17.4997V5.22197Z"
            fill="currentColor"
        />
    </SVG>
);

export default Table;
