import { _ } from '@splunk/ui-utils/i18n';
import pick from '@splunk/themes/pick';
import variables from '@splunk/themes/variables';
import ThresholdConfig from '../../common/editorConfig/Threshold';

const editorConfig = [
    {
        label: _('Fill'),
        layout: [
            [
                {
                    label: _('Color'),
                    option: 'fill',
                    editor: 'editor.color',
                    editorProps: {
                        pickFromTheme: theme =>
                            pick({
                                enterprise: {
                                    light: variables.gray80,
                                    dark: variables.gray25,
                                },
                                prisma: variables.backgroundColorSidebar,
                            })({ theme }),
                    },
                },
                {
                    label: _('Opacity (%)'),
                    option: 'fillOpacity',
                    editor: 'editor.percent',
                    editorProps: {
                        min: 0,
                    },
                },
            ],
        ],
    },
    {
        label: _('Border'),
        layout: [
            [
                {
                    label: _('Color'),
                    option: 'stroke',
                    editor: 'editor.color',
                    editorProps: {
                        pickFromTheme: theme =>
                            pick({
                                enterprise: {
                                    dark: variables.gray80,
                                    light: variables.textColor,
                                },
                                prisma: variables.neutral500,
                            })({ theme }),
                    },
                },
                {
                    label: _('Opacity (%)'),
                    option: 'strokeOpacity',
                    editor: 'editor.percent',
                    editorProps: {
                        min: 0,
                    },
                },
            ],
            [
                {
                    label: _('Thickness (px)'),
                    option: 'strokeWidth',
                    editor: 'editor.number',
                    editorProps: {
                        min: 0,
                    },
                },
            ],
            [
                {
                    label: _('Dash Style'),
                    option: 'strokeDasharray',
                    editor: 'editor.number',
                    editorProps: {
                        min: 0,
                    },
                },
            ],
        ],
    },
    ThresholdConfig,
];

export default editorConfig;
