const isObjectLike = (
    value: unknown
): value is Record<string, unknown> | ArrayLike<unknown> =>
    !!value && typeof value === 'object';

/**
 * Recursively freezes obj and its properties using Object.freeze(). The values of a frozen object
 * cannot be changed. There is a risk that this function may freeze an object that should not be
 * frozen (i.e. window).
 * @param {*} obj The object the should be recursively frozen.
 * @returns {*} The object after having all its properties recursively frozen.
 */
const deepFreeze = <T>(obj: T): T => {
    if (!isObjectLike(obj)) {
        return obj;
    }

    Object.values(obj).forEach((val) => {
        deepFreeze(val);
    });
    return Object.freeze(obj);
};

export default deepFreeze;
