import { FieldEntry } from '@splunk/visualizations-shared/dataSourceUtils';

export interface JsonColumnData {
    fields: FieldEntry[] | string[];
    columns: string[][];
}
export interface Meta {
    totalCount: number;
    sid: string;
    app: string;
}

export const validSortDirs = ['asc', 'desc', 'none'] as const;
export type SortDirType = typeof validSortDirs[number];

export interface RequestParams {
    count?: number;
    offset?: number;
    sort?: {
        [sortKey: string]: SortDirType;
    };
}

export interface DataSource {
    requestParams: Partial<RequestParams>;
    onRequestParamsChange?: (p: Record<string, unknown>) => void;
    data: JsonColumnData;
    error?: { level: string; message: string };
    meta: Partial<Meta>;
}

export interface DataSourceMeta {
    requestParams: Partial<RequestParams>;
    meta: Partial<Meta>;
    onRequestParamsChange: (payload: RequestParams) => void;
}
