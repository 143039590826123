import type {
    DashboardJSON,
    RootDataSourcesDefinition,
    RootInputsDefinition,
    RootVisualizationsDefinition,
    EventHandlerDefinition,
    VisualizationDefinition,
    InputDefinition,
} from '@splunk/dashboard-types';

type DrilldownCounts = {
    numDrilldowns: number;
    drilldownTypeCounts: Record<string, number>;
};

interface DefinitionInfo {
    componentCounts: {
        dataSourceTypeCounts: Record<string, number>;
        inputTypeCounts: Record<string, number>;
        vizTypeCounts: Record<string, number>;
        drilldownTypeCounts: Record<string, number>;
    };
    numConditionallyVisible: number;
    numDataSources: number;
    numInputs: number;
    numViz: number;
    numSmartSourcesDS: number;
    numDrilldowns: number;
    numInputsOnCanvas: number;
}

interface DataObject {
    [key: string]: { type?: string };
}

type DataCount = {
    [key: string]: number;
};

export const getTypeCounts = (
    dataObject: DataObject = {}
): Record<string, number> => {
    const dataCount: DataCount = {};
    Object.keys(dataObject).forEach((data) => {
        const dataType = dataObject[data] && dataObject[data].type;
        if (!dataType) {
            return;
        }
        if (!dataCount[dataType]) {
            dataCount[dataType] = 0;
        }
        dataCount[dataType] += 1;
    });
    return dataCount;
};

/**
 * Returns the number of definitions that contain
 * an options.enableSmartSources flag set to true
 *
 * @param {Object} definitions root definitions for 'dataSources', 'visualizations', or 'inputs'
 * @returns {number} count of definitions that contain options?.enableSmartSources
 */
const getSmartSourceCount = (
    definitions:
        | RootDataSourcesDefinition
        | RootVisualizationsDefinition
        | RootInputsDefinition
) => {
    let count = 0;

    Object.keys(definitions).forEach((key) => {
        if (definitions[key].options?.enableSmartSources) {
            count += 1;
        }
    });

    return count;
};

const getDrilldownCounts = (definition: DashboardJSON): DrilldownCounts => {
    const visualizations = definition.visualizations ?? {};
    const handlers = Object.values(visualizations).reduce(
        (prev: EventHandlerDefinition[], { eventHandlers = [] }) =>
            prev.concat(eventHandlers),
        []
    );
    const numDrilldowns = handlers.length;
    const drilldownTypeCounts: Record<string, number> = {};

    handlers.forEach((handler) => {
        const { type } = handler;
        drilldownTypeCounts[type] ??= 0;
        drilldownTypeCounts[type] += 1;
    });
    return { numDrilldowns, drilldownTypeCounts };
};

const getShowHideCountReducer = <
    T extends VisualizationDefinition | InputDefinition
>(
    count: number,
    def: T
) => (def.hideWhenNoData ? count + 1 : count);

const getShowHideCount = (definition: DashboardJSON): number => {
    const vizShowHideCount = Object.values(
        definition.visualizations || {}
    ).reduce(getShowHideCountReducer, 0);

    const inputShowHideCount = Object.values(definition.inputs || {}).reduce(
        getShowHideCountReducer,
        0
    );

    return vizShowHideCount + inputShowHideCount;
};

export const extractDefinitionInfo = (
    definition: DashboardJSON = {}
): DefinitionInfo => {
    const visualizations = definition.visualizations || {};
    const inputs = definition.inputs || {};
    const dataSources = definition.dataSources || {};
    const layoutStructure: unknown = definition.layout?.structure || [];

    const dataSourceTypeCounts = getTypeCounts(dataSources);
    const inputTypeCounts = getTypeCounts(inputs);
    const vizTypeCounts = getTypeCounts(visualizations);
    const drilldownCounts = getDrilldownCounts(definition);

    return {
        componentCounts: {
            dataSourceTypeCounts,
            inputTypeCounts,
            vizTypeCounts,
            drilldownTypeCounts: drilldownCounts.drilldownTypeCounts,
        },
        numConditionallyVisible: getShowHideCount(definition),
        numDataSources: Object.keys(dataSources).length,
        numInputs: Object.keys(inputs).length,
        numViz: Object.keys(visualizations).length,
        numSmartSourcesDS: getSmartSourceCount(dataSources),
        numDrilldowns: drilldownCounts.numDrilldowns,
        numInputsOnCanvas: Array.isArray(layoutStructure)
            ? layoutStructure.filter(
                  (item: unknown) =>
                      !!item &&
                      !Array.isArray(item) &&
                      typeof item === 'object' &&
                      (item as Record<string, unknown>).type === 'input'
              ).length
            : 0,
    };
};
