import { get } from 'lodash';
import { sanitizeArrayOfColors } from '@splunk/visualizations-shared/colorUtils';
import config from './config';
import withDashboardViz, { DashboardVizOpts } from '../common/withDashboardViz';
import PureTable from './PureTable';
import type { ColumnFormat } from './hooks';

export const computeVizProps = ({ ...props }): Record<string, unknown> => {
    const vizProps = { ...props };
    if (vizProps.columnFormat) {
        const columns: Partial<ColumnFormat> = vizProps.columnFormat;

        const themeRowBackgroundColorOdd = vizProps.themeFunc('rowBackgroundColorOdd');
        const themeRowBackgroundColorEven = vizProps.themeFunc('rowBackgroundColorEven');
        const themeTextColor = vizProps.themeFunc('textColor');

        // `columns` looks like { `column title`: { rowBackgroundColors, rowColors... }}
        Object.entries(columns).forEach(([columnKey, columnProperties]) => {
            let rowBackgroundColors;
            let rowColors;

            // If columns have `rowBackgroundColors` or `rowColors` arrays defined, sanitize them. If there are undefined values after sanitization, these
            //  elements are populated with the default values provided by the theme.
            if (columnProperties.rowBackgroundColors) {
                rowBackgroundColors = sanitizeArrayOfColors(columnProperties.rowBackgroundColors).map(
                    (color, i) =>
                        // using (i + 1) % 2 because 'odd' refers to row number, so index 0 -> row 1 -> odd colored
                        color ||
                        ((i + 1) % 2 === 0 ? themeRowBackgroundColorEven : themeRowBackgroundColorOdd)
                );
            }
            if (columnProperties.rowColors) {
                rowColors = sanitizeArrayOfColors(columnProperties.rowColors).map(
                    color => color || themeTextColor
                );
            }

            vizProps.columnFormat[columnKey] = {
                ...vizProps.columnFormat[columnKey],
                ...(columnProperties.rowBackgroundColors && { rowBackgroundColors }),
                ...(columnProperties.rowColors && { rowColors }),
            };
        });
    }

    if (vizProps.onOptionsChange) {
        const handleColumnFormatChange = updatedColumnFormat => {
            const cloneOfExistingColumnFormat = { ...vizProps?.options?.columnFormat } ?? {};
            const keys = Object.keys(updatedColumnFormat);
            const values = Object.values(updatedColumnFormat);
            keys.forEach((key: string, index: number) => {
                if (!cloneOfExistingColumnFormat[key]) {
                    cloneOfExistingColumnFormat[key] = {};
                }
                const newWidth = get(values[index], 'width');
                cloneOfExistingColumnFormat[key] = {
                    ...cloneOfExistingColumnFormat[key],
                    width: newWidth,
                };
            });
            vizProps.onOptionsChange({
                columnFormat: cloneOfExistingColumnFormat,
            });
        };
        vizProps.onColumnFormatChange = handleColumnFormatChange;
    }

    return vizProps;
};

const Table = withDashboardViz({
    ReactViz: PureTable,
    vizConfig: config,
    disableEditModeCover: true,
    computeVizProps,
} as DashboardVizOpts);
const { themes } = config;

export { themes, PureTable, config };
export default Table;
