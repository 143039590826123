/**
 * Helper to convert old label and value options into consolidated labelDisplay and valueDisplay
 * If the `showOption` is explicitly false, then we return 'off'
 * If the `showOption` is undefined (which corresponds to a default of `true`), we return nothing to avoid conflating the viz definition (since this corresponds to `number` in the new display)
 * @param {Boolean} showOption - either one of `showLabel` or `showValue` in the old gauges
 * @param {Boolean} percentageOption - either one of `usePercentageRange` or `usePercentageValue` in the old gauges
 * @returns {String}
 */
export const consolidateDisplayOption = (showOption: boolean, percentageOption: boolean): string | void => {
    if (showOption === false) {
        return 'off';
    }
    if (percentageOption === true) {
        return 'percentage';
    }
    if (showOption === true) {
        return 'number';
    }
    // if showOption is undefined and percentageOption is false, we don't need to return an explicit value (since 'number' is passed in implicitly)
    return null;
};
