import { _ } from '@splunk/ui-utils/i18n';
import DefaultValueEditor from './DefaultSelectedValueEditor';
import { BaseEditor } from './DropdownEditorConfig';

export default [
    ...BaseEditor,
    {
        label: _('Default selected values'),
        enableSeparator: false,
        layout: [
            [
                {
                    label: _('Default value'),
                    option: 'defaultValue',
                    context: 'statics',
                    editor: DefaultValueEditor,
                    editorProps: { isMultiselect: true },
                },
            ],
        ],
    },
];
