import { _ } from '@splunk/ui-utils/i18n';
import pick from '@splunk/themes/pick';
import variables from '@splunk/themes/variables';
import NumberFormattingConfig from '../../common/editorConfig/NumberFormatting';
import ThresholdConfig from '../../common/editorConfig/Threshold';

const editorConfig = [
    {
        label: _('Visualization Settings'),
        layout: [
            [
                {
                    label: _('Show Trending Value'),
                    option: 'showTrendIndicator',
                    editor: 'editor.checkbox',
                },
            ],
            [
                {
                    label: _('Trend Display as'),
                    option: 'trendDisplayMode',
                    editor: 'editor.select',
                    editorProps: {
                        values: [
                            { label: _('Percent'), value: 'percent' },
                            { label: _('Absolute'), value: 'absolute' },
                        ],
                    },
                    // If property matches the default value specified in schema, its not set in options. Hence we check if its undefined.
                    showEditor: ({ options }) =>
                        options.showTrendIndicator || options.showTrendIndicator === undefined,
                },
            ],
            [
                {
                    label: _('Show Sparkline'),
                    option: 'showSparkline',
                    editor: 'editor.checkbox',
                },
            ],
            [
                {
                    label: '',
                    option: 'sparklinePosition',
                    editor: 'editor.radioBar',
                    editorProps: {
                        values: [
                            { label: _('Before'), value: 'before' },
                            { label: _('After'), value: 'after' },
                            { label: _('Below'), value: 'below' },
                        ],
                    },
                    // If property matches the default value specified in schema, its not set in options. Hence we check if its undefined.
                    showEditor: ({ options }) => options.showSparkline || options.showSparkline === undefined,
                },
            ],
        ],
    },
    {
        label: _('Font Settings'),
        open: true,
        layout: [
            [
                {
                    label: _('Major Font Size (px)'),
                    option: 'majorFontSize',
                    editor: 'editor.number',
                    editorProps: {
                        min: 1,
                    },
                    placeholder: _('Auto'),
                },
                {
                    label: _('Delta Font Size (px)'),
                    option: 'deltaFontSize',
                    editor: 'editor.number',
                    editorProps: {
                        min: 1,
                    },
                    placeholder: _('Auto'),
                },
            ],
        ],
    },
    ThresholdConfig,
    {
        label: _('Background Color'),
        layout: [
            [
                {
                    label: '',
                    option: 'backgroundColor',
                    editor: 'editor.color',
                    editorProps: {
                        pickFromTheme: theme =>
                            pick({
                                enterprise: {
                                    dark: variables.black,
                                    light: variables.backgroundColor,
                                },
                                prisma: variables.backgroundColorSidebar,
                            })({ theme }),
                    },
                },
            ],
        ],
    },
    NumberFormattingConfig,
];

export default editorConfig;
