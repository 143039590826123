import { once } from 'lodash';
import type { SearchMetric } from './filters/filterSearchMetrics';

export interface MetricsCallbackData {
    searchMetrics: SearchMetric[];
    loadTime: number;
    isUnmounting?: boolean;
}
type AllMetricsCallback = (data: MetricsCallbackData) => void;

interface SearchMetricsCollectorProps {
    dataSourceCount: number;
    timeout?: number;
    onAllMetricsCollected?: AllMetricsCallback;
}

export class SearchMetricsCollector {
    startTime: number;

    count: number;

    searchMetrics: SearchMetric[];

    inFlightDataSources: Set<string>;

    onAllMetricsCollected: AllMetricsCallback;

    callbacks: AllMetricsCallback[] = [];

    metricsCompleteData: MetricsCallbackData | null = null;

    constructor({
        dataSourceCount,
        timeout,
        onAllMetricsCollected,
    }: SearchMetricsCollectorProps) {
        this.startTime = performance.now();
        this.count = dataSourceCount;
        this.searchMetrics = [];
        this.inFlightDataSources = new Set();
        if (onAllMetricsCollected) {
            this.addMetricsCollectedCallback(onAllMetricsCollected);
        }
        this.onAllMetricsCollected = once(this.handleAllMetricsCollected);
        // We timeout after specified timeout or 60s and force log metrics.
        // Caveat: If a dashboard has unused datasources, we may not log until the timeout.
        setTimeout(() => this.forceComplete(), timeout || 60 * 1000);
    }

    // Handler to call when all metrics are collected: internal handler so changes to the external callback won't cause it to be invoked again
    handleAllMetricsCollected = (data: MetricsCallbackData) => {
        this.metricsCompleteData = data;
        this.callbacks.forEach((fn) => fn(data));
    };

    addMetricsCollectedCallback(callback: AllMetricsCallback) {
        // If a callback is added after all searches are complete, call it immediately
        // NOTE: this will not prevent the callback from being called multiple times when added
        if (this.metricsCompleteData) {
            callback(this.metricsCompleteData);
        } else {
            // Save the callback for later when search completes
            // It doesn't make sense to save the callbacks after searches complete, as they can never be called,
            // unless we want to check the callback list for the fn reference to see if we called it already (e.g implement once)
            this.callbacks.push(callback);
        }
    }

    addInFlightDataSource = (id: string) => {
        this.inFlightDataSources.add(id);
    };

    collect = (metric: SearchMetric) => {
        if (
            !metric.dataSourceId ||
            !this.inFlightDataSources.has(metric.dataSourceId)
        ) {
            return;
        }

        this.searchMetrics.push(metric);
        this.inFlightDataSources.delete(metric.dataSourceId);

        if (this.inFlightDataSources.size === 0) {
            // we've recorded metrics for all datasources, now log to server.
            this.onAllMetricsCollected({
                searchMetrics: this.searchMetrics,
                loadTime: performance.now() - this.startTime,
            });
        }
    };

    forceComplete = ({ isUnmounting = false } = {}) => {
        this.onAllMetricsCollected({
            searchMetrics: this.searchMetrics,
            loadTime: performance.now() - this.startTime,
            isUnmounting,
        });
    };
}
