import React from 'react';
import { _ } from '@splunk/ui-utils/i18n';
import SVG, {
    type SVGPropsOptionalViewBox,
} from '@splunk/react-icons/SVGEnterprise';

/* eslint-disable max-len */
export default function AlignBottom(
    props: SVGPropsOptionalViewBox
): ReturnType<typeof SVG> {
    return (
        <SVG
            viewBox="0 0 24 24"
            screenReaderText={_('Align Bottom')}
            {...props}
        >
            <path
                fill="currentColor"
                d="M23.5 23L0.5 23C0.223858 23 9.2554e-09 23.2239 5.96244e-09 23.5C2.66948e-09 23.7761 0.223857 24 0.5 24L23.5 24C23.7761 24 24 23.7761 24 23.5C24 23.2239 23.7761 23 23.5 23Z"
            />
            <path
                fill="currentColor"
                d="M13 8.5L13 20.5C13 20.7761 13.2239 21 13.5 21L19.5 21C19.7761 21 20 20.7761 20 20.5L20 8.5C20 8.22386 19.7761 8 19.5 8L13.5 8C13.2239 8 13 8.22386 13 8.5Z"
            />
            <path
                fill="currentColor"
                d="M4 0.5L4 20.5C4 20.7761 4.22386 21 4.5 21L10.5 21C10.7761 21 11 20.7761 11 20.5L11 0.5C11 0.223858 10.7761 8.08047e-08 10.5 7.75117e-08L4.5 5.96244e-09C4.22386 2.66948e-09 4 0.223858 4 0.5Z"
            />
        </SVG>
    );
}
