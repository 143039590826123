import React from 'react';
import styled from 'styled-components';
import { pick, variables } from '@splunk/themes';

type StyledInlineCodeProps = {
    wordBreak?: NonNullable<React.CSSProperties['wordBreak']>;
};

const StyledInlineCode = styled.span<StyledInlineCodeProps>`
    word-break: ${(props) => props.wordBreak};
    color: ${pick({
        enterprise: {
            light: variables.white,
            dark: variables.gray30,
        },
        prisma: variables.contentColorDefault,
    })};
    background-color: ${pick({
        enterprise: {
            dark: variables.gray92,
            light: variables.gray22,
        },
        prisma: variables.neutral200,
    })};
    font-family: ${variables.monoFontFamily};
`;

export type InlineCodeProps = StyledInlineCodeProps & {
    text: string;
};

export const InlineCode = ({
    text,
    wordBreak = 'break-all',
}: InlineCodeProps): JSX.Element => (
    <StyledInlineCode wordBreak={wordBreak}>{text}</StyledInlineCode>
);
