import React, { createContext, useContext, useMemo } from 'react';
import { DashboardProfilerContextProvider } from './DashboardProfilerContext';
import type { MetricsCollector } from './MetricsCollector';
import TelemetryAPI from './TelemetryAPI';

export type TelemetryContextProviderProps = {
    metricsCollectors?: MetricsCollector | MetricsCollector[];
    children: React.ReactNode;
};

const defaultTelemetryAPI = new TelemetryAPI();
const TelemetryContext = createContext(defaultTelemetryAPI);

const noCollectors: MetricsCollector[] = [];

export const TelemetryContextProvider = ({
    metricsCollectors = noCollectors,
    children,
}: TelemetryContextProviderProps): React.ReactElement => {
    const value = useMemo(() => {
        let combinedMetricsCollectors: MetricsCollector[] = [];

        combinedMetricsCollectors =
            combinedMetricsCollectors.concat(metricsCollectors);

        return new TelemetryAPI({
            metricsCollectors: combinedMetricsCollectors,
        });
    }, [metricsCollectors]);

    return (
        <TelemetryContext.Provider value={value}>
            <DashboardProfilerContextProvider telemetryAPI={value}>
                {children}
            </DashboardProfilerContextProvider>
        </TelemetryContext.Provider>
    );
};

export const TelemetryContextConsumer = TelemetryContext.Consumer;

export const useTelemetryApi = () => {
    return useContext(TelemetryContext);
};

export default TelemetryContext;
