import type { VisualizationDefinition } from '@splunk/dashboard-types';
import config from '@splunk/visualizations/SingleValueRadial.config';
import type { EncodingConfig } from '../encoding';
import { encodingToDynamicOptionsDSL } from '../encoding';
import {
    removeInvalidOptions,
    renameVizOptions,
    preserveDataSelectionForValueOption,
} from '../utils/migrationUtils';
import { consolidateTrendDisplay } from '../utils/singlevalue';

const validOptions = {
    ...config.optionsSchema,
};

// old default configurations should be retained in migrated configuration
const defaultsToRetain = {
    majorValue: '> primary | seriesByIndex(0) | lastPoint()',
    trendValue: '> primary | seriesByIndex(0) | delta(-2)',
};

const optionRenames = {
    useTrendUnits: 'shouldAbbreviateTrendValue',
    useThousandSeparators: 'shouldUseThousandSeparators',
};

export const migrateVizToSplunkSingleValueRadial = (
    vizDefinition: VisualizationDefinition
): VisualizationDefinition => {
    const { options = {}, encoding = {}, ...otherDefinitionParts } = vizDefinition;
    const migratedVisualization = {
        ...otherDefinitionParts,
        type: 'splunk.singlevalueradial',
        context: {},
        options: {},
    };

    const { options: dataOptions, context: dataContext } = encodingToDynamicOptionsDSL(
        encoding as Record<string, EncodingConfig>
    );
    const { trend: originalTrend, fill: originalFill } = dataOptions;

    const migratedDataOptions = {
        ...(originalTrend && {
            majorValue: preserveDataSelectionForValueOption(originalTrend, 'lastPoint()') ?? originalTrend,
            trendValue: preserveDataSelectionForValueOption(originalTrend, 'delta(-2)') ?? originalTrend,
        }),
        ...(originalFill && {
            backgroundColor: preserveDataSelectionForValueOption(originalFill, 'lastPoint()') ?? originalFill,
        }),
    };

    const renamedOptions = renameVizOptions(options, optionRenames);

    const { trendDisplayMode, showTrendIndicator } = renamedOptions;

    const trendDisplay = consolidateTrendDisplay(trendDisplayMode as string, showTrendIndicator as boolean);

    migratedVisualization.options = {
        ...defaultsToRetain,
        ...renamedOptions,
        ...migratedDataOptions, // fill encoding takes precedence over explicit static backgroundColor
        ...(typeof trendDisplay === 'string' && { trendDisplay }),
    };

    migratedVisualization.context = dataContext;

    // remove invalid (and deprecated) options using the optionsSchema for splunk.singlevalueradial as reference
    removeInvalidOptions(migratedVisualization.options, validOptions);

    return migratedVisualization;
};
