import React from 'react';
import styled from 'styled-components';

const Group = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`;
const OptionGroup = ({ children }: { children: React.ReactNode }) => (
    <Group data-test="option-group">{children}</Group>
);

export default OptionGroup;
