import type {
    EHRequired,
    EventCanHandle,
    EventHandle,
    LinkToEvent,
} from '@splunk/dashboard-types';

import { isEventAllowed } from './utils/events';

interface CustomUrlDrilldownOptions {
    url?: string;
    newTab?: boolean;
    events?: string[];
}

class CustomUrlDrilldown
    implements EHRequired<Record<string, never>, LinkToEvent[]>
{
    private options: CustomUrlDrilldownOptions;

    private events: string[];

    /**
     * A configuration object is passed to the handler
     * @constructor
     * @param {Object} options
     */
    constructor(options: CustomUrlDrilldownOptions = {}) {
        this.options = options;
        this.events = Array.isArray(options.events) ? options.events : ['any'];
    }

    /**
     * Determines if the custom event handler is designed to convert the event
     * @method canHandle
     * @param {VisualizationEvent} event
     * @returns {Boolean} true if the event handler will convert the event
     */
    canHandle: EventCanHandle = (event) => {
        return (
            event &&
            isEventAllowed(event) &&
            this.options &&
            !!this.options.url &&
            (this.events.includes('any') || this.events.includes(event.type))
        );
    };

    /**
     * Convert the event payload into a list of actionable tasks
     * @method handle
     * @param {VisualizationEvent} event
     * @returns {Array} List of action(s)
     */
    handle: EventHandle<Record<string, never>, LinkToEvent[]> = () => {
        // TODO: verify url token updates happen external to the handler eventSaga:findEventHandler
        return Promise.resolve([
            {
                type: 'linkTo',
                payload: {
                    url: this.options.url,
                    newTab: this.options.newTab,
                },
            },
        ]);
    };
}

export default CustomUrlDrilldown;
