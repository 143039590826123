import React, { useCallback } from 'react';
import { noop } from '@splunk/dashboard-utils';
import styled from 'styled-components';
import Remove from '@splunk/react-icons/enterprise/Remove';
import RemoteIcon from '@splunk/visualizations-shared/RemoteIcon';
import { variables, pick } from '@splunk/themes';
import type { ItemProps } from './Gallery';

const customVariables = {
    transparentPatternEvenColor: pick({
        enterprise: variables.gray80,
        prisma: variables.neutral400,
    }),

    transparentPatternOddColor: pick({
        enterprise: variables.gray92,
        prisma: variables.neutral500,
    }),
};

const IconContainer = styled.div`
    width: 60px;
    height: 65px;
    margin: 8px 5px 0 5px;
    border-radius: 3px;
    &:hover {
        cursor: pointer;
        background: ${variables.backgroundColorHover};
    }
`;
const IconClose = styled.div<Pick<ItemProps, 'selected' | 'onClick'>>`
    display: flex;
    visibility: ${(props) => (props.selected ? 'visible' : 'hidden')};
    justify-content: flex-end;
`;

const IconText = styled.div`
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    text-align: center;
    color: ${variables.contentColorDefault};
`;

const Icon = styled.div`
    width: 40px;
    height: 40px;
    margin: 0px 10px 0px 10px;
    background-color: transparent;
    background-image: linear-gradient(
            45deg,
            ${customVariables.transparentPatternOddColor} 25%,
            transparent 25%,
            transparent 75%,
            ${customVariables.transparentPatternOddColor} 75%,
            ${customVariables.transparentPatternOddColor} 100%
        ),
        linear-gradient(
            45deg,
            ${customVariables.transparentPatternOddColor} 25%,
            ${customVariables.transparentPatternEvenColor} 25%,
            ${customVariables.transparentPatternEvenColor} 75%,
            ${customVariables.transparentPatternOddColor} 75%,
            ${customVariables.transparentPatternOddColor} 100%
        );
    background-size: 5px 5px;
    background-position: 0 0, 2.5px 2.5px;
    border-radius: 3px;
    display: flex;
    align-items: center;
`;

export type IconGalleryItemProps = ItemProps;

const IconGalleryItem = ({
    item,
    selected = false,
    onClick = noop,
    onMouseEnter = noop,
    onMouseLeave = noop,
    onRemove = noop,
}: IconGalleryItemProps) => {
    const { name, url } = item;
    const handleClick = useCallback(() => onClick(item.url), [onClick, item]);
    const handleMouseEnter = useCallback(
        () => onMouseEnter(item),
        [onMouseEnter, item]
    );
    const handleMouseLeave = useCallback(
        () => onMouseLeave(item),
        [onMouseLeave, item]
    );
    const handleDelete = useCallback(
        (evt) => {
            evt.stopPropagation();
            onRemove?.(item);
        },
        [onRemove, item]
    );

    return (
        <IconContainer
            data-test="icon-container"
            data-url={url}
            key={url}
            title={name}
            onClick={handleClick}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
        >
            {onRemove && (
                <IconClose
                    selected={selected}
                    data-test="icon-close"
                    onClick={handleDelete}
                >
                    <Remove size={0.5} />
                </IconClose>
            )}
            <Icon data-test="icon">
                <RemoteIcon iconURL={url} width={30} height={30} />
            </Icon>
            <IconText data-test="icon-name">{name}</IconText>
        </IconContainer>
    );
};

export default IconGalleryItem;
