import React from 'react';
import Table from '@splunk/react-ui/Table';
import { formatTime } from '@splunk/dashboard-visualizations/utils/timeUtils';
import { CellPropTypes, CellDefaultProps, CellDefaultStyle } from './CellPropShape';

const TimeCell = ({
    value,
    onCellClick,
    cellOptions: { textColor },
    fieldOptions: { timeGranularity, align },
}) => {
    const time = formatTime(value, timeGranularity);
    const style = { ...CellDefaultStyle, color: textColor };
    return (
        <Table.Cell align={align} style={style} title={time} onClick={onCellClick} test-cell-type="time">
            {time}
        </Table.Cell>
    );
};

TimeCell.propTypes = CellPropTypes;
TimeCell.defaultProps = CellDefaultProps;
TimeCell.canRender = (field, fieldOptions) => fieldOptions.type === 'time';

export default TimeCell;
