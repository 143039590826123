import { useRef, useMemo } from 'react';
import { isEqual } from 'lodash';
import type { DashboardJSON, BindingType } from '@splunk/dashboard-types';
import { computeStableDSDefinition } from '@splunk/dashboard-search';
import type { SearchesToExecute } from '../utils/getSearchesToExecute';

interface UseManageSearchesType {
    searchesToExecute: SearchesToExecute;
    dataSourceDefinitions: DashboardJSON['dataSources'];
}

type ForEachSearchBindingCallback = ({
    dsId,
    consumerId,
    bindingTypes,
}: {
    dsId: string;
    consumerId: string;
    bindingTypes: BindingType[];
}) => void;

export const forEachSearchBinding = (
    searches: SearchesToExecute,
    callback: ForEachSearchBindingCallback
) => {
    Object.entries(searches).forEach(([dsId, bindings]) => {
        Object.entries(bindings).forEach(([consumerId, bindingTypes]) =>
            callback({
                dsId,
                consumerId,
                bindingTypes,
            })
        );
    });
};

export const getBindingDifferences = ({
    bindingTypes,
    previousBindingTypes,
}: {
    bindingTypes: string[];
    previousBindingTypes: string[];
}) => {
    const added: string[] = [];
    const existing: string[] = [];
    const removed: string[] = [];

    bindingTypes.forEach((bindingType) => {
        if (previousBindingTypes.includes(bindingType)) {
            existing.push(bindingType);
        } else {
            added.push(bindingType);
        }
    });

    // if previous binding was removed
    previousBindingTypes.forEach((previousBindingType) => {
        if (!bindingTypes.includes(previousBindingType)) {
            removed.push(previousBindingType);
        }
    });

    return { added, existing, removed };
};

/**
 * useManageSearches - custom hook for determining whether to create, remove, or update a search
 * @param searchesToExecute a list of searches that are marked for execution
 */
export const useManageSearches = ({
    searchesToExecute,
    dataSourceDefinitions,
}: UseManageSearchesType) => {
    const prevPropsRef = useRef<UseManageSearchesType>({
        searchesToExecute: {},
        dataSourceDefinitions: {},
    });
    return useMemo(() => {
        const create: SearchesToExecute = {};
        const remove: SearchesToExecute = {};
        const update: SearchesToExecute = {};
        const {
            searchesToExecute: previousSearches,
            dataSourceDefinitions: previousDataSources,
        } = prevPropsRef.current;

        forEachSearchBinding(
            searchesToExecute,
            ({ dsId, consumerId, bindingTypes }) => {
                const previousBindingTypes =
                    previousSearches[dsId]?.[consumerId] ?? [];

                const { added, existing, removed } = getBindingDifferences({
                    bindingTypes,
                    previousBindingTypes,
                });

                if (added.length) {
                    create[dsId] ??= {};
                    create[dsId][consumerId] = added;
                }

                if (removed.length) {
                    remove[dsId] ??= {};
                    remove[dsId][consumerId] = removed;
                }

                // if the search existed previously, check if the datasource definition changed
                //  with the exception of `name`.
                const prevDS = computeStableDSDefinition(
                    previousDataSources?.[dsId]
                );
                const currentDS = computeStableDSDefinition(
                    dataSourceDefinitions?.[dsId]
                );
                if (existing.length && !isEqual(prevDS, currentDS)) {
                    // update only existing searches (there's no point in updating searches that were just added to `create`)
                    update[dsId] ??= {};
                    update[dsId][consumerId] = existing;
                }
            }
        );

        // The logic before used `difference` to determine any missing bindingTypes. However, we now need to see whether
        //   a consumerId or a dsId was removed entirely
        forEachSearchBinding(
            previousSearches,
            ({ dsId, consumerId, bindingTypes }) => {
                // If for a certain dsId, a consumerId was removed, add it to the searches to be removed
                // OR if a dsId was removed entirely, then add its previous searches to be removed
                if (
                    typeof searchesToExecute[dsId]?.[consumerId] === 'undefined'
                ) {
                    remove[dsId] ??= {};
                    remove[dsId][consumerId] = bindingTypes;
                }
            }
        );

        prevPropsRef.current = { searchesToExecute, dataSourceDefinitions };

        return {
            searchesToCreate: create,
            searchesToRemove: remove,
            searchesToUpdate: update,
        };
    }, [dataSourceDefinitions, searchesToExecute]);
};
