import * as React from 'react';
import styled from 'styled-components';
import { select as d3select, selectAll as d3selectAll } from 'd3-selection';
import { brushY as d3brushY } from 'd3-brush';
import { axisLeft as d3axisLeft, axisTop as d3axisTop } from 'd3-axis';
import { drag as d3drag } from 'd3-drag';
import type { ScalePoint } from 'd3-scale';
import { convertHexToRgb } from '@mdhnpm/rgb-hex-converter';
import ParallelCoordinatesCanvas from './ParallelCoordinatesCanvas';

type marginObj = { top: number; right: number; bottom: number; left: number };
type yPositionScaleType = { [key: string]: any };

const ChartDiv = styled.div<{
    width: number;
    height: number;
    backgroundColor: string;
    margin: marginObj;
}>`
    background-color: ${props => props.backgroundColor};
    width: ${props => props.width + props.margin.left + props.margin.right}px;
    height: ${props => props.height + props.margin.top + props.margin.bottom}px;
    position: relative;
`;

const PromptDiv = styled.div<{
    width: number;
    height: number;
    margin: marginObj;
}>`
    position: absolute;
    top: ${props => props.height + props.margin.top + props.margin.bottom - 27}px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-size: 12px;
    vertical-align: middle;
    margin-left: 0;
    width: ${props => props.margin.left + props.width + props.margin.right}px;
`;

const FilterButton = styled.button.attrs({ 'data-testid': 'clear-filter-button' })<{
    filterButtonColor: string;
}>`
    margin-left: 16px;
    display: flex;
    border: none;
    background: none;
    color: ${props => props.filterButtonColor};
    cursor: pointer;
    font-size: 12px;
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 0;
`;

interface ParallelCoordinatesChartProps {
    width: number;
    height: number;
    margin: marginObj;
    axisPadding: number;
    data: any[];
    dimensions: string[];
    xPositionScale: ScalePoint<any>;
    yPositionScale: yPositionScaleType;
    showNullAxis: boolean;
    nullOffsetHeight: number;
    nullAxisScale: ScalePoint<any>;
    lineColor: string;
    lineOpacity: number;
    backgroundColor: string;
    axisLineHighlightColor: string;
    axisLineColor: string;
    axisTitleColor: string;
    axisLabelColor: string;
    filterButtonDisabledColor: string;
    filterButtonActiveColor: string;
    filterTextColor: string;
    filterSelectionColor: string;
    truncatedCategoriesFlag: boolean;
    truncatedTextColor: string;
    textShadowColor: string;
    mode: string;
}

const ParallelCoordinatesChart = ({ ...props }: ParallelCoordinatesChartProps): React.ReactElement => {
    const {
        width,
        height,
        margin,
        axisPadding,
        data,
        dimensions,
        xPositionScale,
        yPositionScale,
        showNullAxis,
        nullOffsetHeight,
        nullAxisScale,
        lineColor,
        lineOpacity,
        backgroundColor,
        axisLineHighlightColor,
        axisLineColor,
        axisTitleColor,
        axisLabelColor,
        filterButtonDisabledColor,
        filterButtonActiveColor,
        filterTextColor,
        truncatedCategoriesFlag,
        truncatedTextColor,
        filterSelectionColor,
        textShadowColor,
    } = props;

    const brushWidth = 16;
    const maxStrLength = 7;

    // axis dragging storage variable
    const dragging = {};

    // brushing event handling variables
    const brush = d3brushY().extent([
        [-brushWidth / 2, 0],
        [brushWidth / 2, height],
    ]);

    const updatedTextShadowColor = backgroundColor === 'transparent' ? textShadowColor : backgroundColor;

    // axis dragging feature state declaration
    const [currentDimensions, setCurrentDimensions] = React.useState(dimensions);
    const [dragStatus, setDragStatus] = React.useState(false);

    // brushing feature state declaration
    const [selectedData, setSelectedData] = React.useState(data);
    const [selections, setSelections] = React.useState(new Map());
    const [filterPromptState, setFilterPromptState] = React.useState(false);
    const [filterButtonColor, setFilterButtonColor] = React.useState(filterButtonDisabledColor);

    const svgRef = React.useRef(null);

    // update xPositionScale
    xPositionScale.domain(currentDimensions);
    nullAxisScale.domain(currentDimensions).range([0, width - 2 * axisPadding * xPositionScale.step()]);

    // axis dragging helper functions
    const position = d => {
        const v = dragging[d];
        return v == null ? xPositionScale(d) : v;
    };

    const transition = g => g.transition().duration(500);

    // clear filter button
    const clearAllFilters = () => {
        d3select(svgRef.current).selectAll('.brush').call(brush.move, null);
        setSelections(new Map());
        setSelectedData(data);
        setFilterPromptState(false);
        setFilterButtonColor(filterButtonDisabledColor);
    };

    // truncated string to a length for categorical axis label
    const truncate = (str, maxLength) => {
        const updatedSuffix = '...';

        let updateStr = str || 'null';

        if (Array.isArray(updateStr)) {
            updateStr = updateStr.join(', ');
        }

        if (typeof updateStr === 'string' && updateStr.length > maxLength) {
            updateStr = updateStr.substring(0, maxLength - 1);
            updateStr += updatedSuffix;
        }

        return updateStr;
    };

    React.useEffect(() => {
        // clean svg before drawing
        const svg = d3select(svgRef.current)
            .html('')
            .attr('height', height + margin.top + margin.bottom)
            .attr('width', width + margin.left + margin.right)
            .append('g')
            .attr('transform', `translate(${margin.left},${margin.top})`);

        // Add a group element for each dimension
        const g = svg
            .selectAll('.dimension')
            .data(currentDimensions)
            .join('g')
            .attr('class', 'dimension')
            .attr('data-test', 'dimension')
            .attr('transform', d => `translate(${xPositionScale(d)})`)
            .style('cursor', 'ew-resize');

        // axis dragging event handling
        const dragstarted = (event, d) => {
            d3select(event.sourceEvent.currentTarget)
                .selectAll('.axis path')
                .style('stroke', axisLineHighlightColor);
            d3select(event.sourceEvent.currentTarget)
                .selectAll('.axis line')
                .style('stroke', axisLineHighlightColor);
            dragging[d] = xPositionScale(d);
            setDragStatus(true);
        };

        const dragged = (event, d) => {
            dragging[d] = Math.min(width, Math.max(0, event.x));
            currentDimensions.sort((a, b) => position(a) - position(b));
            xPositionScale.domain(currentDimensions);
            setCurrentDimensions([...currentDimensions]);
            g.attr('transform', p => `translate(${position(p)})`);
        };

        function dragended(_event, d) {
            delete dragging[d];
            d3select(this).select('.axis path').style('stroke', axisLineColor);
            d3select(this).selectAll('.axis line').style('stroke', axisLineColor);

            transition(d3select(this).attr('transform', `translate(${xPositionScale(d)})`));
            setDragStatus(false);
        }

        // Add vertical axis and title.
        g.append('g')
            .attr('class', 'axis vertical-axis')
            .each((d, i, nodes) => {
                if (yPositionScale[d].ticks) {
                    // numerical axis
                    d3select(nodes[i])
                        .call(d3axisLeft(yPositionScale[d]))
                        .selectAll('text')
                        .append('title')
                        .text(datum => datum && datum.toString());
                } else {
                    // categorical axis and truncated long labels
                    d3select(nodes[i])
                        .call(d3axisLeft(yPositionScale[d]).tickFormat(p => truncate(p, maxStrLength)))
                        // add title to show full value upon hover
                        .selectAll('text')
                        .append('title')
                        .text(datum => datum && datum.toString());
                }
            })
            .selectAll('text')
            .attr('fill', axisLabelColor)
            .clone(true)
            .lower()
            .attr('fill', 'none')
            .attr('stroke-width', 2)
            .attr('stroke-linejoin', 'round')
            .attr(
                'stroke',
                `rgba(${convertHexToRgb(updatedTextShadowColor)[0]},${
                    convertHexToRgb(updatedTextShadowColor)[1]
                },${convertHexToRgb(updatedTextShadowColor)[2]}, ${0.8})`
            );

        g.append('text')
            .attr('class', 'axisTitle')
            .attr('fill', axisTitleColor)
            .attr('font-size', '12px')
            .attr('text-anchor', 'middle')
            .attr('y', -9)
            .text(d => d);

        // drawing the null axis
        if (showNullAxis) {
            const nullAxisOffsetHeight = yPositionScale[currentDimensions[0]].range()[0] + nullOffsetHeight;
            // append null axis and title
            svg.append('g')
                .attr('class', 'axis null-axis')
                .attr(
                    'transform',
                    `translate(${xPositionScale.step() * axisPadding}, ${nullAxisOffsetHeight})`
                )
                .attr('fill', 'none')
                .call(d3axisTop(nullAxisScale).tickFormat(() => ''));

            svg.append('text')
                .attr('text-anchor', 'end')
                .attr('font-size', '11px')
                .attr('x', axisPadding * xPositionScale.step() - 9)
                .attr('y', height + nullOffsetHeight)
                .text('null')
                .attr('fill', axisLabelColor);
        }

        // customize axis line and ticks based on themes
        d3selectAll('.axis .domain').style('stroke', axisLineColor);
        d3selectAll('.axis line').style('stroke', axisLineColor);

        // added dragging event functions to each dimension
        svg.selectAll<SVGGElement, any>('.dimension').call(
            d3drag<SVGGElement, any>().on('start', dragstarted).on('drag', dragged).on('end', dragended)
        );

        // brushing event handling
        const brushStart = event => {
            // prevent brushing event trigger clicking event
            if (event.sourceEvent) {
                event.sourceEvent.stopPropagation();
            }
            setFilterPromptState(true);
            setFilterButtonColor(filterButtonActiveColor);

            // style filter selection box rect
            d3selectAll('rect.selection')
                .attr('fill', filterSelectionColor)
                .attr('fill-opacity', '0.3')
                .attr('stroke', 'none')
                .attr('rx', '4px');
        };

        // updated selectedData after brushing
        const brushed = ({ selection }, key) => {
            if (selection === null) {
                selections.delete(key);
                if (selections.size === 0) {
                    setFilterButtonColor(filterButtonDisabledColor);
                    setFilterPromptState(false);
                }
            } else if (yPositionScale[key].ticks) {
                // numeric scale (aka scaleLinear)
                // use .ticks to differentiate categorical vs numeric axis
                // d3.scaleLinear().ticks = true
                // d3.scalePoint().ticks = undefined
                selections.set(key, selection.map(yPositionScale[key].invert));
            } else {
                // categorical scale (aka scalePoint)
                selections.set(key, [selection[1], selection[0]]);
            }

            const selected = [];

            data.forEach(d => {
                // Categorical Axis: convert ordinal dimension's values to pixels
                // Numerical Axis: compare numerical values with dataSources
                const active = Array.from(selections).every(([activeKey, [max, min]]) => {
                    const newP = yPositionScale[activeKey].ticks
                        ? d[activeKey]
                        : yPositionScale[activeKey](d[activeKey]);
                    return newP >= min && newP <= max;
                });
                if (active) {
                    selected.push(d);
                }
            });
            setSelectedData(selected);
        };

        brush.on('start', brushStart).on('brush end', brushed);

        // add and store a brush for each axis.
        g.append('g')
            .attr('class', 'brush')
            .each((_d, i, nodes) => {
                d3select(nodes[i]).call(brush);
            });
    }, [
        data,
        width,
        height,
        margin,
        dimensions,
        xPositionScale,
        yPositionScale,
        selections,
        axisLabelColor,
        updatedTextShadowColor,
        axisTitleColor,
        showNullAxis,
        axisLineColor,
        axisLineHighlightColor,
        nullOffsetHeight,
        filterButtonActiveColor,
        filterButtonDisabledColor,
        truncatedCategoriesFlag,
        truncatedTextColor,
    ]);

    return (
        <div>
            <ChartDiv width={width} height={height} margin={margin} backgroundColor={backgroundColor}>
                <ParallelCoordinatesCanvas
                    width={width}
                    height={height}
                    margin={margin}
                    xPositionScale={xPositionScale}
                    yPositionScale={yPositionScale}
                    data={data}
                    currentDimensions={currentDimensions}
                    selectedData={selectedData}
                    dragStatus={dragStatus}
                    showNullAxis={showNullAxis}
                    nullOffsetHeight={nullOffsetHeight}
                    lineColor={lineColor}
                    lineOpacity={lineOpacity}
                    backgroundColor={backgroundColor}
                />
                <svg
                    ref={svgRef}
                    style={{ position: 'relative' }}
                    data-testid="svg-layer"
                    data-test-dimensions={currentDimensions.length}
                />
                <PromptDiv width={width} height={height} margin={margin}>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <FilterButton filterButtonColor={filterButtonColor} onClick={clearAllFilters}>
                            Clear filters
                        </FilterButton>

                        <span
                            style={{ display: filterPromptState ? 'flex' : 'none', color: filterTextColor }}
                            data-testid="line-selection-prompt"
                        >
                            {selectedData.length}/{data.length} lines selected
                        </span>
                    </div>

                    <span
                        style={{
                            display: truncatedCategoriesFlag ? 'flex' : 'none',
                            color: truncatedTextColor,
                            marginRight: 16,
                        }}
                        data-testid="truncated-data-warning"
                    >
                        Note: Your data is currently truncated due to a high amount of categorical values
                    </span>
                </PromptDiv>
            </ChartDiv>
        </div>
    );
};

export default ParallelCoordinatesChart;
