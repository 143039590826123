import { isEmpty, memoize } from 'lodash';
import { console } from '@splunk/dashboard-utils';
import type { BackgroundImageOptions } from '@splunk/dashboard-types';

// /**
//  * Check whether image src url is valid or not
//  * @method validImageSrc
//  * @param {String} src
//  */
// export const validImageSrc = src => {
//     const img = new Image();
//     img.onerror = () => {
//         console.error(`Background image url ${src} is invalid`);
//     };
//     img.src = src
// };

/**
 * check if an image is from Image Registry
 * @param {*} src
 */
export const isFromImageRegistry = (src = ''): boolean => {
    const [type] = src.split('://');
    return (
        src.indexOf('://') > -1 &&
        type !== 'http' &&
        type !== 'https' &&
        type !== 'ftp' &&
        type !== 'file'
    );
};

interface ValidImagePosArgs {
    x?: number;
    y?: number;
    canvasWidth: number;
    canvasHeight: number;
}

/**
 * Check whether image position is valid or not
 * @method validImagePos
 * @param {Number} x
 * @param {Number} y
 * @param {Number} canvasWidth
 * @param {Number} canvasHeight
 * @returns {Boolean}
 */
export const validImagePos = ({
    x,
    y,
    canvasWidth,
    canvasHeight,
}: ValidImagePosArgs): boolean =>
    !!(
        x != null &&
        Number.isInteger(x) &&
        y != null &&
        Number.isInteger(y) &&
        x <= canvasWidth &&
        y <= canvasHeight &&
        x >= 0 &&
        y >= 0
    );

/**
 * Check whether image width and height is valid or not
 * @method validImageWidthHeight
 * @param {Number} imageWidth //Defaulted to 1 because w can't be undefined
 * @param {Number} imageHeight //Defaulted to 1 because h can't be undefined
 * @param {Number} canvasWidth
 * @param {Number} canvasHeight
 * @returns {Boolean}
 */
export const validImageWidthHeight = (
    imageWidth = 1,
    imageHeight = 1
): boolean =>
    !!(
        Number.isInteger(imageWidth) &&
        Number.isInteger(imageHeight) &&
        imageWidth > 0 &&
        imageHeight > 0
    );

/**
 * Check whether image size type is valid or not (cover/auto/contain/undefined)
 * @method invalidImageSizeType
 * @param {String} sizeType
 * @returns {Boolean}
 */
export const invalidImageSizeType = (sizeType: string): boolean =>
    !!(
        sizeType !== 'cover' &&
        sizeType !== 'contain' &&
        sizeType !== 'auto' &&
        sizeType !== undefined
    );

/**
 * Return the original width and height of a valid image
 * @method getImageWidthHeight
 * @param {String} imgSrc
 * @returns {Number. Number} Image width and height
 */
export const getImageDimension = memoize(
    (imgSrc: string): { width: number; height: number } => {
        const img = new Image();
        try {
            img.src = imgSrc;
            img.style.position = 'absolute';
            img.style.left = '-9999'; // Image width must not exceed 9999 pixels
            img.style.visibility = 'hidden';
            document.body.appendChild(img);
            const height = img.naturalHeight;
            const width = img.naturalWidth;
            return { width, height };
        } catch (ex) {
            return { width: 0, height: 0 };
        } finally {
            if (document.body.contains(img)) {
                document.body.removeChild(img); // Removes the image from the DOM
            }
        }
    }
);

interface ValidateBackgroundImageArgs {
    backgroundImage: BackgroundImageOptions;
    canvasWidth: number;
    canvasHeight: number;
}

/**
 * Check whether background image options are valid or not. Default to x:0, y:0, w:0, h:0 and sizeType:'auto'
 * @method validateBackgroundImage
 * @param {Object} backgroundImage
 * @param {String} backgroundImage.src
 * @param {Number} backgroundImage.x
 * @param {Number} backgroundImage.y
 * @param {Number} backgroundImage.w
 * @param {Number} backgroundImage.h
 * @param {String} backgroundImage.sizeType
 * @param {Number} width
 * @param {Number} height
 * @returns {Object}
 */
export const validateBackgroundImage = ({
    backgroundImage = {},
    canvasWidth,
    canvasHeight,
}: ValidateBackgroundImageArgs): BackgroundImageOptions => {
    const { src } = backgroundImage;
    let { x, y, w, h, sizeType } = backgroundImage;
    if (isEmpty(backgroundImage)) {
        return { src, x, y, w, h, sizeType };
    }
    if (src == null) {
        return { src, x, y, w, h, sizeType };
    }
    // validImageSrc(src); make no sense to validate but not display errors.
    if (!validImagePos({ x, y, canvasWidth, canvasHeight })) {
        console.warn(
            `Invalid background image position, x: ${x} y: ${y}. Defaulting to x: 0, y: 0`
        );
        x = 0;
        y = 0;
    }

    if (
        w == null &&
        h == null &&
        (sizeType == null || invalidImageSizeType(sizeType))
    ) {
        console.warn(
            `Invalid background image sizeType, ${sizeType}. Defaulting to contain`
        );
        sizeType = 'contain';
    } else if (
        (sizeType == null || invalidImageSizeType(sizeType)) &&
        ((w != null && h == null) ||
            (w == null && h != null) ||
            !validImageWidthHeight(w, h))
    ) {
        console.warn(
            `Invalid background image width and height, w: ${w} h: ${h}. Defaulting to image original size`
        );
        w = w || 0;
        h = h || 0;
    }
    return { src, x, y, w, h, sizeType };
};
