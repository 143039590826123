import { _ } from '@splunk/ui-utils/i18n';
import { EditorConfig } from '../interfaces/Editor';

const AxesChartYGridAndLabels: EditorConfig = {
    label: _('Y-axis grid and labels'),
    layout: [
        [
            {
                label: _('Axis title'),
                option: 'yAxisTitleVisibility',
                editor: 'editor.toggle',
            },
        ],
        [
            {
                label: _('Axis title text'),
                option: 'yAxisTitleText',
                editor: 'editor.text',
                showEditor: ({ options }) =>
                    options.yAxisTitleVisibility === 'show' || options.yAxisTitleVisibility === undefined,
            },
        ],
        [
            {
                label: _('Scale'),
                option: 'yAxisScale',
                editor: 'editor.radioBar',
                editorProps: {
                    values: [
                        { label: _('Linear'), value: 'linear' },
                        { label: _('Log'), value: 'log' },
                    ],
                },
            },
        ],
        [
            {
                label: _('Label visibility'),
                option: 'yAxisLabelVisibility',
                editor: 'editor.radioBar',
                editorProps: {
                    values: [
                        { label: _('Auto'), value: 'auto' },
                        { label: _('Show'), value: 'show' },
                        { label: _('Hide'), value: 'hide' },
                    ],
                },
            },
        ],
        [
            {
                label: _('SI prefix abbreviation for labels'),
                option: 'yAxisAbbreviation',
                editor: 'editor.checkbox',
                showEditor: ({ options }) =>
                    options.yAxisLabelVisibility === 'show' ||
                    options.yAxisLabelVisibility === 'auto' ||
                    options.yAxisLabelVisibility === undefined,
            },
        ],
        [
            {
                label: _('Include zero'),
                option: 'showYAxisWithZero',
                editor: 'editor.checkbox',
            },
        ],
        [
            {
                label: _('Extend to whole grid'),
                option: 'showYAxisExtendedRange',
                editor: 'editor.checkbox',
            },
        ],
        [
            {
                label: _('Minimum axis value'),
                option: 'yAxisMin',
                editor: 'editor.number',
            },
            {
                label: _('Maximum axis value'),
                option: 'yAxisMax',
                editor: 'editor.number',
            },
        ],
        [
            {
                label: _('Axis line visibility'),
                option: 'yAxisLineVisibility',
                editor: 'editor.checkbox',
            },
        ],
        [
            {
                label: _('Major gridlines'),
                option: 'showYMajorGridLines',
                editor: 'editor.checkbox',
            },
        ],
        [
            {
                label: _('Major tick visibility'),
                option: 'yAxisMajorTickVisibility',
                editor: 'editor.radioBar',
                editorProps: {
                    values: [
                        { label: _('Auto'), value: 'auto' },
                        { label: _('Show'), value: 'show' },
                        { label: _('Hide'), value: 'hide' },
                    ],
                },
            },
        ],
        [
            {
                label: _('Major tick size (px)'),
                option: 'yAxisMajorTickSize',
                editor: 'editor.slider',
                editorProps: {
                    min: 0,
                    max: 10,
                    step: 1,
                },
                showEditor: ({ options }) =>
                    options.yAxisMajorTickVisibility === 'show' ||
                    options.yAxisMajorTickVisibility === 'auto' ||
                    options.yAxisMajorTickVisibility === undefined,
            },
        ],
        [
            {
                label: _('Major tick interval spacing'),
                option: 'yAxisMajorTickInterval',
                editor: 'editor.number',
                showEditor: ({ options }) =>
                    options.yAxisMajorTickVisibility === 'show' ||
                    options.yAxisMajorTickVisibility === 'auto' ||
                    options.yAxisMajorTickVisibility === undefined,
            },
        ],
        [
            {
                label: _('Minor gridlines'),
                option: 'showYMinorGridLines',
                editor: 'editor.checkbox',
            },
        ],
        [
            {
                label: _('Minor tick visibility'),
                option: 'yAxisMinorTickVisibility',
                editor: 'editor.radioBar',
                editorProps: {
                    values: [
                        { label: _('Auto'), value: 'auto' },
                        { label: _('Show'), value: 'show' },
                        { label: _('Hide'), value: 'hide' },
                    ],
                },
            },
        ],
        [
            {
                label: _('Minor tick size (px)'),
                option: 'yAxisMinorTickSize',
                editor: 'editor.slider',
                editorProps: {
                    min: 0,
                    max: 10,
                    step: 1,
                },
                showEditor: ({ options }) =>
                    options.yAxisMinorTickVisibility === 'show' ||
                    options.yAxisMinorTickVisibility === 'auto' ||
                    options.yAxisMinorTickVisibility === undefined,
            },
        ],
    ],
};

export default AxesChartYGridAndLabels;
