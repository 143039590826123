import * as React from 'react';
import { _ } from '@splunk/ui-utils/i18n';
import SVG from '@splunk/react-icons/SVGEnterprise';

const Bar = (props: React.ComponentProps<typeof SVG>): React.ReactElement<SVGElement> => (
    <SVG screenReaderText={_('Bar')} viewBox="0 0 16 18" {...props}>
        <path
            d="M0.5 16.5C0.5 16.9602 0.873096 17.3333 1.33333 17.3333C1.79357 17.3333 2.16667 16.9602 2.16667 16.5V1.49996C2.16667 1.03972 1.79357 0.666626 1.33333 0.666626C0.873096 0.666626 0.5 1.03972 0.5 1.49996V16.5Z"
            fill="currentColor"
        />
        <path
            d="M10.0833 3.16663C10.7737 3.16663 11.3333 3.72627 11.3333 4.41663V6.91663C11.3333 7.60698 10.7737 8.16663 10.0833 8.16663H3.83333V3.16663H10.0833Z"
            fill="currentColor"
        />
        <path
            d="M14.25 9.83329C14.9404 9.83329 15.5 10.3929 15.5 11.0833V13.5833C15.5 14.2736 14.9404 14.8333 14.25 14.8333H3.83333V9.83329H14.25Z"
            fill="currentColor"
        />
    </SVG>
);

export default Bar;
