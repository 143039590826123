import * as React from 'react';
import { _ } from '@splunk/ui-utils/i18n';
import SVG from '@splunk/react-icons/SVGEnterprise';

const ParallelCoordinates = (props: React.ComponentProps<typeof SVG>): React.ReactElement<SVGElement> => (
    <SVG
        screenReaderText={_('Parallel Coordinates')}
        viewBox="0 0 24 24"
        {...props}
        style={{ ...props.style, overflow: 'hidden' }}
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M4.00012 5C4.00012 4.44772 3.5524 4 3.00012 4C2.44784 4 2.00012 4.44772 2.00012 5V10.4837C1.99996 10.494 1.99996 10.5043 2.00012 10.5147V19C2.00012 19.5523 2.44784 20 3.00012 20C3.5524 20 4.00012 19.5523 4.00012 19V18.5369L7.39046 14.9044L11.0001 17.5113V19C11.0001 19.5523 11.4478 20 12.0001 20C12.5524 20 13.0001 19.5523 13.0001 19V17.489L20.0001 12.0446V19C20.0001 19.5523 20.4478 20 21.0001 20C21.5524 20 22.0001 19.5523 22.0001 19V6.02293C22.0005 6.0079 22.0005 5.99282 22.0001 5.97771V5C22.0001 4.44772 21.5524 4 21.0001 4C20.4478 4 20.0001 4.44772 20.0001 5V5.23968L13.0001 7.18413V5C13.0001 4.44772 12.5524 4 12.0001 4C11.4478 4 11.0001 4.44772 11.0001 5V8.10561L7.13169 12.2504L4.00012 9.98872V5ZM4.00012 12.4558V15.6057L5.75626 13.7241L4.00012 12.4558ZM11.0001 11.0368L8.76589 13.4307L11.0001 15.0443V11.0368ZM13.0001 14.9553L20.0001 9.51088V7.31541L13.0001 9.25985V14.9553Z"
            fill="currentColor"
        />
    </SVG>
);

export default ParallelCoordinates;
