import React, { useMemo } from 'react';
import styled from 'styled-components';
import { pick, variables } from '@splunk/themes';
import { customThemeVariables } from '@splunk/dashboard-ui';

export const TITLE_CONTAINER_PADDING = 12;
export const TITLE_HEIGHT = 20;
export const DESCRIPTION_HEIGHT = 16;

const Title = styled.div.attrs(() => ({
    'data-test': 'viz-title',
}))`
    flex-basis: auto;
    font-size: 14px;
    font-weight: 600;
    line-height: ${TITLE_HEIGHT}px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    color: ${variables.contentColorActive};
`;

const Description = styled.div.attrs(() => ({
    'data-test': 'viz-description',
}))<{ noTitle?: boolean }>`
    flex-basis: auto;
    font-size: 12px;
    line-height: ${DESCRIPTION_HEIGHT}px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    color: ${variables.contentColorActive};
    opacity: ${pick({
        enterprise: {
            light: 0.6,
            dark: 0.7,
        },
        prisma: 0.7,
    })};
    /* offset top margin when there is no title so description lines up with other titles */
    margin-top: ${({ noTitle }) => (noTitle ? '3px' : 'unset')};
`;

const Container = styled.div.attrs(() => ({
    'data-test': 'viz-title-description-container',
}))<{ backgroundColor?: string | null }>`
    padding: ${TITLE_CONTAINER_PADDING / 2}px 10px;
    background-color: ${(props) =>
        props.backgroundColor || customThemeVariables.vizPanelBackgroundColor};
    /* Fix sub-pixel gaps between elements when scaled */
    box-shadow: 0 1px 1px 0
        ${(props) =>
            props.backgroundColor ||
            customThemeVariables.vizPanelBackgroundColor};
    overflow: hidden;
`;

const TextWithStatusAllotment = styled.div.attrs(() => ({
    'data-test': 'viz-title-description-status-padding',
}))`
    width: calc(100% - 12px);
`;

interface TitleAndDescriptionProps {
    description?: string;
    title?: string;
    backgroundColor?: string | null;
    truncateFirstLine?: boolean;
}

/**
 * Renders a container for a visualization title and description
 * @param {Object} param0 VizTitleAndDescription parameters
 * @param {string | undefined} [description] Visualization description to be rendered
 * @param {string | undefined} [title] Visualization title to be rendered
 * @param {string | null | undefined} [backgroundColor] Background color to fill the container and for box shadows. Will default to `customThemeVariables.vizPanelBackgroundColor`
 * @param {boolean | undefined} [truncateFirstLine] Indicate that the first rendered line - either title or description - should contain padding to prevent rendering in same space as a viz status icon
 * @returns A rendered container with the title and/or description, else null
 */
const VizTitleAndDescription = ({
    description,
    title,
    backgroundColor,
    truncateFirstLine,
}: TitleAndDescriptionProps): JSX.Element | null => {
    const TitleMemo = useMemo(() => {
        if (!title) {
            return null;
        }

        if (truncateFirstLine) {
            return (
                <TextWithStatusAllotment>
                    <Title>{title}</Title>
                </TextWithStatusAllotment>
            );
        }

        return <Title>{title}</Title>;
    }, [title, truncateFirstLine]);

    // If there's no title then the description may need to be truncated to
    // prevent rendering text on top of the status icon (when it's present)
    const DescriptionMemo = useMemo(() => {
        if (!description) {
            return null;
        }

        // If there's a title or truncateFirstLine is false then there's no
        // need to render the text in a TextWithStatusAllotment container
        if (title || !truncateFirstLine) {
            return <Description>{description}</Description>;
        }

        return (
            <TextWithStatusAllotment>
                <Description noTitle>{description}</Description>
            </TextWithStatusAllotment>
        );
    }, [description, title, truncateFirstLine]);

    // Cannot have conditional hook execution. This check needs to come after useMemo
    if (!title && !description) {
        return null;
    }

    return (
        <Container backgroundColor={backgroundColor}>
            {TitleMemo}
            {DescriptionMemo}
        </Container>
    );
};

export default VizTitleAndDescription;
