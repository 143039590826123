import { _ } from '@splunk/ui-utils/i18n';
import type { DashboardJSON, StructureItem } from '@splunk/dashboard-types';
import type { ValidationErrors } from '../types';
import {
    DUPLICATE_ITEM,
    VISUALIZATION_NONEXISTENT,
    VISUALIZATION_NOT_PRESENT,
    CANVAS_INPUT_NONEXISTENT,
} from './messages';
import { isPlainObject } from './typeGuards';

export const checkVisualizationsInStructure = (
    definition: DashboardJSON
): ValidationErrors => {
    const seen = new Set<StructureItem['item']>();
    const {
        visualizations = {},
        inputs = {},
        layout: { structure = [] } = {},
    } = definition;

    const errors: ValidationErrors = [];

    (structure as StructureItem[])?.forEach((item, idx) => {
        if (seen.has(item.item)) {
            errors.push({
                instancePath: DUPLICATE_ITEM,
                message: _(
                    `Structure item ${idx} has the same key "${item.item}" as another structure item`
                ),
            });
        }

        if (
            (item.type === 'block' || typeof item.type === 'undefined') &&
            !isPlainObject(visualizations?.[item.item])
        ) {
            errors.push({
                instancePath: VISUALIZATION_NONEXISTENT,
                message: _(
                    `Structure item ${idx} references a visualization "${item.item}" that does not exist`
                ),
            });
        }

        if (item.type === 'input' && !isPlainObject(inputs?.[item.item])) {
            errors.push({
                instancePath: CANVAS_INPUT_NONEXISTENT,
                message: _(
                    `Structure item ${idx} references an input "${item.item}" that does not exist`
                ),
            });
        }

        seen.add(item.item);
    });

    Object.keys(visualizations).forEach((vizId) => {
        if (!seen.has(vizId)) {
            errors.push({
                instancePath: VISUALIZATION_NOT_PRESENT,
                message: _(
                    `Visualization "${vizId}" is not present in Layout Structure`
                ),
            });
        }
    });
    return errors;
};
