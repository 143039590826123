import React from 'react';
import { _ } from '@splunk/ui-utils/i18n';
import SVG, {
    type SVGPropsOptionalViewBox,
} from '@splunk/react-icons/SVGEnterprise';

/* eslint-disable max-len */
export default function Remove(
    props: SVGPropsOptionalViewBox
): ReturnType<typeof SVG> {
    return (
        <SVG screenReaderText={_('Remove')} viewBox="0 0 24 24" {...props}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10 6V4H14V6H10ZM17 6H16V3.5C16 2.67157 15.3284 2 14.5 2H9.5C8.67157 2 8 2.67157 8 3.5V6H7H5H4C3.44772 6 3 6.44772 3 7C3 7.55228 3.44772 8 4 8H5V20.5C5 21.3284 5.67157 22 6.5 22H17.5C18.3284 22 19 21.3284 19 20.5V8H20C20.5523 8 21 7.55228 21 7C21 6.44772 20.5523 6 20 6H19H17ZM12 12.5858L10.7071 11.293C10.3166 10.9024 9.68342 10.9024 9.29289 11.293C8.90237 11.6835 8.90237 12.3166 9.29289 12.7072L10.5858 14.0001L9.2929 15.293C8.90237 15.6835 8.90237 16.3166 9.2929 16.7072C9.68342 17.0977 10.3166 17.0977 10.7071 16.7072L12 15.4143L13.2929 16.7072C13.6834 17.0977 14.3166 17.0977 14.7071 16.7072C15.0976 16.3166 15.0976 15.6835 14.7071 15.293L13.4142 14.0001L14.7071 12.7072C15.0976 12.3166 15.0976 11.6835 14.7071 11.293C14.3166 10.9024 13.6834 10.9024 13.2929 11.293L12 12.5858Z"
                fill="currentColor"
            />
        </SVG>
    );
}
