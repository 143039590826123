import React from 'react';
import T from 'prop-types';
import styled from 'styled-components';
import SingleValueBasic from '@splunk/visualizations/SingleValueBasic';
import IconContainer from './IconContainer';
import { isHorizontal } from './utils/iconUtils';

const Column = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    width: 100%;
`;

const IconRow = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    height: 100%;
`;

const IconColumn = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
`;

const SingleValueIconLayout = ({ ...props }) => {
    const {
        majorColor,
        majorText,
        majorTextFontSize,
        majorValueTitle,
        trendColor,
        trendText,
        trendTextFontSize,
        showTrendIndicator,
        unit,
        unitPosition,
        underLabel,
        underLabelFontSize,
        underLabelColor,
        onValueClick,
        iconWidth,
        iconHeight,
        icon,
        iconColor,
        iconOpacity,
        iconPosition,
        iconFontSize,
        showValue,
        svgIconComponent,
    } = props;

    const iconContainer = (
        <IconContainer
            width={iconWidth}
            height={iconHeight}
            fontSize={iconFontSize}
            color={iconColor}
            opacity={iconOpacity}
            icon={icon}
            IconComponent={svgIconComponent}
            onIconClick={onValueClick}
        />
    );

    const singleValueBasic = showValue ? (
        <SingleValueBasic
            majorColor={majorColor}
            majorText={majorText}
            majorTextFontSize={majorTextFontSize}
            majorValueTitle={majorValueTitle}
            trendColor={trendColor}
            trendText={trendText}
            trendTextFontSize={trendTextFontSize}
            showTrendIndicator={showTrendIndicator}
            unit={unit}
            unitPosition={unitPosition}
            underLabel={underLabel}
            underLabelFontSize={underLabelFontSize}
            underLabelColor={underLabelColor}
            onValueClick={onValueClick}
        />
    ) : null;

    let iconLayout = null;
    const showHorizontal = isHorizontal(iconPosition);
    if (showHorizontal) {
        iconLayout = (
            <IconRow>
                {iconPosition === 'before' && iconContainer}
                {singleValueBasic}
                {iconPosition === 'after' && iconContainer}
            </IconRow>
        );
    } else {
        iconLayout = (
            <IconColumn>
                {iconPosition === 'top' && iconContainer}
                {singleValueBasic}
                {iconPosition === 'below' && iconContainer}
            </IconColumn>
        );
    }

    return <Column>{iconLayout}</Column>;
};

SingleValueIconLayout.propTypes = {
    ...SingleValueBasic.propTypes,
    iconWidth: T.number,
    iconHeight: T.number,
    icon: T.string,
    iconPosition: T.oneOf(['before', 'after', 'top', 'below']),
    iconColor: T.string,
    iconOpacity: T.number,
    iconFontSize: T.number,
    showValue: T.bool,
    svgIconComponent: T.element,
};

export default SingleValueIconLayout;
