import * as React from 'react';
import * as T from 'prop-types';
import styled from 'styled-components';
import pick from '@splunk/themes/pick';
import variables from '@splunk/themes/variables';

const LEGEND_ITEM_VERTICAL_SPACER = 25;
const LEGEND_ITEM_HORIZONTAL_SPACER = 10;
const LEGEND_RECT_WIDTH = 5;
const LEGEND_RECT_HEIGHT = 10;
const LEGEND_OFFSET_TOP = 7;
const LEGEND_OFFSET_RIGHT = 16;
const LEGEND_OFFSET_BOTTOM = 16;

const LegendGroup = styled.g<{
    muted: boolean;
    onClick: () => void;
}>`
    cursor: pointer;
    opacity: ${props => (props.muted ? 0.15 : 1)};
    transition: opacity 0.15s;
`;

const LegendIcon = styled.rect`
    width: ${LEGEND_RECT_WIDTH}px;
    height: ${LEGEND_RECT_HEIGHT}px;
    rx: 1px;
    ry: 1px;
`;

const LegendText = styled.text`
    dominant-baseline: middle;
    fill: ${pick({
        /* was previously named textColor in themeReg */
        enterprise: variables.textColor,
        prisma: variables.contentColorDefault,
    })};
    font-size: 12px;
`;

interface PunchcardLegendProps {
    category: string[];
    colorCategoryMapping: (...args: any) => string;
    handleMouseEnter: (...args: any) => void;
    handleMouseLeave: (...args: any) => void;
    selected: string;
    startPositionY: number;
    startPositionX: number;
    containerWidth: number;
    containerHeight: number;
    handleLegendClick: () => void;
}

const PunchcardLegend = (props: PunchcardLegendProps): React.ReactElement => {
    const {
        category,
        colorCategoryMapping,
        handleMouseEnter,
        handleMouseLeave,
        selected,
        startPositionX,
        startPositionY,
        containerWidth,
        containerHeight,
        handleLegendClick,
    } = props;

    const legendWidth = containerWidth - LEGEND_OFFSET_RIGHT - startPositionX;
    const legendHeight = containerHeight - LEGEND_OFFSET_BOTTOM;

    return (
        <svg
            x={startPositionX}
            y={startPositionY - LEGEND_OFFSET_TOP} // Align legend with topmost grid line
            width={legendWidth}
            height={legendHeight}
        >
            <g>
                {category.map((c, index) => {
                    const yValue = index * LEGEND_ITEM_VERTICAL_SPACER;

                    return (
                        <LegendGroup
                            key={c}
                            muted={selected && selected !== c}
                            onMouseEnter={() => handleMouseEnter(c)}
                            onMouseLeave={handleMouseLeave}
                            onClick={() => handleLegendClick()}
                            data-test="legend-group"
                        >
                            <LegendIcon x={0} y={yValue} fill={colorCategoryMapping(c)} />
                            <LegendText
                                x={LEGEND_ITEM_HORIZONTAL_SPACER}
                                y={yValue + LEGEND_RECT_HEIGHT / 2 + 2}
                            >
                                <title>{c}</title>
                                {c}
                            </LegendText>
                        </LegendGroup>
                    );
                })}
            </g>
        </svg>
    );
};

const propTypes: Record<keyof PunchcardLegendProps, T.Validator<any>> = {
    category: T.arrayOf(T.string),
    colorCategoryMapping: T.func.isRequired,
    handleMouseEnter: T.func,
    handleMouseLeave: T.func,
    selected: T.string,
    startPositionX: T.number,
    startPositionY: T.number,
    containerWidth: T.number,
    containerHeight: T.number,
    handleLegendClick: T.func,
};

const defaultProps: Record<keyof PunchcardLegendProps, any> = {
    category: null,
    // eslint-disable-next-line react/default-props-match-prop-types
    colorCategoryMapping: null,
    handleMouseEnter: () => {},
    handleMouseLeave: () => {},
    selected: null,
    startPositionX: 0,
    startPositionY: 0,
    containerWidth: 100,
    containerHeight: 100,
    handleLegendClick: () => {},
};

PunchcardLegend.propTypes = propTypes;
PunchcardLegend.defaultProps = defaultProps;

export default PunchcardLegend;
