import React from 'react';
import { _ } from '@splunk/ui-utils/i18n';
import SVG, {
    type SVGPropsOptionalViewBox,
} from '@splunk/react-icons/SVGEnterprise';

/* eslint-disable max-len */
export default function AlignTop(
    props: SVGPropsOptionalViewBox
): ReturnType<typeof SVG> {
    return (
        <SVG viewBox="0 0 24 24" screenReaderText={_('Align Top')} {...props}>
            <path
                fill="currentColor"
                d="M0.499998 1L23.5 1C23.7761 1 24 0.776142 24 0.5C24 0.223858 23.7761 2.66947e-09 23.5 5.96243e-09L0.5 2.80235e-07C0.223858 2.83528e-07 2.66948e-09 0.223858 5.96244e-09 0.5C9.25541e-09 0.776143 0.223856 1 0.499998 1Z"
            />
            <path
                fill="currentColor"
                d="M11 15.5L11 3.5C11 3.22386 10.7761 3 10.5 3L4.5 3C4.22386 3 4 3.22386 4 3.5L4 15.5C4 15.7761 4.22386 16 4.5 16L10.5 16C10.7761 16 11 15.7761 11 15.5Z"
            />
            <path
                fill="currentColor"
                d="M20 23.5L20 3.5C20 3.22386 19.7761 3 19.5 3L13.5 3C13.2239 3 13 3.22386 13 3.5L13 23.5C13 23.7761 13.2239 24 13.5 24L19.5 24C19.7761 24 20 23.7761 20 23.5Z"
            />
        </SVG>
    );
}
