import * as chroma from 'chroma-js';
import { isColor } from '@splunk/visualizations-shared/colorUtils';
import { AbstractFormatter } from '../Formatter';
import { TypedValue } from '../DataPrimitive';
import { DataSeries } from '../DataSeries';
import { DataPoint } from '../DataPoint';
import EncodingExecutor from '../EncodingExecutor';

export const DEFAULT_MIN_COLOR = 'rgba(123,86,219,0.4)';
export const DEFAULT_MAX_COLOR = 'rgba(123,86,219,1)';

export interface GradientConfig {
    stops?: number[];
    colors: string[];
}

/**
 * Based on stops and colors, this formatter maps the value to an interpolated color.
 *
 * The `config` follows this criteria: `{ stops: [0, 100, 230], colors: ['red', 'green', 'blue'] }`
 *
 * If no config is specified, the default `colors` are `['rgba(123,86,219,0.4)', 'rgba(123,86,219,1)']`. The default `stops` are dependent on the DataSeries provided.
 *
 * Given a DataSeries with n values, the stops would consist of [min, ...(configured stops),  max] if stops values are contained in [min, max] - recommended.
 * And min/max map to 'rgba(123,86,219,0.4)'/'rgba(123,86,219,1)', respectively.
 *
 * For example, if 0 < value < 100, the corresponding interpolated color will be between red and green.
 *
 * ```js
 * <SampleViz
 *     context={{
 *         gradientConfig: { stops: [50, 100, 150], colors: ['red', 'green', 'blue'] }
 *     }}
 *     options={{
 *         colorOption: '> primary | seriesByIndex(0) | gradient(gradientConfig)' // it maps [0, 50, 230] to ['#7B56DB66', '#FF0000', '#7B56DB']
 *         colorOption1: '> primary | seriesByIndex(1) | gradient(gradientConfig)' // it maps [50, 100, 150] to ['red', 'green', 'blue']
 *     }}
 *     dataSources={{
 *         data: {
 *             primary: {
 *                 columns: [[0, 50, 230], [50, 100, 150]],
 *                 fields: [{ name: 'foo' }, { name: 'bar' }]
 *             }
 *         }
 *     }}
 * />
 * ```
 *
 * @extends AbstractFormatter<DataType, DataType>
 */
export class Gradient extends AbstractFormatter<'number', 'color'> {
    private config: GradientConfig;

    private colors: string[];

    private stops: number[];

    constructor(config?: GradientConfig) {
        super();
        this.config = EncodingExecutor.rawTree(config) || {};

        this.colors = this.config.colors || [];
        this.stops = this.config.stops || [];
        if (!Array.isArray(this.colors) || !Array.isArray(this.stops)) {
            throw new Error("Gradient config: both 'colors' and 'stops' in should be 1D array.");
        }
        this.colors.forEach(c => {
            if (!isColor(c)) {
                throw new Error("Gradient config: every element in 'colors' should be a color.");
            }
        });
        this.sortArray(this.stops);
    }

    sortArray = (array: number[]): void => {
        array.sort((a, b) => a - b);
    };

    addDefaultEndingColors = (colors: string[], n: number): void => {
        // add n ending purples to colors
        for (let i = 0; i < n; i += 1) {
            colors.push(DEFAULT_MAX_COLOR);
        }
    };

    ignoreMinMaxWhenNeeded = (
        min: number,
        max: number,
        stops: number[],
        newStops: number[],
        newColors: string[]
    ): void => {
        if (min >= stops[0]) {
            // ignore "min" stop and color
            newStops.shift();
            newColors.shift();
        }
        if (max <= stops[stops.length - 1]) {
            // ignore "max" stop and color
            newStops.pop();
            newColors.pop();
        }
    };

    private prepareColorsAndStopsSeries(series: DataSeries) {
        const colorsLength = this.colors.length;
        const stopsLength = this.stops.length;

        const min = Number(series.min().getRawValue());
        const max = Number(series.max().getRawValue());

        let newColors = [DEFAULT_MIN_COLOR, DEFAULT_MAX_COLOR];
        // case-0: no stops configured
        // if colors configured -- use stops, otherwise use default purples
        if (stopsLength === 0) {
            if (colorsLength > 2) {
                // evenly distribute colors inbetween [min, max]
                const newStops = [];
                for (let i = 0; i < colorsLength; i += 1) {
                    newStops.push(min + ((max - min) * i) / (colorsLength - 1));
                }
                return { newStops, newColors: this.colors };
            }
            this.colors.forEach((c, i) => {
                newColors[i] = c;
            });
            return {
                newStops: [min, max],
                newColors,
            };
        }

        // cases-1: configured stops length > 0
        const newStops = [...this.stops];
        // always add min max stops first
        newStops.unshift(min);
        newStops.push(max);
        // case-1-0: no colors configured
        if (colorsLength === 0) {
            // cases-1-0-1: two special cases where colors not configured and stops length <= 2
            if (stopsLength === 1) {
                newColors = [DEFAULT_MIN_COLOR, DEFAULT_MAX_COLOR, DEFAULT_MAX_COLOR];
            } else if (stopsLength === 2) {
                newColors = [DEFAULT_MIN_COLOR, DEFAULT_MIN_COLOR, DEFAULT_MAX_COLOR, DEFAULT_MAX_COLOR];
            } else {
                throw new Error('Gradient config: provide at most two stops when colors is empty.');
            }
            this.ignoreMinMaxWhenNeeded(min, max, this.stops, newStops, newColors);
            return {
                newStops,
                newColors,
            };
        }
        // case-1-1: both colors and stops configured, and having proper lengths
        if (stopsLength + 2 >= colorsLength) {
            // prepend default color for "min" stop
            newColors = [...this.colors];
            if (stopsLength >= colorsLength) {
                newColors.unshift(DEFAULT_MIN_COLOR);
            }
            // append default color(s) for "max" stop
            this.addDefaultEndingColors(newColors, stopsLength + 2 - newColors.length);
            this.ignoreMinMaxWhenNeeded(min, max, this.stops, newStops, newColors);
            if (newStops.length !== newColors.length) {
                throw new Error('Gradient config: not equal length of stops and colors.');
            }
            return {
                newStops,
                newColors,
            };
        }

        throw new Error('Gradient config: too many colors set for the given stops.');
    }

    private prepareColorsAndStopsPoint(value: number) {
        let newStops = this.stops;
        let newColors = this.colors;
        if (this.stops.length === 0) {
            newStops = [0, 1];
        }
        if (this.colors.length === 0) {
            newColors = [DEFAULT_MIN_COLOR, DEFAULT_MAX_COLOR];
        }
        if (this.stops.length === this.colors.length && this.stops.length === 1 && value < this.stops[0]) {
            newColors = [DEFAULT_MIN_COLOR, ...this.colors];
        }
        return { newStops, newColors };
    }

    protected formatTypedValue(p: DataPoint<'number'>, series: DataSeries): TypedValue<'color'> {
        const input: TypedValue<'number'> = p.getValue();
        const { type, value } = input;
        if (type !== 'number') {
            throw new Error(`Type '${type}' with value '${value}' is not a valid input to gradient`);
        }

        const isDataSeries = series && series.points && series.points.length;
        const { newStops, newColors } = isDataSeries
            ? this.prepareColorsAndStopsSeries(series)
            : this.prepareColorsAndStopsPoint(value);

        let interpolateColor;
        const isBeyondUpperBound = value > newStops[newStops.length - 1];
        const isBeyondLowerBound = value < newStops[0];
        if (isBeyondUpperBound || isBeyondLowerBound) {
            const index = isBeyondUpperBound ? newColors.length - 1 : 0;
            interpolateColor = chroma(newColors[index]).hex().toUpperCase();
            return { type: 'color', value: interpolateColor };
        }

        for (let i = 1; i < newStops.length; i += 1) {
            if (Number.isNaN(Number(newStops[i]))) {
                // eslint-disable-next-line
                console.warn(
                    'Could not format gradient color: stops are required to be numbers. Falling back to first color.'
                );
                interpolateColor = chroma(newColors[0]).hex().toUpperCase() || '#000';
                return { type: 'color', value: interpolateColor };
            }

            const upperVal = newStops[i];
            if (value <= upperVal) {
                const lowerVal = newStops[i - 1];
                let tau = 0;
                // deal with special case when the upper and lower bounds of the colors are equal
                if (upperVal === lowerVal) {
                    // user prolly expects lower end of color stops if value is zero
                    // otherwise use the upper bound
                    tau = value === 0 ? 0 : 1;
                } else {
                    tau = (value - lowerVal) / (upperVal - lowerVal);
                }
                interpolateColor = chroma
                    .scale([newColors[i - 1], newColors[i]])(tau)
                    .hex()
                    .toUpperCase();
                return { type: 'color', value: interpolateColor };
            }
        }

        // eslint-disable-next-line
        console.warn(`Could not format value for gradient: ${value}`);
        interpolateColor = chroma(newColors[0]).hex().toUpperCase() || '#000';
        return { type: 'color', value: interpolateColor };
    }
}
