import * as React from 'react';
import * as T from 'prop-types';
import { ScaleOrdinal } from 'd3-scale';
import { DEFAULT_LINK_COLOR, INACTIVE_OPACITY } from './PureSankey';
import { COLOR_MODE_TYPES, SankeyDataLink } from './SankeyModels';
import { extractLinkMidPoint } from './SankeyUtils';

export interface SankeyLinkProps {
    link: SankeyDataLink;
    activeLinks: SankeyDataLink[];
    linkOpacity: number;
    colorMode: COLOR_MODE_TYPES;
    handleMouseOver: (...args: any[]) => void;
    handleMouseLeave: (...args: any[]) => void;
    colorCategoryMapping: ScaleOrdinal<string, unknown, string> | string[];
    path: (a: number) => (link: SankeyDataLink) => string;
}

const getLinkFill = (link, colorCategoryMapping, colorMode) => {
    if (colorMode === 'categorical') {
        return colorCategoryMapping ? colorCategoryMapping(link.source.name) : DEFAULT_LINK_COLOR;
    }
    return colorCategoryMapping && colorCategoryMapping[link.index]
        ? colorCategoryMapping[link.index]
        : DEFAULT_LINK_COLOR;
};

const getLinkOpacity = (link: SankeyDataLink, activeLinks: SankeyDataLink[], linkOpacity: number) => {
    if (!activeLinks || !activeLinks.length) {
        return linkOpacity;
    }
    const isActiveLink = activeLinks.find(l => l.id === link.id);
    return isActiveLink ? linkOpacity : INACTIVE_OPACITY;
};

const SankeyLink = (props: SankeyLinkProps): React.ReactElement => {
    const {
        link,
        colorCategoryMapping,
        activeLinks,
        linkOpacity,
        colorMode,
        path,
        handleMouseOver,
        handleMouseLeave,
    } = props;
    // This is to draw an invisible circle added to the link's path midpoint,
    // which acts as an anchor for the link tooltip
    const circlePoints = extractLinkMidPoint(path(1)(link));
    return (
        <g
            className="link"
            key={link.id}
            id={link.id}
            data-test={link.id}
            fill={getLinkFill(link, colorCategoryMapping, colorMode)}
            opacity={getLinkOpacity(link, activeLinks, linkOpacity)}
            onMouseOver={ev => {
                handleMouseOver(ev);
            }}
            onMouseLeave={ev => {
                const relatedTarget = ev.relatedTarget as HTMLElement;
                let isRelatedTargetPopover = false;
                // This is to verify if the mouseLeave is being triggered because of mouseover on a the popover itself.
                // In that case the relatedTarget will have the popover elements and this logic will check on the class name used.
                // If detects the relatedTarget is a popover, then mouseLeave event handler wont be triggered.
                if (relatedTarget && relatedTarget.classList) {
                    const { classList } = relatedTarget;
                    classList.forEach(name => {
                        if (
                            name.toLocaleLowerCase().indexOf('sankeytooltip') > -1 ||
                            name.toLocaleLowerCase().indexOf('popoverstyles') > -1
                        ) {
                            isRelatedTargetPopover = true;
                        }
                    });
                }
                if (!isRelatedTargetPopover) {
                    handleMouseLeave(ev);
                }
            }}
        >
            <path d={path(0)(link)} />
            <path d={path(1)(link)} />
            <path d={path(2)(link)} />
            {circlePoints && <circle cx={circlePoints.x} cy={circlePoints.y} r={5} fill="transparent" />}
        </g>
    );
};

SankeyLink.propTypes = {
    link: T.object,
    activeLinks: T.arrayOf(T.object),
    linkOpacity: T.number,
    path: T.func,
    handleMouseOver: T.func,
    handleMouseLeave: T.func,
    colorCategoryMapping: T.oneOfType([T.func, T.arrayOf(T.string)]),
};

SankeyLink.defaultProps = {
    handleMouseOver: () => {},
    handleMouseLeave: () => {},
};

export default SankeyLink;
