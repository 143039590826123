import styled from 'styled-components';

export interface ActionMenuPortalProps {
    x: number;
    y: number;
    w: number;
    h: number;
    zIndex?: number;
}

const ActionMenuPortal = styled.div.attrs<ActionMenuPortalProps>((props) => ({
    'data-test': 'action-menu-portal',
    style: {
        width: props.w,
        top: props.y + props.h,
        left: props.x,
    },
}))<ActionMenuPortalProps>`
    display: block;
    position: absolute;
    background: transparent;
    pointer-events: all;
    box-sizing: border-box;
    height: 0px;
    z-index: ${(props) => props.zIndex};
`;

export default ActionMenuPortal;
