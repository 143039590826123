/* eslint-disable class-methods-use-this,no-unused-vars, @typescript-eslint/no-unused-vars, @typescript-eslint/no-empty-function */
import { isEqual, isEmpty } from 'lodash';
import type {
    ExtendableDataSourceDefinition,
    RequestParams,
    DSTypes,
} from '@splunk/dashboard-types';
import type { Observable, Subscriber, Observer } from 'rxjs';
import { deprecated } from '@splunk/dashboard-utils';
import type { ObservableData } from './types';

type DefaultOptions = Record<string, unknown>;
type DefaultContext = Record<string, unknown>;

/**
 * Base class of a DataSource
 */
export default class DataSource<O = DefaultOptions, C = DefaultContext> {
    options: O;

    context: C;

    // deprecated property
    meta?: Record<string, unknown>;

    // deprecated property
    baseChainModel?: Record<string, ExtendableDataSourceDefinition>;

    /**
     * Create a new DataSource instance
     * @param {Object} options DataSource options such as search string, earliest/latest time
     * @param {Object} context contextual information such as api key or scope
     * @param {Object} meta metadata of this datasource
     * @param {Object} baseChainModel base chain definition
     */
    constructor(
        options: O = {} as O,
        context: C = {} as C,
        meta = {},
        baseChainModel = {}
    ) {
        this.options = options;
        this.context = context;
        this.meta = meta;
        this.baseChainModel = baseChainModel;
        if (!isEmpty(meta)) {
            deprecated('Property "meta" has been deprecated');
        }
        if (!isEmpty(baseChainModel)) {
            deprecated('Property "baseChainModel" has been deprecated');
        }
    }

    /**
     * Setup the DataSource, create a connection or search job.
     * setup() will be called only once per DataSource.
     * Returns null by default; override to implement custom setup logic.
     *
     * @return {Promise}
     * @public
     */
    setup(): DSTypes['setup'] {
        return Promise.resolve(null);
    }

    /**
     * Request a Data Stream represent by an Observable.
     * Once the Observable was created and returned,
     * it's the DataSource itself responsible for 'pushing' the data out via Observable.next() function.
     * Override this function to implement your own data fetching logic.
     *
     * @param {Object} [requestParams] RequestParams
     * @param {Number} [requestParams.offset] result offset
     * @param {Number} [requestParams.count] result count, use with offset to return data in pages
     * @param {Object} [requestParams.sort] result sorting, key indicate the sorting fields and value must be one of ['none', 'asc', 'desc']
     * @param {Boolean} [requestParams.requireTotalCount] true if TotalCount is required in meta
     * @param {Boolean} [requestParams.progress] true to emit progress data, false to only return data when search is finalized
     * @return {Observable}
     * @public
     */
    request(requestParams?: RequestParams): DSTypes['request'] {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        return (observer?: Subscriber<any>) => {
            if (observer) {
                observer.complete();
            }
            return () => {
                // cleanup
            };
        };
    }

    /**
     * Teardown the DataSource, clean up the connection or other resources.
     * Returns null by default; override to implement custom teardown logic.
     *
     * @return {Promise}
     * @public
     */
    teardown(): DSTypes['teardown'] {
        return Promise.resolve(null);
    }

    /**
     * @param {DataSource} another another DataSource to compare with
     * @return {Boolean} true if the these two DataSources are considered equal.
     * @public
     */
    equals(another: DataSource): DSTypes['equals'] {
        return (
            this.constructor === another.constructor &&
            isEqual(this.options, another.options)
        );
    }

    /**
     * Return an observer that can be used for chaining with other DataSources
     *
     * @return {Object}
     * @public
     */
    getObserver(): DSTypes['getObserver'] {
        return {
            next({ requestParams, data, meta }) {
                // next
            },
            error({ level, message }) {
                // error
            },
            complete() {
                // complete
            },
        };
    }

    /**
     * Flag used to indicate whether the subscription for this DataSource should be refreshed
     *
     * @return {Boolean}
     * @public
     */
    shouldRefreshSubscription(): DSTypes['shouldRefreshSubscription'] {
        return false;
    }
}
