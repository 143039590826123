import { _ } from '@splunk/ui-utils/i18n';
import type {
    AbsoluteLayoutStructure,
    SelectedItem,
    AbsoluteBlockItem,
    Port,
    Coordinate,
} from '@splunk/dashboard-types';
import { isBlockItem } from '@splunk/dashboard-utils';
import type { LayoutStructureState } from '../types';

interface FindSelectedBlockItemsArgs {
    layoutStructure: AbsoluteLayoutStructure;
    selectedItems: SelectedItem[];
}

export const findSelectedBlockItems = ({
    layoutStructure,
    selectedItems,
}: FindSelectedBlockItemsArgs): AbsoluteBlockItem[] =>
    layoutStructure.filter(
        (item) =>
            selectedItems.findIndex(
                ({ id }) => item.item === id && isBlockItem(item)
            ) >= 0
    ) as AbsoluteBlockItem[];

interface GetAllBlockItemsArgs {
    layoutStructure: AbsoluteLayoutStructure;
    layoutStructureState?: LayoutStructureState;
}

export const getAllBlockItems = ({
    layoutStructure,
    layoutStructureState = {},
}: GetAllBlockItemsArgs): AbsoluteBlockItem[] => {
    return layoutStructure
        .map((item) => layoutStructureState[item.item] ?? item)
        .filter((item) => isBlockItem(item)) as AbsoluteBlockItem[];
};

/**
 * compute port position for a given block item
 * @param {Object} blockItem
 * @param {String} port
 */
export const computePortPosition = (
    blockItem: AbsoluteBlockItem,
    port: Port
): Coordinate => {
    const blockPosition = blockItem.position;
    switch (port) {
        case 'n':
            return {
                x: Math.round(blockPosition.x + blockPosition.w / 2),
                y: blockPosition.y,
            };
        case 'w':
            return {
                x: blockPosition.x,
                y: Math.round(blockPosition.y + blockPosition.h / 2),
            };
        case 'e':
            return {
                x: blockPosition.x + blockPosition.w,
                y: Math.round(blockPosition.y + blockPosition.h / 2),
            };
        case 's':
        default:
            return {
                x: Math.round(blockPosition.x + blockPosition.w / 2),
                y: blockPosition.y + blockPosition.h,
            };
    }
};

interface GetBlockItemArgs {
    layoutStructure: AbsoluteLayoutStructure;
    id: string;
}

export const InvalidBlockItemError = 'refers to an invalid block item';
const InvalidBlockItemErrorMessage = _(`%s ${InvalidBlockItemError}`);

export const getBlockItem = ({
    layoutStructure,
    id,
}: GetBlockItemArgs): AbsoluteBlockItem => {
    const blockItem = layoutStructure.find(({ item }) => item === id);

    if (!blockItem || blockItem.type === 'line') {
        throw Error(InvalidBlockItemErrorMessage.replace('%s', id));
    }

    return blockItem;
};
