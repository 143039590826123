import React, { useCallback, useMemo } from 'react';
import styled from 'styled-components';
import { isEqual } from 'lodash';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import T from 'prop-types';
import {
    makeSelectIsSelected,
    removeInput,
    selectGlobalInputs,
    selectInputDefinitionFactory,
    selectMode,
    updateSelectedItems,
    useDispatch,
    useSelector,
} from '@splunk/dashboard-state';
import { useFeatureFlags } from '@splunk/dashboard-context';
import { useSubscribeToSearches } from '@splunk/dashboard-ui';
import InputContent from '../InputContent';
import { useCanItemBeHidden } from '../../hooks/useCanItemBeHidden';

const wrapperAttributes = ({ $transform, $transition, isDragging } = {}) => ({
    'data-test': 'input-item',
    style: {
        transform: CSS.Translate.toString($transform),
        transition: isDragging ? $transition : undefined,
    },
});

const Wrapper = styled.div.attrs(wrapperAttributes)`
    display: inline-block;
    z-index: ${({ isDragging }) => (isDragging ? 1 : undefined)};
    opacity: ${({ isDragging }) => (isDragging ? 0.4 : 1)};
`;

const ConnectedInputContainer = ({
    setNodeRef,
    transform,
    transition,
    isDragging,
    attributes,
    listeners,
    mode,
    isSelected,
    ...props
}) => {
    const selectInputDef = useMemo(selectInputDefinitionFactory, []);

    const dispatch = useDispatch();

    // mapDispatchToProps
    const onRemove = useCallback(
        (inputId) => dispatch(removeInput(inputId)),
        [dispatch]
    );
    const onSelect = useCallback(
        () => dispatch(updateSelectedItems([{ id: props.id, type: 'input' }])),
        [dispatch, props.id]
    );

    // mapStateToProps
    const inputDef = useSelector((state) => selectInputDef(state, props.id));

    const { type, dataSources: dataSourceBindings = {} } = inputDef;

    const primaryDataSourceId = dataSourceBindings.primary;

    const commonProps = {
        onRemove,
        itemDefinition: inputDef,
        mode,
        isSelected: isSelected && !isDragging,
        attributes,
        listeners,
    };

    const { loading, dataSources, refresh, updateRequestParams } =
        useSubscribeToSearches({
            consumerId: props.id,
            bindings: dataSourceBindings,
        });

    const canBeHidden = useCanItemBeHidden({
        itemDefinition: inputDef,
        itemId: props.id,
    });

    if (
        mode === 'view' &&
        canBeHidden &&
        !dataSources?.primary?.meta?.totalCount
    ) {
        return null;
    }

    return (
        <Wrapper
            ref={setNodeRef}
            data-input-id={props.id}
            data-input-type={type}
            data-test-selected={isSelected}
            onClick={onSelect}
            $transform={transform}
            $transition={transition}
            isDragging={isDragging}
        >
            {primaryDataSourceId ? (
                <InputContent
                    {...props}
                    {...commonProps}
                    loading={loading}
                    dataSources={dataSources}
                    refresh={refresh}
                    updateRequestParams={updateRequestParams}
                />
            ) : (
                <InputContent {...props} {...commonProps} />
            )}
        </Wrapper>
    );
};

ConnectedInputContainer.propTypes = {
    id: T.string,
};

const withSortable = (Component) => (props) => {
    const { enableDragDropInputs } = useFeatureFlags();

    const mode = useSelector(selectMode);

    const selectIsInputSelected = useMemo(makeSelectIsSelected, []);
    const isSelected = useSelector((state) =>
        selectIsInputSelected(state, props.id)
    );

    const globalInputs = useSelector(selectGlobalInputs);
    const isGlobalInput = globalInputs?.includes(props.id) ?? false;

    const isDraggable = useMemo(
        () =>
            enableDragDropInputs &&
            mode === 'edit' &&
            isSelected &&
            isGlobalInput,
        [enableDragDropInputs, mode, isSelected, isGlobalInput]
    );

    const {
        setNodeRef,
        transform,
        transition,
        isDragging,
        attributes,
        listeners,
    } = useSortable({
        id: props.id,
        disabled: !isDraggable,
        data: { snapCenterToCursor: true },
    });

    const sortableProps = {
        setNodeRef,
        transform,
        transition,
        isDragging,
        attributes,
        listeners,
        mode,
        isSelected,
    };

    return <Component {...props} {...sortableProps} />;
};

// Do our best to keep @dnd-kit/sortable context changes from causing large tree computations
export default withSortable(React.memo(ConnectedInputContainer, isEqual));
