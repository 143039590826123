import * as React from 'react';
import { _ } from '@splunk/ui-utils/i18n';
import SVG from '@splunk/react-icons/SVGEnterprise';

const Area = (props: React.ComponentProps<typeof SVG>): React.ReactElement<SVGElement> => (
    <SVG screenReaderText={_('Area')} fill="currentColor" viewBox="0 0 150 100" {...props}>
        <path d="M119.232 20.8622C118.994 20.0798 118.066 19.7512 117.388 20.2089L114.739 21.9982C114.46 22.1867 114.271 22.4826 114.218 22.8153L111.15 42.1538L108.055 33.8295C107.626 32.6745 105.948 32.8176 105.721 34.0286L101.206 58.0477L90.7144 63.1063L88.8399 51.0927C88.6335 49.7699 86.7528 49.7034 86.4536 51.0084L83.2806 64.8453L80.3296 56.2886C80.217 55.9621 79.9709 55.6989 79.6526 55.5647L76.7686 54.3486L72.67 36.4603C72.3936 35.2541 70.702 35.1837 70.3264 36.3628L67.3007 45.861L59.0463 56.9048L56.3767 54.5809C55.7921 54.072 54.887 54.2327 54.5133 54.9117L51.8824 59.6918L44.8789 33.9402C44.7796 33.5753 44.5163 33.2774 44.1663 33.1341L40.9262 31.8078C40.3348 31.5657 39.6569 31.8228 39.3747 32.3962L33.3516 44.6356L29.4441 42.9163L25.4855 33.286C25.181 32.5453 24.2561 32.2962 23.6208 32.7837L20.6924 35.0311L18.578 23.8136C18.3215 22.453 16.3539 22.5105 16.1773 23.8837L11.8923 57.2113L10.0716 66.3688L6.8644 51.1945C6.76787 50.7378 6.41836 50.3766 5.96503 50.2652L2.50596 49.4152C1.73979 49.2269 1 49.8069 1 50.5958V79.6529C1 80.3244 1.54433 80.8687 2.21581 80.8687H148.72C149.392 80.8687 149.936 80.3244 149.936 79.6529V52.6903C149.936 52.5299 149.904 52.3711 149.843 52.223L145.895 42.742C145.557 41.9294 144.494 41.7344 143.89 42.374L140.915 45.5202C140.766 45.6782 140.661 45.8734 140.613 46.0853L136.476 64.2347L133.579 37.8911C133.426 36.5051 131.44 36.4246 131.176 37.7937L129.455 46.7158L126.005 26.9741C125.8 25.7994 124.202 25.5915 123.703 26.6745L122.077 30.2035L119.232 20.8622Z" />
    </SVG>
);

export default Area;
