import memoize from 'lodash/memoize';

export const computeTextSize = memoize(
    (text, font) => {
        const canvas = document.createElement('canvas');
        const context = canvas.getContext('2d');
        context.font = font;
        const measure = context.measureText(text);
        return measure.width;
    },
    (text, font) => `${text}${font}`
);

export function getPropValue(element, property) {
    return window.getComputedStyle(element).getPropertyValue(property);
}
