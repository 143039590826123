import { uniqBy, isEmpty, differenceBy } from 'lodash';
import {
    parse as baseParse,
    mergeEncoding,
} from '@splunk/visualization-encoding-parsers/Base';
import { deprecated } from '@splunk/dashboard-utils';
import { Options } from '@splunk/visualization-encoding/Options';

export const dataContract = {
    requiredDataSources: ['primary'],
    initialRequestParams: {
        primary: { offset: 0, count: 1000, progress: false },
    },
    encoding: {
        value: {
            isRequired: true,
            type: ['number', 'string'],
            default: 'primary[0]',
        },
        label: {
            type: ['number', 'string'],
            default: 'primary[1]',
        },
    },
};

/**
 * Extracts data from dataset
 * @param {Hash[DataSet]} dataSources The extracted data binding
 * @param {Object} userDefinedEncoding Encoding contract for label and value
 * @returns {Object} label (optionally) and value arrays
 */
const parseEncoding = (dataSources, userDefinedEncoding) => {
    return baseParse(
        dataSources,
        mergeEncoding(dataContract.encoding, userDefinedEncoding)
    );
};

/**
 * Convert dynamic data into item list
 * @param {Object} dataProps with label & value fields
 * @param {Array[String|Number]} [dataProps.label] The labels for the input options
 * @param {Array[String|Number]} dataProps.value The values for the input options
 * @returns Array[InputItem] List of label/value pairs
 */
export const dataToItems = ({ label, value }) => {
    if (!Array.isArray(value) || value.length === 0) {
        return [];
    }

    if (Array.isArray(label)) {
        return value.map((val, idx) => ({ label: label[idx], value: val }));
    }

    return value.map((val) => ({ label: val, value: val }));
};

const hasNoData = (dataSources) =>
    Object.values(dataSources).every(({ data }) => isEmpty(data));

/**
 * Get dynamic items from dataSources and encoding
 * @param {Object} dataSources Hash of dataSources data
 * @param {Object} encoding Encoding config
 * @returns {Object[]} Array of label/value pairs
 */
export const getDynamicItems = (dataSources, encoding) => {
    if (isEmpty(dataSources) || hasNoData(dataSources)) {
        return [];
    }

    const dataProps = parseEncoding(dataSources, encoding);
    return dataToItems(dataProps);
};

const formatItems = (items) => {
    return items.map(({ label, value }) => ({
        label: label || value,
        value,
    }));
};

/**
 * retrieve first search result from static & dynamic items, if it exists
 * @param {Array[InputItem]} staticItems User defined static list
 * @param {Array[InputItem]} [dynamicItems=[]] Search provided dynamic list
 * @returns {string}
 */
export const getFirstSearchResult = ({ staticItems, dynamicItems = [] }) => {
    const formattedItems = formatItems([...dynamicItems, ...staticItems]);
    return formattedItems.length > 0 ? formattedItems[0].value : null;
};

/**
 * merge static and dynamic items together
 * @param {Array[DefaultItem]} [defaultValues=[]] default values
 * @param {Array[InputItem]} staticItems User defined static list
 * @param {Array[InputItem]} [dynamicItems=[]] Search provided dynamic list
 * @returns {Array[InputItem]}
 */
export const mergeItems = ({
    defaultValues = [],
    staticItems,
    dynamicItems = [],
}) => {
    const formattedDefaultValues = defaultValues.map((value) => ({
        label: value,
        value,
    }));

    // Sanitize items, always have a label and value
    const formattedItems = formatItems([...staticItems, ...dynamicItems]);

    const dedupedItems = uniqBy(formattedItems, ({ value }) => {
        return value;
    });

    const defaultValuesNotInItems = differenceBy(
        formattedDefaultValues,
        dedupedItems,
        'value'
    );

    return [...defaultValuesNotInItems, ...dedupedItems];
};

/**
 * Merges items from options with items from dataSource
 * @param {Object} cfg
 * @param {Object} cfg.dataSources Hash of dataSources data
 * @param {Object} cfg.encoding Encoding config
 * @param {Object[]} cfg.items List of default items from options
 * @param {Error} cfg.error A dataSource error
 * @param {Boolean} cfg.loading Datasource is fetching data
 * @param {String[]} [cfg.defaultValues=[]] default values
 */
export const getItemList = ({
    error,
    loading,
    dataSources,
    encoding,
    items,
    defaultValues = [],
    skipEncoding,
}) => {
    if (error || loading) {
        return [];
    }

    const dynamicItems = skipEncoding
        ? []
        : getDynamicItems(dataSources, encoding);

    return mergeItems({
        defaultValues,
        staticItems: items,
        dynamicItems,
    });
};

const noItems = [];

export const hasDynamicOptions = (arr) => {
    if (!Array.isArray(arr)) {
        throw new TypeError('hasDynamicOptions should receive an array');
    }
    return arr.some((v) => typeof v === 'string' && v.trim().startsWith('>'));
};

const defaultEncoding = {
    label: 'primary[0]',
    value: 'primary[0]',
};

export const fetchDynamicContent = ({
    context,
    items,
    dataSources,
    encoding,
}) => {
    const hasEncoding = !isEmpty(encoding);
    if (hasEncoding) {
        deprecated(
            'Encoding is deprecated, please use Dynamic Options instead.'
        );
    }

    // Return early if there's no data to be dynamic
    if (isEmpty(dataSources)) {
        return noItems;
    }

    // Calculate items from dynamic items config
    if (hasDynamicOptions([items])) {
        const { items: resolvedItems } = Options.evaluate(
            {
                context,
                options: { items },
            },
            dataSources
        );

        return resolvedItems;
    }

    // Use user's provided encoding
    if (hasEncoding) {
        return getDynamicItems(dataSources, encoding);
    }

    // Use default encoding
    return getDynamicItems(dataSources, defaultEncoding);
};

// check if the input search (when connected) ran successfully but didn't return any results
export const getSearchStatus = (dataSources) =>
    dataSources.primary?.meta?.status === 'done' &&
    dataSources.primary?.meta?.totalCount === 0;
