import * as React from 'react';
import * as T from 'prop-types';

import styled from 'styled-components';
import { range } from 'lodash';
import pick from '@splunk/themes/pick';
import variables from '@splunk/themes/variables';

// was previously lineColor in themeRegistry
const GridLine = styled.line`
    stroke: ${pick({
        enterprise: {
            light: variables.gray96,
            dark: variables.gray25,
        },
        prisma: variables.interactiveColorBackgroundDisabled,
    })};
    stroke-width: 1px;
`;

interface PunchcardGridLinesProps {
    xStartPosition: number;
    xEndPosition: number;
    yStartPosition: number;
    yEndPosition: number;
    xDelta: number;
    yDelta: number;
}

const PunchcardGridLines = (props: PunchcardGridLinesProps): React.ReactElement => {
    const { xStartPosition, xEndPosition, yStartPosition, yEndPosition, xDelta, yDelta } = props;

    const yGridLinesXPositions = range(xStartPosition, xEndPosition + 1, xDelta);
    const xGridLinesYPositions = range(yStartPosition, yEndPosition + 1, yDelta);

    return (
        <g data-test="data-test-gridlines">
            {yGridLinesXPositions.map(xValue => (
                <GridLine
                    key={`${xValue}x${yStartPosition}`}
                    x1={xValue}
                    y1={yStartPosition}
                    x2={xValue}
                    y2={yEndPosition}
                />
            ))}
            {xGridLinesYPositions.map(yValue => (
                <GridLine
                    key={`${xStartPosition}x${yValue}`}
                    x1={xStartPosition}
                    y1={yValue}
                    x2={xEndPosition}
                    y2={yValue}
                />
            ))}
        </g>
    );
};

const propTypes: Record<keyof PunchcardGridLinesProps, T.Validator<any>> = {
    xStartPosition: T.number,
    xEndPosition: T.number,
    yStartPosition: T.number,
    yEndPosition: T.number,
    xDelta: T.number,
    yDelta: T.number,
};

const defaultProps: Record<keyof PunchcardGridLinesProps, any> = {
    xStartPosition: undefined,
    xEndPosition: undefined,
    yStartPosition: undefined,
    yEndPosition: undefined,
    xDelta: 10,
    yDelta: 10,
};

PunchcardGridLines.propTypes = propTypes;
PunchcardGridLines.defaultProps = defaultProps;

export default PunchcardGridLines;
