import * as React from 'react';

const ReverseRangeValuesIcon = (): React.ReactElement<SVGElement> => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0)">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M4.83008 11.0587L2.90476 9.13335C2.64506 8.87365 2.224 8.87365 1.96431 9.13335C1.70461 9.39305 1.70461 9.8141 1.96431 10.0738L5.02385 13.1334C5.1542 13.2637 5.32519 13.3286 5.49603 13.3281C5.66687 13.3286 5.83787 13.2637 5.96821 13.1334L9.02776 10.0738C9.28746 9.8141 9.28746 9.39305 9.02776 9.13335C8.76806 8.87365 8.34701 8.87365 8.08731 9.13335L6.16008 11.0606L6.16008 3.33102C6.16008 2.96375 5.86235 2.66602 5.49508 2.66602C5.12781 2.66602 4.83008 2.96375 4.83008 3.33102L4.83008 11.0587Z"
                fill="#3C444D"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M11.8306 4.93547L9.90525 6.86079C9.64555 7.12049 9.22449 7.12049 8.96479 6.86079C8.70509 6.60109 8.70509 6.18004 8.96479 5.92034L12.0243 2.86079C12.1547 2.73044 12.3257 2.66552 12.4965 2.66602C12.6674 2.66552 12.8384 2.73044 12.9687 2.86079L16.0282 5.92034C16.2879 6.18004 16.2879 6.60109 16.0282 6.86079C15.7685 7.12049 15.3475 7.12049 15.0878 6.86079L13.1606 4.93356L13.1606 12.6631C13.1606 13.0304 12.8628 13.3281 12.4956 13.3281C12.1283 13.3281 11.8306 13.0304 11.8306 12.6631L11.8306 4.93547Z"
                fill="#3C444D"
            />
        </g>
        <defs>
            <clipPath id="clip0">
                <rect width="16" height="15.9993" fill="white" transform="matrix(1 0 0 -1 0 15.9994)" />
            </clipPath>
        </defs>
    </svg>
);

export default ReverseRangeValuesIcon;
