import { _ } from '@splunk/ui-utils/i18n';
import { COLOR_EDITOR_PALETTE } from '@splunk/visualizations-shared/colorConstants';
import { isDynamicOption } from '@splunk/visualizations-shared/configUtils';

const StaticIconColorEditor = [
    {
        label: _('Icon'),
        option: 'iconColor',
        editor: 'editor.color',
        showEditor: ({ options }) => !isDynamicOption(options.iconColor),
        editorProps: {
            labelPosition: 'top',
            value: null,
            palette: [null, ...COLOR_EDITOR_PALETTE],
        },
    },
];

export default StaticIconColorEditor;
