import type { Observer } from 'rxjs';
import hash from 'hash-it';
import { mapKeys } from 'lodash';
import { Observable } from 'rxjs';

import type {
    DataSourceConfigMeta,
    DataSourceMeta,
    RequestParams,
} from '@splunk/dashboard-types';
import {
    console,
    noop,
    getDefaultDataSourceName,
} from '@splunk/dashboard-utils';
import {
    createPostSearchQueryArray,
    mergeSearches,
} from '@splunk/datasource-utils';
import SearchJob from '@splunk/search-job';
import { _ } from '@splunk/ui-utils/i18n';

import DataSource from './DataSource';
import SplunkSearchOptionsSchema from './SplunkSearchOptionsSchema';
import {
    combineResultWhenProgress,
    combineResultWhenFinalized,
    previewFetchPredicate,
    projectFunc,
    transformObserver,
    getEnterpriseSearchContext,
} from './utils/SplunkSearchUtils';
import { getDefaultOptionsForSearchQuery } from './utils/SearchConfigUtils';

import type {
    EnterpriseQueryParameterNameMap,
    EnterpriseQueryParameters,
    EnterpriseSearchContext,
    EnterpriseSearchObservable,
    SplunkSearchOptions,
    ParsedEnterpriseQueryParameters,
    ObservableData,
} from './types';

type ResultsObservable =
    | ReturnType<typeof combineResultWhenProgress>
    | ReturnType<typeof combineResultWhenFinalized>;

type SplunkSearchConfigMeta = DataSourceConfigMeta<
    typeof SplunkSearchOptionsSchema
>;

interface SplunkSearchConfig extends SplunkSearchConfigMeta {
    isChainEnabled: boolean;
}

export const ownRequestParams: Partial<RequestParams> = {
    output_mode: 'json_cols',
    show_metadata: true,
};

export const defaultParams: Partial<EnterpriseQueryParameters> = {
    // Note: if preview is changed to false, we will not get resultPreviewCount below and
    // may break search status icon behavior
    preview: true,
};

export const defaultRequestParams: Partial<RequestParams> = {
    count: 0,
    offset: 0,
    progress: true,
};

const paramsMapping: EnterpriseQueryParameterNameMap = {
    earliest: 'earliest_time',
    latest: 'latest_time',
};

const parseQueryParameters = (
    options: SplunkSearchOptions
): Partial<ParsedEnterpriseQueryParameters> => ({
    ...defaultParams,
    ...mapKeys(options.queryParameters, (_value, key) => {
        const paramName = key as keyof EnterpriseQueryParameters;
        return paramsMapping[paramName] ?? paramName;
    }),
});

const noCollectInSplRegex = /\|\s*collect/gi;

/**
 * A DataSource that talks to splunk enterprise
 * @param {Object} options
 * @param {String} options.query spl query string
 * @param {String} options.queryParameters.earliest earliest time
 * @param {String} options.queryParameters.latest latest time
 * @param {Object} context See [search-job api](https://splunkui.sv.splunk.com/Packages/search-job/API) dispatchOptions object
 * @param {Boolean} [context.keepAlive=true] If true, keep the job alive before teardown
 * @param {Boolean} [context.cache=false] If true, will try and reuse an existing search job if it has the same request params.
 * @param {String} [context.app] The current app in use as defined in the page url and @splunk/splunk-utils/Config
 * @param {String} [context.splunkdPath] splunk rest endpoint path
 * @param {String} [context.sessionKey] current session key
 * @param {String} [context.auditProvenance] provenance value to appear in the splunk audit log
 * @param {String} [context.id] ID of the dashboard performing the query
 * @param {Object} meta
 * @param {Object} baseChainModel base chain definition
 * @returns {SplunkSearch} A new SplunkSearch datasource instance.
 * @public
 */
class SplunkSearch extends DataSource<
    SplunkSearchOptions,
    EnterpriseSearchContext
> {
    static config: SplunkSearchConfig;

    static schema = SplunkSearchOptionsSchema;

    completeSearchQuery?: string;

    // Base search ID if this is a chain search
    extend?: string;

    // Parent search ID from base/chain relation
    parentChainQuery?: string;

    results: Record<number, ResultsObservable> | null = {};

    // Most recent API search execution
    searchJob?: typeof SearchJob;

    // Error set during failed setup() call
    setupError?: Error;

    // Flag if the child DS should refresh
    shouldRefreshSub = false;

    constructor(
        options = {},
        context = {},
        meta: DataSource['meta'] = {},
        baseChainModel: DataSource['baseChainModel'] = {}
    ) {
        super(options, context);

        if (!this.options.query && !this.options.sid) {
            throw Error('query string or sid is required!');
        }

        // if there is extend, then this is chain search
        this.extend = this.options.extend;

        this.context = getEnterpriseSearchContext(
            this.context
        ) as EnterpriseSearchContext;

        this.options = {
            ...parseQueryParameters(this.options),
            search: this.options.query, // map query to search
            sid: this.options.sid,
            check_risky_command: this.options.checkRiskyCommand,
            label: this.context.id,
        };

        if (this.context.auditProvenance) {
            this.options.provenance = this.context.auditProvenance;
        }

        this.baseChainModel = baseChainModel;
        this.meta = meta;
    }

    /**
     * Creates a search job and returns sid once the job is created on the server.
     * @returns {Promise} The sid of the created job.
     * @public
     */
    async setup() {
        try {
            /**
             * SearchJob can only modify config and fetch params globally.
             */
            const { splunkdPath, sessionKey } = this.context;
            if (splunkdPath) {
                SearchJob.setSplunkConfig({
                    splunkdPath,
                });
            }
            if (sessionKey) {
                SearchJob.setBaseFetchInit({
                    headers: {
                        Authorization: `Splunk ${sessionKey}`,
                    },
                });
            }

            if (this.context.id && this.baseChainModel?.[this.context.id]) {
                // this is base data source
                this.validation();
                this.options = {
                    ...this.options,
                    postprocess_searches: JSON.stringify(
                        createPostSearchQueryArray(
                            this.context.id,
                            this.baseChainModel
                        )
                    ),
                    auto_cancel: 90, // cancel search job after 90 seconds of inactivity,
                    label: this.context.id,
                };
            }

            let sid = null;

            if (this.extend) {
                if (this.options.sid) {
                    this.searchJob = SearchJob.fromSid(this.options.sid);
                }
            } else {
                this.searchJob = this.options.sid
                    ? SearchJob.fromSid(this.options.sid)
                    : SearchJob.create(this.options, this.context);
                sid = await this.searchJob.getSid().first().toPromise();
            }

            return sid;
        } catch (e) {
            if (e instanceof Error) {
                if (this.options.sid) {
                    console.error('failed to retrieve search job:', e);
                } else {
                    console.error('failed to create search job:', e);
                }

                this.setupError = e;
            }

            return null;
        }
    }

    /**
     * Returns an Observable that emits job information and search results.
     *
     * @param requestParams {Object} - [See documentation for available parameters.
     * ](http://docs.splunk.com/Documentation/Splunk/latest/RESTREF/RESTsearch#search.2Fjobs.2F.7Bsearch_id.7D.2Fresults)
     * @returns {Observable}
     * @public
     */
    request(requestParams: RequestParams = {}): EnterpriseSearchObservable {
        if (this.setupError) {
            return (observer) => {
                observer?.error({
                    level: 'error',
                    message: this.setupError?.message,
                    meta: {
                        search: this.options.search,
                    },
                });

                return noop;
            };
        }

        const key = hash(requestParams);
        let requestResult = this.results?.[key];

        // One observable serves for all viz and ds subscription with same requestParams
        if (!requestResult && this.searchJob) {
            let searchRequestParams = requestParams;
            if (this.extend) {
                searchRequestParams = {
                    ...requestParams,
                    check_risky_command: this.options.check_risky_command,
                    search: this.parentChainQuery
                        ? mergeSearches(
                              this.parentChainQuery,
                              this.options.search
                          )
                        : this.options.search,
                };
            }

            const params = {
                ...defaultRequestParams,
                ...searchRequestParams,
                ...ownRequestParams,
            };

            if (params.sort) {
                let sortStr = '';

                Object.entries(params.sort).forEach(([sortKey, sortDir]) => {
                    if (sortDir === 'desc') {
                        sortStr += ` -"${sortKey}"`;
                    } else if (sortDir === 'asc') {
                        sortStr += ` +"${sortKey}"`;
                    }
                });

                if (sortStr) {
                    if (params.search) {
                        params.search += ' | sort 0';
                    } else {
                        params.search = '| sort 0';
                    }

                    params.search += sortStr;
                }

                delete params.sort;
            }

            const progressObservable = this.searchJob.getProgress();

            if (params.progress) {
                requestResult = combineResultWhenProgress(
                    this.searchJob.getResultsPreview(params, {
                        fetchPredicate: previewFetchPredicate,
                    }),
                    progressObservable,
                    // populate the search query to the chained data source for construct the chain-chain query
                    this.combineResultCallback(searchRequestParams.search)
                );
            } else {
                requestResult = combineResultWhenFinalized(
                    this.searchJob.getResults(params),
                    progressObservable,
                    this.combineResultCallback(searchRequestParams.search)
                );
            }

            this.results ??= {};
            this.results[key] = requestResult;
        }

        return (observer) => {
            // note: due to the nature that 'data' and 'meta' are from two different endpoints, there's no
            // way to guarantee that they always match. But they'll eventually match when search is done.
            // no search job? then return a empty Observable
            const result = requestResult || Observable.of();
            const subscription = result.subscribe(
                transformObserver(
                    observer,
                    this.completeSearchQuery || this.options.search
                )
            );

            return () => {
                subscription.unsubscribe();
            };
        };
    }

    /**
     * Cancel the search job and returns the server response.
     * Job will not be cancelled if `context.cache` is true.
     * @returns {Promise}
     * @public
     */
    async teardown() {
        this.results = null;
        // chain search does not cancel search job
        if (this.extend) {
            return null;
        }
        try {
            const { cache } = this.context;
            if (!cache && this.searchJob) {
                return this.searchJob.cancel().first().toPromise();
            }
            return null;
        } catch (ex) {
            console.error('failed to cancel search job:', ex);
            return null;
        }
    }

    getObserver = (): Observer<ObservableData> => ({
        next: ({ meta }) => {
            const { sid, search, checkRiskyCommand, completeSearchQuery } =
                meta as DataSourceMeta;

            if (
                this.options.sid !== sid ||
                this.options.check_risky_command !== checkRiskyCommand ||
                this.parentChainQuery !== search ||
                this.completeSearchQuery !== completeSearchQuery
            ) {
                this.options.sid = sid;
                this.options.check_risky_command = checkRiskyCommand;
                this.parentChainQuery = search;
                this.completeSearchQuery = completeSearchQuery;
                this.results = {};
                this.shouldRefreshSub = true;

                // re-create SearchJob. setup() is async but should be fine here
                this.setup();
            } else {
                this.shouldRefreshSub = false;
            }
        },
        error: (error) => {
            if (error instanceof Error) {
                console.log(error);
            }
        },
        complete: noop,
    });

    shouldRefreshSubscription(): boolean {
        return this.shouldRefreshSub;
    }

    validation(): void {
        const { search } = this.options;
        if (search && noCollectInSplRegex.test(search)) {
            throw new Error(
                _(
                    `The "collect" command cannot be used in a base search. Use an ad-hoc search for data source ${this.context.id} to use the "collect" command.`
                )
            );
        }
    }

    // eslint-disable-next-line class-methods-use-this
    private combineResultCallback = (search = ''): typeof projectFunc => {
        return (results, progress) => projectFunc(results, progress, search);
    };
}

SplunkSearch.config = {
    title: ({ searchType }) =>
        searchType === 'ds.search' ? _('Search') : _('Chain search'),
    displayDataSourceItemListByDefault: true,
    canCreateDataSource: true,
    dataSourceRemoveVerb: 'delete',
    isDataSourceNameEditable: true,
    getDataSourceName: getDefaultDataSourceName,
    defaultOptions: ({ searchType, definition }) =>
        searchType === 'ds.search'
            ? getDefaultOptionsForSearchQuery(definition)
            : {},
    editorConfig: [],
    optionsSchema: SplunkSearchOptionsSchema,
    isChainEnabled: true,
};

export default SplunkSearch;
