import { AbstractFormatter } from '../Formatter';
import EncodingExecutor from '../EncodingExecutor';
import { DataPoint } from '../DataPoint';
import { TypedValue } from '../DataPrimitive';
import { TypeSafeValue } from '../TypeSafeValue';
import { DataSeries } from '../DataSeries';

export class Default extends AbstractFormatter {
    private defaultVal: string;

    constructor(val: any) {
        super();
        this.defaultVal = EncodingExecutor.rawTree(val);
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    protected formatTypedValue(p: DataPoint, s: DataSeries, i = 0, j = 0): TypedValue {
        if (p.getRawValue() === undefined) {
            return TypeSafeValue.fromRaw(this.defaultVal);
        }
        return p.getValue();
    }
}
