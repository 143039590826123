import type {
    LifecycleEventPayload,
    LifecycleEvent,
} from '@splunk/dashboard-types';

/**
 * Base event
 */
export class DashboardLifecycleEvent implements LifecycleEvent {
    error?: unknown;

    [x: string]: unknown;

    constructor(payload: LifecycleEventPayload) {
        Object.assign(this, payload);
    }
}
