import { mapKeys, chain } from 'lodash';
import { convertPropsToString } from '@splunk/visualizations-shared/propUtils';
import { FieldEntry } from '@splunk/visualizations-shared/dataSourceUtils';
import { getFieldsFromDSL } from '@splunk/visualization-encoding/utils/dsl';
import { deepMergeWithArrayPrimitiveOverrides } from '@splunk/visualizations-shared/hocUtils';
import { pickFieldFromJSONSchema } from '@splunk/visualizations-shared/JSONSchemaUtils';
import { DataSource } from '../common/interfaces/DataSource';
import { VizConfig } from '../common/interfaces/VizConfig';

interface PieOptions {
    [key: string]: any;
}

// CONVERT THIS TO PIE THEN ADD TO Pie.TSX

// map for key names which are either new or not present in SimpleXML options mapping
const pieOptionsSCMapping = {
    resultLimit: 'resultTruncationLimit',
    seriesColorsByField: 'fieldColors',
    collapseThreshold: 'chart.sliceCollapsingThreshold',
    collapseLabel: 'chart.sliceCollapsingLabel',
};

/**
 * Helper method to convert option which contains object literal values (e.g. arrays, objects)
 * to strings as SC can only accept their stringified equivalents
 * @method convertToStringValues
 * @param {Object} options
 * @returns {Object}
 */
export const convertToStringValues = (options: PieOptions): PieOptions => {
    const updatedOptions = convertPropsToString({
        vizProps: options,
        propNames: ['seriesColors', 'fieldColors'],
    });
    return { ...options, ...updatedOptions };
};

interface labelDisplayOptions extends PieOptions {
    labelDisplay?: string;
}

/**
 * Helper method to map labelDisplay options to jsCharting options
 * @method mapLabelDisplay
 * @param {Object} options
 * @returns {Object}
 */
export const mapLabelDisplay = ({ labelDisplay, ...options }: labelDisplayOptions): PieOptions => {
    const convertedOptions: Record<string, any> = {
        ...options,
        // default for labelDisplay option is 'values'
        'chart.showLabels': true,
        'chart.showPercent': false,
    };
    if (labelDisplay === 'valuesAndPercentage') {
        convertedOptions['chart.showPercent'] = true;
    } else if (labelDisplay === 'off') {
        convertedOptions['chart.showLabels'] = false;
    }
    return convertedOptions;
};

/**
 * Helper method to update the option names which can be mapped to SimpleXML options or
 * can be used directly with SC if don't have a 1-1 mapping in SimpleXML
 * @method mapToOldKey
 * @param {Object} options
 * @returns {Object}
 */
export const mapToOldKey = (options: PieOptions): PieOptions =>
    mapKeys(options, (val, key) => (pieOptionsSCMapping[key] ? pieOptionsSCMapping[key] : key));

/**
 * Chained helper for mapping the option properties (and values) we expose in config.ts into property values that splunk-charting accepts
 * This is for options that has new key name and can not directly be mapped with SimpleXML or
 * don't have a 1-1 mapping in SimpleXML (i.e. a straightforward name mapping where values are preserved)
 * @method convertToPieSCProperties
 * @param {Object} originalOptions
 * @returns {Object}
 */
export const convertToPieSCProperties = (originalOptions: PieOptions): PieOptions =>
    chain(mapToOldKey(originalOptions)).thru(convertToStringValues).thru(mapLabelDisplay).value();

/**
 * Return all merged options (default + config) using vizConfig and options
 * @param options
 * @param vizConfig
 * @returns
 */
export const getPieMergedOptions = (
    options: Record<string, unknown>,
    vizConfig: VizConfig
): Record<string, unknown> =>
    deepMergeWithArrayPrimitiveOverrides(
        {},
        options,
        pickFieldFromJSONSchema(vizConfig.optionsSchema, 'default')
    );

/**
 * Returns option's field index. If not found returns -1
 * @param optionName
 * @param dataSources
 * @param options
 * @param vizConfig
 * @returns
 */
export const getPieOptionFieldIndex = (
    optionName: string,
    dataSources: { [name: string]: DataSource },
    options: Record<string, unknown>,
    vizConfig: VizConfig
): number => {
    const mergedOptions = getPieMergedOptions(options, vizConfig);
    // find the field name configured for pie "value"
    const fieldName = getFieldsFromDSL(mergedOptions[optionName], dataSources)[0];
    const { fields } = dataSources.primary.data;
    // find the field index configured for pie "value"
    const fieldIndex = fields.findIndex(
        field => (typeof field === 'string' ? field : (field as FieldEntry).name.toString()) === fieldName
    );
    return fieldIndex;
};

/**
 * Returns true if columns data has invalid data format for specified option (label or value)
 * @param optionName
 * @param dataSources
 * @param options
 * @param vizConfig
 * @returns
 */
export const invalidOptionField = (
    optionName: 'label' | 'value',
    dataSources: { [name: string]: DataSource },
    options: Record<string, unknown>,
    vizConfig: VizConfig
): boolean => {
    const { columns } = dataSources.primary.data;
    const fieldIndex = getPieOptionFieldIndex(optionName, dataSources, options, vizConfig);
    if (
        fieldIndex === -1 ||
        !columns[fieldIndex] ||
        !Array.isArray(columns[fieldIndex]) ||
        (Array.isArray(columns[fieldIndex]) && !columns[fieldIndex].length)
    ) {
        return true;
    }
    return false;
};

/**
 * Helper function to evaluate if all data values are invalid (zero or negative)
 */
export const allInvalidDataValues = (
    dataSources: { [name: string]: DataSource },
    options: Record<string, unknown>,
    vizConfig: VizConfig
): boolean => {
    const { columns } = dataSources.primary.data;
    // find the field index configured for pie "value"
    const valueFieldIndex = getPieOptionFieldIndex('value', dataSources, options, vizConfig);
    // check if all values are invalid
    if (Array.isArray(columns) && columns[valueFieldIndex] && Array.isArray(columns[valueFieldIndex])) {
        return !columns[valueFieldIndex].some(value => +value > 0);
    }
    return false;
};
