import React from 'react';
import { _ } from '@splunk/ui-utils/i18n';
import { mapKeys } from 'lodash';
import { withInputWrapper } from '../utils/enhancer';
import DefaultTimeRangeConnector from '../components/DefaultTimeRangeConnector';
import { BaseTimeRangePicker } from '../components/BaseTimeRange';
import TRPEditor from '../components/TRPEditor';

const TimeRangePicker = (props) => {
    const { value } = props;
    return (
        <DefaultTimeRangeConnector value={value}>
            <BaseTimeRangePicker {...props} />
        </DefaultTimeRangeConnector>
    );
};

TimeRangePicker.valueToTokens = BaseTimeRangePicker.valueToTokens;
TimeRangePicker.tokensToValue = BaseTimeRangePicker.tokensToValue;

const editor = [
    {
        enableCollapsible: false,
        enableSeparator: false,
        layout: [
            [
                {
                    label: _('Token name'),
                    option: 'token',
                    editor: 'editor.text',
                },
            ],
            [
                {
                    label: _('Default value'),
                    option: 'defaultValue',
                    editor: TRPEditor,
                    editorProps: {
                        Connector: DefaultTimeRangeConnector,
                    },
                },
            ],
        ],
    },
];

TimeRangePicker.config = {
    editorConfig: editor,
    optionsSchema: BaseTimeRangePicker.config.schema,
    ...mapKeys(BaseTimeRangePicker.config.meta, (value, key) =>
        key === 'defaultConfig' ? 'baseShape' : key
    ),
};

export default withInputWrapper(TimeRangePicker);
