import { useMemo, useEffect } from 'react';
import type { DataSourceEventPayload } from '@splunk/dashboard-types';
import { useUserMessageAPI } from '@splunk/dashboard-context';
import { isRiskyCommandError } from '@splunk/dashboard-utils';
import { emitRiskyWarningMessage } from '../RiskyWarningPopoverContent';

export const useRiskySearchToast = ({
    dataSource,
}: {
    dataSource?: DataSourceEventPayload;
} = {}) => {
    const userMessageApi = useUserMessageAPI();

    const hasRiskyCommand = useMemo(() => {
        const errorMessage = dataSource?.error?.message;

        return errorMessage ? isRiskyCommandError(errorMessage) : false;
    }, [dataSource?.error?.message]);

    useEffect(() => {
        if (userMessageApi && hasRiskyCommand) {
            emitRiskyWarningMessage({ userMessage: userMessageApi });
        }
    }, [hasRiskyCommand, userMessageApi]);
};
