import type { GeoJsonDataType } from '@splunk/visualizations-shared/mapUtils';
import { DataPoint } from './DataPoint';
import { DataPrimitive, DataType, IDataSeries, TypedValue } from './DataPrimitive';
import { inferDataTypeFromDataPoints } from './utils/types';

/**
 * DataSeries class and associated DataSeries selectors
 * @implements {DataPrimitive}
 */
export class DataSeries<T extends DataType = DataType> implements DataPrimitive<T>, IDataSeries<T> {
    public readonly points: DataPoint<T>[];

    public readonly field: string;

    public type: string;

    static isDataSeries(o: any): o is DataSeries {
        return o instanceof DataSeries;
    }

    static fromRaw(pts: any[]): DataSeries {
        return new DataSeries(pts.map(p => DataPoint.fromRaw(p)));
    }

    /**
     *
     * @param {array} points list of data points
     * @param {string} type user explicitly sets the data type
     */
    constructor(points: DataPoint<T>[] = [], type?: string) {
        this.points = points;
        if (points.length > 0) {
            this.field = points[0].field; // the field is an immutable property of the series
        }
        this.type = type;
    }

    /**
     * Return first dataPoint in series.
     * @public
     * @returns {DataPoint}
     */
    firstPoint(): DataPoint<T> {
        return this.points[0];
    }

    /**
     * Return last dataPoint in series.
     * @public
     * @returns {DataPoint}
     */
    lastPoint(): DataPoint<T> {
        return this.points.slice(-1)[0];
    }

    /**
     * Finds dataPoint(s) in DataSeries by index(es).
     * @public
     * @param {...number} indexes
     * @returns {DataSeries}
     */
    pointsByIndexes(...indexes: number[]): DataSeries<T> {
        const indexedPoints: DataPoint<T>[] = [];
        indexes.forEach(index => {
            const dp = this.points[index]; // should we allow negative indexes?
            if (dp != null) {
                indexedPoints.push(dp);
            }
        });
        return new DataSeries(indexedPoints);
    }

    /**
     * Finds and returns the individual dataPoint at the given index.
     * @public
     * @param {number} index
     * @returns {DataPoint}
     */
    pointByIndex(indexIn: number): DataPoint<T> {
        let index = indexIn;
        if (index < 0) {
            index = this.points.length + index;
        }
        if (index < 0 || index >= this.points.length) {
            // will handle out-of-range indexes for the time being by returning null
            return null;
        }
        const dp = this.points[index];
        const { field } = dp;
        const { type, value } = dp.getValue();
        return new DataPoint(field, { type, value });
    }

    /**
     * Finds the delta between the last point and point at the given index.
     * A negative index can be used, indicating an offset from the end of the sequence.
     * @public
     * @param {number} index
     * @returns {DataPoint}
     */
    delta(index: number): DataPoint<T> {
        const dp1 = this.lastPoint();
        if (dp1 === undefined) {
            return undefined;
        }
        const { field } = dp1;

        const { type, value: val1 } = dp1.getValue();
        if (type !== 'number') {
            console.warn(`DSL delta cannot be computed for non-numerical data series of type ${type}`); // Throw an error and catch in pipeline executor?
        }

        let dp2 = null;
        // use undefined as default, because null values will be coerced to 0 (thus invalid indexes return a delta of 0)
        // for aggregate SPL queries like `stats count`, we want no trend value rather than 0 as the trend value
        let delta;
        if (index >= 0) {
            dp2 = this.points[index];
        } else {
            dp2 = this.points.slice().reverse()[Math.abs(index) - 1];
        }
        if (dp2 == null) {
            console.warn('DSL delta cannot be computed, no data - invalid index'); // Throw an error and catch in pipeline executor?
        } else {
            const { value: val2 } = dp2.getValue();
            delta = val1 - val2;
        }
        return new DataPoint(field, { type, value: delta }); // create a new data point since we don't want meta data to carry over
    }

    /**
     * Sets all the values in the Data Series to a static TypedValue.
     * @param {TypedValue} v
     */
    setValue(v: TypedValue<T>) {
        this.points.forEach(p => {
            p.setValue(v);
        });
        this.type = this.points[0].getType();
    }

    /**
     * Gets all the values + their type in the Data Series.
     * @returns {TypedValue[]}
     */
    getValue(): TypedValue<T>[] {
        const values: TypedValue<T>[] = [];
        this.points.forEach(p => {
            values.push(p.getValue());
        });
        return values;
    }

    /**
     * Gets all the values (only) in the Data Series.
     * @returns {array}
     */
    getRawValue(): (string | number | GeoJsonDataType)[] {
        const values: (string | number | GeoJsonDataType)[] = [];
        this.points.forEach(p => {
            values.push(p.getRawValue());
        });
        return values;
    }

    /**
     * Returns the data source field which the series belongs to.
     * @public
     * @returns {DataPoint<'string'>}
     */
    getField(): DataPoint<'string'> {
        const dp1 = this.lastPoint();
        return dp1.getField();
    }

    /**
     * Returns the inferred data type of the series.
     * @public
     * @returns {string}
     */
    getType(): string {
        if (this.type === undefined) {
            this.type = inferDataTypeFromDataPoints(this.points);
        }
        return this.type;
    }

    /**
     * Returns the minimum DataPoint in the series or undefined if no numbers in series.
     * @public
     * @returns {DataPoint<T>}
     */
    min(): DataPoint<T> {
        return this.reduce((v1, v2) => v1 < v2);
    }

    /**
     * Returns the maximum DataPoint in the series.
     * @public
     * @returns {DataPoint<T>}
     */
    max(): DataPoint<T> {
        return this.reduce((v1, v2) => v1 > v2);
    }

    private reduce(comparator: (v1: T, v2: T) => boolean): DataPoint<T> {
        return this.points.reduce((agg: DataPoint<T>, cur: DataPoint<T>) => {
            // note: cannot use agg.getRawValue here instead of agg.value.value as that limits returned value to string|number
            return !agg || comparator(cur.getValue().value, agg.getValue().value) ? cur : agg;
        }, undefined);
    }
}
