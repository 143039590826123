import React from 'react';
import styled from 'styled-components';
import { variables } from '@splunk/themes';
import LastUpdateTime from './LastUpdateTime';
import type { LastUpdateTimeProps } from './LastUpdateTime';

interface ContainerProps {
    itemId?: string;
    zIndex?: number;
}

const Container = styled.div.attrs<ContainerProps>(({ itemId }) => ({
    /**
     * As a child of a viz, data-id is probably not needed for tests as the viz already has that,
     * and the action menu should be a child of that ancestor.
     * It is left here for consistency with previous implementation
     */
    'data-id': itemId,
    'data-test': 'action-menus',
}))<ContainerProps>`
    display: inline-flex;
    flex-direction: row;
    justify-content: flex-end;
    flex-shrink: 1;
    align-items: center;
    border-radius: 6px;
    padding: 3px;
    background-color: ${variables.backgroundColorPopup};
    color: ${variables.contentColorDefault};
    box-shadow: ${variables.overlayShadow};

    /* hide me when there are no child elements (no buttons or time displayed) */
    &:empty {
        display: none;
    }
`;

export interface ActionMenuContainerProps extends LastUpdateTimeProps {
    actionButtons?: JSX.Element[];
    showLastUpdated?: boolean;
    itemId?: string;
    children?: React.ReactNode;
}

/* To stop propagation on clicking on action menus to avoid executing the mouse events at the canvas level. */
const handleClick = (e: React.MouseEvent) => {
    e.stopPropagation();
};

const ActionMenuContainer = ({
    actionButtons,
    lastUpdated,
    status,
    isRealTimeSearch,
    showLastUpdated,
    itemId,
    children,
}: ActionMenuContainerProps): JSX.Element => {
    return (
        <Container
            itemId={itemId}
            onMouseDown={handleClick}
            onClick={handleClick}
            onMouseUp={handleClick}
        >
            {actionButtons}
            {children}
            {showLastUpdated && (
                <LastUpdateTime
                    status={status}
                    lastUpdated={lastUpdated}
                    isRealTimeSearch={isRealTimeSearch}
                />
            )}
        </Container>
    );
};

export default ActionMenuContainer;
