import tinycolor from 'tinycolor2';
import type { AnyTheme, OptionalThemedProps } from '@splunk/themes';
import type { ColorParameter } from '.';

// The SUI colorWithAlpha mixin will overwrite the alpha of the source color but
// the goal here is to mimic setting the color and an opacity CSS property without
// actually using opacity and causing changes to the content/children of the element
export const applyTranslucence =
    <T extends AnyTheme>(color: ColorParameter<T>, alpha: number) =>
    (props: OptionalThemedProps<T>) => {
        const colorValue = typeof color === 'function' ? color(props) : color;
        const colorObj = tinycolor(colorValue);

        // Ignore invalid alpha values. Unlike SUI which logs the warning in a
        // __DEV__ environment just silently return the original color
        if (alpha > 1) {
            return colorObj.toRgbString();
        }

        // For negative alphas, use a value of 0 (transparent)
        if (alpha < 0) {
            return colorObj.setAlpha(0).toRgbString();
        }

        const origAlpha = colorObj.getAlpha() ?? 1;
        return colorObj.setAlpha(alpha * origAlpha).toRgbString();
    };
