import styled from 'styled-components';
import { customThemeVariables } from './customThemeVariables';

const Separator = styled.div.attrs(() => ({
    'data-test': 'separator',
}))`
    border-bottom: 1px solid ${customThemeVariables.sidebarPanelBorderColor};
`;

export default Separator;
