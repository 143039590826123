import * as React from 'react';
import styled, { withTheme } from 'styled-components';
import { pick, omit } from 'lodash';
import { toDimension } from '@splunk/visualizations-shared/style';
import SizeAwareWrapper from '@splunk/visualizations-shared/SizeAwareWrapper';
import SvgChoroplethDynamic, { AnimationProps } from './SvgChoroplethDynamic';
import VizStyleWrapper from '../../common/components/VizStyleWrapper';

const Container = styled.div<{
    height: number;
    width: number;
}>`
    position: relative;
    flex-direction: column;
    flex: 1;
    display: flex;
    ${props => toDimension(pick(props, ['width', 'height']))};
`;

const ChoroplethSvgWrapper = styled.div`
    min-height: 0px;
    flex: 1;
    position: relative;
`;

interface PureChoroplethSvgProps {
    width?: number;
    height?: number;
    svg: string;
    backgroundColor?: string;
    areaIds?: string[];
    areaColors?: string[];
    areaValues?: (string | number)[];
    animation?: AnimationProps;
    onAreaClick?: (...args: any[]) => void;
}

export const computeSvgDynamicProps = ({ ...props }) => {
    const data = {
        featureIDs: props.areaIds || [],
        values: props.areaValues || [],
        fill: props.areaColors || [],
    };
    // current implementation is limited to only setting the fill attribute
    const dataBinding = [
        {
            xPath: "'//*[@id=\"'+featureIDs[$i]+'\"]/@fill'", // fixme todo move to DO as "xPath: areadIds | prepefix(`//*[@id="`)| suffix(`"/@fill`) > "
            value: 'fill[$i]',
        },
    ];
    return {
        ...omit(props, ['areaIds', 'areaValues', 'areaColors']),
        data,
        dataBinding,
    };
};

const PureChoroplethSvg = ({ ...props }: PureChoroplethSvgProps) => {
    const { width, height, backgroundColor } = props;

    const renderVisualization = ({ width: containerWidth, height: containerHeight }) => {
        const svgProps = computeSvgDynamicProps({
            ...props,
            width: containerWidth,
            height: containerHeight,
        });
        return (
            <VizStyleWrapper backgroundColor={backgroundColor} dataTestKey="ChoroplethSvgWrapper">
                <SvgChoroplethDynamic {...svgProps} />
            </VizStyleWrapper>
        );
    };
    return (
        <Container width={width} height={height}>
            <ChoroplethSvgWrapper>
                <SizeAwareWrapper>
                    {containerDimension => renderVisualization(containerDimension)}
                </SizeAwareWrapper>
            </ChoroplethSvgWrapper>
        </Container>
    );
};

PureChoroplethSvg.defaultProps = {
    width: '100%',
    height: 250,
    onAreaClick: () => {},
};

export default withTheme(PureChoroplethSvg as any);
