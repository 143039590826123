const validDSL = new Set([
    'getField',
    'max',
    'min',
    'frameBySeriesIndexes',
    'frameBySeriesIndexRange',
    'frameBySeriesNames',
    'frameBySeriesNamesOrIndexes',
    'frameBySeriesTypes',
    'frameWithoutSeriesNames',
    'getRawValue',
    'getValue',
    'seriesByIndex',
    'seriesByName',
    'seriesByPrioritizedTypes',
    'seriesByType',
    'seriesByTypes',
    'setValue',
    'delta',
    'firstPoint',
    'lastPoint',
    'pointByIndex',
    'pointsByIndexes',
    'formatByType',
    'frame',
    'gradient',
    'matchValue',
    'multiFormat',
    'objects',
    'pick',
    'prefix',
    'prepend',
    'rangeValue',
    'type',
    'maxContrast',
    'setColorChannel',
]);

export default validDSL;
