import type {
    AbsoluteLayoutStructure,
    ConnectedLineItem,
    Port,
    AbsoluteBlockItem,
    Coordinate,
    HandleDirection,
} from '@splunk/dashboard-types';
import type { LineDirection, LayoutStructureState } from '../types';
import {
    updateBlockItemPosition,
    snapOffsetToXY,
    createOffset,
    isLineConnected,
    disconnectLine,
    connectLine,
} from './layoutUtils';

import { computeLineAbsPosition, handleSingleLineMove } from './lineUtils';

type Action =
    | {
          type: 'lineMove';
          payload: ConnectedLineItem;
      }
    | {
          type: 'lineDragStart';
          payload: ConnectedLineItem;
      }
    | {
          type: 'lineDrag';
          payload: {
              id: string;
              dir: LineDirection;
              absPos: Coordinate;
          };
      }
    | {
          type: 'lineConnect';
          payload: {
              lineId: string;
              lineDir: LineDirection;
              itemId: string;
              port: Port;
          };
      }
    | {
          type: 'lineDisconnect';
          payload: {
              lineId: string;
              lineDir: LineDirection;
          };
      }
    | {
          type: 'blockResize';
          payload: AbsoluteBlockItem;
      }
    | {
          type: 'blocksMove';
          payload: AbsoluteBlockItem[];
      }
    | {
          type: 'reset';
          payload: AbsoluteLayoutStructure;
      };

export const initializeLayoutStructureState = (
    layoutStructure: AbsoluteLayoutStructure
): LayoutStructureState =>
    layoutStructure.reduce((obj, item) => {
        // eslint-disable-next-line no-param-reassign
        obj[item.item] = { ...item };
        return obj;
    }, {} as LayoutStructureState);

export const reducer = (
    state: LayoutStructureState,
    action: Action
): LayoutStructureState => {
    switch (action.type) {
        case 'lineMove':
            return {
                ...state,
                [action.payload.item]: action.payload,
            };
        case 'lineDragStart':
            return {
                ...state,
                [action.payload.item]: action.payload,
            };
        case 'lineDrag': {
            const lineToDrag = state[action.payload.id] as ConnectedLineItem;
            return isLineConnected({
                line: lineToDrag,
                dir: action.payload.dir,
            })
                ? state
                : {
                      ...state,
                      [action.payload.id]: {
                          ...lineToDrag,
                          position: {
                              ...lineToDrag.position,
                              [action.payload.dir]: action.payload.absPos,
                          },
                      },
                  };
        }
        case 'lineConnect': {
            const lineToConnect = state[
                action.payload.lineId
            ] as ConnectedLineItem;
            return isLineConnected({
                line: lineToConnect,
                dir: action.payload.lineDir,
            })
                ? state
                : {
                      ...state,
                      [action.payload.lineId]: connectLine({
                          line: lineToConnect,
                          dir: action.payload.lineDir,
                          itemId: action.payload.itemId,
                          port: action.payload.port,
                      }),
                  };
        }
        case 'lineDisconnect': {
            const lineToDisconnect = state[
                action.payload.lineId
            ] as ConnectedLineItem;

            return isLineConnected({
                line: lineToDisconnect,
                dir: action.payload.lineDir,
            })
                ? {
                      ...state,
                      [action.payload.lineId]: disconnectLine({
                          line: lineToDisconnect,
                          dir: action.payload.lineDir,
                          absPos: computeLineAbsPosition({
                              layoutStructure: Object.values(state),
                              position: lineToDisconnect.position,
                          })[action.payload.lineDir],
                      }),
                  }
                : state;
        }
        case 'blockResize':
            return {
                ...state,
                [action.payload.item]: action.payload,
            };
        case 'blocksMove':
            return action.payload.reduce(
                (s, blockItem) => ({
                    ...s,
                    [blockItem.item]: blockItem,
                }),
                { ...state }
            );
        case 'reset':
            return initializeLayoutStructureState(action.payload);
        default:
            return state;
    }
};

interface UpdateLayoutStructureOnKeyboardMoveArgs {
    selectedLineItems: ConnectedLineItem[];
    selectedBlockItems: AbsoluteBlockItem[];
    layoutStructure: AbsoluteLayoutStructure;
    dir: HandleDirection;
    snap: boolean;
    gridSize: number;
}

export const updateLayoutStructureOnKeyboardMove = ({
    selectedLineItems,
    selectedBlockItems,
    layoutStructure,
    dir,
    snap,
    gridSize,
}: UpdateLayoutStructureOnKeyboardMoveArgs): AbsoluteLayoutStructure => {
    const updatedLines = selectedLineItems.map(({ item }) => {
        const offset = createOffset(dir, 1, 1);

        return handleSingleLineMove({
            layoutStructure,
            lineId: item,
            offset,
        });
    });

    const updatedBlocks = selectedBlockItems.map((blockItem) => {
        const { position } = blockItem;
        const snapOffset = snap
            ? snapOffsetToXY({
                  position,
                  offset: createOffset(dir, gridSize, gridSize),
                  gridWidth: gridSize,
                  gridHeight: gridSize,
                  spacing: 0,
                  padding: 0,
              })
            : createOffset(dir, 1, 1);
        return updateBlockItemPosition(blockItem, snapOffset);
    });

    const updatedItems = [...updatedLines, ...updatedBlocks];

    const updatedLayoutStructure = layoutStructure.map(
        (item) => updatedItems.find((line) => line.item === item.item) ?? item
    );

    return updatedLayoutStructure;
};
