import React from 'react';
import { _ } from '@splunk/ui-utils/i18n';
import SVG, {
    type SVGPropsOptionalViewBox,
} from '@splunk/react-icons/SVGEnterprise';

/* eslint-disable max-len */
export default function Download(
    props: SVGPropsOptionalViewBox
): ReturnType<typeof SVG> {
    return (
        <SVG screenReaderText={_('Download')} viewBox="0 0 24 24" {...props}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8.94008 2C8.11165 2 7.44008 2.67157 7.44008 3.5V9H5.47861C4.11314 9 3.45783 10.676 4.46119 11.6022L10.9824 17.6218C11.557 18.1521 12.4426 18.1521 13.0172 17.6217L19.5384 11.6022C20.5417 10.676 19.8864 9 18.5209 9H16.5553V3.5C16.5553 2.67157 15.8838 2 15.0553 2H8.94008ZM9.44008 11V4H14.5553V11H17.2421L11.9998 15.8391L6.75743 11H9.44008ZM4 20C3.44772 20 3 20.4477 3 21C3 21.5523 3.44772 22 4 22H20C20.5523 22 21 21.5523 21 21C21 20.4477 20.5523 20 20 20H4Z"
                fill="currentColor"
            />
        </SVG>
    );
}
