import { createElement } from 'react';

// legacy visualizations
import Table from '@splunk/dashboard-visualizations/Table';
import Area from '@splunk/dashboard-visualizations/Area';
import Bar from '@splunk/dashboard-visualizations/Bar';
import Bubble from '@splunk/dashboard-visualizations/Bubble';
import Column from '@splunk/dashboard-visualizations/Column';
import FillerGauge from '@splunk/dashboard-visualizations/FillerGauge';
import Line from '@splunk/dashboard-visualizations/Line';
import MarkerGauge from '@splunk/dashboard-visualizations/MarkerGauge';
import Pie from '@splunk/dashboard-visualizations/Pie';
import Punchcard from '@splunk/dashboard-visualizations/Punchcard';
import RadialGauge from '@splunk/dashboard-visualizations/RadialGauge';
import Scatter from '@splunk/dashboard-visualizations/Scatter';
import SingleValue from '@splunk/dashboard-visualizations/SingleValue';
import SingleValueIcon from '@splunk/dashboard-visualizations/SingleValueIcon';
import SingleValueRadial from '@splunk/dashboard-visualizations/SingleValueRadial';
import Image from '@splunk/dashboard-visualizations/Image';
import Markdown from '@splunk/dashboard-visualizations/Markdown';
import Ellipse from '@splunk/dashboard-visualizations/Ellipse';
import Rectangle from '@splunk/dashboard-visualizations/Rectangle';
import Text from '@splunk/dashboard-visualizations/Text';
import Choropleth from '@splunk/dashboard-visualizations/Choropleth';
import ChoroplethSvg from '@splunk/dashboard-visualizations/ChoroplethSvg';

import {
    withShowDrilldown,
    withCommonCapabilities,
    withUpdatedConfig,
} from './utils';

// these wrappers are needed because the with* HOCs mutate the viz properties that may override each other if we use the same component for more than one viz types.
const ChoroplethUs = (props) => createElement(Choropleth, props);
const ChoroplethWorld = (props) => createElement(Choropleth, props);
Object.keys(Choropleth).forEach((field) => {
    ChoroplethUs[field] = Choropleth[field];
    ChoroplethWorld[field] = Choropleth[field];
});

const visualizations = {
    'viz.area': withCommonCapabilities(Area),
    'viz.bar': withCommonCapabilities(Bar),
    'viz.bubble': withCommonCapabilities(Bubble),
    'viz.choropleth.svg': withCommonCapabilities(ChoroplethSvg),
    'viz.column': withCommonCapabilities(Column),
    'viz.ellipse': Ellipse,
    'viz.fillergauge': withCommonCapabilities(FillerGauge),
    'viz.geojson.us': withCommonCapabilities(ChoroplethUs),
    'viz.geojson.world': withCommonCapabilities(ChoroplethWorld),
    'viz.img': Image,
    'viz.line': withCommonCapabilities(Line),
    'viz.markdown': Markdown,
    'viz.markergauge': withCommonCapabilities(MarkerGauge),
    'viz.pie': withCommonCapabilities(Pie),
    'viz.punchcard': withCommonCapabilities(Punchcard),
    'viz.radialgauge': withCommonCapabilities(RadialGauge),
    'viz.rectangle': Rectangle,
    'viz.scatter': withCommonCapabilities(Scatter),
    'viz.singlevalue': withCommonCapabilities(SingleValue),
    'viz.singlevalueicon': SingleValueIcon,
    'viz.singlevalueradial': withCommonCapabilities(SingleValueRadial),
    'viz.table': withCommonCapabilities(Table),
    'viz.text': Text,
};

// drilldown deny list
const hideDrilldownList = [
    'viz.fillergauge',
    'viz.markergauge',
    'viz.punchcard',
    'abslayout.line',
    'viz.markdown',
];

Object.entries(visualizations).forEach(([key, Viz]) => {
    // Convert legacy viz config to platform viz config
    visualizations[key] = withUpdatedConfig(Viz, key);
    // apply showDrilldown to all visualizations except the ones in the denyList
    if (!hideDrilldownList.includes(key)) {
        visualizations[key] = withShowDrilldown(Viz);
    }
});

export default {
    visualizations,
};
