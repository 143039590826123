import React from 'react';
import { _ } from '@splunk/ui-utils/i18n';
import SVG, {
    type SVGPropsOptionalViewBox,
} from '@splunk/react-icons/SVGEnterprise';

/* eslint-disable max-len */
export default function Undo(
    props: SVGPropsOptionalViewBox
): ReturnType<typeof SVG> {
    return (
        <SVG screenReaderText={_('Undo')} viewBox="0 0 32 32" {...props}>
            <path
                fill="currentColor"
                d="M7.338 17c1.73-2.99 4.962-5 8.662-5 5.06 0 9.243 3.758 9.908 8.635l1.982-.27C27.092 14.51 22.074 10 16 10a11.988 11.988 0 0 0-10 5.365V11H4v8h8v-2H7.338z"
            />
        </SVG>
    );
}
