export enum VizCategory {
    SINGLE_VALUE = 'Single Value',
    TRENDS = 'Trends',
    COMPARISONS = 'Comparisons',
    TABLE = 'Table',
    GAUGE = 'Gauge',
    DISTRIBUTIONS = 'Distributions',
    CHOROPLETH = 'Choropleth Maps',
    SHAPES = 'Shapes',
    MISC = 'misc',
    FLOW = 'Flow',
    CORRELATION = 'Correlation',
    SPATIAL = 'Spatial',
}
