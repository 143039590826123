import { _ } from '@splunk/ui-utils/i18n';
import { isDynamicOption } from '@splunk/visualizations-shared/configUtils';

export const getDynamicGaugeColorEditor = ({ defaultContext, defaultPalettesConfig }) => ({
    label: _('Gauge'),
    option: 'gaugeColor',
    context: 'gaugeColorConfig',
    editor: 'editor.dynamicColor',
    showEditor: ({ options }) => isDynamicOption(options.gaugeColor),
    editorProps: {
        labelPosition: 'top',
        flyoutTitle: _('Dynamic coloring: gauge'),
        formatters: [
            {
                label: _('Ranges'),
                value: 'rangeValue',
                defaults: {
                    value: defaultContext.gaugeColorConfig,
                },
            },
        ],
        dataSelectors: [
            {
                label: _('Value'),
                value: 'value',
            },
        ],
        defaultPalettesConfig,
    },
});
