import { isEqual } from 'lodash';
import { createSelector, createSlice } from '@reduxjs/toolkit';
import type {
    SelectedItem,
    GlobalState,
    EditorSlice,
    DashboardJSON,
    SourceEditorFlyout,
    SidebarState,
    ActivePanel,
} from '@splunk/dashboard-types';
import resetStore from './resetStore';
import { updateDefinition } from './definition';
import { DEFAULT_SOURCE_FLYOUT_HEIGHT } from '../constants';
import { createVisualization } from '../sagas/sagaActions';

const filterInvalidSelectedItems = ({
    state,
    definition,
}: {
    state: EditorSlice;
    definition: DashboardJSON;
}) => {
    // check that the selected item is either in visualizations or in inputs
    const newSelectedItems = state.selectedItems.filter(
        ({ id }) => definition.visualizations?.[id] || definition.inputs?.[id]
    );

    if (!isEqual(newSelectedItems, state.selectedItems)) {
        return newSelectedItems;
    }

    // return same state if all were valid
    return state.selectedItems;
};

export const selectEditor = (state: GlobalState): EditorSlice => state.editor;

// this is an optimization to avoid re-renders for components that use selectedItems as prop
const noSelectedItems: SelectedItem[] = [];
const empty = {};
const defaultSidebarState = {
    activePanel: 'config' as ActivePanel,
    panelProps: {},
};

const initialState: EditorSlice = {
    selectedItems: noSelectedItems,
    showGridLines: true,
    sourceEditorFlyout: {
        // By default the expanded editor is closed
        visibility: 'closed',
        height: DEFAULT_SOURCE_FLYOUT_HEIGHT,
    },
    sidebarState: defaultSidebarState,
};

export const selectSelectedItems = createSelector(
    [selectEditor],
    (editor) => editor.selectedItems || noSelectedItems
);

export const selectSidebarState = createSelector(
    [selectEditor],
    (editor) => editor.sidebarState || empty
);

export const selectAreMultipleVizSelected = createSelector(
    [selectSelectedItems],
    (items) => items.length > 1
);

export const selectAreGridLinesEnabled = createSelector(
    [selectEditor],
    (editor) => editor.showGridLines
);

const selectSourceEditorFlyout = createSelector(
    [selectEditor],
    (editor) => editor.sourceEditorFlyout
);

export const selectSourceEditorFlyoutVisibility = (state: GlobalState) =>
    selectSourceEditorFlyout(state).visibility;

export const selectUncommittedSourceEditorFlyoutItem = (state: GlobalState) =>
    selectSourceEditorFlyout(state).uncommittedItem;

export const selectSourceEditorFlyoutHeight = (state: GlobalState) =>
    selectSourceEditorFlyout(state).height;

export const selectSourceEditorFlyoutItem = createSelector(
    [selectSourceEditorFlyout],
    (editor) => editor.item
);

export const selectSourceEditorFlyoutSchema = createSelector(
    [selectSourceEditorFlyout],
    (editor) => editor.schema
);

const selectVizId = (_state: GlobalState, vizId: string) => vizId;

type makeSelectIsSelectedReturn = (
    state: GlobalState,
    vizId: string
) => boolean;

export const makeSelectIsSelected = (): makeSelectIsSelectedReturn =>
    createSelector(
        [selectSelectedItems, selectVizId],
        (selectedItems, vizId) =>
            !!selectedItems.find((item) => item.id === vizId)
    );

const editorSlice = createSlice({
    name: 'editor',
    initialState,
    extraReducers(builder) {
        builder
            .addCase(resetStore, (state, action) => {
                const { editor, definition } = action.payload;
                if (editor) {
                    Object.assign(state, editor);
                }

                // if the definition changed, then verify that all currently selected items exist in the new definition
                if (definition) {
                    state.selectedItems = filterInvalidSelectedItems({
                        state,
                        definition,
                    });
                }
            })
            .addCase(updateDefinition, (state, action) => {
                const definition = action.payload;
                // if the definition changed, then verify that all currently selected items exist in the new definition
                if (definition) {
                    state.selectedItems = filterInvalidSelectedItems({
                        state,
                        definition,
                    });
                }
            })
            .addCase(createVisualization, (state) => {
                // When creating a visualization the newDataPanel could be shown
                // in which case the current flyout definition needs to be removed
                delete state.sourceEditorFlyout.item;
                delete state.sourceEditorFlyout.uncommittedItem;
            });
    },
    reducers: {
        updateSelectedItems(
            state,
            { payload: selectedItems }: { payload: SelectedItem[] }
        ) {
            if (!isEqual(state.selectedItems, selectedItems)) {
                state.selectedItems = selectedItems;

                // When no items (or multiple items) are selected, clear the expanded editor definition
                if (selectedItems.length !== 1) {
                    delete state.sourceEditorFlyout.item;
                }

                // Any time the selected item changes the uncommittedItem value should be cleared
                delete state.sourceEditorFlyout.uncommittedItem;
                if (!isEqual(defaultSidebarState, state.sidebarState)) {
                    state.sidebarState = defaultSidebarState;
                }
            }
        },
        updateSidebarState(
            state,
            { payload: sidebarState }: { payload: SidebarState }
        ) {
            if (!isEqual(sidebarState, state.sidebarState)) {
                state.sidebarState = sidebarState;
            }
        },
        showGridLines(state, { payload: showGridLines }: { payload: boolean }) {
            state.showGridLines = showGridLines;
        },
        setIsSourceEditorFlyoutOpen(state, { payload }: { payload: boolean }) {
            // ignore invalid payloads
            if (typeof payload === 'boolean') {
                state.sourceEditorFlyout.visibility = payload
                    ? 'open'
                    : 'closed';
            }
        },
        toggleSourceEditorFlyout(state) {
            // 'closed' --> 'open'
            // 'open' or 'collapsed' --> 'closed'
            state.sourceEditorFlyout.visibility =
                state.sourceEditorFlyout.visibility === 'closed'
                    ? 'open'
                    : 'closed';
        },
        toggleIsSourceEditorFlyoutCollapsed(state) {
            // when closed ignore this dispatch
            if (state.sourceEditorFlyout.visibility === 'closed') {
                return;
            }

            // when open switch between 'open' or 'collapsed' states
            state.sourceEditorFlyout.visibility =
                state.sourceEditorFlyout.visibility === 'collapsed'
                    ? 'open'
                    : 'collapsed';
        },
        setSourceEditorFlyoutHeight(state, { payload }: { payload: number }) {
            // Don't set a non-numeric value, a negative value, or a numeric value which is too large
            if (
                typeof payload === 'number' &&
                payload >= 0 &&
                payload <= Number.MAX_SAFE_INTEGER
            ) {
                state.sourceEditorFlyout.height = payload;
            }
        },
        setUncommittedSourceEditorFlyoutItem(
            state,
            { payload }: { payload: SourceEditorFlyout['uncommittedItem'] }
        ) {
            if (payload) {
                state.sourceEditorFlyout.uncommittedItem = payload;
            } else {
                delete state.sourceEditorFlyout.uncommittedItem;
            }
        },
        setSourceEditorFlyoutItem(
            state,
            { payload }: { payload: SourceEditorFlyout['item'] }
        ) {
            if (payload) {
                state.sourceEditorFlyout.item = payload;
            } else {
                delete state.sourceEditorFlyout.item;
            }
            // Any item change should clear uncommitted item definitions
            delete state.sourceEditorFlyout.uncommittedItem;
        },
        setSourceEditorFlyoutSchema(
            state,
            { payload }: { payload: SourceEditorFlyout['schema'] }
        ) {
            if (payload) {
                state.sourceEditorFlyout.schema = payload;
            } else {
                delete state.sourceEditorFlyout.schema;
            }
        },
        clearSourceEditorFlyoutItem(state) {
            delete state.sourceEditorFlyout.item;
            delete state.sourceEditorFlyout.uncommittedItem;
        },
    },
});

export const {
    clearSourceEditorFlyoutItem,
    setIsSourceEditorFlyoutOpen,
    setSourceEditorFlyoutHeight,
    setUncommittedSourceEditorFlyoutItem,
    setSourceEditorFlyoutItem,
    setSourceEditorFlyoutSchema,
    showGridLines,
    toggleIsSourceEditorFlyoutCollapsed,
    toggleSourceEditorFlyout,
    updateSelectedItems,
    updateSidebarState,
} = editorSlice.actions;
export default editorSlice.reducer;
