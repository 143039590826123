import { isPlainObject } from './typeGuards';

/**
 * @method isEmptyArray
 * @description Check if an unknown variable is an empty array
 * @param val Variable which may be an array with no elements or all `undefined` elements
 * @returns true if the parameter is an array with length 0 or an array in which all elements are `undefined`
 */
export const isEmptyArray = (val: unknown): boolean =>
    Array.isArray(val) && val.filter((v) => v !== undefined).length === 0;

/**
 * @method isEmptyObject
 * @description Check if an unknown variable is an empty plain object
 * @param val Variable which may be a keyless plain object
 * @returns true if parameter is record collection with no keys
 */
export const isEmptyObject = (val: unknown): boolean =>
    isPlainObject(val) && Object.keys(val).length === 0;

/**
 * @method isEmpty
 * @description Detect if an object is considered "empty" (see return info for what is empty)
 * @param {any} val Variable which may be an empty object
 * @returns boolean true if parameter is null, undefined, an empty string, an
 * object with no keys, an array with no DEFINED values (arrays with only `undefined`
 * will be considered empty), or a non-object+non-string (such as a function)
 */
export const isEmpty = (val: unknown): boolean =>
    val === null || // null
    typeof val === 'undefined' || // undefined
    (typeof val === 'string' && !val) || // Empty string
    (typeof val !== 'string' && typeof val !== 'object') || // Non-object/non-string (things such as functions)
    isEmptyArray(val) || // Empty array or array with only `undefined` elements
    isEmptyObject(val); // Record collection with no keys
