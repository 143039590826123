import { DataPrimitiveFormatter } from '../Formatter';
import { IDimension } from '../Dimensions';
import { DataFrame } from '../DataFrame';
import { DataSeries } from '../DataSeries';
import { DataType, isDataPrimitive } from '../DataPrimitive';
import { DataPoint } from '../DataPoint';
import { Helper } from './Helper';
import EncodingExecutor from '../EncodingExecutor';

/**
 * This formatter returns a new DataFrame made by combining its arguments.
 *
 * Example: frame(series1, series2, frame1). It is also possible to pass
 * DataPoint as argument, in which case the DataPoint is wrapped in DataSeries.
 *
 * ```js
 * <SampleViz
 *     context={{
 *         users: '> primary | seriesByName("users")',
 *         ids: '> primary | seriesByName("ids")'
 *     }}
 *     options={{
 *         frameOption1: '> frame(users, ids)' // returns [['Maurine', 'Jennings', 'Giuseppe'], ['1', '2', '3']]
 *         frameOption2: '> frame(frameOption1, ids)' // returns [['Maurine', 'Jennings', 'Giuseppe'], ['1', '2', '3'], ['1', '2', '3']]
 *     }}
 *     dataSources={{
 *         primary: {
 *             data: {
 *                 columns: [['Maurine', 'Jennings', 'Giuseppe'], ['1', '2', '3']],
 *                 fields: [{ name: 'users' }, { name: 'ids' }]
 *             }
 *         }
 *     }}
 * />
 * ```
 */
export class Frame implements DataPrimitiveFormatter<DataType, DataType> {
    private args: IDimension[] = [];

    constructor(...args: any[]) {
        // frame formatter must also support 'raw' coming form context so we rawTree as needed
        this.args = args.map(a =>
            isDataPrimitive(a) ? a : Helper.dataPrimitiveFromRaw(EncodingExecutor.rawTree(a))
        );
    }

    format(p: DataPoint): DataPoint;

    format(s: DataSeries): DataSeries;

    format(f: DataFrame): DataFrame;

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    format(ignored: IDimension): IDimension {
        let series = [];
        this.args.forEach(dataPrim => {
            if (DataSeries.isDataSeries(dataPrim)) {
                series.push(dataPrim);
            } else if (DataFrame.isDataFrame(dataPrim)) {
                series = series.concat(dataPrim.series);
            } else if (DataPoint.isDataPoint(dataPrim)) {
                series.push(new DataSeries([dataPrim]));
            } else {
                throw new Error(`frame formatter args incorrect`);
            }
        });
        return new DataFrame(series);
    }
}
