import { baseInputSchema } from '../components/BaseInput';

export default {
    ...baseInputSchema,
    defaultValue: {
        description: 'Default value of the input when no value is specified',
        anyOf: [{ type: 'number' }, { pattern: '^>.*', type: 'string' }],
    },
    min: {
        description: 'The smallest allowable value.',
        anyOf: [{ type: 'number' }, { pattern: '^>.*', type: 'string' }],
    },
    max: {
        description: 'The largest allowable value.',
        anyOf: [{ type: 'number' }, { pattern: '^>.*', type: 'string' }],
    },
    step: {
        description:
            'The amount of increment and decrement applied by the buttons and arrow keys.',
        anyOf: [
            { type: 'number', default: 1, exclusiveMinimum: 0 },
            { pattern: '^>.*', type: 'string' },
        ],
    },
};
