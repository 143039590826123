import React, {
    cloneElement,
    useState,
    useCallback,
    useRef,
    useEffect,
    useMemo,
} from 'react';
import T from 'prop-types';
import { makeCancelable } from '@splunk/ui-utils/promise';
import { parse, defaultTimePreset } from '@splunk/dashboard-utils';
import { parseValue } from '../utils/helpers';

const parsePromise = (time) => Promise.resolve(parse(time));

const DefaultTimeRangeConnector = ({ children, value = null }) => {
    const { earliest, latest } = useMemo(() => parseValue(value), [value]);
    const [parseEarliest, setParseEarliest] = useState(parse(earliest));
    const [parseLatest, setParseLatest] = useState(parse(latest));
    const cancelableEarliestTimeParser = useRef(null);
    const cancelableLatestTimeParser = useRef(null);

    useEffect(() => {
        // cancel on unmount
        return () => {
            if (cancelableEarliestTimeParser.current) {
                cancelableEarliestTimeParser.current.cancel();
            }
            if (cancelableLatestTimeParser.current) {
                cancelableLatestTimeParser.current.cancel();
            }
        };
    }, []);

    const handleRequestParseEarliest = useCallback((time) => {
        if (cancelableEarliestTimeParser.current) {
            cancelableEarliestTimeParser.current.cancel();
        }
        // Wrap with a cancelable promise
        cancelableEarliestTimeParser.current = makeCancelable(
            parsePromise(time)
        );
        // Call set state when promise resolves
        cancelableEarliestTimeParser.current.promise
            .then((timeData) => {
                setParseEarliest(timeData);
            })
            .catch(() => undefined);
    }, []);

    const handleRequestParseLatest = useCallback((time) => {
        if (cancelableLatestTimeParser.current) {
            cancelableLatestTimeParser.current.cancel();
        }
        // Wrap with a cancelable promise
        cancelableLatestTimeParser.current = makeCancelable(parsePromise(time));
        // Call set state when promise resolves
        cancelableLatestTimeParser.current.promise
            .then((timeData) => {
                setParseLatest(timeData);
            })
            .catch(() => undefined);
    }, []);

    return cloneElement(React.Children.only(children), {
        presets: defaultTimePreset,
        parseEarliest,
        parseLatest,
        onRequestParseEarliest: handleRequestParseEarliest,
        onRequestParseLatest: handleRequestParseLatest,
        realTimeDisabled: true,
    });
};

DefaultTimeRangeConnector.propTypes = {
    children: T.element,
    value: T.string,
};

export default DefaultTimeRangeConnector;
