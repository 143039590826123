import { parse as baseParse, mergeEncoding } from '@splunk/visualization-encoding-parsers/Base';
import isEmpty from 'lodash/isEmpty';
import { ErrorMsgs } from './errorMsgs';

export const dataContract = {
    requiredDataSources: [
        {
            name: 'primary',
            description: 'DataSource that powers the visualization',
        },
    ],
    initialRequestParams: {
        primary: { offset: 0, count: 10000 },
    },
    encoding: {
        x: {
            isRequired: true,
            type: ['number', 'string'],
            default: 'primary[0]',
        },
        y: {
            isRequired: true,
            type: ['number', 'string'],
            default: 'primary[1]',
        },
        size: {
            isRequired: true,
            type: ['number'],
            default: 'primary[2]',
        },
        category: {
            isRequired: false,
            type: ['string'],
            default: 'primary[3]',
        },
    },
};

/**
 * Punchcard parse function
 * @param {object} dataSources
 * @param {object} userDefinedEncoding
 * @return {object} props that dashboard/react visualizations can be used.
 * @public
 */
export const parse = (dataSources, userDefinedEncoding) => {
    if (isEmpty(dataSources)) {
        throw new Error(ErrorMsgs.NO_DATA_SOURCES);
    }

    const encoding = mergeEncoding(dataContract.encoding, userDefinedEncoding);
    const parsedProps = baseParse(dataSources, encoding);
    let parsedSize = parsedProps.size;

    if (typeof parsedProps.size[0] === 'string') {
        parsedSize = parsedProps.size.map(sizeValue => {
            const parsedSizeNum = Number(sizeValue);
            if (Number.isNaN(Number(parsedSizeNum))) {
                throw new Error(ErrorMsgs.ERROR_PARSE);
            }
            return parsedSizeNum;
        });
    }

    return {
        x: parsedProps.x,
        y: parsedProps.y,
        size: parsedSize,
        category: parsedProps.category,
        xFieldName: parsedProps['_meta'].fieldNames.x, // eslint-disable-line dot-notation
        yFieldName: parsedProps['_meta'].fieldNames.y, // eslint-disable-line dot-notation
        categoryFieldName: parsedProps['_meta'].fieldNames.category, // eslint-disable-line dot-notation
        sizeFieldName: parsedProps['_meta'].fieldNames.size, // eslint-disable-line dot-notation
    };
};
