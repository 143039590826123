import React, { useCallback } from 'react';
import T from 'prop-types';
import styled from 'styled-components';
import pick from 'lodash/pick';
import isEmpty from 'lodash/isEmpty';
import { toDimension } from '@splunk/visualizations-shared/style';
import SizeAwareWrapper from '@splunk/visualizations-shared/SizeAwareWrapper';
import FeatureFlagContext from '@splunk/visualization-context/FeatureFlagContext';
import VizStyleWrapper from '../../common/components/VizStyleWrapper';
import { TrellisTitle, TRELLIS_TITLE_HEIGHT } from '../../common/components/TrellisTitle';
import { LAYOUT_TYPE } from '../../common/utils/layoutUtils';
import StandardLayout from './StandardLayout';
import {
    computeColors,
    computeSingleValueContent,
    shouldShowTrend,
    handleValueClickCallback,
} from '../../common/utils/singleValueUtils';
import {
    parseSparklineValues,
    shouldShowSparkline,
    isValidPosition,
    getSparklines,
    getSparklineColors,
    getUnderLabelHeight,
    getAvailableContentWidth,
    getAvailableContentHeight,
    VALID_SPARKLINE_POSITIONS,
} from './utils/displayUtils';

export const CONTENT_OVERFLOW_FADEOUT_AREA_PX = 16;
export const DEFAULT_CONTENT_HEIGHT_ALLOCATION_RATIO = 0.8;
const DEFAULT_SPARKLINE_SEVERITY_COLORS = {
    none: {
        background: '#818d99',
        stroke: '#171d21',
    },
    low: {
        background: '#275126',
        stroke: '#FFFFFF',
    },
    guarded: {
        background: '#002b3e',
        stroke: '#FFFFFF',
    },
    elevated: {
        background: '#7d600f',
        stroke: '#FFFFFF',
    },
    high: {
        background: '#79401a',
        stroke: '#FFFFFF',
    },
    severe: {
        background: '#6f261d',
        stroke: '#FFFFFF',
    },
};

const Container = styled.div`
    overflow: hidden;
    ${props => toDimension(pick(props, ['width', 'height']))};
`;

const SingleValue = ({ ...props }) => {
    const {
        width,
        height = 250,
        hasEventHandlers,
        style,
        majorFontSize,
        majorValue,
        majorValueField,
        trendValue,
        trendFontSize,
        unitPosition,
        underLabelFontSize,
        numberPrecision, // fixme todo probably belongs in a DSL formatter
        shouldUseThousandSeparators, // fixme todo probably belongs in a DSL formatter
        shouldAbbreviateTrendValue, // fixme todo probably belongs in a DSL formatter
        sparklineValues,
        sparklineHighlightSegments, // fixme this is not a pure viz if it has lots of rendering logic for a sub-viz.
        sparklineHighlightDots,
        showSparklineAreaGraph,
        showSparklineTooltip,
        shouldSparklineAcceptNullData,
        onValueClick,
        defaultFontColor,
        defaultBackgroundColor,
        defaultBlockFontColor,
        sparklineSeverityColors = DEFAULT_SPARKLINE_SEVERITY_COLORS,
        sparklineAreaColor,
        defaultThemeColors,
        splitByLayout,
        trellisKey = '',
        trellisSplitBy = '',
    } = props;
    const defaultColors = {
        defaultFontColor,
        defaultBackgroundColor,
        defaultBlockFontColor,
        sparklineSeverityColors,
    };

    const featureFlagContext = React.useContext(FeatureFlagContext);
    const isTrellisFeatureFlagOn = Boolean(
        featureFlagContext && featureFlagContext.visualizations_enableTrellis
    );
    const trellisEnabled = isTrellisFeatureFlagOn && splitByLayout === LAYOUT_TYPE.Trellis;
    const handleValueClick = useCallback(
        ev =>
            handleValueClickCallback(
                ev,
                majorValue,
                majorValueField,
                trendValue,
                onValueClick,
                trellisEnabled,
                trellisSplitBy,
                trellisKey
            ),
        [onValueClick, majorValue, majorValueField, trendValue, trellisEnabled, trellisSplitBy, trellisKey]
    );

    // FIXME todo 'compute colors' is not pure, it must be lifted into behavior or DSL
    // compute colors
    const { backgroundEnabled, backgroundColor, majorColor, trendColor, underLabelColor } = computeColors(
        props,
        defaultColors
    );

    // TODO(fkurniawan): possibly remove getSparklineColors, since for the new viz package,
    // the default is obtained from > themes.defaultSparklineStrokeColor
    const sparklineStrokeColor =
        props.sparklineStrokeColor ||
        getSparklineColors({
            backgroundEnabled,
            backgroundColor,
            sparklineSeverityColors,
        }).stroke;

    const renderVisualization = ({ width: containerWidth, height: containerHeight }) => {
        // will update later based on text size
        let { sparklineDisplay = 'below', trendDisplay = 'absolute', unit, underLabel } = props;

        // todo: need this function to maitain the behavior for dashboard-viz and react-viz.
        // can refactor it after we discard dashboard-viz and react-viz repo
        const parsedSparklineValues = parseSparklineValues({ sparklineValues });
        let hasSparkline = shouldShowSparkline({
            showSparkline: isValidPosition(sparklineDisplay) && sparklineDisplay !== 'off',
            sparklineValues: parsedSparklineValues,
        });

        // by default, the sparkline position is always below
        if (hasSparkline) {
            if (!isValidPosition(sparklineDisplay)) {
                sparklineDisplay = 'below';
            }
        }
        const isSparklinePositionBelow = hasSparkline && sparklineDisplay === 'below';
        const contentWidth = getAvailableContentWidth({
            hasSparkline,
            isSparklinePositionBelow,
            containerWidth,
            overflowArea: CONTENT_OVERFLOW_FADEOUT_AREA_PX,
        });

        // calculate major and trend text and font size
        const toRemoveList = [
            { key: 'sparklineDisplay', value: 'off' },
            { key: 'underLabel', value: '' },
            { key: 'trendDisplay', value: 'off' },
            { key: 'unit', value: '' },
        ];
        const {
            majorText,
            trendText,
            majorTextFontSize,
            trendTextFontSize,
            majorValueTitle,
            smallVizProps,
        } = computeSingleValueContent({
            majorValue,
            trendValue,
            majorFontSize,
            trendFontSize,
            trendDisplay,
            contentWidth,
            contentHeight: containerHeight * DEFAULT_CONTENT_HEIGHT_ALLOCATION_RATIO,
            containerWidth,
            containerHeight,
            unit,
            numberPrecision,
            shouldUseThousandSeparators,
            shouldAbbreviateTrendValue,
            toRemoveList,
        });
        if (!isEmpty(smallVizProps)) {
            ({ sparklineDisplay, trendDisplay, unit, underLabel } = smallVizProps);
            hasSparkline = shouldShowSparkline({
                showSparkline: isValidPosition(sparklineDisplay) && sparklineDisplay !== 'off',
                sparklineValues: parsedSparklineValues,
            });
        }

        const contentHeight = getAvailableContentHeight({
            height: containerHeight,
            hasSparkline,
            isSparklinePositionBelow,
            majorTextFontSize,
            underLabelHeight: getUnderLabelHeight({ underLabel, underLabelFontSize }),
        });

        // calculate sparkline size and positions
        const { sparklineWidth, sparklineHeight, sparklineSegments, endDots } = getSparklines({
            width: containerWidth,
            height: containerHeight,
            contentHeight,
            hasSparkline,
            isSparklinePositionBelow,
            sparklineHighlightSegments,
            sparklineHighlightDots,
        });

        return (
            <VizStyleWrapper backgroundColor={backgroundColor} style={style} dataTestKey="SingleValueWrapper">
                <StandardLayout
                    data-test="StandardLayout"
                    backgroundColor={backgroundColor}
                    contentHeight={contentHeight}
                    majorColor={majorColor}
                    majorText={majorText}
                    majorTextFontSize={majorTextFontSize}
                    majorValueTitle={majorValueTitle}
                    trendColor={trendColor}
                    trendText={trendText}
                    trendTextFontSize={trendTextFontSize}
                    showTrendIndicator={shouldShowTrend(trendDisplay)}
                    unit={unit}
                    unitPosition={unitPosition}
                    underLabel={underLabel}
                    underLabelFontSize={underLabelFontSize}
                    underLabelColor={underLabelColor}
                    onValueClick={hasEventHandlers && handleValueClick}
                    showSparkline={hasSparkline}
                    sparklinePosition={sparklineDisplay}
                    sparklineWidth={sparklineWidth}
                    sparklineHeight={sparklineHeight}
                    sparklineValues={parsedSparklineValues}
                    sparklineAreaColor={sparklineAreaColor}
                    sparklineStrokeColor={sparklineStrokeColor}
                    showSparklineTooltip={showSparklineTooltip}
                    showSparklineAreaGraph={showSparklineAreaGraph}
                    shouldSparklineAcceptNullData={shouldSparklineAcceptNullData}
                    sparklineSegments={sparklineSegments}
                    endDots={endDots}
                />
            </VizStyleWrapper>
        );
    };

    if (trellisEnabled) {
        return (
            <>
                <TrellisTitle
                    fontColor={defaultThemeColors?.defaultFontColor}
                    backgroundColor={backgroundColor}
                    trellisKey={trellisKey}
                />
                <Container width={width} height={height - TRELLIS_TITLE_HEIGHT}>
                    <SizeAwareWrapper>
                        {containerDimension => renderVisualization(containerDimension)}
                    </SizeAwareWrapper>
                </Container>
            </>
        );
    }

    return (
        <Container width={width} height={height}>
            <SizeAwareWrapper>
                {containerDimension => renderVisualization(containerDimension)}
            </SizeAwareWrapper>
        </Container>
    );
};

SingleValue.propTypes = {
    width: T.oneOfType([T.string, T.number]),
    height: T.oneOfType([T.string, T.number]),
    style: T.object,
    sparklineValues: T.arrayOf(T.oneOfType([T.string, T.number])),
    majorValue: T.oneOfType([T.string, T.number]),
    majorColor: T.string,
    majorFontSize: T.number,
    majorValueField: T.string,
    trendValue: T.oneOfType([T.string, T.number]),
    trendColor: T.string,
    trendFontSize: T.number,
    trendDisplay: T.oneOf(['absolute', 'percent', 'off']),
    shouldAbbreviateTrendValue: T.bool,
    backgroundColor: T.string,
    unit: T.string,
    unitPosition: T.string,
    underLabel: T.string,
    underLabelFontSize: T.number,
    numberPrecision: T.number,
    shouldUseThousandSeparators: T.bool,
    showSparklineTooltip: T.bool,
    showSparklineAreaGraph: T.bool,
    sparklineDisplay: T.oneOf(VALID_SPARKLINE_POSITIONS),
    sparklineAreaColor: T.string,
    sparklineStrokeColor: T.string,
    shouldSparklineAcceptNullData: T.bool,
    sparklineHighlightSegments: T.number,
    sparklineHighlightDots: T.number,
    hasEventHandlers: T.bool,
    onValueClick: T.func,
    defaultFontColor: T.string,
    defaultBackgroundColor: T.string,
    defaultBlockFontColor: T.string,
    sparklineSeverityColors: T.object,
    defaultThemeColors: T.object,
    splitByLayout: T.string,
    trellisKey: T.string,
    trellisSplitBy: T.string,
};

SingleValue.defaultProps = {
    width: '100%',
    height: 250,
    majorValue: 'N/A',
    majorValueField: '',
    trendDisplay: 'absolute',
    shouldAbbreviateTrendValue: false,
    unitPosition: 'after',
    underLabelFontSize: 12,
    numberPrecision: 0,
    shouldUseThousandSeparators: true,
    showSparklineTooltip: false,
    showSparklineAreaGraph: false,
    sparklineDisplay: 'below',
    shouldSparklineAcceptNullData: true,
    sparklineHighlightSegments: 0,
    sparklineHighlightDots: 0,
    hasEventHandlers: false,
    onValueClick: () => {},
    trellisKey: '',
    trellisSplitBy: '',
    // fixme todo: a pure viz should not be passed a map of severity colors! This should be resolved
    // by behaviors or DSL in future. Pure viz does not understand concepts such as severity.
};
export default SingleValue;
