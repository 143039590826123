import React from 'react';
import SVG, {
    type SVGPropsOptionalViewBox,
} from '@splunk/react-icons/SVGEnterprise';

/* eslint-disable max-len */
export default function Profile(
    props: SVGPropsOptionalViewBox
): ReturnType<typeof SVG> {
    return (
        <SVG viewBox="0 0 57 60" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path d="m28.5155 30c8.2843 0 15-6.7157 15-15 0-8.28427-6.7157-15-15-15-8.2842 0-15 6.71573-15 15 0 8.2843 6.7158 15 15 15zm-11.9562 7.4682c-.3659 0-.7248.1004-1.0376.2902l-13.5695 8.2342c-.59755.3626-.962454 1.0109-.962454 1.7098v11.2976c0 .5523.447714 1 1.000004 1h53.03575c.5523 0 1-.4477 1-1v-11.2985c0-.6985-.3643-1.3463-.9611-1.7091l-13.545-8.2332c-.3131-.1903-.6725-.2909-1.0389-.2909z" />
        </SVG>
    );
}
