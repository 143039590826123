import { useEffect } from 'react';

/**
 * useBeforeUnloadEvent - custom hook for enforcing clean up procedures before page unload
 * @param cleanupFn cleanup function to execute on unmount or before page unload
 */
export const useBeforeUnloadEvent = (cleanupFn: () => void) => {
    useEffect(() => {
        const runCleanup = cleanupFn;
        window.addEventListener('beforeunload', runCleanup);

        return () => {
            window.removeEventListener('beforeunload', runCleanup);
        };
    }, [cleanupFn]);
};
