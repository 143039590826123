import Button from '@splunk/react-ui/Button';
import ControlGroup from '@splunk/react-ui/ControlGroup';
import Modal from '@splunk/react-ui/Modal';
import Paragraph from '@splunk/react-ui/Paragraph';
import Select, { type SelectChangeHandler } from '@splunk/react-ui/Select';
import { exportVizAsPng, exportSearchDataAsCsv } from '@splunk/dashboard-utils';
import TextInput, { type TextChangeHandler } from '@splunk/react-ui/Text';
import { _ } from '@splunk/ui-utils/i18n';
import React, {
    useCallback,
    useMemo,
    useState,
    useRef,
    useEffect,
} from 'react';
import type {
    DashboardApi,
    InputDefinition,
    VisualizationDefinition,
    SearchData,
} from '@splunk/dashboard-types';
import { useTelemetryApi } from '@splunk/dashboard-telemetry';
import {
    useUserMessageAPI,
    useSearchContext,
    type SearchContextValue,
} from '@splunk/dashboard-context';
import WaitSpinner from '@splunk/react-ui/WaitSpinner';
import styled from 'styled-components';
import { generateId } from '@splunk/dashboard-definition';

const modalStyle = { width: '400px' };

enum ExportValues {
    PNG = 'png',
    CSV = 'csv',
}

const LoadingMessage = styled.div`
    display: flex;
    gap: 10px;
`;

const TRUNCATED_DATA_MESSAGE = `The exported CSV will only include %d rows due to application search limits.`;

interface Props {
    open: boolean;
    onModalClose: () => void;
    itemDefinition: NonNullable<InputDefinition | VisualizationDefinition>;
    dashboardApi: NonNullable<DashboardApi>;
    itemId: string;
    /**
     * @deprecated The createToast prop for generating user messages is deprecated. The userMessage API is preferred and will be used when createToast is not provided.
     */
    createToast?: (config: { message: string; type: string }) => void;
}

type UnsubscribeFn = ReturnType<SearchContextValue['createSearchAndSubscribe']>;

export const ExportVisualizationModal = ({
    open,
    onModalClose,
    itemDefinition,
    dashboardApi,
    itemId,
    createToast,
}: Props) => {
    const searchApi = useSearchContext();
    const telemetry = useTelemetryApi();
    const [filenameInput, setFilenameInput] = useState();
    const filenameInputRef = useCallback((el) => setFilenameInput(el), []);
    const userMessage = useUserMessageAPI();
    const searchUnsubscribeFnRef = useRef<UnsubscribeFn | null>(null);

    const [filename, setFilename] = useState(itemDefinition.title ?? '');
    const [exportValue, setExportValue] = useState(ExportValues.PNG);
    const [isLoading, setIsLoading] = useState(false);
    const searchData = useRef<SearchData | null>(null);
    const [searchDataWarning, setSearchDataWarning] = useState('');
    // use a ref to avoid adding deps
    const shouldFetchData = useRef(false);
    shouldFetchData.current =
        exportValue === ExportValues.CSV &&
        !searchUnsubscribeFnRef.current &&
        !searchData.current &&
        !isLoading;

    const handleErrorToast = useCallback(
        (message) => {
            telemetry.emit({
                pageAction: 'udf.export_viz',
                source: 'actionMenu',
                event: 'viz.export_failed',
                metadata: {
                    type: itemDefinition.type,
                    exportType: exportValue,
                },
                error: message,
            });

            if (createToast) {
                createToast({
                    type: 'error',
                    message,
                });
                return;
            }

            userMessage?.({ level: 'error', message });
        },
        [createToast, exportValue, itemDefinition.type, telemetry, userMessage]
    );

    const handleResults = useCallback(
        (data: SearchData = {}) => {
            // if there the search isn't completed, errored and is not a real-time search
            //   then wait for it to complete OR to return an error
            if (
                data.meta?.status !== 'done' &&
                !data.meta?.isRealTimeSearch &&
                !data.error
            ) {
                return;
            }
            setIsLoading(false);
            // cancel subscription because we only need to fetch once
            //  especially important for real-time searches!
            searchUnsubscribeFnRef.current?.();
            searchUnsubscribeFnRef.current = null;
            if (data.error) {
                handleErrorToast(data.error.message);
                return;
            }

            searchData.current = data;
            // check number of results returned vs totalCount and show warning
            const resultsCount = data?.data?.columns?.[0]?.length ?? 0;
            const totalCount = data?.meta?.totalCount ?? 0;
            // need to exclude real-time search because the `totalCount` does not accurately reflect the count being returned
            if (resultsCount < totalCount && !data?.meta?.isRealTimeSearch) {
                const message = TRUNCATED_DATA_MESSAGE.replace(
                    '%d',
                    resultsCount.toLocaleString()
                );
                setSearchDataWarning(message);
            } else {
                setSearchDataWarning('');
            }
        },
        [handleErrorToast]
    );

    useEffect(() => {
        // cancel subscription on component unmount
        return () => {
            searchUnsubscribeFnRef.current?.();
        };
    }, []);

    const fetchData = useCallback(() => {
        const dsId = itemDefinition?.dataSources?.primary ?? '';
        const consumerId = `${itemId}_${generateId('export')}`;
        // set loading to true. Must be before subscribe due to potential future async logic
        setIsLoading(true);

        const unsubscribe = searchApi.createSearchAndSubscribe({
            dsId,
            consumerId,
            bindingType: 'unbound',
            initialRequestParams: {},
            subscriberId: 'export-modal',
            onUpdate: handleResults,
        });

        searchUnsubscribeFnRef.current = unsubscribe;
    }, [
        handleResults,
        itemDefinition?.dataSources?.primary,
        itemId,
        searchApi,
    ]);

    useEffect(() => {
        if (open && shouldFetchData.current) {
            // if we're opening a previously closed modal that opens directly to CSV export
            fetchData();
        }

        if (!open) {
            // if the modal is being closed, clear any fetched data. Note that closing modal does not unmount
            searchUnsubscribeFnRef.current?.();
            searchUnsubscribeFnRef.current = null;
            searchData.current = null;
            setSearchDataWarning('');
            setIsLoading(false);
        }
    }, [fetchData, open]);

    const handleCloseModal = useCallback(() => {
        onModalClose();
    }, [onModalClose]);

    const handleExport = useCallback(async () => {
        try {
            switch (exportValue) {
                case ExportValues.CSV: {
                    if (!searchData.current) {
                        throw new Error(
                            _('Search data was not retrieved correctly')
                        );
                    }
                    await exportSearchDataAsCsv(searchData.current, filename);
                    break;
                }

                case ExportValues.PNG: {
                    const vizDomElement =
                        dashboardApi.getVisualizationDomElement(
                            itemId
                        ) as HTMLElement;

                    await exportVizAsPng({
                        vizDomNode: vizDomElement,
                        vizId: itemId,
                        vizType: itemDefinition.type,
                        scale: 1.5,
                        filename,
                    });
                    break;
                }

                default: {
                    break;
                }
            }

            telemetry.emit({
                pageAction: 'udf.export_viz',
                source: 'actionMenu',
                event: `viz.export_${exportValue}_success`,
                metadata: {
                    type: itemDefinition.type,
                    exportType: exportValue,
                },
            });
        } catch (error) {
            const { message } = error as Error;
            handleErrorToast(message);
        }

        // close modal at the end to ensure clean-up is done _after_ any downloading
        handleCloseModal();
    }, [
        handleCloseModal,
        exportValue,
        telemetry,
        itemDefinition.type,
        filename,
        dashboardApi,
        itemId,
        handleErrorToast,
    ]);

    const handleExportValueChange = useCallback<SelectChangeHandler>(
        (_e, { value }) => {
            setExportValue(value as ExportValues);
            // if there's no subscription running AND no data has been returned previously
            if (
                value === ExportValues.CSV &&
                !searchUnsubscribeFnRef.current &&
                !searchData.current
            ) {
                fetchData();
            }
        },
        [fetchData]
    );

    const handleFilenameChange = useCallback<TextChangeHandler>(
        (_e, { value }) => {
            setFilename(String(value));
        },
        []
    );

    const showDataSourceWarning = useMemo(() => {
        const itemDefSources = itemDefinition.dataSources ?? {};
        const hasMultipleDataSources = Object.keys(itemDefSources).length > 1;
        const csvOptionSelected = exportValue === ExportValues.CSV;
        return hasMultipleDataSources && csvOptionSelected;
    }, [itemDefinition.dataSources, exportValue]);

    return (
        <Modal
            open={open}
            onRequestClose={handleCloseModal}
            initialFocus={filenameInput}
            style={modalStyle}
        >
            <Modal.Header
                onRequestClose={handleCloseModal}
                title={_('Export visualization')}
            />
            <Modal.Body>
                <ControlGroup
                    data-test="file-name"
                    label={_('File name')}
                    labelPosition="top"
                >
                    <TextInput
                        inputRef={filenameInputRef}
                        onChange={handleFilenameChange}
                        value={filename}
                    />
                </ControlGroup>
                <ControlGroup
                    data-test="file-type"
                    label={_('File type')}
                    labelPosition="top"
                >
                    <Select
                        value={exportValue}
                        onChange={handleExportValueChange}
                    >
                        <Select.Option
                            label={_('PNG')}
                            value={ExportValues.PNG}
                        />
                        <Select.Option
                            label={_('CSV')}
                            value={ExportValues.CSV}
                        />
                    </Select>
                </ControlGroup>
                {showDataSourceWarning && (
                    <Paragraph data-test="data-source-warning">
                        {_(
                            'Exported CSV data will only include the primary data source.'
                        )}
                        {/* TODO: Add "Learn more" link for location strings - Ent: `dashboard.download`, SCP: `pdf.export` */}
                    </Paragraph>
                )}
                {exportValue === ExportValues.CSV && searchDataWarning && (
                    <Paragraph data-test="search-data-warning">
                        {searchDataWarning}
                    </Paragraph>
                )}
                {isLoading && exportValue === ExportValues.CSV && (
                    <LoadingMessage>
                        <WaitSpinner />
                        <Paragraph data-test="fetching-data-warning">
                            {_('Retrieving search data...')}
                        </Paragraph>
                    </LoadingMessage>
                )}
            </Modal.Body>
            <Modal.Footer>
                <Button label={_('Cancel')} onClick={handleCloseModal} />
                <Button
                    label={_('Export')}
                    appearance="primary"
                    onClick={handleExport}
                    data-test="export-btn"
                    disabled={isLoading && exportValue === ExportValues.CSV}
                />
            </Modal.Footer>
        </Modal>
    );
};

export default ExportVisualizationModal;
