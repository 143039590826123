import { cloneDeep } from 'lodash';
import { updateRemovedVizNeighbors } from '@splunk/dashboard-utils';
import type { AbsoluteBlockItem } from '@splunk/dashboard-types';

import { getContainingRowColumn } from './getContainingRowColumn';
import {
    handleHorizontalResizing,
    handleVerticalResizing,
} from './gridLayoutAutoResize';
import type { ReflowFn } from '../hooks';

type ItemMap = Record<string, AbsoluteBlockItem>;

/**
 * Create a deep clone of a source AbsoluteBlockItem array
 * @param {AbsoluteBlockItem[]} source source array to be cloned
 * @returns Deep-clone of source array
 */
const cloneLayoutStructure = (
    source: AbsoluteBlockItem[]
): AbsoluteBlockItem[] => {
    if (!source.length) {
        return [];
    }

    // This could be structuredClone to remove the lodash dep, but it would need a polyfill
    return cloneDeep(source);
};

/**
 * Create an object which maps the structure using AbsoluteBlockItem['item'] as
 * the key and the corresponding AbsoluteBlockItem as the value. This makes it
 * possible to store, access, and update block item data without array iteration
 * @param {AbsoluteBlockItem[]} structure The array of AbsoluteBlockItem objects to process
 * @returns {ItemMap} The generated block item mapping
 */
const createStructureMap = (structure: AbsoluteBlockItem[]): ItemMap =>
    Object.fromEntries(structure.map((item) => [item.item, item]));

/**
 * Calculate the width of a layout structure by calculating the left and right extremes
 * of the block item positions
 * @param {AbsoluteBlockItem[]} structure The block items to process
 * @returns {Number} The calculated difference between the right-most and left-most edges of the structure entries
 */
const getWidthFromStructure = (structure: AbsoluteBlockItem[]): number => {
    const [min, max] = structure.reduce(
        ([minX, maxX], { position: { x, w } }) => [
            // minX = either block item left or current minX (initial: MAX_SAFE_INTEGER)
            Math.min(minX, x),
            // maxX = either block item right or current maxX (initial: 0)
            Math.max(maxX, x + w),
        ],
        [Number.MAX_SAFE_INTEGER, 0]
    );

    // Return max - min or 0, whichever is larger (if no structure to be reduced then
    // max - min = MIN_SAFE_INTEGER which is not a desired return value, so use 0)
    return Math.max(max - min, 0);
};

/**
 * Remove and return the AbsoluteBlockItem from itemsToRemove which is in most top-left position.
 *
 * ⚠ This mutates the itemsToRemove argument ⚠
 * @param {AbsoluteBlockItem[]} arg0.itemsToRemove Array of AbsoluteBlockItem objects being removed from the layout
 * @param {ItemMap} arg0.itemMap A map of AbsoluteBlockItem['item'] :: AbsoluteBlockItem which contains up-to-date block item properties
 * @returns {AbsoluteBlockItem} The top-left most AbsoluteBlockItem from itemsToRemove.
 */
const popTopLeftBlock = ({
    itemsToRemove,
    itemMap,
}: {
    itemsToRemove: AbsoluteBlockItem[];
    itemMap: ItemMap;
}) => {
    let result = itemMap[itemsToRemove[0].item];
    let resultIdx = 0;
    itemsToRemove.forEach(({ item }, idx) => {
        const itemMapItem = itemMap[item];
        // Lower Y-Position = closer to top. Always take that.
        if (result.position.y > itemMapItem.position.y) {
            result = itemMapItem;
            resultIdx = idx;
            return;
        }

        // Equal Y-Position but lower X-Position = Closer to left. Take that.
        if (
            result.position.y === itemMapItem.position.y &&
            result.position.x > itemMapItem.position.x
        ) {
            result = itemMapItem;
            resultIdx = idx;
        }
    });

    // Splice the array to remove the result and then return it (the result)
    itemsToRemove.splice(resultIdx, 1);
    return result;
};

/**
 * Apply the data stored in itemMap to the structure array and remove itemToRemove
 * from both the structure array and itemMap object.
 *
 * ⚠ This mutates the structure and itemMap arguments ⚠
 * @param {AbsoluteBlockItem[]} arg0.structure The layout structure array to be updated
 * @param {ItemMap} arg0.itemMap The mapping object for all entries of the layout structure array
 * @param {AbsoluteBlockItem} arg0.itemToRemove The AbsoluteBlockItem to be removed from the structure
 * @param {ItemMap} arg0.updatesMap The mapping object for any updates to be applied to the layout structure array
 */
const applyUpdatesToStructure = ({
    structure,
    itemMap,
    itemToRemove,
    updatesMap,
}: {
    structure: AbsoluteBlockItem[];
    itemMap: ItemMap;
    itemToRemove: AbsoluteBlockItem;
    updatesMap: ItemMap;
}) => {
    let idxToRemove = -1;

    // Iterate the structure. If itemToRemove is found, track its index.
    // Else apply the update from updatesMap to both the structure and itemMap if defined.
    structure.forEach((item, idx) => {
        if (item.item === itemToRemove.item) {
            idxToRemove = idx;
        } else if (item.item in updatesMap) {
            /* eslint-disable no-param-reassign */
            structure[idx] = updatesMap[item.item];
            itemMap[item.item] = updatesMap[item.item];
            /* eslint-enable no-param-reassign */
        }
    });

    // If itemToRemove is found in the structure (it should be), then delete it
    if (idxToRemove >= 0) {
        structure.splice(idxToRemove, 1);
    }

    // Remove itemToRemove from itemMap
    // eslint-disable-next-line no-param-reassign
    delete itemMap[itemToRemove.item];
};

/**
 * Handle the removal of a layout item which cannot be handled through recursive
 * resizing of neighbors. This occurs when removing a full-row item or if something
 * goes wrong during an earlier step of the reflow algorithm.
 *
 * ⚠ This mutates the structure and itemMap arguments ⚠
 * @param {AbsoluteBlockItem[]} arg0.structure The layout structure array to be updated
 * @param {ItemMap} arg0.itemMap The mapping object for all entries of the layout structure array
 * @param {AbsoluteBlockItem} arg0.itemToRemove The AbsoluteBlockItem to be removed from the structure
 * @param {Number} arg0.width The width of the layout structure
 */
const baseCaseRemoval = ({
    structure,
    itemMap,
    itemToRemove,
    width,
}: {
    structure: AbsoluteBlockItem[];
    itemMap: ItemMap;
    itemToRemove: AbsoluteBlockItem;
    width: number;
}) => {
    // Get an array representing neighbor updates to be made
    const updates = updateRemovedVizNeighbors({
        items: structure,
        itemToRemove,
        width,
    });

    // Convert that array into an object to make processing easier
    const updatesMap = createStructureMap(updates);

    // Apply the updates from updateRemovedVizNeighbors onto the structure
    // and remove itemToRemove from the structure array
    applyUpdatesToStructure({
        structure,
        itemMap,
        itemToRemove,
        updatesMap,
    });
};

/**
 * Handle the recursive removal of items from the layout structure until
 * itemsToRemove is an empty array.
 *
 * ⚠ This mutates all received arguments ⚠
 * @param {AbsoluteBlockItem[]} arg0.structure The layout structure array to be updated
 * @param {AbsoluteBlockItem[]} arg0.itemsToRemove The array of all AbsoluteBlockItem objects to be removed from the structure
 * @param {ItemMap} arg0.itemMap The mapping object for all entries of the layout structure array
 */
const handleRecursiveItemRemoval = ({
    structure,
    itemsToRemove,
    itemMap,
}: {
    structure: AbsoluteBlockItem[];
    itemsToRemove: AbsoluteBlockItem[];
    itemMap: ItemMap;
}) => {
    if (itemsToRemove.length === 0) {
        return;
    }

    const itemToRemove = popTopLeftBlock({ itemsToRemove, itemMap });
    const container = getContainingRowColumn({
        structure,
        itemToContain: itemToRemove,
    });

    if (!container || container.content.items.length <= 1) {
        baseCaseRemoval({
            structure,
            itemMap,
            width: container?.gridWidth ?? getWidthFromStructure(structure),
            itemToRemove,
        });
    } else {
        const { width, height } = container.content;
        let updatesMap: ItemMap | undefined;

        if (itemToRemove.position.w === width) {
            // Item to remove spans the full width of the container, so vertical resizing is needed
            updatesMap = handleVerticalResizing({ container, itemToRemove });
        } else if (itemToRemove.position.h === height) {
            // Item to remove spans the full height of the container, so horizontal resizing is needed
            updatesMap = handleHorizontalResizing({ container, itemToRemove });
        } else {
            // Something went wrong, but don't break the process
            baseCaseRemoval({
                structure,
                width: container?.gridWidth ?? getWidthFromStructure(structure),
                itemToRemove,
                itemMap,
            });
        }

        if (updatesMap) {
            applyUpdatesToStructure({
                structure,
                itemMap,
                itemToRemove,
                updatesMap,
            });
        }
    }

    handleRecursiveItemRemoval({ structure, itemsToRemove, itemMap });
};

/**
 * Generate a new layout structure which has all itemsToRemove entries taken out
 * of the structure and any remaining content updated to fill the space originally
 * ued by the removed block items
 * @param {AbsoluteBlockItem[]} initialStructure The initial layout structure array to be processed
 * @param {AbsoluteBlockItem[]} itemsToRemove The array of all AbsoluteBlockItem objects to be removed from the structure
 * @returns {AbsoluteBlockItem[]} The updated layout structure with applied item removals
 */
export const gridLayoutShowHideReflow: ReflowFn = (
    initialStructure,
    itemsToRemove
) => {
    if (!itemsToRemove.length) {
        return initialStructure;
    }

    // Clone the initialStructure array so the parameter mutated
    const newStructure = cloneLayoutStructure(
        initialStructure as AbsoluteBlockItem[]
    );

    // Clone the itemsToRemove array so the parameter isn't mutated
    const clonedItemsToRemove = cloneLayoutStructure(
        itemsToRemove as AbsoluteBlockItem[]
    );

    // Create a Record<string, AbsoluteBlockItem> to make indexing easier
    const itemMap = createStructureMap(newStructure);

    // Update the newStructure array
    handleRecursiveItemRemoval({
        structure: newStructure,
        itemsToRemove: clonedItemsToRemove,
        itemMap,
    });

    return newStructure;
};
