import React from 'react';
import styled from 'styled-components';
import T from 'prop-types';
import { pick } from '@splunk/themes';

const ErrorOverlay = styled.div`
    position: absolute;
    box-sizing: border-box;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    pointer-events: none;
    background-color: ${pick({
        light: '#a81916cc',
        dark: '#a81916b3',
    })};
    color: white;
    overflow: hidden;
    padding: 8px;
    z-index: 2;
`;

export const MessageContainer = styled.div`
    padding-top: 8px;
`;

const OverlayTitle = styled.div`
    font-weight: bold;
`;

export const ErrorMessageOverlay = ({ messages }) => {
    if (!messages || messages.length === 0) {
        return null;
    }
    return (
        <ErrorOverlay data-test="error-overlay">
            <OverlayTitle>Layout Errors:</OverlayTitle>
            {messages.map((message) => (
                <MessageContainer
                    data-test="error-overlay-message"
                    key={message}
                >
                    {message}
                </MessageContainer>
            ))}
        </ErrorOverlay>
    );
};

ErrorMessageOverlay.propTypes = {
    messages: T.arrayOf(T.string),
};
