import React, { type JSXElementConstructor } from 'react';
import { once } from 'lodash';
import type {
    DashboardJSON,
    DashboardPlugin,
    Mode,
    TokenState,
    SelectedItem,
} from '@splunk/dashboard-types';
import { printHoistedPropError } from '@splunk/dashboard-utils';
import type { DashboardCoreProps } from '../types/DashboardCoreProps';

export type DeprecatedProps = Partial<{
    /**
     * @deprecated use initialDefinition on DashboardContextProvider
     */
    definition: DashboardJSON;
    /**
     * @deprecated use initialTokenBinding on DashboardContextProvider
     */
    tokenBinding: TokenState;
    /**
     * @deprecated use onTokenBindingChange on DashboardContextProvider
     */
    onTokenBindingChange: (tokens: TokenState) => void;
    /**
     * @deprecated use initialReadOnlyTokenNamespaces on DashboardContextProvider
     */
    readOnlyTokenNamespaces: Set<string>;
    /**
     * @deprecated use onDefinitionChange on DashboardContextProvider
     */
    onDefinitionChange: (def: DashboardJSON) => void;
    /**
     * @deprecated use initialMode on DashboardContextProvider
     */
    mode: Mode;
    /**
     * @deprecated use dashboardPlugin on DashboardContextProvider
     */
    dashboardCorePlugin: DashboardPlugin;
    /**
     * @deprecated use initialShowGrid on DashboardContextProvider
     */
    showGrid: boolean;
    /**
     * @deprecated use onItemsSelect on DashboardContextProvider
     */
    onItemsSelect: () => void;
    /**
     * @deprecated use initialSelectedItems on DashboardContextProvider
     */
    selectedItems: SelectedItem[];
}>;

const handlePropDeprecationMessages = once(
    <P extends DeprecatedProps>(props: P) => {
        // Hoisted props are now removed
        if (props.onTokenBindingChange) {
            printHoistedPropError({
                oldPropName: 'onTokenBindingChange',
                oldComponent: 'DashboardCore',
                provideSubject:
                    'a callback to be executed when token bindings change',
                newPropName: 'onTokenBindingChange',
            });
        }
        if (props.readOnlyTokenNamespaces) {
            printHoistedPropError({
                oldPropName: 'readOnlyTokenNamespaces',
                oldComponent: 'DashboardCore',
                provideSubject: 'an initial readOnlyTokenNamespaces',
                newPropName: 'initialReadOnlyTokenNamespaces',
                updateMessage: 'To update the value',
                updateMethod: 'replaceReadOnlyTokenNamespaces',
            });
        }
        if (props.definition) {
            printHoistedPropError({
                oldPropName: 'definition',
                oldComponent: 'DashboardCore',
                provideSubject: 'an initial dashboard definition',
                newPropName: 'initialDefinition',
                updateMessage:
                    'To update the definition to a value different than its initial value',
                updateMethod: 'replaceDefinition',
            });
        }
        if (props.tokenBinding) {
            printHoistedPropError({
                oldPropName: 'tokenBinding',
                oldComponent: 'DashboardCore',
                provideSubject: 'an initial tokenBinding',
                newPropName: 'initialTokenBinding',
                updateMessage: 'To update the token binding',
                updateMethod: 'replaceTokenBinding',
            });
        }
        if (props.onDefinitionChange) {
            printHoistedPropError({
                oldPropName: 'onDefinitionChange',
                oldComponent: 'DashboardCore',
                provideSubject:
                    'a callback to be executed when dashboard definition changes',
                newPropName: 'onDefinitionChange',
            });
        }
        if (props.mode) {
            printHoistedPropError({
                oldPropName: 'mode',
                oldComponent: 'DashboardCore',
                provideSubject: 'an initial mode',
                newPropName: 'initialMode',
                updateMessage: 'To update the value',
                updateMethod: 'switchMode',
            });
        }
        if (props.selectedItems) {
            printHoistedPropError({
                oldPropName: 'selectedItems',
                oldComponent: 'DashboardCore',
                provideSubject: 'an initial set of selected items',
                newPropName: 'initialSelectedItems',
            });
        }
        if (typeof props.showGrid !== 'undefined') {
            printHoistedPropError({
                oldPropName: 'showGrid',
                oldComponent: 'DashboardCore',
                provideSubject: 'whether to show grid lines in edit mode',
                newPropName: 'initialShowGrid',
                updateMessage: 'To update the value',
                updateMethod: 'toggleGridLines',
            });
        }
        if (props.onItemsSelect) {
            printHoistedPropError({
                oldPropName: 'onItemsSelect',
                oldComponent: 'DashboardCore',
                provideSubject:
                    'a callback to be executed when the selected items change',
                newPropName: 'onItemsSelect',
            });
        }
    }
);

export const withDeprecatedProps =
    (Component: JSXElementConstructor<DashboardCoreProps>) =>
    ({
        dashboardCoreApiRef,
        width,
        height,
        actionMenus,
        metadata,
        onValidationError,
        overrides,
        ...rest // deprecated props
    }: DashboardCoreProps) => {
        handlePropDeprecationMessages(rest as DeprecatedProps);

        return (
            <Component
                dashboardCoreApiRef={dashboardCoreApiRef}
                width={width}
                height={height}
                actionMenus={actionMenus}
                metadata={metadata}
                onValidationError={onValidationError}
                overrides={overrides}
            />
        );
    };
