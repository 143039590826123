import React, {
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import { noop } from '@splunk/dashboard-utils';
import styled from 'styled-components';
import { _ } from '@splunk/ui-utils/i18n';
import WaitSpinner from '@splunk/react-ui/WaitSpinner';
import { pick, variables } from '@splunk/themes';
import GalleryDeletionModal, {
    type GalleryDeletionModalProps,
} from './GalleryDeletionModal';

const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    padding: 5px;
    width: 515px;
    min-height: 200px;
    background-color: ${variables.backgroundColorDialog};
`;

const GalleryList = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    overflow: auto;
    padding: 5px 0px;
    width: 100%;
    height: 200px;
    border-bottom: ${pick({
        prisma: '1px solid #43454b',
        enterprise: '1px solid #c3cbd4',
    })};
`;

const spinnerStyle = { margin: 'auto' };

export type Item = {
    name: string;
    url: string;
};

export interface ItemProps {
    item: Item;
    selected?: boolean;
    onClick?: (itemUrl: Item['url']) => void;
    /**
     * TODO:
     * Icon/ImageGalleryItem both have defaultProps that set onRemove to noop if undefined so a falsy value must be passed.
     * On next pass to update the icon/image gallery code, fix the default props so this doesn't need to be done
     */
    onRemove?: ((item: Item) => void) | null;
    onMouseEnter?: (item: Item) => void;
    onMouseLeave?: (item: Item) => void;
}

export interface GalleryProps {
    testId?: string;
    isLoading?: boolean;
    message?: React.ReactNode;
    items?: Item[];
    Item: React.JSXElementConstructor<ItemProps>;
    search?: JSX.Element | null;
    upload?: React.ReactNode;
    onItemClick?: (itemUrl: Item['url']) => void;
    onItemRemove?: (item: Item, index?: number) => void;
    setHasChildModal?: (val: boolean) => void;
    galleryType?: 'image' | 'icon' | 'item';
}

const Gallery = ({
    isLoading = false,
    search = null,
    message = null,
    upload = null,
    items,
    Item,
    onItemRemove,
    onItemClick = noop,
    testId = 'gallery',
    setHasChildModal,
    galleryType = 'item',
}: GalleryProps) => {
    const hasItems = Array.isArray(items) && items.length > 0;
    const [selectedItem, setSelectedItem] = useState<string | null>(null);

    const [confirmationModalData, setConfirmationModalData] =
        useState<Omit<GalleryDeletionModalProps, 'testId'>>();
    const confirmationModalIsOpen = useRef(false);

    const searchRef = useRef<HTMLInputElement | null>(null);
    const searchWithRef = useMemo(() => {
        if (search) {
            return React.cloneElement(search, { ref: searchRef });
        }

        return null;
    }, [search]);

    const handleModalOpen = useCallback(() => {
        confirmationModalIsOpen.current = true;
        setHasChildModal?.(true);
    }, [setHasChildModal]);

    const handleModalClose = useCallback(() => {
        confirmationModalIsOpen.current = false;
        setHasChildModal?.(false);
        searchRef.current?.focus();
    }, [setHasChildModal]);

    // Callback to handle logic associated with the
    // opening and closing of the confirmation modal
    useEffect(() => {
        if (confirmationModalIsOpen.current && !confirmationModalData) {
            handleModalClose();
        } else if (!confirmationModalIsOpen.current && confirmationModalData) {
            handleModalOpen();
        }
    }, [confirmationModalData, handleModalOpen, handleModalClose]);

    const handleItemEnter = useCallback(
        (item) => setSelectedItem(item.url),
        [setSelectedItem]
    );

    const handleItemLeave = useCallback(
        () => setSelectedItem(null),
        [setSelectedItem]
    );

    const handleDeletionCancel = useCallback(() => {
        setConfirmationModalData(undefined);
    }, []);

    const handleObjectDeletion = useCallback(
        (item) => {
            if (onItemRemove) {
                onItemRemove(item);
            }

            handleDeletionCancel();
        },
        [onItemRemove, handleDeletionCancel]
    );

    const onRemove = useCallback(
        (item: Item) => () => {
            setConfirmationModalData({
                objectName: item.name,
                objectType: galleryType,
                handleObjectDeletion: () => handleObjectDeletion(item),
                handleDeletionCancel,
            });
        },
        [galleryType, handleDeletionCancel, handleObjectDeletion]
    );

    const galleryItems = useMemo(() => {
        if (isLoading || !hasItems) {
            return [];
        }

        return items.map((item) => (
            <Item
                key={item.url}
                item={item}
                selected={selectedItem === item.url}
                onMouseEnter={handleItemEnter}
                onMouseLeave={handleItemLeave}
                onRemove={onItemRemove ? onRemove(item) : null}
                onClick={onItemClick}
            />
        ));
    }, [
        hasItems,
        isLoading,
        items,
        selectedItem,
        handleItemEnter,
        handleItemLeave,
        onItemClick,
        onItemRemove,
        onRemove,
        Item,
    ]);

    if (confirmationModalData) {
        return (
            <GalleryDeletionModal
                testId={`${testId}-deletion-modal`}
                {...confirmationModalData}
            />
        );
    }

    return (
        <Container
            data-test={testId}
            data-test-loading={`${isLoading}`}
            tabIndex={-1}
        >
            {searchWithRef}
            {message}
            {isLoading && (
                <WaitSpinner
                    size="medium"
                    screenReaderText={_('loading')}
                    style={spinnerStyle}
                />
            )}
            {!isLoading && hasItems && (
                <GalleryList data-test="gallery-list">
                    {galleryItems}
                </GalleryList>
            )}
            {!isLoading && upload}
        </Container>
    );
};

export default Gallery;
