import { forEach, sum, isNumber, min as lodashMin, max as lodashMax, get, isEqual, isObject } from 'lodash';
import { LngLatBoundsLike } from 'maplibre-gl';
import { scalePow } from 'd3-scale';
import * as chroma from 'chroma-js';
import type { MapContextInterface, BaseTileConfig } from '@splunk/visualization-context/MapContext';
import { isColor } from '@splunk/visualizations-shared/colorUtils';
import { VIZ_CATEGORICAL } from '@splunk/visualization-color-palettes';
import { isNumber as isNumberUtil } from '@splunk/visualization-encoding/utils/types';
import type { GeoJsonDataType } from '@splunk/visualizations-shared/mapUtils';

export const MAX_LATITUDE = 90;
export const MIN_LATITUDE = -90;
export const MAX_LONGITUDE = 180;
export const MIN_LONGITUDE = -180;
export const DEFAULT_RESULT_LIMIT = 1000;
export const DEFAULT_CLUSTER_COLOR = '#909090';
export const DEFAULT_SERIES_COLORS = VIZ_CATEGORICAL;
// MapLibre requires a map style specification number which is always 8
export const MAP_STYLE_VERSION_NUMBER = 8;
// currently using one [min, max] value set for bubbles across all zoom levels
// TODO: design choices on [min, max] bubble size for different zoom levels
export const MIN_BUBBLE_RADIUS = 7.11; // 10px
export const MAX_BUBBLE_RADIUS = 45.26; // 85px
export const DEFAULT_OPACITY = 0.8;
const LONGITUDE = 'longitude';
const LATITUDE = 'latitude';

export enum LAYER_TYPE {
    Marker = 'marker',
    Bubble = 'bubble',
    Choropleth = 'choropleth',
}

interface GenericLayerType {
    type: string;
    latitude: number[];
    longitude: number[];
    dataColors?: string[];
    seriesColors: string[];
    additionalTooltipFields?: string[];
    resultLimit: number;
    metadata?: Array<{ [key: string]: number | string }>;
}

export interface MarkerLayerType extends GenericLayerType {
    type: LAYER_TYPE.Marker;
}

export interface BubbleLayerType extends GenericLayerType {
    type: LAYER_TYPE.Bubble;
    bubbleSize?: number[][] | number[];
    bubbleSizeFields: string[];
    geobin?: string[];
}

interface BubbleLayerDatumType {
    zoom: number;
    values: number[];
    sanitizedValues: number[];
    coordinates: number[];
}

export interface BubbleLayerDataType {
    data: BubbleLayerDatumType[];
    dataRange: number[];
    isPie: boolean;
    resultLimit: number;
    bubbleSizeFields: string[];
}

export interface ChoroplethLayerType {
    type: LAYER_TYPE.Choropleth;
    areaIds: string[];
    areaValues: number[];
    dataColors: string[];
    additionalTooltipFields?: string[];
    geom?: GeoJsonDataType[];
    resultLimit?: number;
    metadata?: Array<{ [key: string]: number | string | GeoJsonDataType }>;
    source?: string;
    width?: string | number;
    height?: string | number;
    choroplethEmptyAreaColor?: string;
    choroplethStrokeColor?: string;
    choroplethOpacity?: number;
}

// todo(@ssahnoune) - look into this
export interface MapLayerClickPayloadType {
    name?: string;
    value?: string | number;
    /* cell values of all cells of clicked row with the field and value - in shape row.<field>.value */
    [key: string]: string | number;
}

export type ClusterLayerType = BubbleLayerType | MarkerLayerType;
export type LayerType = ClusterLayerType | ChoroplethLayerType;
/**
 * Construct a tile preset {key: config} by combining the default + custom tile configs
 * Also returns the default tile config for each theme - light/dark
 * @param {MapContextInterface} args
 * @param {BaseTileConfig[]} args.defaultTileConfig
 * @param {BaseTileConfig[]} args.customTileConfig
 * @returns {Object} tile preset
 */
export const getTilePreset = ({
    defaultTileConfig = [],
    customTileConfig = [],
}: MapContextInterface): {
    tilePreset: Record<string, BaseTileConfig>;
    defaultTileLight: BaseTileConfig;
    defaultTileDark: BaseTileConfig;
} => {
    const tilePreset = {};
    let defaultTileLight = {};
    let defaultTileDark = {};
    const tileConfigs = [].concat(defaultTileConfig).concat(customTileConfig);
    forEach(tileConfigs, tileConfig => {
        const key = tileConfig?.key;
        const updatedConfig = { theme: 'all', ...tileConfig, version: MAP_STYLE_VERSION_NUMBER };
        tilePreset[key] = updatedConfig;
    });

    if (defaultTileConfig.length > 0) {
        defaultTileLight =
            defaultTileConfig.find(config => config.theme === 'light') ??
            defaultTileConfig.find(config => config.theme === undefined);
        defaultTileDark =
            defaultTileConfig.find(config => config.theme === 'dark') ??
            defaultTileConfig.find(config => config.theme === undefined);
    }

    return { tilePreset, defaultTileLight, defaultTileDark };
};

/**
 * Check if baseLayerTileServer is in tilePreset and config matches current theme, if yes, return.
 * If no, construct a mapStyle with baseLayerTilerServer and baseLayerTileServerType
 * Else return default tile config based on current theme
 * @param {Object} args
 * @param {BaseTileConfig[]} args.tilePreset
 * @param {String} args.baseLayerTilerServer
 * @param {String} args.baseLayerTileServerType
 * @param {String} args.colorScheme
 * @param {BaseTileConfig} args.defaultTileDark
 * @param {BaseTileConfig} args.defaultTileLight
 * @returns {BaseTileConfig} mapStyle
 */
export const getMapStyle = ({
    showBaseLayer,
    baseLayerTileServer = '',
    baseLayerTileServerType = '',
    tilePreset = {},
    colorScheme = 'light',
    defaultTileLight = {},
    defaultTileDark = {},
}: {
    showBaseLayer: boolean;
    baseLayerTileServer?: string;
    baseLayerTileServerType?: string;
    tilePreset?: Record<string, BaseTileConfig>;
    colorScheme?: string;
    defaultTileLight?: BaseTileConfig;
    defaultTileDark?: BaseTileConfig;
}): BaseTileConfig => {
    if (!showBaseLayer) {
        return {
            version: MAP_STYLE_VERSION_NUMBER,
            sources: {
                'base-tiles': {
                    type: 'raster',
                    tiles: [
                        'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=',
                    ],
                    tileSize: 256,
                },
            },
            layers: [
                {
                    id: 'base-layer',
                    type: 'raster',
                    source: 'base-tiles',
                    minzoom: 0,
                    maxzoom: 22,
                },
            ],
        };
    }

    if (
        baseLayerTileServer &&
        tilePreset[baseLayerTileServer] &&
        (tilePreset[baseLayerTileServer].theme === colorScheme ||
            tilePreset[baseLayerTileServer].theme === 'all')
    ) {
        return tilePreset[baseLayerTileServer];
    }

    if (baseLayerTileServer && baseLayerTileServerType) {
        let tiles = [];
        /**
         * Tile Server URLs (eg: http://{s}.somedomain.com/blabla/{z}/{x}/{y}{r}.png) can specify the variable {s} which refers to a subdomain
         * Subdomains are used to help with browser parallel requests per domain limitation. These subdomains are typically single letters 'a', 'b' and 'c'
         * Refer to https://leafletjs.com/SlavaUkraini/reference.html#tilelayer and https://wiki.openstreetmap.org/wiki/Tile_servers
         */
        if (baseLayerTileServer.match(/^(https?):\/\/{(s|S)}\../)) {
            /**
             * sXML (Leaflet) allows users to define their own tile server URLs + list of available subdomains
             * In order to preserve this behavior during conversion to splunk.map,
             * if the variable {s} or {S} is detected, replace it with 'a', 'b' and 'c' - default values for subdomains
             * TODO: in the future, add an additional prop - baseLayerTileServerSubdomains: ['x', 'y', 'z'], default being ['a', 'b', 'c']
             */
            tiles = [
                baseLayerTileServer.replace(/{(s|S)}/, 'a'),
                baseLayerTileServer.replace(/{(s|S)}/, 'b'),
                baseLayerTileServer.replace(/{(s|S)}/, 'c'),
            ];
        } else {
            tiles = [baseLayerTileServer];
        }
        // TODO: remove hardcoded style when we allow users to pass in layer style through Viz options
        const mapStyle = {
            version: MAP_STYLE_VERSION_NUMBER,
            sources: {
                'base-tiles': {
                    type: baseLayerTileServerType,
                    tiles,
                    tileSize: 256,
                },
            },
            layers: [
                {
                    id: 'base-layer',
                    type: 'raster',
                    source: 'base-tiles',
                    minzoom: 0,
                    maxzoom: 24, // https://maplibre.org/maplibre-gl-js-docs/api/map/
                },
            ],
        };
        return mapStyle;
    }

    // if tile server is not present in the preset AND we can't construct a mapStyle, fall back on defaults
    return colorScheme === 'light' ? defaultTileLight : defaultTileDark;
};

export interface ZoomConstraints {
    minZoom: number;
    maxZoom: number;
}

export const getZoomConstraintsFromTileConfig = (
    tileConfig: BaseTileConfig,
    baseLayerTileServer?: string
): ZoomConstraints => {
    const { sources, layers } = tileConfig;
    let matchedBaseLayerTileServer = false;
    // check sources for minzoom maxzoom first
    const sourceKeys = Object.keys(sources);
    for (let i = 0; i < sourceKeys.length; i += 1) {
        const { minzoom, maxzoom } = sources[sourceKeys[i]];
        if (baseLayerTileServer && sources[sourceKeys[i]].tiles.indexOf(baseLayerTileServer) !== -1) {
            matchedBaseLayerTileServer = true;
        }
        if (
            ((baseLayerTileServer && matchedBaseLayerTileServer) || !baseLayerTileServer) &&
            isNumber(minzoom) &&
            isNumber(maxzoom)
        ) {
            return {
                minZoom: minzoom,
                maxZoom: maxzoom,
            };
        }
    }
    // then check layers
    for (let i = 0; i < layers.length; i += 1) {
        const { minzoom, maxzoom } = layers[i];
        if (
            ((baseLayerTileServer && matchedBaseLayerTileServer) || !baseLayerTileServer) &&
            isNumber(minzoom) &&
            isNumber(maxzoom)
        ) {
            return {
                minZoom: minzoom,
                maxZoom: maxzoom,
            };
        }
    }
    // Maplibre default - would reset previous min/maxZoom https://maplibre.org/maplibre-gl-js-docs/api/map/
    return {
        minZoom: 0,
        maxZoom: 24,
    };
};

export const getZoomLevelFromGeobin = (geobinStr: string): number => {
    if (!geobinStr) {
        return null;
    }
    // geostats cmds return geobin with zoom level 0-18, 0-99 in the geobinPattern covers the range
    const geobinPattern = /bin_id_zl_([0-9]{0,2})?_y_/g;
    const match = geobinPattern.exec(geobinStr);
    return (match?.length && parseInt(match[1], 10)) ?? null;
};

// Bubble colors - static
export const getStaticColors = (colorCount: number, colorArray: string[]): string[] => {
    if (colorCount < 0 || !colorCount) {
        return [DEFAULT_CLUSTER_COLOR];
    }
    if (!colorArray || !colorArray.length) {
        return [...Array(colorCount).keys()].map(i => DEFAULT_SERIES_COLORS[i]);
    }
    // loop through colorArray to pick the first $colorCount colors
    // start from begining again if colorCount > colorArray.length
    return [...Array(colorCount).keys()].map(i =>
        isColor(colorArray[i % colorArray.length]) ? colorArray[i % colorArray.length] : DEFAULT_CLUSTER_COLOR
    );
};

// Bubble size
export const matchBubbleSize = (dataRange: number[], data: number): any => {
    const size = scalePow().exponent(0.2).domain(dataRange).range([MIN_BUBBLE_RADIUS, MAX_BUBBLE_RADIUS])(
        data
    );

    if (size > MAX_BUBBLE_RADIUS) {
        return MAX_BUBBLE_RADIUS;
    }
    if (size < MIN_BUBBLE_RADIUS) {
        return MIN_BUBBLE_RADIUS;
    }
    return size;
};

export const transposeArray = (array: number[][]): number[][] => {
    if (!array || !array.length || !array[0] || !array[0].length) {
        return [];
    }
    return array[0].map((_, i) => array.map(row => row[i]));
};

// sanitize values: update all null, undefined, or negative values to 0
export const sanitizeValues = (values: number[] | number[][]) => {
    // null / undefined
    if (!values) {
        return 0;
    }
    // number
    if (!Array.isArray(values)) {
        return values > 0 ? values : 0;
    }
    // array
    return values.map(arr => sanitizeValues(arr));
};

export const bakeBubbleData = (layer: BubbleLayerType): BubbleLayerDataType => {
    if (
        !layer ||
        !layer.bubbleSize?.length ||
        !layer.geobin ||
        !layer.geobin.length ||
        !layer.latitude ||
        !layer.latitude.length ||
        !layer.longitude ||
        !layer.longitude.length
    ) {
        return null;
    }
    // if bubbleSize is passed as an array of single number array (data series)
    // e.g. [value0, value1, ...]
    // format each value as an array to unify data operations for data series and frames
    const sanitizedBubbleSize = sanitizeValues(layer.bubbleSize);
    let transposedBubbleSize: number[][];
    let transposedSanitizedBubbleSize: number[][];
    let isPie = false;
    // format array if bubbleSize is passed as an array of array (data frame)
    if (Array.isArray(layer.bubbleSize[0])) {
        // one-time array transpose,
        // e.g. from [column0data, column0data] (multi series)
        // to [[column0datum0, column1datum0], [column0datum1, column1data1], ...]
        // or e.g. from [column0data] (single series)
        // to [[column0data]]
        // for easy access of values at each geo location,
        // and min max pie size computation
        transposedBubbleSize = transposeArray(layer.bubbleSize as number[][]);
        transposedSanitizedBubbleSize = transposeArray(sanitizedBubbleSize);
        // if multiple series in the dataframe, set isPie to true
        if (layer.bubbleSize.length > 1) {
            isPie = true;
        }
    } else {
        transposedBubbleSize = layer.bubbleSize.map(n => [n]);
        transposedSanitizedBubbleSize = sanitizedBubbleSize.map(n => [n]);
    }
    if (!transposedBubbleSize || !transposedBubbleSize.length) {
        return null;
    }
    const data = layer.latitude
        .map((lat, i) => ({
            zoom: getZoomLevelFromGeobin(layer.geobin[i]),
            values: transposedBubbleSize[i],
            sanitizedValues: transposedSanitizedBubbleSize[i],
            coordinates: [layer.longitude[i], lat],
            metadata: layer.metadata ? layer.metadata[i] : {},
        }))
        .filter(d => Boolean(d.values));

    // compute the min/max value to encode min/max pie or bubble size
    let min = sum(transposedSanitizedBubbleSize[0]);
    let max = sum(transposedSanitizedBubbleSize[1]);
    transposedSanitizedBubbleSize.forEach(row => {
        const bubbleSize = sum(row);
        max = bubbleSize > max ? bubbleSize : max;
        min = bubbleSize < min ? bubbleSize : min;
    });
    return {
        data,
        dataRange: [min, max],
        isPie,
        resultLimit: layer.resultLimit,
        bubbleSizeFields: layer.bubbleSizeFields,
    };
};

export const isValidLatLon = (latlon: Array<number | string>): boolean => {
    if (!latlon || !Array.isArray(latlon) || latlon.length !== 2) {
        return false;
    }
    const [lat, lon] = latlon;
    if (!isNumberUtil(lat) || !(lat >= MIN_LATITUDE && lat <= MAX_LATITUDE)) {
        return false;
    }
    if (!isNumberUtil(lon) || !(lon >= MIN_LONGITUDE && lon <= MAX_LONGITUDE)) {
        return false;
    }
    return true;
};

/**
 * Returns a list of all lat or lon coordinates used in a specified layer
 * @param {LayerType[]} layer
 * @param {string} field
 * @returns {number[]} list of lat coordinates or lon coordinates
 */
const getLatOrLonCoordinates = (layer: LayerType, field: string): number[] => {
    const getClusterValues = (clusterLayer: ClusterLayerType): number[] =>
        get(clusterLayer, field, []).slice(
            0,
            clusterLayer.type === LAYER_TYPE.Marker && clusterLayer.resultLimit
                ? clusterLayer.resultLimit
                : DEFAULT_RESULT_LIMIT
        );

    const getChoroplethValues = (choroplethLayer: ChoroplethLayerType): number[] => {
        const slicedGeomList = choroplethLayer.geom.slice(0, choroplethLayer.resultLimit);
        const flatCoordinates = slicedGeomList.flatMap(geomObject => geomObject.coordinates.flat(2));
        return flatCoordinates.map(coord => coord[field === LONGITUDE ? 0 : 1]);
    };

    return layer.type === LAYER_TYPE.Choropleth ? getChoroplethValues(layer) : getClusterValues(layer);
};

/**
 * Returns the min and max LongLat pairs to determine the layer boundaries to fit all markers/bubbles
 * @param {LayerType[]} layers
 * @returns {LngLatBoundsLike}
 */
export const getGlobalLayerBoundaries = (layers: LayerType[]): LngLatBoundsLike => {
    const isLayerValid = (field: string): boolean =>
        layers.every(layer => layer[field] || (layer as ChoroplethLayerType).geom);
    const getDefaultMax = (field: string) => (field === LONGITUDE ? MAX_LONGITUDE : MAX_LATITUDE);
    const getDefaultMin = (field: string) => (field === LONGITUDE ? MIN_LONGITUDE : MIN_LATITUDE);

    if (!layers || !Array.isArray(layers) || !layers.length) {
        return null;
    }

    const getMin = (field: string): number => {
        if (!isLayerValid(field)) {
            return getDefaultMin(field);
        }
        return layers.reduce((prevVal, layer) => {
            const coordinatesNumArr = getLatOrLonCoordinates(layer, field).map(x => +x);
            return lodashMin([prevVal, ...coordinatesNumArr]);
        }, getDefaultMax(field));
    };

    const getMax = (field: string): number => {
        if (!isLayerValid(field)) {
            return getDefaultMax(field);
        }
        return layers.reduce((prevVal, layer) => {
            const coordinatesNumArr = getLatOrLonCoordinates(layer, field).map(Number);
            return lodashMax([prevVal, ...coordinatesNumArr]);
        }, getDefaultMin(field));
    };

    return [
        [getMin(LONGITUDE), getMin(LATITUDE)],
        [getMax(LONGITUDE), getMax(LATITUDE)],
    ];
};

export const getScaledOpacities = (colors: string[], cfgOpacity: number): number[] => {
    // extract opacity information from 8 digit hex codes
    const opacities = colors?.map(color => (color?.length === 9 ? chroma(color).alpha() ?? 1 : 1));
    // opacity cannot be greater than configured opacity
    return opacities?.map(opacity => opacity * cfgOpacity);
};

export const compareBaseStyles = (curr, next): boolean => {
    const currBaseLayer = curr?.layers?.find(layer => layer?.id === 'base-layer');
    const nextBaseLayer = next?.layers?.find(layer => layer?.id === 'base-layer');
    const currBaseSource = get(curr?.sources, (currBaseLayer?.source as string) ?? '');
    const nextBaseSource = get(next?.sources, (nextBaseLayer?.source as string) ?? '');

    if (!currBaseLayer || !nextBaseLayer || !currBaseSource || !nextBaseSource) {
        return false;
    }

    return isEqual(currBaseLayer, nextBaseLayer) && isEqual(currBaseSource, nextBaseSource);
};

export const formatMetadata = (
    choroplethLayer: ChoroplethLayerType,
    i: number
): { [key: string]: string } => {
    if (!choroplethLayer || !choroplethLayer.areaIds || !choroplethLayer.areaValues) return {};
    if (i >= choroplethLayer.areaIds.length) return {};

    const metadata = choroplethLayer.metadata ? choroplethLayer.metadata[i] : {};
    const name = choroplethLayer.areaIds[i];
    const value = choroplethLayer.areaValues[i];
    const base = { name, value: String(value) };

    return Object.keys(metadata).reduce((result, curr) => {
        const key = `row.${curr}.value`;
        const val = isObject(metadata[curr]) ? JSON.stringify(metadata[curr]) : String(metadata[curr]);
        return { ...result, [key]: val };
    }, base);
};
