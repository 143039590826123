import React, {
    type ReactNode,
    useMemo,
    useRef,
    useCallback,
    type MutableRefObject,
    useEffect,
} from 'react';
import {
    type SearchContextValue,
    useSearchContext,
} from '@splunk/dashboard-context';
import DataSourceContext from '@splunk/visualization-context/DataSourceContext';
import type {
    BindingType,
    SearchData,
    DataSourceBindingMap,
} from '@splunk/dashboard-types';
import { useSubscribeToSearches, type DataSources } from './hooks';

interface OnSubscriberUpdateArgs extends SearchData {
    binding: BindingType;
}

type OnSubscriberUpdate = (args: OnSubscriberUpdateArgs) => void;
export interface DataSourceApi {
    subscribe: (
        bindingType: string,
        callback: OnSubscriberUpdate
    ) => () => void;
}

interface Props {
    consumerId: string;
    bindings: DataSourceBindingMap;
    children: ReactNode;
}

export type Subscribers = Record<string, OnSubscriberUpdate[]>;

export const DEFAULT_REQUEST_PARAMS = { count: 100, offset: 0 };

// a helper function to help testing the subscribe and unsubscribe
export const createSubscribe =
    (
        subscribers: MutableRefObject<Subscribers>,
        results: MutableRefObject<DataSources>
    ) =>
    (bindingType: string, callback: OnSubscriberUpdate): (() => void) => {
        if (!subscribers.current[bindingType]) {
            // eslint-disable-next-line no-param-reassign
            subscribers.current[bindingType] = [];
        }
        subscribers.current[bindingType].push(callback);

        // if data already exists for the subscription, push it to the sub
        const dataSource = results.current?.[bindingType];
        if (dataSource && dataSource.error) {
            callback({ binding: bindingType, error: dataSource.error });
        } else if (
            dataSource &&
            dataSource.data !== null &&
            dataSource.meta !== null &&
            dataSource.requestParams !== null
        ) {
            callback({
                binding: bindingType,
                data: dataSource.data,
                meta: dataSource.meta,
                requestParams: dataSource.requestParams,
            });
        }

        return () => {
            const idx = subscribers.current[bindingType].indexOf(callback);

            if (idx === -1) {
                return;
            }

            subscribers.current[bindingType].splice(idx, 1);
        };
    };

export const DataSourceContextProvider = ({
    consumerId,
    bindings,
    children,
}: Props): JSX.Element => {
    const subscribers = useRef<Subscribers>({});
    const results = useRef<DataSources>({});
    const searchApi = useSearchContext();

    const subscribeFn = useCallback<SearchContextValue['subscribe']>(
        ({ dsId, consumerId: id, bindingType, subscriberId, onUpdate }) => {
            return searchApi.createSearchAndSubscribe({
                dsId,
                consumerId: id,
                bindingType,
                subscriberId,
                onUpdate,
                initialRequestParams: DEFAULT_REQUEST_PARAMS,
            });
        },
        [searchApi]
    );

    const { dataSources } = useSubscribeToSearches({
        consumerId,
        bindings,
        subscribeFn,
    });

    useEffect(() => {
        Object.entries(dataSources).forEach(([binding, searchData]) => {
            const subs = subscribers.current[binding] ?? [];

            // update the subs by calling their callbacks
            subs.forEach((cb) => cb({ binding, ...searchData }));
        });
    }, [dataSources]);

    results.current = dataSources;

    const dataSourceApi = useMemo(() => {
        return {
            subscribe: createSubscribe(subscribers, results),
        };
    }, []);

    return (
        <DataSourceContext.Provider value={dataSourceApi}>
            {children}
        </DataSourceContext.Provider>
    );
};

export { default as DataSourceContext } from '@splunk/visualization-context/DataSourceContext';
