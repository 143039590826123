import React from 'react';
import T from 'prop-types';
import { _ } from '@splunk/ui-utils/i18n';
import SVG, {
    type SVGPropsOptionalViewBox,
} from '@splunk/react-icons/SVGEnterprise';

/* eslint-disable max-len */
export default function DropdownInput({
    screenReaderText,
    ...otherProps
}: SVGPropsOptionalViewBox): ReturnType<typeof SVG> {
    return (
        <SVG
            viewBox="0 0 24 24"
            screenReaderText={screenReaderText}
            {...otherProps}
        >
            <path
                d="M7.77904 9.00002C7.34633 9.00002 7.11792 9.51239 7.40715 9.83423L11.908 14.8426C12.1067 15.0637 12.4533 15.0636 12.6519 14.8425L17.1502 9.8341C17.4392 9.51223 17.2108 9 16.7782 9L7.77904 9.00002Z"
                fill="currentColor"
            />
        </SVG>
    );
}

DropdownInput.propTypes = {
    screenReaderText: T.string,
};

DropdownInput.defaultProps = {
    screenReaderText: _('Dropdown Input'),
};
