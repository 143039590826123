import * as React from 'react';
import { _ } from '@splunk/ui-utils/i18n';
import SVG from '@splunk/react-icons/SVGEnterprise';

const Area = (props: React.ComponentProps<typeof SVG>): React.ReactElement<SVGElement> => (
    <SVG screenReaderText={_('Area')} viewBox="0 0 18 14" {...props}>
        <path
            d="M17.3337 1.4905C17.3337 0.40624 16.0477 -0.163839 15.2443 0.564266L10.7797 4.61035L7.36815 0.787101L0.666992 7.48826V13.6668H17.3337V1.4905Z"
            fill="currentColor"
        />
    </SVG>
);

export default Area;
