import React from 'react';
import { _ } from '@splunk/ui-utils/i18n';
import SVG, {
    type SVGPropsOptionalViewBox,
} from '@splunk/react-icons/SVGEnterprise';

/* eslint-disable max-len */
export default function DownloadSolid(
    props: SVGPropsOptionalViewBox
): ReturnType<typeof SVG> {
    return (
        <SVG screenReaderText={_('Download')} viewBox="-3 -2 24 24" {...props}>
            <path
                d="M4.44032 1.5C4.44032 0.671574 5.1119 0 5.94032 0H12.0556C12.884 0 13.5556 0.671572 13.5556 1.5V7H15.5212C16.8867 7 17.542 8.67603 16.5386 9.6022L10.0174 15.6217C9.44285 16.1521 8.5572 16.1521 7.9826 15.6218L1.46143 9.60221C0.458078 8.67603 1.11338 7 2.47885 7H4.44032V1.5Z"
                fill="currentColor"
            />
            <path
                d="M0 19C0 18.4477 0.447715 18 1 18H17C17.5523 18 18 18.4477 18 19C18 19.5523 17.5523 20 17 20H1C0.447715 20 0 19.5523 0 19Z"
                fill="currentColor"
            />
        </SVG>
    );
}
