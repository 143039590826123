import type { VisualizationDefinition } from '@splunk/dashboard-types';
import config from '@splunk/visualizations/FillerGauge.config';
import type { EncodingConfig } from '../encoding';
import { encodingToDynamicOptionsDSL } from '../encoding';
import { consolidateDisplayOption } from '../utils/gauges';
import {
    preserveDataSelectionForValueOption,
    removeInvalidOptions,
    renameVizOptions,
} from '../utils/migrationUtils';

const validOptions = {
    ...config.optionsSchema,
};

const optionRenames = {
    majorUnit: 'majorTickInterval',
};

const previousRangeValueConfig = [
    { to: 10, value: '#5fbcff' },
    { from: 10, to: 30, value: '#4beba8' },
    { from: 30, to: 50, value: '#f4df7a' },
    { from: 50, to: 70, value: '#fc9850' },
    { from: 70, to: 100, value: '#ff7152' },
];

const defaultChanges = {
    gaugeColor: '> value | rangeValue(gaugeColorRangeValueContext)',
    value: '> primary | seriesByIndex(0) | lastPoint()',
};

export const migrateVizToSplunkFillerGauge = (
    vizDefinition: VisualizationDefinition
): VisualizationDefinition => {
    const { options = {}, encoding = {}, ...otherDefinitionParts } = vizDefinition;
    const migratedVisualization = {
        options,
        ...otherDefinitionParts,
        type: 'splunk.fillergauge',
        context: {},
    };

    const renamedOptions = renameVizOptions(options, optionRenames);

    const { options: encodingOptions, context: gaugeColorContext } = encodingToDynamicOptionsDSL(
        encoding as Record<string, EncodingConfig>
    );

    // if a viz.fillergauge receives an entire column for `value` (e.g. primary[0]), it will select the last value of the column
    // this contrasts with viz.markergauge (which takes the first point of the column)
    // this is due to differing behavior in the FillerGaugeParser, which explicitly retrieves the last point in a received array
    // https://cd.splunkdev.com/devplat/vision/-/blob/main/packages/visualization-encoding-parsers/src/FillerGaugeParser.js#L79
    const originalGaugeValue = encodingOptions.value;
    if (typeof originalGaugeValue === 'string') {
        encodingOptions.value =
            preserveDataSelectionForValueOption(originalGaugeValue, 'lastPoint()') ?? originalGaugeValue;
    }
    if (encodingOptions?.gaugeColor) {
        migratedVisualization.context = gaugeColorContext;
    } else {
        // preserve the default context if no gaugeColor encoding was configured
        migratedVisualization.context = {
            gaugeColorRangeValueContext: previousRangeValueConfig,
        };
    }

    const { showLabels, usePercentageRange, showValue, usePercentageValue } = options;
    const labelDisplay = consolidateDisplayOption(showLabels as boolean, usePercentageRange as boolean);
    const valueDisplay = consolidateDisplayOption(showValue as boolean, usePercentageValue as boolean);

    migratedVisualization.options = {
        ...defaultChanges,
        ...renamedOptions,
        ...encodingOptions,
        ...(typeof labelDisplay === 'string' && { labelDisplay }),
        ...(typeof valueDisplay === 'string' && { valueDisplay }),
    };

    // remove invalid options using the optionsSchema for splunk.fillergauge as reference
    removeInvalidOptions(migratedVisualization.options, validOptions);

    return migratedVisualization;
};
