import * as chroma from 'chroma-js';
import { get } from 'lodash';
import { AbstractFormatter } from '../Formatter';
import { TypedValue } from '../DataPrimitive';
import { DataPoint } from '../DataPoint';
import EncodingExecutor from '../EncodingExecutor';

export interface SetColorChannelConfig {
    channel: string;
    value: string | number;
}

/**
 * SetColorChannel can be used to modify a given color.
 *
 * ```js
 * <SampleViz
 *     context={{
 *          rowBGConfig: {
 *              channel: 'hsl.l',
 *              value: '*0.2'
 *          },
 *          headerBackgroundColor: {
 *              channel: 'hsv.v',
 *              value: '*0.66'
 *          }
 *     }}
 *     options={{
 *         backgroundColor: 'orangered',
 *         rowBackgroundColor: '> backgroundColor | setColorChannel(rowBGConfig)',   // #330e00
 *         headerColor: '> backgroundColor | setColorChannel(headerColorConfig)',    // #a82e00
 *     }}
 *     dataSources={{
 *         primary: {
 *             data: {
 *                 fields: [{ name: 'foo' }]
 *                 columns: [[100, 200]]
 *             }
 *         }
 *     }}
 * />
 * ```
 * Leverages [Chromajs.set](https://gka.github.io/chroma.js/#color-set) to change the color
 * 
 * ## Config Object
 *  * **channel**: 'string' refers to channel of the color. For ex: 'hsl.l' or 'hsl.s' or 'rgb.b'
 *  * **value**: 'string' or 'number'. Can be used to set absolute. For ex: setColorChannel({channel: 'hsl.h', value: 0}). Can be used as relative. For ex: setColorChannel({channel: 'lab.l', value: '*0.5'})

 *  
 */
export class SetColorChannel extends AbstractFormatter<'color', 'color'> {
    private config: SetColorChannelConfig;

    constructor(config: SetColorChannelConfig) {
        super();
        this.config = EncodingExecutor.rawTree(config);
    }

    protected formatTypedValue(p: DataPoint<'color'>): TypedValue<'color'> {
        const { value, type } = p.getValue();
        if (type !== 'color') {
            return { type, value };
        }
        const channel = get(this, 'config.channel');
        const v = get(this, 'config.value');
        if (!channel || value == null) {
            console.warn(
                'SetColorChannel requires channel (ex: hsv.v, rgb.r) and value configuration to be set. It will return the same color when missing.'
            );
        }
        try {
            return {
                type: 'color',
                value: chroma(value.toString()).set(channel, v).hex(),
            };
        } catch (error) {
            console.warn(error);
            return { type: 'color', value };
        }
    }
}
