import { flatten, isEqual, memoize, pick, values } from 'lodash';
import { DashboardDefinition } from '@splunk/dashboard-definition';
import {
    DEFAULT_CANVAS_HEIGHT,
    DEFAULT_CANVAS_WIDTH,
} from '@splunk/dashboard-utils';
import { createSelector, createSlice } from '@reduxjs/toolkit';
import type { OutputParametricSelector, PayloadAction } from '@reduxjs/toolkit';
import type {
    DashboardDefaultsDefinition,
    DashboardJSON,
    DataSourceDefinition,
    GlobalState,
    InputDefinition,
    LayoutDefinition,
    LayoutItemType,
    RootDataSourcesDefinition,
    RootInputsDefinition,
    RootVisualizationsDefinition,
    StructureItem,
    TokenState,
    VisualizationDefinition,
} from '@splunk/dashboard-types';
import { selectSubmittedTokens } from './tokens';
import {
    replaceTokenForVisualization,
    replaceTokenForDataSources,
    replaceTokenForLayout,
    replaceTokenForInput,
    memoizeResolver,
    replaceTokenForLayoutIncludingBackgroundImage,
} from '../utils/token';

// Sub-slices
import {
    updateVisualization,
    updateVisualizationReducer,
    removeDataSourceFromVisualization,
    removeDataSourceFromVisualizationReducer,
    updateVizOptions,
    updateVizOptionsReducer,
    updateVizTitle,
    updateVizTitleReducer,
    updateVizDescription,
    updateVizDescriptionReducer,
} from './visualizations';
import {
    updateInputStructure,
    updateInputStructureReducer,
    updateLayoutOptions,
    updateLayoutOptionsReducer,
    updateLayoutOptionDisplay,
    updateLayoutOptionDisplayReducer,
    updateLayoutStructure,
    updateLayoutStructureReducer,
} from './layout';
import { updateInput, updateInputReducer } from './inputs';
import resetStore from './resetStore';

const empty = Object.freeze({});
const emptyStructure: readonly StructureItem[] = Object.freeze([]);
const emptyDefaults: DashboardDefaultsDefinition = empty;
// empty needs to be cast as LayoutDefinition as it does not include type
const emptyLayout = empty as LayoutDefinition;
const emptyRootViz: RootVisualizationsDefinition = empty;
const emptyRootInputs: RootInputsDefinition = empty;
const emptyRootDS: RootDataSourcesDefinition = empty;

/**
 * Selectors
 */
export const selectDefinition = (state: GlobalState): DashboardJSON =>
    state.definition;

export const selectDefaults = createSelector(
    [selectDefinition],
    (def) => def.defaults ?? emptyDefaults
);

// Raw selectors retrieve parts of the definition without modifications
const selectLayoutRaw = createSelector(
    [selectDefinition],
    (def) => def?.layout ?? emptyLayout
);

export const selectLayoutDimensions = createSelector(
    [selectLayoutRaw],
    (layout) => ({
        width: layout.options?.width ?? DEFAULT_CANVAS_WIDTH,
        height: layout.options?.height ?? DEFAULT_CANVAS_HEIGHT,
    })
);

const selectVisualizationsRaw = createSelector(
    [selectDefinition],
    (def) => def.visualizations ?? emptyRootViz
);

const selectInputsRaw = createSelector(
    [selectDefinition],
    (def) => def.inputs ?? emptyRootInputs
);

const selectInputRaw = (state: GlobalState, id: string) =>
    selectInputsRaw(state)?.[id];

const selectDataSourcesRaw = createSelector(
    [selectDefinition],
    (def) => def.dataSources ?? emptyRootDS
);

const selectVisualizationRaw = (state: GlobalState, id: string) =>
    selectVisualizationsRaw(state)?.[id];

export const selectDataSourceNames = createSelector(
    [selectDataSourcesRaw],
    (dataSources) => {
        const existingDataSourceNames: Record<
            keyof RootDataSourcesDefinition,
            DataSourceDefinition['name']
        > = {};
        Object.keys(dataSources).forEach((key) => {
            if (typeof dataSources[key].name === 'string') {
                existingDataSourceNames[key] = dataSources[key].name;
            }
        });
        return existingDataSourceNames;
    }
);

export const selectLayout = createSelector(
    [selectLayoutRaw, selectVisualizationsRaw, selectInputsRaw],
    (layout, visualizations, inputs) => {
        // escape if this isn't a known UDF layout type, we can't assume custom layout structures are consistent
        if (layout.type !== 'absolute' && layout.type !== 'grid') {
            return layout;
        }

        const structure: StructureItem[] = layout.structure ?? emptyStructure;
        const items = new Set<string>();

        let hasDuplicate = false;
        // Remove items that are duplicated, or do not appear in visualizations OR inputs sections
        const updatedStructure = structure.filter((item) => {
            if (
                !items.has(item.item) &&
                (visualizations?.[item.item] || inputs?.[item.item])
            ) {
                items.add(item.item);
                return true;
            }

            hasDuplicate = true;
            return false;
        });

        if (hasDuplicate) {
            return {
                ...layout,
                structure: updatedStructure,
            };
        }

        // this is an optimization that avoids re-renders for components that use `layout` as prop
        return layout;
    }
);

export const selectLayoutType = createSelector(
    selectLayoutRaw,
    (layout) => layout.type
);

export const selectLayoutStructure = createSelector(
    [selectLayoutRaw],
    (layout): unknown => layout.structure
);

export const selectResolvedLayout = createSelector(
    [selectLayout, selectSubmittedTokens],
    replaceTokenForLayout
);

export const selectResolvedLayoutIncludingBackgroundImage = createSelector(
    [selectLayout, selectSubmittedTokens],
    replaceTokenForLayoutIncludingBackgroundImage
);

export const selectGlobalInputs = createSelector(
    [selectLayout],
    (layout) => layout.globalInputs
);

// Flatten only the viz you need instead of the entire definition
const selectFlattenedVisualization = createSelector(
    [selectVisualizationRaw, selectDefaults],
    (vizDef, defaults) => {
        // We don't actually need the original viz id, so just using "vizId" instead
        return DashboardDefinition.flattenVisualizations(
            { vizId: vizDef },
            defaults ?? {}
        ).vizId;
    }
);

// dataSources configs aren't flattened in viz, so we don't need the flattened def
const getDsValues = (def: VisualizationDefinition | InputDefinition) =>
    values(def?.dataSources || {});

const selectVizDSIds = createSelector([selectVisualizationRaw], getDsValues);

const selectInputDSIds = createSelector([selectInputRaw], getDsValues);

const getAllDsValues = (
    defs: RootVisualizationsDefinition | RootInputsDefinition
) => flatten(Object.values(defs).map(getDsValues));

export const selectAllVizDSIds = createSelector(
    [selectVisualizationsRaw],
    getAllDsValues
);

export const selectAllInputDSIds = createSelector(
    [selectInputsRaw],
    getAllDsValues
);

// a utility function to get all parent ids given a chain id
const getParentIds = ({
    dataSources,
    id,
}: {
    dataSources?: RootDataSourcesDefinition;
    id: string;
}): string[] => {
    if (
        typeof dataSources?.[id]?.options?.extend !== 'string' ||
        dataSources[id].type !== 'ds.chain'
    ) {
        return [id];
    }

    const parentId = dataSources?.[id]?.options?.extend as string;

    return [id, ...getParentIds({ dataSources, id: parentId })];
};

// Filter down to only the datasource definitions used by the visualization, and their parents in case they are chain data sources
const pickDsById = (dataSources: RootDataSourcesDefinition, dsIds: string[]) =>
    pick(
        dataSources,
        dsIds.flatMap((id: string) => getParentIds({ dataSources, id }))
    );

const selectDataSourcesForVizRaw = createSelector(
    [selectDataSourcesRaw, selectVizDSIds],
    pickDsById
);

const selectDataSourcesForInputRaw = createSelector(
    [selectDataSourcesRaw, selectInputDSIds],
    pickDsById
);

// Flatten only the datasources used by the visualization, and their parents in case they are chain data sources
const selectFlattenedDataSourcesForViz = createSelector(
    [selectDataSourcesForVizRaw, selectDefaults],
    DashboardDefinition.flattenDataSources
);

const selectFlattenedDataSourcesForInput = createSelector(
    [selectDataSourcesForInputRaw, selectDefaults],
    DashboardDefinition.flattenDataSources
);

/**
 * The following are factory methods to generate unique selectors for a visualization
 * Potential Improvement: selectSubmittedTokensUsedBy*
 * Note: replaceTokenFor* is memoized against used tokens already
 */
export const selectVisualizationDefinitionFactory =
    (): OutputParametricSelector<
        GlobalState,
        string,
        VisualizationDefinition,
        (
            res: VisualizationDefinition,
            res2: TokenState
        ) => VisualizationDefinition
    > =>
        createSelector(
            [selectFlattenedVisualization, selectSubmittedTokens],
            replaceTokenForVisualization
        );

export const selectInputDefinitionFactory = (): OutputParametricSelector<
    GlobalState,
    string,
    InputDefinition,
    (res: InputDefinition, res2: TokenState) => VisualizationDefinition
> =>
    createSelector(
        [selectInputRaw, selectSubmittedTokens],
        replaceTokenForInput
    );

export const selectItemDefinitionFactory = (itemType: LayoutItemType) =>
    itemType === 'block'
        ? selectVisualizationDefinitionFactory
        : selectInputDefinitionFactory;

// we only want to return the data sources used by the viz or input, NOT including their parents
const dsTokenizer = memoize(
    (
        dataSources: RootDataSourcesDefinition,
        tokens: TokenState,
        dsIds: string[]
    ) => pick(replaceTokenForDataSources(dataSources, tokens), dsIds),
    (dataSources, tokens, dsIds) =>
        memoizeResolver(dataSources, tokens) + JSON.stringify(dsIds)
);

export const selectDataSourceDefinitionForVizFactory =
    (): OutputParametricSelector<
        GlobalState,
        string,
        RootDataSourcesDefinition,
        (
            res: RootDataSourcesDefinition,
            res2: TokenState,
            res3: string[]
        ) => RootDataSourcesDefinition
    > =>
        createSelector(
            [
                selectFlattenedDataSourcesForViz,
                selectSubmittedTokens,
                selectVizDSIds,
            ],
            dsTokenizer
        );

export const selectDataSourceDefinitionForInputFactory =
    (): OutputParametricSelector<
        GlobalState,
        string,
        RootDataSourcesDefinition,
        (
            res: RootDataSourcesDefinition,
            res2: TokenState,
            res3: string[]
        ) => RootDataSourcesDefinition
    > =>
        createSelector(
            [
                selectFlattenedDataSourcesForInput,
                selectSubmittedTokens,
                selectInputDSIds,
            ],

            dsTokenizer
        );

export const selectDataSourceDefinitionFactory = (itemType: LayoutItemType) =>
    itemType === 'block'
        ? selectDataSourceDefinitionForVizFactory
        : selectDataSourceDefinitionForInputFactory;

/**
 * The following are not optimized but are kept for backwards compatibility
 * until all usage of them can be removed
 */
// this is an optimization to avoid re-renders for components that use dataSources as props
let dataSourcesWithDefaultsCache: RootDataSourcesDefinition;
export const selectDataSources = (
    state: GlobalState
): RootDataSourcesDefinition => {
    const { dataSources = emptyRootDS } = selectDefinition(state);
    const defaults = selectDefaults(state);
    const dataSourcesWithDefaults = DashboardDefinition.flattenDataSources(
        dataSources,
        defaults
    );
    if (!isEqual(dataSourcesWithDefaults, dataSourcesWithDefaultsCache)) {
        dataSourcesWithDefaultsCache = dataSourcesWithDefaults;
    }
    return dataSourcesWithDefaultsCache;
};

let visualizationsWithDefaultsCache: RootVisualizationsDefinition;
export const selectVisualizations = (
    state: GlobalState
): RootVisualizationsDefinition => {
    const { visualizations = emptyRootViz } = selectDefinition(state);
    const defaults = selectDefaults(state);
    const visualizationsWithDefaults =
        DashboardDefinition.flattenVisualizations(visualizations, defaults);
    if (!isEqual(visualizationsWithDefaults, visualizationsWithDefaultsCache)) {
        visualizationsWithDefaultsCache = visualizationsWithDefaults;
    }
    return visualizationsWithDefaultsCache;
};

export const selectDataSource = (
    state: GlobalState,
    dsId: string
): DataSourceDefinition | undefined => selectDataSources(state)[dsId];
export const selectVisualization = (
    state: GlobalState,
    vizId: string
): VisualizationDefinition | undefined => selectVisualizations(state)[vizId];
export const selectInputs = (
    state: GlobalState
): RootInputsDefinition | undefined => selectDefinition(state).inputs;
export const selectInput = (
    state: GlobalState,
    inputId: string
): InputDefinition | undefined => selectInputs(state)?.[inputId];
export const selectTitle = (state: GlobalState): string | undefined =>
    selectDefinition(state).title;

export const selectDataSourcesForViz = (
    state: GlobalState,
    id: string
): RootDataSourcesDefinition => {
    const viz = selectVisualization(state, id);
    const dataSourceIds = values(viz?.dataSources || {});
    return pick(selectDataSources(state), dataSourceIds);
};

export const selectDataSourcesForInput = (
    state: GlobalState,
    id: string
): RootDataSourcesDefinition => {
    const input = selectInput(state, id);
    const dataSourceIds = values(input?.dataSources || {});
    return pick(selectDataSources(state), dataSourceIds);
};

export const selectDataSourceDefinitions = createSelector(
    [selectDataSources, selectSubmittedTokens],
    replaceTokenForDataSources
);

const initialState: DashboardJSON = {};

const definitionSlice = createSlice({
    name: 'definition',
    initialState,
    extraReducers: (builder) => {
        builder
            // update a single viz definition
            .addCase(updateVisualization, updateVisualizationReducer)
            // remove datasource binding from visualization
            .addCase(
                removeDataSourceFromVisualization,
                removeDataSourceFromVisualizationReducer
            )
            // update just the options of a viz
            .addCase(updateVizOptions, updateVizOptionsReducer)
            // update just the viz title
            .addCase(updateVizTitle, updateVizTitleReducer)
            // update just the viz description
            .addCase(updateVizDescription, updateVizDescriptionReducer)
            // update the layout options
            .addCase(updateLayoutOptions, updateLayoutOptionsReducer)
            // update the layout option: 'display'
            .addCase(
                updateLayoutOptionDisplay,
                updateLayoutOptionDisplayReducer
            )
            // update the layout structure
            .addCase(updateLayoutStructure, updateLayoutStructureReducer)
            // update globalInputs
            .addCase(updateInputStructure, updateInputStructureReducer)
            // update input configuration
            .addCase(updateInput, updateInputReducer)
            // reset store
            .addCase(
                resetStore,
                (state, action) => action.payload.definition || state
            );
    },
    reducers: {
        // Replace the current definition entirely.
        update(_state, action: PayloadAction<DashboardJSON>) {
            // Use sparingly as this will likely cause all selectors to recompute
            return action.payload;
        },
    },
});

export default definitionSlice.reducer;
export const updateDefinition = definitionSlice.actions.update;
