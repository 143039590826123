/**
 * Controller caches the SPL query
 */
export default class RiskyCommandController {
    disableRiskyCheckList: Set<string>;

    constructor() {
        this.disableRiskyCheckList = new Set<string>();
    }

    /**
     * Check if a search query requires risky cmd verification
     * @param {String} query
     * @returns Boolean
     */
    shouldCheckRiskyCommand(query: string): boolean {
        return !this.disableRiskyCheckList.has(query);
    }

    /**
     * Add query to disableRiskyCheckList
     * @param {String} query
     */
    addToDisableCheckList(query: string): void {
        this.disableRiskyCheckList.add(query);
    }

    /**
     * Clean up the disableRiskyCheckList
     */
    clearDisableCheckList(): void {
        this.disableRiskyCheckList.clear();
    }
}
