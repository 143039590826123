import React, { type ReactNode } from 'react';
import styled from 'styled-components';
import ChevronDown from '@splunk/react-icons/enterprise/ChevronDown';
import ChevronUp from '@splunk/react-icons/enterprise/ChevronUp';
import Close from '@splunk/react-icons/enterprise/Close';
import Button, { type ButtonClickHandler } from '@splunk/react-ui/Button';
import Tooltip from '@splunk/react-ui/Tooltip';
import { useSplunkTheme } from '@splunk/themes';
import { _ } from '@splunk/ui-utils/i18n';

export const labels = {
    close: _('Close Pane'),
    collapse: _('Collapse Pane'),
    expand: _('Expand Pane'),
};

type ButtonProps = {
    dataTest: string;
    icon: ReactNode;
    tooltip: string;
    onClick: ButtonClickHandler;
};

export const ButtonGroup = styled.div.attrs(() => ({
    'data-test': 'flyout-header-button-group',
}))`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
`;

const IconButton = ({ dataTest, icon, tooltip, onClick }: ButtonProps) => {
    const { isPrisma } = useSplunkTheme();

    return (
        <Tooltip
            contentRelationship="label"
            content={tooltip}
            openDelay="primary"
        >
            <Button
                data-test={dataTest}
                appearance={isPrisma ? 'secondary' : 'pill'}
                icon={icon}
                onClick={onClick}
            />
        </Tooltip>
    );
};

const CloseButtonIcon = <Close screenReaderText={labels.close} />;
export const CloseButton = ({ onClick }: Pick<ButtonProps, 'onClick'>) => (
    <IconButton
        dataTest="flyout-header-close-btn"
        icon={CloseButtonIcon}
        tooltip={labels.close}
        onClick={onClick}
    />
);

const CollapseButtonIcon = <ChevronDown screenReaderText={labels.collapse} />;
export const CollapseButton = ({ onClick }: Pick<ButtonProps, 'onClick'>) => (
    <IconButton
        dataTest="flyout-header-collapse-btn"
        icon={CollapseButtonIcon}
        tooltip={labels.collapse}
        onClick={onClick}
    />
);

const ExpandButtonIcon = <ChevronUp screenReaderText={labels.expand} />;
export const ExpandButton = ({ onClick }: Pick<ButtonProps, 'onClick'>) => (
    <IconButton
        dataTest="flyout-header-expand-btn"
        icon={ExpandButtonIcon}
        tooltip={labels.expand}
        onClick={onClick}
    />
);
