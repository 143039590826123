import isEqual from 'lodash/isEqual';
import isString from 'lodash/isString';
import zip from 'lodash/zip';
import zipObject from 'lodash/zipObject';
import findIndex from 'lodash/findIndex';

/**
 * @file
 * DataSet represent a set of 2 dimensional data
 */
export default class DataSet {
    constructor(fields = [], columns = []) {
        this.fields = fields.map(field => {
            if (isString(field)) {
                return {
                    name: field,
                };
            }
            return field;
        });
        this.columns = columns;
    }

    /**
     * Returns a empty Dataset
     *
     * Examples:
     * ```js
     * const empty = DataSet.empty();
     * ```
     * @return {DataSet} DataSet
     * @public
     */
    static empty() {
        return new DataSet();
    }

    /**
     * Construct a Dataset with data in json array format
     *
     * Examples:
     * ```js
     *  const dataset = DataSet.fromJSONArray(
     *     [{ name: 'x' }, { name: 'y' }, { name: 'z' }],
     *     [{ x: 'a', y: 4, z: 70 }, { x: 'b', y: 5, z: 80 }, { x: 'c', y: 6, z: 90 }]
     *  );
     * ```
     * @return {DataSet} DataSet
     * @public
     */
    static fromJSONArray(fields = [], results = []) {
        let fieldList = fields;
        if (fieldList == null || fieldList.length === 0) {
            if (results.length > 0) {
                const rowSample = results[0];
                fieldList = Object.keys(rowSample).map(field => ({ name: field }));
            } else {
                fieldList = [];
            }
        }

        const columns = fieldList.map(({ name }) =>
            results.reduce((col, row) => {
                // if a field is not present on a result entry we set it to null
                col.push(row[name] === undefined ? null : row[name]);
                return col;
            }, [])
        );

        return new DataSet(fieldList, columns);
    }

    /**
     * Construct a Dataset with data in json columns format
     *
     * Examples:
     * ```js
     *  const dataset = DataSet.fromJSONCols(
     *     [{ name: 'x' }, { name: 'y' }, { name: 'z' }],
     *     [['a', 'b', 'c'], [4, 5, 6], [70, 80, 90]];
     *  );
     * ```
     * @return {DataSet} DataSet
     * @public
     */
    static fromJSONCols(fields = [], columns = []) {
        return new DataSet(fields, columns);
    }

    /**
     * Construct a Dataset with data in json rows format
     *
     * Examples:
     * ```js
     *  const dataset = DataSet.fromJSONRows(
     *     [{ name: 'x' }, { name: 'y' }, { name: 'z' }],
     *     [['a', 4, 70], ['b', 5, 80], ['c', 6, 90]];
     *  );
     * ```
     * @return {DataSet} DataSet
     * @public
     */
    static fromJSONRows(fields = [], rows = []) {
        return new DataSet(fields, zip(...rows));
    }

    /**
     * Convert data to json array
     * @return {Object} data in json array format
     * @public
     */
    toJSONArray() {
        return {
            fields: this.fields,
            results: zip(...this.columns).map(row =>
                zipObject(
                    this.fields.map(field => field.name),
                    row
                )
            ),
        };
    }

    /**
     * Convert data to json columns
     * @return {Object} data in json columns format
     * @public
     */
    toJSONCols() {
        const { fields, columns } = this;

        return { fields, columns };
    }

    /**
     * Convert data to json rows
     * @return {Object} data in json rows format
     * @public
     */
    toJSONRows() {
        return {
            fields: this.fields,
            rows: zip(...this.columns),
        };
    }

    /**
     * List all fields
     * @return {Object} fields array
     * @public
     */
    getFields() {
        return this.fields;
    }

    /**
     * List data columns
     * @return {Object} columns array
     * @public
     */
    getColumns() {
        return this.columns;
    }

    /**
     *
     * @param {String} fieldName
     * @return {Object} column data
     * @public
     */
    getColumnByField(fieldName) {
        const index = findIndex(this.fields, ({ name }) => name === fieldName);
        return this.columns[index];
    }

    /**
     *
     * @param {String} fieldName
     * @return {Boolean}
     * @public
     */
    hasField(fieldName) {
        return findIndex(this.fields, ({ name }) => name === fieldName) !== -1;
    }

    /**
     * @return {Boolean} true if DataSet has no data
     * @public
     */
    isEmpty() {
        return this.columns.length === 0;
    }

    /**
     *
     * @param {DataSet} dataSet DataSet to compare
     * @return {Boolean} true if another dataset is equals to current one
     * @public
     */
    equals(dataSet) {
        return isEqual(this.fields, dataSet.fields) && isEqual(this.columns, dataSet.columns);
    }
}
