import React, { useMemo, useEffect, useCallback } from 'react';
import type { Property } from 'csstype';
import { isEmpty } from 'lodash';
import styled from 'styled-components';
import type { useSortable } from '@dnd-kit/sortable';
import {
    ERROR_LEVEL_INFO,
    ERROR_LEVEL_ERROR,
    WAITING_FOR_INPUT_MSG,
    useSelector,
    selectInputValue,
    useDispatch,
    inputValueChanged,
} from '@splunk/dashboard-state';
import { type DataSources, sanitizeColor } from '@splunk/dashboard-ui';
import type { InputDefinition, InputAlignment } from '@splunk/dashboard-types';
import {
    usePreset,
    useApiRegistry,
    useEventRegistry,
} from '@splunk/dashboard-context';
import { useCanItemBeHidden } from '../hooks/useCanItemBeHidden';
import type { ItemContainerProps } from '../types/ItemContainer';

// Map the top/center/bottom alignment values to CSS justify-content property
const AlignmentToJustifyMap: {
    [key in InputAlignment]: Property.JustifyContent;
} = {
    top: 'start',
    center: 'center',
    bottom: 'end',
};

interface OnCanvasWrapperProps {
    alignment: InputAlignment;
    backgroundColor?: string;
}

const OnCanvasWrapper = styled.div.attrs(() => ({
    'data-test': 'input-canvas-wrapper',
}))<OnCanvasWrapperProps>`
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    justify-content: ${(props) => AlignmentToJustifyMap[props.alignment]};
    background-color: ${(props) => props.backgroundColor};
`;

export interface InputContentProps extends ItemContainerProps {
    itemDefinition: InputDefinition;
    isSelected: boolean;
    isOnCanvas?: boolean;
    attributes?: ReturnType<typeof useSortable>['attributes'];
    listeners?: ReturnType<typeof useSortable>['listeners'];
    // TO-DO: unify onRemove with the types used in enhancer.tsx
    onRemove?: (id: string) => void;
}

const empty = {};

/**
 * compute isError, errorMessage, isDisabled, disabledMessage based on whether
 * primary dataSource contains error (level: info/error)
 * @method getErrorDisabledStates
 * @param {Object} inputData
 * @param {Object} inputData.dataSources dataSources containing primary dataSource
 * @returns {Object}
 */
export const getErrorDisabledStates = ({
    dataSources,
}: {
    dataSources?: DataSources;
}) => {
    const errorLevel = dataSources?.primary?.error?.level;
    const message = dataSources?.primary?.error?.message || '';
    // Search is waiting for unresolved tokens
    if (errorLevel === ERROR_LEVEL_INFO) {
        return {
            isDisabled: true,
            disabledMessage: message || WAITING_FOR_INPUT_MSG,
        };
    }
    if (errorLevel === ERROR_LEVEL_ERROR) {
        return { isError: true, errorMessage: message };
    }
    return {};
};

/**
 * Display input content
 * @param {Object} props
 * @param {Object} props.inputProps props required by the input to render
 * @param {Boolean} props.loading indicates whether datasource is still fetching data and hasn't had an error
 * @param {Object} props.dataSources primary dataSource (if any) used by the input
 * @param {String} props.id input id
 * @param {String} props.type input type
 * @param {Function} props.onSelect callback to update the store when input is selected
 */
export default ({
    itemDefinition,
    loading = false,
    dataSources = {},
    id,
    isSelected,
    refresh,
    mode,
    onRemove,
    width,
    height,
    attributes,
    listeners,
    isOnCanvas = false,
}: InputContentProps) => {
    const apiRegistry = useApiRegistry();
    const preset = usePreset();
    const eventRegistry = useEventRegistry();
    const dispatch = useDispatch();

    const {
        type,
        title,
        options = empty,
        encoding = empty,
        context = empty,
    } = itemDefinition;

    const input = preset.findInput(type);
    const inputStaticDef = useMemo(
        () => ({
            options,
            context,
        }),
        [context, options]
    );

    const handleValueChange = useCallback(
        (e, newValue) => {
            const eventId = eventRegistry.registerEvent(e);
            dispatch(inputValueChanged(id, newValue, eventId));
        },
        [dispatch, eventRegistry, id]
    );

    const registerApi = useCallback(
        (ref: HTMLDivElement) => {
            apiRegistry.registerInputApi(id, ref);
        },
        [apiRegistry, id]
    );

    useEffect(() => {
        return () => {
            apiRegistry.removeInputApi(id);
        };
    }, [apiRegistry, id]);

    // Register refresh to api on mount, unregister on unmount
    useEffect(() => {
        apiRegistry.registerInputActionsApi(id, { refresh });
        return () => {
            apiRegistry.removeInputActionsApi(id);
        };
    }, [apiRegistry, id, refresh]);

    // check whether the input dataSource contains search errors
    const {
        isError: dsError = false,
        errorMessage: dsErrorMessage = '',
        isDisabled = false,
        disabledMessage = '',
    } = useMemo(
        () =>
            getErrorDisabledStates({
                dataSources,
            }),
        [dataSources]
    );

    // check whether the static input config (options/context) contain validation errors
    const { errorMessages: inputErrorMessages } = useMemo(() => {
        const validate = input?.validate;
        return typeof validate === 'function'
            ? validate(inputStaticDef)
            : { errorMessages: [] };
    }, [inputStaticDef, input]);

    const value = useSelector((state) => selectInputValue(state, id));

    const canBeHidden = useCanItemBeHidden({ itemDefinition });

    // Generate final props passed to input
    const props = useMemo(
        () => ({
            id,
            key: id,
            loading,
            title,
            dataSources,
            encoding,
            context,
            options,
            isError: dsError || !isEmpty(inputErrorMessages), // dataSource error take precedence over input options error
            errorMessage: dsErrorMessage || inputErrorMessages.join(' '), // TODO: refactor tooltip to display multiline errors
            isDisabled,
            disabledMessage,
            value,
            isSelected,
            inputApiRef: registerApi,
            onRemove,
            onValueChange: handleValueChange,
            mode,
            width,
            height,
            isOnCanvas,
            canBeHidden,
            attributes,
            listeners,
        }),
        [
            attributes,
            listeners,
            id,
            loading,
            title,
            dataSources,
            encoding,
            context,
            options,
            dsError,
            inputErrorMessages,
            dsErrorMessage,
            isDisabled,
            disabledMessage,
            value,
            isSelected,
            registerApi,
            onRemove,
            handleValueChange,
            mode,
            width,
            height,
            isOnCanvas,
            canBeHidden,
        ]
    );

    const Input = useMemo(
        () => preset.createInput(type, props),
        [preset, type, props]
    );

    const alignment: InputAlignment = itemDefinition?.canvasAlignment ?? 'top';
    const backgroundColor = itemDefinition?.options?.backgroundColor
        ? sanitizeColor(itemDefinition.options.backgroundColor as string)
        : null;
    const bgColor = backgroundColor || 'transparent';

    return isOnCanvas ? (
        <OnCanvasWrapper alignment={alignment} backgroundColor={bgColor}>
            {Input}
        </OnCanvasWrapper>
    ) : (
        Input
    );
};
