import React from 'react';
import T from 'prop-types';
import { _ } from '@splunk/ui-utils/i18n';
import SVG, {
    type SVGPropsOptionalViewBox,
} from '@splunk/react-icons/SVGEnterprise';

/* eslint-disable max-len */
export default function TimeRangeInput({
    screenReaderText,
    ...otherProps
}: SVGPropsOptionalViewBox): ReturnType<typeof SVG> {
    return (
        <SVG
            viewBox="0 0 30 30"
            screenReaderText={screenReaderText}
            {...otherProps}
        >
            <path
                d="M7.2 1.22427V4.79141C7.2 5.49826 6.6904 6.00784 6 6.00784C5.3096 6.00784 4.8 5.49824 4.8 4.79141V1.22427C4.8 0.517427 5.3096 0.00784302 6 0.00784302C6.6904 0.00784302 7.2 0.517443 7.2 1.22427ZM19.2 1.24072V4.79141C19.2 5.49826 18.6904 6.00784 18 6.00784C17.3096 6.00784 16.8 5.49824 16.8 4.79141V1.2736C16.8 0.550307 17.3096 0.024275 18.0164 0.040723C18.7068 0.040723 19.2 0.550323 19.2 1.24072ZM20.4 5.99141V3.60784H22.7836C23.4904 3.60784 24 4.11744 24 4.82428V22.7914C24 23.4983 23.4904 24.0078 22.7836 24.0078H1.21643C0.509584 24.0078 0 23.4982 0 22.7914V4.82428C0 4.11743 0.5096 3.60784 1.21643 3.60784H3.6V5.99141C3.6 6.69826 4.1096 7.20784 4.81643 7.20784H7.18357C7.89042 7.20784 8.4 6.69824 8.4 5.99141V3.60784H15.6V5.99141C15.6 6.69826 16.1096 7.20784 16.8164 7.20784H19.1836C19.8904 7.20784 20.4 6.69824 20.4 5.99141ZM2.6 21.4078H21.4V9.60784H2.6V21.4078Z"
                fill="currentColor"
            />
            <path
                d="M19.8613 15.104L15.9443 12.0702C15.7258 11.8948 15.4232 12.0702 15.4232 12.3508V14.18H12.498H11.5062H8.58104V12.3508C8.58104 12.0702 8.27843 11.9124 8.05989 12.0702L4.12608 15.104C3.95797 15.2443 3.95797 15.5074 4.12608 15.6477L8.05989 18.699C8.27843 18.8744 8.58104 18.699 8.58104 18.4184V16.59H11.5062H12.498H15.4232V18.4184C15.4232 18.699 15.7258 18.8568 15.9443 18.699L19.8613 15.6477C20.0462 15.5249 20.0462 15.2443 19.8613 15.104Z"
                fill="currentColor"
            />
        </SVG>
    );
}

TimeRangeInput.propTypes = {
    screenReaderText: T.string,
};

TimeRangeInput.defaultProps = {
    screenReaderText: _('Time Range Input'),
};
