import { _ } from '@splunk/ui-utils/i18n';
import { EditorConfig } from '../interfaces/Editor';
import { insertLayout } from '../utils/configUtils';

const AxesChartDataConfig: EditorConfig = {
    label: _('Data configurations'),
    layout: [
        [
            {
                label: _('X'),
                option: 'x',
                editor: 'editor.columnSelector',
                editorProps: {
                    dataSourceKey: 'primary',
                    supportsDSL: true,
                    expectedDataPrimitive: ['series'],
                },
            },
        ],
        [
            {
                label: _('Y'),
                option: 'y',
                editor: 'editor.columnMultiSelector',
                editorProps: {
                    dataSourceKey: 'primary',
                    filterByTypes: ['number'],
                    supportsDSL: true,
                    expectedDataPrimitive: ['series', 'frame'],
                },
            },
        ],
        [
            {
                label: _('Result limit'),
                option: 'resultLimit',
                editor: 'editor.number',
                editorProps: {
                    dataSourceKey: 'primary',
                },
            },
        ],
    ],
};

export const AxesChartDataConfigBar: EditorConfig = {
    label: AxesChartDataConfig.label,
    // insert Y2 after Y
    layout: insertLayout(AxesChartDataConfig.layout, 2, [
        {
            label: _('Y2'),
            option: 'y2',
            editor: 'editor.columnMultiSelector',
            editorProps: {
                dataSourceKey: 'primary',
                filterByTypes: ['number'],
                supportsDSL: true,
                expectedDataPrimitive: ['series', 'frame'],
            },
        },
    ]),
};

export const AxesChartDataConfigScatter: EditorConfig = {
    label: AxesChartDataConfig.label,
    layout: [
        [
            {
                label: _('X'),
                option: 'x',
                editor: 'editor.columnSelector',
                editorProps: {
                    dataSourceKey: 'primary',
                    supportsDSL: true,
                    expectedDataPrimitive: ['series'],
                },
            },
        ],
        [
            {
                label: _('Y'),
                option: 'y',
                editor: 'editor.columnSelector',
                editorProps: {
                    dataSourceKey: 'primary',
                    filterByTypes: ['number'],
                    supportsDSL: true,
                    expectedDataPrimitive: ['series'],
                },
            },
        ],
        [
            {
                label: _('Category'),
                option: 'category',
                editor: 'editor.columnSelector',
                editorProps: {
                    dataSourceKey: 'primary',
                    supportsDSL: true,
                    expectedDataPrimitive: ['series'],
                },
            },
        ],
        [
            {
                label: _('Result limit'),
                option: 'resultLimit',
                editor: 'editor.number',
                editorProps: {
                    dataSourceKey: 'primary',
                },
            },
        ],
    ],
};

export const AxesChartDataConfigBubble: EditorConfig = {
    label: AxesChartDataConfigScatter.label,
    // layout: insert Size after Category
    layout: insertLayout(AxesChartDataConfigScatter.layout, 3, [
        {
            label: _('Size'),
            option: 'size',
            editor: 'editor.columnSelector',
            editorProps: {
                dataSourceKey: 'primary',
                supportsDSL: true,
                expectedDataPrimitive: ['series'],
            },
        },
    ]),
};

export const AxesChartDataConfigAreaColumnLine: EditorConfig = {
    label: AxesChartDataConfigBar.label,
    layout: [
        ...AxesChartDataConfigBar.layout,
        [
            {
                label: _('Annotation x'),
                option: 'annotationX',
                editor: 'editor.columnSelector',
                editorProps: {
                    dataSourceKey: 'annotation',
                    supportsDSL: true,
                    expectedDataPrimitive: ['series'],
                },
            },
        ],
        [
            {
                label: _('Annotation labels'),
                option: 'annotationLabel',
                editor: 'editor.columnSelector',
                editorProps: {
                    dataSourceKey: 'annotation',
                    supportsDSL: true,
                    expectedDataPrimitive: ['series'],
                },
            },
        ],
    ],
};

export default AxesChartDataConfig;
