import { chain } from 'lodash';
import { VIZ_CATEGORICAL } from '@splunk/visualization-color-palettes';
import vizConfig from './config';
import withDashboardViz from '../common/withDashboardViz';
import Punchcard, { PunchcardProps } from './PurePunchcard/Punchcard';
import { convertToPunchcardProperties } from './punchcardUtils';

export const config = vizConfig;
export const PurePunchcard = Punchcard;

const validOptions = Object.keys(config.optionsSchema);

type computeVizPropsFn = (options: Record<string, any>) => Partial<PunchcardProps>;
export const computeVizProps: computeVizPropsFn = ({
    onPointClick,
    onLegendClick,
    x,
    y,
    category,
    ...otherOptions
}): Partial<PunchcardProps> => {
    const options = chain(otherOptions).pick(validOptions).thru(convertToPunchcardProperties).value();
    if (options.seriesColors && typeof options.seriesColors === 'string') {
        try {
            options.seriesColors = JSON.parse(options.seriesColors);
        } catch (e) {
            options.seriesColors = VIZ_CATEGORICAL;
        }
    }

    return {
        onPointClick,
        onLegendClick,
        x,
        y,
        // PurePunchcard accepts category as string array
        category: category && category.map(String),
        ...options,
    };
};

const viz = withDashboardViz({
    ReactViz: Punchcard as any,
    computeVizProps,
    vizConfig,
});

export const { themes } = vizConfig;
export default viz;
