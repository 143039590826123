import { useEffect, useState } from 'react';
import { useImageRegistry } from '@splunk/dashboard-context';
import { console } from '@splunk/dashboard-utils';

// temp until the ImageRegistry is typed
interface ImageRegistry {
    getByURL: (url: string) => Promise<{ dataURI: string }>;
    isResourceURL: (imgSrc: string) => boolean;
}

export const useBackgroundImage = (backgroundImageSrc?: string): string => {
    const [imageSrc, setImageSrc] = useState('');
    const imageRegistry = useImageRegistry();

    /**
     * Check whether backgroundImage src is a remote image url or stored in the image registry. Fetch if its the latter
     * @param string backgroundImageSrc
     */
    const fetchBackgroundImage = async (
        registry: ImageRegistry,
        bgImageSrc: string
    ) => {
        try {
            // use imageRegistry to fetch the image if it's there
            if (registry) {
                if (registry.isResourceURL(bgImageSrc)) {
                    const imageObject = await registry.getByURL(bgImageSrc);
                    setImageSrc(imageObject.dataURI);
                } else {
                    setImageSrc(bgImageSrc);
                }
            } else {
                setImageSrc(bgImageSrc);
            }
        } catch (error) {
            console.error(error); // TODO Refactor error display
        }
    };

    useEffect(() => {
        if (backgroundImageSrc) {
            fetchBackgroundImage(
                imageRegistry as ImageRegistry,
                backgroundImageSrc
            );
        } else {
            // Reset to the original state value
            setImageSrc('');
        }
    }, [imageRegistry, backgroundImageSrc]);

    return imageSrc;
};
