import React, { useCallback } from 'react';
import T from 'prop-types';
import styled from 'styled-components';
import pick from 'lodash/pick';
import isEmpty from 'lodash/isEmpty';
import FeatureFlagContext from '@splunk/visualization-context/FeatureFlagContext';
import { toDimension } from '@splunk/visualizations-shared/style';
import { _ } from '@splunk/ui-utils/i18n';
import SizeAwareWrapper from '@splunk/visualizations-shared/SizeAwareWrapper';
import Message from '@splunk/visualizations-shared/Message';
import VizStyleWrapper from '../../common/components/VizStyleWrapper';
import { TrellisTitle, TRELLIS_TITLE_HEIGHT } from '../../common/components/TrellisTitle';
import { LAYOUT_TYPE } from '../../common/utils/layoutUtils';
import SingleValueIconLayout from './SingleValueIconLayout';
import {
    computeColors,
    computeSingleValueContent,
    getUnderLabel,
    getUnderLabelHeight,
    shouldShowTrend,
    handleValueClickCallback,
} from '../../common/utils/singleValueUtils';
import {
    isValidPosition,
    isHorizontal,
    getIconWidth,
    getIconHeight,
    getIconFontSize,
    getSVGDimension,
    getValuesWidth,
    getValuesHeight,
    getIconColor,
} from './utils/iconUtils';

const Container = styled.div`
    overflow: hidden;
    ${props => toDimension(pick(props, ['width', 'height']))};
`;

const SingleValueIcon = ({ ...props }) => {
    const {
        width,
        height = 250,
        style,
        majorFontSize,
        majorValue,
        majorValueField,
        trendValue,
        trendFontSize,
        unitPosition,
        numberPrecision,
        shouldUseThousandSeparators,
        shouldAbbreviateTrendValue,
        icon,
        iconOpacity,
        IconComponent,
        noDataSources,
        noResult,
        onValueClick,
        defaultFontColor,
        defaultBlockFontColor,
        splitByLayout,
        trellisKey = '',
        trellisSplitBy = '',
    } = props;
    const defaultColors = { defaultBlockFontColor, defaultFontColor };
    const { backgroundColor, majorColor, trendColor, underLabelColor } = computeColors(props, defaultColors);
    const iconColor = getIconColor({
        iconColor: props.iconColor,
        backgroundColor,
        defaultFontColor: defaultColors.defaultFontColor,
        defaultBlockFontColor: defaultColors.defaultBlockFontColor,
    });
    const featureFlagContext = React.useContext(FeatureFlagContext);
    const isTrellisFeatureFlagOn = Boolean(
        featureFlagContext && featureFlagContext.visualizations_enableTrellis
    );
    const trellisEnabled = isTrellisFeatureFlagOn && splitByLayout === LAYOUT_TYPE.Trellis;
    const handleValueClick = useCallback(
        ev =>
            handleValueClickCallback(
                ev,
                majorValue,
                majorValueField,
                trendValue,
                onValueClick,
                trellisEnabled,
                trellisSplitBy,
                trellisKey
            ),
        [onValueClick, majorValue, majorValueField, trendValue, trellisEnabled, trellisSplitBy, trellisKey]
    );

    const renderVisualization = ({ width: containerWidth, height: containerHeight }) => {
        if ((noDataSources || noResult) && !IconComponent) {
            return (
                <Message
                    width={containerWidth}
                    height={containerHeight}
                    message={_('No Icon')}
                    level="warning"
                />
            );
        }

        let IconLayout = null;
        if ((noDataSources || noResult) && IconComponent) {
            IconLayout = React.cloneElement(IconComponent, {
                width: containerWidth,
                height: containerHeight,
                color: props.iconColor,
                opacity: iconOpacity,
            });
        } else {
            // will update later based on text size
            let { showValue = true, trendDisplay, unit, underLabel, iconPosition } = props;

            if (!isValidPosition(iconPosition)) {
                iconPosition = 'before';
            }
            const showHorizontal = isHorizontal(iconPosition);
            const iconWidth = getIconWidth({ showHorizontal, width: containerWidth });
            const iconHeight = getIconHeight({ showHorizontal, height: containerHeight });

            let svgIconComponent = null;
            if (IconComponent) {
                svgIconComponent = React.cloneElement(IconComponent, {
                    width: getSVGDimension({
                        showValue,
                        containerDimension: containerWidth,
                        iconDimension: iconWidth,
                        ratio: 0.25,
                    }),
                    height: getSVGDimension({
                        showValue,
                        containerDimension: containerHeight,
                        iconDimension: iconHeight,
                        ratio: 0.25,
                    }),
                    color: props.iconColor,
                    opacity: iconOpacity,
                });
            }

            // claculate major and trend text and font size
            const contentWidth = getValuesWidth({ showHorizontal, width: containerWidth });
            const contentHeight = getValuesHeight({ showHorizontal, height: containerHeight, iconHeight });
            const toRemoveList = [
                { key: 'underLabel', value: '' },
                { key: 'trendDisplay', value: 'off' },
                { key: 'unit', value: '' },
                { key: 'showValue', value: false },
            ];
            const {
                majorText,
                trendText,
                majorTextFontSize,
                trendTextFontSize,
                majorValueTitle,
                smallVizProps,
            } = computeSingleValueContent({
                majorValue,
                trendValue,
                majorFontSize,
                trendFontSize,
                trendDisplay,
                contentWidth,
                contentHeight,
                containerWidth,
                containerHeight,
                unit,
                numberPrecision,
                shouldUseThousandSeparators,
                shouldAbbreviateTrendValue,
                toRemoveList,
            });
            if (!isEmpty(smallVizProps)) {
                ({ showValue, trendDisplay, underLabel, unit } = smallVizProps);
            }

            const iconFontSize = getIconFontSize({ showValue, majorTextFontSize, iconWidth });

            // calculate underlabel size
            const underLabelHeight = getUnderLabelHeight(containerHeight * 0.1);
            const underLabelFontSize = getUnderLabel({
                underLabel,
                width: contentWidth,
                height: underLabelHeight,
            });
            IconLayout = (
                <SingleValueIconLayout
                    data-test="SingleValueIconLayout"
                    majorColor={majorColor}
                    majorText={majorText}
                    majorTextFontSize={majorTextFontSize}
                    majorValueTitle={majorValueTitle}
                    trendColor={trendColor}
                    trendText={trendText}
                    trendTextFontSize={trendTextFontSize}
                    showTrendIndicator={shouldShowTrend(trendDisplay)}
                    unit={unit}
                    unitPosition={unitPosition}
                    underLabel={underLabel}
                    underLabelFontSize={underLabelFontSize}
                    underLabelColor={underLabelColor}
                    showValue={showValue}
                    iconWidth={iconWidth}
                    iconHeight={iconHeight}
                    iconFontSize={iconFontSize}
                    iconColor={iconColor}
                    iconOpacity={iconOpacity}
                    iconPosition={iconPosition}
                    icon={icon}
                    svgIconComponent={svgIconComponent}
                    onValueClick={handleValueClick}
                />
            );
        }

        return (
            <VizStyleWrapper
                backgroundColor={backgroundColor}
                style={style}
                dataTestKey="SingleValueIconWrapper"
            >
                {IconLayout}
            </VizStyleWrapper>
        );
    };

    if (isTrellisFeatureFlagOn && splitByLayout === LAYOUT_TYPE.Trellis) {
        return (
            <>
                <TrellisTitle
                    fontColor={defaultFontColor}
                    backgroundColor={backgroundColor}
                    trellisKey={trellisKey}
                />
                <Container width={width} height={height - TRELLIS_TITLE_HEIGHT}>
                    <SizeAwareWrapper>
                        {containerDimension => renderVisualization(containerDimension)}
                    </SizeAwareWrapper>
                </Container>
            </>
        );
    }
    return (
        <Container width={width} height={height}>
            <SizeAwareWrapper>
                {containerDimension => renderVisualization(containerDimension)}
            </SizeAwareWrapper>
        </Container>
    );
};

SingleValueIcon.propTypes = {
    width: T.oneOfType([T.string, T.number]),
    height: T.oneOfType([T.string, T.number]),
    style: T.object,
    majorValue: T.oneOfType([T.string, T.number]),
    majorValueField: T.string,
    majorColor: T.string,
    majorFontSize: T.number,
    trendColor: T.string,
    trendValue: T.oneOfType([T.string, T.number]),
    trendFontSize: T.number,
    trendDisplay: T.oneOf(['absolute', 'percent', 'off']),
    shouldAbbreviateTrendValue: T.bool,
    backgroundColor: T.string,
    unit: T.string,
    unitPosition: T.string,
    underLabel: T.string,
    numberPrecision: T.number,
    shouldUseThousandSeparators: T.bool,
    icon: T.string,
    iconPosition: T.oneOf(['before', 'after', 'top', 'below']),
    iconColor: T.string,
    iconOpacity: T.number,
    showValue: T.bool,
    IconComponent: T.element,
    noDataSources: T.bool,
    noResult: T.bool,
    onValueClick: T.func,
    defaultFontColor: T.string,
    defaultBlockFontColor: T.string,
    splitByLayout: T.string,
    trellisKey: T.string,
    trellisSplitBy: T.string,
};

SingleValueIcon.defaultProps = {
    width: '100%',
    height: 250,
    majorValue: 'N/A',
    majorValueField: '',
    trendDisplay: 'absolute',
    shouldAbbreviateTrendValue: false,
    numberPrecision: 0,
    shouldUseThousandSeparators: true,
    unitPosition: 'after',
    icon: 'default',
    iconPosition: 'before',
    iconOpacity: 1,
    showValue: true,
    noDataSources: false,
    noResult: false,
    onValueClick: () => {},
    trellisKey: '',
    trellisSplitBy: '',
};

export default SingleValueIcon;
