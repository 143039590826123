import getTheme from '@splunk/themes/getTheme';

const themeEnterprise = getTheme({ family: 'enterprise', colorScheme: 'light', density: 'comfortable' });

export const DELTA_GREEN = themeEnterprise.successColor;
export const DELTA_RED = themeEnterprise.errorColor;

export const SEMANTIC_SUCCESS = '#53a051';
export const SEMANTIC_WARNING = '#f8be34';
export const SEMANTIC_ERROR = '#dc4e41';

export const SPARKLINE_FILL_COLOR = themeEnterprise.gray60;
export const SPARKLINE_STROKE_COLOR = themeEnterprise.gray20;
export const SPARKLINE_WHITE = themeEnterprise.white;

export const RADIAL_BACKGROUND_COLOR = themeEnterprise.gray92;
export const RADIAL_STROKE_COLOR = themeEnterprise.gray60;

export const RADIAL_SEVERITY_COLORS = {
    none: {
        background: themeEnterprise.gray92,
        stroke: themeEnterprise.gray60,
    },
    low: {
        background: themeEnterprise.successColorL40,
        stroke: themeEnterprise.successColorD50,
    },
    guarded: {
        background: themeEnterprise.infoColorL40,
        stroke: themeEnterprise.infoColorD50,
    },
    elevated: {
        background: themeEnterprise.warningColorL40,
        stroke: themeEnterprise.warningColorD50,
    },
    high: {
        background: themeEnterprise.alertColorL40,
        stroke: themeEnterprise.alertColorD50,
    },
    severe: {
        background: themeEnterprise.errorColorL40,
        stroke: themeEnterprise.errorColorD50,
    },
};

export const COLOR_EDITOR_PALETTE = [
    'transparent',
    '#000000',
    '#ffffff',
    '#dc4e41',
    '#f1813f',
    '#f8be34',
    '#53a051',
    '#0877a6',
    '#ec9960',
    '#af575a',
    '#62b3b2',
    '#4fa484',
    '#f8be44',
    '#5a4575',
    '#708794',
    '#294e70',
    '#b6c75a',
];
