import type {
    InputDefinition,
    CreateVisualizationActionPayload,
} from '@splunk/dashboard-types';
import { createAction } from '@reduxjs/toolkit';

// TODO: rename actions following best practices after redux hoist completed

/**
 * Actions that communicate with Saga, these actions will not update redux store directly (no reducer)
 */
export const initialize = createAction('SAGA_INITIALIZE');

export const teardown = createAction('SAGA_TEARDOWN');

export const removeDashboardItems = createAction<string[]>(
    'REMOVE_DASHBOARD_ITEMS'
);

export const removeVisualizations = createAction<string[]>(
    'REMOVE_VISUALIZATIONS'
);

export const createVisualization =
    createAction<CreateVisualizationActionPayload>('CREATE_VISUALIZATIONS');

export interface CloneDashboardItemsActionPayload {
    from: string[];
    to: string[];
    offsetMultiplier?: number;
}
export const cloneDashboardItems =
    createAction<CloneDashboardItemsActionPayload>('CLONE_DASHBOARD_ITEMS');

export const adjustVisualizationOrder = createAction(
    'ADJUST_VIZ_ORDER',
    (from: number, to: number) => ({
        payload: {
            from,
            to,
        },
    })
);

export const triggerEvent = createAction(
    'EVENT_TRIGGERED',
    (
        targetId: string | null,
        eventType: string,
        eventPayload: unknown,
        eventId?: string
    ) => ({
        payload: {
            targetId,
            eventType,
            eventPayload,
            eventId,
        },
    })
);

export interface CreateInputActionPayload {
    inputId: string;
    inputDefinition: InputDefinition;
}
export const createInput =
    createAction<CreateInputActionPayload>('CREATE_INPUT');

export interface AdjustInputOrderActionPayload {
    from: number;
    to: number;
}
export const adjustInputOrder =
    createAction<AdjustInputOrderActionPayload>('ADJUST_INPUT_ORDER');

export const inputValueChanged = createAction(
    'INPUT_VALUE_CHANGED',
    (id: string, newValue: string | string[], eventId: string) => ({
        payload: {
            id,
            value: newValue,
            eventId,
        },
    })
);

export const removeInput = createAction('REMOVE_INPUT', (id) => ({
    payload: [id],
}));

export const inputChanged = createAction('INPUT_CHANGED');

export const moveInputToCanvas = createAction<string>('MOVE_INPUT_TO_CANVAS');

export const moveInputToGlobalInputs = createAction<string>(
    'MOVE_INPUT_TO_GLOBAL_INPUTS'
);
