import * as React from 'react';
import { camelCase, has, get, defaultsDeep } from 'lodash';
import getSettingsFromThemedProps from '@splunk/themes/getSettingsFromThemedProps';
import { Options as Opts } from '@splunk/visualization-encoding/Options';
import { withSanitizedProps } from '@splunk/visualizations-shared/SanitizeProps';
import TimezoneContext from '@splunk/visualization-context/TimezoneContext';
import moment from '@splunk/moment';
import FeatureFlagContext from '@splunk/visualization-context/FeatureFlagContext';
import { withTheme } from 'styled-components';
import { deepMergeWithArrayPrimitiveOverrides } from '@splunk/visualizations-shared/hocUtils';
import { pickFieldFromJSONSchema } from '@splunk/visualizations-shared/JSONSchemaUtils';
import AsyncDynamicOptionsEvaluator, {
    AsyncDynamicOptionsEvaluatorParams,
} from '@splunk/async-dynamic-options-evaluator';
import { createGUID } from '@splunk/ui-utils/id';
import SplunkVisualization from './SplunkVisualization';
import type { DashboardVizProps, DataSource, VizConfig, VizProps } from './interfaces';
import { VizBehavior } from './interfaces/VizBehavior';
import { getSortingParams } from './hooks/getSorting';
import { getPaginationParams } from './hooks/getPagination';
import { useDeepMemo } from './hooks/useDeepMemo';
import MissingPropsMessage, { missingKeys } from './components/MissingPropsMessage';
import withEditModeCover from './hocs/withEditModeCover';
import withPlaceholder from './hocs/withPlaceholder';
import { ChartingProps } from './Charting';

type HOC = (props: DashboardVizProps) => React.ReactElement<DashboardVizProps>;
export interface DashboardVizOpts {
    ReactViz: React.ComponentType<ChartingProps | VizProps>; // we will wrap this in DashboardViz (see const DashboardViz = ...)
    vizConfig: VizConfig;
    computeVizProps?: (props) => any;
    disableEditModeCover?: boolean;
    useIconPlaceholder?: (
        dataSources: { [name: string]: DataSource },
        loading: boolean,
        options: Record<string, unknown>
    ) => boolean;
}

/**
 * hoc that wraps a pure react visualization in a Dashboard visualization
 * @param {DashboardVizOpts} opts
 * @returns {HOC}
 */
const withDashboardViz = (opts: DashboardVizOpts): HOC => {
    const {
        ReactViz,
        vizConfig,
        computeVizProps = () => {},
        disableEditModeCover,
        useIconPlaceholder,
    } = opts;

    // sanitizing props before sending them to reactviz.
    const SanitizedReactViz = withSanitizedProps(ReactViz);
    const defaultOptions = pickFieldFromJSONSchema(vizConfig.optionsSchema, 'default');

    const DashboardViz = (props: DashboardVizProps): React.ReactElement<DashboardVizProps> => {
        const {
            mode,
            hasEventHandlers,
            dataSources, // type of { [key: string]: DataSource }
            options,
            context = {},
            width,
            height,
            onComputedProps,
            onEventTrigger,
            theme,
            onRequestParamsChange,
        } = props;
        const { serializedTimezone } = React.useContext(TimezoneContext);
        if (serializedTimezone) {
            /*
                @splunk/moment currently does not allow us to customize the timezone on a per visualization basis.
                This ensures that if a timezone was provided via TimezoneContext @splunk/moment time formatting
                 will use it.

                @TODO(pwied):
                 - remove when we moved away from @splunk/moment
                 - provide timezone context to dynamic option evaluation phase
            */
            if (!moment.getDefaultSplunkTimezone()) {
                moment.setDefaultSplunkTimezone(serializedTimezone);
            }
        }

        const themeFunc = themeVar => vizConfig.themes[themeVar]?.(props);
        const bgColorFromTheme = themeFunc('defaultBackgroundColor');
        const {
            family: currentThemeFamily,
            colorScheme: currentThemeColorScheme,
            density: currentThemeDensity,
        } = getSettingsFromThemedProps({ theme });

        let evaluatedOptions: Opts = {}; // evaluatedOptions need to be initialized as an empty object
        const [evaluatedOptionsAsync, setEvaluatedOptionsAsync] = React.useState({}); // evaluatedOptions need to be initialized as an empty object
        const featureFlagContext = React.useContext<FeatureFlagContext>(FeatureFlagContext);

        const [visualizationID] = React.useState(`${vizConfig.name} - ${createGUID()}`);

        const [dataSourcesInState, setDataSourcesInState] = React.useState({});
        const [dataFramesInState, setDataFramesInState] = React.useState();

        const [useAsyncEvaluatedOptions, setUseAsyncEvaluatedOptions] = React.useState(
            Boolean(
                featureFlagContext &&
                    featureFlagContext.visualizations_useWebWorkersForDSL &&
                    typeof Worker !== 'undefined'
            )
        );

        evaluatedOptions = useDeepMemo((): Record<string, unknown> => {
            try {
                if (useAsyncEvaluatedOptions) {
                    const optionsToEvaluate = {
                        context: defaultsDeep({}, context, vizConfig.defaultContext),
                        options: deepMergeWithArrayPrimitiveOverrides({}, options, defaultOptions),
                    };
                    const asyncDynamicOptionsEvaluatorParams: AsyncDynamicOptionsEvaluatorParams = {
                        visualizationID,
                        props,
                        options: optionsToEvaluate,
                        dataSources,
                        theme: vizConfig.themes,
                    };
                    AsyncDynamicOptionsEvaluator.evaluate(asyncDynamicOptionsEvaluatorParams)
                        .then(results => {
                            setEvaluatedOptionsAsync(results.results);
                        })
                        .catch(e => {
                            // eslint-disable-next-line no-console
                            console.error(`unexpected error evaluating options - async: ${e.message}`);
                            setUseAsyncEvaluatedOptions(false);
                        });
                } else {
                    let dataFrames = dataFramesInState;
                    if (dataSources !== dataSourcesInState) {
                        setDataSourcesInState(dataSources);
                        dataFrames = Opts.dataFrameFromDataSource(dataSources);
                        setDataFramesInState(dataFrames);
                    }
                    return Opts.evaluate(
                        {
                            context: defaultsDeep({}, context, vizConfig.defaultContext),
                            options: deepMergeWithArrayPrimitiveOverrides({}, options, defaultOptions),
                        },
                        dataSources,
                        themeFunc,
                        dataFrames
                    );
                }
            } catch (e) {
                // eslint-disable-next-line no-console
                console.error(`unexpected error evaluating options: ${e.message}`);
                return {};
            }
            return {};
        }, [
            currentThemeFamily,
            currentThemeColorScheme,
            currentThemeDensity,
            options,
            context,
            dataSources,
            serializedTimezone,
            useAsyncEvaluatedOptions,
        ]);

        const evaluatedOptionsToUse = !useAsyncEvaluatedOptions ? evaluatedOptions : evaluatedOptionsAsync;

        // trellis specific backgroundColor update for onComputedProps
        // if trellis is enabled following these three criteria - 1) trellis feature flag is enabled 2) vizConfig supports array has 'trellis' 3)  option 'splitByLayout' is set to 'trellis
        // onComputedProps api should return back trellisBackgroundColor value for backgroundColor option to support coloring of sibling panels (for eg:- title/ description on UDF side)
        const isTrellisFeatureFlagOn = Boolean(
            featureFlagContext && featureFlagContext.visualizations_enableTrellis
        );
        let clonedEvaluatedOptions = evaluatedOptionsToUse;
        if (
            isTrellisFeatureFlagOn &&
            vizConfig?.supports?.includes(VizBehavior.TRELLIS) &&
            clonedEvaluatedOptions?.splitByLayout === VizBehavior.TRELLIS
        ) {
            clonedEvaluatedOptions = {
                ...evaluatedOptionsToUse,
                backgroundColor: clonedEvaluatedOptions?.trellisBackgroundColor,
            };
        }
        // useEffect hook needs to be initialized before any early returns
        // this is in accordance with the order of hook calls being the same on each render https://reactjs.org/docs/hooks-rules.html#explanation
        React.useEffect((): void => {
            onComputedProps(clonedEvaluatedOptions);
        });

        React.useEffect((): (() => void) => {
            return () => {
                if (useAsyncEvaluatedOptions) {
                    AsyncDynamicOptionsEvaluator.reset(visualizationID);
                }
            };
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, []);

        // present warning message if any required props are missing
        const { requiredProps = [], supports } = vizConfig;
        const missing = missingKeys(requiredProps, evaluatedOptionsToUse);
        if (
            missing.length > 0 &&
            supports.includes(VizBehavior.PLACEHOLDER) &&
            vizConfig.key !== 'splunk.singlevalue' // For SV missing props msg is not displayed if required props majorValue is missing, instead this will be handled from the component, instead handled within component
        ) {
            return (
                <MissingPropsMessage
                    data-test="missing-props-message"
                    width={width}
                    height={height}
                    missingProps={missing}
                    backgroundColor={bgColorFromTheme}
                />
            );
        }

        // events
        const eventCallbackProps = {};
        if (hasEventHandlers && mode === 'view' && vizConfig.supports.indexOf(VizBehavior.EVENTS) > -1) {
            const supportedEvents = vizConfig.events;
            Object.keys(supportedEvents).forEach((eventName): void => {
                const eventCallbackPropName = camelCase(`on.${eventName}`);
                eventCallbackProps[eventCallbackPropName] = null;
                // todo: refactor single value and single value icon to send payload from viz
                // then remove these lines
                const { payloadKeys } = supportedEvents[eventName];
                let payload = {};
                if (Array.isArray(payloadKeys) && payloadKeys.length) {
                    payloadKeys.forEach(p => {
                        payload[p] = evaluatedOptionsToUse[p];
                    });
                }

                eventCallbackProps[eventCallbackPropName] = (ev): Record<string, unknown> => {
                    if (ev && ev.payload) {
                        payload = ev.payload;
                    }

                    return onEventTrigger({ originalEvent: ev, payload, type: eventName });
                };
            });
        }

        // compute pure viz props which are not from options
        const computedVizProps = computeVizProps({
            ...props,
            ...evaluatedOptionsToUse,
            ...eventCallbackProps,
            themeFunc,
        });

        /**
         * PAGE_AND_SORT behavior
         *
         * Some visualizations, like table, requires metadata about the datasource,
         * like total number of results, current count, offset, and sort, for rendering
         * paginator and sorting direction. In this approach, the visualization
         * allow users to set 'paginateDataSourceKey' to select which datasource
         * should the visualization extract the metadata from. It defaults to 'primary' datasource.
         * The visualization config should set the PAGE_AND_SORT behavior to receive
         * 'requestParams', 'meta' and onRequestParams callback as props.
         *
         * In future, we could automatically detect which all datasources are being used
         * and bind those magically to pagination/sorting behavior.
         */
        let dataSourceMetadata = {};
        const OptionKey = 'paginateDataSourceKey';
        const paginateDataSourceKey =
            (has(evaluatedOptionsToUse, OptionKey) && evaluatedOptionsToUse[OptionKey]) || 'primary';
        if (
            vizConfig.supports.indexOf(VizBehavior.PAGE_AND_SORT) > -1 &&
            has(dataSources, paginateDataSourceKey)
        ) {
            const requestParams = get(dataSources, [paginateDataSourceKey, 'requestParams'], {});
            const meta = get(dataSources, [paginateDataSourceKey, 'meta'], {});
            const handleRequestParamsChange = (payload: Record<string, unknown>): void =>
                onRequestParamsChange(paginateDataSourceKey, payload);

            const sortParams = getSortingParams({
                requestParams,
                meta,
                onRequestParamsChange: handleRequestParamsChange,
            });

            const paginatorParams = getPaginationParams({
                requestParams,
                meta,
                onRequestParamsChange: handleRequestParamsChange,
            });

            dataSourceMetadata = {
                sortParams,
                paginatorParams,
            };
        }

        // if were using webworkers and this is the first render we dont want to show a viz with missing data so we return null
        if (useAsyncEvaluatedOptions && Object.keys(evaluatedOptionsToUse).length === 0) {
            return null;
        }

        return (
            <SanitizedReactViz
                mode={mode}
                width={width}
                height={height}
                {...evaluatedOptionsToUse}
                {...computedVizProps}
                {...eventCallbackProps}
                {...dataSourceMetadata}
            />
        );
    };

    DashboardViz.propTypes = {
        ...SplunkVisualization.propTypes,
        ...ReactViz.propTypes,
    };

    DashboardViz.defaultProps = {
        ...SplunkVisualization.defaultProps,
        ...ReactViz.defaultProps,
        // using withTheme requires component to receive theme prop. In case the consumer did not provide ThemeProvider, a default empty object will prevent warnings.
        theme: {},
    };
    // attach viz config
    DashboardViz.config = vizConfig;

    // @ts-ignore: TODO fix sc upgrade TS issues
    return withTheme(
        withPlaceholder(withEditModeCover(DashboardViz, disableEditModeCover), useIconPlaceholder)
    );
};

export default withDashboardViz;
