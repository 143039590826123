import React from 'react';
import T from 'prop-types';
import Line from '@splunk/react-sparkline/Line';
import { formatColor } from '@splunk/visualizations-shared/propUtils';

const SingleValueSparkline = ({
    width,
    height,
    data,
    fill,
    fillOpacity = 0.2,
    stroke,
    showTooltip,
    isArea,
    lineLength = 0,
    endDotCount = 0,
    acceptNull = true,
}) => (
    <Line
        lineColor={formatColor(stroke)}
        fillColor={formatColor(fill)}
        fillOpacity={fillOpacity.toString(10)}
        data={data}
        height={height}
        width={width}
        showTooltip={showTooltip}
        isArea={isArea}
        lineStrokeWidth={1.5}
        lineLength={isArea ? lineLength : undefined}
        endDotCount={isArea ? endDotCount : undefined}
        endDotRadius={Math.min(width, height) < 15 ? 1 : 3}
        endDotStroke={formatColor(stroke)}
        endDotFillColor={formatColor(stroke)}
        acceptNull={acceptNull}
        style={{ flex: '0 0 auto', justifyContent: 'center', display: 'flex', alignItems: 'flex-end' }}
    />
);

SingleValueSparkline.propTypes = {
    acceptNull: T.bool,
    data: T.arrayOf(T.number),
    endDotCount: T.number,
    fill: T.string,
    fillOpacity: T.number,
    height: T.number,
    isArea: T.bool,
    lineLength: T.number,
    showTooltip: T.bool,
    stroke: T.string,
    width: T.number,
};

export default SingleValueSparkline;
