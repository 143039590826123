import React, { type ReactNode, useMemo, useRef } from 'react';
import { pick } from 'lodash';
import styled from 'styled-components';
import { SortableContext } from '@dnd-kit/sortable';
import { reset } from '@splunk/themes/mixins';
import { toDimension } from '@splunk/dashboard-utils';
import { SizeWrapper } from '@splunk/dashboard-ui';
import { CanvasContext } from '@splunk/dashboard-context';
import { useDashboardDragMonitor } from '../hooks/useDashboardDragMonitor';

interface DashboardLayoutContainerProps {
    width: string | number;
    height: string | number;
}

const DashboardLayoutContainer = styled.div.attrs({
    'data-test': 'DashboardLayoutContainer',
})<DashboardLayoutContainerProps>`
    ${reset('flex')};
    position: relative;
    overflow: hidden;
    flex-direction: column;
    padding-bottom: 8px;
    ${(props) => toDimension(pick(props, ['width', 'height']))};
`;

const GlobalInputsContainer = styled.div.attrs({
    'data-test': 'GlobalInputsContainer',
})`
    flex-basis: auto;
    z-index: 100;
`;

const LayoutContainer = styled.div.attrs({
    'data-test': 'LayoutContainer',
})`
    flex-grow: 1;
    min-height: 0px;
    overflow: auto;
    position: relative;
`;

interface DashboardLayoutProps extends DashboardLayoutContainerProps {
    renderGlobalInput: () => ReactNode;
    renderLayout: NonNullable<
        React.ComponentProps<typeof SizeWrapper>['children']
    >;
    showGlobalInputs: boolean;
}

const DashboardLayout = ({
    height,
    width,
    renderGlobalInput,
    renderLayout,
    showGlobalInputs,
}: DashboardLayoutProps) => {
    // this is mainly for auto scrolling to the bottom of canvas when adding a new viz in grid layout
    const canvasRef = useRef<HTMLDivElement | null>(null);

    const globalInputsOrder = useDashboardDragMonitor();

    const renderGlobalInputsContainer = useMemo(
        () =>
            showGlobalInputs && (
                <GlobalInputsContainer>
                    {renderGlobalInput()}
                </GlobalInputsContainer>
            ),
        [showGlobalInputs, renderGlobalInput]
    );

    return (
        <DashboardLayoutContainer
            height={height}
            width={width}
            data-udf-core-version={__UDF_VERSION__ || 'unknown'}
        >
            <SortableContext
                id="sortable-global-inputs"
                items={globalInputsOrder}
            >
                {renderGlobalInputsContainer}
            </SortableContext>
            <LayoutContainer ref={canvasRef}>
                <CanvasContext.Provider value={canvasRef}>
                    <SizeWrapper>
                        {(containerDimensions) =>
                            renderLayout(containerDimensions)
                        }
                    </SizeWrapper>
                </CanvasContext.Provider>
            </LayoutContainer>
        </DashboardLayoutContainer>
    );
};

export default DashboardLayout;
