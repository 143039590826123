import TestDataSource from '@splunk/datasources/TestDataSource';
import CloudSearch from '@splunk/datasources/CloudSearch';
import type { PresetMap } from '@splunk/dashboard-types';

export default {
    dataSources: {
        'ds.test': TestDataSource,
        'ds.search': CloudSearch,
    } as PresetMap['dataSources'],
};
