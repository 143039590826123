/* eslint-disable import/first, react/prop-types */
import { pick, debounce, isEqual, get } from 'lodash';
import { _ } from '@splunk/ui-utils/i18n';
import React, { Component } from 'react';
import T from 'prop-types';
import EventListener from 'react-event-listener';
import '@splunk/visualizations-shared/i18n'; // fixme, @splunk/charting should be refactor to use locale & timezone instead of i18n functions
import TimezoneContext from '@splunk/visualization-context/TimezoneContext';
import jsCharting from '@splunk/charting-bundle';
import { toDimension } from '@splunk/visualizations-shared/style';
import styled from 'styled-components';
import Message from '@splunk/visualizations-shared/Message';
import ChartingApi from './ChartingApi';

export const NO_INPLACE_PROPS = [
    'chart',
    'chart.orientation',
    'layout.splitSeries',
    'chart.style',
    'legend.placement',
];

jsCharting.enableScaledEvents();

export const changedOptions = (a, b) =>
    Object.keys(a)
        .filter(k => !(k in b))
        .concat(Object.keys(b).filter(k => !(k in a) || a[k] !== b[k]));

const ChartingContainer = styled.div`
    overflow: hidden;
    position: relative;
    ${props => toDimension(pick(props, ['width', 'height']))};
`;

export default class Charting extends Component {
    static propTypes = {
        /**
         * width in pixel or string, defaults to 100%
         */
        width: T.oneOfType([T.string, T.number]),
        /**
         * height in pixel or string
         */
        height: T.oneOfType([T.string, T.number]),
        /**
         * visualization formatting options
         */
        options: T.object,
        /**
         * charting dataset from jsCharting.extractChartReadyData
         */
        chartData: T.object,
        /**
         * callbacks to trigger event
         */
        onEventTrigger: T.func,
        onClick: T.func,
        onSelect: T.func,
        onPointMouseOver: T.func,
        onPointMouseOut: T.func,
        /**
         * Custom style on visualization
         */
        style: T.object,
        themeKey: T.string,
        colorPalette: T.array,
        visualizationApiRef: T.func,
    };

    static defaultProps = {
        width: '100%',
        options: {},
        style: {},
        // todo: need remove it
        onEventTrigger: () => {},
        // todo: need change to a function
        onClick: null,
        onSelect: null,
        onPointMouseOver: () => {},
        onPointMouseOut: () => {},
        colorPalette: [],
        visualizationApiRef: () => {},
    };

    static contextType = TimezoneContext;

    /**
     * Check if a chart can be updated without re-init
     */
    static canUpdateInPlace(curOptions, nextOptions) {
        return (
            !NO_INPLACE_PROPS.some(p => curOptions[p] !== nextOptions[p]) &&
            !changedOptions(curOptions, nextOptions).some(p => p.toLowerCase().endsWith('color'))
        );
    }

    drawChart = debounce(() => {
        const { options, chartData, themeKey, colorPalette } = this.props;
        const updatedOptions = { ...options, shouldColorizeTooltipData: false };
        const { width, height } = this.computeChartingSize();
        const serializedTimezone = get(this, ['context', 'serializedTimezone']);
        const ianaTimezone = get(this, ['context', 'ianaTimezone']);
        const utcOffset = get(this, ['context', 'utcOffset']);
        if (!width || !height) {
            return;
        }
        if (!chartData || !chartData.length) {
            return;
        }
        try {
            if (this.needsInit) {
                this.destroyChart();
                jsCharting.setTheme(themeKey);
                if (colorPalette.length > 0) {
                    jsCharting.setColorPalette(colorPalette);
                }
                this.chart = jsCharting.createChart(this.container, {
                    ...updatedOptions,
                    drilldown: 'all',
                    ...(typeof serializedTimezone === 'string' && {
                        'time.serializedTz': serializedTimezone,
                    }),
                    ...(typeof ianaTimezone === 'string' && {
                        'time.ianaTimezone': ianaTimezone,
                    }),
                    ...(typeof utcOffset === 'number' && {
                        'time.timezoneOffset': utcOffset,
                    }),
                });
                // bind event handlers
                this.chart.on('pointClick', e => {
                    this.handleClick(e, 'point.click');
                });
                this.chart.on('pointMouseOver', e => {
                    this.handlePointMouseOver(e, 'point.mouseover');
                });
                this.chart.on('pointMouseOut', e => {
                    this.handlePointMouseOut(e, 'point.mouseout');
                });
                this.chart.on('legendClick', e => {
                    this.handleClick(e, 'legend.click');
                });
                this.chart.on('chartRangeSelect', this.handleSelect);
            }
            if (this.needsRedraw) {
                jsCharting.setTheme(themeKey);
                if (colorPalette.length > 0) {
                    jsCharting.setColorPalette(colorPalette);
                }
                this.chart.prepareAndDraw(chartData, updatedOptions, () => {});
            }
            if (this.chart) {
                this.chart.resize(width, height);
            }
            this.needsInit = false;
            this.needsRedraw = false;
        } catch (e) {
            window.console.error('Caught error rendering chart:', e);
        }
    }, 5);

    constructor(props, context) {
        super(props, context);
        this.needsInit = true;
        this.needsRedraw = true;
        this.container = null;
        this.api = new ChartingApi(this);
    }

    componentDidMount() {
        this.drawChart();
        this.props.visualizationApiRef(this.api);
    }

    // eslint-disable-next-line camelcase
    UNSAFE_componentWillReceiveProps(nextProps) {
        const { options: nextOptions, chartData: nextData } = nextProps;
        const { options: currentOptions, chartData: currentData } = this.props;
        if (!this.chart || !Charting.canUpdateInPlace(currentOptions, nextOptions)) {
            this.needsInit = true;
        }
        if (
            !isEqual(currentOptions, nextOptions) ||
            !isEqual(currentData.toJSON(), nextData.toJSON()) ||
            !isEqual(currentData.annotations, nextData.annotations)
        ) {
            this.needsRedraw = true;
        }
    }

    componentDidUpdate() {
        this.drawChart();
    }

    componentWillUnmount() {
        this.props.visualizationApiRef(null);
        this.destroyChart();
    }

    onContainerMount = container => {
        this.container = container;
        this.drawChart();
    };

    computeChartingSize = () => {
        if (this.container) {
            const rect = this.container.getBoundingClientRect();
            return {
                height: rect.height,
                width: rect.width,
            };
        }
        return {};
    };

    handleClick = (chartEvent, type) => {
        // todo: need change this after breaking all visualizations
        if (this.props.onClick && typeof this.props.onClick === 'function') {
            this.props.onClick({
                type,
                originalEvent: chartEvent,
                payload: pick(
                    chartEvent,
                    'name',
                    'value',
                    'name2',
                    'value2',
                    '_span',
                    'rowContext',
                    'modifierKey'
                ),
            });

            return;
        }

        // todo: need remove it after breaking all visualizations
        // the chartEvent here is a jQuery event
        this.props.onEventTrigger({
            type,
            originalEvent: chartEvent,
            payload: pick(
                chartEvent,
                'name',
                'value',
                'name2',
                'value2',
                '_span',
                'rowContext',
                'modifierKey'
            ),
        });
    };

    handlePointMouseOver = (chartEvent, type) => {
        this.props.onPointMouseOver({
            type,
            originalEvent: chartEvent,
            payload: pick(
                chartEvent,
                'name',
                'value',
                'name2',
                'value2',
                '_span',
                'rowContext',
                'modifierKey',
                'tooltipContext'
            ),
        });
    };

    handlePointMouseOut = (chartEvent, type) => {
        this.props.onPointMouseOut({
            type,
            originalEvent: chartEvent,
            payload: pick(
                chartEvent,
                'name',
                'value',
                'name2',
                'value2',
                '_span',
                'rowContext',
                'modifierKey',
                'tooltipContext'
            ),
        });
    };

    handleSelect = chartEvent => {
        // todo: need change this after breaking all visualizations
        if (this.props.onSelect && typeof this.props.onSelect === 'function') {
            this.props.onSelect({
                type: 'range.select',
                originalEvent: chartEvent,
                payload: pick(chartEvent, 'startXIndex', 'endXIndex', 'startXValue', 'endXValue'),
            });

            return;
        }

        // the chartEvent here is a jQuery event
        this.props.onEventTrigger({
            type: 'range.select',
            originalEvent: chartEvent,
            payload: pick(chartEvent, 'startXIndex', 'endXIndex', 'startXValue', 'endXValue'),
        });
    };

    destroyChart() {
        if (this.chart) {
            this.chart.destroy();
            this.chart = null;
        }
        this.needsRedraw = true;
        this.needsInit = true;
    }

    render() {
        const { width, height, style, chartData } = this.props;
        return (
            <ChartingContainer style={style} width={width} height={height} ref={this.onContainerMount}>
                <EventListener target={window} onResize={this.drawChart} />
                {!chartData && <Message text={_('No DataSource')} level="warning" />}
                {chartData && !chartData.length && <Message text={_('No Result')} level="info" />}
            </ChartingContainer>
        );
    }
}
