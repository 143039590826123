import { get } from 'lodash';
import { isRemoteImage } from '@splunk/visualizations-shared/RemoteImage';

/**
 * Image Api
 */
class ImageApi {
    viz: any;

    constructor(viz) {
        this.viz = viz;
    }

    /**
     * put focus on the visualization
     */
    // eslint-disable-next-line class-methods-use-this
    focus() {}

    /**
     * return snapshot of that includes inlined image
     */
    snapshot() {
        const srcFromOptions = get(this.viz.options, 'src', '');
        const isRemote = isRemoteImage(srcFromOptions);
        return {
            options: {
                ...this.viz.options,
                src:
                    isRemote && this.viz.remoteImage
                        ? get(this.viz.remoteImage, ['current', 'state', 'imageDataURI'], '')
                        : srcFromOptions,
            },
        };
    }
}

export default ImageApi;
