import React from 'react';
import T from 'prop-types';
import { withSanitizedProps } from '@splunk/visualizations-shared/SanitizeProps';
import PureSingleValueRadial from './src/components/SingleValueRadial';
import { propsConvert, propsDeprecate } from '../../utils/propUtils';

// This propsMapping is for React SingleValue to use PureSingleValue.
const propsMapping = {
    value: 'majorValue',
    deltaValue: 'trendValue',
    deltaColor: 'trendColor',
    deltaFontSize: 'trendFontSize',
    useTrendUnits: 'shouldAbbreviateTrendValue',
    useThousandSeparators: 'shouldUseThousandSeparators',
};

const deprecatedProps = ['showTrendIndicator', 'trendDisplayMode'];

const ReactSingleValueRadial = ({ ...props }) => {
    let newProps = propsConvert(props, propsMapping);
    // change props
    const trendDisplay = newProps.showTrendIndicator ? newProps.trendDisplayMode : 'off';
    newProps = {
        ...propsDeprecate(newProps, deprecatedProps),
        trendDisplay,
    };
    return <PureSingleValueRadial {...newProps} />;
};

ReactSingleValueRadial.propTypes = {
    width: T.oneOfType([T.string, T.number]),
    height: T.oneOfType([T.string, T.number]),
    style: T.object,
    value: T.oneOfType([T.string, T.number]),
    majorColor: T.string,
    majorFontSize: T.number,
    deltaValue: T.oneOfType([T.string, T.number]),
    deltaColor: T.string,
    deltaFontSize: T.number,
    trendDisplayMode: T.string,
    trendIndicatorPosition: T.oneOf(['standard', 'below', 'outside']),
    useTrendUnits: T.bool,
    showTrendIndicator: T.bool,
    backgroundColor: T.string,
    unit: T.string,
    unitPosition: T.string,
    underLabel: T.string,
    numberPrecision: T.number,
    useThousandSeparators: T.bool,
    radialBackgroundColor: T.string,
    radialStrokeColor: T.string,
    onValueClick: T.func,
};

ReactSingleValueRadial.defaultProps = {
    width: '100%',
    height: 250,
    trendDisplayMode: 'absolute',
    trendIndicatorPosition: 'standard',
    useTrendUnits: false,
    showTrendIndicator: true,
    unitPosition: 'after',
    numberPrecision: 0,
    useThousandSeparators: true,
    onValueClick: () => {},
};

export default withSanitizedProps(ReactSingleValueRadial);
