export default {
    'excerpt-command': {
        isList: false,
        args: [
            {
                key: 'maxterms',
                valueType: 'int',
            },
            {
                key: 'maxlines',
                valueType: 'int',
            },
        ],
        functions: [],
        keywords: [],
        other: [],
        list: [],
    },
    'abstract-command': {
        isList: false,
        args: [
            {
                key: 'maxterms',
                valueType: 'int',
            },
            {
                key: 'maxlines',
                valueType: 'int',
            },
        ],
        functions: [],
        keywords: [],
        other: [],
        list: [],
    },
    'accum-command': {
        isList: false,
        args: [],
        functions: [],
        keywords: ['AS'],
        other: ['field', 'field'],
        list: [],
    },
    'addcoltotals-command': {
        isList: false,
        args: [
            {
                key: 'labelfield',
                valueType: 'field',
            },
            {
                key: 'label',
                valueType: 'string',
            },
        ],
        functions: [],
        keywords: [],
        other: ['field-list'],
        list: [],
    },
    'addinfo-command': {
        isList: false,
        args: [],
        functions: [],
        keywords: [],
        other: [],
        list: [],
    },
    'addtotals-command': {
        isList: false,
        args: [
            {
                key: 'row',
                valueType: 'bool',
            },
            {
                key: 'col',
                valueType: 'bool',
            },
            {
                key: 'labelfield',
                valueType: 'field',
            },
            {
                key: 'label',
                valueType: 'string',
            },
            {
                key: 'fieldname',
                valueType: 'field',
            },
        ],
        functions: [],
        keywords: [],
        other: ['field-list'],
        list: [],
    },
    'af-command': {
        isList: false,
        args: [
            {
                key: 'classfield',
                valueType: 'field',
            },
        ],
        functions: [],
        keywords: [],
        other: [],
        list: [],
    },
    'analyzefields-command': {
        isList: false,
        args: [
            {
                key: 'classfield',
                valueType: 'field',
            },
        ],
        functions: [],
        keywords: [],
        other: [],
        list: [],
    },
    'anomalies-command': {
        isList: false,
        args: [
            {
                key: 'threshold',
                valueType: 'num',
            },
            {
                key: 'labelonly',
                valueType: 'bool',
            },
            {
                key: 'normalize',
                valueType: 'bool',
            },
            {
                key: 'maxvalues',
                valueType: 'int',
            },
            {
                key: 'field',
                valueType: 'field',
            },
            {
                key: 'denylist',
                valueType: 'filename',
            },
            {
                key: 'denylistthreshold',
                valueType: 'num',
            },
        ],
        functions: [],
        keywords: ['by'],
        other: ['field-list'],
        list: [],
    },
    'anomalousvalue-command': {
        isList: false,
        args: [
            {
                key: 'minsupcount',
                valueType: 'int',
            },
            {
                key: 'maxanofreq',
                valueType: 'num',
            },
            {
                key: 'minsupfreq',
                valueType: 'num',
            },
            {
                key: 'minnormfreq',
                valueType: 'num',
            },
            {
                key: 'action',
                valueType: '',
            },
            {
                key: 'pthresh',
                valueType: 'num',
            },
        ],
        functions: [],
        keywords: [],
        other: ['field-list'],
        list: [],
    },
    'anomalydetection-command': {
        isList: false,
        args: [
            {
                key: 'method',
                valueType: '',
            },
            {
                key: 'action',
                valueType: '',
            },
            {
                key: 'action',
                valueType: '',
            },
            {
                key: 'pthresh',
                valueType: 'num',
            },
            {
                key: 'cutoff',
                valueType: 'bool',
            },
        ],
        functions: [],
        keywords: [],
        other: ['field-list'],
        list: [],
    },
    'append-command': {
        isList: false,
        args: [
            {
                key: 'extendtimerange',
                valueType: 'bool',
            },
            {
                key: 'maxtime',
                valueType: 'int',
            },
            {
                key: 'maxout',
                valueType: 'int',
            },
            {
                key: 'timeout',
                valueType: 'int',
            },
        ],
        functions: [],
        keywords: [],
        other: ['string'],
        list: [],
    },
    'appendcols-command': {
        isList: false,
        args: [
            {
                key: 'override',
                valueType: 'bool',
            },
            {
                key: 'extendtimerange',
                valueType: 'bool',
            },
            {
                key: 'maxtime',
                valueType: 'int',
            },
            {
                key: 'maxout',
                valueType: 'int',
            },
            {
                key: 'timeout',
                valueType: 'int',
            },
        ],
        functions: [],
        keywords: [],
        other: ['string'],
        list: [],
    },
    'appendpipe-command': {
        isList: false,
        args: [
            {
                key: 'run_in_preview',
                valueType: 'bool',
            },
        ],
        functions: [],
        keywords: [],
        other: ['subpipeline'],
        list: [],
    },
    'archivebuckets-command': {
        isList: false,
        args: [
            {
                key: 'forcerun',
                valueType: '',
            },
            {
                key: 'retries',
                valueType: '',
            },
        ],
        functions: [],
        keywords: [],
        other: [],
        list: [],
    },
    'arules-command': {
        isList: false,
        args: [
            {
                key: 'sup',
                valueType: 'int',
            },
            {
                key: 'conf',
                valueType: 'num',
            },
        ],
        functions: [],
        keywords: [],
        other: ['fields'],
        list: [],
    },
    'associate-command': {
        isList: false,
        args: [
            {
                key: 'supcnt',
                valueType: 'int',
            },
            {
                key: 'supfreq',
                valueType: 'num',
            },
            {
                key: 'improv',
                valueType: 'num',
            },
        ],
        functions: [],
        keywords: [],
        other: ['field-list'],
        list: [],
    },
    'audit-command': {
        isList: false,
        args: [],
        functions: [],
        keywords: [],
        other: [],
        list: [],
    },
    'ar-command': {
        isList: false,
        args: [
            {
                key: 'p',
                valueType: '',
            },
        ],
        functions: [],
        keywords: ['AS'],
        other: ['field', 'field:newfield'],
        list: [],
    },
    'autoregress-command': {
        isList: false,
        args: [
            {
                key: 'p',
                valueType: '',
            },
        ],
        functions: [],
        keywords: ['AS'],
        other: ['field', 'field:newfield'],
        list: [],
    },
    'bucket-command': {
        isList: false,
        args: [
            {
                key: 'bins',
                valueType: 'int',
            },
            {
                key: 'minspan',
                valueType: '',
            },
            {
                key: 'span',
                valueType: '',
            },
            {
                key: 'start',
                valueType: 'num',
            },
            {
                key: 'end',
                valueType: 'num',
            },
            {
                key: 'aligntime',
                valueType: '',
            },
        ],
        functions: [],
        keywords: ['as'],
        other: ['field', 'field'],
        list: [],
    },
    'discretize-command': {
        isList: false,
        args: [
            {
                key: 'bins',
                valueType: 'int',
            },
            {
                key: 'minspan',
                valueType: '',
            },
            {
                key: 'span',
                valueType: '',
            },
            {
                key: 'start',
                valueType: 'num',
            },
            {
                key: 'end',
                valueType: 'num',
            },
            {
                key: 'aligntime',
                valueType: '',
            },
        ],
        functions: [],
        keywords: ['as'],
        other: ['field', 'field'],
        list: [],
    },
    'bin-command': {
        isList: false,
        args: [
            {
                key: 'bins',
                valueType: 'int',
            },
            {
                key: 'minspan',
                valueType: '',
            },
            {
                key: 'span',
                valueType: '',
            },
            {
                key: 'start',
                valueType: 'num',
            },
            {
                key: 'end',
                valueType: 'num',
            },
            {
                key: 'aligntime',
                valueType: '',
            },
        ],
        functions: [],
        keywords: ['as'],
        other: ['field', 'field'],
        list: [],
    },
    'bucketdir-command': {
        isList: false,
        args: [
            {
                key: 'pathfield',
                valueType: 'field',
            },
            {
                key: 'sizefield',
                valueType: 'field',
            },
            {
                key: 'maxcount',
                valueType: 'int',
            },
            {
                key: 'countfield',
                valueType: 'field',
            },
            {
                key: 'sep',
                valueType: 'char',
            },
        ],
        functions: [],
        keywords: [],
        other: [],
        list: [],
    },
    'chart-command': {
        isList: false,
        args: [
            {
                key: 'sep',
                valueType: 'string',
            },
            {
                key: 'format',
                valueType: 'string',
            },
            {
                key: 'cont',
                valueType: 'bool',
            },
            {
                key: 'limit',
                valueType: 'chart-limit-opt',
            },
            {
                key: 'agg',
                valueType: 'stats-agg-term',
            },
            {
                key: 'bins',
                valueType: 'int',
            },
            {
                key: 'minspan',
                valueType: '',
            },
            {
                key: 'span',
                valueType: '',
            },
            {
                key: 'start',
                valueType: 'num',
            },
            {
                key: 'end',
                valueType: 'num',
            },
            {
                key: 'aligntime',
                valueType: '',
            },
            {
                key: 'usenull',
                valueType: 'bool',
            },
            {
                key: 'useother',
                valueType: 'bool',
            },
            {
                key: 'nullstr',
                valueType: 'string',
            },
            {
                key: 'otherstr',
                valueType: 'string',
            },
            {
                key: 'dedup_splitvals',
                valueType: 'bool',
            },
        ],
        functions: [
            {
                name: 'c',
                parenOptional: true,
            },
            {
                name: 'count',
                parenOptional: true,
            },
            {
                name: 'dc',
                parenOptional: true,
            },
            {
                name: 'distinct_count',
                parenOptional: true,
            },
            {
                name: 'mean',
                parenOptional: true,
            },
            {
                name: 'avg',
                parenOptional: true,
            },
            {
                name: 'stdev',
                parenOptional: true,
            },
            {
                name: 'stdevp',
                parenOptional: true,
            },
            {
                name: 'var',
                parenOptional: true,
            },
            {
                name: 'varp',
                parenOptional: true,
            },
            {
                name: 'sum',
                parenOptional: true,
            },
            {
                name: 'sumsq',
                parenOptional: true,
            },
            {
                name: 'min',
                parenOptional: true,
            },
            {
                name: 'max',
                parenOptional: true,
            },
            {
                name: 'mode',
                parenOptional: true,
            },
            {
                name: 'median',
                parenOptional: true,
            },
            {
                name: 'earliest',
                parenOptional: true,
            },
            {
                name: 'first',
                parenOptional: true,
            },
            {
                name: 'last',
                parenOptional: true,
            },
            {
                name: 'latest',
                parenOptional: true,
            },
            {
                name: 'list',
                parenOptional: true,
            },
            {
                name: 'values',
                parenOptional: true,
            },
            {
                name: 'range',
                parenOptional: true,
            },
            {
                name: 'estdc',
                parenOptional: true,
            },
            {
                name: 'estdc_error',
                parenOptional: true,
            },
            {
                name: 'earliest_time',
                parenOptional: true,
            },
            {
                name: 'latest_time',
                parenOptional: true,
            },
            {
                name: 'rate',
                parenOptional: true,
            },
            {
                name: 'eval',
                parenOptional: false,
            },
            {
                name: 'abs',
                parenOptional: false,
            },
            {
                name: 'avg',
                parenOptional: false,
            },
            {
                name: 'case',
                parenOptional: false,
            },
            {
                name: 'ceiling',
                parenOptional: false,
            },
            {
                name: 'ceil',
                parenOptional: false,
            },
            {
                name: 'cidrmatch',
                parenOptional: false,
            },
            {
                name: 'coalesce',
                parenOptional: false,
            },
            {
                name: 'commands',
                parenOptional: false,
            },
            {
                name: 'exact',
                parenOptional: false,
            },
            {
                name: 'exp',
                parenOptional: false,
            },
            {
                name: 'false',
                parenOptional: false,
            },
            {
                name: 'floor',
                parenOptional: false,
            },
            {
                name: 'if',
                parenOptional: false,
            },
            {
                name: 'ifnull',
                parenOptional: false,
            },
            {
                name: 'isbool',
                parenOptional: false,
            },
            {
                name: 'isint',
                parenOptional: false,
            },
            {
                name: 'isnotnull',
                parenOptional: false,
            },
            {
                name: 'isnull',
                parenOptional: false,
            },
            {
                name: 'isnum',
                parenOptional: false,
            },
            {
                name: 'isstr',
                parenOptional: false,
            },
            {
                name: 'len',
                parenOptional: false,
            },
            {
                name: 'like',
                parenOptional: false,
            },
            {
                name: 'ln',
                parenOptional: false,
            },
            {
                name: 'log',
                parenOptional: false,
            },
            {
                name: 'lookup',
                parenOptional: false,
            },
            {
                name: 'lower',
                parenOptional: false,
            },
            {
                name: 'match',
                parenOptional: false,
            },
            {
                name: 'max',
                parenOptional: false,
            },
            {
                name: 'md5',
                parenOptional: false,
            },
            {
                name: 'min',
                parenOptional: false,
            },
            {
                name: 'mvappend',
                parenOptional: false,
            },
            {
                name: 'mvcount',
                parenOptional: false,
            },
            {
                name: 'mvdedup',
                parenOptional: false,
            },
            {
                name: 'mvindex',
                parenOptional: false,
            },
            {
                name: 'mvfilter',
                parenOptional: false,
            },
            {
                name: 'mvfind',
                parenOptional: false,
            },
            {
                name: 'mvjoin',
                parenOptional: false,
            },
            {
                name: 'mvmap',
                parenOptional: false,
            },
            {
                name: 'mvrange',
                parenOptional: false,
            },
            {
                name: 'mvsort',
                parenOptional: false,
            },
            {
                name: 'mvzip',
                parenOptional: false,
            },
            {
                name: 'now',
                parenOptional: false,
            },
            {
                name: 'null',
                parenOptional: false,
            },
            {
                name: 'nullif',
                parenOptional: false,
            },
            {
                name: 'pi',
                parenOptional: false,
            },
            {
                name: 'pow',
                parenOptional: false,
            },
            {
                name: 'random',
                parenOptional: false,
            },
            {
                name: 'relative_time',
                parenOptional: false,
            },
            {
                name: 'replace',
                parenOptional: false,
            },
            {
                name: 'round',
                parenOptional: false,
            },
            {
                name: 'searchmatch',
                parenOptional: false,
            },
            {
                name: 'sha1',
                parenOptional: false,
            },
            {
                name: 'sha256',
                parenOptional: false,
            },
            {
                name: 'sha512',
                parenOptional: false,
            },
            {
                name: 'sigfig',
                parenOptional: false,
            },
            {
                name: 'spath',
                parenOptional: false,
            },
            {
                name: 'split',
                parenOptional: false,
            },
            {
                name: 'sqrt',
                parenOptional: false,
            },
            {
                name: 'strftime',
                parenOptional: false,
            },
            {
                name: 'strptime',
                parenOptional: false,
            },
            {
                name: 'substr',
                parenOptional: false,
            },
            {
                name: 'sum',
                parenOptional: false,
            },
            {
                name: 'time',
                parenOptional: false,
            },
            {
                name: 'tostring',
                parenOptional: false,
            },
            {
                name: 'trim',
                parenOptional: false,
            },
            {
                name: 'ltrim',
                parenOptional: false,
            },
            {
                name: 'rtrim',
                parenOptional: false,
            },
            {
                name: 'true',
                parenOptional: false,
            },
            {
                name: 'typeof',
                parenOptional: false,
            },
            {
                name: 'upper',
                parenOptional: false,
            },
            {
                name: 'urldecode',
                parenOptional: false,
            },
            {
                name: 'validate',
                parenOptional: false,
            },
            {
                name: 'tonumber',
                parenOptional: false,
            },
            {
                name: 'acos',
                parenOptional: false,
            },
            {
                name: 'acosh',
                parenOptional: false,
            },
            {
                name: 'asin',
                parenOptional: false,
            },
            {
                name: 'asinh',
                parenOptional: false,
            },
            {
                name: 'atan',
                parenOptional: false,
            },
            {
                name: 'atan2',
                parenOptional: false,
            },
            {
                name: 'atanh',
                parenOptional: false,
            },
            {
                name: 'cos',
                parenOptional: false,
            },
            {
                name: 'cosh',
                parenOptional: false,
            },
            {
                name: 'hypot',
                parenOptional: false,
            },
            {
                name: 'sin',
                parenOptional: false,
            },
            {
                name: 'sinh',
                parenOptional: false,
            },
            {
                name: 'tan',
                parenOptional: false,
            },
            {
                name: 'tanh',
                parenOptional: false,
            },
            {
                name: 'json_array_to_mv',
                parenOptional: false,
            },
            {
                name: 'mv_to_json_array',
                parenOptional: false,
            },
            {
                name: 'json_append',
                parenOptional: false,
            },
            {
                name: 'json_extend',
                parenOptional: false,
            },
            {
                name: 'json_extract_exact',
                parenOptional: false,
            },
            {
                name: 'json_set_exact',
                parenOptional: false,
            },
            {
                name: 'sparkline',
                parenOptional: false,
            },
            {
                name: 'c',
                parenOptional: false,
            },
            {
                name: 'count',
                parenOptional: false,
            },
            {
                name: 'dc',
                parenOptional: false,
            },
            {
                name: 'mean',
                parenOptional: false,
            },
            {
                name: 'avg',
                parenOptional: false,
            },
            {
                name: 'stdev',
                parenOptional: false,
            },
            {
                name: 'stdevp',
                parenOptional: false,
            },
            {
                name: 'var',
                parenOptional: false,
            },
            {
                name: 'varp',
                parenOptional: false,
            },
            {
                name: 'sum',
                parenOptional: false,
            },
            {
                name: 'sumsq',
                parenOptional: false,
            },
            {
                name: 'min',
                parenOptional: false,
            },
            {
                name: 'max',
                parenOptional: false,
            },
            {
                name: 'range',
                parenOptional: false,
            },
            {
                name: 'c',
                parenOptional: false,
            },
            {
                name: 'count',
                parenOptional: false,
            },
            {
                name: 'dc',
                parenOptional: false,
            },
            {
                name: 'distinct_count',
                parenOptional: false,
            },
            {
                name: 'mean',
                parenOptional: false,
            },
            {
                name: 'avg',
                parenOptional: false,
            },
            {
                name: 'stdev',
                parenOptional: false,
            },
            {
                name: 'stdevp',
                parenOptional: false,
            },
            {
                name: 'var',
                parenOptional: false,
            },
            {
                name: 'varp',
                parenOptional: false,
            },
            {
                name: 'sum',
                parenOptional: false,
            },
            {
                name: 'sumsq',
                parenOptional: false,
            },
            {
                name: 'min',
                parenOptional: false,
            },
            {
                name: 'max',
                parenOptional: false,
            },
            {
                name: 'mode',
                parenOptional: false,
            },
            {
                name: 'median',
                parenOptional: false,
            },
            {
                name: 'earliest',
                parenOptional: false,
            },
            {
                name: 'first',
                parenOptional: false,
            },
            {
                name: 'last',
                parenOptional: false,
            },
            {
                name: 'latest',
                parenOptional: false,
            },
            {
                name: 'list',
                parenOptional: false,
            },
            {
                name: 'values',
                parenOptional: false,
            },
            {
                name: 'range',
                parenOptional: false,
            },
            {
                name: 'estdc',
                parenOptional: false,
            },
            {
                name: 'estdc_error',
                parenOptional: false,
            },
            {
                name: 'earliest_time',
                parenOptional: false,
            },
            {
                name: 'latest_time',
                parenOptional: false,
            },
            {
                name: 'rate',
                parenOptional: false,
            },
        ],
        keywords: ['LIKE', 'AND|OR|XOR', 'as', 'BY', 'where', 'OVER'],
        other: ['field', 'num', 'string', 'wc-field', 'int', '|'],
        list: [],
    },
    'slc-command': {
        isList: false,
        args: [
            {
                key: 't',
                valueType: 'num',
            },
            {
                key: 'delims',
                valueType: 'string',
            },
            {
                key: 'showcount',
                valueType: 'bool',
            },
            {
                key: 'countfield',
                valueType: 'field',
            },
            {
                key: 'labelfield',
                valueType: 'field',
            },
            {
                key: 'field',
                valueType: 'field',
            },
            {
                key: 'labelonly',
                valueType: 'bool',
            },
            {
                key: 'match',
                valueType: '',
            },
        ],
        functions: [],
        keywords: [],
        other: [],
        list: [],
    },
    'cluster-command': {
        isList: false,
        args: [
            {
                key: 't',
                valueType: 'num',
            },
            {
                key: 'delims',
                valueType: 'string',
            },
            {
                key: 'showcount',
                valueType: 'bool',
            },
            {
                key: 'countfield',
                valueType: 'field',
            },
            {
                key: 'labelfield',
                valueType: 'field',
            },
            {
                key: 'field',
                valueType: 'field',
            },
            {
                key: 'labelonly',
                valueType: 'bool',
            },
            {
                key: 'match',
                valueType: '',
            },
        ],
        functions: [],
        keywords: [],
        other: [],
        list: [],
    },
    'cofilter-command': {
        isList: false,
        args: [],
        functions: [],
        keywords: [],
        other: [],
        list: [],
    },
    'collapse-command': {
        isList: false,
        args: [
            {
                key: 'chunksize',
                valueType: 'num',
            },
            {
                key: 'force',
                valueType: 'bool',
            },
        ],
        functions: [],
        keywords: [],
        other: [],
        list: [],
    },
    'stash-command': {
        isList: false,
        args: [
            {
                key: 'index',
                valueType: 'string',
            },
            {
                key: 'addtime',
                valueType: 'bool',
            },
            {
                key: 'file',
                valueType: 'string',
            },
            {
                key: 'spool',
                valueType: 'bool',
            },
            {
                key: 'marker',
                valueType: 'string',
            },
            {
                key: 'testmode',
                valueType: 'bool',
            },
            {
                key: 'run_in_preview',
                valueType: 'bool',
            },
            {
                key: 'host',
                valueType: 'string',
            },
            {
                key: 'source',
                valueType: 'string',
            },
            {
                key: 'sourcetype',
                valueType: 'string',
            },
            {
                key: 'uselb',
                valueType: 'bool',
            },
            {
                key: 'output_format',
                valueType: '',
            },
        ],
        functions: [],
        keywords: [],
        other: [],
        list: [],
    },
    'summaryindex-command': {
        isList: false,
        args: [
            {
                key: 'index',
                valueType: 'string',
            },
            {
                key: 'addtime',
                valueType: 'bool',
            },
            {
                key: 'file',
                valueType: 'string',
            },
            {
                key: 'spool',
                valueType: 'bool',
            },
            {
                key: 'marker',
                valueType: 'string',
            },
            {
                key: 'testmode',
                valueType: 'bool',
            },
            {
                key: 'run_in_preview',
                valueType: 'bool',
            },
            {
                key: 'host',
                valueType: 'string',
            },
            {
                key: 'source',
                valueType: 'string',
            },
            {
                key: 'sourcetype',
                valueType: 'string',
            },
            {
                key: 'uselb',
                valueType: 'bool',
            },
            {
                key: 'output_format',
                valueType: '',
            },
        ],
        functions: [],
        keywords: [],
        other: [],
        list: [],
    },
    'sumindex-command': {
        isList: false,
        args: [
            {
                key: 'index',
                valueType: 'string',
            },
            {
                key: 'addtime',
                valueType: 'bool',
            },
            {
                key: 'file',
                valueType: 'string',
            },
            {
                key: 'spool',
                valueType: 'bool',
            },
            {
                key: 'marker',
                valueType: 'string',
            },
            {
                key: 'testmode',
                valueType: 'bool',
            },
            {
                key: 'run_in_preview',
                valueType: 'bool',
            },
            {
                key: 'host',
                valueType: 'string',
            },
            {
                key: 'source',
                valueType: 'string',
            },
            {
                key: 'sourcetype',
                valueType: 'string',
            },
            {
                key: 'uselb',
                valueType: 'bool',
            },
            {
                key: 'output_format',
                valueType: '',
            },
        ],
        functions: [],
        keywords: [],
        other: [],
        list: [],
    },
    'collect-command': {
        isList: false,
        args: [
            {
                key: 'index',
                valueType: 'string',
            },
            {
                key: 'addtime',
                valueType: 'bool',
            },
            {
                key: 'file',
                valueType: 'string',
            },
            {
                key: 'spool',
                valueType: 'bool',
            },
            {
                key: 'marker',
                valueType: 'string',
            },
            {
                key: 'testmode',
                valueType: 'bool',
            },
            {
                key: 'run_in_preview',
                valueType: 'bool',
            },
            {
                key: 'host',
                valueType: 'string',
            },
            {
                key: 'source',
                valueType: 'string',
            },
            {
                key: 'sourcetype',
                valueType: 'string',
            },
            {
                key: 'uselb',
                valueType: 'bool',
            },
            {
                key: 'output_format',
                valueType: '',
            },
        ],
        functions: [],
        keywords: [],
        other: [],
        list: [],
    },
    'concurrency-command': {
        isList: false,
        args: [
            {
                key: 'duration',
                valueType: 'field',
            },
            {
                key: 'start',
                valueType: 'field',
            },
            {
                key: 'output',
                valueType: 'field',
            },
        ],
        functions: [],
        keywords: [],
        other: [],
        list: [],
    },
    'counttable-command': {
        isList: false,
        args: [
            {
                key: 'maxrows',
                valueType: 'int',
            },
            {
                key: 'maxcols',
                valueType: 'int',
            },
            {
                key: 'mincolcover',
                valueType: 'num',
            },
            {
                key: 'minrowcover',
                valueType: 'num',
            },
            {
                key: 'usetotal',
                valueType: 'bool',
            },
            {
                key: 'totalstr',
                valueType: 'field',
            },
        ],
        functions: [],
        keywords: [],
        other: ['field', 'field'],
        list: [],
    },
    'ctable-command': {
        isList: false,
        args: [
            {
                key: 'maxrows',
                valueType: 'int',
            },
            {
                key: 'maxcols',
                valueType: 'int',
            },
            {
                key: 'mincolcover',
                valueType: 'num',
            },
            {
                key: 'minrowcover',
                valueType: 'num',
            },
            {
                key: 'usetotal',
                valueType: 'bool',
            },
            {
                key: 'totalstr',
                valueType: 'field',
            },
        ],
        functions: [],
        keywords: [],
        other: ['field', 'field'],
        list: [],
    },
    'contingency-command': {
        isList: false,
        args: [
            {
                key: 'maxrows',
                valueType: 'int',
            },
            {
                key: 'maxcols',
                valueType: 'int',
            },
            {
                key: 'mincolcover',
                valueType: 'num',
            },
            {
                key: 'minrowcover',
                valueType: 'num',
            },
            {
                key: 'usetotal',
                valueType: 'bool',
            },
            {
                key: 'totalstr',
                valueType: 'field',
            },
        ],
        functions: [],
        keywords: [],
        other: ['field', 'field'],
        list: [],
    },
    'convert-command': {
        isList: false,
        args: [
            {
                key: 'timeformat',
                valueType: 'string',
            },
        ],
        functions: [
            {
                name: 'auto',
                parenOptional: true,
            },
            {
                name: 'dur2sec',
                parenOptional: false,
            },
            {
                name: 'mstime',
                parenOptional: false,
            },
            {
                name: 'memk',
                parenOptional: false,
            },
            {
                name: 'none',
                parenOptional: false,
            },
            {
                name: 'num',
                parenOptional: false,
            },
            {
                name: 'rmunit',
                parenOptional: false,
            },
            {
                name: 'rmcomma',
                parenOptional: false,
            },
            {
                name: 'ctime',
                parenOptional: false,
            },
            {
                name: 'mktime',
                parenOptional: false,
            },
        ],
        keywords: ['as'],
        other: ['wc-field', 'wc-field'],
        list: [],
    },
    'copyresults-command': {
        isList: false,
        args: [
            {
                key: 'dest',
                valueType: 'string',
            },
            {
                key: 'sid',
                valueType: 'string',
            },
        ],
        functions: [],
        keywords: [],
        other: [],
        list: [],
    },
    'correlate-command': {
        isList: false,
        args: [],
        functions: [],
        keywords: [],
        other: [],
        list: [],
    },
    'createrss-command': {
        isList: false,
        args: [
            {
                key: 'path',
                valueType: 'string',
            },
            {
                key: 'name',
                valueType: 'string',
            },
            {
                key: 'link',
                valueType: 'string',
            },
            {
                key: 'descr',
                valueType: 'string',
            },
            {
                key: 'count',
                valueType: 'int',
            },
            {
                key: 'graceful',
                valueType: 'bool',
            },
        ],
        functions: [],
        keywords: [],
        other: [],
        list: [],
    },
    'datamodel-command': {
        isList: false,
        args: [
            {
                key: 'allow_old_summaries',
                valueType: 'bool',
            },
            {
                key: 'summariesonly',
                valueType: 'bool',
            },
            {
                key: 'strict_fields',
                valueType: 'bool',
            },
        ],
        functions: [],
        keywords: [],
        other: ['string'],
        list: [],
    },
    'dbinspect-command': {
        isList: false,
        args: [
            {
                key: 'index',
                valueType: 'string',
            },
            {
                key: 'span',
                valueType: '',
            },
            {
                key: 'timeformat',
                valueType: 'string',
            },
            {
                key: 'corruptonly',
                valueType: 'bool',
            },
            {
                key: 'bucketstate',
                valueType: 'bucketstate-type',
            },
        ],
        functions: [],
        keywords: [],
        other: [],
        list: [],
    },
    'debug-command': {
        isList: false,
        args: [
            {
                key: 'cmd',
                valueType: 'debug-method',
            },
            {
                key: 'param1',
                valueType: 'string',
            },
            {
                key: 'param2',
                valueType: 'string',
            },
        ],
        functions: [],
        keywords: [],
        other: ['string'],
        list: [],
    },
    'dedup-command': {
        isList: false,
        args: [
            {
                key: 'keepevents',
                valueType: 'bool',
            },
            {
                key: 'keepempty',
                valueType: 'bool',
            },
            {
                key: 'consecutive',
                valueType: 'bool',
            },
        ],
        functions: [],
        keywords: ['sortby'],
        other: ['int', 'field-list', 'field'],
        list: [],
    },
    'delete-command': {
        isList: false,
        args: [],
        functions: [],
        keywords: [],
        other: [],
        list: [],
    },
    'delta-command': {
        isList: false,
        args: [
            {
                key: 'p',
                valueType: 'int',
            },
        ],
        functions: [],
        keywords: ['as'],
        other: ['field', 'field:newfield'],
        list: [],
    },
    'dfsjob-command': {
        isList: false,
        args: [],
        functions: [],
        keywords: [],
        other: ['string'],
        list: [],
    },
    'diff-command': {
        isList: false,
        args: [
            {
                key: 'position1',
                valueType: 'int',
            },
            {
                key: 'position2',
                valueType: 'int',
            },
            {
                key: 'attribute',
                valueType: 'string',
            },
            {
                key: 'diffheader',
                valueType: 'bool',
            },
            {
                key: 'context',
                valueType: 'bool',
            },
            {
                key: 'maxlen',
                valueType: 'int',
            },
        ],
        functions: [],
        keywords: [],
        other: [],
        list: [],
    },
    'dispatch-command': {
        isList: false,
        args: [
            {
                key: 'ttl',
                valueType: 'num',
            },
            {
                key: 'maxresults',
                valueType: 'num',
            },
            {
                key: 'maxtime',
                valueType: 'num',
            },
            {
                key: 'id',
                valueType: 'string',
            },
            {
                key: 'spawn_process',
                valueType: 'bool',
            },
            {
                key: 'label',
                valueType: 'string',
            },
            {
                key: 'start_time',
                valueType: 'num',
            },
            {
                key: 'end_time',
                valueType: 'num',
            },
        ],
        functions: [],
        keywords: [],
        other: ['string', 'search-pipeline'],
        list: [],
    },
    'dump-command': {
        isList: false,
        args: [
            {
                key: 'basefilename',
                valueType: 'string',
            },
            {
                key: 'rollsize',
                valueType: 'num',
            },
            {
                key: 'compress',
                valueType: 'int',
            },
            {
                key: 'format',
                valueType: 'string',
            },
            {
                key: 'fields',
                valueType: 'comma-delimited-string',
            },
        ],
        functions: [],
        keywords: [],
        other: [],
        list: [],
    },
    'editinfo-command': {
        isList: false,
        args: [
            {
                key: 'keyset',
                valueType: '',
            },
            {
                key: 'starttime',
                valueType: '',
            },
            {
                key: 'endtime',
                valueType: '',
            },
            {
                key: 'msg_error',
                valueType: '',
            },
            {
                key: 'msg_warn',
                valueType: '',
            },
            {
                key: 'msg_info',
                valueType: '',
            },
            {
                key: 'msg_debug',
                valueType: '',
            },
        ],
        functions: [],
        keywords: [],
        other: [],
        list: [],
    },
    'erex-command': {
        isList: false,
        args: [
            {
                key: 'examples',
                valueType: 'erex-examples',
            },
            {
                key: 'counterexamples',
                valueType: 'erex-examples',
            },
            {
                key: 'fromfield',
                valueType: 'field',
            },
            {
                key: 'maxtrainers',
                valueType: 'int',
            },
        ],
        functions: [],
        keywords: [],
        other: ['field'],
        list: [],
    },
    'eval-command': {
        isList: false,
        args: [],
        functions: [
            {
                name: 'abs',
                parenOptional: false,
            },
            {
                name: 'avg',
                parenOptional: false,
            },
            {
                name: 'case',
                parenOptional: false,
            },
            {
                name: 'ceiling',
                parenOptional: false,
            },
            {
                name: 'ceil',
                parenOptional: false,
            },
            {
                name: 'cidrmatch',
                parenOptional: false,
            },
            {
                name: 'coalesce',
                parenOptional: false,
            },
            {
                name: 'commands',
                parenOptional: false,
            },
            {
                name: 'exact',
                parenOptional: false,
            },
            {
                name: 'exp',
                parenOptional: false,
            },
            {
                name: 'false',
                parenOptional: false,
            },
            {
                name: 'floor',
                parenOptional: false,
            },
            {
                name: 'if',
                parenOptional: false,
            },
            {
                name: 'ifnull',
                parenOptional: false,
            },
            {
                name: 'isbool',
                parenOptional: false,
            },
            {
                name: 'isint',
                parenOptional: false,
            },
            {
                name: 'isnotnull',
                parenOptional: false,
            },
            {
                name: 'isnull',
                parenOptional: false,
            },
            {
                name: 'isnum',
                parenOptional: false,
            },
            {
                name: 'isstr',
                parenOptional: false,
            },
            {
                name: 'len',
                parenOptional: false,
            },
            {
                name: 'like',
                parenOptional: false,
            },
            {
                name: 'ln',
                parenOptional: false,
            },
            {
                name: 'log',
                parenOptional: false,
            },
            {
                name: 'lookup',
                parenOptional: false,
            },
            {
                name: 'lower',
                parenOptional: false,
            },
            {
                name: 'match',
                parenOptional: false,
            },
            {
                name: 'max',
                parenOptional: false,
            },
            {
                name: 'md5',
                parenOptional: false,
            },
            {
                name: 'min',
                parenOptional: false,
            },
            {
                name: 'mvappend',
                parenOptional: false,
            },
            {
                name: 'mvcount',
                parenOptional: false,
            },
            {
                name: 'mvdedup',
                parenOptional: false,
            },
            {
                name: 'mvindex',
                parenOptional: false,
            },
            {
                name: 'mvfilter',
                parenOptional: false,
            },
            {
                name: 'mvfind',
                parenOptional: false,
            },
            {
                name: 'mvjoin',
                parenOptional: false,
            },
            {
                name: 'mvmap',
                parenOptional: false,
            },
            {
                name: 'mvrange',
                parenOptional: false,
            },
            {
                name: 'mvsort',
                parenOptional: false,
            },
            {
                name: 'mvzip',
                parenOptional: false,
            },
            {
                name: 'now',
                parenOptional: false,
            },
            {
                name: 'null',
                parenOptional: false,
            },
            {
                name: 'nullif',
                parenOptional: false,
            },
            {
                name: 'pi',
                parenOptional: false,
            },
            {
                name: 'pow',
                parenOptional: false,
            },
            {
                name: 'random',
                parenOptional: false,
            },
            {
                name: 'relative_time',
                parenOptional: false,
            },
            {
                name: 'replace',
                parenOptional: false,
            },
            {
                name: 'round',
                parenOptional: false,
            },
            {
                name: 'searchmatch',
                parenOptional: false,
            },
            {
                name: 'sha1',
                parenOptional: false,
            },
            {
                name: 'sha256',
                parenOptional: false,
            },
            {
                name: 'sha512',
                parenOptional: false,
            },
            {
                name: 'sigfig',
                parenOptional: false,
            },
            {
                name: 'spath',
                parenOptional: false,
            },
            {
                name: 'split',
                parenOptional: false,
            },
            {
                name: 'sqrt',
                parenOptional: false,
            },
            {
                name: 'strftime',
                parenOptional: false,
            },
            {
                name: 'strptime',
                parenOptional: false,
            },
            {
                name: 'substr',
                parenOptional: false,
            },
            {
                name: 'sum',
                parenOptional: false,
            },
            {
                name: 'time',
                parenOptional: false,
            },
            {
                name: 'tostring',
                parenOptional: false,
            },
            {
                name: 'trim',
                parenOptional: false,
            },
            {
                name: 'ltrim',
                parenOptional: false,
            },
            {
                name: 'rtrim',
                parenOptional: false,
            },
            {
                name: 'true',
                parenOptional: false,
            },
            {
                name: 'typeof',
                parenOptional: false,
            },
            {
                name: 'upper',
                parenOptional: false,
            },
            {
                name: 'urldecode',
                parenOptional: false,
            },
            {
                name: 'validate',
                parenOptional: false,
            },
            {
                name: 'tonumber',
                parenOptional: false,
            },
            {
                name: 'acos',
                parenOptional: false,
            },
            {
                name: 'acosh',
                parenOptional: false,
            },
            {
                name: 'asin',
                parenOptional: false,
            },
            {
                name: 'asinh',
                parenOptional: false,
            },
            {
                name: 'atan',
                parenOptional: false,
            },
            {
                name: 'atan2',
                parenOptional: false,
            },
            {
                name: 'atanh',
                parenOptional: false,
            },
            {
                name: 'cos',
                parenOptional: false,
            },
            {
                name: 'cosh',
                parenOptional: false,
            },
            {
                name: 'hypot',
                parenOptional: false,
            },
            {
                name: 'sin',
                parenOptional: false,
            },
            {
                name: 'sinh',
                parenOptional: false,
            },
            {
                name: 'tan',
                parenOptional: false,
            },
            {
                name: 'tanh',
                parenOptional: false,
            },
            {
                name: 'json_array_to_mv',
                parenOptional: false,
            },
            {
                name: 'mv_to_json_array',
                parenOptional: false,
            },
            {
                name: 'json_append',
                parenOptional: false,
            },
            {
                name: 'json_extend',
                parenOptional: false,
            },
            {
                name: 'json_extract_exact',
                parenOptional: false,
            },
            {
                name: 'json_set_exact',
                parenOptional: false,
            },
        ],
        keywords: ['LIKE', 'AND|OR|XOR'],
        other: ['field', 'num', 'string'],
        list: [],
    },
    'eventcount-command': {
        isList: false,
        args: [
            {
                key: 'index',
                valueType: 'string',
            },
            {
                key: 'summarize',
                valueType: 'bool',
            },
            {
                key: 'report_size',
                valueType: 'bool',
            },
            {
                key: 'list_vix',
                valueType: 'bool',
            },
        ],
        functions: [],
        keywords: [],
        other: [],
        list: [],
    },
    'eventstats-command': {
        isList: false,
        args: [
            {
                key: 'allnum',
                valueType: 'bool',
            },
        ],
        functions: [
            {
                name: 'c',
                parenOptional: true,
            },
            {
                name: 'count',
                parenOptional: true,
            },
            {
                name: 'dc',
                parenOptional: true,
            },
            {
                name: 'distinct_count',
                parenOptional: true,
            },
            {
                name: 'mean',
                parenOptional: true,
            },
            {
                name: 'avg',
                parenOptional: true,
            },
            {
                name: 'stdev',
                parenOptional: true,
            },
            {
                name: 'stdevp',
                parenOptional: true,
            },
            {
                name: 'var',
                parenOptional: true,
            },
            {
                name: 'varp',
                parenOptional: true,
            },
            {
                name: 'sum',
                parenOptional: true,
            },
            {
                name: 'sumsq',
                parenOptional: true,
            },
            {
                name: 'min',
                parenOptional: true,
            },
            {
                name: 'max',
                parenOptional: true,
            },
            {
                name: 'mode',
                parenOptional: true,
            },
            {
                name: 'median',
                parenOptional: true,
            },
            {
                name: 'earliest',
                parenOptional: true,
            },
            {
                name: 'first',
                parenOptional: true,
            },
            {
                name: 'last',
                parenOptional: true,
            },
            {
                name: 'latest',
                parenOptional: true,
            },
            {
                name: 'list',
                parenOptional: true,
            },
            {
                name: 'values',
                parenOptional: true,
            },
            {
                name: 'range',
                parenOptional: true,
            },
            {
                name: 'estdc',
                parenOptional: true,
            },
            {
                name: 'estdc_error',
                parenOptional: true,
            },
            {
                name: 'earliest_time',
                parenOptional: true,
            },
            {
                name: 'latest_time',
                parenOptional: true,
            },
            {
                name: 'rate',
                parenOptional: true,
            },
            {
                name: 'eval',
                parenOptional: false,
            },
            {
                name: 'abs',
                parenOptional: false,
            },
            {
                name: 'avg',
                parenOptional: false,
            },
            {
                name: 'case',
                parenOptional: false,
            },
            {
                name: 'ceiling',
                parenOptional: false,
            },
            {
                name: 'ceil',
                parenOptional: false,
            },
            {
                name: 'cidrmatch',
                parenOptional: false,
            },
            {
                name: 'coalesce',
                parenOptional: false,
            },
            {
                name: 'commands',
                parenOptional: false,
            },
            {
                name: 'exact',
                parenOptional: false,
            },
            {
                name: 'exp',
                parenOptional: false,
            },
            {
                name: 'false',
                parenOptional: false,
            },
            {
                name: 'floor',
                parenOptional: false,
            },
            {
                name: 'if',
                parenOptional: false,
            },
            {
                name: 'ifnull',
                parenOptional: false,
            },
            {
                name: 'isbool',
                parenOptional: false,
            },
            {
                name: 'isint',
                parenOptional: false,
            },
            {
                name: 'isnotnull',
                parenOptional: false,
            },
            {
                name: 'isnull',
                parenOptional: false,
            },
            {
                name: 'isnum',
                parenOptional: false,
            },
            {
                name: 'isstr',
                parenOptional: false,
            },
            {
                name: 'len',
                parenOptional: false,
            },
            {
                name: 'like',
                parenOptional: false,
            },
            {
                name: 'ln',
                parenOptional: false,
            },
            {
                name: 'log',
                parenOptional: false,
            },
            {
                name: 'lookup',
                parenOptional: false,
            },
            {
                name: 'lower',
                parenOptional: false,
            },
            {
                name: 'match',
                parenOptional: false,
            },
            {
                name: 'max',
                parenOptional: false,
            },
            {
                name: 'md5',
                parenOptional: false,
            },
            {
                name: 'min',
                parenOptional: false,
            },
            {
                name: 'mvappend',
                parenOptional: false,
            },
            {
                name: 'mvcount',
                parenOptional: false,
            },
            {
                name: 'mvdedup',
                parenOptional: false,
            },
            {
                name: 'mvindex',
                parenOptional: false,
            },
            {
                name: 'mvfilter',
                parenOptional: false,
            },
            {
                name: 'mvfind',
                parenOptional: false,
            },
            {
                name: 'mvjoin',
                parenOptional: false,
            },
            {
                name: 'mvmap',
                parenOptional: false,
            },
            {
                name: 'mvrange',
                parenOptional: false,
            },
            {
                name: 'mvsort',
                parenOptional: false,
            },
            {
                name: 'mvzip',
                parenOptional: false,
            },
            {
                name: 'now',
                parenOptional: false,
            },
            {
                name: 'null',
                parenOptional: false,
            },
            {
                name: 'nullif',
                parenOptional: false,
            },
            {
                name: 'pi',
                parenOptional: false,
            },
            {
                name: 'pow',
                parenOptional: false,
            },
            {
                name: 'random',
                parenOptional: false,
            },
            {
                name: 'relative_time',
                parenOptional: false,
            },
            {
                name: 'replace',
                parenOptional: false,
            },
            {
                name: 'round',
                parenOptional: false,
            },
            {
                name: 'searchmatch',
                parenOptional: false,
            },
            {
                name: 'sha1',
                parenOptional: false,
            },
            {
                name: 'sha256',
                parenOptional: false,
            },
            {
                name: 'sha512',
                parenOptional: false,
            },
            {
                name: 'sigfig',
                parenOptional: false,
            },
            {
                name: 'spath',
                parenOptional: false,
            },
            {
                name: 'split',
                parenOptional: false,
            },
            {
                name: 'sqrt',
                parenOptional: false,
            },
            {
                name: 'strftime',
                parenOptional: false,
            },
            {
                name: 'strptime',
                parenOptional: false,
            },
            {
                name: 'substr',
                parenOptional: false,
            },
            {
                name: 'sum',
                parenOptional: false,
            },
            {
                name: 'time',
                parenOptional: false,
            },
            {
                name: 'tostring',
                parenOptional: false,
            },
            {
                name: 'trim',
                parenOptional: false,
            },
            {
                name: 'ltrim',
                parenOptional: false,
            },
            {
                name: 'rtrim',
                parenOptional: false,
            },
            {
                name: 'true',
                parenOptional: false,
            },
            {
                name: 'typeof',
                parenOptional: false,
            },
            {
                name: 'upper',
                parenOptional: false,
            },
            {
                name: 'urldecode',
                parenOptional: false,
            },
            {
                name: 'validate',
                parenOptional: false,
            },
            {
                name: 'tonumber',
                parenOptional: false,
            },
            {
                name: 'acos',
                parenOptional: false,
            },
            {
                name: 'acosh',
                parenOptional: false,
            },
            {
                name: 'asin',
                parenOptional: false,
            },
            {
                name: 'asinh',
                parenOptional: false,
            },
            {
                name: 'atan',
                parenOptional: false,
            },
            {
                name: 'atan2',
                parenOptional: false,
            },
            {
                name: 'atanh',
                parenOptional: false,
            },
            {
                name: 'cos',
                parenOptional: false,
            },
            {
                name: 'cosh',
                parenOptional: false,
            },
            {
                name: 'hypot',
                parenOptional: false,
            },
            {
                name: 'sin',
                parenOptional: false,
            },
            {
                name: 'sinh',
                parenOptional: false,
            },
            {
                name: 'tan',
                parenOptional: false,
            },
            {
                name: 'tanh',
                parenOptional: false,
            },
            {
                name: 'json_array_to_mv',
                parenOptional: false,
            },
            {
                name: 'mv_to_json_array',
                parenOptional: false,
            },
            {
                name: 'json_append',
                parenOptional: false,
            },
            {
                name: 'json_extend',
                parenOptional: false,
            },
            {
                name: 'json_extract_exact',
                parenOptional: false,
            },
            {
                name: 'json_set_exact',
                parenOptional: false,
            },
        ],
        keywords: ['LIKE', 'AND|OR|XOR', 'as', 'by'],
        other: ['field', 'num', 'string', 'wc-field', 'field-list'],
        list: [],
    },
    'kv-command': {
        isList: false,
        args: [
            {
                key: 'segment',
                valueType: 'bool',
            },
            {
                key: 'reload',
                valueType: 'bool',
            },
            {
                key: 'kvdelim',
                valueType: 'string',
            },
            {
                key: 'pairdelim',
                valueType: 'string',
            },
            {
                key: 'limit',
                valueType: 'int',
            },
            {
                key: 'maxchars',
                valueType: 'int',
            },
            {
                key: 'mv_add',
                valueType: 'bool',
            },
            {
                key: 'clean_keys',
                valueType: 'bool',
            },
        ],
        functions: [],
        keywords: [],
        other: ['string'],
        list: [],
    },
    'extract-command': {
        isList: false,
        args: [
            {
                key: 'segment',
                valueType: 'bool',
            },
            {
                key: 'reload',
                valueType: 'bool',
            },
            {
                key: 'kvdelim',
                valueType: 'string',
            },
            {
                key: 'pairdelim',
                valueType: 'string',
            },
            {
                key: 'limit',
                valueType: 'int',
            },
            {
                key: 'maxchars',
                valueType: 'int',
            },
            {
                key: 'mv_add',
                valueType: 'bool',
            },
            {
                key: 'clean_keys',
                valueType: 'bool',
            },
        ],
        functions: [],
        keywords: [],
        other: ['string'],
        list: [],
    },
    'fieldformat-command': {
        isList: false,
        args: [],
        functions: [
            {
                name: 'abs',
                parenOptional: false,
            },
            {
                name: 'avg',
                parenOptional: false,
            },
            {
                name: 'case',
                parenOptional: false,
            },
            {
                name: 'ceiling',
                parenOptional: false,
            },
            {
                name: 'ceil',
                parenOptional: false,
            },
            {
                name: 'cidrmatch',
                parenOptional: false,
            },
            {
                name: 'coalesce',
                parenOptional: false,
            },
            {
                name: 'commands',
                parenOptional: false,
            },
            {
                name: 'exact',
                parenOptional: false,
            },
            {
                name: 'exp',
                parenOptional: false,
            },
            {
                name: 'false',
                parenOptional: false,
            },
            {
                name: 'floor',
                parenOptional: false,
            },
            {
                name: 'if',
                parenOptional: false,
            },
            {
                name: 'ifnull',
                parenOptional: false,
            },
            {
                name: 'isbool',
                parenOptional: false,
            },
            {
                name: 'isint',
                parenOptional: false,
            },
            {
                name: 'isnotnull',
                parenOptional: false,
            },
            {
                name: 'isnull',
                parenOptional: false,
            },
            {
                name: 'isnum',
                parenOptional: false,
            },
            {
                name: 'isstr',
                parenOptional: false,
            },
            {
                name: 'len',
                parenOptional: false,
            },
            {
                name: 'like',
                parenOptional: false,
            },
            {
                name: 'ln',
                parenOptional: false,
            },
            {
                name: 'log',
                parenOptional: false,
            },
            {
                name: 'lookup',
                parenOptional: false,
            },
            {
                name: 'lower',
                parenOptional: false,
            },
            {
                name: 'match',
                parenOptional: false,
            },
            {
                name: 'max',
                parenOptional: false,
            },
            {
                name: 'md5',
                parenOptional: false,
            },
            {
                name: 'min',
                parenOptional: false,
            },
            {
                name: 'mvappend',
                parenOptional: false,
            },
            {
                name: 'mvcount',
                parenOptional: false,
            },
            {
                name: 'mvdedup',
                parenOptional: false,
            },
            {
                name: 'mvindex',
                parenOptional: false,
            },
            {
                name: 'mvfilter',
                parenOptional: false,
            },
            {
                name: 'mvfind',
                parenOptional: false,
            },
            {
                name: 'mvjoin',
                parenOptional: false,
            },
            {
                name: 'mvmap',
                parenOptional: false,
            },
            {
                name: 'mvrange',
                parenOptional: false,
            },
            {
                name: 'mvsort',
                parenOptional: false,
            },
            {
                name: 'mvzip',
                parenOptional: false,
            },
            {
                name: 'now',
                parenOptional: false,
            },
            {
                name: 'null',
                parenOptional: false,
            },
            {
                name: 'nullif',
                parenOptional: false,
            },
            {
                name: 'pi',
                parenOptional: false,
            },
            {
                name: 'pow',
                parenOptional: false,
            },
            {
                name: 'random',
                parenOptional: false,
            },
            {
                name: 'relative_time',
                parenOptional: false,
            },
            {
                name: 'replace',
                parenOptional: false,
            },
            {
                name: 'round',
                parenOptional: false,
            },
            {
                name: 'searchmatch',
                parenOptional: false,
            },
            {
                name: 'sha1',
                parenOptional: false,
            },
            {
                name: 'sha256',
                parenOptional: false,
            },
            {
                name: 'sha512',
                parenOptional: false,
            },
            {
                name: 'sigfig',
                parenOptional: false,
            },
            {
                name: 'spath',
                parenOptional: false,
            },
            {
                name: 'split',
                parenOptional: false,
            },
            {
                name: 'sqrt',
                parenOptional: false,
            },
            {
                name: 'strftime',
                parenOptional: false,
            },
            {
                name: 'strptime',
                parenOptional: false,
            },
            {
                name: 'substr',
                parenOptional: false,
            },
            {
                name: 'sum',
                parenOptional: false,
            },
            {
                name: 'time',
                parenOptional: false,
            },
            {
                name: 'tostring',
                parenOptional: false,
            },
            {
                name: 'trim',
                parenOptional: false,
            },
            {
                name: 'ltrim',
                parenOptional: false,
            },
            {
                name: 'rtrim',
                parenOptional: false,
            },
            {
                name: 'true',
                parenOptional: false,
            },
            {
                name: 'typeof',
                parenOptional: false,
            },
            {
                name: 'upper',
                parenOptional: false,
            },
            {
                name: 'urldecode',
                parenOptional: false,
            },
            {
                name: 'validate',
                parenOptional: false,
            },
            {
                name: 'tonumber',
                parenOptional: false,
            },
            {
                name: 'acos',
                parenOptional: false,
            },
            {
                name: 'acosh',
                parenOptional: false,
            },
            {
                name: 'asin',
                parenOptional: false,
            },
            {
                name: 'asinh',
                parenOptional: false,
            },
            {
                name: 'atan',
                parenOptional: false,
            },
            {
                name: 'atan2',
                parenOptional: false,
            },
            {
                name: 'atanh',
                parenOptional: false,
            },
            {
                name: 'cos',
                parenOptional: false,
            },
            {
                name: 'cosh',
                parenOptional: false,
            },
            {
                name: 'hypot',
                parenOptional: false,
            },
            {
                name: 'sin',
                parenOptional: false,
            },
            {
                name: 'sinh',
                parenOptional: false,
            },
            {
                name: 'tan',
                parenOptional: false,
            },
            {
                name: 'tanh',
                parenOptional: false,
            },
            {
                name: 'json_array_to_mv',
                parenOptional: false,
            },
            {
                name: 'mv_to_json_array',
                parenOptional: false,
            },
            {
                name: 'json_append',
                parenOptional: false,
            },
            {
                name: 'json_extend',
                parenOptional: false,
            },
            {
                name: 'json_extract_exact',
                parenOptional: false,
            },
            {
                name: 'json_set_exact',
                parenOptional: false,
            },
        ],
        keywords: ['LIKE', 'AND|OR|XOR'],
        other: ['field', 'num', 'string'],
        list: [],
    },
    'fields-command': {
        isList: false,
        args: [],
        functions: [],
        keywords: [],
        other: ['wc-field-list'],
        list: [],
    },
    'fieldsummary-command': {
        isList: false,
        args: [
            {
                key: 'maxvals',
                valueType: 'num',
            },
        ],
        functions: [],
        keywords: [],
        other: ['wc-field-list'],
        list: [],
    },
    'test-command': {
        isList: false,
        args: [],
        functions: [],
        keywords: [],
        other: ['filename'],
        list: [],
    },
    'file-command': {
        isList: false,
        args: [],
        functions: [],
        keywords: [],
        other: ['filename'],
        list: [],
    },
    'filldown-command': {
        isList: false,
        args: [],
        functions: [],
        keywords: [],
        other: ['wc-field-list'],
        list: [],
    },
    'fillnull-command': {
        isList: false,
        args: [
            {
                key: 'value',
                valueType: 'string',
            },
        ],
        functions: [],
        keywords: [],
        other: ['field-list'],
        list: [],
    },
    'findkeywords-command': {
        isList: false,
        args: [
            {
                key: 'labelfield',
                valueType: 'field',
            },
            {
                key: 'dedup',
                valueType: 'bool',
            },
        ],
        functions: [],
        keywords: [],
        other: [],
        list: [],
    },
    'findtypes-command': {
        isList: false,
        args: [
            {
                key: 'max',
                valueType: 'int',
            },
        ],
        functions: [],
        keywords: [],
        other: [],
        list: [],
    },
    'folderize-command': {
        isList: false,
        args: [
            {
                key: 'attr',
                valueType: 'string',
            },
            {
                key: 'sep',
                valueType: 'string',
            },
            {
                key: 'size',
                valueType: 'string',
            },
            {
                key: 'minfolders',
                valueType: 'int',
            },
            {
                key: 'maxfolders',
                valueType: 'int',
            },
        ],
        functions: [],
        keywords: [],
        other: [],
        list: [],
    },
    'foreach-command': {
        isList: false,
        args: [
            {
                key: 'fieldstr',
                valueType: 'string',
            },
            {
                key: 'matchstr',
                valueType: 'string',
            },
            {
                key: 'matchseg1',
                valueType: 'string',
            },
            {
                key: 'matchseg2',
                valueType: 'string',
            },
            {
                key: 'matchseg3',
                valueType: 'string',
            },
        ],
        functions: [],
        keywords: [],
        other: ['wc-field', 'string'],
        list: [],
    },
    'format-command': {
        isList: false,
        args: [
            {
                key: 'quote',
                valueType: 'bool',
            },
            {
                key: 'mvsep',
                valueType: '',
            },
            {
                key: 'maxresults',
                valueType: 'int',
            },
        ],
        functions: [],
        keywords: [],
        other: ['double-quoted-string', 'row end'],
        list: [],
    },
    'from-command': {
        isList: true,
        args: [],
        functions: [],
        keywords: [],
        other: ['ws', 'string'],
        list: ['datamodel', 'savedsearch', 'inputlookup', 'federated'],
    },
    'gauge-command': {
        isList: false,
        args: [],
        functions: [],
        keywords: [],
        other: ['num', 'field', 'num', 'field'],
        list: [],
    },
    'gentimes-command': {
        isList: false,
        args: [
            {
                key: 'start',
                valueType: 'timestamp',
            },
            {
                key: 'end',
                valueType: 'timestamp',
            },
            {
                key: 'increment',
                valueType: 'increment',
            },
        ],
        functions: [],
        keywords: [],
        other: [],
        list: [],
    },
    'geom-command': {
        isList: false,
        args: [
            {
                key: 'allFeatures',
                valueType: 'bool',
            },
            {
                key: 'featureIdField',
                valueType: 'string',
            },
            {
                key: 'gen',
                valueType: 'num',
            },
            {
                key: 'min_x',
                valueType: 'num',
            },
            {
                key: 'min_y',
                valueType: 'num',
            },
            {
                key: 'max_x',
                valueType: 'num',
            },
            {
                key: 'max_y',
                valueType: 'num',
            },
        ],
        functions: [],
        keywords: [],
        other: ['string'],
        list: [],
    },
    'geomfilter-command': {
        isList: false,
        args: [
            {
                key: 'min_x',
                valueType: 'num',
            },
            {
                key: 'min_y',
                valueType: 'num',
            },
            {
                key: 'max_x',
                valueType: 'num',
            },
            {
                key: 'max_y',
                valueType: 'num',
            },
        ],
        functions: [],
        keywords: [],
        other: [],
        list: [],
    },
    'geostats-command': {
        isList: false,
        args: [
            {
                key: 'translatetoxy',
                valueType: 'bool',
            },
            {
                key: 'latfield',
                valueType: 'string',
            },
            {
                key: 'longfield',
                valueType: 'string',
            },
            {
                key: 'outputlatfield',
                valueType: 'string',
            },
            {
                key: 'outputlongfield',
                valueType: 'string',
            },
            {
                key: 'globallimit',
                valueType: 'int',
            },
            {
                key: 'locallimit',
                valueType: 'int',
            },
            {
                key: 'binspanlat',
                valueType: 'float',
            },
            {
                key: 'binspanlong',
                valueType: '',
            },
            {
                key: 'maxzoomlevel',
                valueType: 'int',
            },
        ],
        functions: [
            {
                name: 'c',
                parenOptional: true,
            },
            {
                name: 'count',
                parenOptional: true,
            },
            {
                name: 'dc',
                parenOptional: true,
            },
            {
                name: 'distinct_count',
                parenOptional: true,
            },
            {
                name: 'mean',
                parenOptional: true,
            },
            {
                name: 'avg',
                parenOptional: true,
            },
            {
                name: 'stdev',
                parenOptional: true,
            },
            {
                name: 'stdevp',
                parenOptional: true,
            },
            {
                name: 'var',
                parenOptional: true,
            },
            {
                name: 'varp',
                parenOptional: true,
            },
            {
                name: 'sum',
                parenOptional: true,
            },
            {
                name: 'sumsq',
                parenOptional: true,
            },
            {
                name: 'min',
                parenOptional: true,
            },
            {
                name: 'max',
                parenOptional: true,
            },
            {
                name: 'mode',
                parenOptional: true,
            },
            {
                name: 'median',
                parenOptional: true,
            },
            {
                name: 'earliest',
                parenOptional: true,
            },
            {
                name: 'first',
                parenOptional: true,
            },
            {
                name: 'last',
                parenOptional: true,
            },
            {
                name: 'latest',
                parenOptional: true,
            },
            {
                name: 'list',
                parenOptional: true,
            },
            {
                name: 'values',
                parenOptional: true,
            },
            {
                name: 'range',
                parenOptional: true,
            },
            {
                name: 'estdc',
                parenOptional: true,
            },
            {
                name: 'estdc_error',
                parenOptional: true,
            },
            {
                name: 'earliest_time',
                parenOptional: true,
            },
            {
                name: 'latest_time',
                parenOptional: true,
            },
            {
                name: 'rate',
                parenOptional: true,
            },
            {
                name: 'eval',
                parenOptional: false,
            },
            {
                name: 'abs',
                parenOptional: false,
            },
            {
                name: 'avg',
                parenOptional: false,
            },
            {
                name: 'case',
                parenOptional: false,
            },
            {
                name: 'ceiling',
                parenOptional: false,
            },
            {
                name: 'ceil',
                parenOptional: false,
            },
            {
                name: 'cidrmatch',
                parenOptional: false,
            },
            {
                name: 'coalesce',
                parenOptional: false,
            },
            {
                name: 'commands',
                parenOptional: false,
            },
            {
                name: 'exact',
                parenOptional: false,
            },
            {
                name: 'exp',
                parenOptional: false,
            },
            {
                name: 'false',
                parenOptional: false,
            },
            {
                name: 'floor',
                parenOptional: false,
            },
            {
                name: 'if',
                parenOptional: false,
            },
            {
                name: 'ifnull',
                parenOptional: false,
            },
            {
                name: 'isbool',
                parenOptional: false,
            },
            {
                name: 'isint',
                parenOptional: false,
            },
            {
                name: 'isnotnull',
                parenOptional: false,
            },
            {
                name: 'isnull',
                parenOptional: false,
            },
            {
                name: 'isnum',
                parenOptional: false,
            },
            {
                name: 'isstr',
                parenOptional: false,
            },
            {
                name: 'len',
                parenOptional: false,
            },
            {
                name: 'like',
                parenOptional: false,
            },
            {
                name: 'ln',
                parenOptional: false,
            },
            {
                name: 'log',
                parenOptional: false,
            },
            {
                name: 'lookup',
                parenOptional: false,
            },
            {
                name: 'lower',
                parenOptional: false,
            },
            {
                name: 'match',
                parenOptional: false,
            },
            {
                name: 'max',
                parenOptional: false,
            },
            {
                name: 'md5',
                parenOptional: false,
            },
            {
                name: 'min',
                parenOptional: false,
            },
            {
                name: 'mvappend',
                parenOptional: false,
            },
            {
                name: 'mvcount',
                parenOptional: false,
            },
            {
                name: 'mvdedup',
                parenOptional: false,
            },
            {
                name: 'mvindex',
                parenOptional: false,
            },
            {
                name: 'mvfilter',
                parenOptional: false,
            },
            {
                name: 'mvfind',
                parenOptional: false,
            },
            {
                name: 'mvjoin',
                parenOptional: false,
            },
            {
                name: 'mvmap',
                parenOptional: false,
            },
            {
                name: 'mvrange',
                parenOptional: false,
            },
            {
                name: 'mvsort',
                parenOptional: false,
            },
            {
                name: 'mvzip',
                parenOptional: false,
            },
            {
                name: 'now',
                parenOptional: false,
            },
            {
                name: 'null',
                parenOptional: false,
            },
            {
                name: 'nullif',
                parenOptional: false,
            },
            {
                name: 'pi',
                parenOptional: false,
            },
            {
                name: 'pow',
                parenOptional: false,
            },
            {
                name: 'random',
                parenOptional: false,
            },
            {
                name: 'relative_time',
                parenOptional: false,
            },
            {
                name: 'replace',
                parenOptional: false,
            },
            {
                name: 'round',
                parenOptional: false,
            },
            {
                name: 'searchmatch',
                parenOptional: false,
            },
            {
                name: 'sha1',
                parenOptional: false,
            },
            {
                name: 'sha256',
                parenOptional: false,
            },
            {
                name: 'sha512',
                parenOptional: false,
            },
            {
                name: 'sigfig',
                parenOptional: false,
            },
            {
                name: 'spath',
                parenOptional: false,
            },
            {
                name: 'split',
                parenOptional: false,
            },
            {
                name: 'sqrt',
                parenOptional: false,
            },
            {
                name: 'strftime',
                parenOptional: false,
            },
            {
                name: 'strptime',
                parenOptional: false,
            },
            {
                name: 'substr',
                parenOptional: false,
            },
            {
                name: 'sum',
                parenOptional: false,
            },
            {
                name: 'time',
                parenOptional: false,
            },
            {
                name: 'tostring',
                parenOptional: false,
            },
            {
                name: 'trim',
                parenOptional: false,
            },
            {
                name: 'ltrim',
                parenOptional: false,
            },
            {
                name: 'rtrim',
                parenOptional: false,
            },
            {
                name: 'true',
                parenOptional: false,
            },
            {
                name: 'typeof',
                parenOptional: false,
            },
            {
                name: 'upper',
                parenOptional: false,
            },
            {
                name: 'urldecode',
                parenOptional: false,
            },
            {
                name: 'validate',
                parenOptional: false,
            },
            {
                name: 'tonumber',
                parenOptional: false,
            },
            {
                name: 'acos',
                parenOptional: false,
            },
            {
                name: 'acosh',
                parenOptional: false,
            },
            {
                name: 'asin',
                parenOptional: false,
            },
            {
                name: 'asinh',
                parenOptional: false,
            },
            {
                name: 'atan',
                parenOptional: false,
            },
            {
                name: 'atan2',
                parenOptional: false,
            },
            {
                name: 'atanh',
                parenOptional: false,
            },
            {
                name: 'cos',
                parenOptional: false,
            },
            {
                name: 'cosh',
                parenOptional: false,
            },
            {
                name: 'hypot',
                parenOptional: false,
            },
            {
                name: 'sin',
                parenOptional: false,
            },
            {
                name: 'sinh',
                parenOptional: false,
            },
            {
                name: 'tan',
                parenOptional: false,
            },
            {
                name: 'tanh',
                parenOptional: false,
            },
            {
                name: 'json_array_to_mv',
                parenOptional: false,
            },
            {
                name: 'mv_to_json_array',
                parenOptional: false,
            },
            {
                name: 'json_append',
                parenOptional: false,
            },
            {
                name: 'json_extend',
                parenOptional: false,
            },
            {
                name: 'json_extract_exact',
                parenOptional: false,
            },
            {
                name: 'json_set_exact',
                parenOptional: false,
            },
        ],
        keywords: ['LIKE', 'AND|OR|XOR', 'as', 'by'],
        other: ['field', 'num', 'string', 'wc-field', 'field-list'],
        list: [],
    },
    'head-command': {
        isList: false,
        args: [
            {
                key: 'limit',
                valueType: 'int',
            },
            {
                key: 'null',
                valueType: 'bool',
            },
            {
                key: 'keeplast',
                valueType: 'bool',
            },
        ],
        functions: [
            {
                name: 'abs',
                parenOptional: false,
            },
            {
                name: 'avg',
                parenOptional: false,
            },
            {
                name: 'case',
                parenOptional: false,
            },
            {
                name: 'ceiling',
                parenOptional: false,
            },
            {
                name: 'ceil',
                parenOptional: false,
            },
            {
                name: 'cidrmatch',
                parenOptional: false,
            },
            {
                name: 'coalesce',
                parenOptional: false,
            },
            {
                name: 'commands',
                parenOptional: false,
            },
            {
                name: 'exact',
                parenOptional: false,
            },
            {
                name: 'exp',
                parenOptional: false,
            },
            {
                name: 'false',
                parenOptional: false,
            },
            {
                name: 'floor',
                parenOptional: false,
            },
            {
                name: 'if',
                parenOptional: false,
            },
            {
                name: 'ifnull',
                parenOptional: false,
            },
            {
                name: 'isbool',
                parenOptional: false,
            },
            {
                name: 'isint',
                parenOptional: false,
            },
            {
                name: 'isnotnull',
                parenOptional: false,
            },
            {
                name: 'isnull',
                parenOptional: false,
            },
            {
                name: 'isnum',
                parenOptional: false,
            },
            {
                name: 'isstr',
                parenOptional: false,
            },
            {
                name: 'len',
                parenOptional: false,
            },
            {
                name: 'like',
                parenOptional: false,
            },
            {
                name: 'ln',
                parenOptional: false,
            },
            {
                name: 'log',
                parenOptional: false,
            },
            {
                name: 'lookup',
                parenOptional: false,
            },
            {
                name: 'lower',
                parenOptional: false,
            },
            {
                name: 'match',
                parenOptional: false,
            },
            {
                name: 'max',
                parenOptional: false,
            },
            {
                name: 'md5',
                parenOptional: false,
            },
            {
                name: 'min',
                parenOptional: false,
            },
            {
                name: 'mvappend',
                parenOptional: false,
            },
            {
                name: 'mvcount',
                parenOptional: false,
            },
            {
                name: 'mvdedup',
                parenOptional: false,
            },
            {
                name: 'mvindex',
                parenOptional: false,
            },
            {
                name: 'mvfilter',
                parenOptional: false,
            },
            {
                name: 'mvfind',
                parenOptional: false,
            },
            {
                name: 'mvjoin',
                parenOptional: false,
            },
            {
                name: 'mvmap',
                parenOptional: false,
            },
            {
                name: 'mvrange',
                parenOptional: false,
            },
            {
                name: 'mvsort',
                parenOptional: false,
            },
            {
                name: 'mvzip',
                parenOptional: false,
            },
            {
                name: 'now',
                parenOptional: false,
            },
            {
                name: 'null',
                parenOptional: false,
            },
            {
                name: 'nullif',
                parenOptional: false,
            },
            {
                name: 'pi',
                parenOptional: false,
            },
            {
                name: 'pow',
                parenOptional: false,
            },
            {
                name: 'random',
                parenOptional: false,
            },
            {
                name: 'relative_time',
                parenOptional: false,
            },
            {
                name: 'replace',
                parenOptional: false,
            },
            {
                name: 'round',
                parenOptional: false,
            },
            {
                name: 'searchmatch',
                parenOptional: false,
            },
            {
                name: 'sha1',
                parenOptional: false,
            },
            {
                name: 'sha256',
                parenOptional: false,
            },
            {
                name: 'sha512',
                parenOptional: false,
            },
            {
                name: 'sigfig',
                parenOptional: false,
            },
            {
                name: 'spath',
                parenOptional: false,
            },
            {
                name: 'split',
                parenOptional: false,
            },
            {
                name: 'sqrt',
                parenOptional: false,
            },
            {
                name: 'strftime',
                parenOptional: false,
            },
            {
                name: 'strptime',
                parenOptional: false,
            },
            {
                name: 'substr',
                parenOptional: false,
            },
            {
                name: 'sum',
                parenOptional: false,
            },
            {
                name: 'time',
                parenOptional: false,
            },
            {
                name: 'tostring',
                parenOptional: false,
            },
            {
                name: 'trim',
                parenOptional: false,
            },
            {
                name: 'ltrim',
                parenOptional: false,
            },
            {
                name: 'rtrim',
                parenOptional: false,
            },
            {
                name: 'true',
                parenOptional: false,
            },
            {
                name: 'typeof',
                parenOptional: false,
            },
            {
                name: 'upper',
                parenOptional: false,
            },
            {
                name: 'urldecode',
                parenOptional: false,
            },
            {
                name: 'validate',
                parenOptional: false,
            },
            {
                name: 'tonumber',
                parenOptional: false,
            },
            {
                name: 'acos',
                parenOptional: false,
            },
            {
                name: 'acosh',
                parenOptional: false,
            },
            {
                name: 'asin',
                parenOptional: false,
            },
            {
                name: 'asinh',
                parenOptional: false,
            },
            {
                name: 'atan',
                parenOptional: false,
            },
            {
                name: 'atan2',
                parenOptional: false,
            },
            {
                name: 'atanh',
                parenOptional: false,
            },
            {
                name: 'cos',
                parenOptional: false,
            },
            {
                name: 'cosh',
                parenOptional: false,
            },
            {
                name: 'hypot',
                parenOptional: false,
            },
            {
                name: 'sin',
                parenOptional: false,
            },
            {
                name: 'sinh',
                parenOptional: false,
            },
            {
                name: 'tan',
                parenOptional: false,
            },
            {
                name: 'tanh',
                parenOptional: false,
            },
            {
                name: 'json_array_to_mv',
                parenOptional: false,
            },
            {
                name: 'mv_to_json_array',
                parenOptional: false,
            },
            {
                name: 'json_append',
                parenOptional: false,
            },
            {
                name: 'json_extend',
                parenOptional: false,
            },
            {
                name: 'json_extract_exact',
                parenOptional: false,
            },
            {
                name: 'json_set_exact',
                parenOptional: false,
            },
        ],
        keywords: ['LIKE', 'AND|OR|XOR'],
        other: ['int', 'field', 'num', 'string'],
        list: [],
    },
    'hilite-command': {
        isList: false,
        args: [],
        functions: [],
        keywords: [],
        other: ['string'],
        list: [],
    },
    'highlight-command': {
        isList: false,
        args: [],
        functions: [],
        keywords: [],
        other: ['string'],
        list: [],
    },
    'history-command': {
        isList: false,
        args: [
            {
                key: 'events',
                valueType: 'bool',
            },
        ],
        functions: [],
        keywords: [],
        other: [],
        list: [],
    },
    'iconify-command': {
        isList: false,
        args: [],
        functions: [],
        keywords: [],
        other: ['field-list'],
        list: [],
    },
    'ingestpreview-command': {
        isList: false,
        args: [
            {
                key: 'meta_mode',
                valueType: 'string',
            },
            {
                key: 'ingest_processor',
                valueType: 'string',
            },
            {
                key: 'emit_copy_pastable',
                valueType: 'bool',
            },
        ],
        functions: [],
        keywords: [],
        other: [],
        list: [],
    },
    'inputcsv-command': {
        isList: false,
        args: [
            {
                key: 'dispatch',
                valueType: 'bool',
            },
            {
                key: 'append',
                valueType: 'bool',
            },
            {
                key: 'strict',
                valueType: 'bool',
            },
            {
                key: 'start',
                valueType: 'int',
            },
            {
                key: 'max',
                valueType: 'int',
            },
            {
                key: 'events',
                valueType: 'bool',
            },
        ],
        functions: [],
        keywords: ['WHERE'],
        other: ['filename', 'string:search-query'],
        list: [],
    },
    'inputlookup-command': {
        isList: false,
        args: [
            {
                key: 'append',
                valueType: 'bool',
            },
            {
                key: 'start',
                valueType: 'int',
            },
            {
                key: 'max',
                valueType: 'int',
            },
        ],
        functions: [],
        keywords: ['where'],
        other: ['filename', 'string:tablename', 'string:search-query'],
        list: [],
    },
    'internalinputcsv-command': {
        isList: false,
        args: [],
        functions: [],
        keywords: [],
        other: ['filename'],
        list: [],
    },
    'iplocation-command': {
        isList: false,
        args: [
            {
                key: 'prefix',
                valueType: 'string',
            },
            {
                key: 'allfields',
                valueType: 'bool',
            },
            {
                key: 'lang',
                valueType: 'string',
            },
        ],
        functions: [],
        keywords: [],
        other: ['field'],
        list: [],
    },
    'join-command': {
        isList: false,
        args: [
            {
                key: 'type',
                valueType: '',
            },
            {
                key: 'usetime',
                valueType: 'bool',
            },
            {
                key: 'earlier',
                valueType: 'bool',
            },
            {
                key: 'overwrite',
                valueType: 'bool',
            },
            {
                key: 'max',
                valueType: 'int',
            },
            {
                key: 'return_multivalue',
                valueType: 'bool',
            },
            {
                key: 'left',
                valueType: 'leftalias',
            },
            {
                key: 'right',
                valueType: 'rightalias',
            },
        ],
        functions: [],
        keywords: ['WHERE'],
        other: ['field-list', 'string', 'field'],
        list: [],
    },
    'jsontxn-command': {
        isList: false,
        args: [],
        functions: [],
        keywords: [],
        other: [],
        list: [],
    },
    'kmeans-command': {
        isList: false,
        args: [
            {
                key: 'reps',
                valueType: 'int',
            },
            {
                key: 'maxiters',
                valueType: 'int',
            },
            {
                key: 't',
                valueType: 'num',
            },
            {
                key: 'k',
                valueType: '',
            },
            {
                key: 'cfield',
                valueType: 'field',
            },
            {
                key: 'dt',
                valueType: '',
            },
            {
                key: 'showcentroid',
                valueType: 'bool',
            },
        ],
        functions: [],
        keywords: [],
        other: ['field-list'],
        list: [],
    },
    'kvform-command': {
        isList: false,
        args: [
            {
                key: 'form',
                valueType: 'string',
            },
            {
                key: 'field',
                valueType: 'field',
            },
        ],
        functions: [],
        keywords: [],
        other: [],
        list: [],
    },
    'loadjob-command': {
        isList: false,
        args: [
            {
                key: 'savedsearch',
                valueType: '',
            },
            {
                key: 'events',
                valueType: 'bool',
            },
            {
                key: 'job_delegate',
                valueType: 'string',
            },
            {
                key: 'artifact_offset',
                valueType: 'int',
            },
            {
                key: 'ignore_running',
                valueType: 'bool',
            },
            {
                key: 'wait_until_finished',
                valueType: 'bool',
            },
            {
                key: 'wait_timeout',
                valueType: 'int',
            },
        ],
        functions: [],
        keywords: [],
        other: ['string'],
        list: [],
    },
    'localize-command': {
        isList: false,
        args: [
            {
                key: 'maxpause',
                valueType: '',
            },
            {
                key: 'timeafter',
                valueType: '',
            },
            {
                key: 'timebefore',
                valueType: '',
            },
        ],
        functions: [],
        keywords: [],
        other: [],
        list: [],
    },
    'localop-command': {
        isList: false,
        args: [],
        functions: [],
        keywords: [],
        other: [],
        list: [],
    },
    'lookup-command': {
        isList: false,
        args: [
            {
                key: 'local',
                valueType: 'bool',
            },
            {
                key: 'update',
                valueType: 'bool',
            },
            {
                key: 'event_time_field',
                valueType: 'string',
            },
        ],
        functions: [],
        keywords: ['as', 'OUTPUT|OUTPUTNEW', 'as'],
        other: [
            'string:lookup-table-name',
            'field:lookup',
            'field:local',
            'field:dest',
            'field:local-dest',
        ],
        list: [],
    },
    'makecontinuous-command': {
        isList: false,
        args: [
            {
                key: 'bins',
                valueType: 'int',
            },
            {
                key: 'minspan',
                valueType: '',
            },
            {
                key: 'span',
                valueType: '',
            },
            {
                key: 'start',
                valueType: 'num',
            },
            {
                key: 'end',
                valueType: 'num',
            },
            {
                key: 'aligntime',
                valueType: '',
            },
        ],
        functions: [],
        keywords: [],
        other: ['field'],
        list: [],
    },
    'makejson-command': {
        isList: false,
        args: [
            {
                key: 'output',
                valueType: 'string',
            },
        ],
        functions: [],
        keywords: [],
        other: ['wc-field-list'],
        list: [],
    },
    'makemv-command': {
        isList: false,
        args: [
            {
                key: 'delim',
                valueType: 'string',
            },
            {
                key: 'tokenizer',
                valueType: '',
            },
            {
                key: 'allowempty',
                valueType: 'bool',
            },
            {
                key: 'setsv',
                valueType: 'bool',
            },
        ],
        functions: [],
        keywords: [],
        other: ['field'],
        list: [],
    },
    'makeresults-command': {
        isList: false,
        args: [
            {
                key: 'count',
                valueType: 'int',
            },
            {
                key: 'annotate',
                valueType: 'bool',
            },
            {
                key: 'splunk_server',
                valueType: 'string',
            },
            {
                key: 'splunk_server_group',
                valueType: 'string',
            },
        ],
        functions: [],
        keywords: [],
        other: [],
        list: [],
    },
    'map-command': {
        isList: false,
        args: [
            {
                key: 'search',
                valueType: '',
            },
            {
                key: 'maxsearches',
                valueType: 'int',
            },
        ],
        functions: [],
        keywords: [],
        other: ['string'],
        list: [],
    },
    'mcatalog-command': {
        isList: false,
        args: [
            {
                key: 'prestats',
                valueType: 'bool',
            },
            {
                key: 'append',
                valueType: 'bool',
            },
            {
                key: 'timeformat',
                valueType: 'string',
            },
            {
                key: 'earliest',
                valueType: 'time_modifier',
            },
            {
                key: '_index_earliest',
                valueType: 'time_modifier',
            },
            {
                key: 'starttime',
                valueType: 'string',
            },
            {
                key: 'startdaysago',
                valueType: 'int',
            },
            {
                key: 'startminutesago',
                valueType: 'int',
            },
            {
                key: 'starthoursago',
                valueType: 'int',
            },
            {
                key: 'startmonthsago',
                valueType: 'int',
            },
            {
                key: 'starttimeu',
                valueType: 'num',
            },
            {
                key: 'latest',
                valueType: 'time_modifier',
            },
            {
                key: '_index_latest',
                valueType: 'time_modifier',
            },
            {
                key: 'endtime',
                valueType: 'string',
            },
            {
                key: 'enddaysago',
                valueType: 'int',
            },
            {
                key: 'endminutesago',
                valueType: 'int',
            },
            {
                key: 'endhoursago',
                valueType: 'int',
            },
            {
                key: 'endmonthsago',
                valueType: 'int',
            },
            {
                key: 'endtimeu',
                valueType: 'num',
            },
            {
                key: 'searchtimespanhours',
                valueType: 'int',
            },
            {
                key: 'searchtimespanminutes',
                valueType: 'int',
            },
            {
                key: 'searchtimespandays',
                valueType: 'int',
            },
            {
                key: 'searchtimespanmonths',
                valueType: 'int',
            },
            {
                key: 'daysago',
                valueType: 'int',
            },
            {
                key: 'minutesago',
                valueType: 'int',
            },
            {
                key: 'hoursago',
                valueType: 'int',
            },
            {
                key: 'monthsago',
                valueType: 'int',
            },
            {
                key: 'savedsearch',
                valueType: 'string',
            },
            {
                key: 'savedsplunk',
                valueType: 'string',
            },
        ],
        functions: [],
        keywords: ['as', 'WHERE', 'IN', 'BY|GROUPBY'],
        other: [
            'field',
            'string',
            'boolean-operator-not',
            'term',
            'num',
            'boolean-operator-or',
            'boolean-operator-and',
            'field-list',
        ],
        list: [],
    },
    'mcollect-command': {
        isList: false,
        args: [
            {
                key: 'index',
                valueType: 'string',
            },
            {
                key: 'file',
                valueType: 'string',
            },
            {
                key: 'split',
                valueType: 'true|false|allnums',
            },
            {
                key: 'spool',
                valueType: 'bool',
            },
            {
                key: 'prefix_field',
                valueType: 'string',
            },
            {
                key: 'host',
                valueType: 'string',
            },
            {
                key: 'source',
                valueType: 'string',
            },
            {
                key: 'sourcetype',
                valueType: 'string',
            },
            {
                key: 'marker',
                valueType: 'string',
            },
        ],
        functions: [],
        keywords: [],
        other: ['field-list'],
        list: [],
    },
    'metadata-command': {
        isList: false,
        args: [
            {
                key: 'type',
                valueType: 'metadata-type',
            },
            {
                key: 'index',
                valueType: 'string',
            },
            {
                key: 'splunk_server',
                valueType: 'wc-string',
            },
            {
                key: 'splunk_server_group',
                valueType: 'wc-string',
            },
            {
                key: 'datatype',
                valueType: 'metric|event',
            },
        ],
        functions: [],
        keywords: [],
        other: [],
        list: [],
    },
    'metasearch-command': {
        isList: false,
        args: [
            {
                key: 'timeformat',
                valueType: 'string',
            },
            {
                key: 'earliest',
                valueType: 'time_modifier',
            },
            {
                key: '_index_earliest',
                valueType: 'time_modifier',
            },
            {
                key: 'starttime',
                valueType: 'string',
            },
            {
                key: 'startdaysago',
                valueType: 'int',
            },
            {
                key: 'startminutesago',
                valueType: 'int',
            },
            {
                key: 'starthoursago',
                valueType: 'int',
            },
            {
                key: 'startmonthsago',
                valueType: 'int',
            },
            {
                key: 'starttimeu',
                valueType: 'num',
            },
            {
                key: 'latest',
                valueType: 'time_modifier',
            },
            {
                key: '_index_latest',
                valueType: 'time_modifier',
            },
            {
                key: 'endtime',
                valueType: 'string',
            },
            {
                key: 'enddaysago',
                valueType: 'int',
            },
            {
                key: 'endminutesago',
                valueType: 'int',
            },
            {
                key: 'endhoursago',
                valueType: 'int',
            },
            {
                key: 'endmonthsago',
                valueType: 'int',
            },
            {
                key: 'endtimeu',
                valueType: 'num',
            },
            {
                key: 'searchtimespanhours',
                valueType: 'int',
            },
            {
                key: 'searchtimespanminutes',
                valueType: 'int',
            },
            {
                key: 'searchtimespandays',
                valueType: 'int',
            },
            {
                key: 'searchtimespanmonths',
                valueType: 'int',
            },
            {
                key: 'daysago',
                valueType: 'int',
            },
            {
                key: 'minutesago',
                valueType: 'int',
            },
            {
                key: 'hoursago',
                valueType: 'int',
            },
            {
                key: 'monthsago',
                valueType: 'int',
            },
            {
                key: 'savedsearch',
                valueType: 'string',
            },
            {
                key: 'savedsplunk',
                valueType: 'string',
            },
        ],
        functions: [],
        keywords: ['IN'],
        other: [
            'string',
            'field',
            'boolean-operator-not',
            'term',
            'num',
            'boolean-operator-or',
            'boolean-operator-and',
        ],
        list: [],
    },
    'meventcollect-command': {
        isList: false,
        args: [
            {
                key: 'index',
                valueType: 'string',
            },
            {
                key: 'split',
                valueType: 'bool',
            },
            {
                key: 'spool',
                valueType: 'bool',
            },
            {
                key: 'prefix_field',
                valueType: 'string',
            },
            {
                key: 'host',
                valueType: 'string',
            },
            {
                key: 'source',
                valueType: 'string',
            },
            {
                key: 'sourcetype',
                valueType: 'string',
            },
        ],
        functions: [],
        keywords: [],
        other: ['field-list'],
        list: [],
    },
    'msearch-command': {
        isList: false,
        args: [
            {
                key: 'filter',
                valueType: 'string',
            },
            {
                key: 'index',
                valueType: 'string',
            },
            {
                key: 'splunk_server',
                valueType: 'wc-string',
            },
            {
                key: 'splunk_server_group',
                valueType: 'wc-string',
            },
            {
                key: 'earliest',
                valueType: 'mpreview-time-specifier',
            },
            {
                key: 'latest',
                valueType: 'mpreview-time-specifier',
            },
            {
                key: 'chunk_size',
                valueType: 'int',
            },
            {
                key: 'target_per_timeseries',
                valueType: 'int',
            },
        ],
        functions: [],
        keywords: [],
        other: [],
        list: [],
    },
    'mpreview-command': {
        isList: false,
        args: [
            {
                key: 'filter',
                valueType: 'string',
            },
            {
                key: 'index',
                valueType: 'string',
            },
            {
                key: 'splunk_server',
                valueType: 'wc-string',
            },
            {
                key: 'splunk_server_group',
                valueType: 'wc-string',
            },
            {
                key: 'earliest',
                valueType: 'mpreview-time-specifier',
            },
            {
                key: 'latest',
                valueType: 'mpreview-time-specifier',
            },
            {
                key: 'chunk_size',
                valueType: 'int',
            },
            {
                key: 'target_per_timeseries',
                valueType: 'int',
            },
        ],
        functions: [],
        keywords: [],
        other: [],
        list: [],
    },
    'mrollup-command': {
        isList: false,
        args: [
            {
                key: 'source',
                valueType: 'string',
            },
            {
                key: 'target',
                valueType: 'string',
            },
            {
                key: 'file',
                valueType: 'string',
            },
            {
                key: 'span',
                valueType: 'string:timespan',
            },
            {
                key: 'aggregate',
                valueType: '',
            },
            {
                key: 'dimension-list',
                valueType: '',
            },
            {
                key: 'dimension-list-type',
                valueType: '',
            },
            {
                key: 'metric-list',
                valueType: '',
            },
            {
                key: 'metric-list-type',
                valueType: '',
            },
            {
                key: 'metric-overrides',
                valueType: '',
            },
            {
                key: 'app',
                valueType: 'string',
            },
        ],
        functions: [],
        keywords: [],
        other: [],
        list: [],
    },
    'mstats-command': {
        isList: false,
        args: [
            {
                key: 'chart',
                valueType: 'bool',
            },
            {
                key: 'chart.limit',
                valueType: 'chart-limit-opt',
            },
            {
                key: 'chart.agg',
                valueType: 'stats-agg-term',
            },
            {
                key: 'chart.usenull',
                valueType: 'bool',
            },
            {
                key: 'chart.useother',
                valueType: 'bool',
            },
            {
                key: 'chart.nullstr',
                valueType: 'string',
            },
            {
                key: 'chart.otherstr',
                valueType: 'string',
            },
            {
                key: 'prestats',
                valueType: 'bool',
            },
            {
                key: 'append',
                valueType: 'bool',
            },
            {
                key: 'backfill',
                valueType: 'bool',
            },
            {
                key: 'update_period',
                valueType: 'int',
            },
            {
                key: 'fillnull_value',
                valueType: 'string',
            },
            {
                key: 'chunk_size',
                valueType: 'int',
            },
            {
                key: 'timeformat',
                valueType: 'string',
            },
            {
                key: 'earliest',
                valueType: 'time_modifier',
            },
            {
                key: '_index_earliest',
                valueType: 'time_modifier',
            },
            {
                key: 'starttime',
                valueType: 'string',
            },
            {
                key: 'startdaysago',
                valueType: 'int',
            },
            {
                key: 'startminutesago',
                valueType: 'int',
            },
            {
                key: 'starthoursago',
                valueType: 'int',
            },
            {
                key: 'startmonthsago',
                valueType: 'int',
            },
            {
                key: 'starttimeu',
                valueType: 'num',
            },
            {
                key: 'latest',
                valueType: 'time_modifier',
            },
            {
                key: '_index_latest',
                valueType: 'time_modifier',
            },
            {
                key: 'endtime',
                valueType: 'string',
            },
            {
                key: 'enddaysago',
                valueType: 'int',
            },
            {
                key: 'endminutesago',
                valueType: 'int',
            },
            {
                key: 'endhoursago',
                valueType: 'int',
            },
            {
                key: 'endmonthsago',
                valueType: 'int',
            },
            {
                key: 'endtimeu',
                valueType: 'num',
            },
            {
                key: 'searchtimespanhours',
                valueType: 'int',
            },
            {
                key: 'searchtimespanminutes',
                valueType: 'int',
            },
            {
                key: 'searchtimespandays',
                valueType: 'int',
            },
            {
                key: 'searchtimespanmonths',
                valueType: 'int',
            },
            {
                key: 'daysago',
                valueType: 'int',
            },
            {
                key: 'minutesago',
                valueType: 'int',
            },
            {
                key: 'hoursago',
                valueType: 'int',
            },
            {
                key: 'monthsago',
                valueType: 'int',
            },
            {
                key: 'savedsearch',
                valueType: 'string',
            },
            {
                key: 'savedsplunk',
                valueType: 'string',
            },
            {
                key: 'span',
                valueType: 'string:timespan',
            },
            {
                key: 'every',
                valueType: 'string:timespan',
            },
        ],
        functions: [
            {
                name: 'rate_avg',
                parenOptional: false,
            },
            {
                name: 'rate_sum',
                parenOptional: false,
            },
        ],
        keywords: ['as', 'WHERE', 'IN', 'BY|GROUPBY'],
        other: [
            'metric_name',
            'string',
            'stats-func-value',
            'field',
            'boolean-operator-not',
            'term',
            'num',
            'boolean-operator-or',
            'boolean-operator-and',
            'field-list',
        ],
        list: [],
    },
    'multikv-command': {
        isList: false,
        args: [
            {
                key: 'conf',
                valueType: 'stanza_name',
            },
            {
                key: 'copyattrs',
                valueType: 'bool',
            },
            {
                key: 'forceheader',
                valueType: 'int',
            },
            {
                key: 'multitable',
                valueType: 'bool',
            },
            {
                key: 'noheader',
                valueType: 'bool',
            },
            {
                key: 'rmorig',
                valueType: 'bool',
            },
        ],
        functions: [],
        keywords: ['fields', 'filter'],
        other: ['field-list'],
        list: [],
    },
    'multisearch-command': {
        isList: false,
        args: [],
        functions: [],
        keywords: [],
        other: ['string'],
        list: [],
    },
    'mvcombine-command': {
        isList: false,
        args: [
            {
                key: 'delim',
                valueType: 'string',
            },
        ],
        functions: [],
        keywords: [],
        other: ['field'],
        list: [],
    },
    'mvexpand-command': {
        isList: false,
        args: [
            {
                key: 'limit',
                valueType: 'int',
            },
        ],
        functions: [],
        keywords: [],
        other: ['field'],
        list: [],
    },
    'newseriesfilter-command': {
        isList: false,
        args: [],
        functions: [],
        keywords: [],
        other: ['string'],
        list: [],
    },
    'nokv-command': {
        isList: false,
        args: [],
        functions: [],
        keywords: [],
        other: [],
        list: [],
    },
    'nomv-command': {
        isList: false,
        args: [],
        functions: [],
        keywords: [],
        other: ['field'],
        list: [],
    },
    'outlierfilter-command': {
        isList: false,
        args: [
            {
                key: 'action',
                valueType: '',
            },
            {
                key: 'param',
                valueType: 'num',
            },
            {
                key: 'uselower',
                valueType: 'bool',
            },
            {
                key: 'mark',
                valueType: 'bool',
            },
        ],
        functions: [],
        keywords: [],
        other: ['field-list'],
        list: [],
    },
    'outlier-command': {
        isList: false,
        args: [
            {
                key: 'action',
                valueType: '',
            },
            {
                key: 'param',
                valueType: 'num',
            },
            {
                key: 'uselower',
                valueType: 'bool',
            },
            {
                key: 'mark',
                valueType: 'bool',
            },
        ],
        functions: [],
        keywords: [],
        other: ['field-list'],
        list: [],
    },
    'outputcsv-command': {
        isList: false,
        args: [
            {
                key: 'append',
                valueType: 'bool',
            },
            {
                key: 'create_empty',
                valueType: 'bool',
            },
            {
                key: 'override_if_empty',
                valueType: '',
            },
            {
                key: 'dispatch',
                valueType: 'bool',
            },
            {
                key: 'usexml',
                valueType: 'bool',
            },
            {
                key: 'singlefile',
                valueType: 'bool',
            },
        ],
        functions: [],
        keywords: [],
        other: ['filename'],
        list: [],
    },
    'outputlookup-command': {
        isList: false,
        args: [
            {
                key: 'append',
                valueType: 'bool',
            },
            {
                key: 'create_empty',
                valueType: 'bool',
            },
            {
                key: 'override_if_empty',
                valueType: '',
            },
            {
                key: 'max',
                valueType: 'int',
            },
            {
                key: 'key_field',
                valueType: 'field',
            },
            {
                key: 'createinapp',
                valueType: 'bool',
            },
            {
                key: 'create_context',
                valueType: 'string',
            },
            {
                key: 'output_format',
                valueType: 'string',
            },
        ],
        functions: [],
        keywords: [],
        other: ['filename', 'string:tablename'],
        list: [],
    },
    'outputraw-command': {
        isList: false,
        args: [],
        functions: [],
        keywords: [],
        other: [],
        list: [],
    },
    'outputrawr-command': {
        isList: false,
        args: [],
        functions: [],
        keywords: [],
        other: [],
        list: [],
    },
    'outputtelemetry-command': {
        isList: false,
        args: [
            {
                key: 'input',
                valueType: 'string',
            },
            {
                key: 'type',
                valueType: 'string',
            },
            {
                key: 'component',
                valueType: 'string',
            },
            {
                key: 'support',
                valueType: 'bool',
            },
            {
                key: 'anonymous',
                valueType: 'bool',
            },
            {
                key: 'license',
                valueType: 'bool',
            },
            {
                key: 'optinrequired',
                valueType: 'int',
            },
        ],
        functions: [],
        keywords: [],
        other: [],
        list: [],
    },
    'outputtext-command': {
        isList: false,
        args: [
            {
                key: 'usexml',
                valueType: 'bool',
            },
        ],
        functions: [],
        keywords: [],
        other: [],
        list: [],
    },
    'overlap-command': {
        isList: false,
        args: [],
        functions: [],
        keywords: [],
        other: [],
        list: [],
    },
    'pivot-command': {
        isList: false,
        args: [],
        functions: [],
        keywords: [],
        other: ['datamodel-name', 'object-name', 'pivot-element'],
        list: [],
    },
    'predict-command': {
        isList: false,
        args: [
            {
                key: 'algorithm',
                valueType: '',
            },
            {
                key: 'correlate',
                valueType: 'field',
            },
            {
                key: 'future_timespan',
                valueType: 'num',
            },
            {
                key: 'holdback',
                valueType: 'num',
            },
            {
                key: 'period',
                valueType: 'num',
            },
            {
                key: 'upper\\d+',
                valueType: 'field',
            },
            {
                key: 'lower\\d+',
                valueType: 'field',
            },
            {
                key: 'suppress',
                valueType: 'field',
            },
        ],
        functions: [],
        keywords: ['as'],
        other: ['field-list', 'field'],
        list: [],
    },
    'preview-command': {
        isList: false,
        args: [],
        functions: [],
        keywords: [],
        other: [],
        list: [],
    },
    'prjob-command': {
        isList: false,
        args: [],
        functions: [],
        keywords: [],
        other: ['string'],
        list: [],
    },
    'rangemap-command': {
        isList: false,
        args: [
            {
                key: 'field',
                valueType: 'field',
            },
            {
                key: 'default',
                valueType: 'string',
            },
        ],
        functions: [],
        keywords: [],
        other: ['string', 'num'],
        list: [],
    },
    'rare-command': {
        isList: false,
        args: [
            {
                key: 'showcount',
                valueType: 'bool',
            },
            {
                key: 'showperc',
                valueType: 'bool',
            },
            {
                key: 'limit',
                valueType: 'int',
            },
            {
                key: 'countfield',
                valueType: 'string',
            },
            {
                key: 'percentfield',
                valueType: 'string',
            },
            {
                key: 'useother',
                valueType: 'bool',
            },
            {
                key: 'otherstr',
                valueType: 'string',
            },
        ],
        functions: [],
        keywords: ['by'],
        other: ['field-list'],
        list: [],
    },
    'rawstats-command': {
        isList: false,
        args: [],
        functions: [],
        keywords: [],
        other: [],
        list: [],
    },
    'redistribute-command': {
        isList: false,
        args: [
            {
                key: 'dfs',
                valueType: '',
            },
            {
                key: 'num_of_reducers',
                valueType: 'int',
            },
        ],
        functions: [],
        keywords: ['by'],
        other: ['field-list'],
        list: [],
    },
    'regex-command': {
        isList: false,
        args: [],
        functions: [],
        keywords: [],
        other: ['field', 'string'],
        list: [],
    },
    'relevancy-command': {
        isList: false,
        args: [],
        functions: [],
        keywords: [],
        other: [],
        list: [],
    },
    'reltime-command': {
        isList: false,
        args: [
            {
                key: 'timefield',
                valueType: 'field-list',
            },
            {
                key: 'prefix',
                valueType: 'string',
            },
        ],
        functions: [],
        keywords: [],
        other: [],
        list: [],
    },
    'rename-command': {
        isList: false,
        args: [],
        functions: [],
        keywords: ['as'],
        other: ['wc-field', 'wc-field'],
        list: [],
    },
    'replace-command': {
        isList: false,
        args: [],
        functions: [],
        keywords: ['with', 'in'],
        other: ['wc-str', 'wc-str', 'field-list'],
        list: [],
    },
    'require-command': {
        isList: false,
        args: [],
        functions: [],
        keywords: [],
        other: [],
        list: [],
    },
    'rest-command': {
        isList: false,
        args: [
            {
                key: 'count',
                valueType: 'int',
            },
            {
                key: 'strict',
                valueType: 'bool',
            },
            {
                key: 'splunk_server',
                valueType: 'string',
            },
            {
                key: 'splunk_server_group',
                valueType: 'string',
            },
            {
                key: 'timeout',
                valueType: 'int',
            },
        ],
        functions: [],
        keywords: [],
        other: ['string'],
        list: [],
    },
    'return-command': {
        isList: false,
        args: [],
        functions: [],
        keywords: [],
        other: ['int:count', 'field:alias', 'field', 'field', 'field'],
        list: [],
    },
    'reverse-command': {
        isList: false,
        args: [],
        functions: [],
        keywords: [],
        other: [],
        list: [],
    },
    'rex-command': {
        isList: false,
        args: [
            {
                key: 'field',
                valueType: 'field',
            },
            {
                key: 'max_match',
                valueType: 'int',
            },
            {
                key: 'offset_field',
                valueType: 'string',
            },
            {
                key: 'mode',
                valueType: '',
            },
        ],
        functions: [],
        keywords: [],
        other: ['string', 'sed-expression'],
        list: [],
    },
    'rtorder-command': {
        isList: false,
        args: [
            {
                key: 'discard',
                valueType: 'bool',
            },
            {
                key: 'buffer_span',
                valueType: 'span-length',
            },
            {
                key: 'max_buffer_size',
                valueType: 'int',
            },
        ],
        functions: [],
        keywords: [],
        other: [],
        list: [],
    },
    'runshellscript-command': {
        isList: false,
        args: [],
        functions: [],
        keywords: [],
        other: [
            'script-filename',
            'result-count',
            'search-terms',
            'search-string',
            'savedsearch-name',
            'description',
            'results-url',
            'deprecated-arg',
            'search-id',
        ],
        list: [],
    },
    'macro-command': {
        isList: false,
        args: [
            {
                key: 'nosubstitution',
                valueType: 'bool',
            },
        ],
        functions: [],
        keywords: [],
        other: ['string'],
        list: [],
    },
    'savedsplunk-command': {
        isList: false,
        args: [
            {
                key: 'nosubstitution',
                valueType: 'bool',
            },
        ],
        functions: [],
        keywords: [],
        other: ['string'],
        list: [],
    },
    'savedsearch-command': {
        isList: false,
        args: [
            {
                key: 'nosubstitution',
                valueType: 'bool',
            },
        ],
        functions: [],
        keywords: [],
        other: ['string'],
        list: [],
    },
    'run-command': {
        isList: false,
        args: [
            {
                key: 'maxinputs',
                valueType: 'int',
            },
        ],
        functions: [],
        keywords: [],
        other: ['string'],
        list: [],
    },
    'script-command': {
        isList: false,
        args: [
            {
                key: 'maxinputs',
                valueType: 'int',
            },
        ],
        functions: [],
        keywords: [],
        other: ['string'],
        list: [],
    },
    'scrub-command': {
        isList: false,
        args: [
            {
                key: 'public-terms',
                valueType: 'filename',
            },
            {
                key: 'private-terms',
                valueType: 'filename',
            },
            {
                key: 'name-terms',
                valueType: 'filename',
            },
            {
                key: 'dictionary',
                valueType: 'filename',
            },
            {
                key: 'timeconfig',
                valueType: 'filename',
            },
            {
                key: 'namespace',
                valueType: 'string',
            },
        ],
        functions: [],
        keywords: [],
        other: [],
        list: [],
    },
    'search-command': {
        isList: false,
        args: [
            {
                key: 'timeformat',
                valueType: 'string',
            },
            {
                key: 'earliest',
                valueType: 'time_modifier',
            },
            {
                key: '_index_earliest',
                valueType: 'time_modifier',
            },
            {
                key: 'starttime',
                valueType: 'string',
            },
            {
                key: 'startdaysago',
                valueType: 'int',
            },
            {
                key: 'startminutesago',
                valueType: 'int',
            },
            {
                key: 'starthoursago',
                valueType: 'int',
            },
            {
                key: 'startmonthsago',
                valueType: 'int',
            },
            {
                key: 'starttimeu',
                valueType: 'num',
            },
            {
                key: 'latest',
                valueType: 'time_modifier',
            },
            {
                key: '_index_latest',
                valueType: 'time_modifier',
            },
            {
                key: 'endtime',
                valueType: 'string',
            },
            {
                key: 'enddaysago',
                valueType: 'int',
            },
            {
                key: 'endminutesago',
                valueType: 'int',
            },
            {
                key: 'endhoursago',
                valueType: 'int',
            },
            {
                key: 'endmonthsago',
                valueType: 'int',
            },
            {
                key: 'endtimeu',
                valueType: 'num',
            },
            {
                key: 'searchtimespanhours',
                valueType: 'int',
            },
            {
                key: 'searchtimespanminutes',
                valueType: 'int',
            },
            {
                key: 'searchtimespandays',
                valueType: 'int',
            },
            {
                key: 'searchtimespanmonths',
                valueType: 'int',
            },
            {
                key: 'daysago',
                valueType: 'int',
            },
            {
                key: 'minutesago',
                valueType: 'int',
            },
            {
                key: 'hoursago',
                valueType: 'int',
            },
            {
                key: 'monthsago',
                valueType: 'int',
            },
            {
                key: 'savedsearch',
                valueType: 'string',
            },
            {
                key: 'savedsplunk',
                valueType: 'string',
            },
        ],
        functions: [],
        keywords: ['IN'],
        other: [
            'string',
            'field',
            'boolean-operator-not',
            'term',
            'num',
            'boolean-operator-or',
            'boolean-operator-and',
        ],
        list: [],
    },
    'searchtxn-command': {
        isList: false,
        args: [
            {
                key: 'max_terms',
                valueType: 'int',
            },
            {
                key: 'use_disjunct',
                valueType: 'bool',
            },
            {
                key: 'eventsonly',
                valueType: 'bool',
            },
        ],
        functions: [],
        keywords: [],
        other: ['string', 'search-string'],
        list: [],
    },
    'report-command': {
        isList: false,
        args: [],
        functions: [],
        keywords: [],
        other: ['string'],
        list: [],
    },
    'select-command': {
        isList: false,
        args: [],
        functions: [],
        keywords: [],
        other: ['string'],
        list: [],
    },
    'selfjoin-command': {
        isList: false,
        args: [
            {
                key: 'overwrite',
                valueType: 'bool',
            },
            {
                key: 'max',
                valueType: 'int',
            },
            {
                key: 'keepsingle',
                valueType: 'int',
            },
        ],
        functions: [],
        keywords: [],
        other: ['field-list'],
        list: [],
    },
    'sendalert-command': {
        isList: false,
        args: [
            {
                key: 'results_link',
                valueType: 'url',
            },
            {
                key: 'results_path',
                valueType: 'path',
            },
            {
                key: 'param.<param-name>',
                valueType: 'value',
            },
        ],
        functions: [],
        keywords: [],
        other: ['string'],
        list: [],
    },
    'sendemail-command': {
        isList: false,
        args: [
            {
                key: 'to',
                valueType: 'email_list',
            },
            {
                key: 'from',
                valueType: 'email_list',
            },
            {
                key: 'cc',
                valueType: 'email_list',
            },
            {
                key: 'bcc',
                valueType: 'email_list',
            },
            {
                key: 'subject',
                valueType: 'string',
            },
            {
                key: 'message',
                valueType: 'string',
            },
            {
                key: 'footer',
                valueType: 'string',
            },
            {
                key: 'sendresults',
                valueType: 'bool',
            },
            {
                key: 'inline',
                valueType: 'bool',
            },
            {
                key: 'format',
                valueType: '',
            },
            {
                key: 'sendcsv',
                valueType: 'bool',
            },
            {
                key: 'sendpdf',
                valueType: 'bool',
            },
            {
                key: 'pdfview',
                valueType: 'string',
            },
            {
                key: 'paperorientation',
                valueType: '',
            },
            {
                key: 'papersize',
                valueType: '',
            },
            {
                key: 'priority',
                valueType: '',
            },
            {
                key: 'server',
                valueType: 'string',
            },
            {
                key: 'graceful',
                valueType: 'bool',
            },
            {
                key: 'content_type',
                valueType: '',
            },
            {
                key: 'width_sort_columns',
                valueType: 'bool',
            },
            {
                key: 'use_ssl',
                valueType: 'bool',
            },
            {
                key: 'use_tls',
                valueType: 'bool',
            },
            {
                key: 'maxinputs',
                valueType: 'int',
            },
            {
                key: 'maxtime',
                valueType: '',
            },
        ],
        functions: [],
        keywords: [],
        other: [],
        list: [],
    },
    'set-command': {
        isList: false,
        args: [],
        functions: [],
        keywords: ['union|diff|intersect'],
        other: ['string'],
        list: [],
    },
    'setfields-command': {
        isList: false,
        args: [],
        functions: [],
        keywords: [],
        other: ['string'],
        list: [],
    },
    'shape-command': {
        isList: false,
        args: [
            {
                key: 'maxvalues',
                valueType: 'int',
            },
            {
                key: 'maxresolution',
                valueType: 'int',
            },
        ],
        functions: [],
        keywords: [],
        other: ['field'],
        list: [],
    },
    'showargs-command': {
        isList: false,
        args: [],
        functions: [],
        keywords: [],
        other: ['string'],
        list: [],
    },
    'sichart-command': {
        isList: false,
        args: [
            {
                key: 'sep',
                valueType: 'string',
            },
            {
                key: 'format',
                valueType: 'string',
            },
            {
                key: 'cont',
                valueType: 'bool',
            },
            {
                key: 'limit',
                valueType: 'chart-limit-opt',
            },
            {
                key: 'agg',
                valueType: 'stats-agg-term',
            },
            {
                key: 'bins',
                valueType: 'int',
            },
            {
                key: 'minspan',
                valueType: '',
            },
            {
                key: 'span',
                valueType: '',
            },
            {
                key: 'start',
                valueType: 'num',
            },
            {
                key: 'end',
                valueType: 'num',
            },
            {
                key: 'aligntime',
                valueType: '',
            },
            {
                key: 'usenull',
                valueType: 'bool',
            },
            {
                key: 'useother',
                valueType: 'bool',
            },
            {
                key: 'nullstr',
                valueType: 'string',
            },
            {
                key: 'otherstr',
                valueType: 'string',
            },
            {
                key: 'dedup_splitvals',
                valueType: 'bool',
            },
        ],
        functions: [
            {
                name: 'c',
                parenOptional: true,
            },
            {
                name: 'count',
                parenOptional: true,
            },
            {
                name: 'dc',
                parenOptional: true,
            },
            {
                name: 'distinct_count',
                parenOptional: true,
            },
            {
                name: 'mean',
                parenOptional: true,
            },
            {
                name: 'avg',
                parenOptional: true,
            },
            {
                name: 'stdev',
                parenOptional: true,
            },
            {
                name: 'stdevp',
                parenOptional: true,
            },
            {
                name: 'var',
                parenOptional: true,
            },
            {
                name: 'varp',
                parenOptional: true,
            },
            {
                name: 'sum',
                parenOptional: true,
            },
            {
                name: 'sumsq',
                parenOptional: true,
            },
            {
                name: 'min',
                parenOptional: true,
            },
            {
                name: 'max',
                parenOptional: true,
            },
            {
                name: 'mode',
                parenOptional: true,
            },
            {
                name: 'median',
                parenOptional: true,
            },
            {
                name: 'earliest',
                parenOptional: true,
            },
            {
                name: 'first',
                parenOptional: true,
            },
            {
                name: 'last',
                parenOptional: true,
            },
            {
                name: 'latest',
                parenOptional: true,
            },
            {
                name: 'list',
                parenOptional: true,
            },
            {
                name: 'values',
                parenOptional: true,
            },
            {
                name: 'range',
                parenOptional: true,
            },
            {
                name: 'estdc',
                parenOptional: true,
            },
            {
                name: 'estdc_error',
                parenOptional: true,
            },
            {
                name: 'earliest_time',
                parenOptional: true,
            },
            {
                name: 'latest_time',
                parenOptional: true,
            },
            {
                name: 'rate',
                parenOptional: true,
            },
            {
                name: 'eval',
                parenOptional: false,
            },
            {
                name: 'abs',
                parenOptional: false,
            },
            {
                name: 'avg',
                parenOptional: false,
            },
            {
                name: 'case',
                parenOptional: false,
            },
            {
                name: 'ceiling',
                parenOptional: false,
            },
            {
                name: 'ceil',
                parenOptional: false,
            },
            {
                name: 'cidrmatch',
                parenOptional: false,
            },
            {
                name: 'coalesce',
                parenOptional: false,
            },
            {
                name: 'commands',
                parenOptional: false,
            },
            {
                name: 'exact',
                parenOptional: false,
            },
            {
                name: 'exp',
                parenOptional: false,
            },
            {
                name: 'false',
                parenOptional: false,
            },
            {
                name: 'floor',
                parenOptional: false,
            },
            {
                name: 'if',
                parenOptional: false,
            },
            {
                name: 'ifnull',
                parenOptional: false,
            },
            {
                name: 'isbool',
                parenOptional: false,
            },
            {
                name: 'isint',
                parenOptional: false,
            },
            {
                name: 'isnotnull',
                parenOptional: false,
            },
            {
                name: 'isnull',
                parenOptional: false,
            },
            {
                name: 'isnum',
                parenOptional: false,
            },
            {
                name: 'isstr',
                parenOptional: false,
            },
            {
                name: 'len',
                parenOptional: false,
            },
            {
                name: 'like',
                parenOptional: false,
            },
            {
                name: 'ln',
                parenOptional: false,
            },
            {
                name: 'log',
                parenOptional: false,
            },
            {
                name: 'lookup',
                parenOptional: false,
            },
            {
                name: 'lower',
                parenOptional: false,
            },
            {
                name: 'match',
                parenOptional: false,
            },
            {
                name: 'max',
                parenOptional: false,
            },
            {
                name: 'md5',
                parenOptional: false,
            },
            {
                name: 'min',
                parenOptional: false,
            },
            {
                name: 'mvappend',
                parenOptional: false,
            },
            {
                name: 'mvcount',
                parenOptional: false,
            },
            {
                name: 'mvdedup',
                parenOptional: false,
            },
            {
                name: 'mvindex',
                parenOptional: false,
            },
            {
                name: 'mvfilter',
                parenOptional: false,
            },
            {
                name: 'mvfind',
                parenOptional: false,
            },
            {
                name: 'mvjoin',
                parenOptional: false,
            },
            {
                name: 'mvmap',
                parenOptional: false,
            },
            {
                name: 'mvrange',
                parenOptional: false,
            },
            {
                name: 'mvsort',
                parenOptional: false,
            },
            {
                name: 'mvzip',
                parenOptional: false,
            },
            {
                name: 'now',
                parenOptional: false,
            },
            {
                name: 'null',
                parenOptional: false,
            },
            {
                name: 'nullif',
                parenOptional: false,
            },
            {
                name: 'pi',
                parenOptional: false,
            },
            {
                name: 'pow',
                parenOptional: false,
            },
            {
                name: 'random',
                parenOptional: false,
            },
            {
                name: 'relative_time',
                parenOptional: false,
            },
            {
                name: 'replace',
                parenOptional: false,
            },
            {
                name: 'round',
                parenOptional: false,
            },
            {
                name: 'searchmatch',
                parenOptional: false,
            },
            {
                name: 'sha1',
                parenOptional: false,
            },
            {
                name: 'sha256',
                parenOptional: false,
            },
            {
                name: 'sha512',
                parenOptional: false,
            },
            {
                name: 'sigfig',
                parenOptional: false,
            },
            {
                name: 'spath',
                parenOptional: false,
            },
            {
                name: 'split',
                parenOptional: false,
            },
            {
                name: 'sqrt',
                parenOptional: false,
            },
            {
                name: 'strftime',
                parenOptional: false,
            },
            {
                name: 'strptime',
                parenOptional: false,
            },
            {
                name: 'substr',
                parenOptional: false,
            },
            {
                name: 'sum',
                parenOptional: false,
            },
            {
                name: 'time',
                parenOptional: false,
            },
            {
                name: 'tostring',
                parenOptional: false,
            },
            {
                name: 'trim',
                parenOptional: false,
            },
            {
                name: 'ltrim',
                parenOptional: false,
            },
            {
                name: 'rtrim',
                parenOptional: false,
            },
            {
                name: 'true',
                parenOptional: false,
            },
            {
                name: 'typeof',
                parenOptional: false,
            },
            {
                name: 'upper',
                parenOptional: false,
            },
            {
                name: 'urldecode',
                parenOptional: false,
            },
            {
                name: 'validate',
                parenOptional: false,
            },
            {
                name: 'tonumber',
                parenOptional: false,
            },
            {
                name: 'acos',
                parenOptional: false,
            },
            {
                name: 'acosh',
                parenOptional: false,
            },
            {
                name: 'asin',
                parenOptional: false,
            },
            {
                name: 'asinh',
                parenOptional: false,
            },
            {
                name: 'atan',
                parenOptional: false,
            },
            {
                name: 'atan2',
                parenOptional: false,
            },
            {
                name: 'atanh',
                parenOptional: false,
            },
            {
                name: 'cos',
                parenOptional: false,
            },
            {
                name: 'cosh',
                parenOptional: false,
            },
            {
                name: 'hypot',
                parenOptional: false,
            },
            {
                name: 'sin',
                parenOptional: false,
            },
            {
                name: 'sinh',
                parenOptional: false,
            },
            {
                name: 'tan',
                parenOptional: false,
            },
            {
                name: 'tanh',
                parenOptional: false,
            },
            {
                name: 'json_array_to_mv',
                parenOptional: false,
            },
            {
                name: 'mv_to_json_array',
                parenOptional: false,
            },
            {
                name: 'json_append',
                parenOptional: false,
            },
            {
                name: 'json_extend',
                parenOptional: false,
            },
            {
                name: 'json_extract_exact',
                parenOptional: false,
            },
            {
                name: 'json_set_exact',
                parenOptional: false,
            },
            {
                name: 'sparkline',
                parenOptional: false,
            },
            {
                name: 'c',
                parenOptional: false,
            },
            {
                name: 'count',
                parenOptional: false,
            },
            {
                name: 'dc',
                parenOptional: false,
            },
            {
                name: 'mean',
                parenOptional: false,
            },
            {
                name: 'avg',
                parenOptional: false,
            },
            {
                name: 'stdev',
                parenOptional: false,
            },
            {
                name: 'stdevp',
                parenOptional: false,
            },
            {
                name: 'var',
                parenOptional: false,
            },
            {
                name: 'varp',
                parenOptional: false,
            },
            {
                name: 'sum',
                parenOptional: false,
            },
            {
                name: 'sumsq',
                parenOptional: false,
            },
            {
                name: 'min',
                parenOptional: false,
            },
            {
                name: 'max',
                parenOptional: false,
            },
            {
                name: 'range',
                parenOptional: false,
            },
            {
                name: 'c',
                parenOptional: false,
            },
            {
                name: 'count',
                parenOptional: false,
            },
            {
                name: 'dc',
                parenOptional: false,
            },
            {
                name: 'distinct_count',
                parenOptional: false,
            },
            {
                name: 'mean',
                parenOptional: false,
            },
            {
                name: 'avg',
                parenOptional: false,
            },
            {
                name: 'stdev',
                parenOptional: false,
            },
            {
                name: 'stdevp',
                parenOptional: false,
            },
            {
                name: 'var',
                parenOptional: false,
            },
            {
                name: 'varp',
                parenOptional: false,
            },
            {
                name: 'sum',
                parenOptional: false,
            },
            {
                name: 'sumsq',
                parenOptional: false,
            },
            {
                name: 'min',
                parenOptional: false,
            },
            {
                name: 'max',
                parenOptional: false,
            },
            {
                name: 'mode',
                parenOptional: false,
            },
            {
                name: 'median',
                parenOptional: false,
            },
            {
                name: 'earliest',
                parenOptional: false,
            },
            {
                name: 'first',
                parenOptional: false,
            },
            {
                name: 'last',
                parenOptional: false,
            },
            {
                name: 'latest',
                parenOptional: false,
            },
            {
                name: 'list',
                parenOptional: false,
            },
            {
                name: 'values',
                parenOptional: false,
            },
            {
                name: 'range',
                parenOptional: false,
            },
            {
                name: 'estdc',
                parenOptional: false,
            },
            {
                name: 'estdc_error',
                parenOptional: false,
            },
            {
                name: 'earliest_time',
                parenOptional: false,
            },
            {
                name: 'latest_time',
                parenOptional: false,
            },
            {
                name: 'rate',
                parenOptional: false,
            },
        ],
        keywords: ['LIKE', 'AND|OR|XOR', 'as', 'BY', 'where', 'OVER'],
        other: ['field', 'num', 'string', 'wc-field', 'int', '|'],
        list: [],
    },
    'sirare-command': {
        isList: false,
        args: [
            {
                key: 'showcount',
                valueType: 'bool',
            },
            {
                key: 'showperc',
                valueType: 'bool',
            },
            {
                key: 'limit',
                valueType: 'int',
            },
            {
                key: 'countfield',
                valueType: 'string',
            },
            {
                key: 'percentfield',
                valueType: 'string',
            },
            {
                key: 'useother',
                valueType: 'bool',
            },
            {
                key: 'otherstr',
                valueType: 'string',
            },
        ],
        functions: [],
        keywords: ['by'],
        other: ['field-list'],
        list: [],
    },
    'sistats-command': {
        isList: false,
        args: [
            {
                key: 'partitions',
                valueType: 'num',
            },
            {
                key: 'allnum',
                valueType: 'bool',
            },
            {
                key: 'delim',
                valueType: 'string',
            },
            {
                key: 'dedup_splitvals',
                valueType: 'bool',
            },
        ],
        functions: [
            {
                name: 'c',
                parenOptional: true,
            },
            {
                name: 'count',
                parenOptional: true,
            },
            {
                name: 'dc',
                parenOptional: true,
            },
            {
                name: 'distinct_count',
                parenOptional: true,
            },
            {
                name: 'mean',
                parenOptional: true,
            },
            {
                name: 'avg',
                parenOptional: true,
            },
            {
                name: 'stdev',
                parenOptional: true,
            },
            {
                name: 'stdevp',
                parenOptional: true,
            },
            {
                name: 'var',
                parenOptional: true,
            },
            {
                name: 'varp',
                parenOptional: true,
            },
            {
                name: 'sum',
                parenOptional: true,
            },
            {
                name: 'sumsq',
                parenOptional: true,
            },
            {
                name: 'min',
                parenOptional: true,
            },
            {
                name: 'max',
                parenOptional: true,
            },
            {
                name: 'mode',
                parenOptional: true,
            },
            {
                name: 'median',
                parenOptional: true,
            },
            {
                name: 'earliest',
                parenOptional: true,
            },
            {
                name: 'first',
                parenOptional: true,
            },
            {
                name: 'last',
                parenOptional: true,
            },
            {
                name: 'latest',
                parenOptional: true,
            },
            {
                name: 'list',
                parenOptional: true,
            },
            {
                name: 'values',
                parenOptional: true,
            },
            {
                name: 'range',
                parenOptional: true,
            },
            {
                name: 'estdc',
                parenOptional: true,
            },
            {
                name: 'estdc_error',
                parenOptional: true,
            },
            {
                name: 'earliest_time',
                parenOptional: true,
            },
            {
                name: 'latest_time',
                parenOptional: true,
            },
            {
                name: 'rate',
                parenOptional: true,
            },
            {
                name: 'eval',
                parenOptional: false,
            },
            {
                name: 'abs',
                parenOptional: false,
            },
            {
                name: 'avg',
                parenOptional: false,
            },
            {
                name: 'case',
                parenOptional: false,
            },
            {
                name: 'ceiling',
                parenOptional: false,
            },
            {
                name: 'ceil',
                parenOptional: false,
            },
            {
                name: 'cidrmatch',
                parenOptional: false,
            },
            {
                name: 'coalesce',
                parenOptional: false,
            },
            {
                name: 'commands',
                parenOptional: false,
            },
            {
                name: 'exact',
                parenOptional: false,
            },
            {
                name: 'exp',
                parenOptional: false,
            },
            {
                name: 'false',
                parenOptional: false,
            },
            {
                name: 'floor',
                parenOptional: false,
            },
            {
                name: 'if',
                parenOptional: false,
            },
            {
                name: 'ifnull',
                parenOptional: false,
            },
            {
                name: 'isbool',
                parenOptional: false,
            },
            {
                name: 'isint',
                parenOptional: false,
            },
            {
                name: 'isnotnull',
                parenOptional: false,
            },
            {
                name: 'isnull',
                parenOptional: false,
            },
            {
                name: 'isnum',
                parenOptional: false,
            },
            {
                name: 'isstr',
                parenOptional: false,
            },
            {
                name: 'len',
                parenOptional: false,
            },
            {
                name: 'like',
                parenOptional: false,
            },
            {
                name: 'ln',
                parenOptional: false,
            },
            {
                name: 'log',
                parenOptional: false,
            },
            {
                name: 'lookup',
                parenOptional: false,
            },
            {
                name: 'lower',
                parenOptional: false,
            },
            {
                name: 'match',
                parenOptional: false,
            },
            {
                name: 'max',
                parenOptional: false,
            },
            {
                name: 'md5',
                parenOptional: false,
            },
            {
                name: 'min',
                parenOptional: false,
            },
            {
                name: 'mvappend',
                parenOptional: false,
            },
            {
                name: 'mvcount',
                parenOptional: false,
            },
            {
                name: 'mvdedup',
                parenOptional: false,
            },
            {
                name: 'mvindex',
                parenOptional: false,
            },
            {
                name: 'mvfilter',
                parenOptional: false,
            },
            {
                name: 'mvfind',
                parenOptional: false,
            },
            {
                name: 'mvjoin',
                parenOptional: false,
            },
            {
                name: 'mvmap',
                parenOptional: false,
            },
            {
                name: 'mvrange',
                parenOptional: false,
            },
            {
                name: 'mvsort',
                parenOptional: false,
            },
            {
                name: 'mvzip',
                parenOptional: false,
            },
            {
                name: 'now',
                parenOptional: false,
            },
            {
                name: 'null',
                parenOptional: false,
            },
            {
                name: 'nullif',
                parenOptional: false,
            },
            {
                name: 'pi',
                parenOptional: false,
            },
            {
                name: 'pow',
                parenOptional: false,
            },
            {
                name: 'random',
                parenOptional: false,
            },
            {
                name: 'relative_time',
                parenOptional: false,
            },
            {
                name: 'replace',
                parenOptional: false,
            },
            {
                name: 'round',
                parenOptional: false,
            },
            {
                name: 'searchmatch',
                parenOptional: false,
            },
            {
                name: 'sha1',
                parenOptional: false,
            },
            {
                name: 'sha256',
                parenOptional: false,
            },
            {
                name: 'sha512',
                parenOptional: false,
            },
            {
                name: 'sigfig',
                parenOptional: false,
            },
            {
                name: 'spath',
                parenOptional: false,
            },
            {
                name: 'split',
                parenOptional: false,
            },
            {
                name: 'sqrt',
                parenOptional: false,
            },
            {
                name: 'strftime',
                parenOptional: false,
            },
            {
                name: 'strptime',
                parenOptional: false,
            },
            {
                name: 'substr',
                parenOptional: false,
            },
            {
                name: 'sum',
                parenOptional: false,
            },
            {
                name: 'time',
                parenOptional: false,
            },
            {
                name: 'tostring',
                parenOptional: false,
            },
            {
                name: 'trim',
                parenOptional: false,
            },
            {
                name: 'ltrim',
                parenOptional: false,
            },
            {
                name: 'rtrim',
                parenOptional: false,
            },
            {
                name: 'true',
                parenOptional: false,
            },
            {
                name: 'typeof',
                parenOptional: false,
            },
            {
                name: 'upper',
                parenOptional: false,
            },
            {
                name: 'urldecode',
                parenOptional: false,
            },
            {
                name: 'validate',
                parenOptional: false,
            },
            {
                name: 'tonumber',
                parenOptional: false,
            },
            {
                name: 'acos',
                parenOptional: false,
            },
            {
                name: 'acosh',
                parenOptional: false,
            },
            {
                name: 'asin',
                parenOptional: false,
            },
            {
                name: 'asinh',
                parenOptional: false,
            },
            {
                name: 'atan',
                parenOptional: false,
            },
            {
                name: 'atan2',
                parenOptional: false,
            },
            {
                name: 'atanh',
                parenOptional: false,
            },
            {
                name: 'cos',
                parenOptional: false,
            },
            {
                name: 'cosh',
                parenOptional: false,
            },
            {
                name: 'hypot',
                parenOptional: false,
            },
            {
                name: 'sin',
                parenOptional: false,
            },
            {
                name: 'sinh',
                parenOptional: false,
            },
            {
                name: 'tan',
                parenOptional: false,
            },
            {
                name: 'tanh',
                parenOptional: false,
            },
            {
                name: 'json_array_to_mv',
                parenOptional: false,
            },
            {
                name: 'mv_to_json_array',
                parenOptional: false,
            },
            {
                name: 'json_append',
                parenOptional: false,
            },
            {
                name: 'json_extend',
                parenOptional: false,
            },
            {
                name: 'json_extract_exact',
                parenOptional: false,
            },
            {
                name: 'json_set_exact',
                parenOptional: false,
            },
            {
                name: 'sparkline',
                parenOptional: false,
            },
            {
                name: 'c',
                parenOptional: false,
            },
            {
                name: 'count',
                parenOptional: false,
            },
            {
                name: 'dc',
                parenOptional: false,
            },
            {
                name: 'mean',
                parenOptional: false,
            },
            {
                name: 'avg',
                parenOptional: false,
            },
            {
                name: 'stdev',
                parenOptional: false,
            },
            {
                name: 'stdevp',
                parenOptional: false,
            },
            {
                name: 'var',
                parenOptional: false,
            },
            {
                name: 'varp',
                parenOptional: false,
            },
            {
                name: 'sum',
                parenOptional: false,
            },
            {
                name: 'sumsq',
                parenOptional: false,
            },
            {
                name: 'min',
                parenOptional: false,
            },
            {
                name: 'max',
                parenOptional: false,
            },
            {
                name: 'range',
                parenOptional: false,
            },
        ],
        keywords: ['LIKE', 'AND|OR|XOR', 'as', 'by'],
        other: ['field', 'num', 'string', 'wc-field', 'int', 'field-list'],
        list: [],
    },
    'sitimechart-command': {
        isList: false,
        args: [
            {
                key: 'sep',
                valueType: 'string',
            },
            {
                key: 'format',
                valueType: 'string',
            },
            {
                key: 'fixedrange',
                valueType: 'bool',
            },
            {
                key: 'partial',
                valueType: 'bool',
            },
            {
                key: 'cont',
                valueType: 'bool',
            },
            {
                key: 'limit',
                valueType: 'chart-limit-opt',
            },
            {
                key: 'bins',
                valueType: 'int',
            },
            {
                key: 'minspan',
                valueType: '',
            },
            {
                key: 'span',
                valueType: '',
            },
            {
                key: 'start',
                valueType: 'num',
            },
            {
                key: 'end',
                valueType: 'num',
            },
            {
                key: 'aligntime',
                valueType: '',
            },
            {
                key: 'usenull',
                valueType: 'bool',
            },
            {
                key: 'useother',
                valueType: 'bool',
            },
            {
                key: 'nullstr',
                valueType: 'string',
            },
            {
                key: 'otherstr',
                valueType: 'string',
            },
            {
                key: 'dedup_splitvals',
                valueType: 'bool',
            },
        ],
        functions: [
            {
                name: 'c',
                parenOptional: true,
            },
            {
                name: 'count',
                parenOptional: true,
            },
            {
                name: 'dc',
                parenOptional: true,
            },
            {
                name: 'distinct_count',
                parenOptional: true,
            },
            {
                name: 'mean',
                parenOptional: true,
            },
            {
                name: 'avg',
                parenOptional: true,
            },
            {
                name: 'stdev',
                parenOptional: true,
            },
            {
                name: 'stdevp',
                parenOptional: true,
            },
            {
                name: 'var',
                parenOptional: true,
            },
            {
                name: 'varp',
                parenOptional: true,
            },
            {
                name: 'sum',
                parenOptional: true,
            },
            {
                name: 'sumsq',
                parenOptional: true,
            },
            {
                name: 'min',
                parenOptional: true,
            },
            {
                name: 'max',
                parenOptional: true,
            },
            {
                name: 'mode',
                parenOptional: true,
            },
            {
                name: 'median',
                parenOptional: true,
            },
            {
                name: 'earliest',
                parenOptional: true,
            },
            {
                name: 'first',
                parenOptional: true,
            },
            {
                name: 'last',
                parenOptional: true,
            },
            {
                name: 'latest',
                parenOptional: true,
            },
            {
                name: 'list',
                parenOptional: true,
            },
            {
                name: 'values',
                parenOptional: true,
            },
            {
                name: 'range',
                parenOptional: true,
            },
            {
                name: 'estdc',
                parenOptional: true,
            },
            {
                name: 'estdc_error',
                parenOptional: true,
            },
            {
                name: 'earliest_time',
                parenOptional: true,
            },
            {
                name: 'latest_time',
                parenOptional: true,
            },
            {
                name: 'rate',
                parenOptional: true,
            },
            {
                name: 'eval',
                parenOptional: false,
            },
            {
                name: 'abs',
                parenOptional: false,
            },
            {
                name: 'avg',
                parenOptional: false,
            },
            {
                name: 'case',
                parenOptional: false,
            },
            {
                name: 'ceiling',
                parenOptional: false,
            },
            {
                name: 'ceil',
                parenOptional: false,
            },
            {
                name: 'cidrmatch',
                parenOptional: false,
            },
            {
                name: 'coalesce',
                parenOptional: false,
            },
            {
                name: 'commands',
                parenOptional: false,
            },
            {
                name: 'exact',
                parenOptional: false,
            },
            {
                name: 'exp',
                parenOptional: false,
            },
            {
                name: 'false',
                parenOptional: false,
            },
            {
                name: 'floor',
                parenOptional: false,
            },
            {
                name: 'if',
                parenOptional: false,
            },
            {
                name: 'ifnull',
                parenOptional: false,
            },
            {
                name: 'isbool',
                parenOptional: false,
            },
            {
                name: 'isint',
                parenOptional: false,
            },
            {
                name: 'isnotnull',
                parenOptional: false,
            },
            {
                name: 'isnull',
                parenOptional: false,
            },
            {
                name: 'isnum',
                parenOptional: false,
            },
            {
                name: 'isstr',
                parenOptional: false,
            },
            {
                name: 'len',
                parenOptional: false,
            },
            {
                name: 'like',
                parenOptional: false,
            },
            {
                name: 'ln',
                parenOptional: false,
            },
            {
                name: 'log',
                parenOptional: false,
            },
            {
                name: 'lookup',
                parenOptional: false,
            },
            {
                name: 'lower',
                parenOptional: false,
            },
            {
                name: 'match',
                parenOptional: false,
            },
            {
                name: 'max',
                parenOptional: false,
            },
            {
                name: 'md5',
                parenOptional: false,
            },
            {
                name: 'min',
                parenOptional: false,
            },
            {
                name: 'mvappend',
                parenOptional: false,
            },
            {
                name: 'mvcount',
                parenOptional: false,
            },
            {
                name: 'mvdedup',
                parenOptional: false,
            },
            {
                name: 'mvindex',
                parenOptional: false,
            },
            {
                name: 'mvfilter',
                parenOptional: false,
            },
            {
                name: 'mvfind',
                parenOptional: false,
            },
            {
                name: 'mvjoin',
                parenOptional: false,
            },
            {
                name: 'mvmap',
                parenOptional: false,
            },
            {
                name: 'mvrange',
                parenOptional: false,
            },
            {
                name: 'mvsort',
                parenOptional: false,
            },
            {
                name: 'mvzip',
                parenOptional: false,
            },
            {
                name: 'now',
                parenOptional: false,
            },
            {
                name: 'null',
                parenOptional: false,
            },
            {
                name: 'nullif',
                parenOptional: false,
            },
            {
                name: 'pi',
                parenOptional: false,
            },
            {
                name: 'pow',
                parenOptional: false,
            },
            {
                name: 'random',
                parenOptional: false,
            },
            {
                name: 'relative_time',
                parenOptional: false,
            },
            {
                name: 'replace',
                parenOptional: false,
            },
            {
                name: 'round',
                parenOptional: false,
            },
            {
                name: 'searchmatch',
                parenOptional: false,
            },
            {
                name: 'sha1',
                parenOptional: false,
            },
            {
                name: 'sha256',
                parenOptional: false,
            },
            {
                name: 'sha512',
                parenOptional: false,
            },
            {
                name: 'sigfig',
                parenOptional: false,
            },
            {
                name: 'spath',
                parenOptional: false,
            },
            {
                name: 'split',
                parenOptional: false,
            },
            {
                name: 'sqrt',
                parenOptional: false,
            },
            {
                name: 'strftime',
                parenOptional: false,
            },
            {
                name: 'strptime',
                parenOptional: false,
            },
            {
                name: 'substr',
                parenOptional: false,
            },
            {
                name: 'sum',
                parenOptional: false,
            },
            {
                name: 'time',
                parenOptional: false,
            },
            {
                name: 'tostring',
                parenOptional: false,
            },
            {
                name: 'trim',
                parenOptional: false,
            },
            {
                name: 'ltrim',
                parenOptional: false,
            },
            {
                name: 'rtrim',
                parenOptional: false,
            },
            {
                name: 'true',
                parenOptional: false,
            },
            {
                name: 'typeof',
                parenOptional: false,
            },
            {
                name: 'upper',
                parenOptional: false,
            },
            {
                name: 'urldecode',
                parenOptional: false,
            },
            {
                name: 'validate',
                parenOptional: false,
            },
            {
                name: 'tonumber',
                parenOptional: false,
            },
            {
                name: 'acos',
                parenOptional: false,
            },
            {
                name: 'acosh',
                parenOptional: false,
            },
            {
                name: 'asin',
                parenOptional: false,
            },
            {
                name: 'asinh',
                parenOptional: false,
            },
            {
                name: 'atan',
                parenOptional: false,
            },
            {
                name: 'atan2',
                parenOptional: false,
            },
            {
                name: 'atanh',
                parenOptional: false,
            },
            {
                name: 'cos',
                parenOptional: false,
            },
            {
                name: 'cosh',
                parenOptional: false,
            },
            {
                name: 'hypot',
                parenOptional: false,
            },
            {
                name: 'sin',
                parenOptional: false,
            },
            {
                name: 'sinh',
                parenOptional: false,
            },
            {
                name: 'tan',
                parenOptional: false,
            },
            {
                name: 'tanh',
                parenOptional: false,
            },
            {
                name: 'json_array_to_mv',
                parenOptional: false,
            },
            {
                name: 'mv_to_json_array',
                parenOptional: false,
            },
            {
                name: 'json_append',
                parenOptional: false,
            },
            {
                name: 'json_extend',
                parenOptional: false,
            },
            {
                name: 'json_extract_exact',
                parenOptional: false,
            },
            {
                name: 'json_set_exact',
                parenOptional: false,
            },
            {
                name: 'c',
                parenOptional: false,
            },
            {
                name: 'count',
                parenOptional: false,
            },
            {
                name: 'dc',
                parenOptional: false,
            },
            {
                name: 'distinct_count',
                parenOptional: false,
            },
            {
                name: 'mean',
                parenOptional: false,
            },
            {
                name: 'avg',
                parenOptional: false,
            },
            {
                name: 'stdev',
                parenOptional: false,
            },
            {
                name: 'stdevp',
                parenOptional: false,
            },
            {
                name: 'var',
                parenOptional: false,
            },
            {
                name: 'varp',
                parenOptional: false,
            },
            {
                name: 'sum',
                parenOptional: false,
            },
            {
                name: 'sumsq',
                parenOptional: false,
            },
            {
                name: 'min',
                parenOptional: false,
            },
            {
                name: 'max',
                parenOptional: false,
            },
            {
                name: 'mode',
                parenOptional: false,
            },
            {
                name: 'median',
                parenOptional: false,
            },
            {
                name: 'earliest',
                parenOptional: false,
            },
            {
                name: 'first',
                parenOptional: false,
            },
            {
                name: 'last',
                parenOptional: false,
            },
            {
                name: 'latest',
                parenOptional: false,
            },
            {
                name: 'list',
                parenOptional: false,
            },
            {
                name: 'values',
                parenOptional: false,
            },
            {
                name: 'range',
                parenOptional: false,
            },
            {
                name: 'estdc',
                parenOptional: false,
            },
            {
                name: 'estdc_error',
                parenOptional: false,
            },
            {
                name: 'earliest_time',
                parenOptional: false,
            },
            {
                name: 'latest_time',
                parenOptional: false,
            },
            {
                name: 'rate',
                parenOptional: false,
            },
            {
                name: 'per_second',
                parenOptional: false,
            },
            {
                name: 'per_minute',
                parenOptional: false,
            },
            {
                name: 'per_hour',
                parenOptional: false,
            },
            {
                name: 'per_day',
                parenOptional: false,
            },
        ],
        keywords: ['LIKE', 'AND|OR|XOR', 'as', 'by', 'where'],
        other: ['field', 'num', 'string', 'wc-field', 'int', '|'],
        list: [],
    },
    'sitop-command': {
        isList: false,
        args: [
            {
                key: 'showcount',
                valueType: 'bool',
            },
            {
                key: 'showperc',
                valueType: 'bool',
            },
            {
                key: 'limit',
                valueType: 'int',
            },
            {
                key: 'countfield',
                valueType: 'string',
            },
            {
                key: 'percentfield',
                valueType: 'string',
            },
            {
                key: 'useother',
                valueType: 'bool',
            },
            {
                key: 'otherstr',
                valueType: 'string',
            },
        ],
        functions: [],
        keywords: ['by'],
        other: ['int', 'field-list'],
        list: [],
    },
    'sort-command': {
        isList: false,
        args: [],
        functions: [],
        keywords: [],
        other: ['int', 'field'],
        list: [],
    },
    'spath-command': {
        isList: false,
        args: [
            {
                key: 'output',
                valueType: 'field',
            },
            {
                key: 'path',
                valueType: 'datapath',
            },
            {
                key: 'input',
                valueType: 'field',
            },
        ],
        functions: [],
        keywords: [],
        other: ['datapath'],
        list: [],
    },
    'stats-command': {
        isList: false,
        args: [
            {
                key: 'partitions',
                valueType: 'num',
            },
            {
                key: 'allnum',
                valueType: 'bool',
            },
            {
                key: 'delim',
                valueType: 'string',
            },
            {
                key: 'dedup_splitvals',
                valueType: 'bool',
            },
        ],
        functions: [
            {
                name: 'c',
                parenOptional: true,
            },
            {
                name: 'count',
                parenOptional: true,
            },
            {
                name: 'dc',
                parenOptional: true,
            },
            {
                name: 'distinct_count',
                parenOptional: true,
            },
            {
                name: 'mean',
                parenOptional: true,
            },
            {
                name: 'avg',
                parenOptional: true,
            },
            {
                name: 'stdev',
                parenOptional: true,
            },
            {
                name: 'stdevp',
                parenOptional: true,
            },
            {
                name: 'var',
                parenOptional: true,
            },
            {
                name: 'varp',
                parenOptional: true,
            },
            {
                name: 'sum',
                parenOptional: true,
            },
            {
                name: 'sumsq',
                parenOptional: true,
            },
            {
                name: 'min',
                parenOptional: true,
            },
            {
                name: 'max',
                parenOptional: true,
            },
            {
                name: 'mode',
                parenOptional: true,
            },
            {
                name: 'median',
                parenOptional: true,
            },
            {
                name: 'earliest',
                parenOptional: true,
            },
            {
                name: 'first',
                parenOptional: true,
            },
            {
                name: 'last',
                parenOptional: true,
            },
            {
                name: 'latest',
                parenOptional: true,
            },
            {
                name: 'list',
                parenOptional: true,
            },
            {
                name: 'values',
                parenOptional: true,
            },
            {
                name: 'range',
                parenOptional: true,
            },
            {
                name: 'estdc',
                parenOptional: true,
            },
            {
                name: 'estdc_error',
                parenOptional: true,
            },
            {
                name: 'earliest_time',
                parenOptional: true,
            },
            {
                name: 'latest_time',
                parenOptional: true,
            },
            {
                name: 'rate',
                parenOptional: true,
            },
            {
                name: 'eval',
                parenOptional: false,
            },
            {
                name: 'abs',
                parenOptional: false,
            },
            {
                name: 'avg',
                parenOptional: false,
            },
            {
                name: 'case',
                parenOptional: false,
            },
            {
                name: 'ceiling',
                parenOptional: false,
            },
            {
                name: 'ceil',
                parenOptional: false,
            },
            {
                name: 'cidrmatch',
                parenOptional: false,
            },
            {
                name: 'coalesce',
                parenOptional: false,
            },
            {
                name: 'commands',
                parenOptional: false,
            },
            {
                name: 'exact',
                parenOptional: false,
            },
            {
                name: 'exp',
                parenOptional: false,
            },
            {
                name: 'false',
                parenOptional: false,
            },
            {
                name: 'floor',
                parenOptional: false,
            },
            {
                name: 'if',
                parenOptional: false,
            },
            {
                name: 'ifnull',
                parenOptional: false,
            },
            {
                name: 'isbool',
                parenOptional: false,
            },
            {
                name: 'isint',
                parenOptional: false,
            },
            {
                name: 'isnotnull',
                parenOptional: false,
            },
            {
                name: 'isnull',
                parenOptional: false,
            },
            {
                name: 'isnum',
                parenOptional: false,
            },
            {
                name: 'isstr',
                parenOptional: false,
            },
            {
                name: 'len',
                parenOptional: false,
            },
            {
                name: 'like',
                parenOptional: false,
            },
            {
                name: 'ln',
                parenOptional: false,
            },
            {
                name: 'log',
                parenOptional: false,
            },
            {
                name: 'lookup',
                parenOptional: false,
            },
            {
                name: 'lower',
                parenOptional: false,
            },
            {
                name: 'match',
                parenOptional: false,
            },
            {
                name: 'max',
                parenOptional: false,
            },
            {
                name: 'md5',
                parenOptional: false,
            },
            {
                name: 'min',
                parenOptional: false,
            },
            {
                name: 'mvappend',
                parenOptional: false,
            },
            {
                name: 'mvcount',
                parenOptional: false,
            },
            {
                name: 'mvdedup',
                parenOptional: false,
            },
            {
                name: 'mvindex',
                parenOptional: false,
            },
            {
                name: 'mvfilter',
                parenOptional: false,
            },
            {
                name: 'mvfind',
                parenOptional: false,
            },
            {
                name: 'mvjoin',
                parenOptional: false,
            },
            {
                name: 'mvmap',
                parenOptional: false,
            },
            {
                name: 'mvrange',
                parenOptional: false,
            },
            {
                name: 'mvsort',
                parenOptional: false,
            },
            {
                name: 'mvzip',
                parenOptional: false,
            },
            {
                name: 'now',
                parenOptional: false,
            },
            {
                name: 'null',
                parenOptional: false,
            },
            {
                name: 'nullif',
                parenOptional: false,
            },
            {
                name: 'pi',
                parenOptional: false,
            },
            {
                name: 'pow',
                parenOptional: false,
            },
            {
                name: 'random',
                parenOptional: false,
            },
            {
                name: 'relative_time',
                parenOptional: false,
            },
            {
                name: 'replace',
                parenOptional: false,
            },
            {
                name: 'round',
                parenOptional: false,
            },
            {
                name: 'searchmatch',
                parenOptional: false,
            },
            {
                name: 'sha1',
                parenOptional: false,
            },
            {
                name: 'sha256',
                parenOptional: false,
            },
            {
                name: 'sha512',
                parenOptional: false,
            },
            {
                name: 'sigfig',
                parenOptional: false,
            },
            {
                name: 'spath',
                parenOptional: false,
            },
            {
                name: 'split',
                parenOptional: false,
            },
            {
                name: 'sqrt',
                parenOptional: false,
            },
            {
                name: 'strftime',
                parenOptional: false,
            },
            {
                name: 'strptime',
                parenOptional: false,
            },
            {
                name: 'substr',
                parenOptional: false,
            },
            {
                name: 'sum',
                parenOptional: false,
            },
            {
                name: 'time',
                parenOptional: false,
            },
            {
                name: 'tostring',
                parenOptional: false,
            },
            {
                name: 'trim',
                parenOptional: false,
            },
            {
                name: 'ltrim',
                parenOptional: false,
            },
            {
                name: 'rtrim',
                parenOptional: false,
            },
            {
                name: 'true',
                parenOptional: false,
            },
            {
                name: 'typeof',
                parenOptional: false,
            },
            {
                name: 'upper',
                parenOptional: false,
            },
            {
                name: 'urldecode',
                parenOptional: false,
            },
            {
                name: 'validate',
                parenOptional: false,
            },
            {
                name: 'tonumber',
                parenOptional: false,
            },
            {
                name: 'acos',
                parenOptional: false,
            },
            {
                name: 'acosh',
                parenOptional: false,
            },
            {
                name: 'asin',
                parenOptional: false,
            },
            {
                name: 'asinh',
                parenOptional: false,
            },
            {
                name: 'atan',
                parenOptional: false,
            },
            {
                name: 'atan2',
                parenOptional: false,
            },
            {
                name: 'atanh',
                parenOptional: false,
            },
            {
                name: 'cos',
                parenOptional: false,
            },
            {
                name: 'cosh',
                parenOptional: false,
            },
            {
                name: 'hypot',
                parenOptional: false,
            },
            {
                name: 'sin',
                parenOptional: false,
            },
            {
                name: 'sinh',
                parenOptional: false,
            },
            {
                name: 'tan',
                parenOptional: false,
            },
            {
                name: 'tanh',
                parenOptional: false,
            },
            {
                name: 'json_array_to_mv',
                parenOptional: false,
            },
            {
                name: 'mv_to_json_array',
                parenOptional: false,
            },
            {
                name: 'json_append',
                parenOptional: false,
            },
            {
                name: 'json_extend',
                parenOptional: false,
            },
            {
                name: 'json_extract_exact',
                parenOptional: false,
            },
            {
                name: 'json_set_exact',
                parenOptional: false,
            },
            {
                name: 'sparkline',
                parenOptional: false,
            },
            {
                name: 'c',
                parenOptional: false,
            },
            {
                name: 'count',
                parenOptional: false,
            },
            {
                name: 'dc',
                parenOptional: false,
            },
            {
                name: 'mean',
                parenOptional: false,
            },
            {
                name: 'avg',
                parenOptional: false,
            },
            {
                name: 'stdev',
                parenOptional: false,
            },
            {
                name: 'stdevp',
                parenOptional: false,
            },
            {
                name: 'var',
                parenOptional: false,
            },
            {
                name: 'varp',
                parenOptional: false,
            },
            {
                name: 'sum',
                parenOptional: false,
            },
            {
                name: 'sumsq',
                parenOptional: false,
            },
            {
                name: 'min',
                parenOptional: false,
            },
            {
                name: 'max',
                parenOptional: false,
            },
            {
                name: 'range',
                parenOptional: false,
            },
        ],
        keywords: ['LIKE', 'AND|OR|XOR', 'as', 'by'],
        other: ['field', 'num', 'string', 'wc-field', 'int', 'field-list'],
        list: [],
    },
    'strcat-command': {
        isList: false,
        args: [
            {
                key: 'allrequired',
                valueType: 'bool',
            },
        ],
        functions: [],
        keywords: [],
        other: ['field', 'double-quoted-string', 'field'],
        list: [],
    },
    'streamedcsv-command': {
        isList: false,
        args: [
            {
                key: 'chunk',
                valueType: 'int',
            },
        ],
        functions: [],
        keywords: [],
        other: ['string'],
        list: [],
    },
    'streamstats-command': {
        isList: false,
        args: [
            {
                key: 'reset_on_change',
                valueType: 'bool',
            },
            {
                key: 'reset_before',
                valueType: '',
            },
            {
                key: 'reset_after',
                valueType: '',
            },
            {
                key: 'current',
                valueType: 'bool',
            },
            {
                key: 'window',
                valueType: 'int',
            },
            {
                key: 'time_window',
                valueType: 'span-length',
            },
            {
                key: 'global',
                valueType: 'bool',
            },
            {
                key: 'allnum',
                valueType: 'bool',
            },
        ],
        functions: [
            {
                name: 'c',
                parenOptional: true,
            },
            {
                name: 'count',
                parenOptional: true,
            },
            {
                name: 'dc',
                parenOptional: true,
            },
            {
                name: 'distinct_count',
                parenOptional: true,
            },
            {
                name: 'mean',
                parenOptional: true,
            },
            {
                name: 'avg',
                parenOptional: true,
            },
            {
                name: 'stdev',
                parenOptional: true,
            },
            {
                name: 'stdevp',
                parenOptional: true,
            },
            {
                name: 'var',
                parenOptional: true,
            },
            {
                name: 'varp',
                parenOptional: true,
            },
            {
                name: 'sum',
                parenOptional: true,
            },
            {
                name: 'sumsq',
                parenOptional: true,
            },
            {
                name: 'min',
                parenOptional: true,
            },
            {
                name: 'max',
                parenOptional: true,
            },
            {
                name: 'mode',
                parenOptional: true,
            },
            {
                name: 'median',
                parenOptional: true,
            },
            {
                name: 'earliest',
                parenOptional: true,
            },
            {
                name: 'first',
                parenOptional: true,
            },
            {
                name: 'last',
                parenOptional: true,
            },
            {
                name: 'latest',
                parenOptional: true,
            },
            {
                name: 'list',
                parenOptional: true,
            },
            {
                name: 'values',
                parenOptional: true,
            },
            {
                name: 'range',
                parenOptional: true,
            },
            {
                name: 'estdc',
                parenOptional: true,
            },
            {
                name: 'estdc_error',
                parenOptional: true,
            },
            {
                name: 'earliest_time',
                parenOptional: true,
            },
            {
                name: 'latest_time',
                parenOptional: true,
            },
            {
                name: 'rate',
                parenOptional: true,
            },
            {
                name: 'eval',
                parenOptional: false,
            },
            {
                name: 'abs',
                parenOptional: false,
            },
            {
                name: 'avg',
                parenOptional: false,
            },
            {
                name: 'case',
                parenOptional: false,
            },
            {
                name: 'ceiling',
                parenOptional: false,
            },
            {
                name: 'ceil',
                parenOptional: false,
            },
            {
                name: 'cidrmatch',
                parenOptional: false,
            },
            {
                name: 'coalesce',
                parenOptional: false,
            },
            {
                name: 'commands',
                parenOptional: false,
            },
            {
                name: 'exact',
                parenOptional: false,
            },
            {
                name: 'exp',
                parenOptional: false,
            },
            {
                name: 'false',
                parenOptional: false,
            },
            {
                name: 'floor',
                parenOptional: false,
            },
            {
                name: 'if',
                parenOptional: false,
            },
            {
                name: 'ifnull',
                parenOptional: false,
            },
            {
                name: 'isbool',
                parenOptional: false,
            },
            {
                name: 'isint',
                parenOptional: false,
            },
            {
                name: 'isnotnull',
                parenOptional: false,
            },
            {
                name: 'isnull',
                parenOptional: false,
            },
            {
                name: 'isnum',
                parenOptional: false,
            },
            {
                name: 'isstr',
                parenOptional: false,
            },
            {
                name: 'len',
                parenOptional: false,
            },
            {
                name: 'like',
                parenOptional: false,
            },
            {
                name: 'ln',
                parenOptional: false,
            },
            {
                name: 'log',
                parenOptional: false,
            },
            {
                name: 'lookup',
                parenOptional: false,
            },
            {
                name: 'lower',
                parenOptional: false,
            },
            {
                name: 'match',
                parenOptional: false,
            },
            {
                name: 'max',
                parenOptional: false,
            },
            {
                name: 'md5',
                parenOptional: false,
            },
            {
                name: 'min',
                parenOptional: false,
            },
            {
                name: 'mvappend',
                parenOptional: false,
            },
            {
                name: 'mvcount',
                parenOptional: false,
            },
            {
                name: 'mvdedup',
                parenOptional: false,
            },
            {
                name: 'mvindex',
                parenOptional: false,
            },
            {
                name: 'mvfilter',
                parenOptional: false,
            },
            {
                name: 'mvfind',
                parenOptional: false,
            },
            {
                name: 'mvjoin',
                parenOptional: false,
            },
            {
                name: 'mvmap',
                parenOptional: false,
            },
            {
                name: 'mvrange',
                parenOptional: false,
            },
            {
                name: 'mvsort',
                parenOptional: false,
            },
            {
                name: 'mvzip',
                parenOptional: false,
            },
            {
                name: 'now',
                parenOptional: false,
            },
            {
                name: 'null',
                parenOptional: false,
            },
            {
                name: 'nullif',
                parenOptional: false,
            },
            {
                name: 'pi',
                parenOptional: false,
            },
            {
                name: 'pow',
                parenOptional: false,
            },
            {
                name: 'random',
                parenOptional: false,
            },
            {
                name: 'relative_time',
                parenOptional: false,
            },
            {
                name: 'replace',
                parenOptional: false,
            },
            {
                name: 'round',
                parenOptional: false,
            },
            {
                name: 'searchmatch',
                parenOptional: false,
            },
            {
                name: 'sha1',
                parenOptional: false,
            },
            {
                name: 'sha256',
                parenOptional: false,
            },
            {
                name: 'sha512',
                parenOptional: false,
            },
            {
                name: 'sigfig',
                parenOptional: false,
            },
            {
                name: 'spath',
                parenOptional: false,
            },
            {
                name: 'split',
                parenOptional: false,
            },
            {
                name: 'sqrt',
                parenOptional: false,
            },
            {
                name: 'strftime',
                parenOptional: false,
            },
            {
                name: 'strptime',
                parenOptional: false,
            },
            {
                name: 'substr',
                parenOptional: false,
            },
            {
                name: 'sum',
                parenOptional: false,
            },
            {
                name: 'time',
                parenOptional: false,
            },
            {
                name: 'tostring',
                parenOptional: false,
            },
            {
                name: 'trim',
                parenOptional: false,
            },
            {
                name: 'ltrim',
                parenOptional: false,
            },
            {
                name: 'rtrim',
                parenOptional: false,
            },
            {
                name: 'true',
                parenOptional: false,
            },
            {
                name: 'typeof',
                parenOptional: false,
            },
            {
                name: 'upper',
                parenOptional: false,
            },
            {
                name: 'urldecode',
                parenOptional: false,
            },
            {
                name: 'validate',
                parenOptional: false,
            },
            {
                name: 'tonumber',
                parenOptional: false,
            },
            {
                name: 'acos',
                parenOptional: false,
            },
            {
                name: 'acosh',
                parenOptional: false,
            },
            {
                name: 'asin',
                parenOptional: false,
            },
            {
                name: 'asinh',
                parenOptional: false,
            },
            {
                name: 'atan',
                parenOptional: false,
            },
            {
                name: 'atan2',
                parenOptional: false,
            },
            {
                name: 'atanh',
                parenOptional: false,
            },
            {
                name: 'cos',
                parenOptional: false,
            },
            {
                name: 'cosh',
                parenOptional: false,
            },
            {
                name: 'hypot',
                parenOptional: false,
            },
            {
                name: 'sin',
                parenOptional: false,
            },
            {
                name: 'sinh',
                parenOptional: false,
            },
            {
                name: 'tan',
                parenOptional: false,
            },
            {
                name: 'tanh',
                parenOptional: false,
            },
            {
                name: 'json_array_to_mv',
                parenOptional: false,
            },
            {
                name: 'mv_to_json_array',
                parenOptional: false,
            },
            {
                name: 'json_append',
                parenOptional: false,
            },
            {
                name: 'json_extend',
                parenOptional: false,
            },
            {
                name: 'json_extract_exact',
                parenOptional: false,
            },
            {
                name: 'json_set_exact',
                parenOptional: false,
            },
        ],
        keywords: ['LIKE', 'AND|OR|XOR', 'as', 'by'],
        other: ['field', 'num', 'string', 'wc-field', 'field-list'],
        list: [],
    },
    'surrounding-command': {
        isList: false,
        args: [
            {
                key: 'id',
                valueType: 'event-id',
            },
            {
                key: 'timebefore',
                valueType: 'int',
            },
            {
                key: 'timeafter',
                valueType: 'int',
            },
            {
                key: 'searchkeys',
                valueType: 'key-list',
            },
            {
                key: 'readlevel',
                valueType: 'readlevel-int',
            },
        ],
        functions: [],
        keywords: [],
        other: ['int:maxresults', 'string'],
        list: [],
    },
    'table-command': {
        isList: false,
        args: [],
        functions: [],
        keywords: [],
        other: ['wc-field-list'],
        list: [],
    },
    'tagcreate-command': {
        isList: false,
        args: [],
        functions: [],
        keywords: [],
        other: ['tag', 'field-and-value-list'],
        list: [],
    },
    'tagdelete-command': {
        isList: false,
        args: [],
        functions: [],
        keywords: [],
        other: ['tag', 'field-and-value-list'],
        list: [],
    },
    'tags-command': {
        isList: false,
        args: [
            {
                key: 'outputfield',
                valueType: 'field',
            },
            {
                key: 'inclname',
                valueType: 'bool',
            },
            {
                key: 'inclvalue',
                valueType: 'bool',
            },
            {
                key: 'allowed_tags',
                valueType: 'string',
            },
        ],
        functions: [],
        keywords: [],
        other: ['field'],
        list: [],
    },
    'tagset-command': {
        isList: false,
        args: [],
        functions: [],
        keywords: [],
        other: ['field-and-value', 'tag-list'],
        list: [],
    },
    'tail-command': {
        isList: false,
        args: [],
        functions: [],
        keywords: [],
        other: ['int'],
        list: [],
    },
    'timechart-command': {
        isList: false,
        args: [
            {
                key: 'sep',
                valueType: 'string',
            },
            {
                key: 'format',
                valueType: 'string',
            },
            {
                key: 'fixedrange',
                valueType: 'bool',
            },
            {
                key: 'partial',
                valueType: 'bool',
            },
            {
                key: 'cont',
                valueType: 'bool',
            },
            {
                key: 'limit',
                valueType: 'chart-limit-opt',
            },
            {
                key: 'bins',
                valueType: 'int',
            },
            {
                key: 'minspan',
                valueType: '',
            },
            {
                key: 'span',
                valueType: '',
            },
            {
                key: 'start',
                valueType: 'num',
            },
            {
                key: 'end',
                valueType: 'num',
            },
            {
                key: 'aligntime',
                valueType: '',
            },
            {
                key: 'usenull',
                valueType: 'bool',
            },
            {
                key: 'useother',
                valueType: 'bool',
            },
            {
                key: 'nullstr',
                valueType: 'string',
            },
            {
                key: 'otherstr',
                valueType: 'string',
            },
            {
                key: 'dedup_splitvals',
                valueType: 'bool',
            },
        ],
        functions: [
            {
                name: 'c',
                parenOptional: true,
            },
            {
                name: 'count',
                parenOptional: true,
            },
            {
                name: 'dc',
                parenOptional: true,
            },
            {
                name: 'distinct_count',
                parenOptional: true,
            },
            {
                name: 'mean',
                parenOptional: true,
            },
            {
                name: 'avg',
                parenOptional: true,
            },
            {
                name: 'stdev',
                parenOptional: true,
            },
            {
                name: 'stdevp',
                parenOptional: true,
            },
            {
                name: 'var',
                parenOptional: true,
            },
            {
                name: 'varp',
                parenOptional: true,
            },
            {
                name: 'sum',
                parenOptional: true,
            },
            {
                name: 'sumsq',
                parenOptional: true,
            },
            {
                name: 'min',
                parenOptional: true,
            },
            {
                name: 'max',
                parenOptional: true,
            },
            {
                name: 'mode',
                parenOptional: true,
            },
            {
                name: 'median',
                parenOptional: true,
            },
            {
                name: 'earliest',
                parenOptional: true,
            },
            {
                name: 'first',
                parenOptional: true,
            },
            {
                name: 'last',
                parenOptional: true,
            },
            {
                name: 'latest',
                parenOptional: true,
            },
            {
                name: 'list',
                parenOptional: true,
            },
            {
                name: 'values',
                parenOptional: true,
            },
            {
                name: 'range',
                parenOptional: true,
            },
            {
                name: 'estdc',
                parenOptional: true,
            },
            {
                name: 'estdc_error',
                parenOptional: true,
            },
            {
                name: 'earliest_time',
                parenOptional: true,
            },
            {
                name: 'latest_time',
                parenOptional: true,
            },
            {
                name: 'rate',
                parenOptional: true,
            },
            {
                name: 'eval',
                parenOptional: false,
            },
            {
                name: 'abs',
                parenOptional: false,
            },
            {
                name: 'avg',
                parenOptional: false,
            },
            {
                name: 'case',
                parenOptional: false,
            },
            {
                name: 'ceiling',
                parenOptional: false,
            },
            {
                name: 'ceil',
                parenOptional: false,
            },
            {
                name: 'cidrmatch',
                parenOptional: false,
            },
            {
                name: 'coalesce',
                parenOptional: false,
            },
            {
                name: 'commands',
                parenOptional: false,
            },
            {
                name: 'exact',
                parenOptional: false,
            },
            {
                name: 'exp',
                parenOptional: false,
            },
            {
                name: 'false',
                parenOptional: false,
            },
            {
                name: 'floor',
                parenOptional: false,
            },
            {
                name: 'if',
                parenOptional: false,
            },
            {
                name: 'ifnull',
                parenOptional: false,
            },
            {
                name: 'isbool',
                parenOptional: false,
            },
            {
                name: 'isint',
                parenOptional: false,
            },
            {
                name: 'isnotnull',
                parenOptional: false,
            },
            {
                name: 'isnull',
                parenOptional: false,
            },
            {
                name: 'isnum',
                parenOptional: false,
            },
            {
                name: 'isstr',
                parenOptional: false,
            },
            {
                name: 'len',
                parenOptional: false,
            },
            {
                name: 'like',
                parenOptional: false,
            },
            {
                name: 'ln',
                parenOptional: false,
            },
            {
                name: 'log',
                parenOptional: false,
            },
            {
                name: 'lookup',
                parenOptional: false,
            },
            {
                name: 'lower',
                parenOptional: false,
            },
            {
                name: 'match',
                parenOptional: false,
            },
            {
                name: 'max',
                parenOptional: false,
            },
            {
                name: 'md5',
                parenOptional: false,
            },
            {
                name: 'min',
                parenOptional: false,
            },
            {
                name: 'mvappend',
                parenOptional: false,
            },
            {
                name: 'mvcount',
                parenOptional: false,
            },
            {
                name: 'mvdedup',
                parenOptional: false,
            },
            {
                name: 'mvindex',
                parenOptional: false,
            },
            {
                name: 'mvfilter',
                parenOptional: false,
            },
            {
                name: 'mvfind',
                parenOptional: false,
            },
            {
                name: 'mvjoin',
                parenOptional: false,
            },
            {
                name: 'mvmap',
                parenOptional: false,
            },
            {
                name: 'mvrange',
                parenOptional: false,
            },
            {
                name: 'mvsort',
                parenOptional: false,
            },
            {
                name: 'mvzip',
                parenOptional: false,
            },
            {
                name: 'now',
                parenOptional: false,
            },
            {
                name: 'null',
                parenOptional: false,
            },
            {
                name: 'nullif',
                parenOptional: false,
            },
            {
                name: 'pi',
                parenOptional: false,
            },
            {
                name: 'pow',
                parenOptional: false,
            },
            {
                name: 'random',
                parenOptional: false,
            },
            {
                name: 'relative_time',
                parenOptional: false,
            },
            {
                name: 'replace',
                parenOptional: false,
            },
            {
                name: 'round',
                parenOptional: false,
            },
            {
                name: 'searchmatch',
                parenOptional: false,
            },
            {
                name: 'sha1',
                parenOptional: false,
            },
            {
                name: 'sha256',
                parenOptional: false,
            },
            {
                name: 'sha512',
                parenOptional: false,
            },
            {
                name: 'sigfig',
                parenOptional: false,
            },
            {
                name: 'spath',
                parenOptional: false,
            },
            {
                name: 'split',
                parenOptional: false,
            },
            {
                name: 'sqrt',
                parenOptional: false,
            },
            {
                name: 'strftime',
                parenOptional: false,
            },
            {
                name: 'strptime',
                parenOptional: false,
            },
            {
                name: 'substr',
                parenOptional: false,
            },
            {
                name: 'sum',
                parenOptional: false,
            },
            {
                name: 'time',
                parenOptional: false,
            },
            {
                name: 'tostring',
                parenOptional: false,
            },
            {
                name: 'trim',
                parenOptional: false,
            },
            {
                name: 'ltrim',
                parenOptional: false,
            },
            {
                name: 'rtrim',
                parenOptional: false,
            },
            {
                name: 'true',
                parenOptional: false,
            },
            {
                name: 'typeof',
                parenOptional: false,
            },
            {
                name: 'upper',
                parenOptional: false,
            },
            {
                name: 'urldecode',
                parenOptional: false,
            },
            {
                name: 'validate',
                parenOptional: false,
            },
            {
                name: 'tonumber',
                parenOptional: false,
            },
            {
                name: 'acos',
                parenOptional: false,
            },
            {
                name: 'acosh',
                parenOptional: false,
            },
            {
                name: 'asin',
                parenOptional: false,
            },
            {
                name: 'asinh',
                parenOptional: false,
            },
            {
                name: 'atan',
                parenOptional: false,
            },
            {
                name: 'atan2',
                parenOptional: false,
            },
            {
                name: 'atanh',
                parenOptional: false,
            },
            {
                name: 'cos',
                parenOptional: false,
            },
            {
                name: 'cosh',
                parenOptional: false,
            },
            {
                name: 'hypot',
                parenOptional: false,
            },
            {
                name: 'sin',
                parenOptional: false,
            },
            {
                name: 'sinh',
                parenOptional: false,
            },
            {
                name: 'tan',
                parenOptional: false,
            },
            {
                name: 'tanh',
                parenOptional: false,
            },
            {
                name: 'json_array_to_mv',
                parenOptional: false,
            },
            {
                name: 'mv_to_json_array',
                parenOptional: false,
            },
            {
                name: 'json_append',
                parenOptional: false,
            },
            {
                name: 'json_extend',
                parenOptional: false,
            },
            {
                name: 'json_extract_exact',
                parenOptional: false,
            },
            {
                name: 'json_set_exact',
                parenOptional: false,
            },
            {
                name: 'c',
                parenOptional: false,
            },
            {
                name: 'count',
                parenOptional: false,
            },
            {
                name: 'dc',
                parenOptional: false,
            },
            {
                name: 'distinct_count',
                parenOptional: false,
            },
            {
                name: 'mean',
                parenOptional: false,
            },
            {
                name: 'avg',
                parenOptional: false,
            },
            {
                name: 'stdev',
                parenOptional: false,
            },
            {
                name: 'stdevp',
                parenOptional: false,
            },
            {
                name: 'var',
                parenOptional: false,
            },
            {
                name: 'varp',
                parenOptional: false,
            },
            {
                name: 'sum',
                parenOptional: false,
            },
            {
                name: 'sumsq',
                parenOptional: false,
            },
            {
                name: 'min',
                parenOptional: false,
            },
            {
                name: 'max',
                parenOptional: false,
            },
            {
                name: 'mode',
                parenOptional: false,
            },
            {
                name: 'median',
                parenOptional: false,
            },
            {
                name: 'earliest',
                parenOptional: false,
            },
            {
                name: 'first',
                parenOptional: false,
            },
            {
                name: 'last',
                parenOptional: false,
            },
            {
                name: 'latest',
                parenOptional: false,
            },
            {
                name: 'list',
                parenOptional: false,
            },
            {
                name: 'values',
                parenOptional: false,
            },
            {
                name: 'range',
                parenOptional: false,
            },
            {
                name: 'estdc',
                parenOptional: false,
            },
            {
                name: 'estdc_error',
                parenOptional: false,
            },
            {
                name: 'earliest_time',
                parenOptional: false,
            },
            {
                name: 'latest_time',
                parenOptional: false,
            },
            {
                name: 'rate',
                parenOptional: false,
            },
            {
                name: 'per_second',
                parenOptional: false,
            },
            {
                name: 'per_minute',
                parenOptional: false,
            },
            {
                name: 'per_hour',
                parenOptional: false,
            },
            {
                name: 'per_day',
                parenOptional: false,
            },
        ],
        keywords: ['LIKE', 'AND|OR|XOR', 'as', 'by', 'where'],
        other: ['field', 'num', 'string', 'wc-field', 'int', '|'],
        list: [],
    },
    'timewrap-command': {
        isList: false,
        args: [
            {
                key: 'align',
                valueType: '',
            },
            {
                key: 'series',
                valueType: '',
            },
            {
                key: 'time_format',
                valueType: 'str',
            },
        ],
        functions: [],
        keywords: [],
        other: ['int'],
        list: [],
    },
    'tojson-command': {
        isList: false,
        args: [
            {
                key: 'fill_null',
                valueType: 'bool',
            },
            {
                key: 'include_internal',
                valueType: 'bool',
            },
        ],
        functions: [
            {
                name: 'auto',
                parenOptional: true,
            },
            {
                name: 'num',
                parenOptional: true,
            },
            {
                name: 'str',
                parenOptional: true,
            },
            {
                name: 'bool',
                parenOptional: true,
            },
            {
                name: 'json',
                parenOptional: true,
            },
            {
                name: 'none',
                parenOptional: true,
            },
        ],
        keywords: [],
        other: ['wc-field', 'output_field', 'default_type'],
        list: [],
    },
    'common-command': {
        isList: false,
        args: [
            {
                key: 'showcount',
                valueType: 'bool',
            },
            {
                key: 'showperc',
                valueType: 'bool',
            },
            {
                key: 'limit',
                valueType: 'int',
            },
            {
                key: 'countfield',
                valueType: 'string',
            },
            {
                key: 'percentfield',
                valueType: 'string',
            },
            {
                key: 'useother',
                valueType: 'bool',
            },
            {
                key: 'otherstr',
                valueType: 'string',
            },
        ],
        functions: [],
        keywords: ['by'],
        other: ['int', 'field-list'],
        list: [],
    },
    'top-command': {
        isList: false,
        args: [
            {
                key: 'showcount',
                valueType: 'bool',
            },
            {
                key: 'showperc',
                valueType: 'bool',
            },
            {
                key: 'limit',
                valueType: 'int',
            },
            {
                key: 'countfield',
                valueType: 'string',
            },
            {
                key: 'percentfield',
                valueType: 'string',
            },
            {
                key: 'useother',
                valueType: 'bool',
            },
            {
                key: 'otherstr',
                valueType: 'string',
            },
        ],
        functions: [],
        keywords: ['by'],
        other: ['int', 'field-list'],
        list: [],
    },
    'transam-command': {
        isList: false,
        args: [
            {
                key: 'name',
                valueType: 'transaction-name',
            },
            {
                key: 'maxspan',
                valueType: '',
            },
            {
                key: 'maxpause',
                valueType: '',
            },
            {
                key: 'maxevents',
                valueType: 'int',
            },
            {
                key: 'startswith',
                valueType: 'transam-filter-string',
            },
            {
                key: 'endswith',
                valueType: 'transam-filter-string',
            },
            {
                key: 'connected',
                valueType: 'bool',
            },
            {
                key: 'unifyends',
                valueType: 'bool',
            },
            {
                key: 'keeporphans',
                valueType: 'bool',
            },
            {
                key: 'maxopentxn',
                valueType: 'int',
            },
            {
                key: 'maxopenevents',
                valueType: 'int',
            },
            {
                key: 'keepevicted',
                valueType: 'bool',
            },
            {
                key: 'delim',
                valueType: 'string',
            },
            {
                key: 'mvlist',
                valueType: '',
            },
            {
                key: 'nullstr',
                valueType: 'string',
            },
            {
                key: 'mvraw',
                valueType: 'bool',
            },
        ],
        functions: [],
        keywords: [],
        other: ['field-list'],
        list: [],
    },
    'transaction-command': {
        isList: false,
        args: [
            {
                key: 'name',
                valueType: 'transaction-name',
            },
            {
                key: 'maxspan',
                valueType: '',
            },
            {
                key: 'maxpause',
                valueType: '',
            },
            {
                key: 'maxevents',
                valueType: 'int',
            },
            {
                key: 'startswith',
                valueType: 'transam-filter-string',
            },
            {
                key: 'endswith',
                valueType: 'transam-filter-string',
            },
            {
                key: 'connected',
                valueType: 'bool',
            },
            {
                key: 'unifyends',
                valueType: 'bool',
            },
            {
                key: 'keeporphans',
                valueType: 'bool',
            },
            {
                key: 'maxopentxn',
                valueType: 'int',
            },
            {
                key: 'maxopenevents',
                valueType: 'int',
            },
            {
                key: 'keepevicted',
                valueType: 'bool',
            },
            {
                key: 'delim',
                valueType: 'string',
            },
            {
                key: 'mvlist',
                valueType: '',
            },
            {
                key: 'nullstr',
                valueType: 'string',
            },
            {
                key: 'mvraw',
                valueType: 'bool',
            },
        ],
        functions: [],
        keywords: [],
        other: ['field-list'],
        list: [],
    },
    'transpose-command': {
        isList: false,
        args: [
            {
                key: 'column_name',
                valueType: 'string',
            },
            {
                key: 'header_field',
                valueType: 'field',
            },
            {
                key: 'include_empty',
                valueType: 'bool',
            },
        ],
        functions: [],
        keywords: [],
        other: ['int'],
        list: [],
    },
    'trendline-command': {
        isList: false,
        args: [],
        functions: [
            {
                name: 'sma\\d+',
                parenOptional: false,
            },
            {
                name: 'ema\\d+',
                parenOptional: false,
            },
            {
                name: 'wma\\d+',
                parenOptional: false,
            },
        ],
        keywords: ['as'],
        other: ['field', 'field'],
        list: [],
    },
    'tscollect-command': {
        isList: false,
        args: [
            {
                key: 'namespace',
                valueType: 'string',
            },
            {
                key: 'squashcase',
                valueType: 'bool',
            },
            {
                key: 'keepresults',
                valueType: 'bool',
            },
        ],
        functions: [],
        keywords: [],
        other: [],
        list: [],
    },
    'tstats-command': {
        isList: false,
        args: [
            {
                key: 'prestats',
                valueType: 'bool',
            },
            {
                key: 'local',
                valueType: 'bool',
            },
            {
                key: 'append',
                valueType: 'bool',
            },
            {
                key: 'summariesonly',
                valueType: 'bool',
            },
            {
                key: 'include_reduced_buckets',
                valueType: 'bool',
            },
            {
                key: 'allow_old_summaries',
                valueType: 'bool',
            },
            {
                key: 'chunk_size',
                valueType: 'int',
            },
            {
                key: 'fillnull_value',
                valueType: 'string',
            },
            {
                key: 'sid',
                valueType: 'string:tscollect-job-id',
            },
            {
                key: 'datamodel',
                valueType: '',
            },
            {
                key: 'timeformat',
                valueType: 'string',
            },
            {
                key: 'earliest',
                valueType: 'time_modifier',
            },
            {
                key: '_index_earliest',
                valueType: 'time_modifier',
            },
            {
                key: 'starttime',
                valueType: 'string',
            },
            {
                key: 'startdaysago',
                valueType: 'int',
            },
            {
                key: 'startminutesago',
                valueType: 'int',
            },
            {
                key: 'starthoursago',
                valueType: 'int',
            },
            {
                key: 'startmonthsago',
                valueType: 'int',
            },
            {
                key: 'starttimeu',
                valueType: 'num',
            },
            {
                key: 'latest',
                valueType: 'time_modifier',
            },
            {
                key: '_index_latest',
                valueType: 'time_modifier',
            },
            {
                key: 'endtime',
                valueType: 'string',
            },
            {
                key: 'enddaysago',
                valueType: 'int',
            },
            {
                key: 'endminutesago',
                valueType: 'int',
            },
            {
                key: 'endhoursago',
                valueType: 'int',
            },
            {
                key: 'endmonthsago',
                valueType: 'int',
            },
            {
                key: 'endtimeu',
                valueType: 'num',
            },
            {
                key: 'searchtimespanhours',
                valueType: 'int',
            },
            {
                key: 'searchtimespanminutes',
                valueType: 'int',
            },
            {
                key: 'searchtimespandays',
                valueType: 'int',
            },
            {
                key: 'searchtimespanmonths',
                valueType: 'int',
            },
            {
                key: 'daysago',
                valueType: 'int',
            },
            {
                key: 'minutesago',
                valueType: 'int',
            },
            {
                key: 'hoursago',
                valueType: 'int',
            },
            {
                key: 'monthsago',
                valueType: 'int',
            },
            {
                key: 'savedsearch',
                valueType: 'string',
            },
            {
                key: 'savedsplunk',
                valueType: 'string',
            },
            {
                key: 'span',
                valueType: 'string:timespan',
            },
        ],
        functions: [
            {
                name: 'c',
                parenOptional: false,
            },
            {
                name: 'count',
                parenOptional: false,
            },
            {
                name: 'dc',
                parenOptional: false,
            },
            {
                name: 'distinct_count',
                parenOptional: false,
            },
            {
                name: 'mean',
                parenOptional: false,
            },
            {
                name: 'avg',
                parenOptional: false,
            },
            {
                name: 'stdev',
                parenOptional: false,
            },
            {
                name: 'stdevp',
                parenOptional: false,
            },
            {
                name: 'var',
                parenOptional: false,
            },
            {
                name: 'varp',
                parenOptional: false,
            },
            {
                name: 'sum',
                parenOptional: false,
            },
            {
                name: 'sumsq',
                parenOptional: false,
            },
            {
                name: 'min',
                parenOptional: false,
            },
            {
                name: 'max',
                parenOptional: false,
            },
            {
                name: 'mode',
                parenOptional: false,
            },
            {
                name: 'median',
                parenOptional: false,
            },
            {
                name: 'earliest',
                parenOptional: false,
            },
            {
                name: 'first',
                parenOptional: false,
            },
            {
                name: 'last',
                parenOptional: false,
            },
            {
                name: 'latest',
                parenOptional: false,
            },
            {
                name: 'list',
                parenOptional: false,
            },
            {
                name: 'values',
                parenOptional: false,
            },
            {
                name: 'range',
                parenOptional: false,
            },
            {
                name: 'estdc',
                parenOptional: false,
            },
            {
                name: 'estdc_error',
                parenOptional: false,
            },
            {
                name: 'earliest_time',
                parenOptional: false,
            },
            {
                name: 'latest_time',
                parenOptional: false,
            },
            {
                name: 'rate',
                parenOptional: false,
            },
        ],
        keywords: ['as', 'FROM', 'WHERE', 'IN', 'by|GROUPBY'],
        other: [
            'field',
            'string',
            'string:namespace',
            'boolean-operator-not',
            'term',
            'num',
            'boolean-operator-or',
            'boolean-operator-and',
            'field',
            'field',
        ],
        list: [],
    },
    'typeahead-command': {
        isList: false,
        args: [
            {
                key: 'prefix',
                valueType: 'string',
            },
            {
                key: 'count',
                valueType: 'int',
            },
            {
                key: 'max_time',
                valueType: 'int',
            },
            {
                key: 'index',
                valueType: 'string',
            },
            {
                key: 'starttimeu',
                valueType: 'num',
            },
            {
                key: 'endtimeu',
                valueType: 'num',
            },
            {
                key: 'collapse',
                valueType: 'bool',
            },
        ],
        functions: [],
        keywords: [],
        other: [],
        list: [],
    },
    'typelearner-command': {
        isList: false,
        args: [
            {
                key: 'maxlen',
                valueType: 'int',
            },
        ],
        functions: [],
        keywords: [],
        other: ['field'],
        list: [],
    },
    'typer-command': {
        isList: false,
        args: [
            {
                key: 'eventtypes',
                valueType: 'string',
            },
            {
                key: 'maxlen',
                valueType: 'int',
            },
        ],
        functions: [],
        keywords: [],
        other: [],
        list: [],
    },
    'union-command': {
        isList: false,
        args: [
            {
                key: 'extendtimerange',
                valueType: 'bool',
            },
            {
                key: 'maxtime',
                valueType: 'int',
            },
            {
                key: 'maxout',
                valueType: 'int',
            },
            {
                key: 'timeout',
                valueType: 'int',
            },
        ],
        functions: [],
        keywords: [],
        other: [],
        list: [],
    },
    'uniq-command': {
        isList: false,
        args: [],
        functions: [],
        keywords: [],
        other: [],
        list: [],
    },
    'untable-command': {
        isList: false,
        args: [],
        functions: [],
        keywords: [],
        other: ['field'],
        list: [],
    },
    'walklex-command': {
        isList: false,
        args: [
            {
                key: 'type',
                valueType: 'walklex-type',
            },
            {
                key: 'prefix',
                valueType: '',
            },
            {
                key: 'index',
                valueType: 'string',
            },
            {
                key: 'splunk_server',
                valueType: 'wc-string',
            },
            {
                key: 'splunk_server_group',
                valueType: 'wc-string',
            },
        ],
        functions: [],
        keywords: [],
        other: [],
        list: [],
    },
    'where-command': {
        isList: false,
        args: [],
        functions: [
            {
                name: 'abs',
                parenOptional: false,
            },
            {
                name: 'avg',
                parenOptional: false,
            },
            {
                name: 'case',
                parenOptional: false,
            },
            {
                name: 'ceiling',
                parenOptional: false,
            },
            {
                name: 'ceil',
                parenOptional: false,
            },
            {
                name: 'cidrmatch',
                parenOptional: false,
            },
            {
                name: 'coalesce',
                parenOptional: false,
            },
            {
                name: 'commands',
                parenOptional: false,
            },
            {
                name: 'exact',
                parenOptional: false,
            },
            {
                name: 'exp',
                parenOptional: false,
            },
            {
                name: 'false',
                parenOptional: false,
            },
            {
                name: 'floor',
                parenOptional: false,
            },
            {
                name: 'if',
                parenOptional: false,
            },
            {
                name: 'ifnull',
                parenOptional: false,
            },
            {
                name: 'isbool',
                parenOptional: false,
            },
            {
                name: 'isint',
                parenOptional: false,
            },
            {
                name: 'isnotnull',
                parenOptional: false,
            },
            {
                name: 'isnull',
                parenOptional: false,
            },
            {
                name: 'isnum',
                parenOptional: false,
            },
            {
                name: 'isstr',
                parenOptional: false,
            },
            {
                name: 'len',
                parenOptional: false,
            },
            {
                name: 'like',
                parenOptional: false,
            },
            {
                name: 'ln',
                parenOptional: false,
            },
            {
                name: 'log',
                parenOptional: false,
            },
            {
                name: 'lookup',
                parenOptional: false,
            },
            {
                name: 'lower',
                parenOptional: false,
            },
            {
                name: 'match',
                parenOptional: false,
            },
            {
                name: 'max',
                parenOptional: false,
            },
            {
                name: 'md5',
                parenOptional: false,
            },
            {
                name: 'min',
                parenOptional: false,
            },
            {
                name: 'mvappend',
                parenOptional: false,
            },
            {
                name: 'mvcount',
                parenOptional: false,
            },
            {
                name: 'mvdedup',
                parenOptional: false,
            },
            {
                name: 'mvindex',
                parenOptional: false,
            },
            {
                name: 'mvfilter',
                parenOptional: false,
            },
            {
                name: 'mvfind',
                parenOptional: false,
            },
            {
                name: 'mvjoin',
                parenOptional: false,
            },
            {
                name: 'mvmap',
                parenOptional: false,
            },
            {
                name: 'mvrange',
                parenOptional: false,
            },
            {
                name: 'mvsort',
                parenOptional: false,
            },
            {
                name: 'mvzip',
                parenOptional: false,
            },
            {
                name: 'now',
                parenOptional: false,
            },
            {
                name: 'null',
                parenOptional: false,
            },
            {
                name: 'nullif',
                parenOptional: false,
            },
            {
                name: 'pi',
                parenOptional: false,
            },
            {
                name: 'pow',
                parenOptional: false,
            },
            {
                name: 'random',
                parenOptional: false,
            },
            {
                name: 'relative_time',
                parenOptional: false,
            },
            {
                name: 'replace',
                parenOptional: false,
            },
            {
                name: 'round',
                parenOptional: false,
            },
            {
                name: 'searchmatch',
                parenOptional: false,
            },
            {
                name: 'sha1',
                parenOptional: false,
            },
            {
                name: 'sha256',
                parenOptional: false,
            },
            {
                name: 'sha512',
                parenOptional: false,
            },
            {
                name: 'sigfig',
                parenOptional: false,
            },
            {
                name: 'spath',
                parenOptional: false,
            },
            {
                name: 'split',
                parenOptional: false,
            },
            {
                name: 'sqrt',
                parenOptional: false,
            },
            {
                name: 'strftime',
                parenOptional: false,
            },
            {
                name: 'strptime',
                parenOptional: false,
            },
            {
                name: 'substr',
                parenOptional: false,
            },
            {
                name: 'sum',
                parenOptional: false,
            },
            {
                name: 'time',
                parenOptional: false,
            },
            {
                name: 'tostring',
                parenOptional: false,
            },
            {
                name: 'trim',
                parenOptional: false,
            },
            {
                name: 'ltrim',
                parenOptional: false,
            },
            {
                name: 'rtrim',
                parenOptional: false,
            },
            {
                name: 'true',
                parenOptional: false,
            },
            {
                name: 'typeof',
                parenOptional: false,
            },
            {
                name: 'upper',
                parenOptional: false,
            },
            {
                name: 'urldecode',
                parenOptional: false,
            },
            {
                name: 'validate',
                parenOptional: false,
            },
            {
                name: 'tonumber',
                parenOptional: false,
            },
            {
                name: 'acos',
                parenOptional: false,
            },
            {
                name: 'acosh',
                parenOptional: false,
            },
            {
                name: 'asin',
                parenOptional: false,
            },
            {
                name: 'asinh',
                parenOptional: false,
            },
            {
                name: 'atan',
                parenOptional: false,
            },
            {
                name: 'atan2',
                parenOptional: false,
            },
            {
                name: 'atanh',
                parenOptional: false,
            },
            {
                name: 'cos',
                parenOptional: false,
            },
            {
                name: 'cosh',
                parenOptional: false,
            },
            {
                name: 'hypot',
                parenOptional: false,
            },
            {
                name: 'sin',
                parenOptional: false,
            },
            {
                name: 'sinh',
                parenOptional: false,
            },
            {
                name: 'tan',
                parenOptional: false,
            },
            {
                name: 'tanh',
                parenOptional: false,
            },
            {
                name: 'json_array_to_mv',
                parenOptional: false,
            },
            {
                name: 'mv_to_json_array',
                parenOptional: false,
            },
            {
                name: 'json_append',
                parenOptional: false,
            },
            {
                name: 'json_extend',
                parenOptional: false,
            },
            {
                name: 'json_extract_exact',
                parenOptional: false,
            },
            {
                name: 'json_set_exact',
                parenOptional: false,
            },
        ],
        keywords: ['LIKE', 'AND|OR|XOR'],
        other: ['field', 'num', 'string'],
        list: [],
    },
    'x11-command': {
        isList: false,
        args: [],
        functions: [
            {
                name: 'mult',
                parenOptional: false,
            },
            {
                name: 'add',
                parenOptional: false,
            },
        ],
        keywords: ['as'],
        other: ['field', 'field'],
        list: [],
    },
    'xmlkv-command': {
        isList: false,
        args: [
            {
                key: 'maxinputs',
                valueType: 'int',
            },
        ],
        functions: [],
        keywords: [],
        other: [],
        list: [],
    },
    'xmlunescape-command': {
        isList: false,
        args: [
            {
                key: 'maxinputs',
                valueType: 'int',
            },
        ],
        functions: [],
        keywords: [],
        other: [],
        list: [],
    },
    'xpath-command': {
        isList: false,
        args: [
            {
                key: 'field',
                valueType: 'field',
            },
            {
                key: 'outfield',
                valueType: 'field',
            },
            {
                key: 'default',
                valueType: 'string',
            },
        ],
        functions: [],
        keywords: [],
        other: ['string:xpath'],
        list: [],
    },
    'maketable-command': {
        isList: false,
        args: [
            {
                key: 'grouped',
                valueType: 'bool',
            },
            {
                key: 'sep',
                valueType: 'string',
            },
            {
                key: 'format',
                valueType: 'string',
            },
        ],
        functions: [],
        keywords: [],
        other: ['field'],
        list: [],
    },
    'xyseries-command': {
        isList: false,
        args: [
            {
                key: 'grouped',
                valueType: 'bool',
            },
            {
                key: 'sep',
                valueType: 'string',
            },
            {
                key: 'format',
                valueType: 'string',
            },
        ],
        functions: [],
        keywords: [],
        other: ['field'],
        list: [],
    },
};
