import { _ } from '@splunk/ui-utils/i18n';

/**
 * Function to check if returned error message is a risky command error message
 * @param {String} message
 * @returns {Boolean}
 */
export const isRiskyCommandError = (message: string): boolean => {
    // TODO: fix this after SUI-2487 is resolved
    // For error cases, the search job currently only returns message.text instead of the whole message, use message.text to identify the risky command cases is not ideal, this should be fixed after SUI-2487 is resolved.

    // Note: the backend only returns the message.text in english
    return !!message && message.startsWith('Found potentially risky commands:');
};

/**
 * Risky commands error message returns in the following format: Found potentially risky commands: cmd1 cmd2 cmd3
 * Function to parse the error message and return the unique risky commands in array
 * @param {String} message
 * @returns {Array} commands
 */
export const getRiskyCommands = (message: string): string[] => {
    if (!isRiskyCommandError(message)) {
        return [];
    }

    return message
        ? Array.from(new Set(message.split(':')[1].trim().split(' ')))
        : [];
};
/**
 * Formats the risky command error message to be more readable
 * @param {String} message
 * @returns {String} formattedErrorMessage
 */
export const formatRiskyCommandErrorMessage = (message: string): string => {
    return (
        _('Found potentially risky command(s): ') +
        getRiskyCommands(message).join(' ')
    );
};
