import { COLOR_OR_TOKEN_PATTERN, getPattern } from '@splunk/visualizations-shared/schemaUtils';

export default {
    unit: { description: 'Specify text to show next to the major value.', type: 'string' },
    unitPosition: {
        default: 'after',
        description: 'Specify whether the unit text should appear before or after the major value.',
        pattern: getPattern(['before', 'after']),
        type: 'string',
    },
    underLabel: { description: 'Specify the text that appears below the major value.', type: 'string' },
    backgroundColor: {
        description:
            'Specify the color to use for the background. The hex value format should be #FFFFFF. The default for light mode is "#FFFFFF". The default for dark mode is "#171d21". The default for prisma dark is "#0b0c0e".',
        pattern: COLOR_OR_TOKEN_PATTERN,
        type: 'string',
    },
    showTrendIndicator: {
        default: true,
        description: 'Specify whether to show or hide the trend indicator.',
        type: 'boolean',
    },
    useTrendUnits: {
        default: false,
        description:
            'Specify whether to truncate the trend value to 2 significant digits. A magnitude unit will be displayed.',
        type: 'boolean',
    },
    showSparkline: {
        default: true,
        description: 'Specify whether to show or hide the single value sparkline.',
        type: 'boolean',
    },
    sparklinePosition: {
        default: 'below',
        description: 'Specify the location to display a sparkline.',
        pattern: getPattern(['before', 'after', 'below']),
        type: 'string',
    },
    sparklineFillColor: {
        default: '#6F7A87',
        description:
            'Specify a color to use as the fill for the sparkline graph. The hex value format should be #FFFFFF.',
        pattern: COLOR_OR_TOKEN_PATTERN,
        type: 'string',
    },
    sparklineStrokeColor: {
        default: '#2F353C',
        description:
            'Specify a color to use for the sparkline stroke. The hex value format should be #FFFFFF.',
        pattern: COLOR_OR_TOKEN_PATTERN,
        type: 'string',
    },
    showSparklineTooltip: {
        default: false,
        description: 'Show a tooltip to display values on the sparkline.',
        type: 'boolean',
    },
    sparklineAreaGraph: {
        default: false,
        description: 'Specify whether to show the sparkline as an area graph rather than a line.',
        type: 'boolean',
    },
    sparklineAcceptNullData: {
        default: true,
        description: 'Specify whether to convert null and non-numeric values in the sparkline to 0.',
        type: 'boolean',
    },
    sparklineHighlightSegments: {
        default: 0,
        description: 'Specify the number of segments to be highlighted at the top of a sparkline area graph.',
        type: 'number',
    },
    sparklineHighlightDots: {
        default: 0,
        description:
            'Specify the number of markers, or dots, to display at the top of a sparkline area graph.',
        type: 'number',
    },
    trendDisplayMode: {
        default: 'absolute',
        description: 'Specify whether the trend value is displayed as a percentage or an absolute count.',
        pattern: getPattern(['percent', 'absolute']),
        type: 'string',
    },
    numberPrecision: {
        default: 0,
        maximum: 20,
        minimum: 0,
        description:
            'Specify the number of decimal places to display. For example, to display 3 decimal places, use a value of 3. The maximum value is 20.',
        type: 'number',
    },
    useThousandSeparators: {
        default: true,
        description: 'Specify whether numeric values use commas as thousandths separaters.',
        type: 'boolean',
    },
    majorFontSize: {
        description:
            'Specify the font size (px) for the major value. By default the major value font size is calculated dynamically based on the available space.',
        type: 'number',
    },
    deltaFontSize: {
        description:
            'Specify the font size (px) for the delta value. By default the delta value font size is calculated dynamically based on the available space.',
        type: 'number',
    },
};
