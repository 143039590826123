import React, {
    useMemo,
    useState,
    useCallback,
    useEffect,
    useRef,
} from 'react';
import styled from 'styled-components';
import { useFeatureFlags } from '@splunk/dashboard-context';
import type { InputStatics } from '@splunk/dashboard-types';
import { applyTranslucence } from '@splunk/dashboard-ui';
import { noop } from '@splunk/dashboard-utils';
import Remove from '@splunk/react-icons/enterprise/Remove';
import { pick, pickVariant, variables } from '@splunk/themes';
import type { InputProps, HOCProps } from '../types';
import { InputStatusIcon } from '../components/InputStatusIcon';

// todo: change them back to the original values above, after the react-sortable-hoc supporting variable width in grid https://github.com/clauderic/react-sortable-hoc#grid-support
export const SHORT_WIDTH_DRAGGABLE = 198;
export const LONG_WIDTH_DRAGGABLE = 198;

interface InputContainerProps {
    showSelection: boolean;
    mode: 'edit' | 'view';
    width: number;
    isOnCanvas: boolean;
    canBeHidden?: boolean;
    showBorder: boolean;
}

const selectedInputBackgroundColor = {
    enterprise: {
        light: variables.gray92,
        dark: variables.gray30,
    },
    prisma: variables.backgroundColorPopup,
};

const unselectedHoverColorTree = {
    enterprise: {
        dark: variables.borderLightColor,
        light: variables.borderColor,
    },
    prisma: variables.interactiveColorBorderHover,
};

// Use the colorWithAlpha mixin to emulate the opacity: 0.7 in
// SelectableContainer but without causing text/child content opacity to be set
const unselectedTranslucentColorTree = {
    enterprise: {
        dark: applyTranslucence(variables.borderLightColor, 0.7),
        light: applyTranslucence(variables.borderColor, 0.7),
    },
    prisma: applyTranslucence(variables.interactiveColorBorder, 0.7),
};

export const InputContainer = styled.div.attrs<InputContainerProps>(
    ({ width }) => ({
        'data-test': 'input-container',
        style: {
            width,
        },
    })
)<InputContainerProps>`
    padding: 8px;
    border-radius: 4px;
    box-sizing: border-box;
    border: 1px solid;
    position: relative;
    background-color: ${pickVariant<InputContainerProps>('showSelection', {
        true: selectedInputBackgroundColor,
        false: 'transparent',
    })};
    border-style: ${pickVariant<InputContainerProps>('canBeHidden', {
        true: 'dashed',
        false: 'solid',
    })};
    border-color: ${pickVariant<InputContainerProps>('showBorder', {
        true: pickVariant<InputContainerProps>('showSelection', {
            // selected:
            true: {
                enterprise: variables.focusColor,
                prisma: variables.interactiveColorPrimary,
            },
            // can be hidden but not selected:
            false: unselectedTranslucentColorTree,
        }),
        false: 'transparent',
    })};
    &:hover {
        background-color: ${({ isOnCanvas, mode }) =>
            isOnCanvas || mode === 'view'
                ? 'transparent'
                : pick(selectedInputBackgroundColor)};
        border-color: ${pickVariant<InputContainerProps>('showBorder', {
            true: pickVariant<InputContainerProps>('showSelection', {
                false: unselectedHoverColorTree,
            }),
            false: 'transparent',
        })};
    }
`;

const InputTitleRemoveContainer = styled.div.attrs({
    'data-test': 'input-title-remove-container',
})`
    display: flex;
    margin-bottom: 4px;
    align-items: center;
`;

const InputTitle = styled.div.attrs({
    'data-test': 'input-title',
})`
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    font-size: 13px;
    cursor: default;
    color: ${variables.contentColorActive};
    min-height: ${(props) => (props.title ? null : variables.lineHeight)};
    flex-grow: 0;
`;

interface InputRemoveProps {
    isSelected: boolean;
}

const InputRemove = styled.div.attrs({
    'data-test': 'input-remove',
})<InputRemoveProps>`
    height: 16px;
    margin-left: 8px;
    visibility: ${(props) => (props.isSelected ? 'visible' : 'hidden')};
    color: ${variables.contentColorMuted};
    &:hover {
        cursor: pointer;
    }
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
    flex-grow: 0;
`;

const InputDragHandle = styled.div.attrs({ 'data-test': 'input-drag-handle' })`
    display: none;
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    width: 30px;
    height: 15px;
    /* background gradient dots */
    background: ${variables.draggableBackground};
    /* blurred background */
    background-color: ${pick({
        enterprise: {
            light: 'rgba(184, 184, 184, 0.3)',
            dark: 'rgba(35, 36, 43, 0.2)',
        },
        prisma: {
            light: 'rgba(184, 184, 184, 0.3)',
            dark: 'rgba(35, 36, 43, 0.8)',
        },
    })};
    cursor: move;

    ${InputContainer}:hover & {
        display: block;
    }
`;

const Spacer = styled.div`
    display: flex;
    flex-grow: 1;
`;

const empty = {};
const noopValidator = (): { errorMessages: string[] } => ({
    errorMessages: [],
});

type HOC = ((props: HOCProps) => React.ReactElement<HOCProps>) & InputStatics;

/**
 * HOC that wraps the Input component in a container displaying input title, error icon if error is present
 * and remove button if selected in edit mode. Also sets container style (backgroundColor, border) based on mode and isSelected
 * @param {ReactElement} InputComponent Input Component
 * @param {String} mode Dashboard mode - edit or view
 * @param {String} title Input title
 * @param {String} id Input id
 * @param {Object} options Input options
 * @param {Boolean} isSelected Input is selected in edit mode or not
 * @param {Number} width Input Container width - TRP would override this
 */
export const withInputWrapper = (
    InputComponent: React.ComponentType<InputProps> & InputStatics
): HOC => {
    const WrappedComponent = ({
        mode = 'view',
        title,
        id,
        attributes,
        listeners,
        options = empty,
        isSelected = false,
        width = SHORT_WIDTH_DRAGGABLE,
        onRemove = noop,
        dataSources = empty,
        isOnCanvas = false,
        canBeHidden = false,
        ...rest
    }: HOCProps): React.ReactElement => {
        const [currentWidth, setWidth] = useState(width);
        const { enableDragDropInputs } = useFeatureFlags();
        const validator = InputComponent.validate ?? noopValidator;
        const isFirstRenderRef = useRef(true);

        useEffect(() => {
            // The reason we need to avoid setting it on first render is that it
            //   overwrites the call to `onUpdateWidth` from components such as TRP
            if (!isFirstRenderRef.current) {
                setWidth(width);
            }
            isFirstRenderRef.current = false;
        }, [width]);

        const { errorMessages: validationErrors } = useMemo(
            () => validator({ options }),
            [validator, options]
        );

        const titleMemo = useMemo(() => {
            const displayTitle = title || '';
            return <InputTitle title={displayTitle}>{displayTitle}</InputTitle>;
        }, [title]);

        const handleRemove = useCallback(
            (e) => {
                onRemove(id);
                e.stopPropagation();
            },
            [onRemove, id]
        );

        const removeMemo = useMemo(() => {
            if (mode !== 'edit' || isOnCanvas) {
                return null;
            }
            return (
                <InputRemove onClick={handleRemove} isSelected={isSelected}>
                    <Remove size="11px" />
                </InputRemove>
            );
        }, [mode, isOnCanvas, handleRemove, isSelected]);

        const isDraggable =
            enableDragDropInputs &&
            mode === 'edit' &&
            isSelected &&
            !isOnCanvas;

        const dragHandleEl = useMemo<React.ReactNode>(
            () =>
                isDraggable && (
                    <InputDragHandle {...attributes} {...listeners} />
                ),
            [attributes, isDraggable, listeners]
        );

        const isGlobalEditModeInput = mode === 'edit' && !isOnCanvas;
        const showSelection = isSelected && isGlobalEditModeInput;
        const showBorder = isGlobalEditModeInput && (isSelected || canBeHidden);

        return (
            <InputContainer
                mode={mode}
                width={currentWidth}
                showSelection={showSelection}
                showBorder={showBorder}
                isOnCanvas={isOnCanvas}
                canBeHidden={canBeHidden}
            >
                {dragHandleEl}
                <InputTitleRemoveContainer>
                    {titleMemo}
                    <InputStatusIcon
                        dataSource={dataSources?.primary}
                        componentId={id}
                        validationMessage={validationErrors.join(', ')}
                    />
                    <Spacer />
                    {removeMemo}
                </InputTitleRemoveContainer>
                <InputComponent
                    id={id}
                    isSelected={isSelected}
                    options={options}
                    onUpdateWidth={isOnCanvas ? noop : setWidth}
                    dataSources={dataSources}
                    {...rest}
                />
            </InputContainer>
        );
    };
    WrappedComponent.valueToTokens = InputComponent.valueToTokens;
    WrappedComponent.tokensToValue = InputComponent.tokensToValue;
    WrappedComponent.validate = InputComponent.validate;
    WrappedComponent.config = InputComponent.config;
    return WrappedComponent;
};
