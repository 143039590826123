import * as React from 'react';
import { _ } from '@splunk/ui-utils/i18n';
import SVG from '@splunk/react-icons/SVGEnterprise';

const Bubble = (props: React.ComponentProps<typeof SVG>): React.ReactElement<SVGElement> => (
    <SVG screenReaderText={_('Bubble')} viewBox="0 0 16 16" {...props}>
        <path
            d="M2.58333 4.66667C1.43274 4.66667 0.5 3.73393 0.5 2.58333C0.5 1.43274 1.43274 0.5 2.58333 0.5C3.73393 0.5 4.66667 1.43274 4.66667 2.58333C4.66667 3.73393 3.73393 4.66667 2.58333 4.66667Z"
            fill="currentColor"
        />
        <path
            d="M11.3333 3.83333C11.3333 4.98393 12.2661 5.91667 13.4167 5.91667C14.5673 5.91667 15.5 4.98393 15.5 3.83333C15.5 2.68274 14.5673 1.75 13.4167 1.75C12.2661 1.75 11.3333 2.68274 11.3333 3.83333Z"
            fill="currentColor"
        />
        <path
            d="M3.83333 7.16667C3.83333 9.00762 5.32572 10.5 7.16667 10.5C9.00762 10.5 10.5 9.00762 10.5 7.16667C10.5 5.32572 9.00762 3.83333 7.16667 3.83333C5.32572 3.83333 3.83333 5.32572 3.83333 7.16667Z"
            fill="currentColor"
        />
        <path
            d="M3 15.5C1.61929 15.5 0.5 14.3807 0.5 13C0.5 11.6193 1.61929 10.5 3 10.5C4.38071 10.5 5.5 11.6193 5.5 13C5.5 14.3807 4.38071 15.5 3 15.5Z"
            fill="currentColor"
        />
        <path
            d="M9.66667 11.3333C9.66667 12.714 10.786 13.8333 12.1667 13.8333C13.5474 13.8333 14.6667 12.714 14.6667 11.3333C14.6667 9.95262 13.5474 8.83333 12.1667 8.83333C10.786 8.83333 9.66667 9.95262 9.66667 11.3333Z"
            fill="currentColor"
        />
    </SVG>
);

export default Bubble;
