import React from 'react';
import SVG, {
    type SVGPropsOptionalViewBox,
} from '@splunk/react-icons/SVGEnterprise';

/* eslint-disable max-len */
export default function ActiveDirectory(
    props: SVGPropsOptionalViewBox
): ReturnType<typeof SVG> {
    return (
        <SVG viewBox="0 0 61 61" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path d="m32.5257 7.75406c-.5907.3587-1.2841.56524-2.0257.56524-.7386 0-1.4293-.20487-2.0185-.56088l-.0011.00149c-.783-.4743-1.3864-1.21535-1.6841-2.09713v-.00003c-.1328-.39303-.2048-.81408-.2048-1.25194 0-2.1586 1.7499-3.908491 3.9085-3.908491s3.9085 1.749891 3.9085 3.908491c0 .43431-.0708.85207-.2016 1.24237l.0073.00959c-.2977.88179-.9011 1.62284-1.6841 2.09713zm-24.23201 31.00484c.01539-.1409.02329-.2841.02329-.4291 0-1.0173-.38862-1.9438-1.02548-2.6391l.0059-.0078c-.60008-.6536-1.41949-1.1026-2.34158-1.2304l-.00466.0061c-.17736-.0246-.35853-.0373-.54267-.0373-2.1586 0-3.90849 1.7499-3.90849 3.9085s1.74989 3.9085 3.90849 3.9085c1.0483 0 2.00022-.4127 2.70212-1.0845l.00654.0052c.64665-.6179 1.08186-1.4552 1.18612-2.3925zm52.20631-.4291c0 2.1586-1.7499 3.9085-3.9085 3.9085-1.0459 0-1.9959-.4109-2.6973-1.08l-.0009.0007c-.4786-.4574-.8415-1.035-1.0399-1.6844-.1108-.3621-.1704-.7465-.1704-1.1448 0-1.0203.391-1.9493 1.0312-2.6454l-.0011-.0015c.6001-.6536 1.4195-1.1026 2.3416-1.2304l.0036.0048c.1744-.0237.3524-.036.5332-.036 2.1586 0 3.9085 1.7499 3.9085 3.9085zm-26.0945 18.4195c-.0817 2.0865-1.799 3.753-3.9055 3.753-2.1586 0-3.9085-1.7499-3.9085-3.9085 0-.0098 0-.0197.0001-.0296.0067-.932.3393-1.7866.8894-2.4555l.0013.0011c.7168-.8699 1.8025-1.4245 3.0177-1.4245 1.2175 0 2.3051.5568 3.0219 1.4295l.0076-.0061c.5558.6758.8895 1.5411.8895 2.4844 0 .0494-.0009.0986-.0028.1476zm-.2931-46.90368c.7382-.49089 1.3707-1.12815 1.8561-1.87037l18.3803 24.22855c-.8364.3071-1.5924.7805-2.2279 1.3802zm-7.2143 0c-.7382-.49088-1.3707-1.12815-1.8561-1.87036l-18.38029 24.22854c.83635.3071 1.59237.7805 2.22792 1.3803zm-17.73261 32.95208c.60055-.6384 1.07391-1.3978 1.37961-2.2377l14.8983 11.9186c-.5581.6859-.9797 1.4873-1.2232 2.3628zm27.62481 12.0438 15.0547-12.0438c-.6006-.6384-1.0739-1.3978-1.3796-2.2377l-14.8983 11.9186c.5581.6859.9797 1.4873 1.2232 2.3629zm-4.6989-9.6409 12.6954-9.9022-12.6954-16.468zm-3.2615-26.1734-12.4307 16.2712 12.4307 9.7838z" />
        </SVG>
    );
}
