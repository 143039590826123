import React, {
    useState,
    useCallback,
    type Ref,
    forwardRef,
    useEffect,
} from 'react';
import { uniqBy } from 'lodash';
import type {
    Coordinate,
    AbsoluteBlockItem as BlockItem,
    SelectedItem,
    StructureItemType,
} from '@splunk/dashboard-types';
import { useFeatureFlags } from '@splunk/dashboard-context';
import { useEventCallback, useMouseMoveHandler } from '@splunk/dashboard-ui';
import Canvas from './Canvas';
import type { CanvasProps, Offset } from '../types';
import {
    getOffset,
    considerMoved,
    positionsToBoundary,
    computeRelativePosition,
    findTopBlockItemByPosition,
    filterBlockItemsByBoundary,
} from '../utils/layoutUtils';
import { applyVizPadding } from '../utils/edgeUtils';
import { useMouseDownWithHandleEventListeners } from '../hooks';

interface GridCanvasState {
    startPosition: Coordinate | null;
    isMoving: boolean;
}

const defaultState: GridCanvasState = {
    startPosition: null,
    isMoving: false,
};

export interface GridCanvasProps extends CanvasProps {
    panelPadding?: number;
    movable?: boolean;
    selectable?: boolean;
    blockItems: BlockItem[];
    selectedLayoutItems?: SelectedItem[];
    onItemSelected: (e: React.MouseEvent, items: SelectedItem[]) => void;
    onItemMove?: (
        e: MouseEvent,
        { currentPosition }: { currentPosition: Coordinate }
    ) => void;
    onItemMoved?: (e: MouseEvent, offset: Offset) => void;
    children: React.ReactNode;
}

const DEFAULT_SELECTED_LAYOUT_ITEMS: SelectedItem[] = [];
const noop = () => undefined;

const GridCanvas = (
    props: GridCanvasProps & { canvasRef: Ref<HTMLDivElement> }
): JSX.Element => {
    const {
        movable = false,
        selectable = false,
        selectedLayoutItems = DEFAULT_SELECTED_LAYOUT_ITEMS,
        panelPadding = 0,
        scale = 1,
        onItemMove = noop,
        onItemMoved = noop,
        onItemSelected,
        children,
        canvasRef,
        blockItems,
        ...canvasProps
    } = props;

    const { enableGridLayoutCssScaling } = useFeatureFlags();

    const [startPosition, setStartPosition] = useState(
        defaultState.startPosition
    );
    const [isMoving, setIsMoving] = useState(defaultState.isMoving);

    const resetState = useCallback(() => {
        setIsMoving(defaultState.isMoving);
        setStartPosition(defaultState.startPosition);
    }, []);

    const mouseScale = enableGridLayoutCssScaling ? scale : 1;

    const handleItemSelected = useCallback(
        (e, itemIds = []) => {
            if (e.metaKey) {
                // Do not allow selecting block item with meta+click
                const selectedItems = selectedLayoutItems.filter(
                    ({ type }) => type === 'block'
                );
                onItemSelected(e, uniqBy([...selectedItems, ...itemIds], 'id'));
            } else {
                onItemSelected(e, itemIds);
            }
        },
        [selectedLayoutItems, onItemSelected]
    );

    const handleMouseDownOnItem = useCallback(
        (event: React.MouseEvent, blockItem: SelectedItem) => {
            const { id, type = 'block' } = blockItem;
            // mouse down on an item, set it to selected and start moving.
            if (
                !selectedLayoutItems.find(
                    ({ id: selectedId }) => selectedId === id
                )
            ) {
                // select the block item right away if it's not selected.
                handleItemSelected(event, [{ id, type }]);
            }
        },
        [handleItemSelected, selectedLayoutItems]
    );

    // Track starting position of mouse down event and select blockItem if it's not selected
    const handleMouseDown = useCallback(
        (e) => {
            const pos = computeRelativePosition(e, canvasRef, mouseScale);
            const block = findTopBlockItemByPosition(
                blockItems,
                pos,
                panelPadding
            );
            // always track start position
            if (selectable) {
                setStartPosition(pos);
            }
            if (block) {
                // mouse down on an item, start moving.
                handleMouseDownOnItem(e, {
                    id: block.item,
                    type: block.type as StructureItemType,
                });
                if (movable) {
                    setIsMoving(true);
                }
            }
        },
        [
            canvasRef,
            mouseScale,
            blockItems,
            panelPadding,
            selectable,
            handleMouseDownOnItem,
            movable,
        ]
    );

    // Trigger onItemMove (callback) with move offset
    const handleMouseMove = useEventCallback((e: MouseEvent) => {
        if (startPosition) {
            const currentPosition = computeRelativePosition(
                e,
                canvasRef,
                mouseScale
            );
            if (currentPosition) {
                const offset = getOffset(currentPosition, startPosition);
                if (isMoving && considerMoved(offset)) {
                    e.preventDefault();
                    onItemMove(e, { currentPosition });
                }
            }
        }
    });

    // Trigger onItemMoved (callback) with final offset if an item was moved OR multiselect items within a boundary
    const handleMouseUp = useEventCallback((e: MouseEvent) => {
        if (startPosition) {
            const currentPosition = computeRelativePosition(
                e,
                canvasRef,
                mouseScale
            );
            if (currentPosition) {
                // if will be either complete a move or a multi select
                const offset = getOffset(currentPosition, startPosition);
                if (isMoving && considerMoved(offset)) {
                    onItemMoved(e, offset);
                } else {
                    // todo: we shouldn't need to use absolute coordinates to figure out which item is clicked, given there's no overlapped visualizations in grid layout. Ideally, we should let the actual viz being clicked update the selectedItem state.
                    let blocks = blockItems;
                    if (panelPadding) {
                        blocks = blocks.map((block) =>
                            applyVizPadding({
                                item: block,
                                padding: panelPadding,
                            })
                        );
                    }
                    const boundary = positionsToBoundary(
                        startPosition,
                        currentPosition
                    );
                    const selectedBlocks = filterBlockItemsByBoundary(
                        blocks,
                        boundary
                    ).map(({ item, type = 'block' }) => ({
                        id: item,
                        type,
                    }));

                    // select single item
                    const topItem = selectedBlocks[selectedBlocks.length - 1];
                    handleItemSelected(e, topItem ? [topItem] : []);
                }
                resetState();
            }
        }
    });

    useMouseDownWithHandleEventListeners({
        handleMouseDownOnItem,
        canvasRef,
        scale,
        setStartPosition,
        movable,
        setIsMoving,
    });

    useEffect(() => {
        document.addEventListener('mouseup', handleMouseUp);
        return () => {
            document.removeEventListener('mouseup', handleMouseUp);
        };
    }, [handleMouseUp]);

    useMouseMoveHandler({ onMouseMove: handleMouseMove, isEnabled: movable });

    return (
        <Canvas
            ref={canvasRef}
            {...canvasProps}
            scale={scale}
            onMouseDown={handleMouseDown}
            onContextMenu={resetState}
            cssScaling={enableGridLayoutCssScaling}
        >
            {children}
        </Canvas>
    );
};

export default forwardRef(
    (props: GridCanvasProps, ref: React.Ref<HTMLDivElement>) => (
        <GridCanvas canvasRef={ref} {...props} />
    )
);
