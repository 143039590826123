import T from 'prop-types';

export const CellPropTypes = {
    onCellClick: T.func,
    value: T.oneOfType([T.number, T.string, T.array]).isRequired,
    cellOptions: T.object,
    fieldOptions: T.object,
};

export const CellDefaultProps = {
    cellOptions: {},
    fieldOptions: {},
    onCellClick: () => {},
};

export const CellDefaultStyle = {
    maxWidth: '100px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
};
