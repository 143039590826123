import { _ } from '@splunk/ui-utils/i18n';
import { COLOR_OR_TOKEN_PATTERN_WITH_RGBA, getPattern } from '@splunk/visualizations-shared/schemaUtils';
import { defaultPalettesConfig } from '@splunk/visualization-color-palettes/editors/PresetPalettes';
import { enterprise, enterpriseDark, scp } from '@splunk/visualization-themes/variables';
import pick from '@splunk/themes/pick';
import variables from '@splunk/themes/variables';
import { SingleValue as SingleValueIcon } from '@splunk/visualization-icons';
import { SingleValue as SingleValuePlaceholderIcon } from '@splunk/visualization-icons/placeholders';
import { defaultContext } from '../common/configContext/singleValueContext';
import { VizConfig } from '../common/interfaces/VizConfig';
import { DataContract } from '../common/interfaces/DataContract';
import { VizSize } from '../common/interfaces/VizSize';
import { OptionsSchema } from '../common/interfaces/OptionsSchema';
import { EditorConfig } from '../common/interfaces/Editor';
import { VizBehavior } from '../common/interfaces/VizBehavior';
import { getDynamicMajorColorEditor } from '../common/editorConfig/DynamicMajorColorEditor';
import { getDynamicTrendColorEditor } from '../common/editorConfig/DynamicTrendColorEditor';
import { getDynamicBackgroundColorEditor } from '../common/editorConfig/DynamicBackgroundColorEditor';
import StaticMajorAndTrendLayout from '../common/editorConfig/StaticMajorAndTrendLayout';
import StaticMajorColorEditor from '../common/editorConfig/StaticMajorColorEditor';
import StaticTrendColorEditor from '../common/editorConfig/StaticTrendColorEditor';
import StaticBackgroundColorEditor from '../common/editorConfig/StaticBackgroundColorEditor';
import { PresetEntry, enhanceConfig, getInitialPreset } from '../common/utils/configUtils';
import { TrellisEditorConfig, TrellisBackgroundColorEditor } from '../common/editorConfig/TrellisEditors';
import { ThemedDefaults } from '../common/interfaces/ThemedDefaults';
import { VizCategory } from '../common/interfaces/VizCategory';
import { LAYOUT_TYPE } from '../common/utils/layoutUtils';

const dataContract: DataContract = {
    requiredDataSources: [
        {
            name: 'primary',
            description: 'DataSource that powers the visualization',
        },
    ],
    optionalDataSources: [],
    initialRequestParams: {
        primary: { offset: 0, count: 10000 },
    },
};

const size: VizSize = {
    initialWidth: 250,
    initialHeight: 250,
};

const optionsSchema: OptionsSchema = {
    backgroundColor: {
        default: '> themes.defaultBackgroundColor',
        description:
            'Specify the color for the background. You may use a dataSource to apply the color. The default for enterprise light is "#ffffff". The default for enterprise dark is "#000000". The default for prisma dark is "#0b0c0e".',
        pattern: COLOR_OR_TOKEN_PATTERN_WITH_RGBA,
        type: 'string',
    },
    majorColor: {
        default: '> themes.defaultFontColor',
        description:
            'Specify the color for the major value.  You may use a dataSource to apply the color. The hex value format should be “#FFFFFF”. The default for enterprise light is "#000000". The default for enterprise dark is "#ffffff". The default for prisma dark is "rgba(255, 255, 255, 0.98)".',
        pattern: COLOR_OR_TOKEN_PATTERN_WITH_RGBA,
        type: 'string',
    },
    majorFontSize: {
        description:
            'Specify the font size (px) for the major value. By default the major value font size is calculated dynamically based on the available space.',
        type: 'number',
    },
    majorValue: {
        default: '> sparklineValues|lastPoint()',
        description: 'The major value to display in the visualization.',
        type: ['string', 'number'],
    },
    majorValueField: {
        default: '> majorValue | getField()',
        description: 'The field name of major value.',
        type: 'string',
    },
    numberPrecision: {
        default: 0,
        maximum: 20,
        minimum: 0,
        description:
            'Specify the number of decimal places to display. For example, to display 3 decimal places, use a value of 3. Values can range from 0 to 20.',
        type: 'number',
    },
    shouldAbbreviateTrendValue: {
        default: false,
        description:
            'Specify whether to abbreviate the trend value to 2 decimal points. A magnitude unit will be displayed.',
        type: 'boolean',
    },
    shouldSparklineAcceptNullData: {
        default: true,
        description: 'Specify whether to convert null and non-numeric values in the sparkline to 0.',
        type: 'boolean',
    },
    shouldUseThousandSeparators: {
        default: true,
        description: 'Specify whether numeric values use commas as thousandths separators.',
        type: 'boolean',
    },
    showSparklineAreaGraph: {
        default: false,
        description: 'Specify whether to show the sparkline as an area graph rather than a line.',
        type: 'boolean',
    },
    showSparklineTooltip: {
        default: false,
        description: 'Show a tooltip to display values on the sparkline.',
        type: 'boolean',
    },
    splitByLayout: {
        default: 'off',
        description:
            'Specify the layout method by which to display the visualization, which splits the data into individual visualizations based on a certain category.',
        type: 'string',
        pattern: getPattern([LAYOUT_TYPE.Off, LAYOUT_TYPE.Trellis]),
        enum: [LAYOUT_TYPE.Off, LAYOUT_TYPE.Trellis],
    },
    sparklineAreaColor: {
        default: '> sparklineStrokeColor',
        description:
            'Specify the color for the sparkline area fill. You may use a dataSource to apply the color. The hex value format should be “#FFFFFF”. The graph area will have an opacity of 20% and will blend with the background color.',
        pattern: COLOR_OR_TOKEN_PATTERN_WITH_RGBA,
        type: 'string',
    },
    sparklineDisplay: {
        default: 'below',
        description: 'Specify how to display a sparkline.',
        pattern: getPattern(['before', 'after', 'below', 'off']),
        type: 'string',
    },
    sparklineHighlightDots: {
        default: 0,
        description:
            'Specify the number of markers, or dots, to display at the top of a sparkline area graph.',
        type: 'number',
    },
    sparklineHighlightSegments: {
        default: 0,
        description: 'Specify the number of segments to be highlighted at the top of a sparkline area graph.',
        type: 'number',
    },
    sparklineStrokeColor: {
        default: '> themes.defaultSparklineStrokeColor',
        description:
            'Specify the color for the sparkline stroke. You may use a dataSource to apply the color. The hex value format should be “#FFFFFF”. The default for enterprise light is "#000000". The default for enterprise dark is "#ffffff". The default for prisma dark is "rgba(255, 255, 255, 0.98)".',
        pattern: COLOR_OR_TOKEN_PATTERN_WITH_RGBA,
        type: 'string',
    },
    sparklineValues: {
        default: '> primary|seriesByPrioritizedTypes("number", "string", "time")',
        description:
            'List of numerical values to display on a sparkline. If the values are string type, the sparkline will not be shown.',
        type: 'array',
        items: { type: ['string', 'number'] },
    },
    trendColor: {
        default: '> themes.defaultFontColor',
        description:
            'Specify the color for the trend value. You may use a dataSource to apply the color. The hex value format should be “#FFFFFF”. The default for enterprise light is "#000000". The default for enterprise dark is "#ffffff". The default for prisma dark is "rgba(255, 255, 255, 0.98)".',
        pattern: COLOR_OR_TOKEN_PATTERN_WITH_RGBA,
        type: 'string',
    },
    trendDisplay: {
        default: 'absolute',
        description: 'Specify how to display the trend value.',
        pattern: getPattern(['percent', 'absolute', 'off']),
        type: 'string',
    },
    trendFontSize: {
        description:
            'Specify the font size (px) for the trend value. By default the trend value font size is calculated dynamically based on the available space.',
        type: 'number',
    },
    trendValue: {
        default: '> sparklineValues|delta(-2)', // todo: may need update delta method
        description: 'The trend value to display in the visualization.',
        type: 'number',
    },
    trellisBackgroundColor: {
        default: '> themes.defaultBackgroundColor',
        description:
            'Specify the color used for the trellis container background by using a hexadecimal code. For example, #0000FF.',
        pattern: COLOR_OR_TOKEN_PATTERN_WITH_RGBA,
        type: 'string',
    },
    trellisColumns: {
        description:
            'Specify the number of visualizations to display in a given row of the trellis container. The remaining visualizations will wrap accordingly. If nothing is specified, it will be auto-set based on the trellisMinColumnWidth. The minimum value is 1.',
        type: 'number',
        minimum: 1,
    },
    trellisMinColumnWidth: {
        default: 100,
        minimum: 1,
        description:
            'Specify the minimum width, in pixels, of each visualization in the trellis container. If the window or panel is resized, the remaining visualizations may be viewed by scrolling. The minimum value is 1.',
        type: 'number',
    },
    trellisPageCount: {
        default: 20,
        minimum: 1,
        description:
            'Specify the maximum number of visualizations to display in a single page in the trellis container. The remaining visualizations will paginate accordingly. The minimum value is 1.',
        type: 'number',
    },
    trellisRowHeight: {
        default: 70,
        minimum: 1,
        description:
            'Specify the height, in pixels, of each visualization in the trellis container. If the window or panel is resized, the remaining visualizations may be viewed by scrolling. The minimum value is 1.',
        type: 'number',
    },
    trellisSplitBy: {
        description:
            'Specify the field name of the column with categories used, or “aggregations”, to split the data into individual visualizations for trellis display, if applicable. If a SPL `timechart` command is used, this may default to the SPL `by` clause field. If a SPL `chart` command is used, this may default to the first string field.',
        type: 'string',
    },
    underLabel: { description: 'Specify the text that appears below the major value.', type: 'string' },
    underLabelFontSize: {
        default: 12,
        description: 'Specify the font size (px) for the under label text.',
        type: 'number',
    },
    unit: {
        description: 'Specify text to show next to the major value.',
        type: 'string',
    },
    unitPosition: {
        default: 'after',
        description: 'Specify whether the unit text should appear before or after the major value.',
        pattern: getPattern(['before', 'after']),
        type: 'string',
    },
};

const basePresetContext = {
    majorColorEditorConfig: undefined,
    trendColorEditorConfig: undefined,
    backgroundColorEditorConfig: undefined,
};

const basePresetOptions = {
    majorColor: undefined,
    trendColor: undefined,
    backgroundColor: undefined,
};

const presets: PresetEntry[] = [
    {
        // dummy context variables to allow for resetting of unused context states
        label: _('None'),
        name: 'singleValue.none',
        value: {
            context: { ...basePresetContext },
            options: { ...basePresetOptions },
        },
    },
    {
        label: _('Major value'),
        name: 'singleValue.majorValue',
        value: {
            context: {
                ...basePresetContext,
                majorColorEditorConfig: defaultContext.majorColorConfig,
            },
            options: {
                ...basePresetOptions,
                majorColor: '> majorValue | rangeValue(majorColorEditorConfig)',
            },
        },
    },
    {
        label: _('Trend value'),
        name: 'singleValue.trend',
        value: {
            context: {
                ...basePresetContext,
                trendColorEditorConfig: defaultContext.trendColorConfig,
            },
            options: {
                ...basePresetOptions,
                trendColor: '> trendValue | rangeValue(trendColorEditorConfig)',
            },
        },
    },
    {
        label: _('Major value and Trend value'),
        name: 'singleValue.majorValueAndTrend',
        value: {
            context: {
                ...basePresetContext,
                majorColorEditorConfig: defaultContext.majorColorConfig,
                trendColorEditorConfig: defaultContext.trendColorConfig,
            },
            options: {
                ...basePresetOptions,
                majorColor: '> majorValue | rangeValue(majorColorEditorConfig)',
                trendColor: '> trendValue | rangeValue(trendColorEditorConfig)',
            },
        },
    },
    {
        label: _('Background'),
        name: 'singleValue.background',
        value: {
            context: {
                ...basePresetContext,
                backgroundColorEditorConfig: defaultContext.majorColorConfig,
            },
            options: {
                ...basePresetOptions,
                backgroundColor: '> majorValue | rangeValue(backgroundColorEditorConfig)',
            },
        },
    },
];

// @TODO(pwied):
// remove once react-visualizations/SV was deprecated
// including unused sparklineFillColor
const LIGHTMODE_SPARKLINE_AREA_COLOR = '#171D21';
const DARKMODE_SPARKLINE_AREA_COLOR = '#FFFFFF';
const themes: ThemedDefaults = {
    defaultFontColor: props =>
        pick({
            enterprise: {
                dark: variables.textColor(props),
                light: variables.gray20(props),
            },
            prisma: variables.contentColorActive(props),
        })(props),
    defaultBlockFontColor: props =>
        pick({
            enterprise: variables.white(props),
            prisma: variables.contentColorActive(props),
        })(props),
    defaultBackgroundColor: props =>
        pick({
            enterprise: {
                dark: variables.black(props),
                light: variables.backgroundColor(props),
            },
            prisma: variables.backgroundColorSidebar(props),
        })(props),
    defaultSparklineStrokeColor: props =>
        pick({
            enterprise: variables.textColor(props),
            prisma: variables.contentColorActive(props),
        })(props),
    // TODO(fkurniawan): update with SCP-36839
    sparklineSeverityColors: props =>
        pick({
            enterprise: {
                dark: JSON.stringify({
                    none: {
                        background: enterpriseDark.gray60, // note: may need update later
                        stroke: enterpriseDark.white,
                    },
                    low: {
                        background: enterpriseDark.successColorD50,
                        stroke: enterpriseDark.white,
                    },
                    guarded: {
                        background: enterpriseDark.infoColorD50,
                        stroke: enterpriseDark.white,
                    },
                    elevated: {
                        background: enterpriseDark.warningColorD50,
                        stroke: enterpriseDark.white,
                    },
                    high: {
                        background: enterpriseDark.alertColorD50,
                        stroke: enterpriseDark.white,
                    },
                    severe: {
                        background: enterpriseDark.errorColorD50,
                        stroke: enterpriseDark.white,
                    },
                }),
                light: JSON.stringify({
                    none: {
                        background: enterprise.gray60,
                        stroke: enterprise.gray20,
                    },
                    low: {
                        background: enterprise.successColorD50,
                        stroke: enterprise.white,
                    },
                    guarded: {
                        background: enterprise.infoColorD50,
                        stroke: enterprise.white,
                    },
                    elevated: {
                        background: enterprise.warningColorD50,
                        stroke: enterprise.white,
                    },
                    high: {
                        background: enterprise.alertColorD50,
                        stroke: enterprise.white,
                    },
                    severe: {
                        background: enterprise.errorColorD50,
                        stroke: enterprise.white,
                    },
                }),
            },
            prisma: JSON.stringify({
                none: {
                    background: scp.gray52,
                    stroke: scp.white,
                },
                low: {
                    background: scp.successColorD50,
                    stroke: scp.white,
                },
                guarded: {
                    background: scp.infoColorD50,
                    stroke: scp.white,
                },
                elevated: {
                    background: scp.warningColorD50,
                    stroke: scp.white,
                },
                high: {
                    background: scp.alertColorD50,
                    stroke: scp.white,
                },
                severe: {
                    background: scp.errorColorD50,
                    stroke: scp.white,
                },
            }),
        })(props),
    // TODO(fkurniawan): update with SCP-36839
    sparklineFillColor: props =>
        pick({
            enterprise: {
                light: LIGHTMODE_SPARKLINE_AREA_COLOR,
                dark: DARKMODE_SPARKLINE_AREA_COLOR,
            },
            prisma: DARKMODE_SPARKLINE_AREA_COLOR,
        })(props),
};

const editorConfig: EditorConfig[] = [
    {
        label: _('Data configurations'),
        layout: [
            [
                {
                    label: _('Value'),
                    editor: 'editor.columnSelector',
                    option: 'sparklineValues',
                    context: 'sparklineValuesContext',
                    editorProps: {
                        dataSourceKey: 'primary',
                        prepareValue: (
                            definition
                        ): { context: Record<string, any>; options: Record<string, any> } => {
                            const {
                                options: { sparklineValues },
                            } = definition;
                            return {
                                context: {},
                                options: {
                                    majorValue: `> sparklineValues | lastPoint()`,
                                    trendValue: `> sparklineValues | delta(-2)`,
                                    sparklineValues,
                                },
                            };
                        },
                    },
                    isDisabled: ({ options }) => options.splitByLayout === LAYOUT_TYPE.Trellis,
                },
            ],
        ],
    },
    {
        label: _('Data display'),
        layout: [
            ...StaticMajorAndTrendLayout,
            [
                {
                    label: _('Trend display'),
                    option: 'trendDisplay',
                    editor: 'editor.select',
                    editorProps: {
                        values: [
                            { label: _('Percent'), value: 'percent' },
                            { label: _('Absolute'), value: 'absolute' },
                            { label: _('Off'), value: 'off' },
                        ],
                    },
                    showEditor: ({ options }) => options.showValue || options.showValue === undefined,
                },
                {
                    label: _('Sparkline display'),
                    option: 'sparklineDisplay',
                    editor: 'editor.select',
                    editorProps: {
                        values: [
                            { label: _('Off'), value: 'off' },
                            { label: _('Before'), value: 'before' },
                            { label: _('Below'), value: 'below' },
                            { label: _('After'), value: 'after' },
                        ],
                    },
                },
            ],
            ...TrellisEditorConfig,
        ],
    },
    {
        label: _('Color and style'),
        layout: [
            [
                {
                    label: 'Dynamic elements',
                    key: 'presetSelector',
                    editor: 'editor.presetSelector',
                    editorProps: {
                        presets,
                        value: ({ context, options }) => {
                            const initialPreset = getInitialPreset(context, options, presets);
                            return (initialPreset && initialPreset.label) || null;
                        },
                    },
                },
            ],
            [
                {
                    ...StaticMajorColorEditor,
                    editorProps: {
                        themes,
                    },
                },
            ],
            [getDynamicMajorColorEditor({ defaultContext, defaultPalettesConfig })],
            [
                {
                    ...StaticTrendColorEditor,
                    editorProps: {
                        themes,
                    },
                },
            ],
            [getDynamicTrendColorEditor({ defaultContext, defaultPalettesConfig })],
            [
                {
                    ...StaticBackgroundColorEditor,
                    editorProps: {
                        themes,
                    },
                },
            ],
            [
                {
                    ...TrellisBackgroundColorEditor,
                    editorProps: {
                        themes,
                    },
                },
            ],
            [getDynamicBackgroundColorEditor({ defaultContext, defaultPalettesConfig })],
            [
                {
                    label: _('Major value size (px)'),
                    option: 'majorFontSize',
                    editor: 'editor.number',
                    editorProps: {
                        min: 1,
                    },
                    placeholder: _('Auto'),
                    showEditor: ({ options }) => options.showValue || options.showValue === undefined,
                },
                {
                    label: _('Trend value size (px)'),
                    option: 'trendFontSize',
                    editor: 'editor.number',
                    editorProps: {
                        min: 1,
                    },
                    placeholder: _('Auto'),
                    showEditor: ({ options }) => options.showValue || options.showValue === undefined,
                    isDisabled: ({ options }) => options.trendDisplay === 'off',
                },
            ],
        ],
    },
];

/**
 * visualization configuration
 */
const config: VizConfig = {
    /**
     * unique viz key
     */
    key: 'splunk.singlevalue',
    /**
     * viz name
     */
    name: 'Single Value',
    category: VizCategory.SINGLE_VALUE,
    /**
     * viz icon
     */
    icon: SingleValueIcon,
    placeholderIcon: SingleValuePlaceholderIcon,
    dataContract,
    size,
    defaultContext,
    optionsSchema,
    editorConfig,
    events: {
        'value.click': {
            description: 'trigger when user clicks major value',
            payloadKeys: ['majorValue', 'trendValue'],
        },
    },
    supports: [VizBehavior.DYNAMIC_OPTIONS, VizBehavior.EVENTS, VizBehavior.PLACEHOLDER, VizBehavior.TRELLIS],
    themes,
    requiredProps: ['majorValue'],
};

export default enhanceConfig(config);
