/* eslint-disable class-methods-use-this */
import { DataType, TypedValue } from './DataPrimitive';
import { IDimension } from './Dimensions';
import { DataFrame } from './DataFrame';
import { DataSeries } from './DataSeries';
import { DataPoint } from './DataPoint';

export interface DataPrimitiveFormatter<IN extends DataType, OUT extends DataType> {
    format(dataPrimitive: IDimension<IN>): IDimension<OUT> | Record<string, unknown>;
}

export abstract class AbstractFormatter<IN extends DataType = DataType, OUT extends DataType = DataType>
    implements DataPrimitiveFormatter<IN, OUT> {
    // use method overload signatures to tell compiler that if we format a DataPoint, we return a DataPoint, etc
    format(p: DataPoint<IN>): DataPoint<OUT>;

    format(s: DataSeries<IN>): DataSeries<OUT>;

    format(f: DataFrame<IN>): DataFrame<OUT>;

    format(dataPrimitive: IDimension<IN>): IDimension<OUT> {
        if (dataPrimitive instanceof DataFrame) {
            const newSeries: DataSeries<OUT>[] = [];
            dataPrimitive.series.forEach((dataSeries: DataSeries<IN>, i: number) => {
                newSeries.push(this.formatSeries(dataSeries, i));
            });
            return new DataFrame<OUT>(newSeries);
        }
        if (dataPrimitive instanceof DataSeries) {
            return this.formatSeries(dataPrimitive);
        }
        return this.formatPoint(dataPrimitive);
    }

    protected formatSeries(s: DataSeries<IN>, i = 0): DataSeries<OUT> {
        const newPoints: DataPoint<OUT>[] = [];
        s.points.forEach((dataPoint, j) => {
            newPoints.push(this.formatPoint(dataPoint, s, i, j));
        });
        return new DataSeries<OUT>(newPoints); // new DataSeries must have the type of the formatter's output
    }

    protected formatPoint(p: DataPoint<IN>, s?: DataSeries<IN>, i = 0, j = 0): DataPoint<OUT> {
        const { field } = p;
        const dp = new DataPoint(field, { type: p.getValue().type, value: p.getValue().coercedValue });
        const tmp = this.formatTypedValue(dp, s, i, j);
        return new DataPoint<OUT>(field, tmp);
    }

    protected abstract formatTypedValue(
        p: DataPoint<IN>,
        s?: DataSeries<IN>,
        i?: number,
        j?: number
    ): TypedValue<OUT>;

    protected makeArrays2D(a: any): any[][] {
        if (Array.isArray(a)) {
            if (Array.isArray(a[0])) {
                return a;
            }
            return [a];
        }
        throw new Error("argument wasn't array");
    }
}
