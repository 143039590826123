import { mapKeys, chain } from 'lodash';
import { convertPropsToString } from '@splunk/visualizations-shared/propUtils';
import { mapToValues } from '../common/chartingUtils';

interface BubbleOptions {
    [key: string]: any;
}

// map for key names which are either new or not present in SimpleXML options mapping
const bubbleOptionsSCMapping = {
    xAxisLabelRotation: 'xAxisMajorLabelRotation',
    xAxisLabelVisibility: 'xAxisMajorLabelVisibility',
    yAxisLabelVisibility: 'yAxisMajorLabelVisibility',
    showRoundedXAxisLabels: 'roundXAxisLabelsToInteger',
    xAxisLineVisibility: 'xAxisVisibility',
    yAxisLineVisibility: 'yAxisVisibility',
    showYMajorGridLines: 'showMajorYGridLines',
    showXMinorGridLines: 'showMinorXGridLines',
    showYMinorGridLines: 'showMinorYGridLines',
    xAxisMajorTickInterval: 'xAxisMajorUnit',
    yAxisMajorTickInterval: 'yAxisMajorUnit',
    showXAxisExtendedRange: 'xAxisExtendsAxisRange',
    showYAxisExtendedRange: 'yAxisExtendsAxisRange',
    showXAxisWithZero: 'xAxisIncludeZero',
    showYAxisWithZero: 'yAxisIncludeZero',
    legendTruncation: 'legendLabelOverflowMode',
    legendDisplay: 'legendPlacement',
    resultLimit: 'resultTruncationLimit',
    seriesColorsByField: 'fieldColors',
    bubbleSizeMax: 'bubbleMaxSize',
    bubbleSizeMin: 'bubbleMinSize',
    bubbleSizeMethod: 'bubbleSizeBy',
};

// key value mapping of what options (and their corresponding values) which we expose in config.ts to property values SC understands
export const bubbleOptionValuesSCMapping = {
    xAxisTitleVisibility: {
        hide: 'collapsed',
    },
    yAxisTitleVisibility: {
        hide: 'collapsed',
    },
    yAxisAbbreviation: {
        off: 'none',
    },
    legendPlacement: {
        off: 'none',
    },
    legendLabelOverflowMode: {
        ellipsisOff: 'ellipsisNone',
    },
};

/**
 * Helper method to convert option which contains object literal values (e.g. arrays, objects)
 * to strings as SC can only accept their stringified equivalents
 * @method convertToStringValues
 * @param {Object} options
 * @returns {Object}
 */
export const convertToStringValues = (options: BubbleOptions): BubbleOptions => {
    const updatedOptions = convertPropsToString({
        vizProps: options,
        propNames: ['seriesColors', 'fieldColors'],
    });
    return { ...options, ...updatedOptions };
};

/**
 * Helper method to update the option names which can be mapped to SimpleXML options or
 * can be used directly with SC if don't have a 1-1 mapping in SimpleXML
 * @method mapToOldKey
 * @param {Object} options
 * @returns {Object}
 */
export const mapToOldKey = (options: BubbleOptions): BubbleOptions =>
    mapKeys(options, (val, key) => (bubbleOptionsSCMapping[key] ? bubbleOptionsSCMapping[key] : key));

/**
 * Chained helper for mapping the option properties (and values) we expose in config.ts into property values that splunk-charting accepts
 * This is for options that has new key name and can not directly be mapped with SimpleXML or
 * don't have a 1-1 mapping in SimpleXML (i.e. a straightforward name mapping where values are preserved)
 * @method convertToBubbleSCProperties
 * @param {Object} originalOptions
 * @returns {Object}
 */
export const convertToBubbleSCProperties = (originalOptions: BubbleOptions): BubbleOptions =>
    chain(mapToOldKey(originalOptions))
        .thru(mapToValues(bubbleOptionValuesSCMapping))
        .thru(convertToStringValues)
        .value();
