import React from 'react';
import { _ } from '@splunk/ui-utils/i18n';
import SVG, {
    type SVGPropsOptionalViewBox,
} from '@splunk/react-icons/SVGEnterprise';

/* eslint-disable max-len */
export default function AddIconDropdown(
    props: SVGPropsOptionalViewBox
): ReturnType<typeof SVG> {
    return (
        <SVG viewBox="0 0 24 24" screenReaderText={_('Add icon')} {...props}>
            <path
                fill="currentColor"
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9 5H5V9H9V5ZM3 3V11H11V3H3Z"
            />
            <path
                fill="currentColor"
                fillRule="evenodd"
                clipRule="evenodd"
                d="M19 5H15V9H19V5ZM13 3V11H21V3H13Z"
            />
            <path
                fill="currentColor"
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9 15H5V19H9V15ZM3 13V21H11V13H3Z"
            />
            <path
                fill="currentColor"
                fillRule="evenodd"
                clipRule="evenodd"
                d="M19 15H15V19H19V15ZM13 13V21H21V13H13Z"
            />
        </SVG>
    );
}
