import React, { useCallback, useMemo } from 'react';
import type {
    Coordinate,
    RootDataSourcesDefinition,
    VisualizationDefinition,
    VizProps,
} from '@splunk/dashboard-types';
import { noop } from '@splunk/dashboard-utils';
import { useSubscribeToSearches } from '@splunk/dashboard-ui';
import { usePreset, useEventRegistry } from '@splunk/dashboard-context';

interface BaseLineContainerProps extends VizProps {
    from?: Coordinate;
    to?: Coordinate;
    onLineSelect?: () => void;
}

export type LineContainerProps = BaseLineContainerProps & {
    vizDef?: VisualizationDefinition;
    dataSourceDefs?: RootDataSourcesDefinition;
    handleEvent?: (
        eventType: string,
        payload: unknown,
        eventId: string
    ) => void;
};

type LineContainerWithDataSourceProps = BaseLineContainerProps & {
    dataSourceId: string;
    vizType: string;
};

const LineContainerWithDataSource = (
    props: LineContainerWithDataSourceProps
) => {
    const { dataSourceId, vizType, ...lineProps } = props;

    const dataSourceBindings = useMemo(
        () => ({ primary: dataSourceId }),
        [dataSourceId]
    );

    const preset = usePreset();

    const { loading, dataSources, updateRequestParams } =
        useSubscribeToSearches({
            consumerId: lineProps.id,
            bindings: dataSourceBindings,
        });

    return preset.createVisualization(vizType, {
        ...lineProps,
        loading,
        dataSources,
        onRequestParamsChange: updateRequestParams,
    });
};

const LineContainer = (props: LineContainerProps) => {
    const {
        id,
        mode,
        from,
        to,
        vizDef: {
            type,
            options = {},
            dataSources: dataSourceBindings = {},
            context,
        } = {},
        onLineSelect,
        onOptionsChange,
        dataSourceDefs,
        handleEvent,
    } = props;

    const preset = usePreset();
    const eventRegistry = useEventRegistry();
    const onEventTrigger = useCallback(
        ({
            type: eventType,
            originalEvent,
            payload,
        }: {
            type: string;
            originalEvent: MouseEvent;
            payload: unknown;
        }) => {
            const eventId = eventRegistry.registerEvent(originalEvent);
            handleEvent?.(eventType, payload, eventId);
        },
        [handleEvent, eventRegistry]
    );

    if (!type) {
        return null;
    }

    const lineProps = {
        id,
        mode,
        from,
        to,
        options,
        context,
        onLineSelect,
        onOptionsChange,
        onEventTrigger,
    } as LineContainerProps;

    // line only support primary DataSource
    const primaryDataSourceId = dataSourceBindings.primary;
    if (primaryDataSourceId && dataSourceDefs?.[primaryDataSourceId]) {
        return (
            <LineContainerWithDataSource
                {...lineProps}
                vizType={type}
                dataSourceId={primaryDataSourceId}
            />
        );
    }

    return preset.createVisualization(type, {
        ...lineProps,
        loading: false,
        dataSources: {},
        onRequestParamsChange: noop,
    });
};

export default React.memo(LineContainer);
