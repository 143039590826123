import { Encoding } from './Encoding';
import { AST, Dimension, DimensionEncoding, OptionsStanza, OptionsAST } from './AST';
import { DslParser } from './DslParser';

export default class EncodingParser {
    static parseOptions(optionsStanza: OptionsStanza): OptionsAST {
        const optionsAST: OptionsAST = { simpleOptions: {}, expressions: {}, context: optionsStanza.context }; // context is just passed along
        const { options } = optionsStanza;
        Object.keys(options).forEach(k => {
            const v = options[k];
            if (EncodingParser.isDslString(v)) {
                optionsAST.expressions[k] = DslParser.parse(EncodingParser.withoutArrow(v));
            } else {
                optionsAST.simpleOptions[k] = v; // things that are not expressions just pass through
            }
        });
        return optionsAST;
    }

    static isDslString(s: any): boolean {
        return s ? s.toString().trim().startsWith('>') : false;
    }

    static withoutArrow(s: any): string {
        return s
            .toString()
            .match(/\s*>\s*(.+$)/)[1]
            .trim();
    }

    static parse(encoding: Encoding): AST {
        const { dimensions: dslFields, context } = encoding;
        const ast: AST = { dimensions: {}, context }; // this is what we will populate and return

        Object.keys(dslFields).forEach((f: string) => {
            const astDimension: Dimension = { value: [] };
            let value = null;
            if (typeof dslFields[f] === 'string') {
                value = dslFields[f];
            } else {
                const dim = dslFields[f] as DimensionEncoding;
                value = dim.value;
            }
            astDimension.value = DslParser.parse(value);
            ast.dimensions[f] = astDimension;
        });
        return ast;
    }
}
