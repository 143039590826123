/* eslint-disable no-param-reassign */
import * as React from 'react';
import { get, isEqual } from 'lodash';
import styled from 'styled-components';
import { ISvgFeatureCollection } from './src/utils/ISvgFeatureCollection';
import { DataPoint } from './src/utils/ICoordinateTransformation';

export const DATA_ATTRIBUTE = 'data-value';

export interface SvgChoroplethProps {
    featureCollection: ISvgFeatureCollection;
    data: { featureIDs: string[]; fill: string[]; values: any[] };
    fillColor: string;
    strokeColor: string;
    strokeHighlightColor?: string;
    backgroundColor?: string;
    selector: string;
    onFeatureHover: (params: { featureId: string; value: any; label?: string }) => void;
    onClick?: (sourceCoordinates: DataPoint, featureId: string) => void;
}

export interface SvgChoroplethState {
    svgNode: React.ReactNode;
}

export { GeoFeatureGroup } from './src/utils/GeoTypes';

interface StyledSvgChoroplethProps {
    readonly backgroundColor: string;
}

const StyledSvgChoropleth = styled.div<StyledSvgChoroplethProps>`
    display: flex;
    flex-direction: column;
    position: relative;
    background-color: ${(props): string => props.backgroundColor};
`;

export default class SvgChoropleth extends React.Component<SvgChoroplethProps, SvgChoroplethState> {
    containerRef: React.RefObject<HTMLDivElement> = React.createRef();

    // lastFeatureStroke: string;
    svgDom: SVGElement;

    highlightedCountry: SVGElement = null;

    constructor(props: SvgChoroplethProps) {
        super(props);
        const { featureCollection } = props;
        this.state = { svgNode: featureCollection.getSvgNode(this.registerSvg) };
    }

    shouldComponentUpdate(nextProps: Readonly<SvgChoroplethProps>, nextState: SvgChoroplethState): boolean {
        const shouldUpdate =
            !isEqual(nextState.svgNode, this.state.svgNode) || !isEqual(nextProps, this.props);
        return shouldUpdate;
    }

    componentDidUpdate(prevProps): void {
        if (this.props !== prevProps) {
            if (prevProps.featureCollection !== this.props.featureCollection) {
                // eslint-disable-next-line react/no-did-update-set-state
                this.setState({
                    svgNode: this.props.featureCollection.getSvgNode(this.registerSvg),
                });
            } else {
                this.updateSvg(this.props);
            }
        }
    }

    getLocalCoords = (clientX: number, clientY: number): { x: number; y: number } => {
        const rect = this.containerRef.current.getBoundingClientRect();
        const physX = rect.left;
        const physY = rect.top;
        return { x: clientX - physX, y: clientY - physY };
    };

    handleMapClick = (event: React.MouseEvent<HTMLInputElement>): void => {
        const { clientX, clientY } = event;
        const pos = this.getLocalCoords(clientX, clientY);
        const { featureCollection, onClick } = this.props;
        const clickedCoords: DataPoint = featureCollection.transformation.transformBack(pos);
        const feature: SVGSVGElement = event.target as SVGSVGElement;
        const wholeFeature: SVGElement = feature.id ? feature : (feature.parentNode as SVGElement);
        if (onClick) {
            onClick(clickedCoords, wholeFeature.id);
        }
    };

    handleFeatureEnter = (ev: MouseEvent): void => {
        const feature: SVGSVGElement = ev.target as SVGSVGElement;

        if (!feature.id && !feature.ownerSVGElement) {
            return;
        }

        const wholeFeature: SVGElement = feature.id ? feature : (feature.parentNode as SVGElement);
        // remove the highlighted country IF we are entering a different country
        if (this.highlightedCountry && wholeFeature.id !== this.highlightedCountry.id) {
            this.unHighlightCountry();
        }
        this.highlightCountry(wholeFeature);

        if (this.props.onFeatureHover) {
            this.props.onFeatureHover({
                featureId: wholeFeature.id,
                label: wholeFeature.getAttribute('label_en'),
                value: wholeFeature.getAttribute(DATA_ATTRIBUTE),
            });
        }
    };

    handleFeatureLeave = (ev: MouseEvent): void => {
        const feature: SVGSVGElement = ev.target as SVGSVGElement;

        if (!feature.id && !feature.ownerSVGElement) {
            return;
        }

        // 'wholeFeature' means the SVG <g> (group) of multipolygon features
        const wholeFeature: SVGElement = feature.id ? feature : (feature.parentNode as SVGElement);
        // handling feature leaving should always only deal with the highlighted feature
        if (wholeFeature !== this.highlightedCountry) {
            return;
        }
        this.unHighlightCountry();
        // wholeFeature.style.stroke = this.lastFeatureStroke;

        if (this.props.onFeatureHover) {
            this.props.onFeatureHover({ featureId: null, value: null, label: null });
        }
    };

    // allows a provided alias like 'US-CA' to get mapped to 'California' which is the element id for the SVG shape
    private getFeatureId(alias: string): string {
        return this.props.featureCollection.resolveAlias(alias);
    }

    registerSvg = (el: SVGSVGElement): void => {
        if (!el) return;

        this.svgDom = el;

        const { selector = '.feature', strokeColor, fillColor } = this.props;

        this.svgDom.onmouseover = this.handleFeatureEnter;
        this.svgDom.onmouseout = this.handleFeatureLeave;

        this.svgDom.querySelectorAll(selector).forEach((node: HTMLElement): void => {
            node.style.stroke = strokeColor;
            node.style.fill = fillColor;
        });

        this.updateSvg(this.props);
    };

    /**
     * this method renders a copy of the polygon on top of all the others, with a highlighted boundary.
     * Technically we don't render in on top of ALL the others. There are three totally landlocked countries,
     * the first of which in our geojson file is Lesotho. These are also the last three countries in the geojson
     * file. So when we insert the highlight shape into the dom, we inert it *before* these three countries.
     * So the highlight for South Africa will not cover up Lesotho.
     * @param {SVGElement} el
     */
    highlightCountry(el: SVGElement): void {
        if (this.highlightedCountry) {
            return; // do not add more than one hovered element at a time
        }

        const firstLandlockedCountry = this.svgDom.querySelector(`#Lesotho`);
        const parent = el.parentNode;
        const clone: SVGElement = el.cloneNode(true) as SVGElement;
        const strokeColor = this.props.strokeHighlightColor || '#000';
        clone.style.stroke = strokeColor;
        this.highlightedCountry = clone;

        parent.insertBefore(clone, firstLandlockedCountry);
    }

    unHighlightCountry(): void {
        if (this.highlightedCountry) {
            this.svgDom.removeChild(this.highlightedCountry);
        }
        this.highlightedCountry = null;
    }

    updateSvg(props: SvgChoroplethProps): void {
        if (!this.svgDom) {
            return;
        }

        const { selector = '.feature', fillColor, data } = props;

        if (!data) {
            return;
        }
        const dataHashMap = {};

        data.featureIDs.forEach((alias, index): void => {
            const featureId = this.getFeatureId(alias);
            dataHashMap[featureId] = { fill: data.fill[index], value: data.values[index] };
        });
        this.svgDom.querySelectorAll(selector).forEach((node: HTMLElement): void => {
            const nodeData = dataHashMap[node.id];
            node.style.fill = get(nodeData, 'fill') || fillColor;
            const value = get(nodeData, 'value');
            if (value) {
                node.setAttribute(DATA_ATTRIBUTE, value);
            } else {
                node.removeAttribute(DATA_ATTRIBUTE);
            }
        });
    }

    render(): JSX.Element {
        const { backgroundColor = 'transparent' } = this.props;
        // note: for some reason, onclick event can't be triggered on dashboard. So change it to onMouseDown
        return (
            <StyledSvgChoropleth
                data-test="styled-svg-choropleth"
                backgroundColor={backgroundColor}
                onMouseDown={this.handleMapClick}
                ref={this.containerRef}
            >
                {this.state.svgNode}
            </StyledSvgChoropleth>
        );
    }
}
