import each from 'lodash/each';
import isNull from 'lodash/isNull';
import slice from 'lodash/slice';
import { isNumerial } from '@splunk/react-visualizations/utils/numberUtils';
import DataSet from './DataSet';

const DATA_FIELD_REGEX = /^[^_]|^_time$|^_raw$/;

export const isDataField = fieldName => DATA_FIELD_REGEX.test(fieldName);
export const isTimeField = fieldName =>
    fieldName === '_time' || fieldName === 'earliest_time' || fieldName === 'latest_time';
/**
 *
 * @param {*} column
 */
export const isSparklineArray = column =>
    column.length > 0 && Array.isArray(column[0]) && column[0][0] === '##__SPARKLINE__##';
/**
 *
 * @param {*} value
 */
export const isSparklineData = value => Array.isArray(value) && value[0] === '##__SPARKLINE__##';

/**
 * Checks if a list of values can be considered "numeric"
 * @param {array} values
 * @returns {boolean} true if half or more of the non-null values can be parsed to a float.
 * @public
 */
export const isNumericArray = values => {
    let numericCount = 0;
    let nonNullCount = 0;

    each(values, value => {
        // in the case of multivalue fields, use the first value
        let v = value;
        if (Array.isArray(value)) {
            v = value.length > 0 ? value[0] : null;
        }
        if (isNull(v)) {
            return;
        }
        nonNullCount += 1;
        if (isNumerial(v)) {
            numericCount += 1;
        }
    });
    if (nonNullCount === 0) {
        return false;
    }
    return numericCount >= nonNullCount / 2;
};

/**
 * Truncate DataSet to limit result
 * @param {data} array of 2 dimensional data
 * @param {truncationLimit} integer
 */
export const truncateData = (data, truncationLimit) => {
    if (data.columns[0] && truncationLimit > 0 && data.columns[0].length > truncationLimit) {
        const { fields, results } = data.toJSONArray();
        const truncatedResults = slice(results, 0, truncationLimit);
        return DataSet.fromJSONArray(fields, truncatedResults);
    }
    return data;
};

// todo: need remove after breaking out all visualizations
/**
 * formatData checks for null value and resultTruncationLimit option
 * @param {dataSet} set of 2 dimensional data
 * @param {options} charting options
 *
 */
export const formatData = (dataSet, options) => {
    if (dataSet == null) {
        return null;
    }
    let ds = dataSet;
    const limit = parseInt(options['chart.resultTruncationLimit'], 10);
    if (limit > 0) {
        ds = truncateData(dataSet, limit);
    }
    return ds;
};

// todo: this function may need go to parser in the future. DataSet can be removed when this function go to parser
/**
 * truncateParsedData truncate the label and value for the parsed dataset
 * @param {dataSet}
 * @param {truncationLimit} options['chart.resultTruncationLimit']
 *
 */
export const truncateParsedData = (dataSet, truncationLimit) => {
    if (!dataSet) {
        return null;
    }

    const limit = parseInt(truncationLimit, 10);
    if (!limit || limit <= 0) {
        return dataSet;
    }

    const truncatedData = {};
    Object.keys(dataSet).forEach(key => {
        if (dataSet[key] && Array.isArray(dataSet[key])) {
            truncatedData[key] = dataSet[key].slice(0, limit);
        } else {
            truncatedData[key] = dataSet[key];
        }
    });
    return truncatedData;
};
