import { merge } from 'lodash';
import { DataPrimitiveFormatter } from '../Formatter';
import { DataType } from '../DataPrimitive';
import { DataPoint } from '../DataPoint';
import { DataSeries } from '../DataSeries';
import { DataFrame } from '../DataFrame';
import EncodingExecutor from '../EncodingExecutor';

interface ObjectConfig {
    rename: { [key: string]: string };
}

/**
 * Formatter which converts a DataPrimitive into an array of objects. This is typically used in conjunction with UDF inputs.
 *
 * ```js
 * // UDF inputs
 * {
 *     "inputs": {
 *         "input1": {
 *             "context": {
 *                  "staticOptions": [["All Users"], ["*"]],
 *                  "field1": "> primary | seriesByName(\"users\") | renameSeries(\"label\")",
 *                  "field2": "> primary | seriesByName(\"ids\") | renameSeries(\"value\")"",
 *              },
 *              "type": "input.multiselect",
 *              "options": {
 *                  "items": "> frame(field1, field2) | prepend(staticOptions) | objects()"
 *                      // returns [
 *                      //     { label: 'All users', value: '*' },
 *                      //     { label: 'jane doe', value: 'jdoe' },
 *                      //     { label: 'joe schmo', value: 'jschmo' },
 *                      //     { label: 'jack schmidt', value: 'jschmidt' },
 *                      // ]
 *                  ]
 *              },
 *              "dataSources": {
 *                  "primary": {
 *                      "data": {
 *                          "fields": [{ "name": 'users' } , { "name": "ids" } ],
 *                          "columns": [[ "jane doe", "joe schmo", "jack schmidt"], ["jdoe", "jschmo", "jschmidt"]]
 *                      }
 *                  }
 *             },
 *         }
 *     }
 * }
 * ```
 */
export class Objects implements DataPrimitiveFormatter<DataType, DataType> {
    private config: ObjectConfig;

    constructor(objectConfig?: ObjectConfig) {
        this.config = EncodingExecutor.rawTree(objectConfig);
    }

    format(dp: DataPoint<DataType> | DataSeries<DataType> | DataFrame<DataType>): Record<string, any> {
        if (DataFrame.isDataFrame(dp)) {
            const mergedObjectSeries = this.seriesToObjects(dp.series[0]);
            for (let i = 1; i < dp.series.length; i += 1) {
                const mergeMeIn = this.seriesToObjects(dp.series[i]);
                for (let j = 0; j < mergedObjectSeries.length; j += 1) {
                    mergedObjectSeries[j] = merge(mergedObjectSeries[j], mergeMeIn[j]);
                }
            }
            return mergedObjectSeries;
        }
        if (DataSeries.isDataSeries(dp)) {
            return this.seriesToObjects(dp);
        }
        if (DataPoint.isDataPoint(dp)) {
            return [{ [dp.field]: dp.getRawValue() }];
        }
        throw new Error(`'objects' formatter only allowed on DataFrame, DataPoint, or DataSeries`);
    }

    private seriesToObjects(s: DataSeries): Record<string, unknown>[] {
        return s.points.reduce((acc, pt) => {
            let key = pt.field;
            if (this.config && this.config.rename) {
                key = this.config.rename[key] || key;
            }
            acc.push({ [key]: pt.getRawValue() });
            return acc;
        }, []);
    }
}
