import React, { useCallback, useEffect, useContext } from 'react';
import T from 'prop-types';
import TimeRangeDropdown from '@splunk/react-time-range/Dropdown';
import { createRangeLabel } from '@splunk/time-range-utils/time';
import { _ } from '@splunk/ui-utils/i18n';
import DashboardContext from '@splunk/dashboard-context';
import { TelemetryContext } from '@splunk/dashboard-telemetry';
import { get } from 'lodash';
import { TimeRangeInput as TimeRangeIcon } from '@splunk/dashboard-icons';
import BaseInput, { baseInputSchema } from './BaseInput';
import { SHORT_WIDTH_DRAGGABLE, LONG_WIDTH_DRAGGABLE } from '../utils/enhancer';
import { parseValue } from '../utils/helpers';

export const DEFAULT_VALUE = '-24h@h,now';
export const DEFAULT_LATEST = 'now';
export const FORM_INPUT_TYPES = ['relative', 'date', 'dateTime'];
export const ADVANCED_INPUT_TYPES = ['relative', 'allTime', 'dateTime'];
export const SHORT_WIDTH = 278;
export const LONG_WIDTH = 395;
export const LONG_LABEL_LENGTH = 28;

export const actionPayload = (currentTime) => ({
    pageAction: 'performance.markAll',
    metadata: {
        markInformation: 'TimeRangePicker',
        currentTime,
    },
});

const toValue = ({ earliest, latest }) => `${earliest},${latest}`;

export const BaseTimeRangePicker = ({
    id,
    value,
    presets,
    options: { defaultValue = DEFAULT_VALUE },
    realTimeDisabled,
    onValueChange,
    momentTimeZone,
    isError,
    isDisabled,
    onUpdateWidth,
    parseEarliest,
    parseLatest,
    onRequestParseEarliest,
    onRequestParseLatest,
}) => {
    const { earliest, latest } = parseValue(value, defaultValue);

    const { featureFlags: { enableDragDropInputs } = {} } =
        useContext(DashboardContext);
    const telemetry = useContext(TelemetryContext);

    useEffect(() => {
        const label = createRangeLabel(earliest, latest, {
            timeRangePresets: presets,
            maxChars: 0,
        });
        if (label.length > LONG_LABEL_LENGTH) {
            onUpdateWidth(
                enableDragDropInputs ? LONG_WIDTH_DRAGGABLE : LONG_WIDTH
            );
        } else {
            onUpdateWidth(
                enableDragDropInputs ? SHORT_WIDTH_DRAGGABLE : SHORT_WIDTH
            );
        }
    }, [earliest, latest, presets, onUpdateWidth, enableDragDropInputs]);

    const handleChange = useCallback(
        (evt, changeData) => {
            telemetry.emit(actionPayload(performance.now()));
            return onValueChange(evt, toValue(changeData));
        },
        [onValueChange, telemetry]
    );

    return (
        <TimeRangeDropdown
            describedBy={id}
            presets={presets}
            onChange={handleChange}
            earliest={earliest}
            latest={latest}
            realTimeDisabled={realTimeDisabled}
            onRequestParseEarliest={onRequestParseEarliest}
            onRequestParseLatest={onRequestParseLatest}
            parseEarliest={parseEarliest}
            parseLatest={parseLatest}
            momentTimeZone={momentTimeZone}
            inline={false}
            labelMaxChars={0}
            // Not supported https://jira.splunk.com/browse/SCP-31483
            // placeholder={errorMessage || disabledMessage || null}
            disabled={isError || isDisabled}
        />
    );
};

/**
 * Transforms the value or values from the input to a set of token: value pairs
 * @param {String} value value of the TRP input
 * @param {Object} meta
 * @param {String} meta.token The token name
 * @returns {Object}
 */
BaseTimeRangePicker.valueToTokens = (value, { token }) => {
    if (!token) {
        return {};
    }
    const { earliest, latest } = parseValue(value);
    return {
        [`${token}.earliest`]: earliest,
        [`${token}.latest`]: latest,
    };
};

/**
 * Transforms a set of tokens belonging to the input to a valid input value
 * @param {Object} tokens set of submitted tokens
 * @param {String} tokenName the token name associated with the input
 * @param {String} tokenNamespace the namespace the token belongs to
 * @returns {String|null}
 */
BaseTimeRangePicker.tokensToValue = ({ tokens, tokenName, tokenNamespace }) => {
    if (!tokens || !tokenName || !tokenNamespace) {
        return null;
    }

    const earliest = get(tokens, [tokenNamespace, `${tokenName}.earliest`]);
    const latest = get(tokens, [tokenNamespace, `${tokenName}.latest`]);
    return earliest && latest ? toValue({ earliest, latest }) : null;
};

BaseTimeRangePicker.propTypes = {
    ...BaseInput.propTypes,
    realTimeDisabled: T.bool,
    // See: https://eng.sv.splunk.com/react-time-range/6.0.0/index.html#/Dropdown
    presets: T.arrayOf(T.object),
    momentTimeZone: T.object,
    parseEarliest: T.object,
    parseLatest: T.object,
    onRequestParseEarliest: T.func,
    onRequestParseLatest: T.func,
};

BaseTimeRangePicker.defaultProps = {
    ...BaseInput.defaultProps,
    presets: [],
};

const meta = {
    label: _('Time range'),
    description: _('Select a time range'),
    defaultConfig: {
        options: {
            defaultValue: '-24h@h,now',
        },
        title: _('Time Range Input Title'),
    },
    tokenPrefix: 'tr',
    includeInToolbar: true,
    icon: TimeRangeIcon,
};

const schema = {
    ...baseInputSchema,
    defaultValue: {
        description: 'Default value of the input when no value is specified',
        type: 'string',
    },
};

BaseTimeRangePicker.config = {
    meta,
    schema,
};
