/**
 * Returns a fully qualified search string by prepending the 'search'
 * command of unqualified searches.  This method deems strings as unqualified
 * if it does not start with a '|', 'search ', or 'from '
 *
 * @param {String} search - The search string.
 * @returns {String}
 * @public
 */
export default function addLeadingSearchCommand(search: string): string {
    const trimmedSearch = search.trim();
    if (trimmedSearch.startsWith('|')) {
        return search;
    }
    if (
        (trimmedSearch.startsWith('search ') && trimmedSearch.length > 7) ||
        (trimmedSearch.startsWith('from ') && trimmedSearch.length > 5)
    ) {
        return search;
    }
    return `search ${trimmedSearch}`;
}
