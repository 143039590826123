import React, { useCallback, useState } from 'react';
import styled from 'styled-components';

import type { LinkToEvent } from '@splunk/dashboard-types';
import { navigateToUrl } from '@splunk/dashboard-utils';
import Modal from '@splunk/react-ui/Modal';
import Button from '@splunk/react-ui/Button';
import Switch from '@splunk/react-ui/Switch';
import P from '@splunk/react-ui/Paragraph';
import { _ } from '@splunk/ui-utils/i18n';

const StyledSwitch = styled(Switch)`
    float: left;
`;

// To ensure a really long url doesn't force the modal to expand or scroll
const StyledUrl = styled.span`
    word-break: break-word;
`;

const redirectionStyle = {
    fontWeight: 500,
    marginBottom: 0,
};

const noteStyle = {
    fontSize: '12px',
};

const TEXT = {
    TITLE: _('Redirecting away from Splunk'),
    CONTINUE: _('Continue'),
    CANCEL: _('Cancel'),
    ALLOW_AND_DONT_ASK: _("Don't show this again"),
    ALLOW_AND_DONT_ASK_NOTE: _(
        'Applies to your current session for this URL domain and parameters.'
    ),
    REDIRECTION: _('You are being redirected away from Splunk to:'),
    NOTE: _(
        'Note that tokens embedded in a URL could contain sensitive information.'
    ),
};

export interface ProtectedUrlModalProps {
    event?: LinkToEvent['payload'];
    onRequestClose?: () => void;
    onAddUrlToAllowList?: (url: string) => void;
}

export const ProtectedUrlModal = ({
    event,
    onRequestClose,
    onAddUrlToAllowList,
    ...rest
}: ProtectedUrlModalProps) => {
    const { url, newTab } = event ?? {};
    const [isDontAskChecked, setIsDontAskChecked] = useState(false);

    const handleClose = useCallback(() => {
        if (typeof onRequestClose === 'function') {
            onRequestClose();
        }
    }, [onRequestClose]);

    const handleAllow = useCallback(() => {
        if (url) {
            if (typeof onAddUrlToAllowList === 'function' && isDontAskChecked) {
                onAddUrlToAllowList(url);
            }
            navigateToUrl(url, newTab);
        }

        handleClose();
    }, [url, newTab, isDontAskChecked, handleClose, onAddUrlToAllowList]);

    const handleDontAsk = useCallback((_e, { selected }) => {
        setIsDontAskChecked(!selected);
    }, []);

    return (
        <Modal
            onRequestClose={handleClose}
            open={!!url}
            divider="none"
            {...rest}
        >
            <Modal.Header title={TEXT.TITLE} onRequestClose={handleClose} />
            <Modal.Body>
                <StyledUrl>
                    <P style={redirectionStyle}>{TEXT.REDIRECTION}</P>
                    <P>{url}</P>
                    <P style={noteStyle}>{TEXT.NOTE}</P>
                </StyledUrl>
                {typeof onAddUrlToAllowList === 'function' && (
                    <StyledSwitch
                        selected={isDontAskChecked}
                        onClick={handleDontAsk}
                        appearance="checkbox"
                        inline
                    >
                        <P>{TEXT.ALLOW_AND_DONT_ASK}</P>
                        <P style={noteStyle}>{TEXT.ALLOW_AND_DONT_ASK_NOTE}</P>
                    </StyledSwitch>
                )}
            </Modal.Body>
            <Modal.Footer>
                <Button
                    appearance="secondary"
                    onClick={handleClose}
                    label={TEXT.CANCEL}
                />
                <Button
                    appearance="primary"
                    onClick={handleAllow}
                    label={TEXT.CONTINUE}
                />
            </Modal.Footer>
        </Modal>
    );
};

export default ProtectedUrlModal;
