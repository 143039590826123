import React, { useCallback, useMemo, useEffect } from 'react';
import T from 'prop-types';
import styled from 'styled-components';
import { _ } from '@splunk/ui-utils/i18n';
import Button from '@splunk/react-ui/Button';
import Link from '@splunk/react-ui/Link';
import Scroll from '@splunk/react-ui/Scroll';
import Close from '@splunk/react-icons/enterprise/Close';
import Input from '@splunk/react-search/components/Input';
import { useDashboardCoreApi } from '@splunk/dashboard-context';
import {
    getRiskyCommands,
    defaultSPLSyntax as searchBNF,
} from '@splunk/dashboard-utils';
import { createDocsURL } from '@splunk/splunk-utils/url';
import { get, once } from 'lodash';
import { variables, pick } from '@splunk/themes';
import { useTelemetryApi } from '@splunk/dashboard-telemetry';
import type { DataSourceEventPayload } from '@splunk/dashboard-types';
import type { DashboardContextType } from '@splunk/dashboard-context';

export const RISKY_WARNING_MESSAGE = _(
    'Some visualizations and/or inputs have not loaded since we detected potential security risks. To learn more, click on the icon in the top-right corner of the visualization and/or in the input.'
);

export const RISKY_WARNING_POPOVER_DESCRIPTION = _(
    'The search that you are about to run contains commands that might present a security risk.'
);

// Don't spam multiple times
export const emitRiskyWarningMessage = once(
    ({
        userMessage,
    }: {
        userMessage: DashboardContextType['userMessage'];
    }): void => {
        if (typeof userMessage !== 'function') {
            return;
        }
        userMessage({
            message: RISKY_WARNING_MESSAGE,
            level: 'warning',
            sender: 'RiskyWarningPopover',
        });
    }
);

const WarningContainer = styled.div`
    width: 400px;
    max-height: 375px;
    padding: 24px;
    color: ${variables.contentColorDefault};
    font-size: 14px;
    background-color: ${variables.backgroundColorPopup};
`;

const BodyContainer = styled(Scroll)`
    width: 100%;
    max-height: 280px;
    margin-bottom: 12px;
`;

const HeaderContainer = styled.div`
    display: flex;
    justify-content: space-between;
`;

const TitleContainer = styled.div`
    height: 28px;
    font-size: 17px;
    font-weight: 500;
`;

const CloseButton = styled(Button)`
    width: 32px;
    height: 32px;
    flex-grow: 0;
    margin-top: -8px;
`;

const FooterContainer = styled.div`
    text-align: right;
    margin-top: 16px;
`;

const RiskyCommandsList = styled.div`
    font-weight: 500;
    color: ${pick({
        light: variables.focusColor,
        dark: variables.linkColor,
    })};
    text-indent: 24px;
    word-break: break-word;
`;

const RiskyCommands = ({ message = '' }: { message?: string }) => (
    <RiskyCommandsList data-test="risky-command-list">
        {getRiskyCommands(message).join(', ')}
    </RiskyCommandsList>
);

const getRiskyCommandDocsUrl = () => {
    const instanceType = get(window, [
        '__splunkd_partials__',
        `/services/server/info`,
        'entry',
        0,
        'content',
        'instance_type',
    ]);

    return instanceType === 'cloud'
        ? createDocsURL('sds.cloud-risky')
        : createDocsURL('sds.ent-risky');
};

const DescriptionText = styled.div.attrs(() => ({
    'data-test': 'risky-description-text',
}))`
    padding-top: 16px;
    padding-bottom: 8px;
`;

const Description = () => (
    <DescriptionText>
        {RISKY_WARNING_POPOVER_DESCRIPTION}{' '}
        <Link to={getRiskyCommandDocsUrl()} openInNewContext>
            {_('Learn more')}
        </Link>
    </DescriptionText>
);

const WarningText = styled.div`
    font-weight: 500;
    margin-top: 8px;
    margin-bottom: 16px;
`;

const Warning = () => (
    <WarningText>
        {_(
            'If the commands seem unusual, do not run the query without further investigation or contact your Splunk administrator.'
        )}
    </WarningText>
);

const InputContainer = styled.div`
    .disabled .ace_content {
        opacity: 1;
    }
`;

const SearchView = ({ search }: { search?: string }) => (
    <InputContainer>
        <Input
            syntax={searchBNF}
            showLineNumbers={false}
            maxLines={4}
            value={search}
            enabled={false}
        />
    </InputContainer>
);

export interface RiskyWarningPopoverContentProps {
    dataSource: DataSourceEventPayload;
    componentId: string;
    onRequestClose: () => void;
    type?: string;
}

const RiskyWarningPopoverContent = ({
    dataSource,
    componentId,
    onRequestClose,
    type = 'visualization',
}: RiskyWarningPopoverContentProps) => {
    const message = dataSource?.error?.message;
    const riskySearch =
        dataSource?.meta?.completeSearchQuery || dataSource?.meta?.search;

    const dashboardApi = useDashboardCoreApi();
    const telemetry = useTelemetryApi();

    const handleClick = useCallback(() => {
        if (dashboardApi) {
            telemetry.emit({
                pageAction: 'riskyWarningDialog.runSearch',
                source: 'riskyWarningDialog',
                metadata: { type },
            });

            if (type === 'input') {
                dashboardApi.refreshInput(componentId, {
                    checkRiskyCommand: false,
                });
            } else {
                dashboardApi.refreshVisualization(componentId, {
                    checkRiskyCommand: false,
                });
            }
            onRequestClose();
        }
    }, [dashboardApi, componentId, type, telemetry, onRequestClose]);

    const handleRequestClose = useCallback(() => {
        telemetry.emit({
            pageAction: 'riskyWarningDialog.cancel',
            source: 'riskyWarningDialog',
            metadata: { type },
        });
        onRequestClose();
    }, [telemetry, type, onRequestClose]);

    useEffect(() => {
        telemetry.emit({
            pageAction: 'riskyWarningDialog.open',
            source: 'riskyWarningDialog',
            metadata: { type },
        });
    }, [telemetry, type]);

    const Header = useMemo(
        () => (
            <HeaderContainer>
                <TitleContainer>
                    {type === 'input'
                        ? _('This input has a potential security risk')
                        : _('This visualization has a potential security risk')}
                </TitleContainer>
                <CloseButton
                    appearance="pill"
                    prepend
                    icon={<Close />}
                    onClick={handleRequestClose}
                    data-test="risky-warning-close-button"
                />
            </HeaderContainer>
        ),
        [type, handleRequestClose]
    );

    const Body = useMemo(
        () => (
            <BodyContainer stopScrollPropagation>
                <Description />
                <RiskyCommands message={message} />
                <Warning />
                <SearchView search={riskySearch} />
            </BodyContainer>
        ),
        [message, riskySearch]
    );

    const Footer = useMemo(
        () => (
            <FooterContainer>
                <Button
                    label={_('Run Query')}
                    appearance="primary"
                    onClick={handleClick}
                    data-test="risky-warning-run-button"
                />
                <Button
                    label={_('Cancel')}
                    appearance="secondary"
                    onClick={handleRequestClose}
                    data-test="risky-warning-cancel-button"
                />
            </FooterContainer>
        ),
        [handleClick, handleRequestClose]
    );

    return (
        <WarningContainer>
            {Header}
            {Body}
            {_('Do you want to run the search string?')}
            {Footer}
        </WarningContainer>
    );
};

RiskyWarningPopoverContent.propTypes = {
    dataSource: T.object,
    onRequestClose: T.func,
    componentId: T.string.isRequired,
    type: T.oneOf(['visualization', 'input']),
};

export default React.memo(RiskyWarningPopoverContent);
