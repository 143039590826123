import type { VisualizationDefinition } from '@splunk/dashboard-types';
import config from '@splunk/visualizations/Pie.config';
import type { EncodingConfig } from '../encoding';
import { encodingToDynamicOptionsDSL } from '../encoding';
import { migrateChartingOptions, otherValueReplacements } from './splunk-charting';

const validOptions = {
    ...config.optionsSchema,
};

const migratePieChartLabelAndPercent = (options: Record<string, unknown>): Record<string, string> => {
    // if neither chart.showLabels or chart.showPercent is specified in viz.pie,
    // splunk.pie use the default labelDisplay value
    if (!('chart.showLabels' in options) && !('chart.showPercent' in options)) {
        return {};
    }

    // if only chart.showPercent is specified in viz.pie
    if (!('chart.showLabels' in options) && 'chart.showPercent' in options) {
        return { labelDisplay: options['chart.showPercent'] ? 'valuesAndPercentage' : 'values' };
    }

    // if only chart.showLabels is specified in viz.pie
    if ('chart.showLabels' in options && !('chart.showPercent' in options)) {
        return { labelDisplay: options['chart.showLabels'] ? 'values' : 'off' };
    }

    // if both chart.showLabels and chart.showPercent are specified in viz.pie
    const labelOption = {
        labelDisplay: 'values',
    };
    if (options['chart.showLabels'] && options['chart.showPercent']) {
        labelOption.labelDisplay = 'valuesAndPercentage';
    }
    if (!options['chart.showLabels']) {
        labelOption.labelDisplay = 'off';
    }

    return labelOption;
};

// retain the pie position when converting viz.pie to splunk.pie - SCP-48658
const migrateDonutChart = (options: Record<string, unknown>): Record<string, string> => {
    if (options.hasDonutHole) {
        if (options['legend.placement'] === 'none') {
            return { labelDisplay: 'off' };
        }
        return { labelDisplay: 'values' };
    }
    return {};
};

const migrateFieldColors = (options: Record<string, any>): Record<string, any> => {
    if ('fieldColors' in options) {
        return {
            seriesColorsByField: otherValueReplacements.seriesColorsByField(options.fieldColors),
        };
    }
    return {};
};

const migrateVizToSplunkPie = (vizDefinition: VisualizationDefinition): VisualizationDefinition => {
    const { encoding = {}, options = {}, ...otherDefinitionParts } = vizDefinition;
    const migratedDefinition: VisualizationDefinition = {
        ...otherDefinitionParts,
        type: 'splunk.pie',
        options: {},
        context: {},
    };
    // translate encoding to dynamic options equivalent
    const { options: dataOptions, context: dataContext } = encodingToDynamicOptionsDSL(
        encoding as Record<string, EncodingConfig>
    );

    const migratedOptions = {
        ...migratePieChartLabelAndPercent(options),
        ...migrateChartingOptions(options),
        ...migrateFieldColors(options),
        ...migrateDonutChart(options),
        ...dataOptions,
    };
    // remove invalid options
    Object.keys(migratedOptions).forEach(key => {
        if (!validOptions[key]) {
            delete migratedOptions[key];
        }
    });
    migratedDefinition.options = migratedOptions;
    migratedDefinition.context = dataContext;

    return migratedDefinition;
};

export { migrateVizToSplunkPie };
