/* eslint-disable react/no-unused-prop-types,react/prefer-stateless-function, no-unused-vars */
import { Component } from 'react';
import T from 'prop-types';
import { noop } from '@splunk/dashboard-utils';

class BaseLayout extends Component {
    static propTypes = {
        /**
         * dashboard mode
         */
        mode: T.string,
        /**
         * whether gridlines should be displayed in edit mode
         */
        showGrid: T.bool,
        /**
         * layout formatting options
         */
        options: T.object,
        /**
         * layout structure
         */
        layoutStructure: T.arrayOf(T.any),
        /**
         * container width in pixel
         */
        containerWidth: T.number,
        /**
         * container height in pixel
         */
        containerHeight: T.number,
        /**
         * list of selected items
         */
        selectedItems: T.arrayOf(
            T.shape({ id: T.string, type: T.oneOf(['block', 'line', 'input']) })
        ),
        /**
         * layout item render prop
         */
        renderLayoutItem: T.func,
        /**
         * callback when items are selected
         */
        onLayoutItemsSelect: T.func,
        /**
         * callback to trigger event
         */
        onEventTrigger: T.func,
        /**
         * callback when layout structure changed
         */
        onLayoutStructureChange: T.func,
        /**
         * layoutApi ref
         */
        layoutApiRef: T.func,
    };

    static defaultProps = {
        mode: 'view',
        options: {},
        showGrid: true,
        layoutStructure: [],
        selectedItems: [],
        renderLayoutItem: noop,
        onEventTrigger: noop,
        onLayoutStructureChange: noop,
        onLayoutItemsSelect: noop,
        layoutApiRef: noop,
    };

    render() {
        return null;
    }
}

export default BaseLayout;
