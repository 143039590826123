import type { VisualizationDefinition } from '@splunk/dashboard-types';
import {
    migrateVizToSplunkArea,
    migrateVizToSplunkBar,
    migrateVizToSplunkBubble,
    migrateVizToSplunkChoroplethSvg,
    migrateVizToSplunkColumn,
    migrateVizToSplunkFillerGauge,
    migrateVizToSplunkLine,
    migrateVizToSplunkImage,
    migrateVizToSplunkMarkdown,
    migrateVizToSplunkMarkerGauge,
    migrateVizToSplunkPie,
    migrateVizToSplunkRectangle,
    migrateVizToSplunkSingleValue,
    migrateVizToSplunkSingleValueIcon,
    migrateVizToSplunkSingleValueRadial,
    migrateVizToSplunkScatter,
    migrateVizToSplunkTable,
    migrateVizTextToSplunkMarkdown,
    migrateVizToSplunkEllipse,
    migrateVizToSplunkPunchcard,
    migrateVizToSplunkMapChoropleth,
} from './visualizations';

const supportedMigrations = {
    'viz.area': {
        'splunk.area': migrateVizToSplunkArea,
    },
    'viz.bar': {
        'splunk.bar': migrateVizToSplunkBar,
    },
    'viz.bubble': {
        'splunk.bubble': migrateVizToSplunkBubble,
    },
    'viz.column': {
        'splunk.column': migrateVizToSplunkColumn,
    },
    'viz.choropleth.svg': {
        'splunk.choropleth.svg': migrateVizToSplunkChoroplethSvg,
    },
    'viz.geojson.us': {
        'splunk.map': migrateVizToSplunkMapChoropleth,
    },
    'viz.geojson.world': {
        'splunk.map': migrateVizToSplunkMapChoropleth,
    },
    'viz.ellipse': {
        'splunk.ellipse': migrateVizToSplunkEllipse,
    },
    'viz.fillergauge': {
        'splunk.fillergauge': migrateVizToSplunkFillerGauge,
    },
    'viz.line': {
        'splunk.line': migrateVizToSplunkLine,
    },
    'viz.img': {
        'splunk.image': migrateVizToSplunkImage,
    },
    'viz.markdown': {
        'splunk.markdown': migrateVizToSplunkMarkdown,
    },
    'viz.markergauge': {
        'splunk.markergauge': migrateVizToSplunkMarkerGauge,
    },
    'viz.pie': {
        'splunk.pie': migrateVizToSplunkPie,
    },
    'viz.rectangle': {
        'splunk.rectangle': migrateVizToSplunkRectangle,
    },
    'viz.singlevalue': {
        'splunk.singlevalue': migrateVizToSplunkSingleValue,
    },
    'viz.singlevalueicon': {
        'splunk.singlevalueicon': migrateVizToSplunkSingleValueIcon,
    },
    'viz.singlevalueradial': {
        'splunk.singlevalueradial': migrateVizToSplunkSingleValueRadial,
    },
    'viz.scatter': {
        'splunk.scatter': migrateVizToSplunkScatter,
    },
    'viz.table': {
        'splunk.table': migrateVizToSplunkTable,
    },
    'viz.text': {
        'splunk.markdown': migrateVizTextToSplunkMarkdown,
    },
    'viz.punchcard': {
        'splunk.punchcard': migrateVizToSplunkPunchcard,
    },
};

const inferToType = (typeFromDefinition: string): string => {
    if (typeFromDefinition === 'viz.img') {
        return 'splunk.image';
    }
    if (typeFromDefinition === 'viz.text') {
        return 'splunk.markdown';
    }

    if (typeFromDefinition === 'viz.geojson.us' || typeFromDefinition === 'viz.geojson.world') {
        return 'splunk.map';
    }
    return typeFromDefinition.replace(/^viz\./, 'splunk.');
};

interface MigrationParams {
    definition: VisualizationDefinition;
    toType?: string;
}

const migrate = ({ definition, toType: toTypeFromParams }: MigrationParams): VisualizationDefinition => {
    const toType = toTypeFromParams ?? inferToType(definition.type);
    if (!supportedMigrations?.[definition?.type]?.[toType]) {
        throw new Error('Migration currently not supported');
    }
    return supportedMigrations[definition.type][toType](definition);
};

interface CanMigrateParams {
    fromType: string;
    toType: string;
}

const canMigrate = ({ fromType, toType }: CanMigrateParams): boolean => {
    if (supportedMigrations?.[fromType]?.[toType]) {
        return true;
    }
    return false;
};

export { migrate, canMigrate, inferToType };
