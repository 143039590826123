import { COLOR_OR_TOKEN_PATTERN, getPattern } from '@splunk/visualizations-shared/schemaUtils';

export default {
    backgroundColor: {
        description:
            'Specify the color to use for the chart background using a Hex code. The default for light mode is "#ffffff". The default for dark mode is "#212527". The default for prisma dark mode is "#0b0c0e".',
        pattern: COLOR_OR_TOKEN_PATTERN,
        type: 'string',
    },
    count: {
        description: 'Specify the maximum number of rows to display.',
        type: 'number',
        default: 20,
    },
    rowNumbers: {
        description: 'Specify whether to display the row numbers.',
        type: 'boolean',
        default: false,
    },
    showHeader: {
        description: 'Specify whether to display the table header.',
        type: 'boolean',
        default: true,
    },
    headerBackgroundColor: {
        description: `Specify the table header background color using a Hex code. The default for light mode is "#e1e6eb". The default for dark mode is "#171d21". The default for prisma dark mode is "#272a2f".`,
        pattern: COLOR_OR_TOKEN_PATTERN,
        type: 'string',
    },
    headerTextColor: {
        description: `Specify the table header text color using a Hex code. The default for light mode is "#3C444D". The default for dark mode is "#FFFFFF". The default for prisma dark mode is "#f5f5f5".`,
        pattern: COLOR_OR_TOKEN_PATTERN,
        type: 'string',
    },
    rowBackgroundColorEven: {
        description: `Specify the background color for even table rows using a Hex code. The default for light mode is "#F2F4F5". The default for dark mode is "#0D1012". The default for prisma dark mode is "#0b0c0e".`,
        pattern: COLOR_OR_TOKEN_PATTERN,
        type: 'string',
    },
    rowBackgroundColorOdd: {
        description: `Specify the background color for odd table rows using a Hex code. The default for light mode is "#FFFFFF". The default for dark mode is "#000000". The default for prisma dark mode is "#111215".`,
        pattern: COLOR_OR_TOKEN_PATTERN,
        type: 'string',
    },
    rowTextColorEven: {
        description: `Specify the text color for even table rows using a Hex code. The default for light mode is "#006EAA". The default for dark mode is "#00A4FD". The default for prisma dark mode is "#acacad".`,
        pattern: COLOR_OR_TOKEN_PATTERN,
        type: 'string',
    },
    rowTextColorOdd: {
        description: `Specify the text color for odd table rows using a Hex code. The default for light mode is "#006EAA". The default for dark mode is "#00A4FD". The default for prisma dark mode is "#acacad".`,
        pattern: COLOR_OR_TOKEN_PATTERN,
        type: 'string',
    },
    numberPrecision: {
        default: 0,
        maximum: 20,
        minimum: 0,
        description:
            'Specify the number of decimal places to display. For example, to display 3 decimal places, use a value of 3. The maximum value is 20.',
        type: 'number',
    },
    useThousandSeparators: {
        default: false,
        description: 'Specify whether numeric values use commas as thousandths separaters.',
        type: 'boolean',
    },
    unit: {
        description:
            'Specify a label to show next to the cell value. The unit text should be brief. For example, an abbreviation such as MB, or a symbol such as $, can be used.',
        type: 'string',
    },
    unitPosition: {
        default: 'after',
        description: 'Specify whether the unit label should appear before or after the cell value.',
        type: 'string',
        pattern: getPattern(['before', 'after']),
    },
    dataOverlayMode: {
        default: 'none',
        description: 'Specify which type of overlay to display. Overlays only apply to numerical columns.',
        type: 'string',
        pattern: getPattern(['heatmap', 'highlow', 'none']),
    },
};
