import React from 'react';
import SVG, {
    type SVGPropsOptionalViewBox,
} from '@splunk/react-icons/SVGEnterprise';

/* eslint-disable max-len */
export default function Laptop(
    props: SVGPropsOptionalViewBox
): ReturnType<typeof SVG> {
    return (
        <SVG viewBox="0 0 62 44" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path d="m5 4c0-2.20914 1.79086-4 4-4h44c2.2091 0 4 1.79086 4 4v34h-52zm3.91016.07861h44.00004v29.99999h-44.00004zm49.08984 39.92139h-54c-2.20914 0-4-1.7909-4-4h23 16 23c0 2.2091-1.7909 4-4 4z" />
        </SVG>
    );
}
