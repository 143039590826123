import type {
    SetTokenEvent,
    Token,
    Tokens,
    SetTokenOptions,
    TokenRecord,
    EventCanHandle,
    EventHandle,
    EHRequired,
} from '@splunk/dashboard-types';
import { DEFAULT_TOKEN_NAMESPACE, deprecated } from '@splunk/dashboard-utils';

import { isEventAllowed } from './utils/events';

type Payload = Record<string, unknown>;

class SetToken implements EHRequired<{ payload: Payload }, SetTokenEvent[]> {
    private options: SetTokenOptions;

    private events: string[];

    private tokens: Tokens;

    /**
     * A configuration object is passed to the handler
     * @constructor
     * @param {Object} options
     */
    constructor(options: SetTokenOptions) {
        this.options = options;
        this.events =
            Array.isArray(options.events) && options.events.length > 0
                ? options.events
                : ['any'];
        const { key, token } = options;
        if (key && token) {
            deprecated(
                'Using a single token option is deprecated. Please use the tokens option. `tokens: [{ key, token }]`'
            );
            this.tokens = [
                {
                    key,
                    token,
                },
            ];
        } else {
            this.tokens = options.tokens ?? [];
        }
    }

    /**
     * Determines if the custom event handler is designed to convert the event
     * @method canHandle
     * @param {VisualizationEvent} event
     * @returns {Boolean} true if the event handler will convert the event
     */
    canHandle: EventCanHandle<{ payload: Payload }> = (event) => {
        return (
            isEventAllowed(event) &&
            this.areTokensValid(event.payload) &&
            (this.events.includes('any') || this.events.includes(event.type))
        );
    };

    // a token is valid if there is a non undefined value in its payload[key] or value
    areTokensValid(payload: Payload): boolean {
        if (this.tokens.length < 1) {
            return false;
        }

        return Object.values(this.getTokenValuesFromPayload(payload)).some(
            (val) => val !== undefined
        );
    }

    getTokenValuesFromPayload(payload: Payload): TokenRecord {
        const tokenValuesMap: TokenRecord = {};
        this.tokens.forEach(({ token, key, value }: Token) => {
            if (key !== undefined && payload[key] !== undefined) {
                tokenValuesMap[token] = payload[key] as string;
            } else if (value !== undefined) {
                // If there is no payload value but there is a static value use it
                tokenValuesMap[token] = value;
            }
        });

        return tokenValuesMap;
    }

    /**
     * Convert the event payload into a list of actionable tasks
     * @method handle
     * @param {VisualizationEvent} event
     * @returns {EventAction[]} List of action(s)
     */
    handle: EventHandle<Payload, SetTokenEvent[]> = (event) => {
        return Promise.resolve([
            {
                type: 'setToken',
                payload: {
                    namespace:
                        this.options.tokenNamespace || DEFAULT_TOKEN_NAMESPACE,
                    tokens: this.getTokenValuesFromPayload(event.payload),
                    submit: true,
                },
            },
        ]);
    };
}

export default SetToken;
