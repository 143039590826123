import React from 'react';
import SVG, {
    type SVGPropsOptionalViewBox,
} from '@splunk/react-icons/SVGEnterprise';

/* eslint-disable max-len */
export default function Desktop(
    props: SVGPropsOptionalViewBox
): ReturnType<typeof SVG> {
    return (
        <SVG viewBox="0 0 60 54" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path d="m0 3c0-1.65685 1.34315-3 3-3h54c1.6569 0 3 1.34315 3 3v38c0 1.6569-1.3431 3-3 3h-54c-1.65685 0-3-1.3431-3-3zm3.91016 1.04492h52.00004v31.99998h-52.00004zm20.03704 41.95508h12.0429v5.8146c0 .044.0354.0799.0794.0806l6.9577.1085c.5451.0085.9826.4529.9826.9981 0 .5513-.4469.9982-.9982.9982h-26.0133c-.5514 0-.9983-.4469-.9983-.9983 0-.5452.4374-.9896.9826-.9981l6.8859-.1084c.0436-.0007.0787-.0362.0787-.0799z" />
        </SVG>
    );
}
