/* eslint-env node */
import { configureStore } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
import changeMonitor from '../middleware/changeMonitor';
import batchedDispatch from '../middleware/batchedDispatch';
import clonedItemLayering from '../middleware/clonedItemLayering';
import blurOnSelectionChange from '../middleware/blurOnSelectedItemsChange';
import { rootReducer } from '../reducers';
import createSaga from '../sagas';

const linkReduxDevTools = process.env.NODE_ENV !== 'production';

export default function createStore({
    initialState = {},
    middlewareContext = {},
    featureFlags = {},
}) {
    const rootSaga = createSaga(middlewareContext, featureFlags);

    const sagaMiddleware = createSagaMiddleware();
    // Middleware are applied from right-to-left.
    // clonedItemLayering can mutate payloads handled by the editing saga so it
    // has to be listed later in the middleware array than sagaMiddleware
    const middleware = [
        blurOnSelectionChange,
        changeMonitor,
        sagaMiddleware,
        clonedItemLayering,
    ];

    // If the smart sources FF is enabled then add middleware to batch any
    // smartSources/set dispatch payloads and reduce selector recalculation
    if (featureFlags?.enableSmartSourceDS) {
        middleware.push(batchedDispatch);
    }

    const store = configureStore({
        reducer: rootReducer,
        preloadedState: initialState,
        middleware,
        devTools: linkReduxDevTools,
    });
    sagaMiddleware.run(rootSaga);
    return store;
}
