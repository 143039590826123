import styled from 'styled-components';

import SUIControlGroup from '@splunk/react-ui/ControlGroup';
import { variables } from '@splunk/themes';

export const ControlGroup = styled(SUIControlGroup)`
    margin-top: 0;
    margin-bottom: ${variables.spacingSmall};
    width: 100%;

    &:first-child {
        margin-top: 0;
    }

    &:last-child {
        margin-bottom: 0;
    }
`;
