import get from 'lodash/get';
import BaseApi from '@splunk/dashboard-visualizations/common/BaseVisualizationApi';
import { isRemoteImage } from '@splunk/visualizations-shared/RemoteImage';
/**
 * Image Api
 */
class ImageApi extends BaseApi {
    constructor(viz) {
        super();
        this.viz = viz;
    }

    /**
     * return snapshot of that includes inlined image
     */
    snapshot() {
        const srcFromOptions = get(this.viz.props, ['options', 'src'], '');
        const isRemote = isRemoteImage(srcFromOptions);
        return {
            options: {
                ...this.viz.props.options,
                src:
                    isRemote && this.viz.remoteImage
                        ? get(this.viz.remoteImage, ['state', 'imageDataURI'], '')
                        : srcFromOptions,
            },
        };
    }
}

export default ImageApi;
