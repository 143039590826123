import React, { useState } from 'react';
import styled from 'styled-components';
import { variables } from '@splunk/themes';
import { _ } from '@splunk/ui-utils/i18n';
import moment from '@splunk/moment';
import useInterval from './hooks/useInterval';

export interface LastUpdateTimeProps {
    lastUpdated?: string;
    isRealTimeSearch?: boolean;
    status?: string;
}

const Container = styled.div.attrs(() => ({
    'data-test': 'last-update-time',
}))`
    padding-left: 20px;
    padding-right: 10px;
    white-space: nowrap;
    font-size: 12px;
    color: ${variables.contentColorMuted};
`;

const RelativeTime = styled.span`
    font-weight: bold;
`;

const prefix = _('As of');

const getPrefix = ({
    status,
    lastUpdated,
    isRealTimeSearch = false,
}: LastUpdateTimeProps): string => {
    if (isRealTimeSearch) {
        return _('Updating live');
    }

    switch (status) {
        case 'failed':
            return _('Failed to update');
        case 'running':
        case 'finalizing':
            return lastUpdated ? prefix : _('Updating');
        default:
            return lastUpdated ? prefix : '';
    }
};

const convertTime = (
    isRealTimeSearch: boolean,
    lastUpdated?: string
): string | null =>
    !isRealTimeSearch && lastUpdated ? moment(lastUpdated).fromNow() : null;

const LastUpdateTime = (props: LastUpdateTimeProps): JSX.Element | null => {
    const { isRealTimeSearch = false, lastUpdated, status } = props;
    const [update, triggerUpdate] = useState(false);

    const relativeTime = convertTime(isRealTimeSearch, lastUpdated);
    const timePrefix = getPrefix(props);

    useInterval(
        () => {
            // update every one minute to check the update time if no new search job comes
            return triggerUpdate(!update);
        },
        status ? 60000 : null
    );

    if (!status) {
        return null;
    }

    if (!timePrefix && !relativeTime) {
        return null;
    }

    return (
        <Container>
            {timePrefix}
            {timePrefix === prefix ? (
                <RelativeTime> {relativeTime}</RelativeTime>
            ) : null}
        </Container>
    );
};

export default LastUpdateTime;
