import * as T from 'prop-types';
import { cloneDeep, noop } from 'lodash';
import { DataSourceMeta, RequestParams } from '../interfaces/DataSource';

export interface OnChangeParams {
    page?: number;
    count?: number;
}
export interface PaginatorParams {
    isPaging?: boolean;
    current?: number;
    totalPages?: number;
    onChange?: (e: MouseEvent, x: OnChangeParams) => void;
}

export const PaginatorParamsPropTypes = T.shape({
    isPaging: T.bool,
    current: T.number,
    totalPages: T.number,
    onChange: T.func,
});

export const getPaginationParams = ({
    requestParams,
    meta,
    onRequestParamsChange,
}: DataSourceMeta): PaginatorParams => {
    const totalCount = (meta && meta.totalCount) || 0;
    const offset = (requestParams && requestParams.offset) || 0;
    const count = requestParams && requestParams.count;
    const totalPages = typeof count === 'number' && count > 0 ? Math.ceil(totalCount / count) : 0;
    const current = typeof count === 'number' && count > 0 ? Math.floor(offset / count) + 1 : 0;
    const isPaging = totalPages > 1 && typeof onRequestParamsChange === 'function';

    const onChangeHandler = (e, { page, count: countArg }: OnChangeParams): void => {
        const newParams: RequestParams = cloneDeep(requestParams);

        if (typeof countArg === 'number') {
            newParams.count = countArg;
            newParams.offset = 0;
        }

        const pageCount = typeof newParams.count === 'number' ? newParams.count : 10;
        if (typeof page === 'number' && page > 0) {
            newParams.count = pageCount;
            newParams.offset = (page - 1) * pageCount;
        }

        onRequestParamsChange(newParams);
    };

    return {
        isPaging,
        current,
        totalPages,
        onChange: typeof onRequestParamsChange === 'function' ? onChangeHandler : noop,
    };
};
