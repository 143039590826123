import type { DataSourceEventPayload } from '@splunk/dashboard-types';

export const getIconStatus = (
    dataSource?: DataSourceEventPayload
): 'ok' | 'warning' | 'error' | null => {
    if (!dataSource) {
        return null;
    }

    const status = dataSource?.error
        ? 'failed'
        : dataSource?.meta?.status || 'queued';

    if (dataSource?.meta?.serverLog?.[0]?.level === 'WARN') {
        return 'warning';
    }

    // NOTE: these status are not in the order they would be executed on the server
    switch (status) {
        case 'queued':
        case 'parsing':
        case 'running':
        case 'finalizing':
            return 'ok';
        case 'paused':
            return 'warning';
        case 'failed':
            return 'error';
        case 'done':
            // TODO: totalCount > 10000 = results are truncated
            return dataSource.meta?.totalCount === 0 ? 'warning' : null;
        case 'canceled':
        default:
            return null;
    }
};
