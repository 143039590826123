import { get, mapValues } from 'lodash';
import { hashString } from '@splunk/dashboard-utils';
import type { PlainObject, FilterDefinition } from '../FilterTypes';

const MAX_ENCODING_RECURSION_LEVEL = 2;

type FilterEncodingHelper = (
    encoding: PlainObject,
    recursionLevel?: number
) => PlainObject;

/**
 * This is copied from previous implementation.
 */
const filterEncodingHelper: FilterEncodingHelper = (
    encoding,
    recursionLevel = 0
) => {
    // no valid encoding exists where the root value is an array
    if (Array.isArray(encoding) && recursionLevel === 0) {
        return encoding;
    }
    if (encoding && typeof encoding === 'object') {
        return mapValues(
            encoding,
            (val: string | PlainObject[] | PlainObject) => {
                if (recursionLevel < MAX_ENCODING_RECURSION_LEVEL) {
                    if (typeof val === 'string') {
                        return true;
                    }
                    if (Array.isArray(val)) {
                        return val.length;
                    }
                    if (val && typeof val === 'object') {
                        return filterEncodingHelper(val, recursionLevel + 1);
                    }
                }
                return val;
            }
        );
    }
    return encoding;
};

type FilterEncodingOnItem = (item: PlainObject) => PlainObject;

type FilterMatchValues = (encoding: PlainObject) => PlainObject;

type MatchValueItem = { match: string; value: string };

/**
 * anonymize field value while keeping the color settings.
 */
const filterMatchValues: FilterMatchValues = (encoding) => {
    if (get(encoding, ['majorColor', 'format', 'type']) !== 'matchvalue') {
        return encoding;
    }

    const encodingCopy = JSON.parse(JSON.stringify(encoding));

    encodingCopy.majorColor.format.matches =
        encodingCopy.majorColor.format.matches.map(
            ({ match, value }: MatchValueItem) => ({
                match: hashString(match, 'field_'),
                value,
            })
        );

    return encodingCopy;
};

const filterEncodingOnItem: FilterEncodingOnItem = (item) =>
    mapValues(item, (v: PlainObject, k: string) =>
        k === 'encoding' ? filterMatchValues(filterEncodingHelper(v)) : v
    );

const filterEncodings: FilterDefinition = (definition) =>
    mapValues(definition, (value: PlainObject, key) => {
        if (['visualizations', 'inputs'].includes(key)) {
            return mapValues(value, filterEncodingOnItem);
        }

        if (key === 'defaults') {
            return mapValues(value, filterEncodingOnItem);
        }

        return value;
    });

export default filterEncodings;
