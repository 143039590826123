import memoizeOne from 'memoize-one';
import React, {
    useEffect,
    createContext,
    useMemo,
    useContext,
    useRef,
} from 'react';
import T from 'prop-types';

import { console } from '@splunk/dashboard-utils';
import { GeoContextProvider } from '@splunk/visualization-context/GeoContext';
import { ImageContextProvider } from '@splunk/visualization-context/ImageContext';
import { IconContextProvider } from '@splunk/visualization-context/IconContext';
import { FeatureFlagContextProvider } from '@splunk/visualization-context/FeatureFlagContext';
import { TimezoneContextProvider } from '@splunk/visualization-context/TimezoneContext';
import { MessageContextProvider } from '@splunk/visualization-context/MessageContext';
import {
    MapContextProvider,
    testTileConfig,
} from '@splunk/visualization-context/MapContext';
import { EventsContextProvider } from '@splunk/visualization-context/EventsContext';
import {
    type MetricsCollector,
    TelemetryContextProvider,
} from '@splunk/dashboard-telemetry';
import {
    StateProvider,
    type StateProviderProps,
} from '@splunk/dashboard-state';
import type {
    DashboardPlugin,
    PresetMap,
    InitialDashboardContextProps,
} from '@splunk/dashboard-types';
import { SnapshotDataSource } from '@splunk/datasources';
import { DEFAULT_DEFINITION } from '@splunk/dashboard-definition';
import RegistryContextProvider from './RegistryContext';

import KeyboardListener from './KeyboardListener';
import defaultFeatureFlags, { type FeatureFlags } from './FeatureFlags';
import type BaseContentExportClient from './BaseContentExportClient';
import DashboardContextErrorBoundary from './DashboardContextErrorBoundary';
import { DragAndDropContextProvider } from './DragAndDropContext';
import { LayoutLayersContextProvider } from './LayoutLayersContext';
import Preset from './preset/Preset';
import { updatePreset } from './utils/presetUtils';
import { SidebarContextProvider } from './SidebarContext';
import { SearchContextProvider } from './SearchContext';
import {
    DashboardPluginContextProvider,
    createCollector,
    createPlugin,
    type PluginContextType,
} from './DashboardPluginContext';
import {
    DataSourceContextProvider,
    defaultDataSourceContextValue,
    type DataSourceContextValue,
} from './DataSourceContext';
import { OnChangeCallbacks, type OnChangeCallbacksProps } from './onChange';
import { DashboardCoreApiContextWrapper } from './DashboardCoreApiContext';

export type UserMessageArgs = {
    message: string;
    level: string;
    sender?: string;
    stackTrace?: string;
};

export type UserMessageFn = (props: UserMessageArgs) => void;

// we could allow functions in the future, but for now I think string is good enough to cover most use cases.
interface DocumentationLinks {
    learnMoreSetTokens?: string;
    availableDrilldownTokens?: string;
    migrations?: string;
    cspMessageLink?: string;
    smartSources?: string;
    chainSearches?: string;
    [key: string]: string | undefined;
}

const DocumentationLinksContext = createContext<DocumentationLinks>({});

// can't import these types from @splunk/visualization-context/EventsContext
interface Action {
    label: string;
    eventType: string;
    eventtypeValues?: string[];
}

type FieldAction = Action;

type EventAction = Action & {
    fieldFilters?: string[];
};
interface EventsContextInterface {
    eventActions: EventAction[];
    fieldActions: Record<string, FieldAction[]>;
    isSplunkWebAvailable: boolean;
}

export interface DashboardContextType {
    featureFlags?: FeatureFlags;
    contentExportClient?: Partial<BaseContentExportClient>;
    dataSourceContext?: DataSourceContextValue;
    geoRegistry?: unknown;
    iconRegistry?: unknown;
    imageRegistry?: unknown;
    keyboardListener?: KeyboardListener;
    logger?: unknown;
    userMessage?: UserMessageFn;
}

// A tuple containing an element constructor and optional element props
type ConstructableTuple<P = Record<string, unknown>> = [
    React.JSXElementConstructor<P>,
    Omit<P, 'children'>?
];

// An array of ConstructableTuples with a default prop type
type UntypedTupleArray = ConstructableTuple[];

// Provider tuple can be:
// 1. [React.Provider<P>, React.ProviderProps<P>]
// 2. [(props: P) => JSX.Element, P]
// 3. [() => JSX.Element]
type ContextTuple<C> = C extends React.JSXElementConstructor<infer P>
    ? ConstructableTuple<P>
    : never;

// Shim for removing the need to explicitly provide generic types for every tuple declaration
const tuple = <C,>(arg0: C, arg1?: ContextTuple<C>[1]): ContextTuple<C> =>
    [arg0, arg1] as unknown as ContextTuple<C>;

/**
 * A reducer to be used in reduceRight to transform a list of ContextTuples into a nested set of context providers
 * @param {JSX.Element} prev A built up set of nested contexts and/or children
 * @param {ConstructableTuple} next A tuple containing a Context constructor, and an optional set of props
 * @returns JSX.Element
 */
const contextComposer = (
    prev: JSX.Element,
    next: ConstructableTuple
): JSX.Element => {
    const [Context, props = {}] = next;
    return React.createElement(Context, props, prev);
};

export const DashboardContext = createContext<DashboardContextType>({});

const EMPTY_FEATURE_FLAGS = Object.freeze({});
const createFeatureFlags = memoizeOne(
    (flags: FeatureFlags = EMPTY_FEATURE_FLAGS): FeatureFlags => ({
        ...defaultFeatureFlags,
        ...flags,
    }),
    (newArgs, oldArgs) =>
        newArgs === oldArgs ||
        JSON.stringify(newArgs) === JSON.stringify(oldArgs)
);

export const defaultMapTileConfig = { defaultTileConfig: testTileConfig };

export const defaultEventsConfig = {
    eventActions: [],
    fieldActions: {},
    isSplunkWebAvailable: false,
};

const defaultDashboardPlugin = Object.freeze<DashboardPlugin>({});

/**
 * dashboard context provider that provides contextual objects over the react render tree
 * @param {*} param01
 *
 */
export interface DashboardContextProviderProps
    extends DashboardContextType,
        InitialDashboardContextProps {
    children: React.ReactElement;
    metricsCollectors?: MetricsCollector | MetricsCollector[];
    timezone?: {
        ianaTimezone?: string;
        serializedTimezone?: string;
        utcOffset?: number;
    };
    documentationLinks?: DocumentationLinks;
    mapTileConfig?: Record<string, unknown>;
    eventsConfig?: EventsContextInterface;
    preset: PresetMap;
    dashboardPlugin?: DashboardPlugin;
    onTokenBindingChange?: OnChangeCallbacksProps['onTokenBindingChange'];
    onDefinitionChange?: OnChangeCallbacksProps['onDefinitionChange'];
    onModeChange?: OnChangeCallbacksProps['onModeChange'];
    onItemsSelect?: OnChangeCallbacksProps['onItemsSelect'];
}

const emptyDocsLinks = {};

const ContextProvider = ({
    children,
    contentExportClient,
    dataSourceContext = defaultDataSourceContextValue,
    featureFlags,
    geoRegistry,
    iconRegistry,
    imageRegistry,
    keyboardListener,
    logger,
    userMessage,
    metricsCollectors,
    timezone,
    documentationLinks: docLinks = emptyDocsLinks,
    mapTileConfig,
    eventsConfig,
    preset: presetDefinition,
    initialDefinition,
    initialTokenBinding,
    initialReadOnlyTokenNamespaces,
    initialMode = 'view',
    initialSelectedItems,
    initialShowGrid,
    dashboardPlugin,
    onTokenBindingChange,
    onDefinitionChange,
    onModeChange,
    onItemsSelect,
}: DashboardContextProviderProps): React.ReactElement => {
    const initialDefinitionRef = useRef(initialDefinition);
    const initialTokenBindingRef = useRef(initialTokenBinding);
    const initialReadOnlyTokenNamespacesRef = useRef(
        initialReadOnlyTokenNamespaces
    );
    const initialModeRef = useRef(initialMode);
    const initialSelectedItemsRef = useRef(initialSelectedItems);
    const initialShowGridRef = useRef(initialShowGrid);

    // only call setup() when component mount, and only call teardown() when component unmount
    useEffect(() => {
        if (!keyboardListener) {
            return undefined;
        }
        keyboardListener.setup();

        return () => keyboardListener.teardown();
    }, [keyboardListener]);

    const coalescedEventsConfig = useMemo(
        () => eventsConfig ?? defaultEventsConfig,
        [eventsConfig]
    );

    const coalescedMapTileConfig = useMemo(
        () => mapTileConfig ?? defaultMapTileConfig,
        [mapTileConfig]
    );

    const featureFlagList = createFeatureFlags(featureFlags);

    const vizMessages = useMemo(
        () => ({ cspMessageLink: docLinks.cspMessageLink }),
        [docLinks]
    );

    const preset = useMemo(
        () =>
            new Preset({
                presetDefinition: updatePreset(presetDefinition),
                featureFlags: featureFlagList,
                SnapshotDataSource,
            }),
        [presetDefinition, featureFlagList]
    );

    const value = useMemo(
        () => ({
            contentExportClient,
            featureFlags: featureFlagList,
            geoRegistry,
            iconRegistry,
            imageRegistry,
            keyboardListener,
            logger,
            userMessage,
        }),
        [
            contentExportClient,
            featureFlagList,
            geoRegistry,
            iconRegistry,
            imageRegistry,
            keyboardListener,
            logger,
            userMessage,
        ]
    );

    const collector = useMemo(
        () =>
            createCollector({
                initialDefinition: initialDefinitionRef.current,
            }),
        []
    );

    const pluginRef = useRef<DashboardPlugin>(defaultDashboardPlugin);
    pluginRef.current = dashboardPlugin ?? defaultDashboardPlugin;
    const wrappedPlugin = useMemo(
        () =>
            createPlugin({
                plugin: pluginRef,
                collector,
            }),
        [collector]
    );

    const dashboardPluginContextValue = useMemo<PluginContextType>(
        () => [wrappedPlugin, collector],
        [wrappedPlugin, collector]
    );

    const stateProviderValue = useMemo<Omit<StateProviderProps, 'children'>>(
        () => ({
            preset,
            featureFlags: featureFlagList,
            initialDefinition: initialDefinitionRef.current,
            initialTokenBinding: initialTokenBindingRef.current,
            initialReadOnlyTokenNamespaces:
                initialReadOnlyTokenNamespacesRef.current,
            initialMode: initialModeRef.current,
            initialSelectedItems: initialSelectedItemsRef.current,
            initialShowGrid: initialShowGridRef.current,
            dashboardPlugin: wrappedPlugin,
        }),
        [preset, featureFlagList, wrappedPlugin]
    );

    const contexts = useMemo(
        () => [
            // Highest in the tree
            tuple(DashboardContext.Provider, { value }),
            tuple(DataSourceContextProvider, { value: dataSourceContext }),
            tuple(TelemetryContextProvider, { metricsCollectors }),
            tuple(DashboardPluginContextProvider, {
                value: dashboardPluginContextValue,
            }),
            tuple(FeatureFlagContextProvider, { value: featureFlagList }),
            tuple(GeoContextProvider, { value: geoRegistry }),
            tuple(ImageContextProvider, { value: imageRegistry }),
            tuple(IconContextProvider, { value: iconRegistry }),
            tuple(TimezoneContextProvider, { value: timezone }),
            tuple(MapContextProvider, { value: coalescedMapTileConfig }),
            tuple(EventsContextProvider, { value: coalescedEventsConfig }),
            tuple(DocumentationLinksContext.Provider, {
                value: docLinks,
            }),
            tuple(MessageContextProvider, { value: vizMessages }),
            tuple(StateProvider, stateProviderValue),
            tuple(LayoutLayersContextProvider),
            tuple(RegistryContextProvider, { dataSourceContext, preset }),
            // DashboardCoreApiContextWrapper must be rendered below StateProvider and RegistryContextProvider
            tuple(DashboardCoreApiContextWrapper),
            // ** Parent of DragAndDropContextProvider must have type `children: React.ReactFragment`
            tuple(DragAndDropContextProvider),
            tuple(SidebarContextProvider),
            tuple(SearchContextProvider),
            // Lowest in the tree
        ],
        [
            value,
            dataSourceContext,
            docLinks,
            featureFlagList,
            geoRegistry,
            iconRegistry,
            imageRegistry,
            coalescedMapTileConfig,
            coalescedEventsConfig,
            metricsCollectors,
            preset,
            stateProviderValue,
            timezone,
            vizMessages,
            dashboardPluginContextValue,
        ]
    );

    return (contexts as UntypedTupleArray).reduceRight(
        contextComposer,
        <OnChangeCallbacks
            initialTokenBinding={initialTokenBindingRef.current}
            onTokenBindingChange={onTokenBindingChange}
            onDefinitionChange={onDefinitionChange}
            onModeChange={onModeChange}
            onItemsSelect={onItemsSelect}
        >
            {children}
        </OnChangeCallbacks>
    );
};

export const DashboardContextProvider = (
    props: DashboardContextProviderProps
) => (
    <DashboardContextErrorBoundary>
        <ContextProvider {...props} />
    </DashboardContextErrorBoundary>
);

DashboardContextProvider.propTypes = {
    /**
     * an icon registry instance
     */
    iconRegistry: T.object,
    /**
     * an image registry instance
     */
    imageRegistry: T.object,
    /**
     * a geo registry instance
     */
    geoRegistry: T.object,
    /**
     * global keyboard listener
     */
    keyboardListener: T.instanceOf(KeyboardListener),
    /**
     * global datasource context object
     */
    dataSourceContext: T.object,
    /**
     * Client implementation of API workflow with Content Export service
     */
    contentExportClient: T.object,
    /**
     * Collection of feature flags to override/introduce
     */
    featureFlags: T.object,
    /**
     * children node
     */
    children: T.any,
    /**
     * logger for error, warning, and info level events
     */
    logger: T.object,
    /**
     * Callback for messages to display to user.
     *
     * Callback function parameters can expect this object interface as a first parameter:
     * {
     *     level: 'error' | 'warning' | 'info'
     *     message: String
     *     sender?: String
     *     stackTrace?: String
     * }
     *
     * For example, the callback could expect this object to contain these parameters:
     * {
     *     level: 'error',
     *     message: 'Example message to deliver to user.',
     *     sender: 'GridLayout', // optional parameter.
     *     stackTrace: 'Example stack trace' // optional parameter.
     * }
     */
    userMessage: T.func,
    /**
     * list of user defined metrics collectors that send telemetry data to their backend API
     */
    metricsCollectors: T.oneOfType([T.object, T.array]),
    /**
     * Pass timezone data in at least one format to have time data formatted in charts
     */
    timezone: T.shape({
        ianaTimezone: T.string,
        serializedTimezone: T.string,
        utcOffset: T.number,
    }),
    /**
     * Initial dashboard definition
     */
    initialDefinition: T.object,
    /**
     * Initial token binding
     */
    initialTokenBinding: T.object,
    /**
     * Initial mode
     */
    initialMode: T.oneOf(['view', 'edit']),
    /**
     * Items to be initially selected
     */
    initialSelectedItems: T.array,
    /**
     * Whether grid lines should be displayed in edit mode
     */
    initialShowGrid: T.bool,
    /**
     * A callback for when the tokenBinding change
     */
    onTokenBindingChange: T.func,
    /**
     * A callback for when the definition changes
     */
    onDefinitionChange: T.func,
    /**
     * A callback for when the mode changes
     */
    onModeChange: T.func,
    /**
     * A callback for when the selected items change
     */
    onItemsSelect: T.func,
    /**
     * Documentation links
     */
    documentationLinks: T.object,
    /**
     * preset
     */
    preset: T.object,
};

DashboardContextProvider.defaultProps = {
    keyboardListener: new KeyboardListener(),
    userMessage: ({ message }: { message: string }) => console.log(message),
    timezone: {},
    initialDefinition: DEFAULT_DEFINITION,
    preset: {},
};

export const DashboardContextConsumer = DashboardContext.Consumer;

export const useFeatureFlags = (): FeatureFlags => {
    const { featureFlags = defaultFeatureFlags } = useContext(DashboardContext);
    return featureFlags;
};

export const useImageRegistry = (): DashboardContextType['imageRegistry'] => {
    const { imageRegistry } = useContext(DashboardContext);
    return imageRegistry;
};

export const useIconRegistry = (): DashboardContextType['iconRegistry'] => {
    const { iconRegistry } = useContext(DashboardContext);
    return iconRegistry;
};

export const useUserMessageAPI = (): UserMessageFn | undefined => {
    const { userMessage } = useContext(DashboardContext);
    return userMessage;
};

export const useDocumentationLinks = (): DocumentationLinks =>
    useContext(DocumentationLinksContext);

export const useKeyboardListener = (): KeyboardListener => {
    const { keyboardListener } = useContext(DashboardContext);

    return keyboardListener as KeyboardListener;
};

export const useLogger = () => {
    const { logger } = useContext(DashboardContext);
    return logger;
};

export default DashboardContext;
