import * as React from 'react';
import { _ } from '@splunk/ui-utils/i18n';
import FeatureFlagContext from '@splunk/visualization-context/FeatureFlagContext';
import { LAYOUT_TYPE } from '../utils/layoutUtils';

/* eslint-disable react-hooks/rules-of-hooks */
export const TrellisEditorConfig = [
    [
        {
            label: _('Trellis layout'),
            option: 'splitByLayout',
            editor: 'editor.toggle',
            showEditor: (): boolean => {
                const featureFlagContext = React.useContext<FeatureFlagContext>(FeatureFlagContext);
                const isTrellisFeatureFlagOn = Boolean(
                    featureFlagContext && featureFlagContext.visualizations_enableTrellis
                );
                return isTrellisFeatureFlagOn;
            },
        },
    ],
    [
        {
            label: _('Split by'),
            option: 'trellisSplitBy',
            editor: 'editor.trellisSplitBy',
            editorProps: {
                dataSourceKey: 'primary',
            },
            showEditor: ({ options }): boolean => {
                const featureFlagContext = React.useContext<FeatureFlagContext>(FeatureFlagContext);
                const isTrellisFeatureFlagOn = Boolean(
                    featureFlagContext && featureFlagContext.visualizations_enableTrellis
                );
                return isTrellisFeatureFlagOn && options.splitByLayout === LAYOUT_TYPE.Trellis;
            },
        },
    ],
    [
        {
            label: _('Number of columns'),
            option: 'trellisColumns',
            editor: 'editor.number',
            editorProps: {
                min: 1,
            },
            placeholder: _('Auto'),
            showEditor: ({ options }): boolean => {
                const featureFlagContext = React.useContext<FeatureFlagContext>(FeatureFlagContext);
                const isTrellisFeatureFlagOn = Boolean(
                    featureFlagContext && featureFlagContext.visualizations_enableTrellis
                );
                return isTrellisFeatureFlagOn && options.splitByLayout === LAYOUT_TYPE.Trellis;
            },
        },
    ],
    [
        {
            label: _('Min column width'),
            option: 'trellisMinColumnWidth',
            editor: 'editor.number',
            editorProps: {
                min: 1,
            },
            showEditor: ({ options }): boolean => {
                const featureFlagContext = React.useContext<FeatureFlagContext>(FeatureFlagContext);
                const isTrellisFeatureFlagOn = Boolean(
                    featureFlagContext && featureFlagContext.visualizations_enableTrellis
                );
                return isTrellisFeatureFlagOn && options.splitByLayout === LAYOUT_TYPE.Trellis;
            },
        },
        {
            label: _('Row height'),
            option: 'trellisRowHeight',
            editor: 'editor.number',
            editorProps: {
                min: 1,
            },
            showEditor: ({ options }): boolean => {
                const featureFlagContext = React.useContext<FeatureFlagContext>(FeatureFlagContext);
                const isTrellisFeatureFlagOn = Boolean(
                    featureFlagContext && featureFlagContext.visualizations_enableTrellis
                );
                return isTrellisFeatureFlagOn && options.splitByLayout === LAYOUT_TYPE.Trellis;
            },
        },
    ],
    [
        {
            label: _('Max number of components per page'),
            option: 'trellisPageCount',
            editor: 'editor.number',
            editorProps: {
                min: 1,
            },
            showEditor: ({ options }): boolean => {
                const featureFlagContext = React.useContext<FeatureFlagContext>(FeatureFlagContext);
                const isTrellisFeatureFlagOn = Boolean(
                    featureFlagContext && featureFlagContext.visualizations_enableTrellis
                );
                return isTrellisFeatureFlagOn && options.splitByLayout === LAYOUT_TYPE.Trellis;
            },
        },
    ],
];

export const TrellisBackgroundColorEditor = {
    label: _('Trellis background'),
    option: 'trellisBackgroundColor',
    editor: 'editor.color',
    showEditor: ({ options }): boolean => {
        const featureFlagContext = React.useContext<FeatureFlagContext>(FeatureFlagContext);
        const isTrellisFeatureFlagOn = Boolean(
            featureFlagContext && featureFlagContext.visualizations_enableTrellis
        );
        return isTrellisFeatureFlagOn && options.splitByLayout === LAYOUT_TYPE.Trellis;
    },
};
