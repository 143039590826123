/* eslint-disable class-methods-use-this */
import { DataPrimitiveFormatter } from '../Formatter';
import { DataPoint } from '../DataPoint';
import { DataType, isDataPrimitive } from '../DataPrimitive';
import { DataSeries } from '../DataSeries';
import { DataFrame } from '../DataFrame';
import { Helper } from './Helper';
import { IDimension } from '../Dimensions';
import EncodingExecutor from '../EncodingExecutor';

/**
 * Formatter that prepends a DataFrame, DataSeries, or DataPoint to a pre-existing DataFrame or DataSeries with compatible types.
 *
 * If prepending a DataFrame to another DataFrame, the DataFrames must have the same number of DataSeries.
 * For example, a DataFrame with 3 series such as `[[1, 2], [3, 4], [5, 6]]` cannot be prepended to a DataFrame with 2 series such as `[[7, 8], [9, 10]]`.
 *
 * Only the same DataPrimitive type or a DataPrimitive's composite DataPrimitives
 * (e.g. DataSeries or DataPoint for DataFrame, or a DataPoint for a DataSeries) can be prepended to the pre-existing DataPrimitive.
 *
 * ```js
 * <SampleViz
 *     context={{
 *         staticUser: 'All users',
 *         staticId: '*',
 *         additionalUsers: ['Andrew', 'Chanelle'],
 *         idsAsNumbers: [1, 2],
 *         users: '> primary | seriesByName("users") | prepend(staticUser)', // returns ['All users', 'Maurine', 'Jennings']
 *         ids: '> primary | seriesByName("ids") | prepend(staticId)' // returns ['*', '1', '2', '3']
 *         pathologicalIds: '> primary | seriesByName("idsAsNumbers") | prepend(staticId)' // throws an error, since `staticId` is a string, while the pre-existing series is composed of numbers
 *     }}
 *     options={{
 *         consolidatedUsers: '> primary | seriesByName("users") | prepend(additionalUsers)', // returns ['Andrew', 'Chanelle', 'Maurine', 'Jennings']
 *         // returns {
 *         //     columns: [['Maurine', 'Jennings', 'Maurine', 'Jennings'], ['1', '2', '1', '2']]
 *         //     fields: [{ name: 'users' }, { name: 'ids' }]
 *         // }
 *         prependingFrames: '> primary | prepend(primary)'
 *     }}
 *     dataSources={{
 *         primary: {
 *             data: {
 *                 columns: [['Maurine', 'Jennings'], ['1', '2']],
 *                 fields: [{ name: 'users' }, { name: 'ids' }]
 *             }
 *         }
 *     }}
 * />
 * ```
 */
export class Prepend implements DataPrimitiveFormatter<DataType, DataType> {
    private prependMe: IDimension;

    constructor(d: any) {
        // needs to be able to prepend DataPrimitive and raw
        this.prependMe = isDataPrimitive(d) ? d : Helper.dataPrimitiveFromRaw(EncodingExecutor.rawTree(d));
    }

    format(
        subject: DataPoint<DataType> | DataSeries<DataType> | DataFrame<DataType>
    ): DataPoint<DataType> | DataSeries<DataType> | DataFrame<DataType> {
        if (DataFrame.isDataFrame(subject)) {
            return this.prependToFrame(subject);
        }
        if (DataSeries.isDataSeries(subject)) {
            return this.prependToSeries(subject);
        }
        throw new Error(`Can only prepend to DataFrame or DataSeries`);
    }

    prependToFrame(subject: DataFrame<DataType>) {
        if (DataFrame.isDataFrame(this.prependMe)) {
            return this.prependFrameToFrame(subject);
        }
        if (DataSeries.isDataSeries(this.prependMe)) {
            return this.prependASeriesToEachSeriesOfFrame(subject);
        }
        if (DataPoint.isDataPoint(this.prependMe)) {
            return this.prependAPointToEachSeriesOfFrame(subject);
        }
        throw new Error(`'prepend' formatter only accepts DataFrame, DataSeries, or DataPoint as argument`);
    }

    private prependToSeries(subject: DataSeries<DataType>) {
        if (DataFrame.isDataFrame(this.prependMe)) {
            throw new Error('cannot prepend a DataFrame to a DataSeries');
        } else if (DataSeries.isDataSeries(this.prependMe)) {
            return this.prependSeriesToSeries(subject, this.prependMe);
        } else if (DataPoint.isDataPoint(this.prependMe)) {
            return this.prependPointToSeries(subject);
        }
        throw new Error(`'prepend' to series formatter only DataSeries, or DataPoint as argument`);
    }

    private prependAPointToEachSeriesOfFrame(dp: DataFrame<DataType>): DataFrame {
        return new DataFrame(dp.series.map(s => this.prependPointToSeries(s)));
    }

    private prependASeriesToEachSeriesOfFrame(frame: DataFrame<DataType>): DataFrame {
        return new DataFrame(
            frame.series.map(s => this.prependSeriesToSeries(s, this.prependMe as DataSeries))
        );
    }

    private prependFrameToFrame(frame: DataFrame<DataType>): DataFrame {
        const numSeries1 = (this.prependMe as DataFrame).series.length;
        const numSeries2 = frame.series.length;
        if (numSeries1 !== numSeries2) {
            throw new Error(
                `can't prepend a frame with ${numSeries1} columns to a field with ${numSeries2} columns`
            );
        }
        return new DataFrame(
            frame.series.map((s, i) =>
                this.prependSeriesToSeries(s, (this.prependMe as DataFrame).seriesByIndex(i))
            )
        );
    }

    private prependSeriesToSeries(series: DataSeries, prependMe: DataSeries): DataSeries {
        const { field } = series; // we will use this to insure the prepended series has the same field
        const type1 = series.points.length > 0 ? series.points[0].getValue().type : undefined;
        const type2 = prependMe.points.length > 0 ? prependMe.points[0].getValue().type : undefined;
        if (type1 && type2 && type1 !== type2) {
            throw new Error(`cannot prepend ${type2} to ${type1}`);
        }
        return new DataSeries(
            prependMe.points.map(p => new DataPoint(field, p.getValue())).concat(series.points)
        );
    }

    private prependPointToSeries(s: DataSeries): DataSeries {
        const { field } = s;
        const typedValue = s.firstPoint().getValue(); // we must use the existing series type for the prepended point
        const type1 = typedValue.type;
        const typedValue2 = (this.prependMe as DataPoint).getValue();
        const type2 = typedValue2.type;
        if (type1 !== type2) {
            throw new Error(`cannot prepend point of type ${type2} to series of type ${type1}`);
        }
        const newPoint = new DataPoint(field, typedValue2);
        return new DataSeries<DataType>([newPoint, ...s.points]);
    }
}
