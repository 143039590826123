import { get } from 'lodash';
import { getDataSourceStateInfo, DataSourceState } from '@splunk/visualizations-shared/dataSourceUtils';

import withDashboardViz, { DashboardVizOpts } from '../common/withDashboardViz';
import { DataSource } from '../common/interfaces/DataSource';
import { EMPTY_DATASOURCE } from './docs/constants';
import config from './config';

import PureEvents from './PureEvents/PureEvents';
import { replaceTokens } from './EventUtils';

/**
 * @method showPlaceHolderIcon
 * @param {Object} dataSources
 * @returns {Boolean} return true if loading, error, non existent state or without columns in dataSources
 */
export const showPlaceHolderIcon = dataSources => {
    const columns = get(dataSources, 'primary.data.columns', undefined);
    const noColumns = columns === undefined;
    const dataSourceState = getDataSourceStateInfo(dataSources);

    return (
        dataSourceState === DataSourceState.ERROR ||
        dataSourceState === DataSourceState.LOADING ||
        dataSourceState === DataSourceState.NONEXISTENT ||
        noColumns
    );
};

export const computeVizProps = ({ ...props }) => {
    // for specific changes to view type (List -> Raw, etc.), can create handling function here that calls onOptionsChange

    // defaults to an empty datasource if one isn't provided, which shows the No Results Found UI:
    const dataSources = props?.dataSources ?? EMPTY_DATASOURCE;

    return { ...props, dataSources };
};

const Events = withDashboardViz({
    ReactViz: PureEvents,
    vizConfig: config,
    computeVizProps,
    disableEditModeCover: true,
    useIconPlaceholder: (dataSources: { [name: string]: DataSource }) => showPlaceHolderIcon(dataSources),
} as DashboardVizOpts);
const { themes } = config;
export default Events;
export { themes, config, PureEvents, replaceTokens };
