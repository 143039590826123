import type { VisualizationDefinition } from '@splunk/dashboard-types';
import config from '@splunk/visualizations/Line.config';
import { migrateBaseChartingVizDefinition } from './splunk-charting';

const validOptions = {
    ...config.optionsSchema,
};

// old default configurations should be retained in migrated configuration
// except for markerDisplay which migrates to the new default 'off'
const defaultChanges = {
    yAxisAbbreviation: 'off',
    y2AxisAbbreviation: 'off',
    showRoundedY2AxisLabels: false,
    legendTruncation: 'ellipsisMiddle',
    showY2MajorGridLines: true,
};

const migrateVizToSplunkLine = (vizDefinition: VisualizationDefinition): VisualizationDefinition =>
    migrateBaseChartingVizDefinition({
        defaultChanges,
        validOptions,
        vizDefinition,
        vizType: 'splunk.line',
    });

export { migrateVizToSplunkLine };
