export const SCP_CATEGORICAL = [
    '#A9F5E7',
    '#A870EF',
    '#1E6271',
    '#45D4BA',
    '#602CA1',
    '#9AE6F7',
    '#6CD0F0',
    '#2F8AA7',
    '#422879',
    '#F29BAC',
    '#E85B79',
    '#C6335F',
    '#FDAF93',
    '#FB865C',
    '#E9643A',
    '#7EEFDA',
    '#26AA92',
    '#207865',
    '#C093F9',
    '#8747DA',
    '#4EB2D2',
    '#912344',
    '#AD3F20',
    '#D44C20',
    '#FFC34E',
    '#95651D',
    '#C0891E',
    '#ECAE11',
    '#FCD9A4',
    '#78062A',
];
export const ENTERPRISE_CATEGORICAL = ['#1E93C6', '#F2B827', '#D6563C', '#6A5C9E', '#31A35F'];
export const CATEGORICAL = [
    '#7B56DB',
    '#0089CF',
    '#00CDAF',
    '#DD9900',
    '#FF677B',
    '#CB2196',
    '#813193',
    '#0051B5',
    '#008C80',
    '#99B100',
    '#FFA476',
    '#FF6ACE',
    '#AE8CFF',
    '#00689D',
    '#00490A',
    '#465D00',
    '#9D6300',
    '#F6540B',
    '#FF969E',
    '#E47BFE',
];
export const VIZ_CATEGORICAL = [
    '#7B56DB',
    '#009CEB',
    '#00CDAF',
    '#DD9900',
    '#FF677B',
    '#CB2196',
    '#813193',
    '#0051B5',
    '#008C80',
    '#99B100',
    '#FFA476',
    '#FF6ACE',
    '#AE8CFF',
    '#00689D',
    '#00490A',
    '#465D00',
    '#9D6300',
    '#F6540B',
    '#FF969E',
    '#E47BFE',
];
