import { _ } from '@splunk/ui-utils/i18n';
import { isDynamicOption } from '@splunk/visualizations-shared/configUtils';

const StaticGaugeColorEditor = {
    label: _('Gauge'),
    option: 'gaugeColor',
    editor: 'editor.color',
    showEditor: ({ options }) => !isDynamicOption(options.gaugeColor),
};

export default StaticGaugeColorEditor;
