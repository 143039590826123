import { each, bind, isEqual, isEmpty } from 'lodash';
import type { Store } from '@reduxjs/toolkit';
import { _ } from '@splunk/ui-utils/i18n';
import {
    isBaseDataSource,
    isChainDataSource,
    createBaseChainModel,
    getChainDataSources,
    getBaseDataSourceId,
} from '@splunk/datasource-utils';
import { MAX_CHAIN_LENGTH } from '@splunk/dashboard-utils';
import { triggerEvent } from '@splunk/dashboard-state';
import type {
    BroadcastEventCallback,
    BroadcastEventArgs,
    DataSourceEventPayload,
    ExtendableDataSourceDefinition,
    PresetUtility,
    RootDataSourcesDefinition,
} from '@splunk/dashboard-types';

import { computeDSHash } from '../utils';
import DataSourceController from './DataSourceController';
import RiskyCommandController from './RiskyCommandController';

type DataSourceContext = Record<string, unknown>;

type DataSourceEventArgs = BroadcastEventArgs<DataSourceEventPayload>;

type DataSourcesSnapshot = Record<
    DataSourceController['id'],
    Record<string, unknown>
>;

/**
 * A module that create reuseable DataSource controller
 */
class DataSourceRegistry {
    definition: Record<string, ExtendableDataSourceDefinition>;

    preset: PresetUtility;

    store: Store;

    dataSourceContext?: DataSourceContext;

    controllers: Record<number, DataSourceController>;

    riskyCommandController: RiskyCommandController;

    broadcastCallback: BroadcastEventCallback;

    /**
     *
     * @param {Object} preset dashboard preset
     * @param {Object} dataSourceContext DataSource context
     * @returns {DataSourceRegistry}
     */
    constructor({
        preset,
        dataSourceContext,
    }: {
        preset: PresetUtility;
        dataSourceContext?: DataSourceContext;
    }) {
        this.preset = preset;
        this.dataSourceContext = dataSourceContext;
        this.definition = {};
        this.controllers = {};
        this.store = null as unknown as Store;

        this.riskyCommandController = new RiskyCommandController();

        // The default behavior is that the event handler
        // triggers the event and dispatches to the store
        this.broadcastCallback = ({
            eventType,
            targetId,
            payload,
        }: DataSourceEventArgs) => {
            const dispatchPayload: Record<string, unknown> = {
                ...payload,
                tokenNamespace: targetId
                    ? this.preset?.getDataSourceName?.(
                          this.definition?.[targetId]
                      )
                    : undefined,
            };

            // If enableSmartSources option exists on the DS definition (or in
            // the original definition of an errored snapshot) then include the
            // flag in the dispatch payload so it can be validated in the handler
            // NOTE: The FF check should be on the handler and not needed here
            const definition = this.definition[targetId ?? ''];
            if (
                definition?.options?.enableSmartSources ||
                (
                    definition?.options?.meta as {
                        originalDefinition?: ExtendableDataSourceDefinition;
                    }
                )?.originalDefinition?.options?.enableSmartSources
            ) {
                dispatchPayload.enableSmartSources = true;
            }

            this.store?.dispatch(
                triggerEvent(targetId, eventType, dispatchPayload)
            );
        };
    }

    setDataSourceContext(ctx: DataSourceContext) {
        this.dataSourceContext = ctx;
    }

    setPreset(preset: PresetUtility) {
        this.preset = preset;
    }

    subscribeToStore(store: Store): void {
        this.store = store;
    }

    updateDefinition({
        definition,
    }: {
        definition: RootDataSourcesDefinition;
    }): void {
        const lastDefinition = this.definition;
        this.definition = definition as Record<
            string,
            ExtendableDataSourceDefinition
        >;
        // The following code detects if any base/chain data source without any UI component binding has been updated in the new definition.
        // If there are such data sources, the following code triggers the allocate() function to create new data source and remap into base chain tree
        each(lastDefinition, (dsDef, dsId) => {
            const newDataSourceDef = this.definition?.[dsId];
            const chainDataSources = this.getActiveChainControllers(dsId);
            const oldDataSourceController =
                this.controllers[computeDSHash(dsDef)];
            if (
                !isEqual(dsDef, newDataSourceDef) && // definition is changed
                (isBaseDataSource(dsId, this.definition) ||
                    isChainDataSource(dsId, this.definition)) && // this data source is used in base chain
                oldDataSourceController && // this data source has been created before
                oldDataSourceController.getSubscriptions().size === // this data source has only data source subscriptions
                    oldDataSourceController.getDataSourceSubscriptions().size &&
                Object.keys(chainDataSources).length > 0 // and this data source has chained data source which means this data source is used by chain data source(s)
            ) {
                // call allocate() to create new data source and remap into current base chain
                this.allocate({
                    dataSourceId: dsId,
                });
            }
        });
    }

    /**
     * Return a DataSource controller that matches the definition
     * @param {Object} dataSourceId DataSource id
     * @returns {DataSourceController} DataSource controller instance
     */
    allocate = ({
        dataSourceId,
    }: {
        dataSourceId: string;
    }): DataSourceController => {
        if (!dataSourceId) {
            throw Error(
                'dataSourceId is required to allocate DataSourceController'
            );
        }

        // allocate a DataSource controller that matches the definition.
        // an existing controller will be returned if definition matches,
        // otherwise a new one will be created and return.

        // we want to resolve tokens and apply the defaults, thus it is necessary to use the `selectDataSources` instead of the raw dataSourceDef from the arguments
        const dataSourceDef = this.definition[dataSourceId];

        if (isEmpty(dataSourceDef)) {
            throw Error(
                `cannot find dataSourceDef for dataSourceId: ${dataSourceId}`
            );
        }

        const key = computeDSHash(dataSourceDef);
        if (this.controllers[key]) {
            this.controllers[key].addAdditionalDataSourceId(dataSourceId);
        } else {
            const newController = new DataSourceController({
                id: dataSourceId,
                dataSourceDef,
                dataSourceContext: this.dataSourceContext,
                preset: this.preset,
                riskyCommandController: this.riskyCommandController,
            });

            newController.onTeardown(
                bind(this.handleDataSourceTeardown, this, key)
            );
            newController.onDataSourceEvent(this.broadcastDataSourceEvent);

            if (isBaseDataSource(dataSourceId, this.definition)) {
                const baseChainModel = createBaseChainModel(
                    dataSourceId,
                    this.definition
                );

                newController.setBaseChainModel(baseChainModel);

                const chainControllers =
                    this.getActiveChainControllers(dataSourceId);
                each(chainControllers, (controller) => {
                    controller.setParent(newController);
                    controller.subscribeToParentDataSourceController();
                });
            } else if (isChainDataSource(dataSourceId, this.definition)) {
                this.dataSourceValidation(dataSourceId);
                const parentDataSourceId = dataSourceDef.options
                    ?.extend as string;
                const parentDataSourceDef = this.definition[parentDataSourceId];

                if (!parentDataSourceId || !parentDataSourceDef) {
                    throw new Error(
                        _(
                            `Cannot find parent dataSource: ${parentDataSourceId}`
                        )
                    );
                }

                let parentController =
                    this.getDataSourceController(parentDataSourceId);

                // get chain dataSource controller
                const chainControllers =
                    this.getActiveChainControllers(dataSourceId);

                if (parentController) {
                    const subs = parentController.getSubscriptions();
                    // Special case:
                    // if parent has only one data source subscription, and this subscription is the current newController
                    //
                    if (subs.size === 1 && subs.has(dataSourceId)) {
                        parentController.setHandleSubscriptionCancelOverride();
                    }
                } else {
                    parentController = this.allocate({
                        dataSourceId: parentDataSourceId,
                    });
                }

                // set parent
                newController.setParent(parentController);

                // map chain dataSources, and this triggers newController to execute setup
                each(chainControllers, (controller) => {
                    controller.setParent(newController);
                    controller.subscribeToParentDataSourceController();
                });

                const baseDataSourceId = getBaseDataSourceId(
                    dataSourceId,
                    this.definition
                );
                if (baseDataSourceId) {
                    const baseDataSourceController =
                        this.getDataSourceController(baseDataSourceId);
                    if (baseDataSourceController) {
                        baseDataSourceController.setBaseChainModel(
                            createBaseChainModel(
                                baseDataSourceId,
                                this.definition
                            )
                        );
                    }
                }
            }
            this.controllers[key] = newController;
        }

        return this.controllers[key];
    };

    /**
     * Return a DataSource controller given the DataSource definition
     * @param {String} dataSourceId DataSource id
     * @returns {DataSourceController} DataSource controller instance
     */
    getDataSourceController = (
        dataSourceId: string
    ): DataSourceController | undefined =>
        this.controllers[computeDSHash(this.definition[dataSourceId])];

    /**
     * Remove DataSource controller from registry
     * @param {String} key cached key
     */
    handleDataSourceTeardown = (key: number): void => {
        delete this.controllers[key];
    };

    /**
     * Pause all DataSource controllers from registry
     */
    pauseDataSources = (): void => {
        each(this.controllers, (controller) => {
            controller.pause();
        });
    };

    /**
     * Broadcast DataSource event via broadcastCallback
     * @param {Object} event DataSource event
     */
    broadcastDataSourceEvent = (event: BroadcastEventArgs): void => {
        if (this.broadcastCallback) {
            this.broadcastCallback(event);
        }
    };

    /**
     * register event broadcast callback
     * @param {Function} callback broadcast callback
     */
    onEventBroadcast = (callback: BroadcastEventCallback): void => {
        this.broadcastCallback = callback;
    };

    /**
     * capture the snapshot of all DataSources
     */
    snapshot(): DataSourcesSnapshot {
        const state: DataSourcesSnapshot = {};
        each(this.controllers, (controller) => {
            const subs = controller.getSubscriptions();
            const additionalDataSourceIds =
                controller.getAdditionalDataSourceIds();
            const subState: Record<string, unknown> = {};
            subs.forEach((sub) => {
                subState[sub.consumerId] = sub.getLastState();
            });
            state[controller.id] = subState;
            additionalDataSourceIds.forEach((dsId) => {
                state[dsId] = subState;
            });
        });
        return state;
    }

    /**
     * Get chain data source controllers that are subscribed to the given parentDataSourceId
     * @param {String} parentDataSourceId
     */
    getActiveChainControllers(
        parentDataSourceId: string
    ): Record<string, DataSourceController> {
        if (!parentDataSourceId) {
            return {};
        }

        const chainDataSourceDefs = getChainDataSources(
            parentDataSourceId,
            this.definition
        );

        const result: Record<string, DataSourceController> = {};

        each(chainDataSourceDefs, (chainDataSourceDef, chainDataSourceId) => {
            const chainDataSourceController =
                this.getDataSourceController(chainDataSourceId);
            if (chainDataSourceController) {
                result[chainDataSourceId] = chainDataSourceController;
            }
        });

        return result;
    }

    dataSourceValidation(dataSourceId: string): void {
        if (!isChainDataSource(dataSourceId, this.definition)) {
            return;
        }

        let references = 0;
        let currentId = dataSourceId;
        let currentDS = this.definition[currentId];
        const visitedDS = [];
        const extend = currentDS?.options?.extend ?? '';

        if (!this.definition[extend]) {
            throw new Error(
                _(
                    `Chain data source ${dataSourceId} must have valid extend property`
                )
            );
        }

        while (currentDS != null) {
            if (visitedDS.indexOf(currentId) > -1) {
                throw new Error(
                    _(
                        `Data source ${currentId} creates an invalid circular reference; check parent data sources referenced by extend property.`
                    )
                );
            }
            if (references > MAX_CHAIN_LENGTH) {
                throw new Error(
                    _(
                        `${references} chained data sources exceeds the maximum of ${MAX_CHAIN_LENGTH}`
                    )
                );
            }
            if (isBaseDataSource(currentId, this.definition)) {
                return;
            }
            visitedDS.push(currentId);
            references += 1;
            currentId = currentDS?.options?.extend ?? '';
            currentDS = this.definition[currentId];
        }
    }

    getDataSourceControllers(): Record<string, DataSourceController> {
        return this.controllers;
    }

    teardown(): void {
        const controllers = Object.values(this.controllers);
        controllers.forEach((controller) => controller.teardown());
    }
}

export default DataSourceRegistry;
