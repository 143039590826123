import React, { useCallback, useMemo, useState } from 'react';
import styled from 'styled-components';
import {
    DndContext,
    KeyboardSensor,
    MouseSensor,
    useSensor,
    useSensors,
    type Modifiers,
} from '@dnd-kit/core';
import { sortableKeyboardCoordinates } from '@dnd-kit/sortable';
import { SnapCenterXToCursor } from './dndModifiers/SnapCenterXToCursor';

/**
 * @private
 * Modifiers for @dnd-kit functionality.
 */
export const dndModifiers: Modifiers = [SnapCenterXToCursor];

/**
 * @dnd-kit/accessibility content root
 */
const DndAccessibility = styled.div`
    width: 0px;
    height: 0px;
    // @dnd-kit/accessibility is injected by the DndContext and has a left margin
    // of -1px which messes with visual tests but has no impact when rendered in browser
    transform: translateX(1px);
`;

/**
 * @private
 * Exported for testing
 */
export interface DragAndDropContextProps {
    dndAccessibilityPortal: HTMLDivElement | null;
}

/**
 * @private
 * Exported for testing
 */
export const DragAndDropContext = ({
    children,
    dndAccessibilityPortal,
}: React.PropsWithChildren<DragAndDropContextProps>) => {
    const sensors = useSensors(
        useSensor(MouseSensor),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        })
    );

    const dndAccessibility = useMemo(
        () =>
            dndAccessibilityPortal
                ? { container: dndAccessibilityPortal }
                : undefined,
        [dndAccessibilityPortal]
    );

    return (
        <DndContext
            sensors={sensors}
            modifiers={dndModifiers}
            accessibility={dndAccessibility}
        >
            {children}
        </DndContext>
    );
};

export const DragAndDropContextProvider = ({
    children,
}: React.PropsWithChildren<Record<never, never>>) => {
    const [dndAccessibilityPortal, setDndAccessibilityPortal] =
        useState<HTMLDivElement | null>(null);

    const setDndAccessibilityRef = useCallback((element: HTMLDivElement) => {
        setDndAccessibilityPortal(element);
    }, []);

    return (
        <>
            <DragAndDropContext dndAccessibilityPortal={dndAccessibilityPortal}>
                {children}
            </DragAndDropContext>
            <DndAccessibility
                ref={setDndAccessibilityRef}
                data-test="dnd-accessibility-portal"
            />
        </>
    );
};
