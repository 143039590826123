import React from 'react';
import { _ } from '@splunk/ui-utils/i18n';
import SVG, {
    type SVGPropsOptionalViewBox,
} from '@splunk/react-icons/SVGEnterprise';

/* eslint-disable max-len */
export default function DataInput(
    props: SVGPropsOptionalViewBox
): ReturnType<typeof SVG> {
    return (
        <SVG viewBox="0 0 24 24" screenReaderText={_('Data Icon')} {...props}>
            <path
                fill="currentColor"
                d="M12 7.77901C7.58154 7.77901 4 6.70795 4 5.38951C4 4.07106 7.58154 3 12 3C16.4185 3 20 4.07106 20 5.38951C20 6.70795 16.4185 7.77901 12 7.77901ZM4 10.5902C4 11.0934 6.72308 12.2769 12 12.2769C17.2769 12.2769 20 11.0934 20 10.5902V7.28986C18.3169 8.39185 15.1508 8.90348 12 8.90348C8.84923 8.90348 5.68308 8.39185 4 7.28986V10.5902ZM4 15.0881C4 15.5913 6.72308 16.7748 12 16.7748C17.2769 16.7748 20 15.5913 20 15.0881V12.0914C18.2923 12.9825 15.0769 13.4014 12 13.4014C8.92308 13.4014 5.70769 12.9825 4 12.0914V15.0881ZM4 18.6808C4 21.7731 20 21.7731 20 18.6808V16.5893C18.2923 17.4804 15.0769 17.8993 12 17.8993C8.92308 17.8993 5.70769 17.4804 4 16.5893V18.6808Z"
            />
        </SVG>
    );
}
