import mousetrap from 'mousetrap';
import type { ExtendedKeyboardEvent } from 'mousetrap';
import { console, isMac } from '@splunk/dashboard-utils';
import EventListener from './EventListener';

interface KeyMap {
    event: string;
    keys: string;
    when?: string;
    meta?: {
        snap?: boolean;
        dir?: 'n' | 'e' | 's' | 'w' | 'in' | 'out' | 'reset';
    };
}

/**
 * Adding keycode missing in Firefox
 */
mousetrap.addKeycodes({ 173: '-' });

/**
 * default keymap
 */
export const DEFAULT_KEYMAP: KeyMap[] = [
    {
        event: 'move.left.unsnap',
        keys: 'shift+left',
        meta: { snap: false, dir: 'w' },
    },
    { event: 'move.left', keys: 'left', meta: { snap: true, dir: 'w' } },
    {
        event: 'move.right.unsnap',
        keys: 'shift+right',
        meta: { snap: false, dir: 'e' },
    },
    { event: 'move.right', keys: 'right', meta: { snap: true, dir: 'e' } },
    {
        event: 'move.up.unsnap',
        keys: 'shift+up',
        meta: { snap: false, dir: 'n' },
    },
    { event: 'move.up', keys: 'up', meta: { snap: true, dir: 'n' } },
    {
        event: 'move.down.unsnap',
        keys: 'shift+down',
        meta: { snap: false, dir: 's' },
    },
    { event: 'move.down', keys: 'down', meta: { snap: true, dir: 's' } },
    { event: 'copy', keys: 'meta+c', when: 'mac' },
    { event: 'copy', keys: 'ctrl+c', when: '!mac' },
    { event: 'paste', keys: 'meta+v', when: 'mac' },
    { event: 'paste', keys: 'ctrl+v', when: '!mac' },
    { event: 'duplicate', keys: 'meta+d', when: 'mac' },
    { event: 'duplicate', keys: 'ctrl+d', when: '!mac' },
    { event: 'selectAll', keys: 'meta+a', when: 'mac' },
    { event: 'selectAll', keys: 'ctrl+a', when: '!mac' },
    { event: 'selectNone', keys: 'meta+shift+a', when: 'mac' },
    { event: 'selectNone', keys: 'ctrl+shift+a', when: '!mac' },
    { event: 'removeItems', keys: 'backspace' },
    { event: 'removeItems', keys: 'del' },
    { event: 'cancel', keys: 'escape' },
    { event: 'undo', keys: 'meta+z', when: 'mac' },
    { event: 'undo', keys: 'ctrl+z', when: '!mac' },
    { event: 'redo', keys: 'meta+shift+z', when: 'mac' },
    { event: 'redo', keys: 'ctrl+shift+z', when: '!mac' },
    { event: 'zoom.in', keys: 'ctrl+meta+=', meta: { dir: 'in' }, when: 'mac' },
    {
        event: 'zoom.out',
        keys: 'ctrl+meta+-',
        meta: { dir: 'out' },
        when: 'mac',
    },
    {
        event: 'zoom.reset',
        keys: 'ctrl+meta+0',
        meta: { dir: 'reset' },
        when: 'mac',
    },
    { event: 'zoom.in', keys: 'ctrl+alt+=', meta: { dir: 'in' }, when: '!mac' },
    {
        event: 'zoom.out',
        keys: 'ctrl+alt+-',
        meta: { dir: 'out' },
        when: '!mac',
    },
    {
        event: 'zoom.reset',
        keys: 'ctrl+alt+0',
        meta: { dir: 'reset' },
        when: '!mac',
    },
];

/**
 * validate a key binding rule
 * @param {String} when
 * @return {Boolean} true indicate the rule is valid
 * @private
 */
const isValid = (when?: string) => {
    const isMacOS = isMac();
    return (
        !when || (isMacOS && when === 'mac') || (!isMacOS && when === '!mac')
    );
};

type mousetrapStopCallbackSig = (
    e: ExtendedKeyboardEvent,
    element: Element,
    combo: string
) => boolean;

const defaultMousetrapStopCallback: mousetrapStopCallbackSig =
    mousetrap.prototype.stopCallback;

// Sonar doesn't understand the special significance of `this` as the first param in typescript. Typescript wants `this` to be typed.
mousetrap.prototype.stopCallback = function stopCallback(
    this: InstanceType<typeof mousetrap>, // NOSONAR
    e,
    element,
    combo
) {
    // The original stopCallback needs the context object passed in
    if (defaultMousetrapStopCallback.call(this, e, element, combo)) {
        return true;
    }

    // SUI dropdowns and modals are rendered in layer containers in the body
    const popoverContainer = document.querySelectorAll(
        '[data-test="layer-container"]'
    );
    if (popoverContainer?.length) {
        // If the element in question is contained in a layer container
        // then the current focus is on a SUI dropdown/modal and we
        // don't want to process the input against bound callbacks
        return Array.from(popoverContainer).some((container) =>
            container.contains(element)
        );
    }

    return false;
} as mousetrapStopCallbackSig;

/*
 * Create a new KeyboardListener
 * @param {Object} keyMapConfig
 * @return {KeyboardListener} KeyboardListener instance
 * @public
 */
class KeyboardListener extends EventListener {
    keyMaps: Record<string, KeyMap> = {};

    constructor(keyMapConfig = DEFAULT_KEYMAP) {
        super();
        this.updateKeyMap(keyMapConfig);
    }

    /**
     * update key mapping config
     * @param {Object} keyMapConfig
     * @public
     */
    updateKeyMap = (keyMapConfig = DEFAULT_KEYMAP): void => {
        this.buildKeyMaps(keyMapConfig);
        this.setup();
    };

    /**
     * build reverse key mapping, using keys as the mapping key
     * @param {Object} keyMapConfig
     * @private
     */
    buildKeyMaps = (keyMapConfig: KeyMap[]): void => {
        this.keyMaps = {};
        keyMapConfig.forEach((keyRule) => {
            const valid = isValid(keyRule.when);
            if (valid) {
                if (this.keyMaps[keyRule.keys]) {
                    // eslint-disable-next-line no-console
                    console.warn(
                        `duplicate key mapping detected for ${keyRule.keys}`
                    );
                }
                this.keyMaps[keyRule.keys] = {
                    ...keyRule,
                };
            }
        });
    };

    /**
     * set up keyboard listener
     * @public
     */
    setup(): void {
        this.teardown();
        Object.keys(this.keyMaps).forEach((key) => {
            mousetrap.bind(key, () => {
                let defaultPrevented = false;
                const payload = {
                    ...this.keyMaps[key].meta,
                    preventDefault: () => {
                        defaultPrevented = true;
                    },
                };
                const eventName = this.keyMaps[key].event;
                this.publishKeyEvent(eventName, payload);
                return !defaultPrevented;
            });
        });
    }

    /**
     * teardown all keyboard listener
     * @public
     */
    // eslint-disable-next-line class-methods-use-this
    teardown(): void {
        mousetrap.reset();
    }

    /** publish keyboard action event(s)
     * @param {Object} event
     * @private
     */
    publishKeyEvent = (
        eventName: string,
        payload: KeyMap['meta'] & {
            [key: string]: boolean | string | (() => void) | undefined;
        }
    ): void => {
        eventName.split('.').forEach((_, i, arr) => {
            this.publish(arr.slice(0, i + 1).join('.'), payload);
        });
    };
}

export default KeyboardListener;
