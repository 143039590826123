import React from 'react';
import { _ } from '@splunk/ui-utils/i18n';
import SVG, {
    type SVGPropsOptionalViewBox,
} from '@splunk/react-icons/SVGEnterprise';

/* eslint-disable max-len */
export default function Layers(
    props: SVGPropsOptionalViewBox
): ReturnType<typeof SVG> {
    return (
        <SVG screenReaderText={_('Layers')} viewBox="0 0 24 24" {...props}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12.3687 4.008C12.0568 3.90271 11.7189 3.90294 11.4072 4.00864L3.87769 6.56175C2.52293 7.02113 2.51893 8.93579 3.87174 9.40084L11.4013 11.9892C11.7166 12.0976 12.0591 12.0978 12.3746 11.9898L19.9374 9.4015C21.2928 8.93762 21.2888 7.01932 19.9315 6.56111L12.3687 4.008ZM3.87174 13.4009C2.84734 13.0487 2.60095 11.8653 3.12994 11.1121L10.9062 13.7042L11.8893 14.0421L13.3107 13.5556L20.6736 11.1013C21.2128 11.8545 20.9683 13.0487 19.9374 13.4015L12.3746 15.9899C12.0591 16.0979 11.7166 16.0976 11.4013 15.9892L3.87174 13.4009ZM3.12994 15.1121C2.60095 15.8653 2.84734 17.0487 3.87174 17.4009L11.4013 19.9892C11.7166 20.0976 12.0591 20.0979 12.3746 19.9899L19.9374 17.4015C20.9683 17.0487 21.2128 15.8545 20.6736 15.1013L13.3107 17.5556L11.8893 18.0421L10.9062 17.7042L3.12994 15.1121Z"
                fill="currentColor"
            />
        </SVG>
    );
}
