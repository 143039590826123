import React, { Component } from 'react';
import styled from 'styled-components';
import isEmpty from 'lodash/isEmpty';
import BaseVisualization from '@splunk/dashboard-visualizations/common/BaseVisualization';
import { getStyledShape } from '@splunk/visualizations/common/shapeUtils';
import { parse, dataContract } from '@splunk/visualization-encoding-parsers/ShapeParser';
import { getClickHandler, enableClickHandler } from '@splunk/dashboard-visualizations/utils/eventUtils';
import optionsSchema from './optionsSchema';
import editor from './editorConfig';

const defaultOptions = {
    strokeWidth: 1,
};

const SVGContainer = styled.svg`
    width: ${props => props.width}px;
    height: ${props => props.height}px;
`;

const EllipseShape = getStyledShape('ellipse');

class Ellipse extends Component {
    static propTypes = BaseVisualization.propTypes;

    static defaultProps = {
        ...BaseVisualization.defaultProps,
        height: 250,
        width: 350,
    };

    static dataContract = dataContract;

    static vizContract = {
        initialDimension: {
            width: 150,
            height: 150,
        },
    };

    static initialRequestParams = { offset: 0, count: 10000 };

    static supportedDataSources = ['primary'];

    static schema = optionsSchema;

    static editor = editor;

    render() {
        const { dataSources, encoding, width, height, mode, onEventTrigger, hasEventHandlers } = this.props;
        const options = { ...defaultOptions, ...this.props.options };
        const encodingProps = !isEmpty(dataSources) ? parse(dataSources, encoding) : {};
        const onClick = enableClickHandler(hasEventHandlers, mode)
            ? getClickHandler(onEventTrigger, { ...encodingProps }, 'ellipse.click')
            : null;

        // TODO StrokeWidth validation
        return (
            <SVGContainer width={width} height={height}>
                <EllipseShape
                    cx="50%"
                    cy="50%"
                    rx={`${width / 2 - options.strokeWidth / 2}px`}
                    ry={`${height / 2 - options.strokeWidth / 2}px`}
                    {...encodingProps}
                    {...options}
                    vectorEffect="non-scaling-stroke"
                    onClick={onClick}
                />
            </SVGContainer>
        );
    }
}

export default Ellipse;
