import { mapKeys, chain } from 'lodash';
import { mapToValues } from '../common/chartingUtils';

interface PunchcardOptions {
    [key: string]: any;
}

const punchcardOptionsMapping = {
    legendDisplay: 'showLegend',
    bubbleLabelDisplay: 'showBubbleLabels',
    showDefaultSort: 'useDefaultSort',
    bubbleSizeMethod: 'bubbleScale',
    showDynamicBubbleSize: 'isBubbleSizeDynamic',
    bubbleRadiusMax: 'maxBubbleRadius',
    bubbleRadiusMin: 'minBubbleRadius',
    bubbleSizeMax: 'maxBubbleSize',
    bubbleSizeMin: 'minBubbleSize',
};

// key value mapping of what options (and their corresponding values) which
// we expose in config.ts to property values PurePunchcard understands
export const punchcardOptionValuesMapping = {
    showLegend: {
        right: true,
        off: false,
    },
    showBubbleLabels: {
        off: 'none',
    },
    colorMode: {
        dynamic: 'sequential',
    },
};

/**
 * Helper method to update the option names which can be mapped to PurePunchcard options
 * @method mapToOldKey
 * @param {Object} options
 * @returns {Object}
 */
export const mapToOldKey = (options: PunchcardOptions): PunchcardOptions =>
    mapKeys(options, (val, key) => (punchcardOptionsMapping[key] ? punchcardOptionsMapping[key] : key));

/**
 * Chained helper for mapping the option properties (and values) we expose in config.ts into property values that PurePunchcard accepts
 * This is for options that has new key name and can not directly be mapped with PurePunchcard props
 * @method convertToPunchcardProperties
 * @param {Object} originalOptions
 * @returns {Object}
 */
export const convertToPunchcardProperties = (originalOptions: PunchcardOptions): PunchcardOptions =>
    chain(mapToOldKey(originalOptions)).thru(mapToValues(punchcardOptionValuesMapping)).value();
