import React, { useMemo } from 'react';
import styled from 'styled-components';
import { pick, variables } from '@splunk/themes';
import type { AbsolutePosition, Coordinate } from '@splunk/dashboard-types';
import { positionToStyle } from '../utils/layoutUtils';

interface PlaceholderProps {
    position: AbsolutePosition;
}

export const Placeholder = styled.div.attrs<PlaceholderProps>(
    ({ position }) => ({
        style: positionToStyle(position),
    })
)<PlaceholderProps>`
    background-color: ${pick({
        enterprise: {
            light: variables.backgroundColorHover,
            dark: variables.neutral300,
        },
        prisma: variables.interactiveColorOverlayDrag,
    })};
    opacity: 0.75;
    user-select: none;
    position: absolute;
    flex-direction: column;
    top: 0;
    left: 0;
    cursor: move;
`;

export const ItemDragPlaceholder = ({
    position,
    size = 128,
}: {
    position: Coordinate;
    size?: number;
}): JSX.Element => {
    const placeHolderPosition = useMemo(() => {
        return {
            // update x and y to be the center of the placeholder
            x: position.x - Math.round(size / 2),
            y: position.y - Math.round(size / 2),
            w: size,
            h: size,
        };
    }, [position, size]);
    return (
        <Placeholder
            data-test="item-drag-placeholder"
            position={placeHolderPosition}
        />
    );
};
