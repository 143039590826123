/**
 * @file
 * Visualization Event
 *
 * Create new Event:
 * ```js
 * import VisualizationEvent from '@splunk/dashboard-visualizations/common/VisualizationEvent';
 *
 * const myEvent = new VisualizationEvent({
    originalEvent: e, 
    payload: {
      foo: 'bar',
      value: 'myValue'
    },
    type: 'user.click',
})
 * ```
 */
export default class VisualizationEvent {
    constructor({ originalEvent, payload, type = 'unknown' }) {
        this.type = type;
        this.originalEvent = originalEvent;
        this.payload = payload;
    }

    /**
     * call preventDefault on downstream event
     * @public
     */
    preventDefault() {
        if (this.originalEvent && this.originalEvent.preventDefault) {
            this.originalEvent.preventDefault();
        }
    }
}
