import { useRef } from 'react';
import { isEqual } from 'lodash';

/**
 * This hook evaluates and stores the result of a passed function and only
 * re-evaluates the function when passed dependencies change.
 *
 * It is different from the internal React useMemo in the following aspects:
 * - it only stores the last evaluated result
 * - it uses a deep equality check to determine if dependencies have changed
 *
 * Inspired by apollo-client's useDeepMemo
 *
 * @param fn the function to be executed and
 * @param dependencies an array of dependencies
 */
export const useDeepMemo = <FnReturnType, DepType>(
    fn: () => FnReturnType,
    dependencies: DepType
): FnReturnType => {
    const ref = useRef<{ dependencies: DepType; value: FnReturnType }>();
    if (!ref.current || !isEqual(dependencies, ref.current.dependencies)) {
        ref.current = {
            dependencies,
            value: fn(),
        };
    }
    return ref.current.value;
};
