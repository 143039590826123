import noop from '@splunk/dashboard-utils/noop';
import { parse, epochToMoment } from '@splunk/dashboard-utils/time';
import type { RefreshFunction } from '@splunk/dashboard-types';

const minInterval = 3; // 3 secs

const computeInterval = (interval: number, refreshType: string) => {
    return refreshType === 'delay' ? interval : Math.max(minInterval, interval);
};

export const parseExprToSeconds = (exp: string | number = '5s'): number => {
    try {
        if (typeof exp === 'number' && Number.isFinite(exp)) {
            return Math.abs(Math.trunc(exp));
        }
        if (typeof exp === 'string') {
            const now = epochToMoment();
            const { momentTime } = parse(`+${exp}`, now);
            const diff = momentTime.diff(now, 'seconds');
            return Math.abs(diff);
        }
        return 0;
    } catch (ex) {
        return 0;
    }
};

/**
 * Control DataSource refresh behaviour
 */
export default class RefreshScheduler {
    refreshFunc: RefreshFunction;

    refreshType: string;

    interval: number;

    stopped: boolean;

    timeout: ReturnType<typeof setTimeout> | null;

    constructor({
        refreshFunc = noop,
        refreshInterval,
        refreshType = 'delay',
    }: {
        refreshFunc: RefreshFunction;
        refreshInterval: number;
        refreshType?: string;
    }) {
        this.refreshFunc = refreshFunc;
        this.refreshType = refreshType;
        this.interval = computeInterval(refreshInterval, refreshType);
        this.stopped = false;
        this.timeout = null;
    }

    scheduleNextRefresh = (): void => {
        // Do not schedule a new refresh if the timer has already started
        if (!this.timeout) {
            this.timeout = setTimeout(() => {
                this.timeout = null;
                if (!this.stopped) {
                    this.refreshFunc();
                }
            }, this.interval * 1000);
        }
    };

    stop = (): void => {
        if (this.timeout) {
            clearTimeout(this.timeout);
            this.timeout = null;
        }
        this.stopped = true;
    };
}
