// ***** TOKENS ***** //

/**
 * contains only:
 *-     - alphanumeric
 *      - underscore
 *      - space
 *      - dash
 *      - period
 *
 */
const DEFAULT_ALLOWABLE_NAME_PATTERN = ' \\w-.';

export const TOKEN_NAMESPACE_PREFIX_PATTERN = `([${DEFAULT_ALLOWABLE_NAME_PATTERN}]+:)?`;

/**
 * TOKEN NAME REGEX RULES:
 * 1. starts and ends with an alphanumeric character, underscore, dash, or period
 * 2. contains only:
 *      - alphanumeric
 *      - underscore
 *      - space
 *      - dash
 *      - period
 */
export const TOKEN_NAME_CHARS_PATTERN = /((?=[\w.-])[\w .-]*[\w.-])/.source;

const FLAGS_PATTERN = /(\|[|a-zA-Z]+)?/.source;

const TOKEN_NAME_CHARS_PATTERN_WITH_FLAGS =
    TOKEN_NAME_CHARS_PATTERN + FLAGS_PATTERN;

// additional regex used to test an entire string if matches token
export const TokenNameRegExp = new RegExp(`^${TOKEN_NAME_CHARS_PATTERN}$`);

const TOKEN_PATTERN =
    /\$/.source +
    TOKEN_NAMESPACE_PREFIX_PATTERN +
    TOKEN_NAME_CHARS_PATTERN_WITH_FLAGS +
    /\$/.source;
export const TOKEN_OR_DOLLAR_RE = new RegExp(
    `${TOKEN_PATTERN}|${/\$\$/.source}`,
    'g'
);
export const TokenRegExp = new RegExp(TOKEN_PATTERN);
