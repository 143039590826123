import omit from 'lodash/omit';

/**
 * convert the old props to new props for pure visualization based on propsMapping
 * @param {Object} oldProps   props used by react visualizations
 * @param {Object} propsMapping
 * @return {Object} newprops    props used by pure visualizations
 */
export const propsConvert = (oldProps, propsMapping) => {
    const newProps = {};
    Object.keys(oldProps).forEach(propKey => {
        const key = propsMapping[propKey] || propKey;
        newProps[key] = oldProps[propKey];
    });
    return newProps;
};

/**
 * omit the deprecated props for pure visualization
 * @param {Object} props   props used by react visualizations
 * @param {Array} deprecatedProps
 * @return {Object} newprops    props used by pure visualizations
 */
export const propsDeprecate = (props, deprecatedProps) => omit(props, deprecatedProps);
