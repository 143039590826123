import { type PayloadAction, createSlice } from '@reduxjs/toolkit';
import type { GlobalState, MigrationSlice } from '@splunk/dashboard-types';

/**
 * Selectors
 */
export const selectHasDismissedAutoMigrate = (state: GlobalState): boolean =>
    state.migration.hasDismissedAutoMigrate;

export const selectHasDismissedManualMigrate = (state: GlobalState): boolean =>
    state.migration.hasDismissedManualMigrate;

export const selectHasDismissedViewMode = (state: GlobalState): boolean =>
    state.migration.hasDismissedViewMode;

export const selectNumMigratedViz = (state: GlobalState): number =>
    state.migration.numMigratedViz;

export const selectHasAutoMigrated = (state: GlobalState): boolean =>
    state.migration.hasAutoMigrated;

const initialState: MigrationSlice = {
    hasDismissedAutoMigrate: false,
    hasDismissedManualMigrate: false,
    hasDismissedViewMode: false,
    numMigratedViz: 0,
    hasAutoMigrated: false,
};

const migrationSlice = createSlice({
    name: 'migration',
    initialState,
    reducers: {
        dismissAutoMigrate(state) {
            state.hasDismissedAutoMigrate = true;
            state.numMigratedViz = 0;
        },
        dismissManualMigrate(state) {
            state.hasDismissedManualMigrate = true;
        },
        dismissViewMode(state) {
            state.hasDismissedViewMode = true;
        },
        setNumMigrateViz(state, action: PayloadAction<number>) {
            state.numMigratedViz = action.payload;
        },
        onAutoMigrate(state) {
            state.hasAutoMigrated = true;
        },
    },
});

export const {
    dismissAutoMigrate,
    dismissManualMigrate,
    dismissViewMode,
    setNumMigrateViz,
    onAutoMigrate,
} = migrationSlice.actions;
export default migrationSlice.reducer;
