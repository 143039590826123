import React, { type ComponentType, useMemo } from 'react';
import T from 'prop-types';
import styled from 'styled-components';
// Non-UDF Splunk imports
import Tooltip from '@splunk/react-ui/Tooltip';
import { variables, pick } from '@splunk/themes';
import { Column as ColumnIcon } from '@splunk/visualization-icons/placeholders';
// UDF imports
import { WAITING_FOR_INPUT_MSG } from '@splunk/dashboard-state';
import { customThemeVariables } from '@splunk/dashboard-ui';
import { toPx } from '@splunk/dashboard-utils';

const maxLengthOfTextMessage = 45;

interface ContainerProps {
    width: number | string;
    height: number | string;
    backgroundColor?: string;
}

const Container = styled.div.attrs<ContainerProps>(({ width, height }) => ({
    'data-test': 'viz-waiting-for-input',
    style: {
        width,
        height,
    },
}))<ContainerProps>`
    background-color: ${(props) =>
        props.backgroundColor || customThemeVariables.vizPanelBackgroundColor};
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    overflow: hidden;
`;

const Text = styled.p.attrs(() => ({
    'data-test': 'viz-waiting-for-input-message',
}))`
    color: ${pick({
        enterprise: {
            light: variables.gray20,
            dark: variables.gray92,
        },
        prisma: variables.contentColorDefault,
    })};
    text-overflow: ellipsis;
    text-align: center;
    font-size: 14px;
    margin: 0 50px;
`;

const MessageContainer = styled.div`
    max-width: 500px;
    word-break: break-all;
`;

interface MessageProps {
    message: string;
}

const Message = ({ message }: MessageProps) =>
    message.length > maxLengthOfTextMessage ? (
        <Tooltip content={<MessageContainer>{message}</MessageContainer>}>
            <Text>{`${message.slice(0, maxLengthOfTextMessage)}...`}</Text>
        </Tooltip>
    ) : (
        <Text>{message}</Text>
    );

const StyledPlaceholder = styled(ColumnIcon).attrs((props) => {
    const hasAs = typeof props.as !== 'undefined';

    return {
        'data-test': `${hasAs ? 'placeholder' : 'column'}-icon`,
    };
})`
    color: ${variables.contentColorActive};
    opacity: 0.15;
    height: ${(props) => props.$height};
    width: 100%;
`;

interface WaitingForInputProps extends ContainerProps, MessageProps {
    PlaceholderIcon?: ComponentType<typeof ColumnIcon>;
}

const VizWaitingForInput = ({
    message,
    backgroundColor,
    width,
    height,
    PlaceholderIcon,
}: WaitingForInputProps) => {
    // If a height prop is received then work with that.
    // If no height prop is received then default to 100%
    const pxHeight = useMemo(
        () => (typeof height !== 'undefined' ? toPx(height) : '100%'),
        [height]
    );

    const styledPlaceholderProps = useMemo(() => {
        const calcdHeight = `calc(${pxHeight} * 0.35)`;

        // If a placeholder icon is received then apply the styled-components as
        // polymorphic prop (https://styled-components.com/docs/api#as-polymorphic-prop)
        // to style the appropriate icon
        if (PlaceholderIcon) {
            return {
                $height: calcdHeight,
                as: PlaceholderIcon,
            };
        }

        // When no placeholder icon is provided, `StyledPlaceholder` should render
        // the default column icon; no polymorphic prop is required - only height is needed
        return { $height: calcdHeight };
    }, [pxHeight, PlaceholderIcon]);

    return (
        <Container
            width={width}
            height={height}
            backgroundColor={backgroundColor}
        >
            <StyledPlaceholder {...styledPlaceholderProps} />
            {
                // don't render text if viz height or width is too small
                (height > 150 && width > 80) ||
                (height === '100%' && width === '100%') ? (
                    <Message message={message} />
                ) : null
            }
        </Container>
    );
};

VizWaitingForInput.propTypes = {
    message: T.string,
    backgroundColor: T.string,
    width: T.oneOfType([T.string, T.number]).isRequired,
    height: T.oneOfType([T.string, T.number]),
    PlaceholderIcon: T.func,
};

VizWaitingForInput.defaultProps = {
    message: WAITING_FOR_INPUT_MSG,
};

Message.propTypes = {
    message: T.string,
};

export default VizWaitingForInput;
