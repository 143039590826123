import React from 'react';
import SVG, {
    type SVGPropsOptionalViewBox,
} from '@splunk/react-icons/SVGEnterprise';

/* eslint-disable max-len */
export default function Mobile(
    props: SVGPropsOptionalViewBox
): ReturnType<typeof SVG> {
    return (
        <SVG viewBox="0 0 36 59" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path d="m6 0c-3.31371 0-6 2.64152-6 5.9v47.2c0 3.2585 2.68629 5.9 6 5.9h24c3.3137 0 6-2.6415 6-5.9v-47.2c0-3.25848-2.6863-5.9-6-5.9zm14.5984 53.6c0 1.4359-1.164 2.6-2.6 2.6-1.4359 0-2.6-1.1641-2.6-2.6s1.1641-2.6 2.6-2.6c1.436 0 2.6 1.1641 2.6 2.6zm11.4016-49.6h-28v45h28z" />
        </SVG>
    );
}
