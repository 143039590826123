import type { VisualizationDefinition } from '@splunk/dashboard-types';
import config from '@splunk/visualizations/ChoroplethSvg.config';
import type { EncodingConfig } from '../encoding';
import { encodingToDynamicOptionsDSL } from '../encoding';
import { removeInvalidOptions } from '../utils/migrationUtils';

const validOptions = {
    ...config.optionsSchema,
};

const optionRenames = {
    featureId: 'areaIds',
    value: 'areaValues',
    fill: 'areaColors',
};

// old default encoding configurations should be retained in migrated configuration
const defaultOptionChanges = {
    areaIds: '> primary | seriesByIndex(0)',
    areaValues: '> primary | seriesByIndex(1)',
    areaColors: '> primary | seriesByIndex(1) | gradient(fillGradientContext)',
};

const defaultContextChanges = {
    fillGradientContext: {
        colors: ['#7EEFDA', '#6484F6', '#C093F9', '#293873'],
    },
};

export const migrateVizToSplunkChoroplethSvg = (
    vizDefinition: VisualizationDefinition
): VisualizationDefinition => {
    const { encoding = {}, options = {}, ...otherDefinitionParts } = vizDefinition;
    // translate encoding to dynamic options equivalent
    const { options: dataOptions, context: dataContext } = encodingToDynamicOptionsDSL(
        encoding as Record<string, EncodingConfig>
    );

    const migratedDefinition: VisualizationDefinition = {
        ...otherDefinitionParts,
        type: 'splunk.choropleth.svg',
        options: {},
        context: {},
    };

    // add migrated option names and values
    const migratedOptions = { ...options };
    Object.keys(dataOptions).forEach(key => {
        const updatedKey = optionRenames[key] ?? key;
        migratedOptions[updatedKey] = dataOptions[key];
    });

    // update migrated options and context
    migratedDefinition.options = {
        ...defaultOptionChanges,
        ...migratedOptions,
    };

    migratedDefinition.context = encoding.fill ? dataContext : defaultContextChanges;

    // remove invalid options
    removeInvalidOptions(migratedDefinition.options, validOptions);

    return migratedDefinition;
};
