import * as React from 'react';
import { _ } from '@splunk/ui-utils/i18n';
import { get, omit } from 'lodash';
import GeoContext from '@splunk/visualization-context/GeoContext';
import Message from '@splunk/visualizations-shared/Message';
import { parse, dataContract } from '@splunk/visualization-encoding-parsers/ChoroplethParser';
import { enterprise } from '@splunk/visualization-themes/variables';
import GeoJsonChoropleth, { GeoJsonChoroplethProps } from '@splunk/react-visualizations/GeoJsonChoropleth';
// @TODO geofeaturegroup should be moved to geojsonchoropleth in the future if possible
import { GeoFeatureGroup } from '@splunk/react-visualizations/SvgChoropleth';
import { withBackgroundColor } from '../../utils/enhancer';
import optionsSchema from './optionsSchema';
import VisualizationEvent from '../../common/VisualizationEvent';
import editor from './editorConfig';
import { enableClickHandler } from '../../utils/eventUtils';

export interface ChoroplethProps {
    /**
     * true indicate the visualization is loading data
     */
    loading: boolean;
    /**
     * display mode
     */
    mode: string;
    /**
     * width in pixel or string, defaults to 100%
     */
    width: any;
    /**
     * height in pixel or string
     */
    height: any;
    /**
     * Custom style on visualization
     */
    style: Record<string, unknown>;
    /**
     * visualization formatting options
     */
    options: {
        name: string;
        source: string;
        projection: string;
        sourceBounds: { lat: { min: number; max: number }; long: { min: number; max: number } };
        logicalBounds: { x: { min: number; max: number }; y: { min: number; max: number } };
        fillColor: string;
        strokeColor: string;
        strokeHighlightColor?: string;
        backgroundColor?: string;
        selector: string;
        geoFeatureGroups?: GeoFeatureGroup[];
    };
    encoding: Record<string, unknown>;
    /**
     * datasource state which include data and request params, object key indicate the datasource type.
     */
    dataSources: Record<string, any>;
    /**
     * A callback to update formatting options
     */
    onOptionsChange(): void;
    /*
     * Inform viz if there are handlers listening to events
     */
    hasEventHandlers: boolean;
    /**
     * A callback to trigger event
     */
    onEventTrigger(event: VisualizationEvent): void;
    /**
     * A callback to request new data with updated request params
     */
    onRequestParamsChange(): void;
}

interface ChoroplethState {
    geoJson: { features: Record<string, unknown>[] };
}

export class ChoroplethComponent extends React.Component<ChoroplethProps, ChoroplethState> {
    static contextType = GeoContext;

    static dataContract = dataContract;

    static schema = optionsSchema;

    static editor = editor;

    public constructor(props: ChoroplethProps) {
        super(props);
        this.state = {
            geoJson: null, // eslint-disable-line react/no-unused-state
        };
    }

    handleMapClick = (clickedCoords: any, featureId: string): void => {
        const { hasEventHandlers, mode, onEventTrigger } = this.props;
        if (enableClickHandler(hasEventHandlers, mode)) {
            onEventTrigger({
                originalEvent: null,
                payload: { featureId, clickedCoords },
                type: 'map.click',
            });
        }
    };

    handleFeatureHover = (/* featureId: string */): void => {
        // @TODO: implement
        // if (featureId) {
        //  this.props.onEventTrigger(new VisualizationEvent('map.hover', null, { featureId }));
        // }
    };

    render(): JSX.Element {
        const { encoding, dataSources, options, width, height } = this.props;
        const { source } = options;
        let data = { featureIDs: [], values: [], fill: [] };
        let geoJson: any;
        let geoFeatureGroups: GeoFeatureGroup[];

        const geoRegistry = this.context;
        if (!geoRegistry) {
            return (
                <Message
                    data-test="choropleth-message"
                    width={width}
                    height={height}
                    message={_('No GeoRegistry provided in context')}
                />
            );
        }

        try {
            geoJson = geoRegistry.getByURL(source);
        } catch (e) {
            return (
                <Message
                    data-test="choropleth-message"
                    width={width}
                    height={height}
                    level={e.level}
                    message={_(e.message)}
                />
            );
        }

        // @TODO / @Note
        // can we get rid of logicalBounds option and use
        // width/height that is passed down from layout?
        // insets might need to be configured differently
        const xScaleFactor = width / options.logicalBounds.x.max;
        const yScaleFactor = height / options.logicalBounds.y.max;
        const logicalBounds = {
            x: { min: 0, max: width },
            y: { min: 0, max: height },
        };

        // scale the feature groups logicalBounds
        // based on ratio of configured options to layout item dimensions
        if (options.geoFeatureGroups) {
            geoFeatureGroups = options.geoFeatureGroups.map(
                (group): GeoFeatureGroup => {
                    if (!group.logicalBounds) return group;
                    return {
                        ...group,
                        logicalBounds: {
                            x: {
                                min: group.logicalBounds.x.min * xScaleFactor,
                                max: group.logicalBounds.x.max * xScaleFactor,
                            },
                            y: {
                                min: group.logicalBounds.y.min * yScaleFactor,
                                max: group.logicalBounds.y.max * yScaleFactor,
                            },
                        },
                    };
                }
            );
        }
        if (dataSources && get(dataSources, ['primary', 'data'])) {
            const dataFromEncoding = omit(parse(dataSources, encoding), '_meta');
            data = {
                featureIDs: dataFromEncoding.featureId || [],
                values: dataFromEncoding.value || [],
                fill: dataFromEncoding.fill || [],
            };
        }

        const choroplethProps: GeoJsonChoroplethProps = {
            ...options,
            data,
            geoJson,
            geoFeatureGroups,
            logicalBounds,
            onFeatureHover: this.handleFeatureHover,
            onClick: this.handleMapClick,
        };

        return <GeoJsonChoropleth data-test="react-geo-json-choropleth" {...choroplethProps} />;
    }
}

export default withBackgroundColor({
    enableBackgroundColorOption: true,
    defaultBackgroundColor: enterprise.defaultBackgroundColor,
})(ChoroplethComponent);
