/* eslint-disable class-methods-use-this,no-useless-constructor */
import uuid from 'uuid-v4';
import { _ } from '@splunk/ui-utils/i18n';
import Provider from '@splunk/visualization-context/Provider';

export const KEY = '__images__';

/**
 * a pure frontend local image provider
 */
class LocalImageProvider extends Provider {
    constructor() {
        super();
    }

    /**
     * return provider type
     */
    getType() {
        return 'local';
    }

    /**
     * add a new image
     */
    upload(dataURI, metaData) {
        try {
            const id = uuid();
            const imageObject = { dataURI, metaData };
            let images = JSON.parse(localStorage.getItem(KEY) || '{}');
            images = { ...images, [id]: imageObject };
            localStorage.setItem(KEY, JSON.stringify(images));
            return id;
        } catch (error) {
            throw new Error(
                _('The image cannot be uploaded. Please check whether your localStorage is full.')
            );
        }
    }

    /**
     * get image by imageID
     */
    getById(imageID) {
        const images = JSON.parse(localStorage.getItem(KEY) || '{}');
        const imageObject = images[imageID];
        if (imageObject) {
            return imageObject;
        }
        throw new Error(_('The image is not found.'));
    }

    /**
     * get all imageIDs
     */
    listIds({ offset, count }) {
        try {
            const images = JSON.parse(localStorage.getItem(KEY) || '{}');
            const imageIDS = Object.keys(images);
            return imageIDS.slice(offset, count);
        } catch (error) {
            throw new Error(_('The image ids cannot be listed.'));
        }
    }

    /**
     * delete an image by dataID
     */
    deleteById(imageID) {
        try {
            const imageObject = this.getById(imageID);
            const images = JSON.parse(localStorage.getItem(KEY));
            delete images[imageID];
            localStorage.setItem(KEY, JSON.stringify(images));
            return imageObject;
        } catch (error) {
            throw new Error(_('The image cannot be deleted.'));
        }
    }
}

export default LocalImageProvider;
