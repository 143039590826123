import { type DebouncedFunc, throttle } from 'lodash';
import { useEffect } from 'react';

type MouseMoveHandler = (e: MouseEvent) => void;

/**
 * Hook that conditionally attaches a mouse move handler to the document.
 * Optionally you can throttle it by passing in a non 0 throttledMs value.
 *
 * @param handleMouseMove the function to attach to the document
 * @param isEnabled should the listeners be attached?
 * @param throttledMs throttle the callback
 */
const useMouseMoveHandler = ({
    onMouseMove,
    isEnabled = true,
    throttledMs,
}: {
    onMouseMove: MouseMoveHandler;
    isEnabled?: boolean;
    throttledMs?: number;
}): void => {
    useEffect(() => {
        if (!isEnabled) {
            return undefined;
        }

        let throttledCallback: DebouncedFunc<MouseMoveHandler> | null;
        if (throttledMs) {
            throttledCallback = throttle(onMouseMove, throttledMs, {
                trailing: false,
            });
            document.addEventListener('mousemove', throttledCallback);
        } else {
            document.addEventListener('mousemove', onMouseMove);
        }

        return () => {
            if (throttledCallback) {
                throttledCallback.cancel();
                document.removeEventListener('mousemove', throttledCallback);
            } else {
                document.removeEventListener('mousemove', onMouseMove);
            }
        };
    }, [isEnabled, onMouseMove, throttledMs]);
};

export default useMouseMoveHandler;
