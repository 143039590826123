import { _ } from '@splunk/ui-utils/i18n';
import { EditorConfig } from '../interfaces/Editor';

const gauge: EditorConfig = {
    label: _('Data display'),
    layout: [
        [
            {
                label: _('Value display'),
                option: 'valueDisplay',
                editor: 'editor.radioBar',
                editorProps: {
                    values: [
                        { label: _('Number'), value: 'number' },
                        { label: _('Percentage'), value: 'percentage' },
                        { label: _('Off'), value: 'off' },
                    ],
                },
            },
        ],
        [
            {
                label: _('Label display'),
                option: 'labelDisplay',
                editor: 'editor.radioBar',
                editorProps: {
                    values: [
                        { label: _('Numbers'), value: 'number' },
                        { label: _('Percentages'), value: 'percentage' },
                        { label: _('Off'), value: 'off' },
                    ],
                },
            },
        ],
        [
            {
                label: _('Orientation'),
                option: 'orientation',
                editor: 'editor.radioBar',
                editorProps: {
                    values: [
                        { label: _('Vertical'), value: 'vertical' },
                        { label: _('Horizontal'), value: 'horizontal' },
                    ],
                },
            },
        ],
    ],
};

export default gauge;
