import chroma from 'chroma-js';
import isUndefined from 'lodash/isUndefined';
import find from 'lodash/find';

const { hasOwnProperty } = Object.prototype;

export const rangeFormat = (value, { ranges, values } = {}) => {
    if (Array.isArray(ranges) && Array.isArray(values) && !Number.isNaN(value)) {
        const floatVal = parseFloat(value);
        for (let i = 0; i < ranges.length; i += 1) {
            // bad range value
            if (Number.isNaN(Number(ranges[i]))) {
                // Could not format value: Ranges are required to be numbers. Falling back to original value
                return value;
            }
            // value is less than the range limit
            if (floatVal <= parseFloat(ranges[i])) {
                const valueIndex = Math.min(i, values.length - 1); // correct the index if there are more range items than values
                if (values[valueIndex]) {
                    return values[valueIndex];
                }
            }
        }
        if (floatVal > parseFloat(ranges[ranges.length - 1])) {
            return values[values.length - 1];
        }
    }
    // Could not format value: Ranges and Value are required to be numbers
    return value;
};

export const categoryFormat = (value, { categories, values }) => {
    if (!(Array.isArray(categories) && Array.isArray(values) && categories.length === values.length)) {
        // Could not format value: categories & values should be arrays of the same length. Falling back to original value
        return value;
    }
    const valueIndex = categories.indexOf(value);
    if (valueIndex > -1) {
        return values[valueIndex];
    }
    // Could not format value: Category does not exist. Falling back to original value
    return value;
};

/**
 * based on a range configuration this formatter maps the value to a value specified in a range.
 * the range fitting follows this criteria: `range.from <= value < range.to` so the `to` value is not included
 * A range can be defined as either a closed bound range: `{ from: 10, to: 20, value: 'foo' }`
 * or an open bound range:
 * `{ to: 20, value: 'bar' }` (open lower bound)
 * `{ from: 100, value: 'oof' }` (open upper bound)
 *
 * @param {string} value from data
 * @param {object} formatConfig
 * @param {Object[]} formatConfig.ranges
 */
export const rangeValueFormat = (value, { ranges = [] }) => {
    const floatValue = parseFloat(value);
    if (Number.isNaN(Number(value))) {
        return value;
    }
    for (let i = 0; i < ranges.length; i += 1) {
        if (
            // open upper bound: value is bigger than or equal to open upper bound start (from)
            (i === 0 && hasOwnProperty.call(ranges[i], 'from') && floatValue >= ranges[i].from) ||
            // inbetween: value falls into from - to range
            (floatValue >= ranges[i].from && floatValue < ranges[i].to) ||
            // open lower bound: value is smaller than or equal to open lower bound end (to)
            (i === ranges.length - 1 && hasOwnProperty.call(ranges[i], 'to') && floatValue < ranges[i].to)
        ) {
            return ranges[i].value;
        }
    }
    return value;
};

/**
 * this formatter maps the value to a value specified in a list of potential matches .
 * if the value passed is a match for what is provided in `matches[i].match`, we return that mapped value `matches[i].value`.
 * If there is not match in the array and there is a `defaultValue` provided we return that value. If there is not a match in the list
 * and no defaultValue is passed, we return the original `value`
 * `{ match: 'cherry', value: 'red' }` (exact match)
 *
 * @param {string} value from data
 * @param {object} formatConfig
 * @param {Object[]} formatConfig.matches
 * @param {object} formatConfig.defaultValue
 */
export const matchValueFormat = (value, { matches = [], defaultValue = undefined }) => {
    const defaultResult = isUndefined(defaultValue) ? value : defaultValue;
    const matchResult = find(matches, match => match.match && match.match === value);

    return isUndefined(matchResult) ? defaultResult : matchResult.value;
};

export const NON_NUMERIC_RANGE_ERROR =
    'Could not format value: Ranges are required to be numbers. Falling back to first color';
export const VALUES_AND_RANGES_HAVE_DIFFERENT_LENGTH =
    'gradient ranges and values must have the same number of elements';
export const INVALID_VALUE = 'Could not format value for gradient:';

/**
 * based on ranges and values this formatter maps the value to an interpolated color
 *
 * @param {string} value from data (string representation of number)
 * @param {object} formatConfig
 * @param {number[]} formatConfig.ranges value ranges
 * @param {string[]} formatConfig.values color stops to interpolate from
 * @param {string[]} data the data to be formatted
 * @param errorHandler function
 */
export const colorGradientFormat = (value, { ranges, values }, data, errorHandler) => {
    let colorValues = values;
    let colorRanges = ranges;
    function errorColor() {
        return colorValues ? chroma(colorValues[0]).hex().toUpperCase() : '#000';
    }
    if (!Number.isNaN(Number(value))) {
        // set default values for gradient colors if values not explicit
        if (!colorValues) {
            colorValues = ['white', 'red'];
        }

        if (colorValues.length === 1) {
            colorValues.unshift('white'); // force color bin to have a lower bound color
        }
        // set default values for ranges if ranges not explicit
        if (!ranges) {
            if (data && data.length > 0) {
                const min = data.length > 0 ? Math.min.apply(null, data) : undefined;
                const max = data.length > 0 ? Math.max.apply(null, data) : undefined;
                colorRanges = [min];
                for (let i = 1; i < colorValues.length - 1; i += 1) {
                    colorRanges.push(min + (i * (max - min)) / (colorValues.length - 1));
                }
                colorRanges.push(max);
            } else {
                colorRanges = [0, 1]; // no data values, so just make the range from zero to 1
            }
        }

        if (colorRanges.length === 1) {
            colorRanges.push(colorRanges[0]); // force range to be at least two values
        }
        colorRanges.sort((a, b) => a - b); // force ascending order of range stops
        if (colorRanges.length !== colorValues.length) {
            errorHandler(VALUES_AND_RANGES_HAVE_DIFFERENT_LENGTH);
            return errorColor();
        }

        const floatVal = parseFloat(value);

        // don't let value be outside the allowed range
        if (floatVal > parseFloat(colorRanges[colorRanges.length - 1])) {
            return chroma(colorValues[colorValues.length - 1])
                .hex()
                .toUpperCase(); // high end
        }
        if (floatVal < parseFloat(colorRanges[0])) {
            return chroma(colorValues[0]).hex().toUpperCase(); // low end
        }

        // find the color gradient range that the value is inside
        for (let i = 1; i < colorRanges.length; i += 1) {
            // bad range value
            if (Number.isNaN(Number(colorRanges[i]))) {
                // eslint-disable-next-line
                errorHandler(NON_NUMERIC_RANGE_ERROR);
                return errorColor();
            }

            const upperVal = colorRanges[i];
            if (floatVal <= upperVal) {
                // if value is inside the bin
                const lowerVal = colorRanges[i - 1];
                let tau = (value - lowerVal) / (upperVal - lowerVal);

                // deal with special case when the upper and lower and bounds of the colorRange is equal
                if (upperVal === lowerVal) {
                    if (value === 0) {
                        // user prolly expects lower end of color range if value is zero
                        tau = 0;
                    } else {
                        tau = 1; // otherwise use the upper bound
                    }
                }

                const upperColor = colorValues[i];
                const lowerColor = colorValues[i - 1];
                return chroma.scale([lowerColor, upperColor])(tau).hex().toUpperCase();
            }
        }
    }
    // eslint-disable-next-line
    errorHandler(`${INVALID_VALUE} ${value}`);
    return errorColor();
};

const formatters = {
    rangevalue: rangeValueFormat,
    matchvalue: matchValueFormat,
    gradient: colorGradientFormat,
};

export const MAX_LOGGED_FORMATTER_ERRORS = 10;
export const newFormatterErrorHandler = (maxErrors, handlerFunc) => {
    let errorCount = 0;
    return msg => {
        if (errorCount < maxErrors) {
            handlerFunc(msg);
        }
        errorCount += 1;
    };
};

/**
 * returns a formatter function based on a format config
 * @param {Object} formatCfg formatter config
 * @param {number[]} formatCfg.ranges an array of numeric ranges that will be used to map to values. requires formatCfg.values
 * @param {*[]} formatCfg.values  an array of values to map to. can be used with formatCfg.ranges or formatCfg.categories or formatCfg.gradient
 * @param {string[]|number[]} formatCfg.categories  an array of categories. categories are used for a 1:1 mapping to values. requires formatCfg.values
 * @param {number[]} data the data to be formatted
 */
export const getFormatter = (data, formatCfg = {}) => {
    if (formatCfg.type) {
        if (formatters[formatCfg.type]) {
            // @TODO(pwied):
            // future: should formatters have prepare stage where params are validated
            // and additional formatting context is generated (e.g. min & max value)
            return value =>
                formatters[formatCfg.type](
                    value,
                    formatCfg,
                    data,
                    // eslint-disable-next-line no-console
                    newFormatterErrorHandler(MAX_LOGGED_FORMATTER_ERRORS, msg => console.warn(msg))
                );
        }
        return value => value;
    }
    // @TODO(pwied):
    // - remove post beta deadline
    // - move to formatters with type option
    // - leaving it here for now because Choropleth builds on it
    const { ranges, values } = formatCfg;
    if (values) {
        if (ranges) {
            return value => rangeFormat(value, formatCfg);
        }

        if (formatCfg.categories) {
            return value => categoryFormat(value, formatCfg);
        }
    }
    // eslint-disable-next-line
    console.warn('Could not find a formatter. Falling back to identity fn');
    return value => value;
};
