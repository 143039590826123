import { isUndefined, find } from 'lodash';

import { getDataTypeForPoint, isNumber } from '../utils/types';
import { setDefaultValue } from '../utils/formatterUtils';
import { AbstractFormatter } from '../Formatter';
import { TypedValue, DataType } from '../DataPrimitive';
import { DataPoint } from '../DataPoint';
import EncodingExecutor from '../EncodingExecutor';

// @TODO: explore impact of stricter types here
//  - match: string | number
//  - value: string
export interface MatchesConfig {
    match: any;
    value: any;
}

/**
 * This formatter accepts a list of potential matches and a defaultValue (if no match is found) as the config.
 *
 * It maps each element in the DataSeries to find a corresponding matched value and returns the matched value if found, or the defaultValue if present.
 * Otherwise if neither are present, it returns the original value.
 *
 * ```js
 * <SampleViz
 *     context={{
 *         colorMatches: [
 *             {
 *                 match: 500,
 *                 value: '#FF0000',
 *             },
 *             {
 *                 match: 750,
 *                 value: '#00FF00',
 *             },
 *         ],
 *     }}
 *     options={{
 *         colorOption: '> primary | seriesByIndex(0) | lastPoint() | matchValue(colorMatches, "#0000FF")' // returns #FF0000
 *         colorOption2: '> primary | seriesByIndex(1) | lastPoint() | matchValue(colorMatches, "#0000FF")' // returns #0000FF
 *     }}
 *     dataSources={{
 *         primary: {
 *             data: {
 *                 columns: [[100, 200, 300, 400, 500], [600, 700, 800, 900, 1000]]
 *                 fields: [{ name: 'foo' }, { name: 'bar' }],
 *             }
 *         }
 *     }}
 * />
 *
 *
 * @extends AbstractFormatter<DataType, DataType>
 */
export class MatchValue extends AbstractFormatter<DataType, DataType> {
    private matches: MatchesConfig[];

    private defaultValue: DataType;

    constructor(matches: MatchesConfig[], defaultValue: any = undefined) {
        super();
        this.matches = EncodingExecutor.rawTree(matches);
        this.defaultValue =
            defaultValue === undefined ? (undefined as DataType) : setDefaultValue(defaultValue);
    }

    protected formatTypedValue(input: DataPoint): TypedValue<DataType> {
        const { value } = input.getValue();
        // if no match present, return defaultValue if present, otherwise return the original value if valid
        const defaultMatchValue = isUndefined(this.defaultValue) ? value || '' : this.defaultValue;
        const matchFunc = m => {
            if (!m.match) {
                return false;
            }
            return isNumber(value) ? parseFloat(m.match) === parseFloat(value) : m.match === value; // If value is a number (either '1' or 1) match with the match config based on numeric value (either '1' or 1)
        };
        const matchResult = find(this.matches, matchFunc);
        const updatedValue = isUndefined(matchResult) ? defaultMatchValue : matchResult.value;
        const updatedType = getDataTypeForPoint(updatedValue);
        return { value: updatedValue, type: updatedType };
    }
}
