// this is a copy of RadialGraph.jsx @ 23.5.0 before the design changes to PureSingleValueRadial were rolled out
import React from 'react';
import T from 'prop-types';
import { RADIAL_BACKGROUND_COLOR, RADIAL_STROKE_COLOR } from '@splunk/visualizations-shared/colorConstants';

const RadialGraph = ({
    progress,
    size = 200,
    strokeWidth,
    backgroundColor = RADIAL_BACKGROUND_COLOR,
    progressColor = RADIAL_STROKE_COLOR,
}) => {
    const normalizedSize = size / 2;
    const normalizedRadius = normalizedSize - strokeWidth * 2;
    const circumference = normalizedRadius * 2 * Math.PI;
    const bgCircleOffset = 0.8 * circumference;

    const progress80 = (progress / 100) * 80;

    const progressWidth = (progress80 / 100) * circumference;

    // Offset the start of the circle to a position that makes the arc look evenly spaced
    // with the opening at the bottom
    // We use 80% of a circle (288deg), half on each side of "0" (144deg)
    // SVG starts drawing (0deg) at the 3 o'clock position, so add another 90 to offset this (234deg)
    // This means the arc length that we offset is 2 * PI * r * 234/360 or ...
    const strokeDashoffset = circumference * 0.65;

    return (
        <svg height={normalizedSize * 2} width={normalizedSize * 2}>
            <g>
                <circle
                    data-test="background-radial"
                    stroke={backgroundColor}
                    fill="transparent"
                    strokeWidth={strokeWidth}
                    strokeDasharray={`${bgCircleOffset} ${circumference - bgCircleOffset}`}
                    style={{ strokeDashoffset }}
                    r={normalizedRadius}
                    cx={normalizedSize}
                    cy={normalizedSize}
                />
                <circle
                    data-test="progress-radial"
                    stroke={progressColor}
                    fill="transparent"
                    strokeWidth={strokeWidth}
                    strokeDasharray={`${progressWidth} ${circumference - progressWidth}`}
                    style={{ strokeDashoffset }}
                    r={normalizedRadius}
                    cx={normalizedSize}
                    cy={normalizedSize}
                />
            </g>
        </svg>
    );
};

RadialGraph.propTypes = {
    progress: T.number,
    size: T.number,
    strokeWidth: T.number,
    backgroundColor: T.string,
    progressColor: T.string,
};

RadialGraph.defaultProps = {
    progress: 0,
    strokeWidth: 12,
};

export default RadialGraph;
