import { _ } from '@splunk/ui-utils/i18n';
import { COLOR_OR_TOKEN_PATTERN_WITH_RGBA, getPattern } from '@splunk/visualizations-shared/schemaUtils';
import { Punchcard as PunchcardIcon } from '@splunk/visualization-icons';
import { Punchcard as PunchcardPlaceholderIcon } from '@splunk/visualization-icons/placeholders';
import { VIZ_CATEGORICAL } from '@splunk/visualization-color-palettes';
import pick from '@splunk/themes/pick';
import variables from '@splunk/themes/variables';

import { DataContract } from '../common/interfaces/DataContract';
import { DefaultContext } from '../common/interfaces/DefaultContext';
import { EditorConfig } from '../common/interfaces/Editor';
import { OptionsSchema } from '../common/interfaces/OptionsSchema';
import { ThemedDefaults } from '../common/interfaces/ThemedDefaults';
import { VizBehavior } from '../common/interfaces/VizBehavior';
import { VizCategory } from '../common/interfaces/VizCategory';
import { VizConfig } from '../common/interfaces/VizConfig';
import { VizSize } from '../common/interfaces/VizSize';
import { enhanceConfig } from '../common/utils/configUtils';
import getBackgroundColorEditor from '../common/editorConfig/BackgroundColor';

const dataContract: DataContract = {
    requiredDataSources: [
        {
            name: 'primary',
            description: 'DataSource that powers the visualization',
        },
    ],
    optionalDataSources: [
        // Note: SCP-14090 disable ui for optional datasources. We may need to add it back later
        // {
        //     name: 'annotation',
        //     description: 'DataSource that populate event annotations',
        // },
    ],
    initialRequestParams: {
        primary: { offset: 0, count: 10000 },
    },
};

const size: VizSize = {
    initialWidth: 300,
    initialHeight: 300,
};

const defaultContext: DefaultContext = {
    bubbleColorConfig: {
        colors: ['rgba(123,86,219,0.4)', 'rgba(123,86,219,1)'],
    },
};

const optionsSchema: OptionsSchema = {
    x: {
        default: '> primary | seriesByIndex(0)',
        description: 'Specify the dataSource applied to the x-axis.',
        type: 'array',
        items: { type: ['string', 'number'] },
    },
    y: {
        default: '> primary | seriesByIndex(1)',
        description: 'Specify the dataSource applied to the y-axis.',
        type: 'array',
        items: { type: ['string', 'number'] },
    },
    category: {
        default: '> primary | seriesByIndex(3)',
        description: 'Specify the dataSource to apply series categories.',
        type: 'array',
        items: { type: ['string', 'number'] },
    },
    size: {
        default: '> primary | seriesByIndex(2)',
        description: 'Specify the dataSource to apply bubble size in the chart.',
        type: 'array',
        items: { type: 'number' },
    },
    xField: {
        default: '> x | getField()',
        description: 'Specify the field that should be mapped to the x-axis.',
        type: 'string',
    },
    yField: {
        default: '> y | getField()',
        description: 'Specify the field that should be mapped to the y-axis.',
        type: 'string',
    },
    categoryField: {
        default: '> category | getField()',
        description: 'Specify the field that should be mapped to the series categories.',
        type: 'string',
    },
    sizeField: {
        default: '> size | getField()',
        description: 'Specify the field that should be mapped to the bubble size in the chart.',
        type: 'string',
    },
    backgroundColor: {
        default: '> themes.defaultBackgroundColor',
        description:
            'Specify the color used for the background. The default for enterprise light is "#ffffff". The default for enterprise dark is "#000000". The default for prisma dark is "#0b0c0e".',
        pattern: COLOR_OR_TOKEN_PATTERN_WITH_RGBA,
        type: 'string',
    },
    bubbleColor: {
        default: '> size | gradient(bubbleColorConfig)',
        description:
            'Specify the coloring method used for the bubbles when the colorMode “dynamic” is specified. For example “> size | gradient(bubbleColorConfig)".',
        type: ['string', 'array'],
    },
    bubbleLabelDisplay: {
        default: 'all',
        description:
            'Specify whether all bubble labels, the max value bubble labels, or none of the bubble labels should be displayed.',
        pattern: getPattern(['all', 'max', 'off']),
        type: 'string',
    },
    bubbleRadiusMax: {
        default: 15,
        description:
            'Specify the max radius (in pixels) of the bubbles when showDynamicBubbleSize option is false. Greater than 0.',
        type: 'number',
    },
    bubbleRadiusMin: {
        default: 1,
        description:
            'Specify the min radius (in pixels) of the bubbles when showDynamicBubbleSize option is false. Greater than 0.',
        type: 'number',
    },
    bubbleRowScale: {
        default: 'global',
        description: 'Specify how bubbles are scaled relative to other rows.',
        pattern: getPattern(['global', 'row']),
        type: 'string',
    },
    bubbleSizeMax: {
        default: 1,
        description:
            'Specify the max percentage (in decimal format) of space a bubble should take up within a cell when showDynamicBubbleSize option is true. The number specified must be between 0 and 1.',
        type: 'number',
    },
    bubbleSizeMin: {
        default: 0.25,
        description:
            'Specify the min percentage (in decimal format) of space a bubble should take up within a cell when showDynamicBubbleSize option is true. The number specified must be between 0 and 1.',
        type: 'number',
    },
    bubbleSizeMethod: {
        default: 'area',
        description: 'Specify whether bubble area or diameter corresponds to the size value.',
        pattern: getPattern(['radius', 'area']),
        type: 'string',
    },
    colorMode: {
        default: 'dynamic',
        description: 'Specify the coloring method used for the bubbles.',
        pattern: getPattern(['categorical', 'dynamic']),
        type: 'string',
    },
    legendDisplay: {
        default: 'right',
        description: 'Specify the location of the legend on the panel.',
        pattern: getPattern(['right', 'off']),
        type: 'string',
    },
    seriesColors: {
        default: VIZ_CATEGORICAL,
        description: 'Specify the colors used for a series. For example, "#FF0000", "#0000FF", "#008000".',
        type: 'array',
        items: {
            type: 'string',
        },
    },
    showDefaultSort: {
        default: false,
        description: 'Specify whether axes should be sorted based on order of time, digits and strings.',
        type: 'boolean',
    },
    showDynamicBubbleSize: {
        default: true,
        description: 'Specify whether the bubble size should be dynamic or fixed.',
        type: 'boolean',
    },
    showMaxValuePulsation: {
        default: true,
        description: 'Specify whether the max value bubble should pulsate.',
        type: 'boolean',
    },
};

const themes: ThemedDefaults = {
    defaultBackgroundColor: props =>
        pick({
            enterprise: {
                dark: variables.black(props),
                light: variables.backgroundColor(props),
            },
            prisma: variables.backgroundColorSidebar(props),
        })(props),
};

const editorConfig: EditorConfig[] = [
    {
        label: _('Data display'),
        layout: [
            [
                {
                    label: _('Show bubble labels'),
                    option: 'bubbleLabelDisplay',
                    editor: 'editor.radioBar',
                    editorProps: {
                        values: [
                            { label: _('All'), value: 'all' },
                            { label: _('Max'), value: 'max' },
                            { label: _('Off'), value: 'off' },
                        ],
                    },
                },
            ],
            [
                {
                    label: _('Pulsate max value'),
                    option: 'showMaxValuePulsation',
                    editor: 'editor.toggle',
                },
            ],
            [
                {
                    label: _('Bubble scale'),
                    option: 'bubbleSizeMethod',
                    editor: 'editor.radioBar',
                    editorProps: {
                        values: [
                            { label: _('Radius'), value: 'radius' },
                            { label: _('Area'), value: 'area' },
                        ],
                    },
                },
            ],
            [
                {
                    label: _('Bubble row scale'),
                    option: 'bubbleRowScale',
                    editor: 'editor.radioBar',
                    editorProps: {
                        values: [
                            { label: _('Global'), value: 'global' },
                            { label: _('By Row'), value: 'row' },
                        ],
                    },
                },
            ],
        ],
    },
    {
        label: _('Color and style'),
        layout: [
            [
                {
                    label: _('Color mode'),
                    option: 'colorMode',
                    editor: 'editor.radioBar',
                    editorProps: {
                        values: [
                            { label: _('Dynamic'), value: 'dynamic' },
                            { label: _('Categorical'), value: 'categorical' },
                        ],
                    },
                },
            ],
            [
                // This should be hidden in categorical color mode
                {
                    label: _('Color by field'),
                    editor: 'editor.columnSelector',
                    option: 'bubbleColor',
                    context: 'bubbleColorConfig',
                    showEditor: ({ options }) =>
                        options.colorMode === 'dynamic' || options.colorMode === undefined,
                    editorProps: {
                        dataSourceKey: 'primary',
                        prepareValue: (
                            definition
                        ): { context: Record<string, any>; options: Record<string, any> } => {
                            const {
                                options: { bubbleColor },
                            } = definition;
                            return {
                                context: {},
                                options: {
                                    bubbleColor: `${bubbleColor} | gradient(bubbleColorConfig)`,
                                },
                            };
                        },
                    },
                },
            ],
            getBackgroundColorEditor({ themes }),
            [
                {
                    label: _('Legend display'),
                    option: 'legendDisplay',
                    editor: 'editor.select',
                    editorProps: {
                        values: [
                            { label: _('Right'), value: 'right' },
                            { label: _('Off'), value: 'off' },
                        ],
                    },
                    showEditor: ({ options }) => options.colorMode === 'categorical',
                },
            ],
        ],
    },
];

/**
 * visualization configuration
 */
const config: VizConfig = {
    /**
     * unique viz key
     */
    key: 'splunk.punchcard',
    /**
     * viz name
     */
    name: 'Punchcard',
    category: VizCategory.COMPARISONS,
    /**
     * viz icon
     */
    icon: PunchcardIcon,
    placeholderIcon: PunchcardPlaceholderIcon,
    dataContract,
    size,
    defaultContext,
    optionsSchema,
    editorConfig,
    events: {
        'legend.click': {
            description: 'Triggered when user clicks a chart legend',
        },
        'point.click': {
            description: 'Triggered when user clicks a data point',
        },
    },
    supports: [VizBehavior.DYNAMIC_OPTIONS, VizBehavior.PLACEHOLDER, VizBehavior.EVENTS],
    themes,
    requiredProps: ['x', 'y', 'size'],
};

export default enhanceConfig(config);
