/* eslint-disable class-methods-use-this,no-useless-constructor */
import React from 'react';
import { renderToStaticMarkup } from 'react-dom/server';

import { _ } from '@splunk/ui-utils/i18n';
import Provider from '@splunk/visualization-context/Provider';

import {
    ActiveDirectory,
    Check,
    Cloud,
    Datacenter,
    Datacenters,
    Datastore,
    Datastores,
    Desktop,
    Firewall,
    Gear,
    Laptop,
    LoadBalancer,
    Mobile,
    Profile,
    Server,
    Users,
} from '@splunk/dashboard-icons';

export const toDataURI = (SVG: React.FC): string =>
    `data:image/svg+xml;base64,${btoa(renderToStaticMarkup(<SVG />))}`;

interface Icon {
    dataURI: string;
    metaData: Record<string, string>;
}
type IconMap = Record<string, Icon>;
export const defaultIconList: IconMap = {
    activeDirectory: {
        dataURI: toDataURI(ActiveDirectory),
        metaData: {
            name: 'Active Directory',
        },
    },
    check: {
        dataURI: toDataURI(Check),
        metaData: {
            name: 'Check',
        },
    },
    cloud: {
        dataURI: toDataURI(Cloud),
        metaData: {
            name: 'Cloud',
        },
    },
    datacenter: {
        dataURI: toDataURI(Datacenter),
        metaData: {
            name: 'Datacenter',
        },
    },
    datacenters: {
        dataURI: toDataURI(Datacenters),
        metaData: {
            name: 'Datacenters',
        },
    },
    datastore: {
        dataURI: toDataURI(Datastore),
        metaData: {
            name: 'Datastore',
        },
    },
    datastores: {
        dataURI: toDataURI(Datastores),
        metaData: {
            name: 'Datastores',
        },
    },
    desktop: {
        dataURI: toDataURI(Desktop),
        metaData: {
            name: 'Desktop',
        },
    },
    firewall: {
        dataURI: toDataURI(Firewall),
        metaData: {
            name: 'Firewall',
        },
    },
    gear: {
        dataURI: toDataURI(Gear),
        metaData: {
            name: 'Gear',
        },
    },
    laptop: {
        dataURI: toDataURI(Laptop),
        metaData: {
            name: 'Laptop',
        },
    },
    loadBalancer: {
        dataURI: toDataURI(LoadBalancer),
        metaData: {
            name: 'Load Balancer',
        },
    },
    mobile: {
        dataURI: toDataURI(Mobile),
        metaData: {
            name: 'Mobile',
        },
    },
    profile: {
        dataURI: toDataURI(Profile),
        metaData: {
            name: 'Profile',
        },
    },
    server: {
        dataURI: toDataURI(Server),
        metaData: {
            name: 'Server',
        },
    },
    users: {
        dataURI: toDataURI(Users),
        metaData: {
            name: 'Users',
        },
    },
};

/**
 * a local icon provider
 */
class StandardIconProvider extends Provider {
    iconList: IconMap;

    constructor({ iconList }: { iconList?: IconMap } = {}) {
        super();

        this.iconList = {
            ...iconList,
            ...defaultIconList,
        };
    }

    /**
     * return provider type
     * @return {String} 'splunk-default'
     */
    getType(): string {
        return 'splunk-default';
    }

    /**
     * upload one new icon
     */
    async upload(): Promise<void> {
        throw new Error(
            _('Can not upload icons to the standard icon provider.')
        );
    }

    /**
     * get one icon by icon id
     */
    async getById(id: string): Promise<Icon> {
        if (this.iconList[id]) {
            return this.iconList[id];
        }
        throw new Error(_('The icon is not found.'));
    }

    /**
     * get all icon ids
     * @params options
     * @params options.meta   a list of meta data attribute that would be returned with each id
     * @params options.search  a search string to filter the returned icons
     */
    async listIds({
        offset = 0,
        count = 100,
        meta = [],
        search = null,
    }: {
        offset?: number;
        count?: number;
        meta?: string[];
        search?: string | null;
    } = {}): Promise<Record<string, unknown>[]> {
        try {
            const icons = this.iconList;
            const results: Record<string, unknown>[] = [];

            Object.keys(icons)
                .sort()
                .slice(offset, count)
                .forEach((id) => {
                    const icon: {
                        id: string;
                        metaData?: Record<string, unknown>;
                    } = { id };
                    const iconMeta = icons[id].metaData || {};
                    // If search is a valid string, return the icon with matched meta data
                    const isSearchedIcon =
                        search != null &&
                        Object.keys(iconMeta).some((attr) => {
                            const iconMetaValue = iconMeta[attr].toLowerCase();
                            return (
                                iconMetaValue.indexOf(search.toLowerCase()) > -1
                            );
                        });

                    if (search == null || isSearchedIcon) {
                        if (meta && meta.length) {
                            icon.metaData = {};
                            meta.forEach((attr) => {
                                if (iconMeta[attr] != null && icon.metaData) {
                                    icon.metaData[attr] = iconMeta[attr];
                                }
                            });
                        }

                        results.push(icon);
                    }
                });

            return results;
        } catch (error) {
            throw new Error(_('The icon ids cannot be listed.'));
        }
    }

    /**
     * delete an icon by icon id
     */
    async deleteById(): Promise<void> {
        throw new Error(_('The icon cannot be deleted.'));
    }
}

export default StandardIconProvider;
