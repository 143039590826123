import isNumber from 'lodash/isNumber';

type StyleInput = number | string | null | undefined;

/**
 * convert number to px
 * @param {number | string | null | undefined} number
 * @param {Number} defaultNumber
 * @returns {String}
 */
export const toPx = (
    num: StyleInput,
    defaultNumber: number | string = 0
): string => (isNumber(num) ? `${num}px` : num || toPx(defaultNumber));

interface DimensionArg {
    width?: StyleInput;
    height?: StyleInput;
}

/**
 * generate width/height css properties
 * @param {Object} obj
 * @param {Number | String | null | undefined} obj.width
 * @param {Number | String | null | undefined} obj.height
 * @returns {String}
 */
export const toDimension = ({ width, height }: DimensionArg): string => {
    let css = '';
    if (width || width === 0) {
        css = `
        width: ${toPx(width)};
        `;
    }
    if (height || height === 0) {
        css = `
        ${css}
        height: ${toPx(height)};
        `;
    }
    return css;
};

/**
 * generate margin css property
 */
export const arrayToCSSProp = (
    prop: string,
    values: StyleInput | StyleInput[] = [0, 0, 0, 0]
): string => {
    if (Array.isArray(values)) {
        return `
            ${prop}: ${toPx(values[0])} ${toPx(values[1])} ${toPx(
            values[2]
        )} ${toPx(values[3])};
        `;
    }

    return `${prop}: ${toPx(values)};`;
};

/**
 * generate margin css property
 */
export const toMargin = (
    margins: StyleInput | StyleInput[] = [0, 0, 0, 0]
): ReturnType<typeof arrayToCSSProp> => arrayToCSSProp('margin', margins);

/**
 * generate padding css property
 */
export const toPadding = (
    paddings: StyleInput | StyleInput[] = [0, 0, 0, 0]
): ReturnType<typeof arrayToCSSProp> => arrayToCSSProp('padding', paddings);
