/* eslint-disable class-methods-use-this */
import { _ } from '@splunk/ui-utils/i18n';
import Provider from '@splunk/visualization-context/Provider';

/**
 * Base Registry
 */
class BaseRegistry {
    constructor() {
        this.cachedProviders = {};
        this.defaultProvider = null;
    }

    static create() {
        return new BaseRegistry();
    }

    /**
     * check whether user defined provider extends the base provider
     * @param {Provider} provider
     * @returns {Boolean}
     */
    isValidProvider(provider) {
        if (provider instanceof Provider) {
            return true;
        }
        throw new Error(_('Invalid Provider'));
    }

    /**
     * add default provider to cache
     * @param {Provider} provider
     */
    addDefaultProvider(provider) {
        if (this.isValidProvider(provider)) {
            this.addProvider(provider);
            this.defaultProvider = provider;
        }
    }

    /**
     * add provider to cache
     * @param {Provider} provider
     */
    addProvider(provider) {
        if (this.isValidProvider(provider)) {
            if (this.cachedProviders[provider.getType()]) {
                // eslint-disable-next-line
                console.warn(`The provider "${provider.getType()}" existed already.`);
            }
            this.cachedProviders[provider.getType()] = provider;
        }
    }

    /**
     * delete provider
     * @param {String} providerType
     */
    deleteProvider(providerType) {
        const cachedProvider = this.cachedProviders[providerType];
        if (!cachedProvider) {
            // eslint-disable-next-line
            console.warn(`The provider "${providerType}" does not existed`);
            return;
        }

        if (this.defaultProvider && providerType === this.defaultProvider.getType()) {
            this.defaultProvider = null;
        }
        delete this.cachedProviders[providerType];
    }

    /**
     * find provider from cache
     * @param {String} type
     * @returns {Provider} provider
     */
    findProvider(type) {
        const provider = this.cachedProviders[type];
        if (provider) {
            return provider;
        }
        throw new Error(_('Provider not found'));
    }

    /**
     * list all providers
     * @return {Object} provider = { type: 'local', isDefault: true }
     */
    listProviders() {
        return Object.keys(this.cachedProviders).map(type => ({
            type,
            isDefault: type === this.defaultProvider.getType(),
        }));
    }

    /**
     * check whether src is a http url or a url used for icon/image-registry (e.g. 'kvstore://image123')
     */
    isResourceURL(url) {
        if (!url || url.indexOf('://') < 0) {
            return false;
        }

        const { type } = this.parseURL(url);
        return type !== 'http' && type !== 'https';
    }

    /**
     * parse provider type and id from the url
     * @params {String} url     'kvstore://image123'
     * @returns {Object} obj
     * @returns {String} obj.type   'kvstore'
     * @returns {String} obj.id     'image123'
     */
    parseURL(url) {
        if (url) {
            const [type, id] = url.split('://');

            if (type && id) {
                return { type, id };
            }
        }
        throw new Error(_('Invalid url'));
    }

    /**
     * upload
     * @param {DataURI} dataURI
     * @param {Object} metaData
     * @param {String} providerType      e.g. 'kvstore' or 'sui' or 'local'
     * @returns {String} id               e.g. 'kvstore://image123'
     */
    async upload(dataURI, metaData, providerType) {
        if (providerType == null && this.defaultProvider == null) {
            throw new Error(_('No valid provider was found. Please add a provider'));
        }
        const provider = providerType ? this.findProvider(providerType) : this.defaultProvider;
        const id = await provider.upload(dataURI, metaData);
        return `${provider.getType()}://${id}`;
    }

    /**
     * get object by url
     * @param {String} url     'kvstore://image123'
     * @returns {Object} obj    e.g. an image object or icon
     */
    async getByURL(url) {
        const { type, id } = this.parseURL(url);
        const provider = this.findProvider(type);
        const obj = await provider.getById(id);
        return obj;
    }

    /**
     * delete object by url
     * @param {String} url     'kvstore://image123' || 'sui://icon123'
     * @returns {Object} obj    e.g. an image object or icon
     */
    async deleteByURL(url) {
        const { type, id } = this.parseURL(url);
        const provider = this.findProvider(type);
        const obj = await provider.deleteById(id);
        return obj;
    }

    /**
     * list all object ids
     * @params {String} providerType       e.g. 'kvstore' or 'sui'
     * @params {Object} options
     * @returns {Array} ids
     */
    async listIds(providerType, options = { offset: 0, count: 100 }) {
        const provider = this.findProvider(providerType);
        const ids = await provider.listIds(options);
        return ids;
    }
}

export default BaseRegistry;
