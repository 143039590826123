import * as React from 'react';
import { _ } from '@splunk/ui-utils/i18n';
import SVG from '@splunk/react-icons/SVGEnterprise';

const SingleValueTrend = (props: React.ComponentProps<typeof SVG>): React.ReactElement<SVGElement> => (
    <SVG screenReaderText={_('Single Value')} viewBox="0 0 18 18" {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M11.2095 0.673234C11.6662 0.730689 11.9898 1.14744 11.9323 1.60408L11.8406 2.33329H12.3337C12.7939 2.33329 13.167 2.70639 13.167 3.16663C13.167 3.62686 12.7939 3.99996 12.3337 3.99996H11.6309L11.4212 5.66663H12.3337C12.7939 5.66663 13.167 6.03972 13.167 6.49996C13.167 6.9602 12.7939 7.33329 12.3337 7.33329H11.2115L11.0935 8.27075C11.036 8.72738 10.6193 9.05098 10.1627 8.99353C9.70602 8.93607 9.38242 8.51932 9.43988 8.06268L9.53165 7.33329H7.86185L7.7439 8.27075C7.68644 8.72738 7.26969 9.05098 6.81305 8.99353C6.35641 8.93607 6.03281 8.51932 6.09027 8.06268L6.18204 7.33329H5.66699C5.20676 7.33329 4.83366 6.9602 4.83366 6.49996C4.83366 6.03972 5.20676 5.66663 5.66699 5.66663H6.39175L6.60145 3.99996H5.66699C5.20676 3.99996 4.83366 3.62686 4.83366 3.16663C4.83366 2.70639 5.20676 2.33329 5.66699 2.33329H6.81116L6.92909 1.39602C6.98654 0.939379 7.4033 0.615778 7.85993 0.673234C8.31657 0.730689 8.64017 1.14744 8.58272 1.60408L8.49096 2.33329H10.1608L10.2787 1.39602C10.3362 0.939379 10.7529 0.615778 11.2095 0.673234ZM8.07155 5.66663H9.74136L9.95106 3.99996H8.28126L8.07155 5.66663Z"
            fill="currentColor"
        />
        <path
            d="M17.1938 11.0378C17.4491 11.4207 17.3456 11.9381 16.9627 12.1934L10.9438 16.206C10.5239 16.4859 9.9769 16.4859 9.55703 16.206L6.50041 14.1683L1.96266 17.1934C1.57972 17.4487 1.06233 17.3452 0.807039 16.9623C0.551745 16.5794 0.655223 16.062 1.03816 15.8067L5.80703 12.6274C6.22691 12.3475 6.77391 12.3475 7.19378 12.6274L10.2504 14.6652L16.0382 10.8067C16.4211 10.5514 16.9385 10.6549 17.1938 11.0378Z"
            fill="currentColor"
        />
    </SVG>
);

export default SingleValueTrend;
