import type { MouseEvent as ReactMouseEvent } from 'react';
import React, { useState, useCallback, useMemo, useEffect } from 'react';
import styled from 'styled-components';
import { variables, pick } from '@splunk/themes';
import { useLayoutLayers } from '@splunk/dashboard-context';
import { noop } from '@splunk/dashboard-utils';
import type { Coordinate, Port, SelectedItem } from '@splunk/dashboard-types';
import {
    getOffset,
    getClientPosition,
    computeLineBoxPosition,
    computeLineRelativePosition,
} from '../utils/layoutUtils';
import type { RenderLayoutItem } from '../types';

interface LineBoxProps {
    zIndex?: number;
}

const LineBox = styled.div<LineBoxProps>`
    position: absolute;
    pointer-events: none;
    z-index: ${(props) => props.zIndex};
`;

interface AdjustHandleProps {
    x: number;
    y: number;
}

const AdjustHandle = styled.a.attrs(({ x, y }: AdjustHandleProps) => ({
    style: {
        transform: `translate(${x}px, ${y}px)`,
    },
}))<AdjustHandleProps>`
    width: 10px;
    height: 10px;
    left: -5px;
    top: -5px;
    background-color: ${pick({
        enterprise: variables.accentColorL10,
        prisma: variables.interactiveColorPrimary,
    })};
    border-radius: 50%;
    position: absolute;
    z-index: 999;
    pointer-events: auto;
    cursor: move;
    user-select: none;
`;

type HandleMouseMove = (
    e: MouseEvent,
    offset: ReturnType<typeof getOffset>
) => void;

export interface ResponsiveLineProps {
    fromX: number;
    fromY: number;
    fromItem?: string;
    fromPort?: Port;
    toX: number;
    toY: number;
    toItem?: string;
    toPort?: Port;
    lineId: string;
    scale?: number;
    selectable?: boolean;
    editable?: boolean;
    renderLayoutItem: RenderLayoutItem;
    onItemSelected?: (e: ReactMouseEvent, items: SelectedItem[]) => void;
    onLineDragStart?: (e: ReactMouseEvent, dir: 'from' | 'to') => void;
    onLineMove?: HandleMouseMove;
    onLineMoved?: HandleMouseMove;
    onLineDrag?: HandleMouseMove;
    onLineDragged?: HandleMouseMove;
}

type Action = 'move' | 'drag';

const ResponsiveLine = ({
    fromX,
    fromY,
    fromItem,
    fromPort,
    toX,
    toY,
    toItem,
    toPort,
    lineId,
    scale = 1,
    selectable = false,
    editable = false,
    renderLayoutItem,
    onItemSelected = noop,
    onLineDragStart = noop,
    onLineMove = noop,
    onLineMoved = noop,
    onLineDrag = noop,
    onLineDragged = noop,
}: ResponsiveLineProps): JSX.Element => {
    const [startPosition, setStartPosition] = useState<Coordinate | null>(null);
    const [action, setAction] = useState<Action | null>(null);

    const layerData = useLayoutLayers();
    const lineLayer = useMemo<number | undefined>(() => {
        const selectionLayer = Object.values(layerData ?? {})
            .map((entry) => entry.selection)
            .filter(Boolean)[0];

        // Render lines just above selection outlines
        return typeof selectionLayer === 'number'
            ? selectionLayer + 1
            : undefined;
    }, [layerData]);

    const handleLineSelected = useCallback(
        (e: ReactMouseEvent) => {
            e.stopPropagation();

            setStartPosition(getClientPosition(e, scale));
            setAction('move');

            onItemSelected(e, [{ id: lineId, type: 'line' }]);
        },
        [lineId, onItemSelected, scale]
    );

    const handleMouseDownFrom = useCallback(
        (e: ReactMouseEvent) => {
            e.stopPropagation();

            setStartPosition(getClientPosition(e, scale));
            setAction('drag');

            onLineDragStart(e, 'from');
        },
        [onLineDragStart, scale]
    );

    const handleMouseDownTo = useCallback(
        (e: ReactMouseEvent) => {
            e.stopPropagation();

            setStartPosition(getClientPosition(e, scale));
            setAction('drag');

            onLineDragStart(e, 'to');
        },
        [onLineDragStart, scale]
    );

    const handleMouseMove = useCallback(
        (e: MouseEvent) => {
            if (startPosition == null || action == null) {
                return;
            }

            e.preventDefault();
            const currentPosition = getClientPosition(e, scale);
            const offset = getOffset(currentPosition, startPosition);
            // don't call any callbacks if the line hasn't actually moved
            if (!offset.offsetX && !offset.offsetY) {
                return;
            }
            switch (action) {
                case 'drag':
                    onLineDrag(e, offset);
                    break;
                case 'move':
                    onLineMove(e, offset);
                    break;
                default:
                    break;
            }
        },
        [action, onLineDrag, onLineMove, scale, startPosition]
    );

    const handleMouseUp = useCallback(
        (e: MouseEvent) => {
            if (startPosition == null || action == null) {
                return;
            }

            setStartPosition(null);

            const currentPosition = getClientPosition(e, scale);
            const offset = getOffset(currentPosition, startPosition);
            // don't call any callbacks if the line hasn't actually moved
            if (!offset.offsetX && !offset.offsetY) {
                return;
            }

            switch (action) {
                case 'drag':
                    onLineDragged(e, offset);
                    break;
                case 'move':
                    onLineMoved(e, offset);
                    break;
                default:
                    break;
            }
        },
        [action, onLineDragged, onLineMoved, scale, startPosition]
    );

    const from = { x: fromX, y: fromY };
    const to = { x: toX, y: toY };
    const boxPos = computeLineBoxPosition(from, to);
    const relativePos = computeLineRelativePosition(from, to, boxPos);

    const lineBoxStyle = useMemo(
        () => ({
            transform: `translate(${boxPos.x}px, ${boxPos.y}px)`,
        }),
        [boxPos.x, boxPos.y]
    );

    const fromHandleElement = useMemo(() => {
        if (!editable) {
            return null;
        }

        return (
            <AdjustHandle
                data-test="line-handle-from"
                data-test-item={fromItem}
                data-test-port={fromPort}
                x={relativePos.from.x}
                y={relativePos.from.y}
                onMouseDown={handleMouseDownFrom}
            />
        );
    }, [
        editable,
        fromItem,
        fromPort,
        relativePos.from.x,
        relativePos.from.y,
        handleMouseDownFrom,
    ]);

    const toHandleElement = useMemo(() => {
        if (!editable) {
            return null;
        }

        return (
            <AdjustHandle
                data-test="line-handle-to"
                data-test-item={toItem}
                data-test-port={toPort}
                x={relativePos.to.x}
                y={relativePos.to.y}
                onMouseDown={handleMouseDownTo}
            />
        );
    }, [
        editable,
        toItem,
        toPort,
        relativePos.to.x,
        relativePos.to.y,
        handleMouseDownTo,
    ]);

    const lineElement = useMemo(
        () =>
            renderLayoutItem(
                lineId,
                {
                    from: {
                        x: relativePos.from.x,
                        y: relativePos.from.y,
                    },
                    to: {
                        x: relativePos.to.x,
                        y: relativePos.to.y,
                    },
                    // todo: this is inconsistent with the ResponsiveBlockItem which accepts the `onSelect` callback as the 4th argument of `renderLayoutItem`.
                    onLineSelect: selectable ? handleLineSelected : noop,
                },
                'line'
            ),
        [
            handleLineSelected,
            lineId,
            relativePos.from.x,
            relativePos.from.y,
            relativePos.to.x,
            relativePos.to.y,
            renderLayoutItem,
            selectable,
        ]
    );

    useEffect(() => {
        if (editable) {
            document.addEventListener('mousemove', handleMouseMove);
            document.addEventListener('mouseup', handleMouseUp);
        }

        return () => {
            document.removeEventListener('mousemove', handleMouseMove);
            document.removeEventListener('mouseup', handleMouseUp);
        };
    }, [editable, handleMouseMove, handleMouseUp]);

    return (
        <LineBox
            data-test="line-box"
            style={lineBoxStyle}
            data-viz-type="abslayout.line"
            data-id={lineId}
            data-test-line-position={`${from.x},${from.y}-${to.x},${to.y}`}
            zIndex={lineLayer}
        >
            {fromHandleElement}
            {toHandleElement}
            {lineElement}
        </LineBox>
    );
};

export default ResponsiveLine;
