import moment from '@splunk/moment';
import { DataType } from '../DataPrimitive';
import { isNumber } from './types';

/*
 * @method setDefaultValue
 * @param {DataType} defaultValue
 * @returns {DataType} valid default value
 */
export const setDefaultValue = (defaultValue): DataType => {
    if (isNumber(defaultValue)) {
        return defaultValue;
    }
    return defaultValue || '';
};

const timeToSplunkMoment = (time: string | number): typeof moment => {
    // if splunk time zone is set, create time in splunk timezone and locale
    if (moment.getDefaultSplunkTimezone()) {
        return moment.newSplunkTime({ time });
    }
    // fallback to moment default
    return moment(time);
};

export const formatTimeWithTimezoneCorrection = (
    time: string | number,
    format: string = moment.defaultFormat
) => {
    const utcOffset = moment.parseZone(time).utcOffset();
    const momentTime = timeToSplunkMoment(time).utcOffset(utcOffset);
    // @TODO(pwied): splunkFormat would format based on splunk's locale.
    return momentTime.format(format);
};
