import { _ } from '@splunk/ui-utils/i18n';
import type {
    ErrorPayload,
    OnDataCallback,
    SearchData,
    RequestParams,
    RefreshFunction,
    DataSourceSubscription,
    BindingType,
    ISearchModule,
} from '@splunk/dashboard-types';

// ex. Subscribers: { ds_1: { viz_1: { viz_1: onUpdate }}}
//  the additional viz_1 refers to the subscriberId
type Subscribers = Record<
    string,
    Record<string, Record<string, OnDataCallback>>
>;
// ex. StateData: { ds_1: { viz_1: data }}
type StateData = Record<string, Record<string, SearchData | ErrorPayload>>;
// ex. Subscriptions: { ds_1: { viz_1: sub }}
type Subscriptions = Record<string, Record<string, DataSourceSubscription>>;

interface HandleDataArg {
    dsId: string;
    consumerId: string;
    bindingType: BindingType;
}

interface SubscribeArgs {
    dsId: string;
    consumerId: string;
    bindingType: BindingType;
    subscriberId: string;
    onUpdate: OnDataCallback;
}

interface CreateSearchAndSubscribeArgs extends SubscribeArgs {
    initialRequestParams: RequestParams;
}

interface RefreshArgs {
    consumerId: string;
    bindingType: BindingType;
    dsId: string;
    options?: Parameters<RefreshFunction>[0];
}

interface UpdateRequestParamsArgs {
    dsId: string;
    consumerId: string;
    bindingType: BindingType;
    requestParams: RequestParams;
}

export type UnsubscribeFn = () => void;

interface CreateSearchArgs {
    dsId: string;
    consumerId: string;
    bindingType: BindingType;
    initialRequestParams: RequestParams;
}
type UpdateSearchArgs = CreateSearchArgs;
type RemoveSearchArgs = Omit<CreateSearchArgs, 'initialRequestParams'>;

interface ISearchProvider {
    createSearch({
        dsId,
        consumerId,
        bindingType,
        initialRequestParams,
    }: CreateSearchArgs): Promise<void>;
    createSearchAndSubscribe(args: CreateSearchAndSubscribeArgs): UnsubscribeFn;
    refresh(args: RefreshArgs): void;
    removeSearch({ dsId, consumerId, bindingType }: RemoveSearchArgs): void;
    subscribe(args: SubscribeArgs): UnsubscribeFn;
    teardown(): void;
    updateRequestParams(args: UpdateRequestParamsArgs): void;
    updateSearch({
        dsId,
        consumerId,
        bindingType,
        initialRequestParams,
    }: UpdateSearchArgs): Promise<void>;
}

const getConsumerId = ({
    consumerId,
    bindingType,
}: {
    consumerId: string;
    bindingType: BindingType;
}) => `${consumerId}-${bindingType}`;

export class SearchProvider implements ISearchProvider {
    private data: StateData = {};

    private subscriptions: Subscriptions = {};

    private subscribers: Subscribers = {};

    private searchModule: ISearchModule<DataSourceSubscription>;

    constructor({
        searchModule,
    }: {
        searchModule: ISearchModule<DataSourceSubscription>;
    }) {
        this.searchModule = searchModule;
    }

    private handleUpdateSubscribers({
        dsId,
        consumerId,
        bindingType,
        payload,
    }: HandleDataArg & {
        payload: SearchData;
    }): void {
        const augmentedConsumerId = getConsumerId({
            consumerId,
            bindingType,
        });
        this.data[dsId] ??= {};
        this.data[dsId][augmentedConsumerId] = payload;

        // subs is a Record<subscriberId, onDataCallback>
        const subs = this.subscribers[dsId]?.[augmentedConsumerId] ?? {};
        Object.values(subs).forEach((onUpdate) => {
            onUpdate(payload);
        });
    }

    private handleDataFactory(arg0: HandleDataArg) {
        return (data: SearchData) =>
            this.handleUpdateSubscribers({ ...arg0, payload: data });
    }

    private handleErrorFactory(arg0: HandleDataArg) {
        return ({ meta, level, message }: ErrorPayload) =>
            this.handleUpdateSubscribers({
                ...arg0,
                payload: { error: { level, message }, meta },
            });
    }

    async createSearch({
        dsId,
        consumerId,
        bindingType,
        initialRequestParams,
    }: CreateSearchArgs) {
        const handleError = this.handleErrorFactory({
            dsId,
            consumerId,
            bindingType,
        });
        try {
            const augmentedConsumerId = getConsumerId({
                consumerId,
                bindingType,
            });

            const sub = await this.searchModule.create({
                consumerId: augmentedConsumerId,
                dataSourceId: dsId,
                onData: this.handleDataFactory({
                    dsId,
                    consumerId,
                    bindingType,
                }),
                onError: handleError,
                initialRequestParams,
            });
            // keep track of Subscriptions in order to cancel/refresh
            this.subscriptions[dsId] ??= {};
            this.subscriptions[dsId][augmentedConsumerId] = sub;
        } catch (error) {
            if (error instanceof Error) {
                handleError({
                    level: 'error',
                    message: error.message,
                });
            }
        }
    }

    async updateSearch({
        dsId,
        consumerId,
        bindingType,
        initialRequestParams,
    }: UpdateSearchArgs) {
        const augmentedConsumerId = getConsumerId({
            consumerId,
            bindingType,
        });

        try {
            const sub = this.subscriptions[dsId][augmentedConsumerId];

            this.subscriptions[dsId][augmentedConsumerId] =
                await this.searchModule.update({
                    consumerId: augmentedConsumerId,
                    dataSourceId: dsId,
                    subscription: sub,
                    initialRequestParams,
                });
        } catch (error) {
            if (error instanceof Error) {
                this.handleErrorFactory({
                    dsId,
                    consumerId,
                    bindingType,
                })({
                    level: 'error',
                    message: error.message,
                });
            }
        }
    }

    removeSearch({ dsId, consumerId, bindingType }: RemoveSearchArgs) {
        const augmentedConsumerId = getConsumerId({
            consumerId,
            bindingType,
        });
        const subscription = this.subscriptions[dsId]?.[augmentedConsumerId];

        // cancel the subscription if it exists. If there was an error creating it, it will not exist
        //   and therefore does not need to be cleaned up
        if (subscription) {
            this.searchModule.cancel({ subscription });
        }

        // clean up subscription object
        delete this.subscriptions[dsId]?.[augmentedConsumerId];
        // if there are no more subscriptions for this dsId
        if (!Object.keys(this.subscriptions[dsId] ?? {}).length) {
            delete this.subscriptions[dsId];
        }
        // remove stored data for this search
        delete this.data[dsId]?.[augmentedConsumerId];
        // if there's no more data for this dsId
        if (!Object.keys(this.data[dsId] ?? {}).length) {
            delete this.data[dsId];
        }
    }

    teardown() {
        Object.values(this.subscriptions).forEach((bindings) => {
            Object.values(bindings).forEach((subscription) => {
                this.searchModule.cancel({ subscription });
            });
        });
    }

    createSearchAndSubscribe({
        dsId,
        consumerId,
        bindingType,
        initialRequestParams,
        subscriberId,
        onUpdate,
    }: CreateSearchAndSubscribeArgs) {
        const augmentedConsumerId = getConsumerId({
            consumerId,
            bindingType,
        });

        if (
            typeof this.subscriptions[dsId]?.[augmentedConsumerId] !==
            'undefined'
        ) {
            // trying to create a search that already exists
            throw new Error(
                `${_('The search')} ${dsId} ${_(
                    'created for consumerId'
                )} ${consumerId} ${_('and bindingType')} ${bindingType} ${_(
                    'already exists. In order to avoid a memory leak, unsubscribe and cancel the existing search before creating it again.'
                )}`
            );
        }

        const unsubscribe = this.subscribe({
            dsId,
            consumerId,
            bindingType,
            subscriberId,
            onUpdate,
        });

        this.createSearch({
            dsId,
            consumerId,
            bindingType,
            initialRequestParams,
        });

        return () => {
            // unsubscribe from the search
            unsubscribe();
            // remove the search
            this.removeSearch({ dsId, consumerId, bindingType });
        };
    }

    subscribe({
        dsId,
        consumerId,
        bindingType,
        subscriberId,
        onUpdate,
    }: SubscribeArgs) {
        const augmentedConsumerId = getConsumerId({
            consumerId,
            bindingType,
        });

        if (
            typeof this.subscribers[dsId]?.[augmentedConsumerId]?.[
                subscriberId
            ] !== 'undefined'
        ) {
            // The subscriber is already subscribed. Do not overwrite
            throw new Error(
                `${subscriberId} ${_(
                    'is already to subscribed to'
                )} ${dsId}. ${_(
                    'Overwriting the subscription without first cancelling it will cause a memory leak. Please unsubscribe before resubscribing again or provide a different subscriberId.'
                )}`
            );
        }

        // Add consumer to list of subscribers for any future updates, even if data already exists in dataRef
        this.subscribers[dsId] ??= {};
        this.subscribers[dsId][augmentedConsumerId] ??= {};
        this.subscribers[dsId][augmentedConsumerId][subscriberId] = onUpdate;

        // if state already exists (ie. item is subscribing after search resolved)
        //  then just push the data directly
        const data = this.data[dsId]?.[augmentedConsumerId];
        if (data) {
            onUpdate(data);
        }

        // unsubscribe by deleting the subscriber from the list
        return () => {
            delete this.subscribers[dsId][augmentedConsumerId][subscriberId];

            // if there are no more subscribers to this particular Subscription, delete the entry
            if (
                !Object.keys(this.subscribers[dsId][augmentedConsumerId]).length
            ) {
                delete this.subscribers[dsId][augmentedConsumerId];
            }

            // if there are no more subscribers to ANY consumerId of this dsId, delete the entry
            if (!Object.keys(this.subscribers[dsId]).length) {
                delete this.subscribers[dsId];
            }
        };
    }

    refresh({ consumerId, bindingType, dsId, options }: RefreshArgs) {
        const augmentedConsumerId = getConsumerId({
            consumerId,
            bindingType,
        });

        this.subscriptions[dsId]?.[augmentedConsumerId]?.refresh?.(options);

        Object.entries(this.subscribers[dsId] ?? {}).forEach(
            ([subscriberId, dsSubscribers]) => {
                if (subscriberId === augmentedConsumerId) {
                    return;
                }

                Object.values(dsSubscribers).forEach((onUpdate) => {
                    onUpdate({
                        data: { columns: [], fields: [] },
                        error: undefined,
                        meta: undefined,
                        requestParams: undefined,
                    });
                });
            }
        );
    }

    updateRequestParams({
        consumerId,
        dsId,
        bindingType,
        requestParams,
    }: UpdateRequestParamsArgs) {
        const augmentedConsumerId = getConsumerId({
            consumerId,
            bindingType,
        });
        const sub = this.subscriptions[dsId]?.[augmentedConsumerId];

        if (!sub) {
            return;
        }

        sub.updateRequestParams?.(requestParams);
    }
}
