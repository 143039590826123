import React, { useCallback } from 'react';
import { Message, ErrorBoundary } from '@splunk/dashboard-ui';
import {
    useSelector,
    selectAreGridLinesEnabled,
} from '@splunk/dashboard-state';
import DashboardLayout from '../components/DashboardLayout';
import ConnectedLayoutContainer from './connected/ConnectedLayoutContainer';
import GlobalInputContainer from './layouts/GlobalInputContainer';

const GlobalErrorMessage = ({ message }: { message?: string }) => (
    <Message level="error" message={message} />
);

const renderErrorMessage = (errorMessage?: string) => (
    <GlobalErrorMessage message={errorMessage} />
);

const renderGlobalInput = () => <GlobalInputContainer />;

type Dimension = {
    width: number;
    height: number;
};

export interface DashboardContainerProps {
    message?: string | null;
    height?: string | number;
    width?: string | number;
    globalInputs?: React.ReactNode[];
}

const DashboardContainer = ({
    message,
    height,
    width,
    globalInputs,
}: DashboardContainerProps) => {
    const showGrid = useSelector(selectAreGridLinesEnabled);

    const renderLayout = useCallback(
        (dimension: Dimension) => (
            <ConnectedLayoutContainer
                width={dimension.width}
                height={dimension.height}
                showGrid={showGrid}
            />
        ),
        [showGrid]
    );

    if (message) {
        return <GlobalErrorMessage message={message} />;
    }

    return (
        <ErrorBoundary render={renderErrorMessage}>
            <DashboardLayout
                width={width ?? 0}
                height={height ?? 0}
                renderGlobalInput={renderGlobalInput}
                renderLayout={renderLayout}
                showGlobalInputs={
                    !!(Array.isArray(globalInputs) && globalInputs.length)
                }
            />
        </ErrorBoundary>
    );
};

DashboardContainer.defaultProps = {
    globalInputs: [],
    message: null,
};

export default DashboardContainer;
