import { get } from 'lodash';
import * as turf from '@turf/helpers';
import bboxClip from '@turf/bbox-clip';
import { ISvgFeatureCollection } from './ISvgFeatureCollection';
import { MercatorTransformation } from './MercatorTransformation';
import { GeometryStreamSVGRenderer, IdentityTransform } from './GeometryStreamSVGRenderer';
import ICoordinateTransformation, {
    Bounds,
    calculatedTargetBounds,
    flipBounds,
    logicalSpace,
} from './ICoordinateTransformation';
import LinearTransformation from './LinearTransformation';
import TransformationChain from './TransformationChain';
import { GeometryStream } from './GeometryStream';
import { GeoFeatureGroup } from './GeoTypes';
import BoundsUtils from './BoundsUtils';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const MATCH_ALL_FEATUREGROUP_MATCHER = (feature: Record<string, unknown>): boolean => true;
export function featureGroupMatcher(g: GeoFeatureGroup): (feature: Record<string, any>) => boolean {
    if (!g || !g.featureMatcher || !g.featureMatcher.regex || !g.featureMatcher.property) {
        // if no matcher things defined, then we are not filtering features, and therefore should match all
        // eslint-disable-next-line
        return MATCH_ALL_FEATUREGROUP_MATCHER;
    }

    const matcher = new RegExp(g.featureMatcher.regex);
    return (feature: Record<string, any>): boolean => {
        const val = get(feature, ['properties', g.featureMatcher.property]);
        if (!val) {
            return false;
        }
        return matcher.test(val);
    };
}

export const DEFAULT_LOGICAL_BOUNDS = { x: { min: 0, max: 800 }, y: { min: 0, max: 600 } }; // default logical bounds

// same as with sourceBounds, logical bound can be global or local to the FeatureGroup. They also need to have defaults
// which are provided here.
export function pickLogicalBnds(globalLogicalBnds: Bounds, g: GeoFeatureGroup): Bounds {
    if (g && g.logicalBounds) {
        return g.logicalBounds;
    }
    if (globalLogicalBnds) {
        return globalLogicalBnds;
    }

    return DEFAULT_LOGICAL_BOUNDS;
}

export function ring(
    coords: number[][],
    renderer: GeometryStream,
    bounds?: Bounds,
    props?: Record<string, unknown>
): void {
    // polygon coords is array of number array
    renderer.beginNode('polygon', props);
    for (let i = 0; i < coords.length; i += 1) {
        const [long, lat] = coords[i];
        if (bounds && !BoundsUtils.contains(bounds, { long }, { lat })) {
            // the polygon is not entirely contained by the bounds. We must cancel (remove) the polygon along with any
            // points that were previously added to it.
            renderer.cancelNode();
            console.warn(`out-of-bounds polygon dropped: ${JSON.stringify(props)}`);
            return; // bail ... we don't need to endNode() since we cancelled it
        }
        renderer.addPoint({ long, lat });
    }
    renderer.endNode();
}

export function polygon(coords: number[][][], renderer: GeometryStream, bounds?: Bounds): void {
    // polygon coords is array of number array
    for (let poly = 0; poly < coords.length; poly += 1) {
        ring(coords[poly], renderer, bounds);
    }
}

export function renderGeoJsonFeature(
    feature: Record<string, any>,
    renderer: GeometryStream,
    bounds?: Bounds
): void {
    const bbox: turf.BBox = bounds ? (BoundsUtils.toTurfBBox(bounds) as turf.BBox) : undefined;
    const { type } = feature.geometry;

    if (get(feature, ['properties', 'name']) === 'Antarctica') {
        // skip shapes that cannot be mercator projected
        // no-op
        return;
    }
    // demo hack...
    const props = feature.properties;
    if (props.iso_3166_2) {
        props.id = props.iso_3166_2;
    } else {
        props.id = props.name;
    }
    props.className = 'feature';
    // end demo hack
    switch (type) {
        case 'MultiPolygon': {
            renderer.beginNode('g', props); // svg has groups ('g') but no polygon
            // eslint-disable-next-line no-param-reassign
            feature = bbox ? bboxClip(feature as turf.MultiPolygon, bbox) : feature;
            const multis = get(feature, ['geometry', 'coordinates']);
            multis.forEach((multi: number[][][]): void => polygon(multi, renderer, bounds));
            renderer.endNode();
            break;
        }
        case 'Polygon': {
            // eslint-disable-next-line no-param-reassign
            feature = bbox ? bboxClip(feature as turf.Polygon, bbox) : feature;
            const rings = get(feature, ['geometry', 'coordinates']);
            rings.forEach((r: number[][]): void => ring(r, renderer, bounds, props));
            break;
        }
        default: {
            throw new Error(`Unsupported geojson type: ${type}`);
        }
    }
}

function computeBounds(
    geoJson: Record<string, any>,
    matcher: (feature: Record<string, unknown>) => boolean
): Bounds {
    // replace the renderer with one that is configured only to compute logicalBounds (for performance)
    const boundsComputer = new GeometryStreamSVGRenderer();
    boundsComputer.computeBoundsOnly = true;
    geoJson.features.forEach((feature: Record<string, unknown>): void => {
        if (matcher(feature)) {
            renderGeoJsonFeature(feature, boundsComputer);
        }
    });
    return boundsComputer.getBounds();
}

// the source bounds can be provided globally for all feature groups, or they can be provided within a FeatureGroup
// or they can be omitted entirely which means they must be computed by parsing the geoJson
function pickSrcBnds(
    geoJson: Record<string, unknown>,
    g: GeoFeatureGroup,
    globalSrcBnds: Bounds,
    featureMatcher: (feature: Record<string, unknown>) => boolean
): Bounds {
    if (g && g.sourceBounds) {
        return g.sourceBounds; // return FeatureGroup "local" sourceBounds if they exist.
    }

    if (globalSrcBnds) {
        return globalSrcBnds; // return global bounds if they exist
    }

    // ...'auto' compute the source bounds from the data.
    const b = computeBounds(geoJson, featureMatcher); // automatically compute sourceBounds if not provided
    if (!b) {
        throw new Error(
            `Could not determine bounds for geoFeatureGroup name='${this.name}' (no features matched)`
        );
    }
    return b;
}

export default class GeoJsonFeatureCollection implements ISvgFeatureCollection {
    public name: string;

    private readonly sourceBounds: Bounds;

    public logicalBounds: Bounds;

    private projectionType: string;

    public transformation: ICoordinateTransformation;

    private geoJson: { features: Record<string, unknown>[] };

    private readonly group: GeoFeatureGroup;

    private readonly featureMatcher: (feature: Record<string, unknown>) => boolean;

    private aliases: Map<string, string>;

    public constructor(
        name: string,
        globalLogicalBnds: Bounds,
        projectionType: string,
        geoJson: { features: Record<string, unknown>[] },
        g?: GeoFeatureGroup,
        globalSrcBounds?: Bounds
    ) {
        this.name = name;
        this.projectionType = projectionType || 'mercator';
        this.geoJson = geoJson;
        this.group = g;
        this.featureMatcher = featureGroupMatcher(g);
        this.logicalBounds = pickLogicalBnds(globalLogicalBnds, g);
        this.sourceBounds = pickSrcBnds(geoJson, g, globalSrcBounds, this.featureMatcher);
        const minLat = this.sourceBounds.lat.min;
        const maxLat = this.sourceBounds.lat.max;

        let projection: ICoordinateTransformation;

        switch (projectionType) {
            case 'equirectangular':
                projection = new IdentityTransform();
                break;
            case 'mercator':
                if (Math.abs(minLat) > 85 || Math.abs(maxLat) > 85) {
                    throw new Error(
                        `Invalid latitude boundaries for mercator projection : ${minLat}/${maxLat}`
                    );
                }
                projection = new MercatorTransformation();
                break;
            default: {
                throw new Error(`Unsupported projection: ${projectionType}`);
            }
        }
        const projectedBounds = calculatedTargetBounds(projection, this.sourceBounds);
        const scaleTransformation = new LinearTransformation(
            logicalSpace,
            logicalSpace,
            flipBounds(projectedBounds, ['y']),
            this.logicalBounds,
            null,
            true
        );
        this.transformation = new TransformationChain([projection, scaleTransformation]);
    }

    public contains(x: number, y: number): boolean {
        const bounds = get(this.group, ['logicalBounds']);
        if (!bounds) {
            return true;
        }
        // return whether or not the x,y mouse screen location is inside this feature collection
        return BoundsUtils.contains(bounds, { x }, { y });
    }

    public getSvgNode(ref: (el: SVGSVGElement) => void): React.ReactNode {
        const { features } = this.geoJson;
        const renderer = new GeometryStreamSVGRenderer(this.transformation);
        renderer.beginNode('svg', {
            ref, // remember, 'ref' is a special attribute in react. It's a callback function called on node creation
            height: BoundsUtils.distance(this.logicalBounds.y),
            width: BoundsUtils.distance(this.logicalBounds.x),
        });
        features.forEach((feature: Record<string, unknown>): void => {
            if (this.featureMatcher(feature)) {
                renderGeoJsonFeature(feature, renderer, this.sourceBounds);
            }
        });
        renderer.endNode();
        return renderer.getRoot();
    }

    public resolveAlias(alias: string): string {
        if (!this.aliases) {
            this.aliases = GeoJsonFeatureCollection.makeAliasMap(this.geoJson.features); // memoize
        }

        const { aliases } = this;
        if (aliases) {
            return aliases.get(alias.toLocaleLowerCase()) || alias;
        }
        return alias;
    }

    static makeAliasMap(features: Record<string, any>[]): Map<string, string> {
        // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
        return features.reduce((aliases: Map<string, string>, feature: Record<string, any>) => {
            const geoJsonProperties = feature.properties;
            const name = geoJsonProperties.iso_3166_2;
            // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
            Object.keys(geoJsonProperties).forEach(k => {
                if (k !== name) {
                    aliases.set(geoJsonProperties[k].toLowerCase(), name); // alias all the other properies to the name property
                }
            });
            return aliases;
        }, new Map()) as Map<string, string>;
    }
}
