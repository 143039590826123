import { pick, get } from 'lodash';
import { Observable } from 'rxjs';
import { _ } from '@splunk/ui-utils/i18n';
import { DataSet } from '@splunk/datasource-utils';
import moment from '@splunk/moment';
import { app } from '@splunk/splunk-utils/config';

/**
 * Get status message based on search job properties
 * @param {String} status the status returned by the search job
 * @param {Boolean} isRealTimeSearch
 * @param {Number} totalCount the total number of search results retrieved
 * @returns {String} status message
 */
export const getStatusMessage = ({ status, isRealTimeSearch, totalCount }) => {
    if (status === 'running') {
        if (isRealTimeSearch) {
            return _('Real-time search is running');
        }
        if (totalCount === 0) {
            return _(
                'Search is running, but not enough data to render visualization'
            );
        }
    }
    if (status === 'queued') {
        return _('Search is not yet running, queued on server');
    }
    if (status === 'parsing') {
        return _('Search is not yet running, queued on server');
    }
    if (status === 'done' && totalCount === 0) {
        return _('Search ran successfully, but no results were returned');
    }

    return '';
};

// this is a list of properties that will be removed or normalized in the future. Refer to the RFC 2020_05_05-search_job_metadata.md
const deprecatedSearchMetas = ['resultCount', 'isDone', 'dispatchState'];

/**
 * Formats the results of a Splunk SearchJob to use in UDF
 * @param {Object} results search results object
 * @param {Object} progress search progress object
 * @param {String} search search query
 * @returns {Object} Formatted data and metadata
 */
export const projectFunc = (results, progress, search) => {
    const {
        fields,
        columns,
        messages,
        post_process_count: postProcessCount,
    } = results;

    const { isRealTimeSearch, resultPreviewCount, resultCount } =
        progress.content;

    // totalCount is required for pagination.
    let totalCount;
    if (postProcessCount != null) {
        // for post process searches, the totalCount is post_process_count in the results, NOT the resultPreviewCount/resultCount in the jobProperties.
        totalCount = postProcessCount;
    } else if (resultPreviewCount != null) {
        // we look at resultPreviewCount since that is always set when preview = true.
        // when search job is done, resultPreviewCount can be null,
        // see https://splunk.slack.com/archives/C8YPWKC4U/p1591290195175900 for more context.
        totalCount = resultPreviewCount;
    } else {
        totalCount = resultCount;
    }
    const status = progress.content.dispatchState.toLowerCase();

    return {
        data: new DataSet(fields, columns),
        meta: {
            ...pick(progress.content, deprecatedSearchMetas),
            sid: progress.content.sid,
            app: progress.acl?.app,
            totalCount,
            status,
            statusMessage: getStatusMessage({
                status,
                isRealTimeSearch,
                totalCount,
            }),
            // need to manually convert progress from [0, 1] to [0, 100]
            percentComplete:
                status === 'done'
                    ? 100
                    : Math.round(progress.content.doneProgress * 100),
            isRealTimeSearch,
            lastUpdated: moment(progress.published)
                .add(pick(progress.content, 'runDuration'), 'seconds')
                .toISOString(),
            search,
            serverLog: Array.isArray(messages)
                ? messages.map((m) => ({ level: m.type, message: m.text }))
                : undefined,
        },
    };
};

/**
 * return an Observable that will emit intermediate search result
 * @param {Observable} resultsObservable observable for the search results
 * @param {Observable} progressObservable observable for the search progress so far
 * @param {Function} callback project function to transform the values emitted by the Observable
 */
export const combineResultWhenProgress = (
    resultsObservable,
    progressObservable,
    callback
) => Observable.combineLatest(resultsObservable, progressObservable, callback);

/**
 * return an Observable that will emit final search result
 * @param {Observable} resultsObservable observable for the search results
 * @param {Observable} progressObservable observable for the search progress so far
 * @param {Function} callback project function to transform the values emitted by the Observable
 */
export const combineResultWhenFinalized = (
    resultsObservable,
    progressObservable,
    callback
) => Observable.forkJoin(resultsObservable, progressObservable, callback);

/**
 * Wraps the observer to improve the error handling
 * @param {Observer} observer the observer to be wrapped
 * @param {string} [search] the search query
 * @returns {Observer} the wrapped observer
 */
export const transformObserver = (observer, search) => ({
    next: (response) => observer.next(response),
    error: (err) =>
        observer.error({
            level: 'error',
            message: err.message,
            meta: {
                search,
            },
        }),
    complete: () => observer.complete(),
});

/**
 * Determine if a fetch is needed based on the given search preview state
 * @param {Object} searchState search preview state
 * @returns {Boolean}
 */
export const previewFetchPredicate = (searchState) => {
    if (get(searchState, ['content', 'isRealTimeSearch'], false)) {
        return true;
    }
    // we do a fetch when
    // 1. result count > 0
    // 2. search is done so we can make sure we always return the result
    return (
        get(searchState, ['content', 'resultPreviewCount'], 0) > 0 ||
        !!get(searchState, ['content', 'isDone'])
    );
};

/**
 * Provides common options for splunk search dispatch options
 * @param {Object} context Object containing any configurable dispatchOptions from splunk/search-job
 * @returns {Object}
 */
export const getEnterpriseSearchContext = (context) => {
    return { keepAlive: true, cache: false, app, ...context };
};
