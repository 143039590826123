import { namedColors } from '@splunk/dashboard-ui';
import {
    DEFAULT_CANVAS_WIDTH,
    DEFAULT_CANVAS_HEIGHT,
} from '@splunk/dashboard-utils';
import commonLayoutOptionsSchema from './commonLayoutOptionsSchema';

export default {
    ...commonLayoutOptionsSchema,
    backgroundColor: {
        oneOf: [
            {
                pattern: '^#[0-9a-fA-F]{6}$',
                type: 'string',
                description: `Specify the color of the layout background color using a Hex code, such as "#FF0000".
            The default for light mode is "#F2F4f5". The default for dark mode is "#3C444D". The default for SCP mode is "#15161B"`,
            },
            {
                type: 'string',
                enum: namedColors,
                description: `Specify the color of the layout background color using a valid web color such as 'blue'`,
            },
        ],
    },
    display: {
        type: 'string',
        default: 'auto',
        enum: ['actual-size', 'fit-to-width', 'auto-scale', 'auto'],
        description: `Specify the display behavior.
        If this property is set to actual-size, changing the size of your browser will not change the size of your dashboard or visualizations.
        If this property is set to fit-to-width or auto, your dashboard and visualizations will automatically also change size.`,
    },
    backgroundImage: {
        type: 'object',
        properties: {
            w: {
                type: 'number',
                description: `Specify the image's h (height) and w (width) in pixels. These options should not be specified if you set the sizeType option. If they are, they will overrule that setting.`,
            },
            h: {
                type: 'number',
                description: `Specify the image's h (height) and w (width) in pixels. These options should not be specified if you set the sizeType option. If they are, they will overrule that setting.`,
            },
            x: {
                type: 'number',
                description:
                    'Specify, in pixels, the position of the background image on the x-axis and y-axis. These options should not be specified if you set the sizeType option. If they are, they will overrule that setting.',
            },
            y: {
                type: 'number',
                description:
                    'Specify, in pixels, the position of the background image on the x-axis and y-axis. These options should not be specified if you set the sizeType option. If they are, they will overrule that setting.',
            },
            src: {
                type: 'string',
                description: 'The url of background image.',
            },
            sizeType: {
                type: 'string',
                enum: ['auto', 'contain', 'cover'],
                default: 'contain',
                description: `Specify one of the following:
                (auto | contain | cover).
                auto : Scales the image while maintaining the image proportion.
                contain: Increases the size of the of the image as much as possible without cropping or stretching it.
                cover: Increases the size of the image without stretching it. The image may be cropped vertically and horizontally so that no empty space is shown.`,
            },
        },
        description: `A background image must be one of the following types:
        jpg, jpeg, png, svg, or gif.
        Specify the location of a background image using the option src. For example: "src": "https://www.myImageLocation.com/galleryX/image1.png`,
        additionalProperties: false,
    },
    width: {
        type: 'number',
        default: DEFAULT_CANVAS_WIDTH,
        description: 'Canvas width in pixels.',
    },
    height: {
        type: 'number',
        default: DEFAULT_CANVAS_HEIGHT,
        description: 'Canvas height in pixels.',
    },
};
