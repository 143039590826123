import { _ } from '@splunk/ui-utils/i18n';
import type {
    DashboardJSON,
    DataSourceDefinition,
} from '@splunk/dashboard-types';
import type { ValidationErrors } from '../types';
import {
    CANNOT_CREATE_DS_TOKENS_UNNAMED,
    CANNOT_CREATE_DS_TOKENS_INVALID,
    CANNOT_ENSURE_DS_TOKEN_STABILITY,
} from './messages';

// TODO: Revisit this in the future to see if there's a way to leverage the preset
// This would ideally be handled with the below but the preset can't be posted to a worker
// usePreset().getPresetDefinition().dataSources[dataSource.type].config.getDataSourceName({ dataSource })
const getDSName = ({
    dataSource,
}: {
    dataSource: DataSourceDefinition;
}): string | undefined => {
    return dataSource.name || (dataSource.options?.ref as string | undefined);
};

// This checks that the datasource name isn't a readonly token namespace and
// validates using a regex which is basically just TOKEN_NAMESPACE_PREFIX_PATTERN
// from dashboard-util. The import from dashboard-util isn't used because that would
// require that the utils package is not externalized when bundling the worker code
// (this method executes from the worker's thread and cannot use externalized code)
const isDataSourceNameValid = (
    dsName: string,
    readOnlyTokenNamespaces: Set<string>
): boolean =>
    !readOnlyTokenNamespaces.has(dsName) && /^([ \w-.]+)$/.test(dsName);

export const checkInvalidSmartSources = (
    definition: DashboardJSON,
    readOnlyTokenNamespaces: string[] = []
): ValidationErrors => {
    const { dataSources = {} } = definition;
    const readOnlyTokenNamespacesSet = new Set<string>(readOnlyTokenNamespaces);

    const seenSmartSources = new Set<string>();
    const clashingNamesErrors = new Set<string>();

    return Object.keys(dataSources).reduce<ValidationErrors>((errors, dsId) => {
        if (!dataSources[dsId].options?.enableSmartSources) {
            return errors;
        }

        const dsName = getDSName({ dataSource: dataSources[dsId] });
        if (!dsName) {
            // Data source has an empty/missing name
            errors.push({
                instancePath: _(
                    `Warning: The data source "${dsId}" is unnamed`
                ),
                message: CANNOT_CREATE_DS_TOKENS_UNNAMED,
            });

            return errors;
        }

        if (!isDataSourceNameValid(dsName, readOnlyTokenNamespacesSet)) {
            // Data source has an invalid name
            errors.push({
                instancePath: _(
                    `Warning: The data source "${dsName}" has an invalid name`
                ),
                message: CANNOT_CREATE_DS_TOKENS_INVALID,
            });
        } else if (
            seenSmartSources.has(dsName) &&
            !clashingNamesErrors.has(dsName)
        ) {
            // Data source has an invalid name. Only push one of this error.
            errors.push({
                instancePath: _(
                    `Warning: Multiple data sources are configured with the name "${dsName}"`
                ),
                message: CANNOT_ENSURE_DS_TOKEN_STABILITY,
            });

            clashingNamesErrors.add(dsName);
        }

        seenSmartSources.add(dsName);
        return errors;
    }, []);
};
