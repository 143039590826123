import React from 'react';
import styled from 'styled-components';

import { _ } from '@splunk/ui-utils/i18n';
import { variables, mixins, pick } from '@splunk/themes';
import type { Mode } from '@splunk/dashboard-types';

import { customThemeVariables } from '../customThemeVariables';

const Description = styled.div`
    ${mixins.reset('block')};
    min-height: 22px;
    max-width: 100%;
    margin: 0;
    padding: 2px 12px 0px 9px;
    box-sizing: inherit;
    line-height: 15px;
    font-size: 14px;
    font-weight: 300;
    color: ${pick({
        enterprise: {
            light: variables.contentColorDefault,
            dark: variables.gray96,
        },
        prisma: variables.contentColorDefault,
    })};
    text-overflow: ellipsis;
`;

const DescriptionInput = styled.input`
    ${mixins.reset('block')};
    height: 22px;
    width: 100%;
    margin: 0;
    padding: 2px 12px 2px 8px; // Left & Bottom Padding is intentionally different than the above component for alignment.
    box-sizing: inherit;
    line-height: 15px;
    font-size: 14px;
    font-weight: 300;
    flex-grow: 0;
    color: ${pick({
        enterprise: {
            light: variables.contentColorDefault,
            dark: variables.gray96,
        },
        prisma: variables.contentColorDefault,
    })};
    outline: none;
    background-color: 1px solid transparent;
    border: transparent;

    &:hover,
    &:focus {
        background-color: ${customThemeVariables.selectedInputBackgroundColor};
        border-color: ${customThemeVariables.selectedInputBorderColor};
        border-style: solid;
        border-width: 1px;
        border-radius: 4px;
    }
`;

interface DescriptionProps {
    handleBlur: React.FocusEventHandler;
    handleChange: React.FormEventHandler;
    handleKeyDown: React.KeyboardEventHandler;
    showInViewMode: boolean;
    mode: Mode;
    value?: string;
}

const DashboardDescription = ({
    handleBlur,
    handleChange,
    handleKeyDown,
    showInViewMode,
    mode,
    value,
}: DescriptionProps): JSX.Element | null => {
    if (mode === 'view' && !showInViewMode) {
        return null;
    }

    if (mode === 'edit') {
        return (
            <DescriptionInput
                data-test="dashboard-description-input"
                onBlur={handleBlur}
                onChange={handleChange}
                onKeyDown={handleKeyDown}
                placeholder={_('Enter dashboard description.')}
                value={value}
            />
        );
    }

    return <Description data-test="dashboard-description">{value}</Description>;
};

export default React.memo(DashboardDescription);
