import * as React from 'react';
import { _ } from '@splunk/ui-utils/i18n';
import SVG from '@splunk/react-icons/SVGEnterprise';

const SingleValueRadial = (props: React.ComponentProps<typeof SVG>): React.ReactElement<SVGElement> => (
    <SVG screenReaderText={_('Single Value Radial')} viewBox="0 0 20 17" {...props}>
        <path
            d="M9.99967 2.50004C5.85754 2.50004 2.49967 5.85791 2.49967 10C2.49967 11.5437 2.96515 12.9762 3.76326 14.1678C4.04016 14.5812 4.35715 14.9657 4.70871 15.3156C5.03489 15.6403 5.03611 16.168 4.71143 16.4942C4.38674 16.8203 3.85911 16.8216 3.53292 16.4969C3.10383 16.0698 2.71677 15.6003 2.3785 15.0953C1.40234 13.6378 0.833008 11.8842 0.833008 10C0.833008 4.93743 4.93706 0.833374 9.99967 0.833374C15.0623 0.833374 19.1663 4.93743 19.1663 10C19.1663 11.8869 18.5953 13.643 17.6166 15.1017C17.2806 15.6024 16.8966 16.068 16.4713 16.492C16.1454 16.8169 15.6177 16.8161 15.2928 16.4902C14.9679 16.1642 14.9687 15.6366 15.2946 15.3117C15.6431 14.9643 15.9576 14.5829 16.2326 14.173C17.0328 12.9804 17.4997 11.546 17.4997 10C17.4997 5.85791 14.1418 2.50004 9.99967 2.50004Z"
            fill="currentColor"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12.2089 5.83998C12.6655 5.89744 12.9891 6.31419 12.9317 6.77083L12.8399 7.50004H13.333C13.7932 7.50004 14.1663 7.87314 14.1663 8.33337C14.1663 8.79361 13.7932 9.16671 13.333 9.16671H12.6302L12.4205 10.8334H13.333C13.7932 10.8334 14.1663 11.2065 14.1663 11.6667C14.1663 12.1269 13.7932 12.5 13.333 12.5H12.2108L12.0929 13.4375C12.0354 13.8941 11.6186 14.2177 11.162 14.1603C10.7054 14.1028 10.3818 13.6861 10.4392 13.2294L10.531 12.5H8.8612L8.74324 13.4375C8.68579 13.8941 8.26903 14.2177 7.8124 14.1603C7.35576 14.1028 7.03216 13.6861 7.08961 13.2294L7.18139 12.5H6.66634C6.2061 12.5 5.83301 12.1269 5.83301 11.6667C5.83301 11.2065 6.2061 10.8334 6.66634 10.8334H7.39109L7.6008 9.16671H6.66634C6.2061 9.16671 5.83301 8.79361 5.83301 8.33337C5.83301 7.87314 6.2061 7.50004 6.66634 7.50004H7.8105L7.92844 6.56276C7.98589 6.10613 8.40265 5.78253 8.85928 5.83998C9.31592 5.89744 9.63952 6.31419 9.58206 6.77083L9.49031 7.50004H11.1601L11.278 6.56276C11.3355 6.10613 11.7523 5.78253 12.2089 5.83998ZM9.0709 10.8334H10.7407L10.9504 9.16671H9.28061L9.0709 10.8334Z"
            fill="currentColor"
        />
    </SVG>
);

export default SingleValueRadial;
