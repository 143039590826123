// this is a copy of SingleValueRadial.jsx @ 23.5.0 before the design changes to PureSingleValueRadial were rolled out
import React, { useCallback } from 'react';
import T from 'prop-types';
import pickFromTheme from '@splunk/themes/pick';
import variables from '@splunk/themes/variables';
import styled, { withTheme } from 'styled-components';
import pick from 'lodash/pick';
import isEmpty from 'lodash/isEmpty';
import { _ } from '@splunk/ui-utils/i18n';
import { toDimension } from '@splunk/visualizations-shared/style';
import SizeAwareWrapper from '@splunk/visualizations-shared/SizeAwareWrapper';
import Message from '@splunk/visualizations-shared/Message';
import {
    computeColors,
    computeSingleValueContent,
    getUnderLabelHeight,
    shouldShowTrend,
    handleValueClickCallback,
} from '@splunk/visualizations/common/utils/singleValueUtils';
import RadialLayout from './RadialLayout';
import {
    getGraphSize,
    getValueContentSize,
    getRadialProgress,
    getRadialStrokeWidth,
    computeTrendIndicatorPosition,
    getRadialColors,
} from '../utils/displayUtils';

const Container = styled.div`
    overflow: hidden;
    ${props => toDimension(pick(props, ['width', 'height']))};
`;

const VizStyleWrapper = styled.div.attrs(({ dataTestKey }) => ({
    'data-test': dataTestKey,
}))`
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    height: 100%;
    background-color: ${props => props.backgroundColor};
`;

VizStyleWrapper.displayName = 'VizStyleWrapper';

const SingleValueRadial = ({ ...props }) => {
    const {
        width,
        height = 250,
        style,
        majorFontSize,
        majorValue,
        majorValueField,
        trendValue,
        trendFontSize,
        unitPosition,
        numberPrecision,
        shouldUseThousandSeparators,
        shouldAbbreviateTrendValue,
        trendIndicatorPosition = 'standard',
        onValueClick,
    } = props;

    // calculate colors
    const defaultColors = {
        defaultFontColor: pickFromTheme({
            enterprise: {
                dark: variables.textColor(props),
                light: variables.gray20(props),
            },
            prisma: variables.contentColorActive(props),
        })(props),
        defaultBlockFontColor: pickFromTheme({
            enterprise: variables.white(props),
            prisma: variables.contentColorActive(props),
        })(props),
    };
    const { backgroundEnabled, backgroundColor, majorColor, trendColor, underLabelColor } = computeColors(
        props,
        defaultColors
    );

    const radialColors = getRadialColors({ backgroundEnabled, backgroundColor });
    const radialBackgroundColor = props.radialBackgroundColor || radialColors.background;
    const radialStrokeColor = props.radialStrokeColor || radialColors.stroke;

    const handleValueClick = useCallback(
        ev => handleValueClickCallback(ev, majorValue, majorValueField, trendValue, onValueClick),
        [onValueClick, majorValue, majorValueField, trendValue]
    );

    const renderVisualization = ({ width: containerWidth, height: containerHeight }) => {
        if (containerWidth < 35 || containerHeight < 35) {
            return (
                <Message
                    width={containerWidth}
                    height={containerHeight}
                    message={_('Too small to render content')}
                    level="info"
                />
            );
        }

        // will update later based on text size
        let { trendDisplay, unit, underLabel } = props;
        let showRadialGraph = true;

        // calculate radial graph, major and trend content, and underlabel size
        const graphSize = getGraphSize({ width: containerWidth, height: containerHeight, underLabel });
        const radialStrokeWidth = getRadialStrokeWidth(graphSize);
        const valueContentSize = getValueContentSize({ graphSize, radialStrokeWidth });
        const underLabelFontSize = getUnderLabelHeight(graphSize * 0.1);

        // calculate major and trend text
        const toRemoveList = [
            { key: 'underLabel', value: '' },
            { key: 'showRadialGraph', value: false },
            { key: 'trendDisplay', value: 'off' },
            { key: 'unit', value: '' },
        ];
        const {
            majorText,
            trendText,
            majorTextFontSize,
            trendTextFontSize,
            majorValueTitle,
            smallVizProps,
        } = computeSingleValueContent({
            majorValue,
            trendValue,
            majorFontSize,
            trendFontSize,
            trendDisplay,
            contentWidth: valueContentSize,
            contentHeight: valueContentSize,
            containerWidth,
            containerHeight,
            unit,
            numberPrecision,
            shouldUseThousandSeparators,
            shouldAbbreviateTrendValue,
            toRemoveList,
        });
        if (!isEmpty(smallVizProps)) {
            ({ underLabel, showRadialGraph, trendDisplay, unit } = smallVizProps);
        }

        // calculate trend position
        const position = computeTrendIndicatorPosition({
            trendIndicatorPosition,
            majorTextFontSize,
            trendTextFontSize,
        });

        // calculate radial
        const progress = getRadialProgress(majorValue);

        return (
            <VizStyleWrapper
                backgroundColor={backgroundColor}
                style={style}
                dataTestKey="SingleValueRadialWrapper"
            >
                <RadialLayout
                    data-test="RadialLayout"
                    majorColor={majorColor}
                    majorText={majorText}
                    majorTextFontSize={majorTextFontSize}
                    majorValueTitle={majorValueTitle}
                    trendColor={trendColor}
                    trendText={trendText}
                    trendTextFontSize={trendTextFontSize}
                    showTrendIndicator={shouldShowTrend(trendDisplay)}
                    trendPosition={position}
                    unit={unit}
                    unitPosition={unitPosition}
                    underLabelColor={underLabelColor}
                    underLabelFontSize={underLabelFontSize}
                    underLabel={underLabel}
                    onValueClick={handleValueClick}
                    contentSize={valueContentSize * 0.7}
                    showRadialGraph={showRadialGraph}
                    graphSize={graphSize}
                    progress={progress}
                    radialBackgroundColor={radialBackgroundColor}
                    radialStrokeColor={radialStrokeColor}
                    radialStrokeWidth={radialStrokeWidth}
                />
            </VizStyleWrapper>
        );
    };

    return (
        <Container width={width} height={height}>
            <SizeAwareWrapper>
                {containerDimension => renderVisualization(containerDimension)}
            </SizeAwareWrapper>
        </Container>
    );
};

SingleValueRadial.propTypes = {
    width: T.oneOfType([T.string, T.number]),
    height: T.oneOfType([T.string, T.number]),
    style: T.object,
    majorValue: T.oneOfType([T.string, T.number]),
    majorValueField: T.string,
    majorColor: T.string,
    majorFontSize: T.number,
    trendValue: T.oneOfType([T.string, T.number]),
    trendColor: T.string,
    trendFontSize: T.number,
    trendDisplay: T.string,
    trendIndicatorPosition: T.oneOf(['standard', 'below', 'outside']),
    shouldAbbreviateTrendValue: T.bool,
    backgroundColor: T.string,
    unit: T.string,
    unitPosition: T.string,
    underLabel: T.string,
    numberPrecision: T.number,
    shouldUseThousandSeparators: T.bool,
    radialBackgroundColor: T.string,
    radialStrokeColor: T.string,
    onValueClick: T.func,
};

SingleValueRadial.defaultProps = {
    width: '100%',
    height: 250,
    majorValue: 'N/A',
    majorValueField: '',
    trendDisplay: 'absolute',
    trendIndicatorPosition: 'standard',
    shouldAbbreviateTrendValue: false,
    unitPosition: 'after',
    numberPrecision: 0,
    shouldUseThousandSeparators: true,
    onValueClick: () => {},
};

export default withTheme(SingleValueRadial);
