import React from 'react';
import T from 'prop-types';
import { withTheme } from 'styled-components';
import { PureSingleValueIcon, config } from '@splunk/visualizations/SingleValueIcon';
import { withSanitizedProps } from '@splunk/visualizations-shared/SanitizeProps';
import { propsConvert, propsDeprecate } from '../../utils/propUtils';

const propsMapping = {
    value: 'majorValue',
    deltaValue: 'trendValue',
    deltaColor: 'trendColor',
    useTrendUnits: 'shouldAbbreviateTrendValue',
    useThousandSeparators: 'shouldUseThousandSeparators',
    color: 'iconColor', // todo: deprecate this
};
const deprecatedProps = ['showIcon', 'showTrendIndicator', 'trendDisplayMode'];

const ReactSingleValueIcon = ({ ...props }) => {
    let newProps = propsConvert(props, propsMapping);
    // change props
    const trendDisplay = newProps.showTrendIndicator ? newProps.trendDisplayMode : 'off';
    const defaultColors = {
        defaultFontColor: config.themes.defaultFontColor(props),
        defaultBlockFontColor: config.themes.defaultBlockFontColor(props),
    };

    newProps = {
        ...propsDeprecate(newProps, deprecatedProps),
        ...defaultColors,
        trendDisplay,
    };

    return <PureSingleValueIcon {...newProps} />;
};

ReactSingleValueIcon.propTypes = {
    width: T.oneOfType([T.string, T.number]),
    height: T.oneOfType([T.string, T.number]),
    style: T.object,
    value: T.oneOfType([T.string, T.number]),
    deltaValue: T.oneOfType([T.string, T.number]),
    backgroundColor: T.string,
    majorColor: T.string,
    deltaColor: T.string,
    showTrendIndicator: T.bool,
    useTrendUnits: T.bool,
    trendDisplayMode: T.string,
    numberPrecision: T.number,
    useThousandSeparators: T.bool,
    unit: T.string,
    unitPosition: T.string,
    underLabel: T.string,
    icon: T.string,
    iconPosition: T.string,
    iconOpacity: T.number,
    showValue: T.bool,
    showIcon: T.bool, // todo: need deprecate
    color: T.string, // todo: need deprecate it. map it to iconColor for now.
    iconColor: T.string,
    IconComponent: T.element,
    noDataSources: T.bool,
    noResult: T.bool,
    onValueClick: T.func,
};

ReactSingleValueIcon.defaultProps = {
    width: '100%',
    height: 250,
    showTrendIndicator: true,
    useTrendUnits: false,
    trendDisplayMode: 'absolute',
    numberPrecision: 0,
    useThousandSeparators: true,
    unitPosition: 'after',
    icon: 'default',
    iconPosition: 'before',
    iconOpacity: 1,
    showValue: true,
    showIcon: true, // todo: need deprecate
    noDataSources: false,
    noResult: false,
    onValueClick: () => {},
};

export default withTheme(withSanitizedProps(ReactSingleValueIcon));
