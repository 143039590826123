import React from 'react';
import { useFeatureFlags } from '@splunk/dashboard-context';
import { ActualSizeWrapper } from './ActualSizeWrapper';
import SizeAwareWrapper, {
    type SizeAwareWrapperProps,
} from './SizeAwareWrapper';

export const SizeWrapper = ({ children }: SizeAwareWrapperProps) => {
    const { enableSSR } = useFeatureFlags();

    return enableSSR ? (
        <ActualSizeWrapper>{children}</ActualSizeWrapper>
    ) : (
        <SizeAwareWrapper>{children}</SizeAwareWrapper>
    );
};
