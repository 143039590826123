// Todo: this will be replaced by IconLibrary.Registry
import Activity from '@splunk/react-icons/enterprise/Activity';
import Clock from '@splunk/react-icons/enterprise/Clock';
import Pencil from '@splunk/react-icons/enterprise/Pencil';
import Star from '@splunk/react-icons/enterprise/Star';
import Question from '@splunk/react-icons/enterprise/Question';
import {
    hasBackground,
    getDefaultColorWithBg,
    getFormattedColor,
} from '../../../common/utils/singleValueUtils';

export const ICON_POSITIONS = ['before', 'after', 'top', 'below'];
export const ICON_HORIZONTAL_RATIO = 0.4;
export const ICON_VERTICAL_RATIO = 0.2;
export const iconMap = {
    activity: Activity,
    clock: Clock,
    pencil: Pencil,
    star: Star,
    default: Question,
};

/*
 * get icon from icon map
 * @param {String} key
 * @return {Icon} icon component from icon map
 */
export const getIcon = key => iconMap[key.toLowerCase()] || iconMap.default;

/*
 * @method isValidPosition
 * @param {String} iconPosition
 * @returns {Bool}
 */
export const isValidPosition = iconPosition => ICON_POSITIONS.indexOf(iconPosition) > -1;

/*
 * @method isHorizontal
 * @param {String} iconPosition
 * @returns {Bool}
 */
export const isHorizontal = iconPosition => iconPosition === 'before' || iconPosition === 'after';

/*
 * @method getIconWidth
 * @param {Object} props
 * @param {Bool} props.showHorizontal
 * @param {Number} props.width
 * @returns {Number} iconWidth
 */
export const getIconWidth = ({ showHorizontal, width }) =>
    showHorizontal ? width * ICON_HORIZONTAL_RATIO : width * ICON_VERTICAL_RATIO;

/*
 * @method getIconHeight
 * @param {Object} props
 * @param {Bool} props.showHorizontal
 * @param {Number} props.height
 * @returns {Number} iconHeight
 */
export const getIconHeight = ({ showHorizontal, height }) => (showHorizontal ? height : height * 0.25);

/*
 * @method getIconFontSize
 * @param {Object} props
 * @param {Bool} props.showValue
 * @param {Number} props.majorTextFontSize
 * @param {Number} props.iconWidth
 * @returns {Number} iconFontSize
 */
export const getIconFontSize = ({ showValue, majorTextFontSize, iconWidth }) =>
    showValue ? majorTextFontSize * 0.5 : iconWidth;

/*
 * @method getSVGDimension
 * @param {Object} props
 * @param {Bool} props.showValue
 * @param {Number} props.containerDimension
 * @param {Number} props.iconDimension
 * @param {Number} props.ratio
 * @returns {Number} svg icon dimension
 */
export const getSVGDimension = ({ showValue, containerDimension, iconDimension, ratio }) =>
    showValue ? iconDimension * ratio : containerDimension;

/*
 * @method getValuesWidth
 * @param {Object} props
 * @param {Bool} props.showHorizontal
 * @param {Number} props.width
 * @returns {Number} valuesWidth
 */
export const getValuesWidth = ({ showHorizontal, width }) =>
    showHorizontal ? width * (1 - ICON_HORIZONTAL_RATIO) : width;

/*
 * @method getValuesHeight
 * @param {Object} props
 * @param {Bool} props.showHorizontal
 * @param {Number} props.height
 * @param {Number} props.iconHeight
 * @returns {Number} valuesHeight
 */
export const getValuesHeight = ({ showHorizontal, height, iconHeight }) =>
    showHorizontal ? height : height - iconHeight;

/*
 * getIconColor
 * @param {Object} props
 * @param {String} props.iconColor
 * @param {String} props.backgroundColor
 * @param {String} props.defaultFontColor
 * @param {String} props.defaultBlockFontColor
 * @returns {String} icon color
 */
export const getIconColor = ({ iconColor, backgroundColor, defaultFontColor, defaultBlockFontColor }) => {
    if (hasBackground(backgroundColor) && !iconColor) {
        return getDefaultColorWithBg({
            backgroundColor,
            defaultFontColor,
            defaultBlockFontColor,
        });
    }

    return getFormattedColor(iconColor, defaultFontColor);
};
