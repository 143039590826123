import * as chroma from 'chroma-js';
import { chain, get } from 'lodash';
import { isColor } from '@splunk/visualizations-shared/colorUtils';
import { AbstractFormatter } from '../Formatter';
import { TypedValue } from '../DataPrimitive';
import { DataPoint } from '../DataPoint';
import EncodingExecutor from '../EncodingExecutor';

export interface MaxContrastConfig {
    colors: string[];
    default?: string;
}

/**
 * MaxContrast can be used to pick the color with maxContrast.
 *
 * ```js
 * <SampleViz
 *     context={{
 *          contrastConfig: {
 *              colors: ['white', 'black']
 *          }
 *     }}
 *     options={{
 *         color1: 'green',
 *         maxContrastOfColor1: '> color1 | maxContrast(contrastConfig)',    // white
 *         color2: 'gray'
 *         maxContrastOfColor2: '> color2 | maxContrast(contrastConfig)',    // black
 *     }}
 *     dataSources={{
 *         primary: {
 *             data: {
 *                 fields: [{ name: 'foo' }]
 *                 columns: [[100, 200]]
 *             }
 *         }
 *     }}
 * />
 * ```
 * Leverages [Chromajs.contrast](https://gka.github.io/chroma.js/#chroma-contrast) to determine contrast between color and values
 *
 * ## Config Object
 *  * **colors**: 'string[]' array of colors to compare contrast against
 *  * **default**: 'string' default color if no colors or the contrast is not found
 *
 */
export class MaxContrast extends AbstractFormatter<'color', 'color'> {
    private config: MaxContrastConfig;

    constructor(config?: MaxContrastConfig) {
        super();
        this.config = EncodingExecutor.rawTree(config);
    }

    protected formatTypedValue(p: DataPoint<'color'>): TypedValue<'color'> {
        const { value, type } = p.getValue();
        if (type !== 'color' || !isColor(value)) {
            return { type, value };
        }

        const colorConfig = get(this, 'config.colors');
        const defaultColor = get(this, 'config.default');

        if (!Array.isArray(colorConfig) || colorConfig.length === 0) {
            return isColor(defaultColor) ? { type: 'color', value: defaultColor } : { type, value };
        }

        if (value === 'transparent' && isColor(defaultColor)) {
            return { type: 'color', value: defaultColor };
        }

        try {
            return {
                type: 'color',
                value: chain(colorConfig)
                    .map(c => ({ color: c, contrast: chroma.contrast(value, c) }))
                    .maxBy('contrast')
                    .value().color,
            };
        } catch (error) {
            console.warn(error);
            return { type: 'color', value: defaultColor || value };
        }
    }
}
