import React, { useCallback } from 'react';
import T from 'prop-types';
import { hasTokens } from '@splunk/dashboard-utils';
import ControlGroup from '@splunk/react-ui/ControlGroup';
import styled from 'styled-components';
import { BaseTimeRangePicker, DEFAULT_VALUE } from './BaseTimeRange';

// Prevent TRP from overflowing sidebar when it has a large value
// Setting width on ControlGroup doesn't allow TRP to go full width
const InputContainer = styled.div`
    width: 329px;
`;
const TRPEditor = ({
    onChange,
    name,
    value,
    labelPosition,
    label,
    enableRealTime,
    Connector,
}) => {
    const isDisabled = !value || hasTokens(value);

    const handleChange = useCallback(
        (evt, data) => {
            onChange(evt, name, data);
        },
        [name, onChange]
    );

    return (
        <ControlGroup label={label} labelPosition={labelPosition}>
            <InputContainer>
                <Connector value={value}>
                    <BaseTimeRangePicker
                        data-test={name}
                        onValueChange={handleChange}
                        value={value}
                        disabled={isDisabled}
                        enableRealTime={enableRealTime}
                    />
                </Connector>
            </InputContainer>
        </ControlGroup>
    );
};

TRPEditor.propTypes = {
    /**
     * Callback when changing the text value
     *
     * @param {SyntheticEvent} event The react `SyntheticEvent`
     * @param {string} name The option name
     * @param {string} value The option value
     */
    onChange: T.func.isRequired,
    /**
     * The option name
     */
    name: T.string.isRequired,
    /**
     * A human readable label for the option
     */
    label: T.string.isRequired,
    /**
     * The option value
     */
    value: T.string,
    /**
     * Label position
     */
    labelPosition: T.oneOf(['top', 'left']),
    /**
     * What environment to use
     */
    enableRealTime: T.bool,
    /**
     * A connector that will supply:
     * presets, onRequestParseEarliest, onRequestParseLatest,
     * parseEarliest, and parseLatest props for its children
     */
    Connector: T.elementType.isRequired,
};

TRPEditor.defaultProps = {
    value: DEFAULT_VALUE,
    labelPosition: 'top',
    enableRealTime: false,
};

export default TRPEditor;
