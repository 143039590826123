import React from 'react';
import SVG, {
    type SVGPropsOptionalViewBox,
} from '@splunk/react-icons/SVGEnterprise';

/* eslint-disable max-len */
export default function DragHandle(
    props: SVGPropsOptionalViewBox
): ReturnType<typeof SVG> {
    return (
        <SVG
            height="100%"
            width="100%"
            viewBox="0 0 88 36"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M32.9545 10.5402C34.3102 10.5402 35.4091 11.6391 35.4091 12.9947C35.4091 14.3503 34.3102 15.4493 32.9545 15.4493C31.5989 15.4493 30.5 14.3503 30.5 12.9947C30.5 11.6391 31.5989 10.5402 32.9545 10.5402Z"
                fill="currentColor"
            />
            <path
                d="M44 10.5402C45.3556 10.5402 46.4546 11.6391 46.4546 12.9947C46.4546 14.3503 45.3556 15.4493 44 15.4493C42.6444 15.4493 41.5455 14.3503 41.5455 12.9947C41.5455 11.6391 42.6444 10.5402 44 10.5402Z"
                fill="currentColor"
            />
            <path
                d="M57.5 23.0447C57.5 21.6891 56.4011 20.5902 55.0455 20.5902C53.6899 20.5902 52.5909 21.6891 52.5909 23.0447C52.5909 24.4003 53.6899 25.4993 55.0455 25.4993C56.4011 25.4993 57.5 24.4003 57.5 23.0447Z"
                fill="currentColor"
            />
            <path
                d="M57.5 12.9947C57.5 11.6391 56.4011 10.5402 55.0455 10.5402C53.6899 10.5402 52.5909 11.6391 52.5909 12.9947C52.5909 14.3503 53.6899 15.4493 55.0455 15.4493C56.4011 15.4493 57.5 14.3503 57.5 12.9947Z"
                fill="currentColor"
            />
            <path
                d="M46.4546 23.0447C46.4546 21.6891 45.3556 20.5902 44 20.5902C42.6444 20.5902 41.5455 21.6891 41.5455 23.0447C41.5455 24.4003 42.6444 25.4993 44 25.4993C45.3556 25.4993 46.4546 24.4003 46.4546 23.0447Z"
                fill="currentColor"
            />
            <path
                d="M35.4091 23.0447C35.4091 21.6891 34.3102 20.5902 32.9545 20.5902C31.5989 20.5902 30.5 21.6891 30.5 23.0447C30.5 24.4003 31.5989 25.4993 32.9545 25.4993C34.3102 25.4993 35.4091 24.4003 35.4091 23.0447Z"
                fill="currentColor"
            />
        </SVG>
    );
}
