import SuiChartPieIcon from '@splunk/react-icons/enterprise/ChartPie';
import { Pie as PiePlaceholderIcon } from '@splunk/visualization-icons/placeholders';
import { _ } from '@splunk/ui-utils/i18n';

import { COLOR_OR_TOKEN_PATTERN_WITH_RGBA, getPattern } from '@splunk/visualizations-shared/schemaUtils';
import { VIZ_CATEGORICAL } from '@splunk/visualization-color-palettes';
import pick from '@splunk/themes/pick';
import variables from '@splunk/themes/variables';
import { DataContract } from '../common/interfaces/DataContract';
import { DefaultContext } from '../common/interfaces/DefaultContext';
import { EditorConfig } from '../common/interfaces/Editor';
import { OptionsSchema } from '../common/interfaces/OptionsSchema';
import { ThemedDefaults } from '../common/interfaces/ThemedDefaults';
import { VizBehavior } from '../common/interfaces/VizBehavior';
import { VizCategory } from '../common/interfaces/VizCategory';
import { VizConfig } from '../common/interfaces/VizConfig';
import { VizSize } from '../common/interfaces/VizSize';
import { enhanceConfig } from '../common/utils/configUtils';
import getBackgroundColorEditor from '../common/editorConfig/BackgroundColor';

const dataContract: DataContract = {
    requiredDataSources: [
        {
            name: 'primary',
            description: 'DataSource that powers the visualization',
        },
    ],
    optionalDataSources: [
        // Note: SCP-14090 disable ui for optional datasources. We may need to add it back later
        // {
        //     name: 'annotation',
        //     description: 'DataSource that populate event annotations',
        // },
    ],
    initialRequestParams: {
        primary: { offset: 0, count: 10000 },
    },
};

const size: VizSize = {
    initialWidth: 300,
    initialHeight: 300,
};

const defaultContext: DefaultContext = {};

const optionsSchema: OptionsSchema = {
    backgroundColor: {
        default: '> themes.defaultBackgroundColor',
        description:
            'Specify the color for the background. You may use a dataSource to apply the color. The default for enterprise light is "#ffffff". The default for enterprise dark is "#000000". The default for prisma dark is "#0b0c0e".',
        pattern: COLOR_OR_TOKEN_PATTERN_WITH_RGBA,
        type: 'string',
    },
    collapseLabel: {
        description: 'Specify the label for the consolidated slice.',
        type: 'string',
        default: 'other',
    },
    collapseThreshold: {
        description:
            'Specify the size threshold as a number between 0 and 1 (inclusive), of the whole pie at which slices collapse into one consolidated slice.',
        type: 'number',
        default: 0.01,
    },
    label: {
        description: 'List of string values to display the pie chart labels.',
        type: 'array',
        items: {
            type: 'string',
        },
        default: '> primary | seriesByIndex(0)',
    },
    labelField: {
        default: '> label | getField()',
        description: 'Specify the field that corresponds to the labels.',
        type: 'string',
    },
    labelDisplay: {
        description: 'Specify whether to display the labels and/or slice percentages.',
        type: 'string',
        pattern: getPattern(['values', 'valuesAndPercentage', 'off']),
        default: 'values',
    },
    resultLimit: {
        default: 50000,
        description: 'Specify the number of data points rendered in a chart.',
        type: 'number',
    },
    seriesColors: {
        default: VIZ_CATEGORICAL,
        description: 'Specify the colors used for a series. For example, ["#FF0000", "#0000FF", "#008000"].',
        type: 'array',
        items: {
            type: 'string',
        },
    },
    seriesColorsByField: {
        description:
            'Specify the colors used for specific pie slice labels. For example: {"April": "#008000", "May": "#FFA500"}.',
        type: 'object',
    },
    showDonutHole: {
        default: false,
        description: 'Specify whether the pie should be a donut.',
        type: 'boolean',
    },
    value: {
        description: 'List of numerical values to power the pie chart.',
        type: 'array',
        items: {
            type: 'number',
        },
        default: '> primary | seriesByIndex(1)',
    },
    valueField: {
        default: '> value | getField()',
        description: 'Specify the field that corresponds to the data powering values.',
        type: 'string',
    },
};

const themes: ThemedDefaults = {
    defaultBackgroundColor: props =>
        pick({
            enterprise: {
                dark: variables.black(props),
                light: variables.backgroundColor(props),
            },
            prisma: variables.backgroundColorSidebar(props),
        })(props),
};

const editorConfig: EditorConfig[] = [
    {
        label: _('Data configurations'),
        layout: [
            [
                {
                    label: _('Label'),
                    editor: 'editor.columnSelector',
                    option: 'label',
                    context: 'valuesContext',
                    editorProps: {
                        dataSourceKey: 'primary',
                    },
                },
            ],
            [
                {
                    label: _('Value'),
                    editor: 'editor.columnSelector',
                    option: 'value',
                    context: 'valuesContext',
                    editorProps: {
                        dataSourceKey: 'primary',
                    },
                },
            ],
        ],
    },
    {
        label: _('Data display'),
        layout: [
            [
                {
                    label: _('Display type'),
                    option: 'showDonutHole',
                    editor: 'editor.radioBar',
                    editorProps: {
                        values: [
                            { label: _('Pie'), value: false },
                            { label: _('Donut'), value: true },
                        ],
                    },
                },
            ],
            [
                {
                    label: _('Label display'),
                    option: 'labelDisplay',
                    editor: 'editor.select',
                    editorProps: {
                        values: [
                            { label: _('Values'), value: 'values' },
                            { label: _('Values & Percent'), value: 'valuesAndPercentage' },
                            { label: _('Off'), value: 'off' },
                        ],
                    },
                },
            ],
            [
                {
                    label: _('Collapse threshold'),
                    option: 'collapseThreshold',
                    editor: 'editor.number',
                    editorProps: {
                        min: 0,
                        max: 1,
                        step: 0.01,
                    },
                },
            ],
            [
                {
                    label: _('Collapsed slice label'),
                    option: 'collapseLabel',
                    editor: 'editor.text',
                },
            ],
        ],
    },
    {
        label: _('Color and style'),
        layout: [
            [
                {
                    label: _('Series colors'),
                    option: 'seriesColors',
                    editor: 'editor.seriesColors',
                },
            ],
            getBackgroundColorEditor({ themes }),
        ],
    },
];

/**
 * visualization configuration
 */
const config: VizConfig = {
    /**
     * unique viz key
     */
    key: 'splunk.pie',
    /**
     * viz name
     */
    name: 'Pie',
    category: VizCategory.COMPARISONS,
    /**
     * viz icon
     */
    icon: SuiChartPieIcon,
    placeholderIcon: PiePlaceholderIcon,
    dataContract,
    size,
    defaultContext,
    optionsSchema,
    editorConfig,
    events: {
        'point.click': {
            description: 'triggered when user clicks on a point in the pie chart',
        },
    },
    supports: [VizBehavior.DYNAMIC_OPTIONS, VizBehavior.PLACEHOLDER, VizBehavior.EVENTS],
    themes,
};

export default enhanceConfig(config);
