import { mapValues, isPlainObject } from 'lodash';
import {
    hashString,
    isDynamicOption,
    DYNAMIC_STRING_SUBSTITUTE,
} from '@splunk/dashboard-utils';
import type { PlainObject } from './FilterTypes';

type CategoryToPrefix = {
    [index: string]: string;
};

export const categoryToPrefix: CategoryToPrefix = {
    inputs: 'input_',
    visualizations: 'viz_',
    dataSources: 'ds_',
};

type HashStringOnObject = (
    obj: PlainObject | string | PlainObject[],
    k?: string
) => unknown;

/**
 * Recursively hash string values in a JSON object
 */
export const hashStringOnObject: HashStringOnObject = (
    obj: PlainObject | string | PlainObject[],
    k?: string
) => {
    if (typeof obj === 'string') {
        if (isDynamicOption(obj)) {
            return DYNAMIC_STRING_SUBSTITUTE;
        }
        return hashString(obj);
    }

    if (Array.isArray(obj)) {
        return obj.map((v) => hashStringOnObject(v));
    }

    if (isPlainObject(obj)) {
        if (k === 'context') {
            return {};
        }
        return mapValues(obj, (value: PlainObject, key) =>
            hashStringOnObject(value, key)
        );
    }

    return obj;
};
