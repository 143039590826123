import { useRef, useEffect } from 'react';

/**
 * This is the recommended way of saving previous props. Ref https://reactjs.org/docs/hooks-faq.html#how-to-get-the-previous-props-or-state
 * @param {*} value previous props
 */
export const usePrevious = <T,>(value: T): T | null => {
    const ref = useRef<T | null>(null);
    useEffect(() => {
        ref.current = value;
    });
    return ref.current;
};
