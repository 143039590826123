import SingleValueRadial from './PureSingleValueRadial/SingleValueRadial';
import withDashboardViz from '../common/withDashboardViz';
import vizConfig from './config';
import withTrellis from '../common/hocs/withTrellis';

export const config = vizConfig;
export const PureSingleValueRadial = SingleValueRadial;
// TODO: proper theming support for SVR when we come back to SVR viz
export const PureSingleValueRadialThemes = {};

export const computeVizProps = ({ trellisKey, ...props }) => {
    return { trellisKey, ...props };
};

const viz = withTrellis(
    withDashboardViz({
        ReactViz: SingleValueRadial,
        vizConfig,
        computeVizProps,
    })
);

export const { themes } = vizConfig;
export default viz;
