import React, { useEffect, useRef, useCallback, useMemo } from 'react';
import { _ } from '@splunk/ui-utils/i18n';
import { Message } from '@splunk/dashboard-ui';
import {
    useApiRegistry,
    useEventRegistry,
    usePreset,
} from '@splunk/dashboard-context';
import type {
    StructureItemType,
    Mode,
    LayoutDefinition,
    RootVisualizationsDefinition,
    RootDataSourcesDefinition,
    TokenState,
    SelectedItem,
} from '@splunk/dashboard-types';
import {
    BaseItemContainer,
    type BaseItemContainerProps,
} from '../BaseItemContainer';
import type { LineContainerProps } from '../LineContainer';
import LineContainer from '../connected/ConnectedLineContainer';

/**
 * A prop passed to the layout component.
 * @param {String} id visualization id
 * @param {*} props the props passed down to VisualizationContainer or LineContainer
 * @param {*} layoutItemType layoutItemType, could be 'line' or 'block'
 * @param {*} onSelected a callback function when visualization is selected
 */
const renderLayoutItem = (
    id: string,
    props: BaseItemContainerProps | LineContainerProps,
    layoutItemType: StructureItemType,
    onSelected: () => void
) => {
    if (layoutItemType === 'line') {
        return (
            <LineContainer
                key={id}
                id={id}
                {...(props as Omit<LineContainerProps, 'id'>)}
            />
        );
    }
    return (
        <BaseItemContainer
            key={id}
            id={id}
            {...(props as Omit<BaseItemContainerProps, 'id'>)}
            layoutItemType={layoutItemType}
            onSelected={onSelected}
        />
    );
};

export interface LayoutContainerProps {
    mode?: Mode;
    layout: LayoutDefinition;
    visualizations?: RootVisualizationsDefinition;
    dataSources?: RootDataSourcesDefinition;
    submittedTokens?: TokenState;
    width?: number;
    height?: number;
    actionMenus?: React.ReactNode[];
    selectedItems: SelectedItem[];
    onLayoutItemsSelect: (items: SelectedItem[]) => void;
    onLayoutStructureChange?: (structure: LayoutDefinition) => void;
    handleEvent?: (
        eventType: string,
        payload: Record<string, unknown>,
        eventId: string
    ) => void;
    showGrid?: boolean;
    isDashboardFullscreen: boolean;
}

const LayoutContainer = ({
    handleEvent,
    height, // container height in pixel
    layout,
    mode,
    onLayoutItemsSelect,
    onLayoutStructureChange,
    selectedItems,
    showGrid,
    width, // container width in pixel
    isDashboardFullscreen,
}: LayoutContainerProps) => {
    const layoutApi = useRef(null);
    const apiRegistry = useApiRegistry();
    const eventRegistry = useEventRegistry();
    const preset = usePreset();

    useEffect(() => {
        if (layoutApi.current) {
            apiRegistry.registerLayoutApi(layoutApi.current);
        }

        return () => apiRegistry.removeLayoutApi();
    }, [layoutApi, apiRegistry]);

    const onEventTrigger = useCallback(
        ({ type: eventType, originalEvent, payload }) => {
            const eventId = eventRegistry.registerEvent(originalEvent);
            handleEvent?.(eventType, payload, eventId);
        },
        [handleEvent, eventRegistry]
    );

    const layoutApiRef = useCallback((ref) => {
        layoutApi.current = ref;
    }, []);

    return useMemo(() => {
        const { type, options, structure: layoutStructure } = layout;

        if (!preset.findLayout(type)) {
            return (
                <Message
                    level="error"
                    message={_(`Layout ${type} is not defined`)}
                />
            );
        }

        const layoutProps = {
            // we send down the actual width/height of dashboard-core container
            containerHeight: height,
            containerWidth: width,
            layoutApiRef,
            layoutStructure,
            mode,
            onEventTrigger,
            onLayoutItemsSelect,
            onLayoutStructureChange,
            options:
                isDashboardFullscreen &&
                type === 'absolute' &&
                options?.display !== 'auto'
                    ? { ...options, display: 'auto' }
                    : options,
            renderLayoutItem,
            selectedItems,
            showGrid,
        };

        return preset.createLayout(type, layoutProps);
    }, [
        layout,
        preset,
        height,
        width,
        layoutApiRef,
        mode,
        onEventTrigger,
        onLayoutItemsSelect,
        onLayoutStructureChange,
        isDashboardFullscreen,
        selectedItems,
        showGrid,
    ]);
};

export default LayoutContainer;
