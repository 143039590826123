import { _ } from '@splunk/ui-utils/i18n';
import { defaultPalettesConfig } from '@splunk/visualization-color-palettes/editors/PresetPalettes';
import {
    isDynamicOption,
    getInitialPreset,
} from '@splunk/visualizations/common/utils/configUtils';
import type { ConnectedLineProps, ConnectedLineOptions } from './types';

const defaultContext = {
    defaultRangeValue: [
        { to: 20, value: '#D41F1F' },
        { from: 20, to: 40, value: '#D94E17' },
        { from: 40, to: 60, value: '#CBA700' },
        { from: 60, to: 80, value: '#669922' },
        { from: 80, value: '#118832' },
    ],
};

const basePresetContext = {
    strokeColorEditorConfig: undefined,
};

const basePresetOptions = {
    strokeColor: undefined,
};

export const presets = [
    {
        label: _('Static'),
        name: 'lineColor.static',
        value: {
            context: { ...basePresetContext },
            options: { ...basePresetOptions },
        },
    },
    {
        label: _('Dynamic'),
        name: 'lineColor.dynamic',
        value: {
            context: {
                ...basePresetContext,
                strokeColorEditorConfig: defaultContext.defaultRangeValue,
            },
            options: {
                ...basePresetOptions,
                strokeColor:
                    '> strokeDataPoint | rangeValue(strokeColorEditorConfig)',
            },
        },
    },
];

const StaticColorEditor = {
    label: _('Color Value'),
    option: 'strokeColor',
    editor: 'editor.color',
    showEditor: ({ options }: ConnectedLineProps) =>
        !isDynamicOption(options?.strokeColor),
    editorProps: {
        labelPosition: 'left',
        themeVariable: '@splunk/dashboard-layouts/ConnectedLine.strokeColor',
    },
};

const DynamicColorEditor = {
    label: _('Color Range'),
    option: 'strokeColor',
    context: 'defaultContext',
    editor: 'editor.dynamicColor',
    showEditor: ({ options }: ConnectedLineProps) =>
        isDynamicOption(options?.strokeColor),
    editorProps: {
        labelPosition: 'left',
        flyoutTitle: _('Dynamic Line Color'),
        formatters: [
            {
                label: _('Ranges'),
                value: 'rangeValue',
                isDefault: true, // show it by default when open dynamic color flyout
                defaults: {
                    strokeDataPoint: defaultContext.defaultRangeValue,
                },
            },
        ],
        dataSelectors: [
            {
                label: _('Color Field'),
                value: 'strokeDataPoint',
            },
        ],
        defaultPalettesConfig,
    },
};

export interface Editor {
    label: string;
    option?: string;
    editor: string;
    context?: string;
    key?: string;
    editorProps?: Record<string, unknown>;
    showEditor?: (args: ConnectedLineProps) => boolean;
}

export interface EditorSection {
    label: string;
    layout: Editor[][];
}

const editorConfig: EditorSection[] = [
    {
        label: _('Stroke'),
        layout: [
            [
                {
                    label: _('Opacity'),
                    option: 'strokeOpacity',
                    editor: 'editor.percent',
                },
                {
                    label: _('Thickness (px)'),
                    option: 'strokeWidth',
                    editor: 'editor.number',
                    editorProps: {
                        min: 1,
                    },
                },
                {
                    label: _('Dash Style'),
                    option: 'strokeDasharray',
                    editor: 'editor.number',
                    editorProps: {
                        min: 0,
                    },
                },
            ],
        ],
    },
    {
        label: _('Arrows'),
        layout: [
            [
                {
                    label: _('Starting Point'),
                    option: 'fromArrow',
                    editor: 'editor.toggle',
                    editorProps: {
                        labelPosition: 'right',
                    },
                },
                {
                    label: _('Ending Point'),
                    option: 'toArrow',
                    editor: 'editor.toggle',
                    editorProps: {
                        labelPosition: 'right',
                    },
                },
            ],
        ],
    },
    {
        label: _('Selected Data Field'),
        layout: [
            [
                {
                    label: _('Data Field'),
                    editor: 'editor.columnSelector',
                    option: 'strokeDataSeries',
                    context: 'strokeDataContext',
                    editorProps: {
                        dataSourceKey: 'primary',
                        prepareValue: (definition: {
                            options: ConnectedLineOptions;
                        }) => {
                            const {
                                options: { strokeDataSeries },
                            } = definition;
                            return {
                                context: {},
                                options: {
                                    strokeDataPoint: `> strokeDataSeries | lastPoint()`,
                                    strokeDataSeries,
                                },
                            };
                        },
                    },
                },
            ],
        ],
    },
    {
        label: _('Coloring'),
        layout: [
            [
                {
                    label: _('Mode'),
                    key: 'presetSelector',
                    editor: 'editor.presetSelector',
                    editorProps: {
                        presets,
                        value: ({
                            context,
                            options,
                        }: {
                            context: Record<string, unknown>;
                            options: ConnectedLineOptions;
                        }) => {
                            const initialPreset = getInitialPreset(
                                context,
                                options,
                                presets
                            );
                            return initialPreset?.label || null;
                        },
                    },
                },
            ],
            [
                {
                    ...StaticColorEditor,
                },
            ],
            [
                {
                    ...DynamicColorEditor,
                },
            ],
        ],
    },
];

export default editorConfig;
