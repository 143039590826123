import * as React from 'react';
import * as T from 'prop-types';
import { _ } from '@splunk/ui-utils/i18n';
import Message from '@splunk/visualizations-shared/Message';
import withFixedSizeContainer from '../hocs/FixedSizeContainer';

/**
 * @method isMissing
 * @param {any} v
 * @returns {boolean} true if the input value === null or undefined
 */
export const isMissing = (v): boolean => {
    return v === null || v === undefined;
};

/**
 * @method misskingKeys
 * @param {string[]} keys
 * @param {object} target
 * @returns {string[]} the keys of missing required props
 */
export const missingKeys = (keys: string[], target: Record<string, unknown>): string[] =>
    keys.filter(k => isMissing(target[k]));

export interface MissingPropsProps {
    width?: string | number;
    height?: string | number;
    missingProps: string[];
    backgroundColor?: string;
}

const propTypes: Record<keyof MissingPropsProps, T.Validator<any>> = {
    width: T.oneOfType([T.string, T.number]),
    height: T.oneOfType([T.string, T.number]),
    // eslint-disable-next-line react/no-unused-prop-types
    backgroundColor: T.string,
    missingProps: T.arrayOf(T.string),
};

/**
 * @method MissingPropsMessage
 * @param {MissingPropsProps} props
 * @returns {React.ReactElement}
 */
export const MissingPropsMessage = (props: MissingPropsProps): React.ReactElement => {
    const { width, height, missingProps } = props;
    return (
        <Message
            data-test="message"
            width={width}
            height={height}
            message={_(`Missing property: ${missingProps.join(', ')}`)}
        />
    );
};

MissingPropsMessage.propTypes = propTypes;
MissingPropsMessage.defaultProps = {
    width: '100%',
    height: 250,
};
export default withFixedSizeContainer(MissingPropsMessage);
