import type { MutableRefObject } from 'react';
import { cloneDeep, findLast, isNumber, pullAt, reduce, sortBy } from 'lodash';
import {
    collides,
    computeMaxHeight as utilsComputeMaxHeight,
    console,
} from '@splunk/dashboard-utils';
import type {
    Coordinate,
    ConnectedPosition,
    AbsolutePosition,
    Port,
    ConnectedLineItem,
    ConnectedLinePosition,
    AbsoluteLayoutStructure,
    AbsoluteBlockItem,
    AbsoluteLayoutItem,
    GridLayoutStructure,
    HandleDirection,
} from '@splunk/dashboard-types';
import { applyVizPadding, getAllEdges } from './edgeUtils';
import type { Offset, LineDirection } from '../types';

// export for gridLayout/legacyGridLayout
export const computeMaxHeight = utilsComputeMaxHeight;

/**
 *  check if a position is valid
 * @param {Object} position
 */
export const isBlockPositionValid = (position: AbsolutePosition): boolean =>
    !!(
        position &&
        Number.isInteger(position.x) &&
        Number.isInteger(position.y) &&
        Number.isInteger(position.w) &&
        Number.isInteger(position.h)
    );

export const isValidConnection = (
    connection: Coordinate | ConnectedPosition
): boolean => {
    if (
        'x' in connection &&
        'y' in connection &&
        Number.isInteger(connection.x) &&
        Number.isInteger(connection.y)
    ) {
        return true;
    }
    if (
        'item' in connection &&
        'port' in connection &&
        connection.item != null &&
        connection.port != null
    ) {
        return true;
    }
    return false;
};

/**
 * a line should has from and to
 */
export const isLinePositionValid = (
    position: ConnectedLinePosition
): boolean => {
    if (position.from != null && position.to != null) {
        return (
            isValidConnection(position.from) && isValidConnection(position.to)
        );
    }
    return false;
};

/**
 * filter invalid position
 * @param {Object[]} layoutStructure - Array of items (viz, edges, lines)
 * @returns {Object[]} - Returns filtered array
 */
export const removeInvalidItems = (
    layoutStructure: AbsoluteLayoutStructure = []
): AbsoluteLayoutStructure =>
    layoutStructure.filter((structure) => {
        if (structure.type === 'line') {
            return isLinePositionValid(structure.position);
        }
        return isBlockPositionValid(structure.position);
    });

export interface PositionStyle {
    width: string;
    height: string;
    transform: string;
}

/**
 * convert position to css object
 */
export const positionToStyle = (pos: AbsolutePosition): PositionStyle => ({
    width: `${pos.w}px`,
    height: `${pos.h}px`,
    transform: `translate(${pos.x}px, ${pos.y}px)`,
});

/**
 * convert position to css string
 */
export const positionToStyleString = (pos: AbsolutePosition): string => {
    const style = positionToStyle(pos);
    return reduce(style, (result, v, k) => `${result} ${k}:${v};`, '');
};

/**
 * get client position for given event with scale factor
 */
export const getClientPosition = (
    e: {
        clientX: number;
        clientY: number;
    },
    scaleFactor = 1
): Coordinate => ({
    x: Math.round(e.clientX / scaleFactor),
    y: Math.round(e.clientY / scaleFactor),
});

/**
 * compute offset from 2 positions
 */
export const getOffset = (
    currentPosition: Coordinate,
    startPosition: Coordinate
): Offset => ({
    offsetX: currentPosition.x - startPosition.x,
    offsetY: currentPosition.y - startPosition.y,
});

/**
 * check if a given offset can be considered as 'move'
 * @param {Object} config
 * @param {Number} config.offsetX
 * @param {Number} config.offsetY
 */
export const considerMoved = ({
    offsetX,
    offsetY,
}: {
    offsetX: number;
    offsetY: number;
}): boolean => offsetX !== 0 || offsetY !== 0;

/**
 * return a boundary for 2 positions
 * @param {Coordinate} startPos
 * @param {Coordinate} endPos
 */
export const positionsToBoundary = (
    startPos: Coordinate,
    endPos: Coordinate
): AbsolutePosition => ({
    x: Math.min(startPos.x, endPos.x),
    y: Math.min(startPos.y, endPos.y),
    w: Math.abs(startPos.x - endPos.x),
    h: Math.abs(startPos.y - endPos.y),
});

/**
 * filter abs items within a boundary
 * @param {Array} items
 * @param {Object} boundary
 */
export const filterBlockItemsByBoundary = (
    items: AbsoluteBlockItem[],
    boundary: AbsolutePosition
): AbsoluteBlockItem[] =>
    items.filter((item) => {
        const itemPosition = item.position;
        return !(
            boundary.x > itemPosition.x + itemPosition.w ||
            boundary.x + boundary.w < itemPosition.x ||
            boundary.y > itemPosition.y + itemPosition.h ||
            boundary.y + boundary.h < itemPosition.y
        );
    });

/**
 * find the first item that contains the pos.
 */
export const findTopBlockItemByPosition = (
    items: AbsoluteBlockItem[],
    pos: Coordinate,
    padding = 0
): AbsoluteBlockItem | undefined =>
    findLast(items, (item) => {
        const itemPosition = padding
            ? applyVizPadding({ item, padding }).position
            : item.position;
        return (
            itemPosition.x <= pos.x &&
            itemPosition.x + itemPosition.w >= pos.x &&
            itemPosition.y <= pos.y &&
            itemPosition.y + itemPosition.h >= pos.y
        );
    });

/**
 * Compute the new offset so current + new offset will be the multiple of snapTo unit
 * For examples:
 * (5, 2, 5) => 0, user moved less than half of the snapTo unit, return 0 so 5 + 0 = 5
 * (5, 3, 5) => 5, user moved more than half of the snapTo unit, return 5 so 5 + 5 = 5 * 2
 * (5, 12, 5) => 10
 * (5, 13, 5) => 15
 * @param {Number} current
 * @param {Number} offset
 * @param {Number} snapTo
 */
export const snapOffset = (
    current: number,
    offset: number,
    snapTo: number
): number => {
    // return a new offset
    const remainder = (current + offset) % snapTo;
    const newOffset =
        remainder > snapTo / 2
            ? offset + (snapTo - remainder)
            : offset - remainder;
    return newOffset;
};

export interface SnapOffsetToXYArgs {
    position: AbsolutePosition;
    offset: Offset;
    gridWidth: number;
    gridHeight: number;
    spacing?: number;
    padding?: number;
}

/**
 * snap item with its top-left corner (represent by x, y)
 * @param {Object} position
 * @param {Object} offset
 * @param {Number} gridWidth
 * @param {Number} gridHeight
 * @param {Number} spacing
 */
export const snapOffsetToXY = ({
    position,
    offset,
    gridWidth,
    gridHeight,
    spacing = 0,
    padding = 0,
}: SnapOffsetToXYArgs): Offset => {
    let { offsetX, offsetY } = offset;
    offsetX = snapOffset(position.x, offsetX, gridWidth + spacing) + padding;
    offsetY = snapOffset(position.y, offsetY, gridHeight + spacing) + padding;
    return {
        offsetX,
        offsetY,
    };
};

export type SnapOffsetToWHArgs = SnapOffsetToXYArgs;

/**
 * snap item with its size (represent by w, h)
 * @param {Object} position
 * @param {Object} offset
 * @param {Number} gridWidth
 * @param {Number} gridHeight
 * @param {Number} spacing
 */
export const snapOffsetToWH = ({
    position,
    offset,
    gridWidth,
    gridHeight,
    spacing = 0,
    padding = 0,
}: SnapOffsetToWHArgs): Offset => {
    let { offsetX, offsetY } = offset;
    offsetX =
        snapOffset(position.x + position.w, offsetX, gridWidth + spacing) +
        (padding - spacing);
    offsetY =
        snapOffset(position.y + position.h, offsetY, gridHeight + spacing) +
        (padding - spacing);
    return {
        offsetX,
        offsetY,
    };
};

export interface UpdateBlockItemSizeArgs {
    item: AbsoluteBlockItem;
    offset: Offset;
    dir: HandleDirection;
    options?: {
        minWidth?: number;
        minHeight?: number;
    };
}

/**
 * Mutate item coordinates and/or dimensions.
 * This mutation's new dimensions and coordinates are bounded within the original block item real estate.
 * @param {Object} item
 * @param {Object} offset
 * @param {String} dir Cartesian direction to shift item towards.
 * @param {Object} options
 */
export const updateBlockItemSize = ({
    item,
    offset,
    dir,
    options: { minWidth = 0, minHeight = 0 } = {},
}: UpdateBlockItemSizeArgs): AbsoluteBlockItem => {
    const { w, h } = item.position;
    const { offsetX, offsetY } = offset;
    const updatedPosition = { ...item.position };

    // resizing from north
    if (['n', 'ne', 'nw'].includes(dir)) {
        updatedPosition.y += Math.min(h - minHeight, offsetY);
        updatedPosition.h -= offsetY;
    }
    // resizing from the south
    if (['s', 'se', 'sw'].includes(dir)) {
        updatedPosition.h += offsetY;
    }
    // resizing from the east
    if (['e', 'ne', 'se'].includes(dir)) {
        updatedPosition.w += offsetX;
    }
    // resizing from west
    if (['w', 'nw', 'sw'].includes(dir)) {
        updatedPosition.x += Math.min(w - minWidth, offsetX);
        updatedPosition.w -= offsetX;
    }

    updatedPosition.w = Math.max(minWidth, updatedPosition.w);
    updatedPosition.h = Math.max(minHeight, updatedPosition.h);
    return {
        ...item,
        position: updatedPosition,
    };
};

export const updateBlockItemPosition = (
    item: AbsoluteBlockItem,
    offset: Offset
): AbsoluteBlockItem => {
    const { offsetX, offsetY } = offset;
    return {
        ...item,
        position: {
            ...item.position,
            x: item.position.x + offsetX,
            y: item.position.y + offsetY,
        },
    };
};

/**
 * create offset based on dir, x and y
 * @method createOffset
 * @param {String} dir
 * @param {Number} x
 * @param {Number} y
 * @returns {Object} offset
 */
export const createOffset = (
    dir: HandleDirection,
    x: number,
    y: number
): Offset => {
    switch (dir) {
        case 'n':
            return {
                offsetX: 0,
                offsetY: -y,
            };
        case 's':
            return {
                offsetX: 0,
                offsetY: y,
            };
        case 'w':
            return {
                offsetX: -x,
                offsetY: 0,
            };
        case 'e':
            return {
                offsetX: x,
                offsetY: 0,
            };
        default:
            return {
                offsetX: 0,
                offsetY: 0,
            };
    }
};

interface computeScaleToFitProps {
    canvasWidth: number;
    canvasHeight: number;
    containerWidth: number;
    containerHeight: number;
    scrollbarWidth: number;
    max?: number;
    enableGridLayoutCssScaling?: boolean;
}

/**
 * compute scale factor
 * @param {Number} canvasWidth      width from the definition
 * @param {Number} canvasHeight     height from the definition
 * @param {Number} containerWidth   width from the size aware wrapper
 * @param {Number} containerHeight  height from the size aware wrrapper
 * @param {Number} scrollbarWidth   scrollbar width
 * @param {Number} max              max scale factor
 * @param {Boolean} enableGridLayoutCssScaling in grid layout, determines if scrollbar scale should always be returned
 */

export const computeScaleToFit = ({
    canvasWidth,
    canvasHeight,
    containerWidth,
    containerHeight,
    scrollbarWidth,
    max = Infinity,
    enableGridLayoutCssScaling = true,
}: computeScaleToFitProps): number => {
    if (
        !(
            isNumber(containerWidth) &&
            containerWidth > 0 &&
            isNumber(canvasWidth) &&
            canvasWidth > 0
        )
    ) {
        console.warn(
            `Failed to calculate layout scale: canvasWidth=${canvasWidth}, containerWidth=${containerWidth}; falling back to scale=1`
        );
        return 1;
    }

    const scale = Math.min(containerWidth / canvasWidth, max);
    const scrollbarScale = Math.min(
        (containerWidth - scrollbarWidth) / canvasWidth,
        max
    );

    if (!enableGridLayoutCssScaling) {
        return scrollbarScale;
    }

    if (canvasHeight * scale > containerHeight) {
        return scrollbarScale;
    }

    return scale;
};

/**
 *
 * @param {Array} items
 * @param {Number} from  target item index
 * @param {Number} to    where does this item move to
 */
export const moveLayoutItem = (
    items: AbsoluteLayoutItem[],
    from: number,
    to: number
): AbsoluteLayoutItem[] => {
    const structure = [...items];
    const removed = pullAt(structure, [from]);
    structure.splice(to, 0, removed[0]);
    return structure;
};

interface IsLineConnectedArgs {
    line: ConnectedLineItem;
    dir: LineDirection;
}

/**
 * check if a line is connected
 */
export const isLineConnected = ({ line, dir }: IsLineConnectedArgs): boolean =>
    'item' in line.position[dir];

interface DisconnectLineArgs {
    line: ConnectedLineItem;
    dir: LineDirection;
    absPos: Coordinate;
}

/**
 *  disconnect a line from item and set it to abs position
 */
export const disconnectLine = ({
    line,
    dir,
    absPos,
}: DisconnectLineArgs): ConnectedLineItem => {
    const updatePosition = {
        ...line.position,
        [dir]: {
            ...absPos,
        },
    };
    return {
        ...line,
        position: updatePosition,
    };
};

interface ConnectLineArgs {
    line: ConnectedLineItem;
    dir: LineDirection;
    itemId: string;
    port: Port;
}

/**
 * connect a line with an item and port
 */
export const connectLine = ({
    line,
    dir,
    itemId,
    port,
}: ConnectLineArgs): ConnectedLineItem => {
    const { position } = line;
    const updatedPosition = {
        ...position,
        [dir]: {
            item: itemId,
            port,
        },
    };
    return {
        ...line,
        position: updatedPosition,
    };
};

interface UpdateLineAbsPositionArgs {
    line: ConnectedLineItem;
    dir: LineDirection;
    offset: Offset;
}

/**
 * update line absolute position
 */
export const updateLineAbsPosition = ({
    line,
    dir,
    offset,
}: UpdateLineAbsPositionArgs): ConnectedLineItem => {
    const { offsetX, offsetY } = offset;
    const { position } = line;

    if (!('x' in position[dir])) {
        throw Error(
            `line item ${line.item} does not have x value for its direction ${dir}`
        );
    }

    if (!('y' in position[dir])) {
        throw Error(
            `line item ${line.item} does not have y value for its direction ${dir}`
        );
    }

    const { x, y } = position[dir] as Coordinate;

    return {
        ...line,
        position: {
            ...position,
            [dir]: {
                x: x + offsetX,
                y: y + offsetY,
            },
        },
    };
};

/**
 *
 * @param {Object} from
 * @param {Object} to
 */
export const computeLineBoxPosition = (
    from: Coordinate,
    to: Coordinate
): Coordinate => ({
    x: Math.min(from.x, to.x),
    y: Math.min(from.y, to.y),
});

/**
 *
 * @param {Object} from
 * @param {Object} to
 * @param {Object} box
 */
export const computeLineRelativePosition = (
    from: Coordinate,
    to: Coordinate,
    box: Coordinate
): {
    from: {
        x: number;
        y: number;
    };
    to: {
        x: number;
        y: number;
    };
} => ({
    from: {
        x: from.x - box.x,
        y: from.y - box.y,
    },
    to: {
        x: to.x - box.x,
        y: to.y - box.y,
    },
});

/**
 * Return the bottom coordinate of the layout.
 *
 * @param  {Array} layout Layout array.
 * @return {Number}       Bottom coordinate.
 */
export const bottom = (layout: AbsoluteBlockItem[]): number => {
    return layout.length > 0
        ? Math.max(
              ...layout.map(({ position }) => {
                  return position.y + position.h;
              })
          )
        : 1;
};

/**
 * Before moving item down, it will check if the movement will cause collisions
 * and move those items down before.
 * @method resolveCompactionCollision
 * @param {Array} layout
 * @param {Object} itemToMove current item which need to be moved to solve the collision
 * @param {Number} moveToCoord the destination coordination of y axis
 * @returns {Array} [newLayout, newItem]
 */
export const resolveCompactionCollision = (
    layout: AbsoluteBlockItem[],
    itemToMove: AbsoluteBlockItem,
    moveToCoord: number
): [AbsoluteBlockItem[], AbsoluteBlockItem] => {
    let axisVal = itemToMove.position.y;
    axisVal += 1;
    let newLayout = cloneDeep(layout);
    const itemIndex = newLayout.findIndex(
        (layoutItem) => layoutItem.item === itemToMove.item
    );
    const newItem = cloneDeep(itemToMove);
    newItem.position.y = axisVal;
    // Go through each item we collide with.
    // If there is a collision, we will move this collision down, otherwise set y = moveToCoord and return
    for (let i = itemIndex + 1; i < layout.length; i += 1) {
        // Optimization: we can break early if we know we're past this el
        // We can do this b/c it's a sorted layout
        if (newLayout[i].position.y > newItem.position.y + newItem.position.h) {
            break;
        }
        if (collides(newItem, newLayout[i])) {
            [newLayout, newLayout[i]] = resolveCompactionCollision(
                layout,
                layout[i],
                moveToCoord + newItem.position.h
            );
        }
    }

    newItem.position.y = moveToCoord;
    newLayout[itemIndex] = newItem;
    return [newLayout, newItem];
};

/**
 * get all collisions given an item and current layout
 * @method getAllCollisions
 * @param {Array} layout
 * @param {Object} layoutItem
 * @returns {Array} sorted layout
 */
export const getAllCollisions = (
    layout: AbsoluteBlockItem[],
    layoutItem: AbsoluteBlockItem
): AbsoluteBlockItem[] => {
    return layout.filter((item) => collides(item, layoutItem));
};

interface CloneBlockItemArgs {
    id: string;
    item: AbsoluteBlockItem;
    offsetMultiplier: number;
}

/**
 * clone a block item
 * @param {Number} id
 * @param {Object} item
 * @param {Number} offsetMultiplier
 */
export const cloneBlockItem = ({
    id,
    item,
    offsetMultiplier,
}: CloneBlockItemArgs): AbsoluteBlockItem => {
    const copiedPosition = item.position;
    return {
        ...item,
        item: id,
        position: {
            x: copiedPosition.x + 20 * offsetMultiplier,
            y: copiedPosition.y + 20 * offsetMultiplier,
            w: copiedPosition.w,
            h: copiedPosition.h,
        },
    };
};

interface CloneLineArgs {
    id: string;
    item: ConnectedLineItem;
    offsetMultiplier: number;
}

/**
 * clone an line, it assume the line is not connected
 * @param {Number} id
 * @param {Object} item
 * @param {Number} offsetMultiplier
 */
export const cloneLine = ({
    id,
    item,
    offsetMultiplier,
}: CloneLineArgs): ConnectedLineItem => {
    const copiedPosition = item.position;
    return {
        ...item,
        item: id,
        position: {
            from: {
                x:
                    (copiedPosition.from as Coordinate).x +
                    20 * offsetMultiplier,
                y:
                    (copiedPosition.from as Coordinate).y +
                    20 * offsetMultiplier,
            },
            to: {
                x: (copiedPosition.to as Coordinate).x + 20 * offsetMultiplier,
                y: (copiedPosition.to as Coordinate).y + 20 * offsetMultiplier,
            },
        },
    };
};

interface ShiftViewportOnZoomArgs {
    scrollLeft: number;
    scrollTop: number;
    offsetWidth: number;
    offsetHeight: number;
    scaleRatio: number;
}

/**
 * Calculates new viewport position after a zoom event to keep the previous central point in the center of
 * the scaled canvas. See MR #2030 for algorithm details.
 * @param {Number} scrollLeft viewport left edge offset relative to canvas
 * @param {Number} scrollTop viewport top edge offset relative to canvas
 * @param {Number} offsetWidth viewport width
 * @param {Number} offsetHeight viewport height
 * @param {Number} scaleRatio new scale to old scale ratio
 * @returns {Object} object containing new scrollLeft and scrollTop values
 */
export const shiftViewportOnZoom = ({
    scrollLeft,
    scrollTop,
    offsetWidth,
    offsetHeight,
    scaleRatio,
}: ShiftViewportOnZoomArgs): {
    scrollLeft: number;
    scrollTop: number;
} => {
    const middleOffsetWidth = offsetWidth / 2;
    const middleOffsetHeight = offsetHeight / 2;
    return {
        scrollLeft:
            (scrollLeft + middleOffsetWidth) * scaleRatio - middleOffsetWidth,
        scrollTop:
            (scrollTop + middleOffsetHeight) * scaleRatio - middleOffsetHeight,
    };
};

/**
 * return position relative to the canvas rect
 */
export const computeRelativePosition = (
    e: {
        clientX: number;
        clientY: number;
    },
    canvasRef: unknown,
    scale = 1
): Coordinate => {
    const pos = getClientPosition(e, scale);
    const canvasDomNode = (canvasRef as MutableRefObject<Element> | undefined)
        ?.current;
    const rect = canvasDomNode?.getBoundingClientRect?.();
    if (pos && rect) {
        return {
            x: pos.x - rect.left / scale,
            y: pos.y - rect.top / scale,
        };
    }
    return pos;
};

/**
 * Scales a GridLayout by stretching the width of each item by the scale factor
 * @param {AbsoluteBlockItem[]} items items to scale
 * @param {Number} scale how much to scale
 */
export const scaleGridLayoutStructureByWidth = ({
    layout,
    scale = 1,
}: {
    layout: GridLayoutStructure;
    scale?: number;
}): AbsoluteBlockItem[] => {
    if (scale === 1) {
        return layout;
    }

    // Compute the edges of the existing layout. Since we're scaling
    // just the X and width we only care about vertical edges.
    const edges = getAllEdges(layout);
    const verticalEdges = sortBy(
        edges.filter((edge) => edge.orientation === 'vertical'),
        ['edgeStart.x']
    );

    // Scale the vertical edges and than align the
    // left and right items to the scaled edge position
    const newLayout: AbsoluteBlockItem[] = [];
    verticalEdges.forEach((edge) => {
        const {
            visualizations,
            edgeStart: { x },
        } = edge;
        const scaledEdgeX = Math.round(x * scale);
        const left = visualizations.filter((viz) => viz.position.x < x);
        const right = visualizations.filter((viz) => viz.position.x >= x);

        // Update the Width of the layout items using the difference between the
        // newly scaled edge and the existing x
        left.forEach((layoutItem) => {
            const newLayoutItem = newLayout.find(
                ({ item: id }) => layoutItem.item === id
            );

            if (newLayoutItem) {
                newLayoutItem.position.w =
                    scaledEdgeX - newLayoutItem.position.x;
            }
        });

        // Since we walk the edges from the left to the right we
        // ALWAYS have to create the new layout item here but won't
        // ever need to update it.
        right.forEach((layoutItem) => {
            newLayout.push({
                ...layoutItem,
                position: {
                    ...layoutItem.position,
                    x: scaledEdgeX,
                },
            });
        });
    });

    return newLayout;
};
