import React, {
    forwardRef,
    type Ref,
    useCallback,
    useState,
    type MouseEvent,
    useMemo,
    useEffect,
    useRef,
} from 'react';
import { last, uniqBy } from 'lodash';
import {
    useDashboardProfiler,
    VIZ_MOVE_EVENT,
    VIZ_SELECT_EVENT,
} from '@splunk/dashboard-telemetry';
import { useEventCallback } from '@splunk/dashboard-ui';
import { isMac, noop } from '@splunk/dashboard-utils';
import type {
    AbsoluteBlockItem,
    Coordinate,
    SelectedItem,
    StructureItemType,
} from '@splunk/dashboard-types';
import Canvas from './Canvas';
import SelectBox from './SelectBox';
import { useMouseDownWithHandleEventListeners } from '../hooks';
import {
    getOffset,
    considerMoved,
    computeRelativePosition,
    findTopBlockItemByPosition,
    positionsToBoundary,
    filterBlockItemsByBoundary,
} from '../utils/layoutUtils';
import type { CanvasProps, Offset } from '../types';

interface AbsoluteCanvasState {
    startPosition: Coordinate | null;
    currentPosition: Coordinate | null;
    isMoving: boolean;
    isSelecting: boolean;
}

/**
 * default internal state
 */
const defaultState: AbsoluteCanvasState = {
    startPosition: null,
    currentPosition: null,
    isMoving: false,
    isSelecting: false,
};

export interface Props extends CanvasProps {
    initialState?: AbsoluteCanvasState;
    movable?: boolean;
    allowMultiselect: boolean;
    selectedLayoutItems?: SelectedItem[];
    blockItems: AbsoluteBlockItem[];
    onItemSelected: (e: MouseEvent, items: SelectedItem[]) => void;
    onItemMove?: (e: MouseEvent, offset: Offset) => void;
    onItemMoved?: (e: MouseEvent, offset: Offset) => void;
    children: JSX.Element | JSX.Element[];
}

const DEFAULT_SELECTED_LAYOUT_ITEMS: SelectedItem[] = [];

const AbsoluteCanvas = (
    props: Props & { canvasRef: Ref<HTMLDivElement> }
): JSX.Element => {
    const {
        scale,
        children,
        canvasRef,
        blockItems,
        onItemMove = noop,
        onItemMoved = noop,
        onItemSelected,
        movable = false,
        allowMultiselect,
        selectedLayoutItems = DEFAULT_SELECTED_LAYOUT_ITEMS,
        initialState = defaultState,
        onMouseDownCapture,
        onMouseUpCapture,
        ...canvasProps
    } = props;

    // State management
    const [startPosition, setStartPosition] = useState(
        initialState.startPosition
    );
    const [currentPosition, setCurrentPosition] = useState(
        initialState.currentPosition
    );
    const [isMoving, setIsMoving] = useState(initialState.isMoving);
    const [isSelecting, setIsSelecting] = useState(initialState.isSelecting);
    // a ref to track whether items are moving without triggering any effects
    const isMovingRef = useRef(false);

    const profiler = useDashboardProfiler();

    const resetState = useCallback(() => {
        setStartPosition(defaultState.startPosition);
        setCurrentPosition(defaultState.currentPosition);
        setIsMoving(defaultState.isMoving);
        setIsSelecting(defaultState.isSelecting);
        isMovingRef.current = false;
    }, []);

    useEffect(() => {
        // need ref here to avoid including `isMoving` state and thus
        //  triggering the useEffect when the isMoving state updates
        if (
            isMovingRef.current &&
            blockItems &&
            profiler?.hasPartialMeasurement(VIZ_MOVE_EVENT)
        ) {
            profiler?.endMeasurement(VIZ_MOVE_EVENT);
        }

        // IMPORTANT: we need blockItems as a hook dependency since we only
        //  want to end measurement when an update was caused by blockItems changing
    }, [blockItems, profiler]);

    // Check for newly selected items to emit telemetry for
    useEffect(() => {
        if (selectedLayoutItems.length) {
            profiler?.emitAndClearTimer({
                timerName: VIZ_SELECT_EVENT,
                metadata: {
                    numOfSelectedItems: selectedLayoutItems.length,
                },
            });
        }
    }, [profiler, selectedLayoutItems]);

    const handleItemSelected = useCallback(
        (e, itemIds = []) => {
            // capture telemetry for selecting a viz
            profiler?.startTimer({
                timerName: VIZ_SELECT_EVENT,
            });
            const isMacOS = isMac();
            if ((isMacOS && e.metaKey) || (!isMacOS && e.ctrlKey)) {
                // Only allow block and input types to be selected with meta+click
                const selectedItems = selectedLayoutItems.filter(
                    ({ type }: SelectedItem) =>
                        type === 'block' || type === 'input'
                );
                onItemSelected(e, uniqBy([...selectedItems, ...itemIds], 'id'));
            } else {
                onItemSelected(e, itemIds);
            }
        },
        [profiler, selectedLayoutItems, onItemSelected]
    );

    const handleMouseDownOnItem = useCallback(
        (event: MouseEvent, blockItem: SelectedItem) => {
            const { id, type = 'block' } = blockItem;
            // mouse down on an item, set it to selected and start moving.
            if (
                !selectedLayoutItems.find(
                    ({ id: selectedId }) => selectedId === id
                )
            ) {
                // select the block item right away if it's not selected.
                handleItemSelected(event, [{ id, type }]);
            }
        },
        [handleItemSelected, selectedLayoutItems]
    );

    // Update current position and trigger onItemMove
    const handleMouseMove = useEventCallback((e) => {
        if (!startPosition) {
            return;
        }

        const newPosition = computeRelativePosition(e, canvasRef, scale);

        const offset = getOffset(newPosition, startPosition);
        if (isMoving && considerMoved(offset)) {
            e.preventDefault();
            onItemMove(e, offset);
        }

        setCurrentPosition(newPosition);
    });

    // If moving an item we'll trigger onItemMoved otherwise we select the items within the boundary
    const handleMouseUp = useEventCallback((e) => {
        if (!startPosition) {
            return;
        }

        e.preventDefault();

        const curPosition = computeRelativePosition(e, canvasRef, scale);
        const offset = getOffset(curPosition, startPosition);
        const isMoved = considerMoved(offset);

        if (isMoving && isMoved) {
            isMovingRef.current = false;
            onItemMoved(e, offset);
        } else if (isSelecting) {
            // if the mouse is moved between mousedown and mouseup, then we should find all the items covered by the select box
            const boundary = positionsToBoundary(startPosition, curPosition);
            const selectedBlocks = filterBlockItemsByBoundary(
                blockItems,
                boundary
            ).map(({ item, type = 'block' }) => ({
                id: item,
                type,
            }));

            // Just select the top item if single clicking a stacked viz
            let selectedBlockItems = [];
            if (!isMoved && selectedBlocks.length) {
                selectedBlockItems.push(last(selectedBlocks));
            } else {
                selectedBlockItems = selectedBlocks;
            }

            // it could be an unselect operation in view/edit mode, or a multiselect operation in edit mode
            if (selectedBlocks.length === 0 || isSelecting) {
                handleItemSelected(e, selectedBlockItems);
            }
        } else {
            // we're not moving, we're not selecting. This means we just want to select whatever we clicked on
            // this is an edge case when we have multiple items selected and we want to deselect all
            // except for the one we're clicking on
            const { item, type = 'block' } =
                findTopBlockItemByPosition(blockItems, curPosition) || {};
            if (
                item &&
                selectedLayoutItems.length > 1 &&
                selectedLayoutItems.find(({ id }) => id === item)
            ) {
                handleItemSelected(e, [{ id: item, type }]);
            }
        }

        resetState();
    });

    // Handle starting the select box or selecting and moving and item
    const handleMouseDown = useEventCallback((e) => {
        const pos = computeRelativePosition(e, canvasRef, scale);
        // always track start position
        setStartPosition(pos);

        const block = findTopBlockItemByPosition(blockItems, pos);

        if (!block) {
            // if we didn't click on a viz, start selection
            if (allowMultiselect) {
                setIsSelecting(true);
            }
            return;
        }

        const { item, type } = block;

        // mouse down on an item, set it to selected and start moving.
        handleMouseDownOnItem(e, {
            id: item,
            type: type as StructureItemType,
        });

        if (movable) {
            setIsMoving(true);
            isMovingRef.current = true;
        }
    });

    // we bind mouse move and mouse up on global so user move mouse out of canvas will still work
    useEffect(() => {
        document.addEventListener('mousemove', handleMouseMove);
        return () => {
            document.removeEventListener('mousemove', handleMouseMove);
        };
    }, [handleMouseMove]);

    useEffect(() => {
        document.addEventListener('mouseup', handleMouseUp);
        return () => {
            document.removeEventListener('mouseup', handleMouseUp);
        };
    }, [handleMouseUp]);

    useMouseDownWithHandleEventListeners({
        handleMouseDownOnItem,
        canvasRef,
        scale,
        setStartPosition,
        movable,
        setIsMoving,
        isMovingRef,
    });

    const selectBox = useMemo(() => {
        if (
            allowMultiselect &&
            isSelecting &&
            !isMoving &&
            startPosition &&
            currentPosition
        ) {
            return <SelectBox start={startPosition} end={currentPosition} />;
        }
        return null;
    }, [
        allowMultiselect,
        isSelecting,
        isMoving,
        startPosition,
        currentPosition,
    ]);

    return (
        <Canvas
            ref={canvasRef}
            {...canvasProps}
            scale={scale}
            onMouseDown={handleMouseDown}
            onContextMenu={resetState}
            onMouseDownCapture={onMouseDownCapture}
            onMouseUpCapture={onMouseUpCapture}
        >
            {selectBox}
            {children}
        </Canvas>
    );
};

export default forwardRef((props: Props, ref: React.Ref<HTMLDivElement>) => {
    return <AbsoluteCanvas canvasRef={ref} {...props} />;
});
