import { _ } from '@splunk/ui-utils/i18n';
import pick from '@splunk/themes/pick';
import variables from '@splunk/themes/variables';
import NumberFormattingConfig from '../../common/editorConfig/NumberFormatting';

const editorConfig = [
    {
        label: _('Data Settings'),
        layout: [
            [
                {
                    label: _('Show Header'),
                    option: 'showHeader',
                    editor: 'editor.checkbox',
                },
            ],
            [
                {
                    label: _('Show Row Numbers'),
                    option: 'rowNumbers',
                    editor: 'editor.checkbox',
                },
            ],
        ],
    },
    {
        label: _('Font'),
        layout: [
            [
                {
                    label: _('Header Text Color'),
                    option: 'headerTextColor',
                    editor: 'editor.color',
                    editorProps: {
                        pickFromTheme: theme =>
                            pick({
                                enterprise: variables.textColor,
                                prisma: {
                                    light: variables.contentColorDefault,
                                    dark: variables.neutral500,
                                },
                            })({ theme }),
                    },
                    // If property matches the default value specified in schema, its not set in options. Hence we check if its undefined.
                    showEditor: ({ options }) => options.showHeader || options.showHeader === undefined,
                },
            ],
            [
                {
                    label: _('Odd Row Text Color'),
                    option: 'rowTextColorOdd',
                    editor: 'editor.color',
                    editorProps: {
                        pickFromTheme: theme =>
                            pick({
                                enterprise: variables.linkColor,
                                prisma: variables.neutral500,
                            })({ theme }),
                    },
                },
            ],
            [
                {
                    label: _('Even Row Text Color'),
                    option: 'rowTextColorEven',
                    editor: 'editor.color',
                    editorProps: {
                        pickFromTheme: theme =>
                            pick({
                                enterprise: variables.linkColor,
                                prisma: variables.neutral500,
                            })({ theme }),
                    },
                },
            ],
        ],
    },
    {
        label: _('Fill'),
        layout: [
            [
                {
                    label: _('Background Color'),
                    option: 'backgroundColor',
                    editor: 'editor.color',
                    editorProps: {
                        pickFromTheme: theme =>
                            pick({
                                enterprise: {
                                    dark: variables.black,
                                    light: variables.backgroundColor,
                                },
                                prisma: variables.backgroundColorSidebar,
                            })({ theme }),
                    },
                },
            ],
            [
                {
                    label: _('Header Fill Color'),
                    option: 'headerBackgroundColor',
                    editor: 'editor.color',
                    editorProps: {
                        pickFromTheme: theme =>
                            pick({
                                enterprise: {
                                    light: variables.gray92,
                                    dark: variables.gray20,
                                },
                                prisma: variables.interactiveColorBackground,
                            })({ theme }),
                    },
                    // If property matches the default value specified in schema, its not set in options. Hence we check if its undefined.
                    showEditor: ({ options }) => options.showHeader || options.showHeader === undefined,
                },
            ],
            [
                {
                    label: _('Odd Row Fill Color'),
                    option: 'rowBackgroundColorOdd',
                    editor: 'editor.color',
                    editorProps: {
                        pickFromTheme: theme =>
                            pick({
                                enterprise: {
                                    light: variables.white,
                                    dark: variables.black,
                                },
                                prisma: variables.backgroundColorPage,
                            })({ theme }),
                    },
                },
            ],
            [
                {
                    label: _('Even Row Fill Color'),
                    option: 'rowBackgroundColorEven',
                    editor: 'editor.color',
                    editorProps: {
                        pickFromTheme: theme =>
                            pick({
                                enterprise: {
                                    light: variables.gray96,
                                    dark: () => '#0D1012',
                                },
                                prisma: variables.backgroundColorPage,
                            })({ theme }),
                    },
                },
            ],
        ],
    },
    NumberFormattingConfig,
];

export default editorConfig;
