import { AbstractFormatter } from '../Formatter';
import { TypedValue } from '../DataPrimitive';
import { DataPoint } from '../DataPoint';
import EncodingExecutor from '../EncodingExecutor';

/**
 * Prepends the given prefix (passed as a parameter) to the DataPoint.
 *
 * ```js
 * <SampleViz
 *     options={{
 *         textOption: '> primary | seriesByName("foo") | lastPoint() | prefix("bar")' // returns "bar100"
 *     }}
 *     dataSources={{
 *         primary: {
 *             data: {
 *                 fields: [{ name: 'foo' }]
 *                 columns: [[100, 200]]
 *             }
 *         }
 *     }}
 * />
 * ```
 */
export class Prefix extends AbstractFormatter<'string' | 'number', 'string'> {
    private prefix: string;

    constructor(prefix: any) {
        super();
        this.prefix = EncodingExecutor.rawTree(prefix);
    }

    protected formatTypedValue(p: DataPoint<'string' | 'number'>): TypedValue<'string'> {
        const { value } = p.getValue();
        const prefixedValue = this.prefix + value.toString();
        return { value: prefixedValue, type: 'string' };
    }
}
