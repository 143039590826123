import type {
    AbsoluteBlockItem,
    Coordinate,
    GridLayoutStructure,
} from '@splunk/dashboard-types';
import type { Intersections } from './edgeUtils';
import {
    splitGridByRows,
    splitGridByColumns,
    type SplitGrid,
    type SplitGridEntry,
} from './gridOrderUtils';

export type GridInfo = {
    width: number;
    height: number;
    originItem: AbsoluteBlockItem;
    itemToRemove: AbsoluteBlockItem;
    nodes: Intersections;
    splitByRows: SplitGrid;
    splitByColumns: SplitGrid;
};

export const DEFAULT_BLOCK_ITEM: AbsoluteBlockItem = {
    item: '',
    position: { x: -1, y: -1, w: -1, h: -1 },
};

/**
 * Translate an AbsoluteBlockItem object by the provided X and Y values
 * @param {AbsoluteBlockItem} item An AbsoluteBlockItem object to be translated
 * @param {Coordinate} translation An X and Y delta by which to translate the item
 * @returns A cloned object with the position translated by the provided delta
 */
export const translateBlockItem = (
    item: AbsoluteBlockItem,
    translation: Coordinate
): AbsoluteBlockItem => ({
    ...item,
    position: {
        ...item.position,
        x: item.position.x + translation.x,
        y: item.position.y + translation.y,
    },
});

/**
 * Translate an array of AbsoluteBlockItem objects by the provided X and Y values
 * @param {AbsoluteBlockItem[]} items Array of AbsoluteBlockItem objects to be translated
 * @param {Coordinate} translation An X and Y delta by which to translate the items
 * @returns A mapped array with each entry translated by the provided delta
 */
export const translateBlockItems = (
    items: AbsoluteBlockItem[],
    translation: Coordinate
): AbsoluteBlockItem[] =>
    items.map((item) => translateBlockItem(item, translation));

/**
 * Translate a SplitGridEntry object by the provided X and Y values
 * @param {SplitGridEntry} gridSplit Grid split to be translated
 * @param {Coordinate} translation An X and Y delta by which to translate the split. Applied to all content and the split origin.
 * @returns The SplitGridEntry with an updated origin and content.items[].position values
 */
export const translateGridSplit = (
    gridSplit: SplitGridEntry,
    translation: Coordinate
): SplitGridEntry => ({
    ...gridSplit,
    layout: translateBlockItems(gridSplit.layout, translation),
    content: {
        ...gridSplit.content,
        items: translateBlockItems(gridSplit.content.items, translation),
    },
    origin: {
        x: gridSplit.origin.x + translation.x,
        y: gridSplit.origin.y + translation.y,
    },
});

/**
 * Convert a grid structure into an object which contains the grid width, height,
 * origin block item, intersection nodes, and the result of splitGridByRows and/or
 * splitGridByColumns. If a value for `idToRemove` is provided then the AbsoluteBlockItem
 * with that ID will also be returned
 * @param {GridLayoutStructure} param0.layoutStructure The layout structure of a grid layout to be split
 * @param {String | undefined} param0.idToRemove The ID of a block item to be fetched from the grid structure
 * @param {"both" | "none" | "rows" | "columns"} param0.splitConfig If the grid should be split by rows, columns, both, or neither
 * @returns An object which describes how the grid structure can be split into rows and/or columns
 */
export const splitGrid = ({
    layoutStructure,
    idToRemove,
    splitConfig = 'both',
}: {
    layoutStructure: GridLayoutStructure;
    idToRemove?: string;
    splitConfig?: 'both' | 'none' | 'rows' | 'columns';
}): GridInfo => {
    // Create the result. It will be updated during execeution
    const gridInfo: GridInfo = {
        width: 0,
        height: 0,
        originItem: DEFAULT_BLOCK_ITEM,
        nodes: {},
        splitByRows: [],
        splitByColumns: [],
        itemToRemove: DEFAULT_BLOCK_ITEM,
    };

    if (layoutStructure.length === 0) {
        return gridInfo;
    }

    // Calculate the actual width/height of the structure and all block item nodes
    let originX = Number.MAX_SAFE_INTEGER;
    let originY = Number.MAX_SAFE_INTEGER;
    layoutStructure.forEach((item) => {
        const { x, y, w, h } = item.position;

        // If the current item right or bottom edges exceed the grid width or
        // height then use those edge positions for the new grid dimensions
        gridInfo.width = Math.max(gridInfo.width, x + w);
        gridInfo.height = Math.max(gridInfo.height, y + h);

        if (x <= originX && y <= originY) {
            originX = x;
            originY = y;
            gridInfo.originItem = item;
        }

        // Logic for getNodes but inline to prevent a second loop over layoutStructure
        [
            [x, y],
            [x + w, y],
            [x, y + h],
            [x + w, y + h],
        ].forEach(([cornerX, cornerY]) => {
            gridInfo.nodes[cornerX] ??= {};
            gridInfo.nodes[cornerX][cornerY] ??= [];
            gridInfo.nodes[cornerX][cornerY].push(item);
        });

        // While iterating over layoutStructure try to find the up-to-date itemToRemove value
        if (idToRemove === item.item) {
            gridInfo.itemToRemove = item;
        }
    });

    // Calculate the width and height with respect to the origin, but don't let the result be negative
    gridInfo.width = Math.max(0, gridInfo.width - originX);
    gridInfo.height = Math.max(0, gridInfo.height - originY);

    // Only invoke the cost of splitGridByRows if the value is requested by the caller
    if (splitConfig === 'both' || splitConfig === 'rows') {
        gridInfo.splitByRows = splitGridByRows({
            nodes: gridInfo.nodes,
            layout: layoutStructure,
            gridWidth: gridInfo.width,
            gridHeight: gridInfo.height,
            originViz: gridInfo.originItem,
        });
    }

    // Only invoke the cost of splitGridByColumns if the value is requested by the caller
    if (splitConfig === 'both' || splitConfig === 'columns') {
        gridInfo.splitByColumns = splitGridByColumns({
            nodes: gridInfo.nodes,
            layout: layoutStructure,
            gridWidth: gridInfo.width,
            gridHeight: gridInfo.height,
            originViz: gridInfo.originItem,
        });
    }

    return gridInfo;
};
