import * as React from 'react';
import * as T from 'prop-types';
import { ScaleOrdinal } from 'd3-scale';
import styled from 'styled-components';
import { useRef, useEffect } from 'react';
import {
    DEFAULT_FONT_FAMILY,
    DEFAULT_NODE_COLOR,
    DEFAULT_NODE_OPACITY,
    INACTIVE_OPACITY,
} from './PureSankey';
import { SankeyDataNode } from './SankeyModels';

import { truncateSVGTextWithEllipsis } from './SankeyUtils';

const MAX_NODE_TEXT_WIDTH = 150;
export interface SankeyNodeProps {
    node: SankeyDataNode;
    activeNodes: SankeyDataNode[];
    nodeWidth: number;
    chartWidth: number;
    handleMouseOver: (...args: any[]) => void;
    handleMouseLeave: (...args: any[]) => void;
    nodeTextColor: string;
    colorCategoryMapping: ScaleOrdinal<string, unknown, string> | string[];
}

const getNodeOpacity = (nodeIndex: number, activeNodes: SankeyDataNode[]): number => {
    if (!activeNodes || !activeNodes.length) {
        return DEFAULT_NODE_OPACITY;
    }
    const isActiveNode = activeNodes.find(node => node.index === nodeIndex);
    return isActiveNode ? DEFAULT_NODE_OPACITY : INACTIVE_OPACITY;
};

const getNodeHeight = (node): number => {
    return node.dy < 0 ? 1 : node.dy;
};

const NodeGroup = styled.g<{ node: SankeyDataNode; activeNodes: SankeyDataNode[] }>`
    opacity: ${props => getNodeOpacity(props.node.index, props.activeNodes)};
`;
NodeGroup.displayName = 'NodeGroup';

const NodeRect = styled.rect<{
    node: SankeyDataNode;
    colorCategoryMapping: any;
}>`
    fill: ${props =>
        typeof props.colorCategoryMapping === 'function'
            ? props.colorCategoryMapping(props.node.name)
            : DEFAULT_NODE_COLOR};
`;

const NodeText = styled.text<{ node: SankeyDataNode; chartWidth: number; nodeTextColor: string }>`
    text-anchor: ${props => (props.node.x < props.chartWidth / 2 ? 'start' : 'end')};
    font-size: 12px;
    font-family: ${DEFAULT_FONT_FAMILY};
    letter-spacing: 0.02em;
    fill: ${props => props.nodeTextColor};
`;

const SankeyNode = (props: SankeyNodeProps) => {
    const {
        node,
        activeNodes,
        nodeWidth,
        chartWidth,
        handleMouseOver,
        handleMouseLeave,
        colorCategoryMapping,
        nodeTextColor,
    } = props;

    const nodeTextRef = useRef();

    useEffect(() => {
        truncateSVGTextWithEllipsis(nodeTextRef.current, node.name, MAX_NODE_TEXT_WIDTH);
    });

    return (
        <NodeGroup
            node={node}
            activeNodes={activeNodes}
            className="node"
            key={`node-${node.index}`}
            data-test={`node-${node.index}`}
            transform={`translate(${node.x},${node.y})`}
        >
            <React.Fragment>
                <NodeRect
                    node={node}
                    height={getNodeHeight(node)}
                    width={nodeWidth}
                    colorCategoryMapping={colorCategoryMapping}
                    onMouseOver={ev => handleMouseOver(ev)}
                    onMouseLeave={ev => handleMouseLeave(ev)}
                />
                <NodeText
                    ref={nodeTextRef}
                    node={node}
                    chartWidth={chartWidth}
                    x={node.x < (chartWidth as number) / 2 ? 6 + nodeWidth : -6}
                    y={node.dy / 2}
                    dy=".35em"
                    nodeTextColor={nodeTextColor}
                >
                    {node.name}
                </NodeText>
            </React.Fragment>
        </NodeGroup>
    );
};

SankeyNode.propTypes = {
    node: T.object,
    activeNodes: T.arrayOf(T.object),
    nodeWidth: T.number,
    chartWidth: T.number,
    handleMouseOver: T.func,
    handleMouseLeave: T.func,
    colorCategoryMapping: T.oneOfType([T.func, T.arrayOf(T.string)]),
    nodeTextColor: T.string,
};

SankeyNode.defaultProps = {
    handleMouseOver: () => {},
    handleMouseLeave: () => {},
};

export default SankeyNode;
