import { isNumber } from 'lodash';
import { noop } from '@splunk/dashboard-utils';

/**
 * A Simple task scheduler
 */
class SimpleScheduler {
    static createScheduler(tickFunc, options) {
        return new SimpleScheduler(tickFunc, options);
    }

    /**
     *
     * @param {*} tickFunc a function that will be executed by scheduler, its return value will be used to schedule next execution.
     * return false from this function will stop the execution
     * @param {*} options
     */
    constructor(tickFunc = noop, options = {}) {
        this.tickFunc = tickFunc;
        this.minDelay = options.minDelay || 0;
        this.loop = this.loop.bind(this);
        this.t = null;
        this.stopped = false;
    }

    /**
     * execute tick function and stop next execution if error caught
     */
    async tick() {
        try {
            return await this.tickFunc();
        } catch (ex) {
            return false;
        }
    }

    /**
     * start scheduler
     */
    async start() {
        this.stopped = false;
        await this.loop();
    }

    async loop() {
        const delay = await this.tick();
        if (!this.stopped && delay !== false && isNumber(delay)) {
            this.t = setTimeout(this.loop, Math.max(this.minDelay, delay));
        }
    }

    /**
     * stop scheduler
     */
    stop() {
        this.stopped = true;
        if (this.t) {
            clearTimeout(this.t);
            this.t = null;
        }
    }
}

export default SimpleScheduler;
