import * as React from 'react';
import { isEqual } from 'lodash';
import { SvgMutator } from './SvgMutator';

/**
 * An instruction on how to perform animation via timed mutations of an SVG document
 */
export interface AnimationProps {
    /**
     * The ms with which to delay between binding rows of splunk data into the svg document via mutation. By inserting a delay between successive mutations, an animation effect is created.
     */
    delayMS: number;
    /**
     * Tells whether or not to loop the animation, or just do it once.
     */

    loop: boolean;
}

/**
 * An xPath which identifies which attributes to change in an SVG document, and a replacement value for said attributes. JEXL expressions are
 * used for the xPath and the value. The expressions may access any fields of the PureChoroplethSvgProps's data, as  well as a special field
 * called '$i' whose value will be the ordinal (zero based) of the row of data currently being bound to the SVG.
 */
export interface SvgAttributeBinding {
    /**
     * A JEXL expression which, when evaluated, will yield an XPath which identifies one or more attributes in an svg document
     */
    xPath: string;
    /**
     * A JEXL expression which, when evaluated, will yield a value that will be used to replace the attribute values indicated by the xPath field
     */
    value: string;
}

/**
 * The Viz's react properties
 */
export interface SvgChoroplethDynamicProps {
    /**
     * An svg document in the form of a string
     */
    svg: string; // literal SVG
    /**
     * An object whose fields will all be added to the JEXL context, and consequently can be referenced from the JEXL expressions in the dataBinding. The fields of the object must be arrays of strings representing data 'columns'.
     */
    data?: { [key: string]: (string | number)[] };
    /**
     * An array of SvgAttributeDataBindings that will all be applied via JEXL to extract values from the data, and mutate attributes of the SVG with said values. The data object is added to the JEXL context.
     */
    dataBinding?: SvgAttributeBinding[]; // each DataBinding is an instruction on how splunk data should affect the SVG
    /**
     * If present causes the dataBindings to animate one at a time, rather than to be all computed en-mass and applied a single mutation
     */
    animation?: AnimationProps;
    /**
     * Width of the SVG document
     */
    width?: number;
    /**
     * height of the SVG document
     */
    height?: number;
    onAreaClick?: (...args: any[]) => void;
}

interface SvgChoroplethState {
    svgNode: React.ReactNode;
}

/**
 * This react component simply take an SVG document, and mutates it by applying data to the document according to
 * dataBindings. The data (data prop) is processed by performing the mutation once for each 'row' of the data even though the data is formatted as columns.
 * If the data looks like {foo:['a', 'b', 'c'], bar:['x', 'y', 'z']} then mutation is performed three times, since the data has three rows.
 * The special $i variable is incremented for each row processed.
 * The first mutation has $i=0, the second has $1=1, the third has $i=2.
 * This allows for JEXL expressions to access the data in a row-like fashion via expressions such as 'foo[$i] + bar[$i]' which will return "ax" for the first row mutation, "by" for the second, and "cz" for the third mutation
 */
export default class SvgChoroplethDynamic extends React.Component<
    SvgChoroplethDynamicProps,
    SvgChoroplethState
> {
    node: Node;

    public static defaultProps = {
        svg: '',
    };

    constructor(props, context) {
        super(props, context);
        this.state = { svgNode: null };
        this.node = null;
    }

    componentDidMount(): void {
        this.mutate();
    }

    shouldComponentUpdate(nextProps, nextState): boolean {
        return (
            nextProps.svg !== this.props.svg ||
            !isEqual(nextProps.data, this.props.data) || // must do deep equal on data because when no data is passed we do 'let data = { featureIDs: [], values: [], fill: [] };' in ChoroplethSVG which looks to react like different data every time
            nextState.svgNode !== this.state.svgNode ||
            !isEqual(nextProps.width, this.props.width) ||
            !isEqual(nextProps.height, this.props.height)
        );
    }

    componentDidUpdate(prevProps: Readonly<SvgChoroplethDynamicProps>): void {
        if (
            prevProps.svg !== this.props.svg ||
            !isEqual(prevProps.data, this.props.data) ||
            !isEqual(prevProps.width, this.props.width) ||
            !isEqual(prevProps.height, this.props.height)
        ) {
            this.mutate();
        }
    }

    componentWillUnmount() {
        if (this.node) {
            this.node.removeEventListener('areaClick', this.handleAreaClick);
        }
    }

    handleAreaClick = (ev: CustomEvent): void => {
        if (typeof this.props.onAreaClick === 'function') {
            this.props.onAreaClick({ ...ev, payload: ev.detail });
        }
    };

    areaClickRef = (node: Node): void => {
        if (!node) {
            return;
        }

        node.addEventListener('areaClick', (ev: CustomEvent) => this.handleAreaClick(ev));
        this.node = node;
    };

    mutate(): void {
        const { svg } = this.props;
        if (svg) {
            const m = new SvgMutator(this.props);
            try {
                // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
                this.setState({ svgNode: m.mutate() });
            } catch (e) {
                console.error(e);
            }
        }
    }

    render(): JSX.Element {
        const { width = '100%', height = '100%' } = this.props;
        const { svgNode } = this.state;
        if (svgNode) {
            return (
                <div style={{ width, height }} ref={this.areaClickRef}>
                    {svgNode}
                </div>
            );
        }
        return null;
    }
}
