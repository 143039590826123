/**
 * Add a property to the Item class to inform that it cannot render below a specified size
 * @param {LayoutItem} Item The item to enhance
 * @param {{ width?: number, height?: number }} [minimumSize = {}] Minimum item size
 */
export default (Item, minimumSize) => {
    Item.minimumSize = {
        width: minimumSize?.width ?? 0,
        height: minimumSize?.height ?? 0,
    };

    return Item;
};
