import React from 'react';
import styled from 'styled-components';
import { StatusOk } from '@splunk/dashboard-icons';
import WarningIcon from '@splunk/react-icons/enterprise/Warning';
import ErrorIcon from '@splunk/react-icons/enterprise/Error';
import Clickable from '@splunk/react-ui/Clickable';
import { variables } from '@splunk/themes';
// AllVariables points to non-color theme properties, so we are pulling in PrismaAliases to get shared color properties (may still contain non-colors)
import type { PrismaAliases } from '@splunk/themes/types/enterprise/prismaAliases';

const types = ['error', 'ok', 'warning'] as const;
export type IconStatus = typeof types[number];
const statusMap: Record<
    IconStatus,
    { icon: typeof StatusOk; color: keyof PrismaAliases }
> = {
    error: {
        icon: ErrorIcon,
        color: 'statusColorHigh',
    },
    ok: { icon: StatusOk, color: 'statusColorInfo' },
    warning: {
        icon: WarningIcon,
        color: 'statusColorMedium',
    },
};

interface ClickableStyleProps {
    height: number;
    width: number;
    iconSize: number;
    iconSizeOnHover: number;
    colorKey: keyof PrismaAliases;
    clickable?: boolean;
}

const ClickableIcon = styled(Clickable)`
    display: flex;
    justify-content: center;
    align-items: center;
`;

const IconContainer = styled.div.attrs(() => ({
    'data-test': 'simple-status-icon-container',
}))<ClickableStyleProps>`
    height: ${({ height }) => height}px;
    width: ${({ width }) => width}px;
    // padding to increase the hover area on the icon to show tooltip
    padding: 0 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    svg {
        height: ${({ iconSize }) => iconSize}px;
        width: ${({ iconSize }) => iconSize}px;
    }

    // Increase SVG size when this component is hovered over
    &:hover svg {
        height: ${({ iconSizeOnHover }) => iconSizeOnHover}px;
        width: ${({ iconSizeOnHover }) => iconSizeOnHover}px;
    }
    color: ${({ colorKey }) => variables[colorKey]};
    cursor: ${({ clickable }) => (clickable ? 'pointer' : 'auto')};
`;

export interface SimpleStatusIconProps {
    elementRef?: React.Ref<HTMLButtonElement | HTMLAnchorElement>;
    status?: IconStatus;
    height?: number;
    iconSize?: number;
    iconSizeOnHover?: number;
    onClick?: (() => void) | null;
}

const SimpleStatusIcon = ({
    status,
    height = 24,
    iconSize = 13,
    iconSizeOnHover = 15,
    onClick,
    elementRef,
}: SimpleStatusIconProps) => {
    if (!status || !types.includes(status)) {
        return null;
    }

    const Icon = statusMap[status].icon;
    const clickable = typeof onClick === 'function';

    const ContainedIcon = (
        <IconContainer
            height={height}
            width={iconSizeOnHover}
            iconSize={iconSize}
            iconSizeOnHover={clickable ? iconSizeOnHover : iconSize}
            colorKey={statusMap[status].color}
            clickable={clickable}
        >
            <Icon hideDefaultTooltip />
        </IconContainer>
    );

    return clickable ? (
        <ClickableIcon onClick={onClick} tabIndex={0} elementRef={elementRef}>
            {ContainedIcon}
        </ClickableIcon>
    ) : (
        ContainedIcon
    );
};

export default SimpleStatusIcon;
