import { baseInputSchema } from '../components/BaseInput';

export default {
    ...baseInputSchema,
    placeholder: {
        description: 'Placeholder text displayed when input is empty',
        type: 'string',
    },
    prefix: {
        description: 'Text that will be prepended to the text input value',
        type: 'string',
    },
    suffix: {
        description: 'Text that will be concatenated to the text input value',
        type: 'string',
    },
    defaultValue: {
        description: 'Default value of the input when no value is specified',
        type: 'string',
    },
};
