import * as React from 'react';
import styled from 'styled-components';
import { chain } from 'lodash';
import * as T from 'prop-types';
import withDashboardViz from '../common/withDashboardViz';
import config from './config';
import { mapToOldKey, getStyledShape } from '../common/shapeUtils';

const SVGContainer = styled.svg`
    width: ${props => props.width}px;
    height: ${props => props.height}px;
`;

export interface RectangleProps {
    options?: Record<string, any>;
    width: number;
    height: number;
    mode: string;
    onRectangleClick: (...args: any[]) => void;
}

const propTypes: Record<keyof RectangleProps, T.Validator<any>> = {
    width: T.number,
    height: T.number,
    options: T.object,
    mode: T.string,
    onRectangleClick: T.func,
};

const RectangleShape = getStyledShape('rect');

const RectangleViz = (props: RectangleProps): React.ReactElement => {
    const { width, height, options, onRectangleClick } = props;
    const { strokeWidth } = options;
    let { rx, ry } = options;

    // If either radius value is NaN/undefined, sets value to 0.
    rx = rx || 0;
    ry = ry || 0;

    return (
        <SVGContainer width={width} height={height}>
            <RectangleShape
                {...options}
                rx={rx}
                ry={ry}
                x={`${strokeWidth / 2}px`}
                y={`${strokeWidth / 2}px`}
                vectorEffect="non-scaling-stroke"
                width={`${width - strokeWidth}px`}
                height={`${height - strokeWidth}px`}
                onClick={onRectangleClick}
            />
        </SVGContainer>
    );
};

const validOptions = Object.keys(config.optionsSchema);

export const computeVizProps: any = (props): Partial<RectangleProps> => {
    const { dataSources, ...otherOptions } = props;
    const computedProps = { dataSources, options: null };
    computedProps.options = chain(otherOptions).pick(validOptions).thru(mapToOldKey).value();
    return computedProps;
};

RectangleViz.propTypes = propTypes;
RectangleViz.defaultProps = {
    width: 250,
    height: 300,
};

const Rectangle = withDashboardViz({
    ReactViz: RectangleViz,
    computeVizProps,
    vizConfig: config,
});
export default Rectangle;

export { config };
