import { DataSet } from '@splunk/datasource-utils';
import type {
    JSONCols,
    DataSourceMeta,
    RequestParams,
    ErrorLevel,
} from '@splunk/dashboard-types';
import type { Subscriber } from 'rxjs';
import DataSource from './DataSource';
import type { ObservableData } from './types';

export interface Options {
    data?: JSONCols;
    meta?: DataSourceMeta;
    delay?: number;
    errorLevel?: ErrorLevel;
    error?: string;
}

export default class SnapshotDataSource extends DataSource<Options> {
    data: DataSet;

    delay: number;

    meta: DataSourceMeta;

    errorLevel?: ErrorLevel;

    error?: string;

    constructor(options: Options = {}, context = {}) {
        super(options, context);
        if (options.data) {
            this.data = DataSet.fromJSONCols(
                options.data.fields,
                options.data.columns
            );
        } else {
            this.data = DataSet.fromJSONCols([], []);
        }
        this.meta = options.meta ?? {};
        this.delay = options.delay ?? 0;
        this.errorLevel = options.errorLevel;
        this.error = options.error;
    }

    request(requestParams?: RequestParams) {
        return (observer?: Subscriber<ObservableData>): (() => void) => {
            let timer: ReturnType<typeof setTimeout>;
            if (observer) {
                if (this.error) {
                    observer.error({
                        level: this.errorLevel,
                        message: this.error,
                        meta: { ...this.meta },
                    });
                    return () => undefined;
                }

                timer = setTimeout(() => {
                    observer.next({
                        data: this.data.getPage({
                            count: requestParams?.count,
                            offset: requestParams?.offset,
                        }),
                        meta: {
                            ...this.meta,
                            totalCount: this.data.getTotalCount(),
                        },
                    });
                    observer.complete();
                }, this.delay);
            }

            return () => {
                clearTimeout(timer);
            };
        };
    }
}
