import { _ } from '@splunk/ui-utils/i18n';

const editorConfig = [
    {
        label: _('Markdown Settings'),
        open: true,
        layout: [
            [
                {
                    label: _('Content'),
                    option: 'markdown',
                    editor: 'editor.markdown',
                },
            ],
        ],
    },
];

export default editorConfig;
