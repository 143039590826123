import React from 'react';
import styled from 'styled-components';
import T from 'prop-types';
import type { LayoutItemType } from '@splunk/dashboard-types';
import type { RenderLayoutItem } from '../types';

interface GridDivProps {
    id: string;
}

const GridDiv = styled.div<GridDivProps>`
    height: 100%;
    width: 100%;
    grid-row: ${(props) => props.id}-start / ${(props) => props.id}-end;
    grid-column: ${(props) => props.id}-start / ${(props) => props.id}-end;
    background: transparent;
`;

interface GridItemProps {
    /*
     * The ID of the item being rendered.
     */
    itemId: string;
    /*
     * The type of the item being rendered.
     */
    type?: LayoutItemType;
    /*
     * A method to render the items in the layout structure.
     */
    renderLayoutItem: RenderLayoutItem;
}

/**
 * Item positioned on a grid.
 */
const GridItem = ({
    itemId,
    type = 'block',
    renderLayoutItem,
}: GridItemProps): JSX.Element => (
    <GridDiv id={itemId} data-test="grid-item">
        {renderLayoutItem(itemId, { width: '100%', height: '100%' }, type)}
    </GridDiv>
);

GridItem.propTypes = {
    itemId: T.string.isRequired,
    type: T.string,
    renderLayoutItem: T.func.isRequired,
};

export default GridItem;
