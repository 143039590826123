import { COLOR_OR_TOKEN_PATTERN, getPattern } from '@splunk/visualizations-shared/schemaUtils';

export default {
    backgroundColor: {
        description:
            'Specify the color used for the background using a Hex Code. The default for enterprise light mode is "#FFFFFF". The default for enterprise dark mode is "#000000". The default for prisma dark mode is "#0B0C0E".',
        pattern: COLOR_OR_TOKEN_PATTERN,
        type: 'string',
    },
    majorUnit: {
        default: 'auto',
        description: 'Specify, in pixels, the spacing between major tick marks.',
        type: ['number', 'string'],
    },
    orientation: {
        default: 'vertical',
        description: 'Specify the axis orientation of the gauge.',
        pattern: getPattern(['horizontal', 'vertical']),
        type: 'string',
    },
    showLabels: { default: true, description: 'Specify whether to display labels.', type: 'boolean' },
    showValue: {
        default: true,
        description: 'Specify whether the gauge value is displayed.',
        type: 'boolean',
    },
    usePercentageRange: {
        default: false,
        description: 'Specify whether to format gauge ranges as percentages.',
        type: 'boolean',
    },
    usePercentageValue: {
        default: false,
        description: 'Specify whether to format the gauge values as percentages.',
        type: 'boolean',
    },
};
