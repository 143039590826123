/**
 * Return true if there's document.activeElement
 */
export const hasFocus = (): boolean => document.activeElement !== document.body;

/**
 * return current focus dom element
 */
export const getFocus = (): Element | null =>
    document.activeElement === document.body ? null : document.activeElement;

export const isVizFocused = (): boolean =>
    document.activeElement?.getAttribute('data-test') === 'select-outline';

// Layout items are focusable, but should not prevent keyboard shortcuts
export const allowKeyboardShortcut = (): boolean =>
    !hasFocus() || isVizFocused();
