import React from 'react';
import chroma from 'chroma-js';
import Table from '@splunk/react-ui/Table';
import { formatNumber, isNumerial, parseNumber } from '@splunk/react-visualizations/utils/numberUtils';
import { CellPropTypes, CellDefaultProps, CellDefaultStyle } from './CellPropShape';

const computeColorStyle = (
    number,
    { dataOverlayMode, textColor },
    { heatRange, heatOffset, max: maxNumber, min: minNumber }
) => {
    const style = { ...CellDefaultStyle, color: textColor };
    let heatValue;
    switch (dataOverlayMode) {
        case 'heatmap':
            heatValue =
                heatRange === 0
                    ? 0
                    : Math.min(Math.max(Math.ceil(((number - heatOffset) / heatRange) * 1000) / 1000, 0), 1);

            style.backgroundColor = chroma.scale(['#FFFFFF', '#D6563C'])(heatValue).hex();
            break;
        case 'highlow':
            if (number === minNumber) {
                style.backgroundColor = chroma('#1E93C6').hex();
            } else if (number === maxNumber) {
                style.backgroundColor = chroma('#D6563C').hex();
            }
            break;
        default:
            break;
    }
    return style;
};

const NumberCell = ({ value, onCellClick, cellOptions, fieldOptions }) => {
    const { numberPrecision, useThousandSeparators, unit, unitPosition, defaultPrecision = 20 } = cellOptions;
    const number = parseNumber(value);
    let v = number;
    v = formatNumber(number, numberPrecision, { useThousandSeparators, defaultPrecision });
    const style = computeColorStyle(number, cellOptions, fieldOptions);
    if (unit) {
        v = unitPosition === 'before' ? `${unit} ${v}` : `${v} ${unit}`;
    }
    return (
        <Table.Cell
            data-test="NumberCell-Test"
            style={style}
            title={v}
            align={fieldOptions.align}
            onClick={onCellClick}
            test-cell-type="number"
        >
            {v}
        </Table.Cell>
    );
};

NumberCell.propTypes = CellPropTypes;
NumberCell.defaultProps = CellDefaultProps;
NumberCell.canRender = (field, fieldOptions, value) => isNumerial(value);
export default NumberCell;
