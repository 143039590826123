import React from 'react';
import SVG, {
    type SVGPropsOptionalViewBox,
} from '@splunk/react-icons/SVGEnterprise';

/* eslint-disable max-len */
export default function DataCenter(
    props: SVGPropsOptionalViewBox
): ReturnType<typeof SVG> {
    return (
        <SVG viewBox="0 0 49 61" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path d="m12.1317 15.6274 12.1165-5.68171 12.1742 5.70471-12.1665 5.3183zm-2.0262 1.3159v27.3611l13.1377 5.9213v-27.4946zm15.1588 33.2777 13.1366-5.9166v-27.313l-13.1366 5.7422z" />
            <path d="m22.9548.448579c.8218-.3894462 1.775-.3894466 2.5967 0l21.2216 10.057321c1.0585.5017 1.7333 1.5682 1.7333 2.7396v33.8152c0 1.1711-.6745 2.2374-1.7326 2.7392l-21.2216 10.0642c-.8221.3899-1.7759.3899-2.5981 0l-21.2215-10.0642c-1.05812-.5018-1.7326-1.5681-1.7326-2.7392v-33.8152c0-1.1714.674805-2.2379 1.73331-2.7396zm1.7312 1.826371c-.274-.12981-.5917-.12981-.8656 0l-21.22153 10.05735c-.35284.1672-.57777.5227-.57777.9132v33.8152c0 .3904.22483.7458.57753.9131l21.22157 10.0641c.274.13.592.13.866 0l21.2215-10.0641c.3527-.1673.5776-.5227.5776-.9131v-33.8152c0-.3905-.225-.746-.5778-.9132z" />
        </SVG>
    );
}
