import { COLOR_OR_TOKEN_PATTERN, getPattern } from '@splunk/visualizations-shared/schemaUtils';

export default {
    backgroundColor: {
        description:
            'Specify the color used for the background using a Hex Code. The default for enterprise light mode is "#FFFFFF". The default for enterprise dark mode is "#000000". The default for prisma dark mode is "#0B0C0E".',
        pattern: COLOR_OR_TOKEN_PATTERN,
        type: 'string',
    },
    showBubbleLabels: {
        default: 'all',
        description:
            'Specify when the bubble label will be displayed, no labels, all labels or only the max labels.',
        pattern: getPattern(['all', 'max', 'none']),
        type: 'string',
    },
    bubbleRowScale: {
        default: 'global',
        description: 'Specify how bubbles are scaled relative to other rows.',
        pattern: getPattern(['global', 'row']),
        type: 'string',
    },
    bubbleScale: {
        default: 'area',
        description:
            'Specify whether the bubble size should scale based on the value being proportional to the radius or the area.',
        pattern: getPattern(['radius', 'area']),
        type: 'string',
    },
    colorMode: {
        default: 'categorical',
        description: `When category data is returned, 'categorical' option will color the bubbles by categories and 'sequential' option will color the bubbles by value.`,
        pattern: getPattern(['sequential', 'categorical']),
        type: 'string',
    },
    seriesColors: {
        type: 'array',
        items: { type: 'string' },
        description: `Specify the Hex code for colors used for bubbles when colorMode option is 'categorical'. For example, ['#C093F9', '#0000FF', '#D6563C'].`,
        default: ['#1E93C6', '#F2B827', '#D6563C', '#6A5C9E', '#31A35F'],
    },
    bubbleColor: {
        default: '#7B56DB',
        description: `Specify the Hex code for color used for bubbles when colorMode option is 'sequential'. For example: "#0000FF".`,
        pattern: COLOR_OR_TOKEN_PATTERN,
        type: 'string',
    },
    isBubbleSizeDynamic: {
        default: true,
        description: 'Specify whether the bubble size should be dynamic or fixed.',
        type: 'boolean',
    },
    minBubbleColorIntensity: {
        default: 0.25,
        description: `Specify the minimum opacity of the bubbles based on its value when the colorMode option is 'sequential' .
            Values can range from 0 (transparent) to 1 (opaque).`,
        type: 'number',
    },
    maxBubbleRadius: {
        default: 15,
        description:
            'Specify the max radius (in pixels) of the bubbles when isBubbleSizeDynamic option is false. Greater than 0.',
        type: 'number',
    },
    maxBubbleSize: {
        default: 1,
        description:
            'Specify the max percentage (in decimal format) of space a bubble should take up within a cell when isBubbleSizeDynamic option is true. The number specified must be between 0 and 1.',
        type: 'number',
    },
    minBubbleRadius: {
        default: 1,
        description:
            'Specify the min radius (in pixels) of the bubbles when isBubbleSizeDynamic option is false. Greater than 0.',
        type: 'number',
    },
    minBubbleSize: {
        default: 0.25,
        description:
            'Specify the min percentage (in decimal format) of space a bubble should take up within a cell when isBubbleSizeDynamic option is true. The number specified must be between 0 and 1.',
        type: 'number',
    },
    showMaxValuePulsation: {
        default: true,
        description: 'Specify whether the maximum bubble should pulsate.',
        type: 'boolean',
    },
    showLegend: {
        default: true,
        description: `Specify whether a legend should be rendered when colorMode option is 'categorical'.`,
        type: 'boolean',
    },
    useDefaultSort: {
        default: false,
        description: 'If set to true, will sort axes based on order of time, digits and strings.',
        type: 'boolean',
    },
};
