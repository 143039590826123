/* eslint-disable class-methods-use-this */
import { _ } from '@splunk/ui-utils/i18n';
import BaseRegistry from './BaseRegistry';

/**
 * Icon Registry
 */
class IconRegistry extends BaseRegistry {
    static create() {
        return new IconRegistry();
    }

    static VALID_MEDIA_TYPES = ['svg'];

    /**
     * check if the the media type from the dataURI belongs to the supported types
     * @param {DataURI} dataURI
     * @returns {Boolean}
     */
    isValidMediaType(dataURI) {
        return IconRegistry.VALID_MEDIA_TYPES.some(type => dataURI.indexOf(`image/${type}`) > -1);
    }

    /**
     * upload image if the image format is supported
     * @param {DataURI} dataURI
     * @param {Object} metaData
     * @param {String} providerType   e.g. 'kvstore', or 'sui'
     * @returns {String} id           e.g. 'kvstore://image123'
     */
    upload(dataURI, metaData, providerType) {
        if (!this.isValidMediaType(dataURI)) {
            throw new Error(_('The format of the uploaded file is not supported. Please upload svg file.'));
        }

        return super.upload(dataURI, metaData, providerType);
    }
}

export default IconRegistry;
