import { _ } from '@splunk/ui-utils/i18n';
import pick from '@splunk/themes/pick';
import variables from '@splunk/themes/variables';
import BackgroundColorConfig from '../../common/editorConfig/BackgroundColor';

const editorConfig = [
    {
        label: _('Rotation'),
        open: true,
        layout: [
            [
                {
                    label: _('Degrees'),
                    option: 'rotation',
                    editor: 'editor.number',
                    editorProps: { max: 360, min: -360 },
                },
                {},
            ],
        ],
    },
    {
        label: _('Font Settings'),
        open: true,
        layout: [
            [
                {
                    label: _('Font'),
                    option: 'fontFamily',
                    editor: 'editor.select',
                    editorProps: {
                        values: [
                            { value: 'Splunk Platform Sans' },
                            { value: 'Splunk Data Sans' },
                            { value: 'Splunk Platform Mono' },
                            { value: 'Arial' },
                            { value: 'Helvetica' },
                            { value: 'Times New Roman' },
                            { value: 'Comic Sans MS' },
                        ],
                    },
                },
                {
                    label: _('Font Size (px)'),
                    option: 'fontSize',
                    editor: 'editor.number',
                    editorProps: { min: 10 },
                },
            ],
            [
                {
                    label: _('Text Color'),
                    option: 'textColor',
                    editor: 'editor.color',
                    editorProps: {
                        pickFromTheme: theme =>
                            pick({
                                enterprise: {
                                    dark: variables.white,
                                    light: variables.gray20,
                                },
                                prisma: variables.contentColorDefault,
                            })({ theme }),
                    },
                },
            ],
            [
                {
                    label: _('Weight'),
                    option: 'fontWeight',
                    editor: 'editor.radioBar',
                    editorProps: {
                        values: [
                            { label: _('Normal'), value: 'normal' },
                            { label: _('Bold'), value: 'bold' },
                            { label: _('Light'), value: 'light' },
                        ],
                    },
                },
            ],
        ],
    },
    BackgroundColorConfig,
];

export default editorConfig;
