import { _ } from '@splunk/ui-utils/i18n';
import { isDynamicOption } from '@splunk/visualizations-shared/configUtils';

export const getDynamicMajorColorEditor = ({ defaultContext, defaultPalettesConfig }) => ({
    label: _('Major value'),
    option: 'majorColor',
    context: 'majorColorEditorConfig',
    editor: 'editor.dynamicColor',
    showEditor: ({ options }) => isDynamicOption(options.majorColor),
    editorProps: {
        labelPosition: 'top',
        flyoutTitle: _('Dynamic coloring: major value'),
        formatters: [
            {
                label: _('Ranges'),
                value: 'rangeValue',
                defaults: {
                    majorValue: defaultContext.majorColorConfig,
                },
            },
            {
                label: _('Matches'),
                value: 'matchValue',
                defaults: {
                    majorValue: defaultContext.majorColorMatchConfig,
                },
            },
        ],
        dataSelectors: [
            {
                label: _('Major value'),
                value: 'majorValue',
            },
        ],
        defaultPalettesConfig,
    },
});
