import { _ } from '@splunk/ui-utils/i18n';

export const DUPLICATE_INPUT_TOKEN = _(
    'Error: Token duplicated across multiple inputs'
);

export const DUPLICATE_ITEM = _('Error: Item duplicated in layout structure');

export const DUPLICATE_INPUT = _(
    'Error: Input duplicated in both layout structure and global inputs'
);

export const CANVAS_INPUT_NONEXISTENT = _(
    'Error: Input in layout structure does not exist'
);

export const GLOBAL_INPUT_NONEXISTENT = _(
    'Error: Input in global input structure does not exist'
);

export const GLOBAL_INPUT_NOT_PRESENT = _(
    'Error: Input is not present in the global inputs structure or layout structure'
);

export const VISUALIZATION_NONEXISTENT = _(
    'Error: Visualization in layout structure does not exist'
);

export const VISUALIZATION_NOT_PRESENT = _(
    'Error: Visualization is not present in layout structure'
);

export const CANNOT_CREATE_DS_TOKENS_UNNAMED = _(
    'When a data source is unnamed, generated tokens for the search results and job status will not be available'
);

export const CANNOT_CREATE_DS_TOKENS_INVALID = _(
    'When a data source is configured with an invalid name, generated tokens for the search results and job status will not be available'
);

export const CANNOT_ENSURE_DS_TOKEN_STABILITY = _(
    'When multiple data sources have the same name, generated tokens for the search results and job status may be unpredictable'
);
