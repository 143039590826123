import { Parser } from 'nearley';
import { Expr } from './AST';
import grammar = require('./nearley/generated/vizparser.js');

export class DslParser {
    static cache: Record<string, any> = {};

    public static parse(dsl: string): Expr[] {
        if (DslParser.cache[dsl]) {
            return [...DslParser.cache[dsl]];
        }
        const parser = new Parser(grammar);
        parser.feed(dsl);
        if (parser.results.length > 1) {
            console.warn(`Ambiguous parse of '${dsl}'`);
        }
        if (parser.results.length === 0) {
            console.warn(`No parsed results for '${dsl}'`);
        }
        DslParser.cache[dsl] = [...parser.results[0]];
        return parser.results[0];
    }
}
