import { hashString } from '@splunk/dashboard-utils';
import type { FilterFunction } from './FilterStrategyTypes';
import { categoryToPrefix } from './utils';

type JsonPatch = {
    op: string;
    path: string;
    // value is a serializable object, didn't bother to define its type, ref https://github.com/microsoft/TypeScript/issues/1897
    value: unknown;
};

/**
 * Remove PII data from a json patch generated by diffing dashboard definitions. Refer to https://tools.ietf.org/html/rfc6902
 * @param {Object} patch
 * @param {String} patch.op
 * @param {String} patch.path
 * @param {String} patch.value
 */
const filterDefinitionEdit = ({ op, path }: JsonPatch) => {
    // json patch always starts with / and uses / as delimiter.
    const parts = path.split('/');

    if (
        ['inputs', 'visualizations', 'dataSources'].includes(parts[1]) &&
        parts.length >= 3
    ) {
        parts[2] = hashString(parts[2], categoryToPrefix[parts[1]]);
    } else if (
        parts[1] === 'layout' &&
        parts[2] === 'structure' &&
        parts.length >= 4
    ) {
        parts[3] = hashString(parts[3], categoryToPrefix.visualizations);
    }

    return {
        op,
        path: parts.join('/'),
        // left out `value` because `value` may contain PII
    };
};

type PatchWithoutPII = { op: string; path: string };

interface FilterDefinitionEdits extends FilterFunction {
    (patches: JsonPatch[]): PatchWithoutPII[] | null;
}

const filterDefinitionEdits: FilterDefinitionEdits = (patches: unknown) => {
    return Array.isArray(patches)
        ? patches.map((patch) => filterDefinitionEdit(patch))
        : null;
};

export default filterDefinitionEdits;
