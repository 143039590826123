import { _ } from '@splunk/ui-utils/i18n';

const Threshold = {
    label: _('Threshold Settings'),
    open: true,
    layout: [
        [
            {
                label: _('Fill'),
                encoding: 'fill', // encoding name
                formatKey: 'ranges', // key for value in encoding config (fill.format.ranges)
                editor: 'editor.threshold',
            },
        ],
    ],
};

export default Threshold;
