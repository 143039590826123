import {
    DropdownInput,
    MultiselectInput,
    TextInput,
    NumberInput,
    TimeRangeInput,
} from '@splunk/dashboard-inputs';
import type { PresetMap } from '@splunk/dashboard-types';
import withMinimumSize from './utils/withMinimumSize';
import withResizeHandles from './utils/withResizeHandles';

[
    DropdownInput,
    MultiselectInput,
    TextInput,
    NumberInput,
    TimeRangeInput,
].forEach((Input) => {
    withResizeHandles(Input, ['w', 'e']);
    withMinimumSize(Input, { height: 75 });
});

export default {
    inputs: {
        'input.dropdown': DropdownInput,
        'input.multiselect': MultiselectInput,
        'input.text': TextInput,
        'input.number': NumberInput,
        'input.timerange': TimeRangeInput,
    } as PresetMap['inputs'],
};
