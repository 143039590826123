export default {
    data: {
        type: 'object',
    },
    vizOptions: {
        type: 'object',
    },
    meta: {
        type: 'object',
    },
    errorLevel: {
        type: 'string',
        enum: ['info', 'warning', 'error'],
    },
    error: {
        type: 'string',
    },
    delay: {
        type: 'number',
    },
    timeToStart: {
        type: 'number',
    },
    timeToComplete: {
        type: 'number',
    },
    increments: {
        type: 'number',
    },
};
