import type { VisualizationDefinition } from '@splunk/dashboard-types';
import config from '@splunk/visualizations/Area.config';
import { migrateBaseChartingVizDefinition } from './splunk-charting';

const validOptions = {
    ...config.optionsSchema,
};

// old default configurations should be retained in migrated configuration
const defaultChanges = {
    yAxisAbbreviation: 'off',
    y2AxisAbbreviation: 'off',
    showRoundedY2AxisLabels: false,
    legendTruncation: 'ellipsisMiddle',
    showY2MajorGridLines: true,
};

const migrateVizToSplunkArea = (vizDefinition: VisualizationDefinition): VisualizationDefinition =>
    migrateBaseChartingVizDefinition({
        defaultChanges,
        validOptions,
        vizDefinition,
        vizType: 'splunk.area',
    });

export { migrateVizToSplunkArea };
