import type {
    GlobalState,
    ReplaceTokenBindingParams,
    SmartSourceTokenState,
    TokenName,
    TokenNamespace,
    TokenRecord,
    TokenSlice,
    TokenState,
} from '@splunk/dashboard-types';
import { DEFAULT_TOKEN_NAMESPACE } from '@splunk/dashboard-utils';
import { isEqual, memoize, merge, pick, omit, isEmpty } from 'lodash';
import {
    createSelector,
    createSlice,
    createAction,
    type PayloadAction,
} from '@reduxjs/toolkit';
import { isNullOrUndefined } from '../utils/isNullOrUndefined';
import { contains, noTokens } from '../utils/token';
import resetStore from './resetStore';
import { selectDSTokens } from './smartSources';

interface UpdatePayload {
    tokens: TokenState;
    submit: boolean;
}

interface SetPayload {
    namespace: TokenNamespace;
    tokens: TokenRecord;
    submit: boolean;
}

interface UnsetPayload {
    tokens: Record<TokenNamespace, TokenName[]>;
    submit: boolean;
}

interface SubmitPayload {
    namespace: TokenNamespace;
}

interface SetNamespacesPayload {
    namespaces: Set<TokenNamespace>;
}

/**
 * Selectors
 */
export const selectTokens = (state: GlobalState): TokenSlice =>
    state?.tokens || noTokens;
export const selectSubmittedTokens = createSelector(
    [selectTokens, selectDSTokens],
    // Merge the two input streams using an empty object to prevent mutating tokens.submitted
    // Lodash handles null/undefined sources in the merge function so null evaluation isn't needed
    memoize<
        (
            tokens: TokenSlice,
            dsTokens: SmartSourceTokenState
        ) => TokenState & SmartSourceTokenState
    >(
        (tokens, dsTokens) => merge({}, tokens?.submitted, dsTokens),
        (tokens, dsTokens) => JSON.stringify(tokens) + JSON.stringify(dsTokens)
    )
);
export const selectStashedTokens = createSelector(
    [selectTokens],
    (tokens) => tokens?.stashed || noTokens
);

export const selectAreTokensReadyToSubmit = createSelector(
    [selectSubmittedTokens, selectStashedTokens],
    (submitted, stashed) => {
        // global submit button deal with 'default' namespace
        const defaultStashed = stashed[DEFAULT_TOKEN_NAMESPACE] || {};
        const defaultSubmitted = submitted[DEFAULT_TOKEN_NAMESPACE] || {};
        // Tokens aren't ready to submit if
        // 1. no stashed token
        // 2. stashed tokens are the same as submitted tokens, (submit is a noop in this case)
        return !(
            isEmpty(defaultStashed) ||
            contains(defaultSubmitted, defaultStashed)
        );
    }
);

const initialState: TokenSlice = {
    stashed: {},
    submitted: {},
    readOnlyTokenNamespaces: [],
};

export interface unsetTokensPayload {
    tokens: Record<TokenNamespace, TokenName[]>;
    submit?: boolean;
}

export const unsetTokens =
    createAction<unsetTokensPayload>('tokens/unsetToken');

const tokensSlice = createSlice({
    name: 'tokens',
    initialState,
    extraReducers(builder) {
        builder.addCase(resetStore, (state, action): void => {
            const { tokens } = action.payload;
            if (tokens) {
                Object.assign(state, tokens);
            }
        });
    },
    reducers: {
        updateTokens: {
            reducer: (state, { payload }: PayloadAction<UpdatePayload>) => {
                const { tokens, submit } = payload;
                const space = submit ? 'submitted' : 'stashed';

                if (isEqual(state[space], tokens)) {
                    return;
                }

                const readOnlyTokenNamespacesArray =
                    state.readOnlyTokenNamespaces;

                const writableTokens = omit(
                    tokens,
                    readOnlyTokenNamespacesArray
                );
                const currentReadOnlyTokens = pick(
                    state[space],
                    readOnlyTokenNamespacesArray
                );

                state[space] = {
                    // only update tokens that are writable
                    ...currentReadOnlyTokens,
                    ...writableTokens,
                };
            },
            prepare: (tokens: UpdatePayload['tokens'], submit = true) => ({
                payload: {
                    tokens,
                    submit,
                },
            }),
        },
        setToken: {
            reducer: (state, { payload }: PayloadAction<SetPayload>) => {
                const { tokens, namespace, submit } = payload;
                const space = submit ? 'submitted' : 'stashed';
                state[space] ??= {};

                const currentTokens = state[space][namespace];

                if (
                    state.readOnlyTokenNamespaces.includes(namespace) ||
                    isEqual(pick(currentTokens, Object.keys(tokens)), tokens)
                ) {
                    return;
                }

                state[space][namespace] = { ...currentTokens, ...tokens };
            },
            prepare: (
                tokens: SetPayload['tokens'],
                namespace = DEFAULT_TOKEN_NAMESPACE,
                submit = true
            ) => ({
                payload: {
                    tokens,
                    namespace,
                    submit,
                },
            }),
        },
        setTokenIfUnset: {
            reducer: (state, { payload }: PayloadAction<SetPayload>) => {
                const { tokens, namespace, submit } = payload;
                const space = submit ? 'submitted' : 'stashed';
                state[space] ??= {};
                state[space][namespace] ??= {};

                const currentTokens = state[space][namespace];
                if (state.readOnlyTokenNamespaces.includes(namespace)) {
                    return;
                }

                // iterating and setting values individually to avoid creating
                //   a new object and using spread iterator
                Object.entries(tokens).forEach(([tokenName, tokenValue]) => {
                    if (
                        typeof currentTokens[tokenName] === 'undefined' ||
                        currentTokens[tokenName] === null
                    ) {
                        state[space][namespace][tokenName] = tokenValue;
                    }
                });
            },
            prepare: ({
                tokens,
                namespace = DEFAULT_TOKEN_NAMESPACE,
                submit = true,
            }: Partial<SetPayload> & Pick<SetPayload, 'tokens'>) => ({
                payload: {
                    tokens,
                    namespace,
                    submit,
                },
            }),
        },
        replaceReadOnlyTokenNamespaces(
            state,
            { payload }: { payload: SetNamespacesPayload }
        ) {
            const { namespaces } = payload;
            state.readOnlyTokenNamespaces = Array.from(namespaces);
        },
        replaceTokenBinding(
            state,
            { payload }: { payload: ReplaceTokenBindingParams }
        ) {
            const { tokenBinding } = payload;
            state.submitted = tokenBinding;
        },
        unsetToken: {
            reducer: (
                state,
                { payload }: PayloadAction<Partial<UnsetPayload>>
            ) => {
                const { tokens = {}, submit = true } = payload;
                const space = submit ? 'submitted' : 'stashed';
                state[space] ??= {};

                const namespaces = Object.keys(tokens);
                const { readOnlyTokenNamespaces } = state;

                namespaces.forEach((namespace) => {
                    if (readOnlyTokenNamespaces.includes(namespace)) {
                        return;
                    }
                    const currentTokens = state[space][namespace];

                    const tokensToUnset: Record<
                        keyof typeof currentTokens,
                        string | null
                    > = {};
                    tokens[namespace].forEach((tokenName) => {
                        if (!isNullOrUndefined(currentTokens[tokenName])) {
                            tokensToUnset[tokenName] = null;
                        }
                    });

                    if (!isEmpty(tokensToUnset)) {
                        state[space][namespace] = {
                            ...currentTokens,
                            ...tokensToUnset,
                        };
                    }
                });
            },
            prepare: ({
                tokenName,
                namespace = DEFAULT_TOKEN_NAMESPACE,
                submit = true,
            }: {
                tokenName: TokenName | TokenName[];
                namespace?: TokenNamespace;
                submit?: UnsetPayload['submit'];
            }) => {
                const tokenNameArr =
                    typeof tokenName === 'string' ? [tokenName] : tokenName;
                return {
                    payload: {
                        tokens: {
                            [namespace]: tokenNameArr,
                        },
                        submit,
                    },
                };
            },
        },
        submitTokens: {
            reducer: (state, { payload }: PayloadAction<SubmitPayload>) => {
                // move tokens from stashed to submitted
                const { namespace } = payload;
                const toBeSubmitted = state.stashed[namespace] ?? {};

                if (
                    state.readOnlyTokenNamespaces.includes(namespace) ||
                    isEmpty(toBeSubmitted)
                ) {
                    // nothing to submit
                    return;
                }

                state.stashed[namespace] = {};
                state.submitted[namespace] = {
                    ...state.submitted[namespace],
                    ...toBeSubmitted,
                };
            },
            prepare: (namespace = DEFAULT_TOKEN_NAMESPACE) => ({
                payload: {
                    namespace,
                },
            }),
        },
    },
});

export const {
    setToken,
    unsetToken,
    updateTokens,
    submitTokens,
    setTokenIfUnset,
    replaceReadOnlyTokenNamespaces,
    replaceTokenBinding,
} = tokensSlice.actions;

export default tokensSlice.reducer;
