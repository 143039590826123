import * as React from 'react';
import * as T from 'prop-types';
import { ScaleOrdinal } from 'd3-scale';
import Popover from '@splunk/react-ui/Popover';
import styled from 'styled-components';
import { formatNumber } from '@splunk/visualizations-shared/numberUtils';
import { DEFAULT_NODE_COLOR } from './PureSankey';
import { Connections, NodeConnectionsMap, SankeyDataLink, SankeyDataNode } from './SankeyModels';

const DEFAULT_PLACEMENT = 'below';

export interface SankeyTooltipProps {
    mode: 'node' | 'link';
    node?: SankeyDataNode;
    link?: SankeyDataLink;
    linkValue: string;
    open: boolean;
    connectionsMap: NodeConnectionsMap;
    anchor?: any;
    colorCategoryMapping: ScaleOrdinal<string, unknown, string> | string[];
    width: number;
    height: number;
    tooltipRowColor: string;
    tooltipLinkHeaderColor: string;
    tooltipHeaderColor: string;
    handleTooltipRequestClose: (...args: any[]) => void;
}

const StyledTooltipContainer = styled.div`
    display: flex;
    min-width: 200px;
    max-width: 300px;
    padding: 16px;
    flex-direction: column;
`;

const StyledTooltipSection = styled.div`
    display: flex;
    flex-direction: column;
    > div:nth-child(1) {
        margin-top: 8px;
    }
    > div:last-child {
        margin-bottom: 8px;
    }
`;

const StyledTooltipHeader = styled.div<{ headerColor: string }>`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-size: 14px;
    color: ${props => props.headerColor};
`;

const StyledTooltipLinkHeader = styled.div<{ linkHeaderColor: string }>`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-size: 14px;
    margin-bottom: 8px;
    color: ${props => props.linkHeaderColor};
`;

const StyledTooltipRow = styled.div<{ rowColor: string }>`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-size: 12px;
    color: ${props => props.rowColor};
    margin-bottom: 6px;
`;

const StyledTooltipLeftCell = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
`;

const StyledTooltipRightCell = styled.div`
    margin-left: 5px;
`;

const ColorTile = styled.div<{ node: SankeyDataNode; colorCategoryMapping: any }>`
    width: 6px;
    height: 16px;
    background-color: ${props =>
        typeof props.colorCategoryMapping === 'function'
            ? props.colorCategoryMapping(props.node.name)
            : DEFAULT_NODE_COLOR};}
`;

const NodeValue = styled.div`
    margin-left: 8px;
    max-width: 200px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
`;

const SankeyTooltip = (props: SankeyTooltipProps): React.ReactElement => {
    const {
        mode,
        node,
        link,
        open,
        connectionsMap,
        anchor,
        linkValue,
        colorCategoryMapping,
        width,
        tooltipRowColor,
        tooltipLinkHeaderColor,
        tooltipHeaderColor,
        handleTooltipRequestClose,
    } = props;

    const showSection = (
        connection: Connections,
        nodeData: SankeyDataNode,
        section: 'source' | 'target'
    ): boolean => {
        if (!connection) {
            return false;
        }
        const connectionNodes = section === 'source' ? connection.sourceNodes : connection.targetNodes;
        const filteredNodes = connectionNodes.filter(cNode => cNode.index !== nodeData.index);
        return filteredNodes.length > 0;
    };

    const getPopoverPlacement = (anchorElement): 'above' | 'below' | 'left' | 'right' => {
        if (!anchorElement) {
            return DEFAULT_PLACEMENT;
        }
        const bBox = anchorElement.getBoundingClientRect();

        // TODO: Depending on the testing result in dashboard reg tooltip position, might need further optimization
        // If anchorElement is too close to the left border of the chart, place it to the right side of anchorElement
        if (bBox.x <= 15) {
            return 'right';
        }
        if (bBox.x >= width - 15) {
            // If anchorElement is too close to the right border of the chart, place it to the right side of anchorElement
            return 'left';
        }

        return DEFAULT_PLACEMENT;
    };

    const renderNodeToolTip = () => {
        if (!node) {
            return null;
        }
        const connections = connectionsMap[node.index];
        return (
            <Popover
                open={open}
                anchor={anchor}
                onRequestClose={() => handleTooltipRequestClose()}
                defaultPlacement={getPopoverPlacement(anchor)}
            >
                <StyledTooltipContainer className="sankeyToolTip">
                    <StyledTooltipRow rowColor={tooltipRowColor} data-test="main-row">
                        <StyledTooltipLeftCell data-test="main-row-name">
                            <ColorTile node={node} colorCategoryMapping={colorCategoryMapping} />
                            <NodeValue>{node?.name}</NodeValue>
                        </StyledTooltipLeftCell>
                        <StyledTooltipRightCell data-test="main-row-value">
                            {formatNumber(node?.value, 0, { useTrendUnits: true })}
                        </StyledTooltipRightCell>
                    </StyledTooltipRow>
                    {showSection(connections, node, 'source') && (
                        <StyledTooltipHeader headerColor={tooltipHeaderColor}>
                            <StyledTooltipLeftCell>Source</StyledTooltipLeftCell>
                            <StyledTooltipRightCell>{linkValue}</StyledTooltipRightCell>
                        </StyledTooltipHeader>
                    )}
                    <StyledTooltipSection>
                        {connections &&
                            connections.sourceNodes.map((sNode, idx) => {
                                if (
                                    sNode?.index !== node?.index ||
                                    connections.sourceNodes[idx]?.index ===
                                        connections.targetNodes[idx]?.index
                                ) {
                                    return (
                                        <StyledTooltipRow
                                            key={sNode.index}
                                            rowColor={tooltipRowColor}
                                            data-test="source-row"
                                        >
                                            <StyledTooltipLeftCell>
                                                <ColorTile
                                                    node={sNode}
                                                    colorCategoryMapping={colorCategoryMapping}
                                                />
                                                <NodeValue data-test="source-row-name">
                                                    {sNode?.name}
                                                </NodeValue>
                                            </StyledTooltipLeftCell>
                                            <StyledTooltipRightCell data-test="source-row-value">
                                                {formatNumber(sNode?.value, 0, { useTrendUnits: true })}
                                            </StyledTooltipRightCell>
                                        </StyledTooltipRow>
                                    );
                                }
                                return null;
                            })}
                    </StyledTooltipSection>
                    {showSection(connections, node, 'target') && (
                        <StyledTooltipHeader headerColor={tooltipHeaderColor}>
                            <StyledTooltipLeftCell>Target</StyledTooltipLeftCell>
                            <StyledTooltipRightCell>{linkValue}</StyledTooltipRightCell>
                        </StyledTooltipHeader>
                    )}
                    <StyledTooltipSection>
                        {connections &&
                            connections.targetNodes.map((tNode, idx) => {
                                if (
                                    tNode?.index !== node?.index ||
                                    connections.sourceNodes[idx]?.index ===
                                        connections.targetNodes[idx]?.index
                                ) {
                                    return (
                                        <StyledTooltipRow
                                            key={tNode.index}
                                            rowColor={tooltipRowColor}
                                            data-test="target-row"
                                        >
                                            <StyledTooltipLeftCell>
                                                <ColorTile
                                                    node={tNode}
                                                    colorCategoryMapping={colorCategoryMapping}
                                                />
                                                <NodeValue data-test="target-row-name">
                                                    {tNode?.name}
                                                </NodeValue>
                                            </StyledTooltipLeftCell>
                                            <StyledTooltipRightCell data-test="target-row-value">
                                                {formatNumber(tNode?.value, 0, { useTrendUnits: true })}
                                            </StyledTooltipRightCell>
                                        </StyledTooltipRow>
                                    );
                                }
                                return null;
                            })}
                    </StyledTooltipSection>
                </StyledTooltipContainer>
            </Popover>
        );
    };

    const renderLinkToolTip = () => {
        if (!link) {
            return null;
        }
        return (
            <Popover open={open} anchor={anchor} onRequestClose={() => handleTooltipRequestClose()}>
                <StyledTooltipContainer className="sankeyToolTip">
                    <StyledTooltipLinkHeader linkHeaderColor={tooltipLinkHeaderColor} data-test="main-row">
                        <StyledTooltipLeftCell data-test="main-row-name">Link Size</StyledTooltipLeftCell>
                        <StyledTooltipRightCell data-test="main-row-value">
                            {formatNumber(link?.value, 0, { useTrendUnits: true })}
                        </StyledTooltipRightCell>
                    </StyledTooltipLinkHeader>
                    <StyledTooltipHeader headerColor={tooltipHeaderColor}>
                        <StyledTooltipLeftCell>Source</StyledTooltipLeftCell>
                        <StyledTooltipRightCell>{linkValue}</StyledTooltipRightCell>
                    </StyledTooltipHeader>
                    <StyledTooltipSection>
                        <StyledTooltipRow rowColor={tooltipRowColor} data-test="source-row">
                            <StyledTooltipLeftCell>
                                <ColorTile node={link?.source} colorCategoryMapping={colorCategoryMapping} />
                                <NodeValue data-test="source-row-name">{link?.source?.name}</NodeValue>
                            </StyledTooltipLeftCell>
                            <StyledTooltipRightCell data-test="source-row-value">
                                {formatNumber(link?.source?.value, 0, { useTrendUnits: true })}{' '}
                            </StyledTooltipRightCell>
                        </StyledTooltipRow>
                    </StyledTooltipSection>
                    <StyledTooltipHeader headerColor={tooltipHeaderColor}>
                        <StyledTooltipLeftCell>Target</StyledTooltipLeftCell>
                        <StyledTooltipRightCell>{linkValue}</StyledTooltipRightCell>
                    </StyledTooltipHeader>
                    <StyledTooltipSection>
                        <StyledTooltipRow rowColor={tooltipRowColor} data-test="target-row">
                            <StyledTooltipLeftCell>
                                <ColorTile node={link?.target} colorCategoryMapping={colorCategoryMapping} />
                                <NodeValue data-test="target-row-name">{link?.target?.name}</NodeValue>
                            </StyledTooltipLeftCell>
                            <StyledTooltipRightCell data-test="target-row-value">
                                {formatNumber(link?.target?.value, 0, { useTrendUnits: true })}
                            </StyledTooltipRightCell>
                        </StyledTooltipRow>
                    </StyledTooltipSection>
                </StyledTooltipContainer>
            </Popover>
        );
    };

    return mode === 'node' ? renderNodeToolTip() : renderLinkToolTip();
};

SankeyTooltip.propTypes = {
    mode: T.string,
    node: T.object,
    linkValue: T.string,
    open: T.bool,
    connectionsMap: T.object,
    anchor: T.object,
    colorCategoryMapping: T.oneOfType([T.func, T.arrayOf(T.string)]),
    tooltipRowColor: T.string,
    tooltipLinkHeaderColor: T.string,
    tooltipHeaderColor: T.string,
    width: T.number,
    height: T.number,
    handleTooltipRequestClose: T.func,
};

SankeyTooltip.defaultProps = {
    handleTooltipRequestClose: () => {},
};

export default SankeyTooltip;
