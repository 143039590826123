import { SetColorChannel } from './formatters/SetColorChannel';
import { Prefix } from './formatters/Prefix';
import { MatchValue } from './formatters/MatchValue';
import { RangeValue } from './formatters/RangeValue';
import { Gradient } from './formatters/Gradient';
import { Pick } from './formatters/Pick';
import { Type } from './formatters/Type';
import { FormatByType } from './formatters/FormatByType';
import { Frame } from './formatters/Frame';
import { Prepend } from './formatters/Prepend';
import { Objects } from './formatters/Objects';
import { MultiFormat } from './formatters/MultiFormat';
import { MaxContrast } from './formatters/MaxContrast';
import { RenameSeries } from './formatters/RenameSeries';

export const formatterClasses: { [key: string]: any } = {
    gradient: Gradient,
    matchValue: MatchValue,
    prefix: Prefix,
    rangeValue: RangeValue,
    pick: Pick,
    multiFormat: MultiFormat,
    type: Type,
    formatByType: FormatByType,
    frame: Frame,
    prepend: Prepend,
    objects: Objects,
    setColorChannel: SetColorChannel,
    maxContrast: MaxContrast,
    renameSeries: RenameSeries,
};
