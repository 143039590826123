import { cloneDeep } from 'lodash';
import memoizeOne from 'memoize-one';
import type {
    ConnectedLineItem,
    ConnectedPosition,
    AbsoluteBlockItem,
    AbsoluteLayoutStructure,
    Coordinate,
    AbsolutePosition,
    ConnectedLinePosition,
    AbsoluteLayoutItem,
    VizContract,
    VizConfig,
    StructureItemType,
    LayoutItemType,
} from '@splunk/dashboard-types';

export const DEFAULT_SCALE_FACTOR = 3;
export const VIZ_DEFAULT_HEIGHT_PX = 400;
export const DEFAULT_CANVAS_WIDTH = 1200;
export const DEFAULT_CANVAS_HEIGHT = 900;
export const DEFAULT_INPUT_ON_CANVAS_WIDTH = 198;
export const DEFAULT_INPUT_ON_CANVAS_HEIGHT = 82;

/**
 * Given two layout items, check if they collide.
 * @method collides
 * @param {Object} layoutItemA
 * @param {Object} layoutItemB
 * @returns {Boolean} true or false
 */
export const collides = (
    layoutItemA: AbsoluteBlockItem,
    layoutItemB: AbsoluteBlockItem
): boolean => {
    // same element
    if (layoutItemA.item === layoutItemB.item) {
        return false;
    }
    // layoutItemA is left of layoutItemB
    if (
        layoutItemA.position.x + layoutItemA.position.w <=
        layoutItemB.position.x
    ) {
        return false;
    }
    // layoutItemA is right of layoutItemB
    if (
        layoutItemA.position.x >=
        layoutItemB.position.x + layoutItemB.position.w
    ) {
        return false;
    }
    // layoutItemA is above layoutItemB
    if (
        layoutItemA.position.y + layoutItemA.position.h <=
        layoutItemB.position.y
    ) {
        return false;
    }
    // layoutItemA is below layoutItemB
    if (
        layoutItemA.position.y >=
        layoutItemB.position.y + layoutItemB.position.h
    ) {
        return false;
    }
    // boxes overlap
    return true;
};

/**
 * compute end position based on port
 * @method computeLocationPort
 * @param {Object} linePosition to or from
 * @param {Array} layout
 * @returns {Object} converted end position
 */
export const computeLocationPort = (
    endPosition: ConnectedPosition,
    layout: AbsoluteLayoutStructure
): Coordinate | null => {
    const id = endPosition.item;
    const index = layout.map((item) => item.item).indexOf(id);
    let position: AbsolutePosition;
    if (index >= 0) {
        position = cloneDeep((layout[index] as AbsoluteBlockItem).position);
    } else {
        return null;
    }
    switch (endPosition.port) {
        case 'n':
            return { x: position.x + position.w / 2, y: position.y };
        case 'e':
            return {
                x: position.x + position.w,
                y: position.y + position.h / 2,
            };
        case 's':
            return {
                x: position.x + position.w / 2,
                y: position.y + position.h,
            };
        case 'w':
            return { x: position.x, y: position.y + position.h / 2 };
        default:
            return null;
    }
};

export const toCoordinate = (
    itemPosition: Partial<Coordinate> & Partial<ConnectedPosition>,
    layout: AbsoluteLayoutStructure
): Coordinate => {
    const topLeft: Coordinate = { x: 0, y: 0 };

    if (itemPosition.item && itemPosition.port) {
        return (
            computeLocationPort(
                { item: itemPosition.item, port: itemPosition.port },
                layout
            ) ?? topLeft
        );
    }

    if (
        typeof itemPosition.x === 'number' &&
        typeof itemPosition.y === 'number'
    ) {
        return { x: itemPosition.x, y: itemPosition.y };
    }

    return topLeft;
};

/**
 * convert line position to block position
 * @method convertLineToBlockItem
 * @param {Object} itemPosition
 * @param {Array} layout
 * @param {Number} status
 * @returns {Object} converted block position
 */
export const convertLineToBlockItem = (
    itemPosition: ConnectedLinePosition,
    layout: AbsoluteLayoutItem[]
): AbsolutePosition => {
    const from = toCoordinate(itemPosition.from, layout);
    const to = toCoordinate(itemPosition.to, layout);

    return {
        x: Math.min(from.x, to.x),
        y: Math.min(from.y, to.y),
        w: Math.abs(from.x - to.x),
        h: Math.abs(from.y - to.y),
    };
};

/**
 * convert layout item to block item
 * @method convertToBlockItem
 * @param {Object} item
 * @returns {Object} converted block item
 */
export const convertToBlockItem = (
    item: AbsoluteLayoutItem,
    layout: AbsoluteLayoutStructure
): AbsoluteBlockItem => {
    const blockItem: AbsoluteBlockItem = {
        item: item.item,
        type: 'block',
        position: {
            x: 0,
            y: 0,
            w: 0,
            h: 0,
        },
    };
    if (item.type === 'line') {
        blockItem.position = convertLineToBlockItem(item.position, layout);
    } else {
        if (item.type === 'input') {
            // use a special type for inputs in canvas
            blockItem.type = 'input';
        }

        // default item type is block
        blockItem.position = cloneDeep(item.position);
    }
    return blockItem;
};

/**
 * convert layout items to block items
 * @method convertToBlockItems
 * @param {Array} layout
 * @returns {Array} converted block items
 */
export const convertToBlockItems = (
    layout: AbsoluteLayoutStructure
): AbsoluteBlockItem[] => {
    return layout.map((item) => convertToBlockItem(item, layout));
};

/**
 * Get layout items sorted from top left to right and down.
 * @method sortLayoutItems
 * @param {Array} layout
 * @returns {Array} sorted layout
 */
export const sortLayoutItems = (
    layout: AbsoluteBlockItem[]
): AbsoluteBlockItem[] => {
    return cloneDeep(layout).sort((BlockItem1, BlockItem2) => {
        let res = -1;
        if (
            BlockItem1.position.y > BlockItem2.position.y ||
            (BlockItem1.position.y === BlockItem2.position.y &&
                BlockItem1.position.x > BlockItem2.position.x)
        ) {
            res = 1;
        } else if (
            BlockItem1.position.y === BlockItem2.position.y &&
            BlockItem1.position.x === BlockItem2.position.x
        ) {
            // Without this, we can get different sort results in IE vs. Chrome/FF
            res = 0;
        }
        return res;
    });
};

/**
 * Returns the first item this layout collides with.
 * @method getFirstCollision
 * @param {Array} layout
 * @param {Object} layoutItem
 * @returns {Object} first collision or undefined
 */
export const getFirstCollision = (
    layout: AbsoluteBlockItem[],
    layoutItem: AbsoluteBlockItem
): AbsoluteBlockItem | undefined => {
    return layout.find((item) => collides(item, layoutItem));
};

interface GetPositionByTypeProps {
    col: number;
    row: number;
    width: number;
    height: number;
    type: 'line' | 'block' | string;
}

/**
 * get position based on type
 * @method getPositionByType
 * @param {Object} config
 * @param {Number} config.col
 * @param {Number} config.row
 * @param {Number} config.width
 * @param {Number} config.height
 * @param {String} config.type
 * @returns {Object} new position
 */
export const getPositionByType = ({
    col,
    row,
    width,
    height,
    type,
}: GetPositionByTypeProps): AbsolutePosition | ConnectedLinePosition => {
    if (type === 'line') {
        return {
            from: {
                x: col,
                y: row + height,
            },
            to: {
                x: col + width,
                y: row + height / 2,
            },
        };
    }

    return {
        x: col,
        y: row,
        w: width,
        h: height,
    };
};

interface GetDefaultPositionProps {
    width: number;
    height: number;
    canvasHeight: number;
    canvasWidth: number;
    type: StructureItemType;
}

/**
 * get the default position based on type (Note: does not support legacy grid)
 * @method getDefaultPosition
 * @param {Object} config
 * @param {Number} config.canvasWidth
 * @param {Number} config.canvasHeight
 * @param {Number} config.width
 * @param {Number} config.height
 * @param {String} config.type
 * @returns {Object} default position
 */
export const getDefaultPosition = <T extends GetDefaultPositionProps>({
    canvasWidth,
    canvasHeight,
    width,
    height,
    type,
}: T): AbsolutePosition | ConnectedLinePosition => {
    if (type === 'line') {
        return {
            from: {
                x: Math.round(canvasWidth / DEFAULT_SCALE_FACTOR),
                y: Math.round(canvasHeight / DEFAULT_SCALE_FACTOR),
            },
            to: {
                x: Math.round(canvasWidth / DEFAULT_SCALE_FACTOR + width),
                y: Math.round(canvasHeight / DEFAULT_SCALE_FACTOR + height),
            },
        };
    }

    return {
        x: Math.round(canvasWidth / DEFAULT_SCALE_FACTOR),
        y: Math.round(canvasHeight / DEFAULT_SCALE_FACTOR),
        w: width,
        h: height,
    };
};

interface ComputeNextAvailablePositionProps {
    width: number;
    height: number;
    canvasHeight: number;
    canvasWidth: number;
    items: AbsoluteLayoutStructure;
    type: StructureItemType;
}

/**
 * compute next available position for new added item (Note: does not support legacy grid)
 * @method computeNextAvailablePosition
 * @param {Object} config
 * @param {Number} config.width
 * @param {Number} config.height
 * @param {Number} config.canvasHeight
 * @param {Number} config.canvasWidth
 * @param {Number} config.items
 * @param {Number} config.type absolute/line
 * @returns {Object} position
 */
export const computeNextAvailablePosition = <
    T extends ComputeNextAvailablePositionProps
>({
    width,
    height,
    canvasHeight,
    canvasWidth,
    items,
    type,
}: T): AbsolutePosition | ConnectedLinePosition => {
    const rectA: AbsoluteBlockItem = {
        item: '__canvas__',
        position: {
            x: 0,
            y: 0,
            w: width,
            h: height,
        },
    };
    let minHeight;
    const layout = convertToBlockItems(items);
    const sorted = sortLayoutItems(layout);
    // iterate all possible positions from top left to bottom right
    // for each position, we first try to find collision.
    // for each row, we record the minHeight for the collisions in this row.
    // if we can find a collision,  update col to the right boundary of current collision and update minHeight.
    // After each row, we update row to minHeight.
    // In this way, we can skip a lot of meaningless positions.
    // if find a good position, return this position
    // else return default position
    // Time complexity: O(K*K)   K is the number of layout items.
    let row = 0;
    let col;
    for (; row < canvasHeight; row += 1) {
        minHeight = canvasHeight - 1;
        col = 0;
        for (; col < canvasWidth; col += 1) {
            rectA.position.x = col;
            rectA.position.y = row;
            const rightBoundary = rectA.position.x + rectA.position.w - 1;
            const bottomBoundary = rectA.position.y + rectA.position.h - 1;
            // possible position must within canvas on horizontal and vertical side
            if (
                !(rightBoundary > canvasWidth || bottomBoundary > canvasHeight)
            ) {
                const collision = getFirstCollision(sorted, rectA);
                if (collision) {
                    minHeight = Math.min(
                        minHeight,
                        collision.position.y + collision.position.h - 1
                    );
                    // update col
                    col = collision.position.x + collision.position.w - 1; // NOSONAR
                } else {
                    return getPositionByType({ col, row, width, height, type });
                }
            } else {
                break;
            }
        }
        // update row
        row = minHeight; // NOSONAR
    }
    return getDefaultPosition({
        canvasWidth,
        canvasHeight,
        width,
        height,
        type,
    });
};

interface ComputeNewBlockItemPositionProps {
    canvasWidth: number;
    canvasHeight: number;
    vizContract?: VizContract;
    config?: VizConfig;
    items: AbsoluteLayoutStructure;
}

/**
 * Given the current absolute layout items, return position that new visualization should locate.
 * @method computeNewBlockItemPosition
 * @param {Object} options
 * @param {Number} options.canvasWidth
 * @param {Number} options.canvasHeight
 * @param {Object} options.vizContract
 * @param {Array} options.items
 * @param {Object} options.config
 * @returns {Object} nextPosition
 */
export const computeNewBlockItemPosition = ({
    canvasWidth,
    canvasHeight,
    vizContract,
    config,
    items,
}: ComputeNewBlockItemPositionProps): AbsolutePosition => {
    const width =
        vizContract?.initialDimension?.width ??
        config?.size?.initialWidth ??
        Math.round(canvasWidth / 3);
    const height =
        vizContract?.initialDimension?.height ??
        config?.size?.initialHeight ??
        Math.round(canvasHeight / 3);
    return computeNextAvailablePosition({
        width,
        height,
        canvasHeight,
        canvasWidth,
        items,
        type: 'block',
    }) as AbsolutePosition;
};

interface ComputeNewLinePositionProps {
    canvasWidth: number;
    canvasHeight: number;
    items: AbsoluteLayoutStructure;
}

/**
 * Given the current absolute layout items, return point position
 * @method computeNewLinePosition
 * @params {Object} config
 * @param {Number} config.canvasWidth
 * @param {Number} config.canvasHeight
 * @param {Array} config.items
 * @returns {*} position
 */
export const computeNewLinePosition = ({
    canvasWidth,
    canvasHeight,
    items,
}: ComputeNewLinePositionProps): ConnectedLinePosition => {
    // default width and height for line
    const width = 150;
    const height = 20;
    return computeNextAvailablePosition({
        width,
        height,
        canvasHeight,
        canvasWidth,
        items,
        type: 'line',
    }) as ConnectedLinePosition;
};

/**
 * Given the current absolute layout items, return point position
 * @method computeNewInputPosition
 * @params {Object} config
 * @param {Number} config.canvasWidth
 * @param {Number} config.canvasHeight
 * @param {Array} config.items
 * @returns {*} position
 */
export const computeNewInputPosition = ({
    canvasWidth,
    canvasHeight,
    items,
}: ComputeNewLinePositionProps): AbsolutePosition => {
    // default width and height for input
    const width = DEFAULT_INPUT_ON_CANVAS_WIDTH;
    const height = DEFAULT_INPUT_ON_CANVAS_HEIGHT;
    return computeNextAvailablePosition({
        width,
        height,
        canvasHeight,
        canvasWidth,
        items,
        type: 'input',
    }) as AbsolutePosition;
};

/**
 * compute the max height based on current layout items and minimum height
 * @method computeMaxHeight
 * @param {Array} layoutItems
 * @param {Number} minHeight
 * @returns {Number} max height
 */
export const computeMaxHeight = (
    layoutItems: AbsoluteBlockItem[],
    minHeight = 0
): number => {
    return layoutItems.length > 0
        ? Math.max(
              ...layoutItems.map((item) => item.position.y + item.position.h),
              1
          )
        : minHeight;
};

interface ComputeNewAbsoluteStructureItemProps {
    itemId: string;
    type?: StructureItemType;
    canvasWidth: number;
    canvasHeight: number;
    vizContract?: VizContract;
    config?: VizConfig;
    layoutItems: AbsoluteLayoutStructure;
}

/**
 * Computes a new layout item position for the absolute layout
 * @param {Object} options
 * @param {String} options.itemId The identifier for a visualization
 * @param {String} [options.type="block"] The structure type for a viz: must be "line" or "block"
 * @param {Number} options.canvasWidth The width of the canvas
 * @param {Number} options.canvasHeight The height of the canvas
 * @param {Object} options.vizContract Metadata to define the default height/width of a new viz
 * @param {Object} options.config
 * @param {Array} layoutItems List of items in the layout structure
 * @returns {LayoutItem}
 */
export const computeNewAbsoluteStructureItem = <
    T extends ComputeNewAbsoluteStructureItemProps
>({
    itemId,
    type = 'block',
    canvasWidth,
    canvasHeight,
    vizContract,
    layoutItems,
    config,
}: T): AbsoluteBlockItem | ConnectedLineItem => {
    if (type === 'line') {
        return {
            item: itemId,
            type: 'line',
            position: computeNewLinePosition({
                canvasWidth,
                canvasHeight,
                items: layoutItems,
            }),
        };
    }

    if (type === 'input') {
        return {
            item: itemId,
            type: 'input',
            position: computeNewInputPosition({
                canvasWidth,
                canvasHeight,
                items: layoutItems,
            }),
        };
    }

    return {
        item: itemId,
        type,
        position: computeNewBlockItemPosition({
            canvasWidth,
            canvasHeight,
            vizContract,
            config,
            items: layoutItems,
        }),
    };
};

interface ComputeNewGridStructureItemProps {
    itemId: string;
    layoutItems: AbsoluteBlockItem[];
    canvasWidth: number;
    type?: LayoutItemType;
}

/**
 * Computes a new layout item position for the grid layout
 * @param {Object} config
 * @param {String} config.itemId The identifier for a visualization
 * @param {Number} config.canvasWidth The width of the canvas
 * @param {Array} layoutItems List of items in the layout structure
 * @returns {LayoutItem}
 */
export const computeNewGridStructureItem = ({
    itemId,
    layoutItems,
    canvasWidth,
    type = 'block',
}: ComputeNewGridStructureItemProps): AbsoluteBlockItem => ({
    item: itemId,
    type,
    position: {
        x: 0,
        y: computeMaxHeight(layoutItems),
        w: canvasWidth,
        h: VIZ_DEFAULT_HEIGHT_PX,
    },
});

/**
 * Get the width of a default scrollbar. Obtrusive scrollbars have a non-zero width.
 * @returns {Number}
 */
export const getScrollbarWidth = memoizeOne((): number => {
    const scrollDiv = document.createElement('div');
    scrollDiv.setAttribute(
        'style',
        'width:100px;height:100px;overflow:scroll;position:absolute;top:-9999px;'
    );
    document.body.appendChild(scrollDiv);

    // get the scrollbar width
    const scrollbarWidth = scrollDiv.offsetWidth - scrollDiv.clientWidth;

    // delete the div
    document.body.removeChild(scrollDiv);

    // return the scrollbar width
    return scrollbarWidth;
});

/**
 * Determines if a variable corresponds to an absolute block item
 * @param {unknown} item The value which may or may not be an absolute block item
 * @returns {Boolean} `true` if the item can be of type `AbsoluteBlockItem`, else `false`
 * @see {@link AbsoluteBlockItem}
 */
export const isBlockItem = (item: unknown): item is AbsoluteBlockItem =>
    typeof item === 'object' &&
    item !== null &&
    (typeof (item as AbsoluteBlockItem).type === 'undefined' ||
        (item as AbsoluteBlockItem).type === 'block' ||
        (item as AbsoluteBlockItem).type === 'input');
