import * as React from 'react';
import { omit, noop } from 'lodash';
import { useRef, useEffect, useCallback, useContext, useMemo } from 'react';
import * as T from 'prop-types';
import * as chroma from 'chroma-js';
import { Map, NavigationControl, ScaleControl } from 'maplibre-gl';
import type { StyleSpecification } from 'maplibre-gl';
import MapContext from '@splunk/visualization-context/MapContext';
import type { MapContextInterface } from '@splunk/visualization-context/MapContext';
import { VizProps } from '../../common/interfaces/VizProps';
import PureMapContainer from './PureMapContainer';
import {
    getTilePreset,
    getMapStyle,
    getZoomConstraintsFromTileConfig,
    MAP_STYLE_VERSION_NUMBER,
    ClusterLayerType,
    LayerType,
    MAX_BUBBLE_RADIUS,
    isValidLatLon,
    MAX_LATITUDE,
    getGlobalLayerBoundaries,
    LAYER_TYPE,
    compareBaseStyles,
} from './MapUtils';
import ClusterLayer from './ClusterLayer';
import ChoroplethLayer from './ChoroplethLayer';
import LocalChoroplethLayer from './LocalChoroplethLayer';

export const DEFAULT_CENTER: [number, number] = [0, 0];
export const DEFAULT_CONTROL_BACKGROUND_COLOR = '#2b3033';
export const DEFAULT_CONTROL_FOREGROUND_COLOR = '#B5B5B5';
export const DEFAULT_SCALE_UNIT = 'imperial';
export const DEFAULT_SHOW_SCALE = true;
export const DEFAULT_SHOW_ZOOM_CONTROLS = true;
export const DEFAULT_SHOW_BASELAYER = true;
export const DEFAULT_ZOOM = 0;
interface PureMapProps extends VizProps {
    backgroundColor: string;
    baseLayerTileServer?: string;
    baseLayerTileServerType?: 'vector' | 'raster';
    center?: [number, number];
    controlBackgroundColor?: string;
    controlForegroundColor?: string;
    layers: LayerType[];
    scaleUnit?: 'imperial' | 'metric';
    showScale?: boolean;
    showZoomControls?: boolean;
    showBaseLayer?: boolean;
    themeColorScheme?: string;
    zoom?: number;
    onMapLoad?: () => void;
    onMapClick?: ({ e, payload }) => void;
    onOptionsChange?: (options) => void;
}

export interface HexColorObj {
    color: string;
    opacity: number;
}

// convert rgb/rgba/hex color to encoded color & opacity for SVG strings
export const formatColor = (color: string): HexColorObj => {
    const chromaColor = chroma(color);
    return {
        color: chroma(chromaColor.rgb()).hex().replace('#', '%23'),
        opacity: chromaColor.alpha(),
    };
};

PureMapContainer.displayName = 'PureMapContainer';

const PureMap = (props: PureMapProps): any => {
    const {
        width,
        height,
        backgroundColor,
        baseLayerTileServer,
        baseLayerTileServerType,
        center,
        controlBackgroundColor,
        controlForegroundColor,
        layers = [],
        scaleUnit,
        showScale,
        showZoomControls,
        showBaseLayer,
        themeColorScheme,
        zoom,
        onMapLoad,
        onMapClick,
        onOptionsChange,
        mode,
    } = props;
    const { defaultTileConfig, customTileConfig } = useContext<MapContextInterface>(MapContext);
    const basemapContainer = useRef<HTMLDivElement>(null);
    const map = useRef<Map>(null);
    const scaleControl = useRef<ScaleControl>(null);
    const zoomControl = useRef<NavigationControl>(null);

    const { tilePreset, defaultTileLight, defaultTileDark } = useMemo(
        () => getTilePreset({ defaultTileConfig, customTileConfig }),
        [defaultTileConfig, customTileConfig]
    );

    const [currentZoomLevel, setCurrentZoomLevel] = React.useState<number>(zoom ?? DEFAULT_ZOOM);
    const [isMapLoaded, setMapIsLoaded] = React.useState<boolean>(false);
    const [isThemeChanged, setIsThemeChanged] = React.useState<boolean>(false);

    // Assign default center when no center is defined
    const lngLatCenter = useMemo(() => {
        if (!isValidLatLon(center)) {
            return DEFAULT_CENTER as [number, number];
        }
        return [center[1], center[0]] as [number, number];
    }, [center]);

    // TODO: take out zoomLevel to use zoom instead (set prop zoom = DEFAULT_ZOOM)
    // Assign default zoom when no zoom is defined
    const zoomLevel = useMemo(() => {
        if (zoom === undefined) {
            return DEFAULT_ZOOM;
        }
        return zoom;
    }, [zoom]);

    const setIsThemeChangedCallback = (isMyThemeChanged: boolean) => {
        setIsThemeChanged(isMyThemeChanged);
    };

    const onOptionsChangeProxy = e => {
        if (mode === 'edit') {
            onOptionsChange(e);
        }
    };

    const initializeMap = useCallback(
        mapStyle => {
            const fitMapToContent = () => {
                const lngLatBounds = getGlobalLayerBoundaries([layers[0]]);
                map.current.fitBounds(lngLatBounds, {
                    padding: MAX_BUBBLE_RADIUS + 5,
                    animate: false,
                });
            };

            const addZoomEndEventListener = () => {
                // register event listener - zoom
                map.current.on('zoomend', () => {
                    setCurrentZoomLevel(map.current.getZoom());
                    // when zooming with mouse/track pad,
                    // zooming centers at the cursor position, need to update center as well
                    const newCenter = map.current.getCenter();
                    onOptionsChangeProxy({
                        center: [newCenter.lat, newCenter.lng],
                        zoom: map.current.getZoom(),
                    });
                });
            };

            map.current = new Map({
                container: basemapContainer.current,
                preserveDrawingBuffer: true,
                style: mapStyle,
                center: lngLatCenter,
                zoom: zoomLevel,
                ...getZoomConstraintsFromTileConfig(mapStyle, baseLayerTileServer),
            });
            // Fired immediately after all necessary resources have been downloaded
            // and the first visually complete rendering of the map has occurred.
            map.current.on('load', () => {
                if (layers?.length > 0 && center === undefined && zoom === undefined) {
                    map.current.on('zoomend', () => {
                        addZoomEndEventListener();
                    });
                    fitMapToContent();
                } else {
                    addZoomEndEventListener();
                }
                setMapIsLoaded(true);
                // TODO :- Temp timeout added for onMapLoad event to avoid flaky screenshots caused by
                // https://maplibre.org/maplibre-gl-js-docs/api/map/#map#fitbounds. Need alternate approach to handle the same.
                setTimeout(() => onMapLoad(), 500);
            });

            // register event listener - drag
            map.current.on('dragend', () => {
                const newCenter = map.current.getCenter();
                onOptionsChangeProxy({ center: [newCenter.lat, newCenter.lng] });
            });

            // add zoom control
            zoomControl.current = new NavigationControl({ showCompass: false });
            map.current.addControl(zoomControl.current, 'top-left');
        },
        [lngLatCenter, zoomLevel, layers?.length]
    );

    const initializeScale = useCallback(() => {
        scaleControl.current = new ScaleControl({
            maxWidth: 70,
            unit: scaleUnit,
        });
        map.current.addControl(scaleControl.current);
    }, [scaleUnit]);

    const updateScale = useCallback(() => {
        if (showScale) {
            scaleControl?.current?.setUnit(scaleUnit);
        }
    }, [scaleUnit, showScale]);

    useEffect(() => {
        if (map.current) {
            map.current.resize();
        }
    }, [width, height]);

    useEffect(() => {
        // do not render when defaultTileConfig, customTileConfig and tileset are not configured
        if (Object.keys(tilePreset).length === 0 && (!baseLayerTileServer || !baseLayerTileServerType)) {
            // eslint-disable-next-line no-console
            console.warn(
                'Map Tile Server configuration is missing or invalid. Please provide a valid configuration'
            );
            return;
        }

        const mapStyle = getMapStyle({
            showBaseLayer,
            baseLayerTileServer,
            baseLayerTileServerType,
            tilePreset,
            colorScheme: themeColorScheme,
            defaultTileLight,
            defaultTileDark,
        });

        mapStyle.version = MAP_STYLE_VERSION_NUMBER;

        // If the map has already been initialized, we update the styles
        if (map.current) {
            const curr = map.current.getStyle();
            const next = omit(mapStyle, ['theme', 'name', 'key']);

            // In dashboard studio this setStyle function is triggered by zoom changes as well
            // Since setStyle will reset all style layers on the map including choropleth,
            // we ONLY want to this to be called when there are style changes
            if (!compareBaseStyles(curr, next)) {
                // Refer to https://maplibre.org/maplibre-gl-js-docs/api/map/#setstyle-example for options.diff (default is true)
                map.current.setStyle(omit(mapStyle, ['theme', 'name', 'key']) as StyleSpecification);
                setIsThemeChanged(true);
            }
            const { minZoom, maxZoom } = getZoomConstraintsFromTileConfig(mapStyle, baseLayerTileServer);
            map.current.setMinZoom(minZoom);
            map.current.setMaxZoom(maxZoom);
            updateScale();
        } else {
            // initialize the map only once with a constructed style from user configured options
            // or default configs from the map context
            initializeMap(omit(mapStyle, ['theme', 'name', 'key']) as StyleSpecification);
            initializeScale();
        }
    }, [
        showBaseLayer,
        tilePreset,
        initializeMap,
        initializeScale,
        updateScale,
        defaultTileLight,
        defaultTileDark,
        baseLayerTileServer,
        baseLayerTileServerType,
        themeColorScheme,
    ]);

    useEffect(() => {
        if (map.current) {
            map.current.flyTo({
                center: lngLatCenter,
                zoom: zoomLevel,
            });
        }
    }, [lngLatCenter, zoomLevel]);

    useEffect(
        () => () => {
            if (map.current) {
                map.current.remove();
            }
        },
        []
    );

    const [layer] = layers;

    return (
        <>
            <PureMapContainer
                className="puremap"
                width={width}
                height={height}
                backgroundColor={backgroundColor}
                controlBackgroundColor={controlBackgroundColor}
                controlForegroundColor={controlForegroundColor}
                controlIconColorEncoded={formatColor(controlForegroundColor)}
                ref={basemapContainer}
                showScale={showScale}
                showZoomControls={showZoomControls}
            >
                {isMapLoaded &&
                    layer &&
                    (layer.type === LAYER_TYPE.Bubble || layer.type === LAYER_TYPE.Marker) && (
                        <ClusterLayer
                            clusterLayer={layer as ClusterLayerType}
                            themeColorScheme={themeColorScheme}
                            map={map}
                            zoom={currentZoomLevel}
                            onMapClick={onMapClick}
                        />
                    )}
                {isMapLoaded && layer && layer.type === LAYER_TYPE.Choropleth && layer.source && (
                    <LocalChoroplethLayer
                        width={width}
                        height={height}
                        map={map}
                        choroplethLayer={layer}
                        isThemeChanged={isThemeChanged}
                        setIsThemeChanged={setIsThemeChangedCallback}
                        onMapClick={onMapClick}
                    />
                )}
                {isMapLoaded && layer && layer.type === LAYER_TYPE.Choropleth && !layer.source && (
                    <ChoroplethLayer
                        map={map}
                        choroplethLayer={layer}
                        isThemeChanged={isThemeChanged}
                        setIsThemeChanged={setIsThemeChangedCallback}
                        onMapClick={onMapClick}
                    />
                )}
            </PureMapContainer>
        </>
    );
};

export const isLatLng = (props: PureMapProps, propName: string, componentName: string): Error => {
    if (!props[propName]) {
        return null;
    }
    const latAbs = Math.abs(props[propName][0]);
    const lonAbs = Math.abs(props[propName][1]);
    const latlon = props[propName];
    if (latAbs > MAX_LATITUDE && lonAbs <= MAX_LATITUDE) {
        throw new Error(`${componentName} ${propName} may have reversed [lat, lon] ranges.`);
    }
    if (!isValidLatLon(latlon)) {
        throw new Error(`${componentName} ${propName} incorrect [lat, lon] ranges.`);
    }
    return null;
};

PureMap.propTypes = {
    // eslint-disable-next-line react/no-unused-prop-types
    mode: T.string,
    width: T.oneOfType([T.string, T.number]),
    height: T.oneOfType([T.string, T.number]),
    backgroundColor: T.string,
    baseLayerTileServer: T.string,
    baseLayerTileServerType: T.string,
    center: isLatLng,
    controlBackgroundColor: T.string,
    controlForegroundColor: T.string,
    layers: T.arrayOf(
        T.shape({
            type: T.string,
            latitude: T.arrayOf(T.oneOfType([T.string, T.number])),
            longitude: T.arrayOf(T.oneOfType([T.string, T.number])),
            label: T.string,
            showInLegend: T.bool,
            dataColors: T.oneOfType([T.string, T.arrayOf(T.string)]),
            seriesColors: T.arrayOf(T.string),
            additionalTooltipFields: T.arrayOf(T.string),
            resultLimit: T.number,
            source: T.string,
            choroplethEmptyAreaColor: T.string,
        })
    ),
    scaleUnit: T.string,
    showScale: T.bool,
    themeColorScheme: T.string,
    zoom: T.number,
    onMapLoad: T.func,
    onMapClick: T.func,
    onOptionsChange: T.func,
};

PureMap.defaultProps = {
    mode: 'view',
    width: 800,
    height: 500,
    backgroundColor: '#0b0c0e',
    controlBackgroundColor: DEFAULT_CONTROL_BACKGROUND_COLOR,
    controlForegroundColor: DEFAULT_CONTROL_FOREGROUND_COLOR,
    scaleUnit: DEFAULT_SCALE_UNIT,
    showScale: DEFAULT_SHOW_SCALE,
    showZoomControls: DEFAULT_SHOW_ZOOM_CONTROLS,
    showBaseLayer: DEFAULT_SHOW_BASELAYER,
    layers: [],
    themeColorScheme: 'dark',
    onMapLoad: noop,
    onMapClick: noop,
    onOptionsChange: noop,
};

export default PureMap;
