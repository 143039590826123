import React, { useMemo, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import { FullScreen, useFullScreenHandle } from 'react-full-screen';
import type { VisualizationDefinition } from '@splunk/dashboard-types';
import { toPx } from '@splunk/dashboard-utils';
import { setFullscreenItem, useDispatch } from '@splunk/dashboard-state';
import { useApiRegistry } from '@splunk/dashboard-context';
import VisualizationContent from './VisualizationContent';
import type { ItemContainerProps } from '../types/ItemContainer';

export interface VisualizationContainerProps extends ItemContainerProps {
    itemDefinition: VisualizationDefinition;
    isFullscreen: boolean;
    onSelected: (
        e: React.MouseEvent,
        visualizationIds: { id: string; type: string }[]
    ) => void;
}

interface StyledFullScreenProps {
    width: string | number;
    height?: string | number;
}
const StyledFullScreen = styled(FullScreen)<StyledFullScreenProps>`
    height: ${(props) => toPx(props.height)};
    width: ${(props) => toPx(props.width)};
`;

/**
 * Visualization wrapper that wires up events and data for viz content.
 */

const VisualizationContainer = ({
    id,
    mode,
    itemDefinition,
    height,
    width,
    loading,
    refresh,
    dataSources,
    updateRequestParams,
    onSelected,
    isFullscreen,
}: VisualizationContainerProps): JSX.Element => {
    const dispatch = useDispatch();

    const onExitFullscreen = useCallback(
        () => dispatch(setFullscreenItem(null)),
        [dispatch]
    );

    const apiRegistry = useApiRegistry();
    const fullscreenHandle = useFullScreenHandle();
    const { enter: enterFullscreen, node: vizRef } = fullscreenHandle;

    const vizApi = useMemo(
        () => ({
            getDomElement: () => vizRef.current,
        }),
        [vizRef]
    );

    useEffect(() => {
        if (!apiRegistry.getVisualizationApi(id)) {
            apiRegistry.registerVisualizationApi(id, vizApi);
        }
        return () => {
            apiRegistry.removeVisualizationApi(id);
        };
    }, [apiRegistry, id, vizApi]);

    // Register refresh to api on mount, unregister on unmount
    useEffect(() => {
        apiRegistry.registerVisualizationActionsApi(id, { refresh });

        return () => {
            apiRegistry.removeVisualizationActionsApi(id);
        };
    }, [apiRegistry, id, refresh]);

    const registerApi = useCallback(
        (ref) => {
            apiRegistry.registerVisualizationApi(id, { ...ref, ...vizApi });
        },
        [apiRegistry, id, vizApi]
    );

    const handleToggleFullscreen = useCallback(
        (isFull) => {
            if (isFullscreen && !isFull) {
                onExitFullscreen();
            }
        },
        [isFullscreen, onExitFullscreen]
    );

    // enter fullscreen if initial render includes a fullscreen prop
    useEffect(() => {
        if (isFullscreen) {
            enterFullscreen();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return useMemo(
        () => (
            <StyledFullScreen
                handle={fullscreenHandle}
                onChange={handleToggleFullscreen}
                width={width}
                height={height}
                className="styled-fullscreen"
            >
                <VisualizationContent
                    id={id}
                    mode={mode}
                    visualizationDefinition={itemDefinition}
                    height={height}
                    width={width}
                    isFullscreen={isFullscreen}
                    loading={loading}
                    updateRequestParams={updateRequestParams}
                    dataSources={dataSources}
                    registerApi={registerApi}
                    onSelected={onSelected}
                />
            </StyledFullScreen>
        ),
        [
            id,
            mode,
            fullscreenHandle,
            handleToggleFullscreen,
            itemDefinition,
            width,
            height,
            loading,
            updateRequestParams,
            dataSources,
            isFullscreen,
            registerApi,
            onSelected,
        ]
    );
};

export default VisualizationContainer;
