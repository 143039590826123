import * as React from 'react';
import * as T from 'prop-types';
import styled from 'styled-components';
import { pick } from 'lodash';
import { _ } from '@splunk/ui-utils/i18n';
import { toDimension } from '@splunk/visualizations-shared/style';
import SizeAwareWrapper from '@splunk/visualizations-shared/SizeAwareWrapper';
import Message from '@splunk/visualizations-shared/Message';
import {
    GAUGE_THICKNESS,
    assignGaugeColor,
    getGaugeDimensions,
    getFillBarDimensions,
    getFillerGaugeValueMarkerPositions,
} from '../../common/gaugeUtils';
import MajorTicks from '../../common/GaugeMajorTicks';
import ValueMarker from '../../common/GaugeValueMarker';

const Container = styled.div<{
    width: string | number;
    height: string | number;
    backgroundColor: string;
}>`
    overflow: hidden;
    ${props => toDimension(pick(props, ['width', 'height']))};
    background-color: ${props => props.backgroundColor};
`;

const FillerGaugeWrapper = styled.div<{
    backgroundColor: string;
}>`
    display: flex;
    justify-content: center;
    flex-direction: row;
    width: 100%;
    height: 100%;
    background-color: ${props => props.backgroundColor};
`;

const FillerGaugeContainer = styled.div<{
    width: string | number;
    height: string | number;
}>`
    overflow: hidden;
    position: relative;
    ${props => toDimension(pick(props, ['width', 'height']))};
`;

const FillerGaugeSVG = styled.svg<{
    width: string | number;
    height: string | number;
}>`
    ${props => toDimension(pick(props, ['width', 'height']))};
`;

const BackgroundBar = styled.rect.attrs({ 'data-test': 'background-bar-rect' })<{
    backgroundBarFillColor: string;
}>`
    fill: ${props => props.backgroundBarFillColor};
`;

const FillBar = styled.rect.attrs({ 'data-test': 'fill-bar-rect' })`
    fill: ${props => props.color};
`;

const MAX_VERTICAL_CONTAINER_WIDTH = 200;
const MIN_VERTICAL_CONTAINER_HEIGHT = 200;
const MIN_HORIZONTAL_CONTAINER_WIDTH = 200;
const MIN_HORIZONTAL_CONTAINER_HEIGHT = 100;

export enum AXIS_ORIENTATION_TYPES {
    VERTICAL = 'vertical',
    HORIZONTAL = 'horizontal',
}

export interface FillerGaugeProps {
    width: string | number;
    height: string | number;
    value: number;
    gaugeColor: string;
    min: number;
    max: number;
    // valueFieldName: T.string, // todo: need add it back when displaying name later
    // gaugeColorFieldName: T.string, // todo: may need add it back may not
    orientation: AXIS_ORIENTATION_TYPES;
    majorUnit: string | number;
    showLabels: boolean;
    showValue: boolean;
    usePercentageRange: boolean;
    usePercentageValue: boolean;
    backgroundColor: string;
    backgroundBarFillColor: string;
    majorTickFillColor: string;
    majorTickStrokeColor: string;
    valueMarkerFillColor: string;
    valueMarkerLabelFillColor: string;
}

const FillerGauge = ({ ...props }: FillerGaugeProps): React.ReactElement => {
    const {
        width,
        height,
        value,
        gaugeColor,
        min,
        max,
        orientation,
        majorUnit,
        showLabels,
        showValue,
        usePercentageRange,
        usePercentageValue,
        backgroundColor,
        backgroundBarFillColor,
        majorTickFillColor,
        majorTickStrokeColor,
        valueMarkerFillColor,
        valueMarkerLabelFillColor,
    } = props;

    const renderVisualization = ({ width: containerWidth, height: containerHeight }) => {
        // todo: need change the size when get design for smaller space
        // 58 is the title & description header height in dashboard visualization
        if (
            ((containerWidth < MAX_VERTICAL_CONTAINER_WIDTH ||
                containerHeight + 58 < MIN_VERTICAL_CONTAINER_HEIGHT) &&
                orientation === 'vertical') ||
            ((containerWidth < MIN_HORIZONTAL_CONTAINER_WIDTH ||
                containerHeight < MIN_HORIZONTAL_CONTAINER_HEIGHT) &&
                orientation === 'horizontal')
        ) {
            return (
                <Message
                    width={containerWidth}
                    height={containerHeight}
                    message={_('Too small to render content')}
                    level="info"
                />
            );
        }

        const { gaugeLength, gaugeStartX, gaugeStartY, gaugeWidth, gaugeHeight } = getGaugeDimensions({
            containerWidth,
            containerHeight,
            orientation,
        });
        const { fillBarLength, fillBarX, fillBarY, fillBarWidth, fillBarHeight } = getFillBarDimensions({
            gaugeLength,
            gaugeStartX,
            gaugeStartY,
            gaugeWidth,
            gaugeHeight,
            value,
            min,
            max,
            orientation,
        });
        const { valueMarkerX, valueMarkerY } = getFillerGaugeValueMarkerPositions({
            fillBarX,
            fillBarY,
            fillBarLength,
            orientation,
        });

        return (
            <FillerGaugeWrapper backgroundColor={backgroundColor} data-test="FillerGaugeWrapper">
                <FillerGaugeContainer
                    width={containerWidth}
                    height={containerHeight}
                    data-test="FillerGaugeContainer"
                >
                    <FillerGaugeSVG
                        width={containerWidth}
                        height={containerHeight}
                        data-test="FillerGaugeSVG"
                    >
                        <BackgroundBar
                            x={gaugeStartX}
                            y={gaugeStartY}
                            width={gaugeWidth}
                            height={gaugeHeight}
                            data-test="BackgroundBar"
                            backgroundBarFillColor={backgroundBarFillColor}
                        />
                        <MajorTicks
                            width={gaugeWidth}
                            height={gaugeHeight}
                            startX={gaugeStartX}
                            startY={gaugeStartY}
                            min={min}
                            max={max}
                            majorUnit={majorUnit}
                            orientation={orientation}
                            showLabels={showLabels}
                            usePercentageRange={usePercentageRange}
                            majorTickFillColor={majorTickFillColor}
                            majorTickStrokeColor={majorTickStrokeColor}
                        />
                        <FillBar
                            x={fillBarX}
                            y={fillBarY}
                            width={fillBarWidth}
                            height={fillBarHeight}
                            color={gaugeColor || assignGaugeColor({ value, min, max })}
                            data-test="FillBar"
                        />
                        <ValueMarker
                            x={valueMarkerX}
                            y={valueMarkerY}
                            length={GAUGE_THICKNESS}
                            min={min}
                            max={max}
                            value={value}
                            orientation={orientation}
                            showValue={showValue}
                            usePercentageValue={usePercentageValue}
                            valueMarkerFillColor={valueMarkerFillColor}
                            valueMarkerLabelFillColor={valueMarkerLabelFillColor}
                        />
                    </FillerGaugeSVG>
                </FillerGaugeContainer>
            </FillerGaugeWrapper>
        );
    };

    return (
        <Container width={width} height={height} backgroundColor={backgroundColor}>
            <SizeAwareWrapper>
                {containerDimension => renderVisualization(containerDimension)}
            </SizeAwareWrapper>
        </Container>
    );
};

const propTypes: Record<keyof FillerGaugeProps, T.Validator<any>> = {
    width: T.oneOfType([T.string, T.number]),
    height: T.oneOfType([T.string, T.number]),
    value: T.number,
    gaugeColor: T.string,
    min: T.number,
    max: T.number,
    // valueFieldName: T.string, // todo: need add it back when displaying name later
    // gaugeColorFieldName: T.string, // todo: may need add it back may not
    orientation: T.oneOf(['horizontal', 'vertical']),
    majorUnit: T.oneOfType([T.string, T.number]),
    showLabels: T.bool,
    showValue: T.bool,
    usePercentageRange: T.bool,
    usePercentageValue: T.bool,
    backgroundColor: T.string,
    backgroundBarFillColor: T.string,
    majorTickFillColor: T.string,
    majorTickStrokeColor: T.string,
    valueMarkerFillColor: T.string,
    valueMarkerLabelFillColor: T.string,
};

const defaultProps: Record<keyof FillerGaugeProps, any> = {
    width: '100%',
    height: 250,
    value: undefined,
    gaugeColor: undefined,
    orientation: 'vertical',
    min: 0,
    max: 100,
    majorUnit: 'auto',
    showLabels: true,
    showValue: true,
    usePercentageRange: false,
    usePercentageValue: false,
    backgroundColor: 'transparent',
    backgroundBarFillColor: '#f2f4f5',
    majorTickFillColor: '#3c444d',
    majorTickStrokeColor: '#6b7785',
    valueMarkerFillColor: '#3c444d',
    valueMarkerLabelFillColor: '#f2f4f5',
};

FillerGauge.propTypes = propTypes;
FillerGauge.defaultProps = defaultProps;

export default FillerGauge;
