import { getDataSelectorsFromDSL } from '@splunk/visualization-encoding/utils/dsl';

/**
 * Removes invalid keys in the options
 * @param currentOptions - Current visualization options
 * @param validOptions  - Valid visualization option keys
 * @returns void
 */
export const removeInvalidOptions = (
    currentOptions: Record<string, unknown>,
    validOptions: Record<string, unknown>
): void => {
    if (!currentOptions || !validOptions) {
        return;
    }
    const copiedCurrOptions = currentOptions;
    Object.keys(copiedCurrOptions).forEach(key => {
        if (!(key in validOptions)) {
            delete copiedCurrOptions[key];
        }
    });
};

/**
 * Renames viz options by looking up their migration mapping
 * @param oldOptions - Current visualization options
 * @param optionRenames  - Mapping of old options in viz.X to their migrated counterparts in splunk.X
 * @returns updatedOptions
 */
export const renameVizOptions = (
    oldOptions: Record<string, unknown>,
    optionRenames: Record<string, string>
): Record<string, unknown> => {
    const updatedOptions = {};

    if (!oldOptions) return {};
    if (!optionRenames) return oldOptions;

    Object.entries(oldOptions).forEach(([oldOptName, val]) => {
        const optionName = optionRenames[oldOptName] ?? oldOptName;
        updatedOptions[optionName] = val;
    });

    return updatedOptions;
};

/**
 * Appends appropriate series selector to DSL string to add viz-specific implicit logic
 * @param valueOption - the value of an option
 * @param selector  - the selector to append
 * @returns string | void
 */
export const preserveDataSelectionForValueOption = (
    valueOption: string | unknown,
    selector: string
): string | void => {
    if (valueOption) {
        // if the last data selector starts with seriesBy, append appropriate selector to it
        const dataSelectors = getDataSelectorsFromDSL(valueOption);
        const lastDataSelector = dataSelectors[dataSelectors.length - 1];
        if (lastDataSelector.includes('seriesByIndex') || lastDataSelector.includes('seriesByName')) {
            return `${valueOption} | ${selector}`;
        }
    }

    return null;
};

/**
 * For all data options, calls preserveDataSelectionForValueOption with relevant selector and
 *  updates dataOptions object
 * @param dataOptions - an object reflecting all data options
 * @param selector  - the selector to append
 * @returns void
 */
export const addsDataSelectorToOptions = (
    dataOptions: Record<string, unknown>,
    selector: string
): Record<string, unknown> => {
    const updatedDataOptions = {};

    Object.keys(dataOptions).forEach(key => {
        const statement = dataOptions[key] as string;
        const updatedDSL = preserveDataSelectionForValueOption(statement, selector);
        if (updatedDSL) {
            updatedDataOptions[key] = updatedDSL;
        } else {
            updatedDataOptions[key] = statement;
        }
    });

    return updatedDataOptions;
};
