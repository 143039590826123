import React from 'react';
import { _ } from '@splunk/ui-utils/i18n';
import SVG from '@splunk/react-icons/SVGEnterprise';

export default function Punchcard(props) {
    return (
        <SVG screenReaderText={_('Punchcard')} fill="currentColor" viewBox="0 0 150 100" {...props}>
            <g>
                <path d="M74.6613 25.3907C74.6613 29.4725 71.3493 32.7814 67.2637 32.7814C63.1782 32.7814 59.8661 29.4725 59.8661 25.3907C59.8661 21.3089 63.1782 18 67.2637 18C71.3493 18 74.6613 21.3089 74.6613 25.3907Z" />
                <path d="M25.5895 25.3907C25.5895 29.4157 22.3235 32.6787 18.2947 32.6787C14.266 32.6787 11 29.4157 11 25.3907C11 21.3657 14.266 18.1027 18.2947 18.1027C22.3235 18.1027 25.5895 21.3657 25.5895 25.3907Z" />
                <path d="M35.4796 25.3907C35.4796 25.8662 35.0937 26.2518 34.6177 26.2518C34.1418 26.2518 33.7559 25.8662 33.7559 25.3907C33.7559 24.9152 34.1418 24.5296 34.6177 24.5296C35.0937 24.5296 35.4796 24.9152 35.4796 25.3907Z" />
                <path d="M50.9407 30.6141C53.8282 30.6141 56.169 28.2755 56.169 25.3907C56.169 22.5059 53.8282 20.1673 50.9407 20.1673C48.0532 20.1673 45.7125 22.5059 45.7125 25.3907C45.7125 28.2755 48.0532 30.6141 50.9407 30.6141Z" />
                <path d="M83.5867 26.6448C84.28 26.6448 84.8419 26.0833 84.8419 25.3907C84.8419 24.6981 84.28 24.1366 83.5867 24.1366C82.8935 24.1366 82.3315 24.6981 82.3315 25.3907C82.3315 26.0833 82.8935 26.6448 83.5867 26.6448Z" />
                <path d="M106.749 25.3907C106.749 29.1644 103.687 32.2237 99.9097 32.2237C96.1324 32.2237 93.0704 29.1644 93.0704 25.3907C93.0704 21.617 96.1324 18.5577 99.9097 18.5577C103.687 18.5577 106.749 21.617 106.749 25.3907Z" />
                <path d="M116.233 29.7938C118.667 29.7938 120.64 27.8225 120.64 25.3907C120.64 22.9589 118.667 20.9876 116.233 20.9876C113.799 20.9876 111.825 22.9589 111.825 25.3907C111.825 27.8225 113.799 29.7938 116.233 29.7938Z" />
                <path d="M138.721 25.3907C138.721 28.7925 135.961 31.5502 132.556 31.5502C129.151 31.5502 126.39 28.7925 126.39 25.3907C126.39 21.9889 129.151 19.2312 132.556 19.2312C135.961 19.2312 138.721 21.9889 138.721 25.3907Z" />
                <path d="M18.2947 47.9379C21.7439 47.9379 24.5399 45.1444 24.5399 41.6985C24.5399 38.2526 21.7439 35.4591 18.2947 35.4591C14.8456 35.4591 12.0496 38.2526 12.0496 41.6985C12.0496 45.1444 14.8456 47.9379 18.2947 47.9379Z" />
                <path d="M37.2098 41.6985C37.2098 43.1287 36.0493 44.2882 34.6177 44.2882C33.1862 44.2882 32.0256 43.1287 32.0256 41.6985C32.0256 40.2683 33.1862 39.1088 34.6177 39.1088C36.0493 39.1088 37.2098 40.2683 37.2098 41.6985Z" />
                <path d="M50.9407 47.2758C54.0238 47.2758 56.5232 44.7788 56.5232 41.6985C56.5232 38.6183 54.0238 36.1212 50.9407 36.1212C47.8576 36.1212 45.3583 38.6183 45.3583 41.6985C45.3583 44.7788 47.8576 47.2758 50.9407 47.2758Z" />
                <path d="M71.6758 41.6985C71.6758 44.133 69.7005 46.1065 67.2637 46.1065C64.827 46.1065 62.8516 44.133 62.8516 41.6985C62.8516 39.264 64.827 37.2905 67.2637 37.2905C69.7005 37.2905 71.6758 39.264 71.6758 41.6985Z" />
                <path d="M83.5867 45.8635C85.8891 45.8635 87.7556 43.9988 87.7556 41.6985C87.7556 39.3982 85.8891 37.5335 83.5867 37.5335C81.2843 37.5335 79.4178 39.3982 79.4178 41.6985C79.4178 43.9988 81.2843 45.8635 83.5867 45.8635Z" />
                <path d="M102.117 41.6985C102.117 42.9162 101.129 43.9033 99.9097 43.9033C98.6909 43.9033 97.7028 42.9162 97.7028 41.6985C97.7028 40.4808 98.6909 39.4937 99.9097 39.4937C101.129 39.4937 102.117 40.4808 102.117 41.6985Z" />
                <path d="M116.233 48.039C119.738 48.039 122.579 45.2003 122.579 41.6985C122.579 38.1968 119.738 35.358 116.233 35.358C112.728 35.358 109.886 38.1968 109.886 41.6985C109.886 45.2003 112.728 48.039 116.233 48.039Z" />
                <path d="M139 41.6985C139 45.2543 136.115 48.1368 132.556 48.1368C128.997 48.1368 126.111 45.2543 126.111 41.6985C126.111 38.1427 128.997 35.2602 132.556 35.2602C136.115 35.2602 139 38.1427 139 41.6985Z" />
                <path d="M18.2947 59.6599C19.2089 59.6599 19.9499 58.9196 19.9499 58.0063C19.9499 57.0931 19.2089 56.3527 18.2947 56.3527C17.3806 56.3527 16.6396 57.0931 16.6396 58.0063C16.6396 58.9196 17.3806 59.6599 18.2947 59.6599Z" />
                <path d="M42.0741 58.0063C42.0741 62.1205 38.7358 65.4557 34.6177 65.4557C30.4997 65.4557 27.1614 62.1205 27.1614 58.0063C27.1614 53.8921 30.4997 50.5569 34.6177 50.5569C38.7358 50.5569 42.0741 53.8921 42.0741 58.0063Z" />
                <path d="M50.9407 64.6126C54.5927 64.6126 57.5532 61.6549 57.5532 58.0063C57.5532 54.3578 54.5927 51.4 50.9407 51.4C47.2888 51.4 44.3283 54.3578 44.3283 58.0063C44.3283 61.6549 47.2888 64.6126 50.9407 64.6126Z" />
                <path d="M68.7181 58.0063C68.7181 58.8088 68.0669 59.4593 67.2637 59.4593C66.4605 59.4593 65.8093 58.8088 65.8093 58.0063C65.8093 57.2038 66.4605 56.5533 67.2637 56.5533C68.0669 56.5533 68.7181 57.2038 68.7181 58.0063Z" />
                <path d="M83.5867 61.0282C85.2572 61.0282 86.6114 59.6752 86.6114 58.0063C86.6114 56.3374 85.2572 54.9845 83.5867 54.9845C81.9162 54.9845 80.5621 56.3374 80.5621 58.0063C80.5621 59.6752 81.9162 61.0282 83.5867 61.0282Z" />
                <path d="M104.565 58.0063C104.565 60.575 102.481 62.6573 99.9097 62.6573C97.3386 62.6573 95.2544 60.575 95.2544 58.0063C95.2544 55.4377 97.3386 53.3553 99.9097 53.3553C102.481 53.3553 104.565 55.4377 104.565 58.0063Z" />
                <path d="M116.233 59.9649C117.315 59.9649 118.193 59.088 118.193 58.0063C118.193 56.9246 117.315 56.0477 116.233 56.0477C115.15 56.0477 114.272 56.9246 114.272 58.0063C114.272 59.088 115.15 59.9649 116.233 59.9649Z" />
                <path d="M136.733 58.0063C136.733 60.3111 134.863 62.1795 132.556 62.1795C130.249 62.1795 128.379 60.3111 128.379 58.0063C128.379 55.7015 130.249 53.8331 132.556 53.8331C134.863 53.8331 136.733 55.7015 136.733 58.0063Z" />
                <path d="M18.2947 80.1328C21.5113 80.1328 24.1188 77.5277 24.1188 74.3141C24.1188 71.1006 21.5113 68.4955 18.2947 68.4955C15.0782 68.4955 12.4707 71.1006 12.4707 74.3141C12.4707 77.5277 15.0782 80.1328 18.2947 80.1328Z" />
                <path d="M38.7197 74.3141C38.7197 76.5775 36.8832 78.4123 34.6177 78.4123C32.3523 78.4123 30.5158 76.5775 30.5158 74.3141C30.5158 72.0508 32.3523 70.216 34.6177 70.216C36.8832 70.216 38.7197 72.0508 38.7197 74.3141Z" />
                <path d="M50.9407 82.0016C55.1904 82.0016 58.6354 78.5598 58.6354 74.3141C58.6354 70.0684 55.1904 66.6266 50.9407 66.6266C46.6911 66.6266 43.2461 70.0684 43.2461 74.3141C43.2461 78.5598 46.6911 82.0016 50.9407 82.0016Z" />
                <path d="M73.5562 74.3141C73.5562 77.7862 70.739 80.6008 67.2637 80.6008C63.7885 80.6008 60.9712 77.7862 60.9712 74.3141C60.9712 70.8421 63.7885 68.0275 67.2637 68.0275C70.739 68.0275 73.5562 70.8421 73.5562 74.3141Z" />
                <path d="M83.5867 77.3963C85.2905 77.3963 86.6717 76.0164 86.6717 74.3141C86.6717 72.6119 85.2905 71.232 83.5867 71.232C81.8829 71.232 80.5017 72.6119 80.5017 74.3141C80.5017 76.0164 81.8829 77.3963 83.5867 77.3963Z" />
                <path d="M106.106 74.3141C106.106 77.733 103.332 80.5046 99.9097 80.5046C96.4876 80.5046 93.7135 77.733 93.7135 74.3141C93.7135 70.8952 96.4876 68.1237 99.9097 68.1237C103.332 68.1237 106.106 70.8952 106.106 74.3141Z" />
                <path d="M116.233 78.4384C118.513 78.4384 120.361 76.5919 120.361 74.3141C120.361 72.0364 118.513 70.1899 116.233 70.1899C113.953 70.1899 112.105 72.0364 112.105 74.3141C112.105 76.5919 113.953 78.4384 116.233 78.4384Z" />
                <path d="M136.137 74.3141C136.137 76.2902 134.534 77.8921 132.556 77.8921C130.578 77.8921 128.974 76.2902 128.974 74.3141C128.974 72.3381 130.578 70.7362 132.556 70.7362C134.534 70.7362 136.137 72.3381 136.137 74.3141Z" />
            </g>
        </SVG>
    );
}
