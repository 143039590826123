import React from 'react';
import {
    selectGlobalInputs,
    selectGlobalMessage,
    useSelector,
} from '@splunk/dashboard-state';
import DashboardContainer, {
    type DashboardContainerProps,
} from '../DashboardContainer';

// TODO: migrate the content of this HOC into DashboardContainer
export default (
    props: Omit<DashboardContainerProps, 'message' | 'globalInputs'>
) => {
    // mapStateToProps
    const message = useSelector(selectGlobalMessage);
    const globalInputs = useSelector(selectGlobalInputs);

    return (
        <DashboardContainer
            {...props}
            message={message}
            globalInputs={globalInputs}
        />
    );
};
